import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Link, List, ListItemText, ListItemIcon,Typography } from '@mui/material';
import { MenuItemTheme,AdminListTheme } from './theme/MenuItemTheme.js';
import { withStyles } from '@mui/styles';
import MuiListItem from "@mui/material/ListItem";
import VersagoSvgIcon from 'common/VersagoSvgIcon.js';
import { Divider } from '@mui/material';
import { setTabTitle } from 'client/components/Common/Utility';
import { getVersion } from 'admin/actions/systemSettingsActions';
import { Scrollbars } from 'react-custom-scrollbars';
const ListItem = withStyles(AdminListTheme)(MuiListItem);

class AdminMenu extends React.Component {

    componentDidMount() {
        this.props.dispatch(getVersion());
    }

    handleClick=(e, item)=> {
       
        e.preventDefault();
        setTabTitle(item.label, true);
        this.props.handleClick(item);
       }
    renderIcon = (item) => {
        const classes = this.props.classes;
        if (item && (item.icon && item.icon.length > 0)) {
            return (
                <ListItemIcon className={classes.adminListItemIcon}>
                    <VersagoSvgIcon isAdmin={true} icon={item.icon} className={classes.subtitle2} />
                </ListItemIcon>
            )
        }
        else {
            return null;
        }
    }
    renderMenuItem = (item) => {
        const classes = this.props.classes;

        // TODO: box for top and bottom margin?
        return (
            <React.Fragment>
                {this.renderIcon(item)}
                <ListItemText className={classes.listItemText}
                    primary={this.renderListItemText(item)}
                />

            </React.Fragment>
        );

    }

    renderListItemText = (item) => {
        const classes = this.props.classes;
        const roleListPadding = item.isRoleList ? classes.roleList : null;
        const listItemText = item.subLabel
            ? <Box>
                <Typography className={classes.subtitle2} classes={{ root: roleListPadding }} display="inline">{item.label}</Typography>
                <Typography className={classes.subtitle2 + ' ' + classes.roleSelectedItem} classes={{ root: classes.subLabel }} >{item.subLabel}</Typography>
            </Box>
            : <Typography className={classes.subtitle2} classes={{ root: roleListPadding }} >{item.label}</Typography>;

        return (listItemText);
    }
    renderMenu = (item) => {
        const classes = this.props.classes;
        let param = window.location.pathname;
        const dense = (item.icon && item.icon.length > 0) ? classes.denseMenu : classes.dense;
        if (item.key !== "about") {
        return (
            <ListItem className={dense} button onClick={(e) =>
                this.handleClick(e, item)
            }
                selected={param === item.route}
                component={Link} href={item.route} disableTouchRipple={true}>
                <Box ml={this.props.level * 2} className={classes.adminBoxDisplay}>
                    {this.renderMenuItem(item)}
                </Box>
            </ListItem>
            );
        } else {
            let newitem = { ...item };
            newitem.label = newitem.label + this.props.version;
            return (
                <Box className={dense}>
                    <Box ml={this.props.level * 2} pt={1.5} className={classes.adminBoxDisplay}>
                        {this.renderMenuItem(newitem)}
                    </Box>
                </Box>
            );
        }
    }

    renderHeader = (item) => {
        return (
            <Box className={item.label === "SYSTEM SETUP" ? this.props.classes.adminMenuHeaderItem : this.props.classes.adminMenuHeaderMain}>
                {item.label === "SYSTEM SETUP" ? <Divider sx={{ borderBottomWidth: 2.2 }} /> : ""}
            <Box className={this.props.classes.adminMenuHeaderBox}>
                    <Typography variant={this.props.classes.subtitle2} className={this.props.classes.leftPanelHeader}  display="inline">{item.label}</Typography>
            </Box>
              
            </Box>
        )


    }
    renderMenuHeader = (data) => {
        const classes = this.props.classes;
        return (

            <List className={classes.adminList} dense={true}>

                {this.renderHeader(data)}
                {data.items ? this.renderSubHeader(data.items) : ""}
                
            </List>)

    }


    renderSubHeader = (data) => {

        return (
            data.map(item =>
                this.renderMenu(item)

            )
        );
    }


    render() {

        return (
            <Scrollbars autoHide renderTrackHorizontal={props => <Box {...props} className={this.props.classes.scrollbarHorizontal} />} className={this.props.classes.scrollbarContainer} >
            
            {this.props.items.map(item =>
                this.renderMenuHeader(item)

                )}
            </Scrollbars>
        );
    }

}

function mapStateToProps(state) {
    let props = {
        version: state.systemSettings.version,
    };
    return props;
};

export default withRouter(connect(mapStateToProps)(withStyles(MenuItemTheme)(AdminMenu)));


AdminMenu.propTypes = {
    items: PropTypes.array.isRequired,
};

AdminMenu.defaultProps = {
    level: 0,
    in: false,
    handleClick: (key) => { },
};
