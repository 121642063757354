export const navData = [
    //{
    //  key: "quickLink",
    //  icon: "icon-forms",
    //  label: "Quick Link",
    //  quickLink: true,
    //},
    {
        key: "configureContent",
        icon: "icon-folder",
        label: "CONFIGURED CONTENT",
        items: [
            {
                icon: "icon-dataView",
                key: "dataViews",
                label: "Data Views",
                route: "/Admin/DataViews",
            },
            {
                icon: "icon-forms",
                key: "forms",
                label: "Forms",
                route: "/Admin/Forms",
            },
            {
                icon: "icon-dashboards",
                key: "dashboards",
                label: "Dashboards",
                route: "/Admin/Dashboards",
            },
            {
                icon: "icon-menu",
                key: "menus",
                label: "Menus",
                route: "/Admin/Menus",
            },
            {
                icon: "icon-home",
                key: "banners",
                label: "Home Page",
                route: "/Admin/HomePage",
            },

            {
                icon: "icon-link",
                key: "apisLinks",
                label: "Other Links",
                route: "/Admin/OtherLinks",
            },
            {
                icon: "icon-theme",
                key: "siteTheme",
                label: "Site Theme",
                route: "/Admin/SiteTheme",
            },
        ],
    },
    {
        key: "System Setup",
        icon: "icon-folder",
        label: "SYSTEM SETUP",
        items: [
            {
                icon: "icon-verifiedUser",
                key: "users",
                label: "Users",
                route: "/Admin/Users",
            },
            {
                icon: "icon-role",
                key: "roles",
                label: "Roles",
                route: "/Admin/Roles",
            },

            {
                icon: "icon-filters",
                key: "profile",
                label: "Filters",
                route: "/Admin/Filters"
            },

            {
                icon: "icon-export",
                key: "export",
                label: "Export",
                route: "/Admin/Export",
            },
            {
                icon: "icon-export",
                key: "import",
                class: "icon-export",
                label: "Import",
                route: "/Admin/Import",
            },

            {

                key: "Connections",
                icon: "icon-database",
                label: "Connections",
                route: "/Admin/Databases",
            },
            {

                key: "settings",
                icon: "icon-settings",
                label: "Settings",
                route: "/Admin/Settings",
            },
            {
                key: "about",
                icon: "icon-info",
                label: "Version: ",
            },
        ],
    },

];


