import { produce } from 'immer';
import { ActionTypes as types } from 'admin/actions/actionTypes';
import { newHomePage } from 'admin/actions/homePageActions';

const initialState = {
    homePageContentList: [],
    homePageContent: newHomePage()
};

const homePage = produce((draft, action) => {

    //console.log( 'users reducer - action: ', action );

    draft.isUpdate = false;

    switch (action.type) {

        case types.VGO_GET_HOMEPAGE_SUCCESS:
            draft.homePageContentList = action.data;
            draft.homePageContent = newHomePage();
            draft.errors = [];
            break;
        case types.VGO_CHANGE_HOME_PAGE_CONTENT:

            action.change(draft.homePageContent);
            break;
        case types.VGO_VALIDATE_HOME_PAGE_ON_BLUR:

            draft.errors = produce(draft.errors, draftErrors => { return { ...draftErrors, ...action.errors } });
            break;

        case types.VGO_HOME_PAGE_CONTENT_SUCCESS:
            draft.homePageContent = action.data
            break;
        
        default:
            break;

    }

}, initialState);

export default homePage;
