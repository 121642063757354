import { ActionTypes as types } from './actionTypes';
import api from 'admin/api/adminApi';
import { showError, showSuccess, hideError, showConfirmWarning } from './alertActions';
import { getError } from './errorActions';
import { getErrorMessage } from './actionCommonMethods';
import { validateField, validateObject, validateObjectAt } from 'admin/actions/validationAction';
import { dataViewSetupSchema } from 'admin/components/section/dataViews/validationSchema';
import { dataViewCalendarSchema } from 'admin/components/section/dataViews/calendar/validationSchema';
import * as enumerator from 'client/components/Common/Enum';
import { addNewDataViewLink, getDataViewsLinks} from 'admin/actions/dataViewAddLinksActions';


let modelDataView = {};
const message = 'Please correct the indicated items'
const errorQueryMessage = 'No valid fields found, please check data source'
const dataViewTab = { Setup: 0, Calendar: 3,SubList:4,Links:5, RecordSubmission: 6, Chart: 7 }

const filterType = { UserAppliedFilter: 'userAppliedFilter', UserProfileFilter: 'userProfileFilter' }
const sourceType = { StoredProcedure: 'storedProcedure', Query: 'sqlQuery' }

const apiGetDataViews = () => {
    return api.getDataViews();
};

const apiGetDataView = (id) => {
    return api.getDataView(id);
};
const apiGetDataViewListAndFormListByRoleList = (roleList) => {
    return api.getDataViewListAndFormListByRoleList(roleList);
};
const apiGetDataViewListByRoleList = (roleList) => {
    return api.getDataViewListByRoleList(roleList);
};
const apiDeleteDataView = (id) => {
    return api.deleteDataView(id);
};

const apiGetCategories = () => {
    return api.getCategories();
};
const apiGetDataViewSourceList = (companyId, type) => {
    return api.getDataViewSourceList(companyId, type);
};

const apiGetDataViewSourceFieldList = (companyId, type, dataSource) => {
    return api.getDataViewSourceFieldList(companyId, type, dataSource);
};

const apiGetDataViewQueryFieldList = (dataView) => {
    return api.getDataViewQueryFieldList(dataView);
};
const apiUpdateDataView = (dataView) => {
    return api.updateDataView(dataView);
};


const apiGetAdminFilters = () => {
    return api.getFilters();
};
const apiExecuteStoredProcedureWithParameters = (dataView) => {
    return api.executeStoredProcedureWithParameters(dataView);
};

export const apiGetDataViewDependencyList = (id) => {
    return api.getDataViewDependencyList(id);
};

const getDataViewsRequest = () => {
    return {
        type: types.VGO_GET_DATAVIEWS_REQUEST,
    }
};
const getDataViewListByRoleListRequest = () => {
    return {
        type: types.VGO_GET_DATAVIEWLISTBYROLELIST_REQUEST,
    }
};
const getDataViewListAndFormListByRoleListRequest = () => {
    return {
        type: types.VGO_GET_DATAVIEWLISTANDFORMLISTBYROLELIST_REQUEST,
    }
};
export const getDataViewListByRoleListSuccess = (resp) => {
    return {
        type: types.VGO_GET_DATAVIEWLISTBYROLELIST_SUCCESS,
        dataViewRoleList: resp.data,
    };
};
export const getDataViewListAndFormListByRoleListSuccess = (resp) => {
    return {
        type: types.VGO_GET_DATAVIEWLISTANDFORMLISTBYROLELIST_SUCCESS,
        objectListRoleList: resp.data,
    };
};


const getDataViewRequest = (id) => {
    return {
        type: types.VGO_GET_DATAVIEW_REQUEST,
        reportId: id,
        
    };
};

const getDataViewSuccess = (resp) => {
    return {
        type: types.VGO_GET_DATAVIEW_SUCCESS,
        dataView: resp,
    };
};

const getDataViewsSuccess = (resp) => {
    return {
        type: types.VGO_GET_DATAVIEWS_SUCCESS,
        dataViews: resp.data,
        
    };
};

const deleteDataViewRequest = (id) => {
    return {
        type: types.VGO_DELETE_DATAVIEW_REQUEST,
        reportId: id,
    };
};

const deleteDataViewSuccess = (id) => {
    return {
        type: types.VGO_DELETE_DATAVIEW_SUCCESS,
        reportId: id,
    };
};

const getUpdateDataViewRequest = (dataView) => {
    return {
        type: types.VGO_UPDATE_DATAVIEW_REQUEST,
        dataView: dataView,
    };
};

const getUpdateDataViewSuccess = (resp) => {
    resp.data.isFromUpdate = true;
    return {
        type: types.VGO_GET_DATAVIEW_SUCCESS,
        dataView: resp.data,

    };
};

const getUpdateDataViewSubRecordMappingFieldsSuccess = (resp) => {
    return {
        type: types.VGO_UPDATE_DATAVIEW_SUBRECORD_MAPPINGFIELDS_SUCCESS,
        subRecord: resp.data.reportSubReport,
    };
};

const getUpdateDataViewLinksSuccess = (resp) => {
    return dispatch => {
        if (resp.data.reportLink.length === 0) {
            dispatch(addNewDataViewLink());
        }
        else {
            dispatch(getDataViewsLinks(resp.data.reportLink));
        }
    }
}

const rowUpdateFilterSuccess = (dataView, value, rowIndex) => {
    return {
        type: types.VGO_UPDATE_DATAVIEW_ROW_FILTER_SUCCESS,
        dataView: dataView,
        data: value,
    };
};

const getAdminFiltersRequest = () => {
    return {
        type: types.VGO_GET_FILTERS_REQUEST,
    }
};

const getAdminFiltersSuccess = (resp) => {
    return {
        type: types.VGO_GET_FILTERS_SUCCESS,
        filters: resp.data,
    };
};

export const getNewDataView = () => {
    return {
        type: types.VGO_NEW_DATAVIEW,
    };
};
export const getChangeDataView = (change) => {
    return {
        type: types.VGO_CHANGE_DATAVIEW,
        change,
    };
}
export const getChangeDataViewDatabase = (change) => {
    return {
        type: types.VGO_CHANGE_DATAVIEW_DATABASE,
        change,
    };
}
export const getChangeDataViewDataSourceType = (change) => {
    return {
        type: types.VGO_CHANGE_DATAVIEW_DATASOURCETYPE,
        change,
    };


}
const getCategoriesRequest = () => {
    return {
        type: types.VGO_GET_CATEGORIES_REQUEST,
    }
};
const getCategoriesSuccess = (resp) => {
    return {
        type: types.VGO_GET_CATEGORIES_SUCCESS,
        categories: resp.data,
    };
};
export const updateCategoryList = (catgeory) => {
    return {
        type: types.VGO_UPDATE_CATEGORYLIST,
        category: catgeory,
    };
};
const getDataViewSourceRequest = () => {
    return {
        type: types.VGO_GET_DATAVIEW_DATASOURCE_REQUEST,
    }
};
const getDataViewSourceListSuccess = (resp) => {
    console.log(resp.data)
    return {
        type: types.VGO_GET_DATAVIEW_DATASOURCE_SUCCESS,
        dataSources: resp.data,
    }
};
const getDataViewSourceFieldRequest = () => {
    return {
        type: types.VGO_GET_DATAVIEW_DATASOURCE_FIELD_REQUEST,
    }
};
const getDataViewSourceFieldListSuccess = (resp, tabIndex, oldFieldDisplay, oldFilters, recordProcessing) => {
    return {
        type: types.VGO_GET_DATAVIEW_DATASOURCE_FIELD_SUCCESS,
        fieldSettings: resp.data,
        newTabIndex: tabIndex,
        oldFields: oldFieldDisplay,
        oldFilters: oldFilters,
        recordProcessing: recordProcessing
    }
};

const getDataViewQueryFieldRequest = () => {
    return {
        type: types.VGO_GET_DATAVIEW_QUERY_FIELD_REQUEST,
    }
};
const getDataViewQueryFieldListSuccess = (resp, tabIndex, oldFieldDisplay, oldFilters, type, recordProcessing, subRecord) => {

    let showResult = true;
    if (type === sourceType.StoredProcedure) {
        showResult = false;
    }
    return {
        type: types.VGO_GET_DATAVIEW_QUERY_FIELD_SUCCESS,
        queryData: resp.data.ResponseObject,
        newTabIndex: tabIndex,
        oldFields: oldFieldDisplay,
        oldFilters: oldFilters,
        showResult: showResult,
        recordProcessing: recordProcessing,
        subRecord: subRecord
    }
};

const getExecuteStoredProcedureWithParamRequest = (dataView) => {
    return {
        type: types.VGO_EXECUTE_STORED_PROCEDURE_WITH_PARAMETER_DATAVIEW_REQUEST,
        dataView: dataView,
    };
};

const getExecuteStoredProcedureWithParamSuccess = (resp, tabIndex) => {
    return {
        type: types.VGO_EXECUTE_STORED_PROCEDURE_WITH_PARAMETER_DATAVIEW_SUCCESS,
        queryData: resp.data,
        newTabIndex: tabIndex,
    };
};
const getDataViewCrystalReportFilterListSuccess = (filterList, dataSourceName) => {
    return {
        type: types.VGO_GET_CRYSTALREPORT_FILTER_SCUCCESS,
        filterList: filterList,
        dataSourceName: dataSourceName,
    };
}

const resetReportIdSuccess = () => {
    return {
        type: types.VGO_RESET_REPORTID_SUCCESS,
        
    };
}

const getQueryError = (errorMessage) => {
    return {
        type: types.VGO_GET_DATAVIEW_QUERY_FIELD_ERROR,
        message: errorMessage,
    };
};
const getViewError = (errorMessage) => {
    return {
        type: types.VGO_GET_DATAVIEW_VIEW_FIELD_ERROR,
        message: errorMessage,
    };
};
const showQueryResultPanel = (show) => {
    return {
        type: types.VGO_CHANGE_DATAVIEW_QUERYRESULT_PANEL,
        show: show,
    };
};

const resultWindow = (visible) => {
    return {
        type: types.VGO_HIDE_RESULT_SUCCESS,
        isVisible: visible,
    };
};

export const getDataViews = () => {
    return dispatch => {
        dispatch(getDataViewsRequest());
        return apiGetDataViews()
            .then((resp) => {
                dispatch(getDataViewsSuccess(resp));
                hideError(dispatch);
            })
            .catch(err => {
                //const { message } = err;
                const errorMessage = !err.response.data ? 'This data list does not exist' : err.response.data;
                dispatch(getError(errorMessage));
                showError(dispatch, errorMessage, 5000);
            });
    }
};
export const getDataViewListByRoleList = (roleList) => {
    return dispatch => {
        if (roleList) {
            dispatch(getDataViewListByRoleListRequest());
            return apiGetDataViewListByRoleList(roleList)
                .then((resp) => {
                    dispatch(getDataViewListByRoleListSuccess({ data: { roles: roleList, dataViews: resp.data } }));
                    hideError(dispatch);
                })
                .catch(err => {
                    //const { message } = err;
                    const errorMessage = !err.response.data ? 'This data list does not exist' : err.response.data;
                    dispatch(getError(errorMessage));
                    showError(dispatch, errorMessage, 5000);
                });
        }
        else {
            dispatch(getDataViewListByRoleListSuccess({ data: { roles: '', dataViews: [] }}));
        }
        
    }
};

export const getDataViewListAndFormListByRoleList = (roleList) => {
    return dispatch => {
        if (roleList) {
            dispatch(getDataViewListAndFormListByRoleListRequest());
            return apiGetDataViewListAndFormListByRoleList(roleList)
                .then((resp) => {
                    dispatch(getDataViewListAndFormListByRoleListSuccess({ data: { roles: roleList, objectList: resp.data } }));
                    hideError(dispatch);
                })
                .catch(err => {
                    //const { message } = err;
                    const errorMessage = !err.response.data ? 'This data list does not exist' : err.response.data;
                    dispatch(getError(errorMessage));
                    showError(dispatch, errorMessage, 5000);
                });
        }
        else {
            dispatch(getDataViewListAndFormListByRoleListSuccess({ data: { roles: '', objectList: { dataViewList: [], formList: [] } } }));
        }

    }
};
export const getObjectListByRole = (dataView) => {
    if (dataView.objectRoleDTO) {
        let roleList = "";
        for (let i = 0; i < dataView.roles.length; i++) {
            roleList = `${roleList}roleIdList[${i}]=${dataView.roles[i].roleId}&`
        }

        return { roles: roleList, objectList: dataView.objectRoleDTO }
    }
    else
    {
        return { roles: '', objectList: { dataViewList: [], formList: [] } };
    }
}


export const getDataView = (id) => {
    return dispatch => {
        dispatch(getDataViewRequest(id));
        return apiGetDataView(id)
            .then((resp) => {
                dispatch(getDataViewSuccess(resp.data.responseObject));

                if (resp.data.message !== "") {
                    showError(dispatch, resp.data.message, 5000);

                } else {
                   
                    hideError(dispatch);

                }
                
            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
                showError(dispatch, message, 5000);
            });
    };
};

export const deleteDataView = (id) => {
    return dispatch => {
        dispatch(deleteDataViewRequest(id));
        return apiDeleteDataView(id)
            .then((resp) => {
                dispatch(deleteDataViewSuccess(id));
                showSuccess(dispatch, 'Success', 'Data view deleted', 5000);
            })
            .catch((err) => {
                const message = getErrorMessage(err);
                dispatch(getError(message));
                showError(dispatch, message, 5000);
            });
    };
};

export const getCategories = () => {
    return dispatch => {
        dispatch(getCategoriesRequest());
        return apiGetCategories()
            .then((resp) => {
                dispatch(getCategoriesSuccess(resp));
            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
                showError(dispatch, message, 5000);
            });
    }
};

export const getDataViewSourceList = (companyId, dataSourceType) => {
    return dispatch => {
        dispatch(getDataViewSourceRequest());
        return apiGetDataViewSourceList(companyId, dataSourceType)
            .then(resp => {
                dispatch(getDataViewSourceListSuccess(resp));
            })
            .catch(err => {
                const errorMessage = err.response.data;
                dispatch(getError(errorMessage));
                showError(dispatch, errorMessage, 5000);
            })
    };
};

//view dataosurce change
export const getDataViewSourceFieldList = (companyId, dataSourceType, dataSource, newTabIndex, showErrorMessage, oldFieldDisplay, oldFilters, recordProcessing) => {
    return dispatch => {
        dispatch(getDataViewSourceFieldRequest());
        return apiGetDataViewSourceFieldList(companyId, dataSourceType, dataSource)
            .then(resp => {
                dispatch(getDataViewSourceFieldListSuccess(resp, newTabIndex, oldFieldDisplay, oldFilters, recordProcessing));
            })
            .catch(err => {
                let errorMessage = !err.response.data ? 'Query execution failed' : err.response.data;
                if (dataSource.trim() == '') {

                    dispatch(resultWindow(false));

                } else {
                    dispatch(getQueryError(errorMessage));
                    if (showErrorMessage)
                        showError(dispatch, errorMessage, 5000);
                }

            })
    };
};
export const getDataViewQueryFieldList = (dataView, newTabIndex, showErrorMessage, oldFieldDisplay, oldFilters, recordProcessing, subRecord) => {
    return dispatch => {
        dispatch(getDataViewQueryFieldRequest());
        return apiGetDataViewQueryFieldList(dataView)
            .then(resp => {
               
                dispatch(getDataViewQueryFieldListSuccess(resp, newTabIndex, oldFieldDisplay, oldFilters, dataView.dataSourceType, recordProcessing, subRecord));
                if (dataView.dataSourceType === sourceType.StoredProcedure && resp.data.ResponseObject.fields.length === 0) {
                    showError(dispatch, resp.data.Message, 5000);

                } else {
                    hideError(dispatch);
                }


            })
            .catch(err => {
                let errorMessage = !err.response.data ? 'Query execution failed' : errorQueryMessage;
                if (dataView.dataSourceType === sourceType.Query) {

                    dispatch(getQueryError(errorMessage));
                    if (showErrorMessage)
                        showError(dispatch, errorMessage, 5000);
                } else {
                    if (!dataView.dataSource.name) {
                        dispatch(resultWindow(false));

                    } else {

                        dispatch(getViewError(errorMessage));
                        if (showErrorMessage)
                            showError(dispatch, errorMessage, 5000);
                    }
                }

            })
    };
};

export const getDataViewCrystalReportFilterList = (filterList, dataSourceName) => {
    return dispatch => {
        dispatch(getDataViewCrystalReportFilterListSuccess(filterList, dataSourceName));

    };
};


const updateDataViewCore = (dispatch, dataView, passCalendarValidation, objectListByRole, action) => {
    return dispatch => {
        let validationStatus = validateDataView(dataView);
        if (validationStatus.status) {
            let hasinAccessibleObject = false;
            let actionTab = '', subTab = '';
            let tab = 0;
            if (dataView.reportLink && dataView.reportLink.length > 0) {
                const linkReports = dataView.reportLink.filter(link => link.actionType === enumerator.LinkActionType.Report)
                const linkForms = dataView.reportLink.filter(link => link.actionType === enumerator.LinkActionType.Forms)
                if (linkReports.length > 0) {
                    const inAccessibleReportLinkList = linkReports.filter(link => objectListByRole.objectList.dataViewList.every(dataview => link.actionDestinationObjectId && dataview.objectId !== link.actionDestinationObjectId))
                    if (inAccessibleReportLinkList.length > 0) {
                        hasinAccessibleObject = true;
                        actionTab = 'Links'
                        tab = dataViewTab.Links;
                    }
                    
                }
                if (linkForms.length > 0 && !hasinAccessibleObject) {
                    const inAccessibleFormLinktList = linkForms.filter(link => objectListByRole.objectList.formList.every(form => link.actionDestinationObjectId && form.objectId !== link.actionDestinationObjectId))
                    if (inAccessibleFormLinktList.length > 0) {
                        hasinAccessibleObject = true;
                        actionTab = 'Links'
                        tab = dataViewTab.Links;
                    }
                    
                }

                let messageStatus = false;
                for (var i =0 ; i < dataView.reportLink.length; i++ )
                {
                    let reportLink = dataView.reportLink[i]
                    if ((reportLink.actionName || reportLink.actionType !== 0 || reportLink.actionMode !== 0 || reportLink.actionDestination) && (!reportLink.actionName && reportLink.actionType === 0 || reportLink.actionMode === 0 || !reportLink.actionDestination)) {
                        messageStatus = true;
                        break;
                    }

                    if (reportLink.actionType === enumerator.LinkActionType.BizweaverWebservice ) {
                        if (!reportLink.bizweaverTaskId) {
                            messageStatus = true;
                            break;
                        }
                    }

                    if (reportLink.actionMode === enumerator.ActionMode.ExistingColumn) {
                        if (!reportLink.actionLinkField) {
                            messageStatus = true;
                            break;
                        }
                    }
                }
                if (messageStatus && action.isValidate) {
                   
                    const message = `The setup of one or more links is not complete.Those links will not be saved.<br> Continue?`
                    dispatch(changeTabIndex(dataViewTab.Links));
                    showConfirmWarning(dispatch, message, action);
                    return false;
                }

               
            }
            if (dataView.reportSubReport && dataView.reportSubReport.length > 0 && dataView.reportSubReport[0].reportId ) {
                const inAccessibleSubReportList = objectListByRole.objectList.dataViewList.every(dataview => dataview.objectId !== dataView.reportSubReport[0].reportId)
                if (inAccessibleSubReportList) {
                    hasinAccessibleObject = true;
                    subTab = actionTab ? 'Sub-Records\\' : 'Sub-Records';
                    tab = dataViewTab.SubList;
                }
            }
            if (hasinAccessibleObject) {
                validationStatus.status = false;
                validationStatus.message = `Please remove inaccessible items from ${subTab}${actionTab}`;
                validationStatus.tab = tab;
            }
        }
        if (validationStatus.status && passCalendarValidation) {



            dispatch(getUpdateDataViewRequest(dataView));
            return apiUpdateDataView(dataView)
                .then((resp) => {
                    dispatch(getUpdateDataViewSuccess(resp));
                    if (resp.data.reportSubReport.length > 0) {
                        dispatch(getUpdateDataViewSubRecordMappingFieldsSuccess(resp));
                    }
                    dispatch(getUpdateDataViewLinksSuccess(resp));
                    showSuccess(dispatch, 'Success', 'Data View saved successfully', 5000);
                })
                .catch(err => {
                    let { message } = err;
                    if (err.response && err.response.data) {
                        message = err.response.data;
                        if (typeof (message) !== "string") {
                            if (message.title && typeof (message.title) === "string") {
                                message = message.title;
                            }
                            else {
                                message = err.message;
                            }
                        }
                    }

                    dispatch(getError(message));
                    showError(dispatch, message, 5000);
                });
        }
        else {

            if (validationStatus.status) {
                validationStatus.status = passCalendarValidation;
                validationStatus.message = message;
                validationStatus.tab = dataViewTab.Calendar;
            }
            dispatch(getError(validationStatus.message));
            showError(dispatch, validationStatus.message, 5000);
            dispatch(changeDataViewTab(validationStatus.tab));
            if (validationStatus.tab === dataViewTab.Setup || validationStatus.tab === dataViewTab.RecordSubmission || validationStatus.tab === dataViewTab.Calendar || validationStatus.tab === dataViewTab.Chart) {
                dispatch(showErrorMessage(modelDataView));

            }

        }
    }
}

export const updateDataView = (dataView, objectListByRole, action) => {
    return dispatch => {


        validateObject(dataViewSetupSchema, dataView)
            .then(resp => {
                if (resp !== true) {
                    dispatch(setValidateOnBlur(resp));
                }

            })
        let passCalendarValidation = true;
        if (dataView.reportFormat === enumerator.reportFormat.Calendar) {
            validateObject(dataViewCalendarSchema, dataView.reportCalender)
                .then(resp => {
                    if (resp !== true) {
                        passCalendarValidation = false;
                        dispatch(setValidateCalendarFieldsOnBlur(resp));
                    }

                    // update core function code
                    dispatch(updateDataViewCore(dispatch, dataView, passCalendarValidation, objectListByRole, action));

                })
        }
        else {
            dispatch(updateDataViewCore(dispatch, dataView, passCalendarValidation, objectListByRole, action));
        }
    };
};

export const executeStoredProcedure = (dataView, newTabIndex) => {
    return dispatch => {
        dispatch(getExecuteStoredProcedureWithParamRequest(dataView));
        return apiExecuteStoredProcedureWithParameters(dataView)
            .then((resp) => {
                dispatch(getExecuteStoredProcedureWithParamSuccess(resp, newTabIndex));

            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
                showError(dispatch, "Stored procedure execution failed", 5000);
                dispatch(changeDataViewTab(1));
            });
    };
};




const changeTabIndex = (tabIndex) => {
    return {
        type: types.VGO_CHANGE_DATAVIEW_TAB_INDEX,
        tabIndex: tabIndex,
    };
}

export const changeDataViewTab = (tabIndex) => {
    return dispatch => { dispatch(changeTabIndex(tabIndex)) };
}
export const setQueryResultPanel = (show) => {
    return dispatch => { dispatch(showQueryResultPanel(show)) };
}

export const showErrorMessage = (dataView) => {

    return {
        type: types.VGO_CHANGE_DATAVIEW_ERROR_MESSAGE,
        dataView: dataView,
    };
}



export const rowUpdateFilter = (dataFilters, data, rowIndex) => {
    return dispatch => {
        dispatch(rowUpdateFilterSuccess(dataFilters, data, rowIndex));
    };
};

export const newDataView = () => {
    let dataView = {
        reportId: 0,
        reportName: '',
        title: '',
        description: '',
        userInstruction: '',
        category: { categoryId: 0, categoryName: '' },
        company: { databaseId: 0, databaseConnectionName: "", database: "" },
        dataSourceType: 'sqlQuery',
        roles: [],
        fieldDisplay: [],
        reportFilter: [],
        reportLink: [],
        isPublic: false,
        doNotShowInMenu: false,
        reportFormat: enumerator.reportFormat.Regular,
        enablePaging: false,
        pageCount: 0,
        query: '',
        reportDataSource: '',
        dataSource: { name: '', value: '' },
        hasDependency: false,
        reportNameError: false,
        reportNameHelper: ' ',
        titleError: false,
        titleHelper: ' ',
        categoryError: false,
        categoryHelper: ' ',
        connectionError: false,
        connectionHelper: ' ',
        dataSourceError: false,
        dataSourceHelper: ' ',
        rolesHelper: ' ',
        rolesError: false,
        pageCountHelper: ' ',
        pageCountError: false,
        recordProcessing: {
            enablePayment: false,
            isProcessPayments: false,
            selectedRowScreenTitle: '',
            editableFieldId: '',
            hasAggregate: false,
            selectedRowSubmitCaption: '',
            selectedRowClearCaption: '',
            hasConfirm: false,
            confirmScreenTitle: '',
            confirmInstructions: '',
            displayFieldId: '',
            confirmSubmitCaption: '',
            confirmClearCaption: '',
            databaseId: 0,
            tableName: '',
            selectedRowScreenInstructions: '',
            id: 0,
            oldTableName: '',
            hasBzwEnable: false,
            bzwWorkFlowId: 0,
            bzwServiceURL: '',
            bzwBatchIdParameterName: '',
        },
        reportCalender: getNewCalendarObject(),
        chart: [{
            chartId: 0,
            chartManager: { name: "", },
            chartName: "",
            chartTitle: "",
            chartType: "none",
            chartYAxes: [
                {
                    chartDetailId: 0,
                    chartId: 0,
                    chartType: "none",
                    label: "",
                    legendText: "",
                    radius: "",
                    seriesColor: "#2196F3",
                    seriesLegend: "",
                    toolTip: "",
                    yColumn: "",
                    yText: "",
                }],
            groupField: "",
            id: 0,
            isStacked: false,
            recordCountChart: 0,
            useDefaultYScale: false,
            xColumn: "",
            xText: "",
        }],
        executeQuery: false,
    };
    return dataView;
};

export const newDataFilter = () => {
    let dataFilter = {
        paramName: '',
        paramFriendlyName: '',
        paramDefaultValue: '',
        controlType: 'Text',
        paramType: 'User-AppliedFilter',
        paramFilterType: 'Equals',
        isVisible: true,
        dataFilterDetails: [],
        controlVisibleOrder: 1
    };
    return dataFilter;
};

export const newDataFilterDetails = () => {
    let dataFilterDetails = {
        valueId: 0,
        valueDisplayId: '',
        valueDescription: '',
        paramFriendlyName: '',
    };
    return dataFilterDetails;
};

export const rowUpdateFilterValue = (action) => {

    let dataView = Object.assign([], action.dataView);
    if (action.data) {
        dataView = action.data
    }
    return dataView;
};

export const getAdminFilters = () => {
    return dispatch => {
        dispatch(getAdminFiltersRequest());
        return apiGetAdminFilters()
            .then(resp => {
                dispatch(getAdminFiltersSuccess(resp));
            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
            })
    };
};

const filterData = (dataSourceType, displayOrder) => {
    const data = {
        parameterId: 0,
        dropDownListValueType: 'none',
        isMandatory: false,
        isVisible: true,
        controlType: 'text',
        parameterDefaultValue: '',
        parameterFilterType: 'equals',
        parameterFriendlyName: '',
        adminParameterName: '',
        parameterName: '',
        lineBreak: false,
        parameterType: dataSourceType === sourceType.StoredProcedure
            ? filterType.UserProfileFilter
            : filterType.UserAppliedFilter,
        reportFilterDetails: [],
        controlVisibleOrder: displayOrder
    };
    return data;
};
export const getFilterValues = (storedProcedureList, dataSourceType, fieldList, oldFilters) => {
    let data = [];
    if (dataSourceType === sourceType.StoredProcedure) {
        const oldFilterData = getProfileFilterList(oldFilters)
        data = getStoredProcedureDataFilterList(storedProcedureList, oldFilterData)
        data.push(filterData(dataSourceType, data.length + 1))
    } else {
        data = getQueryDataFilterList(fieldList, oldFilters)
        data.push(filterData(dataSourceType, data.length + 1))

    }
    return data;

}




export const getStoredProcedureDataFilterList = (storedProcedureList, oldFilterData) => {
    let index = 0;


    let dataFilterList = storedProcedureList.map((parameter) => {
        index++;
        return {
            adminParameterName: parameter.fieldName,
            parameterName: parameter.fieldName,
            parameterFriendlyName: parameter.fieldName.replace('@', '').trim(),
            parameterDefaultValue: parameter.value,
            controlType: getControlType(parameter.dataType),
            parameterType: "userAppliedFilter",
            parameterDataType: parameter.dataType,
            parameterFilterType: 'equals',
            isVisible: true,
            reportFilterDetails: [],
            controlVisibleOrder: index
        };
    });


    oldFilterData.forEach((parameter) => {
        index++;
        dataFilterList.push({
            adminParameterName: parameter.parameterName,
            controlType: "text",
            dropDownListValueType: "none",
            isMandatory: false,
            isVisible: true,
            lineBreak: false,
            parameterDataType: "nvarchar",
            parameterDefaultValue: null,
            parameterFilterType: "equals",
            parameterFriendlyName: "",
            parameterId: 709,
            parameterName: parameter.parameterName,
            parameterOrder: index,
            parameterType: "userProfileFilter",
            controlVisibleOrder: index
        });

    });




    return dataFilterList;
}


export const getControlType = (dataType) => {
    let controlType = '';
    switch (dataType) {
        case "int":
        case "smallint":
        case "decimal":
        case "float":
        case "money":
        case "numeric":
        case "real":
        case "integer":
        case "uniqueIdentifier":
            {
                controlType = 'numeric'
                break;
            }
        case "char":
        case "nchar":
        case "nText":
        case "nvarchar":
        case "text":
        case "varchar":
        case "xml":
            {
                controlType = 'text'
                break;
            }
        case "date":
        case "datetime":
        case "timestamp":
            {
                controlType = 'datePicker'
                break;
            }
        case "bit":
            {
                controlType = 'checkBox'
                break;
            }
        default:
            {
                controlType = 'text';
            }
    }

    return controlType;
}

const isCheckValue = (value) => {

    if (value === null || value === "Select" || value === "0" || value === 0) {
        return true;
    } else {

        if (typeof value === 'string' || value instanceof String) {

            if (value.trim() === "") {
                return true;
            }
        }

    }

    return false;
}


const getQueryDataFilterList = (fieldList, oldFilters) => oldFilters.filter(obj => fieldList.some(obj2 => obj.parameterName === obj2.fieldName || obj.parameterType === filterType.UserProfileFilter));
const getProfileFilterList = (oldFilters) => oldFilters.filter(obj => obj.parameterType === filterType.UserProfileFilter && obj.parameterName !== null && obj.parameterName !== '');

const validateDataView = (dataView) => {
    let validationStatus = {
        status: true,
        message: "",
        tab: 0
    }
    modelDataView = Object.assign({}, dataView)
    if (!dataView.reportName) {
        validationStatus.status = false;
        validationStatus.message = message;
        validationStatus.tab = 0;
        modelDataView.reportNameError = true;
        modelDataView.reportNameHelper = 'Name is required';
    }
    if (!dataView.title) {
        validationStatus.status = false;
        validationStatus.message = message;
        validationStatus.tab = 0;
        modelDataView.titleError = true;
        modelDataView.titleHelper = 'Title is required';
    }
    if (dataView.category.categoryId === 0 && !dataView.category.categoryName) {
        validationStatus.status = false;
        validationStatus.message = message;
        validationStatus.tab = 0;
        modelDataView.categoryError = true;
        modelDataView.categoryHelper = 'Category is required';
    }

    if (dataView.roles.length === 0) {
        validationStatus.status = false;
        validationStatus.message = message;
        validationStatus.tab = 0;
        modelDataView.rolesError = true;
        modelDataView.rolesHelper = 'Roles is required';
    }
    if (dataView.enablePaging && !dataView.pageCount) {
        validationStatus.message = message;
        validationStatus.status = false;
        modelDataView.pageCountError = true;
        modelDataView.pageCountHelper = 'Record limit is required';
        validationStatus.tab = 0;
    }
    if (dataView.enablePaging && dataView.pageCount) {
        if (dataView.pageCount < 1) {
            validationStatus.message = message;
            validationStatus.status = false;
            modelDataView.pageCountError = true;
            modelDataView.pageCountHelper = 'Record limit is required';
            validationStatus.tab = 0;
        }
    }
    if (!dataView.dataSourceType) {
        validationStatus.status = false;
        validationStatus.message = message;
        validationStatus.tab = 0;
        modelDataView.dataSourceError = true;
        modelDataView.dataSourceHelper = 'DataSource is required';
    }
    if (dataView.company.databaseId === 0) {
        validationStatus.status = false;
        validationStatus.message = message;
        validationStatus.tab = 0;
        modelDataView.connectionError = true;
        modelDataView.connectionHelper = 'Connection is required';
    }
    else if (dataView.dataSourceType === "sqlQuery" && !dataView.query) {
        validationStatus.status = false;
        validationStatus.message = "A query must be provided";
        validationStatus.tab = 0;
    }
    else if (dataView.dataSourceType === "crystalReport" && !dataView.reportDataSource) {
        validationStatus.status = false;
        validationStatus.tab = 0;
        validationStatus.message = "A Crystal report file is required";
    }
    else if (!dataView.dataSource.name && dataView.dataSourceType !== "sqlQuery" && dataView.dataSourceType !== "crystalReport") {
        validationStatus.status = false;
        validationStatus.message = message;
        validationStatus.tab = 0;
        modelDataView.dataSourceFieldError = true;
        modelDataView.dataSourceFieldHelper = 'DataSource is required';
    }
    else if (!dataView.executeQuery && dataView.dataSourceType !== "crystalReport") {
        validationStatus.status = false;
        validationStatus.message = "Please execute the query";
        validationStatus.tab = 0;
    }


    else if (dataView.fieldDisplay.length === 0 && dataView.dataSourceType !== "crystalReport") {
        validationStatus.status = false;
        validationStatus.message = errorQueryMessage;
        validationStatus.tab = 0;
    }
    validateRecordSubmission(dataView, validationStatus);
    validateChart(dataView, validationStatus);
    validateSubRecord(dataView, validationStatus);
    return validationStatus;
}
const validateRecordSubmission = (dataView, validationStatus) => {
    const recordProcessing = dataView.recordProcessing;
    let modelRecordProcessing = Object.assign({}, modelDataView.recordProcessing)


    if (dataView.hasRecordSubmission === false) {

        if (isCheckValue(recordProcessing.databaseId)) {
            dataView.recordProcessing.databaseId = 0
        }
        return validationStatus;
    }

    if (recordProcessing.enablePayment && isCheckValue(recordProcessing.editableFieldId)) {
        validationStatus.status = false;
        validationStatus.message = message;
        modelRecordProcessing.editableFieldIdError = true;
        modelRecordProcessing.editableFieldIdHelper = 'Editable field is required';
        validationStatus.tab = dataViewTab.RecordSubmission;
    } else {
        modelRecordProcessing.editableFieldIdError = false;
        modelRecordProcessing.editableFieldIdHelper = ' ';

    }


    if (dataView.hasRecordSubmission && isCheckValue(recordProcessing.selectedRowSubmitCaption)) {
        validationStatus.status = false;
        validationStatus.message = message;
        modelRecordProcessing.submitButtonError = true;
        modelRecordProcessing.submitButtonHelper = 'Submit button label is required';
        validationStatus.tab = dataViewTab.RecordSubmission;
    } else {
        modelRecordProcessing.submitButtonError = false;
        modelRecordProcessing.submitButtonHelper = ' ';

    }
    if (dataView.hasRecordSubmission && isCheckValue(recordProcessing.selectedRowClearCaption)) {
        validationStatus.status = false;
        validationStatus.message = message;
        modelRecordProcessing.resetButtonError = true;
        modelRecordProcessing.resetButtonHelper = 'Reset button label is required';
        validationStatus.tab = dataViewTab.RecordSubmission;
    } else {
        modelRecordProcessing.resetButtonError = false;
        modelRecordProcessing.resetButtonHelper = ' ';

    }

    if ((recordProcessing.isProcessPayments || recordProcessing.hasConfirm) && isCheckValue(recordProcessing.confirmScreenTitle)) {
        validationStatus.status = false;
        validationStatus.message = message;
        modelRecordProcessing.confirmScreenTitleError = true;
        modelRecordProcessing.confirmScreenTitleHelper = 'Page title is required';
        validationStatus.tab = dataViewTab.RecordSubmission;
    } else {

        modelRecordProcessing.confirmScreenTitleError = false;
        modelRecordProcessing.confirmScreenTitleHelper = ' ';
    }

    if ((recordProcessing.isProcessPayments || recordProcessing.hasConfirm) && isCheckValue(recordProcessing.confirmInstructions)) {
        validationStatus.status = false;
        validationStatus.message = message;
        modelRecordProcessing.confirmInstructionError = true;
        modelRecordProcessing.confirmInstructionHelper = 'Page instructions is required';
        validationStatus.tab = dataViewTab.RecordSubmission;
    } else {
        modelRecordProcessing.confirmInstructionError = false;
        modelRecordProcessing.confirmInstructionHelper = ' ';
    }

    if (recordProcessing.isProcessPayments && isCheckValue(recordProcessing.displayFieldId)) {
        validationStatus.status = false;
        validationStatus.message = message;
        modelRecordProcessing.displayFieldError = true;
        modelRecordProcessing.displayFieldHelper = 'Confirmation page fields is required';
        validationStatus.tab = dataViewTab.RecordSubmission;
    } else {
        modelRecordProcessing.displayFieldError = false;
        modelRecordProcessing.displayFieldHelper = ' ';
    }
    if ((recordProcessing.isProcessPayments || recordProcessing.hasConfirm) && isCheckValue(recordProcessing.confirmSubmitCaption)) {
        validationStatus.status = false;
        validationStatus.message = message;
        modelRecordProcessing.confirmSubmitLabelError = true;
        modelRecordProcessing.confirmSubmitLabelHelper = 'Submit button label is required';
        validationStatus.tab = dataViewTab.RecordSubmission;
    } else {

        modelRecordProcessing.confirmSubmitLabelError = false;
        modelRecordProcessing.confirmSubmitLabelHelper = ' ';
    }

    if ((recordProcessing.isProcessPayments || recordProcessing.hasConfirm) && isCheckValue(recordProcessing.confirmClearCaption)) {
        validationStatus.status = false;
        validationStatus.message = message;
        modelRecordProcessing.confirmResetLabelError = true;
        modelRecordProcessing.confirmResetLabelHelper = 'Cancel button label is required';
        validationStatus.tab = dataViewTab.RecordSubmission;
    } else {
        modelRecordProcessing.confirmResetLabelError = false;
        modelRecordProcessing.confirmResetLabelHelper = ' ';

    }
    if (dataView.hasRecordSubmission && isCheckValue(recordProcessing.databaseId)) {
        validationStatus.status = false;
        validationStatus.message = message;
        modelRecordProcessing.databaseError = true;
        modelRecordProcessing.databaseHelper = 'Connection is required';
        validationStatus.tab = dataViewTab.RecordSubmission;
    }

    else {
        if (isCheckValue(recordProcessing.databaseId)) {
            dataView.recordProcessing.databaseId = 0
        }

        modelRecordProcessing.databaseError = false;
        modelRecordProcessing.databaseHelper = ' ';
    }

    if (recordProcessing.hasConfirm && isCheckValue(recordProcessing.displayFieldId)) {
        validationStatus.status = false;
        validationStatus.message = message;
        modelRecordProcessing.displayFieldError = true;
        modelRecordProcessing.displayFieldHelper = 'Confirmation page fields is required';
        validationStatus.tab = dataViewTab.RecordSubmission;

    } else {
        modelRecordProcessing.displayFieldError = false;
        modelRecordProcessing.displayFieldHelper = '';

    }

    //bizweaver validation
    if (recordProcessing.hasBzwEnable && isCheckValue(recordProcessing.bzwWorkFlowId)) {
        validationStatus.status = false;
        validationStatus.message = message;
        modelRecordProcessing.bzwWorkFlowIdError = true;
        modelRecordProcessing.bzwWorkFlowIdHelper = 'WorkFlow Id is required';
        validationStatus.tab = dataViewTab.RecordSubmission;
    } else {

        if (typeof recordProcessing.bzwWorkFlowId === 'string' || recordProcessing.bzwWorkFlowId instanceof String) {

            if (recordProcessing.bzwWorkFlowId.trim() === "") {
                recordProcessing.bzwWorkFlowId = 0
            }
        }

        modelRecordProcessing.bzwWorkFlowIdError = false;
        modelRecordProcessing.bzwWorkFlowIdHelper = ' ';
    }

    if (recordProcessing.hasBzwEnable && isCheckValue(recordProcessing.bzwServiceURL)) {
        validationStatus.status = false;
        validationStatus.message = message;
        modelRecordProcessing.bzwServiceURLError = true;
        modelRecordProcessing.bzwServiceURLHelper = 'Target URL is required';
        validationStatus.tab = dataViewTab.RecordSubmission;
    } else {

        modelRecordProcessing.bzwServiceURLError = false;
        modelRecordProcessing.bzwServiceURLHelper = ' ';
    }
    if (recordProcessing.hasBzwEnable && isCheckValue(recordProcessing.bzwBatchIdParameterName)) {
        validationStatus.status = false;
        validationStatus.message = message;
        modelRecordProcessing.bzwBatchIdParameterNameError = true;
        modelRecordProcessing.bzwBatchIdParameterNameHelper = 'Batch ID parameter name is required';
        validationStatus.tab = dataViewTab.RecordSubmission;
    } else {

        modelRecordProcessing.bzwBatchIdParameterNameError = false;
        modelRecordProcessing.bzwBatchIdParameterNameHelper = ' ';
    }

    //bizweaver validation

    if (dataView.hasRecordSubmission && !isCheckValue(recordProcessing.databaseId) && isCheckValue(recordProcessing.tableName)) {
        validationStatus.status = false;
        validationStatus.message = message;
        modelRecordProcessing.tableNameError = true;
        modelRecordProcessing.tableNameHelper = 'Table Name is required';
        validationStatus.tab = dataViewTab.RecordSubmission;
    } else {
        modelRecordProcessing.tableNameError = false;
        modelRecordProcessing.tableNameHelper = ' ';


    }
    if (recordProcessing.hasConfirm && !isCheckValue(recordProcessing.displayFieldId) && validationStatus.message === '') {

        if (recordProcessing.displayFieldId.indexOf(',') === -1) {

            if (recordProcessing.displayFieldId === 'Select') {
                validationStatus.status = false;
                validationStatus.message = message;
                modelRecordProcessing.displayField1Error = true;
                modelRecordProcessing.displayField1Helper = 'Confirmation page fields is required';
                validationStatus.tab = dataViewTab.RecordSubmission;
            }

        } else {
            const array = recordProcessing.displayFieldId.split(',')
            if (array.length < 3) {
                if (array[0] === 'Select' && array[1] === 'Select') {
                    validationStatus.status = false;
                    validationStatus.message = message;
                    modelRecordProcessing.displayFieldError = true;
                    modelRecordProcessing.displayFieldHelper = 'Confirmation page fields is required';
                    validationStatus.tab = dataViewTab.RecordSubmission;

                }
            } else {

                if (array[0] === 'Select' && array[1] === 'Select' && array[2] === 'Select') {
                    validationStatus.status = false;
                    validationStatus.message = message;
                    modelRecordProcessing.displayFieldError = true;
                    modelRecordProcessing.displayFieldHelper = 'Confirmation page fields is required';
                    validationStatus.tab = dataViewTab.RecordSubmission;

                }
            }


        }

    }

    modelDataView.recordProcessing = modelRecordProcessing
    return validationStatus;
}
const validateChart = (dataView, validationStatus) => {
    let chart = dataView.chart;
    chart.forEach((row, index) => {
        if (index < chart.length - 1) {
            if (!row.chartManager.name) {
                validationStatus.status = false;
                validationStatus.message = message;
                validationStatus.tab = 7;
                row.nameError = true;
                row.nameHelperText = "Name is required";
            }
            if (!row.chartTitle) {
                validationStatus.status = false;
                validationStatus.message = message;
                validationStatus.tab = 7;
                row.titleError = true;
                row.titleHelperText = "Title is required";
            }
            if (row.chartType === enumerator.ChartType.None && row.chartYAxes[0].chartType === enumerator.ChartType.None) {
                validationStatus.status = false;
                validationStatus.message = message;
                validationStatus.tab = 7;
                row.chartTypeError = true;
                row.chartTypeHelperText = "Chart type is required";
            }
            if (!row.xText) {
                validationStatus.status = false;
                validationStatus.message = message;
                validationStatus.tab = 7;
                row.xTextError = true;
                row.xTextHelperText = "Label is required";
            }
            if (!row.xColumn) {
                validationStatus.status = false;
                validationStatus.message = message;
                validationStatus.tab = 7;
                row.xColumnError = true;
                row.xColumnHelperText = "Field is required";
            }
            row.chartYAxes.forEach((yAxis, index) => {
                let yAxesLength = row.chartYAxes.length - 1;
                if (row.xText && row.chartYAxes.length === 1) {
                    yAxesLength = row.chartYAxes.length;
                }
                if (index < yAxesLength) {
                    if (yAxis.chartType === enumerator.ChartType.None) {
                        validationStatus.status = false;
                        validationStatus.message = message;
                        validationStatus.tab = 7;
                        yAxis.yChartTypeError = true;
                        yAxis.yChartTypeHelperText = "Chart type is required";
                    }
                    if (!yAxis.yText) {
                        validationStatus.status = false;
                        validationStatus.message = message;
                        validationStatus.tab = 7;
                        yAxis.yTextError = true;
                        yAxis.yTextHelperText = "Label is required";
                    }
                    if (!yAxis.yColumn) {
                        validationStatus.status = false;
                        validationStatus.message = message;
                        validationStatus.tab = 7;
                        yAxis.yColumnError = true;
                        yAxis.yColumnHelperText = "Field is required";
                    }
                }
            });


        }
    });

    return validationStatus;
}

const validateSubRecord = (dataView, validationStatus) => {
    if (dataView.reportSubReport) {
        if (dataView.reportSubReport.length > 0 && dataView.reportSubReport[0].mappingFields) {
            let mappingFields = dataView.reportSubReport[0].mappingFields;
            mappingFields.forEach((row, index) => {
                if (index < mappingFields.length - 1) {
                    if (row.helperText) {
                        validationStatus.status = false;
                        validationStatus.message = message;
                        validationStatus.tab = 4;
                    }
                }
            });
            return validationStatus;
        }
    }
}
const getDataViewsCalendarUpdateRequest = () => {
    return {
        type: types.VGO_UPDATE_DATAVIEW_CALENDER_REQUEST,
    }
};
const getDataViewsCalendarUpdateSuccess = (newValue) => {
    return {
        type: types.VGO_UPDATE_DATAVIEW_CALENDER_SUCCESS,
        calenderData: newValue,
    };
};

export const dataViewCalendarUpdate = (newValue) => {

    return dispatch => {
        dispatch(getDataViewsCalendarUpdateRequest());
        dispatch(getDataViewsCalendarUpdateSuccess(newValue));
    }
};
export const getNewCalendarObjectField = () => {
    let calendarField = {
        fieldId: 0,
        fieldName: "",
        displayFieldName: "",
        fieldValue: "",
        fieldType: "",
        title: "",
        fieldFormat: "none",
        culture: ""
    };
    return calendarField;
}
export const getNewCalendarObject = () => {
    const newCalendarObject = {
        id: 0,
        reportId: 0,
        startDate: getNewCalendarObjectField(),
        endDate: getNewCalendarObjectField(),
        startTime: getNewCalendarObjectField(),
        endTime: getNewCalendarObjectField(),
        titleFields: [getNewCalendarObjectField(),
        getNewCalendarObjectField(),
        getNewCalendarObjectField()
        ],
        eventFieldSettings: enumerator.CalendarEventFieldSettings.HasStartDate
    }
    return newCalendarObject;
}


export const validateOnBlur = (fieldName, value, fieldPath, dependencyFields) => {
    return dispatch => {
        validateField(dataViewSetupSchema, fieldPath, value, fieldPath, dependencyFields)
            .then(resp => {

                dispatch(setValidateOnBlur(resp));
            })

    }
}

const setValidateOnBlur = (errors) => {
    return {
        type: types.VGO_VALIDATE_FIELD_ONBLUR,
        errors: errors,
    };
};

export const validateCalendarFieldsOnBlur = (value, fieldPath,) => {
    return dispatch => {
        validateObjectAt(dataViewCalendarSchema, value, fieldPath, fieldPath)
            .then(resp => {

                dispatch(setValidateCalendarFieldsOnBlur(resp));
            })

    }
}

const setValidateCalendarFieldsOnBlur = (errors) => {
    return {
        type: types.VGO_VALIDATE_CALENDAR_FIELD_ONBLUR,
        errors: errors,
    };
};

export const resetCalendarObject = (removedFields, dataView) => {

    if (dataView.reportFormat === enumerator.reportFormat.Calendar && removedFields.length > 0) {
        if (dataView.reportCalender.startDate.fieldName && removedFields.some(field => field.fieldName === dataView.reportCalender.startDate.fieldName)) {
            dataView.reportCalender.startDate = getNewCalendarObjectField();
        }
        if (dataView.reportCalender.startTime.fieldName && removedFields.some(field => field.fieldName === dataView.reportCalender.startTime.fieldName)) {
            dataView.reportCalender.startTime = getNewCalendarObjectField();
        }
        if (dataView.reportCalender.endDate.fieldName && removedFields.some(field => field.fieldName === dataView.reportCalender.endDate.fieldName)) {
            dataView.reportCalender.endDate = getNewCalendarObjectField();
        }
        if (dataView.reportCalender.endTime.fieldName && removedFields.some(field => field.fieldName === dataView.reportCalender.endTime.fieldName)) {
            dataView.reportCalender.endTime = getNewCalendarObjectField();
        }
        let titleFields = [];
        dataView.reportCalender.titleFields.forEach((titleField) => {
            if (titleField.fieldName && removedFields.some(field => field.fieldName === titleField.fieldName)) {
                titleFields.push(getNewCalendarObjectField());
            }
            else {
                titleFields.push(titleField);
            }
        });
        dataView.reportCalender.titleFields = titleFields;
        return dataView.reportCalender;
    }
    else {
        return dataView.reportCalender;
    }

}

export const getNewCategoryList = (list, category) => {
    list.push(category);
    return list.sort((a, b)=> {

        // converting to uppercase to have case-insensitive comparison
        const name1 = a.categoryName.toUpperCase();
        const name2 = b.categoryName.toUpperCase();

        let comparison = 0;

        if (name1 > name2) {
            comparison = 1;
        } else if (name1 < name2) {
            comparison = -1;
        }
        return comparison;
    })
    
}

export const getControlTypeSubRecord = (dataType) => {
    let controlType = '';
    switch (dataType) {
        case "int":
        case "decimal":
        case "float":
        case "money":
        case "numeric":
        case "real":
        case "uniqueIdentifier":
            {
                controlType = 'numeric'
                break;
            }
        case "integer":
        case "smallint":
            {
                controlType = 'smallint'
                break;
            }
        case "char":
        case "nchar":
        case "nText":
        case "nvarchar":
        case "text":
        case "varchar":
        case "xml":
            {
                controlType = 'text'
                break;
            }
        case "date":
        case "datetime":
        case "timestamp":
            {
                controlType = 'datePicker'
                break;
            }
        case "bit":
            {
                controlType = 'checkBox'
                break;
            }
        default:
            {
                controlType = 'text';
            }
    }

    return controlType;
}

export const resetReportId = () => {
    return dispatch => {
        dispatch(resetReportIdSuccess());

    };
};

