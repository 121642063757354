import React from 'react';

import { Box, Button, Grid, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles'
import AddIcon from '@mui/icons-material/Add';

import { mainTheme } from 'common/theme';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getAlertHide } from 'admin/actions/alertActions';

import AlertMessage from 'admin/components/Common/AlertMessage';

const buttonTheme = createTheme({
    ...mainTheme,
    overrides: {
        MuiButton: {
            containedPrimary: {
                backgroundColor: mainTheme.palette.primary.light
            }
        }
    }
});

class MenuListHeader extends React.Component {
    
    handleClick=() => {
        this.props.history.push("/Admin/Menus/new");

    }
    render() {
        const { classes } = this.props;
        return (

            <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                    <Typography variant="h4">Menus</Typography>
                </Grid>
                <Grid item> 
                    <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        startIcon={<AddIcon />}
                        onClick={this.handleClick}
                    >ADD MENU</Button>

                </Grid>
            </Grid>

        );
    }

}
function mapStateToProps(state) {
    return {
        alert: state.alert,
    };
};

export default withRouter(connect(mapStateToProps)(MenuListHeader));
