import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { bannerTheme } from 'client/components/Banner/theme/bannerTheme'
import LayoutContentHeader from 'client/components/Common/LayoutContentHeader'
class bannerMore extends React.Component {



    createMarkup(text) { return { __html: text }; }
    renderMore = (bannerDetails) => {

        let bannerType = "Notification";
        if (bannerDetails[0].type === 'alert')
            bannerType = "Alert";
        if (bannerDetails[0].type === 'promotion')
            bannerType = "Promotion";
        const { classes } = this.props;
        return (

            <div>
                <div>
                    <LayoutContentHeader classes={classes} title={bannerDetails[0].title}/>

                </div>
                <article className={classes.article}>


                    <div className={classes.articleBody} >
                        <p dangerouslySetInnerHTML={this.createMarkup(bannerDetails[0].content)} />
                    </div>
                </article>
            </div>
        );
    }


    render() {
        let bannerId = this.props.match.params.id;
        const bannerDetails = this.props.banners.filter(banner => banner.id === parseInt(bannerId));

        if (bannerDetails.length !== 0) {
            return (
                this.renderMore(bannerDetails)
            );
        } else {
            return (
                <div></div>
            );
        }

    }
    
}

export default connect(state => state.banners)(withStyles(bannerTheme)(bannerMore));

