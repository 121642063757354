import { produce } from 'immer';
import { ActionTypes as types } from 'admin/actions/actionTypes';

import { newDataView, rowUpdateFilterValue, getFilterValues, resetCalendarObject, getNewCalendarObject, getNewCategoryList, getObjectListByRole } from 'admin/actions/dataViewActions';
import { updateFieldDisplayValue, setFieldDisplayData, updateHighLightValue, updateCalculatedFieldValue, getRemovedFields} from 'admin/actions/fieldDisplayActions';
import { updateRecordSubmissionValue, updateSetupValue, setRecordProcessingData } from 'admin/actions/recordSubmissionActions';
import { resetSubRecord } from 'admin/actions/dataViewSubRecordsActions';
const initialState = {
    activeTabIndex: 0,
    dataViews: [],
    dataViewsByRole: { roles: '', dataViews: [] },
    objectListByRole:{ roles: '', objectList: { dataViewList: [], formList: [] } },
    dataView: newDataView(),
    isUpdate: false,
    roles: [],
    categories: { requestStatus: '', list: [] },
    dataSources: [],
    querySettings: { queryData: [], queryStatus: false, message: '', hasError: false,showResult:false },
    queryFields: [],
    storedProcedureParameters: [],
    adminFilters: [],
    alert: "",
    requestStatus: { getDataView: '', getDataViews: '',getObjectListByRole:'' },
    successExecuteQuery: false,
    errors: {
        calendar: {},
    },
    isLoading:true,
};

const dataViews = produce((draft, action) => {

  //  draft.isUpdate = false;

    switch (action.type) {
        case types.VGO_NEW_DATAVIEW:

            draft.dataView = newDataView();
            draft.errors = {};
            draft.requestStatus.getDataView = '';
            draft.requestStatus.getDataViews = '';
            draft.requestStatus.getObjectListByRole = '';
            draft.activeTabIndex = 0;
            draft.querySettings = produce({ queryData: [], queryStatus: false, message: '', hasError: false, showResult: false }, draftQueryData => { });
            draft.successExecuteQuery = false;
            draft.isUpdate = false;
            break;
        case types.VGO_GET_DATAVIEWS_REQUEST:
            draft.isLoading = true;
            draft.requestStatus.getDataViews = types.VGO_GET_DATAVIEWS_REQUEST;
            break;
        case types.VGO_GET_DATAVIEWLISTANDFORMLISTBYROLELIST_REQUEST:
            draft.isLoading = true;
            draft.getObjectListByRole = types.VGO_GET_DATAVIEWLISTANDFORMLISTBYROLELIST_REQUEST;
            break;
        case types.VGO_GET_DATAVIEWS_SUCCESS:
            //draft.dataViews = [];
            draft.dataViews= produce(action.dataViews, draftDataViews => { });
            draft.requestStatus.getDataViews = types.VGO_GET_DATAVIEWS_SUCCESS;
            draft.isUpdate = false;
            draft.isLoading = false;
            draft.requestStatus.getObjectListByRole = '';
            break;
        case types.VGO_GET_DATAVIEWLISTBYROLELIST_SUCCESS:
            draft.dataViewsByRole = produce(action.dataViewRoleList, draftDataViews => { });

            break;
        case types.VGO_GET_DATAVIEWLISTANDFORMLISTBYROLELIST_SUCCESS:
            draft.objectListByRole = produce(action.objectListRoleList, draftObjectList => { });
            draft.isLoading = false;
            draft.requestStatus.getObjectListByRole = types.VGO_GET_DATAVIEWLISTANDFORMLISTBYROLELIST_SUCCESS;
            break;
        case types.VGO_HIDE_RESULT_SUCCESS:
            draft.querySettings = produce({ queryData: [], queryStatus: false, message: '', hasError: false, showResult: action.isVisible }, draftQueryData => { });
            break;

        case types.VGO_DELETE_DATAVIEW_SUCCESS:
            {
                let dataViews = draft.dataViews;
                if (draft.dataViews.length > 0) {
                    dataViews = draft.dataViews.filter((d) => d.reportId !== action.reportId);
                }
                draft.dataViews = dataViews;
            }
            break;

        case types.VGO_CHANGE_DATAVIEW_ERROR_MESSAGE:
            draft.dataView = action.dataView;
            break;
        case types.VGO_GET_DATAVIEW_REQUEST:
            draft.requestStatus.getDataView = types.VGO_GET_DATAVIEW_REQUEST;
            draft.dataSources = [];
            draft.errors = {};
            break;
        case types.VGO_GET_DATAVIEW_SUCCESS:
            draft.isUpdate = true;
            draft.dataView = produce(action.dataView, draftdataView => { draftdataView.executeQuery = true });
            if (!action.dataView.isFromUpdate) {
                draft.objectListByRole = produce(draft.objectListByRole, draftObjectList => { return getObjectListByRole(action.dataView) });
            }
            draft.querySettings = produce({ queryData: [], queryStatus: false, message: '', hasError: false, showResult: false }, draftQueryData => { });
            draft.requestStatus.getObjectListByRole = '';
            draft.errors = {};
            draft.requestStatus.getDataView = types.VGO_GET_DATAVIEW_SUCCESS;
            break;
        case types.VGO_GET_ROLES_SUCCESS:
            draft.roles = produce(action.roles, draftRoles => { });
            break;
        case types.VGO_GET_CATEGORIES_REQUEST:
            draft.categories = { requestStatus: types.VGO_GET_CATEGORIES_REQUEST, list: [] }
            break;
        case types.VGO_GET_CATEGORIES_SUCCESS:
            draft.categories = { requestStatus: types.VGO_GET_CATEGORIES_SUCCESS, list: produce(action.categories, draftCategories => { }) }
            break;
        case types.VGO_UPDATE_CATEGORYLIST:
            draft.categories = { requestStatus: types.VGO_GET_CATEGORIES_SUCCESS, list: produce(draft.categories.list, draftCategories => { getNewCategoryList(draftCategories,action.category) }) }
            break;
        case types.VGO_CHANGE_DATAVIEW:
           
            action.change(draft.dataView);
            break;
        case types.VGO_CHANGE_RECORD_SUBMISSION:

            action.change(draft.dataView.recordProcessing);
            break;

            
        
        case types.VGO_CHANGE_DATAVIEW_DATASOURCETYPE:
            action.change(draft.dataView);
            draft.dataSources = [];
            draft.querySettings = produce({ queryData: [], queryStatus: false, message: '', hasError: false, showResult: false }, draftQueryData => { });
            draft.queryFields = [];
            draft.dataView.reportFilter = [];
            draft.storedProcedureParameters = [];
            draft.dataView.fieldDisplay = [];
            draft.dataView.query = "";
            draft.dataView.dataSource = { name: '', value: '' };
            draft.dataView.reportDataSource = '';
            draft.dataView.reportCalender = getNewCalendarObject();
            break;
        case types.VGO_CHANGE_DATAVIEW_DATABASE:
            action.change(draft.dataView);
            draft.dataSources = [];
            draft.querySettings = produce({ queryData: [], queryStatus: false, message: '', hasError: false, showResult: false }, draftQueryData => { });
            draft.queryFields = [];
            draft.dataView.reportFilter = [];
            draft.storedProcedureParameters = [];
            draft.dataView.fieldDisplay = [];
            draft.dataView.dataSourceType = 'sqlQuery';
            draft.dataView.query = "";
            draft.dataView.reportDataSource = '';
            draft.dataView.dataSource = { name: '', value: '' };
            draft.dataView.reportCalender = getNewCalendarObject();
            break;
        case types.VGO_CHANGE_DATAVIEW_TAB_INDEX:
            draft.activeTabIndex = action.tabIndex;
            break;
        case types.VGO_GET_DATAVIEW_DATASOURCE_SUCCESS:
            draft.dataSources = produce(action.dataSources, draftDataSources => { });
            break;
        case types.VGO_GET_DATAVIEW_DATASOURCE_FIELD_SUCCESS:
            draft.querySettings = produce({ queryData: action.fieldSettings.queryData, queryStatus: true, message: '', hasError: false, showResult: true }, draftQueryData => { });
            draft.queryFields = produce(action.fieldSettings.fields, draftQueryFields => { });
            draft.dataView.fieldDisplay = setFieldDisplayData(action.fieldSettings.fields, action.oldFields)
            draft.dataView.reportCalender = resetCalendarObject(getRemovedFields(action.fieldSettings.fields, action.oldFields), draft.dataView)
            //draft.dataView.reportFilter = [];
            //draft.storedProcedureParameters = [];
            draft.dataView.reportFilter = getFilterValues(action.fieldSettings.inputParameters, draft.dataView.dataSourceType, action.fieldSettings.fields, action.oldFilters);
            draft.storedProcedureParameters = produce(action.fieldSettings.inputParameters, draftQueryFields => { });
            draft.dataView.recordProcessing = setRecordProcessingData(action.fieldSettings.fields, action.recordProcessing)
            draft.activeTabIndex = action.newTabIndex;
            draft.dataView.reportSubReport = resetSubRecord(action.fieldSettings.fields, action.subRecord);
            draft.successExecuteQuery = true;
            draft.dataView.executeQuery = true;
            break;
        case types.VGO_GET_DATAVIEW_QUERY_FIELD_SUCCESS:
            draft.querySettings =
                produce({ queryData: action.queryData.queryData, queryStatus: true, message: '', hasError: false, showResult: action.showResult},
                    draftQueryData => {});
            draft.queryFields = produce(action.queryData.fields, draftQueryFields => {});
            draft.dataView.reportFilter = getFilterValues(action.queryData.inputParameters, draft.dataView.dataSourceType, action.queryData.fields, action.oldFilters);
            draft.storedProcedureParameters = produce(action.queryData.inputParameters, draftQueryFields => { });
            draft.dataView.fieldDisplay = setFieldDisplayData(action.queryData.fields, action.oldFields);
            draft.dataView.reportCalender = resetCalendarObject(getRemovedFields(action.queryData.fields, action.oldFields), draft.dataView)
            draft.dataView.recordProcessing = setRecordProcessingData(action.queryData.fields, action.recordProcessing)
                        draft.dataView.reportSubReport = resetSubRecord(action.queryData.fields, action.subRecord);
            draft.activeTabIndex = action.newTabIndex;
            draft.successExecuteQuery = true;
            break;

        case types.VGO_EXECUTE_STORED_PROCEDURE_WITH_PARAMETER_DATAVIEW_SUCCESS:
            draft.queryFields = produce(action.queryData.fields, draftQueryFields => { });
            draft.dataView.fieldDisplay = setFieldDisplayData(action.queryData.fields);
            draft.activeTabIndex = action.newTabIndex;

            break;
        case types.VGO_GET_CRYSTALREPORT_FILTER_SCUCCESS:
            draft.dataView.reportFilter = produce(action.filterList, draftfilterFields => { });
            draft.dataView.reportDataSource = action.dataSourceName;
            break;
        case types.VGO_GET_DATAVIEW_QUERY_FIELD_ERROR:
            draft.querySettings = produce({ queryData: [], queryStatus: true, message: action.message, hasError: true, showResult: true }, draftQueryData => { });
            draft.queryFields = produce([], draftQueryFields => { });
            //draft.dataView.fieldDisplay = [];
            draft.successExecuteQuery = false;
            break;
        case types.VGO_GET_DATAVIEW_VIEW_FIELD_ERROR:
            draft.querySettings = produce({ queryData: [], queryStatus: true, message: action.message, hasError: true, showResult: true }, draftQueryData => { });
            draft.queryFields = produce([], draftQueryFields => { });
            draft.dataView.fieldDisplay = [];
            draft.successExecuteQuery = false;
            break;
        case types.VGO_UPDATE_DATAVIEW_ROW_FILTER_SUCCESS:
            draft.dataView = rowUpdateFilterValue(action);
            break;
        case types.VGO_UPDATE_DATAVIEW_SUBRECORD:
            draft.dataView.reportSubReport = [action.subRecord]; 
            break;
        case types.VGO_GET_FILTERS_SUCCESS:
            draft.adminFilters = action.filters;
            break; 

        case types.VGO_GET_FIELD_DISPLAY_SUCCESS:
            draft.dataView.fieldDisplays.fieldDisplay = action.data;
            break;
        case types.VGO_UPDATE_FIELD_FIELD_DISPLAY_SUCCESS:
            draft.dataView.fieldDisplay = updateFieldDisplayValue(action);
            
            break;
        case types.VGO_UPDATE_FIELD_HighLight_SUCCESS:
           draft.dataView.fieldDisplay = updateHighLightValue(action);
           draft.alert = null;
            break;    
        case types.VGO_GET_FORMULA_FIELD_DISPLAY_SUCCESS:
            draft.formulaFields = action.data;
            break;
        case types.VGO_GET_VALIDATE_FORMULA_FIELD_DISPLAY_SUCCESS:
            draft.dataType =  action.data.dataType;
            draft.alert = action.alert;
            break;
        case types.VGO_GET_VALIDATE_FORMULA_FIELD_DISPLAY_ERROR:
            draft.alert = action.alert;
            break;
        case types.VGO_GET_VALIDATE_FORMULA_DISPLAY_ERROR:
            draft.alert = action.alert;
            draft.dataType = action.data ? action.data.dataType : "";
            break;
        case types.VGO_UPDATE_CALCULATED_FIELD_DISPLAY_SUCCESS:
            draft.dataView.fieldDisplay = updateCalculatedFieldValue(action);
            draft.alert = null;
            break;
        case types.VGO_UPDATE_RECORD_PROCESSING_REQUEST:
            draft.dataView.recordProcessing = updateRecordSubmissionValue(action);
            break;
        case types.VGO_UPDATE_RECORD_SETUP_REQUEST:
            draft.dataView= updateSetupValue(action);
            draft.alert = null;
            break;
        
        case types.VGO_CHANGE_DATAVIEW_QUERYRESULT_PANEL:
            draft.querySettings.showResult = action.show;
            break;
        case types.VGO_UPDATE_DATAVIEW_CALENDER_SUCCESS:
            draft.dataView.reportCalender = action.calenderData;
            break;
        case types.VGO_UPDATE_DATAVIEW_LINK_SUCCESS:
            draft.dataView.reportLink = action.link;
            break;
        case types.VGO_VALIDATE_FIELD_ONBLUR:
            
                draft.errors = produce(draft.errors, draftErrors => { return { ...draftErrors, ...action.errors } });
            break;
        case types.VGO_VALIDATE_CALENDAR_FIELD_ONBLUR:
            draft.errors.calendar = produce(draft.errors.calendar, draftErrors => { return { ...draftErrors, ...action.errors } });
            break;
        case types.VGO_RESET_REPORTID_SUCCESS:
            draft.dataView.reportId = 0;
            break;

        default:
            break;

    }

}, initialState);

export default dataViews;