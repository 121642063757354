import React from 'react';
import { Pie } from 'react-chartjs-2';
import { loadDataSet, chartOptions, chartPlugins } from './ChartUtility';
import * as Enums from 'client/components/Common/Enum';
import { Box } from '@mui/material';
import ChartPopup from './ChartPopup';

class pie extends React.Component {

    constructor(props) {
        super(props);

        this.data = (canvas) => {
            return loadDataSet(canvas, this.props);
        };
    }

    render() {
        const chart = <Pie
            data={this.data}
            width={100}
            height={this.props.chartHeight}
            ref="chart"
            options={chartOptions(this, Enums.ChartType.Pie, this.props.deviceType)}
            plugins={[chartPlugins(this.props, this.props.deviceType)]}
        />
        return (
            <React.Fragment>
                <Box className={this.props.popupClassName}>
                    <ChartPopup chart={chart} />
                </Box>
                {chart}
            </React.Fragment>
        );
    }
}

export default (pie);
