import React from 'react';
import { Typography } from '@mui/material';

export default function GridDropDownColumnTemplate(props) {
    const {
        data,
        column,
        dropDownList
    } = props;
   
    let dataValue = "";
    let ddlist = dropDownList[data.index];
    if (ddlist) {
        let index = ddlist.controls.findIndex(value => value.controlId === column.controlId);
        if (index !== -1) {
            dataValue = ddlist.controls[index].label;
        }
    }
    let textAlignment = { textAlign: column.textAlign ? column.textAlign : "left" }
    return <Typography variant="body2" style={textAlignment}>{dataValue}</Typography>;
}