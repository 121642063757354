import { customThemeStyles } from 'common/theme.js';
export const MenuTheme = () => ({
    ...customThemeStyles,
    container: {
        width: '100%',
    },
    list: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
    },
    divider: {
        borderColor: '#50575F !important',
        borderWidth: '2px !important',
    }
});