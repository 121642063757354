import { customThemeStyles } from 'common/theme.js';
export const filterTheme = (theme) => ({
    ...customThemeStyles,
   
    card: {
        height: '100%',
        marginLeft: theme.spacing(-3),
        marginRight: theme.spacing(-3),
        marginTop: theme.spacing(-3),
        padding:0
    },
    td: {
        textAlign: 'right',
    },
    tableContainer: {
        maxHeight: '405px',
       marginTop:'41px',
       maxWidth: '1576px'
    },
    dragIcon: {
        paddingTop: '2px',
        paddingBottom: '2px',
        textAlign: 'center',
        width: '53px!important',
        paddingLeft: '0px',
        paddingRight: '0px',

    },
    filterType: {
        width: '193px!important',
       paddingLeft: '16px',
        paddingRight: '7px',
        paddingTop: '2px',
        paddingBottom: '7px',
        textAlign: 'left'
    },
    fieldName: {
        width: '227px',
       paddingLeft: '16px',
        paddingRight: '0px',
        textAlign: 'left',
        paddingTop: '2px',
       /* paddingBottom: '7px!important',*/
    },
    displayLabel: {
        width: '176px',
      paddingLeft: '0px',
        paddingRight: '0px',
        textAlign: 'left',
        paddingTop: '2px',
        paddingBottom: '4px',
    },
    controlType: {
        width: '140px',
        paddingRight: '0px',
        textAlign: 'left',
        paddingTop: '0px',
        paddingLeft: '16px',
        paddingBottom: '7px',
    },
    required: {
        width: '60px!important',
        paddingLeft: '0px!important',
        paddingRight: '10px',
        textAlign: 'left!important',
        paddingTop: '2px',
        paddingBottom: '3px',
    },
    source: {
        width: '133px!important',
        paddingLeft: '16px',
        paddingRight: '37px',
        textAlign: 'left',
        paddingTop: '2px',
        paddingBottom: '7px',

    },

    operator: {
        width: '150px',
        paddingLeft: '16px',
        paddingRight: '0px',
        textAlign: 'left',
        paddingTop: '2px',
        paddingBottom: '7px',
    },
    defaultValueType: {
        width: '132px',
        paddingLeft: '0px',
        paddingRight: '0px',
        textAlign: 'left',
        paddingTop: '2px',
        paddingBottom: '4px',
    },
    visible: {
        width: '73px',
        paddingLeft: '6px',
        paddingRight: '0px',
        textAlign: 'left',
        paddingTop: '2px',
        paddingBottom: '3px',
    },
    lineBreak: {
        width: '111px',
        paddingLeft: '6px',
        paddingRight: '0px',
        textAlign: 'left',
        paddingTop: '2px',
        paddingBottom: '3px',
    },
    delete: {
        maxWidth: '76px',
        paddingLeft: '3px',
        paddingRight: '0px',
        paddingTop: '2px',
        paddingBottom: '2px',
    },
    tableRow: {

        "&$selected, &$selected:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.08)"
        },
        backgroundColor: '#fff!important',
        height: '20px',
        
    },
    selected: {},
    dragIconControl: {
        marginTop: '5px'
       
    },
    filterTypeControl: {
        maxWidth: '170px',
        paddingRight:'0px!important'

    },
    fieldNameControl: {
        maxWidth: '195px',
        paddingRight: '0px!important'
    }
    ,
    displayLabelControl: {
        maxWidth: '176px',
        paddingRight: '0px!important'
    }
    ,
    controlTypeControl: {
        maxWidth: '120px',
        paddingRight: '0px!important'
    },
    requiredControl: {
       marginLeft:'6px'
    },
    sourceControl: {
        width: '90px!important',
        paddingRight: '0px!important'

    }

    ,
    operatorControl: {
        maxWidth: '164px!important',
        paddingRight: '0px!important'

    }
    ,
    defaultValueControl: {
        maxWidth: '132px!important',
        paddingRight: '0px!important'

    },
    table: {

        tableLayout: 'fixed'
    },
    editIconGrid: {
        paddingLeft: '40px!important'
    },
    tableHeaderCell: {
        backgroundColor: '#fff!important',
       /* paddingTop: '0px!important',*/
        paddingBottom: '9px!important',
        color: '#000000'
      
    },
    editIcon: {
        color: 'rgba(0, 0, 0, 0.54)',
        cursor: 'pointer'
        
    },
    dialog: {
        minWidth: '750px!important',
        maxWidth: '750px!important',
        minHeight: '489px!important',
        maxHeight: '489px!important',
    },
    wrapper: {
        width: "358px",
        height: "42px!important",
        paddingTop: '0px!important'

    },
    highlightWrapper: {
        width: "188px",
        paddingTop: '0px'

    },
    root: {
        paddingBottom: "2px!important",
        paddingTop: "2px!important"
    },
    padding: {
        paddingTop: "4px"
    },
    staticHeader: {
        paddingTop:'20px!important'
    },
    buttonGrid: {
        textAlign: 'right!important',
        paddingTop: '30px!important',
      /*  marginBottom: '24px!important'*/
    },
    alertGrid: {
        
      
          marginBottom: '2px!important'
    },
    dialogContent: {
        paddingLeft: '24px!important',
        paddingRight: '16px!important',
        paddingBottom: '0px!important',
        paddingTop: '9px!important',
    },
    filterDetailsName: {
        width: '118px!important',

    },
    valueDisplay: {
        width: '171px!important ',
        padding:0
    },
    valueDisplayLabel: {
        width: '171px!important ',
       
    },
    buttonClose: {
        paddingRight:'16px!important'
    }


});
