
const requestContent = 'REQUEST_CONTENT';
const receiveContent = 'RECEIVE_CONTENT';

const initialState = { contentList: [], isLoading: false };

export const actionCreators = {

    requestPublicContentList: () => async (dispatch, getState) => {

        dispatch({ type: requestContent });
        const url = 'api/News/GetPublicNewsLayout';
        const response = await fetch(url);
        if (response.status === 200) {
            const contentList = await response.json();
            dispatch({ type: receiveContent, contentList: contentList.contentCategoryList });
        }
        else {

            dispatch({
                type: "SHOW_ERROR",
                message: "News list loading failed"
            });
            dispatch({ type: receiveContent, contentList:[]});

        }

    },
    requestContentList: (roleId) => async (dispatch, getState) => {

        dispatch({ type: requestContent });
        const url = `api/News/GetNewsLayout/${roleId}`;
        const response = await fetch(url);
        if (response.status === 200) {
            const contentList = await response.json();
            dispatch({ type: receiveContent, contentList: contentList.contentCategoryList });
        }
        else {

            dispatch({
                type: "SHOW_ERROR",
                message: "News list loading failed"
            });
            dispatch({ type: receiveContent, contentList: [] });

        }

    }
};



export const reducer = (state, action) => {
    state = state || initialState;
    if (action.type === requestContent) {
        return {
            ...state,
            isLoading: true
        };
    }
    if (action.type === receiveContent) {
        return {
            ...state,
            contentList: action.contentList,
            isLoading: false
        };

    }

    return state;
}