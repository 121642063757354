import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material'

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function ActionBackdrop() {
    const classes = useStyles();
   

    return (
        <div>
           
            <Backdrop className={classes.backdrop} open={true} >
                <CircularProgress color="inherit" />
                <Box
                    sx={{
                        top: 0,
                        left: 130,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                 <Typography variant="caption" component="div" color="inherit"> Loading </Typography>
                </Box>
            </Backdrop>
        </div>
    );
}