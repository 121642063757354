import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { alertHandlerAction } from 'client/store/AlertHandler';
import AlertMessage from 'client/components/Common/AlertMessage';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';
class alertHandlerMessage extends React.Component {
       onMessageClose = () => {
       
        this.props.actions.alertHandler.hideAlert(this.props.alert.severity);
    }

    onMessageCloseWithAction = () => {
        this.props.actions.alertHandler.hideAlertWithAction(this.props.alertAction.severity);
    }
    onMessageCloseWithActionExecute = () => {
        this.props.actions.alertHandler.hideAlertWithAction(this.props.alertAction.severity);
        this.props.alertAction.action();
    }
    onMessageCloseWithActionArrayExecute = () => {
        this.props.actions.alertHandler.hideAlertWithAction(this.props.alertAction.severity);
        const primaryAction = this.props.alertAction.action[0];
        primaryAction();
    }
    onMessageCloseWithActionArray = () => {
        this.props.actions.alertHandler.hideAlertWithAction(this.props.alertAction.severity);
        const secondaryAction = this.props.alertAction.action[1];
        secondaryAction();
    }
    render() {
       
        return (
            <Box>
                {this.props.alert ? <Grid container justifyContent="center"><Grid item xs={12}>
                    <AlertMessage isAction={false} open={this.props.alert.open} severity={this.props.alert.severity ? this.props.alert.severity : "success"}
                        title={this.props.alert.title} message={this.props.alert.message} icon={this.props.alert.icon} close={this.onMessageClose} timerId={this.props.alert.timerId} /></Grid></Grid> : ""}

                {this.props.alertAction && (this.props.alertAction.action && !this.props.alertAction.action.length ) ? <Grid container justifyContent="center">
                    <AlertMessage isAction={true} open={this.props.alertAction.open} severity={this.props.alertAction.severity ? this.props.alertAction.severity : "success"} 
                        title={this.props.alertAction.title} message={this.props.alertAction.message} icon={this.props.alertAction.icon} action={this.onMessageCloseWithActionExecute} close={this.onMessageCloseWithAction} /></Grid> : ""}

                {this.props.alertAction && (this.props.alertAction.action &&this.props.alertAction.action.length>0) ? <Grid container justifyContent="center">
                    <AlertMessage isAction={true} open={this.props.alertAction.open} severity={this.props.alertAction.severity ? this.props.alertAction.severity : "success"} 
                        title={this.props.alertAction.title} message={this.props.alertAction.message} icon={this.props.alertAction.icon} action={this.onMessageCloseWithActionArrayExecute} close={this.onMessageCloseWithActionArray} /></Grid> : ""}
            </Box>
            );
    }
}
function mapDispatchToProps(dispatch) {
    return {
        actions: {
           alertHandler: bindActionCreators(alertHandlerAction, dispatch)
        }
    };
}
export default connect(state => state.alertHandler, mapDispatchToProps)(alertHandlerMessage);