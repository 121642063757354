import React from 'react';
import { Typography, Link, Stack } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
class links extends React.Component {


    renderLinks = (widget) => {

        return (
            <div>
                <header className={this.props.classes.widgetHead}>
                    <Stack direction="row" alignItems="center" gap={1}>
                        <LinkIcon className={this.props.classes.widgetHeaderIconColor } />
                        <Typography className={this.props.classes.widgetHeadContent}>Links and Resources</Typography>
                    </Stack>
                </header>
                <div className={this.props.classes.widgetBody}><ul className={this.props.classes.listLinks } >
        { widget.details.map(link => {

            return (
                <li className={this.props.classes.listItemLinks}>
                    {(this.props.isSiteTheme) ? <Typography variant="body2" style={{ color: this.props.themeColor }}>{link.urlCaption}</Typography> :
                        <Link style={{ color: this.props.themeColor }} target="_blank" rel="noopener" href={link.url} underline="none" variant="body2">
                            {link.urlCaption}
                        </Link>}
                </li>
            );
    }) } </ul></div>
            </div>
    );
}
render() {


    return (
        <li key={this.props.widget.type} className={this.props.classes.widget}>
            {this.renderLinks(this.props.widget)}
        </li>
);
}
}
export default links;