import * as React from 'react';
import * as ReactDom from 'react-dom';
import { withStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import { chartTheme } from 'client/components/Charts/theme/chartTheme';

const defaultFormatter = (info) => {
  return `占比: ${info.percent}`;
};

const getPosition = ({ width, height, x, y, clientWidth, clientHeight }) => {
  if (!clientWidth || !clientHeight) return { x, y };
  let newX = x;
  let newY = y;
  if (x + clientWidth > width) {
    newX = x - 20 - clientWidth;
  }
  if (y + clientHeight > height) {
    newY = y - 20 - clientHeight;
  }
  return { x: newX, y: newY };
};


class ToolTip extends React.Component {


  componentDidUpdate() {
    const $tooltip = ReactDom.findDOMNode(this);
    if (!$tooltip) return;
    this.clientWidth = $tooltip.clientWidth;
    this.clientHeight = $tooltip.clientHeight;
  }

    render() {
        const { classes } = this.props;
        const { x, y, info, title, tooltip, width, height, tooltipStyle, tooltipColor } = this.props || {};
        if (!info) return null;

        const { formatter = defaultFormatter, show = true } = tooltip || {};
        const tooltipText = formatter(info);
        const backgroundColor = (tooltipText.props.children === "null" || tooltipText.props.children === "undefined" || tooltipText.props.children === "") ? '' : classes.funnelTooltipBackground;
        const postion = getPosition({ width, height, x, y, clientWidth: this.clientWidth, clientHeight: this.clientHeight });

        return (
            <Box className={classes.funnelTooltipPosition} style={{ ...Object.assign({}, {}, tooltipStyle), top: `${postion.y - 20}px`, left: `${postion.x + 20}px` }} >
                {(tooltipText != 'undefined' || tooltipText != '') ?
                    <Box className={classes.funnelTooltip + ' ' + backgroundColor} >
                        <Typography variant="subtitle2" color={tooltipColor}>{(tooltipText.props.children === "null" || tooltipText.props.children === "undefined" || tooltipText.props.children === "") ? '' : tooltipText}</Typography>
                    </Box> : null
                }
            </Box>
        )

    }
}
export default withStyles(chartTheme)(ToolTip);


