import React from "react";
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import Typography from '@mui/material/Typography';
import { saveAs } from 'file-saver';
import axios from 'axios';
import isEqual from 'lodash/isEqual';
import {
    detach,
    createElement,
    EventHandler,
} from '@syncfusion/ej2-base';
import 'client/components/Form/theme/fileUpload.css';
class fileUpload extends React.Component {

    constructor(props) {
        super(props);
        this.state = { value: null, showMessage: false };
        this.prevFileList = {};
    }

    componentDidUpdate(prevProps) {

        if (prevProps.formId !== this.props.formId) {
            let fileList = {};
            fileList[this.props.settings.controlId] = this.props.currentfileList;
            this.prevFileList = fileList;
        }
        
       
    }
    onSelect(args) {

        let totalSize = this.props.totalFormFileSize;
        let index = 0;
        let existingFiles = this.uploadObj.getFilesData();
        const currentFileList = existingFiles.filter(file => file.key >= 0).map((file) => {
            return file.key
        });
        let currentCount =  (currentFileList.length === 0 ? 0 : Math.max(...currentFileList))+1;

        
        let removedFiles = [];
        for (let file of args.filesData) {
            if (file.statusCode !== '0') {
                
                if ((totalSize + file.size) > this.props.allowedFormFileSize) {
                    removedFiles.push(file);
                    file.invalidKey = true;
                    file.statusCode = "0"
                    file.status ="File size is too large"
                    // break;
                }
                else
                {
                    totalSize=totalSize + file.size
                    file.key = currentCount + index;
                    file.invalidKey = false;
                    file.status = '';
                    index++;
                }
                
               // file.statusCode = '2';
                
                
            }
            else
            {
                file.invalidKey = true;
            }
            
        }

        //if (removedFiles.length !== 0) {
        //    let message = `Following files `;
        //    for (let file of removedFiles) {
        //        message = `${message} ${file.name},`
        //    }
        //    message = message.substring(0,message.length-1)
        //    message += ` not added since allowed form file upload size of ${this.uploadObj.bytesToSize(this.props.allowedFormFileSize)} got exceeded.`
        //    this.props.showMessageBox(message,false)
        //}
        const validFileList = args.filesData.filter(file => file.statusCode !== '0');
        if (validFileList.length > 0) {
            this.props.setFormFiles(this.props.settings.controlId, validFileList, true, true, this.props.settings.isMandatory, this.props.settings.fieldName);
        }
        //if (removedFiles.length !== 0) {
        //    existingFiles = existingFiles.concat(args.filesData);
        //    args.modifiedFilesData = existingFiles;
        //    args.isModified = true;
        //}
    }
    onRemove(args) {
        args.currentRequest = null;
        args.postRawFile = false;
        args.cancel = true;
        const validFileList = args.filesData.filter(file => file.statusCode !== '0' || !file.invalidKey);
        if (validFileList.length > 0) {
            this.props.setFormFiles(this.props.settings.controlId, validFileList, false, true, this.props.settings.isMandatory, this.props.settings.fieldName);
        }
        //const viewElementId = args.event.target.getAttribute("data-viewElementId");
        
            let viewElement = args.event.target.parentElement.getElementsByClassName("e-file-pause-btn");
            if (viewElement.length >0) {
                EventHandler.remove(
                    viewElement[0],
                    'click',
                    this.viewFile
                );
            }
        detach(args.event.target.parentElement)
        detach(args.event.target)

    }
    onFileListRendering(args) {
        
        args.element.lastElementChild.setAttribute('class', 'e-icons e-file-delete-btn')
      
        if (args.fileInfo.statusCode !== '0'  ) {
            //detach(args.element.lastElementChild)
            let fileDownloadElementId = "";
            if (args.fileInfo.key === undefined) { // preloaded file
                const preLoadFile = this.props.currentfileList.find(file => file.ControlId === this.props.settings.controlId
                    && file.FileName === args.fileInfo.name
                   
                    && file.FileSize === args.fileInfo.size.toString())
                if (preLoadFile) {
                    fileDownloadElementId = `${this.props.settings.controlId}_pre_${preLoadFile.Id}`
                }
            }
            else
            {
                fileDownloadElementId = `${this.props.settings.controlId}_post_${args.fileInfo.key}`
            }
            let fileDownloadElement = createElement('span', {

                className: 'e-icons e-file-pause-btn',
                id: fileDownloadElementId,
            })
            fileDownloadElement.setAttribute('data-Filename', args.fileInfo.name)
            let successStatusMessageElement = args.element.getElementsByClassName("e-upload-success");
            if (successStatusMessageElement.length > 0)
            {
                successStatusMessageElement[0].style.visibility = "hidden"
            }
            if (!this.props.settings.isEditable) {
                args.element.lastElementChild.style.opacity = .35;
            }
           // args.element.lastElementChild.setAttribute('data-viewElementId', fileDownloadElementId)
            
            EventHandler.add(fileDownloadElement, 'click', this.viewFile, this);
            args.element.appendChild(fileDownloadElement);
        }


        
    }
    viewFile=(event)=> {
        
        const fileIdSettings = event.target.id.split('_')
        if (fileIdSettings.length === 3) {
            if (fileIdSettings[1] === "pre") {
                const fileName = event.target.getAttribute('data-Filename');
                this.getFile(fileIdSettings[2], fileName)
            }
            else
            {
                const file = this.uploadObj.getFilesData().find(file => file.key === parseInt(fileIdSettings[2]))
                let reader = new FileReader();
                reader.onload = async function (e) {
                    await saveAs(e.target.result, file.name);
                };
                reader.onerror = function (e) {
                    console.log('Error : ' + e.type);
                };
                reader.readAsDataURL(file.rawFile);
            }
            
        }
        
    }
    getCurrentFiles(currentfileList) {
        return currentfileList.map((file,index) => {
            return {
                name: file.FileName.substring(0, file.FileName.lastIndexOf('.')),
                type: file.FileName.substring(file.FileName.lastIndexOf('.')),
                size: parseInt(file.FileSize),
                fileId: file.Id,
                //statusCode: 1,
            }
        });
    }
    getFile = async (fileId, fileName) => {
        
        const message="File downloading failed"
        let url = `api/FormData/FileDownload/${this.props.formId}/${fileId}`;
                if (this.props.isPublic) {
                    url = `api/FormData/PublicFileDownload/${this.props.formId}/${fileId}`;
        }
        axios.get(url, {
            responseType: 'blob',
        })
            .then(response =>  {
                
                if (response.status === 200) {

                    saveAs(response.data, fileName);
                }
                else {
                    this.props.showMessageBox(message, false)
                }
            }).catch(() => {
                this.props.showMessageBox(message, false)

            });

       
            
        
      

    }
    render() {
        if ((
            (this.props.clearFileControl)
            || !isEqual(this.props.currentfileList, this.prevFileList[this.props.settings.controlId])) && this.uploadObj)
        {
            this.uploadObj.clearAll();

        }
        this.prevFileList[this.props.settings.controlId] = this.props.currentfileList;
        let requiredIndicator = " ";
        let errorState = {};
        const caption = !this.props.settings.caption ? ' ' : this.props.settings.caption;
        
        let helperText = " ";
        if (this.props.settings.isMandatory) {
            requiredIndicator = " *";
            const validation = this.props.validationSchema.filter(field => field.fieldName === this.props.settings.fieldName && field.errorState)
            if (validation.length > 0) {
                errorState.error = true;
                helperText = `${this.props.settings.caption} is required`;
            }
        }
        const labelStyle = {
            color: (errorState.error) ? '#f44336' : !this.props.settings.captionColor ? "rgba(0, 0, 0, 0.54)" : this.props.settings.captionColor,
            lineHeight: '1.5!important',
            zIndex: 1,
           
        }

       // const files = this.props.clearFileControl ?[] : this.getCurrentFiles(this.props.currentfileList)
        const files = this.getCurrentFiles(this.props.currentfileList)
        return (
            <div id='container'>
                <Typography sx={{ paddingBottom: '10px', ...labelStyle }} className={this.props.fileLabelClassName}  > {caption}{requiredIndicator}</Typography>
                <UploaderComponent
                    autoUpload={false}
                    allowedExtensions={this.props.allowedExtensions}
                    enabled={this.props.settings.isEditable}
                    type='file'
                    files={files}
                    maxFileSize={1000000000} // added to bypass inbuild validation and trgigger custom
                    id={`vgoFileUpload_${this.props.settings.fieldName}`}
                    ref={upload => { this.uploadObj = upload; }}
                    selected={this.onSelect = this.onSelect.bind(this)}
                    removing={this.onremove = this.onRemove.bind(this)}
                    fileListRendering={this.onFileListRendering = this.onFileListRendering.bind(this)}
                    asyncSettings={{
                        removeUrl: 'https://ej2.syncfusion.com/services/api/uploadbox/Remove',
                        saveUrl: 'https://ej2.syncfusion.com/services/api/uploadbox/Save'
                    }}
                    />
                    
                <Typography sx={{ marginTop: '3px', color: '#f44336', lineHeight: '1.5!important', }} className={this.props.fileLabelClassName}  > {helperText}</Typography>
            </div>
        );
    }

}
export default fileUpload;


