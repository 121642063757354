import { theme, customThemeStyles } from 'common/theme';
export const formTheme = () => ({
    ...customThemeStyles,
    instruction: {
        wordBreak: "break-word",
        color: "black"
    },

    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    saveButtonGrid: {
        maxWidth: '235px!important',
        float: 'right',
        marginRight: '8px'

    },
    dialogPaper: {
        minHeight: '542px!important',
        maxHeight: '542px!important',
        width: '413px',
        [theme.breakpoints.only('xs')]: {
            width: '100%!important',
            minHeight: '100%!important',
            marginLeft: '0px!important',
            marginRight: '0px!important'
        }
    },
    gridLayout: {
        paddingBottom: '30px'
    },

    saveButton: {
        fontFamily: 'Roboto!important',
        fontStyle: 'normal!important',
        fontWeight: '500px!important',
        fontSize: ' 14px!important',
        lineHeight: '16px!important',
        display: 'flex!important',
        alignItems: 'center!important',
        letterSpacing: ' 0.16px!important',
        textTransform: 'uppercase!important'

    },
    saveButtonGrid1: {
        width: '125px!important'

    }
    ,
    saveButtonGridItem1: {
        width: '108px!important'

    },
    actionButtonGroup: {
        paddingLeft: "8px !important",
    },

    gridAddItem: {
        height: "428px!important",
        overflowY: "auto!important",
        width: "100%!important",
        paddingLeft: "24px!important",
        paddingRight: "14px!important",
        paddingTop: "31px!important",        
        zIndex: 10000,
        position: "relative",
        [theme.breakpoints.only('xs')]: {
            height: 'calc(100vh - 200px) !important',
            position: "static"
        }
    },

    instructionGrid: {
        justifyContent: "space-between",
    },
    clearButton: {
        float: "right",
        marginTop: "0px!important",
        marginBottom: "8px!important"

    },

    signatureItem: {
        width: "71px",


    },

    signature: {
        height: "160px",
        width: "100%",


    },
    signatureError: {

        color: "#f44336!important",
        wordBreak: "break-all",


    },

    signatureHelperText: {
        color: "rgba(0, 0, 0, 0.54)!important",
        wordBreak: "break-all",


    },
    checkBoxHelperText: {
        wordBreak: "break-all",
    },
    fileUploadCaption: {
        wordBreak: "break-all",
        minWidth:"120px"
    },
    sectionTitle: {
        wordBreak: "break-all",
    },
    formContainer: {
      ///*  paddingRight: "56px",*/
      //  minWidth:"300px!important",
      //  [theme.breakpoints.down('md')]: {
      //      paddingRight: "25px"
      //  },
      //  [theme.breakpoints.up('md')]: {
      //      paddingRight: "56px"
      //  }

        paddingRight: "56px",
        minWidth: "300px!important",
        [theme.breakpoints.down('lg')]: {
            paddingRight: "24px"

        }
    },
    buttonContainer: {
        minWidth: "300px!important",
    },
    buttonLayout: {
        marginRight: "56px",
       [theme.breakpoints.down('lg')]: {
            marginRight: "24px",
           
        }
       
    },

    buttonLayoutScroll: {
        background:'#fff'
    }


});