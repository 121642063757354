import React from 'react';
import { withStyles } from '@mui/styles';
import Login from './Login';
import UserPanel from './UserPanel';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loginActionCreators } from 'client/store/Login';
import { errorActionCreator } from 'client/store/Error';
import Cookies from 'universal-cookie';
import { Loading } from 'client/components/Loading';
import { Box } from '@mui/material';
import { loginTheme } from './theme/LoginTheme';
const cookies = new Cookies();
class LoginPanel extends React.Component {
    componentDidMount() {
        const versagoCookie = cookies.get('Versago');
        if (versagoCookie !== undefined) {
            let roleCookie = cookies.get('roleId');
            this.props.actions.loginActionCreators.requestCheckUser(roleCookie);
            }
    }
   
    render() {
        return (
            <Box className={this.props.classes.leftPanelColor}><Loading loading={this.props.loading} />
                {!this.props.propsIsLogin  ?
                    <Login history={this.props.history} expandMenu={this.props.expandMenu} setExpandMenu={this.props.setExpandMenu} /> :
                    this.props.isLogin ? <UserPanel history={this.props.history} expandMenu={this.props.expandMenu} setExpandMenu={this.props.setExpandMenu} menuCollapse={this.props.menuCollapse} resetMargin={this.props.resetMargin}/>:<div></div>}
            </Box>
        );
    }
}
function mapDispatchToProps(dispatch) {
    return {
        actions: {
            loginActionCreators: bindActionCreators(loginActionCreators, dispatch),
            errorActionCreator: bindActionCreators(errorActionCreator, dispatch)
        }
    };
}
export default connect(state => state.Login, mapDispatchToProps)((withStyles(loginTheme)(LoginPanel)));
