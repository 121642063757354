import React from 'react';
import { withStyles } from '@mui/styles';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Box, Grid, Typography, CardContent } from '@mui/material';
import { resetPasswordTheme } from './theme/ResetPasswordTheme';
import { ServerErrorCode } from 'client/components/Common/Enum';
import { BackdropLoading } from 'client/components/Loading';

class validEmail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            heading: 'Enter your email address below and we\'ll send you a link to reset your password.',
            emailTextBoxDisplay: true,
            errorText: '',
            isRequestPasswordButtonDisplay: true,
            isTryAgainButtonDisplay:false,
            isSendAgainButtonDisplay: false,
            loading: false,
        };
    }


    render() {
       
        const { classes } = this.props;
        return (
            <Box pt={0}>
                <BackdropLoading loading={this.state.loading} />
                <Grid container spacing={0} container direction="column" alignItems="center">
                    <Grid item xs={12} >
                        <Box pt={7}>
                            <Typography align="center" variant="h6">{this.state.heading}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box pt={2} className={classes.textPassword}  height={76}>
                            {this.state.emailTextBoxDisplay &&
                                <TextField id="txtRPEmail" variant="standard" fullWidth={true} name="email" label="Email Address/Username" color="primary" className={
classes.body1} required size="medium" inputProps={{ tabIndex: 1 }} type="text" 
                                    value={this.state.email} onChange={this.handleChange} onBlur={this.handleBlur
} onFocus={this.handleFocus} error={this.state.errorText.length === 0 ? false : true} helperText={this.state.errorText
} autoFocus
                                />
                            }
                            </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box pt={6.4} >
                            {this.state.isRequestPasswordButtonDisplay &&
                                <Button type="submit" id="btnSetReqPwd" name="btnSetReqPwd"
                                            tabIndex={3} onClick={this.handleMailSubmit} 
                                            variant="contained"
                                            className={classes.resetButton + ' ' + classes.resetButton_Client}
                                            color="primary"
                                            size="medium" 
                                            disableElevation
                                            startIcon={<LockOutlinedIcon />}
                                        >
                            REQUEST RESET
                                        </Button>}
                                         
                            {this.state.isTryAgainButtonDisplay &&
                                <Button type="submit" id="btnTryagain" name="btnTryagain"
                                            tabIndex={3} onClick={this.handleInitialize} 
                                            variant="contained"
                                            className={classes.resetButton + ' ' + classes.resetButton_Client}
                                            color="primary"
                                            size="medium"  
                                            disableElevation
                                        >
                                Try Again
                                        </Button>}

                            {this.state.isSendAgainButtonDisplay &&
                                <Button type="submit" id="btnSendagain" name="btnSendagain"
                                            tabIndex={3} onClick={this.handleMailSubmit} 
                                            variant="contained"
                                            className={classes.resetButton + ' ' + classes.resetButton_Client}
                                            color="primary"
                                            size="medium" 
                                            disableElevation
                                             >Send Again</Button>}

                            {this.state.isRequestPasswordButtonDisplay &&
                                <Button type="submit" id="btnCancelEmail" 
                                                  tabIndex={15} onClick={this.handleClearEmail}
                                    className={classes.cancelButton}
                                                  variant="containedDefault"
                                            size="medium"
                                                  disableElevation
                                          >
                            CLEAR
                                       </Button>}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    handleClearEmail = async (e) => {   
        e.preventDefault();
        this.setState({
            email: '',
            errorText:'',
        });
    }

    handleBlur = (e) => {
        e.preventDefault();
        if (!this.state.email) {
            this.setState({ errorText: 'Email address is required' })
        }
        else if (!this.validateEmail(this.state.email)) {
            this.setState({ errorText: 'The email address is not properly formatted' })
        }
    }

    handleFocus = (e) => {
        this.setState({ errorText: '' })
    }

    handleMailSubmit = async (e) => {
        e.preventDefault();
        if (!this.state.email) {
            this.setState({ errorText: 'Email address is required' })
            return;
        }

        if (!this.validateEmail(this.state.email)) {
            this.setState({ errorText: 'The email address is not properly formatted' })
            return;
        }
        this.setState({ loading: true });
        const url = `api/ResetPassword/CheckResetPasswordEmail`;
        const response = await window.fetch(url,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    Email: this.state.email
                })
            });
        if (response.status !== ServerErrorCode.SuccessStatus) {
            this.setState({
                heading: "Thanks. If the account is found, an email will be sent to the provided email address.",
                isTryAgainButtonDisplay: false,
                isRequestPasswordButtonDisplay: false,
                isSendAgainButtonDisplay: false,
                emailTextBoxDisplay: false,
                loading: false,
            });
        }
        else {
                this.SendEmail();
            }
    }

    validateEmail = (email) => {
        const regEx =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regEx.test(email);
    } 

    SendEmail = async () => {

        const url = `api/ResetPassword/SendEmail`;
        const response = await window.fetch(url,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ToAddress: this.state.email,
                    LocalTime: this.getLocalTime(),
                    Url: window.location.protocol + "//" + window.location.host + "" + window.location.pathname
                })
            });
        if (response.ok) {
            const status = await response.json();
            this.setState({
                heading: status,
                isTryAgainButtonDisplay: false,
                isRequestPasswordButtonDisplay: false,
                isSendAgainButtonDisplay: false,
                emailTextBoxDisplay: false,
                loading: false,
            });
        } else {
            const status = await response.json();
            this.setState({
                heading: status,
                isTryAgainButtonDisplay: false,
                isRequestPasswordButtonDisplay: false,
                isSendAgainButtonDisplay: false,
                emailTextBoxDisplay: false,
                loading: false,
            });
        } 
    }

    handleInitialize = () => {
        this.setState({
            heading: 'Enter your email address below and we\'ll send you a link to reset your password.',
            isTryAgainButtonDisplay: false,
            isRequestPasswordButtonDisplay: true,
            isSendAgainButtonDisplay: false,
            emailTextBoxDisplay: true,
        });
    }

    handleChange = e => {
        e.preventDefault();
        const { name, value } = e.target;
        if (!value) {
            this.setState({ [name]: value, errorText: 'Email address is required' });
        } else {
            this.setState({ [name]: value, errorText: '' });
        }
        
    }

    getLocalTime = () => {
        let date = new Date();
        let hours = date.getHours() + 1;
        let minutes = date.getMinutes();
        let amPm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        let strTime = hours + ':' + minutes + ' ' + amPm;
        return strTime;
    }
}

export default (withStyles(resetPasswordTheme)(validEmail));