import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid, Typography, InputAdornment, Card, Box, AppBar, Tabs, Tab } from '@mui/material'
import { getChangeForm, showErrorWithValidationMessage } from 'admin/actions/formSetupActions';
import { spacing } from '@mui/system';
import MuiTextField from '@mui/material/TextField';
import { withStyles } from '@mui/styles';
import { styled } from '@mui/styles';
import * as Enum from 'client/components/Common/Enum';
import Autocomplete from '@mui/lab/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import { updateActiveFormUIProperty, getButtonActionObject, getControlWithSuffix, getControlCaption, getControlRoleObject, getControlDefault, getControlCulture } from 'admin/actions/formActions';
import NumericText from 'client/components/Form/Controls/NumericText';
import { getDataView, getNewDataView } from 'admin/actions/dataViewActions';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ColorPicker from 'common/colorPicker/index';
import cloneDeep from 'lodash/cloneDeep';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CircularProgress from "@mui/material/CircularProgress";
import { sectionType } from 'admin/actions/actionTypes';
import { formTheme } from 'admin/components/section/form/theme/formTheme';
import { Scrollbars } from 'react-custom-scrollbars';
import RemoveIcon from '@mui/icons-material/Remove';
import DerivedField from 'admin/components/section/dataViews/fieldDisplay/DerivedField'
import PropTypes from 'prop-types';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ControlParameterList from 'admin/components/section/form/setup/ControlParameterList';
import { ActionTypes as actionTypes } from 'admin/actions/actionTypes';
import { stubFalse } from 'lodash';
import Switch from '@mui/material/Switch';
import { sortName } from 'client/components/Common/Utility';
const TextField = styled(MuiTextField)(spacing);
var validationErrorResultList = [];
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}
class ControlProperties extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            defaultValuePopup: false,
            popupDefaultValue: "", //initially need to set same as that of in property object of the state
            helpTextPopup: false,
            popupHelpTextValue: "", //initially need to set same as that of in property object of the state
            formulaPopup: false,
            popupFormulaValue: "", //initially need to set same as that of in property object of the state
            comboboxType: Enum.ControlMode.None,
            //errorControlType: false,
            //errorFieldType: false,
            //errorCaption: false,
            // validationErrors:[]
            lookupValuePopup: false,
            tabNameActive: "main",
            filterdFieldDataSource: [{ key: "Select", value: "select" }]


        }


    }
    componentDidMount() {
        if (this.props.activeControlIndex !== "" && this.props.activeControlIndex !== undefined && this.props.activeControlIndex >= 0) {
            if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                let activeObject = this.props.formSectionHeader[this.props.activeControlIndex];
                if ((activeObject.controlType === Enum.ControlType.DropdownList || activeObject.controlType === Enum.ControlType.TextBox) && activeObject.listId > 0) {
                    // if (prevProps.formSectionHeader[prevProps.activeControlIndex].listId !== activeObject.listId)
                    this.props.dispatch(getDataView(activeObject.listId));
                }

            } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                let activeObject = this.props.formSection.detail.formGridControl[this.props.activeControlIndex];
                if ((activeObject.controlType === Enum.ControlType.DropdownList || activeObject.controlType === Enum.ControlType.TextBox) && activeObject.listId > 0) {
                    //if (prevProps.formSectionHeader[prevProps.activeControlIndex].listId !== activeObject.listId)
                    this.props.dispatch(getDataView(activeObject.listId));
                }
            }
        }

    }
    componentDidUpdate(prevProps, prevState) {


        if (this.props.activeControlIndex !== "" && this.props.activeControlIndex !== undefined && this.props.activeControlIndex >= 0) {
            const errorTab = this.props.forms.errorTab ? this.props.forms.errorTab : this.props.validationResults.length > 0 ? this.props.validationResults[0].errorTab : "";

            if (errorTab && this.state.tabNameActive !== errorTab) {
                //let aTabName = this.props.forms.errorTab;

                if (this.props.forms.errorTab)
                    this.props.dispatch(showErrorWithValidationMessage({}));
                this.setState({ tabNameActive: errorTab })
            }
            if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                let activeObject = this.props.formSectionHeader[this.props.activeControlIndex];
                if ((activeObject.controlType === Enum.ControlType.DropdownList || activeObject.controlType === Enum.ControlType.TextBox) && activeObject.listId > 0 &&
                    (prevProps.formSectionHeader[prevProps.activeControlIndex] && prevProps.formSectionHeader[prevProps.activeControlIndex].listId !== activeObject.listId)) {
                    // if (prevProps.formSectionHeader[prevProps.activeControlIndex].listId !== activeObject.listId)
                    this.props.dispatch(getDataView(activeObject.listId));
                }

            } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                let activeObject = this.props.formSection.detail.formGridControl[this.props.activeControlIndex];
                if ((activeObject.controlType === Enum.ControlType.DropdownList || activeObject.controlType === Enum.ControlType.TextBox) && activeObject.listId > 0 &&
                    (prevProps.formSection.detail.formGridControl[prevProps.activeControlIndex] && prevProps.formSection.detail.formGridControl[prevProps.activeControlIndex].listId !== activeObject.listId)) {
                    //if (prevProps.formSectionHeader[prevProps.activeControlIndex].listId !== activeObject.listId)
                    this.props.dispatch(getDataView(activeObject.listId));
                }
            }
            if (this.props.activeFormControl.sectionType !== Enum.FormSection.Details) {
                if (this.props.activeControlIndex !== prevProps.activeControlIndex) {
                    if (this.props.activeFormControl.sectionType === Enum.FormSection.Header && this.props.activeFormControl.controlType === Enum.ControlType.Button) {
                        this.setState({ tabNameActive: "bw" });
                    }
                    else {
                        this.setState({ tabNameActive: "main" });
                    }
                } else {
                    this.setActiveTabName(prevState);
                }
            }
        }

    }

    handleDefaultValueDialog = (dialogMode, saveData) => {
        if (saveData) {
            //var currentProps = this.props.controlObject;
            //currentProps.defaultValue = this.state.popupDefaultValue;
            //this.setState({ defaultValuePopup: dialogMode, property: currentProps })
            this.setState({ defaultValuePopup: dialogMode })
            this.handleChangeEvent("defaultValue", this.state.popupDefaultValue)
        } else {
            this.setState({ defaultValuePopup: dialogMode, popupDefaultValue: this.getValueObject()["defaultValue"] })
        }
    }
    setLookupDialog = () => {
        let lookupListID = this.getValueObject()["listId"];
        if (lookupListID) {
            var lookupValuePopup = this.state.lookupValuePopup;
            this.setState({ lookupValuePopup: !lookupValuePopup })
        }
    }
    handleFormulaDialog = (dialogMode, saveData, value) => {
        if (saveData) {
            this.setState({ formulaPopup: dialogMode })
            this.handleChangeEvent("formulaUI", value);
        } else {
            this.setState({ formulaPopup: dialogMode, popupFormulaValue: this.getValueObject()["formulaUI"] })
        }

    }

    handleHelpTextPopup = (dialogMode, saveData) => {
        if (saveData) {
            this.setState({ helpTextPopup: dialogMode })
            this.handleChangeEvent("helpText", this.state.popupHelpTextValue)
        } else {
            this.setState({ helpTextPopup: dialogMode, popupHelpTextValue: this.getValueObject()["helpText"] })
        }
    }

    changeForm = (change) => {
        this.props.dispatch(getChangeForm(change));
    }
    initValidationObject = (isClose) => {

        if (this.props.activeFormControl.sectionType !== sectionType.GridEdit && this.props.activeControlIndex > -1) {
            validationErrorResultList = cloneDeep(this.props.validationResults);
            var valObject = this.getValueObject();
            if (valObject) {
                if (this.getActiveControlObject().controlType !== Enum.ListControlType.SectionTitle && this.getActiveControlObject().controlType !== Enum.ListControlType.FileUpload) {
                    if (valObject["fieldName"]) {
                        this.handleValidationObject("fieldName", valObject["fieldName"], true)
                    }
                }
                this.handleValidationObject("controlType", valObject["controlType"], true)
                if (this.getActiveControlObject().controlType === Enum.ListControlType.DropdownList) {
                    this.handleValidationObject("comboBoxStatic", valObject["comboBoxStatic"], true)
                    let listId = valObject["listId"];
                    if (listId) {

                        this.initAddRemoveValidationCollection("menuItemLabel", valObject["displayField"].trim() === "" ? true : false, "main");
                        this.initAddRemoveValidationCollection("menuItemValue", valObject["valueField"].trim() === "" ? true : false, "main");


                    }

                }
            }



            if (this.props.activeFormControl.sectionType == sectionType.Button || (this.props.activeFormControl.sectionType == sectionType.Header && (this.props.activeFormControl.controlType === Enum.ControlType.Button))) {
                var bizWeaverAction = this.getButtonObjectValue(true, "bizweaver")
                var UrlAction = this.getButtonObjectValue(true, "url");
                var buttonCaption = this.getButtonObjectValue(false, "caption");
                if (bizWeaverAction.length > 0 && bizWeaverAction[0].isActive) {
                    this.initAddRemoveValidationCollection("BactionTarget", bizWeaverAction[0]["actionTarget"] === "" ? true : false, "bw");
                    this.initAddRemoveValidationCollection("BworkflowId", this.getBizWorkFlowID() === "" ? true : false, "bw");
                } else {
                    this.initAddRemoveValidationCollection("BworkflowId", false);
                    this.initAddRemoveValidationCollection("BactionTarget", false);
                }

                if (UrlAction.length > 0 && UrlAction[0].isActive) {
                    //this.addRemoveValidationCollection("UworkflowId", false);
                    this.initAddRemoveValidationCollection("UactionTarget", UrlAction[0]["actionTarget"] === "" ? true : false, "url");
                } else {

                    this.initAddRemoveValidationCollection("UactionTarget", false);
                }
            }
            if (this.getActiveControlObject().controlType === Enum.ListControlType.DropdownList) {
                if (!this.getValueObject()["comboBoxStatic"]) {
                    this.initAddRemoveValidationCollection("lookupListSource", this.getValueObject()["listId"] <= 0 ? true : false, "main");
                } else {
                    this.initAddRemoveValidationCollection("lookupListSource", false, "main");
                }
            }
            this.initAddRemoveValidationCollection("caption", buttonCaption === "" ? true : false, "main");
            this.handleValidationObject("characterLength", valObject["length"], true)
            this.props.handlePropertyValidationResult(validationErrorResultList);


            //this.forceUpdate();
        }
        if (isClose) {
            if (validationErrorResultList.length === 0) {
                this.props.handleClose();

            }

        }
        validationErrorResultList = [];
    }
    addRemoveValidationCollection = (property, add, propertyTab) => {
        var validationError = cloneDeep(this.props.validationResults);
        if (add) {
            let index = -1;
            index = validationError.findIndex(obj => obj.property === property)
            if (index === -1) {
                validationError.push({ property: property, errorTab: propertyTab });
            }
            if (property === "BactionTargetInvalid") {
                let index = -1;
                index = validationError.findIndex(obj => obj.property === "BactionTarget")
                if (index !== -1) {
                    validationError.splice(index, 1);
                }
            }
            if (property === "UactionTargetInvalid") {
                let index = -1;
                index = validationError.findIndex(obj => obj.property === "UactionTarget")
                if (index !== -1) {
                    validationError.splice(index, 1);
                }
            }

            
        } else {
            if (property !== "") {
                let index = -1;
                index = validationError.findIndex(obj => obj.property === property)
                if (index !== -1) {
                    validationError.splice(index, 1);
                }
                if (property === "BactionTargetInvalid") {
                    let index = -1;
                    index = validationError.findIndex(obj => obj.property === "BactionTarget")
                    if (index !== -1) {
                        validationError.splice(index, 1);
                    }
                }
                if (property === "UactionTargetInvalid") {
                    let index = -1;
                    index = validationError.findIndex(obj => obj.property === "UactionTarget")
                    if (index !== -1) {
                        validationError.splice(index, 1);
                    }
                }
            } else {
                validationError = [];
                //let validationErrorList = cloneDeep(validationError)
                //validationErrorList.forEach(object => {
                //    let index = validationError.findIndex(obj => obj.property === object.property)
                //    if (index !== -1) {
                //        validationError.splice(index, 1);
                //    }
                //})
            }
        }
        this.props.handlePropertyValidationResult(validationError);

    }

    //separate validation functon added for avoid multiple state update

    initAddRemoveValidationCollection = (property, add, propertyTab) => {
        var validationError = cloneDeep(validationErrorResultList);
        if (add) {
            let index = -1;
            index = validationError.findIndex(obj => obj.property === property)
            if (index === -1) {
                validationError.push({ property: property, errorTab: propertyTab });
            }
        } else {
            if (property !== "") {
                let index = -1;
                index = validationError.findIndex(obj => obj.property === property)
                if (index !== -1) {
                    validationError.splice(index, 1);
                }
            } else {
                let validationErrorList = cloneDeep(validationError)
                validationErrorList.forEach(object => {
                    let index = validationError.findIndex(obj => obj.property === object.property)
                    if (index !== -1) {
                        validationError.splice(index, 1);
                    }
                })
            }
        }
        validationErrorResultList = validationError;


    }
    handleValidationObject = (property, value, type) => {
        //  var value = this.getValueObject()[property]; 
        switch (property) {
            case "caption": {
                if (type) {
                    this.initAddRemoveValidationCollection("caption", (value === "select" || value === "") ? true : false, "main");
                }
                else {
                    this.addRemoveValidationCollection("caption", (value === "select" || value === "") ? true : false, "main");
                }

                break;
            }
            case "fieldName": {
                var submitWithForm = this.getValueObject()["isDataBind"];
                if (submitWithForm) {
                    if (type) {
                        this.initAddRemoveValidationCollection("fieldName", (value === "select" || value.indexOf("nbd") !== -1 || value === "") ? true : false, "main");
                    }
                    else {
                        this.addRemoveValidationCollection("fieldName", (value === "select" || value.indexOf("nbd") !== -1 || value === "") ? true : false, "main");
                    }
                } else {
                    if (type) {
                        this.initAddRemoveValidationCollection("fieldName", false);
                    }
                    else {
                        this.addRemoveValidationCollection("fieldName", false);
                    }
                }
                break;
            }
            case "isDataBind": {
                if (value) {
                    if (type) {
                        this.initAddRemoveValidationCollection("fieldName", this.getValueObject()["fieldName"] === "" ? true : false, "main");
                    }
                    else {
                        this.addRemoveValidationCollection("fieldName", this.getValueObject()["fieldName"] === "" ? true : false, "main");
                    }
                } else {
                    if (type) {
                        this.initAddRemoveValidationCollection("fieldName", false);
                    }
                    else {
                        this.addRemoveValidationCollection("fieldName", false);
                    }
                }
                break;
            }
            case "controlType": {
                if (type) {
                    this.initAddRemoveValidationCollection("controlType", value === "select" ? true : false, "main");
                }
                else {
                    this.addRemoveValidationCollection("controlType", value === "select" ? true : false, "main");
                }
                break;
            }
            case "characterLength":
                {
                    if (this.props.activeFormControl.controlType === Enum.ControlType.MultiText1 || this.props.activeFormControl.controlType === Enum.ControlType.TextBox) {
                        if (type) {
                            this.initAddRemoveValidationCollection("characterLength", value === "" || value === undefined ? true : false, "main");
                        }
                        else {
                            this.addRemoveValidationCollection("characterLength", value === "" || value === undefined ? true : false, "main");
                        }
                    }
                    break;
                }
            case "comboBoxStatic": {
                if (type) {
                    if (value) {
                        this.initAddRemoveValidationCollection("lookupListSource", false, "main");
                        this.initAddRemoveValidationCollection("menuItemLabel", false, "main");
                        this.initAddRemoveValidationCollection("menuItemValue", false, "main");

                    }
                }
                else {
                    if (value) {
                        this.addRemoveValidationCollection("lookupListSource", false, "main");
                        this.addRemoveValidationCollection("menuItemLabel", false, "main");
                        this.addRemoveValidationCollection("menuItemValue", false, "main");
                    }
                }

                break;
            }
            case "lookupListSource": {
                if (this.props.activeFormControl.controlType === Enum.ControlType.DropdownList) {
                    if (this.getValueObject()["comboBoxStatic"]) {
                        if (type) {
                            this.initAddRemoveValidationCollection("lookupListSource", false, "main");
                            this.initAddRemoveValidationCollection("menuItemLabel", false, "main");
                            this.initAddRemoveValidationCollection("menuItemValue", false, "main");
                        } else {
                            this.addRemoveValidationCollection("lookupListSource", false, "main");
                            this.addRemoveValidationCollection("menuItemLabel", false, "main");
                            this.addRemoveValidationCollection("menuItemValue", false, "main");
                        }
                    } else {
                        if (type) {
                            this.initAddRemoveValidationCollection("lookupListSource", value <= 0 ? true : false, "main");
                        } else {
                            this.addRemoveValidationCollection("lookupListSource", value <= 0 ? true : false, "main");
                        }
                    }
                }
                else {
                    if (type) {
                        this.initAddRemoveValidationCollection("lookupListSource", false, "main");
                        this.initAddRemoveValidationCollection("menuItemLabel", false, "main");
                        this.initAddRemoveValidationCollection("menuItemValue", false, "main");
                    } else {
                        this.addRemoveValidationCollection("lookupListSource", false, "main");
                        this.addRemoveValidationCollection("menuItemLabel", false, "main");
                        this.addRemoveValidationCollection("menuItemValue", false, "main");
                    }
                }
                break;
            }
            case "menuItemLabel": {
                if (this.getValueObject()["comboBoxStatic"]) {
                    if (type) {
                        this.initAddRemoveValidationCollection("displayField", value === "" ? true : false, "main");
                        this.initAddRemoveValidationCollection("menuItemLabel", false, "main");
                    }
                    else {
                        this.addRemoveValidationCollection("displayField", value === "" ? true : false, "main");
                        this.addRemoveValidationCollection("menuItemLabel", false, "main");
                    }
                } else {
                    if (type) {
                        this.initAddRemoveValidationCollection("displayField", (value === "" && this.getValueObject()["listId"] > 0) ? true : false, "main");
                        this.initAddRemoveValidationCollection("menuItemLabel", this.getValueObject()["listId"] > 0 && (this.getValueObject()["displayField"] === "" || this.getValueObject()["displayField"] === null) ? !value : false, "main");
                    } else {
                        this.addRemoveValidationCollection("displayField", (value === "" && this.getValueObject()["listId"] > 0) ? true : false, "main");
                        this.addRemoveValidationCollection("menuItemLabel", this.getValueObject()["listId"] > 0 && (this.getValueObject()["displayField"] === "" || this.getValueObject()["displayField"] === null) ? !value : false, "main");
                    }
                }
                break;
            }
            case "menuItemValue": {

                if (this.getValueObject()["comboBoxStatic"]) {
                    if (type) {
                        this.initAddRemoveValidationCollection("valueField", false, "main");
                        this.initAddRemoveValidationCollection("menuItemValue", false, "main");
                    } else {
                        this.addRemoveValidationCollection("valueField", false, "main");
                        this.addRemoveValidationCollection("menuItemValue", false, "main");
                    }
                } else {
                    if (type) {
                        this.initAddRemoveValidationCollection("valueField", (value === "" && this.getValueObject()["listId"] > 0) ? true : false, "main");
                        this.initAddRemoveValidationCollection("menuItemValue", this.getValueObject()["listId"] > 0 && (this.getValueObject()["valueField"] === "" || this.getValueObject()["valueField"] === null) ? !value : false, "main");
                    } else {
                        this.addRemoveValidationCollection("valueField", (value === "" && this.getValueObject()["listId"] > 0) ? true : false, "main");
                        this.addRemoveValidationCollection("menuItemValue", this.getValueObject()["listId"] > 0 && (this.getValueObject()["valueField"] === "" || this.getValueObject()["valueField"] === null) ? !value : false, "main");
                    }
                }
                break;
            }

            default: { break; }
        }
    }
    handleButtonValidationObject = (property, value) => {
        switch (property) {
            case "caption": {
                this.addRemoveValidationCollection("caption", (value === "select" || value === "") ? true : false, "main");
                break;
            }
            case "actionTarget-Url": {
                var UrlAction = this.getButtonObjectValue(true, "Url");
                if (UrlAction) {
                    this.addRemoveValidationCollection("UactionTarget", (value === "") ? true : false, "url");
                    if (value != "") {

                        try {

                            const regex = new RegExp('(^$|(http(s)?:\/\/)(\.)+)');
                            if (regex.test(value)) {
                                this.addRemoveValidationCollection("UactionTargetInvalid", false, "url");
                            } else {
                                this.addRemoveValidationCollection("UactionTargetInvalid", true, "url");
                            }

                        } catch (err) {
                            this.addRemoveValidationCollection("UactionTargetInvalid", true, "url");
                        }
                    }
                } else {
                    this.addRemoveValidationCollection("UactionTarget", false);
                    
                }
                break;
            }
            case "actionTarget-Bizweaver": {
                var bizWeaverAction = this.getButtonObjectValue(true, "Bizweaver");
                if (bizWeaverAction) {
                    this.addRemoveValidationCollection("BactionTarget", (value === "") ? true : false, "bw");
                    if (value != "") {

                        try {

                            const regex = new RegExp('(^$|(http(s)?:\/\/)(\.)+)');
                            if (regex.test(value)) {
                                this.addRemoveValidationCollection("BactionTargetInvalid", false, "bw");
                            } else {
                                this.addRemoveValidationCollection("BactionTargetInvalid", true, "bw");
                            }
                        } catch (err) {
                            this.addRemoveValidationCollection("BactionTargetInvalid", true, "bw");
                        }
                    }
                }
                else {
                    this.addRemoveValidationCollection("BactionTarget", false);

                }


                break;
            }
            case "workflowId": {
                bizWeaverAction = this.getButtonObjectValue(true, "Bizweaver");
                if (bizWeaverAction) {
                    this.addRemoveValidationCollection("BworkflowId", (value === "") ? true : false, "bw");
                } else {
                    this.addRemoveValidationCollection("BworkflowId", false);
                }
                break;
            }
            default: { break; }
        }
    }
    isValidObject = (property) => {
        var rResult = false;
        if (this.props.validationResults) {
            this.props.validationResults.map((validationObject) => {

                if (validationObject.property === property) {

                    rResult = true;
                }
            });
        }
        return rResult;
    }

    getValueObject = () => {
        let valObject = [{ controlType: "" }];
        if (this.props.activeFormControl !== undefined && this.props.activeFormControl.activeControlIndex > -1) {
            if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                valObject = this.props.formSection.header[this.props.activeControlIndex];
            } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                valObject = this.props.formSection.detail.formGridControl[this.props.activeControlIndex];
            }
            else if (this.props.activeFormControl.sectionType === Enum.FormSection.Button) {
                valObject = this.props.formSection.button[this.props.activeControlIndex];
            }
        }

        return valObject;
    }
    getValueFieldNameValue = () => {
        let valObject = [{ controlType: "" }];
        let dsourceObject = [];
        if (this.state.filterdFieldDataSource !== undefined && this.props.activeFormControl.activeControlIndex > -1) {
            if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                valObject = this.props.formSection.header[this.props.activeControlIndex];
                dsourceObject = this.props.primaryTableFields.filter(field => field.fieldName === valObject.fieldName)
            } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                valObject = this.props.formSection.detail.formGridControl[this.props.activeControlIndex];
                dsourceObject = this.props.detailTableFields.filter(field => field.fieldName === valObject.fieldName)
            }
        }
        if (dsourceObject.length > 0)
            return dsourceObject[0].fieldName;

        return "";
    }
    resetFieldName = (form, fieldName, data) => {

        if (fieldName === "select") {
            if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                form.fieldName = getControlCaption(this.props.form.formSection.header[this.props.activeControlIndex].controlUiId, this.props.activeFormControl.sectionType).fieldName;
            }
            else {
                form.fieldName = getControlCaption(this.props.form.formSection.detail.formGridControl[this.props.activeControlIndex].controlUiId, this.props.activeFormControl.sectionType).fieldName

            }

            form.isPrimaryField = false;
        }
        else {


            form.fieldName = fieldName
            form.isPrimaryField = data.isPrimaryKeyField
        }
        form.controlName = form.fieldName;

    }

    handleControlCollectionUpdate = (fieldName, data) => {
        let model = cloneDeep(this.props.formSection);
        let existingIndex = -1;
        let newActiveControlObject = {
            sectionType: "",
            ControlUiId: "",
            controlType: null,
            fieldName: "",
            formControl: null,
            activeControlIndex: -1,
        }

        if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
            if (fieldName === "" && this.props.formSectionHeader[this.props.activeControlIndex].isDataBind) { this.setState({ errorFieldType: true }) }
            existingIndex = model.header.findIndex(headerObj => headerObj.fieldName === fieldName);
            if (this.props.activeControlIndex === existingIndex) { return true }
            if (existingIndex > -1) {
                let currentObject = this.props.formSectionHeader[this.props.activeControlIndex];
                Object.keys(this.props.formSectionHeader[this.props.activeControlIndex]).map(function (key) {
                    switch (key) {
                        case "controlId":
                            {
                                model.header[existingIndex].controlId = currentObject.controlId;
                                break;
                            }
                        case "precision":
                            {
                                model.header[existingIndex].precision = currentObject.precision;
                                break;
                            }
                        case "format":
                            {
                                 
                              if (currentObject.format !== "" && model.header[existingIndex].format==="")
                                    model.header[existingIndex].format = currentObject.format;
                                break;
                            }
                        case "dataType": {
                            if (currentObject.dataType !== Enum.FormatType.None && currentObject.dataType !== Enum.DataType.Select) {
                                model.header[existingIndex].dataType = currentObject.dataType;
                            }
                            break;
                        }
                        case "controlType":
                            {
                                if (currentObject.controlType !== Enum.FormatType.None)
                                    model.header[existingIndex].controlType = currentObject.controlType; 

                                break;
                            }
                        case "controlName":
                            {
                                if (currentObject.controlName !== "")
                                    model.header[existingIndex].controlName = currentObject.controlName;
                                break;
                            }
                        case "defaultValue":
                            {
                                if (currentObject.defaultValue !== "")
                                    model.header[existingIndex].defaultValue = currentObject.defaultValue;
                                break;
                            }
                        case "isPrimaryField": { break; }
                        case "isForeginKey": { break; }
                        case "isDataBind": { break; }
                        case "tableName": { break; }
                        case "Caption": {
                            if (currentObject.caption !== "")
                                model.header[existingIndex].caption = currentObject.caption;
                            else

                                model.header[existingIndex].caption = this.props.activeFormControl.controlType === Enum.ControlType.Button ? "" : fieldName;
                            break;
                        }
                        default:
                            {
                                model.header[existingIndex][key] = currentObject[key];
                            }
                    }
                    return "";
                }); 
                model.header[existingIndex].fieldName = fieldName;
                model.header[existingIndex].controlUiId = currentObject.controlUiId;
                model.header.splice(this.props.activeControlIndex, 1);
                existingIndex = model.header.findIndex(headerObj => headerObj.fieldName === fieldName);

                this.changeForm(form => { form.formSection.header = model.header })
                newActiveControlObject.sectionType = Enum.FormSection.Header;
                newActiveControlObject.ControlUiId = model.header[existingIndex].controlUiId;
                newActiveControlObject.controlType = this.getControlTypeDataSource("", false);//model.header[existingIndex].controlType;
                newActiveControlObject.fieldName = fieldName;
                newActiveControlObject.formControl = model.header[existingIndex];
                newActiveControlObject.activeControlIndex = existingIndex;
            } else {

                this.changeForm(form => {
                    const currentDataType = this.getFieldPropsDataSource(fieldName).dataType;
                    const formatType = this.getFieldPropsDataSource(fieldName).formatType
                    if (form.formSection.header[this.props.activeControlIndex].dataType !== currentDataType) {
                        if (form.formSection.header[this.props.activeControlIndex].dataType !== 0) {
                          
                        }
                        form.formSection.header[this.props.activeControlIndex].dataType = currentDataType

                        if (currentDataType.toUpperCase() === Enum.FormatType.Date.toUpperCase() || currentDataType.toUpperCase() === Enum.FormatType.DateTime.toUpperCase() || currentDataType.toUpperCase() === Enum.FormatType.Time.toUpperCase()) {
                            form.formSection.header[this.props.activeControlIndex].format = formatType
                        }
                       
                    }


                    if (currentDataType.toUpperCase() === Enum.FormatType.Date.toUpperCase() || currentDataType.toUpperCase() === Enum.FormatType.DateTime.toUpperCase() || currentDataType.toUpperCase() === Enum.FormatType.Time.toUpperCase()) {

                        form.formSection.header[this.props.activeControlIndex].culture = getControlCulture(null, formatType)
                        form.formSection.header[this.props.activeControlIndex].format = this.getFieldPropsDataSource(fieldName).formatType

                    }
                     
                    this.resetFieldName(form.formSection.header[this.props.activeControlIndex], fieldName, data)

                })

              
            }
        }

        else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
            if (fieldName === "" && this.props.formSection.detail.formGridControl[this.props.activeControlIndex].isDataBind) { this.setState({ errorFieldType: true }) }

            existingIndex = model.detail.formGridControl.findIndex(detailObj => detailObj.fieldName === fieldName);
            if (this.props.activeControlIndex === existingIndex) { return true }
            if (existingIndex > -1) {
                let currentObject = this.props.formSection.detail.formGridControl[this.props.activeControlIndex];
                Object.keys(this.props.formSection.detail.formGridControl[this.props.activeControlIndex]).map(function (key) {
                    if (key === "dataType") {
                        if (currentObject[key] !== Enum.FormatType.None &&
                            currentObject[key] !== Enum.DataType.Select) {
                            model.detail.formGridControl[existingIndex][key] = currentObject[key];
                        }
                    }
                    else if (key === "format") {
                        if (currentObject.format !== "" && model.detail.formGridControl[existingIndex].format === "")
                            model.detail.formGridControl[existingIndex].format = currentObject.format;
                    }

                    else {
                        model.detail.formGridControl[existingIndex][key] = currentObject[key];
                    }



                    return "";
                });
                model.detail.formGridControl[existingIndex].fieldName = fieldName;

                //Inherit value
                model.detail.formGridControl[existingIndex].controlId = currentObject.controlId;
                model.detail.formGridControl[existingIndex].precision = currentObject.precision;
                if (currentObject.dataType !== Enum.FormatType.None && currentObject.dataType !== Enum.DataType.Select)
                    model.detail.formGridControl[existingIndex].dataType = currentObject.dataType;
                model.detail.formGridControl[existingIndex].controlType = currentObject.controlType;
                if (currentObject.controlName !== "")
                    model.detail.formGridControl[existingIndex].controlName = currentObject.controlName;
                if (currentObject.defaultValue !== "")
                    model.detail.formGridControl[existingIndex].defaultValue = currentObject.defaultValue;
                model.detail.formGridControl[existingIndex].isPrimaryField = currentObject.isPrimaryField;
                model.detail.formGridControl[existingIndex].isForeginKey = currentObject.isForeginKey;
                model.detail.formGridControl[existingIndex].isDataBind = currentObject.isDataBind;
                model.detail.formGridControl[existingIndex].tableName = currentObject.tableName;
                if (currentObject.Caption !== "")
                    model.detail.formGridControl[existingIndex].Caption = currentObject.Caption;

                //
                model.detail.formGridControl.splice(this.props.activeControlIndex, 1);


                existingIndex = model.detail.formGridControl.findIndex(detailObj => detailObj.fieldName === fieldName);


                newActiveControlObject.sectionType = Enum.FormSection.Details;
                newActiveControlObject.ControlUiId = model.detail.formGridControl[existingIndex].controlUiId;
                newActiveControlObject.controlType = model.detail.formGridControl[existingIndex].controlType;
                newActiveControlObject.fieldName = fieldName;
                newActiveControlObject.formControl = model.detail.formGridControl[existingIndex];
                newActiveControlObject.activeControlIndex = existingIndex;

            } else {
                this.changeForm(form => {
                    const currentDataType = this.getFieldPropsDataSource(fieldName).dataType;
                    const formatType = this.getFieldPropsDataSource(fieldName).formatType;
                    if (form.formSection.detail.formGridControl[this.props.activeControlIndex].dataType !== currentDataType) {
                        if (form.formSection.detail.formGridControl[this.props.activeControlIndex].dataType !== "0") {
                            //form.formSection.detail.formGridControl[this.props.activeControlIndex].controlType = "select"
                        }
                        form.formSection.detail.formGridControl[this.props.activeControlIndex].dataType = this.getFieldPropsDataSource(fieldName).dataType
                        if (currentDataType.toUpperCase() === Enum.FormatType.Date.toUpperCase() || currentDataType.toUpperCase() === Enum.FormatType.DateTime.toUpperCase() || currentDataType.toUpperCase() === Enum.FormatType.Time.toUpperCase()) {
                            form.formSection.detail.formGridControl[this.props.activeControlIndex].format = formatType
                          } 
                    }
                    
                    if (currentDataType.toUpperCase() === Enum.FormatType.Date.toUpperCase() || currentDataType.toUpperCase() === Enum.FormatType.DateTime.toUpperCase() || currentDataType.toUpperCase() === Enum.FormatType.Time.toUpperCase()) {

                        form.formSection.detail.formGridControl[this.props.activeControlIndex].culture = getControlCulture(null, formatType)
                        form.formSection.detail.formGridControl[this.props.activeControlIndex].format = this.getFieldPropsDataSource(fieldName).formatType
                       
                    }

                    this.resetFieldName(form.formSection.detail.formGridControl[this.props.activeControlIndex], fieldName, data)
                    //return form;
                })
            }
        }
        if (existingIndex > -1) {
            //update active object and  index also
            let updateModel = {
                data: model,
                activeControlObject: newActiveControlObject,
            }
            this.props.dispatch(updateActiveFormUIProperty(updateModel));
        }
    }
    //Current Value mmm/yyy/dd hh:mm:ss   newvalu yyy/mmm/dd
    resetCultureValue = (currentValue, newValue, isDate) => {
        let valObject = currentValue.split(" ");
        let currentDate = "";
        let currentTime = "";
        if (valObject.length > 0) {
            currentDate = valObject[0];
            currentTime = valObject[1];
            if (valObject.length > 1) {
                currentTime += " " + valObject[2];
            }
        }
        if (isDate) {
            return newValue + (currentTime ? " " + currentTime : "");
        } else {
            return (currentDate ? currentDate + " " : "") + newValue;
        }
    }

    handleChangeEvent = (property, value, data) => {


        switch (property) {
            case "name":
                {
                    if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                        this.changeForm(form => form.formSection.header[this.props.activeControlIndex].controlName = value)
                    } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                        this.changeForm(form => form.formSection.detail.formGridControl[this.props.activeControlIndex].controlName = value)
                    }
                    break;
                }
            case "dataType":
                {
                    if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                        this.changeForm(form => form.formSection.header[this.props.activeControlIndex].dataType = value)
                    } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                        this.changeForm(form => form.formSection.detail.formGridControl[this.props.activeControlIndex].dataType = value)
                    }

                    break;
                }
            case "tableName":
                {
                    if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                        this.changeForm(form => form.formSection.header[this.props.activeControlIndex].tableName = value)
                    } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                        this.changeForm(form => form.formSection.detail.formGridControl[this.props.activeControlIndex].tableName = value)
                    }

                    break;
                }
            case "controlType":
                {
                    //let controlData = { control: "numericTextBox", dataType: "numbers", fieldName: " Balance", isPrimary: false, length: "9", sectionType: "header" }

                    let control = {};
                    this.addRemoveValidationCollection("", false);
                    var newsuffix = getControlWithSuffix(value, null);
                    if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {

                        control = cloneDeep(this.props.formSection.header[this.props.activeControlIndex]);
                        let controlSplit = control.controlUiId.split("_")
                        control.controlType = value

                        control = getControlDefault(value, control);
  

                        if (control.controlUiId.indexOf("SubRow") !== -1) {
                            control.controlUiId = `${newsuffix}${controlSplit[1]}_${controlSplit[2]}`
                        }
                        else {
                            control.controlUiId = `${newsuffix}${controlSplit[1]}`
                        }
                     
                        if (value !== "select") {
                            this.changeForm(form => form.formSection.header[this.props.activeControlIndex] = control)


                        } else {
                            this.changeForm(form => form.formSection.header[this.props.activeControlIndex].controlType = value)
                        }

                    } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {

                        control = cloneDeep(this.props.formSection.detail.formGridControl[this.props.activeControlIndex])

                        if (value !== "select") {
                            let controlSplit = control.controlUiId.split("_")
                            control.controlType = value
                            control.controlUiId = `${newsuffix}${controlSplit[1]}_${controlSplit[2]}`
                            control = getControlDefault(value, control);
                            
                            this.changeForm(form => form.formSection.detail.formGridControl[this.props.activeControlIndex] = control)
                        }
                        else {
                            this.changeForm(form => form.formSection.detail.formGridControl[this.props.activeControlIndex].controlType = value)

                        }

                    }

                    if (control) {
                        if (value !== "select") {
                            let controlData = { control: value, dataType: control.dataType, fieldName: control.fieldName, isPrimary: control.isPrimary, sectionType: this.props.activeFormControl.sectionType }
                            this.props.addControl(controlData, this.props.activeFormControl.sectionType === Enum.FormSection.Details ? `${control.controlUiId.split("_")[1]}_${control.controlUiId.split("_")[2]}` :
                                control.controlUiId.indexOf("SubRow") !== -1 ? `${control.controlUiId.split("_")[1]}_${control.controlUiId.split("_")[2]}` :
                                    control.controlUiId.split("_")[1], true)
                        }

                    }


                    //this.handleValidationObject("controlType", value)
                    break;
                }
            case "fieldName":
                {

                    this.handleControlCollectionUpdate(value, data);
                    this.handleValidationObject("fieldName", value)
                    //let arrayOfDefault = ["TextBox", "numeric"];//old value
                    if (this.props.activeFormControl.formControl.caption === "") {
                        if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                            if (this.props.form.formSection.header[this.props.activeControlIndex].caption.indexOf("nbd") > -1 || this.props.form.formSection.header[this.props.activeControlIndex].caption.trim() === "") {
                                this.changeForm(form => {
                                    form.formSection.header[this.props.activeControlIndex].caption = value
                                })
                            }


                        } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                            if (this.props.form.formSection.detail.formGridControl[this.props.activeControlIndex].caption.indexOf("nbd") > -1 || this.props.form.formSection.detail.formGridControl[this.props.activeControlIndex].caption.trim() === "") {
                                this.changeForm(form => form.formSection.detail.formGridControl[this.props.activeControlIndex].caption = value)
                            }
                        }
                    }

                    break;
                }
            case "multiLine":
                {
                    if (value) {
                        if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                            this.changeForm(form => form.formSection.header[this.props.activeControlIndex].controlType =
                                Enum.ControlType.MultiText1)
                        } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                            this.changeForm(form => form.formSection.detail.formGridControl[this.props.activeControlIndex].controlType =
                                Enum.ControlType.MultiText1)
                        }
                    } else {
                        if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                            this.changeForm(form => form.formSection.header[this.props.activeControlIndex].controlType =
                                Enum.ControlType.TextBox)
                        } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                            this.changeForm(form => form.formSection.detail.formGridControl[this.props.activeControlIndex].controlType =
                                Enum.ControlType.TextBox)
                        }
                    }
                    break;
                }
            case "characterLength":
                {
                    if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                        this.changeForm(form => form.formSection.header[this.props.activeControlIndex].length = value)
                    } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                        this.changeForm(form => form.formSection.detail.formGridControl[this.props.activeControlIndex].length = value)
                    }
                    this.handleValidationObject("characterLength", value)
                    break;
                }
            case "rowSpan":
                {
                    // controlPropertyObject["rowSpan"] = value;
                    if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                        this.changeForm(form => form.formSection.header[this.props.activeControlIndex].rowSpan = value)
                    } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                        this.changeForm(form => form.formSection.detail.formGridControl[this.props.activeControlIndex].rowSpan = value)
                    }
                    break;
                }
            case "format":
                {
                    if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                        this.changeForm(form => {
                            form.formSection.header[this.props.activeControlIndex].format = value
                            if (value === Enum.FormatType.DateTime) {
                                form.formSection.header[this.props.activeControlIndex].culture = "MM/dd/yyyy hh:mm tt"
                            } else if (value === Enum.FormatType.Time) {
                                form.formSection.header[this.props.activeControlIndex].culture = "MM/dd/yyyy hh:mm tt"
                            } else if (value === Enum.FormatType.Date) {
                                form.formSection.header[this.props.activeControlIndex].culture = "MM/dd/yyyy"
                            }
                        })
                    } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                        this.changeForm(form => {
                            form.formSection.detail.formGridControl[this.props.activeControlIndex].format = value
                            if (value === Enum.FormatType.DateTime) {
                                form.formSection.detail.formGridControl[this.props.activeControlIndex].culture = "MM/dd/yyyy hh:mm tt"
                            } else if (value === Enum.FormatType.Time) {
                                form.formSection.detail.formGridControl[this.props.activeControlIndex].culture = "MM/dd/yyyy hh:mm tt"
                            } else if (value === Enum.FormatType.Date) {
                                form.formSection.detail.formGridControl[this.props.activeControlIndex].culture = "MM/dd/yyyy"
                            }
                        })
                    }
                    break;
                }
            case "dateCulture":
                {
                    if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                        this.changeForm(form => form.formSection.header[this.props.activeControlIndex].culture = this.resetCultureValue(form.formSection.header[this.props.activeControlIndex].culture, value, true))
                    } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                        this.changeForm(form => form.formSection.detail.formGridControl[this.props.activeControlIndex].culture = this.resetCultureValue(form.formSection.detail.formGridControl[this.props.activeControlIndex].culture, value, true))
                    }
                    break;
                }
            case "timeCulture":
                {
                    if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                        this.changeForm(form => form.formSection.header[this.props.activeControlIndex].culture = this.resetCultureValue(form.formSection.header[this.props.activeControlIndex].culture, value, false))
                    } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                        this.changeForm(form => form.formSection.detail.formGridControl[this.props.activeControlIndex].culture = this.resetCultureValue(form.formSection.detail.formGridControl[this.props.activeControlIndex].culture, value, false))
                    }
                    break;

                }
            case "culture": {
                if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                    this.changeForm(form => form.formSection.header[this.props.activeControlIndex].culture = value)
                } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                    this.changeForm(form => form.formSection.detail.formGridControl[this.props.activeControlIndex].culture = value)
                }
                break;
            }
            case "decimalPlace":
                {

                    if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                        this.changeForm(form => form.formSection.header[this.props.activeControlIndex].precision = (value === "" ? -1 : value))
                    } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                        this.changeForm(form => form.formSection.detail.formGridControl[this.props.activeControlIndex].precision = (value === "" ? -1 : value))
                    }

                    break;
                }
            case "defaultValue":
                {
                    if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                        this.changeForm(form => form.formSection.header[this.props.activeControlIndex].defaultValue = value)
                    } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                        this.changeForm(form => form.formSection.detail.formGridControl[this.props.activeControlIndex].defaultValue = value)
                    }
                    this.setState(prevState => ({ popupDefaultValue: value }))
                    break;
                }
            case "lookupListSource":
                {
                    this.handleValidationObject("lookupListSource", value)
                    if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                        this.changeForm(form => {
                            form.formSection.header[this.props.activeControlIndex].listId = value
                            form.formSection.header[this.props.activeControlIndex].formControlListMapping = []
                            form.formSection.header[this.props.activeControlIndex].formControlListParameter = []
                            form.formSection.header[this.props.activeControlIndex].displayField = ""
                            form.formSection.header[this.props.activeControlIndex].valueField = ""
                        })
                    } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                        this.changeForm(form => {
                            form.formSection.detail.formGridControl[this.props.activeControlIndex].listId = value
                            form.formSection.detail.formGridControl[this.props.activeControlIndex].formControlListMapping = []
                            form.formSection.detail.formGridControl[this.props.activeControlIndex].formControlListParameter = []
                            form.formSection.detail.formGridControl[this.props.activeControlIndex].displayField = ""
                            form.formSection.detail.formGridControl[this.props.activeControlIndex].valueField = ""
                        })
                    }
                    if (!value)
                        this.props.dispatch(getNewDataView());

                    break;
                }
            case "formulaUI":
                {
                    if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                        this.changeForm(form => form.formSection.header[this.props.activeControlIndex].formulaUI = value)
                    } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                        this.changeForm(form => form.formSection.detail.formGridControl[this.props.activeControlIndex].formulaUI = value)
                    }
                    this.setState(prevState => ({ popupFormulaValue: value }))
                    break;
                }
            case "visible":
                {

                    if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                        this.changeForm(form => form.formSection.header[this.props.activeControlIndex].isVisible = value)
                    } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                        this.changeForm(form => form.formSection.detail.formGridControl[this.props.activeControlIndex].isVisible = value)
                    }
                    break;
                }
            case "editable":
                {
                    if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                        this.changeForm(form => form.formSection.header[this.props.activeControlIndex].isEditable = value)
                    } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                        this.changeForm(form => form.formSection.detail.formGridControl[this.props.activeControlIndex].isEditable = value)
                    }
                    break;
                }
            case "required":
                {

                    if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                        this.changeForm(form => form.formSection.header[this.props.activeControlIndex].isMandatory = value)
                    } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                        this.changeForm(form => form.formSection.detail.formGridControl[this.props.activeControlIndex].isMandatory = value)
                    }
                    break;
                }
            case "autoIncrement":
                {

                    if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                        this.changeForm(form => form.formSection.detail.formGridControl[this.props.activeControlIndex].isAutoIncrement = value)
                    }
                    break;
                }
            case "initialFocus":
                {
                    if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                        this.changeForm(form => form.formSection.header[this.props.activeControlIndex].hasFocus = value)
                    } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                        this.changeForm(form => form.formSection.detail.formGridControl[this.props.activeControlIndex].hasFocus = value)
                    }
                    break;
                }
            case "submitWithForm":
                {

                    if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                        this.changeForm(form => form.formSection.header[this.props.activeControlIndex].isDataBind = value)
                    } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                        this.changeForm(form => form.formSection.detail.formGridControl[this.props.activeControlIndex].isDataBind = value)
                    }
                    this.handleValidationObject("isDataBind", value)
                    break;
                }
            case "onLostFocus":
                {
                    if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                        this.changeForm(form => form.formSection.header[this.props.activeControlIndex].onLostFocus = value)
                    }
                    break;
                }
            case "alignment":
                {

                    if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                        this.changeForm(form => form.formSection.header[this.props.activeControlIndex].textAlign = value)
                    } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                        this.changeForm(form => form.formSection.detail.formGridControl[this.props.activeControlIndex].textAlign = value)
                    }
                    break;
                }
            case "caption":
                {
                    if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                        this.changeForm(form => form.formSection.header[this.props.activeControlIndex].caption = value)

                    } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                        this.changeForm(form => form.formSection.detail.formGridControl[this.props.activeControlIndex].caption = value)
                    }
                    // this.handleValidationObject("caption",  value)
                    break;
                }
            case "helpText":
                {

                    if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                        this.changeForm(form => form.formSection.header[this.props.activeControlIndex].helpText = value)
                    } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                        this.changeForm(form => form.formSection.detail.formGridControl[this.props.activeControlIndex].helpText = value)
                    }
                    this.setState(prevState => ({ popupHelpTextValue: value }))
                    break;
                }
            case "foreColor":
                {

                    if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                        this.changeForm(form => form.formSection.header[this.props.activeControlIndex].foreColor = value)
                    } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                        this.changeForm(form => form.formSection.detail.formGridControl[this.props.activeControlIndex].foreColor = value)
                    }
                    break;
                }
            case "captionColor":
                {
                    if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                        this.changeForm(form => form.formSection.header[this.props.activeControlIndex].captionColor = value)
                    } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                        this.changeForm(form => form.formSection.detail.formGridControl[this.props.activeControlIndex].captionColor = value)
                    }
                    break;
                }
            case "fillColor":
                {

                    if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                        this.changeForm(form => form.formSection.header[this.props.activeControlIndex].backGroundColor = value)
                    } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                        this.changeForm(form => form.formSection.detail.formGridControl[this.props.activeControlIndex].backGroundColor = value)
                    }
                    break;
                }
            case "actionType-Not":
                {
                    this.setState(prevState => ({
                        property: {
                            ...prevState.property,
                            actionType: value
                        }
                    }))
                    break;
                }
            case "menuItemLabel":
                {//displayField:  
                    if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                        this.changeForm(form => form.formSection.header[this.props.activeControlIndex].displayField = value)
                    } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                        this.changeForm(form => form.formSection.detail.formGridControl[this.props.activeControlIndex].displayField = value)
                    }
                    this.handleValidationObject("menuItemLabel", value)
                    break;
                }
            case "menuItemValue":
                {//valueField: 
                    if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                        this.changeForm(form => form.formSection.header[this.props.activeControlIndex].valueField = value)
                    } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                        this.changeForm(form => form.formSection.detail.formGridControl[this.props.activeControlIndex].valueField = value)
                    }
                    this.handleValidationObject("menuItemValue", value)
                    break;
                }
            case "staticMenuSelections":
                {//staticValues 
                    if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                        this.changeForm(form => form.formSection.header[this.props.activeControlIndex].staticValues = value)
                    } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                        this.changeForm(form => form.formSection.detail.formGridControl[this.props.activeControlIndex].staticValues = value)
                    }

                    break;
                }
            case "comboBoxStatic":
                {//listId=0 & control type combobox then static else dynamic

                    if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {

                        this.changeForm(form => {
                            form.formSection.header[this.props.activeControlIndex].comboBoxStatic = value
                        })
                    } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {

                        this.changeForm(form => {
                            form.formSection.detail.formGridControl[this.props.activeControlIndex].comboBoxStatic = value
                        })
                    }
                    this.handleValidationObject("comboBoxStatic", value)
                    break;
                }
            case "popupDefaultValue":
                {
                    this.setState(prevState => ({
                        popupDefaultValue: value
                    }))
                    break;
                }
            case "popupHelpTextValue":
                {
                    this.setState(prevState => ({
                        popupHelpTextValue: value
                    }))
                    break;
                }
            case "popupFormulaValue":
                {
                    this.setState(prevState => ({
                        popupFormulaValue: value
                    }))
                    break;
                }
            case "isAllowNewValue": {
                if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                    this.changeForm(form => form.formSection.header[this.props.activeControlIndex].isAllowNewValue = value)
                } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                    this.changeForm(form => form.formSection.detail.formGridControl[this.props.activeControlIndex].isAllowNewValue = value)
                }
                break;
            }
            default: {
                break;
            }
        }

    }
    getActionObjectIndex = (object) => {
        let currentindex = -1;
        let controlObject = this.getActiveControlObject();
        controlObject.formAction.forEach(function (x, index) {
            if (x.actionName === object[0].actionName) {
                currentindex = index;
                return currentindex;
            }
        })
    }
    handleChange = (event) => {
        if (event.target.value === "single") {
            this.handleChangeEvent("multiLine", false);
        } else {
            this.handleChangeEvent("multiLine", true);
        }
    }
    handleButtonActionChangeForm = (currentIndex, property, value) => {
        if (this.isButtonOfType(true, false, false, false, false)) {
            this.changeForm(form => form.formSection.header[this.props.activeControlIndex].formAction[currentIndex][property] = value)
        } else {
            this.changeForm(form => form.formSection.button[this.props.activeControlIndex].formAction[currentIndex][property] = value)
        }
    }


    handleButtonChangeForm = (currentIndex, property, value) => {
        if (property === "keyMapped") {

            if (currentIndex === null) { currentIndex = this.props.activeControlIndex };
            if (this.isButtonOfType(true, false, false, false, false)) {
                let normalButtonKeyMappedIndex = this.props.formSection.button.findIndex(buttonObject => buttonObject.keyMapped === true);
                let headerKeyMapped = this.props.formSection.header.findIndex(buttonObj => buttonObj.keyMapped === true);
                this.changeForm(form => {
                    form.formSection.header[currentIndex]["keyMapped"] = value

                    if (value && headerKeyMapped > -1) {
                        form.formSection.header[headerKeyMapped]["keyMapped"] = false
                    }
                    if (value && normalButtonKeyMappedIndex > -1) {
                        form.formSection.button[normalButtonKeyMappedIndex]["keyMapped"] = false
                    }
                }
                )
            } else {
                let customButtonKeyMappedIndex = this.props.formSection.header.findIndex(buttonObject => buttonObject.keyMapped === true);
                let normalButton = this.props.formSection.button.findIndex(buttonObj => buttonObj.keyMapped === true);
                this.changeForm(form => {
                    form.formSection.button[currentIndex]["keyMapped"] = value

                    if (value && normalButton > -1) {
                        form.formSection.button[normalButton]["keyMapped"] = false
                    }
                    if (value && customButtonKeyMappedIndex > -1) {
                        form.formSection.header[customButtonKeyMappedIndex]["keyMapped"] = false
                    }
                })
            }

        }
        else if (property === "initialFocus") {

            if (currentIndex === null) { currentIndex = this.props.activeControlIndex };

            let controlIndex = this.props.formSection.header.findIndex(controlObject => controlObject.hasFocus === true);
            this.changeForm(form => {
                form.formSection.header[currentIndex]["hasFocus"] = value

                if (value && controlIndex > -1) {
                    form.formSection.header[controlIndex]["hasFocus"] = false
                }
            })


        }
        else {
            if (currentIndex === null) { currentIndex = this.props.activeControlIndex };
            if (this.isButtonOfType(true, false, false, false, false)) {
                this.changeForm(form => form.formSection.header[currentIndex][property] = value)
            } else {
                this.changeForm(form => form.formSection.button[currentIndex][property] = value)
            }
        }
    }
    handleButtonSettingsChangeEvent = (property, value) => {

        switch (property) {
            case "actionName-Save":
                {

                    let buttonObject = this.getActiveButtonObject()
                    var actionObject = buttonObject.formAction.filter(actionObj => actionObj.actionSequence === 1);
                    if (value) {
                        if (actionObject.length > 0) {
                            if (this.isButtonOfType(true, false, false, false, false)) {
                                this.changeForm(form => form.formSection.header[this.props.activeControlIndex].formAction.push(actionObject))
                            } else {
                                this.changeForm(form => form.formSection.button[this.props.activeControlIndex].formAction.push(actionObject))
                            }
                        }
                    }
                    break;
                }
            case "actionName-Bizweaver":
                {

                    let buttonObject = this.getActiveButtonObject();
                    if (value) {


                        actionObject = buttonObject.formAction.filter(actionObj => actionObj.isBizweaverService === true);
                        if (actionObject.length === 0) {
                            var newBwAction = getButtonActionObject();
                            newBwAction.formId = 0
                            newBwAction.actionName = "Bizweaver";
                            newBwAction.actionType = Enum.ActionType.Service;
                            newBwAction.actionSequence = 2;// buttonObject.formAction.filter(actionObj => actionObj.isActive === true).length + 1;//buttonObject.formAction.length + 1;
                            newBwAction.serviceType = Enum.Method.Post;
                            newBwAction.actionTarget = "";
                            newBwAction.actionTrigger = "1";
                            newBwAction.isBizweaverService = true;
                            newBwAction.isActive = true;
                            newBwAction.validateErrorMessage = "Action Failed";
                            newBwAction.validateSuccessMessage = "Action Successfull";
                            newBwAction.formActionParameter = { pTaskID: "0", pStartAfter: "0", pArguments: [] };
                            this.setState({ tabNameActive: "bw" })
                            if (this.isButtonOfType(true, false, false, false, false)) {
                                this.changeForm(form => form.formSection.header[this.props.activeControlIndex].formAction.push(newBwAction))
                            } else {
                                this.changeForm(form => form.formSection.button[this.props.activeControlIndex].formAction.push(newBwAction))
                            }


                        } else {
                            let currentIndex = buttonObject.formAction.findIndex(actionObj => actionObj.isBizweaverService === true);
                            this.handleButtonActionChangeForm(currentIndex, "isActive", true);
                            //this.changeForm(form => form.formSection.button[this.props.activeControlIndex].formAction[currentIndex].isActive = true)
                            this.setState({ tabNameActive: "bw" })
                        }

                    } else {
                        let currentIndex = buttonObject.formAction.findIndex(actionObj => actionObj.isBizweaverService === true);
                        this.addRemoveValidationCollection("BactionTarget", false);
                        this.addRemoveValidationCollection("BactionTargetInvalid", false);

                        this.addRemoveValidationCollection("BworkflowId", false);
                        if (currentIndex > -1) {
                            this.handleButtonActionChangeForm(currentIndex, "isActive", false);
                            this.addRemoveValidationCollection("", false, "bw")
                            if (this.props.activeFormControl.sectionType === sectionType.Header && this.props.activeFormControl.controlType === Enum.ControlType.Button) {
                                let urlIndex = buttonObject.formAction.findIndex(actionObj => (actionObj.actionType === Enum.ActionType.Url || actionObj.actionName === "Url" || actionObj.actionName === "url") && actionObj.isBizweaverService !== true)
                                if (urlIndex > -1 && buttonObject.formAction[urlIndex].isActive) {

                                    this.setState({ tabNameActive: "url" })
                                } else {
                                    this.setState({ tabNameActive: "main" })
                                }
                            } else {
                                this.setState({ tabNameActive: "main" })
                            }

                            // this.changeForm(form => form.formSection.button[this.props.activeControlIndex].formAction = actionObject)
                        }
                    }
                    break;
                }
            case "workflowId":
                {
                    let buttonObject = this.getActiveButtonObject();
                    let currentIndex = buttonObject.formAction.findIndex(actionObj => actionObj.isBizweaverService === true);
                    if (currentIndex > -1) {
                        this.handleButtonActionChangeForm(currentIndex, "pTaskId", value);
                        //this.changeForm(form => form.formSection.button[this.props.activeControlIndex].formAction[currentIndex].formActionParameter.pTaskID = value)
                        this.handleButtonValidationObject("workflowId", value);
                    }
                    break;
                }
            case "actionName-Url":
                {

                    let buttonObject = this.getActiveButtonObject();
                    actionObject = buttonObject.formAction.filter(actionObj => (actionObj.actionType === Enum.ActionType.Url || actionObj.actionName === "Url" || actionObj.actionName === "url") && actionObj.isBizweaverService !== true);
                    if (value) {
                        if (actionObject.length === 0) {
                            var newUrlAction = getButtonActionObject();
                            newUrlAction.formId = 0
                            newUrlAction.actionName = "Url";
                            newUrlAction.actionType = Enum.ActionType.Url;
                            newUrlAction.actionSequence = 3;//buttonObject.formAction.filter(actionObj => actionObj.isActive === true).length + 1//buttonObject.formAction.length+1;
                            newUrlAction.serviceType = Enum.Method.Get;
                            newUrlAction.actionTarget = "";
                            newUrlAction.actionTrigger = "1";
                            newUrlAction.isBizweaverService = false;
                            newUrlAction.isActive = true;
                            newUrlAction.formActionParameter = { pTaskID: "0", pStartAfter: "0", pArguments: [] };
                            this.setState({ tabNameActive: "url" })
                            if (this.isButtonOfType(true, false, false, false, false)) {
                                this.changeForm(form => form.formSection.header[this.props.activeControlIndex].formAction.push(newUrlAction))
                            } else {
                                this.changeForm(form => form.formSection.button[this.props.activeControlIndex].formAction.push(newUrlAction))
                            }
                            //this.changeForm(form => form.formSection.button[this.props.activeControlIndex].formAction.push(newUrlAction))

                        }
                        else {
                            let buttonObject = this.getActiveButtonObject();
                            let currentIndex = buttonObject.formAction.findIndex(actionObj => (actionObj.actionType === Enum.ActionType.Url || actionObj.actionName === "Url" || actionObj.actionName === "url") && actionObj.isBizweaverService !== true);
                            // this.changeForm(form => form.formSection.button[this.props.activeControlIndex].formAction[currentIndex].isActive = true)
                            this.handleButtonActionChangeForm(currentIndex, "isActive", true);

                            this.setState({ tabNameActive: "url" })
                        }
                    } else {
                        this.addRemoveValidationCollection("UactionTarget", false);
                        // this.addRemoveValidationCollection("UactionTargetInvalid", false);
                        let buttonObject = this.getActiveButtonObject();
                        let currentIndex = buttonObject.formAction.findIndex(actionObj => (actionObj.actionType === Enum.ActionType.Url || actionObj.actionName === "Url" || actionObj.actionName === "url") && actionObj.isBizweaverService !== true);
                        if (currentIndex > -1) {
                            // let actionObj = cloneDeep(this.props.formSection.button[this.props.activeControlIndex].formAction);
                            this.handleButtonActionChangeForm(currentIndex, "isActive", false);
                            if (this.props.activeFormControl.sectionType === sectionType.Header && this.props.activeFormControl.controlType === Enum.ControlType.Button) {
                                let bwIndex = buttonObject.formAction.findIndex(actionObj => actionObj.isBizweaverService === true);
                                if (bwIndex > -1 && buttonObject.formAction[bwIndex].isActive) {
                                    this.setState({ tabNameActive: "bw" })
                                } else {
                                    this.setState({ tabNameActive: "main" })
                                }
                            } else {
                                this.setState({ tabNameActive: "main" })
                            }
                            // this.changeForm(form => form.formSection.button[this.props.activeControlIndex].formAction[currentIndex].isActive = false)

                        }
                    }
                    break;
                }
            case "actionTarget-Url":
                {
                    let buttonObject = this.getActiveButtonObject();
                    let currentIndex = buttonObject.formAction.findIndex(actionObj => (actionObj.actionType === Enum.ActionType.Url || actionObj.actionName === "Url" || actionObj.actionName === "url") && actionObj.isBizweaverService !== true);
                    
                    //this.changeForm(form => form.formSection.button[this.props.activeControlIndex].formAction[currentIndex].actionTarget = value)
                    this.handleButtonActionChangeForm(currentIndex, "actionTarget", value);
                    this.handleButtonValidationObject("actionTarget-Url", value);
                    break;
                }
            case "actionTarget-Bizweaver":
                {
                    let buttonObject = this.getActiveButtonObject();
                    let currentIndex = buttonObject.formAction.findIndex(actionObj => actionObj.isBizweaverService === true && actionObj.isActive);
                    this.handleButtonActionChangeForm(currentIndex, "actionTarget", value);
                    this.handleButtonValidationObject("actionTarget-Bizweaver", value);
                    break;
                }
            case "isVisible": {
                this.handleButtonChangeForm(null, "isVisible", value);
                break;
            }
            case "keyMapped": {
                this.handleButtonChangeForm(null, "keyMapped", value);
                break;
            }
            case "initialFocus": {
                this.handleButtonChangeForm(null, "initialFocus", value);
                break;
            }
            case "caption": {
                this.handleButtonChangeForm(null, "caption", value);
                this.handleButtonValidationObject("caption", value);
                break;
            }
            case "hideSuccessMessage": {
                this.handleButtonActionChangeForm(0, "hideSuccessMessage", !value);
                break;
            }
            case "hideErrorMessage": {
                this.handleButtonActionChangeForm(0, "hideErrorMessage", !value);
                break;
            }
            case "validateErrorMessage": {
                this.handleButtonActionChangeForm(0, "validateErrorMessage", value);
                break;
            }
            case "validateSuccessMessage": {
                this.handleButtonActionChangeForm(0, "validateSuccessMessage", value);
                break;
            }

            case "BhideSuccessMessage": {
                let buttonObject = this.getActiveButtonObject();
                let currentIndex = buttonObject.formAction.findIndex(actionObj => actionObj.isBizweaverService === true);
                this.handleButtonActionChangeForm(currentIndex, "hideSuccessMessage", !value);
                break;
            }
            case "BhideErrorMessage": {
                let buttonObject = this.getActiveButtonObject();
                let currentIndex = buttonObject.formAction.findIndex(actionObj => actionObj.isBizweaverService === true);
                this.handleButtonActionChangeForm(currentIndex, "hideErrorMessage", !value);
                break;
            }
            case "BvalidateErrorMessage": {
                let buttonObject = this.getActiveButtonObject();
                let currentIndex = buttonObject.formAction.findIndex(actionObj => actionObj.isBizweaverService === true);
                this.handleButtonActionChangeForm(currentIndex, "validateErrorMessage", value);
                break;
            }
            case "BvalidateSuccessMessage": {
                let buttonObject = this.getActiveButtonObject();
                let currentIndex = buttonObject.formAction.findIndex(actionObj => actionObj.isBizweaverService === true);
                this.handleButtonActionChangeForm(currentIndex, "validateSuccessMessage", value);
                break;
            }
            case "UhideSuccessMessage": {
                let buttonObject = this.getActiveButtonObject();
                let currentIndex = buttonObject.formAction.findIndex(actionObj => (actionObj.actionType === Enum.ActionType.Url || actionObj.actionName === "Url" || actionObj.actionName === "url") && actionObj.isBizweaverService !== true);
                this.handleButtonActionChangeForm(currentIndex, "hideSuccessMessage", value);
                break;
            }
            case "UhideErrorMessage": {
                let buttonObject = this.getActiveButtonObject();
                let currentIndex = buttonObject.formAction.findIndex(actionObj => (actionObj.actionType === Enum.ActionType.Url || actionObj.actionName === "Url" || actionObj.actionName === "url") && actionObj.isBizweaverService !== true);
                this.handleButtonActionChangeForm(currentIndex, "hideErrorMessage", !value);
                break;
            }
            case "UvalidateErrorMessage": {
                let buttonObject = this.getActiveButtonObject();
                let currentIndex = buttonObject.formAction.findIndex(actionObj => (actionObj.actionType === Enum.ActionType.Url || actionObj.actionName === "Url" || actionObj.actionName === "url") && actionObj.isBizweaverService !== true);
                this.handleButtonActionChangeForm(currentIndex, "validateErrorMessage", value);
                break;
            }
            case "UvalidateSuccessMessage": {
                let buttonObject = this.getActiveButtonObject();
                let currentIndex = buttonObject.formAction.findIndex(actionObj => (actionObj.actionType === Enum.ActionType.Url || actionObj.actionName === "Url" || actionObj.actionName === "url") && actionObj.isBizweaverService !== true);
                this.handleButtonActionChangeForm(currentIndex, "validateSuccessMessage", value);
                break;
            }
            case "actionParamColletion-Url":
                {
                    let buttonObject = this.getActiveButtonObject();
                    let currentIndex = buttonObject.formAction.findIndex(actionObj => (actionObj.actionType === Enum.ActionType.Url || actionObj.actionName === "Url" || actionObj.actionName === "url") && actionObj.isBizweaverService !== true);
                    this.handleButtonActionChangeForm(currentIndex, "formActionParameter", value);
                    break;
                }
            case "actionParamColletion-Bizweaver":
                {
                    let buttonObject = this.getActiveButtonObject();
                    let currentIndex = buttonObject.formAction.findIndex(actionObj => actionObj.isBizweaverService === true);
                    this.handleButtonActionChangeForm(currentIndex, "formActionParameter", value);
                    break;
                }
            case "refreshAfterSubmit": {
                this.handleButtonActionChangeForm(0, "refreshAfterSubmit", value);
                break;
            }
            default: { }
        }


    }
    getActiveButtonActionTab = () => {
        return this.state.tabNameActive;
    }
    renderObject = (objectName) => {
        let activeControlObject = this.getActiveControlObject();
        let controlType = activeControlObject.controlType;
        let controlModeStatic = activeControlObject.comboBoxStatic;
        switch (objectName) {
            case "name":
                {
                    return true;

                }
            case "primaryField":
                {
                    switch (controlType) {
                        case Enum.ControlType.SectionTitle:
                        case Enum.ControlType.Signature:
                            {
                                return false;
                            }
                        default:
                            {
                                var isPrimary = this.getValueObject()["isPrimaryField"] && this.getValueObject()["fieldName"].indexOf("nbd") === -1 ? true : false
                                return isPrimary;
                            }
                    }
                }
            case "dataType":
                {
                    switch (controlType) {
                        case Enum.ControlType.SectionTitle:
                            {
                                return
                            }
                        default:
                            {
                                return true;
                            }
                    }
                }
            case "table":
                {
                    switch (controlType) {
                        case Enum.ControlType.SectionTitle:
                            {
                                return false;
                            }
                        default:
                            {
                                return true;
                            }
                    }
                }
            case "controlType":
                {
                    switch (controlType) {
                        case Enum.ControlType.TextBox:
                        case Enum.ControlType.NumericTextBox:
                        case Enum.ControlType.FileUpload:
                        case Enum.ControlType.MultiText1:
                        case Enum.ControlType.Button:
                        case Enum.ControlType.DatePicker:
                        case Enum.ControlType.TimePicker:
                        case Enum.ControlType.SectionTitle:
                        case Enum.ControlType.CheckBox:
                        case Enum.ControlType.Image:
                            {
                                return true;
                            }
                        default:
                            {
                                return false;
                            }
                    }
                }
            case "fieldName":
                {
                    switch (controlType) {
                        case Enum.ControlType.TextBox:
                        case Enum.ControlType.MultiText1:
                        case Enum.ControlType.NumericTextBox:
                        case Enum.ControlType.DropdownList:
                        case Enum.ControlType.DatePicker:
                        case Enum.ControlType.TimePicker:
                        case Enum.ControlType.Signature:
                        case Enum.ControlType.CheckBox:
                        case Enum.ControlType.Image:
                            {
                                return true;
                            }
                        default:
                            {
                                return false;
                            }
                    }
                }
            case "multiLine":
                {

                    switch (controlType) {
                        case Enum.ControlType.TextBox:
                        case Enum.ControlType.MultiText1:
                            {
                                return true;
                            }
                        default:
                            {
                                return false;
                            }
                    }
                }
            case "characterLength":
                {
                    switch (controlType) {
                        case Enum.ControlType.TextBox:
                        case Enum.ControlType.MultiText1:
                            {
                                return true;
                            }
                        default:
                            {
                                return false;
                            }
                    }
                }
            case "decimalPlace":
                {
                    switch (controlType) {
                        case Enum.ControlType.NumericTextBox:
                            // case Enum.ControlType.DropdownList:
                            {
                                return true;
                            }
                        default:
                            {
                                return false;
                            }
                    }
                }
            case "rowSpan":
                {
                    switch (controlType) {
                        // case Enum.ControlType.TextBox:
                        case Enum.ControlType.MultiText1:

                            {
                                return true;
                            }
                        default:
                            {
                                return false;
                            }
                    }
                }
            case "format":
                {
                    switch (controlType) {
                        //case Enum.ControlType.TextBox:
                        //case Enum.ControlType.MultiText1:
                        case Enum.ControlType.NumericTextBox:
                        //case Enum.ControlType.DropdownList:
                        case Enum.ControlType.DatePicker:
                        case Enum.ControlType.TimePicker:
                        case Enum.ControlType.Image:
                            {
                                return true;
                            }
                        default:
                            {
                                return false;
                            }
                    }
                }
            case "dateCulture":
                {
                    switch (controlType) {
                        case Enum.ControlType.DatePicker:
                            // case Enum.ControlType.TimePicker:
                            {
                                return true;
                            }
                        default:
                            {
                                return false;
                            }
                    }
                }
            case "timeCulture":
                {
                    switch (controlType) {

                        //case Enum.ControlType.DatePicker:
                        case Enum.ControlType.TimePicker:
                            {
                                return true;
                            }
                        default:
                            {
                                return false;
                            }
                    }
                }
            case "culture":
                {
                    switch (controlType) {
                        //case Enum.ControlType.TextBox:
                        //case Enum.ControlType.MultiText1:
                        case Enum.ControlType.NumericTextBox:
                            //case Enum.ControlType.DropdownList:
                            {
                                return true;
                            }
                        default:
                            {
                                return false;
                            }
                    }
                }
            case "defaultValueText":
                {
                    switch (controlType) {
                        case Enum.ControlType.TextBox:
                        case Enum.ControlType.MultiText1:
                        case Enum.ControlType.NumericTextBox:
                        case Enum.ControlType.DropdownList:
                        case Enum.ControlType.DatePicker:
                        case Enum.ControlType.TimePicker:
                            {
                                return true;
                            }
                        default:
                            {
                                return false;
                            }
                    }
                }
            case "defaultValueOption":
                {
                    switch (controlType) {
                        case Enum.ControlType.CheckBox:
                            {
                                return true;
                            }
                        default:
                            {
                                return false;
                            }
                    }
                }
            case "lookupListSource":
                {
                    switch (controlType) {
                        case Enum.ControlType.TextBox:
                            {
                                return true;
                            }
                        case Enum.ControlType.DropdownList:
                            {
                                if (!controlModeStatic) {
                                    return true;
                                } else { return false; }
                                break;
                            }
                        default:
                            {
                                return false;
                            }
                    }
                    break;
                }
            case "formulaUI":
                {
                    switch (controlType) {
                        case Enum.ControlType.NumericTextBox:
                        case Enum.ControlType.DatePicker:
                        case Enum.ControlType.TimePicker:
                            {
                                return true;
                            }
                        default:
                            {
                                return false;
                            }
                    }
                }
            case "visible":
                {
                    switch (controlType) {
                        case Enum.ControlType.TextBox:
                        case Enum.ControlType.MultiText1:
                        case Enum.ControlType.NumericTextBox:
                        case Enum.ControlType.FileUpload:
                        case Enum.ControlType.Button:
                        case Enum.ControlType.DatePicker:
                        case Enum.ControlType.TimePicker:
                        case Enum.ControlType.DropdownList:
                        case Enum.ControlType.Signature:
                        case Enum.ControlType.CheckBox:
                            {
                                return true;
                            }
                        default:
                            {
                                return false;
                            }
                    }
                }
            case "editable":
                {
                    switch (controlType) {
                        case Enum.ControlType.TextBox:
                        case Enum.ControlType.MultiText1:
                        case Enum.ControlType.NumericTextBox:
                        case Enum.ControlType.DatePicker:
                        case Enum.ControlType.TimePicker:
                        case Enum.ControlType.DropdownList:
                        case Enum.ControlType.Signature:
                        case Enum.ControlType.CheckBox:
                        case Enum.ControlType.FileUpload:
                            {
                                return true;
                            }
                        default:
                            {
                                return false;
                            }
                    }
                }
            case "required":
                {
                    switch (controlType) {
                        case Enum.ControlType.TextBox:
                        case Enum.ControlType.MultiText1:
                        case Enum.ControlType.NumericTextBox:
                        case Enum.ControlType.DatePicker:
                        case Enum.ControlType.TimePicker:
                        case Enum.ControlType.FileUpload:
                        case Enum.ControlType.DropdownList:
                        case Enum.ControlType.Signature:
                        case Enum.ControlType.CheckBox:
                            {
                                return true;
                            }
                        default:
                            {
                                return false;
                            }
                    }
                }
            case "initialFocus":
                {
                    switch (controlType) {
                        case Enum.ControlType.TextBox:
                        case Enum.ControlType.MultiText1:
                        case Enum.ControlType.NumericTextBox:
                        case Enum.ControlType.DatePicker:
                        case Enum.ControlType.TimePicker:
                        case Enum.ControlType.DropdownList:
                        case Enum.ControlType.CheckBox:
                            {
                                return this.props.activeFormControl.sectionType === Enum.FormSection.Header ? true : false;

                            }
                        default:
                            {
                                return false;
                            }
                    }
                }
            case "submitWithForm":
                {
                    switch (controlType) {
                        case Enum.ControlType.TextBox:
                        case Enum.ControlType.MultiText1:
                        case Enum.ControlType.DatePicker:
                        case Enum.ControlType.TimePicker:
                        case Enum.ControlType.DropdownList:
                        case Enum.ControlType.Signature:
                        case Enum.ControlType.NumericTextBox:
                        case Enum.ControlType.CheckBox:
                        case Enum.ControlType.Image:
                            {

                                return true;
                            }
                        default:
                            {
                                return false;
                            }
                    }
                }
            case "autoIncrement":
                {
                    //need to check if the section is in detail
                    switch (controlType) {
                        case Enum.ControlType.NumericTextBox:
                            {
                                if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                                    return true;
                                } else {
                                    return false;
                                }


                            }
                        default:
                            {
                                return false;
                            }
                    }
                }
            case "onLostFocus":
                {

                    switch (controlType) {
                        case Enum.ControlType.NumericTextBox:
                            {
                                return this.props.activeFormControl.sectionType === Enum.FormSection.Header && this.props.activeFormControl.formControl.isPrimaryField === true ? true : false;
                            }
                        default:
                            {
                                return false;
                            }
                    }
                }
            case "alignment":
                {
                    switch (controlType) {
                        case Enum.ControlType.TextBox:
                        case Enum.ControlType.MultiText1:
                        case Enum.ControlType.NumericTextBox:
                        case Enum.ControlType.DropdownList:
                        case Enum.ControlType.SectionTitle:
                        case Enum.ControlType.DatePicker:
                        case Enum.ControlType.TimePicker:
                            {
                                return true;
                            }
                        default:
                            {
                                return false;
                            }
                    }
                }
            case "caption":
                {
                    switch (controlType) {
                        case Enum.ControlType.TextBox:
                        case Enum.ControlType.MultiText1:
                        case Enum.ControlType.NumericTextBox:
                        case Enum.ControlType.FileUpload:
                        case Enum.ControlType.Button:
                        case Enum.ControlType.DropdownList:
                        case Enum.ControlType.DatePicker:
                        case Enum.ControlType.TimePicker:
                        case Enum.ControlType.SectionTitle:
                        case Enum.ControlType.Signature:
                        case Enum.ControlType.CheckBox:
                        case Enum.ControlType.Image:
                            {
                                return true;
                            }
                        default:
                            {
                                return false;
                            }
                    }
                }
            case "helpText":
                {
                    switch (controlType) {
                        case Enum.ControlType.TextBox:
                        case Enum.ControlType.MultiText1:
                        case Enum.ControlType.NumericTextBox:
                        case Enum.ControlType.DropdownList:
                        case Enum.ControlType.DatePicker:
                        case Enum.ControlType.TimePicker:
                        case Enum.ControlType.Signature:
                        case Enum.ControlType.CheckBox:
                            {
                                return true;
                            }
                        default:
                            {
                                return false;
                            }
                    }
                }
            case "textColor":
                {
                    switch (controlType) {
                        case Enum.ControlType.TextBox:
                        case Enum.ControlType.MultiText1:
                        case Enum.ControlType.NumericTextBox:
                        case Enum.ControlType.DropdownList:
                        case Enum.ControlType.DatePicker:
                        case Enum.ControlType.TimePicker:
                        case Enum.ControlType.Signature:
                        case Enum.ControlType.CheckBox:
                            {
                                return true;
                            }
                        default:
                            {
                                return false;
                            }
                    }
                }
            case "captionColor":
                {
                    switch (controlType) {
                        case Enum.ControlType.TextBox:
                        case Enum.ControlType.MultiText1:
                        case Enum.ControlType.NumericTextBox:
                        case Enum.ControlType.FileUpload:
                        case Enum.ControlType.DropdownList:
                        case Enum.ControlType.DatePicker:
                        case Enum.ControlType.TimePicker:
                        case Enum.ControlType.Signature:
                        case Enum.ControlType.SectionTitle:
                        case Enum.ControlType.CheckBox:
                        case Enum.ControlType.Image:
                            {
                                return true;
                            }
                        default:
                            {
                                return false;
                            }
                    }
                }
            case "fillColor":
                {
                    switch (controlType) {
                        case Enum.ControlType.TextBox:
                        case Enum.ControlType.MultiText1:
                        case Enum.ControlType.NumericTextBox:
                        case Enum.ControlType.DropdownList:
                        case Enum.ControlType.DatePicker:
                        case Enum.ControlType.TimePicker:
                        case Enum.ControlType.SectionTitle:
                        case Enum.ControlType.Signature:
                        case Enum.ControlType.CheckBox:
                            {
                                return true;
                            }
                        default:
                            {
                                return false;
                            }
                    }
                }
            case "actionType":
                {
                    switch (controlType) {
                        case Enum.ControlType.Button:
                            {
                                return true;
                            }
                        default:
                            {
                                return false;
                            }
                    }
                }
            case "menuItemLabel":
                {
                    switch (controlType) {
                        case Enum.ControlType.DropdownList:
                            {
                                if (!controlModeStatic) {
                                    return true;
                                } else { return false; }
                            }
                        default:
                            {
                                return false;
                            }
                    }
                }
            case "menuItemValue":
                {
                    switch (controlType) {
                        case Enum.ControlType.DropdownList:
                            {
                                if (!controlModeStatic) {
                                    return true;
                                } else { return false; }
                            }
                        default:
                            {
                                return false;
                            }
                    }
                }
            case "staticMenuSelections":
                {
                    switch (controlType) {
                        case Enum.ControlType.DropdownList:
                            {
                                if (controlModeStatic) {
                                    return true;
                                } else { return false; }
                            }
                        default:
                            {
                                return false;
                            }
                    }
                }
            case "comboboxType":
                {
                    switch (controlType) {
                        case Enum.ControlType.DropdownList:
                            {
                                return true;
                            }
                        default:
                            {
                                return false;
                            }
                    }
                }
            case "isAllowNewValue": {
                switch (controlType) {
                    case Enum.ControlType.DropdownList:
                        {
                            return true;
                        }
                    default:
                        {
                            return false;
                        }
                }
            }
            case "button-refreshaftersubmit": {
                let controlName = this.getButtonObjectValue(false, "controlName");
                if (controlName.toUpperCase() === "SAVE") { return true; }
                return false;
                break;
            }
            case "show": {
                switch (controlType) {
                    case Enum.ControlType.Button:
                        {
                            return this.props.activeFormControl.sectionType === Enum.FormSection.Header ? false : true;
                        }
                    default:
                        {
                            return true;
                        }
                }
            }
            default:
                {
                    return false;
                }
        }
    }
    disableObject = (objectName) => {
        let activeControl = this.getActiveControlObject();
        let controlType = activeControl.controlType;
        let controlFormat = activeControl.format;
        let controlDataType = activeControl.dataType;
        let controlModeStatic = activeControl.comboBoxStatic;//this.getComboBoxTypeMode(false);
        switch (objectName) {
            case "name":
                {
                    return true;

                }
            case "primaryField":
                {
                    switch (controlType) {
                        case Enum.ControlType.SectionTitle:
                        case Enum.ControlType.Signature:
                            {
                                return false;
                            }
                        default:
                            {
                                var isPrimary = this.getValueObject()["isPrimaryField"];
                                return isPrimary;
                            }
                    }
                }
            case "dataType":
                {
                    switch (controlType) {
                        case Enum.ControlType.SectionTitle:
                            {
                                return false;
                            }
                        default:
                            {
                                return true;
                            }
                    }
                }
            case "table":
                {
                    switch (controlType) {
                        case Enum.ControlType.SectionTitle:
                            {
                                return false;
                            }
                        default:
                            {
                                return true;
                            }
                    }
                }

            case "controlType":
                {
                    switch (controlType) {
                        case Enum.ControlType.TextBox:
                        case Enum.ControlType.NumericTextBox:
                        case Enum.ControlType.FileUpload:
                        case Enum.ControlType.MultiText1:
                        case Enum.ControlType.Button:
                        case Enum.ControlType.DatePicker:
                        case Enum.ControlType.TimePicker:
                        case Enum.ControlType.SectionTitle:
                        case Enum.ControlType.CheckBox:
                            {
                                return true;
                            }
                        default:
                            {
                                return false;
                            }
                    }
                }
            case "fieldName":
                {
                    switch (controlType) {
                        case Enum.ControlType.TextBox:
                        case Enum.ControlType.MultiText1:
                        case Enum.ControlType.NumericTextBox:
                        case Enum.ControlType.DropdownList:
                        case Enum.ControlType.DatePicker:
                        case Enum.ControlType.TimePicker:
                        case Enum.ControlType.Signature:
                        case Enum.ControlType.CheckBox:
                            {
                                return true;
                            }
                        default:
                            {
                                return false;
                            }
                    }
                }
            case "multiLine":
                {

                    switch (controlType) {
                        case Enum.ControlType.TextBox:
                        case Enum.ControlType.MultiText1:
                            {
                                return false;
                            }
                        default:
                            {
                                return true;
                            }
                    }
                }
            case "characterLength":
                {
                    switch (controlType) {
                        case Enum.ControlType.TextBox:
                        case Enum.ControlType.MultiText1:
                            {
                                return false;
                            }
                        default:
                            {
                                return true;
                            }
                    }
                }
            case "decimalPlace":
                {
                    switch (controlType) {
                        case Enum.ControlType.NumericTextBox:
                        case Enum.ControlType.DropdownList:
                            {
                                return false;
                            }
                        default:
                            {
                                return true;
                            }
                    }
                }
            case "rowSpan":
                {
                    switch (controlType) {
                        // case Enum.ControlType.TextBox:
                        case Enum.ControlType.MultiText1:
                        case Enum.ControlType.Signature:
                            {
                                return false;
                            }
                        default:
                            {
                                return true;
                            }
                    }
                }
            case "format":
                {
                    switch (controlType) {
                        //case Enum.ControlType.TextBox:
                        //case Enum.ControlType.MultiText1:
                        case Enum.ControlType.NumericTextBox:
                        case Enum.ControlType.DropdownList:
                        case Enum.ControlType.DatePicker:
                        case Enum.ControlType.TimePicker:
                            {
                                return false;
                            }
                        case Enum.ControlType.Image:
                            {
                                return this.props.activeFormControl.sectionType === Enum.FormSection.Header ? false : true;
                            }
                        default:
                            {
                                return true;
                            }
                    }
                }
            case "dateCulture":
                {
                    switch (controlType) {
                        case Enum.ControlType.DatePicker:
                            {
                                return false;
                            }
                        default:
                            {
                                return true;
                            }
                    }
                }
            case "timeCulture":
                {
                    switch (controlType) {

                        case Enum.ControlType.DatePicker:
                            {
                                if (controlFormat === Enum.FormatType.Date) {
                                    return true;
                                } else {
                                    return false;
                                }
                            }
                        case Enum.ControlType.TimePicker:
                            {
                                return false;
                            }
                        default:
                            {
                                return true;
                            }
                    }
                }
            case "culture":
                {
                    switch (controlType) {
                        //case Enum.ControlType.TextBox:
                        //case Enum.ControlType.MultiText1:
                        case Enum.ControlType.NumericTextBox:
                        case Enum.ControlType.DropdownList:
                            {
                                return false;
                            }
                        default:
                            {
                                return true;
                            }
                    }
                }
            case "defaultValueText":
                {
                    switch (controlType) {
                        case Enum.ControlType.TextBox:
                        case Enum.ControlType.MultiText1:
                        case Enum.ControlType.NumericTextBox:
                        case Enum.ControlType.DropdownList:
                        case Enum.ControlType.DatePicker:
                        case Enum.ControlType.TimePicker:
                        case Enum.ControlType.CheckBox:
                            {
                                return false;
                            }
                        default:
                            {
                                return true;
                            }
                    }
                }
            case "lookupListSource":
                {
                    switch (controlType) {
                        case Enum.ControlType.TextBox:
                            {
                                return false;
                            }
                        case Enum.ControlType.DropdownList:
                            {
                                if (controlModeStatic === true) {
                                    return false;
                                }
                                break;
                            }
                        default:
                            {
                                return true;
                            }
                    }
                    break;
                }
            case "formulaUI":
                {
                    switch (controlType) {
                        case Enum.ControlType.NumericTextBox:
                        case Enum.ControlType.DatePicker:
                        case Enum.ControlType.TimePicker:
                            {
                                return false;
                            }
                        default:
                            {
                                return true;
                            }
                    }
                }
            case "visible":
                {
                    switch (controlType) {
                        case Enum.ControlType.TextBox:
                        case Enum.ControlType.MultiText1:
                        case Enum.ControlType.NumericTextBox:
                        case Enum.ControlType.FileUpload:
                        case Enum.ControlType.Button:
                        case Enum.ControlType.DatePicker:
                        case Enum.ControlType.TimePicker:
                        case Enum.ControlType.DropdownList:
                        case Enum.ControlType.Signature:
                        case Enum.ControlType.CheckBox:
                            {
                                return false;
                            }
                        default:
                            {
                                return true;
                            }
                    }
                }
            case "editable":
                {
                    switch (controlType) {
                        case Enum.ControlType.TextBox:
                        case Enum.ControlType.MultiText1:
                        case Enum.ControlType.NumericTextBox:
                        case Enum.ControlType.DatePicker:
                        case Enum.ControlType.TimePicker:
                        case Enum.ControlType.DropdownList:
                        case Enum.ControlType.CheckBox:
                            {
                                return false;
                            }
                        default:
                            {
                                return true;
                            }
                    }
                }
            case "required":
                {
                    switch (controlType) {
                        case Enum.ControlType.TextBox:
                        case Enum.ControlType.MultiText1:
                        case Enum.ControlType.NumericTextBox:
                        case Enum.ControlType.DatePicker:
                        case Enum.ControlType.TimePicker:
                        case Enum.ControlType.FileUpload:
                        case Enum.ControlType.DropdownList:
                        case Enum.ControlType.Signature:
                        case Enum.ControlType.CheckBox:
                            {
                                return false;
                            }
                        default:
                            {
                                return true;
                            }
                    }
                }
            case "initialFocus":
                {
                    switch (controlType) {
                        case Enum.ControlType.TextBox:
                        case Enum.ControlType.MultiText1:
                        case Enum.ControlType.NumericTextBox:
                        case Enum.ControlType.DatePicker:
                        case Enum.ControlType.TimePicker:
                        case Enum.ControlType.DropdownList:
                        case Enum.ControlType.CheckBox:
                            {
                                return this.props.activeFormControl.sectionType === Enum.FormSection.Header ? false : true;

                            }
                        default:
                            {
                                return true;
                            }
                    }
                }
            case "submitWithForm":
                {
                    switch (controlType) {
                        case Enum.ControlType.TextBox:
                        case Enum.ControlType.MultiText1:
                        case Enum.ControlType.DatePicker:
                        case Enum.ControlType.TimePicker:
                        case Enum.ControlType.DropdownList:
                        case Enum.ControlType.Signature:
                        case Enum.ControlType.CheckBox:
                            {

                                return false;
                            }
                        default:
                            {
                                return false;
                            }
                    }
                }
            case "autoIncrement":
                {
                    //need to check if the section is in detail
                    switch (controlType) {
                        case Enum.ControlType.NumericTextBox:
                            {
                                if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                                    return false;
                                } else {
                                    return true;
                                }


                            }
                        default:
                            {
                                return true;
                            }
                    }
                }
            case "onLostFocus":
                {

                    switch (controlType) {
                        case Enum.ControlType.NumericTextBox:
                            {
                                return this.props.activeFormControl.sectionType === Enum.FormSection.Header && this.props.activeFormControl.formControl.isPrimaryField === true ? false : true;
                            }
                        default:
                            {
                                return true;
                            }
                    }
                }
            case "alignment":
                {
                    switch (controlType) {
                        case Enum.ControlType.TextBox:
                        case Enum.ControlType.MultiText1:
                        case Enum.ControlType.NumericTextBox:
                        case Enum.ControlType.DropdownList:
                        case Enum.ControlType.SectionTitle:
                        case Enum.ControlType.DatePicker:
                        case Enum.ControlType.TimePicker:
                            {
                                return false;
                            }
                        default:
                            {
                                return true;
                            }
                    }
                }
            case "caption":
                {
                    switch (controlType) {
                        case Enum.ControlType.TextBox:
                        case Enum.ControlType.MultiText1:
                        case Enum.ControlType.NumericTextBox:
                        case Enum.ControlType.FileUpload:
                        case Enum.ControlType.Button:
                        case Enum.ControlType.DropdownList:
                        case Enum.ControlType.DatePicker:
                        case Enum.ControlType.TimePicker:
                        case Enum.ControlType.SectionTitle:
                        case Enum.ControlType.Signature:
                        case Enum.ControlType.CheckBox:
                        case Enum.ControlType.Image:
                            {
                                return false;
                            }
                        default:
                            {
                                return true;
                            }
                    }
                }
            case "helpText":
                {
                    switch (controlType) {
                        case Enum.ControlType.TextBox:
                        case Enum.ControlType.MultiText1:
                        case Enum.ControlType.NumericTextBox:
                        case Enum.ControlType.DropdownList:
                        case Enum.ControlType.DatePicker:
                        case Enum.ControlType.TimePicker:
                        case Enum.ControlType.Signature:
                        case Enum.ControlType.CheckBox:
                            {
                                return false;
                            }
                        default:
                            {
                                return true;
                            }
                    }
                }
            case "textColor":
                {
                    switch (controlType) {
                        case Enum.ControlType.TextBox:
                        case Enum.ControlType.MultiText1:
                        case Enum.ControlType.NumericTextBox:
                        case Enum.ControlType.DropdownList:
                        case Enum.ControlType.DatePicker:
                        case Enum.ControlType.TimePicker:
                        case Enum.ControlType.Signature:
                        case Enum.ControlType.CheckBox:
                            {
                                return false;
                            }
                        default:
                            {
                                return true;
                            }
                    }
                }
            case "captionColor":
                {
                    switch (controlType) {
                        case Enum.ControlType.TextBox:
                        case Enum.ControlType.MultiText1:
                        case Enum.ControlType.NumericTextBox:
                        case Enum.ControlType.FileUpload:
                        case Enum.ControlType.DropdownList:
                        case Enum.ControlType.DatePicker:
                        case Enum.ControlType.TimePicker:
                        case Enum.ControlType.Signature:
                        case Enum.ControlType.SectionTitle:
                        case Enum.ControlType.CheckBox:
                        case Enum.ControlType.Image:
                            {
                                return false;
                            }
                        default:
                            {
                                return true;
                            }
                    }
                }
            case "fillColor":
                {
                    switch (controlType) {
                        case Enum.ControlType.TextBox:
                        case Enum.ControlType.MultiText1:
                        case Enum.ControlType.NumericTextBox:
                        case Enum.ControlType.DropdownList:
                        case Enum.ControlType.DatePicker:
                        case Enum.ControlType.TimePicker:
                        case Enum.ControlType.SectionTitle:
                        case Enum.ControlType.Signature:
                        case Enum.ControlType.CheckBox:
                            {
                                return false;
                            }
                        default:
                            {
                                return true;
                            }
                    }
                }
            case "actionType":
                {
                    switch (controlType) {
                        case Enum.ControlType.Button:
                            {
                                return false;
                            }
                        default:
                            {
                                return true;
                            }
                    }
                }
            case "menuItemLabel":
                {
                    switch (controlType) {
                        case Enum.ControlType.DropdownList:
                            {
                                if (controlModeStatic) {
                                    // this.props.dispatch(getDataView(55));  //to get field details
                                    return true;
                                } else {
                                    return false;
                                }
                            }
                        default:
                            {
                                return true;
                            }
                    }
                }
            case "menuItemValue":
                {
                    switch (controlType) {
                        case Enum.ControlType.DropdownList:
                            {
                                if (controlModeStatic) {
                                    // getDataView(55);  //to get field details
                                    return true;
                                } else {
                                    return false;
                                }
                            }
                        default:
                            {
                                return true;
                            }
                    }
                }
            case "staticMenuSelections":
                {
                    switch (controlType) {
                        case Enum.ControlType.DropdownList:
                            {
                                if (controlModeStatic) {
                                    return false;
                                } else {
                                    return true;
                                }
                            }
                        default:
                            {
                                return true;
                            }
                    }
                }
            case "isAllowNewValue":
                {
                    switch (controlType) {
                        case Enum.ControlType.DropdownList:
                            {
                                if (controlModeStatic) {
                                    return false;
                                } else {
                                    return true;
                                }
                            }
                        default:
                            {
                                return true;
                            }
                    }
                }

            case "comboboxType":
                {
                    switch (controlType) {
                        case Enum.ControlType.DropdownList:
                            {
                                return false;
                            }
                        default:
                            {
                                return true;
                            }
                    }
                }
            default:
                {
                    return true;
                }
        }
    }
    getObjectValueWithLogicApplied = (objectName) => {
        if (objectName === "decimalPlace") {
            var precision = this.getValueObject()["precision"];
            if (precision === -1) { return "" }
            return precision;
        }
    }
    toSentenceCase = (camelCase) => {
        if (camelCase) {
            const result = camelCase.replace(/([A-Z])/g, ' $1').trim();
            return result;// result[0].toUpperCase() + result.substring(1).toLowerCase();
        }
        return camelCase;
    };
    getControlTypeDataSource = (selectedValue, isDataSource) => {
        const controlTypeSource = [];
        var controlType = this.getActiveControlObject().controlType;
        if (controlType === Enum.ListControlType["SectionTitle"]) {
            controlTypeSource.push({ key: "SectionTitle", value: Enum.ListControlType["SectionTitle"] })
        }
        else if (controlType === Enum.ListControlType["Button"]) {
            controlTypeSource.push({ key: "Button", value: Enum.ListControlType["Button"] })
        }
        else if (controlType === Enum.ListControlType["FileUpload"]) {
            controlTypeSource.push({ key: "FileUpload", value: Enum.ListControlType["FileUpload"] })
        }
        else {
            Object.keys(Enum.ListControlType).map((element) => {
                if (element !== "Button") {
                    if (Enum.ListControlType[element] !== "select")
                        controlTypeSource.push({ key: this.toSentenceCase(element), value: Enum.ListControlType[element] })
                }
            });
        }
        if (!isDataSource) {
            if (selectedValue === Enum.ControlType.MultiText1) {
                selectedValue = Enum.ControlType.TextBox;
            }
            let val = controlTypeSource.filter(paramObj => paramObj.value === selectedValue)
            if (val !== undefined && val !== null && val.length > 0)
                return val[0];
            else
                return {}
        }
        return controlTypeSource.sort((a, b) => sortName(a.value, b.value));
    }
    getControlFormatDataSource = (selectedValue, isDataSource) => {
        const controlFormatSource = [];//[{ key: "", value: "" }];
        let controlType = this.getValueObject()["controlType"];
        let dataType = this.getFieldPropsDataSource(this.getValueObject()["fieldName"])["sqlDataType"];////this.getValueObject()["dataType"];
        dataType = dataType ? dataType : "";
        switch (controlType) {
            case Enum.ControlType["NumericTextBox"]: {
                controlFormatSource.push({ key: "Decimal", value: Enum.FormatType["Decimal"] })
                controlFormatSource.push({ key: "Currency", value: Enum.FormatType["Currency"] })
                break;
            }
            case Enum.ControlType["DatePicker"]: {
                if ((dataType.toUpperCase() === Enum.FormatType["Date"].toUpperCase())) {
                    controlFormatSource.push({ key: "Date", value: Enum.FormatType["Date"] })
                } else if (dataType.toUpperCase() === Enum.FormatType["DateTime"].toUpperCase()) {
                    controlFormatSource.push({ key: "Date", value: Enum.FormatType["Date"] })
                    controlFormatSource.push({ key: "DateTime", value: Enum.FormatType["DateTime"] })
                } else {
                    controlFormatSource.push({ key: "Date", value: Enum.FormatType["Date"] })
                    controlFormatSource.push({ key: "DateTime", value: Enum.FormatType["DateTime"] })
                }
                break;
            }
            case Enum.ControlType["TimePicker"]: {
                controlFormatSource.push({ key: "Time", value: Enum.FormatType["Time"] })
                break;
            }
            case Enum.ControlType["Image"]: {
                if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                    if (controlFormatSource.length == 0) {
                        controlFormatSource.push({ value: Object.keys(Enum.ImageFormat)[0], key: Enum.ImageFormat.Pixel45 })
                    }

                } else {
                    Object.keys(Enum.ImageFormat).map((element) => {

                        controlFormatSource.push({ key: Enum.ImageFormat[element], value: element })


                    });
                }
                break;
            }
            default: {
                Object.keys(Enum.FormatType).map((element) => {
                    controlFormatSource.push({ key: element, value: Enum.FormatType[element] })
                });
            }
        }
        //if (controlType === Enum.ControlType["NumericTextBox"]) {
        //    controlFormatSource.push({ key: "Decimal", value: Enum.FormatType["Decimal"] })
        //    controlFormatSource.push({ key: "Currency", value: Enum.FormatType["Currency"] })
        //} else {
        //    Object.keys(Enum.FormatType).map((element) => {
        //        controlFormatSource.push({ key: element, value: Enum.FormatType[element] })
        //    });
        //}
        if (isDataSource) {
            return controlFormatSource;
        }
        if (!isDataSource) {
            let val = controlFormatSource.filter(paramObj => paramObj.value === selectedValue)
            if (val !== undefined && val !== null && val.length > 0) {
                return val[0];
            }
            else {
                if (controlFormatSource.length > 0) {
                    var frmt = controlFormatSource[1];
                    if (controlType == Enum.ControlType["TimePicker"]) {
                        frmt = controlFormatSource[0];
                    } else if (controlType == Enum.ControlType["DatePicker"] && dataType.toUpperCase() === Enum.FormatType["Date"].toUpperCase()) {
                        frmt = controlFormatSource[0];
                    }
                    return frmt;//

                } else { return {} }
            }
        }

    }
    getFieldNameDataSource = (selectedValue, isDataSource) => {
        var DataSource = [{ key: "Select", value: "select", isPrimaryKeyField: false }];
        if (this.props.activeFormControl.sectionType === Enum.FormSection.Header || this.props.activeFormControl.sectionType === Enum.FormSection.Button) {
            let prmaryFields = cloneDeep(this.props.primaryTableFields)
            this.props.formSection.header.map(function (data, idx) {
                if (selectedValue !== undefined && selectedValue !== "") {
                    if (data.controlUiId && data.fieldName !== selectedValue) {
                        prmaryFields = prmaryFields.filter(field => field.fieldName !== data.fieldName)
                    }
                }
            });
            var formSectionHeder = this.props.formSection.header;
            var sectionType = this.props.activeFormControl.sectionType;
            var controlType = this.props.activeFormControl.controlType;
            prmaryFields.map(function (data, idx) {
                if (data.fieldName !== "") {
                    if (sectionType === Enum.FormSection.Button || controlType === Enum.ControlType.Button) {
                        if (formSectionHeder.some(f => f.fieldName == data.fieldName)) {
                            DataSource.push({ key: data.fieldName, value: data.fieldName, isPrimaryKeyField: data.isPrimaryKeyField });
                        }
                    } else {
                        DataSource.push({ key: data.fieldName, value: data.fieldName, isPrimaryKeyField: data.isPrimaryKeyField });
                    }
                    // });
                }

            });
        } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
            let detailsFields = cloneDeep(this.props.detailTableFields)
            this.props.formSection.detail.formGridControl.map(function (data, idx) {
                if (selectedValue !== undefined && selectedValue !== "") {
                    if (data.controlUiId && data.fieldName !== selectedValue) {
                        detailsFields = detailsFields.filter(field => field.fieldName !== data.fieldName)
                    }
                }
            });
            detailsFields.map(function (data, idx) {
                if (data.fieldName !== "")
                    DataSource.push({ key: data.fieldName, value: data.fieldName, isPrimaryKeyField: data.isPrimaryKeyField });
            });
        }
        if (!isDataSource) {
            if (selectedValue !== undefined && selectedValue !== "") {
                let val = DataSource.filter(paramObj => paramObj.value === selectedValue)
                if (val !== undefined && val !== null && val.length > 0) {
                    return val[0];
                } else { return { key: "Select", value: "select", isPrimaryKeyField: false } }
            } else {
                return { key: "Select", value: "select", isPrimaryKeyField: false }
            }
        }
        //this.setState({ filterdFieldDataSource: DataSource }, function () { return this.state.filterdFieldDataSource })
        return DataSource;

    }
    getFieldPropsDataSource = (selectedValue) => {
        var DataSource = [];
        if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
            DataSource = this.props.headerFields;
        } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {

            DataSource = this.props.detailFields;
        }

        if (selectedValue !== undefined) {
            let val = DataSource.filter(paramObj => paramObj.fieldName === selectedValue)

            if (val !== undefined && val !== null && val.length > 0) {

                return val[0];
            }

        }

        return DataSource;
    }
    getDateCultureDataSource = (selectedValue, isDataSource) => {
        var controlDateCultureSource = [];
        controlDateCultureSource.push({ key: "MM/dd/yyyy", value: "MM/dd/yyyy" });
        controlDateCultureSource.push({ key: "dd/MM/yyyy", value: "dd/MM/yyyy" });
        controlDateCultureSource.push({ key: "dd/MMM/yyyy", value: "dd/MMM/yyyy" });
        controlDateCultureSource.push({ key: "yyyy/MM/dd", value: "yyyy/MM/dd" });
        controlDateCultureSource.push({ key: "MMM/dd/yyyy", value: "MMM/dd/yyyy" });
        if (!isDataSource) {
            let val = controlDateCultureSource.filter(paramObj => paramObj.value === selectedValue)
            if (val.length === 0 && selectedValue) {
                //MMM / dd / yyyy HH: mm tt
                let splitValue = selectedValue.split(" ");
                if (splitValue.length > 0) {
                    val = controlDateCultureSource.filter(paramObj => paramObj.value === splitValue[0]);
                }
            }
            if (val !== undefined && val !== null && val.length > 0)
                return val[0];
            else
                return { key: "MM/dd/yyyy", value: "MM/dd/yyyy" }
        }
        return controlDateCultureSource;
    }
    getTimeCultureDataSource = (selectedValue, isDataSource) => {
        var controlTimeCultureSource = [];
        controlTimeCultureSource.push({ key: "hh:mm (12 hour)", value: "hh:mm tt" });
        controlTimeCultureSource.push({ key: "HH:mm (24 hour)", value: "HH:mm tt" });
        if (!isDataSource) {
            let val = controlTimeCultureSource.filter(paramObj => paramObj.value === selectedValue)
            if (val.length === 0 && selectedValue) {
                //MMM / dd / yyyy HH: mm tt
                let splitValue = selectedValue.split(" ");
                if (splitValue.length > 0) {
                    let timeValue = splitValue[1] + " " + splitValue[2];
                    val = controlTimeCultureSource.filter(paramObj => paramObj.value === timeValue);
                }
            }
            if (val !== undefined && val !== null && val.length > 0)
                return val[0];
            else
                return { key: "hh:mm (12 hour)", value: "hh:mm tt" }
        }
        return controlTimeCultureSource;
    }
    getCultureDataSource = (selectedValue) => {

        var dataSource = [];
        dataSource.push({ key: "", value: "" });
        dataSource.push({ key: "de-Germany", value: "de-DE" });
        dataSource.push({ key: "en-Australia", value: "en-AU" });
        dataSource.push({ key: "en-Canada", value: "en-CA" });
        dataSource.push({ key: "en-NewZealand", value: "en-NZ" });
        dataSource.push({ key: "en-UK", value: "en-GB" });
        dataSource.push({ key: "en-US", value: "en-US" });
        dataSource.push({ key: "es-Argentina", value: "es-AR" });
        dataSource.push({ key: "es-Chile", value: "es-CL" });
        dataSource.push({ key: "es-Mexico", value: "es-MX" });
        dataSource.push({ key: "es-Panama", value: "es-PS" });
        dataSource.push({ key: "es-Spain", value: "es-ES" });
        dataSource.push({ key: "kok-India", value: "kok-IN" });
        dataSource.push({ key: "nl-Netherlands", value: "nl-NL" })
        dataSource.push({ key: "pt-Brazil", value: "pt-BR" });

        if (selectedValue !== "N/A") {
            let val = dataSource.filter(paramObj => paramObj.value === selectedValue)
            if (val !== undefined && val !== null && val.length > 0)
                return val[0];
            else
                return { key: "", value: "" }
        }
        return dataSource;
    }
    getComboBoxTypeDataSource = (selectedValue) => {
        var controlComboBoxType = [];
        controlComboBoxType.push({ key: "Static", value: "static" });
        controlComboBoxType.push({ key: "Dynamic", value: "dynamic" });
        if (selectedValue !== undefined) {
            let val = controlComboBoxType.filter(paramObj => paramObj.value === selectedValue)
            if (val !== undefined && val !== null && val.length > 0)
                return val[0];
        }
        return controlComboBoxType;
    }
    getComboBoxTypeMode_del = (asObject) => {
        var controlComboBoxType = [];

        controlComboBoxType.push({ key: "Static", value: "static" });
        controlComboBoxType.push({ key: "Dynamic", value: "dynamic" });
        if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
            if (this.props.formSectionHeader[this.props.activeControlIndex] !== undefined && this.props.formSectionHeader[this.props.activeControlIndex].controlType === Enum.ControlType.DropdownList && this.props.formSectionHeader[this.props.activeControlIndex].listId === 0) {
                if (asObject) {
                    if (this.state.comboboxType === Enum.ControlMode.None) {
                        return controlComboBoxType[0];
                    } else {
                        if (this.state.comboboxType === Enum.ControlMode.Dynamic) {
                            return controlComboBoxType[1];
                        } else if (this.state.comboboxType === Enum.ControlMode.Static) {
                            return controlComboBoxType[0];
                        }
                    }
                } else {
                    if (this.state.comboboxType === Enum.ControlMode.None) {
                        return Enum.ControlMode.Static;
                    } else {
                        return this.state.comboboxType
                    }
                }
            } else {
                if (asObject) {
                    if (this.state.comboboxType === Enum.ControlMode.None) {
                        return controlComboBoxType[1];
                    } else {
                        if (this.state.comboboxType === Enum.ControlMode.Dynamic) {
                            return controlComboBoxType[1];
                        } else if (this.state.comboboxType === Enum.ControlMode.Static) {
                            return controlComboBoxType[0];
                        }
                    }
                } else {
                    if (this.state.comboboxType === Enum.ControlMode.None) {
                        return Enum.ControlMode.Dynamic;
                    } else {
                        return this.state.comboboxType
                    }

                }
            }
        }
        else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
            if (this.props.formSection.detail.formGridControl[this.props.activeControlIndex].controlType ===
                Enum.ControlType.DropdownList &&
                this.props.formSection.detail.formGridControl[this.props.activeControlIndex].listId === 0) {
                if (asObject) {
                    if (this.state.comboboxType === Enum.ControlMode.None) {
                        return controlComboBoxType[0];
                    } else {
                        if (this.state.comboboxType === Enum.ControlMode.Dynamic) {
                            return controlComboBoxType[1];
                        } else if (this.state.comboboxType === Enum.ControlMode.Static) {
                            return controlComboBoxType[0];
                        }
                    }
                } else {
                    if (this.state.comboboxType === Enum.ControlMode.None) {
                        return Enum.ControlMode.Static;
                    } else {
                        return this.state.comboboxType
                    }
                }
            } else {
                if (asObject) {
                    if (this.state.comboboxType === Enum.ControlMode.None) {
                        return controlComboBoxType[1];
                    } else {
                        if (this.state.comboboxType === Enum.ControlMode.Dynamic) {
                            return controlComboBoxType[1];
                        } else if (this.state.comboboxType === Enum.ControlMode.Static) {
                            return controlComboBoxType[0];
                        }
                    }
                } else {
                    if (this.state.comboboxType === Enum.ControlMode.None) {
                        return Enum.ControlMode.Dynamic;
                    } else {
                        return this.state.comboboxType
                    }
                }
            }
        }
        return null;
    }
    getAlignmentDataSource = (selectedValue, isDataSource) => {
        var controlAlignment = [];

        controlAlignment.push({ key: "Left", value: "1" });
        controlAlignment.push({ key: "Center", value: "2" });
        controlAlignment.push({ key: "Right", value: "3" });

        if (isDataSource) {
            return controlAlignment;
        }
        if (!isDataSource) {
            if (selectedValue && selectedValue != "") {
                let val = controlAlignment.filter(paramObj => paramObj.value === selectedValue)

                if (val !== undefined && val !== null && val.length > 0)
                    return val[0];
                else
                    return { key: "", value: "" };
            } else return { key: "", value: "" };
        }

    }
    getLookUpDataSource = (selectedValue, isDataSource) => {

        var lookupListSource = [{ key: "", value: 0 }];
        let val = this.props.lookupList.filter(paramObj => paramObj.value === selectedValue)
        let reportIdExist = false;
        this.props.lookupList.map(function (data, idx) {
            if (idx === 0) { lookupListSource = [{ key: "", value: 0 }]; }
            const reportId = data["reportId"];
            if (reportId === selectedValue) {
                reportIdExist = true;
            }
            lookupListSource.push({ key: data["reportName"], value: reportId });
        });
        if (!reportIdExist && this.props.dataView.reportId === selectedValue) {
            lookupListSource.push({ key: this.props.dataView.reportName, value: this.props.dataView.reportId });
        }

        if (!isDataSource) {

            let val = lookupListSource.filter(paramObj => paramObj.value === selectedValue)
            if (val !== undefined && val !== null && val.length > 0)
                return val[0];
            else {

                return { key: "", value: 0 }
            }
        }
        return lookupListSource;
    }
    loadLookupList = () => {
        if (this.props.activeControlIndex !== "" && this.props.activeControlIndex !== undefined && this.props.activeControlIndex >= 0) {
            if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                let activeObject = this.props.formSectionHeader[this.props.activeControlIndex];
                if (activeObject.controlType === Enum.ControlType.DropdownList && activeObject.listId > 0 && this.props.dataViewFieldsFetchStatus !== actionTypes.VGO_GET_DATAVIEW_REQUEST) {
                    // if (prevProps.formSectionHeader[prevProps.activeControlIndex].listId !== activeObject.listId)
                    this.props.dispatch(getDataView(activeObject.listId));
                }

            } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                let activeObject = this.props.formSection.detail.formGridControl[this.props.activeControlIndex];
                if (activeObject.controlType === Enum.ControlType.DropdownList && activeObject.listId > 0 && this.props.dataViewFieldsFetchStatus !== actionTypes.VGO_GET_DATAVIEW_REQUEST) {
                    //if (prevProps.formSectionHeader[prevProps.activeControlIndex].listId !== activeObject.listId)
                    this.props.dispatch(getDataView(activeObject.listId));
                }
            }
        }
    }
    getComboBoxMenuItemDataSource = (selectedValue, isDataBind) => {


        if (this.props.lookupFields.length === 0) {
            this.loadLookupList()
        }
        let formControlListMappingObj = [{ key: "", value: " " }];
        this.props.lookupFields.map(function (data, idx) {
            if (idx === 0) { formControlListMappingObj = [{ key: "", value: " " }]; }
            formControlListMappingObj.push({ key: data["fieldName"], value: data["fieldName"] });
        });
        if (!isDataBind) {

            let val = formControlListMappingObj.filter(paramObj => paramObj.key === selectedValue)
            if (val !== undefined && val !== null && val.length > 0) {
                return val[0];
            } else {
                return { key: "", value: "" }
            }
        }
        return formControlListMappingObj.sort((a, b) => sortName(a.value, b.value));;
    }
    getLookupListFieldsDataSource = (selectedValue, isDataBind) => {

        var lookupFieldsSource = [{ key: "", value: "" }];
        this.props.lookupFields.map(function (data, idx) {
            if (idx === 0) { lookupFieldsSource = []; }
            lookupFieldsSource.push({ key: data["fieldName"], value: data["fieldId"] });
        });
        if (!isDataBind) {

            let val = lookupFieldsSource.filter(paramObj => paramObj.key === selectedValue)
            if (val !== undefined && val !== null && val.length > 0)
                return val[0];
            else {
                return { key: "", value: "" }
            }
        }

        return lookupFieldsSource;
    }
    getLookupListDisplayFieldsDataSource = (selectedValue) => {

        var lookupFieldsSource = [{ key: "", value: "" }];
        this.props.lookupFields.map(function (data, idx) {
            if (idx === 0) { lookupFieldsSource = []; }
            lookupFieldsSource.push({ key: data["displayFieldName"], value: data["fieldId"] });
        });
        if (selectedValue !== undefined) {

            let val = lookupFieldsSource.filter(paramObj => paramObj.key === selectedValue)
            if (val !== undefined && val !== null && val.length > 0)
                return val[0];
        }

        return lookupFieldsSource;
    }
    loadLookupFieldsComboSpinner = () => {


    }
    resetGridColumn = (isMandatory, event) => {
        this.props.addDetail(parseInt(event.target.value), this.props.activeFormControl.controlDestination, true);
    }

    getDetailEditComponent = () => {
        const column = { fieldName: 'ColumnCount', isEditable: true }
        const settings = { caption: 'Columns' };
        const dataModel = { ColumnCount: this.props.activeFormControl.columnCount };
        return (
            <Scrollbars className={this.props.classes.scrollCardInnerPannel} >
                <Grid className={`${this.props.classes.controlSettingsInnerPannel} ${this.props.classes.pannelContent}`}>
                    <Grid item xs={12} className={this.props.classes.controlSettingsTopText} >
                        <Grid container direction="row" pt={1.4} >
                            <Grid item xs={12}  >
                                <Typography variant="subtitle1" pr={4} >
                                    Detail Section</Typography>


                            </Grid></Grid>

                        <Grid container direction="row" pt={3.1} >
                            <Grid item xs={12}  >
                                <NumericText settings={settings} column={column} dataModel={dataModel} onChange={this.resetGridColumn} />


                            </Grid></Grid>
                    </Grid>
                </Grid></Scrollbars>)

    }
    getRoleComponentTableName = () => {
        let activeControlType = this.getValueObject()["controlType"];
        if (activeControlType === Enum.ControlType.FileUpload || activeControlType === Enum.ControlType.Button) {
            return "N/A"
        } else {
            if (this.getValueObject()["tableName"]) {
                return this.getValueObject()["tableName"];
            }
            else { return "N/A" }
        }
    }
    getControlRoleComponent = () => {
        if (this.props.controlObject !== undefined) {
            return (
                <Scrollbars className={this.props.classes.scrollCardInnerPannel} >
                    <Grid className={`${this.props.classes.controlSettingsInnerPannel} ${this.props.classes.pannelContent}`}>
                        <Grid item xs={12} className={this.props.classes.controlSettingsTopText} >
                            <Grid container item direction="row" >
                                {this.renderObject("fieldName") && <Grid item xs={6}  ><Typography variant="body2" className={this.props.classes.customBody2}>{this.getValueObject()["fieldName"].indexOf("nbd_") === -1 ? this.getValueObject()["fieldName"] : this.getValueObject()["controlName"]}</Typography></Grid>}
                                {this.renderObject("primaryField") && < Grid item xs={6}><Typography variant="body2" className={this.props.classes.primaryIcon} style={{ "color": "blue" }}>{this.props.activeFormControl.sectionType === Enum.FormSection.Header ? "Primary" : "Linked"}</Typography></Grid>}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={this.props.classes.controlSettingsTopText} >

                            {this.renderObject("dataType") && <Grid container item direction="row" ><Typography variant="body2" className={this.props.classes.customBody1} style={{ "paddingRight": "4px" }}>
                                DataType:</Typography><Typography variant="body2" className={this.props.classes.customBody2}>
                                    {this.getValueObject()["fieldName"] ? this.getValueObject()["fieldName"].substring(0, 3) != "nbd" ? this.getFieldPropsDataSource(this.getValueObject()["fieldName"])["sqlDataType"] : "N/A" : "N/A"}</Typography></Grid>}
                        </Grid>
                        {this.renderObject("table") && <Grid item xs={12} className={this.props.classes.controlSettingsTopText} style={{ "marginTop": "12px" }} ><Grid container item direction="row" >
                            <Typography variant="body2" className={this.props.classes.customBody1} style={{ "paddingRight": "4px" }}>Table: </Typography>
                            <Typography variant="body2" className={this.props.classes.customBody2}>{this.getRoleComponentTableName()}</Typography></Grid></Grid>}

                        <Grid item xs={12} className={this.props.classes.controlSettingsCheckBoxGroup}>
                            {this.getControlRoleList()}

                        </Grid>
                    </Grid>
                </Scrollbars>
            )
        }
        else {
            return "";
        }
    }
    getSectionControlRoleList = () => {
        if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
            return this.props.formSection.header[this.props.activeControlIndex].formControlRoles;
        } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
            return this.props.formSection.detail.formGridControl[this.props.activeControlIndex].formControlRoles;
        } else if (this.props.activeFormControl.sectionType === Enum.ControlType.Button) {
            return this.props.formSection.button[this.props.activeControlIndex].formControlRoles;
        }
    }
    getControlRoleList = () => {
        let existingRoles = this.getSectionControlRoleList();
        // existingRoles = existingRoles.length > 0 ? existingRoles : [getControlRoleObject()];
        let roleListArray = [];
        let selectedRoleIDs = [];
        existingRoles.map((object, i) => {
            selectedRoleIDs.push(object.roleId);
        });

        // let initRole = { roles: selectedRoleIDs, isEditable: true, isVisible: true }
        //roleListArray.push(this.getControlRoleListUI(initRole.roles, initRole.isEditable, initRole.isVisible, [], 0));

        let rolesdefault;
        let rolesEditableAndVisible = { roles: [], isEditable: true, isVisible: true };
        let rolesNotEditableAndVisible = { roles: [], isEditable: false, isVisible: true };
        let rolesNotEditableAndNotVisible = { roles: [], isEditable: false, isVisible: false }
        //existingRoles.forEach((args, i) => {
        //    rolesgroup1.roles.push(args);
        //})

        existingRoles.filter(r => r.isVisible == true && r.isEditable == true).forEach((args, i) => {
            rolesEditableAndVisible.roles.push(args.roleId);
        })
        // if (rolesEditableAndVisible.roles.length > 0) {
        roleListArray.push(this.getControlRoleListUI(rolesEditableAndVisible.roles, rolesEditableAndVisible.isEditable, rolesEditableAndVisible.isVisible, selectedRoleIDs, 0));
        // } else {
        //    if(!rolesdefault)
        //     rolesdefault = rolesEditableAndVisible;
        // }
        existingRoles.filter(r => r.isVisible == true && r.isEditable == false).forEach((args, i) => {
            rolesNotEditableAndVisible.roles.push(args.roleId);
        })
        // if (rolesNotEditableAndVisible.roles.length > 0) {
        roleListArray.push(this.getControlRoleListUI(rolesNotEditableAndVisible.roles, rolesNotEditableAndVisible.isEditable, rolesNotEditableAndVisible.isVisible, selectedRoleIDs, 1));
        //}
        //else {
        //  if (!rolesdefault)
        // rolesdefault = rolesNotEditableAndVisible;
        //}
        existingRoles.filter(r => r.isVisible == false && r.isEditable == false).forEach((args, i) => {
            rolesNotEditableAndNotVisible.roles.push(args.roleId);
        })
        //if (rolesNotEditableAndNotVisible.roles.length > 0) {
        roleListArray.push(this.getControlRoleListUI(rolesNotEditableAndNotVisible.roles, rolesNotEditableAndNotVisible.isEditable, rolesNotEditableAndNotVisible.isVisible, selectedRoleIDs, 2));
        //}
        // else {
        //    if (!rolesdefault)
        //   rolesdefault = rolesNotEditableAndNotVisible;
        // }
        // if (rolesdefault)
        // roleListArray.push(this.getControlRoleListUI(rolesdefault.roles, rolesdefault.isEditable, rolesdefault.isVisible, [], 3));
        return roleListArray;
    }
    getControlRolesByGroup = (index) => {
        let controlRoleList = this.getSectionControlRoleList();
        switch (index) {
            case 0: {
                return controlRoleList.filter(r => r.isVisible == true && r.isEditable == true);

                break;
            }
            case 1: {
                let control = controlRoleList.filter(r => r.isVisible == true && r.isEditable == false);
                return control;
                break;
            }
            case 2: {
                return controlRoleList.filter(r => r.isVisible == false && r.isEditable == false);
                break;
            }
            case 3: {
                // return controlRoleList.filter(r => r.isVisible == true && r.isEditable == true);
                return [];
                break;
            }
            default: {
                return [];
            }
        }
    }
    getControlRolesNotInGroup = (index) => {
        let controlRoleList = this.getSectionControlRoleList();
        switch (index) {
            case 0: {
                return controlRoleList.filter(r => !(r.isVisible === true && r.isEditable === true));
                break;
            }
            case 1: {
                let control = controlRoleList.filter(r => !(r.isVisible === true && r.isEditable === false));
                return control;
                break;
            }
            case 2: {
                return controlRoleList.filter(r => !(r.isVisible === false && r.isEditable === false));
                break;
            }
            case 3: {
                // return controlRoleList.filter(r => r.isVisible == true && r.isEditable == true);
                return [];
                break;
            }
            default: {
                return [];
            }
        }
    }
    getControlRolesByGroupByValue = (isEditable, isVisible, index) => {
        let controlRoleList = this.getSectionControlRoleList();
        let updatedContolObject = cloneDeep(controlRoleList);
        return updatedContolObject.map((role, i) => {
            if (role.isVisible && role.isEditable && index === 0) {
                role.isVisible = isVisible;
                role.isEditable = isEditable;
            } else if (role.isVisible && !role.isEditable && index === 1) {
                role.isVisible = isVisible;
                role.isEditable = isEditable;
            }
            else if (!role.isVisible && !role.isEditable && index === 2) {
                role.isVisible = isVisible;
                role.isEditable = isEditable;
            }
            return role;
        });
    }
    removeRole = (selectedRoleObject, itemIndex) => {
        let otherRoleCollection = this.getControlRolesNotInGroup(itemIndex)
        let isEditable = true;
        let isVisible = true;
        if (itemIndex === 1) {
            isEditable = false;
        } else if (itemIndex === 2) {
            isEditable = false;
            isVisible = false;
        }
        const selectedRoles = selectedRoleObject.map((role, index) => {
            let addRole = getControlRoleObject();
            addRole.roleId = role.roleId;
            addRole.controlId = this.props.activeFormControl.formControl.controlId;
            addRole.sectionId = -1;
            addRole.isEditable = isEditable;
            addRole.isVisible = isVisible;
            return addRole;
        })

        return otherRoleCollection.concat(selectedRoles);
    }
    onControlRoleChange = (event, actionType, property, selectedRoleObject, controlIndex, isEditable, isVisible) => {
        if (property === "ROLE") {
            let formControlObject = this.getSectionControlRoleList();
            if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                if (actionType === "removeOption") {
                    this.changeForm(form => {
                        let selectedRoles = this.removeRole(selectedRoleObject, controlIndex)
                        form.formSection.detail.formGridControl[this.props.activeControlIndex].formControlRoles = selectedRoles;
                    })
                }
                else {
                    let selectedRoles = cloneDeep(formControlObject);
                    selectedRoleObject.map((role, index) => {
                        if (formControlObject.filter(r => r.roleId === role.roleId).length <= 0) {
                            let addRole = getControlRoleObject();
                            addRole.roleId = role.roleId;
                            addRole.controlId = this.props.activeFormControl.formControl.controlId;
                            addRole.sectionId = -1;
                            addRole.isEditable = isEditable;
                            addRole.isVisible = isVisible;
                            selectedRoles.push(addRole);
                        }
                    })
                    this.changeForm(form => {
                        form.formSection.detail.formGridControl[this.props.activeControlIndex].formControlRoles = selectedRoles;
                    })
                }
            }
            else if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                if (actionType === "removeOption") {
                    this.changeForm(form => {
                        let selectedRoles = this.removeRole(selectedRoleObject, controlIndex)
                        form.formSection.header[this.props.activeControlIndex].formControlRoles = selectedRoles;
                    })
                }
                else {
                    let selectedRoles = cloneDeep(formControlObject);
                    selectedRoleObject.map((role, index) => {
                        if (formControlObject.filter(r => r.roleId === role.roleId).length <= 0) {
                            let addRole = getControlRoleObject();
                            addRole.roleId = role.roleId;
                            addRole.controlId = this.props.activeFormControl.formControl.controlId;
                            addRole.sectionId = -1;
                            addRole.isEditable = isEditable;
                            addRole.isVisible = isVisible;
                            selectedRoles.push(addRole);
                        }
                    })

                    this.changeForm(form => {
                        form.formSection.header[this.props.activeControlIndex].formControlRoles = selectedRoles;
                    })
                }
            }
            else if (this.props.activeFormControl.sectionType === Enum.FormSection.Button) {

                if (actionType === "removeOption") {
                    //let selectedRoles = this.remmoveRole(selectedRoleObject, isEditable, isVisible)
                    //this.changeForm(form => {
                    //    form.formSection.button[this.props.activeControlIndex].formControlRoles[Index] = selectedRoles;
                    //})
                    this.changeForm(form => {
                        let selectedRoles = this.removeRole(selectedRoleObject, controlIndex)
                        form.formSection.button[this.props.activeControlIndex].formControlRoles = selectedRoles;
                    })

                }
                else {
                    let selectedRoles = cloneDeep(formControlObject);
                    selectedRoleObject.map((role, index) => {
                        if (formControlObject.filter(r => r.roleId === role.roleId).length <= 0) {
                            let addRole = getControlRoleObject();
                            addRole.roleId = role.roleId;
                            addRole.controlId = this.props.activeFormControl.formControl.controlId;
                            addRole.sectionId = -1;
                            addRole.isEditable = isEditable;
                            addRole.isVisible = isVisible;
                            selectedRoles.push(addRole);
                        }
                    })

                    this.changeForm(form => {
                        form.formSection.button[this.props.activeControlIndex].formControlRoles = selectedRoles;
                    })

                }
            }
        }
        else if (property === "ISEDITABLE" || property === "ISVISIBLE") {
            if (!(isVisible === false && isEditable === true)) {
                let updatedCollection = this.getControlRolesByGroupByValue(isEditable, isVisible, controlIndex);
                if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                    this.changeForm(form => {
                        form.formSection.detail.formGridControl[this.props.activeControlIndex].formControlRoles = updatedCollection;
                    });
                } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                    this.changeForm(form => {
                        form.formSection.header[this.props.activeControlIndex].formControlRoles = updatedCollection
                    });
                } else if (this.props.activeFormControl.sectionType === Enum.ControlType.Button) {
                    this.changeForm(form => {
                        form.formSection.button[this.props.activeControlIndex].formControlRoles = updatedCollection;
                    });

                }
            }
        }
    }
    getselectedRoleValues = (selectedRoles) => {
        return this.props.roles.list.filter((ar) => {
            return selectedRoles.some((sr) => {
                return ar.roleId === sr;
            });
        });
    }
    getRoleList = (usedRoles) => {
        let roleDS = [];
        this.props.form.roles.forEach((role, i) => {
            let roleUsedIndex = usedRoles.findIndex(ur => ur === role.roleId);
            if (roleUsedIndex < 0) {
                roleDS.push(role)
            }
        })
        return roleDS;
    }
    getControlRoleCaption = (isEditable, isVisible) => {
        if (isEditable && isVisible) {
            return "Visible & Editable";
        } else if (!isEditable && isVisible) {
            return "Visible & Not Editable";
        }
        else if (!isVisible) {
            return "Not Visible ";
        }
    }
    getControlRoleListUI = (roles, isEditable, isVisible, usedRoles, Index) => {

        return (<Grid className={this.props.classes.roleSelectionGroup}>
            <Grid item xs={12} pb={2.375}><Typography className={this.props.classes.subtitle1}  > {this.getControlRoleCaption(isEditable, isVisible)}</Typography></Grid>
            <Grid item xs={12}>
                <Autocomplete
                    multiple
                    limitTags={4}
                    id={"formRole" & Index}
                    size="small"
                    className={this.props.classes.roleControl}
                    fullWidth={true}
                    loading={this.props.roles.requestStatus === actionTypes.VGO_GET_ROLES_REQUEST}
                    options={this.getRoleList(usedRoles)}
                    getOptionLabel={option => { if (option) { return option.roleName } }}
                    value={this.getselectedRoleValues(roles)}
                    onChange={(event, value, cause) => {
                        if (value) {
                            this.onControlRoleChange(event, cause, "ROLE", value, Index, isEditable, isVisible)
                        }
                    }}
                    // onBlur={(event, value) => { this.blurValidation('roles') }}
                    renderInput={params => (
                        <TextField {...params}

                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {this.props.roles.requestStatus === actionTypes.VGO_GET_ROLES_REQUEST && <CircularProgress color="inherit" size={20} />}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                            variant="outlined"
                            size="medium"
                            label="Roles"
                        // error={!(!this.props.errors.roles)}
                        // helperText={this.props.errors.roles ? this.props.errors.roles : ' '}
                        />
                    )}
                />
            </Grid>
            {1 === 0 && <Grid item xs={12}>
                < FormControlLabel className={this.props.classes.controlRoleSwitch}
                    control={
                        <Switch checked={isVisible} onChange={(e) => this.onControlRoleChange(e, "change", "ISVISIBLE", [], Index, isEditable, e.target.checked)} />

                    }
                    label={<Typography variant="body2" className={this.props.classes.customBody2}>Visible</Typography>} />
                {Index !== 2 && < FormControlLabel className={this.props.classes.controlRoleSwitch}
                    control={
                        <Switch checked={isEditable} onChange={(e) => this.onControlRoleChange(e, "change", "ISEDITABLE", [], Index, e.target.checked, isVisible)} />
                    }
                    label={<Typography variant="body2" className={this.props.classes.customBody2}>Editable</Typography>} />
                }
            </Grid>}
        </Grid>)
    }
    enableAlert = () => {
        let activeControl = this.getActiveControlObject();
        if (activeControl.controlType === Enum.ControlType.DropdownList) {
            return false;
        }
        return true;
    }
    comboType = () => {
        let dValue = this.getValueObject()["comboBoxStatic"];//!== true ? "dynamic" : "static"
        let listId = this.getValueObject()["listId"];
        //if (listId > 0) {
        //     //if list id not in lookuplist
        //    let listSource = this.getLookUpDataSource("",true).filter(ls => ls.value === listId && ls.key !== "");
        //    if (listSource.length <= 0) {
        //        //return 'static';
        //        if (dValue !== "static") { 
        //            if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
        //                this.changeForm(form => {
        //                    form.formSection.header[this.props.activeControlIndex].listId = 0
        //                    form.formSection.header[this.props.activeControlIndex].formControlListMapping = []
        //                    form.formSection.header[this.props.activeControlIndex].formControlListParameter = []
        //                    form.formSection.header[this.props.activeControlIndex].displayField = ""
        //                    form.formSection.header[this.props.activeControlIndex].valueField = ""
        //                    form.formSection.header[this.props.activeControlIndex].comboBoxStatic = true
        //                })
        //            } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
        //                this.changeForm(form => {
        //                    form.formSection.detail.formGridControl[this.props.activeControlIndex].listId = 0
        //                    form.formSection.detail.formGridControl[this.props.activeControlIndex].formControlListMapping = []
        //                    form.formSection.detail.formGridControl[this.props.activeControlIndex].formControlListParameter = []
        //                    form.formSection.detail.formGridControl[this.props.activeControlIndex].displayField = ""
        //                    form.formSection.detail.formGridControl[this.props.activeControlIndex].valueField = ""
        //                    form.formSection.detail.formGridControl[this.props.activeControlIndex].comboBoxStatic = true
        //                })
        //            }
        //        }
        //    }
        //}

        if (dValue === undefined) {
            if (listId > 0) {
                dValue = false
            } else { dValue = true }
        }
        if (dValue) {
            return 'static'
        } else { return 'dynamic' }
    }
    getDefaultDateCultureFromFormat = (formatType) => {
        if (formatType === Enum.FormatType.DateTime) {
            return "MM/dd/yyyy hh:mm tt"
        } else if (formatType === Enum.FormatType.Time) {
            return "MM/dd/yyyy hh:mm tt"
        } else if (formatType === Enum.FormatType.Date) {
            return "MM/dd/yyyy"
        }

    }
    getSettingsDetailsComponent = () => {

        if (this.props.controlObject !== undefined) {
            return (
                <Scrollbars className={this.props.classes.scrollCardInnerPannel} >
                    <Grid className={`${this.props.classes.controlSettingsInnerPannel} ${this.props.classes.pannelContent}`}>
                        <Grid item xs={12} className={this.props.classes.controlSettingsTopText} >
                            <Grid container item direction="row" >
                                {this.renderObject("fieldName") && <Grid item xs={6}  ><Typography variant="body2" className={this.props.classes.customBody2}>{this.getValueObject()["fieldName"].indexOf("nbd_") === -1 ? this.getValueObject()["fieldName"] : "Field not assigned"}</Typography></Grid>}
                                {this.renderObject("primaryField") && < Grid item xs={6}><Typography variant="body2" className={this.props.classes.primaryIcon} style={{ "color": "blue" }}>{this.props.activeFormControl.sectionType === Enum.FormSection.Header ? "Primary" : "Linked"}</Typography></Grid>}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={this.props.classes.controlSettingsTopText} >

                            {this.renderObject("dataType") && <Grid container item direction="row" ><Typography variant="body2" className={this.props.classes.customBody1} style={{ "paddingRight": "4px" }}>
                                DataType:</Typography><Typography variant="body2" className={this.props.classes.customBody2}>
                                    {this.getValueObject()["fieldName"] ? this.getValueObject()["fieldName"].substring(0, 3) != "nbd" ? this.getFieldPropsDataSource(this.getValueObject()["fieldName"])["sqlDataType"] : "N/A" : "N/A"}</Typography></Grid>}
                        </Grid>
                        {this.renderObject("table") && <Grid item xs={12} className={this.props.classes.controlSettingsTopText} style={{ "marginTop": "12px" }} ><Grid container item direction="row" >
                            <Typography variant="body2" className={this.props.classes.customBody1} style={{ "paddingRight": "4px" }}>Table: </Typography><Typography variant="body2" className={this.props.classes.customBody2}>
                                {this.getValueObject()["controlType"] !== Enum.ControlType.FileUpload && this.getValueObject()["controlType"] !== Enum.ControlType.Button ? this.getValueObject()["tableName"] === "" ? "N/A" : this.getValueObject()["tableName"] : "N/A"}</Typography></Grid></Grid>}

                        <Grid item xs={12} className={this.props.classes.controlSettingsCheckBoxGroup}>
                            {this.renderObject("visible") &&
                                < FormControlLabel className={this.props.classes.controlSettingCheckBoxLabel}
                                    control={
                                        < Checkbox
                                            name="visible"
                                            color="primary"
                                            disabled={!this.renderObject("visible")}
                                            checked={this.getValueObject()["isVisible"]}
                                            onChange={(e) => this.handleChangeEvent("visible", e.target.checked)}

                                        />
                                    }
                                    label={<Typography variant="body2" className={this.props.classes.customBody2}>Visible</Typography>}
                                />}
                            {this.renderObject("editable") &&
                                < FormControlLabel className={this.props.classes.controlSettingCheckBoxLabel}
                                    control={
                                        < Checkbox
                                            name="editable"
                                            color="primary"
                                            disabled={!this.renderObject("editable")}
                                            checked={this.getValueObject()["isEditable"]}
                                            // checked={controlObject["isEditable"]}
                                            onChange={(e) => this.handleChangeEvent("editable", e.target.checked)}
                                        />
                                    }
                                    label={<Typography variant="body2" className={this.props.classes.customBody2}>Editable</Typography>}
                                />}
                            {this.renderObject("required") &&
                                < FormControlLabel className={this.props.classes.controlSettingCheckBoxLabel}
                                    control={
                                        < Checkbox
                                            name="required"
                                            color="primary"
                                            disabled={!this.renderObject("required")}
                                            checked={this.getValueObject()["isMandatory"]}
                                            //checked={controlObject["isMandatory"]}
                                            onChange={(e) => this.handleChangeEvent("required", e.target.checked)}
                                        />
                                    }
                                    label={<Typography variant="body2" className={this.props.classes.customBody2}>Required</Typography>}
                                />}
                            {this.renderObject("initialFocus") &&
                                <FormControlLabel className={this.props.classes.controlSettingCheckBoxLabel2}
                                    control={
                                        <Checkbox
                                            name="initialfocus"
                                            color="primary"
                                            disabled={!this.renderObject("initialFocus")}
                                            checked={this.getValueObject()["hasFocus"]}
                                            //checked={controlObject["hasFocus"]}
                                            //onChange={(e) => this.handleChangeEvent("initialFocus", e.target.checked)}
                                            onChange={(e) => this.handleButtonSettingsChangeEvent("initialFocus", e.target.checked)}
                                            sx={{ pt: 0 }} />
                                    }
                                    label={<Typography variant="body2" className={this.props.classes.customBody2} sx={{ mt: -1 }}>Initial Focus</Typography>}
                                />}
                            {this.renderObject("submitWithForm") &&
                                <FormControlLabel className={this.props.classes.controlSettingCheckBoxLabel2}
                                    control={
                                        <Checkbox
                                            name="sumbitWithForm"
                                            color="primary"
                                            checked={this.getValueObject()["isDataBind"]}
                                            // checked={controlObject["isDataBind"]}
                                            disabled={this.disableObject("submitWithForm")}
                                            onChange={(e) => this.handleChangeEvent("submitWithForm", e.target.checked)}
                                            sx={{ pt: 0 }}
                                        />
                                    }
                                    label={<Typography variant="body2" className={this.props.classes.customBody2} sx={{ mt: -1 }}>Submit with Form</Typography>}
                                />}
                            {this.renderObject("onLostFocus") &&
                                <FormControlLabel className={this.props.classes.controlSettingCheckBoxLabel3}
                                    control={
                                        <Checkbox
                                            name="onlostfocus"
                                            color="primary"
                                            checked={this.getValueObject()["onLostFocus"]}
                                            onChange={(e) => this.handleChangeEvent("onLostFocus", e.target.checked)}
                                            sx={{ pt: 0 }}
                                        />
                                    }
                                    label={<Typography variant="body2" className={this.props.classes.customBody2} sx={{ mt: -1 }}>On lost focus load form</Typography>}
                                />}
                            {this.renderObject("autoIncrement") && <FormControlLabel className={this.props.classes.controlSettingCheckBoxLabel}
                                control={
                                    <Checkbox
                                        name="autoIncrement"
                                        color="primary"
                                        disabled={!this.renderObject("autoIncrement")}
                                        checked={this.getValueObject()["isAutoIncrement"]}
                                        onChange={(e) => this.handleChangeEvent("autoIncrement", e.target.checked)}
                                    />
                                }
                                label={<Typography variant="body2" className={this.props.classes.customBody2} >Auto Increment</Typography>}
                            />}

                        </Grid>
                        <Grid item xs={12} >
                            <Grid container item direction="row" spacing={2} justifyContent="space-between">
                                <Grid item xs={12}>
                                    < Autocomplete
                                        id={"formControlType"}
                                        size="small"
                                        disableClearable={true}
                                        fullWidth={true}
                                        required

                                        options={this.getControlTypeDataSource("", true)}
                                        getOptionLabel={(option) => option.key}
                                        getOptionSelected={(option, value) => value.value === option.value}
                                        variant="body1"
                                        className={this.props.classes.customBody1}
                                        value={this.getControlTypeDataSource(this.getValueObject()["controlType"], false)}
                                        onChange={
                                            (event, newValue) => {
                                                if (newValue)
                                                    this.handleChangeEvent("controlType", newValue.value)
                                            }
                                        }
                                        renderInput={params => (
                                            < TextField {...params}
                                                error={this.isValidObject("controlType")}
                                                id={"id"}
                                                required
                                                placeholder="Control Type"
                                                label="Control Type "
                                                value=""
                                                helperText={this.isValidObject("controlType") ? "Control Type is required" : " "}
                                            />)}
                                    />
                                </Grid>
                                {this.renderObject("fieldName") &&
                                    <Grid item xs={12}>
                                        {this.renderObject("fieldName") &&
                                            < Autocomplete
                                                id={"fieldName"}
                                                size="small"
                                                //disabled={!this.renderObject("fieldName")}
                                                //disableClearable={true}
                                                fullWidth={true}
                                                options={this.getFieldNameDataSource(this.getValueObject()["fieldName"], true)}
                                                getOptionLabel={(option) => option.key}
                                                getOptionSelected={(option, value) => value.value === option.value}
                                                variant="body1"
                                                className={this.props.classes.customBody1}
                                                value={this.getFieldNameDataSource(this.getValueFieldNameValue(), false)}
                                                error={this.isValidObject("fieldName")}
                                                onChange={
                                                    (event, newValue) => {
                                                        this.handleChangeEvent("fieldName", newValue ? newValue.value : "select", newValue)
                                                    }
                                                }
                                                renderInput={params => (

                                                    < TextField {...params}
                                                        id={"id"}
                                                        required={this.getValueObject()["isDataBind"] ? true : false}
                                                        placeholder="Field"
                                                        label="Field"
                                                        value=""
                                                        error={this.isValidObject("fieldName")}
                                                        helperText={this.isValidObject("fieldName") ? "Field is required" : " "}
                                                    />)}
                                            />}
                                        {this.renderObject("multiLine") &&
                                            < RadioGroup row aria-label="position" name="position" defaultValue="single" value={
                                                this.getValueObject()["controlType"] === Enum.ControlType.MultiText1 ? "multiple" : "single"}
                                                onChange={this.handleChange} className={this.props.classes.MultiTextOptions}>
                                                < FormControlLabel
                                                    value="single"
                                                    control={< Radio color="primary" />}
                                                    label={<Typography variant="body2" className={this.props.classes.customBody2}>Single Line</Typography>}
                                                    labelPlacement="end"
                                                    disabled={this.disableObject("multiLine")}
                                                />
                                                < FormControlLabel
                                                    value="multiple"
                                                    control={< Radio color="primary" />}
                                                    label={<Typography variant="body2" className={this.props.classes.customBody2}>Multi Line</Typography>}
                                                    labelPlacement="end"
                                                    disabled={this.disableObject("multiLine")}
                                                />
                                            </ RadioGroup >
                                        }
                                        {this.renderObject("isAllowNewValue") &&
                                            < RadioGroup row aria-label="position" name="position" defaultValue="menu"
                                                className={this.props.classes.ddlMeuOnlySection}
                                                value={this.getValueObject()["isAllowNewValue"] === true ? "combobox" : "menuonly"}
                                                onChange={(e) => this.handleChangeEvent("isAllowNewValue", e.target.value === "combobox" ? true : false)} >
                                                < FormControlLabel
                                                    value="menuonly"
                                                    control={< Radio color="primary" />}
                                                    label={<Typography variant="body2" className={this.props.classes.customBody2}>Menu Only</Typography>}
                                                    labelPlacement="end"
                                                    disabled={this.disableObject("isAllowNewValue")}
                                                />
                                                < FormControlLabel
                                                    value="combobox"
                                                    control={< Radio color="primary" />}
                                                    label={<Typography variant="body2" className={this.props.classes.customBody2}>Combo Box</Typography>}
                                                    labelPlacement="end"
                                                    disabled={this.disableObject("isAllowNewValue")}
                                                />
                                            </ RadioGroup >
                                        }
                                        {this.renderObject("characterLength") &&
                                            < TextField fullWidth={true} disabled={this.disableObject("characterLength")}
                                                value={
                                                    this.getValueObject()["length"]
                                                }
                                                className={this.props.classes.customBody1}
                                                onChange={(event) => {
                                                    const regex = /^[0-9\b]+$/;
                                                    if (event.target.value === '' ||
                                                        regex.test(event.target.value)) {
                                                        this.handleChangeEvent("characterLength", event.target.value)
                                                    } else { event.preventDefault() }
                                                }}
                                                placeholder="Character Length" label="Character Length" size="small"
                                                helperText={this.isValidObject("characterLength") ? "Character Length is required" : " "}
                                                error={this.isValidObject("characterLength")}
                                            >

                                            </ TextField >
                                        }
                                    </Grid>}
                                {this.renderObject("rowSpan") &&
                                    <Grid item xs={12}>
                                        <TextField id="standard-basic" fullWidth disabled={this.disableObject("rowSpan")}
                                            onChange={(event) => {
                                                const regex = /^[0-9\b]+$/;
                                                if (event.target.value === '' ||
                                                    regex.test(event.target.value)) {
                                                    this.handleChangeEvent("rowSpan", event.target.value)
                                                } else { event.preventDefault() }
                                            }}
                                            value={this.getValueObject()["rowSpan"]}
                                            className={this.props.classes.customBody1} placeholder="Row Span" label="Row Span" variant="standard"
                                        />
                                    </Grid>
                                }
                                {this.renderObject("format") &&
                                    <Grid item xs={12}>

                                        < Autocomplete
                                            id={"format"}
                                            size="small"
                                            disableClearable={true}
                                            fullWidth={true}
                                            options={this.getControlFormatDataSource("", true)}
                                            getOptionLabel={(option) => option.key}

                                            getOptionSelected={(option, value) => value.value === option.value}
                                            variant="body1"

                                            className={this.props.classes.customBody1}
                                            value={this.getControlFormatDataSource(this.getValueObject()["format"], false)}
                                            onChange={
                                                (event, newValue) => {
                                                    this.handleChangeEvent("format", newValue ? newValue.value : "")
                                                }
                                            }
                                            renderInput={params => (

                                                < TextField {...params}
                                                    id={"id"}
                                                    label="Format"
                                                    helperText=" "
                                                />)}
                                        />
                                    </Grid>}
                                {(this.renderObject("dateCulture") || this.renderObject("timeCulture")) &&
                                    <Grid item xs={12}>
                                        < Grid container item direction="row" xs={12} spacing={3}>
                                            <Grid item xs={6}>

                                                < Autocomplete
                                                    id={"formDateCulture"}
                                                    size="small"
                                                    disabled={this.disableObject("dateCulture")}
                                                    disableClearable={true}
                                                    fullWidth={true}
                                                    options={this.getDateCultureDataSource("", true)}
                                                    getOptionLabel={(option) => option.key}
                                                    getOptionSelected={(option, value) => value.value === option.value}
                                                    variant="body1"

                                                    className={this.props.classes.customBody1culture}
                                                    value={this.getDateCultureDataSource(this.getValueObject()["culture"], false) || this.getDefaultDateCultureFromFormat(this.getValueObject()["format"])}
                                                    onChange={
                                                        (event, newValue) => {
                                                            this.handleChangeEvent("dateCulture", newValue ? newValue.value : this.getDefaultDateCultureFromFormat(this.getValueObject()["format"]))
                                                        }
                                                    }
                                                    renderInput={params => (

                                                        < TextField {...params}
                                                            id={"id"}
                                                            label="Date Culture"
                                                            helperText=" "
                                                        />)}
                                                />
                                            </Grid>

                                            <Grid item xs={6}>
                                                {

                                                    < Autocomplete
                                                        id={"formTimeCulture"}
                                                        size="small"
                                                        disabled={this.disableObject("timeCulture")}
                                                        disableClearable={true}
                                                        fullWidth={true}
                                                        options={this.getTimeCultureDataSource("", true)}
                                                        getOptionLabel={(option) => option.key}
                                                        getOptionSelected={(option, value) => value.value === option.value}
                                                        variant="body1"
                                                        className={this.props.classes.customBody1culture}
                                                        value={this.getTimeCultureDataSource(this.getValueObject()["culture"], false) || "hh:mm:tt"}
                                                        onChange={
                                                            (event, newValue) => {
                                                                this.handleChangeEvent("timeCulture", newValue ? newValue.value : "hh:mm:tt")
                                                            }
                                                        }
                                                        renderInput={params => (

                                                            < TextField {...params}
                                                                id={"id"}
                                                                label="Time Culture"
                                                                helperText=" "
                                                            />)}
                                                    />}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                                {this.renderObject("culture") &&
                                    <Grid item xs={12}>
                                        < Autocomplete
                                            id={"formCulture"}
                                            size="small"
                                            disableClearable={true}
                                            fullWidth={true}
                                            options={this.getCultureDataSource("N/A")}
                                            getOptionLabel={(option) => option.key}
                                            getOptionSelected={(option, value) => value.value === option.value}
                                            variant="body1"
                                            className={this.props.classes.customBody1culture}
                                            value={this.getCultureDataSource(this.getValueObject()["culture"]) || ""}
                                            onChange={
                                                (event, newValue) => {
                                                    if (newValue)
                                                        this.handleChangeEvent("culture", newValue.value)
                                                }
                                            }
                                            renderInput={params => (
                                                < TextField {...params}
                                                    id={"id"}
                                                    label="Culture"
                                                    helperText=" "
                                                />)}
                                        />
                                    </Grid>}
                                {this.renderObject("decimalPlace") &&
                                    <Grid item xs={12}>
                                        <TextField fullWidth={true}
                                            disabled={!this.renderObject("decimalPlace")}
                                            value={this.getObjectValueWithLogicApplied("decimalPlace")}
                                            className={this.props.classes.customBody1Decimal}
                                            label="Decimal Place" size="small"
                                            helperText=" "
                                            onChange={(event) => {
                                                const regex = /^[0-9\b]+$/;
                                                if (event.target.value === '' || regex.test(event.target.value)) {
                                                    this.handleChangeEvent("decimalPlace", event.target.value)
                                                } else { event.preventDefault(); }
                                            }}></ TextField >
                                    </Grid>
                                }
                                {(this.renderObject("defaultValueText") || this.renderObject("comboboxType") || this.renderObject("defaultValueOption")) &&
                                    <Grid item xs={12}>
                                        <Typography variant="body1" className={this.props.classes.controlSettingsSubTitle2} >Contents</Typography>
                                        <Grid item xs={12}>
                                            {this.renderObject("comboboxType") &&
                                                < RadioGroup row aria-label="position" name="position" defaultValue="static" value={this.comboType()}
                                                    onChange={(e) => this.handleChangeEvent("comboBoxStatic", e.target.value === "static" ? true : false)}  >
                                                    < FormControlLabel
                                                        value="static"
                                                        control={< Radio color="primary" />}
                                                        label={<Typography variant="body2" className={this.props.classes.customBody2}>Static</Typography>}
                                                        labelPlacement="end"
                                                    />
                                                    < FormControlLabel
                                                        value="dynamic"
                                                        control={< Radio color="primary" />}
                                                        label={<Typography variant="body2" className={this.props.classes.customBody2}>Dynamic</Typography>}
                                                        labelPlacement="end"
                                                    />
                                                </ RadioGroup >
                                            }
                                        </Grid>
                                        {this.renderObject("staticMenuSelections") &&
                                            <Grid item xs={12} mt={3}>
                                                < TextField fullWidth={true}
                                                    //disabled={this.disableObject("helpText")}
                                                    value={this.getValueObject()["staticValues"] || ''}
                                                    placeholder="Static Menu Selections(comma delimited)"
                                                    label={<Typography variant="body2" className={this.props.classes.customBody3}>Static Menu Selections(comma delimited)</Typography>}
                                                    multiline rows={3} variant="filled"
                                                    onChange={(e) => this.handleChangeEvent("staticMenuSelections", e.target.value)}
                                                    className={this.props.classes.customBody1StaticMenu}
                                                    inputProps={{ maxLength: 100 }}
                                                    helperText=" "
                                                ></ TextField >
                                            </Grid>
                                        }
                                        {this.renderObject("defaultValueText") &&
                                            <Grid item xs={12}>

                                                < TextField fullWidth={true}
                                                    disabled={this.disableObject("defaultValueText")}
                                                    value={this.getValueObject()["defaultValue"] || ""}
                                                    placeholder="Default Value" label="Default Value" onChange={(e) => this.handleChangeEvent("defaultValue", e.target.value)}
                                                    inputProps={{ maxLength: this.props.activeFormControl.sectionType === Enum.FormSection.Header ? 100 : 50 }}
                                                    className={this.props.classes.customBody1DefaultValue}
                                                    helperText=" "
                                                    InputProps={
                                                        {
                                                            endAdornment: (

                                                                < InputAdornment position="end" >

                                                                    < MoreVertIcon style={{ cursor: "pointer" }}
                                                                        onClick={
                                                                            () => this
                                                                                .handleDefaultValueDialog(true)} />

                                                                </ InputAdornment >
                                                            )
                                                        }
                                                    }
                                                ></ TextField >
                                                < Dialog className="messageDialog" maxWidth={'sm'} disableScrollLock={true}
                                                    open={
                                                        this.state.defaultValuePopup
                                                    }
                                                    aria-labelledby="customized-dialog-title" >
                                                    < DialogContent className={this.props.classes.dialogContent}>

                                                        < TextField id="filled-basic" fullWidth={true}

                                                            label="Default Value" value={
                                                                this.state.popupDefaultValue}
                                                            inputProps={{ maxLength: 100 }}
                                                            multiline rows={4} variant="filled" onChange={
                                                                (e) => this.handleChangeEvent(
                                                                    "popupDefaultValue",
                                                                    e.target.value)} />


                                                    </ DialogContent >

                                                    < DialogActions >
                                                        <Typography className={`${this.props.classes.subtitle3} ${this.props.classes.dialoginstruction}`}>100 character limit</Typography>
                                                        < Button autoFocus color="primary" onClick={
                                                            () => this.handleDefaultValueDialog(
                                                                false)}>
                                                            CANCEL
                                                        </ Button >

                                                        < Button color="primary" onClick={() => this.handleDefaultValueDialog(false, true)}>
                                                            SAVE
                                                        </ Button >

                                                    </ DialogActions >
                                                </ Dialog >
                                            </Grid>
                                        }
                                        {this.renderObject("defaultValueOption") &&
                                            < RadioGroup row aria-label="position" name="position" defaultValue="0" value={this.getValueObject()["defaultValue"] || "0"}
                                                onChange={(e) => this.handleChangeEvent("defaultValue", e.target.value)}  >
                                                < FormControlLabel
                                                    value="1"
                                                    control={< Radio color="primary" />}
                                                    label={<Typography variant="body2" className={this.props.classes.customBody2}>Checked</Typography>}
                                                    labelPlacement="end"
                                                />
                                                < FormControlLabel
                                                    value="0"
                                                    control={< Radio color="primary" />}
                                                    label={<Typography variant="body2" className={this.props.classes.customBody2}>UnChecked</Typography>}
                                                    labelPlacement="end"
                                                />

                                            </ RadioGroup >
                                        }
                                    </Grid>
                                }

                                {this.renderObject("lookupListSource") && <Grid item xs={12}>

                                    < div style={{ display: "flex" }}>
                                        < Autocomplete
                                            id={"lookupListSource"}
                                            size="small"
                                            disabled={this.disableObject("lookupListSource")}
                                            // disableClearab
                                            fullWidth={true}
                                            loading={this.loadLookupFieldsComboSpinner()}
                                            options={this.getLookUpDataSource(this.getValueObject()["listId"], true)}
                                            getOptionLabel={(option) => option.key}
                                            getOptionSelected={(option, value) => value.value === option.value}
                                            value={this.getLookUpDataSource(this.getValueObject()["listId"], false)}
                                            variant="body1"
                                            className={this.props.classes.customBody1LookUpSource}
                                            onChange={
                                                (event, newValue) => {
                                                    this.handleChangeEvent("lookupListSource", newValue ? newValue.value : 0)
                                                }
                                            }
                                            renderInput={params => (

                                                < TextField {...params}
                                                    id={"id"}
                                                    label="Lookup List Source"
                                                    value=""
                                                    error={this.isValidObject("lookupListSource")}
                                                    helperText={this.isValidObject("lookupListSource") ? "Lookup List Source is required" : " "} size="small"
                                                    InputProps={
                                                        {
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                < React.Fragment >
                                                                    {
                                                                        this.loadLookupFieldsComboSpinner() ? (

                                                                            < CircularProgress className={this.props.classes.circularProgressStyle} size={20} />
                                                                        ) : null}
                                                                    {params.InputProps.endAdornment}
                                                                </ React.Fragment >
                                                            )
                                                        }
                                                    }
                                                />)}
                                        />
                                        < MoreVertIcon style={{ cursor: "pointer" }} className={this.props.classes.customBody1LookUpSourceMore} onClick={() => this.setLookupDialog()} />

                                    </ div >
                                    {this.state.lookupValuePopup && <ControlParameterList listId={this.getValueObject()["listId"]} enableAlert={this.enableAlert()} open={this.state.lookupValuePopup} handleClose={this.setLookupDialog}
                                        dataViewFilter={this.props.dataView.reportFilter} dataViewMapping={this.props.dataView.fieldDisplay} lookupFields={this.props.dataView.fieldDisplay} />}
                                </Grid>
                                }
                                {this.renderObject("menuItemLabel") &&
                                    <Grid item xs={12}>
                                        < Autocomplete
                                            id={"menuItemLabel"}
                                            size="small"
                                            disabled={this.disableObject("menuItemLabel")}
                                            //disableClear

                                            fullWidth={true}
                                            loading={this.loadLookupFieldsComboSpinner()}
                                            options={this.getComboBoxMenuItemDataSource("", true)}
                                            getOptionLabel={(option) => option.key}
                                            getOptionSelected={(option, value) => value.value === option.value}
                                            value={this.getComboBoxMenuItemDataSource(this.getValueObject()["displayField"], false)}
                                            variant="body1"
                                            className={this.props.classes.customBody1MenuItemLabel}
                                            onChange={
                                                (event, newValue) => {
                                                    this.handleChangeEvent("menuItemLabel", newValue ? newValue.value : "")
                                                }
                                            }
                                            renderInput={params => (

                                                < TextField {...params}
                                                    id={"id"}
                                                    label="Menu Item Label"
                                                    value=""
                                                    error={this.isValidObject("menuItemLabel")}
                                                    helperText={this.isValidObject("menuItemLabel") ? "Menu Item Label is required" : " "} size="small"
                                                    InputProps={
                                                        {
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                < React.Fragment >
                                                                    {
                                                                        this.loadLookupFieldsComboSpinner() ? (

                                                                            < CircularProgress className={this.props.classes.circularProgressStyle} size={20} />
                                                                        ) : null}
                                                                    {params.InputProps.endAdornment}
                                                                </ React.Fragment >
                                                            )
                                                        }
                                                    }
                                                />)}
                                        />
                                    </Grid>
                                }
                                {this.renderObject("menuItemValue") &&
                                    <Grid item xs={12}>
                                        < Autocomplete
                                            id={"menuItemValue"}
                                            size="small"
                                            disabled={this.disableObject("menuItemValue")}
                                            //disableClearab
                                            fullWidth={true}
                                            loading={this.loadLookupFieldsComboSpinner()}
                                            options={this.getComboBoxMenuItemDataSource("", true)}
                                            getOptionLabel={(option) => option.key}
                                            getOptionSelected={(option, value) => value.value === option.value}
                                            value={this.getComboBoxMenuItemDataSource(this.getValueObject()["valueField"], false)}
                                            variant="body1"
                                            className={this.props.classes.customBody1LookUpSource}
                                            onChange={
                                                (event, newValue) => {
                                                    this.handleChangeEvent("menuItemValue", newValue ? newValue.value : "")
                                                }
                                            }
                                            renderInput={params => (

                                                < TextField {...params}
                                                    id={"id"}
                                                    label="Menu Item Value"
                                                    value=""
                                                    error={this.isValidObject("menuItemValue")}
                                                    helperText={this.isValidObject("menuItemValue") ? "Menu Item Value is required" : " "} size="small"
                                                    InputProps={
                                                        {
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                < React.Fragment >
                                                                    {
                                                                        this.loadLookupFieldsComboSpinner() ? (

                                                                            < CircularProgress className={this.props.classes.circularProgressStyle} size={20} />
                                                                        ) : null}
                                                                    {params.InputProps.endAdornment}
                                                                </ React.Fragment >
                                                            )
                                                        }
                                                    }
                                                />)}
                                        />
                                    </Grid>
                                }
                                {this.renderObject("formulaUI") &&
                                    <Grid item xs={12}>

                                        < TextField fullWidth={true}
                                            disabled={this.disableObject("formulaUI")}
                                            value={
                                                this.getValueObject()["formulaUI"]
                                            }

                                            className={this.props.classes.customBody1}
                                            helperText=" "
                                            label="Formula" size="small" onChange={(e) => this.handleChangeEvent("formulaUI", e.target.value)}
                                            InputProps={
                                                {
                                                    endAdornment: (

                                                        < InputAdornment position="end" >

                                                            < MoreVertIcon style={{ cursor: "pointer" }}
                                                                onClick={
                                                                    () => this
                                                                        .handleFormulaDialog(true)} />

                                                        </ InputAdornment >
                                                    )
                                                }
                                            }
                                        ></TextField >
                                        {this.state.formulaPopup ? <DerivedField fromDataView={false} isActionLink={false} handleClose={this.handleFormulaDialog} derivedField={false} visible={true} value={this.state.popupFormulaValue} formFieldControlType={this.getValueObject()["controlType"]} /> : ""}
                                    </Grid>
                                }
                                {(this.renderObject("alignment") || this.renderObject("caption") || this.renderObject("helpText") || this.renderObject("textColor") || this.renderObject("captionColor") || this.renderObject("fillColor")) &&
                                    <Grid item xs={12}>
                                        <Typography variant="body1" className={this.props.classes.controlSettingsSubTitleAdornment}>Adornment</Typography>
                                    </Grid>
                                }
                                {this.renderObject("alignment") &&
                                    <Grid item xs={12}>

                                        <Autocomplete
                                            id={"alignment"}
                                            size="small"
                                            disabled={this.disableObject("alignment")}
                                            // disableClearable={true}
                                            fullWidth={true}
                                            options={this.getAlignmentDataSource("", true)}
                                            getOptionLabel={(option) => option.key}
                                            variant="body1"
                                            className={this.props.classes.customBody1}
                                            getOptionSelected={(option, value) => value.value === option.value}
                                            value={this.getAlignmentDataSource(this.getValueObject()["textAlign"], false)}
                                            onChange={
                                                (event, newValue) => {
                                                    this.handleChangeEvent("alignment", newValue ? newValue.value : "")
                                                }
                                            }
                                            renderInput={params => (

                                                < TextField {...params}
                                                    id={"id"}
                                                    label="Alignment"
                                                    value=""
                                                    helperText=" "
                                                />)}
                                        />
                                    </Grid>
                                }
                                {this.renderObject("caption") &&
                                    <Grid item xs={12}>

                                        < TextField fullWidth={true}
                                            className={this.state.errorCaption ? this.props.classes.customBody1Error : this.props.classes.customBody1Caption}
                                            disabled={this.disableObject("caption")}
                                            value={this.getValueObject()["caption"] || this.getValueObject()["fieldName"] || ""}
                                            placeholder="Caption" label="Caption"
                                            required
                                            inputProps={{ maxLength: this.props.activeFormControl.sectionType === Enum.FormSection.Details ? 50 : 100 }}
                                            error={this.isValidObject("caption")}
                                            helperText={this.isValidObject("caption") ? "Caption is required" : " "} size="small"
                                            onChange={(e) => this.handleChangeEvent("caption", e.target.value ? e.target.value : this.getValueObject()["fieldName"])}
                                            helperText=" "
                                        //onBlur={(e) => this.handleValidationObject("caption",e.target.value)}
                                        >
                                        </ TextField >

                                    </Grid>
                                }
                                {this.renderObject("helpText") &&
                                    <Grid item xs={12}>
                                        < TextField fullWidth={true}
                                            disabled={this.disableObject("helpText")}
                                            value={this.getValueObject()["helpText"] || ''}
                                            placeholder="Help Text"
                                            label="Help Text"
                                            onChange={(e) => this.handleChangeEvent("helpText", e.target.value)}
                                            className={this.props.classes.customBody1}
                                            inputProps={{ maxLength: 100 }}
                                            helperText=" "
                                            InputProps={
                                                {
                                                    endAdornment: (

                                                        < InputAdornment position="end" >

                                                            < MoreVertIcon style={{ cursor: "pointer" }}
                                                                onClick={
                                                                    () => this
                                                                        .handleHelpTextPopup(true)} />

                                                        </ InputAdornment >
                                                    )
                                                }
                                            }
                                        ></ TextField >
                                        < Dialog className="messageDialog" maxWidth={'sm'} disableScrollLock={true}
                                            open={
                                                this.state.helpTextPopup
                                            }
                                            aria-labelledby="customized-dialog-title" >

                                            < DialogContent className={this.props.classes.dialogContent}>

                                                < TextField id="filled-basic" fullWidth={true}
                                                    label="Help Text" value={
                                                        this.state.popupHelpTextValue}
                                                    inputProps={{ maxLength: 100 }}
                                                    multiline rows={4} variant="filled" onChange={
                                                        (e) => this.handleChangeEvent(
                                                            "popupHelpTextValue",
                                                            e.target.value)} />


                                            </ DialogContent >

                                            < DialogActions >
                                                <Typography className={`${this.props.classes.subtitle3} ${this.props.classes.dialoginstruction}`}>100 character limit</Typography>
                                                < Button autoFocus color="primary" onClick={() => this.handleHelpTextPopup(false)}>
                                                    CANCEL
                                                </ Button >

                                                < Button color="primary" onClick={() => this.handleHelpTextPopup(false, true)}>
                                                    SAVE
                                                </ Button >

                                            </ DialogActions >

                                        </ Dialog >
                                    </Grid>
                                }
                                {(this.renderObject("textColor") || this.renderObject("captionColor") || this.renderObject("fillColor")) &&
                                    <Grid container item direction="row" spacing={1} justifyContent="space-between">
                                        {this.renderObject("textColor") &&
                                            <Grid item xs={3}>
                                                <Grid container item direction="row">
                                                    <Grid item>

                                                        < ColorPicker name='icon'
                                                            className={this.props.classes.colorChip}
                                                            disabled={this.disableObject("textColor")}


                                                            style={{ backgroundColor: this.getValueObject()["foreColor"], width: "50px", marginRight: 20 }}
                                                            value={this.getValueObject()["foreColor"] ? this.getValueObject()["foreColor"] : ""}
                                                            onChange={(color) => this.handleChangeEvent("foreColor", color)}
                                                            variant="filled"
                                                        />

                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="body1" className={this.props.classes.customBody1colorchip}>Text</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                        {this.renderObject("captionColor") &&
                                            <Grid item xs={4}>
                                                <Grid container item direction="row">
                                                    <Grid item>
                                                        < ColorPicker name='icon'
                                                            className={this.props.classes.colorChip}

                                                            disabled={this.disableObject("captionColor")}
                                                            style={{ backgroundColor: this.getValueObject()["captionColor"], width: "50Px", marginRight: 20 }}
                                                            value={this.getValueObject()["captionColor"] ? this.getValueObject()["captionColor"] : ""}
                                                            onChange={(color) => this.handleChangeEvent("captionColor", color)}
                                                            variant="filled"
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="body1" className={this.props.classes.customBody1colorchip}>Caption</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                        {this.renderObject("fillColor") &&
                                            <Grid item xs={4}>
                                                <Grid container item direction="row">
                                                    <Grid item >
                                                        < ColorPicker name='icon'
                                                            className={this.props.classes.colorChip}
                                                            disabled={this.disableObject("fillColor")}
                                                            style={{ backgroundColor: this.getValueObject()["backGroundColor"], width: "50Px", marginRight: 20 }}
                                                            value={this.getValueObject()["backGroundColor"] ? this.getValueObject()["backGroundColor"] : ""}
                                                            onChange={(color) => this.handleChangeEvent("fillColor", color)}
                                                            variant="filled"
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="body1" className={this.props.classes.customBody1colorchip}>Fill</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                        {!this.renderObject("textColor") &&
                                            <Grid item xs={3}></Grid>
                                        }
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Scrollbars>
            )
        } else {
            return "";
        }
    }
    getActiveButtonObject = () => {

        if (this.props.activeFormControl.sectionType === sectionType.Button) {
            return this.props.formSection.button[this.props.activeControlIndex];
        }
        if (this.props.activeFormControl.sectionType === sectionType.Header && this.props.activeFormControl.controlType === Enum.ControlType.Button) {
            return this.props.formSection.header[this.props.activeControlIndex];
        }
        if (this.props.activeFormControl.sectionType === sectionType.Details) {
            return this.props.formSection.header[this.props.activeControlIndex];
        }
        return null;
        //return this.props.activeFormControl.formControl;
    }
    getButtonObject = () => {

        if (this.props.activeFormControl.sectionType === sectionType.Button) {
            return this.props.formSection.button;
        }
        if (this.props.activeFormControl.sectionType === sectionType.Header && this.props.activeFormControl.controlType === Enum.ControlType.Button) {
            return this.props.formSection.header.filter(hrd => hrd.controlType === Enum.ControlType.Button);
        }
        return null;
    }
    getButtonObjectValue = (action, propName) => {

        if (this.props.activeControlIndex || this.props.activeControlIndex === 0) {
            let buttonObject = this.getActiveButtonObject();
            if (buttonObject) {
                if (action) {
                    var aObject;
                    if (propName === "Save" || propName === "save") {
                        aObject = buttonObject.formAction.filter(actionObject => actionObject.actionSequence === 1)
                    } else if (propName === "Bizweaver" || propName === "bizweaver") {
                        aObject = buttonObject.formAction.filter(actionObject => actionObject.isBizweaverService === true)

                    } else if (propName === "Url" || propName === "url") {
                        aObject = buttonObject.formAction.filter(actionObject => actionObject.actionType === "url")
                    }
                    return aObject;
                } else {
                    return buttonObject[propName];
                }
            }
        }
    }
    getButtonObjectActionValue = (property) => {
        let buttonObject = this.getActiveButtonObject()
        let actionObject = buttonObject.formAction[0];
        if (property === "actionTarget-Url") {
            actionObject = buttonObject.formAction.filter(actionObj => (actionObj.actionType === Enum.ActionType.Url || actionObj.actionName === "Url" || actionObj.actionName === "url") && actionObj.isBizweaverService !== true);
            if (actionObject.length > 0) {
                return actionObject[0]["actionTarget"];
            }
        }
        else if (property === "actionTarget-Bizweaver") {
            actionObject = buttonObject.formAction.filter(actionObject => actionObject.isBizweaverService === true)
            if (actionObject.length > 0) {
                return actionObject[0]["actionTarget"];
            }
        }
        if (actionObject !== undefined) {
            return actionObject[property];
        }
        return ""
    }
    handleTabChange = (event, newValue) => {


        let tabValue = newValue;
        let activeControlObject = this.getActiveControlObject();
        if (activeControlObject.controlType === Enum.ControlType.ClearWithGetAction) {
            if (this.state.tabNameActive === "main") {
                let hasBwService = activeControlObject.formAction.filter(faction => faction.actionName === "Bizweaver" && faction.isActive);
                let hasUrl = activeControlObject.formAction.filter(faction => faction.actionName === "Url" && faction.isActive);
                if (hasBwService) {

                    tabValue = "bw";
                    //alert("1")
                }
                else if (hasUrl) {

                    tabValue = "url";
                    // alert("2")
                }
            }

        }

        this.setState({ tabNameActive: tabValue });


    }
    getPrimaryActionName = () => {
        if (this.isButtonOfType(false, false, false, true)) {
            return "Form Save"
        } else {
            let activeControlObject = this.getActiveControlObject();
            return activeControlObject ? activeControlObject.controlName : "";// (false, "controlName");
        }
    }
    showMainButton = () => {
        if (this.props.activeFormControl.sectionType === sectionType.Header && this.props.activeFormControl.controlType === Enum.ControlType.Button) {
            return false;
        }
        if (this.getActiveControlObject().controlType === Enum.ControlType.ClearWithGetAction) { return false; }
        return true;
    }
    setActiveTabName = (prevState) => {

        let tabValue = this.state.tabNameActive;
        let activeControlObject = this.getActiveControlObject();
        if ((activeControlObject.controlType === Enum.ControlType.ClearWithGetAction || activeControlObject.controlType === Enum.ControlType.Button) && prevState.tabNameActive === tabValue) {
            let currentAction = activeControlObject.formAction.filter(faction => faction.actionType === tabValue && faction.isActive);
            if (!currentAction.length) {
                //if (prevState.tabNameActive === 'main') {
                let hasBwService = activeControlObject.formAction.filter(faction => faction.actionName === "Bizweaver" && faction.isActive);
                let hasUrl = activeControlObject.formAction.filter(faction => faction.actionName === "Url" && faction.isActive);
                if (hasBwService.length) {

                    tabValue = "bw";
                    //alert("1")
                }
                else if (hasUrl.length) {

                    tabValue = "url";
                } else {
                    tabValue = "main";
                }
                //  }
                if (this.state.tabNameActive !== tabValue) {
                    this.setState({ tabNameActive: tabValue });
                }
            }
        } else {
            //tabValue = this.props.validationResults.length > 0? this.props.validationResults[0].errorTab : this.state.tabNameActive;
            if (this.state.tabNameActive !== tabValue) {
                this.setState({ tabNameActive: tabValue });
            }
        }

    }
    disableBizWeaverTab = () => {
        let enableBiz = this.getButtonObjectValue(true, "Bizweaver");
        //let enableUrl = this.getButtonObjectValue(true, "Url");
        if (enableBiz.length > 0 && enableBiz[0].isActive) { return false; }
        return true;
    }
    disableUrlTab = () => {
        let enableUrl = this.getButtonObjectValue(true, "Url");
        if (enableUrl.length > 0 && enableUrl[0].isActive) { return false; }
        return true;
    }
    disableMainTab = () => {
        let isCustomButton = this.isButtonOfType(true, false, false, false);
        let isCancelButton = this.isButtonOfType(false, true, false, false);

        if (isCustomButton || isCancelButton)
            return true;
        return false;
    }
    //getTabWithError = (tabvalue) => {
    //    let tab = tabvalue
    //    if (!this.isValidObject("actionTarget-Url") && !this.disableUrlTab()) {
    //        tab = "url";
    //    }
    //    if ((!this.isValidObject("actionTarget-Bizweaver") || !this.isValidObject("workflowId")) &&  !this.disableMainTab()) {
    //        tab = "bw"
    //    }
    //    return tab;
    //}
    activeTabValue = () => {
        if (this.state.tabNameActive === "main") {
            if (this.state.tabNameActive === "main" && !this.disableMainTab()) {
                return "main";
            } else if (!this.disableBizWeaverTab()) {
                return "bw";
            } else if (!this.disableUrlTab()) {
                return "url";
            }
            return "main"
        } else if (this.state.tabNameActive === "bw" && !this.disableBizWeaverTab()) {
            return "bw";
        }
        else if (this.state.tabNameActive === "url" && !this.disableUrlTab()) {
            return "url";
        }

    }
    getSettingsActionButtonComponent = () => {

        if (this.props.controlObject !== undefined && this.props.activeControlIndex > -1) {
            let enableBiz = this.getButtonObjectValue(true, "Bizweaver");
            let enableUrl = this.getButtonObjectValue(true, "Url");

            return (
                <Scrollbars style={{ width: "100%", height: 694 }} >
                    <Grid className={this.props.classes.controlSettingsInnerPannel}>
                        <Grid item xs={12} className={this.props.classes.buttonSettingsExecutionOrder} >
                            <Grid container item direction="row" >
                                <Grid item xs={6}  ><Typography variant="body2" className={this.props.classes.customBody2ExecutionOrder}>Execution Order</Typography></Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={this.props.classes.controlSettingsCheckBoxGroup}>
                            <Grid container item direction="row">
                                {!this.isButtonOfType(true, false, false, false) &&
                                    <Grid item xs={12}>
                                        < FormControlLabel className={this.props.classes.controlSettingCheckBoxLabel}
                                            control={
                                                < Checkbox
                                                    name="formsave"
                                                    color="primary"
                                                    // disabled 
                                                    checked={true}
                                                //onChange={(e) => this.handleButtonSettingsChangeEvent("actionName-Save", e.target.checked)}
                                                />
                                            }
                                            label={<Typography><Typography variant="body2" className={this.props.classes.customBody2} style={{ minWidth: "67px!important", display: 'inline-block' }}>{this.getPrimaryActionName()}</Typography>{!this.isButtonOfType(false, true, false, false) && < Typography variant="body2" className={(this.isButtonOfType(false, false, true, false) ? this.props.classes.buttonSettingsExecutionOrderAction_Del : this.props.classes.buttonSettingsExecutionOrderAction_Save)}  >POST</Typography>}</Typography>}
                                        />  </Grid>
                                }<Grid item xs={12}>
                                    < FormControlLabel className={this.props.classes.controlSettingCheckBoxLabel2}
                                        control={
                                            < Checkbox
                                                name="bizweaver"
                                                color="primary"
                                                checked={enableBiz.length > 0 ? enableBiz[0].isActive : false}
                                                //disabled={this.getButtonObjectValue(true, "Url").length > 0}
                                                onChange={(e) => this.handleButtonSettingsChangeEvent("actionName-Bizweaver", e.target.checked)}
                                            />
                                        }
                                        label={<Typography><Typography variant="body2" className={this.props.classes.customBody2}
                                            style={{ display: 'inline-block' }}>Bizweaver Service</Typography><Typography variant="body2"
                                                className={this.props.classes.buttonSettingsExecutionOrderAction_Bz} >POST</Typography></Typography>}
                                    />  </Grid>  <Grid item xs={12}>
                                    < FormControlLabel className={this.props.classes.controlSettingCheckBoxLabelNoPadding}
                                        control={
                                            < Checkbox
                                                name="url"
                                                color="primary"
                                                //disabled={this.getButtonObjectValue(true, "Bizweaver").length > 0}
                                                checked={enableUrl.length > 0 ? enableUrl[0].isActive : false}
                                                onChange={(e) => this.handleButtonSettingsChangeEvent("actionName-Url", e.target.checked)}
                                            />
                                        }
                                        label={<Typography><Typography variant="body2" className={this.props.classes.customBody2} style={{ display: 'inline-block' }}>URL</Typography><Typography variant="body2" className={this.props.classes.buttonSettingsExecutionOrderAction_Url} >GET</Typography></Typography>}
                                    />   </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} >
                            <Grid container item direction="row" spacing={2.125} justifyContent="space-between">
                                <Grid item xs={12} className={this.props.classes.buttonSettingsActionTab}>
                                    <AppBar position="static" className={`${this.props.classes.tabRoot} ${this.props.classes.buttonSettingsActionTabTabStrip}`}  >
                                        <Tabs value={this.activeTabValue()} onChange={this.handleTabChange} aria-label="tabs" className={this.props.classes.buttonSettingsActionTabTabStrip}>
                                            {!this.disableMainTab() && <Tab className={`${this.props.classes.customSMTab} ${this.props.classes.buttonSettingsActionTabTabForm}`}  {...a11yProps(0)} label={this.getPrimaryActionName().toUpperCase()} value={"main"} />}
                                            <Tab disabled={this.disableBizWeaverTab()} className={`${this.props.classes.customSMTab} ${this.props.classes.buttonSettingsActionTabTabBw}`} {...a11yProps(1)} label="BIZWEAVER SERVICE" value={"bw"} />
                                            <Tab disabled={this.disableUrlTab()} className={`${this.props.classes.customSMTab} ${this.props.classes.buttonSettingsActionTabTabUrl}`}{...a11yProps(2)} label="URL" value={"url"} />
                                        </Tabs>
                                    </AppBar>
                                </Grid>
                                <Grid item xs={12}>
                                    <TabPanel value={this.activeTabValue()} index={"main"}>
                                        {(this.activeTabValue() === "main" && this.showMainButton()) && this.getFormSaveSettings()}
                                    </TabPanel>
                                    <TabPanel value={this.activeTabValue()} index={"bw"}>
                                        {this.activeTabValue() === "bw" && this.getFormBizSettings()}
                                    </TabPanel>
                                    <TabPanel value={this.activeTabValue()} disabled={this.state.tabNameActive != "url"} index={"url"}>
                                        {this.activeTabValue() === "url" && this.getFormUrlSettings()}
                                    </TabPanel>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid item xs={12} className={this.props.classes.controlSettingsSubTitleButtonSettings}>
                                <Typography variant="body2"> Button Settings</Typography>
                            </Grid>
                            {this.renderObject("show") &&
                                <Grid item xs={12}>
                                    < FormControlLabel className={this.props.classes.controlSettingCheckBoxLabel}
                                        control={
                                            < Checkbox
                                                name="show"
                                                color="primary"
                                                checked={this.getButtonObjectValue(false, "isVisible")}
                                                onChange={(e) => this.handleButtonSettingsChangeEvent("isVisible", e.target.checked)}
                                            />
                                        }
                                        label={<Typography variant="body2" className={this.props.classes.customBody2}>Show</Typography>}
                                    />
                                </Grid>}
                            <Grid item xs={12}>
                                <TextField
                                    error={this.isValidObject("caption")}
                                    helperText={this.isValidObject("caption") ? "Caption is required" : " "}
                                    id="outlined-multiline-static"
                                    label="Caption"
                                    placeholder="Caption"
                                    defaultValue="Save"
                                    inputProps={{ maxLength: 100 }}
                                    value={this.getButtonObjectValue(false, "caption")}
                                    onChange={(e) => this.handleButtonSettingsChangeEvent("caption", e.target.value)}
                                    onBlur={(e) => this.handleButtonValidationObject("caption", e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} className={this.props.classes.marginTopMapToEnter}>
                                < FormControlLabel className={this.props.classes.controlSettingCheckBoxLabel}
                                    control={
                                        < Checkbox
                                            name="maptoenterkey"
                                            color="primary"
                                            checked={!this.getButtonObjectValue(false, "keyMapped") || this.getButtonObjectValue(false, "keyMapped") === "none" ? false : true}
                                            onChange={(e) => this.handleButtonSettingsChangeEvent("keyMapped", e.target.checked)}
                                        />
                                    }
                                    label={<Typography variant="body2" className={this.props.classes.customBody2}>Map to Enter key</Typography>}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Scrollbars>
            )
        } else {
            return "";
        }
    }
    getActionTarget = (target) => {
        var actionTarget = target;//"api/FormData/FormSubmit";
        if (this.props.form.formType === 1 && target.indexOf("FormSubmit") > -1) {
            actionTarget = "api/FormData/FormSubmitHDR";
        }
        return actionTarget;
    }

    getFormSaveSettings = () => {
        return (
            <Grid container item direction="row">
                <Grid item xs={12} className={this.props.classes.buttonSettingsTopText}>
                    <Typography variant="body2"> Target</Typography>
                </Grid>

                <Grid item xs={12} className={this.props.classes.buttonSettingsTopText2}>
                    <TextField
                        id="target"
                        multiline
                        placeholder="http://............."
                        variant="filled"
                        disabled
                        InputProps={{ className: this.props.classes.buttonSettingsMultiline }}
                        value={this.getActionTarget(this.getButtonObjectActionValue("actionTarget"))}
                        onChange={(e) => this.handleButtonSettingsChangeEvent("actionTarget", e.target.value)}

                    />
                </Grid>
                {this.renderObject("button-refreshaftersubmit") &&
                    <Grid item >
                        < FormControlLabel className={this.props.classes.controlSettingCheckBoxLabel}
                            control={
                                < Checkbox
                                    name="refreshaftersubmit"
                                    color="primary"

                                    checked={this.getButtonObjectActionValue("refreshAfterSubmit")}
                                    onChange={(e) => this.handleButtonSettingsChangeEvent("refreshAfterSubmit", e.target.checked)}
                                />
                            }
                            label={<Typography variant="body2" className={this.props.classes.customBody2}>Refresh form after submit</Typography>}
                        />
                    </Grid>
                }

                <Grid item xs={12} className={this.props.classes.controlSettingsSubTitleButtonSettingsResponse}>
                    <Typography variant="body2"> Response</Typography>
                </Grid>
                <Grid item xs={12} className={this.props.classes.controlSettingsSubTitleResponse}>
                    < FormControlLabel className={this.props.classes.controlSettingCheckBoxLabel}
                        control={
                            < Checkbox
                                name="successmessage"
                                color="primary"
                                checked={!this.getButtonObjectActionValue("hideSuccessMessage")}
                                onChange={(e) => this.handleButtonSettingsChangeEvent("hideSuccessMessage", e.target.checked)}
                            />
                        }
                        label={<Typography variant="body2" className={this.props.classes.customBody2}>Success Message</Typography>}
                    />
                </Grid>
                <Grid item xs={12} className={this.props.classes.marginBottom21}>
                    <TextField
                        id="outlined-multiline-static"
                        placeholder="Success Message"
                        multiline
                        rowSpan="4"
                        InputProps={{ className: this.props.classes.buttonSettingsMultiline }}
                        defaultValue="Form saved Successfully"
                        variant="filled"
                        inputProps={{ maxLength: 300 }}
                        value={this.getButtonObjectActionValue("validateSuccessMessage")}
                        onChange={(e) => this.handleButtonSettingsChangeEvent("validateSuccessMessage", e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} className={this.props.classes.controlSettingsSubTitleResponse2}>
                    < FormControlLabel className={this.props.classes.controlSettingCheckBoxLabel}
                        control={
                            < Checkbox
                                name="failmessage"
                                color="primary"
                                checked={!this.getButtonObjectActionValue("hideErrorMessage")}
                                onChange={(e) => this.handleButtonSettingsChangeEvent("hideErrorMessage", e.target.checked)}
                            />
                        }
                        label={<Typography variant="body2" className={this.props.classes.customBody2}>Fail/Error Message</Typography>}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="outlined-multiline-static"
                        placeholder="Failure Message"
                        multiline
                        rowSpan="4"
                        InputProps={{ className: this.props.classes.buttonSettingsMultiline }}
                        defaultValue="Save Failed"
                        variant="filled"
                        inputProps={{ maxLength: 300 }}
                        value={this.getButtonObjectActionValue("validateErrorMessage")}
                        onChange={(e) => this.handleButtonSettingsChangeEvent("validateErrorMessage", e.target.value)}
                    />
                </Grid>
            </Grid>
        )
    }
    getFormUrlSettings = () => {
        return (
            <Grid container item direction="row">
                <Grid item xs={12} className={this.props.classes.buttonSettingsTopText}>
                    <Typography variant="body2"> Target</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="outlined-multiline-static"

                        multiline
                        error={this.isValidObject("UactionTarget") || this.isValidObject("UactionTargetInvalid")}
                        helperText={this.isValidObject("UactionTarget") ? "Target is required" : this.isValidObject("UactionTargetInvalid") ? "Target Url is invalid" : ""}
                        InputProps={{ className: this.props.classes.buttonSettingsMultiline }}
                        placeholder="http://............."
                        variant="filled"
                        value={this.getButtonObjectActionValue("actionTarget-Url")}
                        onChange={(e) => this.handleButtonSettingsChangeEvent("actionTarget-Url", e.target.value)}
                        onBlur={(e) =>
                            this.handleButtonValidationObject("actionTarget-Url", e.target.value)

                        }
                    />
                </Grid>
                <Grid item xs={12} className={this.props.classes.buttonSettigsParamMap}>
                    <Typography variant="body2"> Map</Typography>
                </Grid>
                <Grid item xs={12}>
                    {this.getMapParameterObjects("url")}
                </Grid>
            </Grid>)
    }
    getFormBizSettings = () => {

        return (
            <Grid container item direction="row" >
                <Grid item xs={12} className={this.props.classes.buttonSettingsTopText}>
                    <Typography variant="body2"> Target</Typography>
                </Grid>
                <Grid item xs={12} className={this.props.classes.buttonSettingsTopText2}>
                    <TextField
                        id="outlined-multiline-static"

                        multiline
                        error={this.isValidObject("BactionTarget") || this.isValidObject("BactionTargetInvalid")}
                        helperText={this.isValidObject("BactionTarget") ? "Target is required" : this.isValidObject("BactionTargetInvalid") ? "Target Url is invalid" : ""}

                        InputProps={{ className: this.props.classes.buttonSettingsMultiline }}
                        placeholder="http://............."
                        variant="filled"

                        value={this.getButtonObjectActionValue("actionTarget-Bizweaver")}
                        onChange={(e) => this.handleButtonSettingsChangeEvent("actionTarget-Bizweaver", e.target.value)}
                        onBlur={(e) => this.handleButtonValidationObject("actionTarget-Bizweaver", e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        error={this.isValidObject("BworkflowId")}
                        helperText={this.isValidObject("BworkflowId") ? "WorkFlow ID is required" : " "}
                        id="bizWorkFlowid"
                        label="Bizweaver Workflow ID"
                        placeholder="Bizweaver Workflow ID"
                        value={this.getBizWorkFlowID()}

                        onChange={(event) => {
                            const regex = /^[0-9\b]+$/;
                            if (event.target.value === '' || regex.test(event.target.value)) {

                                this.handleButtonActionParameterChange(-1, "workflowId", event.target.value, "Bizweaver")
                            } else { event.preventDefault(); }
                        }}
                        onBlur={(e) => this.handleButtonValidationObject("workflowId", e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} className={this.props.classes.buttonSettigsParamMap}>
                    <Typography variant="body2"> Map</Typography>
                </Grid>

                <Grid item xs={12}>
                    {this.getMapParameterObjects("bizweaver")}
                </Grid>
                {this.isButtonOfType(true, false, false, false) && <React.Fragment>
                    <Grid item xs={12} className={this.props.classes.controlSettingsSubTitleButtonSettingsResponse}>
                        <Typography variant="body2"> Response</Typography>
                    </Grid>
                    <Grid item xs={12} className={this.props.classes.controlSettingsSubTitleResponse}>
                        < FormControlLabel className={this.props.classes.controlSettingCheckBoxLabel}
                            control={
                                < Checkbox
                                    name="successmessage"
                                    color="primary"
                                    checked={!this.getButtonObjectActionValue("hideSuccessMessage")}
                                    onChange={(e) => this.handleButtonSettingsChangeEvent("BhideSuccessMessage", e.target.checked)}
                                />
                            }
                            label={<Typography variant="body2" className={this.props.classes.customBody2}>Success Message</Typography>}
                        />
                    </Grid>
                    <Grid item xs={12} className={this.props.classes.marginBottom21}>
                        <TextField
                            id="outlined-multiline-static"
                            placeholder="Success Message"
                            multiline
                            rowSpan="4"
                            InputProps={{ className: this.props.classes.buttonSettingsMultiline }}
                            defaultValue="Action Successful"
                            variant="filled"
                            inputProps={{ maxLength: 300 }}
                            value={this.getButtonObjectActionValue("validateSuccessMessage")}
                            onChange={(e) => this.handleButtonSettingsChangeEvent("BvalidateSuccessMessage", e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} className={this.props.classes.controlSettingsSubTitleResponse}>
                        < FormControlLabel className={this.props.classes.controlSettingCheckBoxLabel}
                            control={
                                < Checkbox
                                    name="failmessage"
                                    color="primary"
                                    checked={!this.getButtonObjectActionValue("hideErrorMessage")}
                                    onChange={(e) => this.handleButtonSettingsChangeEvent("BhideErrorMessage", e.target.checked)}
                                />
                            }
                            label={<Typography variant="body2" className={this.props.classes.customBody2}>Fail/Error Message</Typography>}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="outlined-multiline-static"
                            placeholder="Failure Message"
                            multiline
                            rowSpan="4"
                            InputProps={{ className: this.props.classes.buttonSettingsMultiline }}
                            defaultValue="Action Failed"
                            variant="filled"
                            inputProps={{ maxLength: 300 }}
                            value={this.getButtonObjectActionValue("validateErrorMessage")}
                            onChange={(e) => this.handleButtonSettingsChangeEvent("BvalidateErrorMessage", e.target.value)}
                        />
                    </Grid>
                </React.Fragment>
                }
            </Grid>)
    }
    getBizWorkFlowID = () => {
        var bizweaverObject = this.getButtonObjectValue(true, "bizweaver");
        var TaskID = "";
        if (bizweaverObject.length > 0) {
            var actionParam = bizweaverObject[0].formActionParameter;
            if (actionParam) {
                TaskID = actionParam.pTaskID;
            }
        }
        if (TaskID === "0") { TaskID = "" }
        return TaskID;
    }
    getMapParameterObjects = (ObjectType) => {
        var actionObject = this.getButtonObjectValue(true, ObjectType);
        var paramArray = [];

        if (actionObject !== undefined && actionObject.length > 0) {
            if (actionObject[0].formActionParameter) {

                if (actionObject[0].formActionParameter.pArguments !== undefined && actionObject[0].formActionParameter.pArguments != null) {
                    actionObject[0].formActionParameter.pArguments.forEach((args, i) => {
                        paramArray.push(this.getMapParameterObject(args.type, args.field, args.parameter, i, false, ObjectType));
                    })
                }
            }
            paramArray.push(this.getMapParameterObject("Dynamic", "select", "", paramArray.length, true, ObjectType));

        }
        return paramArray;
    }
    getMapParameterObject = (type, field, parameter, i, isdefault, ObjectType) => {
        return (
            <Grid className={this.props.classes.buttonSettingsparameterBlock}>
                {isdefault === false && <Grid item xs={12} className={this.props.classes.buttonSettingsparameterRemove}>
                    <RemoveIcon onClick={(e) => this.removeParam(i, ObjectType)}></RemoveIcon>
                </Grid>
                }
                <Grid item xs={12} className={this.props.classes.buttonSettingsparameterStaticCheck}>
                    < FormControlLabel className={this.props.classes.controlSettingCheckBoxLabel}
                        control={
                            < Checkbox
                                name={"staticParam" + i}
                                color="primary"
                                checked={type === "Dynamic" ? false : true}
                                onChange={(e) => this.handleButtonActionParameterChange(i, "type", e.target.checked ? "Static" : "Dynamic", ObjectType)}
                            />
                        }
                        label={<Typography variant="body2" className={this.props.classes.customBody2}>Static</Typography>}
                    />
                </Grid>
                <Grid item xs={12} className={this.props.classes.buttonSettingsparameterFields}>
                    {type === "Dynamic" && < Autocomplete
                        id={"fieldName" + i}
                        size="small"

                        fullWidth={true}
                        options={this.getFieldNameDataSource("", true)}
                        getOptionLabel={(option) => option.key}
                        getOptionSelected={(option, value) => value.value === option.value}
                        variant="body1"
                        className={this.props.classes.customBody1}
                        value={this.getFieldNameDataSource(field, false)}
                        onChange={
                            (event, newValue) => {
                                if (newValue)
                                    this.handleButtonActionParameterChange(i, "field", newValue.value, ObjectType)
                            }}
                        renderInput={params => (
                            < TextField {...params}
                                id={"id"}
                                placeholder="Field"
                                label="Field"
                                value=""
                            />)} />}
                    {type === "Static" && <TextField
                        id={"staticfield" + i}
                        label="Value"
                        value={field}
                        onChange={(e) => this.handleButtonActionParameterChange(i, "field", e.target.value, ObjectType)}
                    />}
                </Grid>
                <Grid item xs={12} className={this.props.classes.buttonSettingsparameterFields}>
                    <TextField
                        id={"paramvalue" + i}
                        label="Parameter"
                        value={parameter}
                        onChange={(e) => this.handleButtonActionParameterChange(i, "parameter", e.target.value, ObjectType)}
                    />
                </Grid>
            </Grid>
        )
    }
    removeParam = (index, ObjectType) => {
        var actionObject = this.getButtonObjectValue(true, ObjectType);

        var paramArgs;
        if (actionObject !== undefined && actionObject.length > 0) {
            activeActionParameter = undefined
            if (actionObject[0].formActionParameter) {
                if (actionObject[0].formActionParameter.pArguments) {
                    var activeActionParameter = cloneDeep(actionObject[0].formActionParameter)
                    paramArgs = cloneDeep(actionObject[0].formActionParameter.pArguments);
                    paramArgs.splice(index, 1);
                    activeActionParameter.pArguments = paramArgs;
                    if (ObjectType === "url") {
                        this.handleButtonSettingsChangeEvent("actionParamColletion-Url", activeActionParameter)
                    } else {
                        this.handleButtonSettingsChangeEvent("actionParamColletion-Bizweaver", activeActionParameter)
                    }
                }
            }
        }
    }
    handleButtonActionParameterChange = (index, property, value, ObjectType) => {
        var actionObject = this.getButtonObjectValue(true, ObjectType);
        var paramArgs;
        if (actionObject !== undefined && actionObject.length > 0) {
            activeActionParameter = undefined
            if (actionObject[0].formActionParameter) {
                if (actionObject[0].formActionParameter.pArguments) {
                    var activeActionParameter = cloneDeep(actionObject[0].formActionParameter)
                    if (property === "workflowId") {
                        activeActionParameter.pTaskID = value;
                        this.handleButtonValidationObject("workflowId", value);

                    } else {
                        paramArgs = cloneDeep(actionObject[0].formActionParameter.pArguments);
                        if (paramArgs[index] != null) {
                            paramArgs[index][property] = value;
                            if (property === "type")
                                paramArgs[index]["field"] = "";
                        } else {
                            paramArgs.push({ type: 'Dynamic', parameter: '', field: 'Select' });
                            paramArgs[index][property] = value;
                            if (property === "type")
                                paramArgs[index]["field"] = "";
                        }
                        activeActionParameter.pArguments = paramArgs;
                    }
                    if (ObjectType === "url") {
                        this.handleButtonSettingsChangeEvent("actionParamColletion-Url", activeActionParameter)
                    } else {
                        this.handleButtonSettingsChangeEvent("actionParamColletion-Bizweaver", activeActionParameter)
                    }

                }
            }
        }
    }
    handleControlClickAway = () => {
        this.initValidationObject();

    }
    handleClose = () => {
        this.initValidationObject(true);


    }
    getActiveControlObject = () => {
        if (this.props.activeControlIndex < 0) { return }
        let activeControl = this.props.activeFormControl;
        if (this.props.activeFormControl.sectionType === Enum.FormSection.Header && activeControl == Enum.ControlType.Button) {
            activeControl = this.props.activeFormControl;
        } else
            if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
                activeControl = this.props.formSection.header[this.props.activeControlIndex];
            } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                activeControl = this.props.formSection.detail.formGridControl[this.props.activeControlIndex];
            } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Button) {
                activeControl = this.props.formSection.button[this.props.activeControlIndex];
            }
        
        return activeControl;

    }
    getPropertySettingTitle = () => {

        var sectionType = this.props.activeFormControl.sectionType;
        if (this.isButtonOfType(true, false, false, false)) {
            return (
                <Grid container item direction="row">
                    <Grid item xs={12}><Typography variant="h5">BUTTON</Typography> </Grid>
                    <Grid item xs={12} className={this.props.classes.buttonSettingsTopText3}><Typography variant="body2">Form ID:{this.props.form.formId > 0 ? this.props.form.formId : "N/A"}</Typography> </Grid>
                </Grid>
            )
        }
        else if (sectionType === Enum.FormSection.Button) {
            return (
                <Grid container item direction="row">
                    <Grid item xs={12}><Typography variant="h5">{this.getButtonObjectValue(false, "controlName").replace('and', '&').toUpperCase()}</Typography> </Grid>
                    <Grid item xs={12} className={this.props.classes.buttonSettingsTopText3}><Typography variant="body2">Form ID:{this.props.form.formId > 0 ? this.props.form.formId : "N/A"}</Typography> </Grid>
                </Grid>
            )
        } else {
            return (
                <Typography variant="subtitle1">{this.props.activeFormControl.subAction !== Enum.ControlSubActions.editRole ? "Settings" : "Roles"}</Typography>
            )
        }
    }
    getPropertySettingsDetails = () => {
        if (this.props.activeFormControl !== undefined) {
            if (this.props.activeFormControl.subAction !== Enum.ControlSubActions.editRole) {
                if (this.props.activeFormControl.sectionType === sectionType.Button && this.props.activeControlIndex !== -1) {
                    return this.getSettingsActionButtonComponent();
                }
                else if (this.props.activeFormControl.sectionType === sectionType.GridEdit && this.props.activeControlIndex !== -1) {
                    return this.getDetailEditComponent();
                }
                if (this.props.activeFormControl.sectionType === sectionType.Header && this.props.activeFormControl.controlType === Enum.ControlType.Button && this.props.activeControlIndex !== -1) {
                    return this.getSettingsActionButtonComponent();
                }
                else {
                    if (this.props.activeControlIndex !== "" && this.props.activeControlIndex !== -1) {
                        return this.getSettingsDetailsComponent();
                    }
                }
            } else {
                return this.getControlRoleComponent();
            }

        }

    }
    isButtonOfType = (isCustom, isCancel, isDelete, CheckifSave) => {
        let activeControlObject = this.getActiveButtonObject();
        if (activeControlObject) {
            let controlName = activeControlObject.controlName ? activeControlObject.controlName.toUpperCase() : "";
            if (isCancel) {
                if (controlName === "CANCEL") { return true; } else { return false; }
            } else if (isDelete) {
                if (controlName === "DELETE") { return true; } else { return false; }
            }
            else if (isCustom) {
                if (this.props.activeFormControl.sectionType === sectionType.Header && this.props.activeFormControl.controlType === Enum.ControlType.Button) {
                    return true;
                } else { return false; }
            } else if (CheckifSave) {
                let textIndex = controlName.indexOf("SAVE");
                if (textIndex > -1) { return true; } else { return false; }
            }
        }
        return false;
    }
    render() {

        return (
            <ClickAwayListener onClickAway={this.handleControlClickAway} mouseEvent="onMouseDown" touchEvent="onTouchStart">
                <Card variant="outlined" className={this.props.classes.controlSettingsPannel}>
                    <Grid container >
                        <Grid item xs={12} >
                            <Grid container item direction="row" justifyContent="space-between" >

                                <Grid container item direction="row" className={this.props.classes.controlSettingsTitlePannel}>
                                    <Grid item xs={10}>
                                        <Box pt={1.9} pb={3}>
                                            {this.getPropertySettingTitle()}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Box pt={1.7} pb={2} pl={1}>
                                            <CloseIcon className={this.props.classes.closeIcon} onClick={(e) => { this.handleClose() }}></CloseIcon>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container item >
                                    {this.getPropertySettingsDetails()}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>


                </Card>
            </ClickAwayListener>
        )
    }
}

function getPrimaryFields(state) {

    if (state.forms.form.formId === 0 && state.forms.form.createNewTable) {
        let tableFields = [];
        let prmaryFields = cloneDeep(state.forms.form.primaryTableDataFields)
        for (var i = 0; i < prmaryFields.length; i++) {
            let fields = prmaryFields[i]
            if (fields.fieldName.trim() !== '') {
                fields["sqlDataType"] = getControlType(fields.dataType, fields.formatType);;
                tableFields.push(fields)
            }
        }

        return tableFields
    } else {


        return state.forms.primaryTableFields.list
    }
}

function getDeatilsFields(state) {

    if (state.forms.form.formId === 0 && state.forms.form.createNewTable) {
        let datailFields = cloneDeep(state.forms.form.detailTableDataFields)
        let tableFields = [];
        for (var i = 0; i < datailFields.length; i++) {
            let fields = datailFields[i]

            if (fields.fieldName.trim() !== '') {
                fields["sqlDataType"] = getControlType(fields.dataType, fields.formatType);
                tableFields.push(fields)
            }
        }
        return tableFields

    } else {


        let detailFields = cloneDeep(state.forms.detailTableFields.list)
        const index = state.forms.form.detailTableDataFields.findIndex(f => f.isPrimaryKeyField);
        if (index !== -1) {
            const fieldName = state.forms.form.detailTableDataFields[index].fieldName;
            detailFields[detailFields.findIndex(f => f.fieldName === fieldName)].isPrimaryKeyField = true
        }
        return detailFields;

        // return state.forms.detailTableFields.list
    }
}
function getControlType(dataType, format) {
    let controlType = 'nvarchar'
    switch (dataType) {

        case 'text':
            {
                controlType = 'nvarchar'
                break;
            }
        case 'numbers':
            {
                if (format === "decimal") {
                    controlType = "decimal"
                } else {
                    controlType = 'int'
                }
                break;
            }
        case 'date':
            {
                if (format !== "date") {
                    controlType = 'dateTime'
                } else {
                    controlType = 'date'
                }
                break;
            }
        default: { break; }
    }

    return controlType;

}

/**
 * 
 * @param {*} state 
 */
function mapStateToProps(state) {
    //console.log("cp-state", state);
    return {
        headerFields: getPrimaryFields(state),
        detailFields: getDeatilsFields(state),
        controlObject: state.forms.activeFormControl.formControl,
        activeFormControl: state.forms.activeFormControl,
        activeControlIndex: state.forms.activeFormControl.activeControlIndex,
        form: state.forms.form,
        forms: state.forms,
        formSection: state.forms.form.formSection,
        detailTableFields: state.forms.detailTableFields.list.length > 0 ? state.forms.detailTableFields.list : state.forms.form.detailTableDataFields,
        primaryTableFields: state.forms.primaryTableFields.list.length > 0 ? state.forms.primaryTableFields.list : state.forms.form.primaryTableDataFields,
        formSectionHeader: state.forms.form.formSection.header,
        dataView: state.dataViews.dataView,
        lookupFields: state.dataViews.dataView.fieldDisplay,
        dataViewFieldsFetchStatus: state.dataViews.requestStatus.getDataView,
        isLoading: state.loader.isLoading,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage,
        roles: { requestStatus: state.roles.getRoleListRequestStatus, list: state.dataViews.roles },
    };
};
export default withRouter(connect(mapStateToProps)(withStyles(formTheme)(ControlProperties)))