import React from 'react';
import Dialog from '@mui/material/Dialog';
import InputText from './Controls/InputText';
import DateTimePicker from './Controls/DateTimePicker';
import NumericText from './Controls/NumericText';
import DropDownList from './Controls/DropdownList';
import Image from './Controls/Image';
import * as Enums from '../Common/Enum';
import LookUpList from './LookUpList';
import Message from 'client/components/Common/Message';
import IconButton from "@mui/material/IconButton";
import { addDate, dateDifference, getNumericSettings, formFormatValue, isMobileDevice, isDesktopOrTabDevice  } from 'client/components/Common/Utility';
import 'client/assets/css/formStyle.css';
import './theme/style.css'
import GridLayout from '@mui/material/Grid';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import { formTheme } from 'client/components/Form/theme/formTheme';
import { ColumnDirective, ColumnsDirective, GridComponent,  Toolbar,  Inject, } from '@syncfusion/ej2-react-grids';
import { Edit } from '@syncfusion/ej2-react-grids';
import cloneDeep from 'lodash/cloneDeep';
import ColumnTemplate from 'client/components/DataView/ColumnTemplate';
import GridDropDownColumnTemplate from './Controls/GridDropDownColumnTemplate';
import CheckboxControl from 'client/components/Form/Controls/CheckBox';
import GridButton from 'client/components/Form/Controls/GridDialogButton';
import  ClickAwayListener  from '@mui/material/ClickAwayListener';
import Alert from '@mui/material/Alert';
import ErrorIcon from '@mui/icons-material/Error';
const mathExpression = require('math-expression-evaluator');

let lookupLists = [];
let dropDownList = [];
let modelDropDownValueList = [];
let defaultDropDownValueList = [];
let gridRowIndex;
class Grid extends React.Component {
    constructor(props) {
        super(props);
        this.renderingMode = (this.props.deviceType === Enums.DeviceType.Mobile ? 'Vertical' : 'Horizontal');
        this.calculationLaunchField = "";
        this.state = {
            visible: false,
            header: 'Add Item',
            dataModel: {},
            source: [],
            isEdit: false,
            //rowIndex: 0,
            lookupVisible: false,
            lookupFields: [],
            message: '',
            messageButtonStyle: '',
            messageTitle: '',
            showMessage: false,
            showAlert:false,
            selectedRows: [],
            validationSchema: cloneDeep(props.detailValidationSchema),
            clickSave: false,
            retrievedDataModel: {},
            dependentFieldMapping:[],
            currentControl: { currentControlName: "" },
        };
        this.toolbarOptions = ['Add', 'Edit', 'Delete'];
        this.editOptions = { allowEditing: true, allowAdding: true, allowDeleting: true };
    }

    componentDidMount() {
        dropDownList = [];
    }

    componentDidUpdate(prevProps, prevState) {
        if ( prevProps.primaryKeyFieldValue != this.props.primaryKeyFieldValue && this.props.buttonType !== Enums.ControlType.ActionWithClearGet ) {
                dropDownList = [];
                this.updateDropdownListOnLoadData();
        }
    }

    onAddClick = () => {
        this.clearModelDropDownValueList();
        const gridModelSettings = this.setDataModel(this.props, this.props.gridDataModel.length);
        gridRowIndex = 0;
        this.setState({ header: 'Add Item', visible: true, isEdit: false, selectedRows: [], ...gridModelSettings, retrievedDataModel: {}, dependentFieldMapping:[] });


    }

    //update grid dropDownList array while retreive data from db
    updateDropdownListOnLoadData = () => {
        let { gridDropDownList, gridDataModel } = this.props;
        const columns = this.props.dataSource[0].columns.filter(filter => (filter.fieldName && filter.isVisible)).map(column => {
            if (column.controlType === Enums.ControlType.DropdownList && column.reportId) {


                let gridDropDownListNew = gridDropDownList.map(controls => {

                    if (column.controlId === controls.FieldName) {
                        //let controlsCount = controls.FieldData.length;
                        let controlsCount = gridDataModel.length;
                        let modellist = {};
                        let gridRowList = [];
                        gridDataModel.map((model,index) => {
                            let controlId = controls.FieldName;
                            let controlList = controls.FieldData.filter(control => control.Value === model[column.fieldName]);
                            let list = [];
                            let details = {
                                controlId: controlId,
                                label: (controlList.length === 0) ? "" : controlList[0].Description,
                                value: (controlList.length === 0) ? "" : controlList[0].Value,
                            };
                            if (dropDownList.length > 0) {
                                dropDownList[index].controls.push(details);
                            } else {
                                list.push(details);
                                modellist = { controls: list };
                                if (controlsCount > 1) {
                                    gridRowList.push(modellist);
                                    modellist = {};
                                }
                            }
                        });
                        if (dropDownList.length === 0) {
                            if (controlsCount > 1)
                                dropDownList = gridRowList;
                            else {
                                if (Object.keys(modellist).length > 0)
                                    dropDownList.push(modellist);
                            }
                        }
                    }

                });
            }
        });
    }

    template = (column, data) => {

        var filterData = {};
        if (column.controlType === Enums.ControlType.DropdownList && column.reportId && column.valueField !== column.displayField) {
            return (<GridDropDownColumnTemplate data={data} column={column} dropDownList={dropDownList} />);
        }
        else {
            if (column.controlType === Enums.ControlType.NumericTextBox && column["fieldFormat"] !== Enums.FormatType.None && data[column.fieldName]) {
                const value = data[column.fieldName];
                if (typeof (value) === "string") {
                    let modifiedData = { ...data }
                    modifiedData[column.fieldName] = parseInt(value);
                    return (<ColumnTemplate data={modifiedData} column={column} dataFilter={filterData} formatValue={formFormatValue} />);
                }
            }
            return (<ColumnTemplate data={data} column={column} dataFilter={filterData} formatValue={formFormatValue} />);
        }

    }


    getColumns = () => {
        const isDesktopOrTab = isDesktopOrTabDevice(this.props.deviceType);
        const columns = this.props.dataSource[0].columns.filter(filter => (filter.fieldName && filter.isVisible)).map(column => {
            const textAlign = isDesktopOrTab ? column.textAlign : 'left';
            if (column.controlType === Enums.ControlType.Image || column.controlType === Enums.ControlType.CheckBox) {
                column["fieldFormat"] = column.controlType;

            }
            else if (column.controlType === Enums.ControlType.TextBox) {
                column["fieldFormat"] = Enums.FormatType.None;
            }
            else {
                column["fieldFormat"] = column.format;
            }
           
            column["kpi"] = [];
            column["isGridColumn"] = true;
            
            return (<ColumnDirective key={column.fieldId} field={column.fieldName} textAlign={textAlign ? textAlign : 'Left'}   showColumnMenu={column.fieldName} headerText={column.caption} width='120'
                template={this.template.bind(this, column)}

            ></ColumnDirective>);

        });
        return (
            <ColumnsDirective>
                {columns}
            </ColumnsDirective>
        );
    }

    validateModel = () => {
        let isValid = true;
        let validationSchema = this.state.validationSchema;
        if (this.state.dataModel) {
            validationSchema = validationSchema.map((field) => {
                let value = this.state.dataModel[field.fieldName]

                if (field.controlType === Enums.ControlType.CheckBox) {
                    if (!value|| value === '0' || value===0 || field.errorStatus) {
                        field.errorState = true;
                        isValid = false;
                    }
                    else
                        field.errorState = false;

                } else {
                    if ((value === null) || value === '' || Number.isNaN(value) || field.errorStatus) {
                        field.errorState = true;
                        isValid = false;
                    }
                    else
                        field.errorState = false;

                }

               
                field.touched = true;
                return field;
            });
        }

        this.setState({ validationSchema: validationSchema });
        return isValid;
    }


    saveRow = (status) => {

        if (!this.validateModel()) {
            return false;
        }
        let sourceModel = [... this.props.gridDataModel];
        
        //Add row
        var item;
        if (this.state.isEdit === false) {
            let array = {};
            for (item in this.state.dataModel) {
                if (this.state.dataModel.hasOwnProperty(item)) {
                    array[item] = (Number.isNaN(this.state.dataModel[item])) ? "" : this.state.dataModel[item];
                }
            }
            sourceModel.push(array);
            this.props.updateGridModel(sourceModel, 0, true);
        } else {
            //Update row
            if (gridRowIndex  > 0) {
                let index = gridRowIndex - 1
                for (item in this.state.dataModel) {
                    if (this.state.dataModel.hasOwnProperty(item)) {
                        sourceModel[index][item] = (Number.isNaN(this.state.dataModel[item])) ? "" :this.state.dataModel[item];
                    }
                }

                this.props.updateGridModel(sourceModel, index, false);

            } else {

                this.props.showMessageBox('Please select any row.', 'Error')
            }

        }

       
        const newList = (modelDropDownValueList.length === 0) ? defaultDropDownValueList : modelDropDownValueList;
        dropDownList = this.updateDropDownList(newList);
        //Save and close
        if (status === true) {
            defaultDropDownValueList = [];
            this.setState({ visible: false, isEdit: false });
        } else {
           
            this.clearModelDropDownValueList();
            gridRowIndex = 0;
            const gridModelSettings = this.setDataModel(this.props, sourceModel.length)
            this.setState({ isEdit: false, selectedRows: [], ...gridModelSettings });
            

        }


    }

    editRow = () => {
        if (gridRowIndex  > 0) {
            this.setState({ visible: true, isEdit: true });
            this.clearModelDropDownValueList();
        } else {

            this.props.showMessageBox('Please select any row.', 'Error')
        }

    }

    deleteRow = () => {
        const data = this.props.dataSource;
        let sourceModel = [... this.props.gridDataModel];
        const rowIndex = parseInt(gridRowIndex );
        sourceModel.splice(rowIndex, 1);
        let fieldIndex = data[0].columns.findIndex(f => f.isAutoIncrement === true)
        if (fieldIndex !== -1) {
            for (var i = 0; i < sourceModel.length; i++) {
                let updateModel = Object.assign({}, sourceModel[i]);
                updateModel[data[0].columns[fieldIndex].fieldName] = i + 1;
                sourceModel[i] = updateModel
            }

        }

        dropDownList.splice(rowIndex, 1);
        gridRowIndex = 0;
        this.setState({ isEdit: false, selectedRows: [] });
        this.props.updateGridModel(sourceModel,rowIndex, false);
        //this.handleClose();
    }

    onSelect = (data, rowind) => {
        let model = this.state.dataModel;
        for (var item in data) {
            if (data.hasOwnProperty(item)) {

                model[item] = data[item];
            }
        }
        gridRowIndex = rowind + 1;
        this.setState({ dataModel: model, selectedRows: [rowind] });
    }

    SetRow(data) {

        let rowIndex = 0;
        lookupLists = [];
        const formRows = data.map((row) => {
            let columnIndex = 0;
            rowIndex++;
            let columns = row.columns.map((column) => {
                let lookupList = {};
                columnIndex++;
                if ((column.controlType === Enums.ControlType.TextBox || column.controlType === Enums.ControlType.MultiText1) &&
                    column.controlId !== '' &&
                    column.isVisible === true) {

                    if (column.listParameterFields.length > 0) {
                        lookupList.param = column.listParameterFields ;
                        let listOutput = column.listOutputFields.filter(output => output.controlName !== column.fieldName);
                        lookupList.output = listOutput;

                        lookupLists.push(lookupList);
                    }
                  
                    const retrievedDataModel = (!this.state.isEdit) ? {}:this.props.gridDataModel[gridRowIndex - 1];
                    return <GridLayout spacing={0} pt={columnIndex === 1 ? 0 : 2.3} container key={"GridColumn" + columnIndex}><GridLayout item xs={12}> <InputText currentControl={this.state.currentControl}
                        settings={column} validationSchema={this.state.validationSchema} onChange={this.onControlChange} dataModel={this.state.dataModel}
                        retrievedDataModel={retrievedDataModel}
                        dependentFieldMapping={this.state.dependentFieldMapping }
                        setLookupListOutputValue={this.setLookupListOutputValue} headerdDataModel={this.props.headerDataModel} isGrid={true}
                        showMessageBox={this.alertAction} isPublic={this.props.isPublic}/></GridLayout> </GridLayout>

                } else if (column.controlType === Enums.ControlType.DropdownList &&
                    column.controlId !== '' &&
                    column.isVisible === true) {

                    const sourceModel = [... this.props.gridDataModel];
                    
                    let staticList = [];
                    if (!column.reportId) {
                        staticList = sourceModel.map(model => model[column.fieldName])
                            .filter((value, index, model) => {
                                return model.indexOf(value) === index;
                            });


                        this.props.newStaticValueList.map(field => {
                            if (field.isGrid && field.fieldName === column.fieldName) {
                                staticList.push(field.value);
                            }
                        });
                        
                    }

                    return <GridLayout container key={"GridColumn" + columnIndex}><GridLayout item xs={12}>  <DropDownList dataModel={this.state.dataModel}
                        onChange={this.onControlChange} settings={column} validationSchema={this.state.validationSchema} newStaticValueList={[]}// newStaticValueList={this.props.newStaticValueList}
                        isGrid={true} setDropDownList={this.setDropDownList} staticList={staticList} headerdDataModel={this.props.headerDataModel} isPublic={this.props.isPublic} showMessageBox={this.props.showMessageBox} retrievedDataModel={this.state.retrievedDataModel}/> </GridLayout></GridLayout>

                } else if ((column.controlType === Enums.ControlType.DatePicker ||
                    column.controlType === Enums.ControlType.TimePicker) &&
                    column.controlId !== '' &&
                    column.isVisible === true) {

                    return <GridLayout spacing={0} pt={columnIndex === 1 ? 0 : 2.3} container key={"GridColumn" + columnIndex}> <GridLayout item xs={12}>
                        <DateTimePicker settings={column} dataModel={this.state.dataModel} onChange={this.onControlChange}
                            showMessageBox={this.props.showMessageBox} validationSchema={this.state.validationSchema} /> </GridLayout></GridLayout>
               
                } else if (column.controlType === Enums.ControlType.NumericTextBox &&
                    column.controlId !== '' &&
                    column.isVisible === true) {
                    if (column.textAlign === "") {
                        column.textAlign = "Right"
                    }

                    let numericSettings = getNumericSettings(column, this.state.dataModel[column.fieldName]);
                    return <GridLayout spacing={0} pt={columnIndex===1?0:2.3} container key={"GridColumn" + columnIndex}><GridLayout item xs={12}>  <NumericText
                        settings={numericSettings}
                        validationSchema={this.state.validationSchema}
                        onChange={this.onControlChange} column={column} dataModel={this.state.dataModel}
                           /></GridLayout> </GridLayout>
                
                } else if (column.controlType === Enums.ControlType.Image &&
                    column.controlId !== '' &&
                    column.isVisible === true) {

                    return (<GridLayout container key={"GridColumn" + columnIndex} pt={3}><GridLayout item xs={12}>
                        <Image key={"GridColumn" + columnIndex} showCaption={true} imageSize={column.culture
                        } showHover={false} deviceType={this.props.deviceType} url={this.state.dataModel[column.fieldName]} caption={column.caption
                        } onChange={this.onControlChange} column={column} />
                    </GridLayout></GridLayout>)
                }

                else if (column.controlType === Enums.ControlType.CheckBox &&
                    column.controlId !== '' &&
                    column.isVisible === true) {
                   return (<GridLayout container key={"GridColumn" + columnIndex} pt={3}><GridLayout item xs={12}>
                        <CheckboxControl key={"GridColumn" + columnIndex} isEditable={column.isEditable} isGrid={true} showCaption={true} settings={column}  validationSchema={this.state.validationSchema} dataModel={this.state.dataModel} onChange={this.onControlChange} />
                    </GridLayout></GridLayout>)
                }


                else {
                    return ""
                }
               
            });
            return <GridLayout item xs={12} key={"GridRow" + rowIndex}> {columns} </GridLayout>

        });
        return formRows;
    }

    setDataModel = (props, gridRowCount) => {
        let data = props.dataSource
        let updatedModel = [];// this.state.dataModel;
        for (var i = 0; i < data.length; i++) {
            let columns = data[i].columns; //props.muicolumns; //
            for (var j = 0; j < columns.length; j++) {
                let column = columns[j];
                if (column.name !== '' && column.fieldName) {
                    if (column.defaultValue.toLowerCase().indexOf('formdata') > -1) {
                        var length = column.defaultValue.length - 1
                        var index = column.defaultValue.indexOf('.') + 2
                        let fieldName = column.defaultValue.substring(index, length);
                        updatedModel[column.fieldName] = props.headerDataModel[fieldName];
                    }
                    else if (column.defaultValue.toUpperCase() === '$GETDATE()') {
                        var currentDate = new Date();
                        updatedModel[column.fieldName] = currentDate;
                    }
                    else if (column.defaultValue) {
                        updatedModel[column.fieldName] = column.controlType === Enums.ControlType.NumericTextBox ? parseInt(column.defaultValue) : column.defaultValue;

                    }
                    else if (column.isAutoIncrement) {
                        updatedModel[column.fieldName] = gridRowCount + 1;
                    }
                    else {
                        updatedModel[column.fieldName] = '';
                    }
                }
            }
            updatedModel[this.props.primaryKeySettings.primaryKeyLinkField] = this.props.headerDataModel[this.props.primaryKeySettings.primaryKeyField];
        }
        const validationDetailSchema = this.state.validationSchema.map((field) => {

                field.errorState = false;
                field.touched = true;
            
            return field;
        });
        return { dataModel: updatedModel, validationSchema: validationDetailSchema };
    }

    onHide = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        let validationSchema = this.clearValidationSchema();
        this.setState({ visible: false, validationSchema: validationSchema });
    }

    clearValidationSchema = () => {
        let validationSchema = this.state.validationSchema;
        return validationSchema.map((field) => {
            field.errorState = false;
            field.errorStatus = false;
            return field;
        });

    }

    clearLookupListDependency = (outputFields) => {
        let updatedModel = this.state.dataModel;
        for (var i = 0; i < outputFields.length; i++) {
            let controlName = outputFields[i].controlName;
            updatedModel[controlName] = "";
            this.applyDetailFormula(controlName, updatedModel);
        }
        return updatedModel;
    }

    setLookupListOutputValue = (report, outputFields) => {
        let updatedModel = this.state.dataModel;
        for (var i = 0; i < outputFields.length; i++) {
            let controlName = outputFields[i].controlName;
            let value = report[outputFields[i].reportField];
            updatedModel[controlName] = value;
            this.applyDetailFormula(controlName, updatedModel);
        }
        this.setState({ dataModel: updatedModel });
    }

    clearModelDropDownValueList = () => {
        modelDropDownValueList = [];
    }

    setDropDownList = (list, controlId, isDefault) => {

        let index = modelDropDownValueList.findIndex(value => value.controlId === controlId);
        if (index === -1) {
            modelDropDownValueList.push(list);
            if (isDefault) {
                defaultDropDownValueList.push(list);
            }
        } else {
            modelDropDownValueList[index] = list;
        }
    }

    updateDropDownList = (lists) => {
        let rowIndex = (!this.state.isEdit) ? this.props.gridDataModel.length : gridRowIndex  - 1;

        let dropDownListCopy = [...dropDownList];
        let ddlist = dropDownListCopy[rowIndex];
        if (!ddlist) {
            let dropDownDetails = {
                controls: lists
            };
            dropDownListCopy.push(dropDownDetails)
        } else {
            lists.map(list => {
                const controlId = list.controlId;
                let index = ddlist.controls.findIndex(value => value.controlId === controlId);
                if (index !== -1) {
                    ddlist.controls[index] = list;
                }
            });
        }

        return dropDownListCopy;
    }

    onControlChange = (isRequired, event, value, reportField, outputFields, report, mappedFieldList, mappedFieldValue, skipValidation, isLookUp) => {

        let dependentFieldMapping = this.state.dependentFieldMapping ? this.state.dependentFieldMapping : [];
        let currentControlName = this.state.currentControlcurrentControlName;

        if (event.bubbles) {
            currentControlName = event.target.name
        }
        if (isLookUp) {
            currentControlName = event
        }

        let updatedModel = this.state.dataModel;
        let validationSchema = this.state.validationSchema;
        this.calculationLaunchField = "";
        let fieldName = "";
        let oldModel = { ...this.state.dataModel };
        if (value !== oldModel[fieldName]) {
            for (let lookupList of lookupLists) {
                for (let param of lookupList.param) {
                    if (fieldName === param.controlName) {
                        updatedModel = this.clearLookupListDependency(lookupList.output);
                    }
                }
            }
        }
        if (typeof event === 'string') {
            updatedModel[event] = value;
            if (mappedFieldList && mappedFieldList.length > 0) { // for dropdownlist
                for (var i = 0; i < mappedFieldList.length; i++) {
                    const controlName = mappedFieldList[i].controlName;
                    const controlValue = value === "" ? value : mappedFieldValue[mappedFieldList[i].reportField];
                    updatedModel[controlName] = controlValue;

                    const dependentFieldIndex = dependentFieldMapping.findIndex(field => field.dependencyField === controlName)
                    if (dependentFieldIndex !== -1) {
                        dependentFieldMapping=dependentFieldMapping.splice(dependentFieldIndex, 1);
                    }
                    dependentFieldMapping.push({ dependencyField: controlName, value: controlValue, parentField: event, parentFieldValue: value });
                }
            }
            this.applyDetailFormula(event, updatedModel);
            fieldName = event;
        } else {
            if (event.type === 'blur') {
                updatedModel[event.target.name] = value;
                if (!value) {
                    if (mappedFieldList && mappedFieldList.length > 0) { // clear dependecny field value
                        for (var i = 0; i < mappedFieldList.length; i++) {
                            const controlName = mappedFieldList[i].controlName;
                            if (currentControlName !== controlName) {
                                updatedModel[controlName] = "";
                            }
                        }
                    }
                }
            } else
                updatedModel[event.target.name] = event.target.value;
            this.applyDetailFormula(event.target.name, updatedModel);
            fieldName = event.target.name;

            const dependentFieldIndex = dependentFieldMapping.findIndex(field => field.dependencyField === fieldName)
            if (dependentFieldIndex !== -1) {
                dependentFieldMapping = dependentFieldMapping.splice(dependentFieldIndex, 1);
            }
        }

        if (isRequired ) {
            const validationDetailSchema = validationSchema.map((field) => {

                if (field.fieldName === fieldName) {

                    if (field.controlType === Enums.ControlType.CheckBox) {

                        if (!value === '' || value === '0' || value === 0) {
                            if (!skipValidation)
                            field.errorState = true;
                        }
                        else {
                            field.errorState = false;
                        }

                    }
                    else {


                        if (value === '' || Number.isNaN(value)) {
                            if (!skipValidation)
                            field.errorState = true;
                        }
                        else {
                            field.errorState = false;
                        }
                    }
                    
                    field.touched = true;
                }
                else if (mappedFieldList && mappedFieldList.length > 0) {
                    const mappedFieldIndex = mappedFieldList.findIndex(control => control.controlName === field.fieldName)
                    if (mappedFieldIndex !== -1) {
                        const mappedValue = updatedModel[field.fieldName];
                        if (mappedValue) {
                            field.errorState = false;
                            field.touched = true;
                        }
                    }
                }
                return field;
            });
            validationSchema = validationDetailSchema;
        }
        else if (!isRequired && mappedFieldList && mappedFieldList.length > 0) {

            const validationDetailSchema = validationSchema.map((field) => {

                const mappedFieldIndex = mappedFieldList.findIndex(control => control.controlName === field.fieldName)
                if (mappedFieldIndex !== -1) {
                    const mappedValue = updatedModel[field.fieldName];
                    if (mappedValue) {
                        field.errorState = false;
                        field.touched = true;
                    }
                }




                return field;
            });

            validationSchema = validationDetailSchema;
        }
        

        this.setState({
            dataModel: updatedModel, validationSchema: validationSchema,
            currentControl: { currentControlName: currentControlName, isLookUp: isLookUp, lookUpRowData: mappedFieldValue, mappedFieldList: mappedFieldList },
            dependentFieldMapping: dependentFieldMapping
        });

        if (outputFields !== undefined && outputFields !== null) {
            if (outputFields.length > 0 && outputFields.length > 0) {
                this.setOutputFieldValue(reportField, value, report, outputFields);
            }
        }

       

    }
    applyDetailFormula = (fieldName, detailModel) => {
        const formulaList = this.props.formulaSettings;
        const result = formulaList.filter(formula => formula.fieldName === fieldName);
        for (let i = 0; i < result.length; i++) {
            const formulaObject = result[i];
            if (formulaObject.formulaType === Enums.FormulaType.DateDifference ||
                formulaObject.formulaType === Enums.FormulaType.DateAdd) {
                this.applyGridDateFormula(formulaObject, detailModel)

            } else {
                let formula = formulaObject.formula;
                const precision = formulaObject.fieldPrecision;
                for (let j = 0; j < formulaObject.dependentFieldList.length; j++) {
                    const fieldName = formulaObject.dependentFieldList[j].fieldName;
                    let value = detailModel[fieldName];
                    if (value === null || value === "" || value === undefined) {
                        value = 0;
                    } else {
                        value = parseFloat(value);
                    }

                    formula = formula.replace(`[${fieldName}]`, value);
                }
                if (this.calculationLaunchField === "") {
                    this.calculationLaunchField = formulaObject.fieldName;
                }
                let calculatedValue = mathExpression.eval(formula);
                if (precision >= 0 && !isNaN(calculatedValue)) {
                    calculatedValue = parseFloat(calculatedValue.toFixed(precision))
                }
                detailModel[formulaObject.formulaField] = calculatedValue;
                if (this.calculationLaunchField !== formulaObject.formulaField) {
                    this.applyDetailFormula(formulaObject.formulaField, detailModel)
                }
            }
        }

    }

    applyGridDateFormula = (formulaObject, detailModel) => {
        let formula = formulaObject.formula;
        if (formulaObject.formulaType === Enums.FormulaType.DateDifference) {
            let startDate = detailModel[formulaObject.dependentFieldList[0].fieldName]
            let endDate = detailModel[formulaObject.dependentFieldList[1].fieldName]
            if (startDate !== null &&
                startDate !== "" &&
                startDate !== undefined &&
                endDate !== null &&
                endDate !== "" &&
                endDate !== undefined) {
                if (this.calculationLaunchField === "") {
                    this.calculationLaunchField = formulaObject.fieldName;
                }
                if (formula.toLowerCase().startsWith("datediff")) {
                    const newValue = dateDifference(startDate, endDate, 'days');
                    detailModel[formulaObject.formulaField] = newValue;
                } else if (formula.toLowerCase().startsWith("monthdiff")) {
                    const newValue = dateDifference(startDate, endDate, 'months');
                    detailModel[formulaObject.formulaField] = newValue;
                } else if (formula.toLowerCase().startsWith("yeardiff")) {
                    const newValue = dateDifference(startDate, endDate, 'years');
                    detailModel[formulaObject.formulaField] = newValue;
                } else if (formula.toLowerCase().startsWith("hourdiff")) {
                    const newValue = dateDifference(startDate, endDate, 'hours');
                    detailModel[formulaObject.formulaField] = newValue;
                } else if (formula.toLowerCase().startsWith("minutediff")) {
                    const newValue = dateDifference(startDate, endDate, 'minutes');
                    detailModel[formulaObject.formulaField] = newValue;
                }
                if (this.calculationLaunchField !== formulaObject.formulaField) {
                    this.applyDetailFormula(formulaObject.formulaField, detailModel)
                }
            }

            //dateDifference

        } else {
            let dateValue, numberValue;
            if (formulaObject.dependentFieldList.length === 2) {
                dateValue = detailModel[formulaObject.dependentFieldList[1].fieldName];
                numberValue = detailModel[formulaObject.dependentFieldList[0].fieldName];

            } else {
                dateValue = detailModel[formulaObject.dependentFieldList[0].fieldName];
                numberValue = formula.substring(formula.indexOf('(') + 1, formula.indexOf(','));
                numberValue = parseInt(numberValue);
            }
            if ((numberValue > 0 || numberValue < 0) &&
                dateValue !== null &&
                dateValue !== "" &&
                dateValue !== undefined) {
                if (this.calculationLaunchField === "") {
                    this.calculationLaunchField = formulaObject.fieldName;
                }
                if (formula.startsWith("AddDay")) {

                    const newValue = addDate(dateValue, numberValue, 'days');
                    detailModel[formulaObject.formulaField] = newValue;
                } else if (formula.startsWith("AddMonth")) {

                    const newValue = addDate(dateValue, numberValue, 'months');
                    detailModel[formulaObject.formulaField] = newValue;
                } else if (formula.startsWith("AddYear")) {

                    const newValue = addDate(dateValue, numberValue, 'years');
                    detailModel[formulaObject.formulaField] = newValue;
                } else if (formula.startsWith("AddHour")) {

                    const newValue = addDate(dateValue, numberValue, 'hours');
                    detailModel[formulaObject.formulaField] = newValue;
                } else if (formula.startsWith("AddMinutes")) {

                    const newValue = addDate(dateValue, numberValue, 'minutes');
                    detailModel[formulaObject.formulaField] = newValue;
                }
                if (this.calculationLaunchField !== formulaObject.formulaField) {
                    this.applyDetailFormula(formulaObject.formulaField, detailModel)
                }
            }
        }
    }

    setOutputFieldValue = (reportField, controlValue, report, outputFields) => {
        let selectedValue = [];
        var i;
        for (i = 0; i < report.length; i++) {
            if (report[i][reportField] === controlValue) {
                selectedValue = report[i];
                break;
            }
        }
        for (i = 0; i < outputFields.length; i++) {
            let controlName = outputFields[i].controlName;
            let value = selectedValue[outputFields[i].reportField];
            let updatedModel = this.state.dataModel;
            updatedModel[controlName] = value;
            this.setState({ dataModel: updatedModel });
        }
    }

    showLookupList = (status, lookupFields) => {
        this.setState({ lookupVisible: status, lookupFields: lookupFields });
    }

    handleClose = () => {

        this.setState({ showMessage: false });

    };

    showConfirmMessageBox = () => {

        if (gridRowIndex  > 0) {
            this.setState({ showMessage: true, message: 'Are you sure you want to delete this line?.' });
        } else {

            this.props.showMessageBox('Please select any row.', 'Error')
        }
    }

    actionBegin(args) {

        if (this.grid && args.requestType === 'add') {
           
            args.cancel = true;
            this.onAddClick();
           
        }

        else if (this.grid && args.requestType === 'beginEdit') {

            args.cancel = true;
            const data = this.props.gridDataModel[args.rowIndex]
            let model = this.state.dataModel;
            for (var item in data) {
                if (data.hasOwnProperty(item)) {

                    model[item] = data[item];
                }
            }
            this.clearModelDropDownValueList();
            gridRowIndex = args.rowIndex + 1;
            this.setState({ header: 'Edit Item',dataModel: model, visible: true, isEdit: true, clickSave: false, retrievedDataModel: { ...model} });
        }
        else if (this.grid && args.requestType === 'delete') {
            args.cancel = true;
            this.deleteRow();
            
        }


        return true;

    }

    rowSelected(args) {

        //this.setState({ rowIndex: args.row.getAttribute('aria-rowindex') });
        gridRowIndex = args.row.getAttribute('aria-rowindex')
    }

    actionComplete(args) {
        args.cancel = true;
        
    }

    alertAction = (show) => {
        this.setState({ showAlert: show ? show:false  });
    }
    handleClickAway = () => {
        if (this.state.showAlert) {
            this.setState({ showAlert: false });
        }
    }
    getAlert = () => {
        const errorIcon = (<ErrorIcon />);
        return (
            <div style={{ width: "100%", justifyContent:"center",display:"flex" }}>
                <ClickAwayListener onClickAway={this.handleClickAway}>
                    <Alert sx={{ ml: 2, mt: -2, pt: 0, pb: 0, height: '35px', width: '80%', position: 'absolute', zIndex: 10001 }}
                        severity="error"
                        icon={errorIcon}
                        onClose={() => { this.alertAction(false) }}>
                        No matching records found
                    </Alert>
                    </ClickAwayListener>
                </div>
        );
    }
    render() {
        this.actionBegin = this.actionBegin.bind(this);
        this.actionComplete = this.actionComplete.bind(this);
        this.rowSelected = this.rowSelected.bind(this);
        const columnCount = this.props.dataSource[0].columns.filter(filter => (filter.fieldName && filter.isVisible && filter.isEditable)).length
        const services = ( columnCount===0) ? [Toolbar] : [Edit, Toolbar];
        const alert = this.state.showAlert ? this.getAlert() : <div />;
        return (

            <GridLayout key="gridLayout" className={this.props.classes.gridLayout}>
                <GridLayout item>
                    {/*<Message handleClose={this.handleClose} handleContinue={this.deleteRow} message={this.state.message} showMessage={this.state.showMessage} messageTitle={"Confirm"} okButtonName={"OK"} cancelButtonName={"Cancel"} buttonType={Enums.ButtonType.OkCancel} />*/}
                    
                    {this.state.lookupVisible ? <LookUpList lookupListOutputValue={this.setLookupListOutputValue} lookupFields={this.state.lookupFields} dataModel={this.state.dataModel} showLookupList={this.showLookupList} visible={this.state.lookupVisible} /> : ''}
                    <Dialog xs={12} open={this.state.visible} onClose={this.onHide} classes={{ paper: this.props.classes.dialogPaper }} disableScrollLock={true} >
                        <MuiDialogTitle disabletypography className={this.props.classes.root}>
                            <Typography variant="body1" style={{ "padding-left": "42%" }}>{this.state.header}</Typography>
                            <IconButton aria-label="close" className={this.props.classes.closeButton} onClick={this.onHide} sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,

                            }}><CloseIcon /></IconButton>
                        </MuiDialogTitle>
                        <MuiDialogContent sx={{ m: 0, p: 0 }} >
                            {alert}
                            <div className={this.props.classes.gridAddItem}>
                                
                                <GridLayout spacing={0} container key="details">
                                    
                                    {this.SetRow(this.props.dataSource)}

                                </GridLayout>
                            </div>
                            <GridLayout container mt={1.5 } spacing={1} key="gridEdit">
                                <GridLayout item xs={12}>
                                    
                                    <GridLayout container spacing={0} key="gridEdit" className={this.props.classes.saveButtonGrid}>
                                        <GridLayout item className={this.props.classes.saveButtonGrid1}>

                                            <GridButton saveNew={false} saveRow={ this.saveRow }/>
                                        </GridLayout>
                                        <GridLayout item className={this.props.classes.saveButtonGridItem1}>
                                            <GridButton saveNew={true} saveRow={ this.saveRow }/>
                                        </GridLayout>
                                    </GridLayout>
                                </GridLayout>
                            </GridLayout>
                        </MuiDialogContent>
                    </Dialog>
                                     

                    <GridComponent
                        className={this.props.deviceType === Enums.DeviceType.Mobile ? "e-bigger" : ""}
                       enableAdaptiveUI={this.props.deviceType === Enums.DeviceType.Mobile ? true : false}
                        rowRenderingMode={this.renderingMode}
                        allowReordering={false}
                        allowTextWrap={true}
                        allowKeyboard={false}
                        allowResizing={true}
                        actionBegin={this.actionBegin}
                        actionComplete={this.actionComplete}
                        rowSelected={this.rowSelected}
                        editSettings={this.editOptions} toolbar={this.toolbarOptions}
                        dataSource={this.props.gridDataModel} ref={grid => this.grid = grid}>
                        {this.getColumns()}

                        <Inject services={services} />
                    </GridComponent>
                </GridLayout>
            </GridLayout>



        )


    }

}



export default withStyles(formTheme)(Grid);

