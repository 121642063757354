

const requestCustomWidget = 'REQUEST_CUSTOM_WIDGET';
const receiveCustomWidget = 'RECEIVE_CUSTOM_WIDGET';
 
const initialState = { customWidget:[], isLoading: false,dataFetched:false,};

export const actionCreators = {

	requestCustomWidget: () => async (dispatch, getState) => {

		dispatch({ type: requestCustomWidget});
        const url = 'adminApi/CustomWidget';
        const response = await fetch(url);
        if (response.status === 200) {
            const objectCustomWidget = await response.json();
            dispatch({ type: receiveCustomWidget, customWidget: objectCustomWidget });
        }
        else {

            dispatch({
                type: "SHOW_ERROR",
                message: "Custom widget loading failed"
            });
            dispatch({ type: receiveCustomWidget, customWidget: [] });

        }

    },
    
};

export const reducer = (state, action) => {
	state = state || initialState;
	if (action.type === requestCustomWidget) {
		return {
			...state,			
            isLoading: true,
            dataFetched:false,
		};
	}
	if (action.type === receiveCustomWidget) {
        return {
			...state,
			customWidget: action.customWidget,
            isLoading: false,
            dataFetched:true,
		};
		
	}
	
	return state;
	
};