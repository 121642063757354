"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.converters = exports.DEFAULT_CONVERTER = void 0;
var DEFAULT_CONVERTER = 'rgba_hex';
exports.DEFAULT_CONVERTER = DEFAULT_CONVERTER;
var converters = {
  rgba: function rgba(c) {
    return "rgba(".concat(c.rgb.r, ", ").concat(c.rgb.g, ", ").concat(c.rgb.b, ", ").concat(c.rgb.a, ")");
  },
  rgb: function rgb(c) {
    return "rgb(".concat(c.rgb.r, ", ").concat(c.rgb.g, ", ").concat(c.rgb.b, ")");
  },
  hex: function hex(c) {
    return c.hex;
  },
  rgba_rgb: function rgba_rgb(c) {
    return c.rgb.a === 1 ? converters.rgb(c) : converters.rgba(c);
  },
  rgba_hex: function rgba_hex(c) {
    return c.rgb.a === 1 ? converters.hex(c) : converters.rgba(c);
  }
};
exports.converters = converters;
var _default = converters;
exports["default"] = _default;