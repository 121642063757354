import { theme, customThemeStyles } from 'common/theme';
export const contentLayoutTheme = () => ({
    ...customThemeStyles,

    homeIcon: {


        [theme.breakpoints.up('sm')]: {
            fontSize: '40px!important',
            marginRight: "50px!important",
            color: customThemeStyles.customTheme.backgroundColor,

        },

        [theme.breakpoints.down('sm')]: {
            fontSize: '30px!important',
            marginRight:"50px!important",
            //marginTop:'2px!important',
            color: customThemeStyles.customTheme.backgroundColor,

        }

    },

    homeIconContainer: {

        position: "absolute",
        //marginTop:"10px"
    },

    layoutContainer: {


        height: "700px!important",

    },
    layoutBody: {

        paddingTop: "24px!important",
        paddingRight: "40px!important",
        paddingBottom: "24px!important",
        fontFamily: "Roboto",
        overflow: "hidden",
        fontSize: "15px",
        lineHeight: 1.7

    },
    articleDate: {
        float: "left",
        width: "70px",
        marginRight: "20px",
        fontFamily: "'Gibson', 'Arial', 'Helvetica', sans - serif"
    },

    articleDateMonth: {
        background: customThemeStyles.customTheme.backgroundColor,
        fontSize: "16px",
        lineHeight: "h",
        height: "21px",
        textAlign: "center",
        textTransform: "uppercase",
        color: " #fff",
        borderRadius: " 4px 4px 0 0"
    },

    articleDateDay: {
        background: "#F8F9F9",
        fontSize: "40px",
        lineHeight: "50px",
        padding: "4px 0 0",
        color: " #5F6871",
        textAlign: "center",
        borderRadius: " 0 0 4px",
        boxShadow: " 0 1px 0 rgba(0, 0, 0, .07)"
    },
    headerH2: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 300,
        fontSize: "29px!important",
        //lineHeight: 1.2,
        //letterSpacing: "-0.00833em",

    },

    alignLeft: {

        float: "left",
        marginRight: "20px",
        height:"auto!important"
    },
    widgetRecentArticlesWidgetBody: {
        paddingTop: "6px"
    },
    widgetRecentArchivesWidgetBody: {
        paddingTop: "6px"
    },

    listRecentArticles: {
        color: "#949AA8",
        listStyle: "none outside none"

    },

    widgetBody: {
        padding: "6px 26px 20px"
    },



    listRecentArticlesSmall: {
        display: "block",
        fontFamily: "'Gibson', 'Arial', 'Helvetica', sans - serif",
        fontSize: 11,
        lineHeight: 1.5
    }
    , widgetHead: {
        padding: "30px 26px 0"
    },
    recentLink: {
        textDecoration: "none",
        fontStyle: "italic",
        color: customThemeStyles.customTheme.backgroundColor,
    },

    articleMetaH6: {
        color: "#989FA6",
        letterSpacing: "1px"
    },
    articleMetaI: {
        color: "#10E08C",
        fontSize: "18px",
        verticalAlign: "middle",
        marginRight: " 3px"
    },

    articleMetaDl: {
        overflow: "hidden",
        fontSize: "14px",
        color: "#989FA6",
        textAlign:"center",
        padding: "0 33px"
    },

    
    articleMetaDD1: {
        float: "left"
    },

    articleMetaDD: {
        margin: "0 15px 0 8px",
        color: "#000",
    }
    ,
    widgets: {
        listStyle: "none outside none"
    },

    archivetoggle: {
        position: "relative",
        '&:after': {
            width: 0,
            height: 0,
            borderWidth: "5px 5px 0",
            borderStyle: "solid",
            content: "''",
            borderColor: "rgba(140, 160, 179, .75) transparent transparent",
            position: "absolute",
            top: "50%",
            marginTop: "-3px",
            cursor: "pointer"

        }
    },

    archiveToggleCurrent: {
        '&:before': {
            webkitTransform: "translate(50 %, -50 %) rotate(90deg) scale(0)",
            msTransform: "translate(50 %, -50 %) rotate(90deg) scale(0)",
            transform: "translate(50 %, -50 %) rotate(90deg) scale(0)"
        },
        '&:after': {
            webkitTransform: "rotate(180deg)",
            msTransform: "rotate(180deg)",
            transform: "rotate(180deg)",
            position: "relative",
            width: 0,
            height: 0,
            borderWidth: "5px 5px 0",
            borderStyle: "solid",
            content: "''",
            borderColor: "rgba(140, 160, 179, .75) transparent transparent",
            position: "absolute",
            marginTop: "10px",
            cursor: "pointer"
        },


    },

    archive: {
        fontFamily: "'Gibson', 'Arial', 'Helvetica', sans - serif",
        webkitBackfaceVisibility: "hidden"
    },

    archiveSpan: {
        display: "block",
        color: "#4B535A",
        position: "relative",
        transition: "backgroundColor .15s",
        cursor: "pointer"
    },

    archiveA: {
        '&:hover': {
            textDecoration: "none"
        }

    },

    archiveUl: {
        listStyle: "none",
        margin: 0
    },

    archiveLi: {
        padding: 0
    },

    archiveBody: {
        display: "none",
        listStyle: "none"
    },

    archiveToggle: {
        position: "relative"
    },
    activeBodyContainer: {
        display: "block"
    },
    contentdetailContainer: {
        textAlign: "center!important",
        padding: " 0 0 0px"

    },
    postedContainer: {
        paddingLeft: "34%"
    },
    nextContainer: {
        float: "right", width: "200px"
    },
    previewContainer: {
        float: "left",
        [theme.breakpoints.up('sm')]: {
            width: "214px"
        },
        [theme.breakpoints.down('sm')]: {
            width: "251px"
        },
    },
    loadingContainer: {

        position: "absolute", zIndex: 10000, top: "5%", left: "50%"
    },
    wrapperRight: {
        [theme.breakpoints.up('lg')]: {
            width: "305px",

        },
        [theme.breakpoints.down('lg')]: {
            width: "100%",

        },
    },
    recentWrapper: {
        [theme.breakpoints.down('lg')]: {
            border: "1px solid rgba(0, 0, 0, 0.12)",
            borderBottom: "0px",
            borderLeft: "0px",
        },
        [theme.breakpoints.down('sm')]: {
            border: "0px",
        },
        width:"100%"
    },
    archiveWrapper: {
        [theme.breakpoints.up('md')]: {
            borderTop: "1px solid rgba(0, 0, 0, 0.12)",

        },
        
        width: "100%"
    }


   


});