const initiateRoute = 'SETROUTE_INITIATE'
const completeRoute = 'SETROUTE_COMPLETE'

const initialState = {type:'',link:'' };

export const linkRouteAction = {


    setLinkRoute: (initiate, link) => (dispatch, getState) => {
        if (initiate) {
            dispatch({ type: initiateRoute, routeAction: { type: initiateRoute, link:link } });
        }
        else {
            dispatch({ type: completeRoute, routeAction: { type: completeRoute, link:'' } });
        }

    },
}


export const reducer = (state, action) => {

    state = state || initialState;

    if (action.type === initiateRoute || action.type === completeRoute) {
        return {
            ...action.routeAction
        };
    }
    
    return state;
}