import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import Admin from "admin/Admin";
import Client from "client/Client";
import IdleTimerContainer from "IdleTimerContainer";
import { useState } from 'react';
export default function App(props) {
    const [fieldValue, setFieldValue] = useState(false);
    const [sessionTimeOut, setSessionTimeOutValue] = useState(0);

    const [logOutAll, setAllLogOutOutValue] = useState(false);

    const setLoginStatus = (sessionTimeOut) => {
      //  console.log("timer-setlogingstatus", sessionTimeOut)
        setFieldValue(!fieldValue);
        setAllLogOutOutValue(false)
        
      
        setSessionTimeOutValue(sessionTimeOut);
    }
    const logoutStatusAction = () => {
      //  console.log("timer-logoutStatusAction fieldvalue/logoutall", fieldValue, logOutAll)
        if (fieldValue) {
            setFieldValue(!fieldValue);
            setAllLogOutOutValue(true)
           
           
        }
       // setAllLogOutOutValue(!logOutAll)
    }
    
    return (<div>
        <meta name="viewport" content="width=device-width, user-scalable=no" />
        <BrowserRouter>

            <Switch>
                <Route path={"/Admin"} component={Admin} />
                <Route
                    path="/"
                    render={(props) => <Client {...props} loginStatusAction={setLoginStatus} logoutStatusAction={logoutStatusAction } />}
                />
            </Switch>
        </BrowserRouter>
        <IdleTimerContainer sessionTimeOut={sessionTimeOut} logOutAll={logOutAll } />
    </div>)
}
