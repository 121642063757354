const showError = 'SHOW_ERROR';
const closeError = 'CLOSE_ERROR';
const showRedirect = 'Show_REDIRECT';

const initialState = { showError: false, message: "", messageQueue: "", redirectProfile:false };

export const errorActionCreator = {
    closeError: () => async (dispatch, getState) => {

        dispatch({ type: closeError});
    },
    showErrorMessageNoLog: (message) => (dispatch, getState) => {
        dispatch({ type: showError, message });
    },
    showErrorMessage: (message) => (dispatch, getState) => {
        var data = new FormData();
        data.append('message', message);
         fetch('api/ErrorLog/WriteLog',
            {
                method: 'POST',
                body: data
            });

        dispatch({ type: showError, message});
    },

    logErrorMessage: (message) => (dispatch, getState) => {
        var data = new FormData();
        data.append('message', message);
        fetch('api/ErrorLog/WriteLog',
            {
                method: 'POST',
                body: data
            });
        
    },
    showMessageAndRedirect: (message) => (dispatch, getState) => {
        dispatch({ type: showRedirect, message });
    }

};

export const reducer = (state, action) => {
    state = state || initialState;
    if (action.type === closeError) {
        return {
            ...initialState
        };
    }
    else if (action.type === showError) {
        const templateMessage = (state.messageQueue === '') ? `${state.messageQueue} ${action.message}` : `${state.messageQueue} <br> ${action.message}`
        return {
            ...state,
            showError: true,
            message: action.message,
            messageQueue: templateMessage
        };
    }
    else if (action.type === showRedirect) {
        const templateMessage = (state.messageQueue === '') ? `${state.messageQueue} ${action.message}` : `${state.messageQueue} <br> ${action.message}`
        return {
            ...state,
            showError: true,
            message: action.message,
            messageQueue: templateMessage,
            redirectProfile:true
        };
    }
    return state;
};