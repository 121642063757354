import React from 'react';
import NumericText from 'client/components/Form/Controls/NumericText';
import InputText from 'client/components/Form/Controls/InputText';
import DropDownList from 'client/components/Form/Controls/DropdownList';
import SectionTitle from 'client/components/Form/Controls/SectionTitle';
import DateTimePicker from 'client/components/Form/Controls/DateTimePicker';
import Button from 'client/components/Form/Controls/Button';
import FileUpload from 'client/components/Form/Controls/FileUpload';
import Signature from 'client/components/Form/Controls/Signature';
import InputTextArea from 'client/components/Form/Controls/InputTextArea';
import * as Enums from 'client/components/Common/Enum';
import { getNumericSettings, getDeviceType } from 'client/components/Common/Utility'
import Image from 'client/components/Form/Controls/Image';
import CheckboxControl from 'client/components/Form/Controls/CheckBox';
const deviceType = getDeviceType();
export default function (props) {

    const control = props.control;
    const dataModel = props.dataModel;

    switch (control.controlType) {
        case Enums.ControlType.NumericTextBox: {
            const numericSettings = getNumericSettings(control, dataModel[control.fieldName]);
            if (!control.textAlign) {
                control.textAlign="Right"
            }
            return (
                <NumericText settings={numericSettings} validationSchema={props.validationSchema} onChange={control.onLostFocus ? props.onControlChangeLoadData : props.onControlChange} column={control} dataModel={dataModel} />
            );
        }
        case Enums.ControlType.TextBox:
         {
            return (
                <InputText currentControl={props.currentControl} settings={control} validationSchema={props.validationSchema} onChange={props.onControlChange} dataModel={dataModel}
                    showMessageBox={props.showMessageBox} setLookupListOutputValue={props.setLookupListOutputValue}
                    isPublic={props.isPublic}
                    retrievedDataModel={props.retrievedDataModel} dependentFieldMapping={props.dependentFieldMapping} />
                );
                }
        case Enums.ControlType.MultiText1: {
            return (
                <InputTextArea classes={props.classes}  settings={control} validationSchema={props.validationSchema} onChange={props.onControlChange} dataModel={dataModel} />
            );
        }
        case Enums.ControlType.DropdownList: {
            return (
                <DropDownList settings={control} validationSchema={props.validationSchema} onChange={props.onControlChange} dataModel={dataModel} showMessageBox={props.showMessageBox} newStaticValueList={props.newStaticValueList} isPublic={props.isPublic} retrievedDataModel={props.retrievedDataModel}/>
            );
        }
        case Enums.ControlType.SectionTitle: {
            return (
                <SectionTitle settings={control} />
            );
        }
        case Enums.ControlType.DatePicker:
        case Enums.ControlType.TimePicker: {
            return (
                <DateTimePicker settings={control} validationSchema={props.validationSchema} onChange={props.onControlChange} dataModel={dataModel} />
            );

        }
        case Enums.ControlType.Button: {
            return (
                <Button
                    saveButtonClassName={!control.isEditable ? props.classes.customButton_Client_Basic : props.classes.customButton_Client}
                    //otherButtonClassName={otherButtonClassName}
                    onClick={control.action ? props.callFormAction : undefined}
                    column={control}
                    disabled={!control.isEditable}
                    caption={control.caption} />
            );
        }
        case Enums.ControlType.FileUpload:
            {
                const fileList = props.fileSettings.currentfileList.filter(file => file.ControlId == control.controlId)
                return (
                    <FileUpload
                        settings={control}
                        setFormFiles={props.setFormFiles}
                        currentfileList={fileList}
                        clearFileControl={props.fileSettings.clearFileControl}
                        allowedExtensions={props.fileSettings.allowedExtensions}
                        totalFormFileSize={props.fileSettings.totalFormFileSize}
                        allowedFormFileSize={props.fileSettings.allowedFormFileSize}
                        showMessageBox={props.showMessageBox}
                        fileLabelClassName={props.fileSettings.fileLabelClassName}
                        formId={props.fileSettings.formId}
                        validationSchema={props.validationSchema}
                        isPublic={props.isPublic}
                    />
                );
            }
        case Enums.ControlType.Signature: {
            return (
                <Signature settings={control} classes={props.classes} validationSchema={props.validationSchema} onChange={props.onControlChange} dataModel={dataModel} />
            );

        }
        case Enums.ControlType.Image: {
            return (
                <Image isForm={true} caption={control.caption} column={control} showCaption={true} width={50} height={50} showHover={false} imageSize={control.format} deviceType={deviceType} url={dataModel[control.fieldName]} alt={control.fieldName} />
            );
        }
        case Enums.ControlType.CheckBox: {
            return (
                <CheckboxControl isEditable={control.isEditable} showCaption={true} settings={control} classes={props.classes} validationSchema={props.validationSchema} onChange={props.onControlChange} dataModel={dataModel} />
            );
        }
        default:
            {
                return (<InputText settings={control} validationSchema={props.validationSchema} onChange={props.onControlChange} dataModel={dataModel} showMessageBox={props.showMessageBox} setLookupListOutputValue={props.setLookupListOutputValue} />);
            }
    }
}
