import React from 'react';
import { produce } from 'immer';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getFilters, deleteFilter } from 'admin/actions/filterActions';
import MUIDataTable from 'mui-datatables';
import { Box } from '@mui/material';
import { withStyles } from '@mui/styles';
import { theme } from 'common/theme.js';
import api from 'admin/api/adminApi';
import { filtersTheme } from './theme/filtersTheme.js';
import DeleteButton from 'common/DeleteButton.js';
import ActionBackdrop from 'admin/components/section/settings/Backdrop';
import { MuiGridOptions } from 'admin/components/Common/CommonOptions';
import { showError } from 'admin/actions/alertActions';
let cellIndex = ""

const defaultColumnOptions = {
    filter: true,
    sort: true,
    // style a compact grid https://github.com/gregnb/mui-datatables/issues/714
    setCellProps: (cellValue, rowIndex, columnIndex) => {
        return {
            style: {
                padding: theme.spacing(1),          // use the theme helper function?
            }
        };
    }
};


class FilterList extends React.Component {
    constructor(props) {
        console.log("ctor-FilterList");
        super(props);
        this.dispatchGetFilters = this.dispatchGetFilters.bind(this);
        this.dispatchdeleteFilters = this.dispatchDeleteFilters.bind(this);
        this.state = {
            deleteFilterId: 0,
            columns: [

                {
                    name: "profileId",
                    label: "",
                    options: {
                        display: false,
                        ...defaultColumnOptions
                    }
                },
                {
                    name: "profileName",
                    label: "Name",
                    options: {
                        ...defaultColumnOptions
                    }
                },
                {
                    name: "remarks",
                    label: "Description",
                    options: {
                        ...defaultColumnOptions,
                        setCellProps: () => ({ className: props.classes.descriptionColumn }),
                    }
                },
                {
                    name: "",
                    label: " ",
                    showIcon: true,
                    options: {
                        ...defaultColumnOptions,
                        customBodyRender: this.deleteFieldTemplate,
                        filter: false,
                    }
                }
            ],
            options: {
                download: false,
                elevation: 0,
                responsive: 'scrollMaxHeight',
                print: false,
                searchOpen: true,
                pagination: false,
                filter: true,
                filterType: 'multiselect',
                searchPlaceholder: 'Enter search text ...',
                selectableRows: 'none',
                sortOrder: {
                    name: 'profileName',
                    direction: 'asc'
                },
                onRowClick: (rowData, rowMeta) => {
                    let dataIndex = rowMeta.dataIndex;
                    let filter = this.props.filters[dataIndex];
                    let filterId = filter.profileId ? filter.profileId : -1;
                    // console.log("/Admin/Filter: ", JSON.stringify(filter));
                    this.getFilterData(filterId);

                },
                onCellClick: (rowNumber, columnId) => {
                    this.setCellIndex(columnId.colIndex)

                },
                onFilterChange: (changedColumn, filterList, type, changedColumnIndex, displayData) => {
                    this.setState(produce(draft => {
                        draft.columns[changedColumnIndex].options.filterList = filterList[changedColumnIndex];
                    }));
                },
                onColumnSortChange: (changedColumn, direction) => {
                    this.setState(produce(draft => {
                        draft.options.sortOrder = { name: changedColumn, direction: direction };
                    }));
                }
            }
        };
    }
    getFilterData = (filterId) => {

        if (cellIndex !== 3) {
            this.props.history.push(`/Admin/Filter/${filterId}`);
        }
    }
    setCellIndex = (index) => {
        cellIndex = index;

    }
    componentDidMount() {
        console.log('componentDidMount');
        this.dispatchGetFilters();
    }

    dispatchGetFilters() {
        console.log('dispatchGetFilters');
        this.props.dispatch(getFilters());

    }

    deletePreValidation = () => {
        return { canDelete: true, message: "" }
    }

    deleteFieldTemplate = (value, tableMeta, updateValue) => {
        console.log("editFieldTemplate", tableMeta);
        const columnIndex = 0;

        return <DeleteButton preValidationServer={api.canDeleteFilter} onClick={this.dispatchDeleteFilters} id={tableMeta.rowData[columnIndex]} />

    }
    dispatchDeleteFilters = (filterId) => {
        console.log("dispatchDeleteFilters id ", filterId);
        this.props.dispatch(deleteFilter(filterId));
        //api.canDeleteFilter(filterId).then((resp) => {
        //    let message = (resp.data === "") ? resp.data : resp.data.message;

        //    if (message) {
        //        showError(this.props.dispatch, message, 0);

        //    } else {
        //        this.props.dispatch(deleteFilter(filterId));
        //    }

        //})
        //    .catch((err) => {
        //        let message = err.response.data;
        //        showError(this.props.dispatch, message, 0);
        //    });





    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Box border={1} className={classes.filterListBox}> {/* default in theme?? */}
                    {this.props.isDataLoading && <ActionBackdrop />}
                    <MUIDataTable container
                        title={''}
                        data={this.props.filters}
                        columns={this.state.columns}
                        options={MuiGridOptions(this.state.options, this.props.isDataLoading)}
                    />
                </Box>
            </div>
        );
    }
}
/**
 * 
 * @param {*} state 
 */
function mapStateToProps(state) {
    return {
        filters: state.filters.filters,
        isLoading: state.loader.isLoading,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage,
        isDataLoading: state.filters.isLoading,
    };
};


export default withRouter(connect(mapStateToProps)(withStyles(filtersTheme)(FilterList)));