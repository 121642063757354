import React from 'react';
import { useState } from 'react';
import * as Enums from 'client/components/Common/Enum';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
import { DatePicker, TimePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { customThemeStyles } from 'common/theme.js';

const DateTime = (props) => {
    let value = (props.dataModel[props.settings.fieldName]) ? props.dataModel[props.settings.fieldName] : "";
    const [fieldValue, setFieldValue] = useState(value);
    const [isError, setIsError] = useState(false);

    React.useEffect(() => {
        if (value !== fieldValue) {
            setFieldValue(value);
        }

    }, [value]);

    const handleChange = (date) => {
        props.onChange(props.settings.isMandatory, props.settings.fieldName, date);
        if (date) {
            if (date.toString() === 'Invalid Date' || date.getFullYear().toString().length<4) {
                checkValidation();

            } else {
                setIsError(false);
            }
        }
    }
    const handleBlur = (event) => {
        
        if (event.target.value === "") {
            
            handleChange(event.target.value)
           
        }
    };


    const checkValidation=()=>{
        if (props.validationSchema) {
            props.validationSchema.map((field) => {
                if (field.fieldName === props.settings.fieldName && field.touched) {
                    setIsError(true);
                    return;
                }

            });
        }

    }


    const handleError = (event) => {
        let validationSchema = props.validationSchema;
        validationSchema = validationSchema.map((field) => {
            if (field.fieldName === props.settings.fieldName) {
                field.errorStatus = event ? true : false;
            }
            return field;
        });
    };
    const formatDate = (value) => {
        if (!value) {
            return null;
        }
        if (typeof value === 'object') {
            return value;
        }
        else {
            const format = (props.settings.controlType === Enums.ControlType.TimePicker) ? value.includes("T") ? "YYYY/MM/DD hh:mm" : "hh:mm" : "YYYY/MM/DD hh:mm";
            const result = moment(value, format).toDate();
            if (isNaN(result)) {
                return null;
            } else {
                return result;
            }
        }
    }
    const textAlignStyle = {
        textAlign: props.settings.textAlign
    }
    let errorState = {};
    let requiredIndicator = "";
    let helperText = props.settings.helpText;
    if (props.settings.isMandatory) {
        requiredIndicator = " *";
        const validation = props.validationSchema.filter(field => field.fieldName === props.settings.fieldName)
        if (validation.length > 0) {
            if (isError || validation[0].errorState) {
                errorState.error = true;
                helperText = `${props.settings.caption} is required`;
            }
        }
    }
    const labelStyle = {
        color: (errorState.error) ? '#f44336 !important' : props.settings.captionColor + ' !important',
        zIndex: 1
    }
    const caption = !props.settings.caption ? ' ' : props.settings.caption;
    const controlStyle = {
        color: props.settings.foreColor,
        background: props.settings.backGroundColor,
    }
    const helperTextStyle = { wordBreak: 'break-all' }
    const iconPosition = { marginRight: "-7px" }
    const setDateTimePicker = () => {
        
        let column = props.settings;

        if (column.controlType === Enums.ControlType.DatePicker && column.format === Enums.FormatType.Date) {
            return (<LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    renderInput={(param) => <TextField
                        {...param}
                        autoFocus={props.settings.hasFocus}
                        onBlur={handleBlur}
                        helperText={!helperText ? ' ' : helperText}
                        {...errorState}
                        FormHelperTextProps={{
                            style: helperTextStyle,
                        }}
                        sx={{ label: labelStyle, input: textAlignStyle, button: iconPosition }} />
                    }
                    OpenPickerButtonProps={{
                        sx: {
                            zIndex: 1.8
                        }
                    }}

                    disabled={!props.settings.isEditable}
                    InputProps={{ style: controlStyle }}
                    variant="inline"
                    name={column.fieldName}
                    inputFormat={column.culture || ''}
                    label={`${caption}${requiredIndicator}`}
                    disableMaskedInput
                    margin="normal"
                    id={column.fieldName}
                    value={formatDate(fieldValue)}
                    onChange={handleChange}
                    onError={handleError}
                    PaperProps={{
                        sx: {
                            "& .MuiPickersDay-root": {
                                "&.Mui-selected": {
                                    backgroundColor: customThemeStyles.customTheme.backgroundColor + '!important',
                                },
                            },
                            "& .MuiPickersDay-today": {
                                border: `1px solid  ${customThemeStyles.customTheme.backgroundColor} !important`,
                                backgroundColor: 'white !important',
                            }
                        }
                    }}
                   
                /></LocalizationProvider>
            )
           
        }
        else if (column.controlType === Enums.ControlType.DatePicker && column.format === Enums.FormatType.DateTime) {
            let format = column.culture + (column.hourFormat ? " a" : "") || '';
            return (<LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                    renderInput={(param) => <TextField
                        {...param}
                        autoFocus={props.settings.hasFocus}
                        onBlur={handleBlur}
                        helperText={!helperText ? ' ' : helperText}
                        {...errorState}
                        FormHelperTextProps={{
                            style: helperTextStyle,
                        }}
                        sx={{ label: labelStyle, input: textAlignStyle, button: iconPosition }} />}
                    OpenPickerButtonProps={{
                        sx: {
                            zIndex: 1.8
                        }
                    }}
                    disabled={!props.settings.isEditable}
                    variant="inline"
                    InputProps={{ style: controlStyle }}
                    name={column.fieldName}
                    inputFormat={format}
                    ampm={column.hourFormat}
                    margin="normal"
                    label={`${caption}${requiredIndicator}`}
                    id={column.fieldName}
                    value={formatDate(fieldValue)}
                    onChange={handleChange}
                    onError={handleError}
                    disableMaskedInput
                    PaperProps={{
                        sx: {
                            "& .MuiPickersDay-root": {
                                "&.Mui-selected": {
                                    backgroundColor: customThemeStyles.customTheme.backgroundColor + '!important',
                                },
                            },
                            "& .MuiPickersDay-today": {
                                border: `1px solid  ${customThemeStyles.customTheme.backgroundColor} !important`,
                                backgroundColor: 'white !important',
                            }
                        }
                    }}
                />
            </LocalizationProvider>
            )
        } else if (column.controlType === Enums.ControlType.TimePicker) {
            return (<LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                    renderInput={(param) => <TextField
                        {...param}
                        autoFocus={props.settings.hasFocus}
                        onBlur={handleBlur}
                        helperText={!helperText ? ' ' : helperText}
                        {...errorState}
                        FormHelperTextProps={{
                            style: helperTextStyle,
                        }}
                        
                        sx={{ label: labelStyle, input: textAlignStyle, button: iconPosition }} />}
                    OpenPickerButtonProps={{
                        sx: {
                            zIndex: 1.8
                        }
                    }}
                    disabled={!props.settings.isEditable}
                    variant="inline"
                    InputProps={{ style: controlStyle }}
                    //inputFormat={format}
                    ampm={column.hourFormat}
                    id={column.fieldName}
                    margin="normal"
                    name={column.fieldName}
                    value={formatDate(fieldValue)}
                    onChange={handleChange}
                    onError={handleError}
                    disableMaskedInput
                    label={`${caption}${requiredIndicator}`}
                /> </LocalizationProvider>)

        }
        else {
            return "";
        }
    }
    return (
        <React.Fragment>
            {setDateTimePicker()}
        </React.Fragment>
    );
}
export default ((DateTime));