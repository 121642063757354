import { ActionTypes as types } from './actionTypes';
import adminApi from 'admin/api/adminApi';
import mockApi from 'admin/api/mockApi';
import { showError } from './alertActions';
import { getError } from './errorActions';
import settings from 'settings.js';
import cloneDeep from 'lodash/cloneDeep';
import * as enumerator from 'client/components/Common/Enum';

/**
 * reads mock data setting from settings
 */
const useMocks = settings.useMocks;

/**
 * api in use, mock or real
 */
const api = useMocks ? mockApi : adminApi;

/**
 * Invokes the mock or real web api to get all filters
 */
 

export const apiDataReportParameterDestinationFieldsList = (id) => {
    return api.getDataViewRecordFields(id);
};

export const apiDataFormFieldsList = (id) => {
    return api.getFormsControls(id);
};

const getDataViewsNewLinksRequest = () => {
    return {
        type: types.VGO_NEW_DATAVIEW_LINK_REQUEST,
    }
};
const getDataViewNewLinksSuccess = (dataViewReportLinks) => {
    return {
        type: types.VGO_UPDATE_DATAVIEW_LINK_SUCCESS,
        link: dataViewNewLinkValue(dataViewReportLinks, newReportLink()[0]),
    };
};

const getDataViewsLinksSuccess = (dataViewReportLinks) => {
    return {
        type: types.VGO_UPDATE_DATAVIEW_LINK_SUCCESS,
        link: dataViewReportLinks,
    };
};
const addNewDataViewsLinksSuccess = () => {
    return {
        type: types.VGO_UPDATE_DATAVIEW_LINK_SUCCESS,
        link: newReportLink(),
    };
};

const getDataViewsConditionUpdateRequest = () => {
    return {
        type: types.VGO_UPDATE_DATAVIEW_CONDITION_REQUEST,
    }
};
const getDataViewConditionUpdateSuccess = (rowIndex, conditionIndex, newValue, dataViewReportLinks) => {
    return {
        type: types.VGO_UPDATE_DATAVIEW_CONDITION_SUCCESS,
        rowIndex: rowIndex,
        conditionIndex: conditionIndex,
        newValue: newValue,
        dataViewReportLinks: dataViewReportLinks,
    };
};

const getDataViewsNewConditionRequest = () => {
    return {
        type: types.VGO_NEW_DATAVIEW_CONDITION_REQUEST,
    }
};
const getDataViewNewConditionNewSuccess = (rowIndex, dataViewReportLinks) => {
    return {
        type: types.VGO_NEW_DATAVIEW_CONDITION_SUCCESS, 
        rowIndex: rowIndex,
        newObject: newReportLinkCondition(),
        dataViewReportLinks: dataViewReportLinks,
    };
};

const getDataViewsCancelConditionRequest = () => {
    return {
        type: types.VGO_CANCEL_DATAVIEW_CONDITION_REQUEST,
    }
};
const getDataViewConditionCancelSuccess = (rowIndex,  oldConditionValue, dataViewReportLinks) => {
    return{
        type: types.VGO_CANCEL_DATAVIEW_CONDITION_SUCCESS, 
        rowIndex: rowIndex,
        oldConditionValue: oldConditionValue,
        dataViewReportLinks: dataViewReportLinks,
    };
};

const getDataViewsRemoveConditionRequest = () => {
    return {
        type: types.VGO_REMOVE_DATAVIEW_CONDITION_REQUEST,
    }
};
const getDataViewRemoveConditionSuccess = (rowIndex, conditionIndex, dataViewReportLinks) => {
    return {
        type: types.VGO_REMOVE_DATAVIEW_CONDITION_SUCCESS,
        rowIndex: rowIndex,
        conditionIndex: conditionIndex,
        dataViewReportLinks: dataViewReportLinks,
    };
};


const getDataViewsRemoveConditionParameterRequest = () => {
    return {
        type: types.VGO_REMOVE_DATAVIEW_CONDITION_PARAMETER_REQUEST,
    }
};

const getDataViewRemoveConditionParamaterSuccess = (conditionIndex, parameterRowIndex, dataViewReportLinks) => {
    return {
        type: types.VGO_UPDATE_DATAVIEW_LINK_SUCCESS,
        link: dataViewRemoveConditionParameterValue(conditionIndex, parameterRowIndex, dataViewReportLinks),
    };
};

const getDataViewsDeleteLinkRequest = () => {
    return {
        type: types.VGO_DELETE_DATAVIEW_LINK_REQUEST,
    }
};

const getDataViewDeleteLinkSuccess = (rowIndex, dataViewReportLinks) => {
    return {
        type: types.VGO_UPDATE_DATAVIEW_LINK_SUCCESS,
        link: dataViewDeleteLinkValue(rowIndex, dataViewReportLinks),
    };
};

//const getDataViewUpdateLinkSuccess = (rowIndex, updatedObject, dataViewReportLinks) => {
//    return {
//        type: types.VGO_UPDATE_DATAVIEW_LINK_SUCCESS,
//        link: dataViewUpdateLinkValue(rowIndex, updatedObject, dataViewReportLinks),
//    };
//};
const getDataViewUpdateLinkSuccess = (dataViewReportLinks) => {
    return {
        type: types.VGO_UPDATE_DATAVIEW_LINK_SUCCESS,
        link: dataViewReportLinks,
    };
};

const getDataViewsNewConditionParameterRequest = () => {
    return {
        type: types.VGO_NEW_DATAVIEW_CONDITION_PARAMETER_REQUEST,
    }
};
const getDataViewNewConditionParameterSuccess = (rowIndex, paramObjectArray, dataViewReportLinks, clearExistingParams) =>
{
    return {
        type: types.VGO_NEW_DATAVIEW_CONDITION_PARAMETER_SUCCESS,
        rowIndex: rowIndex,
        paramObjectArray: paramObjectArray,
        dataViewReportLinks: dataViewReportLinks,
        clearExistingParams: clearExistingParams,
    };
};
export const conditionParameterObject = () => {
    let array = []
    let fieldLink = { actionId: -1, id: -1, isStatic: false, parameterName: "", parameterValue: ""};
    array.push(fieldLink)
    return array; 
}

const newReportLink= () => {
    let array = [
        {
            actionConditionObject: [newReportLinkCondition()],
            actionDestination: "",
            actionDestinationObjectId: 0,
            actionLinkField: "",
            actionMode: 0,
            actionName: "",
            actionType: 0,
            bizweaverDetails: [],
            bizweaverTaskId: "",
            conditionDetails: "",
            conditionFormula: "",
            createdDateTime: new Date(),
            createdUserId: 0,
            currentUtcDate: null,
            encryptedToken: null,
            id: 0,
            isDefaultAction: false,
            isShowInNewTab: true,
            reportId: 0,
            reportLinkDetails: [conditionParameterObject()[0]],
        }
    ]
    return array;
}

const newReportLinkCondition = () => {
    
    return { "conditionOpen": "None", "reportField": "", "operator": "", "value1": "", "value2": "", "logicalOperator": "" };
    
}

export const getDataViewsLinks = (reportLinks) => {
    let newReportLinks = cloneDeep(reportLinks);
    
    newReportLinks = newReportLinks.map(link => {
        if (link.actionType !== enumerator.LinkActionType.CrystalReport) {
            let linkDetails = [...link.reportLinkDetails];
            linkDetails.push(conditionParameterObject()[0]);
            link.reportLinkDetails = linkDetails;
            return link;
        }
        return link;
    });
    
    newReportLinks.push(newReportLink()[0]);
    return dispatch => {
        dispatch(getDataViewsLinksSuccess(newReportLinks));
    }
   
};

export const addNewDataViewLink = () => {
    return dispatch => {
        dispatch(addNewDataViewsLinksSuccess());
    }
}

export const dataViewNewLink = (dataViewReportLinks) => {
    return dispatch => {
        dispatch(getDataViewsNewLinksRequest());
        dispatch(getDataViewNewLinksSuccess(dataViewReportLinks));
    }
};
const dataViewNewLinkValue = (dataViewReportLinks, newReportLink) => {
    let dataViewLinks = [...dataViewReportLinks];
    dataViewLinks.push(newReportLink)
    return dataViewLinks;
}
export const dataViewLinkUpdate = (dataViewReportLinks) => {
    return dispatch => {
        dispatch(getDataViewUpdateLinkSuccess(dataViewReportLinks));
    }
};
//export const dataViewLinkUpdate = (rowIndex, updatedObject, dataViewReportLinks) => {
//    return dispatch => {
//        dispatch(getDataViewUpdateLinkSuccess(rowIndex, updatedObject, dataViewReportLinks));
//    }
//};
//const dataViewUpdateLinkValue = (rowIndex, updatedObject, dataViewReportLinks) => {
//    let dataViewLinks = dataViewReportLinks.map((reportLink, index) => {
//        if (index === rowIndex) {
//            reportLink = updatedObject;
//        }
//        return reportLink;
//    })
//    return dataViewLinks;
//};

export const dataViewConditionUpdate = (rowIndex, conditionIndex, newValue, dataViewReportLinks) => {
    return dispatch => {
        dispatch(getDataViewsConditionUpdateRequest());
        dispatch(getDataViewConditionUpdateSuccess(rowIndex, conditionIndex, newValue, dataViewReportLinks)); 
    }
};
export const dataViewUpdateConditionValue = (action) => {
    //Update exisitng condition 
       let dataViewReportLinks = action.dataViewReportLinks.map((reportLink, rowIndex) => {
           if (rowIndex === action.rowIndex) {
               reportLink.actionConditionObject  = reportLink.actionConditionObject.map((actionConditionObject, conditionIndex) => {
                   if (conditionIndex === action.conditionIndex) {
                       actionConditionObject = action.newValue;
                       return actionConditionObject;
                   } else {
                       return actionConditionObject;
                   }
               }) 
               return reportLink;
           } else {
               return reportLink;
           }

       }) 
    
    return dataViewReportLinks; 
};
export const dataViewNewCondition = (rowIndex,newObject, dataViewReportLinks) => {
    return dispatch => {
        dispatch(getDataViewsNewConditionRequest());
        dispatch(getDataViewNewConditionNewSuccess(rowIndex,newObject, dataViewReportLinks));
    }
};
export const dataViewNewConditionValue= (action) => {
    let dataViewReportLinks = action.dataViewReportLinks.map((reportLink, rowIndex) => {
        if (rowIndex === action.rowIndex) {
            reportLink.actionConditionObject.push(action.newObject); 
            return reportLink;
        } else {
            return reportLink;
        }

    })
    return dataViewReportLinks; 
}
export const dataViewCancelCondition = (rowIndex, oldConditionValue, dataViewReportLinks) => {
    //Cancel Update condition  
    return dispatch => {
        dispatch(getDataViewsCancelConditionRequest());
        dispatch(getDataViewConditionCancelSuccess(rowIndex, oldConditionValue, dataViewReportLinks));
    } 
}
export const dataViewCancelConditionValue = (action) => {
    //Cancel condition  change
    let dataViewReportLinks = action.dataViewReportLinks.map((reportLink, rowIndex) => {
        if (rowIndex === action.rowIndex) {
            reportLink.actionConditionObject = action.oldConditionValue[rowIndex].actionConditionObject;
            return reportLink;
        } else {
            return reportLink;
        } 
    })  
    return dataViewReportLinks;  
};
export const dataViewRemoveCondition = (rowIndex, conditionIndex, dataViewReportLinks) => {
    //Remove  condition  
    return dispatch => {
        dispatch(getDataViewsRemoveConditionRequest());
        dispatch(getDataViewRemoveConditionSuccess(rowIndex, conditionIndex, dataViewReportLinks));
    }
}
export const dataViewRemoveConditionValue = (action) => {
    //Cancel condition  change
    let dataViewReportLinks = action.dataViewReportLinks.map((reportLink, rowIndex) => {
        if (rowIndex === action.rowIndex) {
            reportLink.actionConditionObject.splice(action.conditionIndex, 1); 
            return reportLink; 
        } else {
            return reportLink;
        }
    })
    return dataViewReportLinks;
};
export const dataViewDeleteLink = (rowIndex,  dataViewReportLinks) => {
    //Delete  link  
    return dispatch => {
        dispatch(getDataViewsDeleteLinkRequest());
        dispatch(getDataViewDeleteLinkSuccess(rowIndex, dataViewReportLinks));
    }
}
const dataViewDeleteLinkValue = (rowIndex, reportLinks) => {
    let dataViewReportLinks = [...reportLinks];
    dataViewReportLinks.splice(rowIndex, 1);
    if (rowIndex === reportLinks.length - 1) {
        dataViewReportLinks.push(newReportLink()[0]);
    }
    return dataViewReportLinks;
};

export const dataViewRemoveConditionParameter = (conditionIndex,parameterRowIndex ,dataViewReportLinks) => {
    //Remove  condition  
    return dispatch => {
        dispatch(getDataViewsRemoveConditionParameterRequest());
        dispatch(getDataViewRemoveConditionParamaterSuccess(conditionIndex, parameterRowIndex, dataViewReportLinks));
    }
}
const dataViewRemoveConditionParameterValue = (index, parameterRowIndex, dataViewLinks) => {
    //Cancel condition  change
    let newReportLinks = cloneDeep(dataViewLinks);
    newReportLinks = newReportLinks.map((link, conditionIndex) => {
        if (conditionIndex === index) {
            let reportLink = [...link.reportLinkDetails];
            reportLink.splice(parameterRowIndex, 1);
            if (parameterRowIndex === link.reportLinkDetails.length - 1) {
                reportLink.push(conditionParameterObject()[0])
            }
            link.reportLinkDetails = reportLink
            return link;
        } else {
            return link;
        }
    })
    return newReportLinks;
};

export const dataViewNewConditionParameter = (conditionIndex, paramObjectArray, dataViewReportLinks, clearExistingParams) => {
    return dispatch => {
        dispatch(getDataViewsNewConditionParameterRequest());
        dispatch(getDataViewNewConditionParameterSuccess(conditionIndex, paramObjectArray, dataViewReportLinks, clearExistingParams));
    }
};
export const dataViewNewConditionParameterValue = (action) => {
    let dataViewReportLinks = action.dataViewReportLinks.map((reportLink, rowIndex) => {
        if (rowIndex === action.rowIndex) {
            if (reportLink.reportLinkDetails === undefined || action.clearExistingParams) {
                reportLink.reportLinkDetails = [];
            }
            action.paramObjectArray.map((paramObj) => {
                reportLink.reportLinkDetails.push(paramObj);
            })
            return reportLink;
        } else {
            return reportLink;
        }
    })
    return dataViewReportLinks;
};
 


