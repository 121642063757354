import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { AppBar, Typography, Box } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ObjectType } from 'client/components/Common/Enum';
import { menuTheme } from './theme/menuTheme';
import ActionBackdrop from 'admin/components/section/settings/Backdrop';
import { ActionTypes as actionTypes } from 'admin/actions/actionTypes';
import TabContentWrapper from './TabContentWrapper';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" id={`simple-tabpanel-${index}`}{...other}>
            <Typography component={'div'}>{children}</Typography>
        </div>
    );
}
const ContentList = (props) => {
    const [tabIndex, setTabIndex] = useState(0);
    let rolesList = (props.roles.rolesData) ? props.roles.rolesData : {};
    const { classes } = props;

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };
    
    const setFieldList = (tableDataFieldList, type) => {
        const array = tableDataFieldList.map((tableItem, index) => {
            let contentlist = {};

            if (type === ObjectType.Report) {
                contentlist.id = tableItem.reportId;
                contentlist.contentType = "Data View";
                contentlist.name = tableItem.reportName;
                contentlist.type = tableItem.type;
            }
            if (type === ObjectType.Form) {
                contentlist.id = tableItem.formId;
                contentlist.contentType = "Form";
                contentlist.name = tableItem.formName;
                contentlist.type = tableItem.layoutType;
            }
            if (type === ObjectType.Dashboard) {
                contentlist.id = tableItem.id;
                contentlist.contentType = "Dashboard";
                contentlist.name = tableItem.name;
                contentlist.type = "";
            }

            //contentlist.quickActionChecked = false;
            //contentlist.mainMenuChecked = false;

            return contentlist;
        })
        return array;
    }

    return (
        
        <React.Fragment>
            {props.roles.getRoleRequestStatus === actionTypes.VGO_GET_ROLE_REQUEST && <ActionBackdrop />}
            <Box mr={-5}>
                <AppBar position="static" className={classes.tabRoot}  >
                    <Tabs value={tabIndex} onChange={handleTabChange} aria-label="tabs" variant="fullWidth" className={classes.tabsRoot} >
                        <Tab className={classes.body2} label="DATA VIEWS" value={0} classes={{ root: classes.dataViewTab }} />
                        <Tab className={classes.body2} label="FORMS" value={1} classes={{ root: classes.formTab }} />
                        <Tab className={classes.body2} label="DASHBOARDS" value={2} classes={{ root: classes.dashboardTab }} />
                    </Tabs>
                </AppBar>
            </Box>
            <Box mt={1} mr={-5} border={1} borderColor="grey.300" height={620} style={{ overflow: 'auto' }} >
                <TabPanel value={tabIndex} index={0} >

                    {(Object.keys(rolesList).length > 0) ? <Box className={classes.DatabaseFieldsStyle}>
                        
                        <div style={{ display: tabIndex == 0 ? 'block' : 'none' }}>
                            <TabContentWrapper
                                TableDataField={setFieldList(rolesList.reportList, ObjectType.Report)}
                                handleDrop={props.handleDrop}
                                isFromMenu={true}
                                roleId={props.roles.role.roleId}
                            />

                        </div>
                        <div style={{ display: tabIndex == 1 ? 'block' : 'none' }}>
                            <TabContentWrapper
                                TableDataField={setFieldList(rolesList.formList, ObjectType.Form)}
                                handleDrop={props.handleDrop}
                                isFromMenu={true}
                                roleId={props.roles.role.roleId} />
                        </div>
                        <div style={{ display: tabIndex == 2 ? 'block' : 'none' }}>
                            <TabContentWrapper
                                TableDataField={setFieldList(rolesList.dashboardList, ObjectType.Dashboard)}
                                handleDrop={props.handleDrop}
                                isFromMenu={true}
                                roleId={props.roles.role.roleId} />
                        </div>

                    </Box> :[]
                    }
                </TabPanel>
            </Box>
        </React.Fragment>
    );
}

function mapStateToProps(state) {
    return {
        roles: state.roles,
    };
};

export default withRouter(connect(mapStateToProps)(withStyles(menuTheme)(ContentList)));