import React from 'react';

import { Button, Grid, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';



class DataViewListHeader extends React.Component {

    onClickNewDataView = () => {
        this.props.history.push(`/Admin/DataViews/new`);
    }

    render() {
        return (
                <Grid container direction="row" justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h4">Data Views</Typography>
                    </Grid>
                    <Grid item>
                            <Button
                            variant="contained"
                            color="primary"
                            disableElevation
                                startIcon={<AddIcon />}
                                onClick={this.onClickNewDataView}
                            >ADD DATA VIEW</Button>
                    </Grid>
                </Grid>
        );
    }

}

function mapStateToProps(state) {
    return {
        alert: state.alert,
    };
};

export default withRouter(connect(mapStateToProps)(DataViewListHeader));
