const requestLayout = 'REQUEST_LAYOUT';
const receiveLayout = 'RECEIVE_LAYOUT';
const receiveLeftMenu = 'RECEIVE_LEFTMENU';

const requestSiteTheme = 'REQUEST_SITE_THEME';
const receiveSiteTheme = 'RECEIVE_SITE_THEME';


const initialState = {
    isLogin: false,
    roleId: 0,
    isLoading: true,
    actionSource: 'Initial',
    sessionTimeOut: 0,
    isMenuCollapsed:false,
    siteTheme: {
        theme: '',
        companyLogo: '',
        isLoading: false,
    },
};

export const layoutActionCreator = {
    setLayout: (isLogin, roleId) => async (dispatch, getState) => {

        dispatch({ type: requestLayout});

        dispatch({ type: receiveLayout });
        //dispatch({ type: receiveLayout, isLogin: true, roleId: 1, isLoading: false, roleList: [{ id: 1, name: 'Role1' }, {id:2,name:'Role2'}] });
    },
    getSiteTheme: () => async (dispatch, getState) => {

        dispatch({ type: requestSiteTheme });
        const url = 'api/Object/GetSiteTheme';
        const response = await fetch(url);
        if (response.status === 200) {
            const objectSiteTheme = await response.json();
            dispatch({ type: receiveSiteTheme, siteTheme: { ...objectSiteTheme, isLoading: true, } });
        }
        else {
            const siteTheme = {
                theme: '',
                companyLogo: '',
                isLoading: false,
            };
            dispatch({
                type: "SHOW_ERROR",
                message: "Site theme loading failed"
            });
            dispatch({ type: receiveSiteTheme, siteTheme: siteTheme });

        }

    }

};

export const reducer = (state, action) => {
    state = state || initialState;
    if (action.type === requestLayout) {
        return {
            ...state
        };
    }
    else if (action.type === receiveLayout) {
        return {
            ...state,
            isLogin: action.isLogin,
            roleId: action.roleId,
            actionSource: action.actionSource,
            sessionTimeOut: action.sessionTimeOut ? action.sessionTimeOut : state.sessionTimeOut,
        };
    }
    else if (action.type === requestSiteTheme) {
        return {
            ...state,
        };
    }
    else if (action.type === receiveSiteTheme) {
        return {
            ...state,
            siteTheme: action.siteTheme,
        };

    } else if (action.type === receiveLeftMenu) {
        return {
            ...state,
            isMenuCollapsed: action.isMenuCollapsed
        }
    }
    return state;
};