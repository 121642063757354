import { theme, customThemeStyles } from 'common/theme.js';
export const companyTheme = () => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        height: 136,
        width: 255,
        left: 0,
        top: 0,
    },
    logo: {
        //height: '83px!important',
        //width: '140px!important', 
        width: '157px!important',
        marginTop: '25px!important'

    },
    link: {
        color: '#007ad9',
        textDecoration: 'none',
    },
    name: {
        left: "67px",
        fontFamily: 'Roboto-Bold',
        fontWeight: "900",
        fontSize: "24px",
        lineHeight: "24px",
        letterSpacing: "0.16px",
        color: "white",
        margin: theme.spacing(-1, 0, 0, 0),
        textAlign: 'center',
    },
    customTheme: customThemeStyles.customTheme,
    siteLogo: {
        width: 'auto',
        maxHeight: '140px',
        minHeight: '60px',
        marginTop: "-8px",
        paddingTop: "6px",
    }
});