import React from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { withRouter } from 'react-router';
import { getAlertHide } from 'admin/actions/alertActions';
import AlertMessage from 'admin/components/Common/AlertMessage';
import { hideErrorMessage } from 'admin/actions/roleActions';


class RoleListHeader extends React.Component {
    handleClick = () => {
        this.props.dispatch(hideErrorMessage());
        this.props.history.push("/Admin/Roles/new");
    }
    
    render() {
        let alert = this.props.alert;
        return (
				<Grid container direction="row" justifyContent="space-between">
					<Grid item>
						<Typography variant="h4">Roles</Typography>
                    </Grid>
					<Grid item>
							<Button
								variant="contained" 
								color="primary" 
								disableElevation 
								startIcon={<AddIcon />} onClick={this.handleClick}>ADD Role</Button>
					</Grid>
				</Grid>
        );
    }

}
function mapStateToProps(state) {
    return {
        roles: state.roles,
        isLoading: state.loader.isLoading,
        alert: state.alert,
    };
};
export default withRouter(connect(mapStateToProps)(RoleListHeader));

