import { customThemeStyles } from 'common/theme.js';
import { customColorPalette } from 'common/theme.js';
export const dashboardTheme = (theme) => ({
    ...customThemeStyles,
    
    deleteColumn: { width: "60px!important" },
    nameColumn: { width: "300px!important", wordBreak: "break-word!important" },
    categoryColumn: { width: "200px!important", wordBreak: "break-word!important" },
    dateColumn: { width: "150px!important" },
    addButton:{

        maxWidth: '185px!important',
        maxHeight: '36px!important'
    },

    MUIDataTablePadding: {
        paddingTop: '10px!important',
       
    },
    
    root: {
        justifyContent: 'center!important',
        flexWrap: 'wrap!important',
        height: "93px!important",
        borderRadius: "4px!important"
    },
    card: {
        //width: "724px",
        height: "100%!important",
        marginLeft: theme.spacing(2) +'!important',
        marginRight: theme.spacing(2) + '!important'
    },
    chip: {
        margin: theme.spacing(0.5) + '!important'
    },
    gridColumn: { wordBreak: "break-word" },

    subGrid: {
        //maxWidth: '640px!important'

    },
    mainGridItem: {
        maxWidth: '696px!important'

    },


    gridPadding: {

        paddingBottom: '8px!important'
    }
    ,
    paddingEmpty: {

        paddingBottom: '0px!important',
        paddingTop: '0px!important'
    },

    enablePaging: {

        paddingTop: '0px!important'
    },
    panelInnerFirst: {
        position: "relative",
        height:"100%"
    },
    panelInnerSecond: {
    /* Center vertically and horizontally */
        position: "absolute",
        top: "50%",
        left: "50%",
        margin: "-25px 0 0 - 25px",
    },
    addPanelButton: {
        width: '92px!important',
        height: '32px!important',
            fontFamily: "Roboto!important",
            fontSize: "12px!important",
            fontStyle: "normal!important",
            fontWeight: "400!important",
            lineHeight: "14px!important",
            letterSpacing: "1.25px!important",
            textAlign: "left!important",

        
    },
    panelObjectIcon: {
        color: customColorPalette.black[54],
        //verticalAlign: "middle",
        position: 'relative',
        top: '6px',
    },
    panelHeader: {
        color: "rgba(0, 0, 0, 1)",
        whiteSpace: "normal",
    },
    panelContentOutterBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height:"100%",
    },
    panelContentInnerBox: {
        width: "234px",
        textAlign: "center",
    },
});