import React from 'react'; 
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'; 
import MUIDataTable from 'mui-datatables'; 
import { Box } from '@mui/material'
import { theme } from 'common/theme.js';
import { produce } from 'immer'; 
import { getMenus, deleteMenu, getContentDragData } from 'admin/actions/menuActions';
import DeleteButton from 'common/DeleteButton.js';
import { objectListFormatDate } from 'admin/components/Common//DateFormat';
import { menuListTheme } from './theme/menuListTheme';
import { withStyles } from '@mui/styles';
import ActionBackdrop from 'admin/components/section/settings/Backdrop';

import { MuiGridOptions } from 'admin/components/Common/CommonOptions';


const defaultColumnOptions = {
    filter: true,
    sort: true,
    setCellProps: (cellValue, rowIndex, columnIndex) => {
        return {
            style: {
                padding: theme.spacing(1)         // use the theme helper function?
            }
        };
    }
};

class MenuList extends React.Component {
    constructor(props) {
        const { classes } = props;
        super(props);
        this.state = {
            columns: [
                {
                    name: "menuName",
                    label: "Name",
                    options: {
                        ...defaultColumnOptions,
                        setCellProps: () => ({ className: classes.nameColumn }),
                    }
                },
                {
                    name: "menuDescription",
                    label: "Description",
                    options: {
                        ...defaultColumnOptions,
                        setCellProps: () => ({ className: classes.descriptionColumn }),
                    }
                },
                {
                    name: "roleName",
                    label: "Role",
                    options: {
                        ...defaultColumnOptions,
                        setCellProps: () => ({ className: classes.nameColumn }),
                    }
                },
                {
                    name: "created",
                    label: "Created",
                    options: {
                        ...defaultColumnOptions,
                        customBodyRender: objectListFormatDate,
                        setCellProps: () => ({ className: classes.dateColumn }),
                         
                    }
                },
                {
                    name: "updated",
                    label: "Updated",
                    options: {
                        ...defaultColumnOptions,
                        customBodyRender: objectListFormatDate,
                        setCellProps: () => ({ className: classes.dateColumn }),
                    }
                },
                {
                    name: "",
                    label: "",
                    options: {
                        ...defaultColumnOptions,
                        customBodyRender: this.deleteRowTemplate,
                        setCellProps: () => ({ className: classes.deleteColumn }),
                        filter: false,
                    }
                }
            ],
            options: {
                download: false,
                elevation: 0,
                filter: true,
                filterType: 'multiselect',
                pagination: false, 
                responsive: 'scrollMaxHeight',
                jumpToPage: true,
                
                onCellClick: (colData, cellMeta) => {
                    let dataIndex = cellMeta.dataIndex;
                    let menu = this.props.menus[dataIndex];
                    let columnIndex = this.state.columns.findIndex(c => c.name === "");
                    if (columnIndex !== cellMeta.colIndex)
                        this.getMenuData(menu.menuId)
                },
                print: false,
                searchOpen: true,
                /*searchPlaceholder: 'Enter search text ...',*/
                selectableRows: 'none',
                sortOrder: {
                    name: 'menuName',
                    direction: 'asc'
                },
                onFilterChange: (changedColumn, filterList, type, changedColumnIndex, displayData) => {
                    this.setState(produce(draft => {
                        draft.columns[changedColumnIndex].options.filterList = filterList[changedColumnIndex];
                    }));
                },
                onColumnSortChange: (changedColumn, direction) => {
                    this.setState(produce(draft => {
                        draft.options.sortOrder = { name: changedColumn, direction: direction };
                    }));
                }
            }
        };
    }

    componentDidMount() {
        this.dispatchGetMenus();
    }

    dispatchGetMenus() {
        this.props.dispatch(getMenus()); 
    }

    getMenuData(menuId) {
        this.props.dispatch(getContentDragData({ item: { id: "", contentType: "" } }));
        this.props.history.push(`/Admin/Menus/${menuId}`);
    }
    deletePreValidation = () => {
        return { canDelete: true, message: "" }
    }
    deleteRowTemplate = (value, tableMeta, updateValue) => {
        const columnIndex = 0;
        return <DeleteButton preValidation={this.deletePreValidation} onClick={this.dispatchDeleteMenu} id={tableMeta.rowData[columnIndex]} />
    }

    dispatchDeleteMenu = (name) => {
        const menu = this.props.menus.find(u => u.menuName === name);
        this.props.dispatch(deleteMenu(menu.menuId));
    }

    render() {
        const { classes } = this.props;
        return (
            <Box border={1} className={classes.commonObjectListBox}>
                {this.props.isLoading && <ActionBackdrop />}
                <MUIDataTable container
                    title={''}
                    data={this.props.menus}
                    columns={this.state.columns}
                    options={MuiGridOptions(this.state.options, this.props.isDataLoading)}
                />
            </Box>
        );
    }
}

function mapStateToProps(state) {

    return {
        menus: state.menus.menus,
        isLoading: state.loader.isLoading,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage,
         isDataLoading: state.menus.isLoading,
    };
};

export default withRouter(connect(mapStateToProps)(withStyles(menuListTheme)(MenuList)));