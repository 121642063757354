import { theme, customThemeStyles } from 'common/theme.js';
const drawerWidth = 302;

export const leftPanelTheme = () => ({
    ...customThemeStyles,
    drawer: {
        width: drawerWidth + '!important',
        flexShrink: 0 + '!important',
        // whiteSpace: 'nowrap', 
    },
    border: {
        border: 0 + '!important',
    },
    drawerOpen: {
        width: drawerWidth,
        background: `linear-gradient(0deg, ${customThemeStyles.neutral.backgroundColor} 19%, ${customThemeStyles.customTheme.backgroundColor} 39%)`,
        overflowX: 'hidden !important',
        overflowY: 'auto!important',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp + '!important',
            duration: theme.transitions.duration.enteringScreen + '!important',

        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp + '!important',
            duration: theme.transitions.duration.leavingScreen + '!important',
        }),
        overflowX: 'hidden !important',
        width: theme.spacing(8) + '!important',
        //[theme.breakpoints.up('sm')]: {
        //    width: theme.spacing(9) + 1,
        //},
    },
    drawerPaper: {
        width: drawerWidth + '!important',

    },
    burgerExpanded: {
        display: 'flex!important',
        alignItems: 'center!important',
        padding: theme.spacing(2.31, 2.74, 0, 0) + '!important',
        justifyContent: 'flex-end!important',

    },
    burgerCollapsed: {
        display: 'flex!important',
        alignItems: 'center!important',
        padding: theme.spacing(3) + '!important',
        justifyContent: 'flex-end!important',
        height: theme.spacing(8) + '!important',

    },
    burgerIcon: {
        "& .MuiSvgIcon-root": {
            color: theme.palette.primary.contrastText + '!important',
        },
    },
    scrollbarHorizontal: {
        display: 'none!important',
    },
    siteThemePosition: {
        position: 'initial !important'
    },

    collapseLeftSide: {
        /*  *//* [theme.breakpoints.only('xs')]: {*/
        display: "none"

        /*},*/


    },
    burgerIconRoot: {

        cursor: "pointer"
    },
    menuBox: {
        //backgroundColor: customThemeStyles.neutral.backgroundColor + '!important',
    },
    leftPanelColor: {
        backgroundColor: customThemeStyles.neutral.backgroundColor + '!important',
    },
    menuPadding: {
        flexGrow: 1,
        //paddingBottom: "150px",
    },
    leftPanelFlex: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
    }
});