import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { errorActionCreator } from 'client/store/Error';
import { alertHandlerAction } from 'client/store/AlertHandler';
import AlertHandlerMessage from 'client/components/Common/AlertHandlerMessage';
import { alertStyle } from 'client/components/Common/theme/AlertStyle';

class errorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        });

        
        this.props.actions.errorActionCreator.logErrorMessage(error + "\n" + errorInfo.componentStack);
        this.props.actions.alertHandler.showErrorMessage(
            'Oops! A system error occurred. Please notify your system administrator.');
        // You can also log the error to an error reporting service
        //logErrorToMyService(error, errorInfo);
        
    }

    render() {
        if (this.state.errorInfo) {
            // Error path
            return (
                <div className={this.props.classes.errorBoundaryStyle}>
                    <AlertHandlerMessage />
                </div>
                );


        
        }
        return this.props.children; 
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            errorActionCreator: bindActionCreators(errorActionCreator, dispatch),
            alertHandler: bindActionCreators(alertHandlerAction, dispatch)
        }
    };
}

export default connect(
    state => state,
    mapDispatchToProps
)(withStyles(alertStyle)(errorBoundary));
//export default errorBoundary;