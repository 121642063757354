import React from 'react';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import { TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';

import FormControl from '@mui/material/FormControl';
import ErrorIcon from '@mui/icons-material/Error';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Button, Grid } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh'
import { SettingStyle } from 'admin/components/section/settings/theme/SettingsStyle'
const useStyles = makeStyles((theme) => (SettingStyle(theme)));
export default function AdminPasswordControl(props) {
    const [values, setValues] = React.useState({
        password: props.value,
        showPassword: false,
        disabled: true,
        
    });

    const [error, setError] = React.useState({
        passwordError: false,
        passwordHelper: ' '
    });

        
    
    const handleChange = (prop) => (event) => {
       // setValues({ ...values, [prop]: event.target.value });
       props.updateDataSource(event.target.value);
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };
    const handleClickUpdatePassword = () => {
        setValues({ ...values, disabled: false });
    };
  
    const style = useStyles();

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (<TextField label={props.label} error={props.passwordError} helperText={props.passwordHelper} 
        id="outlined-adornment-password"
name='Password'
                           type={values.showPassword ? 'text' : 'password'}
                                value={props.value}
        onChange={handleChange('password')}
        onBlur={props.onBlur}
        required
        inputProps={{ className: style.emailPassword}}
        InputProps={{
            endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )}}
         />
                            
                 
    );

}