import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Collapse, IconButton } from '@mui/material';
import { Alert } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, Button } from '@mui/material';
import { alertStyle } from 'client/components/Common/theme/AlertStyle';
import { Typography } from '@mui/material';
/**
 * AlertMessage Component
 */
class AlertMessage extends React.Component {
    timerId;
    componentWillUnmount() {
        if (this.timerId) {
            clearTimeout(this.timerId);
            this.timerId = "";
        }

    }
    shouldComponentUpdate(nextProps) {

        if (!nextProps.open && (!this.props.timerId && nextProps.timerId)) {
            return false;
        }

        return true;
    }
    componentDidUpdate(prevProps) {
        if (this.props.open) {
            window.scrollTo(0, 0);
        }
    }
    renderClose = () => {
       
            return (
                <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={this.props.close}
                >
                    <CloseIcon className={this.props.classes.alertCloseIcon} fontSize="inherit" />
                </IconButton>
            );
        
    }
    renderActions = () => {
        if (this.props.close && this.props.severity !== "success") {
            return (



                <Grid container className={this.props.classes.alertContainer}>
                    <Grid item>
                        <Button id="btnConfirmYes" color="inherit" size="small" className={this.props.classes.alertActionButton} onClick={this.props.action}>YES</Button>
                    </Grid>
                    <Grid item>
                        <Button color="inherit" size="small" className={this.props.classes.alertActionButton} onClick={this.props.close}>NO</Button>
                    </Grid>
                    <Grid item >
                        <Box pt={.2}>
                            <IconButton
                                aria-label="close"
                                size="small"
                                onClick={this.props.close}
                            >
                                <CloseIcon
                                    className={this.props.classes.alertCloseIcon} fontSize="inherit" />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>


            );
        }
        else {
            return null;
        }
    }


    /**
     * Render the AlertMessage
     */
    render() {
        const { classes } = this.props;
        if (this.props.open && this.props.timerId) {
            this.timerId = this.props.timerId;
        }

        return (
           

            <Collapse in={this.props.open} classes={{ wrapper: classes.wrapper }}  >

                <Alert classes={{ root: classes.root }}
                    action={this.props.isAction ? this.renderActions() : this.renderClose()}
                    icon={this.props.icon}
                    severity={this.props.severity}
                >
                    <Typography className={classes.messageStyle}>{this.props.message}</Typography>
                    
                </Alert>
            </Collapse>

        );

    }

}

/**
 * Default properties for the AlertMessage
 */
AlertMessage.defaultProps = {
    open: false,
    severity: 'success',
    title: '',
    message: '',
    icon: <CheckIcon fontSize="inherit" />,
    close: null,
    action: null,
};

/**
 * Property types for the AlertMessage properties
 */
AlertMessage.propTypes = {
    open: PropTypes.bool,
    severity: PropTypes.oneOf([
        'success',
        'info',
        'warning',
        'error',
    ]),
    title: PropTypes.string,
    message: PropTypes.string,
    icon: PropTypes.element,
    close: PropTypes.func,
    action: PropTypes.func,
};

export default withStyles(alertStyle)(AlertMessage)
