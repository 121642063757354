import { theme, customThemeStyles } from 'common/theme.js';
export const basicHeaderTheme = {
    heading: {
        textAlign: "center",
        wordBreak: "break-word",
      /*  minWidth: "190px!important",*/
        fontSize:"10px"
    },
    header: {
        paddingTop: "12px",
        marginBottom: "-34px"
    },
    homeIcon: {
        

        [theme.breakpoints.up('sm')]: {
            fontSize: '40px!important',
            color: customThemeStyles.customTheme.backgroundColor,

        },

        [theme.breakpoints.down('sm')]: {
            fontSize: '20px!important',
            color: customThemeStyles.customTheme.backgroundColor,
          
        }

    },
    cardHome: {
          
        background: '#F5F5F5',
        padding: '0px!important',
        borderRadius: 0,
        marginBottom: "11px!important",
                
    },
    title: {
         

        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 400,
            letterSpacing: "0.25px",
            minWidth: "122px",
            wordBreak: "break-word",
            whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"
        },

        [theme.breakpoints.up('sm')]: {
            fontSize: '26px!important',
            fontFamily: "Roboto!important",
            fontStyle: "normal!important",
            fontWeight: 400,
            letterSpacing: "0.25px!important",
            minWidth: "194px",
            wordBreak: "break-word",
            whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"
        },
        

    },
    favoriteIcon: {


        [theme.breakpoints.up('sm')]: {
            //fontSize: '40px!important',
            color: customThemeStyles.customTheme.backgroundColor,
            textAlign: "right",
            cursor: "pointer"

        },

        [theme.breakpoints.down('sm')]: {
            fontSize: '20px!important',
            color: customThemeStyles.customTheme.backgroundColor,
            textAlign: "right",
            cursor: "pointer"

        }

    },


}