import React from 'react';

import ReactChartCanvas from 'common/react-chart-canvas/index';
import Funnel from './funnel';

const FunnelChart = (props) => {
  return <ReactChartCanvas Chart={Funnel} {...props}/>;
};

export default FunnelChart;
