const initialState = {
    isLoading: false,
}

export default function loader(state = initialState, action) {
    return {
        ...state,
        isLoading: action.type.startsWith('VGO') && action.type.endsWith('REQUEST'),
        type: action.type,
           
    }
};