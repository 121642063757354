import { produce } from 'immer';
import { ActionTypes as types } from 'admin/actions/actionTypes';
const initialState = {
    systemSettings: {
        recordCount: "",
        bizweaverToken: "",
        formFileSize: "",
        allowedExtensions: "",

        emailSettings: {
            sender: "",
            mailServer: "",
            username: "",
            password: "",
            mailPort: "",
            enableSSL: false,
            senderName: "",
        },
        paymentSettings: {
            paymentGateway: "",
            merchantUserID: "",
            merchantPassword: "",
            isCCDemoAccount: false,
            iseCheck: false,
            isECheckDemoAccount: false,
            hasVisa: false,
            hasMaster: false,
            hasAmex: false,
            hasDiscover: false,
        },
        paymentGatewayList: [{ id: "authorizeDotNet", paymentGatewayName: "Authorize.Net" }],
        systemTimeZone: "none",
        sessionTime: {
            hours: 0,
            minutes: 0,
            seconds: 0,
        },
        resetPasswordSettings: {
            fromEmail: '',
            subject: '',
            message:'',
        },
        installationID: {
            installationID: '',
            activeUsers: 0,
            licensedUsers: 0,
            message:''
        }
    },
    version: ''
}


const systemSettings = produce((draft, action) => {

    //console.log('systemSetting reducer - action: ', action);

    switch (action.type) {

    

        case types.VGO_UPDATE_SYSTEM_SETTINGS_SUCCESS:
        case types.VGO_GET_SYSTEM_SETTINGS_SUCCESS:
            draft.systemSettings = action.systemSettings;
            draft.initialSystemSettings = action.systemSettings;
            break;

        case types.VGO_UPDATE_EMAIL_SETTINGS_SUCCESS:
            draft.systemSettings.emailSettings = action.emailSettings;
           break;

        case types.VGO_UPDATE_PASSWORD_SETTINGS_SUCCESS:
            draft.systemSettings.resetPasswordSettings = action.passwordSettings;
            break;

        case types.VGO_CHANGE_SYSTEM_SETTINGS:
            action.change(draft.systemSettings);
            break;
       
        
        case types.VGO_DISCARD_SYSTEM_SETTINGS:
            draft.systemSettings = draft.initialSystemSettings;
            draft.systemSettings.resetKey = !draft.systemSettings.resetKey ? 1 : draft.systemSettings.resetKey+1;
            break;

        case types.VGO_GET_EMAIL_SETTINGS_ALERT_SUCCESS:
            draft.notification = action.alert;
            draft.mailSendInitiated = action.false;
            break;

        case types.VGO_GET_EMAIL_CHECK_REQUEST:
            draft.mailSendInitiated = action.status;
            break;

        case types.VGO_GET_SYSTEM_SETTINGS_CLEAR_SUCCESS:
            draft.systemSettings.emailSettings = action.emailSettings;
            break;
            
        case types.VGO_GET_SYSTEM_RESET_PASSWORD_CLEAR_SUCCESS:
            draft.systemSettings.resetPasswordSettings = action.passwordSettings;
            break;

        case types.VGO_UPDATE_RECORD_COUNT_CLEAR_SUCCESS:
            draft.systemSettings.recordCount = action.recordCount;
            break;

        case types.VGO_UPDATE_SETTINGS_SUCCESS:
            draft.systemSettings = action.settings;
            break;

        case types.VGO_UPDATE_BIZWEAVER_TOKEN_CLEAR_SUCCESS:
            draft.systemSettings.bizweaverToken = action.token;
            break;

        case types.VGO_UPDATE_SESSION_PERIOD_CLEAR_SUCCESS:
            draft.systemSettings.sessionTime = action.sessionTime;
            break;

        case types.VGO_UPDATE_TIMEZONE_CLEAR_SUCCESS:
            draft.systemSettings.systemTimeZone =action.data
            break;

        case types.VGO_UPDATE_FILE_UPLOAD_CLEAR_SUCCESS:
            draft.systemSettings.formFileSize =action.size;
                draft.systemSettings.allowedExtensions = action.format;
            break;
        case types.VGO_UPDATE_INSTALLATION_CLEAR_SUCCESS:
            draft.systemSettings.installationID = action.data;
            break;
        case types.VGO_UPDATE_PAYMENT_GATEWAY_SUCCESS:
            draft.systemSettings.paymentSettings = action.data;
            break;
        case types.VGO_GET_VERSION_SUCCESS:
            draft.version = action.version;
            break;
            
            
            

    default:
        break;

    }

}, initialState);

export default systemSettings;