import { produce } from 'immer';
import { ActionTypes as types } from 'admin/actions/actionTypes';

const initialState = {
    open: false,
    severity: 'success',
    title: '',
    message: '',
    icon: null,
    source:'',
};

const alert = produce( ( draft, action ) => {

    switch ( action.type ) {

        case types.VGO_ALERT_SHOW:
            draft.open = action.alert.open;
            draft.severity = action.alert.severity;
            draft.title = action.alert.title;
            draft.message = action.alert.message;
            draft.icon = action.alert.icon;
            draft.action = action.alert.action
            draft.source = action.alert.source
            break;
    
        case types.VGO_ALERT_HIDE:
            draft.open = false;
            draft.severity = action.alert.severity;
            draft.title = '';
            draft.message = '';
            draft.icon = null;
            break;

        default:
            break;

    }

}, initialState );

export default alert;
