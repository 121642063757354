import React from 'react';

import { styled } from '@mui/styles';
import { withStyles } from '@mui/styles';
import { spacing } from '@mui/system';
import { Box, Grid, Typography } from '@mui/material';
import MuiButton from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check'

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { updateDatabase } from 'admin/actions/databaseActions';

import { getAlertHide } from 'admin/actions/alertActions';
import AlertMessage from 'admin/components/Common/AlertMessage';
import { databaseTheme } from './theme/databaseTheme';
import ActionBackdrop from 'admin/components/section/settings/Backdrop';

const Button = styled(MuiButton)(spacing);


class DatabaseEditHeader extends React.Component {

    constructor(props) {
        super(props);
        //this.state = {
        //    isUpdate: this.props.match.params.databaseId !== "new",
        //    //checkClose: false
        //}
        this.isUpdate = this.props.match.params.databaseId !== "new";
    }
    componentDidUpdate() {
        this.isUpdate = this.props.match.params.databaseId !== "new";
    }
    onDiscardClick = () => {
        //this.props.dispatch({ type: types.VGO_DISCARD_DATABASE });
        this.props.history.push('/Admin/Databases');
    }

    onSaveClick = () => {
        this.props.dispatch(updateDatabase({...this.props.database, validatePassword: this.props.validatePassword}));
    }

    render() {
        let alert = this.props.alert;
        console.log("IsUpdate", this.isUpdate);
        return (
            <Grid container direction="row" justifyContent="space-between" spacing={0} xl={12}>
                {this.props.database.actionStatus ==="VGO_UPDATE_DATABASE_REQUEST" && <ActionBackdrop />}
                <Grid item>
                    <Typography variant="h4">{this.isUpdate ? "Edit" : "Add"} Connection</Typography>
                </Grid>
                <Grid item >
                    <Grid container direction="row" justifyContent="space-between" spacing={1} >
                        <Grid item >
                            <Button
                                className={this.props.classes.customControlButton }
                                variant="defaultContained"
                                disableElevation
                                startIcon={""}
                                onClick={this.onDiscardClick}
                            >CANCEL</Button>
                        </Grid>
                        <Grid item >
                            <Button
                                className={this.props.classes.customControlButton}
                                color="primary"
                                variant="contained"
                                disableElevation
                                startIcon={<CheckIcon />}
                                onClick={this.onSaveClick}
                            >SAVE</Button>
                        </Grid> </Grid>
                </Grid>
            </Grid> 
        );
    }

}

function mapStateToProps(state) {
    let props = {
        database: state.databases.database,
        alert: state.alert,
        validatePassword: state.databases.validatePassword,
    };
    return props;
};
 
export default withRouter(connect(mapStateToProps)(withStyles(databaseTheme)(DatabaseEditHeader)));
