import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import { getMenus, setChangeRoleData, setChangeRoleDataName  } from 'admin/actions/roleActions';
import { RoleTab } from 'admin/actions/actionTypes';
import { theme } from 'common/theme.js'; 
import { DateTime } from 'luxon'; 
import GridCheckBox from './GridCheckBox';
import { Checkbox , } from '@mui/material';
import { withStyles } from '@mui/styles';
import { rolesTheme } from './theme/rolesTheme.js';
import ActionBackdrop from 'admin/components/section/settings/Backdrop';
import { MuiGridOptions } from 'admin/components/Common/CommonOptions';
import { objectListFormatDate } from 'admin/components/Common//DateFormat';
const defaultColumnOptions = {
    filter: true,
    sort: true,
   setCellProps: ( cellValue, rowIndex, columnIndex ) => {
        return {
            style: {
                padding: theme.spacing(1) 
            }
        };
    }
};

class MenuList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    name: "activeState",
                    label: "Active",
                    options: {
                        customHeadRender: (columnMeta, updateDirection) => (
                            <td key="selectAll" className={props.classes.selectAll ? props.classes.selectAll : ""}><Checkbox className={this.props.classes.selectCheckbox} color="primary" onChange={this.handleChange}
                                checked={this.props.roles.menus?.length === this.props.roles.role.MenuList?.length ? true : false}
                            /></td>
                        ),
                        //...defaultColumnOptions,
                        filter: false,
                        customBodyRender: this.checkBoxTemplate,
                        //filterList: [ActiveState.active],
                    }
                },
                {
                    name: "id",
                    label: "id",
                    options: {
                        filter: false,
                        display: false,
                        viewColumns: false,
                    }
                },
               
                {
                    name: "label",
                    label: "Name",
                    options: { defaultColumnOptions }
                },
                {
                    name: "createDate",
                    label: "Created",
                    options: {
                        defaultColumnOptions,
                        customBodyRender: objectListFormatDate
                    }
                }
            ]
        };
    }

    componentDidMount() {
        this.dispatchGetMenu();
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.roleData !== undefined) {
            this.getSelectedRow(this.props)
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.roles.role.activeTab === RoleTab.menu || nextProps.roles.role.activeTab === RoleTab.roleName || nextProps.roles.role.activeTab === RoleTab.defaultObjectId || nextProps.roles.role.activeTab === RoleTab.defaultObjectTypeWithOutRefresh) {
            if (this.props.roles.menus !== undefined) {
                return false;
            } else { return true; }
        }
        return true;
    }
    dispatchGetMenu = () => {
        if (this.props.roles.menus === undefined ) {
            this.props.dispatch(getMenus());
        }
    }
    formatDate = (value) => {
        if (typeof (value) === "string") {
            value = DateTime.fromISO(value);
        }
        return value.toFormat('LL-dd-yyyy hh:mm:ss a');

    }
    getSelectedRow = (props) => {
        let array = []
        if (props.roles !== undefined) {
            if (props.roles.role.MenuList !== undefined && props.roles.menus!== undefined) {
                props.roles.role.MenuList.map((menu) => {
                    return props.roles.menus.map((role, index) => {
                        if (role.id === menu.Id) {
                            array.push(index)
                        }
                        return "";
                    });

                });
            }
        }

        return array


    }
    handleChange = (e) => {

        let array = [];
        if (e.target.checked) {

            for (let i = 0; i < this.props.roles.menus.length; i++) {
                var dashObject = this.props.roles.menus[i];
                array.push({ Id: dashObject.id, Name: dashObject.label })
            }
        }

        this.props.dispatch(setChangeRoleData(RoleTab.menuWithRefresh, '', this.props.roles.role.MenuList, array));


    }
    handleSelect = (Id, Name) => {

        this.props.dispatch(setChangeRoleDataName(RoleTab.menu, Id, Name, this.props.roles.role.MenuList));
    }
    checkBoxTemplate = (value, tableMeta, updateValue) => {

        let status = false;
        let rId = tableMeta.rowData[1];
        let rName = tableMeta.rowData[2];
        let formIndex = this.props.roles.role.MenuList.findIndex(c => c.Id === rId)

        if (formIndex !== -1) {
            status = true;
        }
        return (
            <GridCheckBox selectCheckboxclasses={this.props.classes.selectCheckbox ? this.props.classes.selectCheckbox : ""} checked={status} id={rId} name={rName} handleSelect={this.handleSelect} />
        )
    }
    render() {
        const { classes } = this.props;
            const options = {
                filterType: "textField",
                fixedHeader: true,
                sort: true,
                search: true,
                selectableRows: "none",
                responsive: 'scrollMaxHeight',
                download: false,
                elevation: 0,
                filter: true,
                filterType: 'multiselect',
                jumpToPage: true,
                searchOpen: false,
                pagination: false,
                onFilterChange: (changedColumn, filterList, type, changedColumnIndex, displayData) => {

                    //this.setState(produce(draft => {
                    //    draft.columns[changedColumnIndex].options.filterList = filterList[changedColumnIndex];
                    //}));
                },
                customToolbarSelect: () => { },
                onRowSelectionChange: (curRowSelected, allRowsSelected) => {
                    //let selectedReports = allRowsSelected.map((report) => {
                    //    return this.props.roles.reports[report.dataIndex].reportId
                    //});
                    //this.props.dispatch(setChangeRoleData(RoleTab.report, selectedReports));

                },
                onViewColumnsChange: (changedColumn, action) => {
                    //console.log(changedColumn, action);
                    //let columnIndex = this.state.columns.findIndex(c => c.name === changedColumn);
                    //this.setState(produce(draft => {
                    //    draft.columns[columnIndex].options.display = action === "add";
                    //}));
                },
                print: false,
                searchPlaceholder: 'Enter search text ...',
                sortOrder: {
                    name: 'label',
                    direction: 'asc'
                }, searchOpen: true,
            }
        return (
            <div>
                
                {this.props.isLoading && <ActionBackdrop />}
                  <MUIDataTable container classes={{ responsiveScrollMaxHeight: classes.responsiveScrollMaxHeight }}
                    title={''}
                    data={this.props.roles.menus}
                    columns={this.state.columns}
                    options={MuiGridOptions(options, this.props.isLoading)}
                    />  
                   
           </div>
        );

    }

}
function mapStateToProps(state) {
    return {
        roles: state.roles,
        isLoading: state.loader.isLoading,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage
    };
};

//export default withRouter(connect(mapStateToProps)(MenuList));
export default withRouter(connect(mapStateToProps)(withStyles(rolesTheme)(MenuList)));
