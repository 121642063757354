import { ActionTypes as types, displayType } from './actionTypes';
import adminApi from 'admin/api/adminApi';
import mockApi from 'admin/api/mockApi';
import settings from 'settings.js';
/**
 * reads mock data setting from settings
 */
const useMocks = settings.useMocks;

/**
 * api in use, mock or real
 */
const api = useMocks ? mockApi : adminApi;
const isGroupable = 'isGroupable'
const fieldFormat = 'format';
const fieldType = { IsGroupable: 'isGroupable', FieldFormat: 'format', ImageType: 'imageType', Culture: 'culture' }
const displayTypeValue = { Currency: 2, Date: 3, DateTime: 4, Image: 8, Time: 10, Decimal_1: 11, Decimal_2: 12, Decimal_3: 13, Decimal_4: 14}
const cultureValue = { Currency: 'Local Culture', Date: 'mm/dd/yyyy', DateTime: 'mm/dd/yyyy hh:mm AM/PM', Image: 1, Time: 'hh:mm AM/PM', Decimal_1: '1,000.0', Decimal_2: '1,000.00', Decimal_3: '1,000.000', Decimal_4: '1,000.0000'}
/**
 * generic calls
 */

/**
 * Invokes the mock or real web api to get fieldDisplay
 */

const apiGetFieldDisplay = (id) => {
    return api.getFieldDisplay(id);
};

const apiGetFormulaFields = (dbType) => {
    return api.getCalculatedFields(dbType);
};
const apiValidateFormula = (dataview, formula) => {

    let data = { dataview: dataview, formula: formula };
    return api.getValidateFormula(data);
};


/**
 * action creators
 */


const getFieldDisplayRequest = (id) => {
    return {
        type: types.VGO_GET_FIELD_DISPLAY_REQUEST,
        reportId: id
    };
};

const getFormulaFieldsRequest = () => {
    return {
        type: types.VGO_GET_FORMULA_FIELD_DISPLAY_REQUEST,
       
    };
};

const getValidateFormulaRequest = () => {
    return {
        type: types.VGO_GET_VALIDATE_FORMULA_FIELD_DISPLAY_REQUEST,

    };
};


const updateFieldDisplayRequest = (displayData, value) => {
    return {
        type: types.VGO_UPDATE_FIELD_FIELD_DISPLAY_SUCCESS,
        displayData: displayData,
        data: value,
       };
};

const updateCalculatedFieldRequest = (displayData, value) => {
    return {
        type: types.VGO_UPDATE_CALCULATED_FIELD_DISPLAY_SUCCESS,
        displayData: displayData,
        data: value,
    };
};

const updateHighLightRequest = (displayData, value,fieldName) => {
    return {
        type: types.VGO_UPDATE_FIELD_HighLight_SUCCESS,
        displayData: displayData,
        data: value,
        fieldName: fieldName,
    };
};



/**
 * Error action creator
 * @param {*} errorMessage 
 */
const getError = (errorMessage) => {
    return {
        type: types.VGO_API_CALL_ERROR,
        hasError: true,
        errorMessage
    };
};

const getValidateError = (errorMessage,response) => {
    return {
        type: types.VGO_GET_VALIDATE_FORMULA_DISPLAY_ERROR,
        alert: { open: true, message: errorMessage ? errorMessage :'The formula is not valid', title: 'Validation failed', icon: '', severity: 'error' },
       //   data: { calculatedField: "", dataType: "" }
        data: response
    };
};

const showValidateError = (errorMessage) => {
    return {
        type: types.VGO_GET_VALIDATE_FORMULA_FIELD_DISPLAY_ERROR,
        alert: { open: true, message: errorMessage, title: errorMessage, icon: '', severity: 'error' },
        
    };
};

const hideValidateError = () => {
    return {
        type: types.VGO_GET_VALIDATE_FORMULA_FIELD_DISPLAY_ERROR,
        alert: { open: false, message: '', title: '', icon: '', severity: 'error' }
    };
};

/**
 * Get all fieldDisplay success action creator
 * @param {Response} resp 
 */
const getFieldDisplaySuccess = (resp) => {
    return {
        type: types.VGO_GET_FIELD_DISPLAY_SUCCESS,
        data: resp.data
    };
};

const getFormulaFieldSuccess = (resp) => {
    return {
        type: types.VGO_GET_FORMULA_FIELD_DISPLAY_SUCCESS,
        data: resp.data
    };
};

const getValidateFormulaSuccess = (resp) => {
    return {
        type: types.VGO_GET_VALIDATE_FORMULA_FIELD_DISPLAY_SUCCESS,
        data: resp.data[0],
        alert: { open: true, message: 'Validation successful', title: 'Validation successful', icon: '', severity: 'success' }
    };
};



/**
 * Dispatch methods
 */
export const updateFieldDisplay = (displayData, data, rowIndex) => {
    return dispatch => {
        dispatch(updateFieldDisplayRequest(displayData, data, rowIndex));

    };
};

export const updateHighLight = (displayData, data, fieldName) => {
    return dispatch => {
        dispatch(updateHighLightRequest(displayData, data, fieldName));

    };
};

export const updateCalculatedField = (displayData, data) => {
    return dispatch => {
        dispatch(updateCalculatedFieldRequest(displayData, data));

    };
};

export const getFieldDisplay = (id) => {
    return dispatch => {
        dispatch(getFieldDisplayRequest(id));
        return apiGetFieldDisplay(id)
            .then((resp) => {
                dispatch(getFieldDisplaySuccess(resp));
            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
            });
    };
};

export const getCalculatedFormulaFields = (dbType) => {
    return dispatch => {
        dispatch(getFormulaFieldsRequest());
        return apiGetFormulaFields(dbType)
            .then((resp) => {
                dispatch(getFormulaFieldSuccess(resp));
            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
            });
    };
};

export const getValidateFormula = (dataview,formula) => {
    return dispatch => {
        dispatch(getValidateFormulaRequest());
        return apiValidateFormula(dataview, formula)
            .then((resp) => {
                console.log("validateFormula", resp);
                if (resp.data[0].dataType === "" || resp.data[0].dataType === null) {
                    
                    dispatch(getValidateError("Please select a datatype.", { calculatedField: "", dataType: "N/A" } ));
                } else {
                    dispatch(getValidateFormulaSuccess(resp));
                }

            })
            .catch(err => {
                let { message } = err;
                if (err.response && err.response.data) {
                    message = err.response.data;
                    if (typeof (message) !== "string") {
                        if (message.title && typeof (message.title) === "string") {
                            message = message.title;
                        }
                        else {
                            message = err.message;
                        }
                    }
                } 
                dispatch(getValidateError());
                });
    };
};

export const showValidationMessage = (message) => {
    return dispatch => {
        dispatch(showValidateError(message));
        
    };
};

export const hideValidationMessage = () => {
    return dispatch => {
        dispatch(hideValidateError());

    };
};

export const updateFieldDisplayValue = (action) => {
    const displayData = Object.assign([], action.displayData)
    if (action.data) {
        const index = displayData.findIndex(d => d.fieldName === action.data.fieldName)

        if (action.data.type === displayType.update) {
            const rowData = Object.assign({}, displayData[index])


            if (action.data.column === fieldType.FieldFormat) {
                switch (action.data.value) {
                    case displayTypeValue.Currency:
                        rowData[fieldType.Culture] = cultureValue.Currency;
                        break;
                    case displayTypeValue.Date:
                        rowData[fieldType.Culture] = cultureValue.Date;
                        break;
                    case displayTypeValue.DateTime:
                        rowData[fieldType.Culture] = cultureValue.DateTime;
                        break;
                    case displayTypeValue.Image:
                        rowData[fieldType.ImageType] = cultureValue.Image;
                        break;
                    case displayTypeValue.Time:
                        rowData[fieldType.Culture] = cultureValue.Time;
                        break;
                    case displayTypeValue.Decimal_1:
                        rowData[fieldType.Culture] = cultureValue.Decimal_1;
                        break;
                    case displayTypeValue.Decimal_2:
                        rowData[fieldType.Culture] = cultureValue.Decimal_2;
                        break;
                    case displayTypeValue.Decimal_3:
                        rowData[fieldType.Culture] = cultureValue.Decimal_3;
                        break;
                    case displayTypeValue.Decimal_4:
                        rowData[fieldType.Culture] = cultureValue.Decimal_4;
                        break;
                }
            }

          
            rowData[action.data.column] = action.data.value
            displayData[index] = rowData
        } else if (action.data.type === displayType.delete) {
            displayData.splice(index, 1)

        }

       
    }

    return displayData
};


export const updateHighLightValue = (action) => {
    const displayData = Object.assign([], action.displayData)
    const index = displayData.findIndex(d => d.fieldName === action.fieldName)
    const rowData = Object.assign({}, displayData[index])
    if (action.data) {
       rowData.kpiDetails = action.data
        displayData[index] = rowData

    } else {
        rowData.kpiDetails = []
        displayData[index] = rowData
    }

    return displayData
};
 const getNextFieldId = (displayData) => {
     return Math.max.apply(Math, displayData.map(function (o) { return o.fieldId; })) + 1;
}
export const updateCalculatedFieldValue = (action) => {
    const displayData = Object.assign([], action.displayData)
    if (action.data) {
      
        const index = displayData.findIndex(d => d.fieldId === action.data.id)
        if (index !== -1) {
            const rowData = Object.assign({}, displayData[index])
            rowData.formula = action.data.formula;
            rowData.fieldType = action.data.dataType;
            rowData.fieldName = action.data.fieldName;
            rowData.fieldValue = action.data.fieldName;
             
            displayData[index] = rowData

        } else {

            displayData.push({
                displayFieldName: action.data.fieldName, fieldValue: action.data.fieldName, fieldName: action.data.fieldName, fieldType: action.data.dataType, formula: action.data.formula, category: 2, id: getNextFieldId(displayData),
               
                fieldId: getNextFieldId(displayData), linkTo: 0, linkToReportId: 0, linkToUrl: 0, linkTarget: 0, actionTitle: '',
                isShowFooter: 0,
                isGroupable: false,
                sortingOption: 0,
                fieldFormat: 0,
                isVisible: true,
                displayOrder: 0,
                fieldCategory: action.data.fieldCategory,
                formulaId: 0,
                pivotReport: '',
                fileTypeField: '',
                isDisplayTitle: true,
                columnWidth: 0,
                columnTotal: false,
                thumbnailImageType: 0,
                culture: "",
                kPIDetails: []

            })
        }
        

    }

    return displayData
};
export const setFieldDisplayData = (fieldsData, oldFieldsData) => {
    const commonDisplayData = oldFieldsData.filter(obj => fieldsData.some(obj2 => obj.fieldName === obj2.fieldName));
    const newFields = fieldsData.filter(obj => !oldFieldsData.some(obj2 => obj.fieldName === obj2.fieldName));
    let index = commonDisplayData.length - 1;
    const newDisplayData = newFields.map((data, i) => {
        index++;
        const dataType = data.dbDataType ? data.dbDataType : data.dataType
        return {
            displayFieldName: data.fieldName,
            fieldValue: data.fieldName + ' (' + dataType + ')',
            fieldName: data.fieldName,
            fieldType: data.dataType,
            dbDataType: dataType,
            formula: '',
            category: 1,
            id: newFields.length,
            fieldId: 0,
            linkTo: 0,
            linkToReportId: 0,
            linkToUrl: 0,
            linkTarget: 0,
            actionTitle: '',
            isShowFooter: 0,
            isGroupable: false,
            sortingOption: 0,
            fieldFormat: 0,
            isVisible: true,
            displayOrder: index,
            fieldCategory: 1,
            formulaId: 0,
            pivotReport: '',
            fileTypeField: '',
            isDisplayTitle: true,
            columnWidth: 0,
            columnTotal: false,
            thumbnailImageType: 0,
            culture: '',
            kPIDetails: [],
        };
    });


    const displayData = commonDisplayData.concat(newDisplayData);
    return displayData;
    
};


export const getRemovedFields = (fields, oldFields) => {
    const removedFields = oldFields.filter(obj => !fields.some(obj2 => obj.fieldName === obj2.fieldName));
    return removedFields;
}

export const getValidateRuleError = () => {
    return {
        type: types.VGO_GET_VALIDATE_FORMULA_DISPLAY_ERROR,
        alert: { open: true, message: 'The formula is not valid', title: 'Validation failed', icon: '', severity: 'error' },
        data: []
    };
};

export const getValidateRuleSuccess = () => {
    return {
        type: types.VGO_GET_VALIDATE_FORMULA_FIELD_DISPLAY_SUCCESS,
        data: [],
        alert: { open: true, message: 'Validation successful', title: 'Validation successful', icon: '', severity: 'success' }
    };
};


