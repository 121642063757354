import { showError, showSuccess, showWarning, showWarningWithAction, showErrorWithAction } from 'client/components/Common/AlertAction';
const initialState = { showError: false, message: "", messageQueue: "", redirectProfile: false };
const alertShow = 'SHOW_ALERT'
const alertHide = 'HIDE_ALERT'
const alertActionShow = 'SHOW_ALERT_ACTION'
const alertActionHide = 'HIDE_ALERT_ACTION'
const clearAlertAction = 'CLEAR_ALERT_ACTION'
export const alertHandlerAction = {

    showSuccessMessage: (message) => (dispatch, getState) => {
        showSuccess(dispatch, 'Success', message, 5000);
    },
   

    showErrorMessage: (message) => (dispatch, getState) => {
        showError(dispatch, message);
    },
    showErrorMessageWithAction: (message, action) => (dispatch, getState) => {
        showErrorWithAction(dispatch, message, action);
    },
    showWarningMessage:(message)=>(dispatch, getState) => {
         showWarning(dispatch, message);
    },
    showWarningMessageWithAction: (message, action) => (dispatch, getState) => {
        showWarningWithAction(dispatch, message, action);
    },
    hideAlert: (severity) => (dispatch, getState) => {
        dispatch({ type: alertHide, alert: { severity} });
    },
    hideAlertWithAction: (severity) => (dispatch, getState) => {
        dispatch({ type: alertActionHide, alertAction: { severity } });
    },

    clearAlertAction: (severity) => (dispatch, getState) => {
        dispatch({ type: clearAlertAction, alert: { severity } });
    },
   
};



export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === alertShow) {

        return {
            ...state,
            alert: {
                open: action.alert.open,
                severity: action.alert.severity,
                title: action.alert.title,
                message: action.alert.message,
                icon: action.alert.icon,
                timerId: action.alert.timerId,
            },
            alertAction:false

        }
    }
    else if (action.type === alertActionShow) {

        return {
            ...state,
            alertAction: {
                open: action.alertAction.open,
                severity: action.alertAction.severity,
                title: action.alertAction.title,
                message: action.alertAction.message,
                icon: action.alertAction.icon,
                action: action.alertAction.action,
                },
            alert:false,

        }
    }
    else if (action.type === alertHide) {
        const severity = (!action.alert || !action.alert.severity) ? (!state.alert ? '' : state.alert.severity) : action.alert.severity;
        
        return {
            ...state,
            alert: {
                open: false,
                severity: severity,
                title: '',
                message: state.alert ? state.alert.message :'',
                icon: state.alert ? state.alert.icon : (state.alert),
                timerId: action.alert ? action.alert.timerId : 0
            },
            alertAction: false,

        }
    }
    else if (action.type === alertActionHide) {
        const severity = (!action.alertAction || !action.alertAction.severity) ? (!state.alertAction ? '' : state.alertAction.severity) : action.alertAction.severity;
        return {
            ...state,
            alertAction: {
                open: false,
                severity: severity,
                title: '',
                message: state.alertAction ? state.alertAction.message : '',
                icon: state.alertAction ? state.alertAction.icon : (state.alert),
               
            },
            alert: false,

        }
    }
    else if (action.type === clearAlertAction) {
        const severity = (!action.alertAction || !action.alertAction.severity) ? (!state.alertAction ? '' : state.alertAction.severity) : action.alertAction.severity;
        return {
            ...state,
            alertAction: {
                open: false,
                severity: severity,
                title: '',
                message: '',
                icon: state.alertAction ? state.alertAction.icon : (state.alert),

            },
            alert: false,

        }
    }
    return state;
};