import React from 'react';
import { Box } from '@mui/material';
import MaterialTab from 'client/components/Common/MaterialTab';
import Bar from './Bar';
import HorizontalBar from './HorizontalBar';
import Line from './Line';
import Area from './Area';
import Pie from './Pie';
import Doughnut from './Doughnut';
import Radar from './Radar';
import Polar from './Polar';
import Scatter from './Scatter';
import Bubble from './Bubble';
import Funnel from './Funnel';
import * as Enums from 'client/components/Common/Enum';
import { withStyles } from '@mui/styles';
import { chartTheme } from './theme/chartTheme';
import { Typography } from '@mui/material';
class chart extends React.Component {



    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data
        };
    }

    shouldComponentUpdate(nextProps) {

        if (nextProps.data.length !== this.props.data.length) {
            return true;
        } else {
            return false;
        }
    }

    tabDetails = [];

    render() {
        if (this.props.chartId !== undefined && this.props.chartId > 0) {
            var chartId = this.props.chartId;
            var chartDetail = this.props.chartDetails.filter(function(chartObject, index, arr) {
                return chartObject.chartId === chartId;
            });
            if (chartDetail.length > 0) {
                return(
                    <Box height="100%" pt={1} pl={1} pr={1}>
                        {this.renderChart(chartDetail[0])}
                    </Box>
                )
            } else {
                return (<div>Empty</div>)
            }
        } else {
            this.tabDetails = [];
          
                this.props.chartDetails.forEach((chartDetail, i) => {
                    this.renderChart(chartDetail)

                })
          
            const boxPadding = this.props.deviceType != Enums.DeviceType.Desktop ? { pl: 1, pr: 2 } : { pl: 1, pr: 7 }
            if (!this.props.dashboardPanelId) {
                return (
                    <Box height="75%" {...boxPadding}>
                        <MaterialTab details={this.tabDetails} />
                    </Box>
                )
               } else {
                return (<div></div>)
            }
           
        }
    }
    renderChart = (chartDetail) => {
        if (this.props.data && this.props.data.length > 0) {
        if (chartDetail.chartYAxes.length !== 0) {
                      
                const chartType = chartDetail.chartYAxes[0].chartType;
                const isFromPanel = (this.props.chartId !== undefined && this.props.chartId > 0);
                const popupClassName = isFromPanel ? this.props.classes.dashboardPanelpopup : this.props.classes.popup;
                //const bottomPixel = (chartType == Enums.ChartType.Funnel) ? 0 : 40;
                //const funnelChartHeight = isFromPanel ? parseInt(document.getElementById(this.props.dashboardPanelId).style.height.replace('px', '')) - bottomPixel : 600;
                const chartHeight = "100%";
                switch (chartType) {
                    case Enums.ChartType.Column:

                        if (isFromPanel) {
                            return (
                                <Bar chartDetail={chartDetail} columns={this.props.definition.reportOutputFields} data={this.props.data} popupClassName={popupClassName} deviceType={this.props.deviceType} chartHeight={chartHeight} />
                            )
                        } else {
                            let content = <Bar chartDetail={chartDetail} columns={this.props.definition.reportOutputFields} data={this.props.data} popupClassName={popupClassName} deviceType={this.props.deviceType} chartHeight={chartHeight} />;
                            this.tabDetails.push({ name: chartDetail.chartTitle, content: content });
                        }

                        break;
                    case Enums.ChartType.Bar:

                        if (isFromPanel) {
                            return (
                                <HorizontalBar chartDetail={chartDetail} columns={this.props.definition.reportOutputFields} data={this.props.data} popupClassName={popupClassName} deviceType={this.props.deviceType} chartHeight={chartHeight} />
                            )
                        } else {
                            let content = <HorizontalBar chartDetail={chartDetail} columns={this.props.definition.reportOutputFields} data={this.props.data} popupClassName={popupClassName} deviceType={this.props.deviceType} chartHeight={chartHeight} />;
                            this.tabDetails.push({ name: chartDetail.chartTitle, content: content });
                        }

                        break;
                    case Enums.ChartType.Line:

                        if (isFromPanel) {
                            return (
                                <Line chartDetail={chartDetail} columns={this.props.definition.reportOutputFields} data={this.props.data} popupClassName={popupClassName} deviceType={this.props.deviceType} chartHeight={chartHeight} />
                            )
                        } else {
                            let content = <Line chartDetail={chartDetail} columns={this.props.definition.reportOutputFields} data={this.props.data} popupClassName={popupClassName} deviceType={this.props.deviceType} chartHeight={chartHeight} />;
                            this.tabDetails.push({ name: chartDetail.chartTitle, content: content });
                        }

                        break;
                    case Enums.ChartType.Area:

                        if (isFromPanel) {
                            return (
                                <Area chartDetail={chartDetail} columns={this.props.definition.reportOutputFields} data={this.props.data} popupClassName={popupClassName} deviceType={this.props.deviceType} chartHeight={chartHeight} />
                            )
                        } else {
                            let content = <Area chartDetail={chartDetail} columns={this.props.definition.reportOutputFields} data={this.props.data} popupClassName={popupClassName} deviceType={this.props.deviceType} chartHeight={chartHeight} />;
                            this.tabDetails.push({ name: chartDetail.chartTitle, content: content });
                        }

                        break;
                    case Enums.ChartType.Pie:

                        if (isFromPanel) {
                            return (
                                <Pie chartDetail={chartDetail} columns={this.props.definition.reportOutputFields} data={this.props.data} popupClassName={popupClassName} deviceType={this.props.deviceType} chartHeight={chartHeight} />
                            )
                        } else {
                            let content = <Pie chartDetail={chartDetail} columns={this.props.definition.reportOutputFields} data={this.props.data} popupClassName={popupClassName} deviceType={this.props.deviceType} chartHeight={chartHeight} />;
                            this.tabDetails.push({ name: chartDetail.chartTitle, content: content });
                        }

                        break;
                    case Enums.ChartType.Doughnut:

                        if (isFromPanel) {
                            return (
                                <Doughnut chartDetail={chartDetail} columns={this.props.definition.reportOutputFields} data={this.props.data} popupClassName={popupClassName} deviceType={this.props.deviceType} chartHeight={chartHeight} />
                            )
                        } else {
                            let content = <Doughnut chartDetail={chartDetail} columns={this.props.definition.reportOutputFields} data={this.props.data} popupClassName={popupClassName} deviceType={this.props.deviceType} chartHeight={chartHeight} />;
                            this.tabDetails.push({ name: chartDetail.chartTitle, content: content });
                        }

                        break;
                    case Enums.ChartType.Radar:

                        if (isFromPanel) {
                            return (
                                <Radar chartDetail={chartDetail} columns={this.props.definition.reportOutputFields} data={this.props.data} popupClassName={popupClassName} deviceType={this.props.deviceType} chartHeight={chartHeight} />
                            )
                        } else {
                            let content = <Radar chartDetail={chartDetail} columns={this.props.definition.reportOutputFields} data={this.props.data} popupClassName={popupClassName} deviceType={this.props.deviceType} chartHeight={chartHeight} />;
                            this.tabDetails.push({ name: chartDetail.chartTitle, content: content });
                        }

                        break;
                    case Enums.ChartType.Polar:

                        if (isFromPanel) {
                            return (
                                <Polar chartDetail={chartDetail} columns={this.props.definition.reportOutputFields} data={this.props.data} popupClassName={popupClassName} deviceType={this.props.deviceType} chartHeight={chartHeight} />
                            )
                        } else {
                            let content = <Polar chartDetail={chartDetail} columns={this.props.definition.reportOutputFields} data={this.props.data} popupClassName={popupClassName} deviceType={this.props.deviceType} chartHeight={chartHeight} />;
                            this.tabDetails.push({ name: chartDetail.chartTitle, content: content });
                        }

                        break;
                    case Enums.ChartType.Scatter:

                        if (isFromPanel) {
                            return (
                                <Scatter chartDetail={chartDetail} columns={this.props.definition.reportOutputFields} data={this.props.data} popupClassName={popupClassName} deviceType={this.props.deviceType} chartHeight={chartHeight} />
                            )
                        } else {
                            let content = <Scatter chartDetail={chartDetail} columns={this.props.definition.reportOutputFields} data={this.props.data} popupClassName={popupClassName} deviceType={this.props.deviceType} chartHeight={chartHeight} />;
                            this.tabDetails.push({ name: chartDetail.chartTitle, content: content });
                        }

                        break;
                    case Enums.ChartType.Bubble:

                        if (isFromPanel) {
                            return (
                                <Bubble chartDetail={chartDetail} columns={this.props.definition.reportOutputFields} data={this.props.data} popupClassName={popupClassName} deviceType={this.props.deviceType} chartHeight={chartHeight} />
                            )
                        } else {
                            let content = <Bubble chartDetail={chartDetail} columns={this.props.definition.reportOutputFields} data={this.props.data} popupClassName={popupClassName} deviceType={this.props.deviceType} chartHeight={chartHeight} />;
                            this.tabDetails.push({ name: chartDetail.chartTitle, content: content });
                        }

                        break;
                    case Enums.ChartType.Funnel:

                        if (isFromPanel) {
                            return (
                                <Funnel chartDetail={chartDetail} columns={this.props.definition.reportOutputFields} data={this.props.data} isFromDashboard={isFromPanel} popupClassName={popupClassName} deviceType={this.props.deviceType} chartHeight={chartHeight}
                                    panelSizeX={this.props.panelSizeX}
                                    panelSizeY={this.props.panelSizeY} />
                            )
                        } else {
                            let content = <Funnel chartDetail={chartDetail} columns={this.props.definition.reportOutputFields} data={this.props.data} isFromDashboard={isFromPanel} popupClassName={popupClassName} deviceType={this.props.deviceType} chartHeight={chartHeight}
                                panelSizeX={this.props.panelSizeX}
                                panelSizeY={this.props.panelSizeY}
                            />;
                            this.tabDetails.push({ name: chartDetail.chartTitle, content: content });
                        }

                        break;
                    default:
                        return (<div></div>)
                }
            }
        } else {
            this.tabDetails.push({ name: chartDetail.chartTitle, content: "" });

            if (this.props.dashboardPanelId) {
                
                return (<div> <Typography variant="subtitle1" className={this.props.classes.chartTitle }>{chartDetail.chartTitle}</Typography></div>)
            }
        }
    }
}

export default withStyles(chartTheme)(chart);