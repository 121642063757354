import React, { useState } from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => (option.databaseConnectionName ? option.databaseConnectionName : ''),
});
export default function ImportDestinationConnection(props)  {

    const destinationConnection = (props.databaseList.length > 0 && props.data.newObjectId) ? props.databaseList.filter(connection => connection.databaseId === props.data.newObjectId)[0] : {};
    const invalidConnectionIndex = props.invalidConnectionList.findIndex(connection => connection.oldObjectId === props.data.oldObjectId);
    const [connectionSearch, setSearch] = useState('');
    return (
        <div >
            <Autocomplete

                disableClearable={true}

                onChange={(event, newValue) => {
                    props.connectionChange(importPackage => {
                        importPackage.connectionMapping[props.data.index].newObjectId = newValue.databaseId;
                        return importPackage;
                    }, { oldObjectId: props.data.oldObjectId, newObjectId: newValue.databaseId });
                }}
                id="controllable-states-demo"
                options={props.databaseList}
                value={destinationConnection}
                inputValue={connectionSearch}
                getOptionLabel={(option) => option.databaseConnectionName || ""}


                sx={{ width: 300, height: 58 ,paddingBottom:5}}
                filterOptions={filterOptions}
                renderOption={(props, option) => <li {...props}>
                    <div >
                        <Typography   variant="body1"> {option.databaseConnectionName} </Typography>
                        <Typography variant="caption"> {option.database} </Typography>
                    </div>
                </li>}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        onChange={(event) => {
                            setSearch(event.target.value)
                        }}
                        onBlur={(event) => {
                            setSearch('')
                        }}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                            
                            // value: "",
                        }}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: connectionSearch ? "" : <Box  >
                                <Typography sx={{ wordBreak: "break-word" }} variant="body1"> {destinationConnection.databaseConnectionName} </Typography>
                                <Typography variant="caption"> {destinationConnection.database} </Typography>
                            </Box>
                        }}

                        error={invalidConnectionIndex === -1 ? false : true}
                        helperText={invalidConnectionIndex === -1 ? " " : "Please select a connection name "}
                    />
                )}

            />
        </div>
    );
}