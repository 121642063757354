import React from 'react';

import { withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';

import { Box } from '@mui/material';
import UserList from './users/UserList';
import UserEdit from './users/UserEdit';
import SystemSettings from './settings/SystemSettings';

import DatabaseEdit from './database/DatabaseEdit';
import DatabaseList from './database/DatabaseList';

import DashboardList from './dashboard/DashboardList';
import DashboardEdit from './dashboard/DashboardEdit';


import FilterList from './filter/FilterList';
import FilterEdit from './filter/FilterEdit';

import RoleList from './roles/RoleList';
import RoleEdit from './roles/RoleEdit';

import DataViewList from './dataViews/DataViewList';
import DataViewEditWrapper from './dataViews/DataViewEditWrapper';
import FormList from './form/FormList';
import FormEditWrapper from './form/FormEditWrapper';
import APILinks from './customWidgets/APILinks';

import SiteThemeEdit from './siteTheme/SiteThemeEdit';


import MenuEdit from './menu/MenuEdit';
import MenuList from './menu/MenuList';

import HomePageEdit from './homePage/HomePageEdit';
import HomePageList from './homePage/HomePageList';

import ExportContent from './package/ExportContent';
import ImportContent from './package/ImportContent';
 
import Cookies from 'universal-cookie';
const cookies = new Cookies();
class Content extends React.Component {
    checkforCookieExist = () => {
        const versagoCookie = cookies.get('Versago'); 
        if (versagoCookie !== undefined) {
            let roleCookie = cookies.get('roleId');

            if (roleCookie !== undefined) {
                return true;
            } else {
               
                this.props.history.push("/");
                
            }
        } else {
            
            this.props.history.push("/");
        }
        
        
    }
    render() {
         if (this.checkforCookieExist()) {
            return ( 
                <Switch>
                    <Route path="/Admin/Dashboards/:dashboardId" component={DashboardEdit} />
                    <Route path="/Admin/Dashboards" component={DashboardList} />
                    <Route path="/Admin/DataViews/:reportId" component={DataViewEditWrapper} />
                    <Route path="/Admin/DataViews" component={DataViewList} />
                    <Route path="/Admin/Databases/:databaseId" component={DatabaseEdit} />
                    <Route path="/Admin/Databases" component={DatabaseList} />
                    <Route path="/Admin/Settings" component={SystemSettings} />
                    <Route path="/Admin/Roles/:roleId" component={RoleEdit} />
                    <Route path="/Admin/Roles" component={RoleList} />
                    <Route path="/Admin/Users/:userId" component={UserEdit} />
                    <Route path="/Admin/Users" component={UserList} />
                    <Route path="/Admin" Component={Box} exact />
                    <Route path="/Admin/Filters" component={FilterList} />
                    <Route path="/Admin/Filter/:profileId" component={FilterEdit} />

                    <Route path="/Admin/Forms/:formId" component={FormEditWrapper} />
                    <Route path="/Admin/Forms" component={FormList} />
                    <Route path="/Admin/Menus/:menuId" component={MenuEdit} />
                    <Route path="/Admin/Menus" component={MenuList} />
                    <Route path="/Admin/OtherLinks" component={APILinks} />
                    <Route path="/Admin/SiteTheme" component={SiteThemeEdit} />

                    <Route path="/Admin/HomePage/:homePageId" component={HomePageEdit} />
                    <Route path="/Admin/HomePage" component={HomePageList} />
                    <Route path="/Admin/Export" component={ExportContent} />
                    <Route path="/Admin/Import" component={ImportContent} />
                </Switch>
            );
        } else {
            
            return (<div></div>)
        }
    }

}

export default withRouter(Content);