

/**
 * represents back end store
 */
let mockData = {
    displayData: [],
    sortTypes: [],
    displayTypes: [],
    thumbnail: [],
    localizationTypes: [],
};

/**
 * gets or generates filters and returns all
 */
const mockGetFieldDisplay = (id) => {
    if (mockData.displayData.length === 0 ) {
        let data = generateDisplay();
        mockData.displayData = data;

    }
    return {
        data: [...mockData.displayData],
    };
};


/**
 * Utility function to generator mock filters
 * @param {int} num 
 */
const generateDisplay = ( num = 3 ) => {
    let displayData = [];
       let fieldName ='CardCode';
        displayData.push({
            fieldId: 1,
            fieldValue: fieldName,
            fieldName: fieldName+' (nvarchar)',
            linkTo: 0,
            linkToReportId: 0,
            linkToUrl: 0,
            linkTarget: 0,
            displayFieldName: fieldName,
            actionTitle: fieldName,
            isShowFooter: 0,
            isGroupable: false,
            sortingOption: 0,
            fieldFormat: 0,
            fieldType: 'nvarchar',
            isVisible: true,
            displayOrder: 0,
            fieldCategory: 0,
            formulaId: 0,
            formula: 0,
            pivotReport: fieldName,
            fileTypeField: fieldName,
            isDisplayTitle: true,
            columnWidth: 0,
            columnTotal: false,
            thumbnailImageType: 0,
            culture: "",
            kPIDetails: []
        })
        fieldName = 'CardName';
        displayData.push({
            fieldId: 2,
            fieldValue: fieldName,
            fieldName: fieldName+' (nvarchar)',
            linkTo: 0,
            linkToReportId: 0,
            linkToUrl: 0,
            linkTarget: 0,
            displayFieldName: fieldName,
            actionTitle: fieldName,
            isShowFooter: 0,
            isGroupable: false,
            sortingOption: 0,
            fieldFormat: 0,
            fieldType: 'nvarchar',
            isVisible: true,
            displayOrder: 0,
            fieldCategory: 0,
            formulaId: 0,
            formula: 0,
            pivotReport: fieldName,
            fileTypeField: fieldName,
            isDisplayTitle: true,
            columnWidth: 0,
            columnTotal: false,
            thumbnailImageType: 0,
            culture: "",
            kPIDetails: []
        })

        fieldName = 'Total';
        displayData.push({
            fieldId: 3,
            fieldValue: fieldName,
            fieldName: fieldName + ' (decimal)',
            linkTo: 0,
            linkToReportId: 0,
            linkToUrl: 0,
            linkTarget: 0,
            displayFieldName: fieldName,
            actionTitle: fieldName,
            isShowFooter: 0,
            isGroupable: false,
            sortingOption: 0,
            fieldFormat: 0,
            fieldType: 'decimal',
            isVisible: true,
            displayOrder: 0,
            category: 1,
            formulaId: 0,
            formula: 0,
            pivotReport: fieldName,
            fileTypeField: fieldName,
            isDisplayTitle: true,
            columnWidth: 0,
            columnTotal: false,
            imageType: 0,
            culture: "",
            kPIDetails: []
        })
    
    return displayData;
}; 


export { mockGetFieldDisplay};