import React, { useState, useEffect } from 'react';
import {  TreeItem } from '@mui/lab';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import RemoveIcon from '@mui/icons-material/Remove';
import {  menuTreeviewTheme } from './theme/menuTheme';
import MuiTextField from '@mui/material/TextField';
import { Grid, Typography, Box } from '@mui/material';
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';
import { styled } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import { spacing } from '@mui/system';
import * as Enums from 'client/components/Common/Enum';
const TextField = styled(MuiTextField)(spacing);
const useTreeItemStyles = makeStyles(menuTreeviewTheme);

export default function NavMenuItem(props) {
    const [isLabelVisible, setIsLabelVisible] = useState(true);
    const [isInputVisible, setIsInputVisible] = useState(false);
    const [value, setValue] = useState('');
    const classes = useTreeItemStyles();
    const {
        id,
        keyId,
        nodeId,
        labelText,
        contentTypeText,
        contentSubTypeText,
        color,
        bgColor,
        menuType,
        onDeleteMainMenu,
        onBlurMainMenu,
        onDropMainMenu,
        onSelectMainMenu,
        items,
        item,
        level,
        index,
        parent,
        targetKey,
        isMenu,
        objectList,
        quickActions,
        ...other
    } = props;

    useEffect(() => {
        if (value !== labelText) {
            setValue(value);
        }
    }, [value, labelText])

    function handleDelete(event) {
        onDeleteMainMenu(dropData)
    }

    function handleChange(event) {
        setValue(event.target.value);
    }

    function handleDoubleClick(event) {
        if (event.detail === 2) {
            if (!contentTypeText) {
                setValue(labelText);
                setIsLabelVisible(false);
                setIsInputVisible(true);
            }
            else if (menuType === Enums.MenuItemMode.QuickAction) {
                setValue(labelText);
                setIsLabelVisible(false);
                setIsInputVisible(true);
            }
        }
       
    }
    function handleBlur(event) {
        onBlurMainMenu(dropData, event.target.value)
        setIsLabelVisible(true);
        setIsInputVisible(false);
    }

    

    function handleDrop(event) {
        event.stopImmediatePropagation();
        onDropMainMenu(event);
    };
    function handleItemSelect(event) {
        onSelectMainMenu(dragData);
        
    }
    function getDragElement() {
        return (
            <Box className={classes.menuBox} sx={{ backgroundColor: "white" }} >
                <div >
                <Grid container>
                    <Grid item xs={.8} pt={.375} pl={1}>
                        <DragHandleIcon className="drag" fontSize="small" />
                    </Grid>
                    <Grid item xs={5} pl={(!contentTypeText) ? 2.5 : 0} >
                        <Typography variant="caption" className={classes.AddThreeDots} style={{ display: (!isLabelVisible) ? "none" : "block" }}>{labelText}</Typography>
                        <TextField
                            className={classes.caption}
                            style={{ display: (!isInputVisible) ? "none" : "block" }}
                            size="small"
                            value={value}
                            InputProps={{
                                classes: {
                                    input: classes.textResize,
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={2.3} >
                        <Typography variant="caption">
                            {contentTypeText}</Typography>
                    </Grid>
                    <Grid item xs={2.9} >
                        <Typography variant="caption">
                            {contentSubTypeText}</Typography>
                    </Grid>
                    <Grid item xs={1} pl={2.5} pt={.1}>
                        <RemoveIcon className={classes.deleteIcon}  />
                    </Grid>
                </Grid>
                </div>
            </Box>
            )
    }
    const dragData = {
        mode: menuType,
        contentSubType: contentSubTypeText,
        contentType: contentTypeText,
        id: id,
        item: item,
        label: labelText,
        level: level,
        index: index,
        parent: parent,
        isMenu: isMenu,
        nodeKey: keyId,
        nodeId: nodeId,
        isNav: true,
        isList:false,
    }
    const dropData = {
        mode: menuType,
        contentSubType: contentSubTypeText,
        contentType: contentTypeText,
        id: id,
        item: item,
        label: labelText,
        level: level,
        index: index,
        parent: parent,
        isMenu: isMenu,
        nodeKey: keyId,
        nodeId: nodeId,
        objectList: objectList,
        quickActions: quickActions,
    }
    const dragElement = getDragElement();
    return (
        <TreeItem
            label={
                <DragDropContainer dragElemOpacity={"1"} customDragElement={dragElement} dragHandleClassName="drag" dragData={dragData} onDrop={handleDrop} targetKey={targetKey} >
                    <DropTarget targetKey="content" dropData={dropData}>
                        <DropTarget targetKey="mainMenu" dropData={dropData} >
                            <DropTarget targetKey="quickAction" dropData={dropData}>
                                <Box  className={classes.menuBox} >
                                    <div onClick={handleItemSelect}>
                                        <Grid container>
                                            <Grid item xs={.8} pt={.375} pl={1}>
                                                <DragHandleIcon className="drag" fontSize="small" />
                                            </Grid>
                                            <Grid item xs={5} pl={(!contentTypeText) ? 2.5 : 0} onClick={handleDoubleClick}>
                                                <Typography variant="caption" className={classes.AddThreeDots} style={{ display: (!isLabelVisible) ? "none" : "block" }}>{labelText}</Typography>
                                                <TextField
                                                    className={classes.caption}
                                                    style={{ display: (!isInputVisible) ? "none" : "block" }}
                                                    size="small"
                                                    value={value}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    InputProps={{
                                                        classes: {
                                                            input: classes.textResize,
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={2.3} >
                                                <Typography variant="caption">
                                                    {contentTypeText}</Typography>
                                            </Grid>
                                            <Grid item xs={2.9} >
                                                <Typography variant="caption">
                                                    {contentSubTypeText}</Typography>
                                            </Grid>
                                            <Grid item xs={1} pl={2.5} pt={.1}>
                                                <RemoveIcon className={classes.deleteIcon} onClick={handleDelete} />
                                            </Grid>
                                        </Grid>
                                        </div>
                                    </Box>
                            </DropTarget>
                        </DropTarget>
                    </DropTarget>
                </DragDropContainer>
            }
            nodeId={keyId}
            style={{
                width: "585px",
            }}
            classes={{
                root: classes.root,
                content: classes.content,
                expanded: classes.expanded,
                selected: classes.selected,
                group: classes.group,
                focused: classes.focused,
                label: classes.label,
            }}
            {...other}
        />
    );
}