import React, { Fragment }  from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Home from 'client/components/Home/Home';
import Content from 'client/components/ContentLayout/ContentLayout';
import UserProfile from 'client/components/UserProfile/UserProfile';
import BannerMore from 'client/components/Banner/BannerMore';
import DataView from 'client/components/DataView/DataView';
import Form from 'client/components/Form/Form';
import Dashboard from 'client/components/Dashboard/Dashboard';
import ResetPassword from 'client/components/ResetPasswords/ResetPassword';
import { alertHandlerAction } from 'client/store/AlertHandler';
import AlertHandlerMessage from 'client/components/Common/AlertHandlerMessage';
import { ComponentList } from 'client/components/Common/Enum';
import CrystalReportViewerActionLink from 'client/components/DataView/CrystalReportViewerActionLink'
import { routeTheme } from 'client/components/ComponentRoute/theme/routeTheme'
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';
class ComponentRoute extends React.Component {

    componentDidMount() {
        this.props.actions.alertHandler.hideAlert();
        document.addEventListener("mousedown", this.handleClick);
    }

    handleClick = (event) => {

        if (this.props.alert && this.props.alert.message !== "") {
            if (event.target && event.target.id !== "btnConfirmYes") {
                this.props.actions.alertHandler.clearAlertAction();
            }
        }


    }

    shouldComponentUpdate(nextProps) {

        if (nextProps.match.url !== this.props.match.url) {
            return true;
        } else {
            return false;
        }
    }

    renderSwitch = (props) => {
        switch (props.component) {
            case ComponentList.Home:
                return <Home  {...props} />;
            case ComponentList.Content:
                return <Content  {...props} />;
            case ComponentList.UserProfile:
                return <UserProfile resetMargin={props.resetMargin} {...props} />;
            case ComponentList.Banner:
                return <BannerMore  {...props} />;
            case ComponentList.DataView:
                return <DataView resetMargin={props.resetMargin} {...props} />;
            case ComponentList.Dashboard:
                return <Dashboard resetMargin={props.resetMargin} {...props} />;
            case ComponentList.Form:
                return <Form resetMargin={props.resetMargin} {...props} />;
            case ComponentList.ResetPassword:
                return <ResetPassword  {...props} />;
            case ComponentList.CrystalReportViewer:
                return <CrystalReportViewerActionLink  {...props} />;
            default:
                return <Home {...props}/>;

        }
    }
    render() {
        const { classes } =this.props
        return (
            <Fragment>
                <Grid container>
                    <Grid item className={classes.routeTheme}>
                        <Box sx={{ width: 1, zIndex: 3}} position="fixed">
                        <div className={classes.inner}>
                            <AlertHandlerMessage />
                            </div>
                        </Box>
                    </Grid>
                </Grid>
                {this.renderSwitch(this.props)}
            </Fragment>

        );
    }
            
        
    
}
function mapDispatchToProps(dispatch) {
    return {
        actions: {
            alertHandler: bindActionCreators(alertHandlerAction, dispatch)
        }
    };
}

export default connect(state => state.alertHandler, mapDispatchToProps)(withStyles(routeTheme)(ComponentRoute));

