import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-react-layouts/styles/material.css';
import { Grid, Typography, FormControlLabel, Box, Checkbox } from '@mui/material';
import Autocomplete from '@mui/lab/Autocomplete';
import { FormGroup } from '@mui/material';
import { spacing } from '@mui/system';
import MuiTextField from '@mui/material/TextField';
import { styled } from '@mui/styles';
import { withStyles } from '@mui/styles';
import DateRangeIcon from '@mui/icons-material/DateRange';
import BarChartIcon from '@mui/icons-material/BarChart';
import ListIcon from '@mui/icons-material/List';
import { IconButton } from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ActionTypes as actionTypes } from 'admin/actions/actionTypes';

import MuiCard from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Category from 'admin/components/section/dataViews/setup/Category';
import { DashboardLayoutComponent } from '@syncfusion/ej2-react-layouts';
import DashboardItemList from './DashboardItemList';
import { validateFieldsOnBlur, getChangeDashboard, getDashboard, setPanels, newDashboard, updatePanels, updatePanelsWithObjectList} from 'admin/actions/dashboardActions';
import { getCategories } from 'admin/actions/dataViewActions';
import { getRoles, sortRoles } from 'admin/actions/userActions';
import { dashboardTheme } from './theme/dashboardTheme.js';
import cloneDeep from 'lodash/cloneDeep';
import ActionBackdrop from 'admin/components/section/settings/Backdrop';
import {  hideError } from 'admin/actions/alertActions';

const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
 

class DashboardEdit extends React.Component {

    constructor(props) {
      
        super(props);  
        
        this.state = {
            anchorElement: null,
            panelList: [],
            panelObjectList: [],
        };
        
        this.cellSpacing = [16, 8];
        this.resize = ['e-south-east', 'e-east', 'e-west', 'e-north', 'e-south'];
        
        
    } 
     
    componentDidMount() {
        
        this.dispatchGetRoles();
        this.dispatchGetCategories();
        let dashboardId = this.props.match.params.dashboardId;
        let isUpdate = dashboardId !== "new";
        dashboardId = isUpdate ? dashboardId : null;
        if (isUpdate) {
            this.props.dispatch(getDashboard(dashboardId))

        }
        else {
            this.props.dispatch(newDashboard());
            
        }
    }
   
    componentDidUpdate(prevProps) {

        if (this.props.isUpdateSuccess !== prevProps.isUpdateSuccess) {
            if (this.props.isUpdateSuccess && this.props.dashboard.id > 0) {
                this.props.history.push(`/Admin/Dashboards/${this.props.dashboard.id}`);
            }
        }

    }
    hideAlertMessage = () => {
        return dispatch => {
            hideError(dispatch);
        };
    };
    dispatchGetCategories() {
        if (this.props.categories.list.length === 0) {
            this.props.dispatch(getCategories());
        }
    }
    
    dispatchGetRoles = () => {

        if (this.props.roles.list.length === 0) {

            this.props.dispatch(getRoles());
        }
    }
    blurValidation = (fieldName) => {

        this.props.dispatch(validateFieldsOnBlur(this.props.dashboard, fieldName));
    }
    changeDashboard = (change,error) => {
        this.props.dispatch(getChangeDashboard(change,error));
    }
    changeCategory = (event, input, value) => {

        this.changeDashboard(dashboard => dashboard.category = value);
    }
    toggleIsPublic = (event) => {

        this.changeDashboard(dashboard => dashboard.isPublic = event.target.checked);
    }
    onRolesChange = (event, value, reason) => {

        switch (reason) {

            case 'selectOption':
                {
                    let rlist = cloneDeep(value);
                    const roleList = sortRoles(rlist);
                    this.changeDashboard(dashboard => dashboard.roles = roleList);
                    break;
                }
            case 'removeOption':
                {
                    let rlist = cloneDeep(value);
                    const roleList = sortRoles(rlist);
                    this.changeDashboard(dashboard => dashboard.roles = roleList, roleList)
                    break;

                }

                break;

            default:
                break;

        }
    }
    
   
    onResizeStop=(args) =>{
        
        if (this.dashboardObj) {
            
            const dashboardPanelList = this.dashboardObj.serialize();
            if (dashboardPanelList.length > 0) {
                this.props.dispatch(updatePanels(dashboardPanelList));
            }
            
        }
    }
    onChange = (args) => {
              
        if (this.dashboardObj) {
            
            const dashboardPanelList = this.dashboardObj.serialize();
            

            
            if (dashboardPanelList.length > 0 && args.changedPanels.length >0) {
                this.props.dispatch(updatePanels(dashboardPanelList));
            }
        }
    }
    onDragStop = (args) => {

        args.cancel=true
        
    }
    renderDashboardLayout = () => {
        

        return (
            <div className="inline" id="control">
                <DashboardLayoutComponent
                    id={`dashboard_layout_${this.props.dashboard.id}`}
                    ref={dashboard => this.dashboardObj = dashboard}
                    cellSpacing={this.cellSpacing}
                    panels={this.getSavedPanelListNew()}
                    allowResizing={true}
                    columns={2}
                    resizeStop={this.onResizeStop.bind(this)}
                    dragStop={this.onDragStop.bind(this)}
                    change={this.onChange.bind(this)}
                    resizableHandles={this.resize} />
            </div>
            );
    }
    
    getSavedPanelListNew = () => {
        let panelList = [];
       
        if (this.props.panelListUI.length >0) {
            
            panelList = this.props.panelListUI.map((panel) => {
                let newPanel = { ...panel }
                const selectedObject = this.props.panelObjectList.find(panelObject => panelObject.id === panel.id);
                if (selectedObject) {
                    newPanel.header = this.panelHeaderTemplate.bind(this, selectedObject.frameObject, panel.id, panel.sizeX, panel)
                    newPanel.content = this.panelContentTemplate.bind(this, selectedObject.frameObject, panel.id)
                }
                newPanel.zIndex = 2;
                return newPanel;
            })

        }
        
        return panelList;
    }
    addPanel = (selectedObject) => {
       
        let frameId = `Frame_${this.props.panelObjectList.length}`;
        let panel = {
            id: frameId,
            sizeX: 1,
            sizeY: 1,
            maxSizeY: 2,
            maxSizeX: 2,
            row: 0,
            col: 0,
            header: this.panelHeaderTemplate.bind(this, selectedObject, frameId, 1, {row:0,col:0}),
            content: this.panelContentTemplate.bind(this, selectedObject, frameId), //'<div class="content"><div>' + frameId + '</div></div>'
        };
        
        const newPanelObjectList = [...this.props.panelObjectList, { id: frameId, frameObject: selectedObject }];
    
        const panelList = this.dashboardObj.serialize();
        this.props.dispatch(setPanels(panelList, newPanelObjectList, panel, true));
        
    }
    removePanel = (frameId) => {
        
        //const panel = this.props.panelListUI.find(removePanel => removePanel.id === frameId)
        const panelObjectList = this.props.panelObjectList.filter(panel => panel.id !== frameId)
        //this.props.dispatch(setPanels(panelObjectList, panel, false));
        if (this.dashboardObj) {
             this.dashboardObj.removePanel(frameId);
            const dashboardPanelList = this.dashboardObj.serialize();
                this.props.dispatch(updatePanelsWithObjectList(dashboardPanelList, panelObjectList));
            
        }
        
    }
    panelHeaderTemplate(selectedObject, frameId, sizeX, panel) {
        const titleWidth = sizeX === 2 ? 11 : 10
        const deleteIconWidth = sizeX === 2 ? 1 : 2
        return (
            <Grid container>
                <Grid item xs={titleWidth }>
                    <Typography pt={1} className={this.props.classes.panelHeader} variant="body2">{`Title: ${selectedObject.title ? selectedObject.title : selectedObject.name}`}</Typography>
                </Grid>
                <Grid item xs={deleteIconWidth} pl={ 2}>
                    
                    <IconButton disableFocusRipple={true}  type="button" onClick={() => { this.removePanel(frameId) }}>
                        <DeleteOutlineIcon fontSize="small" />
                    </IconButton>
                </Grid>
            </Grid>
            );
    }
    panelContentTemplate(selectedObject) {

        let objectIcon = (<span><ListIcon className={this.props.classes.panelObjectIcon} fontSize="medium" />List</span>);
        if (selectedObject.type === "chart") {
            objectIcon = (<span><BarChartIcon className={this.props.classes.panelObjectIcon} fontSize="medium" />Chart</span>);
        }
        else if (selectedObject.type === "schedule") {
            objectIcon = (<span><DateRangeIcon className={this.props.classes.panelObjectIcon} fontSize="medium" />Schedule</span>);
        }
        return (
            <div style={{ height:"100%" }}>
                <Box className={this.props.classes.panelContentOutterBox}>
                    <Box className={this.props.classes.panelContentInnerBox} >
                        <Typography variant="subtitle1">{objectIcon}</Typography>
                        <Typography variant="body2">{`Name: ${selectedObject.name}`}</Typography>
                    </Box>
                </Box>
            </div>
        );
    }
    
    render() {
        
        const spacing = 2;
        const { classes } = this.props;
        return (
            <Grid container direction="row" spacing={11} >
                {(this.props.isLoading  && (this.props.dashboardActionStatus === actionTypes.VGO_GET_DASHBOARD_REQUEST || this.props.dashboardActionStatus === actionTypes.VGO_UPDATE_DASHBOARD_REQUEST)) &&
                    <ActionBackdrop />}
                <Grid item xs={6} >
                    <Card variant="outlined" p={3} pr={8 }>
                        <Grid container direction="row" spacing={spacing} pb={4}  >
                            <Grid item xs={12} className={classes.mainGridItem} >
                                <Grid item><Typography>Information</Typography></Grid>
                                <Box mt={.3} className={classes.subGrid}>
                                    <Grid container direction="row" spacing={4}>
                                        <Grid item xs={6} className={classes.gridPadding}>
                                            <TextField
                                                error={!(!this.props.errors.name)} autoFocus
                                                helperText={this.props.errors.name ? this.props.errors.name : ' '}
                                                required
                                                value={this.props.dashboard.name}
                                               
                                                onChange={(event) => {
                                                    if (event.target.value && this.props.errors.name) { // has error and new value entered
                                                        this.changeDashboard(dashboard => dashboard.name = event.target.value, { name:"" })
                                                    }
                                                    else if (!event.target.value && !this.props.errors.name) {  //no error and value cleared
                                                        this.changeDashboard(dashboard => dashboard.name = event.target.value, { name: "Name is required" })
                                                    }
                                                    else
                                                    { // no change in error status
                                                        this.changeDashboard(dashboard => dashboard.name = event.target.value)
                                                    }
                                                    
                                                }}
                                                onBlur={(event) => {
                                                    if (!event.target.value && !this.props.errors.name) {
                                                        this.blurValidation('name');
                                                    }
                                                }}
                                                name="name"
                                                label="Name"
                                                size="small"
                                                inputProps={{
                                                    maxLength: 50
                                                }}>
                                            </TextField>


                                        </Grid>
                                        <Grid item xs={6} className={classes.gridPadding}>
                                            <TextField
                                                error={!(!this.props.errors.title)}
                                                helperText={this.props.errors.title ? this.props.errors.title : ' '}
                                                required
                                                value={this.props.dashboard.title}
                                                onChange={(event) => {
                                                    if (event.target.value && this.props.errors.title) { // has error and new value entered
                                                        this.changeDashboard(dashboard => dashboard.title = event.target.value, { title: "" })
                                                    }
                                                    else if (!event.target.value && !this.props.errors.title) {  //no error and value cleared
                                                        this.changeDashboard(dashboard => dashboard.title = event.target.value, { title: "Title is required" })
                                                    }
                                                    else { // no change in error status
                                                        this.changeDashboard(dashboard => dashboard.title = event.target.value)
                                                    }

                                                }}
                                                onBlur={(event) => {
                                                    if (!event.target.value && !this.props.errors.title) {
                                                        this.blurValidation('title');
                                                    }
                                                }}
                                                name="title"
                                                label="Title"
                                                size="small"
                                                inputProps={{
                                                    maxLength: 100
                                                }}>
                                            </TextField>

                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box className={classes.subGrid} pt={5.3}>
                                    <Grid container direction="row" spacing={4}>
                                        <Grid item xs={6} className={classes.paddingEmpty}>
                                            <Category
                                                errors={this.props.errors ? this.props.errors : {}}
                                                onBlurValidation={this.blurValidation}
                                                changeDataView={this.changeCategory}
                                                dataView={this.props.dashboard}
                                                requestStatus={this.props.categories.requestStatus}
                                                categories={this.props.categories.list}
                                            />
                                        </Grid>

                                        <Grid item xs={6} className={classes.paddingEmpty}>

                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box className={classes.subGrid} mt={6}>
                                    <Grid container direction="row" spacing={4} >
                                        <Grid item xs={12} className={classes.enablePaging} >
                                            <TextField
                                                fullWidth={true}
                                                value={this.props.dashboard.description}
                                                onChange={event => this.changeDashboard(dashboard => dashboard.description = event.target.value)}
                                                label="Description"
                                                size="small"
                                                inputProps={{ maxLength: 200 }}
                                            >
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box className={classes.subGrid} mt={5.5}>
                                    <Grid container direction="row" spacing={0}>
                                        <Grid item xs={12} className={classes.enablePaging} >
                                            <TextField
                                                fullWidth={true}
                                                value={this.props.dashboard.userInstruction}
                                                onChange={event => this.changeDashboard(dashboard => dashboard.userInstruction = event.target.value)}
                                                label="Instructions"
                                                size="small"
                                                inputProps={{ maxLength: 1000 }}
                                            >
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box className={classes.subGrid} mt={6}>
                                    <Grid container direction="row" spacing={4}>
                                        <Grid item xs={12} >
                                            <Grid container direction="row">
                                                <Grid item ><Typography>Availability</Typography></Grid>
                                            </Grid>

                                            <Grid container direction="row" spacing={0} mt={ 3}>
                                                <Grid item xs={2}  pl={ 1} >
                                                    <FormGroup row={false}>
                                                        <FormControlLabel
                                                            control={<Checkbox
                                                                color="primary"
                                                                checked={this.props.dashboard.isPublic}
                                                                onChange={this.toggleIsPublic}
                                                            />}
                                                            label={<span className={classes.body2}>Public</span>} />

                                                    </FormGroup>

                                                </Grid>
                                                <Grid item xs={10} ml={ -1} >
                                                    <Grid item container direction="column">
                                                        <Box mt={2} >
                                                            <Autocomplete
                                                                multiple
                                                                id="dashboardRole"
                                                                size="small"
                                                                label="Roles"
                                                                disableClearable
                                                                className={classes.roleControl}
                                                                fullWidth={true}
                                                                loading={this.props.roles.requestStatus === actionTypes.VGO_GET_ROLES_REQUEST}
                                                                options={this.props.roles.list}
                                                                getOptionLabel={option => option.roleName}
                                                                isOptionEqualToValue={
                                                                    (option, value) => option.roleName === value.roleName}
                                                                value={this.props.dashboard.roles}
                                                                onChange={(event, value, reason) => { this.onRolesChange(event, value, reason) }}
                                                                onBlur={(event, value) => { this.blurValidation('roles') }}
                                                                renderInput={params => (
                                                                    <TextField {...params}

                                                                        InputProps={{
                                                                            ...params.InputProps,
                                                                            endAdornment: (
                                                                                <React.Fragment>
                                                                                    {this.props.roles.requestStatus === actionTypes.VGO_GET_ROLES_REQUEST && <CircularProgress color="inherit" size={20} />}
                                                                                    {params.InputProps.endAdornment}
                                                                                </React.Fragment>
                                                                            ),
                                                                        }}
                                                                        variant="outlined"
                                                                        size="medium"
                                                                        label="Roles"
                                                                        error={!(!this.props.errors.roles)}
                                                                        helperText={this.props.errors.roles ? this.props.errors.roles : ' '}
                                                                    />
                                                                )}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Grid>


                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box className={classes.subGrid} mt={5}>
                                    <DashboardItemList addPanel={this.addPanel} addPanelButtonClassName= {classes.addPanelButton} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={5} style={{ height: "100%" }} >
                    <Card variant="" style={{ height: "100%" }} pr={2} pb={ 2}>
                        {this.renderDashboardLayout()}
                    </Card>
                </Grid>
            </Grid>
        );
    }
} 

function mapStateToProps(state) {
    
    
    return {
        dashboard: state.dashboards.dashboard,
        categories: state.dataViews.categories,
        roles: { requestStatus: state.roles.getRoleListRequestStatus, list: state.dataViews.roles },
        dashboardActionStatus: state.dashboards.dashboardActionStatus,
        isLoading: state.loader.isLoading,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage,
        errors: state.dashboards.errors,
        panelObjectList: state.dashboards.panelObjectList,
        panelListUI: state.dashboards.panelList,
        isUpdateSuccess: state.dashboards.isUpdate,
        //alert: state.alert,
    };
}; 
export default withRouter(connect(mapStateToProps)(withStyles(dashboardTheme)(DashboardEdit)));