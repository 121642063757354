import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { styled } from '@mui/styles';
import { withStyles } from '@mui/styles';
import { spacing } from '@mui/system';
import { Grid, InputLabel, FormHelperText, Select, MenuItem, Box, IconButton, FormControl } from '@mui/material';
import { getChangeMenu, validateFieldsOnBlur, getContentDragData } from 'admin/actions/menuActions';
import { getRole, getNewRole, discardRole } from 'admin/actions/roleActions';
import { menuTheme } from './theme/menuTheme';
import MuiTextField from '@mui/material/TextField';
import ActionBackdrop from 'admin/components/section/settings/Backdrop';
const TextField = styled(MuiTextField)(spacing);

const Setup = (props) => {
    const changeMenu = (change) => {
        props.dispatch(getChangeMenu(change));
    }
    const handleBlurValidation = (fieldName) => {

        props.dispatch(validateFieldsOnBlur(props.menu, fieldName));
    }
    const dispatchGetRoleData = (roleId) => {
        if (roleId)
            props.dispatch(getRole(roleId));
        else
            props.dispatch(getNewRole());
    }
    const fillRoles = (roles) => {
        let newRoles = roles.map((role, index) => {
            return (<MenuItem key={index} value={role.roleId}> {role.roleName} </MenuItem>);
        });
        newRoles.unshift(<MenuItem key={0} value={0}>{""}</MenuItem>);

        return newRoles;
    }

    return (
        <React.Fragment>
            {/*{props.isLoading && <ActionBackdrop />}*/}
            <Grid item xs={3} ml={3} >
                <Box sx={{ width: 304 }}>
                    <TextField autoFocus
                        required
                        label="Name"
                        size="small"
                        fullWidth={true}
                        value={props.menu.menuName}
                        onChange={event => changeMenu(menu => menu.menuName = event.target.value)}
                        onBlur={(event) => {
                            handleBlurValidation('menuName');
                        }}
                        inputProps={{ maxLength: 100 }}
                        error={!(!props.errors.menuName)}
                        helperText={props.errors.menuName ? props.errors.menuName : ' '}
                    />
                </Box>
            </Grid>
            <Grid item xs={3}  >
                <Box sx={{ width: 304 }} ml={-6.68}>
                    <TextField
                        label="Description"
                        size="small"
                        fullWidth={true}
                        value={props.menu.menuDescription}
                        onChange={event => changeMenu(menu => menu.menuDescription = event.target.value)}
                        inputProps={{ maxLength: 500 }}
                    />
                </Box>
            </Grid>
            <Grid item xs={3} >
                <Box sx={{ width: 304 }} ml={-10.25}>
                    <FormControl error={props.errors.roleId} value="Standard" variant="standard">
                        <InputLabel id="roleId">Role*</InputLabel>
                        <Select MenuProps={{ disableScrollLock: true }}
                            labelId="roleId"
                            size="small"
                            fullWidth={true}
                            defaultValue=""
                            value={props.menu.menuRoles[0].roleId}
                            onChange={event => {
                                props.dispatch(discardRole());
                                props.dispatch(getContentDragData({ item: { id: "", contentType: "" } }));
                               
                                changeMenu(menu => {
                                    menu.roleId = event.target.value
                                    menu.menuRoles[0].roleId = event.target.value
                                    menu.quickActions = []
                                    menu.menuStructure = []
                                })
                                 dispatchGetRoleData(event.target.value);
                            }}
                            onBlur={(event) => {
                                handleBlurValidation('roleId');
                            }}
                        >
                            {fillRoles(props.roles)}

                        </Select>
                        <FormHelperText>{props.errors.roleId ? props.errors.roleId : " "}</FormHelperText>
                    </FormControl>
                </Box>
            </Grid>
        </React.Fragment>
    );
}

function mapStateToProps(state) {
    return {
        menu: state.menus.menu,
        roles: state.menus.roles,// state.roles,
        isLoading: state.loader.isLoading,
        errors: state.menus.errors,
    };
};

export default withRouter(connect(mapStateToProps)(withStyles(menuTheme)(Setup)));
