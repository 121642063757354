import React from 'react';
import PropTypes from 'prop-types';
import { List, Collapse } from '@mui/material';
import MenuItem from './MenuItem.js';
import { MenuTheme } from './theme/MenuTheme.js';
import { ListTheme, AdminListTheme, QuickListTheme } from './theme/MenuItemTheme.js';
import MuiListItem from "@mui/material/ListItem";
import Divider from '@mui/material/Divider';
import { withStyles } from '@mui/styles';

const ListItem = withStyles(ListTheme)(MuiListItem);
const AdminListItem = withStyles(AdminListTheme)(MuiListItem);
const QuickListItem = withStyles(QuickListTheme)(MuiListItem);

class Menu extends React.Component {

    
    render() {
        
        const classes = this.props.classes;
        const listItem = this.props.isAdmin ? AdminListItem : ListItem;
        return (
                <Collapse className={classes.container} in={this.props.in}>
                    <List className={classes.list} dense={true}>
                    {this.props.items.map(item => {
                        return (item.key === "divider") ?
                            <Divider className={classes.divider}/> :
                             <MenuItem
                                listItem={item.quickLink ? QuickListItem : listItem}
                                level={this.props.level + 1}
                                key={item.key}
                                item={item}
                                in={false}
                                activeMenuKey={this.props.activeMenuKey}
                                handleClick={this.props.handleClick} />
                        }
                    )}
                    
                    </List>
                </Collapse>
        );
    }

}

export default (withStyles(MenuTheme)(Menu))

Menu.propTypes = {
    items: PropTypes.array.isRequired,
};

Menu.defaultProps = {
    level: 0,
    in: false,
    handleClick: (key) => { },
    activeMenuKey:'',
};
