import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import MUIDataTable from 'mui-datatables';
import { withStyles } from '@mui/styles';
import { Box } from '@mui/material'
import { produce } from 'immer';
import { databaseTheme } from './theme/databaseTheme.js';
import { getDatabases, deleteDatabase, canDeleteDatabase } from 'admin/actions/databaseActions';
import DeleteButton from 'common/DeleteButton.js';
import api from 'admin/api/adminApi';
import ActionBackdrop from 'admin/components/section/settings/Backdrop';
import { showError } from 'admin/actions/alertActions';
import { MuiGridOptions } from 'admin/components/Common/CommonOptions';

const defaultColumnOptions = {
    filter: true,
    sort: true,
   
};

class DatabaseList extends React.Component {
    constructor(props) {
        const { classes } = props;
        super(props);
        this.state = {
            columns: [
                {
                    name: "databaseId",
                    label: "Database Id",
                    options: {
                        ...defaultColumnOptions,
                        display: 'false'
                    }
                },
                {
                    name: "databaseConnectionName",
                    label: "Name",
                    options: {
                        ...defaultColumnOptions,
                        setCellProps: () => ({ className: classes.nameColumn }),
                    }
                   
                },
                {
                    name: "description",
                    label: "Description",
                    options: {
                        ...defaultColumnOptions,
                        setCellProps: () => ({ className: classes.descriptionColumn }),
                    }
                },
                {
                    name: "serverName",
                    label: "Server Name",
                    options: {
                        ...defaultColumnOptions,
                        setCellProps: () => ({ className: classes.serverColumn }),
                    }
                },
                {
                    name: "database",
                    label: "Database",
                    options: {
                        ...defaultColumnOptions,
                        setCellProps: () => ({ className: classes.gridColumn }),
                    }
                },
                {
                    name: "",
                    label: "",
                    options: {
                        ...defaultColumnOptions,
                        customBodyRender: this.deleteRowTemplate,
                        setCellProps: () => ({ className: classes.deleteColumn }),
                        filter: false,
                    }
                }
            ],
            options: {
                download: false,
                elevation: 0,
                responsive: 'scrollMaxHeight',
                filter: true,
                filterType: 'multiselect',
                jumpToPage: true,
                onFilterChange: (changedColumn, filterList, type, changedColumnIndex) => {
                    if (changedColumnIndex !== null) {
                        this.setState(produce(draft => {
                            draft.columns[changedColumnIndex].options.filterList = filterList[changedColumnIndex];
                        }));
                    }
                },
                onViewColumnsChange: (changedColumn, action) => {
                    let columnIndex = this.state.columns.findIndex(c => c.name === changedColumn);
                    this.setState(produce(draft => {
                        draft.columns[columnIndex].options.display = action === "add";
                    }));
                },
                onCellClick: (colData, cellMeta) => {
                    let dataIndex = cellMeta.dataIndex;
                    let database = this.props.databases[dataIndex];
                    let columnIndex = this.state.columns.findIndex(c => c.name === "");
                    if (columnIndex !== cellMeta.colIndex)
                        this.getDatabaseData(database.databaseId)
                },
                print: false,
                searchOpen: true,
                pagination: false,
                searchPlaceholder: 'Enter search text ...',
                selectableRows: 'none',
                sortOrder: {
                    name: 'databaseConnectionName',
                    direction: 'asc'
                },
                onFilterChange: (changedColumn, filterList, type, changedColumnIndex, displayData) => {
                    this.setState(produce(draft => {
                        draft.columns[changedColumnIndex].options.filterList = filterList[changedColumnIndex];
                    }));
                },
                onColumnSortChange: (changedColumn, direction) => {
                    this.setState(produce(draft => {
                        draft.options.sortOrder = { name: changedColumn, direction: direction };
                    }));
                }
            }
        };
    }

    componentDidMount() {
        this.dispatchGetDatabases();
    }

    dispatchGetDatabases() {
        this.props.dispatch(getDatabases()); 
    }

    getDatabaseData(databaseId) {
        this.props.history.push(`/Admin/Databases/${databaseId}`);
    }

    deleteRowTemplate = (value, tableMeta, updateValue) => {
        const columnIndex = 0;
        return <DeleteButton preValidationServer={api.canDeleteDatabase}  onClick={this.dispatchDeleteDatabase} id={tableMeta.rowData[columnIndex]} />
    }

    deletePreValidation = () => {
        return { canDelete: true, message: "" }
    }

    dispatchDeleteDatabase = (id) => {
        const database = this.props.databases.find(u => u.databaseId === id);
        this.props.dispatch(deleteDatabase(database.databaseId));


        //api.canDeleteDatabase(id).then((resp) => {
        //    let message = (resp.data === "") ? resp.data : resp.data.message;

        //    if (message) {
        //        showError(this.props.dispatch, message, 0);

        //    } else {
        //        this.props.dispatch(deleteDatabase(database.databaseId));
        //    }

        //})
        //    .catch((err) => {
        //        let message = err.response.data;
        //        showError(this.props.dispatch, message, 0);
        //    });



       
    }

    render() {
        const { classes } = this.props;
            return (
                <Box border={1} className={classes.databaseListBox}>
                    {this.props.isDataLoading && <ActionBackdrop />}
                    <MUIDataTable container className={classes.MUIDataTablePadding }
                    title={''}
                    data={this.props.databases}
                    columns={this.state.columns}
                        options={MuiGridOptions(this.state.options, this.props.isDataLoading)}
                />
                </Box>
        );
    }
}

function mapStateToProps(state) {
    return {
        databases: state.databases.databases,
        canDeleteDataBase:state.databases.canDelete,
        isLoading: state.loader.isLoading,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage,
        isDataLoading: state.databases.isLoading,
    };
};
export default withRouter(connect(mapStateToProps)(withStyles(databaseTheme)(DatabaseList)));