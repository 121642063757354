import { customThemeStyles } from 'common/theme.js';
export const setupTheme = (theme) => ({
    ...customThemeStyles,
    root: {
        justifyContent: 'center!important',
        flexWrap: 'wrap!important',
        height: "100%!important",
        borderRadius: "4px!important",
    },
    card: {
       /* height: "642px!important",*/
        marginLeft: theme.spacing(-3) + '!important',
        marginRight: theme.spacing(-3) + '!important',
        marginTop: theme.spacing(-3) + '!important',
    },
    chip: {
        margin: theme.spacing(0.5) + '!important',
    },
    formControl: {
        marginTop: '12px!important',
        marginBottom: 0,
    },
    buttonSave: {
        minWidth: '94px!important'
    },
    buttonCancel: {
        minWidth: '89px!important',
        marginRight: '4px!important',
       },

    queryRoot: {
        width: '100%!important',
    },
    wrapper: {
        position: 'relative!important',
    },
    queryCard: {
        marginLeft: theme.spacing(-3) + ' !important',
        marginRight: theme.spacing(-3) + ' !important',
      marginBottom: theme.spacing(1) + ' !important',
       
    },
    queryChip: {
        margin: theme.spacing(0.5) + ' !important',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15) +' !important',
        fontWeight: theme.typography.fontWeightRegular + ' !important',
    },
    buttonProgress: {
        color: "secondary!important",
        position: 'absolute!important',
        top: '50%!important',
        left: '50%!important',
        marginTop: -12 +'!important',
        marginLeft: -12 +'!important',
    },
    buttonQueryExecute: {
        textAlign: 'right!important',
        paddingTop: '32px!important',
        paddingBottom:'33px!important'
    },

    buttonExecute: {
        minWidth: '123px!important',
        maxHeight:'31px!important',
        backgroundColor: '#5CB660!important',
        color: 'white!important',
        marginRight:'45px!important'
    },
    MUIDataTable: {
        overflow: 'auto!important',
        height: '544px!important',
        width: '100%!important',
        maxWidth: '1481px!important',

    },
    dependencyDataTable: {
        maxHeight: 550,
    },
    mainGrid: {
       /* maxWidth:'1395px'*/

    },
    subGrid: {
        maxWidth: '640px!important'

    },
    mainGridItem: {
        maxWidth: '696px!important'

    },
    roleList: {
        height: '140px!important'

    },
    cloneGrid: {
        textAlign: 'right!important',
    },
    queryEditor: {

        height: '539px!important'
    },

    gridPadding: {

        paddingBottom:'8px!important'
    }
    ,
    paddingEmpty: {

        paddingBottom: '0px!important',
        paddingTop: '0px!important'
    },
    formatPadding: {

        paddingTop: '15px!important',
        maxWidth: '160px!important'
    },
    enablePaging: {

        paddingTop: '0px!important'
    },
    scheduleGrid: {
        maxWidth: '240px',
        paddingBottom: '0px!important'
    },
    cloneButton: {
        marginRight: '8px!important',
        maxWidth: '96px!important',
        maxHeight: '32px!important',
        backgroundColor: theme.palette.primary.main + '!important',
        color: theme.palette.common.white + '!important',
    },
    setupSpan: {
        fontSize:'14px!important'
    },
    dependencyRoot: {
        width: '100%!important',
        
        '& > * + *': {
            marginTop: theme.spacing(1)+'!important',
        },
        
    },

    dependencyCollapse: {
       
        zIndex: 1000,
    },

    alert: {
        padding: "1px 16px!important",
        position: "absolute!important",
        top: "15px!important",
        right: "55px!important",
        zIndex: '10px!important',
    },
    dependencyButtonProgress: {
        color: '#fff!important',
    },
   
    querySummary: {

        minHeight: "0px!important",
        marginTop:'5px!important',
        '&$expanded': {
            minHeight: "0px!important",
            marginTop: '0px!important',
        }
    },

   queryDetails: {
       paddingBottom: '0px!important',
       marginBottom: '41px!important',
       paddingTop:'0px'

    },

    queryText: {

        marginLeft: '8px !important',
        paddingRight: '48px !important',
       
    }
    ,
    queryTextStyle: {
        minHeight:'502px !important',
        maxHeight: '502px !important'
    },
    databaseText:{marginTop:'28px!important'},
    fieldDisplayTab: {
        minWidth: '220px!important',
    },
    setupTab: {
        minWidth: '156px!important',
    },
    subRecordTab: {
        minWidth: '205px!important',
    },
    linksTab: {
        minWidth: '160px!important',
    }
    ,
    recordSubmissionTab: {
        minWidth: '234px!important',
    }
    ,
    chartTab: {
        minWidth: '166px!important',
    }
    ,
    calenderTab: {
        minWidth: '182px!important',
    },
    filterTab: {
        minWidth: '164px!important',
    }
    ,
    reportName: {

        paddingTop: '25px!important',
        paddingLeft: '29px!important',
        wordBreak: 'break-all'
    },
    tabRoot: {
        background:'#fff!important'
    },

    checkboxItem: {
        maxWidth:'230px!important'
    },
    roleControl: {

        minWidth:'382px!important'
    },

    cloneGridItem: {

        minWidth: '166px!important'
    },

    dataSourceItem: {
        paddingTop:'16px!important'
    },
    formSetupTab: {
        minWidth: '160px!important',
    },
    setupAccordion: {
        marginLeft: theme.spacing(-3) + ' !important',
        marginRight: theme.spacing(-3) + ' !important',
        marginTop: theme.spacing(-3) + ' !important',
        marginBottom: theme.spacing(1) + ' !important',

    },
    

});
