import { customThemeStyles } from 'common/theme.js';
export const packageTheme = (theme) => ({
    ...customThemeStyles,
    root: {
        justifyContent: 'center!important',
        flexWrap: 'wrap!important',
        height: "93px!important",
        borderRadius: "4px!important"
    },
    card: {
        
        height: "100%!important",
        marginLeft: theme.spacing(2) +'!important',
        marginRight: theme.spacing(2) + '!important'
    },
    chip: {
        margin: theme.spacing(0.5) + '!important'
    },
    gridPadding: {

        paddingBottom: '8px!important'
    },
    gridPaddingLogFile: {

        paddingBottom: '8px!important',
        marginTop:'-6px!important',
        textAlign:'right',
    },
    subGrid: {
        maxWidth: '640px!important'

    },
    packageSetup: {

        minHeight: "0px!important",
        marginTop: '5px!important',
        '&$expanded': {
            minHeight: "0px!important",
            marginTop: '0px!important',
            marginBottom: '0px!important',
        }
    },
    heading: {
        fontSize: theme.typography.pxToRem(15) + ' !important',
        fontWeight: theme.typography.fontWeightRegular + ' !important',
    },
});