import { theme, customThemeStyles, customTheme} from 'common/theme.js';
export const MenuItemTheme = () => ({
    ...customThemeStyles,
    roleList: {
        paddingLeft: '46px!important',
        wordBreak: "break-all!important",
    },
    dense: {
        minHeight: '48px!important',
        textDecoration: "none !important"
    },
    denseMenu: {
        minHeight: '56px!important',
        textDecoration: "none !important"
    },
    listItemIcon: {
        minWidth: '36px!important',
        color: customThemeStyles.neutral_icon.backgroundColor + '!important',
        alignItems: "center!important",
    },
    listItemIconFavorite: {
        marginLeft: '-5px!important',
        marginRight: '5px!important'
    },
    chip: {
        backgroundColor: theme.palette.primary.dark + '!important',
        color: theme.palette.primary.contrastText + '!important',
    },
    listItemText: {
        color: theme.palette.primary.contrastText + '!important',
        wordBreak: "break-word!important",
    },
    icon: {
        color: customThemeStyles.neutral_icon.backgroundColor + '!important',

    },
    subLabel: {
        paddingLeft: '6px!important',
    },
    boxDisplay: {
        width: "100%!important",
        display: "flex!important"
    },
    roleSelectedItem: {
        paddingLeft: '46px!important',
        marginTop: -21
    },
    adminBoxDisplay: {
        width: "100%!important",
        display: "flex!important",
        marginLeft: "16px!important"
    },
    adminMenuHeaderBox: {
        width: "100%!important",
        textAlign: "center",
        height: "26px"
    },
    adminMenuHeaderItem: {
        marginTop:"-13px"
    },
    adminMenuHeaderMain: {
        marginTop: "-5px"
    },
    burgerExpanded: {
        float: "right",
        margin: theme.spacing(2.31, 2.74, 0, 0) + '!important',

    },
    burgerCollapsed: {
        display: 'flex!important',
        alignItems: 'center!important',
        padding: theme.spacing(3) + '!important',
        justifyContent: 'flex-end!important',
        height: theme.spacing(8) + '!important',
    },

    leftPanelHeader: {
        fontFamily: 'Roboto!important',
        fontStyle: 'normal!important',
        fontWeight: 400,
        fontSize: '14px!important',
        lineHeight: '18px!important',
        letterSpacing: '1.25px!important',
        texTransform: 'uppercase'

    },
    adminIcon: {
        color: '#ffffff !important',

    },
    adminListItemIcon: {
        minWidth: '33px!important',
        color: customThemeStyles.neutral_icon.backgroundColor + '!important',
        alignItems: "center!important",
    },
    burgerIconRoot: {

        cursor: "pointer"
    },
    scrollbarHorizontal: {
        display: 'none!important',
    },
    scrollbarContainer: {
        height: "calc(100vh - 291px)!important",//"670px!important",
        width: "100%!important"
    },
});

export const ListTheme = () => ({
    root: {
        '&$selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.40)!important',
            "& svg": {
                fill: "rgba(0, 0, 0, 0.80)!important",
            },
            "&:hover": {
                backgroundColor: 'rgba(0, 0, 0, 0.40)!important',
                "& svg": {
                    fill: "rgba(0, 0, 0, 0.80)!important",
                }
            },
        },
        "&:hover": {
            backgroundColor: 'rgba(0, 0, 0, 0.40)!important',
            "& svg": {
                fill: "rgba(0, 0, 0, 0.60)!important",
            }
        },
    },
    selected: {},
    gutters: {
        paddingLeft: '0px!important',
        paddingRight: '16px!important',
    },


});
export const AdminListTheme = () => ({
    root: {
        '&$selected': {
            backgroundColor: '#127ACD!important',
            "& svg": {
                fill: "#ffffff!important",
            },
            "&:hover": {
                backgroundColor: '#127ACD!important',
                "& svg": {
                    fill: "#ffffff!important",
                }
            },
        },
        "&:hover": {
            backgroundColor: '#127ACD!important',
            "& svg": {
                fill: "#ffffff!important",
            }
        },
    },
    selected: {},
    gutters: {
        paddingLeft: '0px!important',
        paddingRight: '16px!important',
    },


});
export const QuickListTheme = () => ({
    root: {
        "& svg": {
            fill: "#fff !important",
        },
        '&$selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.40)!important',
            "&:hover": {
                backgroundColor: 'rgba(0, 0, 0, 0.40)!important',
            },
        },
        "&:hover": {
            backgroundColor: 'rgba(0, 0, 0, 0.40)!important',
            "& p": {
                color: `${customTheme.palette.primary.main} !important`
            },
            "& svg": {
                fill: `${customTheme.palette.primary.main} !important`,
            }
        },
    },
    selected: {},
    gutters: {
        paddingLeft: '0px!important',
        paddingRight: '16px!important',
    },


});

