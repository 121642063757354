import { customThemeStyles } from 'common/theme.js'; 
export const loginTheme = () => ({
    ...customThemeStyles,
    toggleButtonStyle: {
        display: "none",
    },
    icon: {
        height: "15px",
        width: "15px",
        left: "1.33px",
        top: "1.33px",
        color: "rgba(0, 0, 0, 0.54)"
    },
    iconButton : {
        padding: "0px"
    },
    loginTextbox: {
        height: "40px",
        width: "209px",
        left: "0px",
        top: "0px",
        borderRadius: "4px",
        backgroundColor: "white",
    },
    signInText: {
        color: "white !important",
        textTransform: "capitalize",
        padding: "0px 0px",
    },
    signInButton: {
        marginLeft: '-18px!important',
        width: '50%'
    },
    loginRememberMeText: {
        display: "flex",
        alignItems: "flex-end",
        color: "#FFFFFF",
        flex: "none",
        order: 1,
        flexGrow: 0,
        fontSize: "12px!important",
        textAlign: "left!important",
        fontFamily: "Roboto!important",
        fontWeight: "700!important",
        lineHeight: "14",
        letterSpacing: "0.25"
       /* width: "90px",*/
    },
    loginResetPasswordText: {
        textAlign: "left",
        color: "white !important",
    },
    errorText: {
        display: "flex",
        textAlign: "left",
        color: "#FFFFFF",
        flex: "none",
        order: 1,
        flexGrow: 0,
        margin: "0px 0px 0px 8px",
    },
    resetIcon: {
        color:"white",
    },
    resetLink: {
        color: '#007ad9',
        textDecoration: 'none !important',
    },
    notchedOutline: {
        //borderWidth: '1px',
        borderColor: '#FF0000 !important',
        //color: '#FF0000  !important',
    },
    image: {
        marginLeft: '-10px!important',
        paddingRight: '14px!important'
    },
    leftPanelColor: {
        backgroundColor: customThemeStyles.neutral.backgroundColor + '!important',
    },
    adminLeftPanelColor: {
        backgroundColor: customThemeStyles.neutral.backgroundColor + '!important',
        paddingBottom: "150px",
    },
});
export const checkboxTheme = () => ({
    root: {
        color: "gray!important",
        "& .MuiIconButton-label": {
            position: "relative!important",
            zIndex: 0
        },
        '&$checked': {
            color: "white!important",
        },
        "&:not($checked):after": {
            content: '""',
            height: 15,
            width: 15,
            position: "absolute!important",
            backgroundColor: "white!important",
            zIndex: -1
        },
        padding: "0px 1px 0px 0px"
    },
    checked: {},
});

