import React from 'react';
import ContentList from './ContentList'
import ContentDetails from './ContentDetail'
import { Route } from 'react-router-dom';

import { Grid } from '@mui/material';
class contentLayout extends React.Component {
    
    
    render() {
        return (
            <div>
                <div id="divMiddle1" >
                    <div>
                        <Grid container><Grid item sx={{width:1}}>
                            <Route path='/Content/:id/:isPreLogin' component={ContentDetails} />

                        </Grid>
                          </Grid>
                                                     
                        </div>
                    </div>
                </div>
            );
    }

}
export default contentLayout;

