export const userMenu = [{ key: "profile", label: "Profile" },
    { key: "admin", label: "Admin" },
    { key: "signout", label: "Sign Out" }];

export const banners = [{ "id": 3, "title": "Promotion for demo & demo2", "content": "Big, big sale!&nbsp;:P", "createDate": "2019-03-28T02:10:19", "type": "promotion", "detailDisplayType": "more" },
    { "id": 2, "title": "Alert for Demo only", "content": "Alert to be seen by the Demo role only.", "createDate": "2019-03-28T02:09:02", "type": "alert", "detailDisplayType": "more" },
    { "id": 1, "title": "Versago 2.4, build 2.4.1", "content": "Installed 25/Oct/2019", "createDate": "2018-10-30T01:26:12", "type": "notification", "detailDisplayType": "expand" }];

export const newsAndAnnouncements = [{ "id": 5, "subject": "Event with embedded image", "summary": "Image embedded in content", "content": "<p>Here is an image.</p><p><img alt=\"Image\" height=\"100\" src=\"../ImageBrowser/Image?path=171.jpg\" width=\"100\" /></p>", "type": "event", "thumbnailImageFilepath": null, "modifiedUser": null, "modifiedDate": "Yesterday, 11:21 PM", "eventDate": "2019-04-01T00:00:00", "isPreLogin": true },
    { "id": 2, "subject": "Announcement for Demo only", "summary": "This is an announcement", "content": "<h1>Big Announcement</h1>", "type": "announcement", "thumbnailImageFilepath": null, "modifiedUser": null, "modifiedDate": "Yesterday, 11:21 PM", "eventDate": "2050-01-01T00:00:00", "isPreLogin": false },]

export const widgets = [{ "name": "Weather API", "description": "Weather API11", "type": "weather", "apiKey": "07b2ded7da2d4e41bc4153341170302", "apiUrl": "https://api.apixu.com/v1/current.json?key=[ApiId]&q=[zipCode]", "details": [] },
    { "name": "Links and Resources", "description": "Links and Resources", "type": "link", "apiKey": "", "apiUrl": "", "details": [{ urlCaption: "Link1", url: "#" }, { urlCaption: "Link2", url: "#" }] },
    { "name": "Quote of the day", "description": "Quote of the day", "type": "quote", "apiKey": "", "apiUrl": "https://quotes.rest/qod.json", "details": [] }]