const lastNames = [
	"Abraham",
	"Allan",
	"Alsop",
	"Anderson",
	"Arnold",
	"Avery",
	"Bailey",
	"Baker",
	"Ball",
	"Bell",
	"Berry",
	"Black",
	"Blake",
	"Bond",
	"Bower",
	"Brown",
	"Buckland",
	"Burgess",
	"Butler",
	"Cameron",
	"Campbell",
	"Carr",
	"Chapman",
	"Churchill",
	"Clark",
	"Clarkson",
	"Coleman",
	"Cornish",
	"Davidson",
	"Davies",
	"Dickens",
	"Dowd",
	"Duncan",
	"Dyer",
	"Edmunds",
	"Ellison",
	"Ferguson",
	"Fisher",
	"Forsyth",
	"Fraser",
	"Gibson",
	"Gill",
	"Glover",
	"Graham",
	"Grant",
	"Gray",
	"Greene",
	"Hamilton",
	"Hardacre",
	"Harris",
	"Hart",
	"Hemmings",
	"Henderson",
	"Hill",
	"Hodges",
	"Howard",
	"Hudson",
	"Hughes",
	"Hunter",
	"Ince",
	"Jackson",
	"James",
	"Johnston",
	"Jones",
	"Kelly",
	"Kerr",
	"King",
	"Knox",
	"Lambert",
	"Langdon",
	"Lawrence",
	"Lee",
	"Lewis",
	"Lyman",
	"MacDonald",
	"Mackay",
	"Mackenzie",
	"MacLeod",
	"Manning",
	"Marshall",
	"Martin",
	"Mathis",
	"May",
	"McDonald",
	"McLean",
	"McGrath",
	"Metcalfe",
	"Miller",
	"Mills",
	"Mitchell",
	"Morgan",
	"Morrison",
	"Murray",
	"Nash",
	"Newman",
	"Nolan",
	"North",
	"Ogden",
	"Oliver",
	"Paige",
	"Parr",
	"Parsons",
	"Paterson",
	"Payne",
	"Peake",
	"Peters",
	"Piper",
	"Poole",
	"Powell",
	"Pullman",
	"Quinn",
	"Rampling",
	"Randall",
	"Rees",
	"Reid",
	"Roberts",
	"Robertson",
	"Ross",
	"Russell",
	"Rutherford",
	"Sanderson",
	"Scott",
	"Sharp",
	"Short",
	"Simpson",
	"Skinner",
	"Slater",
	"Smith",
	"Springer",
	"Stewart",
	"Sutherland",
	"Taylor",
	"Terry",
	"Thomson",
	"Tucker",
	"Turner",
	"Underwood",
	"Vance",
	"Vaughan",
	"Walker",
	"Wallace",
	"Walsh",
	"Watson",
	"Welch",
	"White",
	"Wilkins",
	"Wilson",
	"Wright",
	"Young",
];

const emails = [
    "gmail.com",
    "apple.com",
    "icloud.com",
    "msn.com",
    "yahoo.com",
    "aol.com",
    "hotmail.com",
];

const company = [
    'Acme',
    'Sears',
    'IBM',
    'Microsoft',
    'JC Penney',
	'Exxon',
	'Mobil',
	'Google',
	'Apple',
	'Amazon',
];

const tables = [
    'OCRD',
    'OCMP',
    'ORDR',
    'TWBS_WS_CUSTM'
];

const fields = [
    'firstname',
    'lastname',
    'email',
    'active'
];

const cities = [
	{
		City: "New York", 
		State: "NY",
	},
	{
		City: "Los Angeles", 
		State: "CA",
	},
	{
		City: "Chicago", 
		State: "IL",
	},		
	{
		City: "Houston",
		State: "TX",
	}, 
	{
		City: "Phoenix", 
		State: "AZ",
	},
	{
		City: "Philadelphia",
		State: "PA",
	},
	{
		City: "San Antonio",
		State: "TX",
	},
	{
		City: "San Diego",
		State: "CA",
	},
	{
		City: "Dallas",
		State: "TX",
	},
	{
		City: "San Jose",
		State: "CA",
	},
	{
		City: "Austin",
		State: "TX",
	},
	{
		City: "Jacksonville",
		State: "FL",
	},
	{
		City: "Fort Worth",
		State: "TX",
	},
	{
		City: "Columbus",
		State: "OH",
	},
	{
		City: "Charlotte",
		State: "NC",
	},
	{
		City: "San Francisco",
		State: "CA",
	},
	{
		City: "Indianapolis",
		State: "IN",
	},
	{
		City: "Seattle",
		State: "WA",
	},
	{
		City: "Denver",
		State: "CO",
	},
	{
		City: "Washington",
		State: "DC",
	},
	{
		City: "Boston",
		State: "MA",
	},
	{
		City: "El Paso",
		State: "TX",
	},
	{
		City: "Nashville",
		State: "TN",
	},
	{
		City: "Detroit",
		State: "MI",
	},
	{
		City: "Oklahoma City",
		State: "OK",
	},
	{
		City: "Portland",
		State: "OR",
	},
	{
		City: "Las Vegas",
		State: "NV",
	},
	{
		City: "Memphis",
		State: "TN",
	},
	{
		City: "Louisville",
		State: "KY",
	},
	{
		City: "Baltimore",
		State: "MD",
	},
	{
		City: "Milwaukee",
		State: "WI",
	},
	{
		City: "Albuquerque",
		State: "NM",
	},
	{
		City: "Tucson",
		State: "AZ",
	},
	{
		City: "Fresno",
		State: "CA",
	},
	{
		City: "Mesa",
		State: "AZ",
	},
	{
		City: "Sacramento",
		State: "CA",
	},
	{
		City: "Atlanta",
		State: "GA",
	},
	{
		City: "Kansas City",
		State: "MO",
	},
	{
		City: "Colorado Springs",
		State: "CO",
	},
	{
		City: "Omaha",
		State: "NE",
	},
	{
		City: "Raleigh",
		State: "NC",
	},
	{
		City: "Miami",
		State: "FL",
	},
	{
		City: "Long Beach",
		State: "CA",
	},
	{
		City: "VA Beach",
		State: "VA",
	},
	{
		City: "Oakland",
		State: "CA",
	},
	{
		City: "Minneapolis",
		State: "MN",
	},
	{
		City: "Tulsa",
		State: "OK",
	},
	{
		City: "Tampa",
		State: "FL",
	},
	{
		City: "Arlington",
		State: "TX",
	},
	{
		City: "New Orleans",
		State: "LA"
	},
];

// TODO: get list
const streetNames = [
	"Second",
];

const streetTypes = [
	"Rd"
];

const getRandomArrayIndex = ( array ) => getRandom( 0, array.length );

const getRandomFromArray = ( array ) => array[ getRandomArrayIndex( array ) ];

const getRandomLastName = () => getRandomFromArray( lastNames );

const getRandomEmail = () => getRandomFromArray( emails );

const getRandomEmailAddress = ( lastName, num ) => lastName.toLowerCase() + num + '@' + getRandomEmail();

const getRandomCompany = () => getRandomFromArray( company );

const getRandom = ( min, max ) => Math.floor( Math.random() * ( max - min ) + min );

const getRandomPhone = () => `(${getRandom( 100, 999 )}) ${getRandom( 100, 999 )}-${getRandom( 1000, 9999 )}`;

const getRandomCity = () => getRandomFromArray( cities );

const getRandomZipCode = () => getRandom( 10000, 99999 );

const getRandomStreetName = () => getRandomFromArray( streetNames );

const getRandomStreetType = () => getRandomFromArray( streetTypes );

const getRandomAddress = () => `${getRandom(100, 999)} ${getRandomStreetName()} ${getRandomStreetType()}`;



const getRandomTables = () => getRandomFromArray(tables);

const getRandomFields = () => getRandomFromArray(fields);

export { getRandom, getRandomArrayIndex, getRandomFromArray, getRandomEmail, getRandomEmailAddress, getRandomLastName, getRandomCompany, getRandomPhone,
    getRandomCity, getRandomZipCode, getRandomAddress,  getRandomTables, getRandomFields };
