import React from 'react';

import { styled } from '@mui/styles';
import { withStyles } from '@mui/styles';
import { spacing } from '@mui/system';
import { Box, Grid, Typography } from '@mui/material';
import MuiButton from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check'

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getPackageExportFile, resetPackage, mergePackageInformation } from 'admin/actions/packageActions';
import { ActionTypes as types } from 'admin/actions/actionTypes';
import { getAlertHide } from 'admin/actions/alertActions';
import { showConfirmWarning, hideError } from 'admin/actions/alertActions';
import { packageTheme } from './theme/packageTheme';


const Button = styled(MuiButton)(spacing);


class ExportHeader extends React.Component {

    constructor(props) {
        super(props);
        
    }
    componentDidMount() {
        this.onDiscardClick();
    }
    onDiscardClick = () => {
        
        this.props.dispatch(resetPackage());
    }

    onSaveClick = () => {
       
        if (this.props.objectWithOutRole.length === 0) {
            
            this.mergePackageInformation();
        }
        else
        {
            let action = { primaryAction: this.mergePackageInformation, SecondaryAction: this.closeConfirm, value: "" }
            showConfirmWarning(this.props.dispatch, "Default role will get assigned to objects with out dependent role during import.", action);
        }
        
    }
    mergePackageInformation = () => {
        
        this.props.dispatch(mergePackageInformation());
    }
    callSaveAction = () => {

        this.props.dispatch(getPackageExportFile(this.props.export));
    }
    closeConfirm = () => {

        hideError(this.props.dispatch);

    }
    render() {
       
        if (this.props.actionType === types.VGO_PACKAGE_MERGE) {
            this.callSaveAction();
        }
        return (
            <Grid container direction="row" justifyContent="space-between" spacing={0} xl={12}>
               
                <Grid item>
                    <Typography variant="h4">Export</Typography>
                </Grid>
                <Grid item >
                    <Grid container direction="row" justifyContent="space-between" spacing={1} >
                        <Grid item >
                            <Button
                                className={this.props.classes.customControlButton }
                                variant="defaultContained"
                                disableElevation
                                startIcon={""}
                                onClick={this.onDiscardClick}
                            >RESET</Button>
                        </Grid>
                        <Grid item >
                            <Button
                                className={this.props.classes.customControlButton}
                                color="primary"
                                variant="contained"
                                disableElevation
                                startIcon={<CheckIcon />}
                                onClick={this.onSaveClick}
                            >EXPORT</Button>
                        </Grid> </Grid>
                </Grid>
            </Grid> 
        );
    }

}

function mapStateToProps(state) {
    let props = {
        export: state.packages.package,
        packageInformation: state.packages.packageInformation,
        alert: state.alert,
        objectWithOutRole: state.packages.objectWithOutRole,
        actionType: state.packages.actionType,
        
    };
    return props;
};
 
export default withRouter(connect(mapStateToProps)(withStyles(packageTheme)(ExportHeader)));
