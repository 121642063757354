import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { styled } from '@mui/styles';
import { withStyles } from '@mui/styles';
import { spacing } from '@mui/system';
import { Grid, Checkbox, Switch, Typography, Radio, FormControlLabel, IconButton, Select, MenuItem, Button, FormControl, FormHelperText, Box, InputLabel } from '@mui/material';
import MuiTextField from '@mui/material/TextField';
import MuiCard from '@mui/material/Card';
import { getChangeDataView, validateOnBlur } from 'admin/actions/dataViewActions';
import MUIDataTable from 'mui-datatables';
import Delete from '@mui/icons-material/DeleteOutline';
import SquareIcon from '@mui/icons-material/Square';
import { theme } from 'common/theme.js';
import ColorPicker from 'common/colorPicker/index';
import HighLight from '../fieldDisplay/HighLight';
import { ChartType, DbType, HanaDbType } from 'client/components/Common/Enum';
import { chartTheme } from 'admin/components/section/dataViews/chart/theme/chartTheme';
import { sortName } from 'client/components/Common/Utility';

const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
const chartType = [
    { id: ChartType.None, value: 'None', },
    { id: ChartType.ComboChart, value: 'Combo Chart', },
    { id: ChartType.Pie, value: 'Pie', },
    { id: ChartType.Column, value: 'Column', },
    { id: ChartType.Bar, value: 'Bar', },
    { id: ChartType.Line, value: 'Line', },
    { id: ChartType.Area, value: 'Area', },
    { id: ChartType.Bubble, value: 'Bubble', },
    { id: ChartType.Doughnut, value: 'Doughnut', },
    { id: ChartType.Scatter, value: 'Scatter', },
    { id: ChartType.Funnel, value: 'Funnel', },
    { id: ChartType.Polar, value: 'Polar', },
    { id: ChartType.Radar, value: 'Radar', },
];
const comboChartType = [
    { id: ChartType.None, value: 'None', },
    { id: ChartType.Column, value: 'Column', },
    { id: ChartType.Line, value: 'Line', },
    { id: ChartType.Area, value: 'Area', },
];
const nameErrorMessage = 'Name is required'
const titleErrorMessage = 'Title is required'
const chartTypeErrorMessage = 'Chart type is required'
const xTextErrorMessage = 'Label is required'
const xColumnErrorMessage = 'Field is required'
const yTextErrorMessage = 'Label is required'
const yColumnErrorMessage = 'Field is required'
const yChartTypeErrorMessage = 'Chart type is required'


const defaultColumnOptions = {
    filter: true,
    sort: true,
    setCellHeaderProps: (cellValue, rowIndex, columnIndex) => {
        return {
            style: {
                paddingLeft: theme.spacing(.8),
                paddingBottom: theme.spacing(0),
                paddingRight: theme.spacing(0),
                borderBottom: theme.spacing(0),
            },
        };
    },
};
const defaultYAxesColumnOptions = {
    filter: true,
    sort: true,
    setCellHeaderProps: (cellValue, rowIndex, columnIndex) => {
        return {
            style: {
                paddingLeft: theme.spacing(0),
                paddingBottom: theme.spacing(0),
                paddingRight: theme.spacing(0),
                borderBottom: theme.spacing(0),
            },
        };
    },
};
let selectedRowIndex;
let selectedChartTypeIndex = -1;
let isHeaderChartDelete = false;
class Chart extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rowSelected: 0,
            showHighLight: false,
            highLightData: [],
            headerChartType: "comboChart",
            fromHeaderChart: false,
            chartYAxisOptions: {
                download: false,
                elevation: 0,
                jumpToPage: false,
                viewColumns: false,
                filter: false,
                search: false,
                sorting: true,
                print: false,
                pagination: false,
                selectableRows: 'none',
                responsive: 'scrollMaxHeight',
                tableBodyMaxHeight: '300px',
                tableBodyHeight: '300px',
            },
            names: {},
            titles: {},
            recordCounts: {},
            yTexts: {},
            legends: {}
        };
    }

    componentDidMount() {
        this.updateChart(this.props.dataView.chart);
        this.setState({ rowSelected: 0 });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.dataView.chart.length !== this.props.dataView.chart.length) {
            this.updateChart(this.props.dataView.chart);
            let rowIndex = prevState.rowSelected;
            if (this.props.dataView.chart.length < prevProps.dataView.chart.length) {
                if (this.props.dataView.chart.length === 1)
                    rowIndex = 0;
                else if (this.state.rowSelected === 0)
                    rowIndex = 0;
                else
                    rowIndex = this.state.rowSelected ;
            }
           
            this.setState({ fromHeaderChart: false, rowSelected: rowIndex});
        }
        
    }
    componentWillUnmount() {
        selectedRowIndex = 0;
        selectedChartTypeIndex = -1;
        isHeaderChartDelete = false;
    }

    updateChart(chart) {
        const newChart = [...chart];
        chart.forEach((row, i) => {
            newChart[i] = {
                ...row,
                nameError: false,
                nameHelperText: '',
                titleError: false,
                titleHelperText: '',
                chartTypeError: false,
                chartTypeHelperText: '',
                xTextError: false,
                xTextHelperText: '',
                xColumnError: false,
                xColumnHelperText: '',

            }
            this.changeDataView(dataView => dataView.chart[i] = newChart[i])
        })
        if (chart.length >= 1) {
            if (chart[chart.length - 1].chartId !== 0) {
                this.addNewChart(chart.length);
            }
        }
        chart.forEach((row, i) => {
            this.updateYAxis(row.chartYAxes, i)
        });
        this.setState({ names: {}, titles: {}, recordCounts: {}, yTexts: {}, legends: {}});
    }

    updateYAxis = (chartYAxes, rowIndex) => {
        const newChartYAxes = [...chartYAxes];
        chartYAxes.forEach((row, i) => {
            newChartYAxes[i] = {
                ...row,
                yTextError: false,
                yTextHelperText: '',
                yColumnError: false,
                yColumnHelperText: '',
                yChartTypeError: false,
                yChartTypeHelperText: '',
            }
            this.changeDataView(dataView => dataView.chart[rowIndex].chartYAxes[i] = newChartYAxes[i])
        })
        if (chartYAxes.length == 0) {
            this.addNewYAxis(0, rowIndex);
        }
        else if (chartYAxes.length >= 1) {
            if (chartYAxes[chartYAxes.length - 1].chartDetailId !== 0) {
                if (chartYAxes[chartYAxes.length - 1].chartType !== ChartType.Funnel) {
                    this.addNewYAxis(chartYAxes.length, rowIndex);
                }
            }
        }
    }

    changeDataView = (change) => {
        this.props.dispatch(getChangeDataView(change));
    }

    createMenuItem = (options) => {
        return (
            options.sort((a, b) => sortName(a.value, b.value)).map((option) => (
                <MenuItem key={option.id} value={option.id}>
                    {option.value}
                </MenuItem>
            ))
        );
    }

    getFields = () => {
        const fields = this.props.dataView.fieldDisplay.map(item => {
            return { id: item.fieldName, value: item.displayFieldName };
        });
        fields.unshift({ id: '', value: '' });
        return fields;
    }

    getNumericFields = () => {
        const fields = this.props.dataView.fieldDisplay.filter(f => (f.fieldType.toLowerCase() === DbType.Int || f.fieldType.toLowerCase() === DbType.Decimal || f.fieldType.toLowerCase() === DbType.Numeric || f.fieldType.toLowerCase() === DbType.SmallInt || f.fieldType.toLowerCase() === HanaDbType.Int)).map((item) => {
            return { id: item.fieldName, value: item.displayFieldName };
        });

        fields.unshift({ id: '', value: '' });
        return fields;
    }

    addNewChart = (count) => {
        const newChart = {
            chartId: 0,
            chartManager: { name: "", kpiDetails: [] },
            chartName: "",
            chartTitle: "",
            chartType: "",
            chartYAxes: [
                {
                    chartDetailId: 0,
                    chartId: 0,
                    chartType: "none",
                    label: "",
                    legendText: "",
                    radius: "",
                    seriesColor: "#2196F3",
                    seriesLegend: "",
                    toolTip: "",
                    yColumn: "",
                    yText: "",
                    yTextError: false,
                    yTextHelperText: '',
                    yColumnError: false,
                    yColumnHelperText: '',
                    yChartTypeError: false,
                    yChartTypeHelperText: '',
                },
            ],
            groupField: "",
            id: 0,
            isStacked: false,
            recordCountChart: 0,
            useDefaultYScale: false,
            xColumn: "",
            xText: "",
            nameError: false,
            nameHelperText: '',
            titleError: false,
            titleHelperText: '',
            chartTypeError: false,
            chartTypeHelperText: '',
            xTextError: false,
            xTextHelperText: '',
            xColumnError: false,
            xColumnHelperText: '',
        }
        this.changeDataView(dataView => dataView.chart[count] = newChart)
        this.setState({ rowSelected: count });
    }

    addNewYAxis = (count, rowSelected) => {
        const yAxis = this.props.dataView.chart[rowSelected].chartYAxes;
        const yAxisChartType = (yAxis.length > 0) ? yAxis[0].chartType : "none";
        const chartYAxes = {
            chartDetailId: 0,
            chartId: 0,
            /* chartType: (this.checkComboChart(rowSelected)) ? yAxis[0].chartType : "line",*/
            chartType: (this.checkComboChart(rowSelected)) ? yAxisChartType : "none",
            label: "",
            legendText: "",
            radius: "",
            seriesColor: "#2196F3",
            seriesLegend: "",
            toolTip: "",
            yColumn: "",
            yText: "",
            yTextError: false,
            yTextHelperText: '',
            yColumnError: false,
            yColumnHelperText: '',
            yChartTypeError: false,
            yChartTypeHelperText: '',
        };
        this.changeDataView(dataView => dataView.chart[rowSelected].chartYAxes[count] = chartYAxes)
    }

    checkComboChart = (rowSelected) => {
        const yAxis = this.props.dataView.chart[rowSelected].chartYAxes;
        let isNotComboChart = true;
        if (yAxis.length > 1) {
            isNotComboChart = yAxis.every(val => val.chartType === yAxis[0].chartType);
        }
        return isNotComboChart;
    }

    showHighLight = (fieldName, data) => {
        let highlightData = { fieldName: fieldName, data: data }
        this.setState({ showHighLight: true, highLightData: highlightData })
    }

    closeHighLight = (event, reason) => {

        if (reason && reason === "backdropClick")
            return;
        this.setState({ showHighLight: false, highLightData: [] })
    }

    updateHighLight = (data) => {
        this.changeDataView(dataView => dataView.chart[this.state.rowSelected].chartManager.kpiDetails =
            data)
        this.setState({ showHighLight: false, highLightData: [] })
    }

    deleteRowChart = (rowIndex) => {
        const chart = Object.assign([], this.props.dataView.chart);
        chart.splice(rowIndex, 1)

        if (rowIndex === this.props.dataView.chart.length - 1) {
            this.addNewChart(chart.length);
        } else {
            this.changeDataView(dataView => dataView.chart = chart);
        }
        isHeaderChartDelete = true;
        this.setState({ rowSelected: rowIndex });
    }

    deleteRowYAxis = (rowIndex) => {
        const yAxes = Object.assign([], this.props.dataView.chart[this.state.rowSelected].chartYAxes);
        yAxes.splice(rowIndex, 1)
        if (rowIndex === this.props.dataView.chart[this.state.rowSelected].chartYAxes.length - 1) {
            this.addNewYAxis(yAxes.length, this.state.rowSelected);
        } else {
            this.changeDataView(dataView => dataView.chart[this.state.rowSelected].chartYAxes = yAxes)
        }
        this.setState({ yTexts: {}, legends: {} });
    }

    nameTemplate = (value, tableMeta, updateValue) => {
        return (
            <TextField
                id={"name" + tableMeta.rowIndex}
                size="small"
                variant="standard"
                fullWidth={true}
                className={this.props.classes.name}
                inputProps={{ maxLength: 50 }}
                value={(this.state.names['name' + tableMeta.rowIndex] !== undefined) ? this.state.names['name' + tableMeta.rowIndex] : this.props.dataView.chart[tableMeta.rowIndex].chartManager.name}
                onChange={event => {
                    const names = this.state.names;
                    names['name' + tableMeta.rowIndex] = event.target.value;
                    this.setState({ names });
                }}
                onBlur={event => {
                    const rowIndex = tableMeta.rowIndex;
                    const chart = this.props.dataView.chart;
                    const length = chart.length;
                    const newChart = [...chart];
                    if (!event.target.value) {
                        newChart[rowIndex] = {
                            ...chart[rowIndex],
                            nameError: true,
                            nameHelperText: nameErrorMessage
                        }
                    } else {
                        newChart[rowIndex] = {
                            ...chart[rowIndex],
                            nameError: false,
                            nameHelperText: ' ',
                        }
                    }

                    this.changeDataView(dataView => dataView.chart[rowIndex] = newChart[rowIndex]);
                    this.changeDataView(dataView => dataView.chart[tableMeta.rowIndex].chartManager.name = event.target.value);
                    this.setState({ rowSelected: rowIndex });

                    if (rowIndex === length - 1) {
                        if (event.target.value || newChart[rowIndex].chartTitle) {
                            this.addNewChart(length);
                        }
                    }

                }}
                error={this.props.dataView.chart[tableMeta.rowIndex].nameError ? this.props.dataView.chart[tableMeta.rowIndex].nameError : false}
                helperText={this.props.dataView.chart[tableMeta.rowIndex].nameHelperText ? this.props.dataView.chart[tableMeta.rowIndex].nameHelperText : ' '}
            >
            </TextField>
        );
    }

    titleTemplate = (value, tableMeta, updateValue) => {
        return (
            <TextField
                id={"title" + tableMeta.rowIndex}
                size="small"
                variant="standard"
                fullWidth={true}
                className={this.props.classes.title}
                inputProps={{ maxLength: 50 }}
                value={(this.state.titles['title' + tableMeta.rowIndex] !== undefined) ? this.state.titles['title' + tableMeta.rowIndex] : this.props.dataView.chart[tableMeta.rowIndex].chartTitle}
                onChange={event => {
                    const titles = this.state.titles;
                    titles['title' + tableMeta.rowIndex] = event.target.value;
                    this.setState({ titles });
                }}
                error={this.props.dataView.chart[tableMeta.rowIndex].titleError ? this.props.dataView.chart[tableMeta.rowIndex].titleError : false}
                helperText={this.props.dataView.chart[tableMeta.rowIndex].titleHelperText ? this.props.dataView.chart[tableMeta.rowIndex].titleHelperText : ' '}
                onBlur={event => {

                    const rowIndex = tableMeta.rowIndex;
                    let chart = this.props.dataView.chart
                    const length = chart.length;
                    let newChart = [...chart];

                    if (!event.target.value) {
                        newChart[rowIndex] = {
                            ...chart[rowIndex],
                            titleError: true,
                            titleHelperText: titleErrorMessage
                        }
                    } else {
                        newChart[rowIndex] = {
                            ...chart[rowIndex],
                            titleError: false,
                            titleHelperText: ' '
                        }
                    }
                    this.changeDataView(dataView => dataView.chart[rowIndex] = newChart[rowIndex]);
                    this.changeDataView(dataView => dataView.chart[tableMeta.rowIndex].chartTitle = event.target.value)
                    this.setState({ rowSelected: rowIndex });

                    if (rowIndex === length - 1) {
                        if (newChart[rowIndex].chartManager.name || event.target.value) {
                            this.addNewChart(length);
                        }
                    }

                }}
            >
            </TextField>
        );
    }

    headerChartType = (index) => {
        let chartType;
        const yAxis = this.props.dataView.chart[index].chartYAxes;
        if (this.state.fromHeaderChart) {
            chartType = (selectedChartTypeIndex === index) ? this.state.headerChartType : this.props.dataView.chart[index].chartType;
        } else {
            const yAxisChartType = (yAxis.length > 0) ? yAxis[0].chartType : "none";
            chartType = (this.checkComboChart(index)) ? yAxisChartType : "comboChart"
        }
        return chartType;
    }

    chartTypeTemplate = (value, tableMeta, updateValue) => {
        const yAxis = this.props.dataView.chart[tableMeta.rowIndex].chartYAxes;
        let headerChartType;
        if (this.state.fromHeaderChart) {
            headerChartType = (selectedChartTypeIndex === tableMeta.rowIndex) ? this.state.headerChartType : this.props.dataView.chart[tableMeta.rowIndex].chartType;
        } else {
            if (selectedChartTypeIndex === tableMeta.rowIndex) {
                headerChartType = this.state.headerChartType;
            } else {
                const yAxisChartType = (yAxis.length > 0) ? yAxis[0].chartType : "none";
                headerChartType = (this.checkComboChart(tableMeta.rowIndex)) ? yAxisChartType : "comboChart"
            }
        }
        if (isHeaderChartDelete && this.props.dataView.chart.length - 1 === tableMeta.rowIndex) {
            //isHeaderChartDelete = false;
            headerChartType = "none";
        }

        this.changeDataView(dataView => dataView.chart[tableMeta.rowIndex].chartType = headerChartType)

        let newChartType = [];
        let index = -1;

        newChartType = chartType.map(item => {
            if (item.id === ChartType.None) {
                return { id: item.id, value: item.value };
            }
            //index = this.props.dataView.chart.findIndex((chart, i) => this.headerChartType(i) === item.id)

            //if (index === -1) {
            //    return { id: item.id, value: item.value };
            //} else {

            //    if (index !== tableMeta.rowIndex) {
            //        return null
            //    } else {

                    return { id: item.id, value: item.value };
                //}
            //}

        }).filter(Boolean);

      

        return (
            <FormControl error={this.props.dataView.chart[tableMeta.rowIndex].chartTypeError ? this.props.dataView.chart[tableMeta.rowIndex].chartTypeError : false}>
                <Select MenuProps={{ disableScrollLock: true }}
                    id={"chartType" + tableMeta.rowIndex}
                    value={headerChartType}                    size="small"
                    variant="standard"
                    fullWidth={true}
                    className={this.props.classes.chartType}
                    onOpen={e => {
                        this.setState({ rowSelected: tableMeta.rowIndex });
                    }}
                    onChange={event => {
                        let chartYAxis = this.props.dataView.chart[this.state.rowSelected].chartYAxes;
                        if (event.target.value !== ChartType.ComboChart) {
                            let newChartYAxis = [...chartYAxis];
                            if (event.target.value === ChartType.Funnel) {

                                const chartYAxes = {
                                    chartDetailId: 0,
                                    chartId: 0,
                                    chartType: ChartType.Funnel,
                                    label: "",
                                    legendText: "",
                                    radius: "",
                                    seriesColor: "#2196F3",
                                    seriesLegend: "",
                                    toolTip: "",
                                    yColumn: "",
                                    yText: "",
                                    yTextError: false,
                                    yTextHelperText: '',
                                    yColumnError: false,
                                    yColumnHelperText: '',
                                    yChartTypeError: false,
                                    yChartTypeHelperText: '',
                                };
                                newChartYAxis = [chartYAxes];
                            } else {

                                chartYAxis.forEach((row, i) => {
                                    newChartYAxis[i] = { ...chartYAxis[i], chartType: event.target.value }
                                });

                            }
                            this.changeDataView(dataView => dataView.chart[this.state.rowSelected].chartYAxes = newChartYAxis);

                        } else {
                            let newChartYAxis = [...chartYAxis];
                            chartYAxis.forEach((row, i) => {
                                newChartYAxis[i] = { ...chartYAxis[i], chartType: ChartType.None }
                            });
                            this.changeDataView(dataView => dataView.chart[this.state.rowSelected].chartYAxes =  newChartYAxis);
                        }
                        isHeaderChartDelete = false;
                        selectedChartTypeIndex = tableMeta.rowIndex;
                        this.setState({ headerChartType: event.target.value, fromHeaderChart: true });
                        this.changeDataView(dataView => dataView.chart[tableMeta.rowIndex].chartType = event.target.value)

                        if (event.target.value === ChartType.Scatter || event.target.value === ChartType.Bubble) {
                            this.changeDataView(dataView => dataView.chart[tableMeta.rowIndex].xColumn = "");
                            this.changeDataView(dataView => dataView.chart[tableMeta.rowIndex].groupField = "");
                        }
                       
                    }}
                    onBlur={event => {
                        const rowIndex = tableMeta.rowIndex;
                        let chart = this.props.dataView.chart
                        const length = chart.length;
                        let newChart = [...chart];

                        if (newChart[rowIndex].chartType === ChartType.None) {
                            newChart[rowIndex] = {
                                ...chart[rowIndex],
                                chartTypeError: true,
                                chartTypeHelperText: chartTypeErrorMessage
                            }
                        } else {
                            newChart[rowIndex] = {
                                ...chart[rowIndex],
                                chartTypeError: false,
                                chartTypeHelperText: ' '
                            }
                        }
                        this.changeDataView(dataView => dataView.chart[rowIndex] = newChart[rowIndex]);

                        if (rowIndex === length - 1) {
                            if (newChart[rowIndex].chartManager.name || newChart[rowIndex].chartTitle || newChart[rowIndex].chartType !== ChartType.None) {
                                this.addNewChart(length);
                            }
                        }
                    }}
                >
                    {this.createMenuItem(newChartType)}
                </Select>
                <FormHelperText>{this.props.dataView.chart[tableMeta.rowIndex].chartTypeHelperText ? this.props.dataView.chart[tableMeta.rowIndex].chartTypeHelperText : ' '}</FormHelperText>
            </FormControl>
        );
    }

    groupFieldTemplate = (value, tableMeta, updateValue) => {
        return (

            <FormControl error={false}>
                <Select MenuProps={{ disableScrollLock: true }}
                    id={"groupField" + tableMeta.rowIndex}
                    size="small"
                    variant="standard"
                    fullWidth={true}
                    className={this.props.classes.groupField}
                    value={this.props.dataView.chart[tableMeta.rowIndex].groupField}
                    onChange={event => {
                        this.changeDataView(dataView => dataView.chart[tableMeta.rowIndex].groupField = event.target.value)
                    }}
                >
                    {this.createMenuItem((this.props.dataView.chart[tableMeta.rowIndex].chartType === ChartType.Scatter || this.props.dataView.chart[tableMeta.rowIndex].chartType === ChartType.Bubble) ? this.getNumericFields() : this.getFields())}
                </Select>
                <FormHelperText>{' '}</FormHelperText>
            </FormControl>
        );
    }

    recordCountChartTemplate = (value, tableMeta, updateValue) => {
        return (
            <TextField
                id={"recordCountChart" + tableMeta.rowIndex}
                size="small"
                inputProps={{ type: 'number' }}
                variant="standard"
                fullWidth={true}
                className={this.props.classes.recordCount}
                value={(this.state.recordCounts['recordCount' + tableMeta.rowIndex] !== undefined) ? this.state.recordCounts['recordCount' + tableMeta.rowIndex] : this.props.dataView.chart[tableMeta.rowIndex].recordCountChart}
                onChange={event => {
                    const recordCounts = this.state.recordCounts;
                    recordCounts['recordCount' + tableMeta.rowIndex] = event.target.value;
                    this.setState({ recordCounts });
                }}
                onBlur={event => {
                    this.changeDataView(dataView => dataView.chart[tableMeta.rowIndex].recordCountChart =
                        event.target.value)
                }}
                error={false}
                helperText={' '}
            >
            </TextField>
        );
    }

    stackedTemplate = (value, tableMeta, updateValue) => {
        return (
            <Checkbox
                color="primary"
                checked={this.props.dataView.chart[tableMeta.rowIndex].isStacked}
                className={this.props.classes.stacked}
                onChange={event => {
                    this.changeDataView(dataView => dataView.chart[tableMeta.rowIndex].isStacked = event.target.checked)
                }}
            />
        );
    }

    deleteRowChartTemplate = (value, tableMeta, updateValue) => {
        return (
            <IconButton id={"delete" + tableMeta.rowIndex} type="button" className={this.props.classes.stacked} onClick={() => this.deleteRowChart(tableMeta.rowIndex)} >
                <Delete /></IconButton>
        );
    }

    chartTypeYAxisTemplate = (value, tableMeta, updateValue) => {
        const yAxis = this.props.dataView.chart[this.state.rowSelected].chartYAxes;
        let headerChartType;
        if (this.state.fromHeaderChart) {
            headerChartType = (selectedChartTypeIndex === this.state.rowSelected) ? this.state.headerChartType : this.props.dataView.chart[this.state.rowSelected].chartType;
        } else {
            if (selectedChartTypeIndex === this.state.rowSelected) {
                headerChartType = this.state.headerChartType;
            } else {
                headerChartType = (this.checkComboChart(this.state.rowSelected)) ? yAxis[0].chartType : "comboChart"
            }
        }
        return (
            <FormControl error={this.props.dataView.chart[this.state.rowSelected].chartYAxes[tableMeta.rowIndex].yChartTypeError ? this.props.dataView.chart[this.state.rowSelected].chartYAxes[tableMeta.rowIndex].yChartTypeError : false}>
                <Select MenuProps={{ disableScrollLock: true }}
                    disabled={(headerChartType === "comboChart") ? false : true}
                    id={"chartTypeYAxis" + tableMeta.rowIndex}
                    size="small"
                    fullWidth={true}
                    variant="standard"
                    style={{ width: 178 }}
                    className={this.props.classes.yChartType}
                    value={this.props.dataView.chart[this.state.rowSelected].chartYAxes[tableMeta.rowIndex].chartType}
                    onChange={event => {
                        const chartYAxes = this.props.dataView.chart[this.state.rowSelected].chartYAxes;
                        const length = chartYAxes.length;
                        this.changeDataView(dataView => dataView.chart[this.state.rowSelected].chartYAxes[tableMeta.rowIndex].chartType = event.target.value);

                        if (tableMeta.rowIndex === length - 1) {
                            if (this.props.dataView.chart[this.state.rowSelected].chartType === ChartType.ComboChart) {
                                this.addNewYAxis(length, this.state.rowSelected);
                            }
                        }
                        this.setState({ fromHeaderChart: false });
                    }}
                    onBlur={event => {
                        const rowIndex = tableMeta.rowIndex;
                        const chartYAxes = this.props.dataView.chart[this.state.rowSelected].chartYAxes;
                        const length = chartYAxes.length;
                        const newChartYAxes = [...chartYAxes];
                        if (newChartYAxes[rowIndex].chartType === ChartType.None) {
                            newChartYAxes[rowIndex] = {
                                ...chartYAxes[rowIndex],
                                yChartTypeError: true,
                                yChartTypeHelperText: yChartTypeErrorMessage
                            }
                        } else {
                            newChartYAxes[rowIndex] = {
                                ...chartYAxes[rowIndex],
                                yChartTypeError: false,
                                yChartTypeHelperText: ' ',
                            }
                        }
                        this.changeDataView(dataView => dataView.chart[this.state.rowSelected].chartYAxes[rowIndex] = newChartYAxes[rowIndex]);
                        

                    }}
                >
                    {(headerChartType === "comboChart")
                        ? this.createMenuItem(comboChartType)
                        : this.createMenuItem(chartType)}
                </Select>
                <FormHelperText>{this.props.dataView.chart[this.state.rowSelected].chartYAxes[tableMeta.rowIndex].yChartTypeHelperText ? this.props.dataView.chart[this.state.rowSelected].chartYAxes[tableMeta.rowIndex].yChartTypeHelperText : ' '}</FormHelperText>
            </FormControl>
        );
    }


    yTextTemplate = (value, tableMeta, updateValue) => {
        return (
            <TextField
                id={"yText" + tableMeta.rowIndex}
                size="small"
                fullWidth={true}
                variant="standard"
                className={this.props.classes.yText}
                inputProps={{ maxLength: 50 }}
                value={(this.state.yTexts['yText' + this.state.rowSelected + '_' + tableMeta.rowIndex] !== undefined) ? this.state.yTexts['yText' + this.state.rowSelected + '_' + tableMeta.rowIndex] : this.props.dataView.chart[this.state.rowSelected].chartYAxes[tableMeta.rowIndex].yText}
                onChange={event => {
                    const yTexts = this.state.yTexts;
                    yTexts['yText' + this.state.rowSelected + '_' + tableMeta.rowIndex] = event.target.value;
                    this.setState({ yTexts });
                }}
                onBlur={event => {
                    const rowIndex = tableMeta.rowIndex;
                    const chartYAxes = this.props.dataView.chart[this.state.rowSelected].chartYAxes;
                    const length = chartYAxes.length;
                    const newChartYAxes = [...chartYAxes];
                    if (!event.target.value) {
                        newChartYAxes[rowIndex] = {
                            ...chartYAxes[rowIndex],
                            yTextError: true,
                            yTextHelperText: yTextErrorMessage
                        }
                    } else {
                        newChartYAxes[rowIndex] = {
                            ...chartYAxes[rowIndex],
                            yTextError: false,
                            yTextHelperText: ' ',
                        }
                    }
                    this.changeDataView(dataView => dataView.chart[this.state.rowSelected].chartYAxes[rowIndex] = newChartYAxes[rowIndex]);
                    this.changeDataView(dataView => dataView.chart[this.state.rowSelected].chartYAxes[tableMeta.rowIndex].yText = event.target.value);
                    if (rowIndex === length - 1) {
                        if (this.props.dataView.chart[this.state.rowSelected].chartYAxes[tableMeta.rowIndex].chartType !== ChartType.Funnel) {
                            if (event.target.value || newChartYAxes[rowIndex].yColumn) {
                                this.addNewYAxis(length, this.state.rowSelected);
                            }
                        }
                    }

                }}
                error={this.props.dataView.chart[this.state.rowSelected].chartYAxes[tableMeta.rowIndex].yTextError ? this.props.dataView.chart[this.state.rowSelected].chartYAxes[tableMeta.rowIndex].yTextError : false}
                helperText={this.props.dataView.chart[this.state.rowSelected].chartYAxes[tableMeta.rowIndex].yTextHelperText ? this.props.dataView.chart[this.state.rowSelected].chartYAxes[tableMeta.rowIndex].yTextHelperText : ' '}
            >
            </TextField>
        );
    }

    yColumnTemplate = (value, tableMeta, updateValue) => {
        let index = -1;
        const fields = this.props.dataView.fieldDisplay.filter(f => (f.fieldType.toLowerCase() === DbType.Int || f.fieldType.toLowerCase() === DbType.Decimal || f.fieldType.toLowerCase() === DbType.Numeric || f.fieldType.toLowerCase() === DbType.SmallInt || f.fieldType.toLowerCase() === HanaDbType.Int)).map((item) => {
            index = this.props.dataView.chart[this.state.rowSelected].chartYAxes.findIndex(p => p.yColumn === item.fieldName)
            if (index === -1) {
                return { id: item.fieldName, value: item.displayFieldName };
            } else {

                if (index !== tableMeta.rowIndex) {
                    return null
                } else {

                    return { id: item.fieldName, value: item.displayFieldName };
                }
            }
        }).filter(Boolean);

        fields.unshift({ id: '', value: '' });

        return (
            <FormControl error={this.props.dataView.chart[this.state.rowSelected].chartYAxes[tableMeta.rowIndex].yColumnError ? this.props.dataView.chart[this.state.rowSelected].chartYAxes[tableMeta.rowIndex].yColumnError : false}>
                <Select MenuProps={{ disableScrollLock: true }}
                    id={"yColumn" + tableMeta.rowIndex}
                    size="small"
                    variant="standard"
                    fullWidth={true}
                    className={this.props.classes.yColumn}
                    value={this.props.dataView.chart[this.state.rowSelected].chartYAxes[tableMeta.rowIndex].yColumn}
                    onChange={event => {
                        this.changeDataView(
                            dataView => dataView.chart[this.state.rowSelected].chartYAxes[tableMeta.rowIndex].yColumn =
                                event.target.value)
                    }}
                    onBlur={event => {
                        const rowIndex = tableMeta.rowIndex;
                        const chartYAxes = this.props.dataView.chart[this.state.rowSelected].chartYAxes;
                        const length = chartYAxes.length;
                        const newChartYAxes = [...chartYAxes];
                        if (!newChartYAxes[rowIndex].yColumn) {
                            newChartYAxes[rowIndex] = {
                                ...chartYAxes[rowIndex],
                                yColumnError: true,
                                yColumnHelperText: yColumnErrorMessage
                            }
                        } else {
                            newChartYAxes[rowIndex] = {
                                ...chartYAxes[rowIndex],
                                yColumnError: false,
                                yColumnHelperText: ' ',
                            }
                        }
                        this.changeDataView(dataView => dataView.chart[this.state.rowSelected].chartYAxes[rowIndex] = newChartYAxes[rowIndex]);

                        if (rowIndex === length - 1) {
                            if (this.props.dataView.chart[this.state.rowSelected].chartYAxes[tableMeta.rowIndex].chartType !== ChartType.Funnel) {
                                if (newChartYAxes[rowIndex].yText || newChartYAxes[rowIndex].yColumn) {
                                    this.addNewYAxis(length, this.state.rowSelected);
                                }
                            }
                        }

                    }}
                >
                    {this.createMenuItem(fields)}
                </Select>
                <FormHelperText>{this.props.dataView.chart[this.state.rowSelected].chartYAxes[tableMeta.rowIndex].yColumnHelperText ? this.props.dataView.chart[this.state.rowSelected].chartYAxes[tableMeta.rowIndex].yColumnHelperText : ' '}</FormHelperText>
            </FormControl>
        );
    }

    colorTemplate = (value, tableMeta, updateValue) => {
        const { classes } = this.props;
        const seriesColor = this.props.dataView.chart[this.state.rowSelected].chartYAxes[tableMeta.rowIndex].seriesColor;
        const backgroundColor = seriesColor ? seriesColor : "#2196F3";
        return (
            <ColorPicker name='icon'
                style={{
                    backgroundColor: backgroundColor,
                }}
                value={backgroundColor}
                onChange={color => {
                    this.changeDataView(
                        dataView => dataView.chart[this.state.rowSelected].chartYAxes[tableMeta.rowIndex].seriesColor =
                            color)
                }}
                variant="filled"
                className={classes.colorStyle}
            />
        );
    }

    legendTemplate = (value, tableMeta, updateValue) => {
        return (
            <TextField
                id={"legend" + tableMeta.rowIndex}
                size="small"
                fullWidth={true}
                variant="standard"
                className={this.props.classes.legend}
                inputProps={{ maxLength: 50 }}
                value={(this.state.legends['legend' + this.state.rowSelected + '_' + tableMeta.rowIndex] !== undefined) ? this.state.legends['legend' + this.state.rowSelected + '_' + tableMeta.rowIndex] : this.props.dataView.chart[this.state.rowSelected].chartYAxes[tableMeta.rowIndex].legendText}
                onChange={event => {
                    const legends = this.state.legends;
                    legends['legend' + this.state.rowSelected + '_' + tableMeta.rowIndex] = event.target.value;
                    this.setState({ legends });
                }}
                onBlur={event => {
                    this.changeDataView(
                        dataView => dataView.chart[this.state.rowSelected].chartYAxes[tableMeta.rowIndex].legendText =
                            event.target.value)
                }}
                error={false}
                helperText={' '}
            >
            </TextField>
        );
    }

    labelTemplate = (value, tableMeta, updateValue) => {
        return (
            <FormControl error={false}>
                <Select MenuProps={{ disableScrollLock: true }}
                    id={"label" + tableMeta.rowIndex}
                    size="small"
                    fullWidth={true}
                    variant="standard"
                    className={this.props.classes.dataLabel}
                    value={this.props.dataView.chart[this.state.rowSelected].chartYAxes[tableMeta.rowIndex].label}
                    onChange={event => {
                        this.changeDataView(
                            dataView => dataView.chart[this.state.rowSelected].chartYAxes[tableMeta.rowIndex].label =
                                event.target.value)
                    }}
                >
                    {this.createMenuItem(this.getFields())}
                </Select>
                <FormHelperText>{' '}</FormHelperText>
            </FormControl>
        );
    }

    toolTipTemplate = (value, tableMeta, updateValue) => {
        return (
            <FormControl error={false}>
                <Select MenuProps={{ disableScrollLock: true }}
                    id={"toolTip" + tableMeta.rowIndex}
                    size="small"
                    fullWidth={true}
                    variant="standard"
                    className={this.props.classes.toolTip}
                    value={this.props.dataView.chart[this.state.rowSelected].chartYAxes[tableMeta.rowIndex].toolTip}
                    onChange={event => {
                        this.changeDataView(
                            dataView => dataView.chart[this.state.rowSelected].chartYAxes[tableMeta.rowIndex].toolTip =
                                event.target.value)
                    }}
                >
                    {this.createMenuItem(this.getFields())}
                </Select>
                <FormHelperText>{' '}</FormHelperText>
            </FormControl>
        );
    }

    radiusTemplate = (value, tableMeta, updateValue) => {
        return (
            <FormControl error={false}>
                <Select MenuProps={{ disableScrollLock: true }}
                    id={"radius" + tableMeta.rowIndex}
                    variant="standard"
                    disabled={(this.props.dataView.chart[this.state.rowSelected].chartYAxes[tableMeta.rowIndex]
                        .chartType ===
                        "bubble")
                        ? false
                        : true}
                    size="small"
                    fullWidth={true}
                    className={this.props.classes.radius}
                    value={(this.props.dataView.chart[this.state.rowSelected].chartYAxes[tableMeta.rowIndex].chartType ===
                        "bubble")
                        ? this.props.dataView.chart[this.state.rowSelected].chartYAxes[tableMeta.rowIndex].radius
                        : ''}
                    onChange={event => {
                        this.changeDataView(
                            dataView => dataView.chart[this.state.rowSelected].chartYAxes[tableMeta.rowIndex].radius =
                                event.target.value)
                    }}
                >
                    {this.createMenuItem(this.getNumericFields())}
                </Select>
                <FormHelperText>{' '}</FormHelperText>
            </FormControl>
        );
    }

    deleteRowYAxisTemplate = (value, tableMeta, updateValue) => {
        return (
            <IconButton className={this.props.classes.deleteStyle} id={"deleteYAxis" + tableMeta.rowIndex} type="button" onClick={() => this.deleteRowYAxis(tableMeta.rowIndex)} ><Delete /></IconButton>
        );
    }



    render() {
        const selectedChartCount = this.state.rowSelected;
        const { classes } = this.props;
        const columns = [
            {
                name: "name",
                label: "Name *",
                options: {
                    ...defaultColumnOptions,
                    customBodyRender: this.nameTemplate,
                    setCellProps: () => ({
                        style: {
                            paddingLeft: theme.spacing(.8),
                            borderBottom: theme.spacing(0),
                            width: 267,
                        }
                    })
                },
            },
            {
                name: "chartTitle",
                label: "Title *",
                options: {
                    ...defaultColumnOptions,
                    customBodyRender: this.titleTemplate,
                    setCellProps: () => ({
                        style: {
                            paddingLeft: theme.spacing(.8),
                            borderBottom: theme.spacing(0),
                            width: 265,
                        }
                    })
                },
            },
            {
                name: "chartType",
                label: "Chart Type *",
                options: {
                    ...defaultColumnOptions,
                    customBodyRender: this.chartTypeTemplate,
                    setCellProps: () => ({
                        style: {
                            paddingLeft: theme.spacing(.8),
                            borderBottom: theme.spacing(0),
                            width: 271
                        }
                    })
                },
            },
            {
                name: "groupField",
                label: "Group-by Field",
                options: {
                    ...defaultColumnOptions,
                    customBodyRender: this.groupFieldTemplate,
                    setCellProps: () => ({
                        style: {
                            paddingLeft: theme.spacing(.8),
                            borderBottom: theme.spacing(0),
                            width: 350
                        }
                    })
                },
            },
            {
                name: "recordCountChart",
                label: "Max Records",
                options: {
                    ...defaultColumnOptions,
                    customBodyRender: this.recordCountChartTemplate,
                    setCellProps: () => ({
                        style: {
                            paddingLeft: theme.spacing(.8),
                            borderBottom: theme.spacing(0),
                            width: 152
                        }
                    })
                },
            },
            {
                name: "isStacked",
                label: "Stacked",
                options: {
                    ...defaultColumnOptions,
                    customBodyRender: this.stackedTemplate,
                    setCellProps: () => ({
                        style: {
                            paddingLeft: theme.spacing(.8),
                            paddingBottom: theme.spacing(3),
                            borderBottom: theme.spacing(0),
                            width: 150
                        }
                    })
                },
            },
            {
                name: "",
                label: "",
                options: {
                    ...defaultColumnOptions,
                    customBodyRender: this.deleteRowChartTemplate,
                    setCellProps: () => ({
                        style: {
                            paddingBottom: theme.spacing(3),
                            borderBottom: theme.spacing(0),
                        }
                    })
                },
            },
        ];
        const chartOptions = {
            download: false,
            elevation: 0,
            jumpToPage: false,
            viewColumns: false,
            filter: false,
            search: false,
            sorting: true,
            print: false,
            pagination: false,
            selectableRows: 'none',
            responsive: 'scrollMaxHeight',
            tableBodyMaxHeight: '300px',
            tableBodyHeight: '300px',
            onCellClick: (colData, cellMeta) => {
                const dataIndex = cellMeta.dataIndex;
                selectedRowIndex = dataIndex;
                const columnIndex = columns.findIndex(c => c.name === "");
                if (columnIndex !== cellMeta.colIndex) {
                    this.setState({ rowSelected: dataIndex });
                }
            },

            setRowProps: (row, dataIndex, rowIndex) => {
                if (selectedRowIndex === undefined && dataIndex === 0) {
                    return {
                        className: this.props.classes.selectedRow
                    };
                }
                else if (selectedRowIndex === dataIndex) {
                    return {
                        className: this.props.classes.selectedRow
                    };
                }
            },
        };
        const yAxesColumns =
            [
                {
                    name: "chartType",
                    label: "Chart Type *",
                    options: {
                        ...defaultYAxesColumnOptions,
                        customBodyRender: this.chartTypeYAxisTemplate,
                        setCellProps: () => ({
                            style: {
                                paddingLeft: theme.spacing(0),
                                paddingTop: theme.spacing(5.4),
                                paddingBottom: theme.spacing(5.4),
                                borderBottom: theme.spacing(0),
                                width: 192
                            }
                        })
                    },
                },
                {
                    name: "yText",
                    label: "Label *",
                    options: {
                        ...defaultYAxesColumnOptions,
                        customBodyRender: this.yTextTemplate,
                        setCellProps: () => ({
                            style: {
                                paddingLeft: theme.spacing(0),
                                paddingTop: theme.spacing(5.4),
                                paddingBottom: theme.spacing(5.4),
                                borderBottom: theme.spacing(0),
                                width: 200
                            }
                        })
                    },
                },
                {
                    name: "yColumn",
                    label: "Field *",
                    options: {
                        ...defaultYAxesColumnOptions,
                        customBodyRender: this.yColumnTemplate,
                        setCellProps: () => ({
                            style: {
                                paddingLeft: theme.spacing(0),
                                paddingTop: theme.spacing(1),
                                borderBottom: theme.spacing(0),
                                width: 232
                            }
                        })
                    },
                },
                {
                    name: "seriesColor",
                    label: "Color",
                    options: {
                        ...defaultYAxesColumnOptions,
                        customBodyRender: this.colorTemplate,
                        setCellProps: () => ({
                            style: {
                                paddingLeft: theme.spacing(0),
                                paddingTop: theme.spacing(1),
                                paddingBottom: theme.spacing(4),
                                borderBottom: theme.spacing(0),
                                width: 72
                            }
                        })
                    },
                },
                {
                    name: "legendText",
                    label: "Legend",
                    options: {
                        ...defaultYAxesColumnOptions,
                        customBodyRender: this.legendTemplate,
                        setCellProps: () => ({
                            style: {
                                paddingLeft: theme.spacing(0),
                                paddingTop: theme.spacing(1),
                                borderBottom: theme.spacing(0),
                                width: 200
                            }
                        })
                    },
                },
                {
                    name: "label",
                    label: "Data Label",
                    options: {
                        ...defaultYAxesColumnOptions,
                        customBodyRender: this.labelTemplate,
                        setCellProps: () => ({
                            style: {
                                paddingLeft: theme.spacing(0),
                                paddingTop: theme.spacing(1),
                                borderBottom: theme.spacing(0),
                                width: 232
                            }
                        })
                    },
                },
                {
                    name: "toolTip",
                    label: "Data ToolTip",
                    options: {
                        ...defaultYAxesColumnOptions,
                        customBodyRender: this.toolTipTemplate,
                        setCellProps: () => ({
                            style: {
                                paddingLeft: theme.spacing(0),
                                paddingTop: theme.spacing(1),
                                borderBottom: theme.spacing(0),
                                width: 208
                            }
                        })
                    },
                },
                {
                    name: "radius",
                    label: "Radius",
                    options: {
                        ...defaultYAxesColumnOptions,
                        customBodyRender: this.radiusTemplate,
                        setCellProps: () => ({
                            style: {
                                paddingLeft: theme.spacing(0),
                                paddingTop: theme.spacing(1),
                                borderBottom: theme.spacing(0),
                            }
                        })
                    },
                },
                {
                    name: "",
                    label: "",
                    options: {
                        ...defaultYAxesColumnOptions,
                        customBodyRender: this.deleteRowYAxisTemplate,
                        setCellProps: () => ({
                            style: {
                                paddingLeft: theme.spacing(0),
                                paddingTop: theme.spacing(1),
                                paddingBottom: theme.spacing(4),
                                borderBottom: theme.spacing(0),
                            }
                        })
                    },
                },
            ];

        if (!this.props.dataView.chart[this.state.rowSelected]) {
            return null;
        }
        return (

            <Grid container direction="row" justify="space-between" >
                {this.state.showHighLight
                    ? <HighLight handleClose={this.closeHighLight}
                        handleUpdate={this.updateHighLight}
                        highLight={this.state.highLightData}
                        visible={this.state.showHighLight}
                        isFromChart={true} />
                    : ""}
                <Grid item xs={12} >
                    <Card variant="outlined" pt={2.4} pb={3.1} pl={3} className={classes.card}>
                        <Grid item xs={12}>


                            <MUIDataTable container
                                title={''}
                                data={this.props.dataView.chart}
                                columns={columns}
                                options={chartOptions}
                            />
                        </Grid>
                    </Card>

                    <Card variant="outlined" p={3} className={classes.bottomCard}>
                        <Grid item xs={12}>

                            <Grid item className={classes.xAxis}><Typography>X-Axis</Typography></Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={4} direction="row">
                                    <Grid item  >
                                        <TextField
                                            label="Label *"
                                            size="small"
                                            style={{ width: 200 }}
                                            inputProps={{ maxLength: 50 }}
                                            value={this.props.dataView.chart[this.state.rowSelected].xText}
                                            onChange={event => this.changeDataView(
                                                dataView => dataView.chart[this.state.rowSelected].xText =
                                                    event.target.value)}
                                            onBlur={event => {
                                                const rowIndex = this.state.rowSelected;
                                                const chart = this.props.dataView.chart;
                                                const length = chart.length;
                                                const newChart = [...chart];
                                                if (!newChart[rowIndex].xText) {
                                                    newChart[rowIndex] = {
                                                        ...chart[rowIndex],
                                                        xTextError: true,
                                                        xTextHelperText: xTextErrorMessage
                                                    }
                                                } else {
                                                    newChart[rowIndex] = {
                                                        ...chart[rowIndex],
                                                        xTextError: false,
                                                        xTextHelperText: ' ',
                                                    }
                                                }
                                                this.changeDataView(dataView => dataView.chart[rowIndex] = newChart[rowIndex]);
                                            }}
                                            error={this.props.dataView.chart[this.state.rowSelected].xTextError ? this.props.dataView.chart[this.state.rowSelected].xTextError : false}
                                            helperText={this.props.dataView.chart[this.state.rowSelected].xTextHelperText ? this.props.dataView.chart[this.state.rowSelected].xTextHelperText : ' '}

                                        >
                                        </TextField>
                                    </Grid>
                                    <Grid item >
                                        <FormControl className={classes.select} error={this.props.dataView.chart[this.state.rowSelected].xColumnError ? this.props.dataView.chart[this.state.rowSelected].xColumnError : false}>
                                            <InputLabel>Field *</InputLabel>
                                            <Select MenuProps={{ disableScrollLock: true }}
                                                id={"xFields"}
                                                label="Field"
                                                value={this.props.dataView.chart[this.state.rowSelected].xColumn}
                                                size="small"
                                                fullWidth={true}
                                                style={{ width: 260 }}
                                                onChange={event => this.changeDataView(
                                                    dataView => dataView.chart[this.state.rowSelected].xColumn = event.target.value)}
                                                onBlur={event => {
                                                    const rowIndex = this.state.rowSelected;
                                                    const chart = this.props.dataView.chart;
                                                    const length = chart.length;
                                                    const newChart = [...chart];
                                                    if (!newChart[rowIndex].xColumn) {
                                                        newChart[rowIndex] = {
                                                            ...chart[rowIndex],
                                                            xColumnError: true,
                                                            xColumnHelperText: xColumnErrorMessage
                                                        }
                                                    } else {
                                                        newChart[rowIndex] = {
                                                            ...chart[rowIndex],
                                                            xColumnError: false,
                                                            xColumnHelperText: ' ',
                                                        }
                                                    }
                                                    this.changeDataView(dataView => dataView.chart[rowIndex] = newChart[rowIndex]);
                                                }}
                                            >
                                                {this.createMenuItem((this.props.dataView.chart[this.state.rowSelected].chartType === ChartType.Scatter || this.props.dataView.chart[this.state.rowSelected].chartType === ChartType.Bubble) ? this.getNumericFields() : this.getFields())}
                                            </Select>
                                            <FormHelperText>{this.props.dataView.chart[this.state.rowSelected].xColumnHelperText ? this.props.dataView.chart[this.state.rowSelected].xColumnHelperText : ' '}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} direction="row" justify="space-between">
                            <Grid item xs={1.83} className={classes.yAxis}><Typography>Y-Axis</Typography></Grid>
                            <Grid item xs={1.75} className={classes.displayAxis}>
                                <FormControlLabel control={<Checkbox
                                    color="primary"
                                    checked={this.props.dataView.chart[this.state.rowSelected].useDefaultYScale}
                                    onChange={event => {
                                        this.changeDataView(dataView => dataView.chart[this.state.rowSelected].useDefaultYScale = event.target.checked)
                                    }}
                                />} label={<Typography variant="body2">Display on one axis</Typography>} />

                            </Grid>
                            <Grid item xs={6}>
                                <Box pt={1.5}>
                                    <IconButton id={"chartHighlight"} type="button" onClick={() => {
                                        const item = this.props.dataView.chart[this.state.rowSelected];
                                        this.showHighLight(item.xColumn, item.chartManager.kpiDetails)
                                    }
                                    } >
                                        <SquareIcon color="primary" fontSize="large" /></IconButton>
                                    <Typography className={classes.label} variant="body2">Color Conditions</Typography>
                                </Box>
                                
                            </Grid>

                            <Grid item xs={12}>
                                <MUIDataTable container
                                    title={''}
                                    data={this.props.dataView.chart[selectedChartCount].chartYAxes}
                                    columns={yAxesColumns}
                                    options={this.state.chartYAxisOptions}
                                />
                            </Grid>
                        </Grid>
                    </Card>

                </Grid>
            </Grid>
        );

    }
}

function mapStateToProps(state) {
    return {
        dataViews: state.dataViews.dataViews,
        dataView: state.dataViews.dataView,
        isLoading: state.loader.isLoading,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage,
        errors: state.dataViews.errors ? state.dataViews.errors : {},
    };
};

export default withRouter(connect(mapStateToProps)(withStyles(chartTheme)(Chart)));
