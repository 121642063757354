import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { styled } from '@mui/styles';
import { Grid, Typography,   Select, MenuItem, Tabs, Tab, Box, AppBar, Checkbox,  IconButton } from '@mui/material';
import Autocomplete from '@mui/lab/Autocomplete';
import MuiTextField from '@mui/material/TextField';
import { spacing } from '@mui/system';
import PropTypes from 'prop-types';
import MuiCard from '@mui/material/Card';
import MUIDataTable from 'mui-datatables';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import TableFieldName from 'admin/components/section/form/setup/tableFieldName';

import { getChangeFormSource,  getChangeKeyField,  newTableFields, deleteFormPrimaryTableField} from 'admin/actions/formSetupActions';
import { ActionTypes as actionTypes } from 'admin/actions/actionTypes';
import { setupTheme } from 'admin/components/section/form/setup/theme/setupTheme';
import { FormType, ControlType } from 'client/components/Common/Enum';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const Card = styled(MuiCard)(spacing);

const DataTypeObject = {
    date: "Date",
    numbers: "Number",
    text: "Text",
};
const FormatTypeObject = {
    integer: "Integer",
    decimal: "Decimal",
    date: "Date",
    datetime: "Datetime",
    dateTime: "DateTime",
    text: "Text",
    textMaximum:"Text (Max)",
}
const FormControlTypeObject = {
    datePicker: "DatePicker",
    numericTextBox: "NumericTextBox",
    textBox:"TextBox",
}
const DataType = [
    { name: "Date", value: "date" },
    { name: "Number", value: "numbers" },
    { name: "Text", value: "text" },
];
const FormatType = [
    { name: "Integer", value: "integer", type: "numbers" },
    { name: "Decimal", value: "decimal", type: "numbers" },
    { name: "Date", value: "date", type: "date" },
    { name: "DateTime", value: "dateTime", type: "date" },
    { name: "Text", value: "text", type: "text" },
    { name: "Text (Max)", value: "textMaximum", type: "text" },
];
const FormControlType = [
    { name: "DatePicker", value: ControlType.DatePicker, type: "date" },
    { name: "NumericTextBox", value: ControlType.NumericTextBox, type: "numbers" },
    { name: "TextBox", value: ControlType.TextBox, type: "text" },


];
const TextField = styled(MuiTextField)(spacing);
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};



function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}
class FormSourceTable extends React.Component  {
    constructor(props) {
        super(props);
        this.state = {
            activeTabIndex: 0,
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        const tabChanged = nextState.activeTabIndex !== this.state.activeTabIndex;
        const isEditable = (nextProps.formSetup.createNewTable && !nextProps.isUpdate)
        if (!isEditable && !tabChanged && (nextProps.formActionType === actionTypes.VGO_CHANGE_FORM || nextProps.formActionType === actionTypes.VGO_VALIDATE_FORM_SETUP_ONBLUR  )) {
            return false;
        }
        return true; 
    }
    changeForm = (change) => {
        this.props.dispatch(getChangeFormSource(change));
    }
    ChangeKeyField = (change, tableType, checked, fields) => {
        this.props.dispatch(getChangeKeyField(change, tableType, checked, fields));
    }
    
    tableFieldSelectUpdateOnBlur = (dataIndex, value, type) => {
        if (type === "primary") {
            if (this.props.formSetup.primaryTableDataFields.length - 1 === dataIndex && !(!value)) {
                this.changeForm(form => {
                    form.primaryTableDataFields[dataIndex].fieldName = value
                    form.primaryTableDataFields = newTableFields(form.primaryTableDataFields)
                    return form;
                })
            }
            else {
                this.changeForm(form => form.primaryTableDataFields[dataIndex].fieldName = value)
            }
        }
        else {
            if (this.props.formSetup.detailTableDataFields.length - 1 === dataIndex && !(!value)) {
                this.changeForm(form => {
                    form.detailTableDataFields[dataIndex].fieldName = value
                    form.detailTableDataFields = newTableFields(form.detailTableDataFields)
                    return form;
                })
            }
            else {
                this.changeForm(form => form.detailTableDataFields[dataIndex].fieldName = value)
            }
        }
    }
    customRenderPrimaryTableFieldSelect = (value, tableMeta, updatedValue) => {
        const dataIndex = tableMeta.currentTableData[tableMeta.rowIndex].index;
                return (
                    <TableFieldName
                        type={"primary"}
                        fieldName={this.props.formSetup.primaryTableDataFields[dataIndex].fieldName}
                        id={"primaryFieldName" + dataIndex}
                        dataIndex={dataIndex}
                        updateSource={this.tableFieldSelectUpdateOnBlur}
                    />
                );
            
        
        
    }
    customRenderPrimaryTableDataType = (value, tableMeta, updatedValue) => {
        const dataIndex = tableMeta.currentTableData[tableMeta.rowIndex].index;
        return (
            <Select MenuProps={{ disableScrollLock: true }}
                id={"dataTypePrimary" + dataIndex}
                style={{ width: "100%" }}
                value={this.props.formSetup.primaryTableDataFields[dataIndex].dataType}
                onChange={(event) => {
                    this.changeForm(form => {
                        form.primaryTableDataFields[dataIndex].dataType = event.target.value;
                        if (event.target.value === 'date') {
                            form.primaryTableDataFields[dataIndex].controlType = ControlType.DatePicker;
                            form.primaryTableDataFields[dataIndex].formatType = "dateTime";
                            form.primaryTableDataFields[dataIndex].size = "";
                            form.primaryTableDataFields[dataIndex].precision = "";
                            form.primaryTableDataFields[dataIndex].isPrimaryKeyField = false;
                        }
                        else if (event.target.value === 'text') {
                            form.primaryTableDataFields[dataIndex].controlType = ControlType.TextBox;
                            form.primaryTableDataFields[dataIndex].formatType = "text";
                            form.primaryTableDataFields[dataIndex].size = "50";
                            form.primaryTableDataFields[dataIndex].precision = "";
                            form.primaryTableDataFields[dataIndex].isPrimaryKeyField = false;
                        }
                        else if (event.target.value === 'numbers') {
                            form.primaryTableDataFields[dataIndex].controlType = ControlType.NumericTextBox;
                            form.primaryTableDataFields[dataIndex].formatType = "integer";
                            form.primaryTableDataFields[dataIndex].size = "";
                            form.primaryTableDataFields[dataIndex].precision = "";
                        }

                        return form;
                    })

                }}
                name={"dataTypePrimary" + dataIndex}>
                {
                    DataType.map(function getDataType(dataType, index) {

                        return (<MenuItem key={index + 1} value={dataType.value}> {dataType.name} </MenuItem>);

                    })

                }
            </Select>
        )
    }
    customRenderPrimaryTableFormatType = (value, tableMeta, updatedValue) => {
        const dataIndex = tableMeta.currentTableData[tableMeta.rowIndex].index;
        return (
            <Select MenuProps={{ disableScrollLock: true }}
                id={"formatTypePrimary" + tableMeta.rowIndex}
                style={{ width: "100%" }}
                value={this.props.formSetup.primaryTableDataFields[dataIndex].formatType}
                onChange={(event) => {
                    
                    if (event.target.value === 'decimal') {
                        this.changeForm(form => {
                            form.primaryTableDataFields[dataIndex].formatType = event.target.value
                            form.primaryTableDataFields[dataIndex].size = "18"
                            form.primaryTableDataFields[dataIndex].precision = "2"
                            return form;
                        })

                    } else if (event.target.value === 'integer' || event.target.value === 'date' || event.target.value === 'dateTime' ) {
                        this.changeForm(form => {
                            form.primaryTableDataFields[dataIndex].formatType = event.target.value
                            form.primaryTableDataFields[dataIndex].size = ""
                            form.primaryTableDataFields[dataIndex].precision = ""
                            return form;
                        })
                        
                    }
                    else if (event.target.value === 'text') {
                        this.changeForm(form => {
                            form.primaryTableDataFields[dataIndex].formatType = event.target.value
                            form.primaryTableDataFields[dataIndex].size = "50"
                            form.primaryTableDataFields[dataIndex].precision = ""
                            return form
                        })

                    }
                    else if (event.target.value === 'textMaximum') {
                        this.changeForm(form => {
                            form.primaryTableDataFields[dataIndex].formatType = event.target.value
                            form.primaryTableDataFields[dataIndex].size = "10000"
                            form.primaryTableDataFields[dataIndex].precision = ""
                            return form
                        })

                    }
                    else
                    {
                        this.changeForm(form => form.primaryTableDataFields[dataIndex].formatType = event.target.value);
                    }
                }}
                name={"formatTypePrimary" + dataIndex}
            >
                {
                    FormatType.filter(format => format.type === this.props.formSetup.primaryTableDataFields[dataIndex].dataType).map(function getFormatType(formatType, index) {

                        return (<MenuItem key={index + 1} value={formatType.value}> {formatType.name} </MenuItem>);

                    })

                }
            </Select>
        )
    }
    //customRenderPrimaryTableControlType = (value, tableMeta, updatedValue) => {
    //    const dataIndex = tableMeta.currentTableData[tableMeta.rowIndex].index;
    //    return (
    //        <Select
    //            id={"controlTypePrimary" + dataIndex}
    //            style={{ width: "100%" }}
    //            disabled
    //            value={this.props.formSetup.primaryTableDataFields[dataIndex].controlType}
    //            onChange={(event) => {
    //                this.changeForm(form => form.primaryTableDataFields[dataIndex].controlType = event.target.value)

    //            }}
    //            name={"controlTypePrimary" + dataIndex}
    //        >
    //            {
    //                FormControlType.filter(format => format.type === this.props.formSetup.primaryTableDataFields[dataIndex].dataType).map(function getFormControlType(formControlType, index) {

    //                    return (<MenuItem key={index + 1} value={formControlType.value}> {formControlType.name} </MenuItem>);

    //                })

    //            }
    //        </Select>
    //    )
    //}
    customRenderPrimaryTableSize = (value, tableMeta, updatedValue) => {
        const dataIndex = tableMeta.currentTableData[tableMeta.rowIndex].index;
        let disabled = false;
        if ((!this.props.formSetup.createNewTable && !this.props.isUpdate) || !this.props.formSetup.primaryTableDataFields[dataIndex].isNew) {
            disabled = true;
        }
        else if (this.props.formSetup.primaryTableDataFields[dataIndex].formatType !== "decimal" && this.props.formSetup.primaryTableDataFields[dataIndex].formatType !== "text") {
            disabled = true;
        }
        return (

            <TextField id={"primarySize" + dataIndex}
                disabled={disabled}
                inputProps={{ maxLength: 4 }}
                onChange={(event) => {
                    let input = event.target.value;
                    if (!input || (input[input.length - 1].match('[0-9]') && input[0].match('[1-9]'))) {
                        this.changeForm(form => form.primaryTableDataFields[dataIndex].size = event.target.value)
                    }
                }}

                onBlur={(event) => {
                    let input = event.target.value;
                    if (!input) {
                        if (this.props.formSetup.primaryTableDataFields[dataIndex].formatType === "text") {
                            this.changeForm(form => form.primaryTableDataFields[dataIndex].size = "50")
                        }
                        else if (this.props.formSetup.primaryTableDataFields[dataIndex].formatType === "decimal") {
                            this.changeForm(form => form.primaryTableDataFields[dataIndex].size = "18")
                        }
                    }
                }}

                value={this.props.formSetup.primaryTableDataFields[dataIndex].size} ></TextField>

        )
    }
    customRenderPrimaryTablePrecision = (value, tableMeta, updatedValue) => {
        const dataIndex = tableMeta.currentTableData[tableMeta.rowIndex].index;
        const precisionValue = this.props.formSetup.primaryTableDataFields[dataIndex].precision;
        let disabled = false;
        if ((!this.props.formSetup.createNewTable && !this.props.isUpdate) || !this.props.formSetup.primaryTableDataFields[dataIndex].isNew) {
            disabled = true;
        }
        else if (this.props.formSetup.primaryTableDataFields[dataIndex].formatType !== "decimal") {
            disabled = true;
        }
        return (

            <TextField id={"primaryPrecision" + dataIndex}
                disabled={disabled}
                inputProps={{ maxLength: 1 }}
                onChange={(event) => {
                    let input = event.target.value;
                    if (!input || (input[input.length - 1].match('[0-9]') && input[0].match('[1-9]'))) {
                        this.changeForm(form => form.primaryTableDataFields[dataIndex].precision = event.target.value)
                    }
                }}
                onBlur={(event) => {
                    let input = event.target.value;
                    if (!input) {
                        if (this.props.formSetup.primaryTableDataFields[dataIndex].formatType === "decimal") {
                            this.changeForm(form => form.primaryTableDataFields[dataIndex].precision = "2")
                        }
                    }
                }}
                value={precisionValue < 0 ? "" : precisionValue} ></TextField>

        )
    }
    customRenderPrimaryTableKey = (value, tableMeta, updatedValue) => {
        const dataIndex = tableMeta.currentTableData[tableMeta.rowIndex].index;
        let disabled = false;
        if (this.props.isUpdate || !this.props.formSetup.createNewTable || !this.props.formSetup.primaryTableDataFields[dataIndex].isNew || this.props.formSetup.primaryTableDataFields[dataIndex].dataType !== "numbers") {
            disabled = true;
        }
        return (

            <Checkbox id={"primaryTableKey" + dataIndex} disabled={disabled} onChange={(event) => { this.ChangeKeyField(form => form.primaryTableDataFields[dataIndex].isPrimaryKeyField = event.target.checked, "primary", event.target.checked, this.props.formSetup.primaryTableDataFields) }} checked={this.props.formSetup.primaryTableDataFields[dataIndex].isPrimaryKeyField} ></Checkbox>

        );
    }
    customRenderPrimaryTableDelete = (value, tableMeta, updatedValue) => {
        if ((this.props.formSetup.createNewTable && !this.props.isUpdate)) {
            const dataIndex = tableMeta.currentTableData[tableMeta.rowIndex].index;
            return (<IconButton
                disabled={!this.props.formSetup.primaryTableDataFields[dataIndex].isNew || (this.props.formSetup.primaryTableDataFields.length === (dataIndex + 1))}
                type="button"
                onClick={(e) => this.props.dispatch(deleteFormPrimaryTableField(dataIndex, 'primary'))} >
                <DeleteOutlineIcon />
            </IconButton>)
        }
        else {
            return (<div />)
        }
    }

    customTableGridHeadLabelRender = (columnMeta) => {
        return (<Typography variant="subtitle2"> {columnMeta.label}</Typography>)
    }
    renderPrimaryTableFieldList = () => {
        const { classes } = this.props;
        const isEditable = (this.props.formSetup.createNewTable && !this.props.isUpdate)
        const fieldNameBodyRender = isEditable ? { customBodyRender: this.customRenderPrimaryTableFieldSelect } : {};
        const dataTypeBodyRender = isEditable ? { customBodyRender: this.customRenderPrimaryTableDataType } : {  };
        const formatTypeBodyRender = isEditable ? { customBodyRender: this.customRenderPrimaryTableFormatType } : { };
        const sizeBodyRender = isEditable ? { customBodyRender: this.customRenderPrimaryTableSize } : {};
        const precisionBodyRender = isEditable ? { customBodyRender: this.customRenderPrimaryTablePrecision } : {};
        //const controlTypeBodyRender = isEditable ? { customBodyRender: this.customRenderPrimaryTableControlType } : { };
        const primaryFieldGridSettings = {
            columns: [
                {
                    name: "fieldName",
                    label: "Field Name",
                    options: {
                        sortThirdClickReset: true,
                         ...fieldNameBodyRender ,
                        setCellProps: () => ({ className: isEditable ? classes.formTableGridColumnHeader: `${classes.formTableReadOnlyColumn} ${classes.formTableGridColumnHeader}` }),
                        setCellHeaderProps: () => ({ className: classes.formTableGridColumnHeader }),
                        customHeadLabelRender: this.customTableGridHeadLabelRender,

                    },
                },
                {
                    name: "dataType",
                    label: "Data Type",
                    options: {
                        sort: false,
                        ...dataTypeBodyRender,
                        setCellProps: () => ({ className: isEditable ? classes.formTableDataTypeColumn : `${classes.formTableReadOnlyColumn} ${classes.formTableDataTypeColumn}` }),
                        setCellHeaderProps: () => ({ className: classes.formTableGridColumnHeader }),
                        customHeadLabelRender: this.customTableGridHeadLabelRender,
                    },
                },
                {
                    name: "formatType",
                    label: "Format Type",
                    options: {
                        sort: false,
                        ...formatTypeBodyRender,
                        setCellProps: () => ({ className: isEditable ? classes.formTableDataTypeColumn : `${classes.formTableReadOnlyColumn} ${classes.formTableDataTypeColumn}` }),
                        setCellHeaderProps: () => ({ className: classes.formTableGridColumnHeader }),
                        customHeadLabelRender: this.customTableGridHeadLabelRender,
                    },

                },
                {
                    name: "size",
                    label: "Size",
                    options: {
                        sort: false,
                        ...sizeBodyRender,
                        setCellProps: () => ({ className: isEditable ? classes.formTableSizeColumn : `${classes.formTableReadOnlyColumn} ${classes.formTableSizeColumn}` }),
                        setCellHeaderProps: () => ({ className: classes.formTableGridColumnHeader }),
                        customHeadLabelRender: this.customTableGridHeadLabelRender,
                    },

                },
                {
                    name: "precision",
                    label: "Precision",
                    options: {
                        sort: false,
                        ...precisionBodyRender,
                        setCellProps: () => ({ className: isEditable ? classes.formTablePrecisionColumn : `${classes.formTableReadOnlyColumn} ${classes.formTablePrecisionColumn}` }),
                        setCellHeaderProps: () => ({ className: classes.formTableGridColumnHeader }),
                        customHeadLabelRender: this.customTableGridHeadLabelRender,
                    },
                },
                //{
                //    name: "controlType",
                //    label: "Control Type",
                //    options: {
                //        sort: false,
                //        ...controlTypeBodyRender,
                //        setCellProps: () => ({ className: isEditable ? classes.formTableControlTypeColumn : `${classes.formTableReadOnlyColumn} ${classes.formTableControlTypeColumn}` }),
                //        setCellHeaderProps: () => ({ className: classes.formTableGridColumnHeader }),
                //        customHeadLabelRender: this.customTableGridHeadLabelRender,
                //    },

                //},
                {
                    name: "isPrimaryField",
                    label: "Primary Field",
                    options: {
                        sort: false,
                        customBodyRender: this.customRenderPrimaryTableKey,
                        setCellProps: () => ({ className: classes.formTableGridColumnHeader }),
                        setCellHeaderProps: () => ({ className: classes.formTableGridColumnHeader }),
                        customHeadLabelRender: this.customTableGridHeadLabelRender,
                    },
                },
                {
                    name: "Delete",
                    label: " ",
                    options: {
                        sort: false,
                        customBodyRender: this.customRenderPrimaryTableDelete,
                        setCellProps: () => ({ className: classes.formTableGridColumnHeader }),
                        setCellHeaderProps: () => ({ className: classes.formTableGridColumnHeader }),
                    },

                },
            ],
            options: {
                download: false,
                elevation: 0,
                filter: false,
                print: false,
                search: false,
                viewColumns: false,
                pagination: false,
                selectableRows: 'none',
                sortOrder: {},
                toolbar: {
                    display: false
                },
                tableBodyMaxHeight: '400px',

            },
        }
        return (
            <Grid container direction="row" >
                <Grid item xs={12} >
                    <MUIDataTable container
                        classes={{ responsiveBase: this.props.classes.tableGridRightMargin }}
                        title={''}
                        data={isEditable ? this.props.formSetup.primaryTableDataFields : this.getReadOnlyPrimaryDataList()}
                        columns={primaryFieldGridSettings.columns}
                        options={primaryFieldGridSettings.options}
                    />
                </Grid>

            </Grid>
        );
    }

    customRenderDetailTableFieldSelect = (value, tableMeta, updatedValue) => {
        const dataIndex = tableMeta.currentTableData[tableMeta.rowIndex].index;
        
            return (
                <TableFieldName
                    type={"detail"}
                    fieldName={this.props.formSetup.detailTableDataFields[dataIndex].fieldName}
                    id={"detailFieldName" + dataIndex}
                    dataIndex={dataIndex}
                    updateSource={this.tableFieldSelectUpdateOnBlur}
                />
            );

        
    }
    customRenderDetailTableDataType = (value, tableMeta, updatedValue) => {
        const dataIndex = tableMeta.currentTableData[tableMeta.rowIndex].index;
        return (
            <Select MenuProps={{ disableScrollLock: true }}
                id={"detailDataType" + dataIndex}
                style={{ width: "100%" }}
                value={this.props.formSetup.detailTableDataFields[dataIndex].dataType}
                onChange={(event) => {
                    this.changeForm(form => {
                        form.detailTableDataFields[dataIndex].dataType = event.target.value;
                        if (event.target.value === 'date') {
                            form.detailTableDataFields[dataIndex].controlType = ControlType.DatePicker;
                            form.detailTableDataFields[dataIndex].formatType = "dateTime";
                            form.detailTableDataFields[dataIndex].size = "";
                            form.detailTableDataFields[dataIndex].precision = "";
                            form.detailTableDataFields[dataIndex].isForeignKeyField = false;
                        }
                        else if (event.target.value === 'text') {
                            form.detailTableDataFields[dataIndex].controlType = ControlType.TextBox;
                            form.detailTableDataFields[dataIndex].formatType = "text";
                            form.detailTableDataFields[dataIndex].size = "50";
                            form.detailTableDataFields[dataIndex].precision = "";
                            form.detailTableDataFields[dataIndex].isForeignKeyField = false;
                        }
                        else if (event.target.value === 'numbers') {
                            form.detailTableDataFields[dataIndex].controlType = ControlType.NumericTextBox;
                            form.detailTableDataFields[dataIndex].formatType = "integer";
                            form.detailTableDataFields[dataIndex].size = "";
                            form.detailTableDataFields[dataIndex].precision = "";
                        }

                        return form;
                    })
                }}
                name={"detailDataType" + dataIndex}>
                {
                    DataType.map(function getDataType(dataType, index) {

                        return (<MenuItem key={index + 1} value={dataType.value}> {dataType.name} </MenuItem>);

                    })

                }
            </Select>
        )
    }
    customRenderDetailTableFormatType = (value, tableMeta, updatedValue) => {
        const dataIndex = tableMeta.currentTableData[tableMeta.rowIndex].index;
        return (
            <Select MenuProps={{ disableScrollLock: true }}
                id={"formatTypePrimary" + dataIndex}
                style={{ width: "100%" }}
                value={this.props.formSetup.detailTableDataFields[dataIndex].formatType}
                onChange={(event) => {
                    
                    if (event.target.value === 'decimal') {
                        this.changeForm(form => {
                            form.detailTableDataFields[dataIndex].formatType = event.target.value
                            form.detailTableDataFields[dataIndex].size = "18"
                            form.detailTableDataFields[dataIndex].precision = "2"
                            return form
                        })

                    }
                    else if (event.target.value === 'integer' || event.target.value === 'date' || event.target.value === 'dateTime') {
                        this.changeForm(form => {
                            form.detailTableDataFields[dataIndex].formatType = event.target.value
                            form.detailTableDataFields[dataIndex].size = ""
                            form.detailTableDataFields[dataIndex].precision = ""
                            return form
                        })

                    }
                    else if (event.target.value === 'textMaximum') {
                        this.changeForm(form => {
                            form.detailTableDataFields[dataIndex].formatType = event.target.value
                            form.detailTableDataFields[dataIndex].size = "10000"
                            form.detailTableDataFields[dataIndex].precision = ""
                            return form
                        })

                    }
                    else if (event.target.value === 'text') {
                        this.changeForm(form => {
                            form.detailTableDataFields[dataIndex].formatType = event.target.value
                            form.detailTableDataFields[dataIndex].size = "50"
                            form.detailTableDataFields[dataIndex].precision = ""
                            return form
                        })

                    }
                    else {
                        this.changeForm(form => form.detailTableDataFields[dataIndex].formatType = event.target.value)
                    }
                }}
                name={"detailFormatType" + dataIndex}
            >
                {
                    FormatType.filter(format => format.type === this.props.formSetup.detailTableDataFields[dataIndex].dataType).map(function getFormatType(formatType, index) {

                        return (<MenuItem key={index + 1} value={formatType.value}> {formatType.name} </MenuItem>);

                    })

                }
            </Select>
        )
    }
    //customRenderDetailTableControlType = (value, tableMeta, updatedValue) => {
    //    const dataIndex = tableMeta.currentTableData[tableMeta.rowIndex].index;
    //    return (
    //        <Select
    //            id={"detailControlType" + dataIndex}
    //            style={{ width: "100%" }}
    //            value={this.props.formSetup.detailTableDataFields[dataIndex].controlType}
    //            disabled
    //            onChange={(event) => {
    //                this.changeForm(form => form.detailTableDataFields[dataIndex].controlType = event.target.value)

    //            }}
    //            name={"detailControlType" + dataIndex}
    //        >
    //            {
    //                FormControlType.filter(format => format.type === this.props.formSetup.detailTableDataFields[dataIndex].dataType).map(function getFormControlType(formControlType, index) {

    //                    return (<MenuItem key={index + 1} value={formControlType.value}> {formControlType.name} </MenuItem>);

    //                })

    //            }
    //        </Select>
    //    )
    //}
    customRenderDetailTableSize = (value, tableMeta, updatedValue) => {
        const dataIndex = tableMeta.currentTableData[tableMeta.rowIndex].index;
        let disabled = false;
        if ((!this.props.formSetup.createNewTable && !this.props.isUpdate) || !this.props.formSetup.detailTableDataFields[dataIndex].isNew) {
            disabled = true;
        }
        else if (this.props.formSetup.detailTableDataFields[dataIndex].formatType !== "decimal" && this.props.formSetup.detailTableDataFields[dataIndex].formatType !== "text") {
            disabled = true;
        }
        return (

            <TextField id={"detailSize" + dataIndex}
                disabled={disabled}
                onChange={(event) => {
                    let input = event.target.value;
                    if (!input || (input[input.length - 1].match('[0-9]') && input[0].match('[1-9]'))) {
                        this.changeForm(form => form.detailTableDataFields[dataIndex].size = event.target.value)
                    }
                }}
                onBlur={(event) => {
                    let input = event.target.value;
                    if (!input) {
                        if (this.props.formSetup.detailTableDataFields[dataIndex].formatType === "text") {
                            this.changeForm(form => form.detailTableDataFields[dataIndex].size = "50")
                        }
                        else if (this.props.formSetup.detailTableDataFields[dataIndex].formatType === "decimal") {
                            this.changeForm(form => form.detailTableDataFields[dataIndex].size = "18")
                        }
                    }
                }}
                value={this.props.formSetup.detailTableDataFields[dataIndex].size} ></TextField>

        )
    }
    customRenderDetailTablePrecision = (value, tableMeta, updatedValue) => {
        const dataIndex = tableMeta.currentTableData[tableMeta.rowIndex].index;
        const precisionValue = this.props.formSetup.detailTableDataFields[dataIndex].precision
        let disabled = false;
        if ((!this.props.formSetup.createNewTable && !this.props.isUpdate) || !this.props.formSetup.detailTableDataFields[dataIndex].isNew) {
            disabled = true;
        }
        else if (this.props.formSetup.detailTableDataFields[dataIndex].formatType !== "decimal") {
            disabled = true;
        }
        return (

            <TextField id={"detailPrecision" + dataIndex}
                disabled={disabled}
                onChange={(event) => {
                    let input = event.target.value;
                    if (!input || (input[input.length - 1].match('[0-9]') && input[0].match('[1-9]'))) {
                        this.changeForm(form => form.detailTableDataFields[dataIndex].precision = event.target.value)
                    }
                }}
                onBlur={(event) => {
                    let input = event.target.value;
                    if (!input) {
                        if (this.props.formSetup.detailTableDataFields[dataIndex].formatType === "decimal") {
                            this.changeForm(form => form.detailTableDataFields[dataIndex].precision = "2")
                        }
                    }
                }}
                value={precisionValue < 0 ? "" : precisionValue} ></TextField>

        )
    }
    customRenderDetailTableKey = (value, tableMeta, updatedValue) => {
        const dataIndex = tableMeta.currentTableData[tableMeta.rowIndex].index;
        let disabled = false;
        if ((this.props.isUpdate) || this.props.formSetup.detailTableDataFields[dataIndex].dataType !== "numbers") {
            disabled = true;
        }
        return (

            <Checkbox id={"detailTableKey" + dataIndex} disabled={disabled} onChange={(event) => { this.ChangeKeyField(form => form.detailTableDataFields[dataIndex].isPrimaryKeyField = event.target.checked, "detail", event.target.checked, this.props.formSetup.detailTableDataFields) }} checked={this.props.formSetup.detailTableDataFields[dataIndex].isPrimaryKeyField} ></Checkbox>

        );
    }
    customRenderDetailTableDelete = (value, tableMeta, updatedValue) => {
        if ((this.props.formSetup.createNewTable && !this.props.isUpdate)) {


            const dataIndex = tableMeta.currentTableData[tableMeta.rowIndex].index;
            return (<IconButton
                disabled={!this.props.formSetup.detailTableDataFields[dataIndex].isNew || (this.props.formSetup.detailTableDataFields.length === (dataIndex + 1))}
                type="button"
                onClick={(e) => this.props.dispatch(deleteFormPrimaryTableField(dataIndex, 'detail'))} >
                <DeleteOutlineIcon />
            </IconButton>)
        }
        else {
            return (<div />)
        }
    }
    renderDetailTableFieldList = () => {
        const { classes } = this.props;
        const isEditable = (this.props.formSetup.createNewTable && !this.props.isUpdate)
        const fieldNameBodyRender = isEditable ? { customBodyRender: this.customRenderDetailTableFieldSelect } : {};
        const dataTypeBodyRender = isEditable ? { customBodyRender: this.customRenderDetailTableDataType } : {};
        const formatTypeBodyRender = isEditable ? { customBodyRender: this.customRenderDetailTableFormatType } : {};
        const sizeBodyRender = isEditable ? { customBodyRender: this.customRenderDetailTableSize } : {};
        const precisionBodyRender = isEditable ? { customBodyRender: this.customRenderDetailTablePrecision } : {};
        //const controlTypeBodyRender = isEditable ? { customBodyRender: this.customRenderDetailTableControlType } : {};
        const detailFieldGridSettings = {
            columns: [
                {
                    name: "fieldName",
                    label: "Field Name",
                    sortThirdClickReset: true,
                    options: {
                        ...fieldNameBodyRender,
                        setCellProps: () => ({ className: classes.formTableGridColumnHeader }),
                        setCellProps: () => ({ className: isEditable ? classes.formTableGridColumnHeader : `${classes.formTableReadOnlyColumn} ${classes.formTableGridColumnHeader}` }),
                        setCellHeaderProps: () => ({ className: classes.formTableGridColumnHeader }),
                        customHeadLabelRender: this.customTableGridHeadLabelRender,
                    },
                },
                {
                    name: "dataType",
                    label: "Data Type",
                    options: {
                        sort: false,
                        ...dataTypeBodyRender,
                        setCellProps: () => ({ className: isEditable ? classes.formTableDataTypeColumn : `${classes.formTableReadOnlyColumn} ${classes.formTableDataTypeColumn}` }),
                        setCellHeaderProps: () => ({ className: classes.formTableGridColumnHeader }),
                        customHeadLabelRender: this.customTableGridHeadLabelRender,
                    },
                },
                {
                    name: "formatType",
                    label: "Format Type",
                    options: {
                        sort: false,
                        ...formatTypeBodyRender,
                        setCellProps: () => ({ className: isEditable ? classes.formTableDataTypeColumn : `${classes.formTableReadOnlyColumn} ${classes.formTableDataTypeColumn}` }),
                        setCellHeaderProps: () => ({ className: classes.formTableGridColumnHeader }),
                        customHeadLabelRender: this.customTableGridHeadLabelRender,
                    },

                },
                {
                    name: "size",
                    label: "Size",
                    options: {
                        sort: false,
                        ...sizeBodyRender,
                        setCellProps: () => ({ className: isEditable ? classes.formTableSizeColumn : `${classes.formTableReadOnlyColumn} ${classes.formTableSizeColumn}` }),
                        setCellHeaderProps: () => ({ className: classes.formTableGridColumnHeader }),
                        customHeadLabelRender: this.customTableGridHeadLabelRender,
                    },

                },
                {
                    name: "precision",
                    label: "Precision",
                    options: {
                        sort: false,
                        ...precisionBodyRender,
                        setCellProps: () => ({ className: isEditable ? classes.formTablePrecisionColumn : `${classes.formTableReadOnlyColumn} ${classes.formTablePrecisionColumn}` }),
                        setCellHeaderProps: () => ({ className: classes.formTableGridColumnHeader }),
                        customHeadLabelRender: this.customTableGridHeadLabelRender,
                    },
                },
                //{
                //    name: "controlType",
                //    label: "Control Type",
                //    options: {
                //        sort: false,
                //        ...controlTypeBodyRender,
                //        setCellProps: () => ({ className: isEditable ? classes.formTableControlTypeColumn : `${classes.formTableReadOnlyColumn} ${classes.formTableControlTypeColumn}` }),
                //        setCellHeaderProps: () => ({ className: classes.formTableGridColumnHeader }),
                //        customHeadLabelRender: this.customTableGridHeadLabelRender,
                //    },

                //},
                {
                    name: "isPrimaryField",
                    label: "Link Field",
                    options: {
                        sort: false,
                        customBodyRender: this.customRenderDetailTableKey,
                        setCellProps: () => ({ className: classes.formTableGridColumnHeader }),
                        setCellHeaderProps: () => ({ className: classes.formTableGridColumnHeader }),
                        customHeadLabelRender: this.customTableGridHeadLabelRender,
                    },
                },
                {
                    name: "Delete",
                    label: " ",
                    options: {
                        sort: false,
                        customBodyRender: this.customRenderDetailTableDelete,
                        setCellProps: () => ({ className: classes.formTableGridColumnHeader }),
                        setCellHeaderProps: () => ({ className: classes.formTableGridColumnHeader }),
                    },

                },
            ],
            options: {
                download: false,
                elevation: 0,
                filter: false,
                print: false,
                search: false,
                viewColumns: false,
                pagination: false,
                selectableRows: 'none',
                sortOrder: {},
                toolbar: {
                    display: false
                },
                tableBodyMaxHeight: '400px',
            }
        }
        return (
            <Grid container direction="row"  >
                <Grid item xs={12} >
                    <MUIDataTable container
                        classes={{ responsiveBase: this.props.classes.tableGridRightMargin }}
                        title={''}
                        data={isEditable ? this.props.formSetup.detailTableDataFields : this.getReadOnlyDetailDataList()}
                        columns={detailFieldGridSettings.columns}
                        options={detailFieldGridSettings.options}
                    />
                </Grid>

            </Grid>
        );
    }
    handleTabChange = (event, newValue) => {
        this.setState({ activeTabIndex: newValue });
    }

    getReadOnlyPrimaryDataList = () => {
        const modifiedFieldList= this.props.formSetup.primaryTableDataFields.map((item, index) => {
            var newItem = {...item}
            newItem["dataType"] = DataTypeObject[item["dataType"]];
            newItem["formatType"] = FormatTypeObject[item["formatType"]];
            newItem["controlType"] = FormControlTypeObject[item["controlType"]];
            if (item["precision"] === "-1") {
                newItem["precision"] = "";
            }
            return newItem;
        });
        return modifiedFieldList;
    }
    getReadOnlyDetailDataList = () => {
        const modifiedFieldList = this.props.formSetup.detailTableDataFields.map((item, index) => {
            var newItem = { ...item }
            newItem["dataType"] = DataTypeObject[item["dataType"]];
            newItem["formatType"] = FormatTypeObject[item["formatType"]];
            newItem["controlType"] = FormControlTypeObject[item["controlType"]];
            if (item["precision"] === "-1") {
                newItem["precision"] = "";
            }
            return newItem;
        });
        return modifiedFieldList;
    }
    
    renderFormTableSource = () => {
        const { classes } = this.props;
        return (
           <Box mt={1}>
            <Accordion className={classes.setupAccordion} defaultExpanded={true} mt={1}>
                    <AccordionSummary classes={{ content: classes.setupSummary, root: classes.setupSummary }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography >Tables</Typography>
                </AccordionSummary >
                <AccordionDetails >
            <Grid container direction="row" mt={1} width="auto" >
               <Grid item xs={12}  >
                    <AppBar position="static" className={classes.tabRoot} >
                        <Tabs value={this.state.activeTabIndex} onChange={this.handleTabChange} >
                            <Tab label="PRIMARY TABLE" {...a11yProps(0)} className={classes.body2} />
                            <Tab label="DETAIL TABLE" {...a11yProps(1)} className={classes.body2} disabled={this.props.formSetup.formType === FormType.Standard} />
                        </Tabs>
                    </AppBar>
                    <Box mt={1}>
                        <TabPanel value={this.state.activeTabIndex} index={0}>
                            <Card variant="outlined" pb={7}  >
                                <div>

                                    {
                                        this.renderPrimaryTableFieldList()
                                    }

                                </div>
                            </Card>

                        </TabPanel>
                        <TabPanel value={this.state.activeTabIndex} index={1}>
                            <Card variant="outlined" pb={7}  >
                                <div>

                                    {
                                        this.props.formSetup.formType === FormType.HeaderDetail && this.renderDetailTableFieldList()
                                    }

                                </div>
                            </Card>

                        </TabPanel>
                    </Box>

                </Grid>

                    </Grid>
                </AccordionDetails>
                </Accordion>
           </Box>
            );
    }
  
    render() {
        const tabIndex = this.props.activeTabIndex;
        return (
            <div>
                {tabIndex === 0 && this.renderFormTableSource()}
                </div>
            );
        
    }
}
function mapStateToProps(state) {
    return {
        formSetup: state.forms.formSource,
        isUpdate: state.forms.isUpdate,
        activeTabIndex:state.forms.activeTabIndex,
        formActionType: state.forms.actionType,
        
    };
}
export default connect(mapStateToProps)(withStyles(setupTheme)(FormSourceTable));