import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import { formTheme } from 'client/components/Form/theme/formTheme';

const GridButton = (props) => {
    const [isDisable, setIsDisable] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsDisable(false);
        }, 1000)
        
    }, [isDisable])

    const saveRow = (value, event) => {
        if (!isDisable) {
            props.saveRow(value);
            setIsDisable(true)
        }
    }

    return (
        
        <Button
            color="primary"
            className={ props.classes.saveButton}
            onClick={(event) => { (props.saveNew) ? saveRow(false, event) : saveRow(true, event) }}>
            {(props.saveNew) ? 'Save & New' : 'Save & Close'}
        </Button>
    );

}

export default withStyles(formTheme)(GridButton);