import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { layoutActionCreator } from 'client/store/Layout';
import { ThemeProvider } from '@mui/material/styles';
import ErrorHandler from 'client/components/Common/ErrorHandler';
import MainContent from 'client/components/MainContent/MainContent';
 
import { customTheme, customThemeStyles } from 'common/theme.js';
import { withStyles } from '@mui/styles';
class layout extends React.Component {
    constructor(props) {
        super(props);
    }
    componentWillMount() {
        if (!this.props.siteTheme.isLoading)
            this.props.getSiteTheme();
    }
    componentDidUpdate(prevProps) {

        if (this.props.roleId > 0 && (this.props.roleId != prevProps.roleId)) {
           // console.log("timer-maincontent did update1", this.props.sessionTimeOut)
            this.props.loginStatusAction(this.props.sessionTimeOut);
        } else if(!this.props.isLogin){
           // console.log("timer-maincontent did update2",this.props)
            this.props.logoutStatusAction();
        }
    }
    render() {
        if (this.props.siteTheme.isLoading) {
            if (this.props.siteTheme.theme) {
                customTheme.palette.primary.main = this.props.siteTheme.theme ;
                customTheme.palette.primary.light = this.props.siteTheme.theme ;
                customThemeStyles.saveButton_Client.backgroundColor = this.props.siteTheme.theme + ' !important';
                customThemeStyles.resetButton_Client.backgroundColor = this.props.siteTheme.theme + ' !important';
                customThemeStyles.saveButton_Client_2.backgroundColor = this.props.siteTheme.theme + ' !important';
                customThemeStyles.customTheme.backgroundColor = this.props.siteTheme.theme;
                document.documentElement.style.setProperty('--theme-color', this.props.siteTheme.theme)
                return (
                    <ThemeProvider theme={customTheme}>
                        <ErrorHandler />
                        <MainContent propsRoleId={this.props.roleId} actionSource={this.props.actionSource} />
                    </ThemeProvider>
                );
            }
            else {
                return (
                    <ThemeProvider theme={customTheme}>
                        <ErrorHandler />
                        <MainContent propsRoleId={this.props.roleId} actionSource={this.props.actionSource} />
                    </ThemeProvider>
                );
            }
        }
        else return null
    }
}

export default connect(
    state => state.layout,
    dispatch => bindActionCreators(layoutActionCreator, dispatch),
)(withStyles(customThemeStyles)(layout));

