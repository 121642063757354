import { theme, customColorPalette } from 'common/theme.js';
export const alertStyle = () => ({
    wrapper: {

        [theme.breakpoints.only('xl')]: {
            minWidth: "716px",
            height: "43px",
            paddingTop: '8px',
            maxWidth: "1000px",
            wordBreak: "break-all"

        },
        [theme.breakpoints.down('md')]: {
            height: "43px",
            maxWidth: "1000px",
            wordBreak: "break-word",
            paddingTop: "8px",
            paddingRight: "23px!important",

        },

           [theme.breakpoints.down('xl')]: {
                minWidth: "350px",
                height: "43px",
                paddingTop: '8px',
                maxWidth: "1000px",
                wordBreak: "break-word",


            }


        
    },
    alertCloseIcon: {
        color: `${customColorPalette.black[87]} !important`
    },
    alertActionButton: {
        minWidth: '35px',
    },
    root: {
        paddingBottom: "0px!important",
        paddingTop: "0px!important"
    },
    errorBoundaryStyle: {
        position: "absolute",
        left: "50%",
        transform: "translate(-50%, 0)",
        zIndex: 2000000,
        paddingTop: '68px'
    },

    alertContainer: {

        minWidth: "127px"
    },
    messageStyle: {
        fontFamily: "Roboto !important",
        fontWeight: "400 !important",
        fontSize: "14px !important",
        fontStyle: "normal !important",
        letterSpacing: "0.25px !important",
        wordBreak: "break-word"
    }




})