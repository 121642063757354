import React from "react";
import { connect } from 'react-redux';
import {  formatValue } from 'client/components/Common/Utility';
import { cloneDeep } from 'lodash';
import * as enumerator from 'client/components/Common/Enum';
class SelectedRowSum extends React.Component {

    render() {


        let column = cloneDeep(this.props.column);

        if (column.fieldFormat === enumerator.FormatType.None) {
            column.fieldFormat =enumerator.FormatType.Decimal2
        }

        return (

            <span>Sum: {formatValue(column,this.props.selectedRows.sum)}</span>

        );

    }
}

export default connect(
    state => state.dataView,
)(SelectedRowSum);