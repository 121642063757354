import React from "react";
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import { DataUtil } from '@syncfusion/ej2-data';
import FilterListIcon from '@mui/icons-material/FilterList';

import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import * as Enums from 'client/components/Common/Enum';
import cloneDeep from 'lodash/cloneDeep';
import LookUpList from 'client/components/Form/LookUpList';




export default function InputText(props) {
    let value = (props.dataModel[props.settings.fieldName]) ? props.dataModel[props.settings.fieldName] : "";
    const [fieldValue, setFieldValue] = useState(value);
    const [showLookUpList, setShowLookUpList] = useState(false);
    const [dataViewDefinition, setDataViewDefinition] = useState({});
    const [data, setData] = useState([]);

    const getDependencyList = () => {
        let list = [];
        for (let filterDefinition of props.settings.listParameterFields) {
            if (filterDefinition.controlName !== props.settings.fieldName) {
                
                list.push(props.dataModel[filterDefinition.controlName])    
            }
        }

        return list;
    }
    const dependencyFieldList = [...getDependencyList()]; //props.settings.reportId,

    React.useEffect(() => {
        if (value !== fieldValue) {
            setFieldValue(value);
        }
    }, [value]);

    React.useEffect(() => {

        if (dependencyFieldList.length > 0 && props.currentControl.currentControlName !== props.settings.fieldName) {

            let index = 0;
            let noChangeInDependencyFieldValue = true;
            for (let filterDefinition of props.settings.listParameterFields) {
                if (filterDefinition.controlName !== props.settings.fieldName) {
                    if (props.retrievedDataModel[filterDefinition.controlName] !== dependencyFieldList[index]) {
                        noChangeInDependencyFieldValue = false;
                        break;
                    }
                    index = index + 1;
                }
            }
            let depValue = "";
            if (noChangeInDependencyFieldValue && value === props.retrievedDataModel[props.settings.fieldName]) {
                depValue = value
            }
            else
            {
                const skip = props.dependentFieldMapping ? props.dependentFieldMapping.findIndex(field => field.dependencyField === props.settings.fieldName
                    && field.value === value
                    && dependencyFieldList.includes(field.parentFieldValue)) : -1;
                if (skip !== - 1) {
                    depValue = value
                }
                else
                {
                    if (props.currentControl.isLookUp && props.currentControl.lookUpRowData)
                    {
                        let index = 0;
                        let changeInDependencyFieldValue = false;
                        const mappedCurrentControlIndex = props.currentControl.mappedFieldList.findIndex(mapField => mapField.controlName === props.settings.fieldName)
                        for (let filterDefinition of props.settings.listParameterFields) {

                            if (filterDefinition.controlName !== props.settings.fieldName) {
                                const mappedIndex = props.currentControl.mappedFieldList.findIndex(mapField => mapField.controlName === filterDefinition.controlName)
                                
                                if (mappedIndex !== -1 && mappedCurrentControlIndex === -1
                                    && props.dataModel[filterDefinition.controlName] !== props.currentControl.lookUpRowData[props.currentControl.mappedFieldList[mappedIndex].reportField])
                                {
                                    changeInDependencyFieldValue = true;
                                    break;
                                }
                                else if (mappedIndex !== -1 && mappedCurrentControlIndex ===-1 &&
                                     props.dataModel[filterDefinition.controlName] === props.currentControl.lookUpRowData[props.currentControl.mappedFieldList[mappedIndex].reportField]) {
                                    changeInDependencyFieldValue = true;
                                    break;
                                }
                                index = index + 1;
                            }
                        }
                        if (!changeInDependencyFieldValue) {
                            depValue = value;
                        }
                    }
                }
            }
            if (!props.isGrid) {
               
                props.onChange(props.settings.isMandatory, { type: "blur", target: { name: props.settings.fieldName } }, depValue, props.settings.listOutputFields, {}, false, true);
            }
            else {
                props.onChange(props.settings.isMandatory, { type: "blur", target: { name: props.settings.fieldName } }, depValue, "", "", "", props.settings.listOutputFields, {}, true);
            }
        }

    }, [...dependencyFieldList]);

    const handleChange = (event) => {
        setFieldValue(event.target.value);
    };
    const handleBlur = (event) => {
        if ((event.target.value !== props.dataModel[props.settings.fieldName]) || (props.settings.isMandatory && !errorState.error && !event.target.value)) {
            let skipValidation = false;
            if (event.relatedTarget) {
                if (event.relatedTarget.id === `btn_${props.settings.fieldName}`) {
                    skipValidation = true;
                }
            }
            let proceedOnChange = true;
           
            if (proceedOnChange) {
                if (!props.isGrid) {
                    props.onChange(props.settings.isMandatory, event, fieldValue, props.settings.listOutputFields, {}, false, skipValidation);
                }
                else {
                    props.onChange(props.settings.isMandatory, event, fieldValue, "", "", "", props.settings.listOutputFields, {}, skipValidation);
                }
            }

            if (props.settings.reportId !== 0 && event.target.value) {
                loadLookUpList(); //proceedOnChange =
            }
            
           
        }
    };
   
    const handleLookUpClose = () => {
        const fieldValue = props.dataModel[props.settings.fieldName]
        if (fieldValue && data && data.length !== 0 && props.settings.listParameterFields.length !== 0) {
            const index = props.settings.listOutputFields.findIndex(f => f.controlName === props.settings.fieldName)
            if (index !== -1) {
                const reportFieldName = props.settings.listOutputFields[index].reportField;
                var result = data.filter(row => {
                    return row[reportFieldName] === fieldValue
                })
                if (result.length === 0) {
                    clearFieldValueOnLookUpCancel();
                }
                
            }
        }
        setShowLookUpList(false);
    }
    const clearFieldValueOnLookUpCancel = () => {

        if (props.dataModel[props.settings.fieldName]) {
            if (!props.isGrid) {
                props.onChange(props.settings.isMandatory, props.settings.fieldName, "", props.settings.listOutputFields, {}, "", "", true);
            }
            else {
                props.onChange(props.settings.isMandatory, props.settings.fieldName, "", "", "", "", props.settings.listOutputFields, {}, "", true);
            }
        }
    }
    const handleLookUpselectedRow = (selectedData) => {
        const index = props.settings.listOutputFields.findIndex(f => f.controlName === props.settings.fieldName)
        if (index !== -1) {
            const reportFieldName = props.settings.listOutputFields[index].reportField;
            let selectedValue = selectedData[reportFieldName];
            selectedValue = selectedValue ?  selectedValue :' '
            setFieldValue(selectedValue)
             if (!props.isGrid) {
                 props.onChange(props.settings.isMandatory, props.settings.fieldName, selectedValue, props.settings.listOutputFields, selectedData,"","",true);
            }
            else {
                 props.onChange(props.settings.isMandatory, props.settings.fieldName, selectedValue, "", "", "", props.settings.listOutputFields, selectedData,"",true);
            }
        }
        setShowLookUpList(false);
    }

    const loadLookUpList = () => {
        if (!dataViewDefinition.reportId) {
            getReportDefinition();
        } else
        {
            if (props.settings.listParameterFields.length !== 0) {
                getReportData();
            }
            else
            {
                if (data && data.length === 1) {
                    handleLookUpselectedRow(data[0]);
                    return false;
                }
                else {
                    setShowLookUpList(true);
                }  
            }
        }
        return true;
    }
    const getReportDefinition = async () => {
        const reportId = props.settings.reportId;

        let url = `api/ReportDefinition/GetReportDefinition/${reportId}`;
        if (props.isPublic) {
            url = `api/ReportDefinition/GetPublicReportDefinition/${reportId}`;
        }


        const response = await fetch(url);
        if (response.status === 200) {
            const reportDefinition = await response.json();
            setDataViewDefinition(reportDefinition);
            getReportData();
        }
        else {
            console.log("Lookup list loading failed")
        }
    }

    const getReportData = async () => {
        const reportId = props.settings.reportId;
        let type = dataViewDefinition.reportDataSourceType
        let filterDataObject = [];
        for (let filterDefinition of props.settings.listParameterFields) {
            let value;
            if (!filterDefinition.isGrid) {
                value = props.dataModel[filterDefinition.controlName];
            } else {
                value = (!filterDefinition.isDetailTableField) ? props.headerdDataModel[filterDefinition.controlName] : props.dataModel[filterDefinition.controlName];
            }
            value = Number.isNaN(value) ? "" : value;
            filterDataObject.push({
                fieldName: filterDefinition.listField,
                condition: "startWith",
                value: value,
            });
        }

        let url = `api/ObjectData/GetReportData`;
        let filterData = cloneDeep(filterDataObject);
        if (props.isPublic) {
            url = `api/ObjectData/GetPublicReportData`;
        }

        const response = await fetch(url,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ReportId: reportId,
                    GetDataAboveThreshold: true,
                    FilterParameter: filterData.filter(function (fieldData, index, arr) {
                        
                        if (type === "storedProcedure") {
                            return fieldData
                        } else {

                            return ((fieldData.value !== "" && fieldData.value !== "*" && fieldData.value != null));
                        }
                    }),
                    
                })
            });
        if (response.status === 200) {
            const reportDataObject = await response.json();
            const reportData = DataUtil.parse.parseJson(reportDataObject.result);
            if (reportData.length == 1) {
                setData(reportData);
                handleLookUpselectedRow(reportData[0]);
            }
            else if (reportData.length == 0) {

                //setShowLookUpList(true);
                if (!props.isGrid)
                {
                    props.showMessageBox("No matching records found", false);
                }
                else
                {
                    props.showMessageBox(true);
                }
                setData(reportData);
                clearFieldValueOnLookUpCancel();
            }
            else
            {
                setShowLookUpList(true);
                setData(reportData);
            }
        }
        else {
            // props.showMessageBox("Data view  loading failed", "Error")
            console.log("Lookup list loading failed")
        }

    }

    const controlStyle = {
        color: props.settings.foreColor,
        background: props.settings.backGroundColor,
        width: "100%",
    }

    const textAlignStyle = {
        textAlign: props.settings.textAlign
    }
    let errorState = {};
    let requiredIndicator = "";
    let helperText = props.settings.helpText;
    if (props.settings.isMandatory) {
        requiredIndicator = " *";
        const validation = props.validationSchema.filter(field => field.fieldName === props.settings.fieldName && field.errorState)
        if (validation.length > 0) {
            errorState.error = true;
            helperText = `${props.settings.caption} is required`;
        }
    }
    const labelStyle = {
        color: (errorState.error) ? '#f44336' : props.settings.captionColor,
        zIndex: 1
    }
    const caption = !props.settings.caption ? ' ' : props.settings.caption;
    const helperTextStyle = { wordBreak: 'break-all' }

    return (
        <React.Fragment>
            {showLookUpList ? <LookUpList open={showLookUpList} data={data} handleClose={handleLookUpClose} handleSelectedRow={handleLookUpselectedRow}
                dataViewDefinition={dataViewDefinition} /> : null}
            <TextField
                autoFocus={props.settings.hasFocus}
                disabled={!props.settings.isEditable}
                onChange={handleChange}
                onBlur={handleBlur}
                multiline={(props.settings.controlType === Enums.ControlType.MultiText1) ? true : false}
                rows={(props.settings.controlType === Enums.ControlType.MultiText1) ? props.settings.rowSpan : 0}
                key={props.settings.fieldName}
                id={props.settings.fieldName}
                name={props.settings.fieldName}
                value={fieldValue}
                label={`${caption}${requiredIndicator}`}
                helperText={!helperText ? ' ' : helperText}
                {...errorState}
                FormHelperTextProps={{
                    style: helperTextStyle,
                }}
                InputProps={{
                    inputProps: {
                        style: textAlignStyle,
                        maxLength: props.settings.length,
                    },
                    style: controlStyle,
                    endAdornment: (
                        <InputAdornment position="end">
                            <div style={{zIndex:1.8}}>
                                {(props.settings.reportId !== 0) ?
                                    <IconButton id={`btn_${props.settings.fieldName}`} disabled={!props.settings.isEditable} tabIndex={-1} disableRipple={true} sx={{ p: 0 }} dataskip="true" onClick={loadLookUpList}>
                                        <FilterListIcon />
                                    </IconButton> : null
                                }
                            </div>
                        </InputAdornment>
                    ),
                }}
                InputLabelProps={{
                    style: labelStyle
                }}
            />
        </React.Fragment>
    );
}