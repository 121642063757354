/**
 * represents back end store
 */
let mockData = { 
    RecordID: 1,
    dataViewFields: [],
    subRecords: [],
    subRecordFields:[],
    linkData: [{ subRecordId: -1, linkFields: [{dataViewField:1,subRecordField:2}] }] 
};

/**
 * gets or generates subRecords and returns all
 */
const mockGetSubRecords = () => {
    console.log('mockGetsubRecords');
    if (mockData.subRecords.length === 0) {
        let subRecords = generateSubRecords();
        mockData.subRecords = subRecords;
    }
    return {
        data: [...mockData.subRecords],
    };
};

/**
 * gets or generates subRecords fields and returns all
 */
const mockGetSubRecordFields = () => {
    console.log('mockGetsubRecordFields'); 
    if (mockData.subRecordFields.length === 0) {
        let subRecordFields = generateSubRecordFields();
        mockData.subRecordFields = subRecordFields;
    }
    return {
        data: [...mockData.subRecordFields],
    };
};

/**
 * gets or generates DataView fields and returns all
 */

const mockGetDataViewFields = () => {
    console.log('mockGetDataViewFields');
    if (mockData.dataViewFields.length === 0) {
        let dataViewFields = generateDataViewFields();
        mockData.dataViewFields = dataViewFields;
    }
    return {
        data: [...mockData.dataViewFields],
    };
}; 

/**
 * Utility function to generator subRecords
 * @param {int} num 
 */
const generateSubRecords = (num = 10) => {
    console.log('generateSubRecords', num);
    let subRecords = [];
    for (let i = 0; i < num; i++) {
       subRecords.push({
            subRecordID: i + 1,
            subRecordName: "AutoGenSubRec" + (i + 1) 
       })
    } 
    return subRecords;
}; 

/**
 * Utility function to generator subRecords Fields
 * @param {int} num
 */
const generateSubRecordFields = (num = 10) => {
    console.log('generateSubRecordFields', num);
    let subRecordFields = [];
    for (let i = 0; i < num; i++) {
        subRecordFields.push({
            fieldID: i + 1,
            fieldName: "AutoGenSubRecField" + (i + 1)
        })
    };
    return subRecordFields;
};  

/**
 * Utility function to generator Data View Fields
 * @param {int} num
 */
const generateDataViewFields = (num = 10) => {
    console.log('generateDataViewFields', num);
    let dataViewFields = [];
    for (let i = 0; i < num; i++) {
        dataViewFields.push({
            fieldID: i + 1,
            fieldName: "DataViewFields" + (i + 1)
        })
    };
    return dataViewFields;
};  



export { mockGetSubRecords, mockGetSubRecordFields, mockGetDataViewFields};