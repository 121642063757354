import React from 'react';
import DataViewEdit from './DataViewEdit';
import QueryResult from './setup/QueryResult';

export default function () {
    return (
        <React.Fragment>
            <DataViewEdit />
            <QueryResult />
        </React.Fragment>
    );
}