import React from 'react';
import { connect } from 'react-redux';
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-react-grids/styles/material.css';
import '@syncfusion/ej2-react-navigations/styles/material.css';
import '@syncfusion/ej2-react-inputs/styles/material.css';
import { ColumnDirective, ColumnsDirective, GridComponent } from '@syncfusion/ej2-react-grids';
import { Inject, Search, Toolbar, Sort, VirtualScroll } from '@syncfusion/ej2-react-grids';
import { objectListFormatDate } from 'admin/components/Common//DateFormat';
import { ExportObjectType } from 'client/components/Common/Enum';
import SelectCheckbox from 'admin/components/section/package/SelectCheckbox';
import { Checkbox, Typography } from '@mui/material';

class ExportList extends React.Component {

    
    constructor(props) {
        super(props);
        
    }
    dateTemplate = (data) => {
        
        const field = data.column.field;
        const formatedDate = objectListFormatDate(data[field])
        return (
            <p>{formatedDate}</p>
            )
    }
    dependencyTemplate = (data) => {

        const dependecny = data["hasDependency"]? "Yes" : "No"
        return (
            <p>{dependecny}</p>
        )
    }
    checkChange = (data,checked) => {
        
        const item = { id: data["id"], objectType: data["objectType"], hasDependency: data["hasDependency"]}
        if (checked) {
            this.props.modifyItemList(item,true)
        }
        else
        {
            this.props.modifyItemList(item, false)
        }
    }
    sortSelectedItems = (sortType) => {
        let selectedItems = [];
        let nonSelectedItems = [];
        for (let i = 0; i < this.props.exportList.length; i++) {
            const data = this.props.exportList[i];
            const objectType = data["objectType"];
            switch (objectType) {
                case ExportObjectType.Menu: {
                    const index = this.props.exportPackage.menuDtos.findIndex(menu => menu.menuId === data["id"]);
                    (index >= 0) ?selectedItems.push(data):nonSelectedItems.push(data) ;
                    break;
                }
                case ExportObjectType.Form: {
                    const index = this.props.exportPackage.formDtos.findIndex(form => form.formId === data["id"]);
                        (index >= 0) ? selectedItems.push(data) : nonSelectedItems.push(data);
                    break;
                }
                case ExportObjectType.Dashboard: {
                    const index = this.props.exportPackage.dashboardDtos.findIndex(dashboard => dashboard.id === data["id"]);
                        (index >= 0) ? selectedItems.push(data) : nonSelectedItems.push(data);
                    break;
                }
                case ExportObjectType.Report: {
                    const index = this.props.exportPackage.dataViewDtos.findIndex(report => report.reportId === data["id"]);
                        (index >= 0) ? selectedItems.push(data) : nonSelectedItems.push(data);
                    break;
                }
                case ExportObjectType.User: {
                    const index = this.props.exportPackage.userDtos.findIndex(user => user.userId === data["id"]);
                        (index >= 0) ? selectedItems.push(data) : nonSelectedItems.push(data);
                    break;
                }
                case ExportObjectType.Filter: {
                    const index = this.props.exportPackage.filterDtos.findIndex(filter => filter.profileId === data["id"]);
                        (index >= 0) ? selectedItems.push(data) : nonSelectedItems.push(data);
                    break;
                }
                case ExportObjectType.Role: {
                    const index = this.props.exportPackage.roleDtos.findIndex(role => role.roleId === data["id"]);
                        (index >= 0) ? selectedItems.push(data) : nonSelectedItems.push(data);
                    break;
                }
                
                
                default: {

                    break;
                }
            }
        }
        if (sortType == "Ascending") {
            return [...selectedItems, ...nonSelectedItems]
        }
        else {
            return [...nonSelectedItems, ...selectedItems]
        }
    }
    selectTemplate = (data) => {
        let checked = false;
        const objectType = data["objectType"]
        switch (objectType) {
            case ExportObjectType.Menu: {
                const index = this.props.exportPackage.menuDtos.findIndex(menu => menu.menuId === data["id"])
                checked = (index >= 0);
                break;
            }
            case ExportObjectType.Report: {
                const index = this.props.exportPackage.dataViewDtos.findIndex(report => report.reportId === data["id"])
                checked = (index >= 0);
                break;
            }
            case ExportObjectType.Form: {
                const index = this.props.exportPackage.formDtos.findIndex(form => form.formId === data["id"])
                checked = (index >= 0);
                break;
            }
            case ExportObjectType.Dashboard: {
                const index = this.props.exportPackage.dashboardDtos.findIndex(dashboard => dashboard.id === data["id"])
                checked = (index >= 0);
                break;
            }
            case ExportObjectType.Role: {
                const index = this.props.exportPackage.roleDtos.findIndex(role => role.roleId === data["id"])
                checked = (index >= 0);
                break;
            }
            case ExportObjectType.User: {
                const index = this.props.exportPackage.userDtos.findIndex(user => user.userId === data["id"])
                checked = (index >= 0);
                break;
            }
            case ExportObjectType.Filter: {
                const index = this.props.exportPackage.filterDtos.findIndex(filter => filter.profileId === data["id"])
                checked = (index >= 0);
                break;
            }
            default: {

                break;
            }
        }

        return (
            <SelectCheckbox checked={checked} data={data}
                checkChange={this.checkChange} />
            );
    }
    objectTypeTemplate = (objectType,data) => {
        return (
            <Typography variant="body2"> {objectType[data.objectType]} </Typography>
        );
    }
    headerCheckChange = (event) => {
        this.props.selectAll(event.target.checked);
    }
    selectHeaderTemplate = (props) => {
        return (
            <Checkbox sx={{marginTop:"-4px"}} checked={this.props.exportPackage.selectAll}
                onChange={this.headerCheckChange} />
        );
    }
    gridCreated() {
        const parElement = this.grid.element;
        const searchElement = parElement.querySelector('.e-search');
        searchElement.oninput = (event) => {
            this.grid.search(event.target.value);
        }
        const emptyRecord = parElement.querySelector('.e-emptyrow');
        if (emptyRecord && this.props.isLoading) {

            emptyRecord.firstChild.innerText = "Data Loading"
        }

    }
    actionBegin(action) {

        if (action.requestType === "sorting" && action.columnName === "Select") {

            
            if (action.direction === "Ascending") {
                const selectFirst = this.sortSelectedItems(action.direction);
                this.grid.dataSource = selectFirst;
            }
            else if (action.direction === "Descending") {
                const nonSelectFirst = this.sortSelectedItems(action.direction);;
                this.grid.dataSource = nonSelectFirst;
            }
            else
            {
                this.grid.dataSource = this.props.exportList;
            }
            
        }

    }
    
    setSortOptions() {
        return [
            { field: "sortingOrder", direction: "Ascending" },
            { field: "categoryName", direction: "Ascending" },
            { field: "name", direction: "Ascending" },
        ]
    }
    render() {
        const toolbarOptions = [{ text: 'Search', align: 'Left' }];
        const sortSettings = this.setSortOptions();
        const objectType = {report: "Data View",form:"Form",dashboard:"Dashboard",menu:"Menu",filter:"Account Filter",role:"Role",user:"User"}
        return (
            <GridComponent dataSource={this.props.exportList} height={600} toolbar={toolbarOptions} allowSorting={true} enableVirtualization={this.props.exportList.length>50?true:false}
                ref={grid => this.grid = grid}
                created={this.gridCreated.bind(this)}
                actionBegin={this.actionBegin.bind(this)}
                sortSettings={sortSettings}
                pageSettings={{ pageSize: 50 }}>
                <Inject services={[VirtualScroll, Search,Sort, Toolbar]} />
                <ColumnsDirective>
                    <ColumnDirective field='Select' headerText='id' width='120' template={this.selectTemplate.bind(this)} headerTemplate={this.selectHeaderTemplate.bind(this)} />
                   
                    <ColumnDirective field='name' headerText='Name' width='140' />
                    
                   {/* <ColumnDirective field='objectType' headerText='' width='120' template={this.objectTypeTemplate.bind(this, objectType)} />*/}
                    <ColumnDirective field='displayObjectType' headerText='Type' width='120'  />
                    <ColumnDirective field='objectSubType' headerText='Sub-type' width='120'  />
                    <ColumnDirective field='categoryName' headerText='Category' width='120' />
                    <ColumnDirective field='hasDependency' headerText='Dependencies' width='120' textAlign="center" template={this.dependencyTemplate.bind(this)}  />
                    <ColumnDirective field='createDate' headerText='Created' width='120' template={this.dateTemplate.bind(this)}  />
                    <ColumnDirective field='modifyDate' headerText='Modified' width='120' template={this.dateTemplate.bind(this)}  />
                   
                </ColumnsDirective>
            </GridComponent>
            )
    }
}
function mapStateToProps(state) {

    return {
        exportList: state.packages.exportListItems,
        exportPackage: state.packages.package,
       
    };
};

export default connect(mapStateToProps)(ExportList);