import { FontStyle } from '@syncfusion/ej2-react-pdfviewer';
import { theme, customThemeStyles } from 'common/theme.js';
export const homeTheme = () => ({
    ...customThemeStyles,

    newsMore: {
        color: customThemeStyles.customTheme.backgroundColor,
        textDecoration: "none",
        paddingLeft: "10px",
        fontSize:"14px"

    },
    newsCalendar: {
        background: customThemeStyles.customTheme.backgroundColor,
        fontSize: '16px',
        lineHeight: 'h',
        height: '21px',
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#fff',
        borderRadius: '4px 4px 0 0',
    },

    article: {
        padding: "36px",
        [theme.breakpoints.down('md')]: {
            paddingLeft: "5px",
            paddingRight: "0px",
        },
    },
    articleHead: {
        padding: "0 0 6px"
    },

    articleBody: {
        overflow: "hidden",
      
    },
    articleDate: {
        float: "left",
        width: "70px",
        marginRight: "20px",
        fontFamily: " 'Gibson', 'Arial', 'Helvetica', sans - serif"
    },

    articleDateDay: {
        background: "#F8F9F9",
        fontSize: "40px",
        lineHeight: "50px",
        padding: "4px 0 0",
        color: "#5F6871",
        textAlign: " center",
        borderRadius: "0 0 4px",
        boxShadow: " 0 1px 0 rgba(0, 0, 0, .07)"
    },

    feedIcon: {

        color: customThemeStyles.customTheme.backgroundColor,
        background: "#fff",
      

    },

    towerIcon: {

        //color: customThemeStyles.customTheme.backgroundColor,
        background: "#fff",
        fontSize: "28px"

    },

    title: {

        fontWeight: 500,
        fontSize: 20,
        marginLeft: 10
    },

    section: {
        borderTop: "1px solid rgba(75, 96, 116, .5)",
        /*  marginTop:" 26px",*/

        position: "relative",
        marginTop: "6px;",

    },

    sectionIndent: {
        paddingLeft: "24px",
        paddingRight: "24px",
        [theme.breakpoints.down('md')]: {
            paddingLeft: "5px",
            paddingRight: "0px",
        },
        
    },

    sectionHead: {
        margin: 0,
        padding: "0 24px 10px",
        position: "relative",
        borderBottom: "1px solid rgba(75, 96, 116, .15)",
        overflow: "hidden",
        marginLeft: "68px",
        marginRight: "68px",
        marginTop: "54px",
        paddingLeft: "0px",
        paddingRight: "0px",
        [theme.breakpoints.down('md')]: {
            marginLeft: "1px",
            marginRight: "1px",
        },
    },
    sectionHeadAside: {
        float: "right",
        fontFamily: "'Gibson', 'Arial', 'Helvetica', sans - serif",
        fontSize: "11px",
        padding: "0 18px 0 0",
        color: "#989FA6",
        [theme.breakpoints.down('md')]: {
            float: "left",
            paddingLeft: "15px",
        },
        [theme.breakpoints.only('xs')]: {
          //  paddingLeft: "15px",
        },
    },
    sectionBody: {
        paddingLeft: "28px",
        paddingRight: "28px;",
        [theme.breakpoints.down('md')]: {
            paddingLeft: "5px",
        },
    },

    articleItem: {
        paddingLeft: "10px"
    },
    articleMeta: {
        color:" #989FA6",
        letterSpacing: "1px",
        fontSize: "11px",
        fontWeight:"normal"
    },

    feedSubject: {
        lineHeight: 1.2,
        fontSize: "24px",
        fontWeight: "normal"
    },

      feedContent: {
        lineHeight: "26px",
        fontSize: "15px",
          fontWeight: "normal",
          margin: "0px 0px 10px",
          
        
        
    },
    imageContiner: {
        float: "left",
        /*paddingRight:"20px"*/
    },

    summaryContainer: {

        overflow: "hidden", textOverflow: "ellipsis", maxWidth: '29rem', whiteSpace: 'nowrap'
    },
    feedHead: {

        marginTop:"14px"
    },

    feedUpdateText: {
        fontSize:"11px!important"
    },
    feedUpdateDate: {
        paddingLeft: "5px"
    },

    feedContainer: {
        [theme.breakpoints.down('xl')]: {
            width: "100%",
            textAlign: "right",
        },
        [theme.breakpoints.down('md')]: {
            textAlign: "left",
        },
    },

    towerIcon: {
        [theme.breakpoints.down('md')]: {
            paddingLeft: "5px",
        },
    },

    LoadingStyle: {
        display: 'flex',
        color: customThemeStyles.customTheme.backgroundColor,
        justifyContent: 'center',
        paddingTop:'20px'
    },
    BackdropLoadingStyle: {
        color: customThemeStyles.customTheme.backgroundColor,
        zIndex: 100000,
    },

})