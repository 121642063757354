import React from 'react';
 
import ProcessingDetails from './ProcessingDetails';

import { withStyles } from '@mui/styles';
/*import Grid from '@mui/material/Grid';*/
import MUIDataTable from "mui-datatables";

import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-react-grids/styles/material.css';
import '@syncfusion/ej2-react-navigations/styles/material.css';
import '@syncfusion/ej2-react-inputs/styles/material.css';
import { Internationalization } from '@syncfusion/ej2-base';
import '../theme/dataList.css';
import {
    ColumnDirective, ColumnsDirective, GridComponent, Resize, Reorder, ColumnMenu, Sort, Group, InfiniteScroll, Toolbar, ExcelExport, PdfExport,
    Inject, Aggregate, AggregateColumnsDirective, AggregateColumnDirective, AggregateDirective, AggregatesDirective
} from '@syncfusion/ej2-react-grids';
import { Box, Grid, Typography } from '@mui/material';

import { getDeviceType } from 'client/components/Common/Utility';
import * as Enums from 'client/components/Common/Enum';
import ColumnTemplate from '../ColumnTemplate';
import { dataListTheme } from '../theme/dataListTheme';
import { cloneDeep } from 'lodash';
import { formatValue } from 'client/components/Common/Utility';

 
const intl = new Internationalization();
 
class submitProcessing extends React.Component {
     
     
    renderProcessingDetails = () => {
        
        if (this.props.recordProcessingDetails.isProcessPayments) {
            return (
                <ProcessingDetails ref="payform" showOption={this.props.showOption} paymentSetting={this.props.recordProcessingDetails.recordProcessingDataDTO.paymentSettings}
                    recordProcessOutput={this.props.recordProcessOutput} handleWizardInputData={this.props.handleWizardInputData} handleWizardInputBlur={this.props.handleWizardInputBlur}
                    recordProcessingDetails={this.props.recordProcessingDetails}
                     validationObject={this.props.validationObject} />
            );
        }
        return null;
    }
    
    renderPaymentSummary = () => { 
        return (
            <Box pl={3}   >
                <Grid container  >
                    {this.props.recordProcessingDetails.hasConfirm &&
                        <Grid item md={this.props.recordProcessingDetails.isProcessPayments ? 6 : 8} xs={12} mb={ 2}>
                         <div id="selrows">
                            <Box pb={3} mt={.5}>
                                <Typography variant="body1"> Selections</Typography>
                                
                            </Box>
                            <div id="confirmtablesection"><div id="cnfrmTab_wrapper" className="dataTables_wrapper">
                                {/*<Box pl={this.props.recordProcessingDetails.isProcessPayments ? 0 : 50}>*/}
                                <Box >
                                    {this.renderPaymentSummaryDataTable()}
                                </Box>
                            </div></div> 
                        </div>
                        </Grid>}
                       <Grid item md={.5} xs={.5}  />
                        <Grid item md={this.props.recordProcessingDetails.isProcessPayments ? 4.5 : 0} xs={12}   >
                        <Box className="processing_Details">
                            {this.props.recordProcessingDetails.isProcessPayments && this.renderProcessingDetails()}
                         </Box>
                    </Grid>
                </Grid>
            </Box>
        );
    }
    formatValue_old = (column, value) => {
        let format;
        if (value) {
            switch (column.fieldFormat) {
                case Enums.FormatType.Decimal1:
                    {
                        const newValue = intl.formatNumber(value, {
                            format: 'N1'
                        });
                        format = (column.culture === '1.000,0') ? this.setCulture(newValue) : newValue;
                        break;
                    }
                case Enums.FormatType.Decimal2:
                    {
                        const newValue = intl.formatNumber(value, {
                            format: 'N2'
                        });
                        format = (column.culture === '1.000,00') ? this.setCulture(newValue) : newValue;
                        break;
                    }
                case Enums.FormatType.Decimal3:
                    {
                        const newValue = intl.formatNumber(value, {
                            format: 'N3'
                        });
                        format = (column.culture === '1.000,000') ? this.setCulture(newValue) : newValue;
                        break;
                    }
                case Enums.FormatType.Decimal4:
                    {
                        const newValue = intl.formatNumber(value, {
                            format: 'N4'
                        });
                        format = (column.culture === '1.000,0000') ? this.setCulture(newValue) : newValue;
                        break;
                    }
                case Enums.FormatType.Currency:
                    {
                        const code = this.setCurrencyCode(column.culture);
                        const newValue = intl.formatNumber(value, {
                            format: 'C2', currency: code

                        });
                        format = this.setSymbols(newValue, code);
                        format = (column.culture === 'es-Chile' || column.culture === 'es-Argentina')
                            ? this.setCulture(format)
                            : format;
                        break;
                    }
                case Enums.FormatType.Date:
                case Enums.FormatType.DateTime:
                case Enums.FormatType.Time:
                    {
                        format = intl.formatDate(new Date(value), { format: column.culture });
                        break;
                    }
                default:
                    {
                        format = value;
                        break;
                    }
            }
        }
        return format;
    }
    setCulture = (value) => {
        return value.replace(',', '/').replace('.', ',').replace('/', '.');
    }
    componentDidMount() {
        
    }

    setCurrencyCode = (culture) => {
        let code;
        switch (culture) {
            case 'en-Canada':
            case 'en-New Zealand':
            case 'en-Australia':
            case 'es-Argentina':
            case 'es-Chile':
            case 'es-Mexico':
                code = 'USD';
                break;

            case 'es-Panama':
                code = 'PAB';
                break;

            case 'pt-Brazil':
                code = 'BRL';
                break;

            case 'en-UK':
                code = 'GBP';
                break;

            case 'kok-India':
                code = 'INR';
                break;

            case 'de-Germany':
            case 'es-Spain':
            case 'nl-Netherlands':
                code = 'EUR';
                break;

            default:
                code = 'USD';
                break;

        }
        return code;
    }

    setSymbols = (value, code) => {
        let newValue;
        switch (code) {
            case 'EUR':
                newValue = this.setCulture(value);
                break;

            case 'INR':
                newValue = value.replace('$', '₹');
                break;

            case 'BRL':
                newValue = this.setCulture(value.replace('$', 'R$'));
                break;

            case 'PAB':
                newValue = value.replace('$', 'B/.');
                break;

            default:
                newValue = value;
                break;


        }
        return newValue;
    }
    getFooterTemplate = () => {

        if (this.props.recordProcessingDetails.isProcessPayments) {
            return (<div key={this.props.recordProcessingDetails.editableFieldId + '_PagefooterTotalLabel'} style={{ textAlign: this.isMobileDevice() ? 'left' : 'right', "padding-right": '10px' }}> Total Sum - {this.props.recordProcessingDetails.editableFieldId} - : {this.props.dataSum}</div>);
        }
        return null;
    }
    //Sync funsion grid
    template = (column, data) => { 
        return (<ColumnTemplate data={data} column={column} showErrorMessage={this.props.showErrorMessage} formatValue={formatValue} />);
    }
    setAggregates() {
        let aggregates = [];
         this.props.dataDefinition.map(column => {
            if (column.fieldName === this.props.recordProcessingDetails.editableFieldId) {
                aggregates.push(<AggregateColumnDirective field={column.fieldName} type='Sum' footerTemplate={this.footerSum.bind(this, column)} ></AggregateColumnDirective>)
            }
        }); 
        const value = aggregates.find(obj => obj !== null); 
        if (value) {
            return (
                <AggregatesDirective>
                    <AggregateDirective>
                        <AggregateColumnsDirective>
                            {aggregates}
                        </AggregateColumnsDirective>
                    </AggregateDirective>
                </AggregatesDirective>
            );
        }
        else
            return null;
    }

    footerSum(column, props) {
        let columnData = cloneDeep(column);
        if (columnData.fieldFormat === Enums.FormatType.None) {
            columnData.fieldFormat = Enums.FormatType.Decimal2
        }

        let value = "";
        if (props.Sum !== " ") {
            value = formatValue(columnData, props.Sum);
        }
        return (<span>Sum: {value}</span>);
    }
    getColumns = () => {
       // console.log("sub Process getColumns");
        let isEditableColumnAdded = false;
        let editableColumn = null;
        let displayColumns = this.props.recordProcessingDetails.displayFieldId.split(",");
        let columns = this.props.dataDefinition.map(column => {
           // if (column.isVisible) {
                for (let showColumn of displayColumns) {
                    if (column.fieldName === showColumn) {
                        if (column.fieldName === this.props.recordProcessingDetails.editableFieldId) { isEditableColumnAdded = true; }
                        /* const col = { name: column.fieldName, label: column.fieldName }*/
                        /*showColumnMenu={column.isDisplayTitle} headerText={column.isDisplayTitle ? column.displayFieldName : ' '}*/
                        return (<ColumnDirective field={column.fieldName} showColumnMenu={column.isDisplayTitle} headerText={  column.displayFieldName }
                            width={column.columnWidth} textAlign={this.isMobileDevice() ? 'left' : column.textAlign}
                            template={this.template.bind(this, column)} ></ColumnDirective>);
                    }
                }
                if (this.props.recordProcessingDetails.editableFieldId === column.fieldName && isEditableColumnAdded === false) {
                    //console.log("sub Process getColumns editable");
                    editableColumn = column;
                }

            //}
        });
        if (editableColumn != null) {
            //console.log("sub Process getColumns push");
            columns.push(<ColumnDirective field={editableColumn.fieldName} showColumnMenu={editableColumn.isDisplayTitle}
                headerText={editableColumn.isDisplayTitle ? editableColumn.displayFieldName : ' '} width={editableColumn.columnWidth} textAlign={this.isMobileDevice() ? 'left' : editableColumn.textAlign}
                template={this.template.bind(this, editableColumn)} ></ColumnDirective>)
        }

        return (
            <ColumnsDirective>
                {columns}
            </ColumnsDirective>
        );
    }
    isMobileDevice = () => {
        const  deviceType = getDeviceType();
        return deviceType !== Enums.DeviceType.Desktop ? true : false;
       
    }
    handleResize = () => {
       

    }
    renderPaymentSummaryDataTable = () => { 
        const columns = this.getColumns(); 
        const aggregates = this.setAggregates();
        const isDevice = this.isMobileDevice();
        const renderingMode = (isDevice ? 'Vertical' : 'Horizontal');
        const classes = this.props.classes;
       
        //console.log("Submit Processing aggregates", aggregates);
        return ( 
            <Box height="75%" >
                <div className={isDevice ? "e-bigger  " : " "}  >
                    <div className={classes.summaryTable}>
                    <GridComponent style={{ maxHeight: "686px", overflowY: "auto" }}   dataSource={this.props.selectedData} ref={grid => this.grid = grid}
                    //created={this.gridCreated.bind(this)}
                    //dataBound={this.dataBound.bind(this)}
                    showColumnMenu={false} allowResizing={false} allowGrouping={false} allowSorting={false}
                    //enableInfiniteScrolling={(aggregates) ? false : true}
                    allowExcelExport={false} allowPdfExport={false}
                    allowReordering={false}
                    allowTextWrap={true}
                //toolbar={this.toolbarOptions}
                //toolbarClick={this.toolbarClick.bind(this)}
                //groupSettings={groupSettings}
                //sortSettings={sortSettings}
                   enableAdaptiveUI={isDevice }
                   rowRenderingMode={renderingMode}
                >
                    {columns}
                    {this.props.recordProcessingDetails.hasAggregate && aggregates}
                    <Inject services={[Toolbar, Group, Sort, InfiniteScroll, ColumnMenu, Resize, Reorder, PdfExport, ExcelExport, Aggregate]} />
                        </GridComponent>
                        </div>
                 </div>
          </Box>

        );
    }

    render() {
        return this.renderPaymentSummary()
    }
}
export default withStyles(dataListTheme)(submitProcessing);