import { ServerErrorCode } from 'client/components/Common/Enum';
import Cookies from 'universal-cookie';
const requestLogin = 'REQUEST_LOGIN';
export const receiveLogin = 'RECEIVE_LOGIN';
const requestSignOut = 'REQUEST_SIGNOUT';
const receiveSignOut = 'RECEIVE_SIGNOUT';

const requestUser = 'REQUEST_USER';
const receiveUser = 'RECEIVE_USER';
const receiveImage = 'RECEIVE_IMAGE';
const loginError = 'LOGIN_ERROR';
const invalidAuth = "INVALID_AUTH";
const cookies = new Cookies();
const initialState = { message: "", roles: [], roleId: 0, loading: false, isLogin: false, firstName: '', roleName: '', hasAdmin: false, profileImage: '' };
export const loginActionCreators = {

    requestLogin: (emailId, password, rememberMe) => async (dispatch, getState) => {


        dispatch({ type: requestLogin, emailId, password });
        const response = await fetch('api/Login/SignIn',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    Username: emailId,
                    Password: password,
                    RememberMe: rememberMe,
                })
            });

        const loginResult = await response.json();
        dispatch({ type: receiveLogin, loginResult, status: response.status });
        if (response.status === ServerErrorCode.SuccessStatus) {
            dispatch({
                type: 'RECEIVE_LAYOUT',
                isLogin: true,
                roleId: loginResult.roleId,
                sessionTimeOut: loginResult.sessionTimeOut,
                actionSource: 'SignIn',
                isLoading: false
            });
        } else if (response.status !== ServerErrorCode.BadRequestStatus) {

            dispatch({
                type: "LOGIN_ERROR"

            });

            dispatch({
                type: "SHOW_ERROR",
                message: "Login failed."
            });

        }
    },
    requestSignOut: () => async (dispatch, getState) => { 

        dispatch({ type: requestSignOut });
        const url = `api/Login/SignOut`;
        const response = await fetch(url);
        dispatch({
            type: receiveSignOut, status: response.status
        });

        if (response.status === ServerErrorCode.SuccessStatus) {
            cookies.remove("Versago");
            cookies.remove("roleId");
            //console.log("Document cookie signout success", document.cookie);
            dispatch({
                type: 'RECEIVE_LAYOUT',
                isLogin: false,
                roleId: 0,
                actionSource: 'SignOut',
                isLoading: false
            });
        } else {
            dispatch({
                type: "LOGIN_ERROR"

            });
            dispatch({
                type: "SHOW_ERROR",
                message: "Sign Out failed."
            });

        }


    },
    requestCheckUser: (roleId) => async (dispatch, getState) => {
        dispatch({ type: requestUser });

        const url = `api/Login/CheckUser/${roleId}`;
        const response = await fetch(url);
        if (response.status === ServerErrorCode.SuccessStatus) {
            const responseUser = await response.json();
            dispatch({
                type: receiveUser,
                responseUser,
                status: response.status
            });
        }
        else if (response.status === ServerErrorCode.UnauthorizedStatus) {
            dispatch({
                type: invalidAuth,
                authAction: { auth: false, actionName: "CheckUser" }
            });
        }
        else {
            dispatch({
                type: "LOGIN_ERROR"

            });
            dispatch({
                type: "SHOW_ERROR",
                message: "Loading user details failed."
            });
        }

    },
    resetLayout: (roleId) => async (dispatch, getState) => {
        dispatch({
            type: 'RECEIVE_LAYOUT',
            isLogin: true,
            actionSource: 'Reset',
            roleId: roleId
        });

    }
};

export const unauthorizedMessage = "The username or password entered does not match our records";
export const errorMessage = "An error has occurred";

export const reducer = (state, action) => {

    state = state || initialState;
    if (action.type === requestLogin) {
        return {
            ...state,
            loading: true,
            message: ""

        };
    }

    if (action.type === receiveLogin) {
        if (action.status === ServerErrorCode.SuccessStatus) {
            return {
                ...state,
                isLogin: action.loginResult.isLogin,
                roles: action.loginResult.roles,
                roleId: action.loginResult.roleId,
                message: action.loginResult.message,
                firstName: action.loginResult.firstName,
                roleName: action.loginResult.roleName,
                hasAdmin: action.loginResult.hasAdmin,
                loading: false,
                profileImage: action.loginResult.profileImage,
            };
        } else if (action.status === ServerErrorCode.BadRequestStatus) {
            return {
                ...initialState,
                message: unauthorizedMessage,
            };
        }
        else {
            return {
                ...initialState,
                message: errorMessage,
            };
        }

    }


    if (action.type === requestSignOut) {
        return {
            ...state,
            loading: true
        };
    }

    if (action.type === receiveSignOut) {
        if (action.status === ServerErrorCode.SuccessStatus) {

            return {
                ...initialState

            };
        } else {

            return {
                ...state,
                loading: false
            };

        }

    }

    if (action.type === requestUser) {
        return {
            ...state,
        };
    }

    if (action.type === receiveUser) {
        if (action.status === ServerErrorCode.SuccessStatus) {
            return {
                ...state,
                isLogin: action.responseUser.isLogin,
                roles: action.responseUser.roles,
                roleId: action.responseUser.roleId,
                message: action.responseUser.message,
                firstName: action.responseUser.firstName,
                roleName: action.responseUser.roleName,
                hasAdmin: action.responseUser.hasAdmin,
                loading: false,
                profileImage: action.responseUser.profileImage

            };
        } else {

            return {
                ...state,
                loading: false

            };
        }

    }
    if (action.type === receiveImage) {

        return {
            ...state,
            firstName: action.firstName,
            profileImage: action.profileImage


        };


    }

    if (action.type === loginError) {

        return {
            ...initialState,
            loading: false
        };


    }

    return state;


};