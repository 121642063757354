import React from 'react';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import Company from './Company.js';
import ProfileWrapper from './ProfileWrapper.js';
import Nav from './Nav.js';
import { navData } from './navData.js';
import { mainTheme } from 'common/theme.js';
import { withStyles } from '@mui/styles';
import { MenuItemTheme } from './theme/MenuItemTheme.js';
import {Divider } from '@mui/material';
class Aside extends React.Component {
    constructor(props) {
        super(props);
        this.state = { collapsed: false, expandMenu: "" };

    }

    toggleHeader = (event) => {
        event.preventDefault();
        if (this.state.collapsed) {
            this.setState({ collapsed: false });

        } else {
            this.setState({ collapsed: true });

        }
    }
   
     render() {
         const { classes } = this.props;
         
        return (
            this.state.collapsed ? <Box bgcolor="primary.main" color="primary.contrastText"  className={`${classes.customTheme} ${classes.burgerIcon} ${classes.burgerCollapsed}`}>
                <MenuIcon  className={classes.burgerIconRoot} onClick={this.toggleHeader}></MenuIcon>
            </Box>:
                <Box bgcolor="primary.main" color="primary.contrastText" height="100%" width="302px">

                
                    <Box ><MenuIcon  className={`${classes.burgerIconRoot} ${classes.burgerExpanded}`} onClick={this.toggleHeader}></MenuIcon>
                <Company isAdmin={true}></Company>
                </Box>
                    <ProfileWrapper isAdmin={true} ></ProfileWrapper>
                    <Divider sx={{ borderBottomWidth: 2.2,marginTop:"2px" }} />
                <Nav isAdmin={true} color={mainTheme.palette.primary.contrastText} backgroundColor={mainTheme.palette.primary.main} navData={navData}></Nav>
                </Box>
                );
           
    }
}
export default (withStyles(MenuItemTheme)(Aside))
