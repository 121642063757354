import * as yup from "yup";

export const validateObject = async (schema,objectData) => {
    let validationErrors = {};

    const validationError = await schema
        .validate(objectData, { abortEarly: false })
        .catch((errors) => {
            if (errors.inner) {
                errors.inner.forEach(error => {
                    if (error.path) {
                        validationErrors[error.path] = error.message;
                    }

                });
            }
        });
    if (Object.keys(validationErrors).length === 0) {
        return true;
    }
    else {
        return validationErrors;
    }
}
export const validateField = async (schema,field, value, path, dependencyFields) => {
    const parameters = { context: dependencyFields };
    let validationErrors = {};
    validationErrors[field] = undefined;
    const validationError = await yup.reach(schema, path)
        .validate(value, parameters)
        .catch((error) => {
            return error;
        });
    if (validationError.message) {
        validationErrors[field] = validationError.message
    }
    return validationErrors;

}

export const validateObjectAt = async (schema, objectData,path,field) => {
    let validationErrors = {};

    const validationError = await schema
        .validateAt(path,objectData)
        .catch((error) => {
            return error;
        });
    if (validationError.message) {
        validationErrors[field] = validationError.message
    }
    else {
        validationErrors[field] = "";
    }
    return validationErrors;


}