import React from 'react';
import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import archiveImage from 'client/assets/css/images/icon-news-arch.png';
import { Link } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { contentLayoutTheme } from 'client/components/ContentLayout/theme/contentLayoutTheme'
import { Box } from '@mui/material';
let categoryCount = 0;
class archiveContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = { categoryState: [] };
    }
    toggleCategoryState = (index, classes) => {
        if (this.state.categoryState.length === 0) {
            let newCategoryState = [];
            for (let i = 0; i < categoryCount; i++) {
                if (i === (index - 1)) {
                    newCategoryState.push({ closed: false, iconStyle: classes.archiveToggleCurrent });
                } else {
                    newCategoryState.push({ closed: true, iconStyle: classes.archivetoggle });
                }
            }

            this.setState({ categoryState: newCategoryState });
        } else {

            let currentCategoryState = this.state.categoryState;
            if (currentCategoryState[(index - 1)].closed) {
                currentCategoryState[(index - 1)].closed = false;
                currentCategoryState[(index - 1)].iconStyle = classes.archiveToggleCurrent;
            } else {
                currentCategoryState[(index - 1)].closed = true;
                currentCategoryState[(index - 1)].iconStyle = classes.archivetoggle;
            }
            this.setState({ categoryState: currentCategoryState });
        }

    }

    renderContentList(contents, classes) {
        
        const categoryList = contents.map((category) => {
                    categoryCount++;
                    let index = categoryCount;
            return (
                <li key={index}>
                    <span className={this.state.categoryState.length === 0 ? classes.archivetoggle : this.state.categoryState[(index - 1)].iconStyle} onClick={() => this.toggleCategoryState(index, classes)} >
                        {category.category}
                    </span>
                    <SlideDown closed={this.state.categoryState.length === 0 ? true : this.state.categoryState[(index - 1)].closed}  >
                        {this.renderContentItem(category.contentList, classes)}
                </SlideDown>
                </li>

        );

    });

    return (
            <li>
            <header className={classes.widgetHead}>
                <h4><img alt="" src={archiveImage} />Archives</h4>
                </header>
            <Box pl={6.9 } className={classes.widgetBody} >
                <nav className={classes.archive}>
                    <ul className={classes.archiveUl}>{categoryList}</ul>
                    </nav>
                </Box>
            </li>
);
}
    renderContentItem(contentItems, classes) {
    const items = contentItems.map(function getContentItems(item) {
        return (<li key={item.id}><Link className={classes.recentLink} to={"/Content/" + item.id + "/" + item.isPreLogin}>{item.subject}</Link></li>);
    });

        return (<ul className={`${classes.archiveBody} ${classes.activeBodyContainer}`}>{items}</ul>);
}


    render() {
        const classes = this.props.classes;
        categoryCount = 0;
        return (
            <div>
                <ul id="contentpanelbar1" className={classes.widgets}>
                    {this.renderContentList(this.props.contentList,classes)}
                </ul>
                    </div> 
                );
    }
}

export default (withStyles(contentLayoutTheme)(archiveContent));;