import React from 'react';

import { styled } from '@mui/styles';
import { withStyles } from '@mui/styles';
import { spacing } from '@mui/system';
import { Box, Grid, Typography } from '@mui/material';
import MuiButton from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { updateMenu } from 'admin/actions/menuActions';
import { discardRole } from 'admin/actions/roleActions';
import { menuTheme } from './theme/menuTheme.js';
import { ActionTypes as types } from 'admin/actions/actionTypes';
import ActionBackdrop from 'admin/components/section/settings/Backdrop';


const Button = styled(MuiButton)(spacing);

class MenuEditHeader extends React.Component {

    constructor(props) {
        super(props);
        this.isUpdate = this.props.match.params.menuId !== "new";
    }
    componentDidUpdate(prevProps) {
        this.isUpdate = this.props.match.params.menuId !== "new";
        if (this.props.menu.menuId > 0 && (this.props.menu.menuId !== prevProps.menu.menuId)) {
            this.props.history.push(`/Admin/Menus/${this.props.menu.menuId}`);
        }
    }

    componentWillUnmount() {
        this.props.dispatch(discardRole());
    }

    onDiscardClick = () => {
        this.props.history.push('/Admin/Menus');
    }

    onSaveClick = () => {
        this.props.dispatch(updateMenu(this.props.menu));
    }

    render() {
        let alert = this.props.alert;
        return (
            <Grid container direction="row" justifyContent="space-between" spacing={0} xl={12}>
                <Grid item>
                    <Typography variant="h4">{this.isUpdate ? "Edit" : "Add"} Menu</Typography>
                    {this.props.actionType === types.VGO_UPDATE_MENU_REQUEST && <ActionBackdrop />}
                </Grid>

                <Grid item >
                    <Grid container direction="row" justifyContent="space-between" spacing={1} >
                        <Grid item >
                            <Button
                                className={this.props.classes.customControlButton}
                                variant="defaultContained"
                                disableElevation
                                startIcon={""}
                                onClick={this.onDiscardClick}
                            >CANCEL</Button>
                        </Grid>
                        <Grid item >
                            <Button
                                className={this.props.classes.customControlButton}
                                color="primary"
                                variant="contained"
                                disableElevation
                                startIcon={<CheckIcon />}
                                onClick={this.onSaveClick}
                            >SAVE</Button>
                        </Grid> </Grid>
                </Grid>
            </Grid>
        );
    }

}

function mapStateToProps(state) {
    let props = {
        menu: state.menus.menu,
        alert: state.alert,
        actionType: state.loader.type,
    };
    return props;
};

export default withRouter(connect(mapStateToProps)(withStyles(menuTheme)(MenuEditHeader)));
