import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
class ConfirmBox extends React.Component {
    constructor(props) {
        super(props)
        
    }

   

    handleContinue=()=> {

        this.props.continue(this.props.ItemId);
        this.props.handleClose();
    }

    
    render() {
       return (
           <Dialog style={{ height: '250px' }} open={this.props.open} onClose={this.props.handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
               <DialogContent dividers>
                    <DialogContentText id="alert-dialog-description">
                       {this.props.description}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleContinue} color="primary">
                        OK
                    </Button>
                    <Button onClick={this.props.handleClose} color="primary" autoFocus>
                      CANCEL
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

}

export default ConfirmBox
