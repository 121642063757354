import { ActionTypes as types } from './actionTypes';
import api from 'admin/api/adminApi';
import { showError, showSuccess, hideError } from './alertActions';
import { getError } from './errorActions';
import { getRole } from 'admin/actions/roleActions';
import { getErrorMessage } from './actionCommonMethods';
import { menuSchema } from 'admin/components/section/menu/validationSchema';
import { validateObject, validateObjectAt, validateField } from 'admin/actions/validationAction';
import * as Enums from 'client/components/Common/Enum';

const apiGetMenus = () => {
    return api.getAllMenus();
};
const apiGetRoles = (id) => {
    return api.getRolesForMenu(id);
};
const apiGetMenu = (id) => {
    return api.getMenu(id);
};

const apiUpdateMenu = (menu) => {
    return api.updateMenu(menu);
};

const apiDeleteMenu = (id) => {
    return api.deleteMenu(id);
};

const getMenusRequest = () => {
    return {
        type: types.VGO_GET_MENUS_REQUEST,
    }
};

const getMenusSuccess = (resp) => {
    return {
        type: types.VGO_GET_MENUS_SUCCESS,
        menus: resp.data,
    };
};

const getMenuRequest = (id) => {
    return {
        type: types.VGO_GET_MENU_REQUEST,
        menuId: id,
    };
};

const getMenuSuccess = (resp) => {
    let menu = resp.data;
    if (!menu) {
        menu = newMenu();
    }
    return {
        type: types.VGO_GET_MENU_SUCCESS,
        menu: resp.data,
    };
};

const updateMenuRequest = (menu) => {
    return {
        type: types.VGO_UPDATE_MENU_REQUEST,
        menu: menu,
    };
};

const setValidateFieldsOnBlur = (errors) => {
    return {
        type: types.VGO_VALIDATE_MENU_ONBLUR,
        errors: errors,
    };
};

const updateMenuSuccess = (resp) => {
    return {
        type: types.VGO_UPDATE_MENU_SUCCESS,
        menu: resp.data,
    };
};

const deleteMenuRequest = (id) => {
    return {
        type: types.VGO_DELETE_MENU_REQUEST,
        menuId: id,
    };
};

const deleteMenuSuccess = (id) => {
    return {
        type: types.VGO_DELETE_MENU_SUCCESS,
        menuId: id,
    };
};
const getRolesRequest = () => {
    return {
        type: types.VGO_GET_MENU_ROLES_REQUEST,
    };
};
const getRolesSuccess = (resp) => {
    return {
        type: types.VGO_GET_MENU_ROLES_SUCCESS,
        roles: resp.data,
    };
};
export const modifyNavMenuStructure = (dragMenu, dropMenu, addMenuGroup, moveMenu,deleteMenu) => {
    return {
        type: types.VGO_MODIFY_NAV_STRUCTURE_MENU,
        settings: { dragMenu: dragMenu, dropMenu: dropMenu, moveMenu: moveMenu, addGroup: addMenuGroup, deleteMenu: deleteMenu},
    };
};
export const modifyQuickActionMenuStructure = (dragMenu, dropMenu, addMenu, moveMenu, deleteMenu) => {
    return {
        type: types.VGO_MODIFY_QUICKACTION_STRUCTURE_MENU,
        settings: { dragMenu: dragMenu, dropMenu: dropMenu, moveMenu: moveMenu, addMenu: addMenu, deleteMenu: deleteMenu },
    };
};
export const modifyNavAndQuickActionMenuStructure = (dragMenu, dropMenu, addMenu, moveMenu, deleteMenu) => {
    return {
        type: types.VGO_MODIFY_NAV_QUICKACTION_STRUCTURE_MENU,
        settings: { dragMenu: dragMenu, dropMenu: dropMenu, moveMenu: moveMenu, addMenu: addMenu, deleteMenu: deleteMenu },
    };
};


export const changeMenuItemName = (label,menuItem,menuType) => {
    return {
        type: types.VGO_CHANGE_MENU_MENUITEM_LABEL,
        settings: { label: label, menuItem: menuItem, menuType: menuType },
    };
}
export const setSelectedMenuItem = (menuItem) => {
    return {
        type: types.VGO_NAV_MENU_SELECTED,
        settings: { menuItem: menuItem },
    };
}
export const clearSelection = () => {
    return {
        type: types.VGO_NAV_MENU_CLEAR_SELECTION,
        
    };
}


export const getRoles = (id) => {
    return dispatch => {
        dispatch(getRolesRequest());
        return apiGetRoles(id)
            .then(resp => {
                dispatch(getRolesSuccess(resp));
                
            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
            })
    };
};

export const getMenus = () => {
    return dispatch => {
        dispatch(getMenusRequest());
        return apiGetMenus()
            .then((resp) => {
                hideError(dispatch);
                dispatch(getMenusSuccess(resp));
            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
            });
    }
};

export const getMenu = (id) => {
    return dispatch => {
        dispatch(getMenuRequest(id));
        return apiGetMenu(id)
            .then((resp) => {
                hideError(dispatch);
                dispatch(getMenuSuccess(resp));
                dispatch(getRole(resp.data.menuRoles[0].roleId));
            })
            .catch(err => {
                const errorMessage = getErrorMessage(err);
                dispatch(getError(errorMessage));
                showError(dispatch, errorMessage, 5000);
            });
    };
};

export const deleteMenu = (id) => {
    return dispatch => {
        dispatch(deleteMenuRequest(id));
        return apiDeleteMenu(id)
            .then((resp) => {
                dispatch(deleteMenuSuccess(id));
                showSuccess(dispatch, 'Success', 'Menu deleted!', 5000);
            })
            .catch((err) => {
                const errorMessage = err.response.data;
                dispatch(getError(errorMessage));
                showError(dispatch, errorMessage, 5000);
            });
    };
};

export const updateMenu = (menu) => {
    return dispatch => {
        validateObject(menuSchema, menu)
            .then(resp => {
                if (resp !== true) {
                    dispatch(setValidateFieldsOnBlur(resp));
                    showError(dispatch, 'Please correct the indicated items', 5000);
                }
                else if (menu.quickActions.length === 0 && menu.menuStructure.length === 0) {
                    showError(dispatch, 'To save the menu at least one content item needs to be placed into the menu.', 5000);
                }
                else {
                    dispatch(updateMenuRequest(menu));
                    return apiUpdateMenu(menu)
                        .then((resp) => {
                            dispatch(updateMenuSuccess(resp));
                            showSuccess(dispatch, 'Success', 'Menu saved!', 5000);
                        })
                        .catch(err => {
                            let { message } = err;
                            if (err.response && err.response.data) {
                                message = err.response.data;
                                if (typeof (message) !== "string") {
                                    if (message.title && typeof (message.title) === "string") {
                                        message = message.title;
                                    }
                                    else {
                                        message = err.message;
                                    }
                                }
                            }
                            dispatch(getError(message));
                            showError(dispatch, message, 5000);
                        });
                }
            });
    };
};

export const validateFieldsOnBlur = (value, fieldPath,) => {
    return dispatch => {
        validateObjectAt(menuSchema, value, fieldPath, fieldPath)
            .then(resp => {

                dispatch(setValidateFieldsOnBlur(resp));
            })

    }
}

export const modifyQuickMenuStructure = (menu, settings) => {
    const { dragMenu, dropMenu } = settings;
    if (dropMenu && dropMenu.add === "new") {
        menu.quickActions.splice(dragMenu.index, 1)
        menu.quickActions.push(dragMenu.item)
    }
    else if (settings.moveMenu) {
        
        menu.quickActions =move(menu.quickActions,dragMenu.index,dropMenu.index)
    }
    else if (settings.deleteMenu) {
        menu.quickActions.splice(dragMenu.index, 1)
    }
    else if (settings.addMenu && !dropMenu ) {
        menu.quickActions.push(dragMenu.item)
    }
    else if (settings.addMenu && dropMenu) {
        menu.quickActions.splice(dropMenu.index, 0, dragMenu.item)
        
    }
    return menu;
}
export const modifyMenuStructure = (menu,settings) => {
    const { dragMenu, dropMenu} = settings
    if (dropMenu === undefined && settings.addGroup) {
        menu.menuStructure.push(dragMenu.item);
        return menu;
    }

    if (dropMenu && dropMenu.add === "new") {
        menu.menuStructure.push(dragMenu.item);
    }
    let dropMenuPathObject = {};
    let dragMenuPathObject = {};
    if (settings.moveMenu || settings.addGroup) {
        dropMenuPathObject = menuPathFinder(dropMenu, dropMenuPathObject)
        
    }
    if (settings.moveMenu || settings.deleteMenu) {
        dragMenuPathObject = menuPathFinder(dragMenu, dragMenuPathObject)
    }

    if (settings.moveMenu && !dragMenu.isMenu ) {
        if ((dropMenuPathObject.Level1) && dropMenuPathObject.Level1.id === dragMenu.nodeId) {
            return menu;
        }
        else if ((dropMenuPathObject.Level2) && dropMenuPathObject.Level2.id === dragMenu.nodeId) {
            return menu;
        }
        else if ((dropMenuPathObject.Level3) && dropMenuPathObject.Level3.id === dragMenu.nodeId) {
            return menu;
        }
        else if ((dropMenuPathObject.Level4) && dropMenuPathObject.Level4.id === dragMenu.nodeId) {
            return menu;
        }
    }
    

    getMenuPath(dropMenuPathObject, menu.menuStructure, settings, dragMenuPathObject)
    return menu;
}

export const modifyNavAndQuickMenuStructure =(menu, settings) => {
    const { dragMenu, dropMenu } = settings;
    if (dragMenu.mode === Enums.MenuItemMode.QuickAction && dropMenu.mode === Enums.MenuItemMode.MainMenu)
    {
        if (settings.addMenu) {
            menu.menuStructure.push(dragMenu.item);
        }
        else if (settings.moveMenu) {
            let dropMenuPathObject = {};
            dropMenuPathObject = menuPathFinder(dropMenu, dropMenuPathObject)
            getMenuPath(dropMenuPathObject, menu.menuStructure, settings, {})
        }
        menu.quickActions.splice(dragMenu.index, 1)
       

    }
    else if (dragMenu.mode === Enums.MenuItemMode.MainMenu && dropMenu.mode === Enums.MenuItemMode.QuickAction)
    {
        const hasItem = menu.quickActions.findIndex(item => item.id === dragMenu.item.id && item.contentType === dragMenu.item.contentType)
        if (hasItem < 0)
        {
            if (settings.addMenu) {
                menu.quickActions.push(dragMenu.item);
            }
            else if (settings.moveMenu) {
                menu.quickActions.splice(dropMenu.index, 0, dragMenu.item)
            }
            let dragMenuPathObject = {};
            dragMenuPathObject = menuPathFinder(dragMenu, dragMenuPathObject)
            deleteNavMenuItem(menu.menuStructure, dragMenuPathObject, dragMenu)

        }
        
    }
    return menu;
}

const menuPathFinder = (node, pathObject) =>{
    if (node.parent) {

        if (node.level === 1) {
            pathObject.Level1 = { ...node.parent}
        }
        else if (node.level === 2) {
            pathObject.Level2 = { ...node.parent }
        }
        else if (node.level === 3) {
            pathObject.Level3 = { ...node.parent }
        }
        else if (node.level === 4) {
            pathObject.Level4 = { ...node.parent }
        }
        menuPathFinder(node.parent, pathObject);
    }
    else
    {
        if (!pathObject.Level1) {
            pathObject.Level1 = { ...node }
        }
        
    }

    return pathObject;

}
const getMenuPath = (pathObject, menuStructure, settings, dragMenuPathObject) => {
    
    const dragItem = settings.dragMenu;
    const dropItem = settings.dropMenu;
    let isSameLevel = false;
    if (settings.moveMenu && dragItem.level === dropItem.level && (dragItem.isMenu === dropItem.isMenu || (!dragItem.isMenu && dropItem.isMenu) ))
    {
        isSameLevel = true;
    }

   
    if ((settings.moveMenu || settings.addGroup) && !isSameLevel) {

        if (pathObject.Level4) {
            if (dropItem.isMenu) {

                menuStructure[pathObject.Level4.parent.parent.index].items[pathObject.Level4.parent.index].items[pathObject.Level4.index].items.splice(dropItem.index , 0, dragItem.item);
            }
            else
            {
                // no further level
            }
        }
        else if (pathObject.Level3) {
            if (dropItem.isMenu) {

                menuStructure[pathObject.Level3.parent.index].items[pathObject.Level3.index].items.splice(dropItem.index, 0, dragItem.item);
            }
            else {
                menuStructure[pathObject.Level3.parent.index].items[pathObject.Level3.index].items[dropItem.index].items.push(dragItem.item);
            }
        }
        else if (pathObject.Level2) {
            if (dropItem.isMenu) {

                menuStructure[pathObject.Level2.index].items.splice(dropItem.index,0,dragItem.item);
            }
            else {
                menuStructure[pathObject.Level2.index].items[dropItem.index].items.push(dragItem.item);
            }
        }
        else if (pathObject.Level1) {
            if (dropItem.isMenu) {
                menuStructure.splice(dropItem.index, 0, dragItem.item);
            }
            else {
                menuStructure[pathObject.Level1.index].items.push(dragItem.item);
            }

        }
    }
    if ((settings.moveMenu || settings.deleteMenu) && !isSameLevel) {

        

        if (dragMenuPathObject.Level4) {
            let incrLevel1 = 0, incrLevel2 = 0, incrLevel3 = 0;
            if (dropItem && dropItem.isMenu) {
                if (dropItem.level === dragMenuPathObject.Level4.parent.parent.level) {
                    incrLevel1++;
                }
                else if (dropItem.level === dragMenuPathObject.Level4.parent.level) {
                    incrLevel2++;
                }
                else if (dropItem.level === dragMenuPathObject.Level4.level) {
                    incrLevel3++;
                }
            }
            menuStructure[dragMenuPathObject.Level4.parent.parent.index + incrLevel1].items[dragMenuPathObject.Level4.parent.index + incrLevel2].items[dragMenuPathObject.Level4.index + incrLevel3].items.splice(dragItem.index, 1);
        }
        else if (dragMenuPathObject.Level3) {
            let incrLevel1 = 0, incrLevel2 = 0;
            if (dropItem && dropItem.isMenu) {
                if (dropItem.level === dragMenuPathObject.Level3.parent.level)
                {
                    incrLevel1++;
                }
                else if (dropItem.level === dragMenuPathObject.Level3.level) {
                    incrLevel2++;
                }
            }

            menuStructure[dragMenuPathObject.Level3.parent.index + incrLevel1].items[dragMenuPathObject.Level3.index + incrLevel2].items.splice(dragItem.index, 1);
        }
        else if (dragMenuPathObject.Level2) {
            let incrLevel1 = 0;
            if (dropItem && dropItem.isMenu) {
                if (dropItem.level === dragMenuPathObject.Level2.level) {
                    incrLevel1++;
                }
            }
            menuStructure[dragMenuPathObject.Level2.index + incrLevel1].items.splice(dragItem.index, 1);
        }
        else if (dragMenuPathObject.Level1) {
            menuStructure.splice(dragItem.index, 1);

        }
    }


    if (isSameLevel) {
        if (pathObject.Level4) {
            if (dropItem.isMenu) {
                menuStructure[pathObject.Level4.parent.parent.index].items[pathObject.Level4.parent.index].items[pathObject.Level4.index].items = move(menuStructure[pathObject.Level4.parent.parent.index].items[pathObject.Level4.parent.index].items[pathObject.Level4.index].items, dragItem.index, dropItem.index);
            }
            else {
                // no further level
            }
        }
        else if (pathObject.Level3) {

            menuStructure[pathObject.Level3.parent.index].items[pathObject.Level3.index].items = move(menuStructure[pathObject.Level3.parent.index].items[pathObject.Level3.index].items, dragItem.index, dropItem.index);
            
        }
        else if (pathObject.Level2) {

            menuStructure[pathObject.Level2.index].items = move(menuStructure[pathObject.Level2.index].items, dragItem.index, dropItem.index);
        }
        else if (pathObject.Level1) {
            menuStructure = move(menuStructure, dragItem.index, dropItem.index);

        }
    }

}
const move = (array, from, to) => {
    if (to === from) return array;

    var target = array[from];
    var increment = to < from ? -1 : 1;

    for (var k = from; k != to; k += increment) {
        array[k] = array[k + increment];
    }
    array[to] = target;
    return array;
}

const deleteNavMenuItem = (menuStructure, dragMenuPathObject, dragItem) => {

    if (dragMenuPathObject.Level4) {
        menuStructure[dragMenuPathObject.Level4.parent.parent.index].items[dragMenuPathObject.Level4.parent.index].items[dragMenuPathObject.Level4.index].items.splice(dragItem.index, 1);
    }
    else if (dragMenuPathObject.Level3) {
        menuStructure[dragMenuPathObject.Level3.parent.index].items[dragMenuPathObject.Level3.index].items.splice(dragItem.index, 1);
    }
    else if (dragMenuPathObject.Level2) {

        menuStructure[dragMenuPathObject.Level2.index].items.splice(dragItem.index, 1);
    }
    else if (dragMenuPathObject.Level1) {
        menuStructure.splice(dragItem.index, 1);

    }
}
export const modifyMenuItemName = (menu, settings) => {
    const { menuItem, label, menuType } = settings
    if (menuType === 1) {
        let menuItemPathObject = {};
        menuItemPathObject = menuPathFinder(menuItem, menuItemPathObject)
        if (menuItemPathObject.Level4) {

        }
        else if (menuItemPathObject.Level3) {
            menu.menuStructure[menuItemPathObject.Level3.parent.index].items[menuItemPathObject.Level3.index].items[menuItem.index].label = label;
        }
        else if (menuItemPathObject.Level2) {

            menu.menuStructure[menuItemPathObject.Level2.index].items[menuItem.index].label = label;
        }
        else if (menuItemPathObject.Level1) {
            menu.menuStructure[menuItem.index].label = label;

        }
    }
    else
    {
        menu.quickActions[menuItem.index].label = label;
    }
    return menu;
}

export const clearMenuSelection = (selectedMenuItem) => {
    selectedMenuItem.itemSelected =[];
    selectedMenuItem.treeViewSelected = {};
    return selectedMenuItem;

}

export const newMenu = () => {
    let menu = {
        createDate: "",
        createUserId: 0,
        menuDescription: "",
        menuId: 0,
        menuName: "",
        menuRoles: [{roleId:0,}],
        menuStructure: [],
        updateDate: "",
        quickActions: [],
        roleId: 0,
        //menuNameError: false,
        //menuNameHelper: ' ',
        //roleError: false,
        //roleHelper: ' ',
    };
    return menu;
};

export const getChangeMenu = (change) => {
    return {
        type: types.VGO_CHANGE_MENU,
        change,
    };
}

export const getNewMenu = () => {
    return {
        type: types.VGO_NEW_MENU,
    };
}

export const getContentDragData = (dragData) => {
    return {
        type: types.VGO_GET_MENU_CONTENT_DRAG_DATA,
        dragData: dragData,
    }
}

export const showValidateError = (errorMessage) => {
    return {
        type: types.VGO_VALIDATE_MENU_ERROR,
        alert: { open: true, message: errorMessage, title: errorMessage, icon: '', severity: 'error' },

    };
};

export const hideValidateError = () => {
    return {
        type: types.VGO_VALIDATE_MENU_ERROR,
        alert: { open: false, message: '', title: '', icon: '', severity: 'error' }
    };
};
