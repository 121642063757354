import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Box } from '@mui/material'
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/styles';
import { withStyles } from '@mui/styles';
import { DropTarget } from 'react-drag-drop-container';
import DragDropContainer from './DragDropContainer';
import MuiCard from '@mui/material/Card';
import './formUiStyle.css'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from '@mui/icons-material/Add';
import { spacing } from '@mui/system';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { updateFormUI, getNewFormUI, updateFormUIProperty, updateActiveControlProperty, showErrorMessage, getFormControl, getControlFomat, getControlCulture, getButtonActionObject, getValidationError } from 'admin/actions/formActions';
import { getError, clearError } from 'admin/actions/errorActions';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import fileImage from 'admin/assets/images/File.png'
import signatureImage from 'admin/assets/images/Signature.png'
import { ReactComponent as InsertBelow } from 'admin/assets/images/insertBelow.svg'
import { ReactComponent as InsertAbove } from 'admin/assets/images/insertAbove.svg'
import { ReactComponent as SubRowIcon } from 'admin/assets/images/subRow.svg'
import { ReactComponent as RoleIcon } from 'admin/assets/images/roleIcon.svg'
import image from 'admin/assets/images/Image.png'
import editImage from 'admin/assets/images/Edit.png'
import { ReactComponent as SaveCloseImage } from 'admin/assets/images/SaveClose.svg'
import { ReactComponent as SavePlusImage } from 'admin/assets/images/SavePlus1.svg'
import { ReactComponent as SaveImage } from 'admin/assets/images/Save1.svg'
import { ReactComponent as GridEditIcon } from 'admin/assets/images/EditIcon.svg'
import DeleteButton from '@mui/icons-material/DeleteOutline';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ControlProperties from 'admin/components/section/form/setup/controlProperties';
import ControlTab from 'admin/components/section/form/formLayoutUI/ControlTab';
import { controlType, sectionType, } from 'admin/actions/actionTypes';
import { getControlCaption } from 'admin/actions/formActions';
import { getDataViewListByRoleList, getNewDataView } from 'admin/actions/dataViewActions';
import ConfirmBox from 'common/ConfirmBox.js'
import { formUITheme } from './theme/formUITheme.js';
import Popover from '@mui/material/Popover';
import { Scrollbars } from 'react-custom-scrollbars';
import * as Enum from 'client/components/Common/Enum';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AvTimerSharpIcon from '@mui/icons-material/AvTimerSharp';
import TitleIcon from '@mui/icons-material/Title';
import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined';
import FormatColorTextOutlinedIcon from '@mui/icons-material/FormatColorTextOutlined';
import SmartButtonIcon from '@mui/icons-material/SmartButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import cloneDeep from 'lodash/cloneDeep';
const Card = styled(MuiCard)(spacing);
const updateType = { Add: "add", Rename: "rename", Delete: "delete", Edit: "edit", Role: "role" }
let sourceControlElement = {};
let targetControlElement = {};
let selectedControlColumnId = "";
const message = 'Please correct the indicated items';
const column = {
    column: "",
    controlId: "",
    controlUiId: "",
    controlType: 0,
    style: "",
    css: "",
    fieldName: "",
    caption: "",
    isVisible: false,
    helpText: "",
    defaultValue: "",
    falseValue: "",
    isMandatory: false,
    isEditable: false,
    isPrimaryField: false,
    isForiegnKey: "",
    isViewInMobile: false,
    listId: 0,
    formula: "",
    formulaUI: "",
    endPoint: "",
    displayField: "",
    valueField: "",
    label: "",
    rowCommands: "",
    controlRow: 0,
    controlPosition: 0,
    width: 0,
    subRows: [],
    targetRow: "",
    gridColumns: "",
    rowColumnId: "",
    isUpdateDetail: false,
    errorColumnFormat: false,
    helperTextColumnFormat: ' ',


}
const row = {
    row: '',
    columns: []

}
const MuiPopover = withStyles((theme) => ({
    root: {},
    paper: {
        overflowY: 'hidden!important',
    },
}))(Popover)
let fieldName = "";
let isPrimary = false;
let selectedColumn = "";
let formSectionList = "";
let controlPropertyData = "";
let scrollPosition = 0;
class formUi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataModel: {},
            objectUI: [],
            tabIndex: 0,
            columnFormat: "",
            columnCount: "",
            selectedRow: "",
            rowEditor: true,
            columnEditor: false,
            rowsCount: "",
            columnsCount: "",
            selectedColumnId: "",
            visible: false,
            isExpanded: false,
            confirmOpen: false,
            controlId: "",
            OpenControlSettings: false,
            fromFieldClick: false,
            validationResults: [],


        };

    }

    componentDidMount() {
        let controlObject = {}
        this.props.dispatch(updateActiveControlProperty(controlObject));
        selectedControlColumnId = "";
        this.getDataViewListByRole();
      }

    componentDidUpdate() {
       
            if (this.scrollbar)
                this.scrollbar.scrollTop(scrollPosition);
       
    }


    getDataViewListByRole = () => {
        let roleList = "";
        for (let i = 0; i < this.props.form.roles.length; i++) {
            roleList = `${roleList}roleIdList[${i}]=${this.props.form.roles[i].roleId}&`
        }

        if (roleList !== this.props.dataViewsByRole.roles) {
            this.props.dispatch(getDataViewListByRoleList(roleList));
            this.props.dispatch(getNewDataView());
        }
    }
    loadControlSettings = (checkIndex) => {
       
        if (checkIndex) {
            // if (this.props.activeFormControl.activeControlIndex > -1)
            this.setState({ OpenControlSettings: true, controlClicked: true })
        } else {
            this.setState({ OpenControlSettings: true, controlClicked: true })
        }
      
    }
    handleControlClickAway = (e) => {
        if (this.state.validationResults.length === 0) {
            this.setState({ OpenControlSettings: this.state.controlClicked, controlClicked: false })
        }
    }
    closeControlSettings = () => {

        if (this.state.validationResults.length === 0) {
            selectedControlColumnId = "";
            this.setState({ OpenControlSettings: false, controlClicked: false })

        }

    }
    canInvokeEvent = () => {

        if (this.state.validationResults.length === 0) {
            return true;
        } else { return false; }
    }
    handlePropertyValidationResult = (result) => {
        this.setState({ validationResults: result });
        if (result.length > 0) {
            this.props.dispatch(getValidationError(result, message));

        } else {
            if (this.props.hasError) {
                this.props.dispatch(clearError());
            }
        }

    }
    setUIObject = (row, column, rowColumnId) => {
        let rowArray = this.state.objectUI;


        this.setState({ objectUI: rowArray });

        return rowArray;

    }

    onHandleChange = (event) => {
        let model = this.state.dataModel;
        model[event.target.name] = event.target.value
        this.setState({ dataModel: model });

    }

    setStyle = (rows) => {
        let columnStyle = { borderColor: "", width: "100%" }
        for (var i = 0; i < rows.columns.length; i++) {
            if (rows.columns[i].subRows.length > 0) {

                return columnStyle = { borderColor: "", width: "100%", minHeight: "74px" }
            }
        }
        return columnStyle;
    }


    getFormLayout = (data, classes) => {
        const rows = data.map((rows) => {

            let columnStyle = this.setStyle(rows);
            let columns = rows.columns.map((column) => {

                if (column.subRows.length > 0) {
                    return <Grid className="dropdiv" id={"Row" + rows.row + "-" + column.column} item xs={column.width} style={{ width: "100%", height: "100%" }} key={"Row" + rows.row + "-" + column.column} >
                        <Grid container spacing={0}>


                            <Grid item xs >
                                {this.getSubRowLayout(column.subRows, "Row" + rows.row + "-" + column.column, column, classes)}
                            </Grid>



                            <Grid item className="subRowDeleteItem">
                                <DeleteIcon className="subRowDeleteIcon" onClick={() => { this.deleteSubRowColumn("Row" + rows.row + "-" + column.column) }} />
                            </Grid>
                        </Grid>
                    </Grid>
                } else {


                    return <Grid id={"Row" + rows.row + "-" + column.column} item xs={column.width} style={{ width: "100%" }} key={"Row" + rows.row + "-" + column.column} >

                        {this.setColumn(rows, column, columnStyle, classes)}

                    </Grid>

                }

            });
            return <Grid key={"Rowdiv" + rows.row} container spacing={0} justifyContent="flex-start" >
                <Grid item id={"EditRow" + rows.row} className="editDiv" key={"Column" + rows.row} >{this.getEditColumn("Row" + rows.row, classes)}</Grid>
                <Grid item xs ><Grid container justifyContent="space-between" id={"Row" + rows.row} key={"Row" + rows.row} >{columns}</Grid></Grid >
            </Grid >
        });
        return rows;

    }
    getSubRowLayout = (data, id, columns, classes) => {
        let columnId = "";
        let controlId = ""
        let rowId = "";
        let columnStyle = { borderColor: "", width: "100%" }

        const subRows = data.map((rows) => {
            let subRowColumns = rows.columns.map((columnItem) => {

                let column = cloneDeep(columnItem)
                if (columns.rowColumnId.indexOf("grd") > -1) {
                    columnId = `grd${id}_SubRow${rows.row}-${column.column}`    // "grd" + id + "_" + "SubRow" + rows.row + "-" + column.column
                    controlId = `grd${id}_SubRow${rows.row}-${column.column}`  // "grd" + id + "_" + "SubRow" + rows.row + "-" + column.column
                    rowId = "grdSubRow" + rows.row
                    columnStyle = { borderColor: "#FFCF88", maxWidth: "120px", minWidth: "120px" }
                } else {

                    columnId = `${id}_SubRow${rows.row}-${column.column}` // id + "_" + "SubRow" + rows.row + "-" + column.column
                    controlId = `${id}_SubRow${rows.row}-${column.column}` //id + "_" + "SubRow" + rows.row + "-" + column.column
                    rowId = `SubRow${rows.row}`//"SubRow" + rows.row
                }


                if (column.width === 0) {


                    column.width = 12 / rows.columns.length
                }


                if (columns.rowColumnId.indexOf("grd") > -1) {

                    return <Grid id={columnId} item xs style={columnStyle} key={columnId} >

                        {this.setSubRowColumn(column, columnStyle, columnId, controlId, classes)}
                    </Grid>
                }

                else {
                    return <Grid id={columnId} item xs={column.width} key={columnId} >

                        {this.setSubRowColumn(column, columnStyle, columnId, controlId, classes)}
                    </Grid>

                }



            });

            if (columnId.indexOf("grd") > -1) {
                columnId = `EditSubRow${rows.row}`;//"EditSubRow" + rows.row

                return <Grid key={"sub" + columnId} spacing={0} className="" container justifyContent="flex-start" spacing={0}  >
                    <Grid item className={classes.GridEditItem} >
                        <GridEditIcon onClick={() => { this.editDetailRow(`${id}_grdSubRow${rows.row}`) }} className={classes.GridEditIcon + ' editIcon'} />

                        {/*  <Grid id={columnId} className="editSubDiv" xs item key={"Column" + rows.row} >{this.getGridEditColumn(id + "_" + "grdSubRow" + rows.row, classes)}</Grid>*/}

                    </Grid >
                    <Grid key={"subitem" + columnId} item className={classes.gridItemWidth} ><Grid justifyContent="flex-start" spacing={0} container className="" id={rowId} key={rowId} >{subRowColumns}</Grid></Grid >

                </Grid >
            } else {
                columnId = "EditSubRow" + rows.row

                return <Grid key={"sub" + columnId} spacing={0} className="" container >

                    <Grid id={columnId} className="editSubDiv" item key={`Column${rows.row}`} >{this.getEditColumn(`${id}_SubRow${rows.row}`, classes)}</Grid>


                    <Grid key={"subitem" + columnId} item xs ><Grid container className="" id={rowId} key={rowId} >{subRowColumns}</Grid></Grid >

                </Grid >
            }

        });
        return subRows;

    }

    showControlWindow = (event, target) => {
        if (this.state.validationResults.length === 0) {
            this.setState({ anchorControlWindow: event.currentTarget, controlTarget: target })
        }
    }

    setColumn = (rows, column, columnStyle, classes) => {

        let columnId = `Row${rows.row}-${column.column}`;// "Row" + rows.row + "-" + column.column;


        if (column.controlUiId) {
            return (
                <DropTarget key={columnId} dropData={columnId} targetKey="formContainer" onDragEnter={this.handleHit} >
                    <Grid container spacing={0}><Grid item
                        onClick={() => { this.onColumnSelect(columnId) }}
                        id={columnId} style={columnStyle} xs={12} className={columnId === selectedControlColumnId ? "rowDivBg dropdiv" : "rowDiv dropdiv"}>
                        {this.setControl(column.controlUiId, columnId, classes)}</Grid></Grid>
                </DropTarget>
            )
        } else {
            return (<DropTarget key={columnId} dropData={columnId} targetKey="formContainer" onDragEnter={this.handleHit} ><Grid container spacing={0}><Grid item onClick={() => { this.onColumnSelect(columnId) }} id={columnId} style={columnStyle} xs={12} className={columnId === selectedControlColumnId ? "rowDivBg dropdiv" : "rowDiv dropdiv"}><AddIcon className="addIcon" onClick={(event) => { this.showControlWindow(event, columnId) }} /><SubRowIcon onClick={(e) => { this.addSubRowColumn(columnId) }} className="subRowIcon" /></Grid></Grid></DropTarget>)

        }
    }
    setSubRowColumn = (column, columnStyle, columnId, controlId, classes) => {
        if (column.controlUiId) {
            return (
                <DropTarget key={columnId} className="dropdiv" dropData={columnId} targetKey="formContainer" onDragEnter={this.handleHit} >

                    <Grid item id={columnId} style={columnStyle} xs={12} className={columnId.indexOf("grd") > -1 ?
                        columnId === selectedControlColumnId ? "dropdiv subRowGridColumnBg" : "dropdiv subRowGridColumn"
                        : columnId === selectedControlColumnId ? "dropdiv subRowColumnBg" : "dropdiv subRowColumn"}>{this.setControl(column.controlUiId, controlId, classes)}</Grid>


                </DropTarget>
            )
        } else {
            return (<DropTarget key={columnId} className="dropdiv" dropData={columnId} targetKey="formContainer" onDragEnter={this.handleHit} ><Grid item id={columnId}
                style={columnStyle} xs={12} className={columnId.indexOf("grd") > -1 ? "dropdiv subRowGridColumn" : "dropdiv subRowColumn"}>

                <AddIcon className={columnId.indexOf("grd") > -1 ? "subRowGridAddIcon" : "subRowAddIcon"} onClick={(event) => { this.showControlWindow(event, columnId) }} /></Grid> </DropTarget>)

        }
    }

    getEditColumn = (rowId, classes) => {
        let editArrowStyle = "editArrowStyle1"
        let editStyle = "editStyle1"
        if (rowId.indexOf("SubRow") !== -1) {
            editStyle = "editStyle2"
            editArrowStyle = "editArrowStyle2"
        }

        return (
            <Grid container direction="column" justifyContent="flex-start" spacing={0} >
                <Grid className={classes.editColumn} item xs ><InsertAbove className={editArrowStyle} onClick={() => { this.addRowAbove(rowId) }} /></Grid>
                <Grid className={classes.editColumn} item xs  ><InsertBelow className={editArrowStyle} onClick={() => { this.addRowBelow(rowId) }} /></Grid>
                <Grid className={classes.editColumn} item xs  ><EditIcon className={editStyle + ' ' + classes.editDeleteIcon} onClick={(event) => { this.editRow(rowId, event) }} /></Grid>
                <Grid className={classes.editColumn} item xs ><DeleteIcon className={editStyle + ' ' + classes.editDeleteIcon} onClick={() => { this.deleteRow(rowId) }} /></Grid>

            </Grid>);

    }
    getGridEditColumn = (rowId) => {

        return (
            <Grid container >
                <Grid item xs  >
                    <Grid item  ><GridEditIcon onClick={() => { this.editDetailRow(rowId) }} /></Grid>
                    {/*<Grid container >*/}
                    {/*    <Grid item  ><GridEditIcon className="editIcon"onClick={() => { this.editDetailRow(rowId) }} /></Grid>*/}
                    {/*</Grid>*/}
                    {/*<Grid container >*/}
                    {/*    <Grid item xs={12} ><DeleteIcon className="editIcon" style={{ color: "#FFCF88" }} onClick={() => { this.deleteRow(rowId) }} /></Grid>*/}
                    {/*</Grid>*/}
                </Grid>
            </Grid>);

    }
    getColumnCount = (row, data) => {
        let count = 0;
        for (let i = 0; i < data.length; i++) {

            if (data[i].Row === row) {

                count++;
            }
        }
        return count;
    }
    validateGrid = () => {

        for (let i = 0; i < this.props.formUI.length; i++) {

            for (let j = 0; j < this.props.formUI[i].columns.length; j++) {
                let data = this.props.formUI[i].columns[j]
                if (data.subRows.length > 0) {
                    if (data.rowColumnId.indexOf("grd") > -1) {

                        return false;
                    }

                }

            }

        }
        return true;

    }
    handleDrop = (event) => {
        selectedColumn = "";
        event.preventDefault();

        if (this.state.validationResults.length > 0) {
            return false;
        }
        let control = event.dragData;
        fieldName = "";
        let target = event.dropData
        if (typeof (control) === "object") {
            control = event.dragData.control
            fieldName = event.dragData.fieldName
            if (event.dragData.sectionType === sectionType.Detail && target.indexOf("grd") === -1) {
                this.props.dispatch(showErrorMessage("A detail field can only be placed in the form detail section"))
                return false;
            } else {
                if (event.dragData.sectionType === sectionType.Header && target.indexOf("grd") !== -1) {
                    this.props.dispatch(showErrorMessage("A primary field can not be placed in the form detail sectionn"))
                    return false;
                }

            }

        } else {
            if (control.indexOf("grd") !== -1 && target.indexOf("grd") === -1) {
                this.props.dispatch(showErrorMessage("A detail field can only be placed in the form detail section"))
                return false;
            } else {
                if (control.indexOf("grd") === -1 && target.indexOf("grd") !== -1) {
                    this.props.dispatch(showErrorMessage("A primary field can not be placed in the form detail section"))

                    return false;
                }

            }
        }

        if (control === controlType.Detail) {
            if (this.validateGrid()) {
                this.setState({ visible: true, targetRow: target, isUpdateDetail: false });
                return false;
            } else {
                this.props.dispatch(showErrorMessage("Detail section already added"))
                return false;
            }

        }
        let model = Object.assign([], this.props.formUI);
        let copyModel = Object.assign([], this.props.formUI);

        //Empty control column
        if (control.indexOf("SubRow") > -1) {
            // txt_ Row1 -2_SubRow1 - 2
            let splitRow = control.split("_");
            let rootRow = splitRow[1].split("-")[0].replace("Row", "").replace("grd", "").trim();
            let rootColumn = splitRow[1].split("-")[1].trim();


            const index = model.findIndex(m => m.row === parseInt(rootRow))

            let modelRow = Object.assign({}, model[index])

            let columns = Object.assign([], modelRow.columns)

            const columnIndex = columns.findIndex(m => m.column === parseInt(rootColumn))

            let column = Object.assign({}, model[index].columns[columnIndex]);

            let subRowModel = Object.assign([], column.subRows);

            let subRowIndex = 0;
            subRowIndex = subRowModel.findIndex(m => m.row === parseInt(splitRow[2].split("-")[0].replace("SubRow", "").replace("grd", "").trim()))

            let modelSubRow = Object.assign({}, subRowModel[subRowIndex])
            let subRowColumns = Object.assign([], modelSubRow.columns)
            let subRowColumnIndex = 0;

            subRowColumnIndex = subRowColumns.findIndex(m => m.column === parseInt(splitRow[2].split("-")[1].trim()))
            let subRowColumn = Object.assign({}, subRowModel[subRowIndex].columns[subRowColumnIndex]);
            subRowColumn.controlUiId = "";
            subRowColumn.fieldName = "";
            subRowColumn.dataType = 0;
            subRowColumn.controlType = 0;
            subRowColumns[subRowColumnIndex] = subRowColumn;
            modelSubRow.columns = subRowColumns
            subRowModel[subRowIndex] = modelSubRow
            column.subRows = subRowModel
            columns[columnIndex] = column;
            modelRow.columns = columns;
            model[index] = modelRow



        } else {
            if (control.indexOf("_") > -1) {
                let sourceRow = control.split("_")[1].split("-")[0].replace("Row", "").trim();
                let sourceColumn = control.split("_")[1].split("-")[1].trim();
                const rowIndex = model.findIndex(m => m.row === parseInt(sourceRow))
                let sourceModelRow = Object.assign({}, model[rowIndex])
                let sourceColumns = Object.assign([], sourceModelRow.columns)
                const sourceColumnIndex = sourceColumns.findIndex(m => m.column === parseInt(sourceColumn))
                let column = Object.assign({}, model[rowIndex].columns[sourceColumnIndex]);
                column.controlUiId = "";
                column.fieldName = "";
                column.dataType = 0;
                column.controlType = 0;
                sourceColumns[sourceColumnIndex] = column;
                sourceModelRow.columns = sourceColumns;
                model[rowIndex] = sourceModelRow

            }
        }

        //Add control to sub row column

        if (target.indexOf("SubRow") > -1) {
            //"Row1-2_SubRow1-2"
            let splitRow = target.split("_");
            let rootRow = splitRow[0].split("-")[0].replace("Row", "").replace("grd", "").trim();
            let rootColumn = splitRow[0].split("-")[1].trim();
            const index = model.findIndex(m => m.row === parseInt(rootRow))
            let modelRow = Object.assign({}, model[index])
            let columns = Object.assign([], modelRow.columns)
            const columnIndex = columns.findIndex(m => m.column === parseInt(rootColumn))
            let column = Object.assign({}, model[index].columns[columnIndex]);
            let subRowModel = Object.assign([], column.subRows);
            let subRowIndex = 0;
            subRowIndex = subRowModel.findIndex(m => m.row === parseInt(splitRow[1].split("-")[0].replace("SubRow", "").replace("grd", "").trim()))
            let modelSubRow = Object.assign({}, subRowModel[subRowIndex])
            let subRowColumns = Object.assign([], modelSubRow.columns)
            const subRowColumnIndex =
                subRowColumns.findIndex(m => m.column === parseInt(splitRow[1].split("-")[1].trim()))
            let subRowColumn = Object.assign({}, subRowModel[subRowIndex].columns[subRowColumnIndex]);
            subRowColumn.controlUiId = this.getControlId(control, target)
            subRowColumn.controlType = this.getControlType(control, target)
            let sourceTarget = "";
            if (control.indexOf("_") > -1 && control.indexOf("SubRow") === -1) {
                sourceTarget = control.split("_")[1].trim();
            } else {
                sourceTarget = control.split("_")[1] + "_" + control.split("_")[2];
            }
            let sourceControl = this.getControlId(control, sourceTarget)


            if (control.indexOf("grd") > -1 || target.indexOf("grd") !== -1) {
                if (control.indexOf("_") > -1) {
                    sourceControl = this.getControlId(control, control.split("_")[1] + "_" + control.split("_")[2])
                } else {
                    sourceControl = this.getControlId(control, target)

                }
                this.setControlProperty(sectionType.Detail, updateType.Rename, sourceControl, subRowColumn.controlUiId, subRowColumn.controlType)
            } else {

                this.setControlProperty(sectionType.Header, updateType.Rename, sourceControl, subRowColumn.controlUiId, subRowColumn.controlType)
            }

            subRowColumns[subRowColumnIndex] = subRowColumn;
            modelSubRow.columns = subRowColumns
            subRowModel[subRowIndex] = modelSubRow
            column.subRows = subRowModel
            columns[columnIndex] = column;
            modelRow.columns = columns;
            model[index] = modelRow


            if (copyModel[index].columns[columnIndex].subRows[subRowIndex].columns[subRowColumnIndex].controlUiId) {
                if (control.indexOf("SubRow") > -1) {
                    this.updateSubRowColumn(model, control, copyModel[index].columns[columnIndex].subRows[subRowIndex].columns[subRowColumnIndex].controlUiId)
                } else {

                    this.updateRowColumn(model, control, copyModel[index].columns[columnIndex].subRows[subRowIndex].columns[subRowColumnIndex].controlUiId)
                }
            }




        } else {

            let row = target.split("-")[0].replace("Row", "").trim();
            let columnValue = target.split("-")[1].trim();

            // let model = Object.assign([], this.props.formUI);

            const index = model.findIndex(m => m.row === parseInt(row))

            let modelRow = Object.assign({}, model[index])

            let columns = Object.assign([], modelRow.columns)

            const columnIndex = columns.findIndex(m => m.column === parseInt(columnValue))

            let column = Object.assign({}, model[index].columns[columnIndex]);
            column.controlUiId = this.getControlId(control, target)
            column.controlType = this.getControlType(control, target)
            let sourceTarget = "";
            if (control.indexOf("_") > -1) {
                sourceTarget = control.split("_")[1].trim();
            }
            let sourceControl = "";

            if (control.indexOf("SubRow") !== -1) {
                sourceControl = this.getControlId(control, control.split("_")[1] + "_" + control.split("_")[2])
            } else {
                sourceControl = this.getControlId(control, sourceTarget)
            }

            this.setControlProperty(sectionType.Header, updateType.Rename, sourceControl, column.controlUiId, column.controlType)
            columns[columnIndex] = column;
            modelRow.columns = columns;
            model[index] = modelRow


            if (copyModel[index].columns[columnIndex].controlUiId) {

                if (control.indexOf("SubRow") > -1) {
                    this.updateSubRowColumn(model, control, copyModel[index].columns[columnIndex].controlUiId)
                } else {
                    this.updateRowColumn(model, control, copyModel[index].columns[columnIndex].controlUiId)
                }
            }


        }


        if (sourceControlElement.controlUId) {
            this.resetControlPosition(sourceControlElement.index, sourceControlElement.controlUId, sourceControlElement.section)
            this.resetControlPosition(targetControlElement.index, targetControlElement.controlUId, targetControlElement.section)


            targetControlElement = {};
            sourceControlElement = {};
        }


        this.props.dispatch(updateFormUI(model));
        this.closeControlSettings();
    }

    resetControlPosition = (index, control, controlSection) => {

        let model = Object.assign({}, this.props.formSection);

        if (controlSection === sectionType.Header) {


            let formControl = Object.assign([], model.header);
            let controlObject = Object.assign({}, model.header[index]);
            controlObject.controlUiId = control;
            if (controlObject.fieldName.indexOf("nbd") !== -1) {
                controlObject.fieldName = `nbd_${control.replaceAll("-", "_")}`
            }
            formControl[index] = controlObject;
            model.header = formControl
            //this.props.dispatch(updateFormUIProperty(model));
        } else {

            //------------------------------
            let detail = Object.assign({}, model.detail);
            let formGridControl = Object.assign([], model.detail.formGridControl);
            let controlObject = Object.assign({}, model.detail.formGridControl[index]);
            controlObject.controlUiId = control;
            if (controlObject.fieldName.indexOf("nbd") !== -1) {
                controlObject.fieldName = `nbdgrid_${control.replaceAll("-", "_")}`
            }
            formGridControl[index] = controlObject;
            detail.formGridControl = formGridControl
            model.detail = detail;


            //-----------------------------------

        }



        this.props.dispatch(updateFormUIProperty(model));

    }

    addControl = (controlData, target, isUpdate) => {

        fieldName = "";
        isPrimary = "";
        let control = controlData;

        if (typeof (control) === "object") {
            control = controlData.control;
            fieldName = controlData.fieldName;
            isPrimary = controlData.isPrimary;
            controlPropertyData = controlData;
            if (controlData.sectionType === sectionType.Detail && target.indexOf("grd") === -1) {
                this.props.dispatch(showErrorMessage("A detail field can only be placed in the form detail section"))
                return false;
            } else {
                if (controlData.sectionType === sectionType.Header && target.indexOf("grd") !== -1) {
                    this.props.dispatch(showErrorMessage("A primary field can not be placed in the form detail section"))
                    return false;
                }

            }

        }

        if (control === controlType.Button && target.indexOf("grd") !== -1) {
            this.props.dispatch(showErrorMessage("Buttons can not be placed in the form detail section"))
            return false;
        }
        else if (control === controlType.Signature && target.indexOf("grd") !== -1) {
            this.props.dispatch(showErrorMessage("The signature capture control can not be placed in the form detail section"))
            return false;
        }
        else if (control === controlType.FileUpload && target.indexOf("grd") !== -1) {
            this.props.dispatch(showErrorMessage("The file upload control can not be placed in the form detail section"))
            return false;
        }

        if (control === controlType.Detail) {
            if (this.validateGrid()) {
                this.setState({ visible: true, targetRow: target, isUpdateDetail: false });
                return false;
            } else {
                this.props.dispatch(showErrorMessage("Detail section already added"))
                return false;
            }

        }
        let model = Object.assign([], this.props.formUI);
        let copyModel = Object.assign([], this.props.formUI);
        //Empty control column
        if (control.indexOf("SubRow") > -1) {
            // txt_ Row1 -2_SubRow1 - 2
            let splitRow = control.split("_");
            let rootRow = splitRow[1].split("-")[0].replace("Row", "").replace("grd", "").trim();
            let rootColumn = splitRow[1].split("-")[1].trim();


            const index = model.findIndex(m => m.row === parseInt(rootRow))

            let modelRow = Object.assign({}, model[index])

            let columns = Object.assign([], modelRow.columns)

            const columnIndex = columns.findIndex(m => m.column === parseInt(rootColumn))

            let column = Object.assign({}, model[index].columns[columnIndex]);

            let subRowModel = Object.assign([], column.subRows);

            let subRowIndex = 0;
            subRowIndex = subRowModel.findIndex(m => m.row === parseInt(splitRow[2].split("-")[0].replace("SubRow", "").replace("grd", "").trim()))

            let modelSubRow = Object.assign({}, subRowModel[subRowIndex])
            let subRowColumns = Object.assign([], modelSubRow.columns)
            let subRowColumnIndex = 0;

            subRowColumnIndex = subRowColumns.findIndex(m => m.column === parseInt(splitRow[2].split("-")[1].trim()))
            let subRowColumn = Object.assign({}, subRowModel[subRowIndex].columns[subRowColumnIndex]);
            subRowColumn.controlUiId = "";
            subRowColumn.fieldName = "";
            subRowColumn.dataType = 0;
            subRowColumn.controlType = 0;
            subRowColumns[subRowColumnIndex] = subRowColumn;
            modelSubRow.columns = subRowColumns
            subRowModel[subRowIndex] = modelSubRow
            column.subRows = subRowModel
            columns[columnIndex] = column;
            modelRow.columns = columns;
            model[index] = modelRow



        } else {
            if (control.indexOf("_") > -1) {
                let sourceRow = control.split("_")[1].split("-")[0].replace("Row", "").trim();
                let sourceColumn = control.split("_")[1].split("-")[1].trim();
                const rowIndex = model.findIndex(m => m.row === parseInt(sourceRow))
                let sourceModelRow = Object.assign({}, model[rowIndex])
                let sourceColumns = Object.assign([], sourceModelRow.columns)
                const sourceColumnIndex = sourceColumns.findIndex(m => m.column === parseInt(sourceColumn))
                let column = Object.assign({}, model[rowIndex].columns[sourceColumnIndex]);
                column.controlUiId = "";
                column.fieldName = "";
                column.dataType = 0;
                column.controlType = 0;
                sourceColumns[sourceColumnIndex] = column;
                sourceModelRow.columns = sourceColumns;
                model[rowIndex] = sourceModelRow

            }
        }

        //Add control to sub row column

        if (target.indexOf("SubRow") > -1) {
            //"Row1-2_SubRow1-2"
            let splitRow = target.split("_");
            let rootRow = splitRow[0].split("-")[0].replace("Row", "").replace("grd", "").trim();
            let rootColumn = splitRow[0].split("-")[1].trim();
            const index = model.findIndex(m => m.row === parseInt(rootRow))
            let modelRow = Object.assign({}, model[index])
            let columns = Object.assign([], modelRow.columns)
            const columnIndex = columns.findIndex(m => m.column === parseInt(rootColumn))
            let column = Object.assign({}, model[index].columns[columnIndex]);
            let subRowModel = Object.assign([], column.subRows);
            let subRowIndex = 0;
            subRowIndex = subRowModel.findIndex(m => m.row === parseInt(splitRow[1].split("-")[0].replace("SubRow", "").replace("grd", "").trim()))
            let modelSubRow = Object.assign({}, subRowModel[subRowIndex])
            let subRowColumns = Object.assign([], modelSubRow.columns)
            const subRowColumnIndex =
                subRowColumns.findIndex(m => m.column === parseInt(splitRow[1].split("-")[1].trim()))
            let subRowColumn = Object.assign({}, subRowModel[subRowIndex].columns[subRowColumnIndex]);
            subRowColumn.controlUiId = this.getControlId(control, target)
            subRowColumn.controlType = this.getControlType(control, target)
            let sourceTarget = "";
            if (control.indexOf("_") > -1 && control.indexOf("SubRow") === -1) {
                sourceTarget = control.split("_")[1].trim();
            } else {
                sourceTarget = control.split("_")[1] + "_" + control.split("_")[2];
            }
            let sourceControl = this.getControlId(control, sourceTarget)


            if (control.indexOf("grd") > -1 || target.indexOf("grd") !== -1) {
                if (control.indexOf("_") > -1) {
                    sourceControl = this.getControlId(control, control.split("_")[1] + "_" + control.split("_")[2])
                } else {
                    sourceControl = this.getControlId(control, target)

                }
                if (!isUpdate) {
                    this.setControlProperty(sectionType.Detail, updateType.Rename, sourceControl, subRowColumn.controlUiId, subRowColumn.controlType)
                }
            } else {
                if (!isUpdate) {
                    this.setControlProperty(sectionType.Header, updateType.Rename, sourceControl, subRowColumn.controlUiId, subRowColumn.controlType)
                }
            }

            subRowColumns[subRowColumnIndex] = subRowColumn;
            modelSubRow.columns = subRowColumns
            subRowModel[subRowIndex] = modelSubRow
            column.subRows = subRowModel
            columns[columnIndex] = column;
            modelRow.columns = columns;
            model[index] = modelRow


            if (copyModel[index].columns[columnIndex].subRows[subRowIndex].columns[subRowColumnIndex].controlUiId) {
                if (control.indexOf("SubRow") > -1) {
                    this.updateSubRowColumn(model, control, copyModel[index].columns[columnIndex].subRows[subRowIndex].columns[subRowColumnIndex].controlUiId)
                } else {

                    this.updateRowColumn(model, control, copyModel[index].columns[columnIndex].subRows[subRowIndex].columns[subRowColumnIndex].controlUiId)
                }
            }




        } else {

            let row = target.split("-")[0].replace("Row", "").trim();
            let columnValue = target.split("-")[1].trim();

            // let model = Object.assign([], this.props.formUI);

            const index = model.findIndex(m => m.row === parseInt(row))

            let modelRow = Object.assign({}, model[index])

            let columns = Object.assign([], modelRow.columns)

            const columnIndex = columns.findIndex(m => m.column === parseInt(columnValue))

            let column = Object.assign({}, model[index].columns[columnIndex]);
            column.controlUiId = this.getControlId(control, target)
            column.controlType = this.getControlType(control, target)
            column.length = !controlData.length ? 0 : parseInt(controlData.length)
            let sourceTarget = "";
            if (control.indexOf("_") > -1) {
                sourceTarget = control.split("_")[1].trim();
            }
            let sourceControl = "";

            if (control.indexOf("SubRow") !== -1) {
                sourceControl = this.getControlId(control, control.split("_")[1] + "_" + control.split("_")[2])
            } else {
                sourceControl = this.getControlId(control, sourceTarget)
            }

            if (!isUpdate) {
                this.setControlProperty(sectionType.Header, updateType.Rename, sourceControl, column.controlUiId, column.controlType, column.length)
            }
            columns[columnIndex] = column;
            modelRow.columns = columns;
            model[index] = modelRow


            if (copyModel[index].columns[columnIndex].controlUiId) {

                if (control.indexOf("SubRow") > -1) {
                    this.updateSubRowColumn(model, control, copyModel[index].columns[columnIndex].controlUiId)
                } else {
                    this.updateRowColumn(model, control, copyModel[index].columns[columnIndex].controlUiId)
                }
            }


        }
        this.props.dispatch(updateFormUI(model));
        this.controlWindowClose();
        this.loadControlSettings(false);
        selectedControlColumnId = target;
    }
    updateSubRowColumn = (model, target, data) => {
        if (target.indexOf("_") > -1) {
            let splitRow = target.split("_");
            let rootRow = splitRow[1].split("-")[0].replace("Row", "").replace("grd", "").trim();
            let rootColumn = splitRow[1].split("-")[1].trim();
            const index = model.findIndex(m => m.row === parseInt(rootRow))
            let modelRow = Object.assign({}, model[index])
            let columns = Object.assign([], modelRow.columns)
            const columnIndex = columns.findIndex(m => m.column === parseInt(rootColumn))
            let column = Object.assign({}, model[index].columns[columnIndex]);
            let subRowModel = Object.assign([], column.subRows);
            let subRowIndex = 0;

            subRowIndex = subRowModel.findIndex(m => m.row === parseInt(splitRow[2].split("-")[0].replace("SubRow", "").replace("grd", "").trim()))
            let modelSubRow = Object.assign({}, subRowModel[subRowIndex])
            let subRowColumns = Object.assign([], modelSubRow.columns)
            let subRowColumnIndex = 0;
            subRowColumnIndex = subRowColumns.findIndex(m => m.column === parseInt(splitRow[2].split("-")[1].trim()))

            let subRowColumn = Object.assign({}, subRowModel[subRowIndex].columns[subRowColumnIndex]);

            if (data.indexOf("grd") !== -1) {
                subRowColumn.controlUiId = this.getControlId(data, splitRow[1] + "_" + splitRow[2]);
                this.setControlProperty(sectionType.Detail, updateType.Rename, data, subRowColumn.controlUiId, subRowColumn.controlType)
            } else {
                subRowColumn.controlUiId = this.getControlId(data, splitRow[1] + "_" + splitRow[2]);
                this.setControlProperty(sectionType.Header, updateType.Rename, data, subRowColumn.controlUiId, subRowColumn.controlType)
            }
            subRowColumn.controlType = this.getControlType(data, target)

            subRowColumns[subRowColumnIndex] = subRowColumn;
            modelSubRow.columns = subRowColumns
            subRowModel[subRowIndex] = modelSubRow
            column.subRows = subRowModel
            columns[columnIndex] = column;
            modelRow.columns = columns;
            model[index] = modelRow
        }

        return model;
    }
    updateRowColumn = (model, target, data) => {
        if (target.indexOf("_") > -1) {
            let source = target.split("_")[1].trim();
            let sourceRow = source.split("-")[0].replace("Row", "").trim();
            let sourceColumn = source.split("-")[1].trim();
            const rowIndex = model.findIndex(m => m.row === parseInt(sourceRow))
            let sourceModelRow = Object.assign({}, model[rowIndex])
            let sourceColumns = Object.assign([], sourceModelRow.columns)
            const sourceColumnIndex = sourceColumns.findIndex(m => m.column === parseInt(sourceColumn))
            let column = Object.assign({}, model[rowIndex].columns[sourceColumnIndex]);
            column.controlUiId = this.getControlId(data, target.split("_")[1].trim());
            column.controlType = this.getControlType(data, target)
            this.setControlProperty(sectionType.Header, updateType.Rename, data, column.controlUiId, column.controlType)
            sourceColumns[sourceColumnIndex] = column;
            sourceModelRow.columns = sourceColumns;
            model[rowIndex] = sourceModelRow
        }
        return model;
    }
    getControlId = (control, target) => {
        if (control.indexOf("_") > -1) {
            control = control.split("_")[0].trim();
        }


        if (control === controlType.TextBox || control.indexOf("txtDiv") > -1) {
            return "txtDiv_" + target;
        }
        else if (control === controlType.DropdownList || control.indexOf("ddlDiv") > -1) {
            return "ddlDiv_" + target;
        }

        else if (control === controlType.SectionTitle || control.indexOf("secDiv") > -1) {
            return "secDiv_" + target;
        }
        else if (control === controlType.FileUpload || control.indexOf("fileDiv") > -1) {
            return "fileDiv_" + target;
        }
        else if (control === controlType.DatePicker || control.indexOf("dateDiv") > -1) {
            return "dateDiv_" + target;
        }
        else if (control === controlType.TimePicker || control.indexOf("dtpDiv") > -1) {
            return "dtpDiv_" + target;
        }
        else if (control === controlType.NumericTextBox || control.indexOf("intDiv") > -1) {
            return "intDiv_" + target;
        }
        else if (control === controlType.Signature || control.indexOf("signDiv") > -1) {
            return "signDiv_" + target;
        }
        else if (control === controlType.Button || control.indexOf("btnDiv") > -1) {
            return "btnDiv_" + target;
        }

        else if (control === controlType.Image || control.indexOf("imageDiv") > -1) {
            return "imageDiv_" + target;
        }
        else if (control === controlType.CheckBox || control.indexOf("chkDiv") > -1) {
            return "chkDiv_" + target;
        }

    }
    getControlType = (control, target) => {
        if (control.indexOf("_") > -1) {
            control = control.split("_")[0].trim();
        }

        if (control === controlType.TextBox || control.indexOf("txtDiv") > -1) {
            return controlType.TextBox;

        }
        else if (control === controlType.DropdownList || control.indexOf("ddlDiv") > -1) {
            return controlType.DropdownList;
        }

        else if (control === controlType.SectionTitle || control.indexOf("secDiv") > -1) {
            return controlType.SectionTitle;
        }
        else if (control === controlType.FileUpload || control.indexOf("fileDiv") > -1) {
            return controlType.FileUpload;
        }
        else if (control === controlType.DatePicker || control.indexOf("dateDiv") > -1) {
            return controlType.DatePicker;
        }
        else if (control === controlType.TimePicker || control.indexOf("dtpDiv") > -1) {
            return controlType.TimePicker;
        }
        else if (control === controlType.NumericTextBox || control.indexOf("intDiv") > -1) {
            return controlType.NumericTextBox;

        }
        else if (control === controlType.Signature || control.indexOf("signDiv") > -1) {
            return controlType.Signature;
        }
        else if (control === controlType.Button || control.indexOf("btnDiv") > -1) {
            return controlType.Button;
        }

        else if (control === controlType.Image || control.indexOf("imageDiv") > -1) {
            return controlType.Image;
        }
        else if (control === controlType.CheckBox || control.indexOf("chkDiv") > -1) {
            return controlType.CheckBox;
        }
        else {
            return controlType.Select;
        }

    }
    setColumnControlIdSubRow = (data) => {
        for (let i = 0; i < data.columns.length; i++) {
            let column = data.columns[i];
            column.controlId = column.controlId.replace("Row" + data.Row + "-" + column.column, "Row" + data.Row + 1 + "-" + column.column)

        }

    }
    addRowAbove = (row) => {
        if (this.state.validationResults.length > 0) {
            return false;
        }
        selectedColumn = "";
        formSectionList = "";
        fieldName = "";
        const model = [].concat(this.props.formUI)
            .sort((a, b) => a.row > b.row ? 1 : -1)
        if (row.indexOf('SubRow') > -1) {
            this.addSubRowAbove(row);
        } else {
            row = parseInt(row.replace("Row", "").trim());
            let arrayCopy = [];
            for (let i = 0; i < model.length; i++) {
                let data = Object.assign({}, model[i])

                if (data.row > row) {
                    data.columns = this.resetColumnControlId(data.row, data.columns, 1)
                    data.row = data.row + 1

                } else {
                    if (data.row === row) {
                        arrayCopy.push(Object.assign({}, data));
                        data.columns = this.resetColumnControlId(data.row, data.columns, 1)
                        data.row = data.row + 1

                    }

                }

                model[i] = data;
            }
            for (let i = 0; i < arrayCopy.length; i++) {
                let data = arrayCopy[i]
                data.columns = this.clearControlId(arrayCopy[i].columns)
                model.push(data)

            }

            const sorted = [].concat(model)
                .sort((a, b) => a.row > b.row ? 1 : -1)

            this.dispatchControl(sorted)

        }

    }
    addSubRowAbove = (rowId) => {

        if (this.state.validationResults.length > 0) {
            return false;
        }
        const model = [].concat(this.props.formUI)
            .sort((a, b) => a.row > b.row ? 1 : -1)

        //"Row1-1_SubRow1"


        let splitSubRow = rowId.split("_")
        let splitRow = splitSubRow[0].split("-")

        let rootRowIndex = model.findIndex(row => row.row === parseInt(splitRow[0].replace("Row", "").trim()));
        let rootColumnIndex = model[rootRowIndex].columns.findIndex(column => column.column === parseInt(splitRow[1].trim()));
        let rootModel = Object.assign({}, model[rootRowIndex])
        let rootColumn = Object.assign([], model[rootRowIndex].columns)
        let subRowColumn = Object.assign({}, model[rootRowIndex].columns[rootColumnIndex])
        let subRowId = parseInt(splitSubRow[1].replace("SubRow", "").trim());
        let subRowArray = Object.assign([], subRowColumn.subRows)
        let arrayCopy = [];
        for (let i = 0; i < subRowColumn.subRows.length; i++) {
            let data = Object.assign({}, subRowColumn.subRows[i])

            if (data.row > subRowId) {
                data.columns = this.resetSubRowsControlId(data.row, data.columns, 1)
                data.row = data.row + 1
                subRowArray[i] = data
            }
            else {
                if (data.row === subRowId) {

                    arrayCopy.push(Object.assign({}, data));
                    data.columns = this.resetSubRowsControlId(data.row, data.columns, 1)
                    data.row = data.row + 1
                    subRowArray[i] = data
                }

            }

        }
        for (let i = 0; i < arrayCopy.length; i++) {
            let data = arrayCopy[i]
            data.columns = this.clearControlId(arrayCopy[i].columns)
            subRowArray.push(data)

        }

        const sortedArray = [].concat(subRowArray)
            .sort((a, b) => a.row > b.row ? 1 : -1)


        subRowColumn.subRows = sortedArray
        rootColumn[rootColumnIndex] = subRowColumn
        rootModel.columns = rootColumn;
        model[rootRowIndex] = rootModel
        const sorted = [].concat(model)
            .sort((a, b) => a.row > b.row ? 1 : -1)

        this.dispatchControl(sorted)

    }
    addRowBelow = (row) => {

        if (this.state.validationResults.length > 0) {
            return false;
        }
        selectedColumn = "";
        formSectionList = "";
        fieldName = "";
        const model = [].concat(this.props.formUI)
            .sort((a, b) => a.row > b.row ? 1 : -1)
        if (row.indexOf('SubRow') > -1) {
            this.addSubRowBelow(row);

        } else {
            row = parseInt(row.replace("Row", "").trim());
            let arrayCopy = [];
            for (let i = 0; i < model.length; i++) {
                let data = Object.assign({}, model[i])
                if (data.row > row) {

                    data.columns = this.resetColumnControlId(data.row, data.columns, 1)
                    data.row = data.row + 1
                    model[i] = data;

                } else {
                    if (data.row === row) {
                        arrayCopy.push(Object.assign({}, data));

                    }

                }


            }
            for (let i = 0; i < arrayCopy.length; i++) {
                let data = arrayCopy[i]
                data.row = data.row + 1
                data.columns = this.clearControlId(arrayCopy[i].columns)
                model.push(data)
            }

            const sorted = [].concat(model)
                .sort((a, b) => a.row > b.row ? 1 : -1)


            this.dispatchControl(sorted)
        }

    }
    addSubRowBelow = (rowId) => {

        if (this.state.validationResults.length > 0) {
            return false;
        }
        const model = [].concat(this.props.formUI)
            .sort((a, b) => a.row > b.row ? 1 : -1)


        let splitSubRow = rowId.split("_")
        let splitRow = splitSubRow[0].split("-")

        let rootRowIndex = model.findIndex(row => row.row === parseInt(splitRow[0].replace("Row", "").trim()));
        let rootColumnIndex = model[rootRowIndex].columns.findIndex(column => column.column === parseInt(splitRow[1].trim()));
        let rootModel = Object.assign({}, model[rootRowIndex])
        let rootColumn = Object.assign([], model[rootRowIndex].columns)
        let subRowColumn = Object.assign({}, model[rootRowIndex].columns[rootColumnIndex])
        let subRowId = parseInt(splitSubRow[1].replace("SubRow", "").trim());
        let subRowArray = Object.assign([], subRowColumn.subRows)
        let arrayCopy = [];
        for (let i = 0; i < subRowColumn.subRows.length; i++) {
            let data = Object.assign({}, subRowColumn.subRows[i])

            if (data.row > subRowId) {

                data.columns = this.resetSubRowsControlId(data.row, data.columns, 1)
                data.row = data.row + 1
                subRowArray[i] = data
            }
            else {
                if (data.row === subRowId) {
                    arrayCopy.push(Object.assign({}, data));

                }

            }

        }


        for (let i = 0; i < arrayCopy.length; i++) {
            let data = arrayCopy[i]
            data.row = data.row + 1
            data.columns = this.clearControlId(arrayCopy[i].columns)
            subRowArray.push(data)

        }

        const sortedArray = [].concat(subRowArray)
            .sort((a, b) => a.row > b.row ? 1 : -1)
        subRowColumn.subRows = sortedArray
        rootColumn[rootColumnIndex] = subRowColumn
        rootModel.columns = rootColumn;
        model[rootRowIndex] = rootModel
        const sorted = [].concat(model)
            .sort((a, b) => a.row > b.row ? 1 : -1)
        this.dispatchControl(sorted)


    }
    clearControlId = (columnData) => {
        let column = Object.assign([], columnData)

        for (let i = 0; i < column.length; i++) {
            let data = Object.assign({}, column[i])
            data.controlUiId = ""
            data.fieldName = ""
            data.controlType = 0
            data.dataType = 0
            if (data.rowColumnId.indexOf("grd") !== -1) {
                data.rowColumnId = "";
                data.subRows = [];
                column[i] = data;
                //break;
            }
            if (column[i].subRows.length > 0) {
                let subRows = Object.assign([], column[i].subRows)
                for (let j = 0; j < column[i].subRows.length; j++) {
                    let subRowColumn = Object.assign({}, column[i].subRows[j])
                    subRowColumn.columns = this.clearSubRowControlId(subRowColumn.columns)
                    subRows[j] = subRowColumn
                }
                data.subRows = subRows;
            }


            column[i] = data;

        }

        return column;

    }
    clearSubRowControlId = (columnData) => {
        let column = Object.assign([], columnData)

        for (let i = 0; i < column.length; i++) {
            let data = Object.assign({}, column[i])
            data.controlUiId = ""
            data.fieldName = ""
            data.controlType = 0
            data.dataType = 0
            column[i] = data;

        }

        return column;

    }
    resetColumnControlId = (rowId, column, value) => {
        let columns = Object.assign([], column)
        for (let i = 0; i < columns.length; i++) {
            let data = Object.assign({}, columns[i])
            if (data.controlUiId) {
                let oldControlId = data.controlUiId
                data.controlUiId = data.controlUiId.replace(rowId + "-" + data.column, rowId + value + "-" + data.column)
                // this.setControlProperty(sectionType.Header, updateType.Rename, oldControlId, data.controlUiId, data.controlType)
                this.setControlPropertyList(sectionType.Header, updateType.Rename, oldControlId, data.controlUiId, data.controlType)
            }

            if (data.subRows.length > 0) {
                let subRow = Object.assign([], data.subRows)
                for (let j = 0; j < data.subRows.length; j++) {
                    let subRowData = Object.assign({}, data.subRows[j])
                    subRowData.columns = this.resetSubRowControlId(rowId, data.column, subRowData.columns, value)
                    subRow[j] = subRowData
                }
                data.subRows = subRow
            }
            columns[i] = data

        }

        return columns;

    }
    deleteColumnControlId = (rowId, column) => {
        let columns = Object.assign([], column)
        for (let i = 0; i < columns.length; i++) {
            let data = Object.assign({}, columns[i])
            if (data.controlUiId) {
                let oldControlId = data.controlUiId
                this.setControlPropertyList(sectionType.Header, updateType.Delete, oldControlId, data.controlUiId, data.controlType)
            }

            if (data.subRows.length > 0) {
                for (let j = 0; j < data.subRows.length; j++) {
                    let subRowData = Object.assign({}, data.subRows[j])
                    this.deleteSubRowControlId(rowId, subRowData.columns)

                }

            }

        }

        return "";

    }
    deleteSubRowControlId = (rowId, column) => {
        let columns = Object.assign([], column)
        for (let i = 0; i < columns.length; i++) {
            let data = Object.assign({}, columns[i])
            if (data.controlUiId) {
                let oldControlId = data.controlUiId
                if (oldControlId.indexOf("grd") !== -1) {
                    this.setControlPropertyList(sectionType.Detail, updateType.Delete, oldControlId, data.controlUiId, data.controlType)
                } else {
                    this.setControlPropertyList(sectionType.Header, updateType.Delete, oldControlId, data.controlUiId, data.controlType)
                }


            }
        }

        return ""

    }
    resetSubRowControlId = (rowId, columnId, column, value) => {
        let columns = Object.assign([], column)
        for (let i = 0; i < columns.length; i++) {
            let data = Object.assign({}, columns[i])
            if (data.controlUiId) {
                let oldControlId = data.controlUiId
                data.controlUiId = data.controlUiId.replace("Row" + rowId + "-" + columnId, "Row" + (rowId + value) + "-" + columnId)
                if (oldControlId.indexOf("grd") !== -1) {
                    this.setControlPropertyList(sectionType.Detail, updateType.Rename, oldControlId, data.controlUiId, data.controlType)
                } else {
                    this.setControlPropertyList(sectionType.Header, updateType.Rename, oldControlId, data.controlUiId, data.controlType)
                }

                columns[i] = data
            }
        }

        return columns

    }
    resetSubRowsControlId = (rowId, column, value) => {
        let columns = Object.assign([], column)
        for (let i = 0; i < columns.length; i++) {
            let data = Object.assign({}, columns[i])
            if (data.controlUiId) {
                let oldControlId = data.controlUiId
                data.controlUiId = data.controlUiId.replace("SubRow" + rowId + "-" + data.column, "SubRow" + (rowId + value) + "-" + data.column)
                if (oldControlId.indexOf("grd") !== -1) {
                    this.setControlPropertyList(sectionType.Detail, updateType.Rename, oldControlId, data.controlUiId, data.controlType)
                } else {
                    this.setControlPropertyList(sectionType.Header, updateType.Rename, oldControlId, data.controlUiId, data.controlType)
                }

                columns[i] = data
            }
        }

        return columns

    }
    deleteSubRowsControlId = (rowId, column) => {
        let columns = Object.assign([], column)
        for (let i = 0; i < columns.length; i++) {
            let data = Object.assign({}, columns[i])
            if (data.controlUiId) {
                let oldControlId = data.controlUiId
                if (oldControlId.indexOf("grd") !== -1) {
                    this.setControlPropertyList(sectionType.Detail, updateType.Delete, oldControlId, data.controlUiId, data.controlType)
                } else { this.setControlPropertyList(sectionType.Header, updateType.Delete, oldControlId, data.controlUiId, data.controlType) }

            }
        }

        return ""

    }


    editRow = (rowId, event) => {

        if (this.state.validationResults.length > 0) {
            return false;
        }

        selectedColumn = "";
        let rowArray = this.props.formUI;
        let format = "";
        if (rowId.indexOf('SubRow') > -1) {

            let splitSubRow = rowId.split("_")
            let splitRow = splitSubRow[0].split("-")
            let rootRowIndex = rowArray.findIndex(row => row.row === parseInt(splitRow[0].replace("Row", "").trim()));
            let rootColumnIndex = rowArray[rootRowIndex].columns.findIndex(column => column.column === parseInt(splitRow[1].trim()));
            let subRowColumn = Object.assign({}, rowArray[rootRowIndex].columns[rootColumnIndex])
            let subRowColumnIndex = subRowColumn.subRows.findIndex(row => row.row === parseInt(splitSubRow[1].replace("SubRow", "").trim()))

            for (var j = 0; j < subRowColumn.subRows[subRowColumnIndex].columns.length; j++) {
                let data = subRowColumn.subRows[subRowColumnIndex].columns[j]
                format += data.width + ",";
            }

            this.setState({ rowEditor: true, columnEditor: false, selectedRow: rowId, columnFormat: format.replace(/,\s*$/, ""), columnCount: subRowColumn.subRows[subRowColumnIndex].columns.length, anchorElement: event.currentTarget })

        } else {
            rowId = parseInt(rowId.replace("Row", "").trim());
            const rowIndex = rowArray.findIndex(row => row.row === rowId)
            for (j = 0; j < rowArray[rowIndex].columns.length; j++) {
                let data = rowArray[rowIndex].columns[j]
                format += data.width + ",";
            }
            this.setState({ rowEditor: true, columnEditor: false, selectedRow: rowId, columnFormat: format.replace(/,\s*$/, ""), columnCount: rowArray[rowIndex].columns.length, anchorElement: event.currentTarget })
        }
    }
    deleteControl = (target) => {
        if (this.state.validationResults.length > 0) {
            return false;
        }
        // if (this.canInvokeEvent()) {
        selectedColumn = "";
        let model = Object.assign([], this.props.formUI);
        if (target.indexOf("SubRow") > -1) {

            let splitRow = target.split("_");
            let rootRow = splitRow[0].split("-")[0].replace("Row", "").replace("grd", "").trim();
            let rootColumn = splitRow[0].split("-")[1].trim();


            const index = model.findIndex(m => m.row === parseInt(rootRow))

            let modelRow = Object.assign({}, model[index])

            let columns = Object.assign([], modelRow.columns)

            const columnIndex = columns.findIndex(m => m.column === parseInt(rootColumn))

            let column = Object.assign({}, model[index].columns[columnIndex]);

            let subRowModel = Object.assign([], column.subRows);

            let subRowIndex = subRowModel.findIndex(m => m.row === parseInt(splitRow[1].split("-")[0].replace("SubRow", "").replace("grd", "").trim()))

            let modelSubRow = Object.assign({}, subRowModel[subRowIndex])
            let subRowColumns = Object.assign([], modelSubRow.columns)
            let subRowColumnIndex = subRowColumns.findIndex(m => m.column === parseInt(splitRow[1].split("-")[1].trim()))
            let subRowColumn = Object.assign({}, subRowModel[subRowIndex].columns[subRowColumnIndex]);
            if (target.indexOf("grd") !== -1) {
                this.setControlProperty(sectionType.Detail, updateType.Delete, subRowColumn.controlUiId)
            } else {
                this.setControlProperty(sectionType.Header, updateType.Delete, subRowColumn.controlUiId)
            }
            subRowColumn.action = [];
            subRowColumn.controlId = "";
            subRowColumn.caption = "";
            subRowColumn.controlUiId = "";
            subRowColumn.fieldName = "";
            subRowColumn.dataType = 0;
            subRowColumn.controlType = 0;
            subRowColumns[subRowColumnIndex] = subRowColumn;
            modelSubRow.columns = subRowColumns
            subRowModel[subRowIndex] = modelSubRow
            column.subRows = subRowModel
            columns[columnIndex] = column;
            modelRow.columns = columns;
            model[index] = modelRow



        } else {

            let sourceRow = target.split("-")[0].replace("Row", "").trim();
            let sourceColumn = target.split("-")[1].trim();
            const rowIndex = model.findIndex(m => m.row === parseInt(sourceRow))
            let sourceModelRow = Object.assign({}, model[rowIndex])
            let sourceColumns = Object.assign([], sourceModelRow.columns)
            const sourceColumnIndex = sourceColumns.findIndex(m => m.column === parseInt(sourceColumn))
            let column = Object.assign({}, model[rowIndex].columns[sourceColumnIndex]);
            this.setControlProperty(sectionType.Header, updateType.Delete, column.controlUiId)
            column.action = [];
            column.controlId = "";
            column.caption = "";
            column.controlUiId = "";
            column.fieldName = "";
            column.dataType = 0;
            column.controlType = 0
            sourceColumns[sourceColumnIndex] = column;
            sourceModelRow.columns = sourceColumns;
            model[rowIndex] = sourceModelRow

        }

        this.props.dispatch(updateFormUI(model));
        // }

    }
    editControl = (target) => {

        console.log(target);
        selectedControlColumnId = target;

        if (this.state.validationResults.length > 0) {
            return false;
        }
        let model = Object.assign([], this.props.formUI);
        if (target.indexOf("SubRow") > -1) {
            let splitRow = target.split("_");
            let rootRow = splitRow[0].split("-")[0].replace("Row", "").replace("grd", "").trim();
            let rootColumn = splitRow[0].split("-")[1].trim();


            const index = model.findIndex(m => m.row === parseInt(rootRow))

            let modelRow = Object.assign({}, model[index])

            let columns = Object.assign([], modelRow.columns)

            const columnIndex = columns.findIndex(m => m.column === parseInt(rootColumn))

            let column = Object.assign({}, model[index].columns[columnIndex]);

            let subRowModel = Object.assign([], column.subRows);

            const subRowIndex = subRowModel.findIndex(m => m.row ===
                parseInt(splitRow[1].split("-")[0].replace("SubRow", "").replace("grd", "").trim()))
            let modelSubRow = Object.assign({}, subRowModel[subRowIndex])
            let subRowColumns = Object.assign([], modelSubRow.columns)
            const subRowColumnIndex =
                subRowColumns.findIndex(m => m.column === parseInt(splitRow[1].split("-")[1].trim()))
            let subRowColumn = Object.assign({}, subRowModel[subRowIndex].columns[subRowColumnIndex]);
            if (target.indexOf("grd") > -1) {
                this.setControlProperty(sectionType.Detail, updateType.Edit, subRowColumn.controlUiId, subRowColumn.controlUiId, subRowColumn.controlType)
            } else {
                this.setControlProperty(sectionType.Header, updateType.Edit, subRowColumn.controlUiId, subRowColumn.controlUiId, subRowColumn.controlType)
            }

        } else {

            let sourceRow = target.split("-")[0].replace("Row", "").trim();
            let sourceColumn = target.split("-")[1].trim();
            const rowIndex = model.findIndex(m => m.row === parseInt(sourceRow))
            let sourceModelRow = Object.assign({}, model[rowIndex])
            let sourceColumns = Object.assign([], sourceModelRow.columns)
            const sourceColumnIndex = sourceColumns.findIndex(m => m.column === parseInt(sourceColumn))
            let column = Object.assign({}, model[rowIndex].columns[sourceColumnIndex]);
            this.setControlProperty(sectionType.Header, updateType.Edit, column.controlUiId, column.controlUiId, column.controlType)
        }

        this.loadControlSettings(true);

    }
    editControlRoles = (target, event) => {
        console.log("editControlRoles", target, event)
        console.log(target);
        selectedControlColumnId = target;

        if (this.state.validationResults.length > 0) {
            return false;
        }
        let model = Object.assign([], this.props.formUI);
        if (target && target.indexOf("SubRow") > -1) {
            let splitRow = target.split("_");
            let rootRow = splitRow[0].split("-")[0].replace("Row", "").replace("grd", "").trim();
            let rootColumn = splitRow[0].split("-")[1].trim();


            const index = model.findIndex(m => m.row === parseInt(rootRow))

            let modelRow = Object.assign({}, model[index])

            let columns = Object.assign([], modelRow.columns)

            const columnIndex = columns.findIndex(m => m.column === parseInt(rootColumn))

            let column = Object.assign({}, model[index].columns[columnIndex]);

            let subRowModel = Object.assign([], column.subRows);

            const subRowIndex = subRowModel.findIndex(m => m.row ===
                parseInt(splitRow[1].split("-")[0].replace("SubRow", "").replace("grd", "").trim()))
            let modelSubRow = Object.assign({}, subRowModel[subRowIndex])
            let subRowColumns = Object.assign([], modelSubRow.columns)
            const subRowColumnIndex =
                subRowColumns.findIndex(m => m.column === parseInt(splitRow[1].split("-")[1].trim()))
            let subRowColumn = Object.assign({}, subRowModel[subRowIndex].columns[subRowColumnIndex]);
            if (target.indexOf("grd") > -1) {
                this.setControlProperty(sectionType.Detail, updateType.Role, subRowColumn.controlUiId, subRowColumn.controlUiId, subRowColumn.controlType)
            } else {
                this.setControlProperty(sectionType.Header, updateType.Role, subRowColumn.controlUiId, subRowColumn.controlUiId, subRowColumn.controlType)
            }

        } else {

            let sourceRow = target.split("-")[0].replace("Row", "").trim();
            let sourceColumn = target.split("-")[1].trim();
            const rowIndex = model.findIndex(m => m.row === parseInt(sourceRow))
            let sourceModelRow = Object.assign({}, model[rowIndex])
            let sourceColumns = Object.assign([], sourceModelRow.columns)
            const sourceColumnIndex = sourceColumns.findIndex(m => m.column === parseInt(sourceColumn))
            let column = Object.assign({}, model[rowIndex].columns[sourceColumnIndex]);
            this.setControlProperty(sectionType.Header, updateType.Role, column.controlUiId, column.controlUiId, column.controlType)
        }

        this.loadControlSettings(true);
    }
    getPrimaryUI = (controlValue, fieldName, classes) => {

        if (controlValue.isPrimaryField) {

            return (<Typography variant="customPrimary" className={this.props.classes.spanPrimary}>{controlValue.controlUiId.indexOf("grd") !== -1 ? "L" : "P"}</Typography>)



        } else {
            return ""
        }

    }

    iconClass = (controlId) => {

        return `${this.props.classes.iconColor} ${this.state.OpenControlSettings && this.props.activeFormControl && this.props.activeFormControl.ControlUiId === controlId ? this.props.classes.selectedControl : ""}`
    }

    captionClass = (controlId) => {

        return `${this.props.classes.subtitle3} ${this.props.classes.controlName} pointerIcon ${this.state.OpenControlSettings && this.props.activeFormControl && this.props.activeFormControl.ControlUiId === controlId ? this.props.classes.selectedControl : ""}`
    }

    fieldNameClass = (controlId) => {

        return `${this.props.classes.customLabel} ${this.props.classes.formControl} spanControlFormUi pointerIcon ${this.state.OpenControlSettings && this.props.activeFormControl && this.props.activeFormControl.ControlUiId === controlId ? this.props.classes.selectedControl : ""}`
    }
     
    setControl = (controlId, source, classes) => {

        if (controlId) {
            let fieldName = "";
            const controlValue = this.getControlProperty(controlId)
            if (controlValue !== "") {

                fieldName = controlValue.caption === '' ? controlValue.fieldName : controlValue.caption;

            }


            if (controlId.indexOf("txt") > -1) {

                if (fieldName === "") {
                    fieldName = "Text box"
                }
                return (<DragDropContainer dragData={controlType.TextBox + "_" + source} targetKey="formContainer" onDrop={this.handleDrop} >
                    <Grid container >

                        <Grid item xs={12} id={controlId} >

                            <Grid container justifyContent="space-between" spacing={0} className={classes.deleteFormControlRow + ' ' + classes.deleteFormControlItem}><Grid item xs={12} sm={6} >
                            </Grid><Grid item xs={12} sm={6} ><RoleIcon className={classes.formRoleIcon + ' pointerIcon'} onClick={(event) => { this.editControlRoles(source, event) }} />
                                    <DeleteButton className="formControl pointerIcon" fontSize="small" onClick={() => { this.deleteControl(source) }} /></Grid></Grid>

                            <Grid container className={classes.controlArea} onClick={(event) => { this.editControl(source, event) }} >

                                <Grid item >
                                    <Grid container justifyContent="flex-start" spacing={0} className={classes.controlSecondRow}>
                                        <Grid item xs={12}>
                                            <Grid container >
                                                <Grid item className={`${classes.textBoxIcon}`}><FormatColorTextOutlinedIcon className={this.iconClass(controlId)} /></Grid>
                                                <Grid item><Typography variant="subtitle3" className={this.captionClass(controlId)}  >{controlValue.listId && controlValue.listId !== 0 ? 'Text Box (LL)' : 'Text Box'}</Typography></Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container justifyContent="flex-start" spacing={0} className={classes.controlFieldNameRow}>
                                        <Grid item xs={12} sm={12} className={classes.controlFieldNameItem} >
                                            <Typography className={this.fieldNameClass(controlId)}>{fieldName}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </DragDropContainer>)

            }
            if (controlId.indexOf("sec") > -1) {
                if (fieldName === "") {
                    fieldName = "Section Title"
                }
                return <DragDropContainer dragData={controlType.SectionTitle + "_" + source} targetKey="formContainer" onDrop={this.handleDrop} >
                    <Grid container >

                        <Grid item xs={12} id={controlId} >

                            <Grid container justifyContent="space-between" spacing={0} className={classes.deleteFormControlRow + ' ' + classes.deleteFormControlItem}>
                                <Grid item xs={12} sm={6} ></Grid><Grid item xs={12} sm={6} >
                                    <RoleIcon className={classes.formRoleIcon + ' pointerIcon'} onClick={(event) => { this.editControlRoles(source, event) }} />
                                    <DeleteButton className="formControl pointerIcon" fontSize="small" onClick={() => { this.deleteControl(source) }} /></Grid></Grid>

                            <Grid container className={classes.controlArea} onClick={(event) => { this.editControl(source, event) }} >

                                <Grid item >
                                    <Grid container justifyContent="flex-start" spacing={0} className={classes.controlSecondRow}>
                                        <Grid item xs={12}>
                                            <Grid container >
                                                <Grid item className={classes.textBoxIcon}><TitleIcon className={this.iconClass(controlId)} /></Grid>
                                                <Grid item><Typography variant="subtitle3" className={this.captionClass(controlId)}  >Section Title</Typography></Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container justifyContent="flex-start" spacing={0} className={classes.controlFieldNameRow}>
                                        <Grid item xs={12} sm={12} className={classes.controlFieldNameItem} >
                                            <Typography className={this.fieldNameClass(controlId)}>{fieldName}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>

                </DragDropContainer>
            }
            else if (controlId.indexOf("ddl") > -1) {
                if (fieldName === "") {
                    fieldName = "Drop down menu"
                }
                return <DragDropContainer dragData={controlType.DropdownList + "_" + source} targetKey="formContainer" onDrop={this.handleDrop} >

                    <Grid container >

                        <Grid item xs={12} id={controlId} >

                            <Grid container justifyContent="space-between" spacing={0} className={classes.deleteFormControlRow + ' ' + classes.deleteFormControlItem}>
                                <Grid item xs={12} sm={6} ></Grid><Grid item xs={12} sm={6} ><RoleIcon className={classes.formRoleIcon + ' pointerIcon'} onClick={(event) => { this.editControlRoles(source, event) }} /><DeleteButton className="formControl pointerIcon" fontSize="small" onClick={() => { this.deleteControl(source) }} /></Grid></Grid>

                            <Grid container className={classes.controlArea} onClick={(event) => { this.editControl(source, event) }} >

                                <Grid item >
                                    <Grid container justifyContent="flex-start" spacing={0} className={classes.controlSecondRow}>
                                        <Grid item xs={12}>
                                            <Grid container >
                                                <Grid item className={classes.textBoxIcon}><ArrowDropDownCircleOutlinedIcon className={this.iconClass(controlId)} /></Grid>
                                                <Grid item><Typography variant="subtitle3" className={this.captionClass(controlId)}  >Select Menu</Typography></Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container justifyContent="flex-start" spacing={0} className={classes.controlFieldNameRow}>
                                        <Grid item xs={12} sm={12} className={classes.controlFieldNameItem} >
                                            <Typography className={this.fieldNameClass(controlId)}>{fieldName}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>

                </DragDropContainer>
            }
            else if (controlId.indexOf("chk") > -1) {
                if (fieldName === "") {
                    fieldName = "Checkbox"
                }
                return <DragDropContainer dragData={controlType.CheckBox + "_" + source} targetKey="formContainer" onDrop={this.handleDrop} >

                    <Grid container >

                        <Grid item xs={12} id={controlId} >

                            <Grid container justifyContent="space-between" spacing={0} className={classes.deleteFormControlRow + ' ' + classes.deleteFormControlItem}>
                                <Grid item xs={12} sm={6} ></Grid>
                                <Grid item xs={12} sm={6} >
                                    <RoleIcon className={classes.formRoleIcon + ' pointerIcon'} onClick={(event) => { this.editControlRoles(source, event) }} />
                                    <DeleteButton className="formControl pointerIcon" fontSize="small" onClick={() => { this.deleteControl(source) }} />
                                </Grid>
                            </Grid>

                            <Grid container className={classes.controlArea} onClick={(event) => { this.editControl(source, event) }} >

                                <Grid item >
                                    <Grid container justifyContent="flex-start" spacing={0} className={classes.controlSecondRow}>
                                        <Grid item xs={12}>
                                            <Grid container >
                                                <Grid item className={classes.textBoxIcon}><CheckBoxOutlinedIcon className={this.iconClass(controlId)} /></Grid>
                                                <Grid item><Typography variant="subtitle3" className={this.captionClass(controlId)}  >Checkbox</Typography></Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container justifyContent="flex-start" spacing={0} className={classes.controlFieldNameRow}>
                                        <Grid item xs={12} sm={12} className={classes.controlFieldNameItem} >
                                            <Typography className={this.fieldNameClass(controlId)}>{fieldName}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>

                </DragDropContainer>
            }
            else if (controlId.indexOf("file") > -1) {
                if (fieldName === "") {
                    fieldName = "File"
                }
                return <DragDropContainer dragData={controlType.FileUpload + "_" + source} targetKey="formContainer" onDrop={this.handleDrop} >
                    <Grid container >

                        <Grid item xs={12} id={controlId} >

                            <Grid container justifyContent="space-between" spacing={0} className={classes.deleteFormControlRow + ' ' + classes.deleteFormControlItem}>
                                <Grid item xs={12} sm={6} ></Grid><Grid item xs={12} sm={6} ><RoleIcon className={classes.formRoleIcon + ' pointerIcon'} onClick={(event) => { this.editControlRoles(source, event) }} /><DeleteButton className="formControl pointerIcon" fontSize="small" onClick={() => { this.deleteControl(source) }} /></Grid></Grid>

                            <Grid container className={classes.controlArea} onClick={(event) => { this.editControl(source, event) }} >

                                <Grid item >
                                    <Grid container justifyContent="flex-start" spacing={0} className={classes.controlSecondRow}>
                                        <Grid item xs={12}>
                                            <Grid container >
                                                <Grid item className={classes.textBoxIcon}><UploadFileIcon className={this.iconClass(controlId)} /></Grid>
                                                <Grid item><Typography variant="subtitle3" className={this.captionClass(controlId)}  >File Upload</Typography></Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container justifyContent="flex-start" spacing={0} className={classes.controlFieldNameRow}>
                                        <Grid item xs={12} sm={12} className={classes.controlFieldNameItem} >
                                            <Typography className={this.fieldNameClass(controlId)}>{fieldName}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </DragDropContainer>
            }
            else if (controlId.indexOf("date") > -1) {
                if (fieldName === "") {
                    fieldName = "Date picker"
                }
                return <DragDropContainer dragData={controlType.DatePicker + "_" + source} targetKey="formContainer" onDrop={this.handleDrop} >
                    <Grid container >

                        <Grid item xs={12} id={controlId} >

                            <Grid container justifyContent="space-between" spacing={0} className={classes.deleteFormControlRow + ' ' + classes.deleteFormControlItem}>
                                <Grid item xs={12} sm={6} ></Grid><Grid item xs={12} sm={6} ><RoleIcon className={classes.formRoleIcon + ' pointerIcon'} onClick={(event) => { this.editControlRoles(source, event) }} /><DeleteButton className="formControl pointerIcon" fontSize="small" onClick={() => { this.deleteControl(source) }} /></Grid></Grid>

                            <Grid container className={classes.controlArea} onClick={(event) => { this.editControl(source, event) }} >

                                <Grid item >
                                    <Grid container justifyContent="flex-start" spacing={0} className={classes.controlSecondRow}>
                                        <Grid item xs={12}>
                                            <Grid container >
                                                <Grid item className={classes.textBoxIcon}><DateRangeIcon className={this.iconClass(controlId)} /></Grid>
                                                <Grid item><Typography variant="subtitle3" className={this.captionClass(controlId)}  >Date Picker</Typography></Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container justifyContent="flex-start" spacing={0} className={classes.controlFieldNameRow}>
                                        <Grid item xs={12} sm={12} className={classes.controlFieldNameItem} >
                                            <Typography className={this.fieldNameClass(controlId)}>{fieldName}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>


                </DragDropContainer>
            }
            else if (controlId.indexOf("dtp") > -1) {
                if (fieldName === "") {
                    fieldName = "Time Picker"
                }
                return <DragDropContainer dragData={controlType.TimePicker + "_" + source} targetKey="formContainer" onDrop={this.handleDrop} >

                    <Grid container >

                        <Grid item xs={12} id={controlId} >

                            <Grid container justifyContent="space-between" spacing={0} className={classes.deleteFormControlRow + ' ' + classes.deleteFormControlItem}><Grid item xs={12} sm={6} ></Grid>
                                <Grid item xs={12} sm={6} ><RoleIcon className={classes.formRoleIcon + ' pointerIcon'} onClick={(event) => { this.editControlRoles(source, event) }} /><DeleteButton className="formControl pointerIcon" fontSize="small" onClick={() => { this.deleteControl(source) }} /></Grid></Grid>

                            <Grid container className={classes.controlArea} onClick={(event) => { this.editControl(source, event) }} >

                                <Grid item >
                                    <Grid container justifyContent="flex-start" spacing={0} className={classes.controlSecondRow}>
                                        <Grid item xs={12}>
                                            <Grid container >
                                                <Grid item className={classes.textBoxIcon}><AvTimerSharpIcon className={this.iconClass(controlId)} /></Grid>
                                                <Grid item><Typography variant="subtitle3" className={this.captionClass(controlId)}  >Time Picker</Typography></Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container justifyContent="flex-start" spacing={0} className={classes.controlFieldNameRow}>
                                        <Grid item xs={12} sm={12} className={classes.controlFieldNameItem} >
                                            <Typography className={this.fieldNameClass(controlId)}>{fieldName}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>

                </DragDropContainer>
            }
            else if (controlId.indexOf("int") > -1) {
                if (fieldName === "") {
                    fieldName = "Numeric"
                }

                return (
                    <DragDropContainer dragData={controlType.NumericTextBox + "_" + source} targetKey="formContainer" onDrop={this.handleDrop} >
                        <Grid container >

                            <Grid item xs={12} id={controlId} >

                                <Grid container justifyContent="space-between" spacing={0} className={classes.deleteFormControlRow + ' ' + classes.deleteFormControlItem}>
                                    <Grid item xs={12} sm={6} className={classes.primaryfieldItem} > {this.getPrimaryUI(controlValue, fieldName, classes)}</Grid>


                                    <Grid item xs={12} sm={6} ><RoleIcon className={classes.formRoleIcon + ' pointerIcon'} onClick={(event) => { this.editControlRoles(source, event) }} /><DeleteButton className="formControl pointerIcon" fontSize="small" onClick={() => { this.deleteControl(source) }} /></Grid></Grid>

                                <Grid container className={classes.controlArea} onClick={(event) => { this.editControl(source, event) }} >

                                    <Grid item >
                                        <Grid container justifyContent="flex-start" spacing={0} className={classes.controlSecondRow}><Grid item xs={12}>
                                            <Grid container >
                                                <Grid item className={classes.arrowDownIconItem}><KeyboardArrowDownIcon className={this.iconClass(controlId)} /></Grid>
                                                <Grid item className={classes.arrowUpIconItem}><KeyboardArrowUpIcon className={this.iconClass(controlId)} /></Grid>
                                                <Grid item  ><Typography variant="subtitle3" className={`${this.captionClass(controlId)} ${classes.controlName1}`}>Numeric</Typography></Grid></Grid></Grid></Grid>
                                        <Grid container justifyContent="flex-start" spacing={0} className={classes.controlFieldNameRow}>
                                            <Grid item xs={12} sm={12} className={classes.controlFieldNameItem} > <Typography className={this.fieldNameClass(controlId)}>{fieldName}</Typography></Grid>

                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </DragDropContainer>
                )


            }
            else if (controlId.indexOf("sign") > -1) {
                if (fieldName === "") {
                    fieldName = "Signature"
                }
                return <DragDropContainer dragData={controlType.Signature + "_" + source} targetKey="formContainer" onDrop={this.handleDrop} >
                    <Grid container >

                        <Grid item xs={12} id={controlId} >

                            <Grid container justifyContent="space-between" spacing={0} className={classes.deleteFormControlRow + ' ' + classes.deleteFormControlItem}>
                                <Grid item xs={12} sm={6} ></Grid><Grid item xs={12} sm={6} ><RoleIcon className={classes.formRoleIcon + ' pointerIcon'} onClick={(event) => { this.editControlRoles(source, event) }} /><DeleteButton className="formControl pointerIcon" fontSize="small" onClick={() => { this.deleteControl(source) }} /></Grid></Grid>

                            <Grid container className={classes.controlArea} onClick={(event) => { this.editControl(source, event) }} >

                                <Grid item >
                                    <Grid container justifyContent="flex-start" spacing={0} className={classes.controlSecondRow}>
                                        <Grid item xs={12}>
                                            <Grid container >
                                                <Grid item className={classes.textBoxIcon}><EditOutlinedIcon className={this.iconClass(controlId)} /></Grid>
                                                <Grid item><Typography variant="subtitle3" className={this.captionClass(controlId)}  >Signature</Typography></Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container justifyContent="flex-start" spacing={0} className={classes.controlFieldNameRow}>
                                        <Grid item xs={12} sm={12} className={classes.controlFieldNameItem} >
                                            <Typography className={this.fieldNameClass(controlId)}>{fieldName}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>


                </DragDropContainer>
            }
            else if (controlId.indexOf("btn") > -1) {
                if (fieldName === "") {
                    fieldName = "Button"
                }
                return <DragDropContainer dragData={controlType.Button + "_" + source} targetKey="formContainer" onDrop={this.handleDrop} >
                    <Grid container >

                        <Grid item xs={12} id={controlId} >

                            <Grid container justifyContent="space-between" spacing={0} className={classes.deleteFormControlRow + ' ' + classes.deleteFormControlItem}>
                                <Grid item xs={12} sm={6} ></Grid><Grid item xs={12} sm={6} ><RoleIcon className={classes.formRoleIcon + ' pointerIcon'} onClick={(event) => { this.editControlRoles(source, event) }} /><DeleteButton className="formControl pointerIcon" fontSize="small" onClick={() => { this.deleteControl(source) }} /></Grid></Grid>

                            <Grid container className={classes.controlArea} onClick={(event) => { this.editControl(source, event) }} >

                                <Grid item >
                                    <Grid container justifyContent="flex-start" spacing={0} className={classes.controlSecondRow}>
                                        <Grid item xs={12}>
                                            <Grid container >
                                                <Grid item className={classes.textBoxIcon}><SmartButtonIcon className={this.iconClass(controlId)} /></Grid>
                                                <Grid item><Typography variant="subtitle3" className={this.captionClass(controlId)}  >Button</Typography></Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container justifyContent="flex-start" spacing={0} className={classes.controlFieldNameRow}>
                                        <Grid item xs={12} sm={12} className={classes.controlFieldNameItem} >
                                            <Typography className={this.fieldNameClass(controlId)}>{fieldName}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </DragDropContainer>
            }
            else if (controlId.indexOf("image") > -1) {
                if (fieldName === "") {
                    fieldName = "Image"
                }
                return <DragDropContainer dragData={controlType.Image + "_" + source} targetKey="formContainer" onDrop={this.handleDrop} >
                    <Grid container >

                        <Grid item xs={12} id={controlId} >

                            <Grid container justifyContent="space-between" spacing={0} className={classes.deleteFormControlRow + ' ' + classes.deleteFormControlItem}>
                                <Grid item xs={12} sm={6} ></Grid><Grid item xs={12} sm={6} ><RoleIcon className={classes.formRoleIcon + ' pointerIcon'} onClick={(event) => { this.editControlRoles(source, event) }} /><DeleteButton className="formControl pointerIcon" fontSize="small" onClick={() => { this.deleteControl(source) }} /></Grid></Grid>

                            <Grid container className={classes.controlArea} onClick={(event) => { this.editControl(source, event) }} >

                                <Grid item >
                                    <Grid container justifyContent="flex-start" spacing={0} className={classes.controlSecondRow}>
                                        <Grid item xs={12}>
                                            <Grid container >
                                                <Grid item className={classes.textBoxIcon}><CropOriginalIcon className={this.iconClass(controlId)} /></Grid>
                                                <Grid item><Typography variant="subtitle3" className={this.captionClass(controlId)}  >Image</Typography></Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container justifyContent="flex-start" spacing={0} className={classes.controlFieldNameRow}>
                                        <Grid item xs={12} sm={12} className={classes.controlFieldNameItem} >
                                            <Typography className={this.fieldNameClass(controlId)}>{fieldName}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>


                </DragDropContainer>
            }
        }
        return "";

    }
    validateFormatValue = (columnFormat, count) => {
        let columnCount = 0;
        if (count) {
            columnCount = count
        } else {
            columnCount = this.state.columnCount
        }

        let splitArray = columnFormat.split(",")
        if (columnCount !== 1) {

            if (splitArray.length !== columnCount) {
                return false;
            } else {
                let sum = 0;
                for (let i = 0; i < splitArray.length; i++) {
                    if (splitArray[i] === "") {
                        return false;
                    } else {
                        sum += parseInt(splitArray[i]);
                    }
                }

                if (sum !== 12) {
                    return false;
                }

            }
        } else {


            if (columnFormat !== "12") {
                return false;
            }

        }

        return true;
    }

    alterRow = (columnFormat, columnCount) => {

        if (!this.validateFormatValue(columnFormat, columnCount)) {
            this.setState({ errorColumnFormat: true, helperTextColumnFormat: "Column widths must sum to 12" });
            return false;
        }
        this.setState({ errorColumnFormat: false, helperTextColumnFormat: " " });

        let columnsCount = 0;
        if (columnCount) {
            columnsCount = columnCount
        } else {
            columnsCount = this.state.columnCount
        }

        if (typeof this.state.selectedRow === 'string') {
            if (this.state.selectedRow.indexOf("SubRow") > -1) {
                this.alterSubRow(columnFormat, columnsCount)
            }
        } else {

            let model = Object.assign([], this.props.formUI);
            let rowIndex = this.props.formUI.findIndex(row => row.row === this.state.selectedRow)

            let modelRow = Object.assign({}, model[rowIndex])

            let columns = Object.assign([], modelRow.columns)
            if (columns.length === columnsCount) {

                if (modelRow.columns.length > 1) {
                    let splitArray = columnFormat.split(",")

                    if (splitArray.length === columns.length) {
                        for (let i = 0; i < columns.length; i++) {
                            let column = Object.assign({}, columns[i]);
                            column.width = splitArray[i];
                            columns[i] = column;
                        }


                    }
                }
            } else {
                let splitArray = columnFormat.split(",")
                let columnIndex = 1;


                if (splitArray.length === columnsCount) {
                    for (let i = 0; i < splitArray.length; i++) {
                        let columnObject = Object.assign({}, column)
                        let index = columns.findIndex(column => column.column === columnIndex)
                        if (index !== -1) {
                            let column = Object.assign({}, columns[i]);
                            column.width = splitArray[i];
                            columns[i] = column;

                        } else {

                            columnObject.column = columnIndex;
                            columnObject.width = splitArray[i];
                            columns.push(columnObject)

                        }
                        columnIndex++;

                    }
                }

                if (columns.length > columnsCount) {
                    for (let i = columns.length; i > columnsCount - 1; i--) {

                        const item = columns[i]

                        if (item) {

                            for (let j = 0; j < item.subRows.length; j++) {
                                for (let k = 0; k < item.subRows[j].columns.length; k++) {
                                    const subRowItem = item.subRows[j].columns[k]
                                    if (subRowItem.controlUiId) {
                                        this.setControlPropertyList(sectionType.Header, updateType.Delete, subRowItem.controlUiId, subRowItem.controlUiId, subRowItem.controlType)
                                    }
                                }
                            }

                            if (item.controlUiId) {
                                this.setControlPropertyList(sectionType.Header, updateType.Delete, item.controlUiId, item.controlUiId, item.controlType)
                            }
                        }
                        columns.splice(i, 1);
                    }

                }

            }
            modelRow.columns = columns
            model[rowIndex] = modelRow;
            this.props.dispatch(updateFormUI(model));
        }

    }
    alterSubRow = (columnFormat, columnCount) => {
        let model = Object.assign([], this.props.formUI);
        let splitSubRow = this.state.selectedRow.split("_")
        let splitRow = splitSubRow[0].split("-")
        let rootRowIndex = model.findIndex(row => row.row === parseInt(splitRow[0].replace("Row", "").trim()));
        let rootColumnIndex = model[rootRowIndex].columns.findIndex(column => column.column === parseInt(splitRow[1].trim()));
        let rootModel = Object.assign({}, model[rootRowIndex])
        let rootColumn = Object.assign([], model[rootRowIndex].columns)

        let subRowColumn = Object.assign({}, model[rootRowIndex].columns[rootColumnIndex])
        let subRowColumnIndex = subRowColumn.subRows.findIndex(row => row.row === parseInt(splitSubRow[1].replace("SubRow", "").trim()))

        let subRowArray = Object.assign([], subRowColumn.subRows)
        let modelRow = Object.assign({}, subRowColumn.subRows[subRowColumnIndex])

        let columns = Object.assign([], subRowColumn.subRows[subRowColumnIndex].columns)

        if (columns.length === columnCount) {

            if (modelRow.columns.length > 1) {
                let splitArray = columnFormat.split(",")

                if (splitArray.length === columns.length) {
                    for (let i = 0; i < columns.length; i++) {
                        let column = Object.assign({}, columns[i]);
                        column.width = splitArray[i];
                        columns[i] = column;
                    }

                }
            }
        }
        else {
            let splitArray = columnFormat.split(",")
            let columnIndex = 1;


            if (splitArray.length === columnCount) {
                for (let i = 0; i < splitArray.length; i++) {
                    let columnObject = Object.assign({}, column)
                    let index = columns.findIndex(column => column.column === columnIndex)
                    if (index !== -1) {
                        let column = Object.assign({}, columns[i]);
                        column.width = splitArray[i];
                        columns[i] = column;

                    } else {

                        columnObject.column = columnIndex;
                        columnObject.width = splitArray[i];
                        columns.push(columnObject)

                    }
                    columnIndex++;

                }
            }

            if (columns.length > columnCount) {
                for (let i = columns.length; i > columnCount - 1; i--) {
                    const item = columns[i - 1]
                    if (item.controlUiId) {
                        this.setControlPropertyList(sectionType.Header, updateType.Delete, item.controlUiId, item.controlUiId, item.controlType)
                    }
                    columns.splice(i, 1);
                }

            }

        }

        modelRow.columns = columns
        subRowArray[subRowColumnIndex] = modelRow
        subRowColumn.subRows = subRowArray
        rootColumn[rootColumnIndex] = subRowColumn
        rootModel.columns = rootColumn;
        model[rootRowIndex] = rootModel
        this.props.dispatch(updateFormUI(model));

    }
    deleteRow = (rowId) => {

        if (this.state.validationResults.length > 0) {
            return false;
        }

        selectedColumn = "";
        const model = [].concat(this.props.formUI)
            .sort((a, b) => a.row > b.row ? 1 : -1)

        if (rowId.indexOf('SubRow') > -1) {
            //"Row1-1_SubRow1"
            this.deleteSubRow(rowId)


        } else {
            if (this.props.formUI.length !== 1) {
                rowId = parseInt(rowId.replace("Row", "").trim());
                for (let i = 0; i < model.length; i++) {
                    let data = Object.assign({}, model[i])

                    if (data.row > rowId) {
                        data.columns = this.resetColumnControlId(data.row, data.columns, -1)
                        data.row = data.row - 1

                        model[i] = data;
                    } else {

                        if (data.row === rowId) {
                            this.deleteColumnControlId(data.row, data.columns)
                        }
                    }



                }


                let rowIndex = this.props.formUI.findIndex(row => row.row === rowId)
                model.splice(rowIndex, 1)

                const sorted = [].concat(model)
                    .sort((a, b) => a.row > b.row ? 1 : -1)
                this.dispatchControl(sorted)
            } else {

                if (this.props.formUI.length === 1) {
                    let data = Object.assign({}, model[0])
                    this.deleteColumnControlId(data.row, data.columns)

                }


                this.props.dispatch(getNewFormUI());
            }
            let controlObject = { activeControlIndex: '' }
            this.props.dispatch(updateActiveControlProperty(controlObject));
        }


    }
    deleteSubRow = (rowId) => {

        if (this.state.validationResults.length > 0) {
            return false;
        }

        const model = [].concat(this.props.formUI)
            .sort((a, b) => a.row > b.row ? 1 : -1)

        //"Row1-1_SubRow1"


        let splitSubRow = rowId.split("_")
        let splitRow = splitSubRow[0].split("-")

        let rootRowIndex = model.findIndex(row => row.row === parseInt(splitRow[0].replace("Row", "").trim()));
        let rootColumnIndex = model[rootRowIndex].columns.findIndex(column => column.column === parseInt(splitRow[1].trim()));
        let rootModel = Object.assign({}, model[rootRowIndex])
        let rootColumn = Object.assign([], model[rootRowIndex].columns)
        let subRowColumn = Object.assign({}, model[rootRowIndex].columns[rootColumnIndex])
        let subRowColumnIndex = subRowColumn.subRows.findIndex(row => row.row === parseInt(splitSubRow[1].replace("SubRow", "").trim()))
        let subRowId = parseInt(splitSubRow[1].replace("SubRow", "").replace("grd", "").trim());
        let subRowArray = Object.assign([], subRowColumn.subRows)
        for (let i = 0; i < subRowColumn.subRows.length; i++) {
            let data = Object.assign({}, subRowColumn.subRows[i])

            if (data.row > subRowId) {

                data.columns = this.resetSubRowsControlId(data.row, data.columns, -1)
                data.row = data.row - 1
                subRowArray[i] = data
            } else {
                if (data.row === subRowId) {
                    this.deleteSubRowsControlId(data.row, data.columns)
                }

            }

        }
        subRowArray.splice(subRowColumnIndex, 1)
        const sortedArray = [].concat(subRowArray)
            .sort((a, b) => a.row > b.row ? 1 : -1)
        subRowColumn.subRows = sortedArray
        rootColumn[rootColumnIndex] = subRowColumn
        rootModel.columns = rootColumn;
        model[rootRowIndex] = rootModel
        const sorted = [].concat(model)
            .sort((a, b) => a.row > b.row ? 1 : -1)

        let controlObject = { activeControlIndex: '' }
        this.props.dispatch(updateActiveControlProperty(controlObject));

        this.dispatchControl(sorted)

    }
    deleteSubRowColumn = (rowId) => {
        if (this.state.validationResults.length > 0) {
            return false;
        }
        const model = [].concat(this.props.formUI)
            .sort((a, b) => a.row > b.row ? 1 : -1)

        //"Row1-1_SubRow1"


        let splitSubRow = rowId.split("_")
        let splitRow = splitSubRow[0].split("-")

        let rootRowIndex = model.findIndex(row => row.row === parseInt(splitRow[0].replace("Row", "").trim()));
        let rootColumnIndex = model[rootRowIndex].columns.findIndex(column => column.column === parseInt(splitRow[1].trim()));
        let rootModel = Object.assign({}, model[rootRowIndex])
        let rootColumn = Object.assign([], model[rootRowIndex].columns)
        let subRowColumn = Object.assign({}, model[rootRowIndex].columns[rootColumnIndex])
        for (let i = 0; i < subRowColumn.subRows.length; i++) {
            let data = Object.assign({}, subRowColumn.subRows[i])
            this.deleteSubRowsControlId(data.row, data.columns)

        }
        subRowColumn.subRows = []
        rootColumn[rootColumnIndex] = subRowColumn
        rootModel.columns = rootColumn;
        model[rootRowIndex] = rootModel
        const sorted = [].concat(model)
            .sort((a, b) => a.row > b.row ? 1 : -1)
        this.dispatchControl(sorted)

    }
    onColumnSelect = (columnId) => {
        selectedColumn = columnId;
        this.setState({ selectedColumnId: columnId, rowEditor: false, columnEditor: true, columnsCount: "", rowsCount: "", anchorElement: null })

    }
    alterColumn = () => {

        if (this.state.columnsCount !== "" && this.state.rowsCount !== "" && selectedColumn !== "") {


            let rowsCount = parseInt(this.state.rowsCount);
            let splitColumnId = this.state.selectedColumnId.split("-");
            let model = Object.assign([], this.props.formUI);
            let rowIndex = this.props.formUI.findIndex(row => row.row === parseInt(splitColumnId[0].replace("Row", "").trim()));
            let modelRow = Object.assign({}, model[rowIndex]);
            let columns = Object.assign([], modelRow.columns);
            let columnIndex = columns.findIndex(column => column.column === parseInt(splitColumnId[1].trim()));

            let column = Object.assign({}, columns[columnIndex])
            let rowsIndex = 1;
            let subRow = Object.assign([], column.subRows)
            for (let i = 0; i < rowsCount; i++) {

                const rowObject = Object.assign({}, row)
                rowObject.row = rowsIndex;
                rowObject.columns = this.getColumns(parseInt(this.state.columnsCount), this.state.selectedColumnId + "_SubRow" + rowIndex);
                subRow.push(rowObject);
                rowsIndex++;
            }
            column.subRows = subRow

            columns[columnIndex] = column
            modelRow.columns = columns
            model[rowIndex] = modelRow;
            this.props.dispatch(updateFormUI(model));
            this.setState({ columnsCount: "", rowsCount: "", columnEditor: false });
        }



    }
    addSubRowColumn = (selectedColumnId) => {
        if (this.state.validationResults.length > 0) {
            return false;
        }
        let rowsCount = 1;
        let splitColumnId = selectedColumnId.split("-");
        let model = Object.assign([], this.props.formUI);
        let rowIndex = this.props.formUI.findIndex(row => row.row === parseInt(splitColumnId[0].replace("Row", "").trim()));
        let modelRow = Object.assign({}, model[rowIndex]);
        let columns = Object.assign([], modelRow.columns);
        let columnIndex = columns.findIndex(column => column.column === parseInt(splitColumnId[1].trim()));

        let column = Object.assign({}, columns[columnIndex])
        let rowsIndex = 1;
        let subRow = Object.assign([], column.subRows)
        for (let i = 0; i < rowsCount; i++) {

            const rowObject = Object.assign({}, row)
            rowObject.row = rowsIndex;
            rowObject.columns = this.getColumns(1, selectedColumnId + "_SubRow" + rowIndex);
            subRow.push(rowObject);
            rowsIndex++;
        }
        column.subRows = subRow

        columns[columnIndex] = column
        modelRow.columns = columns
        model[rowIndex] = modelRow;
        this.props.dispatch(updateFormUI(model));
        this.setState({ columnsCount: "", rowsCount: "", columnEditor: false });

    }
    getColumnWidth = (columnCount) => {
        let width = 0;
        if (columnCount === 1) {

            width = 12;
        }
        else if (columnCount === 2) {

            width = 6;
        }
        else if (columnCount === 3) {

            width = 4;
        }
        else if (columnCount === 4) {

            width = 3;
        }
        else if (columnCount === 6) {

            width = 2;
        }
        else {

            width = 1;
        }

        return width
    }
    getColumns = (columnCount, rowColumnId) => {
        const columns = [];
        let width = this.getColumnWidth(columnCount)
        let columnIndex = 1;
        for (let i = 0; i < columnCount; i++) {
            let columnObject = Object.assign({}, column)
            columnObject.column = columnIndex;
            columnObject.width = width;
            columnObject.rowColumnId = rowColumnId + "-" + columnIndex
            columns.push(columnObject)
            columnIndex++;

        }

        return columns;

    }
    onHide = () => {
        this.setState({ visible: false });
    }
    resetGrid = () => {
        this.addDetail(parseInt(this.state.gridColumns), this.state.targetRow, true);
    }
    getGridEditProperty = (controlTarget, columnCount) => {
        let controlObject = { sectionType: sectionType.GridEdit, activeControlIndex: 0, controlDestination: controlTarget, columnCount: columnCount }
        this.props.dispatch(updateActiveControlProperty(controlObject));
        this.loadControlSettings(false);
    }

    addDetail = (columnCount, targetRow, isUpdateDetail) => {


        if (!this.validateGrid() && !isUpdateDetail) {
            this.props.dispatch(showErrorMessage("Detail section already added"))
            return false;
        }

        if (columnCount) {
            let splitColumnId = targetRow.split("-");
            let model = Object.assign([], this.props.formUI);
            let rowIndex = this.props.formUI.findIndex(row => row.row === parseInt(splitColumnId[0].replace("Row", "").trim()));
            let modelRow = Object.assign({}, model[rowIndex]);
            let columns = Object.assign([], modelRow.columns);
            let columnIndex = columns.findIndex(column => column.column === parseInt(splitColumnId[1].trim()));
            let column = Object.assign({}, columns[columnIndex])
            let subRow = Object.assign([], column.subRows)


            if (isUpdateDetail === false) {
                const rowObject = Object.assign({}, row)
                rowObject.row = 1;
                column.rowColumnId = targetRow + "_grdRow" + rowIndex
                rowObject.columns = this.getColumns(parseInt(columnCount), targetRow + "_grdRow" + rowIndex);
                subRow.push(rowObject);
                this.getGridEditProperty(targetRow, columnCount);

            } else {
                if (subRow[0].columns.length !== columnCount) {
                    let subRowColumn = Object.assign({}, subRow[0])
                    const gridColumn = Object.assign([], subRow[0].columns)
                    let columnIndex = 1;
                    for (let i = 0; i < columnCount; i++) {
                        let columnObject = Object.assign({}, column)
                        let index = gridColumn.findIndex(column => column.column === columnIndex)
                        if (index !== -1) {
                            let column = Object.assign({}, gridColumn[i]);
                            column.width = this.getColumnWidth(columnCount);
                            gridColumn[i] = column;

                        } else {

                            columnObject.column = columnIndex;
                            columnObject.rowColumnId = targetRow + "_grdRow1" + "-" + columnIndex
                            columnObject.width = this.getColumnWidth(columnCount);;
                            gridColumn.push(columnObject)

                        }
                        columnIndex++;

                    }



                    if (subRow[0].columns.length > columnCount) {
                        for (let i = subRow[0].columns.length; i > columnCount - 1; i--) {
                            gridColumn.splice(i, 1);
                        }

                    }

                    subRowColumn.columns = gridColumn
                    subRow[0] = subRowColumn
                }

            }

            column.subRows = subRow
            columns[columnIndex] = column
            modelRow.columns = columns
            model[rowIndex] = modelRow;
            this.props.dispatch(updateFormUI(model));
            //this.onHide();
            this.controlWindowClose();

        }

    }
    editDetailRow = (targetRow) => {
        // this.setState({ visible: true, targetRow: rowId, isUpdateDetail: true });
        let splitColumnId = targetRow.split("-");
        let model = Object.assign([], this.props.formUI);
        let rowIndex = this.props.formUI.findIndex(row => row.row === parseInt(splitColumnId[0].replace("Row", "").trim()));
        let modelRow = Object.assign({}, model[rowIndex]);
        let columns = Object.assign([], modelRow.columns);
        let columnIndex = columns.findIndex(column => column.column === parseInt(splitColumnId[1].trim()));
        let column = Object.assign({}, columns[columnIndex])
        this.getGridEditProperty(targetRow, column.subRows[0].columns.length)
    }
    getControlTypeValue = (type) => {
        if (type === controlType.Select) {
            return 0;
        }
        else if (type === controlType.TextBox) {
            return 1;
        }
        else if (type === controlType.DropdownList) {
            return 0;
        }

        else if (type === controlType.SectionTitle) {
            return 26;
        }
        else if (type === controlType.FileUpload) {
            return 16;
        }
        else if (type === controlType.DatePicker) {
            return 12;
        }
        else if (type === controlType.TimePicker) {
            return 13;
        }
        else if (type === controlType.NumericTextBox) {
            return 11;
        }
        else if (type === controlType.Signature) {
            return 28;
        }
        else if (type === controlType.Button) {
            return 7;
        }

        else if (type === controlType.Image) {
            return 17;
        } else {
            return type;
        }

    }
    getControlProperty = (control) => {
        if (this.props.formSection) {
            if (control.indexOf("grd") > -1) {
                const index = this.props.formSection.detail.formGridControl.findIndex(f => f.controlUiId === control)
                if (index !== -1) {
                    return this.props.formSection.detail.formGridControl[index];

                }
            }
            else {
                const index = this.props.formSection.header.findIndex(f => f.controlUiId === control)
                if (index !== -1) {
                    return this.props.formSection.header[index];

                }
            }

            return "";

        }
        return "";
    }

    getTableName = (section) => {
        if (section === sectionType.Header) {
            return (this.props.form.primaryTableName ? this.props.form.primaryTableName : "")
        } else {

            return (this.props.form.detailTableName ? this.props.form.detailTableName : "")
        }
    }
    getDataType = (section, fieldName) => {
        if (fieldName) {
            if (section === sectionType.Header) {
                if (this.props.form.createNewTable) {

                    if (this.props.form.primaryTableDataFields) {

                        let index = this.props.form.primaryTableDataFields.findIndex(c => c.fieldName === fieldName)
                        if (index !== -1) {

                            return this.props.form.primaryTableDataFields[index].dataType;

                        }

                    }


                } else {
                    if (this.props.forms.primaryTableFields.list) {

                        let index = this.props.forms.primaryTableFields.list.findIndex(c => c.fieldName === fieldName)
                        if (index !== -1) {

                            return this.props.forms.primaryTableFields.list[index].dataType;

                        }

                    }
                }
            }
            else if (section === sectionType.Detail) {

                if (this.props.forms.detailTableFields.list) {

                    let index = this.props.forms.detailTableFields.list.findIndex(c => c.fieldName === fieldName)
                    if (index !== -1) {

                        return this.props.forms.detailTableFields.list[index].dataType;

                    }

                }
            }
        }

        return 0;

    }

    setInitialValue = (controlObject, sourceControlType, fieldName, control) => {

        if (sourceControlType === controlType.DatePicker && fieldName !== "") {
            controlObject.format = controlPropertyData.formatType;
            controlObject.culture = getControlCulture(control, controlPropertyData.formatType)
        } else if (sourceControlType === controlType.TextBox && fieldName !== "") {
            controlObject.length = controlPropertyData.length;
        }
        
        if (!controlObject.controlUiId) {
            controlObject.isVisible = true;
        }
        return controlObject
    }

    setControlProperty = (section, type, sourceControl, control, sourceControlType, length) => {
        let activeControlIndex = -1;
        let formControlObject = getFormControl();
        formControlObject.controlName = fieldName ? fieldName : getControlCaption(control, section).caption;
        formControlObject.caption = fieldName ? fieldName : getControlCaption(control, section).caption;
        formControlObject.tableName = this.getTableName(section);
        formControlObject.fieldName = fieldName ? fieldName : getControlCaption(control, section).fieldName;
        formControlObject.dataType = this.getDataType(section, fieldName);
        formControlObject.length = 50;
        formControlObject.controlType = sourceControlType;
        formControlObject.isVisible = true;
        formControlObject.isEditable = true;
        formControlObject.isPrimaryField = isPrimary ? isPrimary : false;
        formControlObject.controlUiId = control;
        formControlObject.format = getControlFomat(control)
        formControlObject.culture = getControlCulture(control, formControlObject.format)
        formControlObject.defaultValue = formControlObject.controlType === Enum.ControlType.CheckBox ? 0 : ""
        if (sourceControlType === Enum.ControlType.Button) {
            var buttonAction = getButtonActionObject();
            buttonAction.actionName = "Bizweaver";
            buttonAction.actionType = Enum.ActionType.Service;
            buttonAction.actionSequence = 2;
            buttonAction.serviceType = Enum.Method.Post;
            buttonAction.actionTarget = "";
            buttonAction.isBizweaverService = true;
            buttonAction.isActive = true;
            buttonAction.formActionParameter = { pTaskID: "0", pStartAfter: "0", pArguments: [] };
            buttonAction.validateErrorMessage = "Action Failed";
            buttonAction.validateSuccessMessage = "Action  Success";
            formControlObject.formAction.push(buttonAction)
        }
        this.setInitialValue(formControlObject, sourceControlType, fieldName, control)
        formControlObject.comboBoxStatic = formControlObject.listId > 0 ? false : true
        if (sourceControlType === Enum.ControlType.SectionTitle || sourceControlType === Enum.ControlType.Button) {
            formControlObject.isDataBind = false;
        }


        if (this.props.formSection) {
            let model = Object.assign({}, this.props.formSection);
            let detail = Object.assign({}, model.detail);
            if (section === sectionType.Detail) {
                if (type === updateType.Rename) {
                    let index = -1;
                    if (fieldName) {
                        index = this.props.formSection.detail.formGridControl.findIndex(f => f.fieldName === fieldName)
                        activeControlIndex = index
                        if (index === -1) {

                            let formGridControl = Object.assign([], model.detail.formGridControl);
                            formGridControl.push(formControlObject);
                            activeControlIndex = formGridControl.length - 1
                            detail.formGridControl = formGridControl
                            model.detail = detail;

                        }

                    } else {



                        //Existing control 
                        index = this.props.formSection.detail.formGridControl.findIndex(f => f.controlUiId === control)
                        const sourceIndex = this.props.formSection.detail.formGridControl.findIndex(f => f.controlUiId === sourceControl)
                        if (index !== -1 && sourceIndex !== -1) {
                            index = this.props.formSection.detail.formGridControl.findIndex(f => f.controlUiId === sourceControl)
                            if (sourceControlElement.controlUId) {
                                targetControlElement['index'] = index
                                targetControlElement['controlUId'] = control
                                targetControlElement['section'] = section

                            } else {
                                sourceControlElement['index'] = index
                                sourceControlElement['controlUId'] = control
                                sourceControlElement['section'] = section
                            }
                            return "";
                        }

                        //Existing control 


                        index = this.props.formSection.detail.formGridControl.findIndex(f => f.controlUiId === sourceControl)
                        activeControlIndex = index
                        if (index === -1) {

                            let formGridControl = Object.assign([], model.detail.formGridControl);
                            formGridControl.push(formControlObject);
                            activeControlIndex = formGridControl.length - 1
                            detail.formGridControl = formGridControl
                            model.detail = detail;


                        }

                    }

                    if (index !== -1) {
                        let formGridControl = Object.assign([], model.detail.formGridControl);
                        let controlObject = Object.assign({}, model.detail.formGridControl[index]);
                        this.setInitialValue(controlObject, sourceControlType, fieldName, control)
                        controlObject.controlUiId = control;
                        if (controlObject.fieldName.indexOf("nbd") !== -1) {
                            controlObject.fieldName = `nbdgrid_${control.replaceAll("-", "_")}`
                        }
                        formGridControl[index] = controlObject;
                        formControlObject = controlObject;
                        detail.formGridControl = formGridControl
                        model.detail = detail;

                    }
                    this.setState({ isExpanded: true })
                }
                else if (type === updateType.Delete) {
                    const index = this.props.formSection.detail.formGridControl.findIndex(f => f.controlUiId === sourceControl)
                    activeControlIndex = -1
                    if (index !== -1) {
                        let formGridControl = Object.assign([], model.detail.formGridControl);
                        let controlObject = Object.assign({}, model.detail.formGridControl[index]);
                        controlObject.controlUiId = "";
                        formGridControl.splice(index, 1);
                        //formGridControl[index] = controlObject;
                        //formControlObject = controlObject;
                        detail.formGridControl = formGridControl
                        model.detail = detail;

                    }
                }
                else if (type === updateType.Edit || type === updateType.Role) {
                    const index = this.props.formSection.detail.formGridControl.findIndex(f => f.controlUiId === sourceControl)
                    activeControlIndex = index
                    if (index !== -1) {
                        let controlObject = Object.assign({}, model.detail.formGridControl[index]);
                        formControlObject = controlObject
                        this.setState({ isExpanded: true })
                    }
                }
            }
            else if (section === sectionType.Header) {

                if (type === updateType.Rename) {
                    let index = -1;
                    if (fieldName) {
                        index = this.props.formSection.header.findIndex(f => f.fieldName === fieldName)
                        activeControlIndex = index
                        if (index === -1) {

                            let formControl = Object.assign([], model.header);
                            formControl.push(formControlObject)
                            activeControlIndex = formControl.length - 1
                            model.header = formControl

                        }

                    } else {

                        //Existing control 
                        index = this.props.formSection.header.findIndex(f => f.controlUiId === control)
                        const sourceIndex = this.props.formSection.header.findIndex(f => f.controlUiId === sourceControl)
                        if (index !== -1 && sourceIndex !== -1) {
                            index = this.props.formSection.header.findIndex(f => f.controlUiId === sourceControl)
                            if (sourceControlElement.controlUId) {
                                targetControlElement['index'] = index
                                targetControlElement['controlUId'] = control
                                targetControlElement['section'] = section

                            } else {
                                sourceControlElement['index'] = index
                                sourceControlElement['controlUId'] = control
                                sourceControlElement['section'] = section
                            }
                            return "";
                        }

                        //Existing control 

                        index = this.props.formSection.header.findIndex(f => f.controlUiId === sourceControl)

                        if (index === -1) {

                            let formControl = Object.assign([], model.header);
                            formControl.push(formControlObject)
                            activeControlIndex = formControl.length - 1
                            model.header = formControl

                        }
                    }

                    if (index !== -1) {
                        let formControl = Object.assign([], model.header);
                        let controlObject = Object.assign({}, model.header[index]);
                        this.setInitialValue(controlObject, sourceControlType, fieldName, control)
                        controlObject.controlUiId = control;
                        formControlObject = controlObject;

                        if (controlObject.fieldName.indexOf("nbd") !== -1) {
                            controlObject.fieldName = `nbd_${control.replaceAll("-", "_")}`
                        }


                        formControl[index] = controlObject;
                        model.header = formControl

                    }
                    this.setState({ isExpanded: true })
                }
                else if (type === updateType.Delete) {
                    const index = this.props.formSection.header.findIndex(f => f.controlUiId === sourceControl)
                    activeControlIndex = -1
                    if (index !== -1) {
                        let formControl = Object.assign([], model.header);
                        let controlObject = Object.assign({}, model.header[index]);
                        controlObject.controlUiId = "";
                        formControlObject = controlObject

                        formControl.splice(index, 1)
                        //formControl[index] = controlObject;
                        model.header = formControl

                    }
                }
                else if (type === updateType.Edit || type === updateType.Role) {
                    const index = this.props.formSection.header.findIndex(f => f.controlUiId === sourceControl)
                    activeControlIndex = index
                    if (index !== -1) {
                        let controlObject = Object.assign({}, model.header[index]);
                        formControlObject = controlObject
                        this.setState({ isExpanded: true })
                    }
                }

            }

            let controlObject = { sectionType: section, ControlUiId: control, controlType: sourceControlType, fieldName: fieldName, formControl: formControlObject, activeControlIndex: activeControlIndex, subAction: type === updateType.Role ? Enum.ControlSubActions.editRole : Enum.ControlSubActions.none }
            if (type !== updateType.Delete) {
                this.props.dispatch(updateFormUIProperty(model, controlObject));
            } else {
                controlObject.activeControlIndex = ''
                this.props.dispatch(updateFormUIProperty(model, controlObject));
            }


        }
        // let controlObject = { sectionType: section, ControlUiId: control, controlType: controlType, fieldName: fieldName, formControl: formControlObject, activeControlIndex: activeControlIndex }
        // this.props.dispatch(updateActiveControlProperty(controlObject));
        fieldName = "";
        isPrimary = false;
        controlPropertyData = "";
        return "";
    }
    setControlPropertyList = (section, type, sourceControl, control, controlType) => {

        let formControlObject = getFormControl();
        formControlObject.tableName = this.getTableName(section);
        formControlObject.fieldName = fieldName
        formControlObject.dataType = this.getDataType(section, fieldName);
        formControlObject.controlType = controlType;
        formControlObject.isPrimaryField = isPrimary;
        formControlObject.isEditable = true;
        formControlObject.controlUiId = control;

        if (formSectionList === "") {

            formSectionList = this.props.formSection
        }

        if (formSectionList) {
            let model = Object.assign({}, formSectionList);
            let detail = Object.assign({}, model.detail);
            if (section === sectionType.Detail) {
                if (type === updateType.Rename) {
                    let index = -1;
                    if (fieldName) {
                        index = formSectionList.detail.formGridControl.findIndex(f => f.fieldName === fieldName)
                        if (index === -1) {

                            let formGridControl = Object.assign([], model.detail.formGridControl);
                            formGridControl.push(formControlObject);
                            detail.formGridControl = formGridControl
                            model.detail = detail;

                        }

                    } else {
                        index = formSectionList.detail.formGridControl.findIndex(f => f.controlUiId === sourceControl)
                    }
                    if (index !== -1) {
                        let formGridControl = Object.assign([], model.detail.formGridControl);
                        let controlObject = Object.assign({}, model.detail.formGridControl[index]);
                        controlObject.controlUiId = control;
                        formGridControl[index] = controlObject;
                        formControlObject = controlObject;
                        detail.formGridControl = formGridControl
                        model.detail = detail;
                    }
                }
                else if (type === updateType.Delete) {
                    const index = formSectionList.detail.formGridControl.findIndex(f => f.controlUiId === sourceControl)
                    if (index !== -1) {
                        let formGridControl = Object.assign([], model.detail.formGridControl);
                        let controlObject = Object.assign({}, model.detail.formGridControl[index]);
                        controlObject.controlUiId = "";
                        formGridControl.splice(index, 1)
                        //formGridControl[index] = controlObject;
                        //formControlObject = controlObject;
                        detail.formGridControl = formGridControl
                        model.detail = detail;
                    }
                }
            }
            else {

                if (type === updateType.Rename) {
                    let index = -1;
                    if (fieldName) {
                        index = formSectionList.header.findIndex(f => f.fieldName === fieldName)
                        if (index === -1) {

                            let formControl = Object.assign([], model.header);
                            formControl.push(formControlObject)
                            model.header = formControl

                        }

                    } else {
                        index = formSectionList.header.findIndex(f => f.controlUiId === sourceControl)
                        if (index === -1) {

                            let formControl = Object.assign([], model.header);
                            formControl.push(formControlObject)
                            model.header = formControl

                        }
                    }

                    if (index !== -1) {
                        let formControl = Object.assign([], model.header);
                        let controlObject = Object.assign({}, model.header[index]);
                        controlObject.controlUiId = control;
                        formControlObject = controlObject;
                        formControl[index] = controlObject;
                        model.header = formControl

                    }
                }
                else if (type === updateType.Delete) {
                    const index = formSectionList.header.findIndex(f => f.controlUiId === sourceControl)
                    if (index !== -1) {
                        let formControl = Object.assign([], model.header);
                        let controlObject = Object.assign({}, model.header[index]);
                        controlObject.controlUiId = "";
                        formControlObject = controlObject
                        //formControl[index] = controlObject;
                        formControl.splice(index, 1);
                        model.header = formControl

                    }
                }
                else if (type === updateType.Edit) {
                    const index = formSectionList.header.findIndex(f => f.controlUiId === sourceControl)
                    if (index !== -1) {
                        let controlObject = Object.assign({}, model.header[index]);
                        formControlObject = controlObject

                    }
                }

            }

            formSectionList = model
            this.props.dispatch(updateFormUIProperty(formSectionList));
        }


        return "";
    }
    dispatchControl = (model) => {

        if (formSectionList !== "") {
            this.props.dispatch(updateFormUIProperty(formSectionList));
            formSectionList = "";
        }

        this.props.dispatch(updateFormUI(model));
    }
    handleCollapse = () => {
        let status = this.state.isExpanded
        this.setState({ isExpanded: !status })

    }
    handleClose = () => {

        this.setState({ confirmOpen: false })
    }
    confirmDelete = (target) => {

        this.setState({ confirmOpen: true, controlId: target })
    }
    handleEditClose = () => {

        if (!this.state.errorColumnFormat) {
            this.setState({
                anchorElement: null
            })
        }
    };
    controlWindowClose = () => {


        this.setState({
            anchorControlWindow: null
        })

    };
    setColumnFormat = (length) => {
        if (length === 1)
            return '12'
        else if (length === 2)
            return '6,6'
        else if (length === 3)
            return '4,4,4'
        else if (length === 4)
            return '3,3,3,3'
        else if (length === 5)
            return '2,2,2,2,4'
        else if (length === 6)
            return '2,2,2,2,2,2'
        else if (length === 7)
            return '1,1,1,1,1,1,6'
        else if (length === 8)
            return '1,1,1,1,1,1,1,5'
        else if (length === 9)
            return '1,1,1,1,1,1,1,1,4'
        else if (length === 10)
            return '1,1,1,1,1,1,1,1,1,3'
        else if (length === 11)
            return '1,1,1,1,1,1,1,1,1,1,2'
        else if (length === 12)
            return '1,1,1,1,1,1,1,1,1,1,1,1'
    }
    handleFormButtonEdit = (buttonType) => {
        console.log("validationResult", this.state.validationResults)
        if (this.state.validationResults.length > 0) {
            return false;
        }
        const index = this.props.formSection.button.findIndex(f => f.controlType === buttonType)
        let model = Object.assign({}, this.props.formSection);
        var activeControlIndex = index
        var formControlObject = getFormControl();
        if (index !== -1) {
            let controlObject = Object.assign({}, model.button[index]);
            formControlObject = controlObject
        }
        let controlObject = {
            sectionType: sectionType.Button, ControlUiId: buttonType, controlType: buttonType, fieldName: formControlObject.controlName, formControl: formControlObject,
            activeControlIndex: activeControlIndex, subAction: Enum.ControlSubActions.none
        }
        this.props.dispatch(updateActiveControlProperty(controlObject));
        this.loadControlSettings(true);
    }
    handleFormButtonEditRole = (buttonType) => {
        if (this.state.validationResults.length > 0) {
            return false;
        }
        const index = this.props.formSection.button.findIndex(f => f.controlType === buttonType)
        let model = Object.assign({}, this.props.formSection);
        var activeControlIndex = index
        var formControlObject = getFormControl();
        if (index !== -1) {
            let controlObject = Object.assign({}, model.button[index]);
            formControlObject = controlObject
        }
        let controlObject = {
            sectionType: sectionType.Button, ControlUiId: buttonType, controlType: buttonType, fieldName: formControlObject.controlName,
            formControl: formControlObject, activeControlIndex: activeControlIndex, subAction: Enum.ControlSubActions.editRole
        }
        //this.props.dispatch(updateFormUIProperty(model, controlObject));
        this.props.dispatch(updateActiveControlProperty(controlObject))
        this.loadControlSettings(true);
    }

    handleScroll=(e,a) => {
        
        scrollPosition = e.scrollTop
        console.log(scrollPosition)
    }
    getLayoutItem = (classes, isOpen) => {
        
        return (<Scrollbars className={classes.scrollCard} ref={scrollbar => this.scrollbar = scrollbar} onScrollFrame={this.handleScroll}>
            <h4>Layout</h4>

            <Grid container spacing={1} justifyContent="left" className={isOpen ? classes.layoutContainer : classes.layoutContainerDefault}>
                <Grid item >
                    <Grid container spacing={1} pl={55.1} >


                        <Grid container spacing={1} className={classes.buttonContainer}>
                            <Grid item   >
                                <SaveImage className='pointerIcon' onClick={(e) => this.handleFormButtonEdit(Enum.ControlType.ActionWithClearGet)} />
                            </Grid>
                            <Grid item xs>
                                <span className="spanButton" onClick={(e) => this.handleFormButtonEdit(Enum.ControlType.ActionWithClearGet)}>SAVE</span>
                            </Grid>

                            <Grid item className={classes.formRoleButtonIcon}>
                                <Grid className={classes.formRoleIconSmall}>
                                    <RoleIcon className={classes.formRoleIconSmall + ' pointerIcon'} onClick={(event) => { this.handleFormButtonEditRole(Enum.ControlType.ActionWithClearGet) }} />
                                </Grid>
                            </Grid>
                        </Grid>


                        <Grid container spacing={1} className={classes.buttonContainer2}>
                            <Grid item style={{ width: 25 }} >
                                <SavePlusImage className='pointerIcon' onClick={(e) => this.handleFormButtonEdit(Enum.ControlType.ActionWithClear)} />
                            </Grid>
                            <Grid item style={{ width: 110 }}>
                                <span className="spanButton" onClick={(e) => this.handleFormButtonEdit(Enum.ControlType.ActionWithClear)}>SAVE & NEW</span>
                            </Grid>
                            <Grid item className={classes.formRoleButtonIcon}>
                                <Grid className={classes.formRoleIconSmall}>
                                    <RoleIcon className={classes.formRoleIconSmall + ' pointerIcon'} onClick={(event) => { this.handleFormButtonEditRole(Enum.ControlType.ActionWithClear) }} />
                                </Grid>
                            </Grid>
                        </Grid>


                        <Grid container spacing={1} className={classes.buttonContainer2} >
                            <Grid item style={{ width: 25 }} >
                                <SaveCloseImage className='pointerIcon' onClick={(e) => this.handleFormButtonEdit(Enum.ControlType.Submit)} />
                            </Grid>
                            <Grid item style={{ width: 124 }}>
                                <span className="spanButton" onClick={(e) => this.handleFormButtonEdit(Enum.ControlType.Submit)}>SAVE & CLOSE</span>
                            </Grid>
                            <Grid item className={classes.formRoleButtonIcon}>
                                <Grid className={classes.formRoleIconSmall}>
                                    <RoleIcon className={classes.formRoleIconSmall + ' pointerIcon'}
                                        onClick={(event) => { this.handleFormButtonEditRole(Enum.ControlType.Submit) }} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} className={classes.buttonContainer3} >
                            <Grid item style={{ width: 73 }}>
                                <span className="spanButton" onClick={(e) => this.handleFormButtonEdit(Enum.ControlType.ClearWithGetAction)}>CANCEL</span>

                            </Grid>
                            <Grid item className={classes.formRoleButtonIcon}>
                                <Grid className={classes.formRoleIconSmall}>
                                    <RoleIcon className={classes.formRoleIconSmall + ' pointerIcon'} onClick={(event) => { this.handleFormButtonEditRole(Enum.ControlType.ClearWithGetAction) }} />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container spacing={1} className={classes.buttonContainer3} >
                            <Grid item style={{ width: 73 }}>
                                <span className="spanButton" onClick={(e) => this.handleFormButtonEdit(Enum.ControlType.ActionWithConfirmClear)}>DELETE</span>

                            </Grid>
                            <Grid item className={classes.formRoleButtonIcon}>
                                <Grid className={classes.formRoleIconSmall}>
                                    <RoleIcon className={classes.formRoleIconSmall + ' pointerIcon'} onClick={(event) => { this.handleFormButtonEditRole(Enum.ControlType.ActionWithConfirmClear) }} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>


                </Grid>
            </Grid>
            <Grid pt={1} container spacing={0} className={isOpen ? classes.layoutContainer : classes.layoutContainerDefault}  >
                <Grid item xs={12}>
                    {this.props.formUI ? this.getFormLayout(this.props.formUI, classes) : ""}
                </Grid>
            </Grid> </Scrollbars>)


    }


    render() {
        const open = Boolean(this.state.anchorElement);
        const openControlWindow = this.state.validationResults.length === 0 ? Boolean(this.state.anchorControlWindow) : false;
        const { classes } = this.props;
        return (

            <div>
                <MuiPopover p={3}
                    open={open}
                    anchorEl={this.state.anchorElement}
                    onClose={this.handleEditClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <MuiCard sx={{ p: 2 }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Grid container spacing={1} direction="column">
                                    <Grid item xs={4}>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="demo-simple-select-label">Columns</InputLabel>
                                            <Select labelId="columnCount" id="columnCount" onChange={(e) => {
                                                const format = this.setColumnFormat(e.target.value)
                                                this.setState({ columnCount: e.target.value, columnFormat: format })
                                                this.alterRow(format, e.target.value);
                                            }} value={this.state.columnCount}>
                                                <MenuItem value={1}>1</MenuItem>
                                                <MenuItem value={2}>2</MenuItem>
                                                <MenuItem value={3}>3</MenuItem>
                                                <MenuItem value={4}>4</MenuItem>
                                                <MenuItem value={5}>5</MenuItem>
                                                <MenuItem value={6}>6</MenuItem>
                                                <MenuItem value={7}>7</MenuItem>
                                                <MenuItem value={8}>8</MenuItem>
                                                <MenuItem value={9}>9</MenuItem>
                                                <MenuItem value={10}>10</MenuItem>
                                                <MenuItem value={11}>11</MenuItem>
                                                <MenuItem value={12}>12</MenuItem>
                                            </Select>
                                        </FormControl>

                                    </Grid>

                                    <Grid item xs={4}>
                                        <TextField error={this.state.errorColumnFormat} helperText={this.state.helperTextColumnFormat} label="Column Widths" onChange={(e) => {
                                            const regex = /^[0-9\b,]+$/;
                                            if (e.target.value === '' || regex.test(e.target.value)) {

                                                this.setState({ columnFormat: e.target.value })
                                                this.alterRow(e.target.value);
                                            }
                                        }} value={this.state.columnFormat} style={{ width: "100%" }}>
                                        </TextField>
                                    </Grid>


                                </Grid>


                            </Grid>

                        </Grid>
                    </MuiCard>
                </MuiPopover>
                <MuiPopover p={3}
                    open={openControlWindow}
                    anchorEl={this.state.anchorControlWindow}
                    onClose={this.controlWindowClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    disableScrollLock={true}
                >
                    <MuiCard>
                        <ControlTab addDetail={this.addDetail} handleDrop={this.handleDrop} addControl={this.addControl} controlTarget={this.state.controlTarget} />
                    </MuiCard>
                </MuiPopover>

                <ConfirmBox open={this.state.confirmOpen} ItemId={this.state.controlId} description="Are you sure you want to remove the control from the form layout ?." title="Delete Confirmation" handleClose={this.handleClose} continue={this.deleteControl} />
                <Dialog xs={12} open={this.state.visible} onClose={this.onHide} maxWidth="md">
                    <DialogTitle id="customized-dialog-title" onClose={this.props.handleClose}>
                        Form Detail Section
                    </DialogTitle>
                    <DialogContent dividers>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                Columns
                            </Grid>
                            <Grid item xs={6}>
                                <TextField value={this.state.gridColumns} onChange={(e) => { this.setState({ gridColumns: e.target.value }) }} label="Columns" style={{ width: "100%" }}>
                                </TextField>
                            </Grid>
                        </Grid>

                        <Grid container spacing={1}>
                            <Grid item xs={6}>

                            </Grid>
                            <Grid item xs={6}>

                                <Grid container spacing={1}>
                                    <Grid item xs={3}>
                                        <button onClick={this.resetGrid}>Add</button>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <button onClick={this.onHide}>Cancel</button>
                                    </Grid>
                                </Grid>


                            </Grid>
                        </Grid>

                    </DialogContent>
                </Dialog>
                <Card variant="outlined" pt={2} pb={0} pl={3} className={classes.card}>
                    {this.state.OpenControlSettings && this.props.activeFormControl.activeControlIndex !== '' ?
                        <Grid container spacing={0}>
                            <Grid item xs>



                                {this.getLayoutItem(classes, this.state.OpenControlSettings)}



                            </Grid>
                            <Grid item className={classes.controlSettingsItem}>

                                <Grid item className={classes.controlSettings}   >

                                    {/*<ControlSettings handleClose={this.closeControlSettings} ref={this.wrapperRef} />*/}
                                    {this.state.OpenControlSettings &&
                                        (<Box>
                                            <ControlProperties addControl={this.addControl} addDetail={this.addDetail} handleClose={this.closeControlSettings} handlePropertyValidationResult={this.handlePropertyValidationResult} validationResults={this.state.validationResults} lookupList={this.props.dataViewsByRole.dataViews} />
                                        </Box>)

                                    }

                                </Grid>

                            </Grid>
                        </Grid>
                        : this.getLayoutItem(classes, this.state.OpenControlSettings)}
                </Card>

            </div>

        );

    }

}


/**
 * mapStateToProps
 * @param {*} state 
 */
function mapStateToProps(state) {
    return {
        formUI: state.forms.form.formUI,
        form: state.forms.form,
        forms: state.forms,
        activeFormControl: state.forms.activeFormControl,
        isLoading: state.loader.isLoading,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage,
        formSection: state.forms.form.formSection,
        dataViewsByRole: state.dataViews.dataViewsByRole,
    };
};

export default withRouter(connect(mapStateToProps)(withStyles(formUITheme)(formUi)));
