import { produce } from 'immer';
import { ActionTypes as types } from 'admin/actions/actionTypes';
import { newForm, newTableFields, removeTableFields, mergeNewTableField, newFormSource, resetControlRoles} from 'admin/actions/formSetupActions';
import { getFormControl } from 'admin/actions/formActions';
const initialState = {
    activeTabIndex: 0,
    forms: [],
    form: newForm(),
    formSource: newFormSource(),
    isUpdate: false,
    updateStatus:false,
    tables: { list: [], requestStatus: '' },
    primaryTableFields: {list:[],requestStatus:''},
    detailTableFields: { list: [], requestStatus: '' },
    requestStatus: { getForm: '' },
    submitClicked: false,
    activeFormControl: { sectionType: "", ControlUiId: "", controlType: "", fieldName: "", formControl: getFormControl(), activeControlIndex: -1 },
    errors: {},
    actionType: '',
    validationObject: {},
    errorTab: '',
    isLoading:true,
};

const forms = produce((draft, action) => {

   // draft.isUpdate = false;
    draft.submitClicked = false;
    draft.actionType = action.type;
    switch (action.type) {
        case types.VGO_GET_FORM_LIST_REQUEST:  
            draft.isLoading = true;
            break;
    case types.VGO_GET_FORM_LIST_SUCCESS:
            draft.forms = action.forms
           // draft.primaryTableFields = { list: [], requestStatus: '' };
           // draft.detailTableFields = { list: [], requestStatus: '' };
            draft.isLoading = false;
            break;
    case types.VGO_GET_FORM_REQUEST:
            draft.requestStatus.getForm = types.VGO_GET_FORM_REQUEST;
            draft.primaryTableFields = { list: [], requestStatus: '' };
            draft.detailTableFields = { list: [], requestStatus: '' };
            draft.activeTabIndex = 0;
            draft.isLoading= true;
        break;
        case types.VGO_GET_FORM_SUCCESS:
            draft.form = action.formRepository.formBuilderDTO;
            draft.primaryTableFields = { list: action.formRepository.primaryTableFields, requestStatus: types.VGO_GET_FORM_PRIMARY_TABLE_FIELDS__SUCCESS };
            draft.detailTableFields = { list: action.formRepository.detailTableFields, requestStatus: types.VGO_GET_FORM_DETAIL_TABLE_FIELDS__SUCCESS };
            draft.form.primaryTableDataFields = mergeNewTableField(action.formRepository.primaryTableFields, action.formRepository.formBuilderDTO.primaryTableDataFields ) //action.formRepository.primaryTableFields;
            draft.form.detailTableDataFields = mergeNewTableField(action.formRepository.detailTableFields, action.formRepository.formBuilderDTO.detailTableDataFields);
            draft.formSource.primaryTableDataFields = produce(draft.form.primaryTableDataFields, draftPrimarySource => {});
            draft.formSource.detailTableDataFields = produce(draft.form.detailTableDataFields, draftDetailSource => { });
            draft.formSource.createNewTable = draft.form.createNewTable ? true : false;
            draft.formSource.formType = draft.form.formType;
            draft.isUpdate = true;
            draft.updateStatus = false;
            draft.requestStatus.getForm = types.VGO_GET_FORM_SUCCESS;
            draft.errors = {};
            draft.isLoading = false;
        break;
        
    case types.VGO_NEW_FORM:
            draft.form = newForm();
            draft.formSource = newFormSource();
            draft.primaryTableFields = { list: [], requestStatus: '' };
            draft.detailTableFields = { list: [], requestStatus: '' };
            draft.isUpdate = false;
            draft.updateStatus = false;
            draft.errors = {};
            draft.activeTabIndex = 0;
            break;
    case types.VGO_CHANGE_FORM_TAB_INDEX:
        draft.activeTabIndex = action.tabIndex;
        break;     
    case types.VGO_CHANGE_FORM:
        action.change(draft.form);
            break;
        case types.VGO_CHANGE_FORMROLE:
            action.roleChange.change(draft.form);
            draft.form = resetControlRoles(draft.form, action.roleChange.resetlookUpList);
            break;
        case types.VGO_CHANGE_FORM_FORMTYPE:
            action.change(draft.form);
            action.change(draft.formSource);
            break;
        case types.VGO_CHANGE_FORM_FORMSOURCE:
            action.change(draft.form);
            draft.formSource.primaryTableDataFields = produce(draft.form.primaryTableDataFields, draftPrimarySource => { });
            draft.formSource.detailTableDataFields = produce(draft.form.detailTableDataFields, draftDetailSource => { });
            break;
        case types.VGO_CHANGE_FORM_TABLE_OPTION:
            action.change(draft.form);
            draft.errors.primaryTableName = '';
            draft.errors.detailTableName = '';
            draft.formSource.primaryTableDataFields = produce(draft.form.primaryTableDataFields, draftPrimarySource => { });
            draft.formSource.detailTableDataFields = produce(draft.form.detailTableDataFields, draftDetailSource => { });
            draft.formSource.createNewTable = draft.form.createNewTable ? true : false;
            break;
        case types.VGO_CHANGE_RESET_FORM:
            action.change(draft.form);
            draft.tables = { list: [], requestStatus: '' };
            draft.primaryTableFields = { list: [], requestStatus: '' };
            draft.detailTableFields = { list: [], requestStatus: '' };
            draft.formSource.primaryTableDataFields = produce(draft.form.primaryTableDataFields, draftPrimarySource => { });
            draft.formSource.detailTableDataFields = produce(draft.form.detailTableDataFields, draftDetailSource => { });
            draft.formSource.createNewTable = draft.form.createNewTable ? true : false;
        break;
    case types.VGO_GET_FILTERS_DATASOURCE_REQUEST:
            draft.tables = { list: [], requestStatus: types.VGO_GET_FILTERS_DATASOURCE_REQUEST };
        break;
    case types.VGO_GET_FILTERS_DATASOURCE_SUCCESS:
            draft.tables = { list: action.dataSources, requestStatus: types.VGO_GET_FILTERS_DATASOURCE_SUCCESS };
            break;
        case types.VGO_GET_FORM_PRIMARY_TABLE_FIELDS__REQUEST:
            draft.primaryTableFields = { list: [], requestStatus: types.VGO_GET_FORM_PRIMARY_TABLE_FIELDS__REQUEST };
            
            break;
        case types.VGO_GET_FORM_PRIMARY_TABLE_FIELDS__SUCCESS:
            draft.primaryTableFields = { list: action.fields, requestStatus: types.VGO_GET_FORM_PRIMARY_TABLE_FIELDS__SUCCESS };
            draft.form.primaryTableDataFields = action.fields;
            draft.formSource.primaryTableDataFields = produce(draft.form.primaryTableDataFields, draftPrimarySource => { });
            break;
    case types.VGO_GET_FORM_DETAIL_TABLE_FIELDS__REQUEST:
            draft.detailTableFields = { list: [], requestStatus: types.VGO_GET_FORM_DETAIL_TABLE_FIELDS__REQUEST };
            
        break;
    case types.VGO_GET_FORM_DETAIL_TABLE_FIELDS__SUCCESS:
            draft.detailTableFields = { list: action.fields, requestStatus: types.VGO_GET_FORM_DETAIL_TABLE_FIELDS__SUCCESS };
            draft.form.detailTableDataFields = action.fields;
            draft.formSource.detailTableDataFields = produce(draft.form.detailTableDataFields, draftDetailSource => { });
        break;
        case types.VGO_NEW_FORM_PRIMARY_TABLE_FIELD:
            draft.form.primaryTableDataFields = newTableFields(draft.form.primaryTableDataFields);
            draft.formSource.primaryTableDataFields = produce(draft.form.primaryTableDataFields, draftPrimarySource => { });
            break;
    case types.VGO_NEW_FORM_DETAIL_TABLE_FIELD:
            draft.form.detailTableDataFields = newTableFields(draft.form.detailTableDataFields);
            draft.formSource.detailTableDataFields = produce(draft.form.detailTableDataFields, draftDetailSource => { });
        break
        case types.VGO_DELETE_FORM_PRIMARY_TABLE_FIELD:
            draft.form.primaryTableDataFields = removeTableFields(draft.form.primaryTableDataFields, action.data.index);
            draft.formSource.primaryTableDataFields = produce(draft.form.primaryTableDataFields, draftPrimarySource => { });
            break;
        case types.VGO_DELETE_FORM_DETAIL_TABLE_FIELD:
            draft.form.detailTableDataFields = removeTableFields(draft.form.detailTableDataFields, action.data.index);
            draft.formSource.detailTableDataFields = produce(draft.form.detailTableDataFields, draftDetailSource => { });
            break;
        case types.VGO_UPDATE_FORM_SUCCESS:
            draft.form = action.formRepository.formBuilderDTO;
            draft.isUpdate = true;
            draft.updateStatus = true;
            draft.errors = {};
            draft.formSource.primaryTableDataFields = produce(draft.form.primaryTableDataFields, draftPrimarySource => { });
            draft.formSource.detailTableDataFields = produce(draft.form.detailTableDataFields, draftDetailSource => { });
            draft.formSource.createNewTable = draft.form.createNewTable ? true : false;
            let formIndex = draft.forms.findIndex(f => f.formId === draft.form.formId);
                if (formIndex >= 0) {
                    draft.forms[formIndex] = action.formRepository.formDTO;
                }
                else {
                    draft.forms.push(action.formRepository.formDTO);
                    draft.primaryTableFields = { list: action.formRepository.primaryTableFields, requestStatus: types.VGO_GET_FORM_PRIMARY_TABLE_FIELDS__SUCCESS };
                    draft.detailTableFields = { list: action.formRepository.detailTableFields, requestStatus: types.VGO_GET_FORM_DETAIL_TABLE_FIELDS__SUCCESS };
            }
            draft.activeFormControl.activeControlIndex = ""

           

            break;
        case types.VGO_ACTION_TRIGGER_FORM_SUBMIT:
            draft.submitClicked = true;
            break;

        case types.VGO_UPDATE_FORM_UI_SUCCESS:
            draft.form.formUI = action.data;
            break;
        case types.VGO_UPDATE_FORM_PROPERTY_SUCCESS:
            draft.form.formSection = action.data;
            if (action.ActiveControlProperty) {
                draft.activeFormControl = action.ActiveControlProperty;
            }
            break;
        case types.VGO_UPDATE_ACTIVE_CONTROL_PROPERTY_SUCCESS:
            draft.activeFormControl = action.data;
            break;
        case types.VGO_UPDATE_ACTIVE_CONTROL_PROPERTY_CHANGE_SUCCESS:
            draft.form.formSection = action.data.data;
            draft.activeFormControl = action.data.activeControlObject;
            break;
        case types.VGO_DELETE_FORM_LIST_SUCCESS: {
            {
                let forms = draft.forms;
                if (draft.forms.length > 0) {
                    forms = draft.forms.filter((d) => d.formId !== action.formId);
                }
                draft.forms = forms;
            }
            break;
        }
        case types.VGO_VALIDATE_FORM_SETUP_ONBLUR:
            draft.errors = produce(draft.errors, draftErrors => { return { ...draftErrors, ...action.errors } });
            break;
        case types.VGO_API_CALL_VALIDATION_ERROR:
            draft.validationObject = action.validationObject;
            break;
        case types.VGO_API_CALL_VALIDATION_TAB_ERROR:
            draft.errorTab = action.errorTab;
            draft.validationObject = action.validationObject;
            break;
        case types.VGO_API_CALL_SUCCESS:
            draft.validationObject = [];
            break;
        
    default:
        break;   

    }
}, initialState);

export default forms;


/*
 *
 * VGO_GET_FORM_PRIMARY_TABLE_FIELDS__REQUEST:null,
    VGO_GET_FORM_PRIMARY_TABLE_FIELDS__SUCCESS:null,
 *
 */