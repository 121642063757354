import { theme, customThemeStyles } from 'common/theme.js';
export const ProfileTheme = () => ({
    root: {
        backgroundColor: customThemeStyles.customTheme.backgroundColor + '!important',
        color: theme.palette.primary.contrastText + '!important',
        border: 0,
        paddingTop: 6,
    },
    icon: {
        color: theme.palette.primary.contrastText,
    },
    content: {
        paddingRight: 8,
        //height: 72,
    },
    accordionDetails: {
        padding: "0px!important" ,
    },
    avatarColor: {
        color: '#fff !important',
        backgroundColor: '#5F6871 !important',
        border: '1px solid !important',
    },
    firstNameItem: {
        paddingTop:'0px!important',
        maxWidth:'150px!important'
    },
    firstName: {
        wordBreak:' break-all'
    },
    roleName: {
        marginTop:-8
    },
    profileItem : {
        paddingTop: 0 +'!important'
    },
    adminProfileRoot: {
        color: theme.palette.primary.contrastText + '!important',
        border: 0,
        paddingTop: 6,
        marginLeft: "17px",
        marginTop: "13px",
        
    },

    adminProfileItem: {
        width: "100%",
       
    }

});