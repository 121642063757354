import React from 'react';
import LoginPanel from './LoginPanel';
import NavMenu from './NavMenu';
import Company from 'admin/components/aside/Company.js';
import { withRouter } from 'react-router-dom';
import { setSelectedMenuItem } from 'client/components/Common/Utility';
import MenuIcon from '@mui/icons-material/Menu';
import Cookies from 'universal-cookie';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'client/store/Objects';
import BergerMenu from './BergerMenu';
import Drawer from '@mui/material/Drawer';
import { withStyles } from '@mui/styles';
import { leftPanelTheme } from './theme/leftPanelTheme.js'
import { Scrollbars } from 'react-custom-scrollbars';
import { Box } from '@mui/material';

const cookies = new Cookies();
class LeftPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = { collapsed: false, expandMenu: "" };

    }

    componentDidMount() {
        let versagoCookie = cookies.get('Versago');
        if (versagoCookie === undefined) {
            this.props.requestPublicBanners();
            this.props.requestPublicNews();
        }
        else {
            let roleCookie = cookies.get('roleId');
            this.props.resetLayout(roleCookie);
        }
        this.props.setLeftMenuState(this.props.collapsed);
    }
    componentDidUpdate(nextProps) {
        if (this.props.propsRoleId !== nextProps.propsRoleId) {
            let versagoCookie = cookies.get('Versago');
          
            if (versagoCookie === undefined) {
              
                this.props.requestPublicBanners();
                this.props.requestPublicNews();
            } else {
               
                this.props.requestBanners(this.props.propsRoleId);
                this.props.requestNews(this.props.propsRoleId, this.props.actionSource);
            }
        }
    }

    toggleHeader = () => {

        setTimeout(
            () => { window.dispatchEvent(new Event('resize')); },
            100
        );
        this.setState({ expandMenu: "" });
        this.props.setLeftMenuState(!this.props.collapsed);
        this.props.resetMargin(!this.props.collapsed);
       
    }
    setExpandMenu = (menu) => {
        this.setState({ expandMenu: menu });
    }
    onClick(event) {
        setSelectedMenuItem(event, false);

    }

    render() {
        let versagoCookie = cookies.get('Versago');
        const isLogin = (versagoCookie === undefined) ? false : true;
        const { classes , siteTheme } = this.props;
        const open = !this.props.collapsed;
        const siteThemeLogo = {
            companyLogo: siteTheme.companyLogo,
        }
        return (

            <Drawer
                variant="permanent"
                className={open ? `${classes.drawer} ${classes.drawerOpen}` : `${classes.drawer} ${classes.drawerClose}`}
                classes={{
                    paper: open ? classes.drawerOpen : classes.drawerClose,
                    paperAnchorDockedLeft: classes.border,
                }}

            >



                {!this.props.collapsed &&

                    <div >
                        <div className={`${classes.customTheme} ${classes.burgerIcon} ${classes.burgerExpanded} }`}><MenuIcon className={classes.burgerIconRoot} onClick={this.toggleHeader}></MenuIcon></div>
                        <Company siteTheme={siteThemeLogo} resetMargin={this.props.resetMargin} />
                    </div>
                }


                <Scrollbars autoHide renderTrackHorizontal={props => <Box {...props} className={classes.scrollbarHorizontal} />}><Box className={classes.leftPanelFlex}>
                    {!this.props.collapsed &&
                        <LoginPanel history={this.props.history} expandMenu={this.state.expandMenu} setExpandMenu={this.setExpandMenu} menuCollapse={this.props.collapsed} resetMargin={this.props.resetMargin} propsIsLogin={isLogin} />}
                    {!this.props.collapsed &&
                        <NavMenu propsRoleId={this.props.propsRoleId} menuCollapse={this.props.collapsed} expandMenu={this.state.expandMenu} setExpandMenu={this.setExpandMenu} resetMargin={this.props.resetMargin} />}

                    {this.props.collapsed &&

                        <BergerMenu toggleHeader={this.toggleHeader} propsRoleId={this.props.propsRoleId}></BergerMenu>

                    }
                </Box>
                </Scrollbars>
               
            </Drawer>


        );
    }

}
const mapStateToProps = (state) => {
    return {
        objects: state.objects,
        siteTheme: state.layout.siteTheme
    }
}
export default withRouter(connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(withStyles(leftPanelTheme)(LeftPanel))  );