import { customThemeStyles } from 'common/theme.js';
export const homePageTheme = (theme) => ({
    ...customThemeStyles,
    deleteColumn: { width: "160px!important"},
    nameColumn: { width: "300px!important", wordBreak: "break-all!important" },
    publishHeader: {
        width: "96px!important",
        paddingLeft:"16px!important"
    },
    typeHeader: {
        width: "118px!important",
        paddingLeft: "16px!important"
    }
    ,
    subTypeHeader: {
        width: "143px!important",
        paddingLeft: "16px!important"
    }
    ,
    titleHeader: {
        width: "296px!important",
        paddingLeft: "16px!important"
    }
    ,
    creatorHeader: {
        width: "244px!important",
        paddingLeft: "16px!important"
    }
    ,
    createdHeader: {
        width: "173px!important",
        paddingLeft: "16px!important"
    },
    modifiedHeader: {
        width: "173px!important",
        paddingLeft: "16px!important"
    }
    ,
    expirationHeader: {
        width: "173px!important",
        paddingLeft: "16px!important"
    },
    deleteHeader: {
        width: "100px!important",
        paddingLeft: "2px!important"
    },

    publishCell: {
        paddingLeft: "2px!important"
    },
   
    deleteCell: {
       
        paddingLeft: "5px!important"
    },
    rowItem: {

        width:"615px"
    },
    fileUploadBox: {

        width: "325px"
    },

    roleItem: {

        width: "560px!important"
    },

    typeCell: {
        textTransform: "capitalize"
    },
    dateCell: {

        width: "150px"
    },
    eventDateItem: {

        width: "183px!important"
    },
    expirationItem: {

        width: "183px!important"
    },
    clickPromptItem: {

        width: "304px!important"
    }

    

});