import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { styled } from '@mui/styles';
import { withStyles} from '@mui/styles';
import { spacing } from '@mui/system';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import MuiTextField from '@mui/material/TextField';
import MuiButton from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import MUIDataTable from 'mui-datatables';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import QueryDependencyAlert from 'admin/components/section/dataViews/setup/DependencyAlert';
import { getDataViewQueryFieldList, apiGetDataViewDependencyList } from 'admin/actions/dataViewActions';
import { showError } from 'admin/actions/alertActions';
import MuiCard from '@mui/material/Card';
import { setupTheme } from 'admin/components/section/dataViews/setup/theme/setupTheme';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
const Button = styled(MuiButton)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Card = styled(MuiCard)(spacing);

class Query extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            query: '',
            options: {
                download: false,
                elevation: 0,
                jumpToPage: false,
                viewColumns: false,
                filter: false,
                search: false,
                sorting: true,
                print: false,
                pagination: false,
                selectableRows: 'none',
                responsive: 'scrollMaxHeight',
            },
            expanded: 'panel1',
            dependencyList: [],
            openDependencyList: false,
            showBusy: false,
    };
    }

    getColumns=() => {
        return this.props.queryFields.map((queryField, i) => {
            return {
                name: queryField.fieldName,
                label: queryField.fieldName,
            }

        });

        
    }
    getDependencyList = () => {
        this.setState({dependencyList: [],showBusy:true });
        apiGetDataViewDependencyList(this.props.dataView.reportId)
            .then((resp) => {
               //resp.data
               this.setState({ openDependencyList: true, dependencyList: resp.data.sort((a,b) =>a.name.localeCompare(b.name)), showBusy:false});

            })
            .catch(err => {
                const { message } = err;
                showError(this.props.dispatch, message, 5000)
            });
    }
    handleClose = (event) => {
       
        this.setState({ openDependencyList: false })
    };
    handleChange = e => {
        const {  value } = e.target;
        this.props.changeDataView(dataView => {
          return(  dataView.query = value,
              dataView.executeQuery = false
          )
        })
    }

    onExecuteClick = () => {
        this.props.changeDataView(dataView => dataView.executeQuery = true);
        let dataViewDto = { company: this.props.dataView.company, reportId: this.props.dataView.reportId, dataSourceType: this.props.dataView.dataSourceType, query: this.props.dataView.query }
        if (dataViewDto.query) {
            this.props.dispatch(getDataViewQueryFieldList(dataViewDto, 0, false, this.props.dataView.fieldDisplay, this.props.dataView.reportFilter, this.props.dataView.recordProcessing, this.props.dataView.reportSubReport));
        } else {
            showError(this.props.dispatch, "No valid fields found, please check the data source.", 5000)
        }
        
    }
     handlePanelChange = (panel) => (event, newExpanded) => {
         this.setState({expanded:newExpanded ? panel : false});
    }
    renderDependencyDetails() {
        const columns = [
            {
                name: "name",
                label: "Object Name",
            },
            {
                name: "type",
                label: "Object Type",
            },
            {
                name: "subType",
                label: "Dependency Type",
            },
        ];
        const { classes } = this.props;
        return (
            <Dialog disableScrollLock={true}
                open={this.state.openDependencyList}
                onClose={this.handleClose}
                aria-labelledby="draggable-dialog-title"
                PaperProps={{ sx: { width: "30%", height: "40%" } }}
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Dependency List
                    </DialogTitle>
                <DialogContent>
                    <MUIDataTable className={classes.dependencyDataTable} container
                        title={''}
                        data={this.state.dependencyList}
                        columns={columns}
                        options={this.state.options}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        
                        disableElevation
                        onClick={this.handleClose}
                    >Close</Button>
                   
                </DialogActions>
            </Dialog>
        );
    }
    renderQuery = (classes) => {

        return (
            <Grid container direction="row" justifyContent="space-between" >
                <Grid item xs={12} >
                    {this.state.openDependencyList && this.renderDependencyDetails()}
                    <Card variant="outlined" className={classes.queryCard}>
                     <Grid container direction="row" justifyContent="space-between">
                             <Grid item xs={12}>
                    <Accordion>
                                    <AccordionSummary classes={{ content: classes.querySummary, root: classes.querySummary, }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>SQL Query</Typography>
                            </AccordionSummary >
                                    <AccordionDetails >
                                        
                                            <FormControl variant="outlined" fullWidth  >
                                            <Grid container direction="row" justifyContent="space-between">
                                            <Grid item xs={12}>
                                        {this.props.dataView.hasDependency && <QueryDependencyAlert showBusy={this.state.showBusy}  showDependency={this.getDependencyList}/>}
                                                    <TextField className={classes.queryText} classes={{ MuiInputBase: classes.queryTextStyle }}
                                        required
                                        variant="outlined"
                                        size="medium"
                                    value={this.props.dataView.query}
                                    onChange={this.handleChange}
                                        fullWidth={true}
                                        multiline
                                                        label="Editor"
                                                        disabled={this.props.disabled}
                                    rows={15}
                                                        InputProps={{ classes: { input: classes.queryTextStyle } }}
                                        
                                    >
                                    </TextField>
                                            </Grid>
                                            </Grid>
                                                <Grid container direction="row" justifyContent="space-between">
                                                <Grid item xs={12} className={classes.buttonQueryExecute} >
                                        <Button
                                            disabled={this.props.disabled}
                                            className={classes.buttonExecute}
                                            variant="contained"
                                            disableElevation
                                            startIcon={<PlayCircleOutlineIcon />} buttonExecute
                                            onClick={this.onExecuteClick} 
                                        >Execute</Button>
                                                </Grid>
                                                </Grid>
                                        </FormControl>
                            </AccordionDetails>
                        </Accordion>
                </Grid>
                        </Grid>

                       
                    </Card>
                  

                

                    </Grid>
                </Grid>

            );
    }
    render() {
        const { classes } = this.props;
        return this.renderQuery(classes);
    }
}

function mapStateToProps(state) {
    return {

        dataView: state.dataViews.dataView,
    };
};

export default withRouter(connect(mapStateToProps)(withStyles(setupTheme)(Query)));