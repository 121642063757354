import { ActionTypes as types } from 'admin/actions/actionTypes';
import { produce } from 'immer';
import { reset, modifyItemList, selectAll, setPackageValidation, roleDependencyChecking, resetPackageInformation, mergePackage, resetImportPackage, getImportFileData, importUpdateInvalidConnectionList } from 'admin/actions/packageActions';

const initialState = {
    exportListItems:[],
    package: reset(),
    packageInformation: resetPackageInformation(),
    objects: [],
    isLoading: false,
    errors: {},
    alert: "",
    actionType: '',
    objectWithOutRole: [],
    downloadLog: false,
    selectDependencies:true,
    importPackage: resetImportPackage(),
    exportConnectionList:[],
    validateImportPackageStatus: '',
    importConnectionMappingErrorList:[],
};

const packages = produce((draft, action) => {
    
    switch (action.type) {
        case types.VGO_PACKAGE_EXPORT_GETLIST_REQUEST:;
            draft.isLoading = true;
            draft.exportListItems = [];
            break;
        case types.VGO_PACKAGE_EXPORT_GETLIST_SUCCESS:
            draft.exportListItems = produce(action.list, () => { });
            draft.isLoading = false;
            break
        case types.VGO_PACKAGE_EXPORT_GET_FILE_REQUEST:
        case types.VGO_PACKAGE_EXPORT_GET_LOG_FILE_REQUEST:
        case types.VGO_PACKAGE_IMPORT_PROCESS_REQUEST:
            draft.actionType = action.type;
            draft.isLoading = true;
            break;
        case types.VGO_PACKAGE_EXPORT_GET_FILE_SUCCESS:
        case types.VGO_PACKAGE_EXPORT_GET_FILE_ERROR:
        case types.VGO_PACKAGE_EXPORT_GET_LOG_FILE_SUCCESS:
        case types.VGO_PACKAGE_IMPORT_PROCESS_SUCCESS:
        case types.VGO_PACKAGE_IMPORT_PROCESS_ERROR:
            draft.isLoading = false;
            draft.downloadLog = true;
            break;
        case types.VGO_CHANGE_PACKAGE:
            action.change(draft.packageInformation);
            draft.selectDependencies = draft.packageInformation.selectDependencies;
            break;
        case types.VGO_PACKAGE_ADD_ITEM:
            draft.objectWithOutRole = produce(draft.objectWithOutRole, draftObjectWithOutRole => { roleDependencyChecking(draftObjectWithOutRole, draft.package.selectDependencies, action.item, true) });
            draft.package = produce(draft.package, draftPackage => { modifyItemList(draftPackage, action.item, true) });
            
            break;
        case types.VGO_PACKAGE_REMOVE_ITEM:
            draft.objectWithOutRole = produce(draft.objectWithOutRole, draftObjectWithOutRole => { roleDependencyChecking(draftObjectWithOutRole, draft.package.selectDependencies, action.item, false) });
            draft.package = produce(draft.package, draftPackage => { modifyItemList(draftPackage, action.item, false) });
            break;
        case types.VGO_PACKAGE_DEPENDENCY_LIST_REQUEST:
            draft.isLoading = true;
            draft.actionType = action.type;
            break;
        case types.VGO_PACKAGE_DEPENDENCY_LIST_SUCCESS:
            draft.isLoading = false;
            draft.actionType = action.type;
            draft.package = produce(action.package, () => { });
            break;
        case types.VGO_PACKAGE_RESET:
            draft.package = reset();
            draft.packageInformation = resetPackageInformation();
            draft.downloadLog = false;
            draft.selectDependencies = true;
            draft.errors = {};
            break;

        case types.VGO_PACKAGE_SELECTALL: {
            draft.package = produce(draft.package, draftPackage => { selectAll(draftPackage, action.checked, draft.exportListItems) });
            draft.isLoading = false;
            break;
        }
        case types.VGO_PACKAGE_VALIDATION:
            draft.actionType = types.VGO_PACKAGE_VALIDATION;
            draft.packageInformation = produce(draft.packageInformation, draftPackageInformation => { setPackageValidation(draftPackageInformation, action.validation) });
            break;

        case types.VGO_PACKAGE_MERGE:
            draft.package = produce(draft.package, draftPackage => { mergePackage(draftPackage, draft.packageInformation) });
            draft.actionType = types.VGO_PACKAGE_MERGE;
            break;
        case types.VGO_PACKAGE_IMPORT_FILE_GET:
            const importSettings = getImportFileData(action.file);
            draft.importPackage = produce(importSettings.importPackage, () => { });
            draft.exportConnectionList = importSettings.exportConnectionList;
            draft.validateImportPackageStatus = "";
            draft.importConnectionMappingErrorList = [];
            draft.downloadLog = false;
            break;

        case types.VGO_IMPORT_CHANGE_PACKAGE:
            action.change(draft.importPackage);
            break;
        case types.VGO_IMPORT_CHANGE_PACKAGE_CONNECTION:
            action.change(draft.importPackage);
            draft.importConnectionMappingErrorList = produce(draft.importConnectionMappingErrorList, draftImportConnectionMappingErrorList => { importUpdateInvalidConnectionList(draftImportConnectionMappingErrorList, action.connectionChangeObject) });
            break;
        case types.VGO_IMPORT_PACKAGE_RESET:
            const importResetSettings = resetImportPackage();
            draft.importPackage = produce(importResetSettings, () => { });
            draft.validateImportPackageStatus = "";
            draft.importConnectionMappingErrorList = [];
            draft.downloadLog = false;
            break;
        case types.VGO_IMPORT_PACKAGE_VALIDATE_REQUEST:
            draft.validateImportPackageStatus = action.type;
            break;
        case types.VGO_IMPORT_PACKAGE_VALIDATE_ERROR:
            draft.validateImportPackageStatus = action.type;
            break;
        case types.VGO_IMPORT_PACKAGE_VALIDATE_CLEAR_ERROR:
            draft.validateImportPackageStatus = action.type;
            break;
        case types.VGO_IMPORT_PACKAGE_VALIDATE_SUCCESS:
            draft.validateImportPackageStatus = '';
            const importObject = getImportFileData({ fileContent: draft.importPackage.packageFile, fileName: draft.importPackage.packageFileName },true);
            draft.importPackage = produce(importObject.importPackage, () => { });
            draft.exportConnectionList = importObject.exportConnectionList;
            break;
        case types.VGO_IMPORT_PACKAGE_CONNECTIONLIST_ERROR:
            draft.importConnectionMappingErrorList = action.connectionList;
            break;
        default:
            break;
    }
}, initialState);

export default packages;