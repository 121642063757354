import React from 'react';

import { Box, Button, Grid, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles'
import AddIcon from '@mui/icons-material/Add';
import { connect } from 'react-redux';
import { styled, withStyles } from '@mui/styles';
import { mainTheme } from 'common/theme';

import { withRouter } from 'react-router-dom';
import AlertMessage from 'admin/components/Common/AlertMessage';
import { filtersTheme } from './theme/filtersTheme.js';
import { getAlertHide } from 'admin/actions/alertActions';
const buttonTheme = createTheme({
    ...mainTheme,
    overrides: {
        MuiButton: {
            containedPrimary: {
                backgroundColor: mainTheme.palette.primary.light
            }
        }
    }
});

class FilterListHeader extends React.Component {
    constructor(props) {
        
        super(props);
    }
    OpenNewFilter = () => { 
        
            this.props.history.push('/Admin/Filter/new'); 
    }
    
    render() {
        
        let alert = this.props.alert;
        return (
            <Grid container direction="row" justifyContent="space-between" spacing={0} xl={12}>
                <Grid item>
                    <Typography variant="h4">Filters</Typography>
                </Grid>
                <Grid item>
                    <ThemeProvider theme={buttonTheme}>
                        <Button
                            variant="contained"
                            color="primary"
                            disableElevation
                            startIcon={<AddIcon />}
                            onClick={(e) => this.OpenNewFilter()}
                        >ADD FILTER</Button>
                    </ThemeProvider>
                </Grid>
            </Grid>

            
        );
    }

}
 
function mapStateToProps(state) {
    return {
        alert: state.alert,
    };
};
export default withRouter(connect(mapStateToProps)(withStyles(filtersTheme)(FilterListHeader)));
 
 