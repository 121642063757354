import React from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Collapse, IconButton, Typography } from '@mui/material';
import { Alert, AlertTitle } from '@mui/lab';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { filterTheme } from 'admin/components/section/dataViews/dataFilters/theme/filterTheme';
/**
 * AlertMessage Component
 */
class AlertMessage extends React.Component {

    renderClose = () => {
        if ( this.props.close ) {
            return (
                <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={this.props.close}
                >
                    <CloseIcon fontSize="inherit" />
                </IconButton>								
            );
        }
        else {
            return null;
        }
    }

    /**
     * Render the AlertMessage
     */
    render () {
        const { classes } = this.props;
        return (
            <Collapse in={this.props.open} classes={{ wrapper: classes.wrapper }}>
                <Alert classes={{ root: classes.root }}
                    action={this.renderClose()}
                    icon={this.props.icon}
                    severity={this.props.severity}
                >
                    {this.props.message}
                </Alert>
            </Collapse>
        );
    }

}

/**
 * Default properties for the AlertMessage
 */
AlertMessage.defaultProps = {
    open: true,
    severity: 'success',
    title: '',
    message: '',
    icon: <CheckIcon fontSize="inherit" />,
    close: null,
};

/**
 * Property types for the AlertMessage properties
 */
AlertMessage.propTypes = {
    open: PropTypes.bool,
    severity: PropTypes.oneOf( [
        'success',
        'info',
        'warning',
        'error',
    ] ),
    title: PropTypes.string,
    message: PropTypes.string,
    icon: PropTypes.element,
    close: PropTypes.func,
};

/**
 * default export for the AlertMessage component
 */
export default withStyles(filterTheme)(AlertMessage)
