import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import { spacing } from '@mui/system';
const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: spacing(1)
        }
    }
}));

function numberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            style={{ textAlign: props.defaultValue.textAlign, color: props.defaultValue.foreColor, paddingRight: "5px", ...props.defaultValue.styleSettings }}

            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                });
            }}
            isNumericString={true}
            decimalSeparator={props.defaultValue.decimalSeparator}
            thousandSeparator={props.defaultValue.thousandSeparator}
            decimalScale={props.defaultValue.decimalScale}
            
            prefix={props.defaultValue.prefix}
            fixedDecimalScale={props.defaultValue.fixedDecimalScale}
        />
    );
}

numberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};
let blurValue = false;

export default function (props) {
 
      let value =  (isNaN(props.value) || props.value === null) ? "" : props.value;
    const [values, setValues] = React.useState({
        numberFormat: value
    });
   
    React.useEffect(() => {
        if (value && parseFloat(value) !== parseFloat(values.numberFormat)) {
            if (!blurValue) {
                setValues({
                    ...values,
                    numberFormat: value
                });
            }
            else
                blurValue = false;
        }
        else if (!value && values.numberFormat ) {
            if (!blurValue) {
                setValues({
                    ...values,
                    numberFormat: value
                });
            }
            else
                blurValue = false;
        }

    }, [value, values])

    const handleChange = (event) => {
        setValues({
            ...values,
            numberFormat: event.target.value
        });
        const floatValue = parseFloat(event.target.value);
        if (floatValue !== value) {
            blurValue = true;
        }
    };

    const handleBlur = (event) => {
        const floatValue = parseFloat(values.numberFormat);
        if (floatValue !== value) {
            props.onChange(event, floatValue,props.settings);
            blurValue = false;
        }
    };

    const controlStyle = {
        color: !props.settings.foreColor ? props.settings.foreColor:'',
        background: !props.settings.backGroundColor ? props.settings.backGroundColor : '',
        width: "100%",
        textAlign:"right",
        height:props.height,
    }
   
    return (
        <div >
            <TextField
                type="tel"
                variant="standard"
                value={values.numberFormat}
                onChange={handleChange}
                onBlur={handleBlur}
                error={props.error}
                helperText={props.helperText}
                name={props.settings.name}
                label={props.settings.caption}
                id={`report-formatted-numberformat-input-${props.settings.name}`}
                defaultValue={{...props.settings, styleSettings:props.styleSettings}}
                className={props.className}
                InputProps={{
                    inputComponent: numberFormatCustom, style: controlStyle
                }}
            />
        </div>
    );
}

