import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { parse } from 'expression-eval';
import { withStyles } from '@mui/styles';
import MuiButton from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Grid,Typography} from "@mui/material";
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import CheckIcon from '@mui/icons-material/Check';
import MenuItem from '@mui/material/MenuItem'; 
import { styled } from '@mui/styles';

import { spacing } from '@mui/system';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { List, ListItemText, Divider } from '@mui/material';
import MuiListItem from "@mui/material/ListItem";
import MuiListItemButton from "@mui/material/ListItemButton";
import * as Enums from 'client/components/Common/Enum';
 
import AlertMessage from 'admin/components/section/dataViews/dataFilters/AlertMessage';
import { derivedFieldTheme, derivedFieldListSX, listItemTheme} from 'admin/components/section/dataViews/fieldDisplay/theme/derivedFieldTheme';
import { convertAllRuleFormula } from 'client/components/Common/Utility';
import { getCalculatedFormulaFields, getValidateFormula, updateCalculatedField, showValidationMessage, hideValidationMessage, getValidateRuleError, getValidateRuleSuccess } from 'admin/actions/fieldDisplayActions';
const Button = styled(MuiButton)(spacing);
const ListItem = withStyles(listItemTheme)(MuiListItem);
const ListItemButton = withStyles(listItemTheme)(MuiListItemButton);
const formDetailsFunctionValueEnum = { SUM: "SUM( )", AVG: "AVG( )" }
const formDetailsFunctionTextEnum = { SUM: "Sum", AVG: "Average" }
const ControlDataType={Numbers:"numbers",Text:"text",Date:"date"}
const dataType = [
    {
        label: "",
        value: "0"
    },
    {
        label: "int",
        value: "int"
    },
    {
        label: "smallint",
        value: "smallint"
    },
    {
        label: "binary",
        value: "binary"
    }
    ,
    {
        label: "boolean",
        value: "boolean"
    }
    ,
    {
        label: "char",
        value: "char"
    }
    ,
    {
        label: "date",
        value: "date"
    }
    ,
    {
        label: "datetime",
        value: "datetime"
    }
    ,
    {
        label: "decimal",
        value: "decimal"
    }
    ,
    {
        label: "float",
        value: "float"
    }
    ,
    {
        label: "image",
        value: "image"
    }
    ,
    {
        label: "money",
        value: "money"
    },
    {
        label: "nchar",
        value: "nchar"
    }
    ,
    {
        label: "ntext",
        value: "ntext"
    },
    {
        label: "numeric",
        value: "numeric"
    }
    ,
    {
        label: "nvarchar",
        value: "nvarchar"
    },
    {
        label: "real",
        value: "real"
    }
    ,
    {
        label: "sql-varient",
        value: "sql-varient"
    }
    ,
    {
        label: "text",
        value: "text"
    },
    {
        label: "timestamp",
        value: "timestamp"
    }
    ,
    {
        label: "uniqueidentifier",
        value: "uniqueidentifier"
    },
    {
        label: "varbinary",
        value: "varbinary"
    }
    ,
    {
        label: "varchar",
        value: "varchar"
    }
    ,
    {
        label: "uniqueidentifier",
        value: "uniqueidentifier"
    },
    {
        label: "varbinary",
        value: "varbinary"
    }
    ,
  

];
const formCommonOperators = [
    {
        value: "-(x)",
        text: "Subtract"
    },
    {
        value: "+(x)",
        text: "Addition"
    },
    {
        value: "*(x)",
        text: "Multiply"
    },
    {
        value: "/(x)",
        text: "Division"
    },
];
const formNumericOperators = [
    {
        value: "DATEDIFF(startdate, enddate)",
        text: "Days DIFF"
    },
    {
        value: "MONTHDIFF(startdate,enddate)",
        text: "Months DIFF"
    },
    {
        value: "YEARDIFF(startdate,enddate)",
        text: "Year DIFF"
    },
    {
        value: "HourDIFF(startdate,enddate)",
        text: "Hour DIFF"
    },
    {
        value: "MinutesDIFF(startdate,enddate)",
        text: "Minutes DIFF"
    }
];
const formDateTimeOperators = [
    {
        value: "AddDay(number,date)",
        text: "Add Day"
    },
    {
        value: "AddMonth(number,date)",
        text: "Add Month"
    },
    {
        value: "AddYear(number,date)",
        text: "Add Year"
    },
    {
        value: "AddHour(number,date)",
        text: "Add Hour"
    },
    {
        value: "AddMinutes(number,date)",
        text: "Add Minutes"
    },
];

const actionLinkComparison = [
    {
        value: " ( ",
        text:"("
    },
    {
        value: " ) ",
        text: ")"
    },
    {
        value: " < ",
        text: "Less Than"
    },
    {
        value: " > ",
        text: "Greater Than"
    },
    {
        value: " == ",
        text: "Equal To"
    },
    {
        value: " != ",
        text: "Not Equal To"
    },
    {
        value: " <= ",
        text: "Less Than or Equal To"
    },
    {
        value: " >= ",
        text: "Greater Than or Equal To"
    },
    {
        value: " between X and Y ",
        text: "Between"
    },
    {
        value: " AND ",
        text: "And"
    },
    {
        value: " OR ",
        text: "Or"
    },
]

const formDetailsFunctions = [
    {
        value: formDetailsFunctionValueEnum.SUM,
        text: formDetailsFunctionTextEnum.SUM,
    },
    {
        value: formDetailsFunctionValueEnum.AVG,
        text: formDetailsFunctionTextEnum.AVG,
    },
];
let fieldValue = "";
let fieldDataType = "";
let fromFormHeader = true;
let fromFormOperatos = true;
let functionValue = "";
let cursorStartPosition = 0;
let cursorEndPosition = 0;
let fieldIndex = "";
const success = "success";
const regularField = 1;
class DerivedField extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            formula: "",
            fieldName: "", 
            formulaList: [],
            selectedField: "",
            selectedFunction: "",
            buttonState: true,
            openFunctions: false,
            openOperators: false,
            openFormHeader: true,
            openFormDetails: true,
            dataTypeSelectedManually: false,
            disableValidateButton:true
        };
    }
    componentDidMount() {
       
        this.props.dispatch(hideValidationMessage());
        if (this.props.fromDataView) {
            if (!this.props.isActionLink) {
                this.dispatchGetCalculatedFormulaData();

                if (this.props.derivedField) {
                    const data = this.props.derivedField

                    this.setState({ formula: data.formula, fieldName: data.fieldName, dataType: data.dataType })
                }
            }
            else {
                this.setState({ formula: (this.props.value) ? this.props.value : "" })
            }
        } else {
            this.setState({ formula: (this.props.value) ? this.props.value : "" })
            fromFormOperatos = true;
        }

    }
    dispatchGetCalculatedFormulaData = () => {
        this.props.dispatch(getCalculatedFormulaFields(this.props.databaseType));

    }

    componentDidUpdate(prevProps) {
        if (this.props.alert !== prevProps.alert) {
            if (this.props.alert.severity === success) {

                this.setState({ buttonState: false })
            } 
        }
        if (this.props.fieldType !== prevProps.fieldType) {
            this.setState({ dataType: this.props.fieldType })
        }
        //if (this.state.formula !=="" && this.state.formula !== prevProps.formula) {
        //    this.setState({ disableValidateButton: false })
        //}
    }

    setDropDownData = (data) => {
        const array = data.map((item, index) => {

            return (<MenuItem key={item + index} value={item.value} > {item.label}</MenuItem >);
           
        });
        return array;
    }

    updateFieldValue = (fromForm) => {

        let updateFormula = (this.state.formula) ? this.state.formula : '';
        let cursorEnd = cursorStartPosition === cursorEndPosition ? cursorStartPosition : cursorEndPosition;
        let textBeforeCursorPosition = updateFormula.substring(0, cursorStartPosition)
        let textAfterCursorPosition = updateFormula.substring(cursorEnd, updateFormula.length)
        let formulaAp1 = (!this.props.isActionLink) ? `${textBeforeCursorPosition} "${fieldValue}"` : `${textBeforeCursorPosition} ${fieldValue}`;
        if (fromForm) {
            if (fromFormHeader) {
                if (fieldDataType === "numbers" || fieldDataType === "date") {
                    if (fromFormOperatos) {
                        formulaAp1 = `${textBeforeCursorPosition}[${fieldValue}]`;
                        updateFormula = formulaAp1 + textAfterCursorPosition;
                    }
                    else {
                        this.setState({ selectedField: "" });
                    }
                } else {
                    this.setState({ selectedField: "" });
                }
            }
            else {
                if (fieldDataType === "numbers" || fieldDataType === "date") {
                    formulaAp1 = `${textBeforeCursorPosition.trimEnd()}[${fieldValue}]`;
                    updateFormula = formulaAp1 + textAfterCursorPosition.trimStart();
                }
                else {
                    this.setState({ selectedField: "" });
                }
            }
        } else {
            updateFormula = formulaAp1 + textAfterCursorPosition;
        }

        cursorStartPosition = formulaAp1.length;
        cursorEndPosition = cursorStartPosition;
        this.setState({ formula: updateFormula, buttonState: true, disableValidateButton: false })
    }
    updateFunctionValue = (fromForm) => {
        
        let cursorEnd = cursorStartPosition === cursorEndPosition ? cursorStartPosition  : cursorEndPosition;
        let updateFormula = (this.state.formula) ? this.state.formula : '';
        let textBeforeCursorPosition = updateFormula.substring(0, cursorStartPosition)
        let textAfterCursorPosition = updateFormula.substring(cursorEnd, updateFormula.length)
        let formulap1 = textBeforeCursorPosition + functionValue;
        updateFormula = formulap1 + textAfterCursorPosition;
         
        cursorStartPosition = formulap1.length;
        cursorEndPosition = cursorStartPosition;
        this.setState({ formula: updateFormula, buttonState: true, disableValidateButton: false })
    }

    validateFormula = () => {
        if (!this.props.isActionLink) {
            this.props.dispatch(getValidateFormula(this.props.dataView, this.state.formula)).then(r => {
                this.setState({ showMessage: this.props.alert.showMessage, dataTypeSelectedManually: false, dataType: this.props.fieldType, disableValidateButton: true })
            });
        }
        else {
            try {
                 let formula = convertAllRuleFormula(this.state.formula);
                //let formula = this.state.formula;
                //if (formula.includes('between')){
                //    formula = convertAllRuleFormula(formula);
                //}
                var parseOutPut = parse(formula);
                //will return compond if there is any error in operator
                if (parseOutPut.type == "Compound") {
                    this.props.dispatch(getValidateRuleError());
                    this.setState({ showMessage: this.props.alert.showMessage, disableValidateButton: false })
                } else {
                    this.props.dispatch(getValidateRuleSuccess());
                    this.setState({ showMessage: this.props.alert.showMessage, disableValidateButton: true })
                }
            }
            catch (err) {
                this.props.dispatch(getValidateRuleError());
                this.setState({ showMessage: this.props.alert.showMessage,  disableValidateButton: false })
            }
        }
    }

    //convertAllRuleFormula = () => {
    //    let stringFormula = this.state.formula
    //    let newStringFormula = '';
    //    if (stringFormula.includes('AND')) {
    //        const formulaList = stringFormula.split('AND');
    //        let newFormula = formulaList.map(formula => {
    //            if (formula.includes('between')) {
    //                formula = this.convertBetweenComparison(formula);
    //            }
    //            return formula;
    //        });
    //        newStringFormula += newFormula.join(' AND ');

    //    }
    //    else if (stringFormula.includes('OR')) {
    //        const formulaList = stringFormula.split('AND');
    //        let newFormula = formulaList.map(formula => {
    //            if (formula.includes('between')) {
    //                formula = this.convertBetweenComparison(formula);
    //            }
    //            return formula;
    //        });
    //        newStringFormula += newFormula.join(' AND ');

    //    }
    //    else {
    //        if (stringFormula.includes('between')) {
    //            newStringFormula = this.convertBetweenComparison(stringFormula);
    //        }
    //    }
    //    return newStringFormula;
    //}

    //convertBetweenComparison = () => {
    //    let formula = this.state.formula;

    //    const arr = formula.split('between');
    //    const openBracket = arr[0].indexOf('(');
    //    const leftSide = arr[0].slice(openBracket + 1, arr[0].length)

    //    const closeBracket = arr[1].indexOf(')');
    //    const rightSide = arr[1].slice(0, closeBracket)

    //    const values = rightSide.split('and');

    //    formula = `${leftSide} <= ${values[0]} && ${leftSide} >= ${values[1]}`
    //    formula = (openBracket !== -1) ? `( ${formula}` : formula;
    //    formula = (closeBracket !== -1) ? `${formula} )` : formula;

    //    return formula;
    //}

    checkFieldName = () => {

        if (this.state.fieldName.trim() === "") {
           
            this.props.dispatch(showValidationMessage("Field name is required"));
            this.setState({ showMessage: this.props.alert.showMessage })
            return false
        }

        if (this.props.derivedField.fieldId) {
            const dObject = this.props.fieldDisplay.find(r => r.fieldName.toLowerCase() === this.state.fieldName.toLowerCase())
            if (dObject) {
                if (dObject.fieldId !== this.props.derivedField.fieldId) {
                    this.props.dispatch(showValidationMessage("Field Name already exists"));
                    this.setState({ showMessage: this.props.alert.showMessage })
                    return false;
                }
            }
        } else {

            const index = this.props.fieldDisplay.findIndex(r => r.fieldName.toLowerCase() === this.state.fieldName.toLowerCase())
            if (index > -1 && index !== this.props.derivedField.index) {
                this.props.dispatch(showValidationMessage("Field Name already exists"));
                 this.setState({ showMessage: this.props.alert.showMessage })
                return false;
            }
        }
        return true;

    }

    recordSelectedSection = (e) => {
        cursorStartPosition = e.target.selectionStart;
        cursorEndPosition = e.target.selectionEnd;
        if (e.target.selectionStart === 0 && e.target.selectionEnd === 0) {
            fromFormOperatos = true;
        }
    }
    updateCalculatedField = () => {
        if (this.props.fromDataView) {
            if (!this.props.isActionLink) {
                if (this.checkFieldName()) {
                    let data = { fieldName: this.state.fieldName, dataType: this.state.dataType, formula: this.state.formula, fieldCategory: "calculatedField", id: this.props.derivedField.id > 0 ? this.props.derivedField.id : this.props.derivedField.index }

                    this.props.dispatch(updateCalculatedField(this.props.fieldDisplay, data));
                    this.props.handleClose();
                }
            } else {
                this.props.handleClose(true, this.state.formula)
            }
        } else {
            this.props.handleClose(false, true, this.state.formula);
        }
    }

    closeDerivedField = (event, reason) => {

        if (reason && reason === "backdropClick")
            return;
        if (this.props.fromDataView) {
            if (!this.props.isActionLink) {
                this.props.handleClose()
            } else {
                this.props.handleClose(false, this.state.formula)
            }
        }
        else {
            this.props.handleClose(false, false, this.state.formula)
        }
    }

    handleFunctionClick = () => this.setState({ openFunctions: !this.state.openFunctions });
    handleOperatorClick = () => this.setState({ openOperators: !this.state.openOperators });
    handleFormHeaderClick = () => this.setState({ openFormHeader: !this.state.openFormHeader });
    handleFormDetailsClick = () => this.setState({ openFormDetails: !this.state.openFormDetails });
     
    setListFieldsDataView = () => {
        const row = this.props.fieldDisplay.map((item, index) => {
             
            if ((item.fieldCategory === Enums.FieldCategory.RegularField || item.fieldCategory === regularField) && (!item.fieldValue.includes("varcharmax"))) {
                return (
                    <ListItem key={"item" + item.fieldName} disablePadding={true} selected={this.state.selectedField ===
                        item.fieldName}>
                        <ListItemButton onClick={() => {
                            fieldValue = item.fieldName;
                            this.setState({ selectedField: item.fieldName })
                            this.updateFieldValue(false);
                        }}>
                            <ListItemText primary={item.fieldValue} />
                        </ListItemButton>
                    </ListItem  >
                )
            } else {

                return null;
            }
        }).filter(Boolean);
        return row; 
    }
    setListFieldsForm = () => {
        if (this.props.form.formType === 2) {
            return (
                <React.Fragment>
                    {this.setFormHeaderCollaps()}
                    {(this.props.form.formSection.detail.formGridControl.length > 0) ?
                        <React.Fragment> <ListItemButton onClick={this.handleFormDetailsClick}>
                            <ListItemText primary="Detail Fields" />
                            {this.state.openFormDetails ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                            <Collapse in={this.state.openFormDetails} timeout="auto" unmountOnExit>
                                {this.setFormDetailFields()}
                            </Collapse> </React.Fragment>
                        : null}
                </React.Fragment>

            );
        } else {
            return (
                <React.Fragment>
                    {this.setFormHeaderCollaps()}
                </React.Fragment>

            );
        }
        
    }
    setFormHeaderFields = () => {
        const row = this.props.form.formSection.header.map((item, index) => {
            if (item.controlUiId && item.controlType !== Enums.ControlType.SectionTitle && item.controlType !== Enums.ControlType.Button &&
                item.controlType !== Enums.ControlType.CheckBox && item.controlType !== Enums.ControlType.FileUpload && item.controlType !== Enums.ControlType.Signature && item.controlType !== Enums.ControlType.Image) {
                return (
                    <ListItemButton key={"item" + item.fieldName} disablePadding={true} selected={this.state.selectedField === item.controlUiId} onClick={() => {
                        fieldValue = item.fieldName;
                        fieldDataType = this.getDataType(item);
                        fromFormHeader = true;
                        this.setState({ selectedField: item.controlUiId })
                        this.updateFieldValue(true);
                    }} sx={{ ml: 3 }}>
                        <ListItemText primary={item.fieldName + ' (' + this.getDataType(item) + ')'} />
                    </ListItemButton>
                )
            }
        }).filter(Boolean);
        return row;
    }

    getDataType=(item) => {
        let dataType =item.dataType;
        if (dataType === "select" || dataType === 0) {
            switch (item.controlType) {
            
                case Enums.ControlType.NumericTextBox:
                    dataType = ControlDataType.Numbers
                    break;
                case Enums.ControlType.TextBox:
                case Enums.ControlType.DropdownList:
                    dataType = ControlDataType.Text;
                    break;
                case Enums.ControlType.DatePicker:
                case Enums.ControlType.TimePicker:
                    dataType = ControlDataType.Date;
                    break;
            default:
            }
        }

        return dataType
    }
    setFormHeaderCollaps = () => {
        return (
            <React.Fragment>
                <ListItemButton onClick={this.handleFormHeaderClick}>

                    <ListItemText primary="Header Fields" />
                    {this.state.openFormHeader ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={this.state.openFormHeader} timeout="auto" unmountOnExit>
                    {this.setFormHeaderFields()}
                </Collapse>
            </React.Fragment>
        );
    }
    setFormDetailFields = () => {
        const row = this.props.form.formSection.detail.formGridControl.map((item, index) => {
            if (item.controlUiId && item.controlType !== Enums.ControlType.Button &&
                item.controlType !== Enums.ControlType.CheckBox && item.controlType !== Enums.ControlType.Image) {
                return (
                    <ListItemButton key={"item" + item.fieldName} disablePadding={true} selected={this.state.selectedField === item.controlUiId} onClick={() => {
                        fieldValue = item.fieldName;
                        fieldDataType = this.getDataType(item);
                        fromFormHeader = false;
                        this.setState({ selectedField: item.controlUiId })
                        this.updateFieldValue(true);
                    }} sx={{ ml: 3 }}>
                        <ListItemText primary={item.fieldName + ' (' + this.getDataType(item) + ')'} />
                    </ListItemButton>
                )
            }
        }).filter(Boolean);
        return row;
    }
    setListFunctionsDataView = () => {
        return (
            <React.Fragment>
                <ListItemButton onClick={ this.handleFunctionClick}>

                    <ListItemText primary="Functions" />
                    {this.state.openFunctions ? <ExpandMore /> : <ExpandLess /> }
                </ListItemButton>
                <Collapse in={this.state.openFunctions} timeout="auto" unmountOnExit>
                    {this.setFunctionsDataView()}
                </Collapse>

                <ListItemButton onClick={this.handleOperatorClick}>

                    <ListItemText primary="Operators" />
                    {this.state.openOperators ? <ExpandMore /> : <ExpandLess />}
                </ListItemButton>
                <Collapse in={this.state.openOperators} timeout="auto" unmountOnExit>
                    {this.setOperatosDataView()}
                </Collapse>
            </React.Fragment>

        );
    }
    setFunctionsDataView = () => {
        if (this.props.formulaFields === undefined)
            return;
        const row = this.props.formulaFields.map((item, index) => {
            if (item.type === "Functions" && item.calculatedSyntax) {

                return (
                    <ListItemButton key={"item" + item.calculatedName} disablePadding={true} selected={this.state.selectedFunction === item.calculatedName} onClick={() => {
                        functionValue = item.calculatedSyntax;
                        this.setState({ selectedFunction: item.calculatedName });
                        this.updateFunctionValue(false);
                    }} sx={{ ml: 3 }}>
                        <ListItemText primary={item.calculatedName} />
                    </ListItemButton>
                )
            }
        });
        return row;
    }
    setOperatosDataView = () => {
        if (this.props.formulaFields === undefined)
            return;
        const row = this.props.formulaFields.map((item, index) => {
            if (item.type === "Operators" && item.calculatedSyntax) {

                return (
                    <ListItemButton key={"item" + item.calculatedName} disablePadding={true} selected={this.state.selectedFunction === item.calculatedName} onClick={() => {
                        functionValue = item.calculatedSyntax;
                        this.setState({ selectedFunction: item.calculatedName });
                        this.updateFunctionValue(false);
                    }} sx={{ ml: 3 }}>
                        <ListItemText primary={item.calculatedName} />
                    </ListItemButton>
                )
            }
        });
        return row;
    }
    setListFunctionsForm = () => {
        if (this.props.form.formType === 2) {
            return (
                <React.Fragment>
                    <ListItemButton onClick={this.handleFunctionClick}>

                        <ListItemText primary="Aggregators" />
                        {this.state.openFunctions ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={this.state.openFunctions} timeout="auto" unmountOnExit>
                        {this.setFunctionsForm()}
                    </Collapse>
                    {this.setFormHeaderOperator()}

                </React.Fragment>

            );
        } else {
            return (
                <React.Fragment>
                    {this.setFormHeaderOperator()}
                </React.Fragment>

            );
        }
    }
    setFormHeaderOperator = () => {
        return (
            <React.Fragment>
                <ListItemButton onClick={this.handleOperatorClick}>
                    <ListItemText primary="Operators" />
                    {this.state.openOperators ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={this.state.openOperators} timeout="auto" unmountOnExit>
                    {this.setOperatosForm()}
                </Collapse>
            </React.Fragment>
        );
    }
    setFunctionsForm = () => {
        const row = formDetailsFunctions.map((item, index) => {
            return (
                <ListItemButton key={"item" + item.text} disablePadding={true} selected={this.state.selectedFunction === item.text} onClick={() => {
                    functionValue = item.value;
                    fromFormOperatos = false;
                    this.setState({ selectedFunction: item.text });
                    this.updateFunctionValue(true);
                }} sx={{ ml: 3 }}>
                    <ListItemText primary={item.text} />
                </ListItemButton>
            )

        });
        return row;
    }
    setOperatosForm = () => {
        let operator = [];
        if (Enums.ControlType.NumericTextBox === this.props.formFieldControlType) {
            operator = [...formCommonOperators, ...formNumericOperators];
        }else {
            operator = [...formCommonOperators, ...formDateTimeOperators];
        }
       
        const row = operator.map((item, index) => {
            return (
                <ListItemButton key={"item" + item.text} disablePadding={true} selected={this.state.selectedFunction === item.text} onClick={() => {
                    functionValue = item.value;
                    fromFormOperatos = true;
                    this.setState({ selectedFunction: item.text });
                    this.updateFunctionValue(true);
                }} sx={{ ml: 3 }}>
                    <ListItemText primary={item.text} />
                </ListItemButton>
            )
        });
        return row;
    }
    setComparisonActionLink = () => {
        const row = actionLinkComparison.map((item, index) => {
            return (
                <ListItemButton key={"item" + item.text} disablePadding={true} selected={this.state.selectedFunction === item.text} onClick={() => {
                    functionValue = item.value;
                    fromFormOperatos = true;
                    this.setState({ selectedFunction: item.text });
                    this.updateFunctionValue(true);
                }} >
                    <ListItemText primary={item.text} />
                </ListItemButton>
            )
        });
        return row;
    }
     
    setAlert = () => {
       
        if (this.props.alert) {
            if (this.props.alert.open) {
               
                return <AlertMessage className={this.props.classes.alertHeight} 
                    open={this.state.showMessage }
                    severity={this.props.alert.severity}
                    title={this.props.alert.title}
                    message={this.props.alert.message}
                    icon={this.props.alert.icon}
                    close={this.onMessageClose}
                />
            } else {
                return "";
            }

        } else {

            return "";
        }
    }
    onMessageClose = () => {
        this.setState({ showMessage: false });
    }
    disableDataTypeDropDown = () => {
        if (this.state.dataType === "N/A" || this.state.dataTypeSelectedManually===true) {
            return false;
        } else { return true;} 
    }
    dataTypeOnChange = (value) => {
        this.props.dispatch(hideValidationMessage());
        this.setState({ dataType: value, dataTypeSelectedManually: true, buttonState:false})
    }
    renderFieldNameArea = () => {
        const { classes, fromDataView, isActionLink } = this.props;
        if (fromDataView && !isActionLink) {
            return (
                <Grid container item direction="row" justify="space-between" spacing={3} mt={-5} pb={4}>
                    <Grid item xs={6}>
                        <TextField className={this.props.classes.widthStyle} required id="standard-required" label="Field Name" value={this.state.fieldName} onChange={(event) => { this.setState({ fieldName: event.target.value }) }} inputProps={{ maxLength: 100 }} />
                    </Grid>
                    <Grid item xs={6} className={classes.rightGrid}>
                        <FormControl className={this.props.classes.widthStyle}>
                            <InputLabel id="demo-simple-select-readonly-label">Data Type</InputLabel>

                            <Select disabled={this.disableDataTypeDropDown()} className={this.props.classes.widthStyle} value={this.state.dataType} onChange={(event) => { this.dataTypeOnChange(event.target.value ) } }>{/*// ? this.state.dataType : this.props.fieldType && this.state.formula? this.props.fieldType : ""*/}
                                {this.setDropDownData(dataType)}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            );
        }
    }
    formulaOnChange = (value) => {
        this.setState({ formula: value, buttonState: true, disableValidateButton: false })
    }

    render() {
        const { classes, fromDataView, isActionLink } = this.props;
        return (

            <Dialog disableScrollLock={ true }  onClose={this.closeDerivedField} aria-labelledby="customized-dialog-title" open={this.props.visible} classes={{ paper: (fromDataView) ? (isActionLink) ? this.props.classes.dialogPaperForm : this.props.classes.dialogPaperDataView : this.props.classes.dialogPaperForm }} disableScrollLock={true} >
                <DialogTitle id="customized-dialog-title" onClose={this.closeDerivedField} className={classes.dialogTitle}>
                    {(fromDataView) ? (isActionLink)? "Rule" : "Derived Field" : "Formula"}
                </DialogTitle>
                <DialogContent className={this.props.classes.dialogOverflow}  >
                    <Grid container justify="space-between" spacing={2}>
                        {this.renderFieldNameArea()}
                        <Grid container item direction="row" justify="space-between" spacing={3}>
                            <Grid item xs={6}>
                                <Typography>Fields</Typography>
                            </Grid>
                            <Grid item xs={6} className={classes.rightGrid}>
                                <Typography>{(isActionLink)?"Comparison":"Operations"}</Typography>
                            </Grid>
                        </Grid>

                        <Grid container item direction="row" justify="space-between" spacing={3} mt={-4}>
                            <Grid item xs={6} >
                                
                                <List sx={derivedFieldListSX} className={classes.listSize}>
                                    {(fromDataView) ? this.setListFieldsDataView() : this.setListFieldsForm()}
                                    </List> 
                            </Grid>
                            <Grid item xs={6} className={classes.rightGrid}>
                                <List sx={derivedFieldListSX} className={classes.listSize} >
                                    {(fromDataView) ? (isActionLink) ? this.setComparisonActionLink(): this.setListFunctionsDataView() : this.setListFunctionsForm()}
                                </List >
                            </Grid>
                        </Grid>
                        <Grid container item   >
                            <Grid item xs={12} >
                                <TextField variant="outlined" ref="myTextarea" className={classes.widthStyle} multiline value={this.state.formula} onChange={(e) => this.formulaOnChange(e.target.value)}
                                    rows={4} required={(fromDataView) ? true : false} id="standard-required" label={(isActionLink) ? "Statement" : "Formula"} onBlur={this.recordSelectedSection}  />


                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider />
                <DialogActions className={classes.dialogStyle} >
                    <Grid container item mt={0} pl={2} pr={3} justifyContent="space-between" spacing={2} className={classes.gridAlign}>
                        <Grid item xs={2} mb={1.25}>
                            {(fromDataView) ?
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={this.state.disableValidateButton}
                                    size="small"
                                    startIcon={<CheckIcon />}
                                    onClick={this.validateFormula}
                                    className={classes.buttonStyle}
                                >Validate
                                </Button> : ''}
                        </Grid>
                        <Grid item xs={6} >
                            {this.setAlert()}
                        </Grid>
                        <Grid item xs={3} mr={1}>
                            <Button color="primary" onClick={this.closeDerivedField}>
                                Cancel
                            </Button>
                            <Button color="primary" autoFocus onClick={this.updateCalculatedField} color="primary" disabled={(fromDataView) ?this.state.buttonState:false}>
                                Save
                            </Button>
                        </Grid> 
                    </Grid>
                    
                </DialogActions>
            </Dialog>
            
        );

    }

}

/**
 * 
 * @param {*} state 
 */
function mapStateToProps(state) {
     
    return {
        fieldDisplay: state.dataViews.dataView.fieldDisplay,
        formulaFields: state.dataViews.formulaFields,
        fieldType: state.dataViews.dataType,
        alert: state.dataViews.alert,
        isLoading: state.loader.isLoading,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage,
        databaseType: state.dataViews.dataView.company.databaseType,
        dataView: state.dataViews.dataView,
        form: state.forms.form,
    };
};

export default withRouter(connect(mapStateToProps)(withStyles(derivedFieldTheme)(DerivedField)));