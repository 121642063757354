import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import { withStyles } from '@mui/styles';
import IconButton from "@mui/material/IconButton";
import { lookUpListTheme } from './theme/lookUpListTheme';
import { L10n } from '@syncfusion/ej2-base';
import { ColumnDirective, ColumnsDirective, GridComponent, Toolbar, Inject, Page, Search, Sort } from '@syncfusion/ej2-react-grids';
import ColumnTemplate from 'client/components/DataView/ColumnTemplate';
import { formatValue } from 'client/components/Common/Utility';
import { getDeviceType } from 'client/components/Common/Utility';
import * as Enums from 'client/components/Common/Enum';
import './theme/lookUpList.css';

L10n.load({
    'en-US': {
        'pager': {
            'currentPageInfo': '',
            'totalItemsInfo': '{1} to {2} of {0}',
        }
    }
});

const LookUpList = (props) => {
    const { classes } = props;
    const deviceType = getDeviceType();
    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        props.handleClose();
    }

    const template = (column, data) => {

        var filterData = {
            ReportId: props.dataViewDefinition.reportId,
            FilterParameter: props.dataViewDefinition.reportFilter.filter(function (fieldData, index, arr) {
                return fieldData.value !== "" && fieldData.value != null;
            }),
            Pagination: {}
        };

        return (<ColumnTemplate data={data} column={column} dataFilter={filterData} showErrorMessage={props.showErrorMessage} formatValue={formatValue} />);
    }

    const getColumns = () => {
        const columns = props.dataViewDefinition.reportOutputFields.filter(field => field.isVisible).map(column => {

            return (<ColumnDirective key={column.fieldId} field={column.fieldName} showColumnMenu={column.isDisplayTitle} headerText={column.isDisplayTitle ? column.displayFieldName : ' '} width={column.columnWidth} textAlign={column.textAlign}
                template={template.bind(this, column)}

            ></ColumnDirective>);

        });
        return (
            <ColumnsDirective>
                {columns}
            </ColumnsDirective>
        );
    }
    const rowselect = (args) => {
        if (args.target) {
            if (args.target) {
                const elementType = args.target.tagName.toLowerCase();
                if (elementType === 'img' || elementType ==='a') {
                    args.cancel = true;
                    return true;
                }
                else if (elementType === 'div' && args.target.childElementCount === 1 && args.target.childNodes[0].tagName.toLowerCase() === 'img') {
                    args.cancel = true;
                    return true;
                }
            }
        } 

        props.handleSelectedRow(args.data);
        
    }
    const columns = getColumns();
    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={props.open} classes={{ paper: classes.dialogPaper }} disableScrollLock={true}>
            <DialogTitle variant="subtitle1" id="customized-dialog-title" onClose={handleClose} className={classes.dialogTitle}>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    className={classes.dialogTitleClose}
                >
                    <CloseIcon />
                </IconButton>
                Find List
            </DialogTitle>
            <DialogContent className={classes.dialogOverflow}  >
                
                <GridComponent height={deviceType !== Enums.DeviceType.Desktop ? '100%' : '46.2vh'} dataSource={props.data} rowSelecting={rowselect.bind(this)}
                    allowPaging={true}
                    allowResizing={true}
                    allowSorting={true}
                    pageSettings={{ pageSize: (deviceType!== Enums.DeviceType.Desktop?20: 10) }}
                    toolbar={['Search']}
                >
                    {columns}
                    <Inject services={[Toolbar, Search, Page, Sort]} />
                </GridComponent>
            </DialogContent>

        </Dialog>

    );
}
export default (withStyles(lookUpListTheme)(LookUpList));
