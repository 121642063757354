let mockData = {
    dataViews: [],
};
const mockGetDataViews = () => {
    if (mockData.dataViews.length === 0) {
        let dataViews = generateDataViews();
        mockData.dataViews = dataViews;
    }
    return {
        data: [...mockData.dataViews],
    };
};

const mockGetDataView = (id) => {
    let dataView = null;
    //let dataFilters = mockData.dataFilters;
    let dataViews = generateDataViews();
    let index = dataViews.findIndex(elem => elem.reportId === id);
    if (index >= 0) {
        dataView = dataViews[index];
    }
    let result = {
        data: dataView,
    };
    return result;
}

const generateDataViews = (num = 2) => {
    let dataViews = [];
    let dataDisplay = [];
    let dataFilters = [];
    let dataFilterDetails = [];

    for (let i = 0; i < num+3 ; i++) {
        dataDisplay.push({
            fieldId: i + 1,
            fieldValue: `DocEntry_${i} (int)`,
            displayFieldName: `DocEntry_${i}`,
        });
    }

    for (let i = 0; i < num-1; i++) {
        dataFilterDetails.push({
            valueId: i + 1,
            valueDisplayId: `USD_${i}`,
            valueDescription: `USD_${i}`,
            paramFriendlyName: `DocEntry_2`
        });
    }

    for (let i = 0; i < num  ; i++) {
        dataFilters.push({
            paramId: i+1,
            paramName: `DocEntry_${i} (int)`,
            paramFriendlyName: `DocEntry_${i}`,
            paramDataType: 1,
            paramDatasource: 2,
            paramDefaultValue: `${i}`,
            //controlType: 2,
            controlType: 'Text',
            controlVisOrder: 0,
            paramOrder: 0,
            paramSessionSource: 0,
            mandatory: true,
            valueType: 'Dynamic',
            paramType: 'User-Applied Filter',
            profileId: 1,
            //paramFilterType: 1,
            paramFilterType: 'Equals',
            isVisible: true,
            dataFilterDetails: dataFilterDetails,
        });
    }

    for (let i = 0; i < num; i++) {
        dataViews.push({
            reportId: i + 1,
            reportName: `Test Report_${i}`,
            dataDisplay: dataDisplay,
            dataFilters: dataFilters,
        });
    }

    return dataViews;
};

export { mockGetDataViews, mockGetDataView };