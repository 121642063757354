import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { Grid, Typography, AppBar } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { sectionType } from 'admin/actions/actionTypes';
import { controlType } from 'admin/actions/actionTypes';
import DatabaseFields from 'common/DatabaseFields';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import fileImage from 'admin/assets/images/File.png'
import radioButtonImage from 'admin/assets/images/radioButton.svg'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import detailImage from 'admin/assets/images/Detail.png'
import signatureImage from 'admin/assets/images/Signature.png'
import { formUITheme } from './theme/formUITheme.js';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import { ObjectType, FormType } from 'client/components/Common/Enum';
import { Scrollbars } from 'react-custom-scrollbars';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AvTimerSharpIcon from '@mui/icons-material/AvTimerSharp';
import TitleIcon from '@mui/icons-material/Title';
import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined';
import FormatColorTextOutlinedIcon from '@mui/icons-material/FormatColorTextOutlined';
import SmartButtonIcon from '@mui/icons-material/SmartButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import cloneDeep from 'lodash/cloneDeep';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`}{...other}>
            <Typography component={'div'}>{children}</Typography>
        </div>
    );
}

class ControlTab extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            tabIndex: 0,
            fields: []
        };
    }

    componentDidMount() {
        this.getTabIndex();
    }

    getTabIndex = () => {
        let ControlTab_Index = cookies.get('Versago_Admin_Form_ControlTab_Index');
        if (ControlTab_Index !== undefined) {

            if (parseInt(ControlTab_Index) == 2 && this.props.form.formType === FormType.Standard) {
                this.setState({ tabIndex: 0 });
                cookies.set("Versago_Admin_Form_ControlTab_Index", 0);
            } else {
                this.setState({ tabIndex: parseInt(ControlTab_Index) });
            }

        }
        else {
            cookies.set("Versago_Admin_Form_ControlTab_Index", 0);
        }

    }


    handleChange = (event, newValue) => {
        this.setState({ tabIndex: newValue });
        cookies.set("Versago_Admin_Form_ControlTab_Index", newValue);
    };



    setFieldList = (tableDataFieldList, controls) => {
        const array = tableDataFieldList.map((item, index) => {


            const rowIndex = controls.findIndex(f => f.fieldName === item.fieldName)
            if (rowIndex !== -1) {
                if (controls[rowIndex].controlUiId) {
                    return { ...item, checked: 1 }
                } else {
                    return { ...item, checked: 0 }
                }
            } else {
                return { ...item, checked: 0 }
            }
        })
        return array;
    }

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <Grid container >
                    <Grid item xs={12} className={classes.controlTabRoot}>

                        <AppBar position="static" className={classes.tabRoot}  >
                            <Tabs value={this.state.tabIndex} onChange={this.handleChange} aria-label="tabs" variant="fullWidth" className={classes.tabsRoot}>
                                <Tab className={classes.body2} classes={{ root: classes.formControltab }} label="FORM CONTROLS" value={0} />
                                <Tab className={classes.body2} classes={{ root: classes.formControltab }} label="HEADER FIELDS" value={1} />
                                {this.props.form.formType === FormType.HeaderDetail ? <Tab className={classes.body2} classes={{ root: classes.formControltab }} label="DETAIL FIELDS" value={2} /> : ""}

                            </Tabs>
                        </AppBar>
                       
                        <TabPanel value={this.state.tabIndex} index={0}>

                            <Grid container direction="column" pt={3} pl={3} >
                                <Grid item >
                                    <Typography variant="body2" className={classes.heading} >Form Sections</Typography>
                                </Grid>

                                <Grid item xs={12} pt={2} pl={2.1} className={classes.sectionControlTab} >
                                    <Grid container item direction="row" >
                                        <Grid item xs={3} className={`${classes.controlBarIcon}`} onClick={() => { this.props.addControl(controlType.SectionTitle, this.props.controlTarget) }}>
                                            <Grid container><Grid item><TitleIcon className={classes.iconColor} /></Grid><Grid item><span className={classes.spanControltab1}>Section Title</span></Grid></Grid>
                                        </Grid>
                                        {this.props.form.formType === FormType.HeaderDetail ?
                                            <Grid item xs={9} className={`${classes.controlBarIcon}`} pl={1} onClick={() => { this.props.addDetail(6, this.props.controlTarget, false) }}>
                                                <img alt='' src={detailImage} /><span className={classes.spanControltab}>Detail Section</span>
                                            </Grid> : ""}
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} pt={1}>
                                    <Typography variant="body2" className={classes.heading} >Data Input</Typography>
                                </Grid>
                                <Grid item xs={12} pt={.2}>
                                    <Grid container direction="row">
                                        <Grid item className={`${classes.controlTextRow} ${classes.controlBarIcon}`} onClick={() => { this.props.addControl(controlType.TextBox, this.props.controlTarget) }}>
                                            <Grid container direction="row" >
                                                <Grid item><FormatColorTextOutlinedIcon className={classes.iconColor} /></Grid><Grid item><span className={classes.spanControlText}>Text</span></Grid></Grid>

                                        </Grid>
                                        <Grid item className={`${classes.controlNumericRow} ${classes.controlBarIcon}`} onClick={() => { this.props.addControl(controlType.NumericTextBox, this.props.controlTarget) }}>

                                            <Grid container><Grid item className={classes.controlTablDownIconItem}><KeyboardArrowDownIcon className={classes.formControl} /></Grid><Grid item className={classes.controlTablUpIconItem}><KeyboardArrowUpIcon className={classes.formControl} /></Grid><Grid item><span className={classes.spanControltab}>Numeric</span></Grid></Grid>

                                        </Grid>
                                        <Grid item className={`${classes.controlSelectRow} ${classes.controlBarIcon}`} onClick={() => { this.props.addControl(controlType.DropdownList, this.props.controlTarget) }}>
                                            <Grid container direction="row" >
                                                <Grid item><ArrowDropDownCircleOutlinedIcon className={classes.iconColor} /></Grid><Grid item><span className={classes.spanControltab1}>Select Menu</span></Grid></Grid>


                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container direction="row" >
                                        <Grid item className={`${classes.controlDateRow} ${classes.controlBarIcon}`} onClick={() => { this.props.addControl(controlType.DatePicker, this.props.controlTarget) }}>
                                            <Grid container> <Grid item><DateRangeIcon className={classes.iconColor} /></Grid><Grid item><span className={classes.spanControltab1}>Date Picker</span></Grid></Grid>
                                        </Grid>
                                        <Grid item className={`${classes.controlTimeRow} ${classes.controlBarIcon}`} onClick={() => { this.props.addControl(controlType.TimePicker, this.props.controlTarget) }}>
                                            <Grid container direction="row" >
                                                <Grid item><AvTimerSharpIcon className={classes.iconColor} /></Grid><Grid item><span className={classes.spanControltab1}>Time Picker</span></Grid></Grid>
                                        </Grid>


                                        <Grid item className={`${classes.controlButtonRow} ${classes.controlBarIcon}`} onClick={() => { this.props.addControl(controlType.Button, this.props.controlTarget) }}>
                                            <Grid container direction="row" >
                                                <Grid item><SmartButtonIcon className={classes.iconColor} /></Grid><Grid item><span className={classes.spanControltab1}>Button</span></Grid></Grid>



                                        </Grid>

                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container direction="row">
                                        <Grid item className={`${classes.controlMultiRow} ${classes.controlBarIcon}`} onClick={() => { this.props.addControl(controlType.CheckBox, this.props.controlTarget) }}>
                                            <Grid container direction="row" >
                                                <Grid item><CheckBoxOutlinedIcon className={classes.iconColor} /></Grid><Grid item><span className={classes.spanControltab1}>Checkbox</span></Grid></Grid>

                                        </Grid>
                                        <Grid item className={`${classes.controlFileRow} ${classes.controlBarIcon}`} onClick={() => { this.props.addControl(controlType.FileUpload, this.props.controlTarget) }}>
                                            <Grid container direction="row" >
                                                <Grid item><UploadFileIcon className={classes.iconColor} /></Grid><Grid item><span className={classes.spanControltab1}>File Upload</span></Grid></Grid>
                                        </Grid>

                                        <Grid item className={`${classes.controlSignRow} ${classes.controlBarIcon}`} onClick={() => { this.props.addControl(controlType.Signature, this.props.controlTarget) }} >

                                            <Grid container direction="row" >
                                                <Grid item><EditOutlinedIcon className={classes.iconColor} /></Grid><Grid item><span className={classes.spanControltab1}>Signature</span></Grid></Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>

                                <Grid item xs={12} pt={3}>
                                    <Typography variant="body2" className={classes.heading} >Content</Typography>
                                </Grid>
                                <Grid item xs={12} pl={4} pt={2} className={`${classes.controlBarIcon}`} onClick={() => { this.props.addControl(controlType.Image, this.props.controlTarget) }}>
                                    <Grid container direction="row" >
                                        <Grid item><CropOriginalIcon className={classes.iconColor} /></Grid><Grid item><span className={classes.spanControltab1}>Image</span></Grid></Grid>

                                </Grid>
                            </Grid>

                        </TabPanel>
                        <TabPanel value={this.state.tabIndex} index={1}>
                            <Grid container pt={1}>
                                <Grid item >
                                    {this.props.primaryTableFields && this.props.formSection ? <DatabaseFields addControl={this.props.addControl} controlTarget={this.props.controlTarget} sectionType={sectionType.Header} TableDataField={this.setFieldList(this.props.primaryTableFields, this.props.formSection.header)} formSection={this.props.formSection.header} handleDrop={this.props.handleDrop} isFromMenu={false} objectType={ObjectType.Form} /> : ""}
                                </Grid>
                            </Grid>
                        </TabPanel>
                        {this.props.formSection.detail ?
                            <TabPanel value={this.state.tabIndex} index={2}>
                                <Grid container pt={1}>
                                    <Grid item >
                                        {this.props.detailTableFields && this.props.formSection ? <DatabaseFields addControl={this.props.addControl} controlTarget={this.props.controlTarget} sectionType={sectionType.Detail} TableDataField={this.setFieldList(this.props.detailTableFields, this.props.formSection.detail.formGridControl)} formSection={this.props.formSection.detail.formGridControl} handleDrop={this.props.handleDrop} isFromMenu={false} objectType={ObjectType.Form} /> : ""}
                                    </Grid>
                                </Grid>
                            </TabPanel> : ""}
                     

                    </Grid>
                </Grid>


            </React.Fragment>
        );
    }
}

function getPrimaryFields(state) {

    if (state.forms.form.createNewTable && state.forms.form.formId === 0) {
        let tableFields = [];
        let prmaryFields = cloneDeep(state.forms.form.primaryTableDataFields)
        for (var i = 0; i < prmaryFields.length; i++) {
            let fields = prmaryFields[i]
            if (fields.fieldName.trim() !== '') {
                fields["sqlDataType"] = getControlType(fields.formatType)
                tableFields.push(fields)
            }
        }

        return tableFields
    } else {


        return state.forms.primaryTableFields.list
    }
}

function getDetailFields(state) {
    if (state.forms.form.formType === FormType.HeaderDetail) {
        if (state.forms.form.createNewTable && state.forms.form.formId === 0) {
            let datailFields = cloneDeep(state.forms.form.detailTableDataFields)
            let tableFields = [];
            for (var i = 0; i < datailFields.length; i++) {
                let fields = datailFields[i]

                if (fields.fieldName.trim() !== '') {
                    fields["sqlDataType"] = getControlType(fields.formatType)
                    tableFields.push(fields)
                }
            }
            return tableFields

        } else {

            let detailFields = cloneDeep(state.forms.detailTableFields.list)
            const index = state.forms.form.detailTableDataFields.findIndex(f => f.isPrimaryKeyField);
            if (index !== -1) {
                const fieldName = state.forms.form.detailTableDataFields[index].fieldName;
                detailFields[detailFields.findIndex(f => f.fieldName === fieldName)].isPrimaryKeyField = true
            }
            return detailFields;
        }
    }
    else {
        return [];
    }
}

function getControlType(dataType) {
    let controlType = 'nvarchar'
    switch (dataType) {

        case 'text':
            {
                controlType = 'nvarchar'
                break;
            }
        case 'integer':
            {
                controlType = 'int'
                break;
            }
        case 'decimal':
            {
                controlType = 'decimal'
                break;
            }
        case 'date':
            {
                controlType = 'date'
                break;
            }
        case 'dateTime':
            {
                controlType = 'datetime'
                break;
            }

        default: {
            controlType = 'nvarchar'
            break;
        }
    }

    return controlType;

}


function mapStateToProps(state) {
    return {
        formUI: state.forms.form.formUI,
        form: state.forms.form,
        forms: state.forms,
        primaryTableFields: getPrimaryFields(state),
        detailTableFields: getDetailFields(state),
        isLoading: state.loader.isLoading,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage,
        formSection: state.forms.form.formSection
    };
};
export default withRouter(connect(mapStateToProps)(withStyles(formUITheme)(ControlTab)));


