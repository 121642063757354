import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import FormControl from '@mui/material/FormControl';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { withStyles } from '@mui/styles';
import { styled } from '@mui/styles';
import { spacing } from '@mui/system';
import { CardContent, Grid } from '@mui/material';
import MuiCard from '@mui/material/Card';
import MuiTextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import User from './UserList';
import { getRole, setChangeRoleData, getNewRole } from 'admin/actions/roleActions';
import { RoleTab, LandingPageType, ActionTypes } from 'admin/actions/actionTypes';
import Report from './ReportList';
import Form from './FormList';
import Dashboard from './DashboardList';
import Menu from './MenuList';
import compose from 'recompose/compose'
import { rolesTheme } from './theme/rolesTheme.js';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import ActionBackdrop from 'admin/components/section/settings/Backdrop';
import FormHelperText from '@mui/material/FormHelperText';
import RoleNameText from './RoleNameText';
import { sortName } from 'client/components/Common/Utility';
const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`}{...other}>
            <Typography component={'div'}>{children}</Typography>
        </div>
    );
}
class RoleEdit extends React.Component {

    constructor(props) {
        
        super(props);
        let roleId = this.props.match.params.roleId;
        let id = null;
        let isUpdate = roleId !== "new";
        if (isUpdate) {
            id = parseInt(roleId);
            if (isNaN(id)) {
                // TODO: error on page?
                id = null;
            }
        }
        this.state = {
            isUpdate: isUpdate,
            tabIndex: 0,
            roleId: isUpdate ? id : null,
            landingPageList: [< MenuItem key = {0} value = {0} > Landing Page</MenuItem >],
            objectDisable: true,
            loading: isUpdate ? true : false

        };

    }
    componentDidMount() { 
        let roleId = this.props.match.params.roleId;
        let isUpdate = roleId !== "new";
        roleId = isUpdate ? roleId : null; 
        this.dispatchGetRoleData(roleId); 
    }   
    componentDidUpdate(prevProps, prevState) {
       
        if (this.props.roles.role !== undefined) { 
            if (prevProps.roles.role.defaultObjectType !== this.props.roles.role.defaultObjectType) { 
                this.getLandingPage(this.props.roles.role.defaultObjectType,true) 
                this.setState({ loading: false })
            } 
            if (this.props.roles.role.ReportList !== prevProps.roles.role.ReportList) { 
                this.getLandingPage(this.props.roles.role.defaultObjectType,true)
                this.setState({ loading: false })
            }
            if (this.props.roles.role.DashboardList !== prevProps.roles.role.DashboardList) {
                this.getLandingPage(this.props.roles.role.defaultObjectType,true)
                this.setState({ loading: false })
            }
            if (this.props.roles.role.FormList !== prevProps.roles.role.FormList) {
                this.getLandingPage(this.props.roles.role.defaultObjectType,true)
                this.setState({ loading: false })
            }
        } 
        if (this.props.roles.role.roleId > 0 && (this.props.roles.role.roleId !== prevProps.roles.role.roleId)) {
                this.props.history.push(`/Admin/Roles/${this.props.roles.role.roleId}`);
                this.setState({ loading: false })
         }  
    }
    dispatchGetRoleData = (roleId) => {
        if (roleId != null) {
            this.props.dispatch(getRole(roleId));
        } else {
            this.props.dispatch(getNewRole());
        }
    }
    handleChange = (event, newValue) => {
        this.setState({ tabIndex: newValue });
    };
    getLandingPage = (page,initialLoad) => { 
        let array = []
        
        if (page === LandingPageType.reportPage) {
            
            if (this.props.roles.role.ReportList != undefined) {
                let reportList = [...this.props.roles.role.ReportList];
                reportList.sort((a, b) => sortName(a.ReportName, b.ReportName)).map((report) => {
                     array.push(<MenuItem key={report.reportId}   value={report.ReportId}> {report.ReportName}</MenuItem>)

                })
            }

        } 
        else
            if (page === LandingPageType.formPage && this.props.roles.role.FormList !== undefined) {
                let formList = [...this.props.roles.role.FormList];

                formList.sort((a, b) => sortName(a.formName, b.formName)).map((form) => {
                    array.push(<MenuItem key={form.FormId} value={form.FormId}> {form.formName}</MenuItem>)
                });
        } 
            else if (page === LandingPageType.dashboardPage && this.props.roles.role.DashboardList !== undefined) {
                let dashboardList= [...this.props.roles.role.DashboardList];
                dashboardList.sort((a, b) => sortName(a.Name, b.Name)).map((dashboard) => {
                    array.push(<MenuItem key={dashboard.Id} value={dashboard.Id}> {dashboard.Name}</MenuItem>) 
                return "";

            }) 
            }
         
        this.props.dispatch(setChangeRoleData(RoleTab.defaultObjectTypeWithOutRefresh, page));
      
            if (page === LandingPageType.homePage) {
                array.push(<MenuItem key={0} value={0} > Landing Page</MenuItem>)
                if (!initialLoad) {
                    this.props.dispatch(setChangeRoleData(RoleTab.defaultObjectId, page));
                }
            }
            else {
                if (!initialLoad) {
                    this.props.dispatch(setChangeRoleData(RoleTab.defaultObjectIdWithOutRefresh, -1));
                
            }
        }

        if (array.length > 0) {
            this.setState({ landingPageList: array, objectDisable: false }); 
          } else {
            this.setState({ landingPageList: array, objectDisable: true });
        } 
        return array; 
    }
    setLandingPageType = (page) => { 
        this.getLandingPage(page); 
    }
    setRoleNameValue = (value) => {
         
        this.props.dispatch(setChangeRoleData(RoleTab.roleName, value));
    }
    getSortedObjectList = (objectList, selectedObjectList, objectKey, selectedObjectKey, selectedObjectNameKey, sortSettings) => {
        const sortDirection = sortSettings.direction
        const sortField = sortSettings.sortFrom
        if (sortField !== "select") {
            return objectList;
        }
        else {
            let selectedReportList = [...selectedObjectList]
            let selectedResult = selectedReportList.sort(function (a, b) {
                let x = a[selectedObjectNameKey].toLowerCase();
                let y = b[selectedObjectNameKey].toLowerCase();
                if (x < y) { return 1; }
                if (x > y) { return -1; }
                return 0;
            }).map(a => a[selectedObjectKey]);
            let sortedObjectList = [...objectList];

            return sortedObjectList.sort(function (a, b) {
                var A = a[objectKey], B = b[objectKey];

                if (selectedResult.indexOf(A) > selectedResult.indexOf(B)) {
                    const sort = sortDirection === "asc" ? -1 : 1;
                    return sort;
                } else {
                    const sort = sortDirection === "asc" ? 1 : -1;
                    return sort;
                }

            });


        }
    }
    render() { 
        const { classes } = this.props;
        return (<Grid container spacing={0}>
            {/*{(this.props.isLoading || this.props.roles.userLoading) && <ActionBackdrop />}*/}
            {this.state.loading  && <ActionBackdrop />}
           {/* {(this.props.roles.userLoading ) && <ActionBackdrop />}*/}
            <Box width={1}>
                <Grid container direction="row" justifyContent="space-between">
                    <Box width={1}>
                        <Card variant="outlined" className={classes.tab}>
                            <CardContent className={classes.tab}>
                                <Box pt={1.7} pl={1.4}>
                                    <Grid container spacing={8} >
                                        <Grid item>
                                            <Box minWidth={316} pt={1.1} > 
                                                <RoleNameText roleName={this.props.roles.role.roleName} error={this.props.roles.role.IsRoleNameError} helperText={this.props.roles.role.RoleNameHelperText} setRoleNameValue={this.setRoleNameValue} />
                                            </Box>
                                        </Grid>
                                        <Grid item>
                                            <Box minWidth={296} pt={1.1}>
                                                <FormControl size="medium">
                                                    <InputLabel id="label">Landing Page Type</InputLabel>
                                                    <Select MenuProps={{ disableScrollLock: true }} value={this.props.roles.role.defaultObjectType} onChange={(e) => this.setLandingPageType(e.target.value)} >
                                                        <MenuItem value={2}>Dashboard</MenuItem>
                                                        <MenuItem value={1}>DataViews</MenuItem>
                                                        <MenuItem value={3}>Form</MenuItem>
                                                        <MenuItem value={0}>Versago Home Page</MenuItem>
                                                    </Select> </FormControl>
                                            </Box>
                                        </Grid>
                                        <Grid item>
                                            <Box minWidth={296} pt={1.1}>
                                                <FormControl error={this.props.roles.role.IsLandingPageError }>
                                                    <InputLabel id="label">Landing Page *</InputLabel>
                                                    
                                                    <Select MenuProps={{ disableScrollLock: true }} disabled={this.state.objectDisable} value={this.props.roles.role.defaultObjectId} label="Landing Page *" onChange={(e) =>
                                                        this.props.dispatch(setChangeRoleData(RoleTab.defaultObjectId, e.target.value))  
                                                    }>
                                                        {this.state.landingPageList}
                                                    </Select>
                                                    <FormHelperText>{this.props.roles.role.LandingPageHelperText}</FormHelperText>
                                                </FormControl>

                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box pt={1.2}>
                                    <Grid container >
                                        <Box width={1}>
                                            <Tabs value={this.state.tabIndex} onChange={this.handleChange} indicatorColor="primary" textColor="primary"
                                            >
                                                <Tab label="USERS" value={0} className={classes.body2} classes={{ root: classes.rolesTab }} />
                                                <Tab label="DATA VIEWS" value={1} className={classes.body2} classes={{ root: classes.rolesTab }} />
                                                <Tab label="FORMS" value={2} className={classes.body2} classes={{ root: classes.rolesTab }} />
                                                <Tab label="DASHBOARDS" value={3} className={classes.body2} classes={{ root: classes.rolesTab }} />
                                                <Tab label="MENUS" value={4} className={`${classes.body2} ${classes.tabMenu}` } classes={{ root: classes.rolesTab }} />
                                            </Tabs>
                                        </Box>
                                    </Grid>
                                </Box>

                            </CardContent>
                        </Card>
                    </Box>

                </Grid>
                <Grid container >
                    <Box width={1} pt={.9}>
                       
                        {/* <Dashboard onChange={this.handleRoleChange} roleData={this.props.roles.rolesData} />*/}
                        <Card variant="outlined" className={classes.roleCard}>
                            <CardContent className={classes.roleCardContent}>
                                <TabPanel value={this.state.tabIndex} index={0}>
                                    {this.state.tabIndex === 0 && (<Box pt={.5}><User getSortedObjectList={this.getSortedObjectList} /></Box>)}
                                </TabPanel>
                                <TabPanel value={this.state.tabIndex} index={1} >
                                    {this.state.tabIndex === 1 && (<Report getSortedObjectList={this.getSortedObjectList} />)}
                                   
                                </TabPanel>
                                <TabPanel value={this.state.tabIndex} index={2} >
                                    {this.state.tabIndex === 2 && (<Form getSortedObjectList={this.getSortedObjectList}  />)}

                                </TabPanel>
                                <TabPanel value={this.state.tabIndex} index={3} >
                                    {this.state.tabIndex === 3 && (<Dashboard getSortedObjectList={this.getSortedObjectList} />)}
                                </TabPanel>
                                <TabPanel value={this.state.tabIndex} index={4} >
                                    {this.state.tabIndex === 4 && (<Menu />)}
                                </TabPanel>
                            </CardContent>
                        </Card>
                    </Box>

                </Grid>
            </Box>

        </Grid>
        );
    }

}

function mapStateToProps(state) {
   
    return {
        roles: state.roles,
        isLoading: state.loader.isLoading,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage,
        isDataLoading: state.roles.isLoading,
    };
};

export default compose(
    withRouter, withStyles(rolesTheme),
    connect(mapStateToProps),
)(RoleEdit);