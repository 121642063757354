import { showError } from 'client/components/Common/AlertAction';

const requestUpdateFavoriteStatus = 'REQUEST_UPDATE_FAVORITE_STATUS';

const resetFavorite = "RESET_FAVORITE";


export const favoriteActionCreator = {
    
    setFavorite: (objectDetails, addFavorite,removedObjectId) => async (dispatch, getState) => {

        dispatch({ type: requestUpdateFavoriteStatus });
        const url = 'api/Object/SetFavoriteObject';
        const response = await fetch(url,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    Type: objectDetails.objectType,
                    Name: objectDetails.objectName,
                    Id: removedObjectId ? removedObjectId :objectDetails.objectId
                })
            });
        if (response.status === 200) {
            let favoriteData = await response.json();
            if (addFavorite) {
                favoriteData = setFavoriteItemIcon(favoriteData, objectDetails)
            }
            else if (removedObjectId) {
                //const removedType = favoriteData.type;
                favoriteData.objectId = 0;
                ////favoriteData.objectType = removedType;
                ////favoriteData.type = objectDetails.objectType;
                
                favoriteData.removedObjectId = removedObjectId;
            }
            dispatch({
                type: resetFavorite, favoriteSettings: { ...favoriteData}
            });
           
            
        }
        else {
            showError(dispatch, 'Favorite setting failed.', 5000);
            
            }
            

        }

    }

const setFavoriteItemIcon = (favoriteObject, objectDetails) => {
    if (objectDetails.hasChart) {
        favoriteObject.icon = "icon-chart";
    }
    else if (objectDetails.hasPayment) {
        favoriteObject.icon = "icon-payments";
    }
    return favoriteObject;
}
   
