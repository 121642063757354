import { cloneDeep } from 'lodash';
export const MuiGridOptions = (options, isLoading, isDisable) => {
    var optionState = cloneDeep(options);
    if (isLoading) {
        optionState.textLabels = {
            body: {
                noMatch: "Data Loading",
            },
        }

    } else {
        optionState.textLabels = {
            body: {
                noMatch: "Sorry, no matching records found",
            },
        }
    }
    if (isDisable) {
        optionState.searchProps = {
            autoFocus: false,

        }
    }

    return optionState;

}