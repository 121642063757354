import { Payment } from "@mui/icons-material";

export const SettingStyle = (theme) => ({
    root: {
        width: '100%!important',
    },
    wrapper: {
        position: 'relative!important',
    },
    card: {
        //width: "724px",
        height: "510px!important",
        marginLeft: theme.spacing(2) + '!important',
        marginRight: theme.spacing(2) + '!important'
    },
    chip: {
        margin: theme.spacing(0.5) + '!important',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15) + '!important',
        fontWeight: theme.typography.fontWeightRegular + '!important',
    },
    buttonProgress: {
        color: "secondary!important",
        position: 'absolute!important',
        top: '50%!important',
        left: '50%!important',
        marginTop: -12,
        marginLeft: -12,
    },
    buttonSend: {
        minWidth: '95px!important',
        maxWidth: '95px!important',
        height: '36px!important',
        boxShadow: 'none!important',
        marginTop: '12px!important'

    },
    gridEmailSSl: {
        textAlign: 'right!important'

    },
    emailSSL: {
        marginRight: '0px!important',
        marginTop: '17px!important'
    },

    label: {
        fontSize: '0.8em!important'
    },
    gridSendButton: {

        maxWidth: '179px!important'
    },
    gridButton: {
        height: '30px!important'
    },
    alert: {

        marginTop: '10px!important'
    },
    accordionSummary: {

        minHeight: "0px!important",
        marginBottom: '0px!important',
        '&$expanded': {
            minHeight: "0px!important",
            margin: '0',
        }
    },

    accordionDetails: {
        paddingLeft: '0px!important',
        paddingRight: '0px!important',
        paddingBottom: '0px!important'

    },
    //Alert

    alertDivRoot: {
        maxWidth: '400px!important',
        '& > * + *': {
            marginTop: theme.spacing(1) + '!important',

        },
    },
    alertRoot: {
        borderRadius: '26px!important',
        padding: "1px 16px!important",

    },

    alertCollapse: {

        marginTop: "15px!important",


    },
    editor: {
        borderColor: 'black',
        color: 'black',
        border: '1px',
        borderWidth: 'thin',
        borderStyle: 'solid',
        minHeight: '172px',
        maxHeight: '172px',
        fontFamily: 'Roboto',
        fontSize: '16px',
        overflow: "auto",
        paddingLeft:'20px'
    },
    editorError: {
        borderColor: 'red',
        color: 'red',
        border: '1px',
        borderWidth: 'thin',
        borderStyle: 'solid',
        minHeight: '172px',
        paddingLeft: '20px',
        "&$focused": {
            color: 'black',
        }
    }
    ,
    editorToolbar: {
        flexShrink: 0,
        maxHeight: '30px!important',
        minWidth: '834px!important',

    },
    editorContainer: {
        maxWidth: '834px!important',
        marginTop: '0px!important'
    },
    
    accordionDetailsPassword: {

        paddingBottom: '0px!important'

    },
    emailPassword: {

        paddingTop: "4px!important"
    },
    emailUserName: {
        paddingTop: '20px!important'
    },

    emailPort: {
        paddingTop: '20px!important'
    },

    errorText: {

        color: 'red!important',
        fontSize: '12px!important',
        fontStyle: 'normal!important',
        marginTop: '3px!important',
        textAlign: 'left!important',
        fontFamily: 'Roboto!important',
        fontWeight: 'Regular!important',
        lineHeight: 1.66,
        letterSpacing: ' 0.4px!important'

    },
    passwordBoxEmail: {

        paddingTop: '18px!important'
    },

    passwordBoxSubject: {

        paddingTop: '32px!important'
    },
    textField: {
        '& p': {
            color: "black!important",
            fontSize: '12px!important',
            fontStyle: 'normal!important',
            fontFamily: 'Roboto!important',
            fontWeight: '400!important',
            lineHeight: '14.06px!important',
            
        }
    },
    installationId: {
        maxWidth:'269px!important'
    },
    installationText: {
        paddingTop:'25px!important'
    },
    installationValue: {
        paddingTop: '25px!important',
        paddingLeft:'5px!important'
    },
    buttonRow:{
        maxHeight: '48px!important'
    },
    paymentGateway: {
        width:'224px!important'
    }
    ,
     paymentGateway: {
        width: '224px!important'
    },
      authorize: {
        width: '256px!important'
    },

    creditCard: {
        width: '121px!important'
    }
    ,

    demoItem: {
        width: '392px!important'
    }
    ,
    demoItemControl: {
        width: '60px!important'
    }
    ,
    eCheck: {
        paddingRight: '23px!important'
    },
    visa: {
        width: '80px!important',
        

    },
      masterCard: {
        width: '123px!important',


    }
    ,
    amex: {
        width: '85px!important',


    },

    paymentButton: {
        paddingRight:"14.9px"
    },

     resetError: {
         float: "right",
         paddingRight:"30px"
    }
 

});

