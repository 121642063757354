import { getRandom } from 'admin/mock/mockData';
import { mockGetUsers, mockUpdateUser, mockGetUser, mockGetRoles } from 'admin/actions/mockUserActions';
import { mockGetFilters, mockUpdateFilter, mockGetFilter, mockDeleteFilter, mockGetDatabaseList, mockGetDataSourceList, mockGetFieldsList} from 'admin/actions/mockFilterActions';
import { mockGetSubRecords, mockGetSubRecordFields, mockGetDataViewFields } from 'admin/actions/mockDataViewSubRecordsActions';
import { mockGetDataView } from 'admin/actions/mockDataFilterActions';
import { mockGetFieldDisplay } from 'admin/actions/mockFieldDisplayActions';

/**
 * mock the api 
 */
const mockApi = {
    getUsers: () => {
        console.log( 'mockApi.getUsers' );
        let promise = new Promise( ( resolve, reject ) => {
            setTimeout( () => resolve( mockGetUsers() ), getRandom( 350, 500 ) );
        });
        return promise;
    },
    updateUser: ( user ) => {
        console.log( 'mockApi.updateUser', user );
        let promise = new Promise( ( resolve, reject ) => {
            setTimeout( () => resolve( mockUpdateUser( user ) ), getRandom( 350, 500 ) );
        });
        return promise;
    },
    getUser: ( id ) => {
        console.log( 'mockApi.getUser', id );
        let promise = new Promise( ( resolve, reject ) => {
            setTimeout( () => resolve( mockGetUser( id ) ), getRandom( 350, 500 ) );
        });
        return promise;
    },
    getRoles: () => {
        console.log( 'mockApi.getRoles' );
        let promise = new Promise( ( resolve, reject ) => {
            setTimeout( () => resolve( mockGetRoles() ), getRandom( 350, 500 ) );
        });
        return promise;
    },
    getFilters: () => {
        console.log('mockApi.getFilters');
        let promise = new Promise((resolve, reject) => {
            setTimeout(() => resolve(mockGetFilters()), getRandom(350, 500));
        });
        return promise;
    },
    updateFilter: (filter) => {
        console.log('mockApi.updateFilter', filter);
        let promise = new Promise((resolve, reject) => {
            setTimeout(() => resolve(mockUpdateFilter(filter)), getRandom(350, 500));
        });
        return promise;
    },
    getFilter: (id) => {
        console.log('mockApi.getFilter', id);
        let promise = new Promise((resolve, reject) => {
            setTimeout(() => resolve(mockGetFilter(id)), getRandom(350, 500));
        });
        return promise;
    },
    deleteFilter: (id) => {
        console.log('mockApi.deleteFilter', id);
        let promise = new Promise((resolve, reject) => {
            setTimeout(() => resolve(mockDeleteFilter(id)), getRandom(350, 500));
        });
        return promise;
    },
    getDatabaseList: () => {
        console.log('mockApi.getDatabaseList');
        let promise = new Promise((resolve, reject) => {
            setTimeout(() => resolve(mockGetDatabaseList()), getRandom(350, 500));
        });
        return promise;
    },
    getDataSourceList: (companyid) => {
        console.log('mockApi.getDataSourceList');
        let promise = new Promise((resolve, reject) => {
            setTimeout(() => resolve(mockGetDataSourceList(companyid)), getRandom(350, 500));
        });
        return promise;
    },
    getFieldsList: (companyid, dataSource) => {
        console.log('mockApi.getFieldsList');
        let promise = new Promise((resolve, reject) => {
            setTimeout(() => resolve(mockGetFieldsList(companyid, dataSource)), getRandom(350, 500));
        });
        return promise;
    },
    getSubRecords: (id) => {
        console.log('mockApi.mockGetSubRecords');
        let promise = new Promise((resolve, reject) => {
            setTimeout(() => resolve(mockGetSubRecords()), getRandom(350, 500));
        });
        return promise;
    },
    getSubRecordFields: (id) => {
        console.log('mockApi.mockGetSubRecordFields');
        let promise = new Promise((resolve, reject) => {
            setTimeout(() => resolve(mockGetSubRecordFields()), getRandom(350, 500));
        });
        return promise;
    },
    getDataViewFields: (id) => {
        console.log('mockApi.mockGetDataViewFields');
        let promise = new Promise((resolve, reject) => {
            setTimeout(() => resolve(mockGetDataViewFields()), getRandom(350, 500));
        });
        return promise;
    },
    getDataView: (id) => {
        console.log('mockApi.getDataView', id);
        let promise = new Promise((resolve, reject) => {
            setTimeout(() => resolve(mockGetDataView(id)), getRandom(350, 500));
        });
        return promise;
    },
    getFieldDisplay: (id) => {
        console.log('mockApi.getFieldDisplay');
        let promise = new Promise((resolve, reject) => {
            setTimeout(() => resolve(mockGetFieldDisplay(id)), getRandom(3, 500));
        });
        return promise;
    },
};

export default mockApi;
