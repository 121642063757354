
import { customThemeStyles, customColorPalette } from 'common/theme';
import createBreakpoints from "@mui/system/createTheme/createBreakpoints";

const filterControlColor = "rgba(109, 109, 109, 0.87)";
const filterControlPlaceholderColor = "rgba(109, 109, 109, 0.36)";
const breakpoints = createBreakpoints({});

export const dataListTheme = () => ({
    ...customThemeStyles,
    thresholdDialogPaper: {
        minHeight: '20vh',
        maxHeight: '20vh',
    },
    instruction: {
        wordBreak: "break-word",
        justifyContent: "space-between!important",
    },
    instructionGrid: {
        justifyContent: "space-between",
    },
    paymentModeLabel: {
        marginTop: "2px!important",
        marginLeft: "6px!important"
    },
    processing_SuccessLabel: {
        color: "#5CB660"
    },
    processing_FailedLabel: {
        color: "#F55448"
    },
    processing_Details: {
        [breakpoints.down('sm')]: {
            marginTop: "4px"
        },
        [breakpoints.up('sm')]: {
            marginTop: "4px"
        },
        [breakpoints.up('md')]: {
            marginLeft: "48px",
            marginTop: "4px"
        },
        [breakpoints.up('lg')]: {
            marginLeft: "48px",
            marginTop: "4px"
        },
    },
    summaryTable: {
        [breakpoints.down('sm')]: {
            marginRight: "16px"
        },
        [breakpoints.up('sm')]: {
            marginRight: "16px"
        },
        [breakpoints.up('md')]: {
            marginRight: "16px"

        },
        [breakpoints.up('lg')]: {

        },
    },
    paymentSection: {
        [breakpoints.down('md')]: {

            marginBottom: "16px!important"
        },
    },

});
export const editableGridNumericControlTheme = {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.54)",
}
export const filterTheme = {
    root: {
        "& .MuiInputBase-root": {
            width: "188px",
            marginTop: "13px",
            "& .MuiButtonBase-root": {
                padding: 7,

            },
        }
    },
    filterControlColor: {
        color: filterControlColor,
    },
    calendarIconColor: {
        color: filterControlPlaceholderColor,
    },
    filterControl: {
        width: "188px",
        margin: "0px",
        color: filterControlColor,
        fontSize: '14px!important',
    },
    numericfilterControl: {
        width: "188px",

        color: filterControlColor + '!important',
        fontSize: '14px!important',
        marginTop: '-3px!important'
    },
    textFilterControl: {
        width: "188px",
        color: filterControlColor + '!important',
        paddingTop: "8px!important"
    },
    selectFilterControl: {
        width: "188px",
        marginTop: "16px",
        color: filterControlColor,
        fontSize: '14px!important'
    },
    dividerFilterControl: {
        height: "47%",
        marginTop: "10px",
        backgroundColor: filterControlPlaceholderColor,

    },
    filterList: {
        width: "100%",
    },
    filterAccordionSummary: {
        minHeight: "0px",
    },
    filerAccordionDetails: {
        marginTop: "-26px",
        paddingBottom: "8px",
    },
    applyButtonGridItem: {
        paddingLeft: '8px',

    },
    applyButton: {
        width: '64px',
        fontSize: '12px'
    },

}
export const displayEventTheme = () => ({
    ...customThemeStyles,
    evenItemLabel: {
        color: `${customColorPalette.black[54]} !important`
    },
    dialog: {
        width: "498px!imortant",
        height: "504px!important",

    },
    dialogTitle: {
        height: '40px !important',
        textAlign: "center !important",
        padding: "0px!important",
        paddingTop: "12px!important",
    },
    dialogContent: {
        padding: "0px!important",
        color: `${customColorPalette.black[54]} !important`
    },
    closeIcon: {
        marginBottom: "10px!important"
    }

});