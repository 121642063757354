import React from "react";
import { useState } from "react";
import { Checkbox, Button, Tooltip  } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Stack from '@mui/material/Stack';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { grey } from '@mui/material/colors';

function GridCheckBoxHeader(props) {
    const sortDirection = props.sortSettings ? props.sortSettings.direction:"";
    const sortFrom = props.sortSettings ? props.sortSettings.sortFrom : "";
    const [clearSort, setClearSort] = useState(sortFrom === "select");
    const [isShown, setIsShown] = useState(false);
    React.useEffect(() => {
        if (clearSort) {
            setClearSort(false);
            props.clearSort()
        }
        
    }, [props.role[props.selectedObjectKey]]);
    const divClick = (event) => {
        if (event.target.localName !== "input") {
            setClearSort(true);
            setIsShown(false);
            props.handleSelectCheckBoxSort();
        } 
    }
    const checkBoxOnChange = (event) => {
        props.handleChange(event)
    }
    return (
        <td key="selectAll" className={props.selectAll ? props.selectAll : ""}>
            <div  style={{ cursor: 'pointer' ,width:"50px" }}>
                <Stack direction="row">
                    <Checkbox
                        className={props.selectCheckbox}
                        color="primary"
                        onChange={checkBoxOnChange}
                        checked={props.checked} />
                    <Tooltip title="Sort" open= {isShown} >
                        <Button onClick={divClick}
                            onMouseEnter={() => setIsShown(true)}
                            onMouseLeave={() => setIsShown(false)}
                        >
                             {(sortFrom === "select" && clearSort) ?  sortDirection === "asc" ?
                                <ArrowUpwardIcon fontSize="small" sx={{ color: grey[600] }} /> : sortDirection === "desc" ?
                                <ArrowDownwardIcon fontSize="small" sx={{ color: grey[600],mt:"3px" }} /> : <div /> :  <div />}
                        </Button>
                    </Tooltip>
                </Stack>
            </div>
        </td>
        );
}
function mapStateToProps(state) {
    return {
        role: state.roles.role,
    };
};

export default withRouter(connect(mapStateToProps)((GridCheckBoxHeader)));
//export default GridCheckBoxHeader;