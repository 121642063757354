import React from 'react';
import { withStyles } from '@mui/styles';
import { Box, Grid, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import MuiButton from '@mui/material/Button';
import { withRouter } from 'react-router';
import { styled } from '@mui/styles';
import { spacing } from '@mui/system';
import { connect } from 'react-redux';
import { updateCustomWidgetList} from 'admin/actions/customWidgetAction';

import { APILinksTheme } from './theme/APILinksTheme.js';

const Button = styled(MuiButton)(spacing);

class APILinkHeader extends React.Component {
    constructor(props) {

         super(props);
        //this.isUpdate = this.props.match.params.filter !== "new";

    }
    componentDidUpdate() {
        //this.isUpdate = this.props.match.params.filter !== "new";

    }
    discardNewFilter = () => {

       // this.props.history.push('/Admin/Filters');
        //this.props.dispatch({ type: types.VGO_DISCARD_USER });
    }
    saveWidgetChanges = () => {
        this.props.dispatch(updateCustomWidgetList(this.props.customWidgets, this.props.activeWidget));
    }

    render() {

       // let alert = this.props.alert;

        return (
            <Grid container direction="row" justifyContent="space-between" spacing={0} xl={12}>
                <Grid item>
                    <Typography variant="h4">API & Links</Typography>
                </Grid>
                <Grid item >
                    <Grid container direction="row" justifyContent="space-between" spacing={1} >
                        <Grid item >
                            {/*<Button*/}
                            {/*    className={this.props.classes.customControlButton}*/}
                            {/*    variant="defaultContained"*/}
                            {/*    disableElevation*/}
                            {/*    startIcon={""}*/}
                            {/*    onClick={this.discardNewFilter}*/}
                            {/*>CANCEL</Button>*/}
                        </Grid>
                        <Grid item >
                            <Button
                                className={this.props.classes.customControlButton}
                                color="primary"
                                variant="contained"
                                disableElevation
                                startIcon={<CheckIcon />}
                                onClick={this.saveWidgetChanges}
                            >SAVE</Button>
                        </Grid> </Grid>
                </Grid>
            </Grid>
        );




    }

}
/**
 * mapStateToProps
 * @param {State} state 
 */
function mapStateToProps(state) {
    let props = {
        isLoading: state.loader.isLoading,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage,
        customWidgets: state.customWidgets.customWidgets,
        activeWidget: state.customWidgets.activeWidget,
        validationError: state.customWidgets.widgetValidation,
    };

    return props;
};
export default withRouter(connect(mapStateToProps)(withStyles(APILinksTheme)(APILinkHeader)));
