import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import  MUIDataTable  from 'mui-datatables';

import { DateTime } from 'luxon';

import { getUsers, getAdminUsers, updateUserLite } from 'admin/actions/userActions';
import { getRoles, getUser, getFilters } from 'admin/actions/userActions';

import Switch from '@mui/material/Switch';
import { ActiveState } from 'admin/actions/actionTypes';
import { MuiGridOptions } from 'admin/components/Common/CommonOptions';

import { Box } from '@mui/material'

import { theme } from 'common/theme.js';

import { produce } from 'immer';
import { objectListFormatDate } from 'admin/components/Common//DateFormat';
import { usersTheme } from './theme/usersTheme.js';
import { withStyles } from '@mui/styles';
const defaultColumnOptions = {
    filter: true,
    sort: true,
     //style a compact grid https://github.com/gregnb/mui-datatables/issues/714
    setCellProps: ( cellValue, rowIndex, columnIndex ) => {
        return {
            style: {
                padding: theme.spacing(1),          // use the theme helper function?
            },
        };
    },
};

class UserList extends React.Component {

    constructor(props) {
        const { classes } = props;
        super(props);
        this.state = {
            columns: [
                {
                    name: "activeState",
                    label: "Active",
                    options: {
                        ...defaultColumnOptions,
                        filterList: [ActiveState.active],
                        customBodyRenderLite: this.userActiveTemplate,
                    },
                },
                {
                    name: "email",
                    label: "Email",
                    options: {
                        ...defaultColumnOptions,
                        setCellProps: () => ({ className: classes.defaultColumn }),
                    },
                },
                {
                    name: "lastName",
                    label: "Last Name",
                    options: {
                        ...defaultColumnOptions,
                        setCellProps: () => ({ className: classes.defaultColumn }),
                    },
                },
                {
                    name: "companyName",
                    label: "Company Name",
                    options: {
                        ...defaultColumnOptions,
                        setCellProps: () => ({ className: classes.defaultColumn }),
                    },
                },
                {
                    name: "createDateTime",
                    label: "Created",
                    options: {
                        ...defaultColumnOptions,
                        customBodyRenderLite: this.createdDateFormatTemplate,
                    },
                },
                {
                    name: "lastUpdateDateTime",
                    label: "Updated",
                    options: {
                        ...defaultColumnOptions,
                        customBodyRenderLite: this.updatedDateFormatTemplate,
                    },
                },
                {
                    name: "userType",
                    label: "Admin Access",
                    options: {
                        ...defaultColumnOptions,
                        customBodyRenderLite: this.adminAccessTemplate,
                    },
                },
            ],
            options: {
                download: false,
                elevation: 0,
                responsive: 'scrollMaxHeight',
                pagination: false,
                filter: true,
                filterType: 'multiselect', 
               // customSearchRender: debounceSearchRender(500),
                onCellClick: ( colData, cellMeta ) => {
                    let colIndex = cellMeta.colIndex;
                    let colName = this.state.columns[ colIndex ].name;
                    if (colName !== "activeState" && colName !== "userType") {
                        let dataIndex = cellMeta.dataIndex;
                        let user = this.props.users[ dataIndex ];
                        //this.props.dispatch( getFilters() );
                        //this.props.dispatch( getRoles() );
                        //this.props.dispatch( getUser( user.userId, () => {
                        //    this.props.history.push(`/Admin/Users/${user.userId}`);
                        //} ) );
                        this.props.history.push(`/Admin/Users/${user.userId}`);
                    }
                },
                onFilterChange: (changedColumn, filterList, type, changedColumnIndex, displayData) => {
                    this.setState(produce(draft => {
                        draft.columns[changedColumnIndex].options.filterList = filterList[changedColumnIndex];
                    }));
                },
                onColumnSortChange: (changedColumn, direction) => {
                    this.setState(produce(draft => {
                        draft.options.sortOrder = { name: changedColumn, direction: direction };
                    }));
                },
                print: false,
                searchOpen: true,
                selectableRows: 'none',
                sortOrder: {
                    name: 'email',
                    direction: 'asc',
                },
            },
        };
    }

    componentDidMount() {
        this.dispatchGetUsers();
    }

    dispatchGetUsers = () => {
        this.props.dispatch(getAdminUsers() );
    }

    dispatchUpdateUser = ( user ) => {
        this.props.dispatch( updateUserLite( user ) );
    }

    updatedDateFormatTemplate = (dataIndex) => {
        const value = this.props.users[dataIndex].lastUpdateDateTime;
        return objectListFormatDate(value)
    }
    createdDateFormatTemplate = (dataIndex) => {
        const value = this.props.users[dataIndex].createDateTime;
        return objectListFormatDate(value)
    }

    setAdminAccess = ( user, value ) => {
        let updateUser = {
            ...user,
            hasAdmin: value,
        };
        this.dispatchUpdateUser( updateUser );
    }

    setUserActive = ( user, value ) => {
        let updateUser = {
            ...user,
            isActive: value,
        };
        this.dispatchUpdateUser( updateUser );
    }

    adminAccessTemplate = (dataIndex) => {
        let user = this.props.users[dataIndex];
        return ( 
            <Switch checked={user.hasAdmin} onChange={ event => this.setAdminAccess( user, event.target.checked ) }></Switch> 
        );
    }

    userActiveTemplate = (dataIndex ) => {
        let user = this.props.users[dataIndex];
        return (
            <Switch checked={user.isActive} onChange={ event => this.setUserActive( user, event.target.checked )}></Switch> 
        );
    }

    render() {
        const { classes } = this.props;
        const options = MuiGridOptions({ ...this.state.options }, this.props.isDataLoading)
        if (this.props.users.length > 500) {
            options.rowsPerPage = 500;
            options.rowsPerPageOptions = [10, 100, 250, 500, 1000];
            options.pagination = true;
            options.jumpToPage = true;
        }
        return (
            <Box border={1} borderColor="grey.300"> {/* default in theme?? */}
                
                <MUIDataTable container classes={{ responsiveScrollMaxHeight: classes.responsiveScrollMaxHeight }}
                        title={''}
                        data={this.props.users}
                        columns={this.state.columns}
                    options={options}
                        />
            </Box>
        ); 
    } 
}

/**
 * mapStateToProps
 * @param {*} state 
 */
function mapStateToProps( state ) {
    return {
        users: state.users.users,
        isDataLoading: state.users.isLoading,
        
    };
};

export default withRouter(connect(mapStateToProps)(withStyles(usersTheme)(UserList)));