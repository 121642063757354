import React from 'react';
import { Link } from 'react-router-dom';
import newsImage from 'client/assets/css/images/icon-news.png';
import { withStyles } from '@mui/styles';
import { contentLayoutTheme } from 'client/components/ContentLayout/theme/contentLayoutTheme'
import { Box } from '@mui/material';
class recentContent extends React.Component {

    renderRecentContentList(contentsCategory, classes) {
       
        let recentContents = [];
        for (let i = 0; i < contentsCategory.length; i++) {
            for (let j = 0; j < contentsCategory[i].contentList.length; j++) {
                
                recentContents.push(<li key={contentsCategory[i].contentList[j].id}>
                    <small className={classes.listRecentArticlesSmall}> {contentsCategory[i].contentList[j].modifiedDate}</small>
                    <Link className={classes.recentLink} to={"/Content/" + contentsCategory[i].contentList[j].id + "/" + contentsCategory[i].contentList[j].isPreLogin}>{contentsCategory[i].contentList[j].subject}</Link>
                                    </li>);
                if (recentContents.length === 5) {
                    break;
                }
            }
            if (recentContents.length === 5) {
                break;
            }
            
        }
        return (
        <li>
            <header className={classes.widgetHead}>
                    <h4><img alt="" src={newsImage} />Recent</h4>
            </header>
                <Box pl={6.9} className={classes.widgetBody}  >
    
                    <ul className={classes.listRecentArticles}>{recentContents}</ul>
    
            </Box>
        </li>
        );

    }
   
    render() {
        const classes = this.props.classes;
        return (
            <div>
                <ul id="contentpanelbar1" className={classes.widgets}>
                    {this.renderRecentContentList(this.props.contentList, classes)}
                </ul>
            </div> 
            );
    }
}
export default (withStyles(contentLayoutTheme)(recentContent));

