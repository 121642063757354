import React from 'react';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/styles';
import { spacing } from '@mui/system';
import { Box, Grid, Typography, CardContent } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Link } from 'react-router-dom'
import MuiCard from '@mui/material/Card';
import * as Enumerator from 'client/components/Common/Enum';
import { basicHeaderTheme } from './theme/layoutBasicHeaderTheme';
import AppBar from '@mui/material/AppBar';
import { getDeviceType } from 'client/components/Common/Utility'
import { connect } from 'react-redux';
const deviceType = getDeviceType();

const Card = styled(MuiCard)(spacing);
const useStyles = makeStyles({
    ...basicHeaderTheme
});
 function LayoutBasicHeader(props) {
    const classes = useStyles(props);
    let menuCollapsed = props.layoutObjects.isMenuCollapsed;
    let isMobile = deviceType === Enumerator.DeviceType.Mobile ? true : false;
    return (
        <Grid container spacing={0} mb={7.5}>
            <AppBar >
            <Grid item xs={12}  >
                <Card variant="outlined" className={classes.cardHome} >
                    <CardContent className={classes.cardHome}>
                        <Box className={classes.header} pb={2} >
                                <Grid container container pl={menuCollapsed ? 8 : isMobile ? 8 : 35}  spacing={0} justifyContent="flex-start" alignItems="flex-start"  >
                                <Grid item xs={4} lg={4} >
                                    <Box pl={3} > <Link to="/#ContentLanding?otype=C">
                                        <HomeOutlinedIcon className={classes.homeIcon} color="primary" /></Link></Box>
                                </Grid>
                                <Grid item xs={4} lg={4} className={classes.heading} >

                                    <Typography  className={classes.title}>{props.title}</Typography>

                                </Grid>
                                <Grid item xs={4} lg={4} >

                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
                </Grid>
                </AppBar >
        </Grid>
        );
}
const mapStateToProps = (state) => {
    return {  
        layoutObjects: state.layout,
    }
}
export default connect(
    state => mapStateToProps, 
)(LayoutBasicHeader);

