
import { ActionTypes as types } from 'admin/actions/actionTypes';
const initialState = {
    activeUser: {
        firstName:"",
        roleName:"",
        roles:[],
    },
};

export default function activeUser(state = initialState, action) {

    console.log('users reducer - action: ', action);

    switch (action.type) {

        case types.VGO_GET_ACTIVE_USER_SUCCESS:
            return {
                ...state,
                activeUser: action.activeUser,
            }
    default:
        break;

    }
    return state;
}

