import { customThemeStyles, customColorPalette } from 'common/theme.js';
export const linksTheme = (theme) => ({
    ...customThemeStyles,
    card: {
        height: '100%',
        marginLeft: theme.spacing(-3),
        marginRight: theme.spacing(-3),
        marginTop: theme.spacing(-3),
        padding: 0
    },
    ruleIconColor: {
        marginTop: 7,
        color: customColorPalette.black[54],
    },
    closeIconSize: {
        fontSize: '19px !important',
    },
    editIconSize: {
        fontSize: '16px !important',
    }

});