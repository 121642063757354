import { ActionTypes as types } from './actionTypes';
import adminApi from 'admin/api/adminApi';
import { getError } from './errorActions';
import settings from 'settings.js';
import mockApi from 'admin/api/mockApi';

const useMocks = settings.useMocks;
const api = useMocks ? mockApi : adminApi;
const apiGetActiveUser = (roleId) => {
    return api.getActiveUser(roleId);
};


const getActiveUserRequest = () => {
    return {
        type: types.VGO_GET_ACTIVE_USER_REQUEST,
    }
};

const getActiveUserSuccess = (resp) => {
    return {
        type: types.VGO_GET_ACTIVE_USER_SUCCESS,
        activeUser: resp.data,
    };
};


export const getActiveUser = (roleId) => {
    
    return dispatch => {
        dispatch(getActiveUserRequest(roleId));
        return apiGetActiveUser(roleId)
            .then((resp) => {
                dispatch(getActiveUserSuccess(resp));
            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
            });
    };
};