import React from 'react';
import { withRouter } from 'react-router-dom';
import { styled } from '@mui/styles';
import { withStyles } from '@mui/styles';

import { connect } from 'react-redux';
import { Grid, Typography, Radio, FormControlLabel, RadioGroup, Box, Checkbox, Button } from '@mui/material';
import Autocomplete from '@mui/lab/Autocomplete';
import MuiTextField from '@mui/material/TextField';
import { spacing } from '@mui/system';
import { FormGroup } from '@mui/material';
import MuiCard from '@mui/material/Card';


import ContentCopy from '@mui/icons-material/ContentCopy';
import CircularProgress from '@mui/material/CircularProgress';
import { getCategories } from 'admin/actions/dataViewActions';
import { getRoles, sortRoles } from 'admin/actions/userActions';
import { getDatabases } from 'admin/actions/databaseActions';

import {
    getChangeForm, getChangeFormType, getChangeFormTableOption, getChangeKeyField, getChangeDataSourceForm, getDataSourceList, getTableFieldsList, newTableFields, updateForms,
    validateFieldsOnBlur, getChangeFormRole
} from 'admin/actions/formSetupActions';
import { ActionTypes as actionTypes } from 'admin/actions/actionTypes';
import ActionBackdrop from 'admin/components/section/settings/Backdrop';
import Category from 'admin/components/section/dataViews/setup/Category';
import { showConfirmWarning, hideError} from 'admin/actions/alertActions';

import cloneDeep from 'lodash/cloneDeep';
import { FormType } from 'client/components/Common/Enum';
import { setupTheme } from 'admin/components/section/form/setup/theme/setupTheme';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const Card = styled(MuiCard)(spacing);



const TextField = styled(MuiTextField)(spacing);




let reportNameList = []
class FormSetup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTabIndex: 0,
        };
    }

    componentDidMount() {

        this.dispatchGetRoles();
        this.dispatchGetDatabases();
        this.dispatchGetCategories();

    }

    dispatchGetRoles = () => {

        if (this.props.roles.list.length === 0) {

            this.props.dispatch(getRoles());
        }
    }

    dispatchGetDatabases() {
        if (this.props.databases.list.length === 0) {
            this.props.dispatch(getDatabases());
        }
    }

    dispatchGetCategories() {
        if (this.props.categories.list.length === 0) {
            this.props.dispatch(getCategories());
        }
    }


    dispatchSubmit = () => {
        this.props.dispatch(updateForms(this.props.formSetup, this.props.primaryTableFields.list, this.props.detailTableFields.list, this.props.isUpdate));
    }
    changeForm = (change) => {
        this.props.dispatch(getChangeForm(change));
    }

    confirmYes = (value) => {
        hideError(this.props.dispatch);
        const resetlookUpListCollection = this.removeRolesFromControls(value.roleList)
        this.props.dispatch(getChangeFormRole(value.change, resetlookUpListCollection));
    }
    confirmNo = () => {
        hideError(this.props.dispatch);
       
    }
    changeFormRole = (change, roleList) => {
        const resetlookUpListCollection = this.removeRolesFromControls(roleList)
        
        if (resetlookUpListCollection.headerList.length > 0 || resetlookUpListCollection.detailList.length > 0) {

            let value = { change: change, roleList: roleList}
            let action = { primaryAction: this.confirmYes, SecondaryAction: this.confirmNo, value: value }

            let reportList = ` [${reportNameList.join()}] `
            const message = `Removing this role will remove user access to ${reportList} look-up lists on this form also assigned to this role. Are you sure?`

            showConfirmWarning(this.props.dispatch, message, action);

            
        } else {

            this.props.dispatch(getChangeFormRole(change, resetlookUpListCollection));

        }
    }
    changeFormType = (change) => {
        this.props.dispatch(getChangeFormType(change));
    }
    changeFormTableOption = (change) => {
        this.props.dispatch(getChangeFormTableOption(change));
    }
    ChangeKeyField = (change, tableType, checked, fields) => {
        this.props.dispatch(getChangeKeyField(change, tableType, checked, fields));
    }
    blurValidation = (fieldName, value) => {

        this.props.dispatch(validateFieldsOnBlur(this.props.formSetup, fieldName));
    }


    toggleIsPublic = (event) => {

        this.changeForm(form => form.isPublic = event.target.checked);
    }

    toggleHideMenu = (event) => {

        this.changeForm(form => form.doNotShowInMenu = event.target.checked);
    }
    toggleReadOnly = (event) => {

        this.changeForm(form => form.isReadOnly = event.target.checked);
    }
    toggleTableOptions = (event) => {
        const createNewTable = (event.target.value === 'true');
        this.changeFormTableOption(form => {
            form.createNewTable = createNewTable
            form.primaryTableName = "";
            form.detailTableName = "";
            form.primaryTableDataFields = createNewTable ? newTableFields([]) : [];
            form.detailTableDataFields = createNewTable ? newTableFields([]) : [];
            return form;
        });
        if (!(event.target.value === 'true')) {

            if (this.props.tables.list.length === 0 && this.props.formSetup.company.databaseId !== 0) {
                this.props.dispatch(getDataSourceList(this.props.formSetup.company.databaseId));
            }
        }
    }

    removeRolesFromControls = (roleList) => {
        let headerDataViewList = [];
        reportNameList = []
        this.props.formSection.header.filter(control => control.listId).forEach((listControl) => {
            const dataView = this.props.dataViewListByRole.dataViews.find(dataView => (dataView.reportId === listControl.listId && dataView.reportRoles.some(role => {
                const roleIndex = roleList.findIndex(cur => cur.roleId === role.roleId);
                return roleIndex === -1;
            })))
            if (dataView) {

                if (this.props.dataViewListByRole.dataViews) {
                    headerDataViewList.push(dataView.reportId);

                    var index = this.props.dataViewListByRole.dataViews.findIndex(r => r.reportId == dataView.reportId)
                    if (index !== -1) {

                        if (reportNameList.indexOf(this.props.dataViewListByRole.dataViews[index].reportName) === -1) {

                            reportNameList.push(this.props.dataViewListByRole.dataViews[index].reportName);
                        }
                    }
                }
            }
        })
        let detailDataViewList = [];
        if (this.props.formSection.detail) {
            this.props.formSection.detail.formGridControl.filter(control => control.listId).forEach((listControl) => {
                const dataView = this.props.dataViewListByRole.dataViews.find(dataView => (dataView.reportId === listControl.listId && dataView.reportRoles.some(role => {
                    const roleIndex = roleList.findIndex(cur => cur.roleId === role.roleId);
                    return roleIndex === -1;
                })))
                if (dataView) {
                    if (this.props.dataViewListByRole.dataViews) {
                        detailDataViewList.push(dataView.reportId);

                        var index = this.props.dataViewListByRole.dataViews.findIndex(r => r.reportId == dataView.reportId)
                        if (index !== -1) {
                            if (reportNameList.indexOf(this.props.dataViewListByRole.dataViews[index].reportName) === -1) {

                                reportNameList.push(this.props.dataViewListByRole.dataViews[index].reportName);
                            }
                        }
                    }
                }
            })
        }


        return { headerList: headerDataViewList, detailList: detailDataViewList };

    }
    onRolesChange = (event, value, reason) => {

        switch (reason) {

            case 'selectOption':
                {
                    let rlist = cloneDeep(value);
                    const roleList = sortRoles(rlist);
                    this.changeForm(form => form.roles = roleList);
                    break;
                }
            case 'removeOption':
                {
                    let rlist = cloneDeep(value);
                    const roleList = sortRoles(rlist);
                    this.changeFormRole(form => form.roles = roleList, roleList)
                    break;

                }

                break;

            default:
                break;

        }
    }


    changeDatabase = (change, value) => {
        this.props.dispatch(getChangeDataSourceForm(change));

        // input.onChange(value)
    }
    changePrimaryTable = (change, value) => {
        this.props.dispatch(getChangeForm(change));
        if (value) {
            this.props.dispatch(getTableFieldsList(this.props.formSetup.company.databaseId, value, 'primary'));
        }

    }
    changeDetailTable = (change, value) => {
        this.props.dispatch(getChangeForm(change));
        if (value) {
            this.props.dispatch(getTableFieldsList(this.props.formSetup.company.databaseId, value, 'detail'));
        }

    }
    handleTabChange = (event, newValue) => {
        this.setState({ activeTabIndex: newValue });
    }
    changeWithReduxForm = (event, input) => {

        // input.onChange(event.target.value);
        this.changeForm(form => form[input.name] = event.target.value);

    }
    changeCategory = (event, input, value) => {

        this.changeForm(form => form.category = value);
    }


    handleClone = () => {
        this.changeForm(form => {
            form.formName = "";
            form.title = "";
            form.formId = 0;
            form.isClone = true;

            //Header section 

            if (form.formSection.header) {
             const header=   form.formSection.header.map((item) => {
                 item.controlId = 0;
                 if (item.formAction) {

                     const action = item.formAction.map((actionItem) => {
                         actionItem.actionId = 0;
                         return actionItem;
                     });

                     item.formAction = action;
                 }

                 if (item.formControlRoles) {

                     const controlRoles = item.formControlRoles.map((roleItem) => {
                        
                         roleItem.controlId = 0;
                         return roleItem;
                     });

                     item.formControlRoles = controlRoles;
                 }

                    return item;
             });

                
            

             



             form.formSection.header = header;
            }

         // Details section

            if (form.formSection.detail) {
                const detail=form.formSection.detail.formGridControl.map((item) => {
                    item.controlId = 0;
                    if (item.formControlRoles) {

                        const controlRoles = item.formControlRoles.map((roleItem) => {
                           // roleItem.id = -1;
                            roleItem.controlId = 0;
                           // roleItem.sectionId = -1;
                            return roleItem;
                        });

                        item.formControlRoles = controlRoles;
                    }
                    return item;
                });
                form.formSection.detail.formGridControl = detail;
            }

            //Button section

            if (form.formSection.button) {
                const button = form.formSection.button.map((item) => {
                    item.controlId = 0;
                    if (item.formAction) {

                        const action = item.formAction.map((actionItem) => {
                            actionItem.actionId = 0;
                            return actionItem;
                        });

                        item.formAction = action;
                    }

                    if (item.formControlRoles) {

                        const controlRoles = item.formControlRoles.map((roleItem) => {
                         //   roleItem.id = -1;
                            roleItem.controlId = 0;
                           // roleItem.sectionId = -1;
                            return roleItem;
                        });

                        item.formControlRoles = controlRoles;
                    }


                    return item;
                });
                form.formSection.button = button;
            }

            return form;
        });
        this.props.history.push(`/Admin/Forms/new`);

    }




    renderTableOption = () => {
        const { classes } = this.props;
        const spacing = 4;

        if (this.props.formSetup.createNewTable) {
            return (
                <Box className={classes.subGrid} pt={5.3}>
                    <Grid container direction="row" spacing={spacing}>
                        <Grid item xs={6} className={classes.paddingEmpty}>
                            <TextField error={!(!this.props.errors.primaryTableName)} helperText={this.props.errors.primaryTableName ? this.props.errors.primaryTableName : ' '}
                                required
                                value={this.props.formSetup.primaryTableName}
                                onBlur={(event) => {
                                    this.blurValidation('primaryTableName', this.props.formSetup);
                                }}
                                onChange={event => this.changeForm(form => form.primaryTableName = event.target.value)}
                                name="primaryTableName"
                                label="Primary table"
                                size="small"
                                disabled={this.props.isUpdate}
                                inputProps={{
                                    maxLength: 50
                                }}>
                            </TextField>
                        </Grid>
                        {
                            this.props.formSetup.formType === FormType.HeaderDetail &&
                            <Grid item xs={6} className={classes.paddingEmpty}>
                                <TextField error={!(!this.props.errors.detailTableName)} helperText={this.props.errors.detailTableName ? this.props.errors.detailTableName : ' '}
                                    required
                                    value={this.props.formSetup.detailTableName}
                                    onBlur={(event) => {
                                        this.blurValidation('detailTableName', this.props.formSetup);
                                    }}
                                    onChange={event => this.changeForm(form => form.detailTableName = event.target.value)}
                                    name="detailTableName"
                                    label="Detail table"
                                    size="small"
                                    disabled={this.props.isUpdate}
                                    inputProps={{
                                        maxLength: 50
                                    }}>
                                </TextField>
                            </Grid>

                        }
                    </Grid>
                </Box>
            );
        } else {

            return (
                <Box className={classes.subGrid} pt={5.3}>
                    <Grid container direction="row" spacing={spacing}>

                        <Grid item xs={6} className={classes.paddingEmpty}>
                            {this.renderTableListField(
                                'primary',
                                'Primary table',
                                'primaryTableName')}
                        </Grid>
                        {this.props.formSetup.formType === FormType.HeaderDetail &&
                            <Grid item xs={6} className={classes.paddingEmpty}>
                                {this.renderTableListField(
                                    'detail',
                                    'Detail table',
                                    'detailTableName')}
                            </Grid>}

                    </Grid>
                </Box>
            );
        }
    }



    renderTableListField = (
        tableType,
        label,
        name
    ) => {
        const showError = tableType === 'primary' ? !(!this.props.errors.primaryTableName) : !(!this.props.errors.detailTableName)
        const message = tableType === 'primary' ? (this.props.errors.primaryTableName ? this.props.errors.primaryTableName : ' ') : (this.props.errors.detailTableName ? this.props.errors.detailTableName : ' ')
        return (
            <div>
                <Autocomplete
                    id={name}
                    size="small"
                    disabled={this.props.isUpdate}
                    fullWidth={true}
                    value={{ name: this.props.formSetup[name] }}
                    options={this.props.tables.list ? this.props.tables.list:[]}
                    getOptionLabel={option => option.name}
                    onChange={(event, newValue) => {
                        const tableName = !newValue ? "" : newValue.name;
                        if (tableType === 'primary') {
                            this.changePrimaryTable((form => {
                                form.primaryTableName = tableName;
                                form.primaryTableDataFields = newTableFields([]);
                                return form;
                            }),
                                tableName
                            )
                        } else {
                            this.changeDetailTable((form => {
                                form.detailTableName = tableName;
                                form.detailTableDataFields = newTableFields([]);
                                return form;
                            }), tableName)
                        }

                    }}
                    onBlur={(event) => {
                        this.blurValidation(name, this.props.formSetup);
                    }}
                    loading={this.props.tables.requestStatus === actionTypes.VGO_GET_FILTERS_DATASOURCE_REQUEST}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            required
                            label={label}
                            placeholder={label}
                            size="small"
                            error={showError}
                            helperText={message}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {this.props.tables.requestStatus ===
                                            actionTypes.VGO_GET_FILTERS_DATASOURCE_REQUEST &&
                                            <CircularProgress color="inherit" size={20} />}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}

                        />
                    )}
                />
            </div>);
    }
    render() {
        const spacing = 2;
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Grid container direction="row" >
                    {this.props.requestStatus.getForm === actionTypes.VGO_GET_FORM_REQUEST && <ActionBackdrop />}
                    {(this.props.primaryTableFields.requestStatus === actionTypes.VGO_GET_FORM_PRIMARY_TABLE_FIELDS__REQUEST
                        || this.props.detailTableFields.requestStatus === actionTypes.VGO_GET_FORM_DETAIL_TABLE_FIELDS__REQUEST


                    ) && <ActionBackdrop />}
                    <Grid item xs={12} >
                        

                        <Accordion className={classes.setupAccordion} defaultExpanded={true}>
                            <AccordionSummary classes={{ content: classes.setupSummary, root: classes.setupSummary }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography >Setup</Typography>
                        </AccordionSummary >
                        <AccordionDetails >
                            <Grid container direction="row" spacing={spacing} pb={4} >
                                <Grid item xs={6} className={classes.mainGridItem}>
                                    <Grid item><Typography>Information</Typography></Grid>
                                    <Box mt={.3} className={classes.subGrid}>
                                        <Grid container direction="row" spacing={4}>
                                            <Grid item xs={6} className={classes.gridPadding}>
                                                <TextField error={!(!this.props.errors.formName)} helperText={this.props.errors.formName ? this.props.errors.formName : ' '} autoFocus
                                                    required
                                                    value={this.props.formSetup.formName}
                                                    onBlur={(event) => {
                                                        this.blurValidation('formName', this.props.formSetup);
                                                    }}
                                                    onChange={event => this.changeForm(form => form.formName = event.target.value)}
                                                    name="formName"
                                                    label="Name"
                                                    size="small"
                                                    inputProps={{
                                                        maxLength: 50
                                                    }}>
                                                </TextField>


                                            </Grid>
                                            <Grid item xs={6} className={classes.gridPadding}>
                                                <TextField error={!(!this.props.errors.title)} helperText={this.props.errors.title ? this.props.errors.title : ' '}
                                                    required
                                                    value={this.props.formSetup.title}
                                                    onBlur={(event) => {
                                                        this.blurValidation('title', this.props.formSetup);
                                                    }}
                                                    onChange={event => this.changeForm(form => form.title = event.target.value)}
                                                    name="title"
                                                    label="Title"
                                                    size="small"
                                                    inputProps={{
                                                        maxLength: 100
                                                    }}>
                                                </TextField>

                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box className={classes.subGrid} pt={5.3}>
                                        <Grid container direction="row" spacing={4}>
                                            <Grid item xs={6} className={classes.paddingEmpty}>
                                                <Category
                                                    disabled={this.props.formSetup.isClone}
                                                    errors={this.props.errors ? this.props.errors : {}}
                                                    onBlurValidation={this.blurValidation}
                                                    changeDataView={this.changeCategory}
                                                    dataView={this.props.formSetup}
                                                    requestStatus={this.props.categories.requestStatus}
                                                    categories={this.props.categories.list}
                                                />
                                            </Grid>

                                            <Grid item xs={6} className={classes.paddingEmpty}>
                                                <Typography className={classes.formIdText}>Form ID: {this.props.formSetup.formId}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box className={classes.subGrid} mt={6}>
                                        <Grid container direction="row" spacing={4} >
                                            <Grid item xs={12} className={classes.enablePaging} >
                                                <TextField
                                                    disabled={this.props.formSetup.isClone}
                                                    fullWidth={true}
                                                    value={this.props.formSetup.description}
                                                    onChange={event => this.changeForm(form => form.description = event.target.value)}
                                                    label="Description"
                                                    size="small"
                                                    inputProps={{ maxLength: 200 }}
                                                >
                                                </TextField>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box className={classes.subGrid} mt={5.5}>
                                        <Grid container direction="row" spacing={0}>
                                            <Grid item xs={12} className={classes.enablePaging} >
                                                <TextField
                                                    disabled={this.props.formSetup.isClone}
                                                    fullWidth={true}
                                                    value={this.props.formSetup.userInstruction}
                                                    onChange={event => this.changeForm(form => form.userInstruction = event.target.value)}
                                                    label="Instructions"
                                                    size="small"
                                                    inputProps={{ maxLength: 1000 }}
                                                >
                                                </TextField>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box className={classes.subGrid} mt={8}>
                                        <Grid container direction="row" spacing={4}>
                                            <Grid item xs={12} >
                                                <FormGroup row={true}>

                                                    <Typography className={classes.formTypeRadioCaption} >Form Type </Typography>
                                                    <RadioGroup row name="formType" disabled={this.props.isUpdate} value={this.props.formSetup.formType} onChange={(event) => {
                                                        this.changeFormType(form => form.formType = parseInt(event.target.value))
                                                    }} >
                                                        <FormControlLabel disabled={this.props.isUpdate} value={FormType.Standard} control={<Radio />} label={<span className={classes.body2}> Standard </span>} />
                                                        <FormControlLabel disabled={this.props.isUpdate} value={FormType.HeaderDetail} control={<Radio />} label={<span className={classes.body2}>Header - Detail</span>} />
                                                    </RadioGroup>

                                                </FormGroup>

                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container direction="row">
                                        <Grid item ><Typography>Availability</Typography></Grid>
                                    </Grid>
                                    <Box pt={3}>
                                        <Grid container direction="row" spacing={0}>
                                            <Grid item xs={4} mt={-.5} >
                                                <FormGroup row={false}>
                                                    <FormControlLabel disabled={this.props.formSetup.isClone}
                                                        control={<Checkbox
                                                            color="primary"
                                                            checked={this.props.formSetup.isPublic}
                                                            onChange={this.toggleIsPublic}
                                                        />}
                                                        label={<span className={classes.body2}>Public</span>} />


                                                    <FormControlLabel className={classes.formAccessCheckBox} disabled={this.props.formSetup.isClone}
                                                        control={<Checkbox
                                                            color="primary"
                                                            checked={this.props.formSetup.doNotShowInMenu}
                                                            onChange={this.toggleHideMenu}
                                                        />}
                                                        label={<span className={classes.body2}>Do not show in menu</span>} />



                                                    <FormControlLabel className={classes.formAccessCheckBox} disabled={this.props.formSetup.isClone}
                                                        control={<Checkbox
                                                            color="primary"
                                                            checked={this.props.formSetup.isReadOnly}
                                                            onChange={this.toggleReadOnly}
                                                        />}
                                                        label={<span className={classes.body2}>Read only</span>} />

                                                </FormGroup>

                                            </Grid>
                                            <Grid item xs={8}  >
                                                <Grid item container direction="column">
                                                    <Box mt={2} pl={1.2}>
                                                        <Autocomplete
                                                            disabled={this.props.formSetup.isClone}
                                                            disableClearable
                                                            multiple
                                                            id="formRole"
                                                            size="small"
                                                            label="Roles"
                                                            className={classes.roleControl}
                                                            fullWidth={true}
                                                            loading={this.props.roles.requestStatus === actionTypes.VGO_GET_ROLES_REQUEST}
                                                            options={this.props.roles.list ? this.props.roles.list:[]}
                                                            getOptionLabel={option => option.roleName}
                                                            isOptionEqualToValue={
                                                                (option, value) => option.roleName === value.roleName}
                                                            value={this.props.formSetup.roles}
                                                            onChange={(event, value, reason) => { this.onRolesChange(event, value, reason) }}
                                                            onBlur={(event, value) => { this.blurValidation('roles') }}
                                                            renderInput={params => (
                                                                <TextField {...params}

                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        endAdornment: (
                                                                            <React.Fragment>
                                                                                {this.props.roles.requestStatus === actionTypes.VGO_GET_ROLES_REQUEST && <CircularProgress color="inherit" size={20} />}
                                                                                {params.InputProps.endAdornment}
                                                                            </React.Fragment>
                                                                        ),
                                                                    }}
                                                                    variant="outlined"
                                                                    size="medium"
                                                                    label="Roles"
                                                                    error={!(!this.props.errors.roles)}
                                                                    helperText={this.props.errors.roles ? this.props.errors.roles : ' '}
                                                                />
                                                            )}
                                                        />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <Grid container direction="row"  >
                                        <Grid item xs={12} mt={5.8}>
                                            <Typography>Connection</Typography>
                                        </Grid>
                                    </Grid>
                                    <Box className={classes.subGrid} mt={1.5} >
                                        <Grid container direction="row" spacing={4}>
                                            <Grid item xs={6} className={classes.gridPadding}>

                                                <Autocomplete
                                                    id="formDatabase"
                                                    disabled={this.props.formSetup.isClone}
                                                    size="small"
                                                    fullWidth={true}
                                                    value={this.props.formSetup.company}
                                                    options={this.props.databases.list ? this.props.databases.list:[]}
                                                    getOptionLabel={option => option.databaseConnectionName}
                                                    onChange={(event, newValue) => {
                                                        if (this.props.isUpdate) {
                                                            if (!(!newValue)) {
                                                                this.changeDatabase(form => form.company = newValue)
                                                            }
                                                        }
                                                        else {
                                                            if (this.props.formSetup.createNewTable) {
                                                                this.changeDatabase(form => form.company = !newValue
                                                                    ? {
                                                                        databaseId: 0,
                                                                        databaseConnectionName: "",
                                                                        database: ""
                                                                    }
                                                                    : newValue)
                                                            }
                                                            else {
                                                                this.changeDatabase(form => {
                                                                    form.company =
                                                                        !newValue
                                                                            ? {
                                                                                databaseId: 0,
                                                                                databaseConnectionName: "",
                                                                                database: ""
                                                                            }
                                                                            : newValue;
                                                                    form.createNewTable = true;
                                                                    form.primaryTableName = "";
                                                                    form.detailTableName = "";
                                                                    form.primaryTableDataFields = newTableFields([]);
                                                                    form.detailTableDataFields = newTableFields([]);
                                                                    return form;
                                                                }, !newValue
                                                                    ? {
                                                                        databaseId: 0,
                                                                        databaseConnectionName: "",
                                                                        database: ""
                                                                    }
                                                                    : newValue

                                                                )
                                                            }
                                                        }
                                                    }
                                                    }
                                                    onBlur={(event, value) => { this.blurValidation('company') }}
                                                    loading={this.props.databases.requestStatus === actionTypes.VGO_GET_DATABASES_REQUEST}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            required
                                                            label="Connection"
                                                            placeholder="Connection"
                                                            size="small"
                                                            error={!(!this.props.errors.company)}
                                                            helperText={this.props.errors.company ? this.props.errors.company : ' '}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <React.Fragment>
                                                                        {this.props.databases.requestStatus === actionTypes.VGO_GET_DATABASES_REQUEST && <CircularProgress color="inherit" size={20} />}
                                                                        {params.InputProps.endAdornment}
                                                                    </React.Fragment>
                                                                ),
                                                            }}

                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={6} className={classes.gridPadding}>
                                                <RadioGroup row name="formTableType" disabled={this.props.isUpdate} value={this.props.formSetup.createNewTable} onChange={this.toggleTableOptions} >
                                                    <FormControlLabel className={classes.tableTypeRadionLabel} disabled={this.props.isUpdate} value={false} control={<Radio />} label={<span className={classes.setupSpan}>Existing tables</span>} />
                                                    <FormControlLabel className={classes.tableTypeRadionLabel} disabled={this.props.isUpdate} value={true} control={<Radio />} label={<span className={classes.setupSpan}>New tables</span>} />
                                                </RadioGroup>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    {this.renderTableOption()}
                                    <Grid container direction="row" ml={3}>
                                        <Grid item xs={9} />
                                        <Grid item xs={3} className={classes.cloneGridItem}>
                                            <Button
                                                className={`${classes.customSmButton} ${classes.cloneButton}`}
                                                disabled={!this.props.isUpdate}
                                                color="primary"
                                                variant="contained"
                                                disableElevation
                                                startIcon={<ContentCopy />} onClick={this.handleClone} >CLONE FORM</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                        </Accordion>
                      
                    </Grid>
                </Grid>







            </React.Fragment>
        );
    }

}

function mapStateToProps(state) {
    return {
        formSetup: state.forms.form,
        roles: { requestStatus: state.roles.getRoleListRequestStatus, list: state.dataViews.roles },
        databases: { requestStatus: state.databases.getListRequestStatus, list: state.databases.sqlDatabases },
        tables: state.forms.tables,
        categories: state.dataViews.categories,
        primaryTableFields: state.forms.primaryTableFields,
        detailTableFields: state.forms.detailTableFields,
        isUpdate: state.forms.isUpdate,
        requestStatus: state.forms.requestStatus,
        isLoading: state.loader.isLoading,
        actionType: state.loader.type,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage,
        initialValues: state.forms.form,
        submitClicked: state.forms.submitClicked,
        errors: state.forms.errors ? state.forms.errors : {},
        formSection: state.forms.form.formSection,
        dataViewListByRole: state.dataViews.dataViewsByRole,
    };
}


export default withRouter(connect(mapStateToProps)(withStyles(setupTheme)(FormSetup)));

