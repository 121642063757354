const initialState = {
    hasError: false,
    errorMessage: '',
};

export default function error(state = initialState, action) {

    if ( action.type.startsWith( 'VGO') ) {
        if ( action.type.endsWith( 'SUCCESS' ) ) {
            return {
                ...state,
                hasError: false,
                errorMessage: '',
            }
        }
        else if ( action.type.endsWith( 'ERROR' ) ) {
            return {
                ...state,
                hasError: true,
                errorMessage: action.errorMessage,
            }
        }
    }

    return state;

}