import { customThemeStyles } from 'common/theme.js';
export const fileUploadTheme = (theme) => ({
    ...customThemeStyles,

    fileUploadIcon: {
        padding: "0px 8px 0px 0px !important",
        marginTop: "-5px !important",
        marginRight: "-60px !important"
    },
    fileName: {
        marginTop: "-13px"
    },
    doNotDisturbIcon: {
        color: "#FFA117"
    },
    checkCircleIcon: {
        color: "#5CB660"
    },

    inputUpload: {
        width: "304px!important"
    }
    ,
    inputThemeUpload: {
        width: "264px!important"
    }
    
});