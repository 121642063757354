import { ActionTypes as types } from './actionTypes';
import api from 'admin/api/adminApi';
import { showError, showSuccess, hideError } from './alertActions';
import { getError } from './errorActions';
import { saveAs } from 'file-saver';
import { ExportObjectType } from 'client/components/Common/Enum';

const packageValidationError = 'Please correct the indicated items'
const packageItemError = 'Please select at least one item to export';
const packageItemErrorImport = 'Please select at least one item to import';
const apiGeneratePackageExportFile = (exportPackage) => {
    return api.generateExportFile(exportPackage);
};
const apiGetPackageLogFile = (isImport) => {
    return api.getPackageLogFile(isImport);
};

const apiGetDependencyList = (dependencyPackage) => {
    return api.GetDependencyList(dependencyPackage);
}
const apiGetExportList = () => {
    return api.getExportList();
}

const apiImportFile = (importPackage) => {
    return api.importFile(importPackage);
}
const apiValidatePackage = (validatePackage) => {
    return api.validatePackage(validatePackage);
}
const getPackageExportFileRequest = (id) => {
    return {
        type: types.VGO_PACKAGE_EXPORT_GET_FILE_REQUEST,
        
    };
};

const getPackageExportLogFileRequest = () => {
    return {
        type: types.VGO_PACKAGE_EXPORT_GET_LOG_FILE_REQUEST,

    };
};
const getPackageExportLogFileSuccess = () => {
    return {
        type: types.VGO_PACKAGE_EXPORT_GET_LOG_FILE_SUCCESS,

    };
};

const getExportListItemsRequest = () => {
    return {
        type: types.VGO_PACKAGE_EXPORT_GETLIST_REQUEST,

    };
};
const getExportListItemsSuccess = (data) => {
    return {
        type: types.VGO_PACKAGE_EXPORT_GETLIST_SUCCESS,
        list:data,
    };
};


const  getPackageExportFileSuccess = () => {
    return {
        type: types.VGO_PACKAGE_EXPORT_GET_FILE_SUCCESS,
        
    };
};
const getPackageExportFileError = () => {
    return {
        type: types.VGO_PACKAGE_EXPORT_GET_FILE_ERROR,

    };
};

export const getChangePackage = (change) => {
    return {
        type: types.VGO_CHANGE_PACKAGE,
        change,
    };
}
export const getChangeImportPackage = (change) => {
    return {
        type: types.VGO_IMPORT_CHANGE_PACKAGE,
        change,
    };
}
export const getConnectionChangeImportPackage = (change, connectionChangeObject) => {
    return {
        type: types.VGO_IMPORT_CHANGE_PACKAGE_CONNECTION,
        change,
        connectionChangeObject,
    };
}

const getValidatePackage = (type) => {
    return {
        type: type,
        
    };
}

export const addItem = (item) => {
    return {
        type: types.VGO_PACKAGE_ADD_ITEM,
        item: item,
    };
}
export const removeItem = (item) => {
    return {
        type: types.VGO_PACKAGE_REMOVE_ITEM,
        item: item,
    };
}
const getDependencyListRequest = () => {
    return {
        type: types.VGO_PACKAGE_DEPENDENCY_LIST_REQUEST,

    };
};
const getDependencyListSuccess = (data) => {
    return {
        type: types.VGO_PACKAGE_DEPENDENCY_LIST_SUCCESS,
        package:data,
    };
};
const resetAction = () => {
    return {
        type: types.VGO_PACKAGE_RESET,
    }
}
 const selectAllRequest = () => {
    return {
        type: types.VGO_PACKAGE_GET_OBJECTLIST_REQUEST,
    };
}
const selectAllAction = (checked) => {
    return {
        type: types.VGO_PACKAGE_SELECTALL,
        checked: checked,
    };
}
const packageValidation = (validation) => {
    return {
        type: types.VGO_PACKAGE_VALIDATION,
        validation: validation,
    };
}

export const mergePackageInformation = () => {
    return {
        type: types.VGO_PACKAGE_MERGE,
    };
}

const importFileProcessRequest = () => {
    return {
        type: types.VGO_PACKAGE_IMPORT_PROCESS_REQUEST,

    };
};
const importFileProcessSuccess = () => {
    return {
        type: types.VGO_PACKAGE_IMPORT_PROCESS_SUCCESS,

    };
};
const importFileProcessError = () => {
    return {
        type: types.VGO_PACKAGE_IMPORT_PROCESS_ERROR,

    };
};
export const importPackageGetFile = (fileContent, fileName) => {
    return {
        type: types.VGO_PACKAGE_IMPORT_FILE_GET,
        file: {
            fileContent: fileContent, fileName: fileName
        },
    };
}
export const importPackageReset = () => {
    return {
        type: types.VGO_IMPORT_PACKAGE_RESET,
    }
}
export const importPackageInvalidConnectionList = (list) => {
    return {
        type: types.VGO_IMPORT_PACKAGE_CONNECTIONLIST_ERROR,
        connectionList:list,
    }
}
export const importPackageClearPasswordError = (list) => {
    return {
        type: types.VGO_IMPORT_PACKAGE_VALIDATE_CLEAR_ERROR,
    }
}
export const getPackageExportFile =  (exportPackage) => {
    return dispatch => {

        const resp=validatePackage(exportPackage)
            
        if (resp.hasPackageError || resp.hasItemError) {
            if (resp.hasItemError) {
                dispatch(packageValidation(resp));
                showError(dispatch,packageItemError,5000);
                
            }
            else {
                dispatch(packageValidation(resp));
                showError(dispatch,packageValidationError,5000);
                
            }

        }

        else {
            dispatch(getPackageExportFileRequest());
            return apiGeneratePackageExportFile(exportPackage)
                .then((resp) => {
                    if (resp.status === 205) {
                        const message = "Oops. The export failed. See the log file for results.";
                        dispatch(getPackageExportFileError());
                        showError(dispatch, message, 5000);
                    }
                    else {

                        saveAs(resp.data, "versagoExport.vgo");
                        dispatch(getPackageExportFileSuccess());
                        if (resp.status === 200) {
                            showSuccess(dispatch, "", "Export Successful")
                        }
                        else if (resp.status === 206) {
                            const message = "Oops. The export was only partial. See the log file for results.";
                            showError(dispatch, message, 5000);
                        }
                    }
                })
                .catch(err => {
                    const message = "Oops. The Export Didn't Work.";
                    dispatch(getPackageExportFileError());
                    showError(dispatch, message, 5000);
                    
                });
        }
        
    }
};

export const getPackageLogFile = (isImport) => {
    return dispatch => {
        const fileName = isImport ? "versagoImportLog.log" : "versagoExportLog.log"
        dispatch(getPackageExportLogFileRequest());
        return apiGetPackageLogFile(isImport)
            .then((resp) => {
                const blob = new Blob([resp.data], { type: "text/plain;charset=utf-8" });
                saveAs(blob,fileName );
                dispatch(getPackageExportLogFileSuccess());
            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
                showError(dispatch, message, 5000);
            });

    }
};

export const getExportListItems = () => {
    return dispatch => {
        dispatch(getExportListItemsRequest());
        return apiGetExportList()
            .then((resp) => {
                dispatch(getExportListItemsSuccess(resp.data));
            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
                showError(dispatch, message, 5000);
            });
    }
};
export const getDependecyListItems = (item, exportPackage) => {
    return dispatch => {
        dispatch(getDependencyListRequest());
        const dependencyPackage = { package: exportPackage, exportItem:item}
        return apiGetDependencyList(dependencyPackage)
            .then((resp) => {
                const response = resp.data;
                response.selectDependencies = true;
                dispatch(getDependencyListSuccess(resp.data));
            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
            });
    }
};

export const selectAllItem = (checked) => {
    return dispatch => {
        dispatch(selectAllRequest());
        dispatch(selectAllAction(checked));
    }
};

export const resetPackage = () => {
    return dispatch => {
        hideError(dispatch);
        dispatch(resetAction());
    }
};

export const mergePackage = (exportItems, packageInformation) => {
    exportItems.packageName = packageInformation.packageName;
    exportItems.packageDescription = packageInformation.packageDescription;
    exportItems.packageVersion = packageInformation.packageVersion;
    exportItems.isPasswordProtected = packageInformation.isPasswordProtected;
    exportItems.password = packageInformation.password;

    return exportItems;

}
export const modifyItemList = (exportPackage,item, add) => {
    if (add) {
        
        switch (item.objectType) {
            case ExportObjectType.Menu: {
                const index = exportPackage.menuDtos.findIndex(menu => menu.menuId === item["id"])
                if (index < 0) {
                    exportPackage.menuDtos.push({ menuId: item.id })
                }
                break;
            }
            case ExportObjectType.Report: {
                const index = exportPackage.dataViewDtos.findIndex(report => report.reportId === item["id"])
                if (index < 0) {
                    exportPackage.dataViewDtos.push({ reportId: item.id })
                }
                break;
            }
            case ExportObjectType.Form: {
                const index = exportPackage.formDtos.findIndex(form => form.formId === item["id"])
                if (index < 0) {
                    exportPackage.formDtos.push({ formId: item.id })
                }
                break;
            }
            case ExportObjectType.Dashboard: {
                const index = exportPackage.dashboardDtos.findIndex(dashboard => dashboard.id === item["id"])
                if (index < 0) {
                    exportPackage.dashboardDtos.push({ id: item.id })
                }
                break;
            }
            case ExportObjectType.Role: {
                const index = exportPackage.roleDtos.findIndex(role => role.roleId === item["id"])
                if (index < 0) {
                    exportPackage.roleDtos.push({ roleId: item.id })
                }
                break;
            }
            case ExportObjectType.User: {
                const index = exportPackage.userDtos.findIndex(user => user.userId === item["id"])
                if (index < 0) {
                    exportPackage.userDtos.push({ userId: item.id })
                }
                break;
            }
            case ExportObjectType.Filter: {
                const index = exportPackage.filterDtos.findIndex(filter => filter.profileId === item["id"])
                if (index < 0) {
                    exportPackage.filterDtos.push({ profileId: item.id })
                }
                break;
            }
            case ExportObjectType.Company: {
                const index = exportPackage.databaseDtos.findIndex(database => database.databaseId === item["id"])
                if (index < 0) {
                    exportPackage.databaseDtos.push({ databaseId: item.id })
                }
                break;
            }
            default: {

                break;
            }
        }
    }
    else
    {
        switch (item.objectType) {
            case ExportObjectType.Menu: {
                const index = exportPackage.menuDtos.findIndex(menu => menu.menuId === item["id"])
                if (index >= 0) {
                    exportPackage.menuDtos.splice(index,1)
                }
                break;
            }
            case ExportObjectType.Report: {
                const index = exportPackage.dataViewDtos.findIndex(report => report.reportId === item["id"])
                if (index >= 0) {
                    exportPackage.dataViewDtos.splice(index, 1)
                }
                break;
            }
            case ExportObjectType.Form: {
                const index = exportPackage.formDtos.findIndex(form => form.formId === item["id"])
                if (index >= 0) {
                    exportPackage.formDtos.splice(index, 1)
                }
                break;
            }
            case ExportObjectType.Dashboard: {
                const index = exportPackage.dashboardDtos.findIndex(dashboard => dashboard.id === item["id"])
                if (index >= 0) {
                    exportPackage.dashboardDtos.splice(index, 1)
                }
                break;
            }
            case ExportObjectType.Role: {
                const index = exportPackage.roleDtos.findIndex(role => role.roleId === item["id"])
                if (index >= 0) {
                    exportPackage.roleDtos.splice(index, 1)
                }
                break;
            }
            case ExportObjectType.User: {
                const index = exportPackage.userDtos.findIndex(user => user.userId === item["id"])
                if (index >= 0) {
                    exportPackage.userDtos.splice(index, 1)
                }
                break;
            }
            case ExportObjectType.Filter: {
                const index = exportPackage.filterDtos.findIndex(filter => filter.profileId === item["id"])
                if (index >= 0) {
                    exportPackage.filterDtos.splice(index, 1)
                }
                break;
            }
            default: {

                break;
            }
        }
        exportPackage.selectAll = false;
    }
    
    return exportPackage;
}
export const reset = () => {
    const objectPackage = {
        packageName: "",
        packageDescription: "",
        packageVersion: "",
        isPasswordProtected: false,
        password: "",
        selectDependencies: true,
        selectAll:false,
        dataViewDtos: [],
        formDtos: [],
        dashboardDtos: [],
        menuDtos: [],
        roleDtos: [],
        filterDtos: [],
        databaseDtos: [],
        userDtos: [],


    };
    return objectPackage;
}
export const resetPackageInformation = () => {
    return {
        packageName: "",
        packageDescription: "",
        packageVersion: "",
        isPasswordProtected: false,
        password: "",
        selectDependencies:true,
    }
}
export const selectAll = (exportPackage,checked,exportItemList) => {
    if (checked) {
        exportPackage.menuDtos =  exportItemList.filter(item => item.objectType === ExportObjectType.Menu).map((item) => {
            return { menuId: item.id}
        })

        exportPackage.dataViewDtos = exportItemList.filter(item => item.objectType === ExportObjectType.Report).map((item) => {
            return { reportId: item.id }
        })
        exportPackage.formDtos = exportItemList.filter(item => item.objectType === ExportObjectType.Form).map((item) => {
            return { formId: item.id }
        })
        exportPackage.dashboardDtos = exportItemList.filter(item => item.objectType === ExportObjectType.Dashboard).map((item) => {
            return { id: item.id }
        })
        exportPackage.roleDtos = exportItemList.filter(item => item.objectType === ExportObjectType.Role).map((item) => {
            return { roleId: item.id }
        })
        exportPackage.filterDtos = exportItemList.filter(item => item.objectType === ExportObjectType.Filter).map((item) => {
            return { profileId: item.id }
        })
        exportPackage.userDtos = exportItemList.filter(item => item.objectType === ExportObjectType.User).map((item) => {
            return { userId: item.id }
        })
       
    }
    else
    {
        exportPackage.dataViewDtos = [];
        exportPackage.formDtos = [];
        exportPackage.dashboardDtos= [];
        exportPackage.menuDtos= [];
        exportPackage.roleDtos= [];
        exportPackage.filterDtos= [];
        exportPackage.userDtos= [];
    }
    exportPackage.selectAll = checked;
    return exportPackage;
}

const  validatePackage = (exportPackage) => {
    let validate = { hasPackageError: false,hasItemError:false};
    if (!exportPackage.packageName) {
        validate.nameError = true;
        validate.nameHelper = "Name is required";
        validate.hasPackageError = true;
    }
    if (!exportPackage.packageDescription) {
        validate.descriptionError = true;
        validate.descriptionHelper = "Description is required";
        validate.hasPackageError = true;
    }
    if (!exportPackage.packageVersion) {
        validate.versionError = true;
        validate.versionHelper = "Version is required";
        validate.hasPackageError = true;
    }
    if (exportPackage.isPasswordProtected && !exportPackage.password) {
        validate.passwordError = true;
        validate.passwordHelper = "Password is required";
        validate.hasPackageError = true;
    }

    if (!validate.hasPackageError) {
        if (exportPackage.menuDtos.length === 0 && exportPackage.dataViewDtos.length === 0
            && exportPackage.formDtos.length === 0 && exportPackage.dashboardDtos.length === 0
            && exportPackage.roleDtos.length === 0 && exportPackage.filterDtos.length === 0
            && exportPackage.userDtos.length === 0
        )
        {
            validate.hasItemError = true;
        }
    }

    return validate;

}
export const setPackageValidation = (exportPackage, validationSettings)=>{
    if (validationSettings.nameError) {
        exportPackage.nameError = true;
        exportPackage.nameHelper = validationSettings.nameHelper;
    }
    if (validationSettings.descriptionError) {
        exportPackage.descriptionError = true;
        exportPackage.descriptionHelper = validationSettings.descriptionHelper;
    }
    if (validationSettings.versionError) {
        exportPackage.versionError = true;
        exportPackage.versionHelper = validationSettings.versionHelper;
    }
    if (validationSettings.passwordError) {
        exportPackage.passwordError = true;
        exportPackage.passwordHelper = validationSettings.passwordHelper;
    }
    
}

export const roleDependencyChecking = (objectWithOutRole, selectDependency,item,add) => {
    if (add && !selectDependency && (item.objectType === ExportObjectType.Menu ||
        item.objectType === ExportObjectType.Report || item.objectType === ExportObjectType.Form ||
        item.objectType === ExportObjectType.Dashboard || item.objectType === ExportObjectType.User)) {
        objectWithOutRole.push(item)

    }
    else
    {
        const index = objectWithOutRole.findIndex(objectItem => objectItem.id === item["id"] && objectItem.objectType === item["objectType"]  )
        if (index >= 0) {
            objectWithOutRole.splice(index, 1)
        }

    }
    return objectWithOutRole;
}


export const resetImportPackage = () => {
    return {
        packageName: "",
        packageDescription: "",
        packageVersion: "",
        skipSameObject: false,
        selectAll: false,
        isPasswordProtected: false,
        importItemList: [],
        connectionMapping: [],
        packageFile:"",
        packageFileName:"",
    }
}

export const getImportFileData = (file,validated) => {
    const dataPart = validated ? file.fileContent : file.fileContent.split(',')[1];
    var decodedData = atob(dataPart);
    const exportPackage = JSON.parse(decodedData);
    if (exportPackage.IsPasswordProtected && !validated) {

        const importPackage = resetImportPackage();
        importPackage.packageFile = dataPart;
        importPackage.packageFileName = file.fileName;
        importPackage.isPasswordProtected = true;
        importPackage.password = exportPackage.Password;
        return {
            importPackage: importPackage,
            exportConnectionList: [],
        }
    }
    let rowIndex = 0;
    let menuImportItemList = exportPackage.MenuDtos.map((item) => {
        rowIndex = rowIndex+1;
        return {
            select: true,
            id: item.MenuId,
            name:item.MenuName,
            objectType: ExportObjectType.Menu,
            rowIndex: rowIndex - 1,
            displayObjectType:"Menu",
        };
    })
    
    let dataViewImportItemList = exportPackage.DataViewDtos.map((item) => {
        rowIndex = rowIndex + 1;
        return {
            select: true,
            id: item.ReportId,
            name:item.ReportName,
            objectType: ExportObjectType.Report,
            rowIndex: rowIndex - 1,
            displayObjectType: "Data View",
        };
    })
    

    let formImportItemList = exportPackage.FormDtos.map((item) => {
        rowIndex = rowIndex + 1;
        return {
            select: true,
            id: item.FormId,
            name:item.FormName,
            objectType: ExportObjectType.Form,
            rowIndex: rowIndex - 1,
            displayObjectType: "Form",
        };
    })
    
    let dashboardImportItemList = exportPackage.DashboardDtos.map((item) => {
        rowIndex = rowIndex + 1;
        return {
            select: true,
            id: item.Id,
            name:item.Name,
            objectType: ExportObjectType.Dashboard,
            rowIndex: rowIndex - 1,
            displayObjectType: "Dashboard",
        };
    })
    
    let roleImportItemList = exportPackage.RoleDtos.map((item) => {
        rowIndex = rowIndex + 1;
        return {
            select: true,
            id: item.RoleId,
            name:item.RoleName,
            objectType: ExportObjectType.Role,
            rowIndex: rowIndex - 1,
            displayObjectType: "Role",
        };
    })
    
    let filterImportItemList = exportPackage.FilterDtos.map((item) => {
        rowIndex = rowIndex + 1;
        return {
            select: true,
            id: item.ProfileId,
            name:item.ProfileName,
            objectType: ExportObjectType.Filter,
            rowIndex: rowIndex - 1,
            displayObjectType: "Account Filter",
        };
    })
    
    let userImportItemList = exportPackage.UserDtos.map((item) => {
        rowIndex = rowIndex + 1;
        return {
            select: true,
            id: item.UserId,
            name:item.Email,
            objectType: ExportObjectType.User,
            rowIndex: rowIndex - 1,
            displayObjectType: "User",
        };
    })
    
    let connectionMapping = [];
    const exportedConnectionList = exportPackage.DatabaseDtos.map((item) => {
        connectionMapping.push({
            oldObjectId: item.DatabaseId,
            newObjectId:0,
        })
        return {
            id: item.DatabaseId,
            name: item.DatabaseConnectionName,
            database:item.Database,
        }
    })
    const importPackage = {

        packageName: exportPackage.PackageName,
        packageDescription: exportPackage.PackageDescription,
        packageVersion: exportPackage.PackageVersion,
        isPasswordProtected:false,
        skipSameObject: false,
        selectAll:true,
        importItemList: [...menuImportItemList, ...dataViewImportItemList, ...formImportItemList, ...dashboardImportItemList, ...roleImportItemList, ...filterImportItemList, ...userImportItemList],
        connectionMapping: connectionMapping,
        packageFile: dataPart,
        packageFileName: file.fileName,
    }
    return {
        importPackage: importPackage,
        exportConnectionList: exportedConnectionList,
    };
}
const getImportObject = (importPackage) => {
    
    const importObject = { validateStatus: true, invalidConnectionMapping: [] }
    if (!importPackage.packageFileName) {
        importObject.validateStatus = false;
        importObject.errorMessage = "Please select export file";
    }
    const importItems = importPackage.importItemList.filter(item => item.select);

    const connectionList = importPackage.connectionMapping.filter(connection => connection.newObjectId === 0);
    if (importItems.length === 0) {
        importObject.validateStatus = false;
    }
    if (connectionList.length > 0) {
        importObject.validateStatus = false;
        importObject.invalidConnectionMapping = connectionList;
    }
    if(importObject.validateStatus) {
        importObject.importObject = {
            packageName: importPackage.PackageName,
            packageDescription: importPackage.PackageDescription,
            packageVersion: importPackage.PackageVersion,
            skipSameObject: importPackage.skipSameObject,
            selectAll: importPackage.selectAll,
            packageFile: importPackage.packageFile,
            importItemList: importItems,
            connectionMapping: importPackage.connectionMapping,
        };
    }

    return importObject;
}
export const importFile = (importPackage) => {
    return dispatch => {
        const importObject = getImportObject(importPackage);
        if (importObject.validateStatus === false) {
            if (importObject.errorMessage) {
                showError(dispatch, importObject.errorMessage, 5000);
            }
            else if (importObject.invalidConnectionMapping.length === 0) {
                showError(dispatch, packageItemErrorImport, 5000);
            }
            else
            {
                showError(dispatch, packageValidationError, 5000);
                dispatch(importPackageInvalidConnectionList(importObject.invalidConnectionMapping));
            }
        }
        else {
            dispatch(importFileProcessRequest());
            return apiImportFile(importObject.importObject)
                .then((resp) => {
                    if (resp.status === 200) {
                        dispatch(importFileProcessSuccess());
                        showSuccess(dispatch, "", "Import Successful")
                    }
                    else if (resp.status === 206) {
                        dispatch(importFileProcessError());
                        showError(dispatch, "Oops. The import was only partial. See the log file for results.",5000)
                    }
                    else if (resp.status === 205) {
                        dispatch(importFileProcessError());
                        showError(dispatch, "Oops. The import failed. See the log file for results.",5000)
                    }
                })
                .catch(err => {
                    const message = "Oops. The Import Didn't Work.";
                    dispatch(getError(message));
                    showError(dispatch, message, 5000);
                    dispatch(importFileProcessError());
                });
        }
     
        

    }
};

export const importUpdateInvalidConnectionList = (invalidList, changeObject) => {
    if (changeObject.newObjectId) {
       
        const index = invalidList.findIndex(connection => connection.oldObjectId === changeObject.oldObjectId);
        if (index >= 0) {
            invalidList.splice(index,1)
        }
    }
   
}

export const processValidateRequest = (validatePackage) => {
    return dispatch => {

        dispatch(getValidatePackage(types.VGO_IMPORT_PACKAGE_VALIDATE_REQUEST));
        return apiValidatePackage(validatePackage)
            .then((resp) => {
                dispatch(getValidatePackage(types.VGO_IMPORT_PACKAGE_VALIDATE_SUCCESS));
            })
            .catch(err => {
                
                dispatch(getValidatePackage(types.VGO_IMPORT_PACKAGE_VALIDATE_ERROR));
            });

    }
};