import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'client/store/ContentList';
import Cookies from 'universal-cookie';
import { Loading } from 'client/components/Loading';
import ArchiveContent from './ArchiveContent'
import RecentContent from './RecentContent'
import { Box, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { contentLayoutTheme } from 'client/components/ContentLayout/theme/contentLayoutTheme'
const cookies = new Cookies();
class contentList extends React.Component {
    
    componentDidMount() {
        let versagoCookie = cookies.get('Versago');
        if (versagoCookie === undefined) {
            this.props.requestPublicContentList();
        }
        else {
            let roleCookie = cookies.get('roleId');
            this.props.requestContentList(roleCookie);

        }
    }

    renderList(contentList) {

        return (
            <Grid container >
                <Grid item xl={12} lg={12} md={6} sm={12} className={this.props.classes.recentWrapper}>
                    <RecentContent contentList={contentList} />
                </Grid>
                <Grid item xl={12} lg={12} md={6} sm={12} className={this.props.classes.archiveWrapper}>
                    <ArchiveContent contentList={contentList} />
                </Grid>
            </Grid>
            );
    }

    render() {
        const classes = this.props.classes;
    return (
       
        <Box sx={{ width: 1 }}>
           
                <Box pt={this.props.isLoading ?2.4:0}>
                    <Loading loading={this.props.isLoading} /> 
                
                <Box pt={this.props.isLoading ? 2.4 : 0}/>
                            {this.props.contentList.length > 0 && this.renderList(this.props.contentList)}
                 
                </Box>
            </Box>
        

            );
    }

}
export default connect(
    state => state.contents,
    dispatch => bindActionCreators(actionCreators, dispatch))(withStyles(contentLayoutTheme)(contentList))