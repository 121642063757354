import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { styled } from '@mui/styles';
import { withStyles } from '@mui/styles';
import { spacing } from '@mui/system';
import { Box, Grid, Typography, Switch, Checkbox, MenuItem, CircularProgress, InputLabel } from '@mui/material';
import {InputAdornment } from '@mui/material';
import MuiButton from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiTextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ActionTypes as types, section } from 'admin/actions/actionTypes';
import AdminPasswordControl from 'admin/components/section/settings/Password';
import TransitionAlerts from 'admin/components/section/settings/Alert';
import ActionBackdrop from 'admin/components/section/settings/Backdrop';
import FormHelperText from '@mui/material/FormHelperText';
import {
    getSystemSettings, getChangeSystemSettings, updateSystemSettings, checkSmtpSettings, updateEmailSettings, showNotification, clearEmailSection, hideSettingsMessage,
    updatePasswordSettings, clearResetPasswordSection, updateSettingsData, clearRecordCountSection, clearBizweaverSection, clearSessionPeriodSection, clearTimeZoneSection, clearFileUploadSection, clearInstallationSection,
    clearPaymentGatewaySection
} from 'admin/actions/systemSettingsActions';
import { getTimeZoneList } from 'common/timezone';
import Divider from '@mui/material/Divider';
import SendIcon from '@mui/icons-material/Send';
import { SettingStyle } from 'admin/components/section/settings/theme/SettingsStyle'
import RichTextEditor from 'admin/components/section/settings/RichTextEditor';
const Button = styled(MuiButton)(spacing);
const emailType = { EmailAddress: 'Email address', SMTPServer: 'SMTP Host Server', UserName: 'UserName', Password: 'Password', PortNo: 'PortNo', SenderName: 'SenderName' }
const PasswordType = { FromEmail: 'fromEmail', Subject: 'subject', Message: 'message' }
const fileUploadType = { FileFormat: 'fileFormat', FileSize: 'fileSize' }
const paymentType = { PaymentGateway: 'paymentGateway', MerchantLogin: 'merchantLogin', Password: 'Password' }
const TextField = styled(MuiTextField)(spacing);
var timeout;
let currentPanelId = "";
const settingsPanels = {
    Threshold: "ThresholdPanel",BizweaverToken: "BizweaverTokenPanel", InactivePeriod: "InactivePeriodPanel", FormFileUpload: "FormFileUploadPanel", InstallationID: "InstallationIDPanel", EmailServer: "EmailServerPanel",
    PaymentGateway: "PaymentGatewayPanel", TimeZone: "TimeZonePanel", PasswordReset: "PasswordResetPanel"
}
class SystemSettings extends React.Component {

    constructor(props) {
        super(props);
        this.threshold = React.createRef();
        this.bizweaverToken = React.createRef();
        this.inactivePeriod = React.createRef();
        this.formFileUpload = React.createRef();
        this.installationID = React.createRef();
        this.emailServer = React.createRef();
        this.paymentGateway = React.createRef();
        this.timeZone = React.createRef();
        this.passwordReset = React.createRef();
            this.state = {
            mailStatus: "",

            alertComponentKey: 0,
            emailAddressError: false,
            emailAddressHelper: ' ',
            smtpServerError: false,
            smtpServerHelper: ' ',
            userNameError: false,
            userNameHelper: ' ',
            passwordHelper: ' ',
            portError: false,
            portHelper: ' ',
            senderNameError: false,
            senderNameHelper: ' ',
            passwordError: false,
            subjectError: false,
            subjectHelper: ' ',
            messageError: false,
            messageHelper: ' ',
            recordCountError: false,
            recordCountHelper: ' ',
            sessionPeriodError: false,
            sessionPeriodHelper: ' ',
            timeZoneError: false,
            timeZoneHelper: ' ',
            fileFormatError: false,
            fileFormatHelper: 'Enter formats comma separated , no spaces ex. .jpg,.docx,.xlsx. etc',
            fileSizeError: false,
            fileSizeHelper: ' ',
            installationIdError: false,
            installationIdHelper: ' ',
            paymentGatewayError: false,
            paymentGatewayHelper: ' ',
            merchantLoginError: false,
            merchantLoginHelper: ' ',
            merchantPasswordError: false,
            merchantPasswordHelper: ' ',
                currentItemIndex:0
        };

    }

    componentDidMount() {

        this.props.dispatch(getSystemSettings());
        this.props.dispatch(hideSettingsMessage());
    }

    changeSystemSettings = (change) => {
        this.props.dispatch(getChangeSystemSettings(change));
    }
    toggleEnableSSL = (event) => {

        this.changeSystemSettings(systemSettings => systemSettings.emailSettings.enableSSL = event.target.checked)
    }
    changeEmailPassword = (value) => {
        this.changeSystemSettings(systemSettings => systemSettings.emailSettings.password = value)

        if (this.checkIsEmpty(value)) {
            this.setState({ passwordError: true, passwordHelper: 'Password is required' });
        } else {
            this.setState({ passwordError: false, passwordHelper: ' ' });
        }
    }
    changePaymentMerchantPassword = (value) => {

        this.changeSystemSettings(systemSettings => systemSettings.paymentSettings.merchantPassword = value)

    }
    onDiscardClick = () => {

        this.props.dispatch({ type: types.VGO_DISCARD_SYSTEM_SETTINGS });
    }
    onSaveClick = () => {
        this.props.dispatch(updateSystemSettings(this.props.systemSettings));
    }
    onSaveEmailClick = () => {

        if (this.validateEmailData()) {
            this.props.dispatch(updateEmailSettings(this.props.systemSettings.emailSettings, section.EmailSettings, this.props.notification));
        }
    }

    onClearEmailClick = () => {
        this.SetControlFocus(settingsPanels.EmailServer)
        this.setState({
            emailAddressError: false,
            emailAddressHelper: ' ',
            smtpServerError: false,
            smtpServerHelper: ' ',
            userNameError: false,
            userNameHelper: ' ',
            passwordHelper: ' ',
            portError: false,
            portHelper: ' ',
            senderNameError: false,
            senderNameHelper: ' ',
            passwordError: false

        });
        this.props.dispatch(clearEmailSection(section.EmailSettings, this.props.notification));

    }
    checkEmail = (email) => {
        return new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z0-9-]{2,15}/g).test(email);

    }

    onSendMailClick = () => {
        if (this.validateEmailData()) {

            if (this.checkIsEmpty(this.props.systemSettings.emailSettings.senderName)) {
                this.setState({ senderNameError: true, senderNameHelper: 'Test email is required' });
                this.showEmailError();

            } else {

                if (this.checkEmail(this.props.systemSettings.emailSettings.senderName)) {
                    this.setState({ senderNameError: false, senderNameHelper: "\u00a0" });
                    this.props.dispatch(checkSmtpSettings(this.props.systemSettings.emailSettings,
                        section.EmailSettings,
                        this.props.notification));
                }
                else {
                    this.setState({ senderNameError: true, senderNameHelper: 'Email Address format is required' });
                    this.showEmailError();
                }



            }

        }

    }

    showEmailError = () => {

        this.props.dispatch(showNotification('Please correct the indicated items', section.EmailSettings, this.props.notification));
    }


    onSavePasswordClick = () => {

        if (this.validatePasswordData()) {

            this.props.dispatch(updatePasswordSettings(this.props.systemSettings.resetPasswordSettings, section.ResetPassword, this.props.notification));
        }
    }
    onClearPasswordClick = () => {
        this.SetControlFocus(settingsPanels.PasswordReset);
        this.setState({
            subjectError: false,
            subjectHelper: ' ',
            messageError: false,
            messageHelper: ' '
        });

        this.props.dispatch(clearResetPasswordSection(section.ResetPassword, this.props.notification));

    }


    validateEmailSettings = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        if (name === emailType.EmailAddress) {
            if (this.checkIsEmpty(value)) {
                this.setState({ emailAddressError: true, emailAddressHelper: 'Email Address is required' });
            } else {
                if (this.checkEmail(value)) {
                    this.setState({ emailAddressError: false, emailAddressHelper: " " });
                } else {
                    this.setState({ emailAddressError: true, emailAddressHelper: 'Email Address format is required' });
                }

            }
        } else if (name === emailType.SMTPServer) {

            if (this.checkIsEmpty(value)) {
                this.setState({ smtpServerError: true, smtpServerHelper: 'SMTP Host server is required' });
            } else {
                this.setState({ smtpServerError: false, smtpServerHelper: ' ' });
            }
        }

        else if (name === emailType.UserName) {

            if (this.checkIsEmpty(value)) {
                this.setState({ userNameError: true, userNameHelper: 'Username is required' });
            } else {
                this.setState({ userNameError: false, userNameHelper: ' ' });
            }
        }

        else if (name === emailType.Password) {

            if (this.checkIsEmpty(value)) {
                this.setState({ passwordError: true, passwordHelper: 'Password is required' });
            } else {
                this.setState({ passwordError: false, passwordHelper: ' ' });
            }
        }
        else if (name === emailType.PortNo) {

            if (this.checkIsEmpty(value)) {
                this.setState({ portError: true, portHelper: 'Port Number is required' });
            } else {
                this.setState({ portError: false, portHelper: ' ' });
            }
        }

        if (name === emailType.SenderName) {
            if (!this.checkIsEmpty(value)) {
                if (this.checkEmail(value)) {
                    this.setState({ senderNameError: false, senderNameHelper: "\u00a0" });
                } else {
                    this.setState({ senderNameError: true, senderNameHelper: 'Email Address format is required' });
                }

            } else {
                this.setState({ senderNameError: false, senderNameHelper: "\u00a0" });

            }
        }

    }
    validateEmailData = () => {

        let status = true;

        if (this.checkIsEmpty(this.props.systemSettings.emailSettings.sender)) {
            this.setState({ emailAddressError: true, emailAddressHelper: 'Email Address format is required' });
            status = false
        }
        else {
            if (!this.checkEmail(this.props.systemSettings.emailSettings.sender)) {
                this.setState({ emailAddressError: true, emailAddressHelper: 'Email Address format is required' });
                status = false

            }
        }

        if (this.checkIsEmpty(this.props.systemSettings.emailSettings.mailServer)) {
            this.setState({ smtpServerError: true, smtpServerHelper: 'SMTP Host server is required' });
            status = false
        }




        if (this.checkIsEmpty(this.props.systemSettings.emailSettings.username)) {
            this.setState({ userNameError: true, userNameHelper: 'Username is required' });
            status = false
        }


        if (this.checkIsEmpty(this.props.systemSettings.emailSettings.password)) {
            this.setState({ passwordError: true, passwordHelper: 'Password is required' });
            status = false
        }



        if (!this.props.systemSettings.emailSettings.mailPort) {
            this.setState({ portError: true, portHelper: 'Port Number is required' });
            status = false
        }




        if (status === false) {
            this.showEmailError();
        }

        return status;


    }
    validatePasswordSettings = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        if (name === PasswordType.Subject) {

            if (this.checkIsEmpty(value)) {
                this.setState({ subjectError: true, subjectHelper: 'Subject is required' });
            } else {
                this.setState({ subjectError: false, subjectHelper: ' ' });
            }
        }



    }
    validatePasswordData = () => {
        let status = true;

        if (this.checkIsEmpty(this.props.systemSettings.resetPasswordSettings.subject)) {
            this.setState({ subjectError: true, subjectHelper: 'Subject is required' });
            status = false
        }




        if (this.checkIsEmpty(this.props.systemSettings.resetPasswordSettings.message)) {
            this.setState({ messageError: true, messageHelper: 'Message is required' })
            status = false
        }

        if (status === false) {
            this.props.dispatch(showNotification('Please correct the indicated items', section.ResetPassword, this.props.notification));
        }

        return status;

    }

    hideMessage = (section) => {
        this.props.dispatch(hideSettingsMessage(section, this.props.notification));

    }
    checkIsEmpty(value) {
        return (!value || value.length === 0 || !value.trim());
    }

    saveEditorData = (event) => {

        let hasText = "";
        var regex = /(<([^>]+)>)/ig
        if (event.value) {
            let value = event.value
            hasText = !!value.replace(regex, "").length;
        }
        if (hasText) {
            this.changeSystemSettings(systemSettings => systemSettings.resetPasswordSettings.message = event.value);
            if (this.state.messageError) {
                this.setState({ messageError: false, messageHelper: ' ' })
            }

        } else {
            if (!this.state.messageError) {
                this.setState({ messageError: true, messageHelper: 'Message is required' })
                this.changeSystemSettings(systemSettings => systemSettings.resetPasswordSettings.message = '');
            }

        }

    }

    handleEditor = (event) => {

        if (this.props.systemSettings.resetPasswordSettings.message) {

            if (this.state.messageError) {
                this.setState({ messageError: false, messageHelper: ' ' })
            }

        } else {
            if (!this.state.messageError) {
                this.setState({ messageError: true, messageHelper: 'Message is required' })

            }

        }

    }



    validateRecordCount = (event) => {
        const target = event.target;
        const value = target.value;
        if (this.checkIsEmpty(value)) {
            this.setState({ recordCountError: true, recordCountHelper: 'Record Count is required' });
        } else {

            this.setState({ recordCountError: false, recordCountHelper: ' ' });

        }

    }

    validateRecordCountData = () => {

        let status = true;

        if (this.checkIsEmpty(this.props.systemSettings.recordCount.toString())) {
            this.setState({ recordCountError: true, recordCountHelper: 'Record Count is required' });
            status = false
        }

        if (status === false) {
            this.props.dispatch(showNotification('Please correct the indicated items', section.RecordCount, this.props.notification));
        }
        return status;


    }

    onSaveRecordCountClick = () => {

        if (this.validateRecordCountData()) {
            this.props.dispatch(updateSettingsData(this.props.systemSettings, section.RecordCount, this.props.notification));
        }
    }

    onClearRecordCountClick = () => {
        this.SetControlFocus(settingsPanels.Threshold)
        this.threshold.current.focus();
        this.setState({
            recordCountError: false,
            recordCountHelper: ' ',
        });
        this.props.dispatch(clearRecordCountSection(this.props.systemSettings.settingsDefaultValue.recordCount, section.RecordCount, this.props.notification));

    }



    onSaveBizWeaverTokenClick = () => {

        this.props.dispatch(updateSettingsData(this.props.systemSettings, section.BizweaverToken, this.props.notification));
    }

    onClearBizWeaverTokenClick = () => {
        this.SetControlFocus(settingsPanels.BizweaverToken)
        this.props.dispatch(clearBizweaverSection(section.BizweaverToken, this.props.notification));
    }




    validateSessionPeriodData = () => {

        let status = true;

        if ((this.checkIsEmpty(this.props.systemSettings.sessionTime.hours.toString()) || this.props.systemSettings.sessionTime.hours.toString() === "0") &&
            (this.checkIsEmpty(this.props.systemSettings.sessionTime.minutes.toString()) || this.props.systemSettings.sessionTime.minutes.toString() === "0")) {
            this.setState({ sessionPeriodError: true, sessionPeriodHelper: 'Minutes are required' });
            status = false
        }

        if (status === false) {
            this.props.dispatch(showNotification('A session inactive period must be provided.', section.SessionPeriod, this.props.notification));
        }
        return status;


    }


    validateSessionPeriod = (event) => {

        if ((this.checkIsEmpty(this.props.systemSettings.sessionTime.hours.toString()) || this.props.systemSettings.sessionTime.hours.toString() === "0") &&
            (this.checkIsEmpty(this.props.systemSettings.sessionTime.minutes.toString()) || this.props.systemSettings.sessionTime.minutes.toString() === "0")) {

            this.setState({ sessionPeriodError: true, sessionPeriodHelper: 'Minutes are required' });

        }

        else {

            this.setState({ sessionPeriodError: false, sessionPeriodHelper: ' ' });
        }

    }


    onSaveSessionPeriodClick = () => {

        if (this.validateSessionPeriodData()) {
            this.props.dispatch(updateSettingsData(this.props.systemSettings, section.SessionPeriod, this.props.notification));
        }
    }

    onClearSessionPeriodClick = () => {
        this.SetControlFocus(settingsPanels.InactivePeriod);
        this.setState({
            sessionPeriodError: false,
            sessionPeriodHelper: ' ',
        });
        this.props.dispatch(clearSessionPeriodSection(this.props.systemSettings.settingsDefaultValue.sessionInactiveTime, section.SessionPeriod, this.props.notification));

    }


    validateTimeZoneData = () => {

        let status = true;

        if (this.props.systemSettings.systemTimeZone === "none") {
            this.setState({ timeZoneError: true, timeZoneHelper: 'System Time Zone is required' });
            status = false
        }

        if (status === false) {
            this.props.dispatch(showNotification('Please correct the indicated items', section.TimeZone, this.props.notification, this.props.notification));
        }
        return status;


    }


    validateTimeZone = (event) => {
        if (this.props.systemSettings.systemTimeZone === "none") {
            this.setState({ timeZoneError: true, timeZoneHelper: 'System Time Zone is required' });

        } else {

            this.setState({ timeZoneError: false, timeZoneHelper: ' ' });
        }

    }

    onSaveTimeZoneClick = () => {

        if (this.validateTimeZoneData()) {
            this.props.dispatch(updateSettingsData(this.props.systemSettings, section.TimeZone, this.props.notification));
        }
    }

    onClearTimeZoneClick = () => {
        this.SetControlFocus(settingsPanels.TimeZone)
        this.setState({ timeZoneError: false, timeZoneHelper: ' ' });
        this.props.dispatch(clearTimeZoneSection(section.TimeZone, this.props.notification));

    }

    validateFileFormatData = () => {

        let status = true;

        if (this.checkIsEmpty(this.props.systemSettings.allowedExtensions)) {
            this.setState({ fileFormatError: true, fileFormatHelper: 'File Format is required' });
            status = false
        }

        if (this.checkIsEmpty(this.props.systemSettings.formFileSize.toString()) ||
            this.props.systemSettings.formFileSize === 0) {
            this.setState({ fileSizeError: true, fileSizeHelper: 'File Size is required' });
            status = false
        } else {
            if (parseInt(this.props.systemSettings.formFileSize) === 0) {
                this.setState({ fileSizeError: true, fileSizeHelper: 'File Size is required' });
                status = false
            }

        }

        if (status === false) {
            this.props.dispatch(showNotification('Please correct the indicated items', section.FileUpload, this.props.notification));
        }
        return status;


    }


    validateFileFormat = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        if (name === fileUploadType.FileFormat) {
            if (this.checkIsEmpty(value)) {
                this.setState({ fileFormatError: true, fileFormatHelper: 'File Format is required' });
            } else {

                this.setState({ fileFormatError: false, fileFormatHelper: 'Enter formats comma separated , no spaces ex. .jpg,.docx,.xlsx. etc' });

            }
        }
        else if (name === fileUploadType.FileSize) {
            if (this.checkIsEmpty(value)) {
                this.setState({ fileSizeError: true, fileSizeHelper: 'File Size is required' });
            } else {

                if (parseInt(value) === 0) {
                    this.setState({ fileSizeError: true, fileSizeHelper: 'File Size is required' });
                } else {
                    this.setState({ fileSizeError: false, fileSizeHelper: ' ' });
                }

            }
        }

    }

    onSaveFileUploadClick = () => {

        if (this.validateFileFormatData()) {
            this.props.dispatch(updateSettingsData(this.props.systemSettings, section.FileUpload, this.props.notification));
        }
    }

    onClearFileUploadClick = () => {
        this.SetControlFocus(settingsPanels.FormFileUpload);
        this.setState({ fileFormatError: false, fileFormatHelper: 'Enter formats comma separated , no spaces ex. .jpg,.docx,.xlsx. etc', fileSizeError: false, fileSizeHelper: ' ' });
        this.props.dispatch(clearFileUploadSection(this.props.systemSettings.settingsDefaultValue.fileSize, this.props.systemSettings.settingsDefaultValue.fileAllowExtension, section.FileUpload, this.props.notification));

    }

    validateInstallationId = (event) => {
        const target = event.target;
        const value = target.value;

        if (this.checkIsEmpty(value)) {
            this.setState({ installationIdError: true, installationIdHelper: 'Installation ID is required' });
        }

        else {

            this.setState({ installationIdError: false, installationIdHelper: ' ' });

        }

    }
    validateInstallationData = (event) => {
        if (this.checkIsEmpty(this.props.systemSettings.installationID.installationID)) {
            this.setState({ installationIdError: true, installationIdHelper: 'Installation ID is required' });
            this.props.dispatch(showNotification('Please correct the indicated items', section.InstallationId, this.props.notification));
            return false;
        }

        return true;

    }

    onClearInstallationIdClick = () => {
        this.SetControlFocus(settingsPanels.InstallationID)
        this.setState({ installationIdError: false, installationIdHelper: ' ' });
        this.props.dispatch(clearInstallationSection(section.InstallationId, this.props.notification));

    }

    onSaveInstallationIdClick = () => {

        if (this.validateInstallationData()) {
            this.props.dispatch(updateSettingsData(this.props.systemSettings.installationID, section.InstallationId, this.props.notification));
        }
    }

    validatePayment = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        //if (name === paymentType.) {
        //    //if (this.checkIsEmpty(value)) {
        //    //    this.setState({ paymentGatewayError: true, paymentGatewayHelper: 'Payment Gateway is required' });
        //    //}
        //    //else {
        //      this.setState({ paymentGatewayError: false, paymentGatewayHelper: ' ' });
        //    //}
        //}
         if (name === paymentType.MerchantLogin) {

            if (this.checkIsEmpty(value)) {
                this.setState({ merchantLoginError: true, merchantLoginHelper: 'Merchant Login is required' });
            }
            else {
                this.setState({ merchantLoginError: false, merchantLoginHelper: ' ' });
            }

        }
        if (name === paymentType.Password) {

            if (this.checkIsEmpty(value)) {
                this.setState({ merchantPasswordError: true, merchantPasswordHelper: 'Password is required' });
            }
            else {
                this.setState({ merchantPasswordError: false, merchantPasswordHelper: ' ' });
            }

        }



    }
    validatePaymentData = (event) => {

        let status = true;
        //if (this.checkIsEmpty(this.props.systemSettings.paymentSettings.paymentGateway)) {
        //    this.setState({ paymentGatewayError: true, paymentGatewayHelper: 'Payment Gateway is required' });
        //    status = false;
        //}
         if (this.checkIsEmpty(this.props.systemSettings.paymentSettings.merchantUserID)) {
            this.setState({ merchantLoginError: true, merchantLoginHelper: 'Merchant Login is required' });
            this.props.dispatch(showNotification('Please correct the indicated items', section.PaymentGateway, this.props.notification));
            status = false;
        }
         if (this.checkIsEmpty(this.props.systemSettings.paymentSettings.merchantPassword)) {
            this.setState({ merchantPasswordError: true, merchantPasswordHelper: 'Password is required' });
            this.props.dispatch(showNotification('Please correct the indicated items', section.PaymentGateWay, this.props.notification));
            status = false;

        }

        if (status === false) {
            this.props.dispatch(showNotification('Please correct the indicated items', section.PaymentGateWay, this.props.notification));
            return status;
        }
        else if (!this.props.systemSettings.paymentSettings.iseCheck && !this.props.systemSettings.paymentSettings.hasVisa && !this.props.systemSettings.paymentSettings.hasMaster && !this.props.systemSettings.paymentSettings.hasAmex && !this.props.systemSettings.paymentSettings.hasDiscover) {
            this.props.dispatch(showNotification('Please select a payment type.', section.PaymentGateWay, this.props.notification));
            status = false;

        }

        

        return status;


        


    }
    onSavePaymentGatewayClick = () => {

        if (this.validatePaymentData()) {
            this.props.dispatch(updateSettingsData(this.props.systemSettings.paymentSettings, section.PaymentGateWay, this.props.notification));
        }
    }

    onClearPaymentGatewayClick = () => {
        this.SetControlFocus(settingsPanels.PaymentGateway)
        this.setState({ paymentGatewayError: false, paymentGatewayHelper: ' ', merchantLoginError: false, merchantLoginHelper: ' ', merchantPasswordError: false, merchantPasswordHelper: ' '});
        this.props.dispatch(clearPaymentGatewaySection(section.PaymentGateWay, this.props.notification));

    }

    SetControlFocus = (panelId) => {

        if (panelId) {
            currentPanelId = panelId;
        }

        switch (currentPanelId) {
            case settingsPanels.Threshold:
            this.threshold.current.focus();
            return;
            case settingsPanels.BizweaverToken:
            this.bizweaverToken.current.focus();
            return;
            case settingsPanels.InactivePeriod:
            this.inactivePeriod.current.focus();
                return;
            case settingsPanels.FormFileUpload:
                this.formFileUpload.current.focus();
                return;
            case settingsPanels.InstallationID:
                this.installationID.current.focus();
                return;
            case settingsPanels.EmailServer:
                this.emailServer.current.focus();
                return;
            case settingsPanels.PaymentGateway:
                this.paymentGateway.current.focus();
                return;
            case settingsPanels.TimeZone:
                this.timeZone.current.focus();
                return;
            case settingsPanels.PasswordReset:
                this.passwordReset.current.focus();
            return;
        }
    }


    setControlPanelFocus=(event) => {

        if (timeout) {
            clearTimeout(timeout);
        }
        currentPanelId = event.currentTarget.id;
        timeout = setTimeout(()=> {
            clearTimeout(timeout);
            this.SetControlFocus();

        }, 500)

        
    }


    renderBizweaverTokenSection = (classes) => {

        return (

            <Grid container direction="row" justifyContent="space-between" >
                <Grid item xs={12}>
                    <Accordion defaultExpanded={false} onChange={(event, expanded) => {
                        if (expanded) {
                            this.setControlPanelFocus(event)

                        }
                    }}>
                        <AccordionSummary classes={{ content: classes.accordionSummary, root: classes.accordionSummary, }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="BizweaverTokenPanel"
                        >
                            <Box ml={1}><Typography variant="subtitle1">Bizweaver Token</Typography> </Box>

                        </AccordionSummary>
                        <AccordionDetails classes={{ root: classes.accordionDetails }}>
                            <Grid container direction="column" spacing={2}>


                                <Grid container direction="row" >

                                    <Box ml={5} mt={3} minWidth={369}>
                                        <TextField inputRef={this.bizweaverToken}

                                            label="Token"
                                            value={this.props.systemSettings.bizweaverToken}
                                            onChange={event => this.changeSystemSettings(systemSettings => systemSettings.bizweaverToken = event.target.value)}
                                            size="small"
                                            fullWidth={true}
                                        >
                                        </TextField>
                                    </Box>
                                    <Box pt={2} pl={8}>
                                        {this.props.notification ? this.props.notification.BizweaverToken ? <TransitionAlerts onHide={() => { this.hideMessage(section.BizweaverToken) }} key={this.state.alertComponentKey} message={this.props.notification.BizweaverTokenMessage} severity={this.props.notification.BizweaverTokenSeverity} /> : "" : ""}
                                    </Box>

                                </Grid>

                                <Box ml={2} pr={2} width={1} mt={3}><Divider /></Box>
                                <Box className={classes.buttonRow}>
                                    <Grid container direction="row" justifyContent="flex-end" spacing={0} >
                                        <Grid item xs={6} lg={6}>
                                        </Grid>
                                        <Grid item xs={6} lg={6}>
                                            <Box pr={3.7} pt={1.7}>

                                                <Grid container direction="row" justifyContent="flex-end" spacing={0} >
                                                    <Box maxWidth={60} mr={.5}>
                                                        <Button variant="default" onClick={this.onClearBizWeaverTokenClick}>Clear</Button>
                                                    </Box>
                                                    <Box>

                                                        <Button color="primary" onClick={this.onSaveBizWeaverTokenClick}>Save</Button>

                                                    </Box>
                                                </Grid></Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                                             


                            </Grid>

                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        );
    }
    renderListRecordThresholdSection = (classes) => {
      
        return (
            <Grid container direction="row" >
                <Grid item xs={12}>
                    <Accordion defaultExpanded={false}  onChange={(event, expanded) => {
                        if (expanded) {
                            this.setControlPanelFocus(event)
                         }
                    }}>
                        <AccordionSummary classes={{ content: classes.accordionSummary, root: classes.accordionSummary }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="ThresholdPanel"
                        >
                            <Box ml={1}><Typography variant="subtitle1">Data View Record Threshold</Typography> </Box>
                        </AccordionSummary>
                        <AccordionDetails classes={{ root: classes.accordionDetails }}>
                            <Grid container direction="column" spacing={2} >
                                <Grid container>
                                    <Box ml={5} mt={2.1} minWidth={316}>
                                        
                                        <TextField inputRef={this.threshold} 
                                            
                                           
                                            required
                                            value={this.props.systemSettings.recordCount} error={this.state.recordCountError} helperText={this.state.recordCountHelper} onBlur={this.validateRecordCount}
                                            onChange={(event) => {
                                                const regex = /^[0-9\b]+$/;
                                                if (event.target.value === '' || regex.test(event.target.value)) {
                                                    this.changeSystemSettings(
                                                        systemSettings => systemSettings.recordCount =
                                                            event.target.value);
                                                }
                                            }}
                                            size="small"
                                            label="Record Count"
                                            fullWidth={true}



                                            inputProps={{ maxLength: 9,autofocus:true }}
                                        >
                                        </TextField>

                                    </Box>
                                    <Box pt={2} pl={8}>
                                        {this.props.notification ? this.props.notification.RecordCount ? <TransitionAlerts onHide={() => { this.hideMessage(section.RecordCount) }} key={this.state.alertComponentKey} message={this.props.notification.RecordCountMessage} severity={this.props.notification.RecordCountSeverity} /> : "" : ""}
                                    </Box>
                                </Grid>

                                <Box ml={2} pr={2} width={1}><Divider /></Box>
                                <Box className={classes.buttonRow}>
                                    <Grid container direction="row" justifyContent="flex-end" spacing={0} >
                                        <Grid item xs={6} lg={6}>
                                        </Grid>
                                        <Grid item xs={6} lg={6}>
                                            <Box pr={3.7} pt={1.7}>

                                                <Grid container direction="row" justifyContent="flex-end" spacing={0} >
                                                    <Box maxWidth={60} mr={.5}>
                                                        <Button variant="default" onClick={this.onClearRecordCountClick}>Clear</Button>
                                                    </Box>
                                                    <Box>

                                                        <Button color="primary" onClick={this.onSaveRecordCountClick}>Save</Button>

                                                    </Box>
                                                </Grid></Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                
                            </Grid>

                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        );
    }
    renderFormSection = (classes) => {

        return (
            <Grid container direction="row" justifyContent="space-between">
                <Grid item xs={12}>
                    <Accordion defaultExpanded={false} onChange={(event, expanded) => {
                        if (expanded) {
                            this.setControlPanelFocus(event)

                        }
                    }}>
                        <AccordionSummary classes={{ content: classes.accordionSummary, root: classes.accordionSummary }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="FormFileUploadPanel"
                        >
                            <Box ml={1}><Typography variant="subtitle1">Form File Upload Settings</Typography> </Box>
                        </AccordionSummary>
                        <AccordionDetails classes={{ root: classes.accordionDetails }}>

                            <Grid container direction="column" spacing={2} >
                                <Box pl={5} pt={1.4} maxWidth={680}>

                                    <TextField onBlur={this.validateFileFormat} inputRef={this.formFileUpload}
                                        required
                                        label="File Formats"
                                        value={this.props.systemSettings.allowedExtensions}
                                        onChange={event => this.changeSystemSettings(systemSettings => systemSettings.allowedExtensions = event.target.value)}
                                        size="small"
                                        helperText={this.state.fileFormatHelper}
                                        error={this.state.fileFormatError}
                                        fullWidth={true}
                                        name={fileUploadType.FileFormat}
                                        classes={{ root: !this.state.fileFormatError ? classes.textField : "" }}
                                    >
                                    </TextField>

                                </Box>


                                <Box pl={5} pt={3.1}>
                                    <Grid container direction="row" >

                                        <Box maxWidth={144}>
                                            <TextField onBlur={this.validateFileFormat}
                                                label="File Size Limit"
                                                required
                                                value={this.props.systemSettings.formFileSize}
                                                onChange={(event) => {
                                                    const regex = /^[0-9\b]+$/;
                                                    if (event.target.value === '' || regex.test(event.target.value)) {
                                                        this.changeSystemSettings(
                                                            systemSettings => systemSettings.formFileSize =
                                                                event.target.value);
                                                    }
                                                }}
                                                size="small"
                                                fullWidth={true}
                                                helperText={this.state.fileSizeHelper}
                                                error={this.state.fileSizeError}
                                                name={fileUploadType.FileSize}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {<Typography variant="subtitle1">MB</Typography>}
                                                        </InputAdornment>)
                                                }}
                                                inputProps={{ maxLength: 9 }}
                                            >
                                            </TextField>
                                        </Box>
                                        <Box ml={5}>
                                            {this.props.notification ? this.props.notification.FileUpload ? <TransitionAlerts onHide={() => { this.hideMessage(section.FileUpload) }} key={this.state.alertComponentKey} message={this.props.notification.FileUploadMessage} severity={this.props.notification.FileUploadSeverity} /> : "" : ""}
                                        </Box>
                                    </Grid>
                                </Box>

                                <Box ml={2} pr={2} width={1}><Divider /></Box>
                                <Box className={classes.buttonRow}>
                                    <Grid container direction="row" justifyContent="flex-end" spacing={0} >
                                        <Grid item xs={6} lg={6}>
                                        </Grid>
                                        <Grid item xs={6} lg={6}>
                                            <Box pr={3.1} pt={1.7}>

                                                <Grid container direction="row" justifyContent="flex-end" spacing={0} >
                                                    <Box maxWidth={60} mr={.5}>
                                                        <Button variant="default" onClick={this.onClearFileUploadClick}>Clear</Button>
                                                    </Box>
                                                    <Box>

                                                        <Button color="primary" onClick={this.onSaveFileUploadClick}>Save</Button>

                                                    </Box>
                                                </Grid></Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                                               
                            </Grid>



                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        );
    }
    renderInstallationMessage = () => {
        if (this.props.systemSettings.installationID.message) {
            return (<Box pl={4} pt={1.3} width={1} >
                <Grid container direction="row" >

                    <Box width={1}>
                        <Typography variant="body1">{this.props.systemSettings.installationID.message}</Typography>
                    </Box>
                </Grid>
            </Box>)

        } else {

            return "";
        }

    }
    renderInstallationId = (classes) => {

        return (
            <Grid container direction="row" justifyContent="space-between">
                <Grid item xs={12}>
                    <Accordion defaultExpanded={false} onChange={(event, expanded) => {
                        if (expanded) {
                            this.setControlPanelFocus(event)

                        }
                    }}>

                        <AccordionSummary classes={{ content: classes.accordionSummary, root: classes.accordionSummary }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="InstallationIDPanel"
                        >
                            <Box ml={1}><Typography variant="subtitle1">SAP Installation ID</Typography> </Box>
                        </AccordionSummary>
                        <AccordionDetails classes={{ root: classes.accordionDetails }}>

                            <Grid container direction="column" spacing={2} >

                                <Box pl={5} pt={1.3} width={1} >
                                    <Grid container direction="row" >

                                        <Box width={511}>
                                            <TextField onBlur={this.validateInstallationId} inputRef={this.installationID}
                                                label="SAP Installation ID"
                                                required
                                                value={this.props.systemSettings.installationID ? this.props.systemSettings.installationID.installationID : ""}
                                                onChange={(event) => {
                                                    this.changeSystemSettings(
                                                        systemSettings => systemSettings.installationID.installationID = event.target.value);

                                                }}
                                                size="small"
                                                className={classes.installationId}
                                                fullWidth={true}
                                                helperText={this.state.installationIdHelper}
                                                error={this.state.installationIdError}
                                                name="installationID"
                                                inputProps={{ maxLength: 40 }}
                                            >
                                            </TextField>
                                        </Box>

                                        <Box width={248}>
                                            <Grid container direction="row" >
                                                <Grid item><Typography className={classes.installationText} variant="body1">Licensed Users:</Typography>
                                                </Grid><Grid item><Typography className={classes.installationValue} variant="body1">{this.props.systemSettings.installationID ? this.props.systemSettings.installationID.licensedUsers : ""}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        <Box maxWidth={144}>
                                            <Grid container direction="row" >
                                                <Grid item><Typography className={classes.installationText} variant="body1">Active Users:</Typography>
                                                </Grid><Grid item><Typography className={classes.installationValue} variant="body1">{this.props.systemSettings.installationID ? this.props.systemSettings.installationID.activeUsers : ""}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box ml={5}>
                                            {this.props.notification ? this.props.notification.InstallationId ? <TransitionAlerts onHide={() => { this.hideMessage(section.InstallationId) }} key={this.state.alertComponentKey} message={this.props.notification.InstallationIdMessage} severity={this.props.notification.InstallationIdSeverity} /> : "" : ""}
                                        </Box>
                                    </Grid>
                                </Box>

                                {this.renderInstallationMessage()}

                                <Box ml={2} pr={2} width={1}><Divider /></Box>
                                <Box className={classes.buttonRow}>
                                    <Grid container direction="row" justifyContent="flex-end" spacing={0} >
                                        <Grid item xs={6} lg={6}>
                                        </Grid>
                                        <Grid item xs={6} lg={6}>
                                            <Box pr={3.1} pt={1.7}>

                                                <Grid container direction="row" justifyContent="flex-end" spacing={0} >
                                                    <Box maxWidth={60} mr={.5}>
                                                        <Button variant="default" onClick={this.onClearInstallationIdClick}>Clear</Button>
                                                    </Box>
                                                    <Box>

                                                        <Button color="primary" onClick={this.onSaveInstallationIdClick}>Save</Button>

                                                    </Box>
                                                </Grid></Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                                                
                            </Grid>



                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        );
    }
    renderEmailSection = (classes) => {
        return (

            <Grid container direction="row" justifyContent="space-between">
                <Grid item xs={12} lg={12}>
                    <Accordion defaultExpanded={false} onChange={(event, expanded) => {
                        if (expanded) {
                            this.setControlPanelFocus(event)

                        }
                    }}>
                        <AccordionSummary classes={{ content: classes.accordionSummary, root: classes.accordionSummary }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="EmailServerPanel"
                        >
                            <Box ml={1}><Typography variant="subtitle1">Email Server</Typography> </Box>
                        </AccordionSummary>
                        <AccordionDetails classes={{ root: classes.accordionDetails }}>
                            <Grid container spacing={0} direction="row">
                                <Box width={1}>
                                    <Grid container spacing={0} direction="column" justifyContent="flex-start" >

                                        <Box ml={1} pr={31} width={1} pl={2}>
                                            <Grid container direction="row" spacing={4} className={classes.gridItem} >
                                                <Grid item xs={12} lg={3} >

                                                    <TextField error={this.state.emailAddressError} helperText={this.state.emailAddressHelper} onBlur={this.validateEmailSettings} inputRef={this.emailServer}
                                                        required
                                                        name="Email address"
                                                        label="Email Address"
                                                        value={this.props.systemSettings.emailSettings.sender}
                                                        onChange={event => this.changeSystemSettings(systemSettings => systemSettings.emailSettings.sender = event.target.value)}
                                                    >
                                                    </TextField>

                                                </Grid>
                                                <Grid item xs={12} lg={3}>
                                                    <TextField error={this.state.smtpServerError} helperText={this.state.smtpServerHelper} onBlur={this.validateEmailSettings}
                                                        required
                                                        name="SMTP Host Server"
                                                        label="SMTP Host Server"
                                                        value={this.props.systemSettings.emailSettings.mailServer}
                                                        onChange={event => this.changeSystemSettings(systemSettings => systemSettings.emailSettings.mailServer = event.target.value)}
                                                    >
                                                    </TextField>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box ml={1} pr={31} width={1} className={classes.emailUserName} pl={2}>
                                            <Grid container direction="row" spacing={4} className={classes.gridItem}>
                                                <Grid item xs={12} lg={3}>
                                                    <TextField error={this.state.userNameError} helperText={this.state.userNameHelper} onBlur={this.validateEmailSettings}
                                                        required
                                                        name='UserName'
                                                        value={this.props.systemSettings.emailSettings.username}
                                                        onChange={event => this.changeSystemSettings(systemSettings => systemSettings.emailSettings.username = event.target.value)}
                                                        label="Username"
                                                        size="small"
                                                    >
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={12} lg={3} >
                                                    <Box><AdminPasswordControl onBlur={this.validateEmailSettings} passwordHelper={this.state.passwordHelper} passwordError={this.state.passwordError} key={!this.props.systemSettings.resetKey ? 0 : this.props.systemSettings.resetKey} label="Password" value={this.props.systemSettings.emailSettings.password} hasPassword={this.props.systemSettings.hasEmailPassword} updateDataSource={this.changeEmailPassword} /></Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box ml={1} pr={31} width={1} className={classes.emailPort} pl={2}>
                                            <Grid container direction="row" spacing={4} className={classes.gridItem}>
                                                <Grid item xs={12} lg={3}>
                                                    <Grid container direction="row" >
                                                        <Grid item xs={6} >
                                                            <TextField error={this.state.portError} helperText={this.state.portHelper} onBlur={this.validateEmailSettings} name='PortNo'
                                                                required label="Port Number" onChange={event => this.changeSystemSettings(systemSettings => systemSettings.emailSettings.mailPort = event.target.value)}
                                                                value={this.props.systemSettings.emailSettings.mailPort}
                                                                size="small"
                                                            > </TextField></Grid>
                                                        <Grid item xs={6} className={classes.gridEmailSSl}  >
                                                            <FormControlLabel classes={{
                                                                root: classes.emailSSL

                                                            }}

                                                                control={<Switch color="primary" checked={this.props.systemSettings.emailSettings.enableSSL} onChange={this.toggleEnableSSL} />}
                                                                label="SSL" /></Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} lg={3} >
                                                    <TextField error={this.state.senderNameError} helperText={this.state.senderNameHelper} onBlur={this.validateEmailSettings} name="SenderName"
                                                        label="Test Email"

                                                        onChange={event => this.changeSystemSettings(systemSettings => systemSettings.emailSettings.senderName = event.target.value)}
                                                        value={this.props.systemSettings.emailSettings.senderName ? this.props.systemSettings.emailSettings.senderName : ""}
                                                        size="small"
                                                    > </TextField>
                                                </Grid>
                                                <Grid item xs={12} lg={3} className={classes.gridSendButton} >

                                                    <Button className={classes.buttonSend}
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={this.props.mailSendInitiated ? this.props.mailSendInitiated : false}
                                                        onClick={this.onSendMailClick}
                                                        endIcon={<SendIcon />}
                                                    >Send
                                                    </Button>
                                                    {this.props.mailSendInitiated ? this.props.mailSendInitiated : false && <CircularProgress size={24} className={classes.buttonProgress} />}

                                                </Grid>
                                                <Grid item lg>
                                                    {this.props.notification ? this.props.notification.EmailSettings ? <TransitionAlerts onHide={() => { this.hideMessage(section.EmailSettings) }} key={this.state.alertComponentKey} message={this.props.notification.EmailSettingsMessage} severity={this.props.notification.EmailSettingsSeverity} /> : "" : ""}
                                                </Grid>
                                            </Grid>

                                        </Box>


                                        <Box width={1}><Divider /></Box>
                                        <Box className={classes.buttonRow}>
                                            <Grid container direction="row" justifyContent="flex-end" spacing={0} >
                                                <Grid item xs={6} lg={6}>
                                                </Grid>
                                                <Grid item xs={6} lg={6}>
                                                    <Box pr={2.2} pt={1.7}>

                                                        <Grid container direction="row" justifyContent="flex-end" spacing={0} >
                                                            <Box maxWidth={60} mr={.5}>
                                                                <Button variant="default" onClick={this.onClearEmailClick}>Clear</Button>
                                                            </Box>
                                                            <Box>

                                                                <Button color="primary" onClick={this.onSaveEmailClick}>Save</Button>

                                                            </Box>
                                                        </Grid></Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                                                                
                                    </Grid>

                                </Box>
                            </Grid>

                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>



        );
    }
    renderPaymentSection = (classes) => {
        return (
            <Grid container direction="row" justifyContent="space-between">
                <Grid item xs={12}>
                    <Accordion defaultExpanded={false} onChange={(event, expanded) => {
                        if (expanded) {
                            this.setControlPanelFocus(event)

                        }
                    }}>
                        <AccordionSummary classes={{ content: classes.accordionSummary, root: classes.accordionSummary, }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="PaymentGatewayPanel"
                        >
                            <Box ml={1}><Typography variant="subtitle1">Payment Gateway</Typography></Box>
                        </AccordionSummary>
                        <AccordionDetails classes={{ root: classes.accordionDetails }}>
                            <Box width={1}  mt={.7}>
                                <Grid container direction="column">
                                    
                                    <Grid item xs={3} pl={3}>
                                        <Grid container direction="row" rowSpacing={1}>
                                            <Box className={classes.authorize}>

                                                <FormControl variant="standard" size="medium" fullWidth={true} className={classes.paymentGateway}>
                                                    <InputLabel htmlFor="outlined-payment-gateway-native-simple">Payment Gateway</InputLabel>
                                                    <Select helperText={this.state.paymentGatewayHelper} error={this.state.paymentGatewayError} onBlur={this.validatePayment} MenuProps={{ disableScrollLock: true }}
                                                        disabled={this.props.systemSettings.paymentGatewayList.length>1?false:true}
                                                        name="paymentGateway"
                                                        native
                                                        value={this.props.systemSettings.paymentSettings.paymentGateway}
                                                        size="medium"
                                                        onChange={event => this.changeSystemSettings(systemSettings => systemSettings.paymentSettings.paymentGateway = event.target.value)}

                                                        label="Payment Gateway"
                                                        inputProps={{
                                                            name: 'paymentGateway',
                                                            id: 'outlined-payment-gateway-native-simple'
                                                        }}
                                                    >
                                                        
                                                        {

                                                            this.props.systemSettings.paymentGatewayList.map((gateway, index) => (

                                                                <option key={index + 1} value={gateway.id}> {gateway.name}</option>
                                                            ))
                                                        }

                                                    </Select>
                                                </FormControl>
                                            </Box>
                                            <Box className={classes.authorize}>
                                                <TextField className={classes.paymentGateway} helperText={this.state.merchantLoginHelper} error={this.state.merchantLoginError} onBlur={this.validatePayment} inputRef={this.paymentGateway}
                                                    name="merchantLogin"
                                                    required
                                                    value={this.props.systemSettings.paymentSettings.merchantUserID}
                                                    onChange={event => this.changeSystemSettings(systemSettings => systemSettings.paymentSettings.merchantUserID = event.target.value)}
                                                    //variant="outlined"
                                                    label="Merchant Login"
                                                    size="medium"
                                                >
                                                </TextField>
                                            </Box>

                                            <Box className={classes.paymentGateway}>

                                                <AdminPasswordControl onBlur={this.validatePayment} passwordHelper={this.state.merchantPasswordHelper} passwordError={this.state.merchantPasswordError} key={!this.props.systemSettings.resetKey ? 0 : this.props.systemSettings.resetKey} label="Password" value={this.props.systemSettings.paymentSettings.merchantPassword} hasPassword={this.props.systemSettings.hasPaymentPassword} updateDataSource={this.changePaymentMerchantPassword} /></Box>



                                        </Grid>
                                        </Grid>
                                    
                                      
                                    <Grid item pt={5.2} pl={3}>
                                        <Grid container direction="row" rowSpacing={3}>
                                            <Box className={classes.creditCard} >
                                                <Typography mt={.7} variant="subtitle1" >Credit Card</Typography>
                                            </Box>
                                            <Box className={classes.demoItem}>

                                                <FormControl className={classes.demoItemControl}>

                                                    <FormControlLabel
                                                        control={<Switch
                                                            color="primary"
                                                            checked={this.props.systemSettings.paymentSettings.isCCDemoAccount}
                                                            onChange={event => this.changeSystemSettings(systemSettings => systemSettings.paymentSettings.isCCDemoAccount = event.target.checked)}
                                                        />}
                                                        label={<Typography variant="body2">Demo</Typography>} />
                                                </FormControl>
                                            </Box>
                                            <Box >
                                                <FormControlLabel className={classes.eCheck + ' ' + classes.body2}
                                                    control={<Checkbox color="primary" checked={this.props.systemSettings.paymentSettings.iseCheck} onChange={event => this.changeSystemSettings(systemSettings => systemSettings.paymentSettings.iseCheck = event.target.checked)} name="eCheck" />}
                                                    label={<Typography variant="body2">eCheck</Typography>}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            color="primary"
                                                            checked={this.props.systemSettings.paymentSettings.isECheckDemoAccount}
                                                            onChange={event => this.changeSystemSettings(systemSettings => systemSettings.paymentSettings.isECheckDemoAccount = event.target.checked)}
                                                        />}
                                                    label={<Typography variant="body2">Demo</Typography>}
                                                />
                                            </Box>
                                        </Grid>
                                        </Grid>
                                    
                                    <Box pt={1.4} pl={6} pb={1}>
                                        <Grid container direction="row" columnSpacing={3}>
                                            <Box className={classes.visa}>
                                                <FormControlLabel
                                                    control={<Checkbox color="primary" checked={this.props.systemSettings.paymentSettings.hasVisa} onChange={event => this.changeSystemSettings(systemSettings => systemSettings.paymentSettings.hasVisa = event.target.checked)} name="eCheck" />}
                                                    label={<Typography variant="body2">Visa</Typography>}
                                                />
                                            </Box>
                                            <Box >
                                                <FormControlLabel
                                                    control={<Checkbox color="primary" checked={this.props.systemSettings.paymentSettings.hasMaster} onChange={event => this.changeSystemSettings(systemSettings => systemSettings.paymentSettings.hasMaster = event.target.checked)} name="eCheck" />}
                                                    label={<Typography variant="body2">Mastercard</Typography>}
                                                />
                                            </Box>
                                            <Box className={classes.amex}>
                                                <FormControlLabel
                                                    control={<Checkbox color="primary" checked={this.props.systemSettings.paymentSettings.hasAmex} onChange={event => this.changeSystemSettings(systemSettings => systemSettings.paymentSettings.hasAmex = event.target.checked)} name="eCheck" />}
                                                    label={<Typography variant="body2">Amex</Typography>}
                                                />
                                            </Box>
                                            <Box>
                                                <FormControlLabel
                                                    control={<Checkbox color="primary" checked={this.props.systemSettings.paymentSettings.hasDiscover} onChange={event => this.changeSystemSettings(systemSettings => systemSettings.paymentSettings.hasDiscover = event.target.checked)} name="eCheck" />}
                                                    label={<Typography variant="body2">Discover</Typography>}
                                                />
                                            </Box>

                                            <Box ml={50} mt={-2}>
                                                {this.props.notification ? this.props.notification.PaymentGateWay ? <TransitionAlerts onHide={() => { this.hideMessage(section.PaymentGateWay) }} key={this.state.alertComponentKey} message={this.props.notification.PaymentGateWayMessage} severity={this.props.notification.PaymentGateWaySeverity} /> : "" : ""}
                                            </Box>

                                        </Grid>
                                    </Box>

                                    <Box  width={1}><Divider /></Box>
                                    <Box className={classes.buttonRow}>
                                        <Grid container direction="row" justifyContent="flex-end" spacing={0} >
                                            <Grid item xs={6} lg={6}>
                                            </Grid>
                                            <Grid item xs={6} lg={6}>
                                                <Box pr={1.1} pt={1.7}>

                                                    <Grid container direction="row" justifyContent="flex-end" spacing={0} >
                                                        <Box maxWidth={60} mr={.5}>
                                                            <Button variant="default" onClick={this.onClearPaymentGatewayClick}>Clear</Button>
                                                        </Box>
                                                        <Box>

                                                            <Button color="primary" onClick={this.onSavePaymentGatewayClick}>Save</Button>

                                                        </Box>
                                                    </Grid></Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Box>
                           
                        </AccordionDetails>

                    </Accordion>
                </Grid>
            </Grid>
        );
    }
    renderTimezoneSection = (classes) => {
        return (
            <Grid container direction="row" justifyContent="space-between">
                <Grid item xs={12}>
                    <Accordion defaultExpanded={false} onChange={(event, expanded) => {
                        if (expanded) {
                            this.setControlPanelFocus(event)

                        }
                    }}>
                        <AccordionSummary classes={{ content: classes.accordionSummary, root: classes.accordionSummary, }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="TimeZonePanel"
                        >
                            <Box ml={1}><Typography variant="subtitle1">Time Zone - Versago Server</Typography> </Box>
                        </AccordionSummary>
                        <AccordionDetails classes={{ root: classes.accordionDetails }}>
                            <Grid container direction="column" spacing={3} >

                                <Box ml={6.2} pt={1.5}>
                                    <Grid container direction="row" >

                                        <Box >
                                            <FormControl error={this.state.timeZoneError}>
                                                <InputLabel id="labelTimeZone">System Time Zone</InputLabel>
                                                <Select onBlur={this.validateTimeZone} inputRef={this.timeZone} MenuProps={{ disableScrollLock: true }}

                                                    labelId="labelTimeZone"
                                                    style={{ width: 220 }}
                                                    id="timeZone"
                                                    name="timeZone"
                                                    label="Time Zone"
                                                    value={this.props.systemSettings.systemTimeZone}
                                                    onChange={event => this.changeSystemSettings(systemSettings => systemSettings.systemTimeZone = event.target.value)}
                                                >
                                                    <MenuItem key="0" value={"none"} > -Select-  </MenuItem>
                                                    {

                                                        getTimeZoneList().map(function getTimeZone(timeZone, index) {

                                                            return (<MenuItem key={index + 1} value={timeZone}> {timeZone} </MenuItem>);

                                                        })

                                                    }

                                                </Select>
                                                <FormHelperText>{this.state.timeZoneHelper}</FormHelperText>
                                            </FormControl>

                                        </Box>
                                        <Box pl={8}>
                                            {this.props.notification ? this.props.notification.TimeZone ? <TransitionAlerts onHide={() => { this.hideMessage(section.TimeZone) }} key={this.state.alertComponentKey} message={this.props.notification.TimeZoneMessage} severity={this.props.notification.TimeZoneSeverity} /> : "" : ""}
                                        </Box>
                                    </Grid>
                                </Box>

                                <Box ml={3} pr={3} width={1}><Divider /></Box>
                                <Box className={classes.buttonRow}>
                                    <Grid container direction="row" justifyContent="flex-end" spacing={0} >
                                        <Grid item xs={6} lg={6}>
                                        </Grid>
                                        <Grid item xs={6} lg={6}>
                                            <Box pr={4.1} pt={1.7}>

                                                <Grid container direction="row" justifyContent="flex-end" spacing={0} >
                                                    <Box maxWidth={60} mr={.5}>
                                                        <Button variant="default" onClick={this.onClearTimeZoneClick}>Clear</Button>
                                                    </Box>
                                                    <Box>

                                                        <Button color="primary" onClick={this.onSaveTimeZoneClick}>Save</Button>

                                                    </Box>
                                                </Grid></Box>
                                        </Grid>
                                    </Grid>
                                </Box>

                            </Grid>

                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        );
    }
    renderSessionSection = (classes) => {
        return (
            <Grid container direction="row" justifyContent="space-between">
                <Grid item xs={12}>
                    <Accordion defaultExpanded={false} onChange={(event, expanded) => {
                        if (expanded) {
                            this.setControlPanelFocus(event)

                        }
                    }}>
                        <AccordionSummary classes={{ content: classes.accordionSummary, root: classes.accordionSummary, }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="InactivePeriodPanel"
                        >
                            <Box ml={1}><Typography variant="subtitle1">Session

                            </Typography> </Box>

                        </AccordionSummary>
                        <AccordionDetails classes={{ root: classes.accordionDetails }}>
                            <Grid container direction="column" spacing={3} >
                                <Box ml={6} mt={3} width={1}>
                                    <Grid container direction="row" spacing={0} >

                                        <Box maxWidth={121}>
                                            <TextField onBlur={this.validateSessionPeriod} inputRef={this.inactivePeriod}
                                                label="Hours"
                                                value={this.props.systemSettings.sessionTime.hours}
                                                onChange={(event) => {
                                                    const regex = /^[0-9\b]+$/;
                                                    if (event.target.value === '' || regex.test(event.target.value)) {
                                                        this.changeSystemSettings(
                                                            systemSettings => systemSettings.sessionTime.hours = event.target.value);
                                                    }
                                                }}
                                                size="small"
                                                inputProps={{ maxLength: 2 }}
                                            >
                                            </TextField>

                                        </Box>
                                        <Box maxWidth={120} ml={2.2}>
                                            <TextField error={this.state.sessionPeriodError} helperText={this.state.sessionPeriodHelper} onBlur={this.validateSessionPeriod}
                                                label="Minutes"
                                                value={this.props.systemSettings.sessionTime.minutes}
                                                required
                                                onChange={(event) => {
                                                    const regex = /^[0-9\b]+$/;
                                                    if (event.target.value === '' || regex.test(event.target.value)) {
                                                        this.changeSystemSettings(
                                                            systemSettings => systemSettings.sessionTime.minutes = event.target.value);
                                                    }
                                                }}
                                                size="small"
                                                type="tele"
                                                fullWidth={true}
                                                inputProps={{ maxLength: 2 }}
                                            >
                                            </TextField>

                                        </Box>

                                        <Box pl={11}>
                                            {this.props.notification ? this.props.notification.SessionPeriod ? <TransitionAlerts onHide={() => { this.hideMessage(section.SessionPeriod) }} key={this.state.alertComponentKey} message={this.props.notification.SessionPeriodMessage} severity={this.props.notification.SessionPeriodSeverity} /> : "" : ""}
                                        </Box>

                                    </Grid>
                                </Box>


                                <Box ml={3} pr={3} mt={.1} width={1}><Divider /></Box>
                                <Box className={classes.buttonRow} pr={3.5}>
                                    <Grid container direction="row" justifyContent="flex-end" spacing={0} >
                                        <Grid item xs={6} lg={6}>
                                        </Grid>
                                        <Grid item xs={6} lg={6}>
                                            <Box pr={3.7} pt={1.7}>

                                                <Grid container direction="row" justifyContent="flex-end" spacing={0} >
                                                    <Box maxWidth={60} mr={.5}>
                                                        <Button variant="default" onClick={this.onClearSessionPeriodClick}>Clear</Button>
                                                    </Box>
                                                    <Box>

                                                        <Button color="primary" onClick={this.onSaveSessionPeriodClick}>Save</Button>

                                                    </Box>
                                                </Grid></Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                                               
                            </Grid>

                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        );
    }
    renderResetPasswordSection = (classes) => {
        return (
            <Grid container direction="row" justifyContent="space-between">
                <Grid item xs={12}>
                    <Accordion defaultExpanded={false} onChange={(event, expanded) => {
                        if (expanded) {
                            this.setControlPanelFocus(event)

                        }
                    }}>
                        <AccordionSummary classes={{ content: classes.accordionSummary, root: classes.accordionSummary, }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="PasswordResetPanel">

                            <Box pl={1}  ><Typography variant="subtitle1">Password Reset</Typography></Box>
                        </AccordionSummary>
                        <AccordionDetails classes={{ root: classes.accordionDetails }} >

                            <Box width={1} pl={1}>
                                <Grid container direction="column" spacing={4} >

                                    <Box pl={6} className={classes.passwordBoxSubject} maxWidth={335}>

                                        <TextField error={this.state.subjectError} helperText={this.state.subjectHelper} onBlur={this.validatePasswordSettings} name="subject" inputProps={{ maxLength: 50 }} inputRef={this.passwordReset}
                                            required
                                            label="Subject"
                                            value={this.props.systemSettings.resetPasswordSettings.subject}
                                            onChange={event => this.changeSystemSettings(systemSettings => systemSettings.resetPasswordSettings.subject = event.target.value)}
                                            size="small"
                                            fullWidth={true}
                                        >
                                        </TextField>

                                    </Box>
                                    <Box pl={6} height={224}>
                                        <Grid container direction="row" alignItems="flex-end" >

                                            <Grid item xs={7}>

                                                <FormControl error={this.state.messageError}>
                                                    <RichTextEditor data={this.props.systemSettings.resetPasswordSettings.message} onChange={this.saveEditorData} onBlur={this.handleEditor} />

                                                    <FormHelperText>{this.state.messageHelper}</FormHelperText>
                                                </FormControl>

                                            </Grid>
                                            <Grid item xs={5}>
                                                <Box pb={2.2} pl={28.5} className={classes.resetError}>
                                                    {this.props.notification ? this.props.notification.ResetPassword ? <TransitionAlerts onHide={() => { this.hideMessage(section.ResetPassword) }} key={this.state.alertComponentKey} message={this.props.notification.ResetPasswordMessage} severity={this.props.notification.ResetPasswordSeverity} /> : "" : ""}
                                                </Box>
                                            </Grid>
                                        </Grid>


                                    </Box>

                                    <Box ml={3} pr={3} width={1}><Divider /></Box>
                                    <Box className={classes.buttonRow}>
                                        <Grid container direction="row" justifyContent="flex-end" spacing={0} >
                                            <Grid item xs={6} lg={6}>
                                            </Grid>
                                            <Grid item xs={6} lg={6}>
                                                <Box pr={4.1} pt={1.7}>

                                                    <Grid container direction="row" justifyContent="flex-end" spacing={0} >
                                                        <Box maxWidth={60} mr={.5}>
                                                            <Button variant="default" onClick={this.onClearPasswordClick}>Clear</Button>
                                                        </Box>
                                                        <Box>

                                                            <Button color="primary" onClick={this.onSavePasswordClick}>Save</Button>

                                                        </Box>
                                                    </Grid></Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Box>

                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        );
    }
    renderSettings = (classes) => {
        const spacing = 1;
        return (
            <Grid container direction="row" >
                <Grid item xs={12} >
                    {this.props.isLoading && <ActionBackdrop />}
                    <Box p={0}>


                        <Grid container spacing={spacing}>
                            <Grid item xs={12} >

                                {this.renderListRecordThresholdSection(classes)}

                            </Grid>

                            <Grid item xs={12}>
                                {this.renderBizweaverTokenSection(classes)}
                            </Grid>

                            <Grid item xs={12}>
                                {this.renderSessionSection(classes)}
                            </Grid>

                            <Grid item xs={12}>
                                {this.renderEmailSection(classes)}
                            </Grid>

                            <Grid item xs={12}>
                                {this.renderResetPasswordSection(classes)}
                            </Grid>

                            <Grid item xs={12}>
                                {this.renderPaymentSection(classes)}
                            </Grid>

                            <Grid item xs={12}>
                                {this.renderTimezoneSection(classes)}
                            </Grid>

                            <Grid item xs={12}>
                                {this.renderFormSection(classes)}
                            </Grid>

                            <Grid item xs={12}>
                                {this.renderInstallationId(classes)}
                            </Grid>
                        </Grid>

                    </Box>

                </Grid>
            </Grid>
        );
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                {this.renderSettings(classes)}
            </div>

        )
    }

}

function mapStateToProps(state) {
    let props = {
        isLoading: state.loader.isLoading,
        alert: state.alert,
        systemSettings: state.systemSettings.systemSettings,
        notification: state.systemSettings.notification,
        mailSendInitiated: state.systemSettings.mailSendInitiated
    };
    console.log('SystemSettings - mapStateToProps state: ', state, ', props: ', props);
    return props;
};

export default withRouter(connect(mapStateToProps)(withStyles(SettingStyle)(SystemSettings)));



