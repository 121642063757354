import { theme, customThemeStyles, mainTheme } from 'common/theme.js';
export const resetPasswordTheme = (resetTheme) => ({
    ...customThemeStyles,
    resetButton: {
        left: "-8px",
        height: "36px",
    },

    cancelButton: {
        height: "36px",
        left: "8px",
        backgroundColor: mainTheme.palette.grey[300] +"!important",
        color: mainTheme.palette.common.black + "!important",
        "&:hover": {
            backgroundColor: mainTheme.palette.grey[300],
        }
    },
    textPassword: {
        [theme.breakpoints.up('sm')]: {
            minWidth: "316px",
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: "272px",
        }
        
    },
    passwordHelp: {
        [theme.breakpoints.up('sm')]: {
            paddingLeft: "96px",
            width: "480px",
        },
        [theme.breakpoints.down('sm')]: {
            width: "292px",
        }

    }
});