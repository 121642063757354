import React from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { linkRouteAction } from 'client/store/LinkRoute';
import { actionCreators } from 'client/store/Objects';
import { authStatusHandlerAction } from 'client/store/AuthStatus'
import { loginActionCreators } from 'client/store/Login';
import { withRouter } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { MenuType } from 'client/components/Common/Enum';
const cookies = new Cookies();
const initiateRoute = 'SETROUTE_INITIATE'

class AuthStatus extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        let versagoCookie = cookies.get('Versago');
        if (versagoCookie !== undefined && this.props.authSettings.auth === false) {
            this.signOutAction();
        }
        if (this.props.linkRoute.type === initiateRoute && this.props.linkRoute.link) {
            this.props.history.push(this.props.linkRoute.link)
            this.props.actions.linkRouteAction.setLinkRoute(false);
        }

    }
    componentDidUpdate(nextProps) {
        let versagoCookie = cookies.get('Versago');
        if (versagoCookie !== undefined && this.props.authSettings.auth === false) {
           
            this.signOutAction();
        }
        if (this.props.linkRoute.type === initiateRoute && this.props.linkRoute.link) {
            this.props.history.push(this.props.linkRoute.link)
            this.props.actions.linkRouteAction.setLinkRoute(false);
        }

        if (this.props.landingSettings && this.props.landingSettings.linkStatus && (this.props.actionSource === "Reset" || this.props.actionSource === "SignIn")) {

            switch (this.props.landingSettings.landingObjectType) {
                case MenuType.Form:
                    this.props.history.push(`/Form/${this.props.landingSettings.objectId}`)
                                       break;
                case MenuType.Dashboard:
                    this.props.history.push(`/Dashboard/${this.props.landingSettings.objectId}`)
                                       break;
                case MenuType.Report:
                    this.props.history.push(`/Report/${this.props.landingSettings.objectId}`)
                    break;
                case MenuType.Calendar:
                    this.props.history.push(`/Calendar/${this.props.landingSettings.objectId}`)
                    break;
                default:
                    this.props.history.push(`/`)
                    break;
            }
            this.props.actions.actionCreators.resetLandingPage(false);
            
        }

    }
    signOutAction = () => {
        this.props.actions.loginActionCreators.requestSignOut();
        this.props.history.push("/");
        this.props.actions.authStatusHandler.setAuthStatus(true, '');
        
    }
    render() {
        return (
            <div>
              
            </div>
        )
    }
}
function mapDispatchToProps(dispatch) {
    return {
        actions: {
            loginActionCreators: bindActionCreators(loginActionCreators, dispatch),
            authStatusHandler: bindActionCreators(authStatusHandlerAction, dispatch),
            linkRouteAction: bindActionCreators(linkRouteAction, dispatch),
            actionCreators: bindActionCreators(actionCreators, dispatch),
            
        }
    };
}
function mapStateToProps(state) {

    return {
        authSettings: state.authStatus,
        linkRoute: state.linkRoute,
        landingSettings: state.news.landingSettings,
        actionSource: state.news.actionSource
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthStatus))