import React from 'react';
import { withRouter } from 'react-router';
import Menu from './Menu.js';
import AdminMenu from './AdminMenu.js';
import * as Enum from 'client/components/Common/Enum';
import { getDeviceType } from 'client/components/Common/Utility'
import { Box } from '@mui/material';
import { withStyles } from '@mui/styles';
import { leftPanelTheme } from 'client/components/LeftPanel/theme/leftPanelTheme'
const deviceType = getDeviceType();
class Nav extends React.Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.state = { activeMenuKey: '' };
    }

    render() {
        return (
            
            this.props.isAdmin ? <AdminMenu in={true} items={this.props.navData} handleClick={this.handleClick} /> :
                <Box className={this.props.classes.leftPanelColor} style={{ paddingBottom: "200px" }}><Menu in={true} items={this.props.navData} handleClick={this.handleClick} activeMenuKey={this.state.activeMenuKey} /></Box>
            
        );
    }

    handleClick(item) {
        if (item.route) {
            const selectedMenuKey = item.key;
            this.setState({ activeMenuKey: selectedMenuKey }, function () {
                if (deviceType === Enum.DeviceType.Mobile) {
                    this.props.resetMargin(true);
                }
                this.props.history.push(item.route);
            })
            
        }
    }

}

export default withRouter((withStyles(leftPanelTheme)(Nav)));
