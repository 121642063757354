import { DateTime } from 'luxon';

import { getRandomLastName, getRandomEmailAddress, getRandomCompany, getRandom, getRandomFromArray, getRandomPhone, 
    getRandomZipCode, getRandomAddress, getRandomCity } from 'admin/mock/mockData';

import profilePic from 'admin/assets/images/temp/profile-pic.jpg';

import { sortRoles } from 'admin/actions/userActions';

const roleNames = [ 'Admin', 'Customer', 'Employee', 'User', 'Backup', 'Operator', 'Reader', 'ReaderWriter', 'Super' ];

/**
 * represents back end store
 */
let mockData = {
    users: [],
    roles: [],
};

/**
 * gets or generates users and returns all
 */
const mockGetUsers = () => {
    console.log( 'mockGetUsers' );
    if ( mockData.users.length === 0 ) {
        let users = generateUsers();
        mockData.users = users;
    }
    return {
        data: [...mockData.users],
    };
};

/**
 * Updates the mock back end store
 * @param {User} user 
 */
const mockUpdateUser = ( user ) => {
    let updateUser = {
        ...user,
    };
    let users = mockData.users;
    let index = users.findIndex( elem => elem.userId === updateUser.userId );
    // keep updated version in "back-end store"
    if ( index >= 0 ) {
        users[ index ] = updateUser;
    }
    else {
        updateUser.id = users.length + 1;
        users.push( updateUser );
    }
    return {
        data: updateUser,
    };
};

/**
 * Finds the mock back end user and returns
 * @param {int} id 
 */
const mockGetUser = ( id ) => {
    let user = null;
    let users = mockData.users;
    let index = users.findIndex( elem => elem.userId === id );
    if ( index >= 0 ) {
        user = users[ index ];
    }
    let result = {
        data: user,
    };
    console.log( "mockGetUser - id: ", id, ', result: ', result );
    return result;
}

/**
 * Utility function to generator mock users
 * @param {int} num 
 */
const generateUsers = ( num = 1234 ) => {
    console.log( 'generateUsers', num );
    let users = [];
    for (let i = 0; i < num; i++) {
        let year = 2020;
        let month1 = getRandom( 0, 12 );
        let max = [ 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];
        let day1 = getRandom( 1, max[ month1 ] + 1 );
        let month2 = getRandom( 0, 12 );
        let day2 = getRandom( 1, max[ month2 ] + 1 );
        let lastName = getRandomLastName();
        let dt1 = DateTime.local( year, month1 + 1, day1 )
        let dt2 = DateTime.local( year, month2 + 1, day2 );
        let city = getRandomCity();
        users.push({
            userId: i + 1,
            email: getRandomEmailAddress( lastName, i ),
            firstName: 'John',
            lastName: lastName,
            companyName: getRandomCompany(),
            createDateTime: dt1,
            lastUpdateDateTime: dt2,
            hasAdmin: getRandom( 0, 100 ) < 33,
            isActive: getRandom( 0, 100 ) < 33,
            roles: getRandomUserRoles(),
            profilePicUrl: profilePic, 
            phone: getRandomPhone(),
            extension: getRandom( 1000, 9999 ),
            fax: getRandomPhone(),
            address1: getRandomAddress(),
            address2: 'Suite 123',
            city: city.City,
            state: city.State,
            zip: getRandomZipCode(),
            country: 'USA',
            password: '',
            password2: '',
        });
    }
    return users;
};

/**
 * mock get roles method
 */
const mockGetRoles = () => {
    if ( mockData.roles.length === 0 ) {
        let i = 1;
        mockData.roles = roleNames.map( roleName => {
            return {
                roleId: i++,
                roleName,
                isSystemRole: getRandom( 0, 100 ) < 33,
            };
        });
        sortRoles( mockData.roles );
    }
    return {
        data: [...mockData.roles],
    };
};

/**
 * gets random roles for a user
 */
const getRandomUserRoles = () => {
    let roles = mockGetRoles().data;
    let randomRoles = [];
    let numRoles = getRandom( 1, roles.length );
    while ( numRoles > 0 ) {
        let role = getRandomFromArray( roles );
        if ( randomRoles.findIndex( elem => elem.roleId === role.roleId ) < 0 ) {
            randomRoles.push( role );
            numRoles--;
        }
    }
    return sortRoles( randomRoles );
};

export { mockGetUsers, mockUpdateUser, mockGetUser, mockGetRoles };