import { ActionTypes as types } from './actionTypes';
import api from 'admin/api/adminApi';
import { dashboardSetupSchema } from 'admin/components/section/dashboard/validationSchema';
import { validateObject, validateObjectAt } from 'admin/actions/validationAction';
import { showError, showSuccess, hideError } from './alertActions';
import { updateCategoryList } from './dataViewActions';
import { getError } from './errorActions';
import cloneDeep from 'lodash/cloneDeep';

const apiGetDashboards = () => {
    return api.getDashboards();
};
const apiGetDashboard = (id) => {
    return api.getDashboard(id);
};
const apiDeleteDashboard = (id) => {
    return api.deleteDashboard(id);
};

const apiUpdateDashboard = (dashboard) => {
    return api.updateDashboard(dashboard);
}

const apiGetDashboardItemListByRoleList = (roleList) => {
    return api.getDashboardItemsByRoleList(roleList);
}
const getDashboardsRequest = () => {
    return {
        type: types.VGO_GET_DASHBOARDS_REQUEST
    }
};


const getDashboardItemListByRoleListRequest = () => {
    return {
        type: types.VGO_GET_DASHBOARDITEMLIST_REQUEST
    }
};
const getDashboardItemListByRoleListSuccess = (resp) => {
    return {
        type: types.VGO_GET_DASHBOARDITEMLIST_SUCCESS,
        dashboardList: resp.data,
    }
};

const getDashboardsSuccess = (resp) => {
    return {
        type: types.VGO_GET_DASHBOARDS_SUCCESS,
        dashboards: resp.data
    };
};

const getDashboardRequest = () => {
    return {
        type: types.VGO_GET_DASHBOARD_REQUEST
    }
};
const getDashboardSuccess = (data) => {
    return {
        type: types.VGO_GET_DASHBOARD_SUCCESS,
        dashboard: data
    };
};

const deleteDashboardRequest = (id) => {
    return {
        type: types.VGO_DELETE_DASHBOARD_REQUEST,
        dashboardId: id
    };
};
const deleteDashboardSuccess = (id) => {
    return {
        type: types.VGO_DELETE_DASHBOARD_SUCCESS,
        dashboardId: id
    };
};
export const getChangeDashboard = (change,errors) => {
    return {
        type: types.VGO_CHANGE_DASHBOARD,
        change,
        errors: errors,
    };
}
const setValidateFieldsOnBlur = (errors) => {
    return {
        type: types.VGO_VALIDATE_DASHBOARD_SETUP_ONBLUR,
        errors: errors,
    };
};

const getDashboardSaveRequest = () => {

    return {
        type: types.VGO_UPDATE_DASHBOARD_REQUEST
    }
};
const getDashboardSaveSuccess = (data) => {

    return {
        type: types.VGO_UPDATE_DASHBOARD_SUCCESS,
        dashboard: data,
    }
};

export const candeleteDashBoard = (id) => {
    return api.canDeleteDashboard(id);
}
export const setPanels = (panels, panelObjects, panel, addPanel) => {
    return {
        type: types.VGO_SET_PANEL_DASHBOARD,
        panelSettings: { panels, panelObjects, panel, addPanel },
    }
}
export const updatePanels = (changedPanels) => {
    return {
        type: types.VGO_SETUI_PANEL_DASHBOARD,
        panelSettings: { panels:changedPanels},
    }
}
export const updatePanelsWithObjectList = (changedPanelList,panelObjectList) => {
    return {
        type: types.VGO_SETUI_PANELWITHOBJECT_DASHBOARD,
        panelSettings: { panelList: changedPanelList, panelObjectList: panelObjectList },
    }
}
export const newDashboard = () => {
    return {
        type: types.VGO_NEW_DASHBOARD,
    }
}
export const getDashboards = () => {
    return dispatch => {
        dispatch(getDashboardsRequest());
        return apiGetDashboards()
            .then((resp) => {
                dispatch(getDashboardsSuccess(resp));
                hideError(dispatch);
            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
            });
    }
};
export const getDashboard = (id) => {
    return dispatch => {
        dispatch(getDashboardRequest());
        return apiGetDashboard(id)
            .then((resp) => {
               
                dispatch(getDashboardSuccess(resp.data));
                hideError(dispatch);
            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
            });
    }
};
export const updateDashboard = (dashboard) => {
    return dispatch => {
        validateObject(dashboardSetupSchema, dashboard)
            .then(resp => {
                if (resp !== true) {
                    dispatch(setValidateFieldsOnBlur(resp));
                    showError(dispatch, 'Please correct the indicated items', 5000);
                }
                else if (dashboard.panelList.length === 0) {
                    showError(dispatch, 'Please add panel and proceed', 5000);
                }
                else {

                   
                    dispatch(getDashboardSaveRequest());
                    return apiUpdateDashboard(dashboard)
                        .then((resp) => {
                            
                            dispatch(getDashboardSaveSuccess(resp.data));
                            showSuccess(dispatch, 'Success', 'Dashboard saved!', 5000,'dashboard');
                            if (dashboard.category.categoryId === 0) {
                                dispatch(updateCategoryList(resp.data.category));
                            }
                        })
                        .catch(err => {
                            let { message } = err;
                            if (err.response && err.response.data) {
                                message = err.response.data;
                                if (typeof (message) !== "string") {
                                    if (message.title && typeof (message.title) === "string") {
                                        message = message.title;
                                    }
                                    else {
                                        message = err.message;
                                    }
                                }
                            }
                            dispatch(getError(message));
                            showError(dispatch, message, 5000);
                        });
                }

            })
    };

};
export const deleteDashboard = (id) => {
    return dispatch => {
        dispatch(deleteDashboardRequest(id));
        return apiDeleteDashboard(id)
            .then((resp) => {
                dispatch(deleteDashboardSuccess(id));
                showSuccess(dispatch, 'Success', 'Dashboard deleted!', 5000,'', 'dashboard');
            })
            .catch((err) => {
                const errorMessage = err.response.data;
                dispatch(getError(errorMessage));
                showError(dispatch, errorMessage, 5000);
            });
    };
};

export const getDashboardItemListByRoleList = (roleList) => {
    return dispatch => {
        if (roleList) {
            dispatch(getDashboardItemListByRoleListRequest());
            return apiGetDashboardItemListByRoleList(roleList)
                .then((resp) => {
                    dispatch(getDashboardItemListByRoleListSuccess({ data: { roles: roleList, items: resp.data, actionStatus: types.VGO_GET_DASHBOARDITEMLIST_SUCCESS} }));
                    hideError(dispatch);
                })
                .catch(err => {
                    //const { message } = err;
                    const errorMessage = 'Dashboard item list  loading failed';
                    dispatch(getError(errorMessage));
                    showError(dispatch, errorMessage, 5000);
                });
        }
        else {
            dispatch(getDashboardItemListByRoleListSuccess({ data: { roles: roleList, dashboardItems: [], actionStatus:'' } }));
        }

    }
};

export const getNewDashboard = () => {
    return  {
        id: 0,
        name: "",
        title: "",
        description: "",
        userInstruction:"",
        roles: [],
        category: { categoryId: 0, categoryName: '' },
        isPublic: false,
        panelList:[],

    }
}
export const removeDashboardFromList = (dashboards,dashboardId) => {
    
    
    const newDashboardList = dashboards.filter((d) => d.dashboardId !== dashboardId);
    
    return newDashboardList;
}
export const modifyDashboardList = (dashboards, dashboard) => {
    let dashboardIndex = dashboards.findIndex(d => d.dashboardId === dashboard.id);
    if (dashboardIndex >= 0) {
        dashboards[dashboardIndex] = dashboard;
    }
    else {
        dashboards.push(dashboard);   
    }
    return dashboards;
}
export const validateFieldsOnBlur = (value, fieldPath,) => {
    return dispatch => {
        validateObjectAt(dashboardSetupSchema, value, fieldPath, fieldPath)
            .then(resp => {

                dispatch(setValidateFieldsOnBlur(resp));
            })

    }
}

export const setPanelObjectList = (dashboard) => {
    const panels = dashboard.panelList.map((panel) => {
        return { id: panel.id, frameObject: panel.dashboardItem };
    })

    return panels;
}

export const setPanelUI = (panels, curPanel, add, panelObjectList) => {
    let newPanelSettings = { panels: [], panelObjectList: [] };

    if (add) {
        curPanel.index = 0;
        if (panels.length === 0) {
            newPanelSettings.panels = [curPanel];
            newPanelSettings.panelObjectList = panelObjectList;
            return newPanelSettings;
        }
        else {
            let newTempPanelList = [];
            let sortedPanels = panels.sort((a, b) => a.row - b.row || a.col - b.col);
            const hasFirstCell = sortedPanels.some(panel => (panel.row === 0 && panel.col === 0 && panel.sizeX !== 2))
            const hasSecondCell = sortedPanels.some(panel => (panel.row === 0 && panel.col === 1))
            let rowInserted = false;
            if (hasFirstCell && hasSecondCell) {
                let emptySpaceUsed = false;
                let lastModifiedPanel = {}
                let rowIncrement = 1;
                let newPanelList = sortedPanels.map((panel, index) => {
                    let modifiedPanel = { ...panel }
                    if (!emptySpaceUsed) {
                        if (!rowInserted) {
                            if (panel.col === 0 && panel.sizeX !== 2) {
                                const verticalPanel = newTempPanelList.filter(p => p.row == (panel.row - 1) && p.col === 1 && p.sizeY === 2)
                                if (verticalPanel.length > 0) {
                                    modifiedPanel.row = panel.row + 1;
                                    modifiedPanel.col = 0;
                                    rowInserted = true;
                                }
                                else {
                                    modifiedPanel.col = 1;
                                    const nextPanel = sortedPanels[index + 1];
                                    const previousPanel = sortedPanels[index - 1];
                                    if (nextPanel && panel.row !== nextPanel.row && previousPanel && previousPanel.sizeY !== 2 && previousPanel.col === 1) {
                                        emptySpaceUsed = true;
                                    }
                                }

                            }
                            else if (panel.col === 0 && panel.sizeX === 2) {
                                if (lastModifiedPanel.col === 0) {
                                    modifiedPanel.row = panel.row + 1;
                                    rowInserted = true;

                                }
                                else {
                                    const verticalPanel = newTempPanelList.filter(p => p.row == (panel.row - 1) && p.col === 0 && p.sizeY === 2)
                                    if (verticalPanel.length > 0) {
                                        modifiedPanel.row = panel.row + 1;
                                        rowInserted = true;
                                        rowIncrement = 1;
                                    }
                                }
                            }
                            else if (panel.col === 1) {
                                const verticalPanel = newTempPanelList.filter(p => p.row == (panel.row - 1) && p.col === 1 && p.sizeY === 2)
                                if (verticalPanel.length > 0) {
                                    modifiedPanel.row = panel.row + 1;
                                    modifiedPanel.col = 0;
                                    rowInserted = true;
                                }
                                else if (lastModifiedPanel.col === 1 && lastModifiedPanel.row === panel.row) {
                                    modifiedPanel.col = 0;
                                    modifiedPanel.row = panel.row + 1;
                                }
                            }
                        }
                        else {
                            modifiedPanel.row = panel.row + rowIncrement;
                        }


                    }
                    modifiedPanel.index = index + 1;
                    lastModifiedPanel = { ...modifiedPanel }
                    newTempPanelList.push(modifiedPanel)
                    return modifiedPanel;
                })


                newPanelList.unshift(curPanel);
                newPanelSettings.panels = newPanelList
            }
            else if (hasFirstCell) {
                let newPanelList = sortedPanels.map((panel, index) => {
                    let modifiedPanel = { ...panel }
                    if (panel.row === 0 && panel.col === 0) {
                        modifiedPanel.col = 1;
                    }
                    modifiedPanel.index = index + 1;
                    return modifiedPanel;
                })

                newPanelList.unshift(curPanel);
                newPanelSettings.panels = newPanelList
            }
            else if (hasSecondCell) {

                let newPanelList = sortedPanels.map((panel, index) => {
                    let modifiedPanel = { ...panel }
                    modifiedPanel.index = index + 1;
                    return modifiedPanel;
                })
                newPanelList.unshift(curPanel)
                newPanelSettings.panels = newPanelList
            }
            else {
                const hasFirstFullRowCell = sortedPanels.some(panel => (panel.row === 0 && panel.col === 0 && panel.sizeX === 2))
                if (hasFirstFullRowCell) {
                    let newPanelList = sortedPanels.map((panel, index) => {
                        let modifiedPanel = { ...panel }
                        modifiedPanel.row = panel.row + 1;
                        modifiedPanel.index = index + 1;
                        return modifiedPanel;
                    })

                    newPanelList.unshift(curPanel);
                    newPanelSettings.panels = newPanelList
                }
            }
        }

    }


    const resetIdPanelObjectList = panelObjectList.map((panelObject) => {
        let newPanel = newPanelSettings.panels.find(panel => panel.id === panelObject.id)
        if (newPanel) {

            return { id: `Frame_${newPanel.index}`, frameObject: panelObject.frameObject };
        }
        return panelObject;
    })

    const resetIdPanels = newPanelSettings.panels.map((panel) => {
        panel.id = `Frame_${panel.index}`
        return panel;
    })
    newPanelSettings.panelObjectList = resetIdPanelObjectList;
    newPanelSettings.panels = resetIdPanels;
    return newPanelSettings;
}

export const updatePanelLayout = (panels, changedPanels) => {
    let hasInvalidPanelStructure = false;
    
    const changedPanelList = panels.map((panel) => {
        let newPanel = {...panel}
        const resizedPanel = changedPanels.find(changedPanel => changedPanel.id === panel.id)

        
        if (resizedPanel) {
            const duplicateRowColumnCount = changedPanels.filter(newPanel => newPanel.row === resizedPanel.row && newPanel.col === resizedPanel.col).length;
            if (duplicateRowColumnCount > 1) {
                hasInvalidPanelStructure = true;
            }

            newPanel.row = resizedPanel.row
            newPanel.col = resizedPanel.col > 1 ? 1 : resizedPanel.col
            newPanel.sizeX = resizedPanel.sizeX
            newPanel.sizeY = resizedPanel.sizeY
        }
        return newPanel;
    })
    return hasInvalidPanelStructure ? panels: changedPanelList;
}

export const updateDashboardWithPanel = (dashboard, panelList, panelObjectList)=>{
   const dashboardPanelList= panelList.map((panel) => {
        const dashboardItem = panelObjectList.find(listItem => listItem.id === panel.id);
        if (dashboardItem) {
            return {
                id: panel.id,
                sizeX: panel.sizeX,
                sizeY: panel.sizeY,
                maxSizeY: 2,
                maxSizeX: 2,
                row: panel.row,
                col: panel.col,
                dashboardItem: dashboardItem.frameObject,
            }
        }
   })

    let newDashboardObject = cloneDeep(dashboard)
    newDashboardObject.panelList = dashboardPanelList;
    return newDashboardObject;
}

export const updatePanelLayoutWithObjectList = (panelList, panelObjectList) => {
    let newPanelSettings = {
        panels: [],
        panelObjectList: []
    }
    panelList = panelList.sort((a, b) => a.row - b.row || a.col - b.col);
    newPanelSettings.panels= panelList.map((panel,index) => {
        const newPanel = panelObjectList.find(panelObject => panelObject.id === panel.id);
        if (newPanel) {
            newPanelSettings.panelObjectList.push( { id: `Frame_${index}`, frameObject: newPanel.frameObject });
        }
        panel.id = `Frame_${index}`
        return panel;
    })

   
    return newPanelSettings;
}
