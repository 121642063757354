import React from 'react';
import CropComponent from 'react-easy-crop';
import { Slider, Box, Grid } from '@mui/material';

import FolderIcon from '@mui/icons-material/Folder';
import CropIcon from '@mui/icons-material/Crop';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CancelIcon from '@mui/icons-material/Cancel';
import previewimg from 'client/assets/css/images/previewImg.png';
import { imageCropTheme } from 'client/components/UserProfile/theme/ImageCropTheme';
import { withStyles } from '@mui/styles';
import Fab from '@mui/material/Fab';


class ImageCrop extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            imgSrc: previewimg,
            crop: { x: 0, y: 0 },
            zoom: 1,
            aspect: 3 / 3,
            croppedImage: previewimg
        };
        this.disableCropButton = true;
        this.disableAcceptButton = true;
        this.showFileUpload = this.showFileUpload.bind(this);

    }

    createImage = url =>
        new Promise(resolve => {
            const image = new Image();
            image.addEventListener('load', () => resolve(image));
            image.setAttribute('crossOrigin', 'anonymous');
            image.src = url;
        })
    readFile = file => {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.addEventListener('load', () => resolve(reader.result), false);
            reader.readAsDataURL(file);
        });
    }
    async getCroppedImg(imageSrc, pixelCrop) {
       
        const image = await this.createImage(imageSrc);

        const canvas = document.createElement('canvas');
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height,
            0,
            0,
            pixelCrop.width,
            pixelCrop.height
        );

        // As Base64 string 
        return canvas.toDataURL('image/jpeg');

        // As a blob
        //return new Promise((resolve, reject) => {
        //	canvas.toBlob(file => {
        //		resolve(URL.createObjectURL(file))
        //	}, 'image/jpeg')
        //})
    }
    showFileUpload() {
        this.fileUpload.current.click();
    }
    onFileUpload = async e => {
        
        if (e.target.files && e.target.files.length > 0) {
           
            const imageurl = await this.readFile(e.target.files[0]);
            this.disableCropButton = false;
            this.setState({ imgSrc: imageurl });
        }
    }
    onCropChange = crop => {
        this.setState({ crop });
    }
    onCropComplete = (cropArea, cropAreaPixels) => {
       this.setState({ cropAreaPixels });

    }
    onZoomChange = zoom => {
        this.setState({ zoom });
    }
    acceptCrop = async () => {
        const croppedImage = await this.getCroppedImg(
            this.state.imgSrc,
            this.state.cropAreaPixels
        );
        this.disableAcceptButton = false;
        this.setState({ croppedImage });
    }
    returnCrop = (accept) => {
        this.props.actionresult(accept, this.state.croppedImage);
    }
   
    render() {
        const { classes } = this.props;

        return (
            <Box className={ classes.cropApp}>
                <Grid className={ classes.cropContainer} >
                    <CropComponent
                        image={this.state.imgSrc}
                        crop={this.state.crop}
                        zoom={this.state.zoom}
                        aspect={this.state.aspect}
                        onCropChange={this.onCropChange}
                        onCropComplete={this.onCropComplete}
                        onZoomChange={this.onZoomChange}
                    />
                </Grid>
                <Grid className={ classes.cropControls }>
                    <Grid item xs={12} className={ classes.cropControlSlider}>
                        <Slider
                            value={this.state.zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            aria-labelledby="Zoom"
                            onChange={(e, zoom) => this.onZoomChange(zoom)}
                            className={ classes.slider }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container item direction="row" justifyContent="space-between" xs={8} >
                            <Grid item>
                                <Fab color="default" title="Choose Image" aria-label="Upload"  onClick={(e) => this.imageChoose.click()}>
                                    <FolderIcon />
                                </Fab>
                            </Grid>
                            <Grid item>
                                <Fab color="default" title="Crop" aria-label="Crop" disabled={this.disableCropButton}  onClick={this.acceptCrop}  >
                                    <CropIcon />
                                    </Fab>
                                </Grid>
                            <Grid item>
                                <Fab color="primary" title="Accept" aria-label="Accept" disabled={this.disableAcceptButton}  onClick={(e) => this.returnCrop(true)}    >
                                    <CheckBoxIcon />
                                        </Fab>
                                    </Grid>
                            <Grid item>
                                <Fab color="secondary" title="Cancel" aria-label="Close" onClick={(e) => this.returnCrop(false)}    >
                                    <CancelIcon />
                                </Fab>
                                        </Grid>

                        </Grid>
                        <Grid item xs={4}>
                            <Box className={ classes.previewBox }>
                                <img alt="" className={classes.prevImage} title="Crop Preview" crossOrigin="anonymous" src={this.state.croppedImage} />
                        
                            </Box>
                        </Grid>
                        <input type="file" id="choosefile" onChange={this.onFileUpload} title="Choose Image" className={ classes.fileUpload} ref={(ref) => this.imageChoose = ref} />
                    </Grid>   
                </Grid>
            </Box>
        );
    } 
}
export default withStyles(imageCropTheme)(ImageCrop);
