import React from "react";
import { connect } from 'react-redux';



class DataResetProxy extends React.Component {

    render() {
        if (this.props.selectedRows && this.props.selectedRows.resetStatus === "INITIATED") {
            this.props.resetData(this.props.groupSettings, this.props.sortSettings);
        }
        return (

            <span></span>

        );

    }
}

export default connect(
    state => state.dataView,
)(DataResetProxy);