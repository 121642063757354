import React from "react";
import Banner from "client/components/Banner/Banner";
import { connect } from 'react-redux';
import { Loading } from 'client/components/Loading';
import { Box } from '@mui/material';
import { withStyles } from '@mui/styles';
import { bannerTheme } from 'client/components/Banner/theme/bannerTheme'
class banners extends React.Component {
    renderBanners = () => {
        const { isSiteTheme, themeColor, bannerList } = (this.props.siteTheme) ? this.props.siteTheme : '';
        const banners = (isSiteTheme) ? bannerList : this.props.banners;
        const bannerItems = banners.map((banner) => {
            return (
                <Banner key={banner.id} banner={banner} isSiteTheme={isSiteTheme} themeColor={themeColor}></Banner>
            );
        });
        return bannerItems;
    }
    render() {
        const { classes } = this.props;
        return (
            <Box className={classes.bannerContainer }>
                {!this.props.siteTheme && <Loading loading={this.props.isLoading} />}
                {this.renderBanners()}
            </Box>
        );
    }
}

export default connect(state => state.banners)(withStyles(bannerTheme)(banners));