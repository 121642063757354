import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default function TableFieldName(props) {
    const [fieldName, setFieldName] = useState(props.fieldName);
    const handleChange = (event) => {
        setFieldName(event.target.value);
    };
    const handleBlur = (event) => {
        if (props.fieldName !== event.target.value) {
            props.updateSource(props.dataIndex,event.target.value,props.type)
        }
    };

    useEffect(() => {
        setFieldName(props.fieldName)
    }, [props.fieldName])

    return (
        <Box>
            <TextField
                id={props.id}
                disabled={props.disabled}
                inputProps={{ maxLength: 50 }}
                value={fieldName}
                onChange={handleChange}
                onBlur={handleBlur}
            />
        </Box>
    );
}