const setSelectedRows = 'SET_SELECTED_ROWS_DATAVIEW';
const setDataResetStatus = 'SET_DATARESET_STATUS_SELECTED_ROWS_DATAVIEW';



const initialState = { selectedRows: { rows: [], sum:0 } };

export const dataViewActionCreator = {
    setRows: (selectedRows) => async (dispatch, getState) => {

        dispatch({ type: setSelectedRows,selectedRows });

    },
    setDataResetStatus: (resetStatus) => async (dispatch, getState) => {

        dispatch({ type: setDataResetStatus, status: resetStatus });

    }

};

export const reducer = (state, action) => {
    state = state || initialState;
    if (action.type === setSelectedRows) {
        return {
            ...state,
            selectedRows: action.selectedRows,
        };
    }
    else if (action.type === setDataResetStatus) {
        return {
            ...state,
            selectedRows: { resetStatus: action.status, rows: [], sum: 0},
        };
    }
    return state;
};