import { ActionTypes as types } from './actionTypes';
import adminApi from 'admin/api/adminApi';
import mockApi from 'admin/api/mockApi';
import { DateTime } from 'luxon';
import { showError, showSuccess, hideError } from './alertActions';
import settings from 'settings.js';
import { validateField, validateObject } from 'admin/actions/validationAction';
import { filterSetupSchema } from 'admin/components/section/filter/validationSchema';

/**
 * reads mock data setting from settings
 */
const useMocks =  settings.useMocks;

/**
 * api in use, mock or real
 */
const api = useMocks ? mockApi : adminApi;

/**
 * Invokes the mock or real web api to get all filters
 */
const apiGetFilters = () => {
    
    return api.getFilters();
};

/**
 * Get all filters success action creator
 * @param {Response} resp 
 */
const getFiltersSuccess = (resp) => {
    return {
        type: types.VGO_GET_FILTERS_SUCCESS,
        filters: resp.data
    };
};
/**
 *Filters Request  action creator 
 */
const getFiltersRequest = () => {

    return {
        type: types.VGO_GET_FILTERS_REQUEST
    }
}; 

/**
 * Invokes the mock or real web api to get all database source
 */ 
const apiGetDatabaseList = () => {
   
    return api.getDatabaseList();
};
/***
 * Data Base Source get Request
 */

const getDataBaseSourceRequest = () => {
  
    return {
        type: types.VGO_GET_FILTERS_DATABASE_SOURCE_REQUEST
    }
}; 
/**
 * Data Base Source List get request success
 * @param {any} resp
 */
const getDataBaseSourceListSuccess = (resp) => {
   
    return {
        type: types.VGO_GET_FILTERS_DATABASE_SOURCE_SUCCESS,
        databases:resp.data
    }
}; 


/**
 * Invokes the mock or real web api to get all datasourceList
 */ 
const apiGetDataSourceList = (companyid) => {
   
    return api.getDataSourceList(companyid);
};

/***
 *Data Source request call
 */
const getDataSourceRequest = () => {
   
    return {
        type: types.VGO_GET_FILTERS_DATASOURCE_REQUEST
    }
};
/**
 * Data Source request call success
 * @param {any} resp
 */
const getDataSourceListSuccess = (resp) => {

    return {
        type: types.VGO_GET_FILTERS_DATASOURCE_SUCCESS,
        datasources: resp.data.value
    }
}; 

/**
 * Invokes the mock or real web api to get all datafieldsList
 * @param {any} companyid
 * @param {any} dataSource
 */
const apiGetDataFieldsList = (companyid,dataSource) => {

    return api.getFieldsList(companyid, dataSource);
};

/**
 * Invokes the mock or real web api to update / save the filter
 * @param {any} filter
 */
const apiUpdateFilter= (filter) => {
  
    return api.updateFilter(filter)
}
/**
 * Invokes the mock or real web api to get all filter
 * @param {any} id
 */
const apiGetFilter = (id) => {
   
    return api.getFilter(id)
}
/**
 * Invokes the mock or real web api to delete filter
 * @param {any} id
 */
const apiDeleteFilter = (id) => {
   
    return api.deleteFilter(id)
}
/**
 * Data Fields request call
 */
const getDataFieldsRequest = () => {
   
    return {
        type: types.VGO_GET_FILTERS_FIELDS_SOURCE_REQUEST
    }
};
/**
 * Data Fields List request call success
 * @param {any} respgetDataSourceList
 */
const getDataFieldsListSuccess = (fieldList) => {
   
    return {
        type: types.VGO_GET_FILTERS_FIELDS_SOURCE_SUCCESS,
        fields: fieldList
    }
};

/***
 *Filter request call
 */
const getFilterRequest = () => {
    
    return {
        type: types.VGO_GET_FILTER_REQUEST
    }
};
/**
 * Filter Request success call
 * @param {any} resp
 */
const getFilterSuccess = (resp) => {
   
    return {
        type: types.VGO_GET_FILTER_SUCCESS,
        filter: resp.data
    }
};
/**
 * Change filter request call
 * @param {any} change
 */
export const getChangeFilter = (change) => {
    return {
        type: types.VGO_CHANGE_FILTER,
        change
    };
}
/**
 * New filter request call
 */
const getFilterNewRequest = () => {
  
    return {
        type: types.VGO_NEW_FILTER
    }
};
 
/***
 * Save Filter request call
 */
const getFilterSaveRequest = () => {
 
    return {
        type: types.VGO_UPDATE_FILTER_REQUEST
    }
};
/**
 * Save Filter Success
 * @param {any} resp
 */
const getFilterSaveSuccess = (resp) => {
     
    return {
        type: types.VGO_UPDATE_FILTER_SUCCESS,
        filter: resp.data
    }
};

/***
 * Filter delete request call
 */
const getFilterDeleteRequest = () => {
    
    return {
        type: types.VGO_DELETE_FILTER_REQUEST
    }
};
/**
 * Delete filter success
 * @param {any} resp
 */
const getFilterDeleteSuccess = (resp) => {
  
    return {
        type: types.VGO_DELETE_FILTER_SUCCESS,
        filters: resp.data
    }
};


/**
 * Error action creator
 * @param {*} errorMessage 
 */
const getError = (errorMessage) => {
    return {
        type: types.VGO_API_CALL_ERROR,
        hasError: true,
        errorMessage
    };
};
/**
 * Get filters list dispatch
 */
export const getFilters = () => {
   
    return dispatch => {
        dispatch(getFiltersRequest());
        return apiGetFilters()
            .then(resp => {
                hideError(dispatch);
                dispatch(getFiltersSuccess(resp));
            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
            })
    };
};
/**
 * Get Filter dispatch
 * @param {any} id
 */
export const getFilter = (id) => {
   
    return dispatch => {
        dispatch(getFilterRequest());
        return apiGetFilter(id)
            .then(resp => {
                
                hideError(dispatch);
                dispatch(getFilterSuccess(resp));
            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
            })
    };
};
/**
 * Get new filter dispatch
 */
export const getNewFilter = () => {
   
        return dispatch => {
            dispatch(getFilterNewRequest());
        }; 
};
/**
 * Get database Source list dispatch
 */
export const getDataBaseSourceList = () => {
    
    return dispatch => {
        dispatch(getDataBaseSourceRequest());
        return apiGetDatabaseList()
            .then(resp => {
                dispatch(getDataBaseSourceListSuccess(resp));
            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
            })
    };
};
/**
 *Get DataSource List dispatch
 * @param {any} companyId
 */
export const getDataSourceList = (companyId) => {
   
    return dispatch => {
        dispatch(getDataSourceRequest());
        return apiGetDataSourceList(companyId)
            .then(resp => {
                dispatch(getDataSourceListSuccess(resp));
            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
            })
    };
};
/**
 * Get Data Fields List dispatch
 * @param {any} companyId
 * @param {any} dataSource
 */
export const getDataFieldsList = (companyId,dataSource) => {
   
    return dispatch => {
        dispatch(getDataFieldsRequest());
        return apiGetDataFieldsList(companyId,dataSource)
            .then(resp => {
                dispatch(getDataFieldsListSuccess(resp.data.value));
            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
                dispatch(getDataFieldsListSuccess([]));
            })
    };
};
/**
 * Update/Insert Filter dispatch
 * @param {any} filter
 */
export const updateFilter = (filter) => {
    return dispatch => {
        validateObject(filterSetupSchema, filter)
            .then(resp => {
                if (resp !== true) {
                    dispatch(setFieldValidate(resp));
                    showError(dispatch, 'Please correct the indicated items', 5000);
                } else { 
                    dispatch(getFilterSaveRequest(filter));
                    return apiUpdateFilter(filter)
                        .then((resp) => {
                            dispatch(getFilterSaveSuccess(resp));
                            showSuccess(dispatch, 'Success', 'Filter saved!', 5000);
                        })
                        .catch(err => {
                            let { message } = err;
                            if (err.response && err.response.data) {
                                message = err.response.data;
                                if (typeof (message) !== "string") {
                                    if (message.title && typeof (message.title) === "string") {
                                        message = message.title;
                                    }
                                    else {
                                        message = err.message;
                                    }
                                }
                            }
                            dispatch(getError(message));
                            showError(dispatch, message, 5000);
                        });
                }

                })
    };
    
};
/**
 * Delete filter dispatch
 * @param {any} id
 */
export const deleteFilter = (id) => {
   
    return dispatch => {
        dispatch(getFilterDeleteRequest());
        return apiDeleteFilter(id)
            .then(resp => {
                dispatch(getFilterDeleteSuccess(resp));
                showSuccess(dispatch, 'Success', 'Filter deleted successfully', 5000);
            })
            .catch(err => {
                let { message } = err;
                if (err.response && err.response.data) {
                    message = err.response.data;
                    if (typeof (message) !== "string") {
                        if (message.title && typeof (message.title) === "string") {
                            message = message.title;
                        }
                        else {
                            message = err.message;
                        }
                    }
                }
                dispatch(getError(message));
                showError(dispatch, message, 5000);
            })
    };
};
/**
 * New Filter constant
 */
export const newFilter = () => {
    let filter = {
        "profileId": 0,
        "profileName": "",
        "createdUserId": 0,
        "createdDateTime": DateTime.local(),
        "updatedUserId": 0,
        "updatedDateTime": DateTime.local(),
        "remarks": "",
        "sourceTableName": "",
        "company": {
            "companyId": 0,
            "companyName":""
        },
        "filterDetails": {
            "profileId": 0,
            "fieldName": "",
            "fieldDisplayName": "",
            "isText": true,
            "profileFieldIdentifier": "",
            "id": 0
        }
    }
    return filter;
};

export const validateOnBlur = (fieldName, value, fieldPath, dependencyFields) => {
    return dispatch => {
        validateField(filterSetupSchema, fieldName, value, fieldPath, dependencyFields)
            .then(resp => {

                dispatch(setFieldValidate(resp));
            }) 
    }
} 
const setFieldValidate = (errors) => {
    return {
        type: types.VGO_VALIDATE_FIELD_ONBLUR,
        errors: errors,
    };
};
 
