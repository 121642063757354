 
﻿import { customThemeStyles } from 'common/theme.js';
export const menuListTheme = (theme) => ({
    ...customThemeStyles,

    deleteColumn: { width: "60px!important" },
    nameColumn: { width: "220px!important", wordBreak: "break-all!important" },
    descriptionColumn: { width: "330px!important", wordBreak: "break-all!important" },
    dateColumn: { width: "220px!important" },
    addButton: {

        maxWidth: '185px!important',
        maxHeight: '36px!important'
    },

    MUIDataTablePadding: {
        paddingTop: '10px!important',

    },

    root: {
        justifyContent: 'center!important',
        flexWrap: 'wrap!important',
        height: "93px!important",
        borderRadius: "4px!important"
    },
    card: {
        //width: "724px",
        height: "100%!important",
        marginLeft: theme.spacing(2) + '!important',
        marginRight: theme.spacing(2) + '!important'
    },
    chip: {
        margin: theme.spacing(0.5) + '!important'
    },
    gridColumn: { wordBreak: "break-all" },

    subGrid: {
        //maxWidth: '640px!important'

    },
    mainGridItem: {
        maxWidth: '696px!important'

    },


    gridPadding: {

        paddingBottom: '8px!important'
    }
    ,
    paddingEmpty: {

        paddingBottom: '0px!important',
        paddingTop: '0px!important'
    },

    enablePaging: {

        paddingTop: '0px!important'
    },
    panelInnerFirst: {
        position: "relative",
        height: "100%"
    },
    panelInnerSecond: {
        /* Center vertically and horizontally */
        position: "absolute",
        top: "50%",
        left: "50%",
        margin: "-25px 0 0 - 25px",
    },
    addPanelButton: {
        width: '92px!important',
        height: '32px!important',
 
﻿ 
        letterSpacing: "0.4px!important",

    },
    content: {
        backgroundColor: theme.palette.common.white + '!important',
    },

    //content: {
    //    border: "1px solid rgba(0, 0, 0, 0.1)",
    //    backgroundColor: theme.palette.common.white + '!important',
    //    shadowRadius: 10,
    //    elevation: 9,
    //    marginLeft: 5,
    //    marginRight: 5,
    //    marginTop: 15,
    //    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.2), 0px 1px 3px rgba(0, 0, 0, 0.1)',
    //    color: theme.palette.text.secondary,
    //    borderRadius: theme.spacing(0.7),
    //},
    group: {
        marginLeft: "42px !important",
        //'& $content': {
        //    paddingLeft: theme.spacing(2),
        //},
    },
    expanded: {},
    selected: {},
    label: {
        fontWeight: 'inherit',
        color: 'inherit',
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0),
    },
    labelText: {
        fontWeight: 'inherit',
        flexGrow: 1,
        display: 'inline-block',
    },
    contentTypeText: {
        fontWeight: 'inherit',
        flexGrow: 1,
        display: 'inline-block',
        width: '135px',
        marginLeft: '10px',
    },
    contentSubTypeText: {
        fontWeight: 'inherit',
        flexGrow: 1,
        textAlign: 'right',
        display: 'inline-block',
        width: '103px',
    },
    span: {
        fontWeight: 'inherit',
        flexGrow: 1,
        display: 'none',
    },
    deleteIcon: {
        marginRight: theme.spacing(1),
    },


    menuBox: {
        border: "1px solid rgba(0, 0, 0, 0.1)",
        marginLeft: "-24px",
        height: "31px",
        width: "585px",
    },
    menuDetails: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginLeft: "-16px",
        marginTop: "3px",
    },
    AddThreeDots: {
        display: "inline-block",
        width: "176px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        padding: "5px",
    },
    deleteIcon: {
        marginRight: theme.spacing(1),
    },
})
 
