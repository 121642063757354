    import { createTheme, adaptV4Theme } from '@mui/material/styles';
import { green, red, blue, yellow, white, grey } from '@mui/material/colors';
import createBreakpoints from "@mui/system/createTheme/createBreakpoints";

// Make and object for the available breakpoints
const breakpoints = createBreakpoints({});

// TODO: really only need to specify the overrides here 
export const mainTheme = {
    breakpoints: {
        keys: ["xs", "sm", "md", "lg", "xl"],
        values: {
            xs: 0,
            sm: 769,
            md: 1024,
            lg: 1201,
            xl: 1920,
                       
        },
    },
    direction: "ltr",
    mixins: {
        toolbar: {
            minHeight: 56,
            "@media (minWidth:0px) and (orientation: landscape)": {
                minHeight: 48,
            },
            "@media (minWidth:600px)": {
                minHeight: 64,
            },
        },
    },
    overrides: {},
    palette: {
        common: {
            black: "#000",
            white: "#fff",
        },
        type: "light",
        primary: {
            light: "#2196F3",
            main: "#2196F3",
            dark: "#2196F3",
            contrastText: "#fff",
        },
        secondary: {
            main: "#E10050",
            dark: "#E10050",
            contrastText: "#fff",
        },
        error: {
            light: "#e57373",
            main: "#f44336",
            dark: "#d32f2f",
           
            contrastText: "#fff",
        },
        warning: {
            light: "#ffb74d",
            main: "#ff9800",
            dark: "#f57c00",
            contrastText: "rgba(0, 0, 0, 0.87)",
        },
        info: {
            light: "#64b5f6",
            main: "#2196f3",
            dark: "#1976d2",
            contrastText: "#fff",
        },
        success: {
            light: "#81c784",
            main: "#4caf50",
            dark: "#388e3c",
            contrastText: "rgba(0, 0, 0, 0.87)",
        },
        //defaultContained: {
        //    main: "#e0e0e0",
        //    dark: "#c8c8c8",
        //    contrastText: "#000",
        //},
        //default: {
        //    contrastText: "#000",
        //    main: "#000",
        //    dark: "#fff",
        //},
        grey: {
            50: "#fafafa",
            100: "#f5f5f5",
            200: "#eeeeee",
            300: "#e0e0e0",
            400: "#bdbdbd",
            500: "#9e9e9e",
            600: "#757575",
            700: "#616161",
            800: "#424242",
            900: "#212121",
            A100: "#d5d5d5",
            A200: "#aaaaaa",
            A400: "#303030",
            A700: "#616161",
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
        text: {
            primary: "rgba(0, 0, 0, 0.87)",
            secondary: "rgba(0, 0, 0, 0.54)",
            disabled: "rgba(0, 0, 0, 0.38)",
            hint: "rgba(0, 0, 0, 0.38)",
        },
        divider: "rgba(0, 0, 0, 0.12)",
        background: {
            paper: "#fff",
            default: "#fafafa",
        },
        action: {
            active: "rgba(0, 0, 0, 0.54)",
            hover: "rgba(0, 0, 0, 0.04)",
            hoverOpacity: 0.04,
            selected: "rgba(0, 0, 0, 0.08)",
            selectedOpacity: 0.08,
            disabled: "rgba(0, 0, 0, 0.26)",
            disabledBackground: "rgba(0, 0, 0, 0.12)",
            disabledOpacity: 0.38,
            focus: "rgba(0, 0, 0, 0.12)",
            focusOpacity: 0.12,
            activatedOpacity: 0.12,
        },
    },
    props: {},
    shadows: [
        "none",
        "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
        "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
        "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
        "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
        "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
        "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
        "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
        "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
        "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
        "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
        "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
        "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
        "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
        "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
        "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
        "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
        "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
        "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
        "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
        "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
        "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
        "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
        "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)",
    ],
    typography: {
        htmlFontSize: 14,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif!important',
        fontSize: 14,
        100: 300,
        400: 400,
        200: 500,
        fontWeightBold: 700,
        h1: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif!important',
            fontWeight: '300px!important',
            fontSize: "6rem!important",
            lineHeight: '1.167px!important',
            letterSpacing: "-0.01562em!important",
        },
        h2: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 300,
            fontSize: "3.75rem",
            lineHeight: 1.2,
            letterSpacing: "-0.00833em",

        },
        h3: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 400,
            fontSize: "3rem",
            lineHeight: 1.167,
            letterSpacing: "0em",

        },
        h4: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 400,
            fontSize: "2.125rem",
            lineHeight: 1.235,
            letterSpacing: "0.00735em",

        },
        h5: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 400,
            fontSize: "1.5rem",
            lineHeight: 1.334,
            letterSpacing: "0em",

        },
        h6: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 500,
            fontSize: "1.25rem",
            lineHeight: 1.6,
            letterSpacing: "0.0075em",

        },
        subtitle1: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 400,
            fontSize: "1rem",
            lineHeight: 1.75,
            letterSpacing: "0.00938em",

        },
        subtitle2: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: 1.57,
            letterSpacing: "0.00714em",

        },
        body1: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif!important',
            fontWeight: '400px!important',
            fontSize: "1rem!important",
            lineHeight: '1.5px!important',
            letterSpacing: "0.00938em!important",

        },
        body2: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif!important',
            fontWeight: '400px!important',
            fontSize: "0.875rem!important",
            lineHeight: '1.43px!important',
            letterSpacing: "0.01071em!important",

        },
        button: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: 1.75,
            letterSpacing: "0.02857em",
            textTransform: "uppercase"
        },
        caption: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 400,
            fontSize: "0.75rem",
            lineHeight: 1.66,
            letterSpacing: "0.03333em",

        },
        overline: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 400,
            fontSize: "0.75rem",
            lineHeight: 2.66,
            letterSpacing: "0.08333em",
            textTransform: "uppercase"
        },
    },
    shape: {
        borderRadius: 4,
    },
    transitions: {
        easing: {
            easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
            easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
            easeIn: "cubic-bezier(0.4, 0, 1, 1)",
            sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
        },
        duration: {
            shortest: 150,
            shorter: 200,
            short: 250,
            standard: 300,
            complex: 375,
            enteringScreen: 225,
            leavingScreen: 195,
        },
    },
    zIndex: {
        mobileStepper: 1000,
        speedDial: 1050,
        appBar: 1100,
        drawer: 1200,
        modal: 1400,
        snackbar: 1400,
        tooltip: 1500,
    },
};
export const customizedTheme = {
    ...mainTheme,
    typography: {
        h1: {
            [breakpoints.down("xs")]: {
                fontSize: '96px',
                fontFamily: "Roboto",
                fontWeight: "100",
                fontStyle: "normal",
                letterSpacing: "-1.5px",


            },
            [breakpoints.up("md")]: {
                fontSize: '96px',
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "100",
                letterSpacing: "-1.5px",

            },
            [breakpoints.up("lg")]: {
                fontSize: '96px',
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "100",
                letterSpacing: "-1.5px",

            }
        },
        h2: {
            [breakpoints.down("xs")]: {
                fontSize: '60px',
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "100",
                letterSpacing: "-0.5px",

            },
            [breakpoints.up("md")]: {
                fontSize: '60px',
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "100",
                letterSpacing: "-0.5px",

            },
            [breakpoints.up("lg")]: {
                fontSize: '60px',
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "100",
                letterSpacing: "-0.5px",

            }
        },
        h3: {
            [breakpoints.down("xs")]: {
                fontSize: '48px',
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "400",
                letterSpacing: "0px",

            },
            [breakpoints.up("md")]: {
                fontSize: '60px',
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "100",
                letterSpacing: "0px",

            },
            [breakpoints.up("lg")]: {
                fontSize: '60px',
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "100",
                letterSpacing: "0px",

            }
        },
        h4: {
            
            [breakpoints.down("md")]: {
                fontSize: '16px',
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "500",
                letterSpacing: "0.25px",

            },
            [breakpoints.up("md")]: {
                fontSize: '34px',
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "400",
                letterSpacing: "0.25px"

            }
        },
        h5: {
            [breakpoints.down("xs")]: {
                fontSize: '24px',
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "400",
                letterSpacing: "0px",

            },
            [breakpoints.up("md")]: {
                fontSize: '24px',
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "400",
                letterSpacing: "0px",

            },
            [breakpoints.up("lg")]: {
                fontSize: '24px',
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "400",
                letterSpacing: "0px",

            }
        },
        h6: {
            [breakpoints.down("xs")]: {
                fontSize: '20px',
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "500",
                letterSpacing: "0.15px",

            },
            [breakpoints.up("md")]: {
                fontSize: '20px',
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "500",
                letterSpacing: "0.15px",

            },
            [breakpoints.up("lg")]: {
                fontSize: '20px',
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "500",
                letterSpacing: "0.15px",

            }
        },
        subtitle1: {
            fontSize: '16px',
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "400",
            letterSpacing: "0.15px",

        },
        subtitle2: {
            fontSize: '14px!important',
            fontFamily: "Roboto!important",
            fontStyle: "normal!important",
            fontWeight: "500!important",
            letterSpacing: "0.1px!important",

        },


        customPrimary: {
            fontFamily: 'Roboto!important',
            fontStyle: 'normal!important',
            fontWeight: '700!important',
            fontSize: '12px!important',
            lineHeight: '14px!important',
            alignItems: 'center!important',
            letterSpacing: '0.25em!important',
        },
        body1: {
            fontFamily: "Roboto!important",
            fontWeight: "400!important",
            fontSize: "16px",
            fontStyle: "normal!important",
            letterSpacing: "0.5px!important",

        },
        body2: {
            fontFamily: "Roboto!important",
            fontWeight: "400!important",
            fontSize: "14px!important",
            fontStyle: "normal!important",
            letterSpacing: "0.25px!important",

        },
        button: {
            fontFamily: "Roboto",
            fontWeight: "200",
            fontSize: "14px",
            fontStyle: "normal",
            letterSpacing: "1.25px",
            textTransform: "uppercase"

        },
        caption: {
            fontFamily: "Roboto",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "400",
            letterSpacing: "0.4px",

        },
        overline: {
            fontFamily: "Roboto",
            fontSize: "10px",
            fontStyle: "normal",
            fontWeight: "400",
            letterSpacing: "1.5px",
            textTransform: "uppercase"

        },

    },
    props: {
        MuiTextField: {
            variant: 'standard',
        },

        MuiSelect: {
            variant: 'standard',
        },
        MuiFormControl: {
            variant: 'standard',
        },

    },
    overrides: {
        MuiListItem: {
            dense: {
                height: "20px",
            }
        },
        MuiListItemIcon: {
            root: {
                minWidth: "0px"
            }
        },

        MuiFormControl: {
            root: {
                width: '100%'

            }
        },
        MuiFab: {
            secondary: {
                backgroundColor: mainTheme.palette.error.light,
                "&:hover": {
                    backgroundColor: mainTheme.palette.error.dark,
                }
            },
        },

        MuiTabs: {
            scroller: {
                overflowX: 'hidden!important',

            }

        },

        MuiChip: {
            root: {
                fontFamily: "Roboto!important",
                fontSize: "13px!important",
                fontStyle: "normal!important",
                fontWeight: "400!important",
                lineHeight: "15px!important",
                letterSpacing: "0px!important",
                textAlign: "left!important",
                height: "24px!important"
            },
            deleteIcon: {
                height: "20px"
            }

        },
        MuiTableCell: {
            root: {
                fontFamily: "Roboto !important",
                fontSize: "14px !important",
                fontStyle: "normal !important",
                fontWeight: "400 !important",
                lineHeight: "20px !important",
                letterSpacing: "0.25px",
                textAlign: "left!important",
                color: '#000000!important',
                paddingTop: '8px!important',
                paddingBottom: '8px!important'


            }

        },

        MUIDataTableHeadCell: {
            toolButton: {
                textTransform: 'none!important',
                fontFamily: "Roboto !important",
                fontSize: "14px !important",
                fontStyle: "normal !important",
                fontWeight: "400 !important",
                lineHeight: "20px !important",
                letterSpacing: "0.25px!important",
                textAlign: "left!important",
                color: '#000000!important',
                paddingLeft: '0px!important'
            },
        },

        MUIDataTable: {
            responsiveBase: {
                marginRight: "79px!important",
            },
            responsiveScrollMaxHeight: {
                maxHeight: 'calc(100vh - 200px) !important',
            },
            responsiveStacked: {
                overflowX: 'hidden!important',
            }

        },
        MUIDataTableViewCol: {
            root: {
                padding: "16px!important"
            }
        },

        MuiToolbar: {
            root: {
                marginRight: "82px!important",
            }
        },
        MuiSwitch: {
            switchBase: {
                color: "#BDBDBD"
            },
            colorSecondary: {
                "&$checked": {
                    color: mainTheme.palette.primary.light,
                }
            },
            track: {
                backgroundColor: "#DEDEDE",
                "$checked$checked + &": {
                    backgroundColor: mainTheme.palette.primary.light,
                },
            },
        },
        MuiSvgIcon: { padding: 0 },
        MuiButton: {
            root: {
                fontFamily: "Roboto!important",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "18px",
                letterSpacing: "1.25px",
                textAlign: "center",
                padding: "9px 12px",
                borderRadius: "4px",
                minWidth: "0px!important"
            },
            containedPrimary: {
                //backgroundColor: mainTheme.palette.primary.light,
            },
            defaultContained: {
                backgroundColor: grey[300],
                "&:hover": {
                    backgroundColor: grey[300],
                }
            },
            default: {
                color: '#000',
                backgroundColor: 'none',
            },

        },
        MuiAlert: {
            standardError: {
                //message
                color: "#000000",
                backgroundColor: "#FDECEA",
                "& svg": {
                    //Icon
                    color: "#F55448"
                }
            },
            standardWarning: {
                //message
                color: "#000000",
                backgroundColor: "#FFF4E5",
                "& svg": {
                    //Icon
                    color: "#FFA117"
                }
            },
            standardInfo: {
            },
            standardSuccess: {
                //message
                color: "#000000",
                backgroundColor: "#EDF7ED",
                "& svg": {
                    //Icon
                    color: "#5CB660"
                }
            },
            outlinedError: {
                //message
                color: "#000000",
                backgroundColor: "#FDECEA",
                "& svg": {
                    //Icon
                    color: "#F55448"
                }
            },
            outlinedWarning: {
                //message
                color: "#000000",
                backgroundColor: "#FFF4E5",
                "& svg": {
                    //Icon
                    color: "#FFA117"
                }
            },
            outlinedInfo: {
            },
            outlinedSuccess: {
                //message
                color: "#000000",
                backgroundColor: "#EDF7ED",
                "& svg": {
                    //Icon
                    color: "#5CB660"
                }
            },
            filledError: {
                //message
                color: "#000000",
                backgroundColor: "#FDECEA",
                "& svg": {
                    //Icon
                    color: "#F55448"
                }
            },
            filledWarning: {
                //message
                color: "#000000",
                backgroundColor: "#FFF4E5",
                "& svg": {
                    //Icon
                    color: "#FFA117"
                }
            },
            filledInfo: {
            },
            filledSuccess: {
                //message
                color: "#000000",
                backgroundColor: "#EDF7ED",
                "& svg": {
                    //Icon
                    color: "#5CB660"
                }
            },

        },

        MuiFormHelperText: {
            root: {
                marginLeft:"0px!important"
            }
        },
        
        MuiPopover: {
            paper: {
               // overflowY:'hidden!important',
            }
        },
        MUIDataTableFilter: {
            root: {
                minWidth:"300px"
            }
        },
        MUIDataTableViewCol: {
            root: {
                minWidth: "170px!important",
                padding: "16px 24px 16px 24px!important",
            }
        },
        MuiAppBar: {
            positionFixed: {
                height: "10px!important",
                backgroundColor:"transparent!important",
            }
        }
    }
     
}
//Actual mui theme object with out any customization
export const theme = createTheme(adaptV4Theme(mainTheme));

//customized theme for mui versago can use in theme provider component
export const customTheme = createTheme(adaptV4Theme(customizedTheme));
// custom object style maker- need to use with withStyles
export const customThemeStyles = {
    //down("breakpoint") mean <= the specifield breakpoint
    //up("breakpoint") mean >= the specified breakpoint 
    mainContainer: {
        overflowX: "auto"
    },
    mainBox: {
        [breakpoints.down('sm')]: {
        },
        [breakpoints.up('sm')]: {
        },
        [breakpoints.up('md')]: {
        },
        [breakpoints.up('lg')]: {
            backgroundColor: "transparent",
            marginLeft: "32px",
            marginRight: "56px",
            minWidth: "1513px",
          
        },
       
    },
    mainBoxCollapsed: {
        [breakpoints.down('sm')]: {
        },
        [breakpoints.up('sm')]: {
        },
        [breakpoints.up('md')]: {
        },
        [breakpoints.up('lg')]: {
            backgroundColor: "transparent",
            marginRight: "56px"
        },
    },
    subtitle1: {
        [breakpoints.down('sm')]: {},
        [breakpoints.up('sm')]: {},
        [breakpoints.up('md')]: {},
        [breakpoints.up('lg')]: {
            fontSize: '16px!important',
            fontFamily: "Roboto!important",
            fontStyle: "normal!important",
            fontWeight: "400!important",
            letterSpacing: "0.15px!important",
        }
    },
    subtitle2: {
        [breakpoints.down('sm')]: {},
        [breakpoints.up('sm')]: {},
        [breakpoints.up('md')]: {},
        [breakpoints.up('lg')]: {
            fontSize: '14px!important',
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "500",
            letterSpacing: "0.1px",
        }
    },
    commonObjectListBox: {
        borderColor: grey[300] + '!important',
        backgroundColor: "white" + '!important',
    },
    subtitle3: {
        //styleName: Custom/Subtitle 3 · Med ·  12|Auto;
        [breakpoints.down('sm')]: {},
        [breakpoints.up('sm')]: {},
        [breakpoints.up('md')]: {},
        [breakpoints.up('lg')]: {
            fontFamily: "Roboto!important",
            fontSize: "12px!important",
            fontStyle: "normal!important",
            fontWeight: "500!important",
            lineHeight: "14px!important",
            letterSpacing: "0.15px!important",
            textAlign: "left!important",

        }
    },
    body2: {
        //styleName: Custom/Subtitle 3 · Med ·  12|Auto;
        [breakpoints.down('sm')]: {},
        [breakpoints.up('sm')]: {
            fontFamily: "Roboto!important",
            fontSize: "14px!important",
            fontStyle: "normal!important",
            fontWeight: "400!important",
            lineHeight: "20px!important",
            letterSpacing: "0.25px!important",
            textAlign: "center!important",
        },
        [breakpoints.up('md')]: {
            fontFamily: "Roboto!important",
            fontSize: "14px!important",
            fontStyle: "normal!important",
            fontWeight: "400!important",
            lineHeight: "20px!important",
            letterSpacing: "0.25px!important",
            textAlign: "center!important",
        },
        [breakpoints.up('lg')]: {
            fontFamily: "Roboto!important",
            fontSize: "14px!important",
            fontStyle: "normal!important",
            fontWeight: "400!important",
            lineHeight: "20px!important",
            letterSpacing: "0.25px!important",
            textAlign: "center!important",

        }
    },

    customLabel: {
        [breakpoints.down('sm')]: {},
        [breakpoints.up('sm')]: {},
        [breakpoints.up('md')]: {},
        [breakpoints.up('lg')]: {
            fontFamily: "Roboto!important",
            fontSize: "12px!important",
            fontStyle: "normal!important",
            fontWeight: "400!important",
            lineHeight: "14px!important",
            letterSpacing: "0.25px!important",
            textAlign: "left!important",
            
        }
    },
    customLabelItalics: {
        [breakpoints.down('sm')]: {},
        [breakpoints.up('sm')]: {},
        [breakpoints.up('md')]: {},
        [breakpoints.up('lg')]: {
            fontFamily: "Roboto",
            fontSize: "12px",
            fontStyle: "italic",
            fontWeight: "500",
            lineHeight: "14px",
            letterSpacing: "0.25px",
            textAlign: "left",

        }
    },
    customLabelBold: {
        [breakpoints.down('sm')]: {},
        [breakpoints.up('sm')]: {},
        [breakpoints.up('md')]: {},
        [breakpoints.up('lg')]: {
            fontFamily: "Roboto",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "14px",
            letterSpacing: "0.25px",
            textAlign: "left",

        }
    },
    smallButton: {
        [breakpoints.down('sm')]: {},
        [breakpoints.up('sm')]: {},
        [breakpoints.up('md')]: {},
        [breakpoints.up('lg')]: {
            fontFamily: "Roboto!important",
            fontSize: "13px!important",
            fontStyle: "normal!important",
            fontWeight: "fontWeightBold!important",
            lineHeight: "14px!important",
            letterSpacing: "0.25px!important",
            textAlign: "left!important",
            textTransform: "uppercase!important"
        }
    },
    chipLabel: {
        [breakpoints.down('sm')]: {},
        [breakpoints.up('sm')]: {},
        [breakpoints.up('md')]: {},
        [breakpoints.up('lg')]: {
            fontFamily: "Roboto!important",
            fontSize: "13px!important",
            fontStyle: "normal!important",
            fontWeight: "400!important",
            lineHeight: "14px!important",
            letterSpacing: "0px!important",
            textAlign: "left!important",

        }
    },
    customSmButton: {
        [breakpoints.down('sm')]: {},
        [breakpoints.up('sm')]: {},
        [breakpoints.up('md')]: {},
        [breakpoints.up('lg')]: {
            height: "32px!important",
            //width: "130px!important",
            backgroundColor: "#2196F3!important",
            fontFamily: "Roboto!important",
            fontSize: "12px!important",
            fontWeight: "500!important",
            letterSpacing: "1.25px!important",
            textAlign: "center!important",
            padding: "0px!important"
        }
    },
    customMedButton: {
        [breakpoints.down('sm')]: {},
        [breakpoints.up('sm')]: {},
        [breakpoints.up('md')]: {},
        [breakpoints.up('lg')]: {
            height: "32px!important",
            width: "130px!important",
            backgroundColor: "#2196F3!important",
            fontFamily: "Roboto!important",
            fontSize: "12px!important",
            fontWeight: "500!important",
            letterSpacing: "1.25px!important",
            textAlign: "center!important",
            padding: "0px!important"
        }
    },
    customMedButtonText: {
        fontFamily: "Roboto!important",
        fontSize: "12px!important",
        fontWeight: "500!important",
        letterSpacing: "1.25px!important",
        textAlign: "center!important",
        padding: "0px!important"
    },
    customControlButton: {
        [breakpoints.down('sm')]: {},
        [breakpoints.up('sm')]: {},
        [breakpoints.up('md')]: {},
        [breakpoints.up('lg')]: {
            height: "36px!important",
            minWidth: '94px!important'
        }
    },
    saveButton_Client: {

        //maxHeight: '32px!important',
        minWidth: '83px!important',
        minHeight: '32px!important',
        backgroundColor: '#6EADEA!important',
        "&:hover": {
            backgroundColor:  'transparent'
        },
        fontFamily: "Roboto!important",
        fontSize: "12px!important",
        fontWeight: "500!important",
        letterSpacing: "1.25px!important",
        textAlign: "center!important",
        padding: "4px 11px!important",
        wordBreak: "break-word",
        width: "100%"
    },
    saveButton_Client_Basic: {

        //maxHeight: '32px!important',
        minWidth: '83px!important',
        minHeight: '32px!important', 
        fontFamily: "Roboto!important",
        fontSize: "12px!important",
        fontWeight: "500!important",
        letterSpacing: "1.25px!important",
        textAlign: "center!important",
        padding: "4px 11px!important",
        wordBreak: "break-word",
        width: "100%"
    },
    saveButton_Client_2: {

        //maxHeight: '32px!important',
        minWidth: '83px!important',
        minHeight: '32px!important',
        backgroundColor: '#6EADEA!important',
        "&:hover": {
            backgroundColor: 'transparent',
        },
        fontFamily: "Roboto!important",
        fontSize: "12px!important",
        fontWeight: "500!important",
        letterSpacing: "1.25px!important",
        textAlign: "center!important",
        padding: "4px 11px!important",
        wordBreak: "break-word", 
    },
    customButton_Client: {
        wordBreak:'break-word',
        minWidth: '83px!important',
        minHeight: '32px!important',
        marginTop: '9px!important',
        backgroundColor: '#0000001A!important',
        "&:hover": {
            backgroundColor: '#0000001A!important',
        },
        color: theme.palette.common.black +'!important',
        fontFamily: "Roboto!important",
        fontSize: "12px!important",
        fontWeight: "500!important",
        letterSpacing: "1.25px!important",
        textAlign: "left!important",
        /*padding: "11px!important"*/
        paddingTop: "7px!important",
        paddingBottom: "7px!important",
    },
    customButton_Client_Basic: {
        wordBreak: 'break-word',
        minWidth: '83px!important',
        minHeight: '32px!important',
        marginTop: '9px!important', 
        fontFamily: "Roboto!important",
        fontSize: "12px!important",
        fontWeight: "500!important",
        letterSpacing: "1.25px!important",
        textAlign: "left!important",
        /*padding: "11px!important"*/
        paddingTop: "7px!important",
        paddingBottom: "7px!important",
    },
    otherButton_Client: {
        minWidth: '83px!important', 
        minHeight: '32px!important',
        marginRight: '4px!important',
        backgroundColor: '#0000001A!important',
        color: theme.palette.common.black + '!important',
        "&:hover": {
            backgroundColor: '#0000001A!important',
        },
        fontFamily: "Roboto!important",
        fontSize: "12px!important",
        fontWeight: "500!important",
        letterSpacing: "1.25px!important",
        textAlign: "center!important",
        padding: "7px 11px !important",
        wordBreak: "break-word",
        width: "100%"
    },
    otherButton_Client_Basic: {
        minWidth: '83px!important', 
        minHeight: '32px!important',
        marginRight: '4px!important', 
        fontFamily: "Roboto!important",
        fontSize: "12px!important",
        fontWeight: "500!important",
        letterSpacing: "1.25px!important",
        textAlign: "center!important",
        padding: "7px 11px !important",
        wordBreak: "break-word",
        width: "100%"
    },
    otherButton_Client_2: {
        minWidth: '83px!important',
        minHeight: '32px!important',
        marginRight: '4px!important',
        backgroundColor: '#0000001A!important',
        color: theme.palette.common.black + '!important',
        "&:hover": {
            backgroundColor: '#0000001A!important',
        },
        fontFamily: "Roboto!important",
        fontSize: "12px!important",
        fontWeight: "500!important",
        letterSpacing: "1.25px!important",
        textAlign: "center!important",
        padding: "7px 11px !important",
        wordBreak: "break-word",
       
    },
    resetButton_Client: {
        backgroundColor: '#6EADEA!important',
        "&:hover": {
            backgroundColor: 'transparent'
        },
    },

    customErrorMessage: {
        backgroundColor: "#43A047"
    },
    customTheme: {
        backgroundColor: "#6EADEA"
    },
    theme_hover: {
        backgroundColor: "#507DA9"
    },
    theme_saturated: {
        backgroundColor: "#449BEE"
    },
    neutral: {
        backgroundColor: "#5F6871"
    },
    neutral_hover: {
        backgroundColor: "#393E44"
    },
    neutral_icon: {
        backgroundColor: "#393E44"
    },
    neutral_icon_hover: {
        backgroundColor: "#111314"
    },
    form_detail_highlight: {
        backgroundColor: "#FFCF88"
    },
    customSMTab: {
        [breakpoints.down('sm')]: {},
        [breakpoints.up('sm')]: {},
        [breakpoints.up('md')]: {},
        [breakpoints.up('lg')]: {
            fontFamily: "Roboto!important",
            fontSize: "11px!important",
            fontWeight: "500!important",
            letterSpacing: "0px!important",
            textAlign: "center!important", 
        }
    },
    tabRoot: {
        backgroundColor:"#fff!important"
    }
};
// Import independent and use the required color pallet
export const customColorPalette = {
    // example usage backgroundColor: customColorPalette.black[36]
    //1E1E1E
    black: {
        1: "rgba(0, 0, 0, 0.1)",
        2: "rgba(0, 0, 0, 0.2)",
        10: "#CFCFCF",
        36: "#929292",
        58: "#696969",
        87: "rgba(0, 0, 0, 0.87)",
        54: "rgba(0, 0, 0, 0.54)",
        36: "rgba(0, 0, 0, 0.36)",//#474f54"

    },
    success: {
        light: "#81c784",
        main: "#4caf50",
        dark: "#388e3c",
        contrastText: "rgba(0, 0, 0, 0.87)",
    },

}


