import * as yup from "yup";
export const menuSchema = yup.object().shape({
    menuName: yup.string().required("Name is required"),
    roleId: yup.string()
        .test('isRoleEmpty',
            'Role is required',
            function (value) {
                return value === undefined || value === "0" ? false : true;
            })
        .required("Role is required"),






})
