
import React from 'react';
import { useState,useRef } from 'react';
import { HtmlEditor, Inject,  RichTextEditorComponent} from '@syncfusion/ej2-react-richtexteditor';
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-icons/styles/material.css';
import '@syncfusion/ej2-buttons/styles/material.css';
import '@syncfusion/ej2-splitbuttons/styles/material.css';
import '@syncfusion/ej2-inputs/styles/material.css';
import '@syncfusion/ej2-lists/styles/material.css';
import '@syncfusion/ej2-navigations/styles/material.css';
import '@syncfusion/ej2-popups/styles/material.css';
import '@syncfusion/ej2-richtexteditor/styles/material.css';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
export default function InputTextArea(props) {
    let value = (props.dataModel[props.settings.fieldName]) ? props.dataModel[props.settings.fieldName] : "";
    const [fieldValue, setFieldValue] = useState(value);
    const [editorHeight, setEditorHeight] = useState();
    const textareaRef = useRef(null);
    const htmlEditorRef = useRef(null);
    React.useEffect(() => {
        if (value !== fieldValue) {
            setFieldValue(value);
        }
    }, [value]);

    React.useEffect(() => {
        const textareaHeight = textareaRef.current.clientHeight;
        if (editorHeight !== textareaHeight + 10) {
            textareaRef.current.hidden = true;
            if (htmlEditorRef.current.element.clientHeight < (textareaHeight + 10)) {
                setEditorHeight(textareaHeight + 10)
            }
            else
            {
                setEditorHeight(htmlEditorRef.current.element.clientHeight)
            }
            
        }
    }, []);
    React.useEffect(() => {
        htmlEditorRef.current.element.style.height = `${editorHeight + 2}px`
        if (htmlEditorRef.current.element.childElementCount >= 2 && htmlEditorRef.current.element.children[1].childElementCount >=1) {
            htmlEditorRef.current.element.children[1].children[0].style.color = controlStyle.color;
            htmlEditorRef.current.element.children[1].children[0].style.background = controlStyle.background;
           
        }

        
    }, [editorHeight]);

    const controlStyle = {
        color: props.settings.foreColor,
        background: props.settings.backGroundColor,
        width: "100%",
    }

    let labelStyle = {
        color: props.settings.captionColor ? props.settings.captionColor : "rgba(0, 0, 0, 0.54)",
        wordBreak: "break-all",
        marginRight: 20
    }

    let styleHelperText = props.classes.signatureHelperText
    let errorState = {};
    let requiredIndicator = "";
    let helperText = !props.settings.helpText ? ' ' : props.settings.helpText;
    if (props.settings.isMandatory) {
        requiredIndicator = " *";
        const validation = props.validationSchema.filter(field => field.fieldName === props.settings.fieldName && field.errorState)
        if (validation.length > 0) {
            errorState.error = true;
            helperText = `${props.settings.caption} is required`;
            labelStyle = {
                color: "#f44336",
                wordBreak: "break-all",
                marginRight: 20
            }
            styleHelperText = props.classes.signatureError;
        }
    }
    const handleChange = (event) => {
        setFieldValue(!event.value ? '' : event.value);
    };
    const handleBlur = (event) => {
        if ((fieldValue !== props.dataModel[props.settings.fieldName]) || (props.settings.isMandatory && !errorState.error && !fieldValue)) {

            props.onChange(props.settings.isMandatory, props.settings.fieldName, fieldValue, [], {}, false, false);
        }
    }
    return (<div >
        <Grid container spacing={0} >
            <Grid item xs >
                <Typography variant="body2" style={labelStyle} > {props.settings.caption}{requiredIndicator}</Typography>
            </Grid>
        </Grid>
        <textarea id={`id_${props.settings.fieldName}`} ref={textareaRef}  rows={props.settings.rowSpan} ></textarea>
        <RichTextEditorComponent
           
            id={props.settings.fieldName}
            ref={htmlEditorRef}
            name={props.settings.fieldName}
            enabled={props.settings.isEditable}
            value={fieldValue}
            toolbarSettings={{ enable: false }}
            change={handleChange}
            blur={handleBlur}
            height={editorHeight}
        >
            <Inject services={[HtmlEditor]} />
        </RichTextEditorComponent>
        <FormHelperText className={styleHelperText}  >{helperText}</FormHelperText>
    </div>
        );
}
