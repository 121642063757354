import React from "react";
import { useState } from 'react';
import { DatePickerComponent, DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import NumericInput from 'client/components/Common/NumericInput';
import * as enumerator from 'client/components/Common/Enum';




export default function EditColumnTemplate(props) {

    const row = { ...props.data };
    const [fieldValue, setFieldValue] = useState(row[props.column.fieldName]);
    

    const handleBlurDate = (event) => {



        let { column, foreignKeyData, ...row } = props.data;
        column = null;
        foreignKeyData = null;

        if (event.model.currentDate !== fieldValue) {
            if (row["Vgo_OldValue"] === undefined) {
                row["Vgo_OldValue"] = fieldValue;
            }
            row[props.column.fieldName] = event.model.currentDate;
            props.setGridValue(row, 'input', props.column.fieldName);
            setFieldValue(event.model.currentDate)
        }

    };
    
    const handleBlurNumericNew = (event, value, settings) => {



        let { column, foreignKeyData, ...row } = props.data;
        column = null;
        foreignKeyData = null;
        if (value !== fieldValue) {
            if (row["Vgo_OldValue"] === undefined) {
                row["Vgo_OldValue"] = fieldValue;
            }
            row[props.column.fieldName] = value;
            props.setGridValue(row, 'input', props.column.fieldName);
            setFieldValue(value)
        }

    };
    const handleBlurText = (event) => {
        let { column, foreignKeyData, ...row } = props.data;
        column = null;
        foreignKeyData = null;

        if (event.target.value !== fieldValue) {
            if (row["Vgo_OldValue"] === undefined) {
                row["Vgo_OldValue"] = fieldValue;
            }
            row[props.column.fieldName] = event.target.value;
            props.setGridValue(row, 'input', props.column.fieldName);
            setFieldValue(event.target.value)
        }

    };
    if (props.column.fieldType === enumerator.DbType.Numeric || props.column.fieldType === enumerator.DbType.Decimal || props.column.fieldType === enumerator.DbType.Int || props.column.fieldType === enumerator.DbType.SmallInt) {
        return (
            
            <NumericInput fullWidth={true} onChange={handleBlurNumericNew}
                settings={props.editableFieldSettings}
                styleSettings={props.styleSettings}
                height= {25}
                value={fieldValue}
                error={false}
                helperText={''} />
        );
    }
    else if (props.column.fieldType === enumerator.DbType.Date) {
        return (
            <DatePickerComponent value={fieldValue} format={props.column.culture} blur={handleBlurDate} />
        );
    }
    else if (props.column.fieldType === enumerator.DbType.DateTime) {
        return (
            <DateTimePickerComponent value={fieldValue} format={props.column.culture} blur={handleBlurDate} />
        );
    }
    else {
        return (
            < TextBoxComponent value={fieldValue} onBlur={handleBlurText} />
        );
    }

}