import React from 'react'; 
import Header from './Header.js';
import Content from './Content.js';
import { Box } from '@mui/material';
export default class Section extends React.Component {

    render() {
        return (
            <React.Fragment>
                <Box pt={4} pb={4} >
                    <Header></Header>
                </Box>
                
                <Content ></Content>
                
            </React.Fragment>
        );
    }
}
