import React, { Component } from 'react'
import Hover from 'client/components/Form/Controls/Image/Hover'
import Trigger from 'client/components/Form/Controls/Image/Trigger'
import { hoverStyle} from 'client/components/Form/Controls/Image/theme/ImageStyles'
import PropTypes from 'prop-types'
class ReactHover extends Component {
    static propTypes = {
        children: PropTypes.array.isRequired,
        options: PropTypes.object.isRequired,
        className: PropTypes.string
    }
    constructor(props) {
        super(props)
        this.state = {
            children: PropTypes.object,
            hoverComponentStyle: hoverStyle
        }
    }


    renderItem(item, index) {
        if (item.type.name === 'Trigger' || item.props.type === 'trigger') {
            return <Trigger key={index}>{item}</Trigger>
        } else if (item.type.name === 'Hover' || item.props.type === 'hover') {
            return <Hover key={index}>{item}</Hover>
        }
        return "";
    }

    render() {
        const { hoverComponentStyle } = this.state
        let childrenWithProps = []
        for (let child of this.props.children) {
            if (child.props) {
                if (child.type.name === 'Trigger' || child.props.type === 'trigger') {
                    childrenWithProps.push(
                        React.cloneElement(child, {
                            setVisibility: this.setVisibility.bind(this),
                            getCursorPosition: this.getCursorPosition.bind(this)
                        }),
                    )
                } else if (child.type.name === 'Hover' || child.props.type === 'hover') {
                    childrenWithProps.push(
                        React.cloneElement(child, {
                            styles: hoverComponentStyle,
                            setVisibility: this.setVisibility.bind(this),
                            getCursorPosition: this.getCursorPosition.bind(this)
                        }),
                    )
                }
            }
        }

        return (
            <div>
                {childrenWithProps.map((item, index) => this.renderItem(item, index))}
            </div>
        )
    }

    setVisibility(flag) {
        let { hoverComponentStyle } = this.state
        let updatedStyles;
        if (flag) {
            updatedStyles = { ...hoverComponentStyle, display: 'block' }
        } else {
            updatedStyles = { ...hoverComponentStyle, display: 'none' }
        }
        this.setState({
            hoverComponentStyle: updatedStyles
        })
    }
    getCursorPosition(e) {
         
         let {
             options: { followCursor },
         } = this.props
         let { hoverComponentStyle } = this.state
         let updatedStyles
         updatedStyles = null;
         if (!followCursor) {
             return
         }
         
         let topPosition = e.clientY;
         
         if (topPosition + 250 > window.innerHeight) {
             let diff = (topPosition + 250) - window.innerHeight
             topPosition = topPosition - (diff + 10);
         }

        let leftPosition = e.clientX;
        if ((leftPosition + 250) > window.innerWidth) {
            let diff = (leftPosition + 250) - window.innerWidth
            leftPosition = leftPosition - (diff + 120);
        }
              
        updatedStyles = {
            ...hoverComponentStyle,
            top: topPosition,
            left: leftPosition
           }
         
        this.setState({
            hoverComponentStyle: updatedStyles
        })
         
    }
}

export { Trigger, Hover }
export default ReactHover