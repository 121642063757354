import { customThemeStyles } from 'common/theme.js';
import { customColorPalette } from 'common/theme.js';

export const calendarTheme = (theme) => ({
    ...customThemeStyles,
    root: {
        justifyContent: 'center!important',
        flexWrap: 'wrap!important',
        height: "100%!important",
        borderRadius: "4px!important",
    },
    card: {
        /* height: "642px!important",*/
        marginLeft: theme.spacing(-3) + '!important',
        marginRight: theme.spacing(-3) + '!important',
        marginTop: theme.spacing(-3) + '!important',
    },
    subGrid: {
        maxWidth: '640px!important'

    },
    mainGridItem: {
        maxWidth: '696px!important'

    },
    presentationgridLabelItem: {

        paddingBottom: '8px!important',
        marginLeft: '-20px',

    },
    presentationgridFieldItem: {

        paddingBottom: '8px!important',
        marginLeft:'-30px',
        
    },
    presentationgridItemUserSeesCaptionColumn: {

        paddingBottom: '8px!important',
        paddingLeft: '30px !important',

    },
    gridPadding: {

        paddingBottom: '8px!important'
    },
    presentationPreview: {
        backgroundColor: customColorPalette.black[10],
        paddingTop: '12px',
        paddingLeft: '16px',
        paddingBottom: '20px',
        paddingRight: '80px'
    },
    presentationItemCaption: {
        fontWeight:"bold"
    }
});