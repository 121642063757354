import React from 'react';

import { ActionTypes as types } from './actionTypes';

import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';

/**
 * Show Alert action creator
 * @param {*} open
 * 
 */
export const getAlertShow = ( open, severity, title, message, icon,action,source ) => {
    return {
        type: types.VGO_ALERT_SHOW,
        alert: {
            open,
            severity,
            title,
            message,
            icon,
            action,
            source,
        },
    };
};

export const getAlertHide = (severity) => {
    return {
        type: types.VGO_ALERT_HIDE,
        alert: {
            open : false,
            severity: severity ? severity :'',
            title : '',
            message : '',
            icon : null,
        },
    };
};

export const showAlert = ( dispatch, severity, title, message, icon, timeOut,action,source ) => {
    dispatch(getAlertShow(true, severity, title, message, icon, action, source ) );
    ////if ( timeOut ) {
    ////    setTimeout(() => dispatch(getAlertHide(severity) ), timeOut );
    ////}
}

export const showAlertWarning = (dispatch, severity, title, message, icon,  action) => {
    dispatch(getAlertShow(true, severity, title, message, icon, action));
    
}

export const showSuccess = ( dispatch, title, message, timeOut,source ) => {
    showAlert(dispatch, 'success', title, message, <CheckCircleIcon />, timeOut,undefined,source );
}

export const showInfo = ( dispatch, title, message, timeOut ) => {
    showAlert( dispatch, 'info', title, message, <InfoIcon />, timeOut );
}

export const showWarning = (dispatch, message, timeOut, action ) => {
    showAlert( dispatch, 'warning', 'Warning', message, <WarningIcon />, timeOut,action );
}

export const showError = ( dispatch, message, timeOut ) => {
    showAlert(dispatch, 'error', 'Error', message, <ErrorIcon />);
}

export const hideError = (dispatch) => {
    dispatch(getAlertHide())
}


export const showConfirmWarning = (dispatch, message, action) => {
    showAlertWarning(dispatch, 'warning', 'Warning', message, <WarningIcon />,  action);
}