import React from 'react';
import PropTypes from 'prop-types';
import { Box, Link, List, ListItemText, ListItemIcon,Typography } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import Menu from './Menu.js';
import VersagoSvgIcon from 'common/VersagoSvgIcon.js';
import { MenuItemTheme} from './theme/MenuItemTheme.js';
import { withStyles } from '@mui/styles';





class MenuItem extends React.Component {
    
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.renderMenu = this.renderMenu.bind(this);
        this.renderMenuItem = this.renderMenuItem.bind(this);
        this.renderSubMenu = this.renderSubMenu.bind(this);
        this.hasSubMenu = this.hasSubMenu.bind(this);
        this.renderIcon = this.renderIcon.bind(this);
        this.state = {
            in: this.props.in,
            initialLoad: true,
        };
    }

    handleClick(e, index) {
        if ( this.hasSubMenu() ) {
            this.setState({
                in: !this.state.in,
                initialLoad: false
            });
        }
        else {
            e.preventDefault();
            this.props.handleClick(this.props.item);
        }
    }

    hasSubMenu() {
        let item = this.props.item;
        let items = item.items;
        let result = items !== undefined && items.length > 0;
        return result;
    }

    render() {
        
        return (
            <Box>
                <React.Fragment>
                    <List disablePadding>
                        {this.renderMenu()}
                    </List>
                </React.Fragment>
                <React.Fragment>
                    {this.state.initialLoad !== true && this.renderSubMenu()}
                </React.Fragment>
            </Box>
        );
    }

    renderOpenClose() {
        const classes = this.props.classes;
        return this.hasSubMenu() ? (this.state.in ? <ExpandLess className={classes.icon} /> : <ExpandMore className={classes.icon} /> ) : null;
    }
    checkMenuIsSelected(item,activeMenuKey) {
        if (activeMenuKey) {
            return activeMenuKey === item.key ? true : false;
        }
        else {
            return true;
        }
    }
    renderMenu() {
        
        const classes = this.props.classes;
        let item = this.props.item;
        let param = window.location.pathname;
        const dense = (item.icon && item.icon.length > 0) ? classes.denseMenu : classes.dense;
        const MenuListItem = this.props.listItem;
        return (
            <MenuListItem className={dense} button onClick={(e) => this.handleClick(e, item.key)}
                selected={param === item.route && this.checkMenuIsSelected(item,this.props.activeMenuKey )}
                component={Link} href={item.route} disableTouchRipple={true}>
                <Box ml={this.props.level * 2} className={classes.boxDisplay}>
                    {this.renderMenuItem()}
                </Box>
            </MenuListItem>
            );
        
    }

    renderIcon() {
        const classes = this.props.classes;
        let item = this.props.item;
        if ( item.icon && item.icon.length > 0 ) {
            return ( 
                <ListItemIcon className={classes.listItemIcon}>
                    <VersagoSvgIcon icon={item.icon} />
                </ListItemIcon>
            )
        }
        else {
            return null;
        }
    }

    renderMenuItem() {
        const classes = this.props.classes;
       
            
          
            // TODO: box for top and bottom margin?
            return (
                <React.Fragment>
                    {this.renderIcon()}
                    <ListItemText className={classes.listItemText}
                        primary={this.renderListItemText()}
                    />
                    {this.renderOpenClose()}
                </React.Fragment>
            );
    }

    renderListItemText() {
        const classes = this.props.classes;
        const item = this.props.item;
        const roleListPadding = item.isRoleList ? classes.roleList : null;
        const listItemText = item.subLabel
            ? <Box>
                <Typography className={classes.subtitle2} classes={{ root: roleListPadding }} display="inline">{item.label}</Typography>
                <Typography className={classes.subtitle2 + ' ' + classes.roleSelectedItem } classes={{ root: classes.subLabel }} >{item.subLabel}</Typography>
              </Box>
            : <Typography className={classes.subtitle2} classes={{ root: roleListPadding }} >{item.label}</Typography>;

        return (listItemText);
    }

    renderSubMenu() {
        let item = this.props.item;
        return this.hasSubMenu() 
            ? <Box>       {/* in theme.spacing units */}
                <Menu
                    level={this.props.level} 
                    in={this.state.in} 
                    items={item.items}
                    activeMenuKey={this.props.activeMenuKey}
                    handleClick={this.props.handleClick} />
            </Box>
            : null;
    }

}
export default (withStyles(MenuItemTheme)(MenuItem))

// TODO: recursive propTypes
MenuItem.propTypes = {
    item: PropTypes.shape(
        {
            key: PropTypes.string,
            icon: PropTypes.string,
        }
    ).isRequired,
};

MenuItem.defaultProps = {
    level: 0,
    in: false,
    handleClick: ( key ) => {},
};

