const validAuth = 'VALID_AUTH'
const invalidAuth = 'INVALID_AUTH'

const initialState = { auth: true, actionName: '' };

export const authStatusHandlerAction = {


    setAuthStatus: (status, action) => (dispatch, getState) => {
        if (status) {
            dispatch({ type: validAuth, authAction: { auth: status, actionName: action } });
        }
        else {
            dispatch({ type: invalidAuth, authAction: { auth: status, actionName: '' } });
        }

    },
}


export const reducer = (state, action) => {

    state = state || initialState;

    if (action.type === invalidAuth && state.auth) {
        return {
            ...initialState,
            auth: false,
            actionName: action.authAction.actionName,
        };
    }
    else if (action.type === validAuth) {
        return {
            ...initialState,
            auth: true,
            actionName: action.authAction.actionName,
        };
    }
    return state;
}