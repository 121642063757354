import { ActionTypes as types } from './actionTypes';

/**
 * Error action creator
 * @param {*} errorMessage 
 */
export const getError = ( errorMessage ) => {
    console.log( 'getError: ', errorMessage );
    return {
        type: types.VGO_API_CALL_ERROR,
        hasError: true,
        errorMessage,
    }
};
export const clearError = () => {
       return {
           type: types.VGO_API_CALL_SUCCESS,
        hasError: false,
        
    }
};
