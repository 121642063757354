import React from 'react';
import { connect } from 'react-redux';
import Alert from '@mui/lab/Alert';
import { withRouter } from 'react-router-dom';
import Button from '@mui/material/Button';
import { IconButton } from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Popover from '@mui/material/Popover';
import { withStyles } from '@mui/styles';
import { deleteActionStyle } from './theme/deleteButtonTheme.js';
import { showError } from 'admin/actions/alertActions';
import CloseIcon from '@mui/icons-material/Close';

const MuiPopover = withStyles((theme) => ({
    root: {},
    paper: {
        boxShadow: deleteActionStyle.popper.boxShadow,
    },
}))(Popover)
class DeleteButton extends React.Component {
    constructor(props) {
        super(props)
        this.state= {
            anchorElement: null,
            innerWarning: false,
            message:""
        }

    }

    deleteRole= () => {
        this.setState({ anchorElement: null })
        this.props.onClick(this.props.role)
    }

    handleOnClick = () => {
        if (this.props.showWarning && !this.state.innerWarning) {

                this.setState({ ... this.state, innerWarning: true, })
            } else {
                this.setState({
                    anchorElement: null,
                    innerWarning: false,
                    message:""
                })
                this.props.onClick(this.props.id)
            }
    }
    showAlert = (event) => {
        
        var cTarget = event.currentTarget;
        if (this.props.preValidation != null) { 
          
            this.handleAlertData(this.props.preValidation(this.props.id), cTarget);
            
        }
        if (this.props.preValidationServer != null) {
            this.props.preValidationServer(this.props.id).then((resp) => {
                let message = (resp.data === "") ? resp.data : resp.data.message;
                this.handleAlertData({ canDelete: true, message: message }, cTarget);
            })
            .catch((err) => {
                const errorMessage = err.response.data; 
                this.handleAlertData({ canDelete: false, message: errorMessage }, cTarget);
            });  
        }
       
    }
    handleAlertData = (vObject, cTarget) => {
        // vObject should be in format => { canDelete: false, message: "" };  
         
        if (vObject.canDelete) {
                    this.setState({
                        anchorElement: cTarget,
                        innerWarning: false,
                        message: vObject.message
                    })
                } else {
            showError(this.props.dispatch, vObject.message, 5000);
                }
    }

    handleClose = () => {
         this.setState({
             anchorElement: null, innerWarning: false, })
    };
    getMessage = () => {
        let message = [];
        const dependencyMessage = (this.state.innerWarning ? " Are you sure ?\nThe role being deleted has users assigned to it." : (this.state.message===""  ? "Are you sure?" : this.state.message));
        const actionButton = (<React.Fragment>
            <Button color="inherit" size="small" className={this.props.classes.alertActionButton} onClick={this.handleOnClick}>YES</Button>
            <Button color="inherit" size="small" className={this.props.classes.alertActionButton} onClick={this.handleClose}>NO</Button>
            <IconButton className={this.props.classes.alertIconButton} onClick={this.handleClose}><CloseIcon className={this.props.classes.closeButton} fontSize="small" /></IconButton>

        </React.Fragment>)
        message = dependencyMessage.split('\n').map((item, key) => {
            return <div><span key={key}>{item}</span>{key === 0 ? actionButton:'' }<br /></div>
       })
        return message;
    };
    render() {
        const open = Boolean(this.state.anchorElement);
        const id = open ? 'simple-popover' : undefined;
        return (
            <div>
                <IconButton disableFocusRipple={true} className={this.props.classes.deleteIconButton} type="button" onClick={this.showAlert}><DeleteOutlineIcon /></IconButton>
                {open==true && <MuiPopover
                    id={id}
                    open={open}
                    //className={this.props.classes.poperBoxShadow}
                    anchorEl={this.state.anchorElement}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Alert severity="warning" classes={{ icon: this.props.classes.alertIcon, message: this.props.classes.alertMessage }}  >
                        {

                            this.getMessage()
                        }


                    </Alert>
                </MuiPopover>
                }
            </div>



            
        );
    }

}

export default withRouter(connect()(withStyles(deleteActionStyle)(DeleteButton)))
