import React from 'react';
import { withRouter } from 'react-router';
import { Grid, Accordion, AccordionSummary, AccordionDetails, Avatar, Typography, Box } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from './Menu.js';
import { ProfileTheme } from './theme/ProfileTheme.js';
import { withStyles } from '@mui/styles';
import PersonIcon from '@mui/icons-material/Person';

class Profile extends React.Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(item) {
        this.props.handleClick(item);
    }

    renderProfile() {
        const classes = this.props.classes;
        const profile = (this.props.user.image) ?
            <Avatar alt={this.props.user.name} src={this.props.user.image} className={classes.avatarColor}></Avatar>
            : <Avatar className={classes.avatarColor} ><PersonIcon /></Avatar>;
        return (profile);
    }

    renderAdminProfile = () => {

        const classes = this.props.classes;
        return (
            <Box className={classes.adminProfileItem}>
                <Box className={classes.adminProfileRoot}>
                    <Grid container direction="row" spacing={1}>
                        <Grid item className={classes.profileItem}>
                            {this.renderProfile()}
                        </Grid>
                        <Grid item className={classes.firstNameItem}>
                            <Grid container direction="column" spacing={0}>
                                <Grid item>
                                    <Typography className={classes.firstName} variant="subtitle1">{this.props.user.name}</Typography>
                                </Grid>
                                <Grid item >
                                    <Typography className={classes.firstName} variant="subtitle2">{this.props.user.title}</Typography>
                                </Grid>


                            </Grid>



                        </Grid>


                    </Grid>

                </Box>
                <Box sx={{ height: "48px" }}>
                    <Grid container direction="row" spacing={0}>
                        <Grid item sx={{ width: "100%" }} >


                            <Menu
                                in={true}
                                isAdmin={true}
                                items={this.props.user.roleList}
                                handleClick={this.handleClick}

                            />


                        </Grid>
                    </Grid>
                </Box>
            </Box>)

    }

    render() {
        const classes = this.props.classes;
        return (
            this.props.isAdmin ? this.renderAdminProfile() :
                <Accordion className={classes.root} square={true} variant="outlined" elevation={0}>
                    <AccordionSummary className={classes.content} expandIcon={<ArrowDropDownIcon className={classes.icon} color="primary.contrastText" />}>
                        <Grid container direction="row" spacing={1}>
                            <Grid item className={classes.profileItem}>
                                {this.renderProfile()}
                            </Grid>
                            <Grid item className={classes.firstNameItem}>
                                <Grid container direction="column" spacing={0}>
                                    <Grid item>
                                        <Typography className={classes.firstName} variant="subtitle1">{this.props.user.name}</Typography>
                                    </Grid>
                                    <Grid item >
                                        <Typography className={classes.firstName} variant="subtitle2">{this.props.user.title}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>

                        <Menu
                            in={true}
                            items={this.props.user.roleList}
                            handleClick={this.handleClick} />
                    </AccordionDetails>
                </Accordion>

        );
    }
}

export default withRouter(withStyles(ProfileTheme)(Profile))




