import { customThemeStyles } from 'common/theme.js';
export const derivedFieldTheme = (theme) => ({
    ...customThemeStyles,
    root: {
        justifyContent: 'center',
        flexWrap: 'wrap',
        height: "9px",
        borderRadius: "4px",
    },
    card: {
        height: "35px",
        padding: "none",

    },
    card1: {
        height: "30px",

    },
    chip: {
        margin: theme.spacing(0.5),
    },
    dialogPaperDataView: {

        minWidth: '696px',
        maxWidth: '696px',
        height: '752px'
    },
    dialogPaperForm: {

        minWidth: '696px',
        maxWidth: '696px',
        height: '665px'
    },
    dialogStyle: {
        alignItems: 'end',
        paddingLeft: '24px',
        marginTop: '11px',
    },
    dialogTitle: {
        paddingTop: '24px !important',
        paddingBottom: '0px !important'
    },
    alertStyle: {
        width: '331px',
        height: '38px'
    },
    listSize: {
        maxHeight: "328px!important",
        minHeight: "328px!important",
        width: "304px !important",
        flexDirection: 'row !important'
    },
    rightGrid: {
        paddingLeft: '32px !important'
    },
    buttonStyle: {
        height: '32px!important',
        boxShadow: 'none!important',
    },
    listFlex: {
        flexDirection: 'row!important'
    },
    widthStyle: {
        width: "100%!important"
    },
    gridAlign: {
        alignItems: 'end!important'
    },
    alertHeight: {
        height: '20px!important'
    },
    dialogOverflow: {
        overflow: "hidden!important",
        paddingTop: '16px !important',
    },
});
export const derivedFieldListSX = { 
    bgcolor: 'background.paper',
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
    minHeight: 300,
    border: '1px solid #959191d6',
    '& ul': { padding: 0 },
}

export const listItemTheme = () => ({
    root: {
        "&$selected": {
            backgroundColor: "#DBDBDB !important",
        },
    },
    selected: {}
});

