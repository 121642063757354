import { theme,customThemeStyles } from 'common/theme.js';
import { customColorPalette } from 'common/theme.js';
export const lookUpListTheme = (lookupTheme) => ({
    ...customThemeStyles,
    dialogPaper: {
       
        minWidth: '51.1%',
        maxWidth: '42.1%',
        height: '70.2%',
        borderRadius: '0px !important',
        boxShadow: 'none !important',

        [theme.breakpoints.only('xs')]: {
        minWidth: '100%',
      minHeight: '90vh',
        
        },
    },
    dialogTitle: {
        backgroundColor: customColorPalette.black[54],
        color: 'white',
        textAlign: 'center',
      paddingTop: '10px !important',
        height: '47px'
    },
    dialogOverflow: {
        padding: '0px !important'
    },
    dialogTitleClose: {
        position: 'absolute  !important',
        right: '0px !important',
        top: '5px !important',
        color: 'white !important',
    },
});