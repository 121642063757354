import { ActionTypes as types } from 'admin/actions/actionTypes';
 
import { produce } from 'immer';
const initialState = {
    customWidgets: [],
    activeWidget: {},
    activeWidgetIndex:1,
    customWidgetStatus: "",
    widgetValidation: {}
}; 
const customWidgets = produce((draft, action) => {
    switch (action.type) { 
        case types.VGO_GET_CUSTOMWIDGET_REQUEST:
            draft.customWidgetStatus = types.VGO_GET_CUSTOMWIDGET_REQUEST;
            break;
        case types.VGO_GET_CUSTOMWIDGET_SUCCESS:
            draft.customWidgets = action.cwidgets;
            draft.customWidgetStatus = types.VGO_GET_CUSTOMWIDGET_SUCCESS;
            draft.activeWidgetIndex = 1;
            draft.activeWidget = draft.customWidgets.find(cw => cw.displayOrder === 1)
            break;
        case types.VGO_CHANGE_CUSTOMWIDGET_ACTIVEINDEX: 
            draft.activeWidgetIndex = action.activeWidgetIndex;
            draft.activeWidget = draft.customWidgets.find(cw => cw.displayOrder === action.activeWidgetIndex)
            draft.widgetValidation = action.widgetValidation
            break;
        case types.VGO_CHANGE_CUSTOMWIDGET:
            action.change(draft.activeWidget);
            var index = draft.customWidgets.findIndex(cw => cw.type === draft.activeWidget.type)
            draft.customWidgets[index] = draft.activeWidget
            break;
        case types.VGO_CUSTOMWIDGET_ERROR: 
            draft.widgetValidation = action.widgetValidation
            break;
        case types.VGO_UPDATE_CUSTOMWIDGET_REQUEST:
            draft.customWidgetStatus = types.VGO_UPDATE_CUSTOMWIDGET_REQUEST;
            break;
        case types.VGO_UPDATE_CUSTOMWIDGET_SUCCESS:
            draft.customWidgets = action.cwidgets;
            draft.customWidgetStatus = types.VGO_UPDATE_CUSTOMWIDGET_SUCCESS;
            //draft.activeWidget = action.cwidgets.find(cw => cw.displayOrder === draft.activeWidgetIndex)
            break;
        case types.VGO_CHANGE_CUSTOMWIDGET_DISPLAYORDER:
            draft.customWidgets = action.cwidgets;
            draft.activeWidgetIndex = action.activeWidgetIndex;
            draft.activeWidget = draft.customWidgets.find(cw => cw.displayOrder === action.activeWidgetIndex)
            break;
        case types.VGO_CHANGE_CUSTOMWIDGET_LINK_DELETE:
        case types.VGO_NEW_CUSTOMWIDGET_LINK:
            draft.customWidgets = action.cwidgets;
            draft.activeWidget = action.cwidgets.find(cw => cw.type === draft.activeWidget.type)
            break;
        case types.VGO_UPDATE_CUSTOMWIDGET_ERROR:
            draft.customWidgets = action.customWidgets;
            draft.activeWidget = action.activeWidget
            break;
        default:
            break;
    }
}, initialState);

export default customWidgets;
