import React from 'react';

import { Route, BrowserRouter, Switch } from 'react-router-dom';

import LeftPanel from 'client/components/LeftPanel/LeftPanel';
import ErrorBoundary from 'client/components/Common/ErrorBoundary';
import AuthStatus from 'client/components/AuthStatus';
import { ComponentList, DeviceType} from 'client/components/Common/Enum';
import 'client/assets/index.css';
import { mainContentTheme } from 'client/components/MainContent/theme/mainContentTheme'
import ComponentRoute from 'client/components/ComponentRoute/ComponentRoute';
import { getDeviceType } from 'client/components/Common/Utility';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import 'client/assets/css/style.css';
import * as Enums from 'client/components/Common/Enum';

class mainContent extends React.Component {
    constructor(props) {
        super(props);
        const deviceType = getDeviceType();

        var collapsed = (deviceType === Enums.DeviceType.Mobile) ? true : false;
        this.state = { collapsed: collapsed, isClicked: false };

    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize, false);
       
       
        this.setState({
            collapsed: this.state.collapsed,
            isClicked: this.state.isClicked,
        }); 
    }
    
    resetMargin = (collapsed) => {
        this.setState({
            collapsed: collapsed,
            isClicked: true
        });
    }

    handleResize = (event) => {
        let windowWidth=event.currentTarget.innerWidth
              
        if (!this.state.isClicked) {
            if (windowWidth < 768) {
               
                this.setState({
                    collapsed: true,
                    //isClicked: true
                });
               
            }
            else {
               
                this.setState({
                    collapsed: false,
                  //  isClicked: true
                });
                
            }
        }

    }

    render() {
        const { classes } = this.props;

        return (
            <BrowserRouter>
                <Grid continer id="baseLayer">
                    <Grid id="idLoginPanel" lg={12} item m={0} p={0}>
                        
                        <Grid continer>
                            <Grid item xs={1} >
                                <ErrorBoundary >
                                    <LeftPanel actionSource={this.props.actionSource} propsRoleId={this.props.propsRoleId} resetMargin={this.resetMargin} collapsed={this.state.collapsed} isClicked={this.state.isClicked}  />
                                </ErrorBoundary>
                            </Grid>


                            <Grid item xs className={this.state.isClicked ? this.state.collapsed ? classes.leftPanelCollapsed : classes.leftPanelExpanded : classes.leftPanelMargin} p={0}>
                                   
                                <AuthStatus />
                                <Switch>
                                    <ErrorBoundary >

                                        <Route exact path='/' render={props => <ComponentRoute component={ComponentList.Home}  {...props} />} />
                                        <Route path='/Content' render={props => <ComponentRoute component={ComponentList.Content} {...props} />} />
                                        <Route path='/UserProfile' render={props => <ComponentRoute component={ComponentList.UserProfile} resetMargin={this.resetMargin} {...props} />} />
                                        <Route path='/Banner/:id' render={props => <ComponentRoute component={ComponentList.Banner} {...props} />} />
                                        <Route path='/Calendar/:id' render={props => <ComponentRoute component={ComponentList.DataView} resetMargin={this.resetMargin} {...props} />} />
                                        <Route path='/Report/:id/:loadType' render={props => <ComponentRoute component={ComponentList.DataView} resetMargin={this.resetMargin}  {...props} />} />
                                        <Route exact path='/Report/:id' render={props => <ComponentRoute component={ComponentList.DataView} resetMargin={this.resetMargin} {...props} />} />
                                        <Route path='/Dashboard/:id' render={props => <ComponentRoute component={ComponentList.Dashboard} resetMargin={this.resetMargin} {...props} />} />
                                        <Route path='/Form/:id/:loadType' render={props => <ComponentRoute component={ComponentList.Form} resetMargin={this.resetMargin} {...props} />} />
                                        <Route exact path='/Form/:id' render={props => <ComponentRoute component={ComponentList.Form} resetMargin={this.resetMargin} {...props} />} />
                                        <Route path='/ResetPassword' render={props => <ComponentRoute component={ComponentList.ResetPassword} {...props} />} />
                                        <Route path='/CrystalReportViewer/:id/:actionid/:loadType' render={props => <ComponentRoute component={ComponentList.CrystalReportViewer} {...props} />} />

                                    </ErrorBoundary >
                                </Switch >
                                   
                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>



            </BrowserRouter>

        );
    }
}

export default withStyles(mainContentTheme)(mainContent);