import React from 'react';

import { styled } from '@mui/styles';
import { spacing } from '@mui/system';
import { Grid, Typography } from '@mui/material';
import MuiButton from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check'

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { resetReportId,updateDataView, getDataViewQueryFieldList, getChangeDataView } from 'admin/actions/dataViewActions';

import { showError, hideError } from 'admin/actions/alertActions';


import { setupTheme } from 'admin/components/section/dataViews/setup/theme/setupTheme';
import cloneDeep from 'lodash/cloneDeep';
import { ActionTypes as actionTypes } from 'admin/actions/actionTypes';
import ActionBackdrop from 'admin/components/section/settings/Backdrop';
import { withStyles } from '@mui/styles';
const Button = styled(MuiButton)(spacing);

class DataViewEditHeader extends React.Component {

    constructor(props) {
        super(props);
        this.isUpdate = this.props.match.params.reportId !== "new";
        this.state= {
            clickSave: false,
            checkExecuteQuery: false,
        }
    }

    componentDidUpdate() {
        this.isUpdate = this.props.match.params.reportId !== "new";
        
    }

   onDiscardClick = () => {
       this.props.dispatch(resetReportId());
        this.props.history.push('/Admin/DataViews');
    }

    onSaveClick = () => {

        if (!this.props.dataView.executeQuery && this.props.dataView.query) {
            this.props.dispatch(getChangeDataView(dataView => dataView.executeQuery = true));
            let dataViewDto = {
                company: this.props.dataView.company,
                reportId: this.props.dataView.reportId,
                dataSourceType: this.props.dataView.dataSourceType,
                query: this.props.dataView.query
            }
            if (dataViewDto.query) {
                this.props.dispatch(getDataViewQueryFieldList(dataViewDto, 0, true, this.props.dataView.fieldDisplay, this.props.dataView.reportFilter, this.props.dataView.recordProcessing,this.props.dataView.reportSubReport));
            } else {
                showError(this.props.dispatch, "Invalid query ", 5000)
            }
            this.setState({ checkExecuteQuery: true });
        }
        this.setState({ clickSave: true });
    }
    resetUrl=(props)=>{
        if (props.match.params.reportId === "new" && props.dataView.reportId !== 0) {
            this.isUpdate = props.dataView.reportId;
            this.props.history.push(`/Admin/DataViews/${props.dataView.reportId}`);
          
        }

    }
    checkSaveCondition = () => {
        if (this.state.clickSave) {
            if (this.state.checkExecuteQuery) {
                if (this.props.successExecuteQuery) {

                    this.saveDataView(true);
                }
            } else {

                if (this.props.dataView.query) {
                    if (!this.props.querySettings.hasError) {
                        this.saveDataView(true);
                    } else {
                        this.setState({ clickSave: false });
                        showError(this.props.dispatch, "No valid fields found, please check the data source.", 5000)
                    }
                } else {
                    this.saveDataView(true);
                }
            }
        }

    }

    confirmYes = (value) => {
        hideError(this.props.dispatch);

        this.setState({ clickSave: true });
        this.saveDataView(false);
    }

    confirmNo = () => {
        hideError(this.props.dispatch);
    }
    saveDataView=(isValidate) => {
        let dataView = cloneDeep(this.props.dataView);
        //dataView.reportLink = this.props.reportLink;

        let action = { primaryAction: this.confirmYes, SecondaryAction: this.confirmNo, value: 'linkError', isValidate: isValidate }
        this.props.dispatch(updateDataView(dataView, this.props.objectList, action));

        this.setState({ clickSave: false });
    }


    render() {
        const { classes } = this.props;
       this.resetUrl(this.props)
        this.checkSaveCondition();
        
        return (
            <Grid container direction="row" justifyContent="space-between" spacing={2}>
                <Grid item xs={2}>
                    <Typography variant="h4">{this.isUpdate ? "Edit" : "Add"} Data View</Typography>
                        {this.props.actionType === actionTypes.VGO_UPDATE_DATAVIEW_REQUEST && <ActionBackdrop />}
                        
                </Grid>
                <Grid item className={classes.reportName} xs={8}>
                    <Typography variant="h5">{this.props.dataView.reportName ? this.props.dataView.reportName:""}</Typography>
                    </Grid>

                <Grid item xs={2}>
                    <Grid container direction="row" justifyContent="flex-end" spacing={1} >
                        <Grid item>
                            <Button className={`${classes.customControlButton} ${classes.buttonCancel}`}
                                variant="defaultContained"
                               disableElevation
                            onClick={this.onDiscardClick}
                        >CANCEL</Button>
                        </Grid>
                        <Grid item>
                            <Button className={`${classes.customControlButton} ${classes.buttonSave}`}
                            color="primary"
                            variant="contained"
                            disableElevation
                            startIcon={<CheckIcon />}
                            onClick={this.onSaveClick}
                        >SAVE</Button>
                    </Grid>
                    </Grid>
            </Grid>
                </Grid>

            
        );
    }

}

/**
 * mapStateToProps
 * @param {State} state 
 */
function mapStateToProps(state) {
    let props = {
        alert: state.alert,
        dataView: state.dataViews.dataView,
        objectList: state.dataViews.objectListByRole,
        actionType: state.loader.type,
        successExecuteQuery: state.dataViews.successExecuteQuery,
        querySettings: state.dataViews.querySettings,
    };

    return props;
};

export default withRouter(connect(mapStateToProps)(withStyles(setupTheme)(DataViewEditHeader)));
