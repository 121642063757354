import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'client/store/ContentDetail';
import { Loading } from 'client/components/Loading';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import ThumbnailImage from './ThumbnailImage'
import { withStyles } from '@mui/styles';
import { Box, Grid, Typography } from '@mui/material';
import { contentLayoutTheme } from 'client/components/ContentLayout/theme/contentLayoutTheme'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ContentList from './ContentList'
import { Divider } from '@mui/material';
import { Scrollbars } from 'react-custom-scrollbars';
import { setTabTitle } from 'client/components/Common/Utility';
import { getDeviceType } from 'client/components/Common/Utility';
import * as Enums from 'client/components/Common/Enum';
const conentType = { Event: "event", Article: "article", Announcement: "announcement" }
const cookies = new Cookies();
class contentDetail extends React.Component {


    componentDidMount() {
        let versagoCookie = cookies.get('Versago');
        if (versagoCookie === undefined) {
            this.props.requestPublicContentById(this.props.match.params.id);
        } else {
            this.props.requestContentById(this.props.match.params.id);
        }


    }
    componentDidUpdate(nextProps) {
        if (this.props.match.params.id !== nextProps.match.params.id) {
            let versagoCookie = cookies.get('Versago');
            if (versagoCookie === undefined) {
                this.props.requestPublicContentById(this.props.match.params.id);
            } else {
                this.props.requestContentById(this.props.match.params.id);
            }
        }
    }

    createMarkup(text) { return { __html: text }; }

  

    renderImage(contentId) {
        return (
             <ThumbnailImage contentId={contentId} />
        );
    }

    renderCommonContent = (contentItem) => {
        const classes = this.props.classes;
         if (contentItem.type === conentType.Announcement || contentItem.type === conentType.Article) {
            if (contentItem.thumbnailImageFilepath !== null) {

                return (

                    <Box>
                        <Box pr={2.4}> {this.renderImage(contentItem.id)}</Box>
                        
                        <p dangerouslySetInnerHTML={this.createMarkup(contentItem.content)} />
                        </Box>
                        )

            } else {
                return <p dangerouslySetInnerHTML={this.createMarkup(contentItem.content)} />;
            }
        } else {
            const date = new Date(contentItem.eventDate);
            const month = date.toLocaleString('en-us', { month: 'short' });
            const day = date.toLocaleString('en-us', { day: '2-digit' });

            return (
                <Box>
                    <div className={classes.articleDate}>
                        <div className={classes.articleDateMonth}> {month} </div>
                        <div className={classes.articleDateDay}>
                        {day}
                    </div>
                    </div>
                    <p dangerouslySetInnerHTML={this.createMarkup(contentItem.content)} />
                    <p />
                </Box>

                    )


        }
    }
     
    renderLayout = (contentItem, nextItem, prevItem) => {
        const classes = this.props.classes;
        const deviceType = getDeviceType();
        return (<Box className={classes.header} pb={2} >
            <Grid container spacing={0} justifyContent="flex-start" alignItems="flex-start"  >
                <Grid item xs={deviceType === Enums.DeviceType.Mobile ? 1.5 : 1} lg={1} >
                    <Box pl={1} pt={deviceType === Enums.DeviceType.Mobile ? 1 : .5}  > <Link to="/#ContentLanding?otype=C2">
                        <HomeOutlinedIcon className={classes.homeIcon} color="primary" /></Link></Box>
                </Grid>
                <Grid item xs={10} lg={10} className={classes.contentdetailContainer} >

                    <Typography className={classes.headerH2}> {contentItem.subject}</Typography>

                </Grid>
                <Grid item xs={12}  >

                    <Typography variant="body2" className={classes.articleMetaDl}>
                        <span>Posted</span>
                        <span className={classes.articleMetaDD}> {contentItem.modifiedDate} </span>
                        <span>By</span>
                        <span className={classes.articleMetaDD}>{contentItem.modifiedUser}</span>
                    </Typography>

                </Grid>
                <Box sx={{ width: 1 }} pl={0} pr={0}><Divider /></Box>
                <Grid container>

                    <Grid item xs pl={5}>
                        <Scrollbars className={classes.layoutContainer}>
                            <Box className={classes.layoutBody}>

                                {this.renderCommonContent(contentItem)}

                            </Box>
                        </Scrollbars>
                        <Box sx={{ width: 1 }}><Divider /></Box>
                        {prevItem !== null && this.renderPreviousComponent(prevItem)}
                        {nextItem !== null && this.renderNextComponent(nextItem)}
                    </Grid>
                    <Grid item className={classes.wrapperRight}>
                        <ContentList />
                    </Grid>
                </Grid>
            </Grid>
        </Box>) 
        
    }

        renderContent = (contentItem, nextItem, prevItem) => {
                     
            setTabTitle(contentItem.subject, true);
            return (
                <div>

                    {this.renderLayout(contentItem, nextItem, prevItem)}
                </div>
            );
        }

    renderNextComponent = (nextContent) => {
        const classes = this.props.classes;
        return (
            <div className={classes.nextContainer}>
                    <header className={classes.widgetHead} >
                        <h4>Next Post</h4>
                    </header>
                    <div className={classes.widgetBody}>
                        <ul className={classes.listRecentArticles}>
                            <li>
                                <small className={classes.listRecentArticlesSmall} >
                                    {nextContent.modifiedDate}
                                </small>
                                <Link className={classes.recentLink} to={"/Content/" + nextContent.id + "/" + nextContent.isPreLogin}>{nextContent.subject}</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            );
        }

    renderPreviousComponent = (previousContent) => {
        const classes = this.props.classes;
        return (
            <div className={classes.previewContainer}>
                    <header className={classes.widgetHead}>
                        <h4>Previous Post</h4>
                    </header>
                    <div className={classes.widgetBody}>
                        <ul className={classes.listRecentArticles}>
                            <li>
                                <small className={classes.listRecentArticlesSmall}>
                                    {previousContent.modifiedDate}
                                </small>
                                <Link className={classes.recentLink} to={"/Content/" + previousContent.id + "/" + previousContent.isPreLogin}>{previousContent.subject}</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            );
        }

        render() {

            return (
                <Box >
                    <Box className={this.props.classes.loadingContainer }> <Loading loading={this.props.isLoading} /></Box>
                    
                    {this.props.contentDetail && this.renderContent(this.props.contentDetail.contentDetails, this.props.contentDetail.nextContent, this.props.contentDetail.previousContent)}
                </Box>

            );

        }
    }
    export default connect(state => state.contentDetail, dispatch => bindActionCreators(actionCreators, dispatch)) (withStyles(contentLayoutTheme)(contentDetail));

