import { customThemeStyles } from 'common/theme.js';
export const highlightTheme = (theme) => ({
    ...customThemeStyles,

    dialog: {
        minWidth: '808px!important',
        maxWidth: '808px!important',
        minHeight: '397px!important',
        maxHeight: '397px!important',
    },

    dialogChart: {
        minWidth: '684px!important',
        maxWidth: '684px!important',
        minHeight: '397px!important',
        maxHeight: '397px!important',
    },

    alertGrid: {
        marginBottom: '2px!important'
    },

    relationStyle: {
        width: '122px!important',
        marginTop: '12px!important',

    },

    value1Style: {
        width: '182.04px!important',
        padding: '0px!important',
        paddingTop: '12px!important',
          },
    value2Style: {
        width: '169px!important',
        paddingTop: '12px!important',
    },
    displayStyle: {
        width: '122px!important',
        marginTop: '12px!important',
    },
    colorStyle: {
        width: '24px!important',
        height: '24px!important',
        flexDirection: 'row!important',
        marginBottom:'12px!important'
    },
    buttonGrid: {
        textAlign: 'right!important',
       
    },
    wrapper: {
        width: "188px!important",
        paddingTop: '0px!important'

    },
    root: {
        paddingBottom: "2px!important",
        paddingTop: "2px!important"
    },
    padding: {
        paddingTop: "4px!important"
    },

     MUIDataTable: {
        overflow: 'auto!important',
        width: '100%!important',
        minWidth: '760px!important',

    },
    MUIDataTableChart: {
        overflow: 'auto!important',
        width: '100%!important',
        minWidth: '628px!important',

    },
    deleteButton: {
        marginBottom: '8px!important',
        
    }


});