import * as yup from "yup";
import { homePageType } from 'admin/actions/homePageActions';
export const homePageValidationSchema = yup.object().shape({
    title: yup.string().required("Title is required"),
    content: yup.string().required("Content is required"),
    subType: yup.string()
        .test('isTypeEmpty',
            'Type is required',
            function (value) {
                return value === "0" || value === 0 ? false : true;
            })
        .required("Type is required"),
    detailDisplayType: yup.string()
        .test('isDetailDisplayType',
            'Click prompt is required',

            function (value, context) {
                const { parent } = context;


                return (value === "0" || value === 0) && parent.homePageType === homePageType.Banner ? false : true;

            })
        .required("Click prompt is required"),
    roles: yup.array().min(1, 'Roles is required'),
    
    contentEventDate: yup.string()
        .test('iscontentEventDate', 'Event date is required',
            function (value, context) {
                const { parent } = context;

                if (parent.homePageType === homePageType.Feed && parent.subType === "2") {
                    return (value === null || value === "" || value === "Invalid Date") ? false : true;
                }
                else
                {
                    return true

                }

            }).nullable()
        .required("Event date is required"),
    expiryDate: yup.string()
        .test('isExpiryDate',
            'Expiration is required',
            function (value, context) {

                return (value === null ||value === "" || value === "Invalid Date") ? false : true;

            }).nullable()
       
})