import React, { useRef } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Toolbar, Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import { chartTheme } from 'client/components/Charts/theme/chartTheme';
import { withStyles } from '@mui/styles';

const ChartPopup = ( props) => {
    const [open, setOpen] = React.useState(false);
    const printRef = useRef(null);
    const closeRef = useRef(null);

    const handleClickOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }

    const handleClickPrint = () => {
        printRef.current.style.display = "none";
        closeRef.current.style.display = "none";
        let dataListElement = document.getElementById('idDataView');
        if (dataListElement) {
            dataListElement.style.display = "none";
        }
        
        if (type === "funnel")
            document.getElementById('idLoginPanel').style.display = "none";
        window.print();
        if (type === "funnel")
            document.getElementById('idLoginPanel').style.display = "block";
        if (dataListElement) {
            dataListElement.style.display = "block";
        }
        closeRef.current.style.display = "block";
        printRef.current.style.display = "block";
    }

    const { chart, type, classes } = props;
    const style = (type === "funnel") ? { width: "100%",} : { display: 'flex', width: "83%", };
    return (
        <React.Fragment>
            <IconButton onClick={handleClickOpen}> <OpenInNewIcon /> </IconButton>
            <Dialog fullScreen open={open} onClose={handleClose} disableScrollLock={true}>
                <Toolbar>
                    <Box sx={{ ml: 2, flex: 1 }} />
                    <IconButton ref={printRef} onClick={handleClickPrint}> <PrintOutlinedIcon /> </IconButton>
                    <IconButton ref={closeRef} onClick={handleClose}> <CloseIcon /> </IconButton>


                </Toolbar>
                <DialogContent >
                    <Box className={classes.chartPopup} style={style}>
                        {chart}
                    </Box>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}

export default withStyles(chartTheme)(ChartPopup);