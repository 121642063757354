import React from 'react';
import { makeStyles } from '@mui/styles';
import Alert from '@mui/lab/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import  CircularProgress  from '@mui/material/CircularProgress';
import { setupTheme } from 'admin/components/section/dataViews/setup/theme/setupTheme';
const useStyles = makeStyles((theme) => (setupTheme(theme)));

export default function QueryDependencyAlert(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);

    return (
        <div className={classes.dependencyRoot} >
            <Collapse in={open} classes={{ wrapperInner: classes.dependencyCollapse}}>
                <Alert
                    className={classes.alert}
                    severity="error"
                    action={
                        <React.Fragment>
                            <Button color="inherit" size="small" onClick={() => { props.showDependency() }}>
                                VIEW
                            </Button>
                            {props.showBusy && <CircularProgress size={24} className={classes.dependencyButtonProgress}  />}
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                </IconButton>
                </React.Fragment>
                    }
                >
                    {"Be aware, other Versago content depends on this data view"}
                </Alert>
            </Collapse>

        </div>
    );
}