import React from 'react';
//import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import * as Enumerator from 'client/components/Common/Enum';
const successButtonStyle = "btn btn-primary successButtonStyle";
const errorButtonStyle = "btn btn-primary errorButtonStyle";
class message extends React.Component {

    componentDidUpdate(nextProps) {
        if (this.props.showMessage !== nextProps.showMessage) {
            this.setState({ showModel: this.props.showMessage });
        }
    }

    handleContinue = () => {
        this.props.handleContinue();
    };

    handleClose = () => {
        this.props.handleClose();
    };

    button = () => {
        if (this.props.buttonType === Enumerator.ButtonType.OkCancel) {
            return (<div><Button className={successButtonStyle} onClick={this.handleContinue} >{this.props.okButtonName}</Button><Button className={errorButtonStyle} onClick={this.handleClose} >{this.props.cancelButtonName} </Button></div>)
        }
        else if (this.props.buttonType === Enumerator.ButtonType.Cancel) {
            return <Button className={errorButtonStyle} onClick={this.handleClose} >{this.props.cancelButtonName} </Button>
        } else {
            return <Button className={successButtonStyle} onClick={this.handleContinue} >{this.props.okButtonName}</Button>;

        }
    }
    
    render() {
       

        return (
            <div key="message">
                <Dialog className="messageDialog" open={this.props.showMessage} aria-labelledby="customized-dialog-title">
                    <DialogTitle className="messageTitle">{this.props.messageTitle}</DialogTitle>
                    <MuiDialogContent dividers> <span className="messageTextStyle">
                        {this.props.message}</span>
                    </MuiDialogContent>

                    <DialogActions>
                        {this.button()}
                    </DialogActions>
                </Dialog>
            </div>
        );

    }
}
export default message