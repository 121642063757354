import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {  Grid, Typography,Box, IconButton } from '@mui/material';
import {  DropTarget } from 'react-drag-drop-container';
import { showError } from 'admin/actions/alertActions';
import AddBoxSharpIcon from '@mui/icons-material/AddBoxSharp';
import { modifyNavMenuStructure, modifyNavAndQuickActionMenuStructure, changeMenuItemName, setSelectedMenuItem, clearSelection } from 'admin/actions/menuActions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeView} from '@mui/lab';
import NavMenuItem from './NavMenuItem';
import { menuTheme } from './theme/menuTheme';
import { withStyles } from '@mui/styles';
import * as Enums from 'client/components/Common/Enum';


class NavMenuStructure extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            dragStart: false,
            expanded: [],
            itemSelected: [],
            groupId: 10000,

        };
    }

    updateMenuStructure = (dragMenu, dropMenu, addMenuGroup, moveMenu, deleteMenu) => {
        this.props.dispatch(modifyNavMenuStructure(dragMenu, dropMenu, addMenuGroup, moveMenu, deleteMenu));
    }


    handleMainMenuNodeToggle = (event, nodeIds) => {

        let iconClicked = event.target.closest(".MuiTreeItem-iconContainer")
        if (iconClicked) {
            this.setState({
                expanded: nodeIds,
            });
        }
    };
    handleMainMenuNodeSelect = (menuItem) => {
        this.props.dispatch(setSelectedMenuItem(menuItem));
    };
    handleDropMainMenu = (event) => {
        const { dragData, dropData } = event;
        if (dropData.mode === Enums.MenuItemMode.MainMenu) {
            if (dropData.add !== "new") {
                this.updateMenuStructure(dragData, dropData, false, true, false);
            }
            else {
                this.updateMenuStructure(dragData, dropData, false, false, true);
            }
        }
        else if (dropData.mode === Enums.MenuItemMode.QuickAction && dragData.isMenu) {
            //adding from nav to QuickAction
            const hasItem = dropData.quickActions ? dropData.quickActions.findIndex(item => item.id === dragData.id && item.contentType === dragData.contentType) : -1;
            if (hasItem >= 0) {
                showError(this.props.dispatch, 'This item is already in the Menu', 5000);

            }
            else {
                this.props.dispatch(modifyNavAndQuickActionMenuStructure(dragData, dropData, dropData.add === "new", dropData.add !== "new", false));
            }
            
        }
        
        
    };

    handleDeleteMainMenu = (menuItem) => {
        this.updateMenuStructure(menuItem,undefined, false, false, true);
    }
    handleBlurMainMenu = (menuItem,label) => {
        this.props.dispatch(changeMenuItemName(label, menuItem,1));
    }
    handleDragEndMainMenu = (event) => {

    }
    renderChildNode = (nodes, menuType, level, menuIndex, parent, objectList) => {
        let contentType = (nodes.contentType === 0) ? "" : nodes.contentType;
        let contentSubType = (nodes.contentSubType === 0) ? "" : nodes.contentSubType;
        if (nodes.isMenu) {
            objectList.push({ id: nodes.id, type: nodes.contentType })
        }
        const nodeId = `${nodes.id}|${contentType}`;
        let parentNode;
        if (level === 1) {
            parentNode = { ...parent };
        }
        else
        {
            parentNode = { id: nodeId, level: level , index: menuIndex, parent: { ...parent }, isMenu: nodes.isMenu };
        }
        return (
            <NavMenuItem
                id={nodes.id}
                keyId={"key_" + nodes.id}
                nodeId={nodeId}
                labelText={nodes.label}
                color="#1a73e8"
                bgColor="#1a73e8"
                targetKey="mainMenu"
                contentTypeText={contentType}
                contentSubTypeText={contentSubType}
                menuType={Enums.MenuItemMode.MainMenu}
                items={nodes.items}
                item={nodes}
                level={level}
                index={menuIndex}
                isMenu={nodes.isMenu}
                parent={level === 1 ? false : parent}
                objectList={objectList}
                quickActions={this.props.menu.quickActions}
                onDeleteMainMenu={this.handleDeleteMainMenu}
                onBlurMainMenu={this.handleBlurMainMenu}
                onDropMainMenu={this.handleDropMainMenu}
                onSelectMainMenu={this.handleMainMenuNodeSelect}
            >
                {
                    
                    nodes.items.map((node, index) => {
                    
                    return (
                        <div key={index}>{this.renderChildNode(node, "", level + 1, index, parentNode, objectList)}</div>
                    )
                })}
            </NavMenuItem>
        )
    };

    renderParentNode = (nodes, menuType) => {
        const level = 1;
        let objectList = [];
        let treeSettings = {}
        treeSettings.nodeList =(
            <div>
                {nodes.map((node, index) => {
                    let parent = { id: `${node.id}|`, level: level, index: index, isRoot: true, isMenu: node.isMenu };
                    if (node.isMenu) {
                        objectList.push({ id: node.id, type: node.contentType})
                    }
                    return (
                        <div key={index}>{this.renderChildNode(node, menuType, level, index, parent, objectList)}</div>
                    )
                })}
            </div>
        )
        treeSettings.objectList = objectList;
        return treeSettings;
    };

    addMenuGroup = () => {
        
            const newGroup = {
                item: {
                    contentSubType: 0,
                    contentType: 0,
                    id: this.props.menuGroupId,
                    items: [],
                    label: "Untitled",
                    isMenu: false,
                }
            }
        if (this.props.selectedMenuItem.itemSelected.length === 0)
            {
                this.updateMenuStructure(newGroup, undefined, true, false, false);
            }
            else
            {
            if (!this.props.selectedMenuItem.treeViewSelected.isMenu && this.props.selectedMenuItem.treeViewSelected.level <=2 ) {
                this.updateMenuStructure(newGroup, this.props.selectedMenuItem.treeViewSelected, true, false, false);
                }
            }
        
       
    }
    onClickMenuFooter = (event) => {
        if (this.props.selectedMenuItem.itemSelected.length !== 0) {
            this.props.dispatch(clearSelection());
        }
        

    }
    render() {
        const { classes } = this.props;
        const treeStructure = this.renderParentNode(this.props.menu.menuStructure, "structure");
        const dropData = { mode: Enums.MenuItemMode.MainMenu, add: "new", menuStructure: this.props.menu.menuStructure, objectList: treeStructure.objectList };
        
        let selectedNodeId ='';
        if (this.props.selectedMenuItem.itemSelected.length > 0) {
            const node = this.props.selectedMenuItem.itemSelected[0].split('|');
            selectedNodeId = `key_${node[0]}`
        }
        
        return (
           <Box >
                    
                    <Grid container>
                    <Grid item xs={11} onClick={this.onClickMenuFooter}><Typography variant="body1" className={classes.menuColor}>Main Menu</Typography></Grid>
                        <Grid item xs={1} className={classes.buttonStyle}>
                            <IconButton type="button" color="primary" fontSize="large" onClick={this.addMenuGroup} >
                                <AddBoxSharpIcon fontSize="large" className={classes.addIcon} />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Box  pl={1} >
                    <div id="super1" style={{ height: "368px", display: "flex", flexDirection: "column" }} >
                        <div id="top1" >

                            <TreeView
                                sx={{ maxHeight: 383, overflowX: 'auto', backgroundColor: "#fff", paddingBottom:1 }}
                                defaultCollapseIcon={<ExpandMoreIcon className={classes.expandIcon} style={{ display: (this.state.dragStart) ? "none" : "block" }} />}
                                defaultExpandIcon={<ChevronRightIcon className={classes.expandIcon} style={{ display: (this.state.dragStart) ? "none" : "block" }} />}
                                expanded={this.state.expanded}
                                selected={[selectedNodeId]}
                                onNodeToggle={this.handleMainMenuNodeToggle}

                            >
                                {treeStructure.nodeList}
                            </TreeView>

                        </div>
                        <div id="bottom1" style={{ flex: 1 }} onClick={this.onClickMenuFooter}>
                            <DropTarget targetKey="content" dropData={dropData}>
                                <DropTarget targetKey="mainMenu" dropData={dropData}>
                                    <DropTarget targetKey="quickAction" dropData={dropData}>
                                        <Box id="mainMenuTreeView" sx={{ height: "100%", paddingBottom: 5 }}  />
                                    </DropTarget>
                                </DropTarget>
                            </DropTarget>
                        </div>
                    </div>
                                    
                                
                    </Box>

            </Box>
        );
    }
}
function mapStateToProps(state) {
    return {
        menus: state.menus.menus,
        menu: state.menus.menu,
        menuGroupId:state.menus.groupId,
        roles: state.roles,
        objects: state.menus.objects,
        isLoading: state.loader.isLoading,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage,
        isUpdateSuccess: state.menus.isUpdate,
        alert: state.menus.alert,
        selectedMenuItem: state.menus.navMenuSelectedNode,
    };
};

export default withRouter(connect(mapStateToProps)(withStyles(menuTheme)(NavMenuStructure)));