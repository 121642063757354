import * as yup from "yup";
import * as Enums from 'client/components/Common/Enum';


export const recordProcessingCCSchema = yup.object().shape({
    cardHolderName: yup.string().nullable().trim().required("Card Holder Name is required"),
    cardType: yup.string().nullable(), 
    cardNumber: yup.string().nullable().trim().when('cardType', (cardType) => {
        console.log("Validation cardnumber", cardType);
        return yup.string().nullable().required("Card Number is required").test('isCardNumberTypeMatch', "The card number doesn't match the selected card type",
            function (value) {
                if (value !== null) {
                    if (cardType === undefined) {
                        cardType = this.options.context.cardType
                    }
                    if (cardType === Enums.CreditCardType.Visa && value.slice(0, 1) !== '4') {
                        return false;
                    }
                    else if (cardType === Enums.CreditCardType.Master && value.slice(0, 1) !== '5') {
                        return false;
                    }
                    else if (cardType === Enums.CreditCardType.Amex && value.slice(0, 1) !== '3') {
                        return false;
                    }
                    else if (cardType === Enums.CreditCardType.Discover && value.slice(0, 1) !== '6') {
                        return false;
                    } 
                }
                return true;
                })
            
        }),
    expire: yup.string().nullable().trim().required("Card Expiration is required")
        .test('isExpDateFormat', 'Expiration date must be in MM/YY format', function (value) {
            if (value !== null) {
                var RegExp = /(0\d{1}|1[0-2])\/\d{2}/g;
                if (!RegExp.test(value)) {
                    return false;
                }
                var expDate = value.split('/');
                if (expDate.length !== 2) {
                    return false;
                }
                else {
                    if (expDate[0] > 12) {
                        return false;
                    }
                    if (expDate[0].length !== 2) {
                        return false;
                    }
                    else {
                        if (isNaN(expDate[0])) {
                            return false;
                        }
                    }
                    if (expDate[1].length !== 2) {
                        return false;
                    }
                    else if (isNaN(expDate[1])) {
                        return false;
                    }
                }
            }
            return true;
          })
        .test('isExpDateValid', 'The month and year entered cannot be earlier than the current month and year', function (value) {
            if (value !== null) {
                var year = new Date().getFullYear();
                var expDate = value.split('/');
                if (parseInt("20" + expDate[1]) < year) {
                    return false;
                }
                else if (parseInt("20" + expDate[1]) === year) {
                    var month = new Date().getMonth();
                    if (parseInt("20" + expDate[0]) < (month + 1)) {
                        return false;
                    }
                }
            }
            return true;
    }),
    cvv: yup.string().nullable().when("cardType",
        (cardType) => {
            return yup.string().nullable().required("Security Code is required").test('iscvvCardType', 'Security code does not match the selected card type',
                function (value) {
                    if (value !== null) {
                        if (cardType === undefined) {
                            cardType = this.options.context.cardType
                        }
                        if (cardType === Enums.CreditCardType.Amex) {
                            if (value === null || value.length !== 4) {
                                return false;
                            }
                        }
                        else {
                            if (value === null || value.length !== 3) {
                                return false;
                            }
                        }
                    }
                    return true;
                })
        }),
    address1: yup.string().nullable().trim().required("Address is required "),
    city: yup.string().nullable().trim().required("City is required "),
    state: yup.string().nullable().trim().required("State is required "),
    zip: yup.string().nullable().trim().required("Zip is required "),
    country: yup.string().nullable().trim().required("Country is required "),
    
});

export const recordProcessingECSchema = yup.object().shape({
    bankName: yup.string().nullable().trim().required("Bank Name is required"),
    firstName: yup.string().nullable().required("First Name or Company Name is required"),
    lastName: yup.string().nullable().when("accountType",
        (accountType) => { 
            return yup.string().nullable().test('lastName', 'Last Name is required',
                function (value) {
                   
                        if (accountType === undefined) {
                            accountType = this.options.context.accountType
                        }
                        if (accountType === Enums.ReportPaymentAccountType.Personal) {
                            if (value === null || value.trim().length === 0) {
                                return false;
                            }
                        }
                     
                    return true;
                })
        }),
    accountNumber: yup.string().nullable().required("Account Number is required"),
    address1: yup.string().nullable().trim().required("Address is required "),
    city: yup.string().nullable().trim().required("City is required "),
    state: yup.string().nullable().trim().required("State is required "),
    zip: yup.string().nullable().trim().required("Zip is required "),
    routingNumber: yup.string().nullable().trim().test('isRoutingNumber', 'Routing Number must be 9 digits', function (value) {
        console.log("validation routing Number", value);
        if (value === null || value.length !== 9) {
            return false;
        } return true;
    }).required("Routing Number is required "),

});



