import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Grid, Typography, Card, CardContent } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import StarOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import StarIcon from '@mui/icons-material/Star';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import { objectHeaderTheme } from './theme/layoutObjectHeaderTheme';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import * as Enumerator from 'client/components/Common/Enum';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { favoriteActionCreator } from 'client/store/Favorite';
import { alertHandlerAction } from 'client/store/AlertHandler';

import { useHistory } from "react-router";
import { useState } from 'react'; 
import AppBar from '@mui/material/AppBar';
 
import { getDeviceType } from 'client/components/Common/Utility'
const deviceType = getDeviceType();

const menuType = Enumerator.MenuType;
const useStyles = makeStyles({
    ...objectHeaderTheme
});
function LayoutObjectHeader(props) {
    let removeObjectId = props.objectDetails ? props.objectDetails.removedObjectId :0;
    const classes = useStyles(props);
     const history = useHistory();
     const [showAlert, setAlert] = useState({ status: false, id: 0 });
     React.useEffect(() => {
         if (showAlert.status) {
             setAlert({ status: false, id: props.objectDetails.removedObjectId });
             showDeleteAction();
             
         }
     }, [showAlert]);

    
     const getRightIcon = () => {
         const dataView = "Data View";
        
         if (props.loadType === "1")//Blank
         {
             return <div><CloseIcon className={classes.homeIcon} color="primary" onClick={() => { windowClose() }}></CloseIcon></div>
         }
         else if (props.loadType === "2")//self
         {

             return <div><ArrowCircleLeftIcon className={classes.homeIcon} color="primary" onClick={() => { goBack() }}></ArrowCircleLeftIcon></div>
         }
         else
         {
             const objectRequestStatus = props.objects.actionStatus;
             if (objectRequestStatus === "REQUEST_OBJECTS") {
                 return <StarOutlinedIcon fontSize="large" className={classes.favoriteIcon} color="primary" />
             }
             const favorites = props.objects.favorites;
             const favId = removeObjectId ? removeObjectId : props.objectDetails.objectId;
             const favType = (props.objectDetails.objectType === menuType.Report) ? dataView.toLowerCase() : props.objectDetails.objectType.toLowerCase();
             var favIndex = favorites.findIndex(fav => fav.id === favId && fav.type.toLowerCase() === favType);
             if (favIndex >= 0) {
                 if (removeObjectId && (!showAlert.status && showAlert.id !== removeObjectId) ) {
                     setAlert({ status: true, id: removeObjectId });
                     //return <StarIcon className={classes.favoriteIcon} color="primary" />
                     return <div/>
                     
                     
                 }
                 else
                 {
                     if (!removeObjectId) {
                         return <StarIcon fontSize="large" className={classes.favoriteIcon} color="primary" onClick={() => { setFavorite(false) }} />
                     }
                     return <div />
                 }
             }
             else
             {
                 if (removeObjectId) {
                     //return <StarOutlinedIcon className={classes.favoriteIcon} color="primary"  />
                     return <div />
                 }
                 else {
                     return <StarOutlinedIcon fontSize="large" className={classes.favoriteIcon} color="primary" onClick={() => { setFavorite(true) }} />
                 }
                 
             }
         } 
        
    }
    const windowClose = () => {
        props.pageAction(history, true);
    } 
    const goBack = () => {
        
        props.pageAction(history, false);
    }
     const setFavorite = (addFavorite) => {
         props.actions.favoriteHandler.setFavorite(props.objectDetails, addFavorite);
     }
     const showDeleteAction = () => {
         let objectType = props.objectDetails.objectType === menuType.Form ? "form" : props.objectDetails.objectType === menuType.Dashboard ? "dashboard" : "dataview";
         props.actions.alertHandler.showErrorMessageWithAction
             (`This ${objectType} has been deleted, or you no longer have access to it. Would you like to remove it as a favorite?`,
                 [removeFavorite, props.actions.alertHandler.hideAlert]);
         
        
     }
     const removeFavorite = () => {
         
         props.actions.favoriteHandler.setFavorite(props.objectDetails, false, props.objectDetails.removedObjectId);
    }
    if (!removeObjectId) {
        let menuCollapsed = props.layoutObjects.isMenuCollapsed;
        let isMobile = deviceType === Enumerator.DeviceType.Mobile ? true : false;
        let isTablet = deviceType === Enumerator.DeviceType.Tablet ? true : false;
        return (
           
            <Grid container spacing={0} mb={isMobile ? 4 : 8}>
               
                <AppBar  >
                    <Grid item xs={12} lg={12} className={classes.headerLayOut} id="divHeader" >
                        <Card variant="outlined" className={classes.cardHome}>
                        <CardContent className={classes.cardHome}>
                            <Box className={classes.header} pb={2} >
                                    <Grid container pl={menuCollapsed ? 8 : isMobile?8:38} spacing={0} justifyContent="flex-start" alignItems="flex-start"  >
                                    <Grid item xs={1} lg={2} >
                                            <Box > <Link to="/#ContentLanding?otype=C">
                                            <HomeOutlinedIcon className={classes.homeIcon} color="primary" /></Link>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={10} lg={8} className={classes.heading} >

                                              <Typography className={classes.title}  > {props.title} </Typography>  

                                    </Grid>
                                    <Grid item xs={1} lg={2}  >
                                        <Box pr={0} className={classes.favoriteIcon} >
                                            {getRightIcon()}
                                        </Box>

                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card> 
                   
                    </Grid>
                </AppBar>
                </Grid>
               
        );
    }
    else {
        return (<div>
            {getRightIcon()}
        </div>);

    }
}
function mapDispatchToProps(dispatch) {
    return {
        actions: {
            favoriteHandler: bindActionCreators(favoriteActionCreator, dispatch),
            alertHandler: bindActionCreators(alertHandlerAction, dispatch) 
        }
    }
}
const mapStateToProps = (state) => {
    return {
        objects: state.objects,
        layoutObjects: state.layout,
    }
} 
export default connect(   
  state => mapStateToProps,
    dispatch => mapDispatchToProps
)(LayoutObjectHeader);
