import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import { spacing } from '@mui/system';
import { withStyles } from '@mui/styles';
import MUIDataTable from 'mui-datatables';
import { subRecordsTheme } from './theme/subRecordsTheme';
import { Box, Grid, IconButton, Typography} from "@mui/material";
import MuiButton from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import MuiTextField from '@mui/material/TextField';
import { getDataViewSubRecordFields, updateDataViewsFieldsLink, deleteDataViewsFieldsLink, getDataViewSubRecord, updateValidationMessage, clearSubRecord } from 'admin/actions/dataViewSubRecordsActions';
import { getControlTypeSubRecord, getDataViewListAndFormListByRoleList } from 'admin/actions/dataViewActions';
import Delete from '@mui/icons-material/DeleteOutline';
import { ActionTypes as actionTypes } from 'admin/actions/actionTypes';
import CircularProgress from '@mui/material/CircularProgress';
import UndoIcon from '@mui/icons-material/Undo';
import { sortName } from 'client/components/Common/Utility';

const TextField = styled(MuiTextField)(spacing);
const Card = styled(MuiCard)(spacing);
const Button = styled(MuiButton)(spacing);

let dataViewField = [];
let subRecordField = [];
const SubRecords = (props) => {
    const { classes } = props;

    useEffect(() => {


        let roleList = "";
        for (let i = 0; i < props.dataView.roles.length; i++) {
            roleList = `${roleList}roleIdList[${i}]=${props.dataView.roles[i].roleId}&`
        }

        if (roleList !== props.objectList.roles && roleList) {
            props.dispatch(getDataViewListAndFormListByRoleList(roleList));
        }

        props.dispatch(getDataViewSubRecord(props.dataView.reportSubReport))
    }, []);

    const dispatchSubRecordsFieldsDetails = (subRecord) => {
        props.dispatch(getDataViewSubRecordFields(subRecord, props.subRecord));
    };

    const dispatchLinkFieldsUpdate = (rowIndex, columnIndex, value) => {
        props.dispatch(updateDataViewsFieldsLink(rowIndex, columnIndex, value, props.subRecord));
    };

    const dispatchLinkFieldsDelete = (rowIndex) => {
        if (props.subRecord.mappingFields.length - 1 !== rowIndex)
            props.dispatch(deleteDataViewsFieldsLink(rowIndex, props.subRecord));
    }

    const dispatchClear = () => {
        props.dispatch(clearSubRecord());
    }

    const validateDataType = (value, fromFirstField, rowIndex) => {
        const selectedFields = props.mappingFields[rowIndex];
        props.dispatch(updateValidationMessage(rowIndex, '', props.subRecord));
        if (fromFirstField) {
            dataViewField = props.reportFields.find((field, index) => field.displayFieldName === value);
            if (props.subRecordFields)
                subRecordField = props.subRecordFields.find((field, index) => field.fieldName === selectedFields.childFieldName);
        } else {
            dataViewField = props.reportFields.find((field, index) => field.fieldName === selectedFields.parentFieldName);
            if (props.subRecordFields)
                subRecordField = props.subRecordFields.find((field, index) => field.displayFieldName === value);
        }
        dataViewField = (dataViewField) ? dataViewField : [];
        subRecordField = (subRecordField) ? subRecordField : [];
        if ((dataViewField.fieldType) && (subRecordField.fieldType)) {
            const dataViewDataType = getControlTypeSubRecord(dataViewField.fieldType);
            const subRecordDataType = getControlTypeSubRecord(subRecordField.fieldType);


            if (dataViewDataType !== subRecordDataType) {
                props.dispatch(updateValidationMessage(rowIndex, 'Data type must match Data View field', props.subRecord));
            }
        }
    }

   const getSelectedFieldObject = (fieldName,sourceFieldList) => {

       const index = sourceFieldList.findIndex(field => field.fieldName === fieldName);
        if (index !== -1) {
            const item = sourceFieldList[index]

            return { fieldId: item.fieldId, fieldName: item.fieldName, displayFieldName: item.displayFieldName };
        } else {
            return { fieldId: 0, fieldName: '', displayFieldName: '' };
        }
    }


    // field
    const customRenderDataViewFields = (value, tableMeta, updatedValue) => {
        let reportFields = [];
        let index = -1;
        reportFields = props.reportFields.map((item) => {
            index = props.mappingFields.findIndex(p => p.parentFieldName === item.fieldName)
            if (index === -1) {
                return { fieldId: item.fieldId, fieldName: item.fieldName, displayFieldName: item.displayFieldName };
            } else {

                if (index !== tableMeta.rowIndex) {
                    return null
                } else {

                    return { fieldId: item.Id, fieldName: item.fieldName, displayFieldName: item.displayFieldName };
                }
            }
        }).filter(Boolean).sort((a, b) => sortName(a.displayFieldName, b.displayFieldName));
        return (<Autocomplete
            id="size-small-outlined"
            size="small"
            className={classes.customRender}
            fullWidth={true}
            options={reportFields}
            getOptionLabel={(option) => option.displayFieldName}
            value={tableMeta.rowData[tableMeta.columnIndex] && props.reportFields !== undefined ? getSelectedFieldObject(tableMeta.rowData[tableMeta.columnIndex], props.reportFields ) : null}
            onChange={(event, newValue) => {
                dispatchLinkFieldsUpdate(tableMeta.rowIndex, tableMeta.columnIndex, (!newValue) ? "" : newValue.fieldName)
            }}
            onBlur={(event) => {
                validateDataType(event.target.value, true, tableMeta.rowIndex);
            }}
            renderInput={params => (
                <TextField {...params}
                    id={"fieldName" + tableMeta.rowIndex}
                    size="small"
                    placeholder="Data View Field"
                    error={false} helperText={' '}
                />
            )}
        />)
    }

    const customRenderSubRecordFields = (value, tableMeta, updatedValue) => {
        let subRecordFields = [];
        let newsubRecordFields = (props.subRecord.reportName) ? props.subRecordFields : [];
        let index = -1;
        if (props.subRecordFields) {
            subRecordFields = newsubRecordFields.map((item) => {
                index = props.mappingFields.findIndex(p => p.childFieldName === item.fieldName)
                if (index === -1) {
                    return { fieldId: item.fieldId, fieldName: item.fieldName, displayFieldName: item.displayFieldName };
                } else {

                    if (index !== tableMeta.rowIndex) {
                        return null
                    } else {

                        return { fieldId: item.fieldId, fieldName: item.fieldName, displayFieldName: item.displayFieldName};
                    }
                }
            }).filter(Boolean).sort((a, b) => sortName(a.displayFieldName, b.displayFieldName));
        }
        return (<Autocomplete
            id="subRecordField"
            size="small"
            fullWidth={true}
            className={classes.customRender}
            options={subRecordFields}
            getOptionLabel={(option) => option.displayFieldName}
            value={tableMeta.rowData[tableMeta.columnIndex] && props.subRecordFields !== undefined ? getSelectedFieldObject(tableMeta.rowData[tableMeta.columnIndex], props.subRecordFields) : null}
            onChange={(event, newValue) => {
                dispatchLinkFieldsUpdate(tableMeta.rowIndex, tableMeta.columnIndex, (!newValue) ? "" : newValue.fieldName)
            }}
            onBlur={(event) => {
                validateDataType(event.target.value, false, tableMeta.rowIndex);
            }}

            renderInput={params => (
                <TextField {...params}
                    id={"displayFieldName" + tableMeta.rowIndex}
                    size="small"
                    placeholder="Sub Record Field"
                    error={props.mappingFields[tableMeta.rowIndex].error} helperText={props.mappingFields[tableMeta.rowIndex].helperText ? props.mappingFields[tableMeta.rowIndex].helperText : ' '}
                />
            )}
        />)
    }

    const deleteRowTemplate = (value, tableMeta, updateValue) => {
        return (
            <IconButton id={"delete" + tableMeta.rowIndex} type="button" className={classes.customRenderDelete} onClick={() => dispatchLinkFieldsDelete(tableMeta.rowIndex)}><Delete /></IconButton>
        );
    }



    const columns = [
        {
            name: "parentFieldName",
            label: "Data View Field",
            options: {
                setCellProps: () => ({ style: { width: 280, backgroundColor: 'white' } }),
                customBodyRender: customRenderDataViewFields,
                customHeadRender: () => null
            }

        },
        {
            name: "childFieldName",
            label: "Sub-Record Field",
            options: {
                setCellProps: () => ({ style: { width: 280, backgroundColor: 'white' } }),
                customBodyRender: customRenderSubRecordFields,
                customHeadRender: () => null
            }
        },
        {
            name: "delete",
            label: " ",
            options: {
                setCellProps: () => ({ style: { width: 50, backgroundColor: 'white' } }),
                customBodyRender: deleteRowTemplate,
                customHeadRender: () => null
            }
        },
    ];

    const options = {
        download: false,
        elevation: 0,
        filter: false,
        print: false,
        search: false,
        viewColumns: false,
        pagination: false,
        selectableRows: false,
        toolbar: {
            display: false,
        },
        responsive: 'scrollMaxHeight',
    };

    return (
        <Card variant="outlined" p={3} className={classes.card}>
            <Grid container direction="row" justifyContent="space-between" pl={3} pr={1} pt={4} spacing={2}>

                <Grid item xs={4}>
                    <Autocomplete
                        id="size-small-outlined"
                        size="small"
                        fullWidth={true}
                        options={props.objectList.objectList.dataViewList}
                        getOptionLabel={(option) => option.objectName ? option.objectName : option.reportName}
                        value={props.subRecord}
                        onChange={(event, value) => {
                            let newValue = !value ? { objectId: 0, objectName: "" } : value;
                            const newSubRecord = {
                                reportName: newValue.objectName,
                                reportId: newValue.objectId,
                                mappingFields: [{ parentFieldName: "", childFieldName: "", childObjectId: 0, error: false, helperText: '' }]
                            };
                            dispatchSubRecordsFieldsDetails(newSubRecord);
                        }}
                        loading={props.requestStatus.getObjectListByRole === actionTypes.VGO_GET_DATAVIEWLISTANDFORMLISTBYROLELIST_REQUEST}
                        renderInput={params => (
                            <TextField {...params}
                                size="small"
                                placeholder="Sub Record Data View"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {props.requestStatus.getObjectListByRole === actionTypes.VGO_GET_DATAVIEWLISTANDFORMLISTBYROLELIST_REQUEST && <CircularProgress color="inherit" size={20} />}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={1}>
                    <Box pt={1.625} pl={2}>
                        <Typography variant="body1">Join on...</Typography>
                    </Box>
                </Grid>
                <Grid item xs={5.4} >
                    <Box mt={-2}>
                        <MUIDataTable container
                            title={''}
                            data={props.mappingFields}
                            columns={columns}
                            options={options}
                            />
                    </Box>
                </Grid>
                <Grid item xs={1.4}>
                    <Button className={`${classes.customControlButton} ${classes.buttonSave} ${classes.clearButton}`}
                        color="primary"
                        variant="contained"
                        disableElevation
                        startIcon={<UndoIcon />}
                        onClick={dispatchClear}
                    >CLEAR ALL</Button>
                </Grid>

            </Grid>
        </Card>
    );
}

function mapStateToProps(state) {
    return {

        dataView: state.dataViews.dataView,
        objectList: state.dataViews.objectListByRole,
        requestStatus: state.dataViews.requestStatus,
        subRecord: state.dataViewsSubRecords.subRecord,
        mappingFields: state.dataViewsSubRecords.subRecord.mappingFields,
        reportFields: state.dataViews.dataView.fieldDisplay,
        subRecordFields: (state.dataViewsSubRecords.subRecordFields.length === 0) ? state.dataViewsSubRecords.subRecord.reportOutputFields : state.dataViewsSubRecords.subRecordFields,
        isLoading: state.loader.isLoading,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage,
    };
};

export default withRouter(connect(mapStateToProps)(withStyles(subRecordsTheme)(SubRecords)));