import React from 'react';
import { HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-icons/styles/material.css';
import '@syncfusion/ej2-buttons/styles/material.css';
import '@syncfusion/ej2-splitbuttons/styles/material.css';
import '@syncfusion/ej2-inputs/styles/material.css';
import '@syncfusion/ej2-lists/styles/material.css';
import '@syncfusion/ej2-navigations/styles/material.css';
import '@syncfusion/ej2-popups/styles/material.css';
import '@syncfusion/ej2-richtexteditor/styles/material.css';

class RichTextEditor extends React.Component {
    constructor(props) {
        super(props);
        this.toolbarSettings = {
            items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
                'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
                'LowerCase', 'UpperCase', '|',
                'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
                'Outdent', 'Indent', '|',
                'CreateLink', 'Image', '|', 'ClearFormat', '|', 'Undo', 'Redo',
               
            ]
        };

        this.imageSettings = {
            saveFormat: "Base64", width: "250", height: "250",

        }
    }
  
   
    render() {
        return (
            
            <RichTextEditorComponent
                actionBegin={(args) => {
                    if (args.type === 'drop' || args.type === 'dragstart') {
                        args.cancel = true;
                    }
                }}
        

                placeholder={'Message*'} width={1020} height={this.props.height ? this.props.height:200} id="defaultRTE" name="defaultRTE" toolbarSettings={this.toolbarSettings} change={this.props.onChange} blur={this.props.onBlur} value={this.props.data} insertImageSettings={this.imageSettings}>
                <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]} />
                
                    </RichTextEditorComponent>
            
                )
           
    }
}
export default RichTextEditor;