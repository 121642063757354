import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from 'admin/reducers';
import { createLogger } from 'redux-logger';

function configureStore() {
    // Check to see if Redux Devtools extension is installed, if so
    // add redux devtools
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    let middlewares = [ thunk ];

    // enable redux logger in development
    if (process.env.NODE_ENV === 'development') {
        var logger = createLogger({collapsed:true});
        middlewares = [...middlewares, logger];
    }

    let store = createStore(
        rootReducer,
        composeEnhancers(
            applyMiddleware(...middlewares)
        )
    );
    return store;
};

let store = configureStore();

export default store;