import {theme, customThemeStyles } from 'common/theme.js';
export const passwordHelperCustom = () => ({
    ...customThemeStyles,
   helperIcon: {
        textTransform: "none !important",
        color:"blue !important"
    },
   helperText: {
       marginTop:"1px !important"
    },
    helperOpen: {
        transform: "rotate(90deg)",
        color: "rgba(0, 0, 0, 0.54)"
    },
    helperClosed: {
        transform: "rotate(0)",
        color: "rgba(0, 0, 0, 0.54)"
    },
    subHeadingHelper: {
        paddingTop:"0px !important"
    },

    helperTextFont: {

        [theme.breakpoints.down('lg')]: {
            fontSize: "12px!important"

        }
       
    },
})