import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import { getUsers, setChangeRoleData, setChangeRoleDataName } from 'admin/actions/roleActions';
import { RoleTab } from 'admin/actions/actionTypes';
import { theme } from 'common/theme.js';
import { produce } from 'immer';

import GridCheckBoxHeader from './GridCheckBoxHeader';
import { rolesTheme } from './theme/rolesTheme.js';
import { withStyles } from '@mui/styles';
import { ActiveState } from 'admin/actions/actionTypes';
import GridCheckBox from './GridCheckBox';
import ActionBackdrop from 'admin/components/section/settings/Backdrop';
import { MuiGridOptions } from 'admin/components/Common/CommonOptions';
import { objectListFormatDate } from 'admin/components/Common//DateFormat';
const defaultColumnOptions = {
    filter: true,
    sort: true,
    setCellProps: (cellValue, rowIndex, columnIndex) => {
        return {
            style: {
                padding: theme.spacing(1) 
            }
        };
    }

};

class UserList extends React.Component {
    constructor(props) {
        super(props);
        const options = {
            filterType: "textField",
            fixedHeader: true,
            sort: true,
            search: true,
            selectableRows: 'none',
            responsive: 'scrollMaxHeight',
            download: false,
            elevation: 0,
            filter: true,
            filterType: 'multiselect',
            onColumnSortChange: (changedColumn, direction) => {
                this.setState(produce(draft => {
                    draft.options.sortOrder = { name: changedColumn, direction: direction };
                    draft.sortSettings = { sortFrom: changedColumn, direction: direction, clearSort: false };
                }));
            },
            onViewColumnsChange: (changedColumn, action) => {

                let columnIndex = this.state.columns.findIndex(c => c.name === changedColumn);
                this.setState(produce(draft => {
                    draft.columns[columnIndex].options.display = action === "add";
                }));
            },
            customToolbarSelect: () => {


            },
            onRowsSelect: (curRowSelected, allRowsSelected) => {

                let selectedUser = allRowsSelected.map((user) => {
                    return this.props.users.users[user.dataIndex].userId
                });


                this.props.dispatch(setChangeRoleData(RoleTab.user, selectedUser));

            },
            print: false,
            searchPlaceholder: 'Enter search text ...',
            sortOrder: {
                name: 'email',
                direction: 'asc',
            }, searchOpen: true,
        }
       this.state = {
           columns: [
               {
                   name: "activeState",
                   label: "Active",
                   options: {
                       customHeadRender: (columnMeta, updateDirection) => (
                           <GridCheckBoxHeader
                               selectAll={props.classes.userSelectAll}
                               selectCheckbox={this.props.classes.selectCheckbox}
                               checked={this.props.users.role.UserList.length === this.props.users.users.length ? true : false}
                               handleChange={this.handleChange}
                               handleSelectCheckBoxSort={this.handleSelectCheckBoxSort}
                               sortSettings={this.state.sortSettings}
                               clearSort={this.clearSelectSort}
                               selectedObjectKey={"UserList"}
                           />
                       ),
                       
                       ...defaultColumnOptions,
                       customBodyRender: this.checkBoxTemplate,
                       filterList: [ActiveState.active],
                   },
               },
               {
                   name: "userId",
                 
                   options: {
                       display: false,
                        viewColumns: false,
                        filter:false,
                   }
               },
              
                {
                    name: "email",
                    label: "Name",
                    options: {
                        ...defaultColumnOptions,
                       
                    }
                },
                {
                    name: "lastName",
                    label: "Last Name",
                    options: {
                        ...defaultColumnOptions
                    }
                },
                {
                    name: "firstName",
                    label: "First Name",
                    options: { ...defaultColumnOptions }
                }
                ,

                {
                    name: "lastUpdateDateTime",
                    label: "Created",
                    options: {
                        ...defaultColumnOptions,
                        customBodyRenderLite: this.dateFormatTemplate
                    }
                }
           ],
           options: options,
           sortSettings: { sortFrom: "", direction: "", clearSort: false }
        };
    }


    componentDidMount() { 
        this.dispatchGetUser();
    }

    componentDidUpdate(prevProps, prevState) {
        
       if (this.props.roleData !== undefined) {
           // if (prevProps.roleData !== this.props.roleData) {
                this.getSelectedRow(this.props)
           // }
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.sortSettings.sortFrom !== this.state.sortSettings.sortFrom && !nextState.sortSettings.clearSort) {
            return true;
        }
        if (nextState.sortSettings.sortFrom === this.state.sortSettings.sortFrom && nextState.sortSettings.direction !== this.state.sortSettings.direction && !nextState.sortSettings.clearSort) {
            return true;
        }
        if (nextProps.users.role.activeTab === RoleTab.user || nextProps.users.role.activeTab === RoleTab.roleName || nextProps.users.role.activeTab === RoleTab.defaultObjectId ) {
            if (this.props.users.users!== undefined) {
                return false;
            } else { return true; }
        }
        //if (nextProps.users.role.activeTab === RoleTab.user || nextProps.users.role.activeTab === RoleTab.roleName || nextProps.users.role.activeTab === RoleTab.defaultObjectId) {
        //    //|| nextProps.users.role.activeTab === RoleTab.defaultObjectType) {
        //    return false;
        //}
        return true;
    }
    checkBoxTemplate = (value, tableMeta, updateValue) => {

        let userId = tableMeta.rowData[1]
        let email = tableMeta.rowData[2];
        let status = false;
        let userIndex = this.props.users.role.UserList.findIndex(c => c.Id === userId)
        if (userIndex !== -1) {
            status = true;
        }

        return ( 
            <GridCheckBox selectCheckboxclasses={this.props.classes.selectCheckbox ? this.props.classes.selectCheckbox : ""} checked={status} id={userId} name={email}   handleSelect={this.handleSelect} />
        )
    }
    dateFormatTemplate = (dataIndex) => {
        const value = this.props.users.users[dataIndex].lastUpdateDateTime;
        return objectListFormatDate(value)
    }
    dispatchGetUser = () => {
        if (this.props.users.users === undefined) {
          
            this.props.dispatch(getUsers());
        }
    }
    getSelectedRow = (props) => {
        let array = []
        if (props.roleData !== undefined && props.users !== undefined) {
            if (props.users.users !== undefined) {
                props.users.role.UserList.map((user) => {
                    return props.users.users.map((users, index) => {
                        if (users.userId === user.Id) {
                            array.push(index)
                        }
                        return "";
                    });

                });
            }

        }

        return array


    }
    handleChange=(e) => {
        
        let array = [];
        if (e.target.checked) {

            for (let i = 0; i < this.props.users.users.length; i++) {
                array.push({ Id: this.props.users.users[i].userId, Name: this.props.users.users[i].email })
            }
        }
        
        this.props.dispatch(setChangeRoleData(RoleTab.userWithRefresh, '', this.props.users.role.UserList, array));


    }
    handleSelect=(userId,name) => {

        this.props.dispatch(setChangeRoleDataName(RoleTab.user, userId, name, this.props.users.role.UserList));
    }
    handleSelectCheckBoxSort = () => {
        const sortDirection = this.state.sortSettings.direction
        const sortField = this.state.sortSettings.sortFrom
        let direction = "";
        if (sortField === "select" && sortDirection === "asc") {
            direction = "desc"
        }
        else if (sortField === "select" && sortDirection === "desc") {
            direction = "asc"
        }
        else {
            direction = "asc";
        }
        this.setState(produce(draft => {
            draft.options.sortOrder = { name: "select", direction: direction };
            draft.sortSettings = { sortFrom: "select", direction: direction, clearSort: false };
        }));
    }
    clearSelectSort = () => {
        this.setState(produce(draft => {
            draft.options.sortOrder = {};
            draft.sortSettings = { sortFrom: "", direction: "", clearSort: true };
        }));
    }
    render() {
        const { classes } = this.props;
        const users = this.props.getSortedObjectList(this.props.users.users, this.props.users.role.UserList, "userId", "Id", "Name", this.state.sortSettings);
       
        let options = MuiGridOptions(this.state.options, this.props.isLoading, true)
        options.jumpToPage = false;
        options.pagination = false;
        if (this.props.users.users && this.props.users.users.length > 500) {
            options.rowsPerPage = 500;
            options.rowsPerPageOptions = [10, 100, 250, 500, 1000];
            options.pagination = true;
            options.jumpToPage = true;
        }
        return (
            <div style={this.props.style}>
                {this.props.isDataLoading && <ActionBackdrop />}
                {this.props.users.users ? <MUIDataTable container classes={{ responsiveScrollMaxHeight: classes.responsiveScrollMaxHeight}}
                    title={''}
                    data={users}
                    columns={this.state.columns}
                    options={options }


                /> : ""}
            </div>
        );

    }

}
function mapStateToProps(state) {
    return {
        users: state.roles,
        isLoading: state.loader.isLoading,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage,
        isDataLoading: state.roles.userLoading,

    };
};

export default withRouter(connect(mapStateToProps) (withStyles(rolesTheme)(UserList)));
