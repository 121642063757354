import keyMirror from 'keymirror';

export let ActionTypes = keyMirror({

    // GET USERS
    VGO_GET_USERS_REQUEST: null,
    VGO_GET_USERS_SUCCESS: null,

    // GET_USER
    VGO_GET_USER_REQUEST: null,
    VGO_GET_USER_SUCCESS: null,

    // UPDATE_USER
    VGO_UPDATE_USER_REQUEST: null,
    VGO_UPDATE_USER_SUCCESS: null ,

    // NEW, CHANGE, DISCARD DRAFT USER
    VGO_NEW_USER: null,
    VGO_CHANGE_USER: null,
    VGO_DISCARD_USER: null,
    VGO_VALIDATE_USER_ON_BLUR:null,

    // GET FORM
    VGO_GET_FORM_REQUEST: null,
    VGO_GET_FORM_SUCCESS: null,

    // GET DASHBOARD
    VGO_GET_DASHBOARD_REQUEST: null,
    VGO_GET_DASHBOARD_SUCCESS: null,

    // GET REPORT
    VGO_GET_REPORT_REQUEST: null,
    VGO_GET_REPORT_SUCCESS: null,

    // GET ROLES
    VGO_NEW_ROLE:null,
    VGO_GET_ROLES_REQUEST: null,
    VGO_GET_ROLES_SUCCESS: null,
    VGO_DISCARD_ROLE: null,
    VGO_GET_ROLES_USERS_REQUEST: null,
    VGO_GET_ROLES_ERROR_REQUEST: null,

    // GET ROLE
    VGO_GET_ROLE_REQUEST: null,
    VGO_GET_ROLE_SUCCESS: null,
    VGO_GET_ROLEUSER_SUCCESS: null,
    VGO_CHANGE_ROLE_DATA: null,

    // UPDATE_ROLES
    VGO_UPDATE_ROLES_REQUEST: null,
    VGO_UPDATE_ROLES_SUCCESS: null,

    // UPDATE_ROLE
    VGO_UPDATE_ROLE_REQUEST: null,
    VGO_UPDATE_ROLE_SUCCESS: null,
    VGO_DELETE_ROLE_REQUEST: null,
    VGO_DELETE_ROLE_SUCCESS: null,
    VGO_GET_ROLE_ERROR_REQUEST: null,
    // universal
    VGO_UNIVERSAL_SEARCH: null,
    VGO_UNIVERSAL_MESSAGE: null,

    // GET_DATABASES
    VGO_GET_DATABASES_REQUEST: null,
    VGO_GET_DATABASES_SUCCESS: null,

    // GET_DATABASE
    VGO_GET_DATABASE_REQUEST: null,
    VGO_GET_DATABASE_SUCCESS: null,

    // DELETE_DATABASE
   
    VGO_DELETE_DATABASE_REQUEST: null,
    VGO_DELETE_DATABASE_SUCCESS: null,

    // UPDATE_DATABASE
    VGO_UPDATE_DATABASE_REQUEST: null,
    VGO_UPDATE_DATABASE_SUCCESS: null,

    // NEW, CHANGE, DISCARD DRAFT DATABASE
    VGO_NEW_DATABASE: null,
    VGO_CHANGE_DATABASE: null,
    VGO_DISCARD_DATABASE: null,
    VGO_VALIDATE_DATABASEFIELD_ONBLUR: null,
    // GET_MENUS
    VGO_GET_MENUS_REQUEST: null,
    VGO_GET_MENUS_SUCCESS: null,

    // GET MENU
    VGO_GET_MENU_REQUEST: null,
    VGO_GET_MENU_SUCCESS: null,
    VGO_GET_MENU_CONTENT_DRAG_DATA: null,

    // DELETE_MENU
    VGO_DELETE_MENU_REQUEST: null,
    VGO_DELETE_MENU_SUCCESS: null,

    // NEW, CHANGE, DISCARD DRAFT MENU
    VGO_CHANGE_MENU: null,
    VGO_NEW_MENU: null,
    VGO_MODIFY_NAV_STRUCTURE_MENU: null,
    VGO_MODIFY_QUICKACTION_STRUCTURE_MENU: null,
    VGO_MODIFY_NAV_QUICKACTION_STRUCTURE_MENU: null,
    VGO_CHANGE_MENU_MENUITEM_LABEL: null,
    VGO_NAV_MENU_SELECTED:null,
    VGO_NAV_MENU_CLEAR_SELECTION: null,
    VGO_NAV_MENU_SET_OBJECTLIST:null,
    // UPDATE_MENU
    VGO_UPDATE_MENU_REQUEST: null,
    VGO_UPDATE_MENU_SUCCESS: null,
    VGO_VALIDATE_MENU_ONBLUR: null,
    VGO_VALIDATE_MENU_ERROR: null,

    //GET USER FILTER
    VGO_GET_USER_FILTERS_REQUEST: null,
    VGO_GET_USER_FILTERS_SUCCESS: null,
    VGO_GET_USER_FILTER_REQUEST: null,
    VGO_GET_USER_FILTER_SUCCESS: null,
    VGO_GET_USER_FILTER_UPDATE_SUCCESS: null,
    // general
    VGO_API_CALL_ERROR: null,
    VGO_API_CALL_SUCCESS: null,
    VGO_API_CALL_VALIDATION_ERROR: null,
    VGO_API_CALL_VALIDATION_TAB_ERROR:null,

    //Get SiteSettings
    VGO_GET_SYSTEM_SETTINGS_REQUEST: null,
    VGO_GET_SYSTEM_SETTINGS_SUCCESS: null,
    VGO_GET_SYSTEM_SETTINGS_CLEAR_SUCCESS: null,

    VGO_GET_EMAIL_SETTINGS_ALERT_SUCCESS: null,
    
    //Update SiteSettings
    VGO_UPDATE_SYSTEM_SETTINGS_REQUEST: null,
    VGO_UPDATE_SYSTEM_SETTINGS_SUCCESS: null,
    VGO_CHANGE_SYSTEM_SETTINGS: null,
    VGO_DISCARD_SYSTEM_SETTINGS: null,
    VGO_UPDATE_EMAIL_SETTINGS_REQUEST: null,
    VGO_UPDATE_EMAIL_SETTINGS_SUCCESS: null,
    VGO_GET_EMAIL_CHECK_REQUEST: null,
    //Get Field Display

    //Reset Password

    VGO_UPDATE_PASSWORD_SETTINGS_REQUEST: null,
    VGO_UPDATE_PASSWORD_SETTINGS_SUCCESS: null,
    VGO_GET_SYSTEM_RESET_PASSWORD_CLEAR_SUCCESS: null,

   
    VGO_UPDATE_SETTINGS_REQUEST: null,
    VGO_UPDATE_SETTINGS_SUCCESS: null,
    VGO_UPDATE_RECORD_COUNT_CLEAR_SUCCESS: null,
    VGO_UPDATE_BIZWEAVER_TOKEN_CLEAR_SUCCESS: null,
    VGO_UPDATE_SESSION_PERIOD_CLEAR_SUCCESS: null,
    VGO_UPDATE_TIMEZONE_CLEAR_SUCCESS: null,
    VGO_UPDATE_INSTALLATION_CLEAR_SUCCESS: null,
    VGO_UPDATE_FILE_UPLOAD_CLEAR_SUCCESS: null,
    VGO_GET_FIELD_DISPLAY_REQUEST: null,
    VGO_GET_FIELD_DISPLAY_SUCCESS: null,
    VGO_UPDATE_FIELD_FIELD_DISPLAY_SUCCESS: null,
    VGO_UPDATE_FIELD_HighLight_SUCCESS: null,
    VGO_GET_FORMULA_FIELD_DISPLAY_REQUEST: null,
    VGO_GET_FORMULA_FIELD_DISPLAY_SUCCESS: null,
    VGO_GET_VALIDATE_FORMULA_FIELD_DISPLAY_REQUEST: null,
    VGO_GET_VALIDATE_FORMULA_FIELD_DISPLAY_SUCCESS: null,
    VGO_GET_VALIDATE_FORMULA_FIELD_DISPLAY_ERROR: null,
    VGO_GET_VALIDATE_FORMULA_DISPLAY_ERROR: null,
    VGO_UPDATE_CALCULATED_FIELD_DISPLAY_SUCCESS: null,
    VGO_UPDATE_PAYMENT_GATEWAY_SUCCESS: null,
    // ALERT
    VGO_ALERT_SHOW: null,
    VGO_ALERT_HIDE: null,


    //Filters
    VGO_GET_FILTERS_REQUEST: null,
    VGO_GET_FILTERS_SUCCESS: null,

    //Filter
    VGO_GET_FILTER_REQUEST: null,
    VGO_GET_FILTER_SUCCESS: null,

    //Filter DataSources
    VGO_GET_FILTERS_DATABASE_SOURCE_REQUEST: null,
    VGO_GET_FILTERS_DATABASE_SOURCE_SUCCESS: null,
    VGO_GET_FILTERS_DATASOURCE_REQUEST: null,
    VGO_GET_FILTERS_DATASOURCE_SUCCESS: null,
    VGO_GET_FILTERS_FIELDS_SOURCE_REQUEST: null,
    VGO_GET_FILTERS_FIELDS_SOURCE_SUCCESS: null,
    VGO_HIDE_RESULT_SUCCESS: null,
    //UPDATE Filters
    VGO_UPDATE_FILTER_REQUEST: null,
    VGO_UPDATE_FILTER_SUCCESS: null,

    //DELETE FILTER
    VGO_DELETE_FILTER_REQUEST: null,
    VGO_DELETE_FILTER_SUCCESS: null,

    // NEW, CHANGE, DISCARD DRAFT  FILTER
    VGO_NEW_FILTER: null,
    VGO_NEW_FILTER_SUCCESS: null,
    VGO_CHANGE_FILTER: null,
    VGO_DISCARD_FILTER: null,

    // GET_ACTIVE_USER
    VGO_GET_ACTIVE_USER_REQUEST: null,
    VGO_GET_ACTIVE_USER_SUCCESS: null,

    // GET_DATAVIEWS
    VGO_GET_DATAVIEWS_REQUEST: null,
    VGO_GET_DATAVIEWS_SUCCESS: null,
    VGO_GET_DATAVIEWLISTBYROLELIST_REQUEST: null,
    VGO_GET_DATAVIEWLISTBYROLELIST_SUCCESS: null,

    VGO_GET_DATAVIEWLISTANDFORMLISTBYROLELIST_REQUEST: null,
    VGO_GET_DATAVIEWLISTANDFORMLISTBYROLELIST_SUCCESS: null,

    // GET_DATAVIEW
    VGO_GET_DATAVIEW_REQUEST: null,
    VGO_GET_DATAVIEW_SUCCESS: null,

    // DELETE_DATAVIEW
    VGO_DELETE_DATAVIEW_REQUEST: null,
    VGO_DELETE_DATAVIEW_SUCCESS: null,

    // UPDATE_DATAVIEW
    VGO_UPDATE_DATAVIEW_REQUEST: null,
    VGO_UPDATE_DATAVIEW_SUCCESS: null,

    // NEW, CHANGE, DISCARD DRAFT USER
    VGO_NEW_DATAVIEW: null,
    VGO_CHANGE_DATAVIEW: null,
    VGO_CHANGE_RECORD_SUBMISSION: null,
    VGO_CHANGE_DATAVIEW_DATABASE: null,
    VGO_CHANGE_DATAVIEW_DATASOURCETYPE: null,
    VGO_GET_DATAVIEW_DATASOURCE_REQUEST: null,
    VGO_GET_DATAVIEW_DATASOURCE_SUCCESS: null,
    VGO_GET_DATAVIEW_DATASOURCE_FIELD_REQUEST: null,
    VGO_GET_DATAVIEW_DATASOURCE_FIELD_SUCCESS: null,

    VGO_GET_DATAVIEW_QUERY_FIELD_REQUEST: null,
    VGO_GET_DATAVIEW_QUERY_FIELD_SUCCESS: null,
    VGO_GET_DATAVIEW_QUERY_FIELD_ERROR: null,
    VGO_GET_DATAVIEW_VIEW_FIELD_ERROR: null,

    VGO_EXECUTE_STORED_PROCEDURE_WITH_PARAMETER_DATAVIEW_REQUEST: null,
    VGO_EXECUTE_STORED_PROCEDURE_WITH_PARAMETER_DATAVIEW_SUCCESS: null,

    VGO_GET_CRYSTALREPORT_FILTER_REQUEST: null,
    VGO_GET_CRYSTALREPORT_FILTER_SCUCCESS: null,

    VGO_CHANGE_DATAVIEW_TAB_INDEX: null,
    VGO_CHANGE_DATAVIEW_ERROR_MESSAGE: null,
    VGO_CHANGE_DATAVIEW_QUERYRESULT_PANEL:null,

    VGO_RESET_REPORTID_SUCCESS: null,
    // GET_CATEGORY
    VGO_GET_CATEGORIES_REQUEST: null,
    VGO_GET_CATEGORIES_SUCCESS: null,

    //UPDATE CATEGORY TO LIST
    VGO_UPDATE_CATEGORYLIST:null,


    VGO_UPDATE_DATAVIEW_ROW_FILTER_SUCCESS: null,
    VGO_UPDATE_DATAVIEW_ROW_FILTER_DETAILS_SUCCESS: null,

    //GET DATAVIEW SUBRECORDS FIELDS LIST
    VGO_GET_DATAVIEW_SUBRECORD_FIELDS_REQUEST: null,
    VGO_GET_DATAVIEW_SUBRECORD_FIELDS_SUCCESS: null,

    //UpdateField Links
    VGO_UPDATE_DATAVIEW_SUBRECORD_FIELDS_LINK_REQUEST: null,
    VGO_UPDATE_DATAVIEW_SUBRECORD_FIELDS_LINK_SUCCESS: null,

    //DeleteField Links
    VGO_DELETE_DATAVIEW_SUBRECORD_FIELDS_LINK_REQUEST: null,
    VGO_DELETE_DATAVIEW_SUBRECORD_FIELDS_LINK_SUCCESS: null,

    VGO_GET_DATAVIEW_SUBRECORD: null,
    VGO_CHANGE_SUBRECORD: null,
    VGO_UPDATE_DATAVIEW_SUBRECORD: null,
    VGO_UPDATE_DATAVIEW_SUBRECORD_MAPPINGFIELDS_SUCCESS: null,


    //Get Data View Reportlink list
    VGO_GET_DATAVIEW_REPORTLINKS_LIST_REQUEST: null,
    VGO_GET_DATAVIEW_REPORTLINKS_LIST_SUCCESS: null,

    //Update Data View Reportlink list
    VGO_UPDATE_DATAVIEW_REPORTLINKS_REQUEST: null,
    VGO_UPDATE_DATAVIEW_REPORTLINKS_SUCCESS: null,

    //Get Data View Reportlink  ANCHORNAMElist
    VGO_GET_DATAVIEW_REPORTLINKS_ANCHORNAME_LIST_REQUEST: null,
    VGO_GET_DATAVIEW_REPORTLINKS_ANCHORNAME_LIST_SUCCESS: null,

    // New DataView Link
    VGO_NEW_DATAVIEW_LINK_REQUEST: null,
    VGO_NEW_DATAVIEW_LINK_SUCCESS: null,
    VGO_UPDATE_DATAVIEW_LINK_SUCCESS: null,

    //New DataView Condition
    VGO_NEW_DATAVIEW_CONDITION_REQUEST: null,
    VGO_NEW_DATAVIEW_CONDITION_SUCCESS: null,

    //Update DataView Condition
    VGO_UPDATE_DATAVIEW_CONDITION_REQUEST: null,
    VGO_UPDATE_DATAVIEW_CONDITION_SUCCESS: null,

    //Cancel DataView Condition
    VGO_CANCEL_DATAVIEW_CONDITION_REQUEST: null,
    VGO_CANCEL_DATAVIEW_CONDITION_SUCCESS: null,

    //Remove DataView Condition
    VGO_REMOVE_DATAVIEW_CONDITION_REQUEST: null,
    VGO_REMOVE_DATAVIEW_CONDITION_SUCCESS: null,

    //Delete  link 
    VGO_DELETE_DATAVIEW_LINK_REQUEST: null,
    VGO_DELETE_DATAVIEW_LINK_SUCCESS: null,

    //New DataView Condition PARAMETER
    VGO_NEW_DATAVIEW_CONDITION_PARAMETER_REQUEST: null,
    VGO_NEW_DATAVIEW_CONDITION_PARAMETER_SUCCESS: null,

    //Remove DataView Condition PARAMETER
    VGO_REMOVE_DATAVIEW_CONDITION_PARAMETER_REQUEST: null,
    VGO_REMOVE_DATAVIEW_CONDITION_PARAMETER_SUCCESS: null,

    VGO_GET_DATAVIEW_REPORTLINKS: null,


    //Forms
    VGO_GET_FORM_LIST_REQUEST: null,
    VGO_GET_FORM_LIST_SUCCESS: null,
    VGO_DELETE_FORM_LIST_REQUEST: null,
    VGO_DELETE_FORM_LIST_SUCCESS: null,

    VGO_NEW_FORM: null,
    VGO_CHANGE_FORM: null,
    VGO_CHANGE_FORMROLE: null,
    VGO_CHANGE_FORM_FORMSOURCE: null,
    VGO_CHANGE_FORM_FORMTYPE: null,
    VGO_CHANGE_FORM_TABLE_OPTION: null,
    VGO_CHANGE_RESET_FORM: null,
    VGO_CHANGE_FORM_TAB_INDEX: null,
    VGO_NEW_FORM_PRIMARY_TABLE_FIELD: null,
    VGO_NEW_FORM_DETAIL_TABLE_FIELD: null,
    VGO_DELETE_FORM_PRIMARY_TABLE_FIELD: null,
    VGO_DELETE_FORM_DETAIL_TABLE_FIELD: null,
    VGO_GET_FORM_PRIMARY_TABLE_FIELDS__REQUEST: null,
    VGO_GET_FORM_PRIMARY_TABLE_FIELDS__SUCCESS: null,
    VGO_GET_FORM_DETAIL_TABLE_FIELDS__REQUEST: null,
    VGO_GET_FORM_DETAIL_TABLE_FIELDS__SUCCESS: null,

    VGO_UPDATE_FORM_REQUEST: null,
    VGO_UPDATE_FORM_SUCCESS: null,

    VGO_ACTION_TRIGGER_FORM_SUBMIT: null,
    VGO_VALIDATE_FORM_SETUP_ONBLUR:null,

    // UPDATE RECORDPROCESSING REQUEST
    VGO_UPDATE_RECORD_PROCESSING_REQUEST: null,

    VGO_UPDATE_RECORD_SETUP_REQUEST: null,
    

    //Update DataViewCalender Data
    VGO_UPDATE_DATAVIEW_CALENDER_REQUEST: null,
    VGO_UPDATE_DATAVIEW_CALENDER_SUCCESS: null,

    
    VGO_UPDATE_FORM_UI_SUCCESS: null,
    VGO_UPDATE_FORM_PROPERTY_SUCCESS: null,
    VGO_UPDATE_ACTIVE_CONTROL_PROPERTY_SUCCESS: null,
    VGO_UPDATE_ACTIVE_CONTROL_PROPERTY_CHANGE_SUCCESS: null,

    // GET COUNTRY
    VGO_GET_COUNTRY_REQUEST: null,
    VGO_GET_COUNTRY_SUCCESS: null,

    // YUP VALIDATION
    VGO_VALIDATE_FIELD_ONBLUR: null,
    VGO_VALIDATE_CALENDAR_FIELD_ONBLUR: null,

    // GET_DASHBOARDS
    VGO_GET_DASHBOARDS_REQUEST: null,
    VGO_GET_DASHBOARDS_SUCCESS: null,

    // GET DASHBOARD
    VGO_GET_DASHBOARD_REQUEST: null,
    VGO_GET_DASHBOARD_SUCCESS: null,

    // DELETE_DATAVIEW
    VGO_DELETE_DASHBOARD_REQUEST: null,
    VGO_DELETE_DASHBOARD_SUCCESS: null,

    VGO_NEW_DASHBOARD: null,
    VGO_CHANGE_DASHBOARD:null,
    VGO_VALIDATE_DASHBOARD_SETUP_ONBLUR: null,

    VGO_GET_DASHBOARDITEMLIST_REQUEST: null,
    VGO_GET_DASHBOARDITEMLIST_SUCCESS: null,

    
    VGO_UPDATE_DASHBOARD_REQUEST: null,
    VGO_UPDATE_DASHBOARD_SUCCESS: null,

    VGO_SET_PANEL_DASHBOARD: null,
    VGO_SETUI_PANEL_DASHBOARD: null,
    VGO_SETUI_PANELWITHOBJECT_DASHBOARD:null,

    //custom widget
    VGO_GET_CUSTOMWIDGET_REQUEST: null,
    VGO_GET_CUSTOMWIDGET_SUCCESS: null,
    VGO_CHANGE_CUSTOMWIDGET_ACTIVEINDEX: null,
    VGO_CHANGE_CUSTOMWIDGET: null,
    VGO_CUSTOMWIDGET_ERROR: null,
    VGO_UPDATE_CUSTOMWIDGET_REQUEST: null,
    VGO_UPDATE_CUSTOMWIDGET_SUCCESS: null,
    VGO_CHANGE_CUSTOMWIDGET_DISPLAYORDER: null,
    VGO_CHANGE_CUSTOMWIDGET_LINK_DELETE: null,
    VGO_NEW_CUSTOMWIDGET_LINK: null,
    VGO_UPDATE_CUSTOMWIDGET_ERROR: null,
    //SITE_THEME
    VGO_GET_SITE_THEME_SUCCESS: null,
    VGO_UPDATE_SITE_THEME_SUCCESS: null,
    VGO_CHANGE_SITE_THEME: null,

    // GET HOMEPAGE
    VGO_GET_HOMEPAGE_REQUEST: null,
    VGO_GET_HOMEPAGE_UPDATE_REQUEST: null,
    VGO_GET_HOMEPAGE_SUCCESS: null,
    VGO_CHANGE_HOME_PAGE_CONTENT: null,
    VGO_VALIDATE_HOME_PAGE_ON_BLUR: null,
    VGO_HOME_PAGE_CONTENT_REQUEST: null,
    VGO_HOME_PAGE_CONTENT_SUCCESS: null,

    VGO_GET_FORMS_ROLE_LIST_SUCCESS: null,
    VGO_GET_DASHBOARD_ROLE_LIST_SUCCESS: null,
    VGO_GET_MENU_ROLE_LIST_SUCCESS: null,
    VGO_GET_MENU_ROLES_REQUEST: null,
    VGO_GET_MENU_ROLES_SUCCESS:null,

    VGO_GET_ROLE_OBJECT_DEPEND_REQUEST: null,
    VGO_GET_ROLE_OBJECT_DEPEND_SUCCESS:null,

    //IMPORT EXPORT
    VGO_PACKAGE_GET_OBJECTLIST_REQUEST: null,
    VGO_PACKAGE_GET_OBJECTLIST_SUCCESS: null,

    VGO_PACKAGE_EXPORT_GET_FILE_REQUEST: null,
    VGO_PACKAGE_EXPORT_GET_FILE_SUCCESS: null,
    VGO_PACKAGE_EXPORT_GET_FILE_ERROR: null,

    VGO_PACKAGE_EXPORT_GET_LOG_FILE_REQUEST: null,
    VGO_PACKAGE_EXPORT_GET_LOG_FILE_SUCCESS: null,

    VGO_PACKAGE_EXPORT_GETLIST_REQUEST: null,
    VGO_PACKAGE_EXPORT_GETLIST_SUCCESS: null,
    VGO_CHANGE_PACKAGE: null,
    VGO_PACKAGE_ADD_ITEM: null,
    VGO_PACKAGE_REMOVE_ITEM: null,
    VGO_PACKAGE_DEPENDENCY_LIST_REQUEST: null,
    VGO_PACKAGE_DEPENDENCY_LIST_SUCCESS: null,
    VGO_PACKAGE_RESET: null,
    VGO_PACKAGE_SELECTALL: null,
    VGO_PACKAGE_VALIDATION: null,
    VGO_PACKAGE_MERGE: null,
    VGO_PACKAGE_IMPORT_FILE_GET: null,
    VGO_PACKAGE_IMPORT_PROCESS_REQUEST: null,
    VGO_PACKAGE_IMPORT_PROCESS_SUCCESS: null,
    VGO_PACKAGE_IMPORT_PROCESS_ERROR: null,
    VGO_IMPORT_CHANGE_PACKAGE: null,
    VGO_IMPORT_CHANGE_PACKAGE_CONNECTION: null,
    VGO_IMPORT_PACKAGE_RESET: null,
    VGO_IMPORT_PACKAGE_VALIDATE_REQUEST: null,
    VGO_IMPORT_PACKAGE_VALIDATE_SUCCESS: null,
    VGO_IMPORT_PACKAGE_VALIDATE_ERROR: null,
    VGO_IMPORT_PACKAGE_VALIDATE_CLEAR_ERROR: null,
    VGO_IMPORT_PACKAGE_CONNECTIONLIST_ERROR: null,
    VGO_GET_VERSION_SUCCESS: null,
});

export const UserType = {
    user: "User",
    admin: "Admin",
};

export const ActiveState = {
    inactive: "Inactive",
    active: "Active",
};

export const RoleTab = { 

    roleHeader: "RoleHeader",
    roleName: "RoleName",
    user: "User",
    report: "Report",
    form: "Form",
    dashboard: "Dashboard",
    menu: "Menu",
    defaultObjectId: "DefaultObjectId",
    defaultObjectType: "DefaultObjectType",

    reportWithRefresh: "ReportWithRefresh",
    userWithRefresh: "UserWithRefresh",
    defaultObjectTypeWithOutRefresh: "defaultObjectTypeWithOutRefresh",
    reportWithRefresh: "FormWithRefresh",
    dashboardWithRefresh:"DashboardWithRefresh",
    menuWithRefresh: "MenuWithRefresh",
    defaultObjectIdWithOutRefresh:"defaultObjectIdWithOutRefresh",

};

export const LandingPageType = {
    homePage:0,
    reportPage: 1,
    dashboardPage:2,
    formPage: 3,

};

export const displayType = {
    update: "0",
    reset: "1",
    delete: "2",

};


export const controlType = {Detail: 'detail', SectionTitle: 'sectionTitle', TextBox: 'textBox', Select: 'select', DropdownList: 'dropdownList', Email: 'email', NumericTextBox: 'numericTextBox', DatePicker: 'datePicker', TimePicker: 'timePicker', FileUpload: 'fileUpload', Signature: 'signature', Image: 'image', MultiText1: 'multiText1', Button: 'button',
    ActionWithConfirmClear: 'actionWithConfirmClear', ActionWithClear: 'actionWithClear', ClearWithGetAction: 'clearWithGetAction', ActionWithClearGet: 'actionWithClearGet', Submit: 'submit', CheckBox: 'checkBox'
}
export const sectionType = { Header: "header", Detail: "detail", Button: "button", GridEdit:"gridEdit" }
export const section = { ResetPassword: 'ResetPassword', EmailSettings: 'EmailSettings', RecordCount: 'RecordCount', BizweaverToken: 'BizweaverToken', SessionPeriod: 'SessionPeriod', TimeZone: 'TimeZone', FileUpload: 'FileUpload', InstallationId: 'InstallationId', PaymentGateWay: 'PaymentGateWay' }

