import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {  updateFieldDisplay, updateHighLight } from 'admin/actions/fieldDisplayActions';
import { displayType as types } from 'admin/actions/actionTypes';
import HighLight from './HighLight'
import DerivedField from './DerivedField'
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import { ReportDataSourceType } from 'client/components/Common/Enum';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import IconButton from '@mui/material/IconButton';
import cloneDeep from 'lodash/cloneDeep';
import {
    DragDropContext,
    Droppable,
    Draggable,
    DraggableProvided,
    DroppableProvided,
    DraggableStateSnapshot
} from "react-beautiful-dnd";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Grid
} from "@mui/material";
import { Checkbox, Select, MenuItem, TextField, Button, Typography } from "@mui/material";
import ReorderIcon from "@mui/icons-material/Reorder";
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import { spacing } from '@mui/system';
import { withStyles } from '@mui/styles';
import { fieldDisplayTheme } from 'admin/components/section/dataViews/fieldDisplay/theme/fieldDisplayTheme';
import * as Enums from 'client/components/Common/Enum';
import Link from '@mui/material/Link';
import { sortName } from 'client/components/Common/Utility';

const Card = styled(MuiCard)(spacing);
const sortingOption = [
    {
        label: "None",
        value: 0
    },
    {
        label: "Ascending",
        value: 1
    },
    {
        label: "Descending",
        value: 2
    }

];
const displayType = [
    {
        label: "None",
        value: 0,
        type: 0
    },
    {
        label: "Currency",
        value: 2,
        type: 1
    },
    {
        label: "Date",
        value: 3,
        type: 2
    },
    {
        label: "DateTime ",
        value: 4,
        type: 2
    },
    {
        label: "Decimal 1",
        value: 11,
        type: 1
    },
    {
        label: "Decimal 2",
        value: 12,
        type: 1
    },
    {
        label: "Decimal 3",
        value: 13,
        type: 1
    },
    {
        label: "Decimal 4",
        value: 14,
        type: 1
    },
    {
        label: "File",
        value: 6,
        type: 4
    },
    {
        label: "Image",
        value: 8,
        type: 3
    },
    {
        label: "Url",
        value: 7,
        type: 3
    },
    
    {
        label: "Time",
        value: 10,
        type: 2
    },
    {
        label: "Time",
        value: 10,
        type: 5
    },

];
const thumbnailImageType = [
   
    {
        label: "45px",
        value: 1
    },
    {
        label: "65px",
        value: 2
    },

];

const aggregateOptions = [
    {
        label: "None",
        value: Enums.AggregateOptions.None,
        count: 1,
    },
    {
        label: "Average",
        value: Enums.AggregateOptions.Average,
        count: 3,
    },
    {
        label: "Count",
        value: Enums.AggregateOptions.Count,
        count: 6,
    },
    {
        label: "Max",
        value: Enums.AggregateOptions.Max,
        count: 5,
    },
    {
        label: "Min",
        value: Enums.AggregateOptions.Min,
        count: 4,
    },
    {
        label: "Sum",
        value: Enums.AggregateOptions.Sum,
        count: 2,
    },
];

const currency = ["Local Culture", "en-US", "en-Canada", "es-Mexico", "pt-Brazil", "es-Panama", "es-Chile", "es-Argentina", "en-UK", "en-Australia", "en-New Zealand", "kok-India", "de-Germany", "es-Spain", "nl-Netherlands"];
const dateFormat = ["mm/dd/yyyy", "dd/mm/yyyy", "dd/mmm/yyyy", "yyyy/mm/dd", "mmm/dd/yyyy"];
const dateTimeFormat = [
    "mm/dd/yyyy hh:mm AM/PM", "mm/dd/yyyy hh:mm", "mm/dd/yyyy hh:mm:ss AM/PM", "mm/dd/yyyy hh:mm:ss",
    "dd/mm/yyyy hh:mm AM/PM", "dd/mm/yyyy hh:mm", "dd/mm/yyyy hh:mm:ss AM/PM", "dd/mm/yyyy hh:mm:ss",
    "dd/mmm/yyyy hh:mm AM/PM", "dd/mmm/yyyy hh:mm", "dd/mmm/yyyy hh:mm:ss AM/PM", "dd/mmm/yyyy hh:mm:ss",
    "yyyy/mm/dd hh:mm AM/PM", "yyyy/mm/dd hh:mm", "yyyy/mm/dd hh:mm:ss AM/PM", "yyyy/mm/dd hh:mm:ss",
    "mmm/dd/yyyy hh:mm AM/PM", "mmm/dd/yyyy hh:mm", "mmm/dd/yyyy hh:mm:ss AM/PM", "mmm/dd/yyyy hh:mm:ss"
];
const timeFormat = ["hh:mm AM/PM", "hh:mm", "hh:mm:ss AM/PM", "hh:mm:ss"];
const decimal1Format = ["1,000.0", "1.000,0"];
const decimal2Format = ["1,000.00", "1.000,00"];
const decimal3Format = ["1,000.000", "1.000,000"];
const decimal4Format = ["1,000.0000", "1.000,0000"];
const displayEnumType = {
    "None": 0,
    "Currency": 2,
    "Date": 3,
    "DateTime": 4,
    "Email": 5,
    "File": 4,
    "Url": 5,
    //"Image": 5,
    "Time": 10,
    "Decimal_1": 11,
    "Decimal_2": 12,
    "Decimal_3": 13,
    "Decimal_4": 14,
};
const columnsEnum = {
    "DisplayFieldName": "displayFieldName",
    "IsDisplayTitle": "isDisplayTitle",
    "ColumnTotal": "columnTotal",
    "IsGroupable": "isGroupable",
    "SortingOption": "sort",
    "FieldFormat": "format",
    "ThumbnailImageType": "imageType",
    "Culture": "culture",
    "FileTypeField": "fileTypeField",
    "AggregateOption": "aggregateOption"
};
let showStatus = true;
let tableData = [];
let rowIndex = "";
class FieldDisplay extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showHighLight: false,
            highLightData: [],
            showDerivedField: false,
            derivedField: {},
            selectedID: "",
            dataType:""
        }
    }
    
    handleDragEnd = (result, provided) => {
        if (!result.destination) {
            return;
        }
        if (result.destination.index === result.source.index) {
            return;
        }

        this.setLocalItems(result)
    }

    setLocalItems = (result) => {

        let updateData = cloneDeep(tableData);
        const selectedItem = updateData[result.source.index]
        updateData.splice(result.source.index, 1)
        let modifiedArray = [];
        let index = 0;

        if (result.destination.index === updateData.length) {
            updateData.push(selectedItem)
            modifiedArray = updateData

        } else {
        
        for (let i = 0; i < updateData.length; i++) {

            if (index === result.destination.index) {
                modifiedArray.push(selectedItem)
                modifiedArray.push(updateData[i])

            } else {


                modifiedArray.push(updateData[i])

            }


            index++;
        }
    }

    let data = { type: types.reset }
        this.props.dispatch(updateFieldDisplay(modifiedArray, data));
        return "";
    }

   
    setDisplayData = () => {
        if (this.props.fieldDisplay) {
            if (this.props.fieldDisplay.length > 0) {
                tableData = this.props.fieldDisplay;
            } else {
                tableData = [];
            }
        } else {
            tableData = [];
        }
    };

    setDropDownData = (data) => {
        const { classes } = this.props;
        const array = data.map((item, index) => {
            return (<MenuItem classes={{ root: classes.body1 }} key={item.label+index} value={item.value} > {item.label}</MenuItem >);
        });
        return array;
    }

    setFileNameDropDownData = () => {
        let array = [];
        const { classes } = this.props;
        array = [...this.props.fieldDisplay].sort((a, b) => sortName(a.fieldName, b.fieldName)).map((item, index) => {
           return (<MenuItem classes={{ root: classes.body1 }} key={item.fieldName + index} value={item.fieldName} > {item.fieldName}</MenuItem >);
       });
        array.unshift(<MenuItem classes={{ root: classes.body1 }} key="MenuItem0" value="None" >None</MenuItem >)
        return array;
    }

    setLocalizationData = (format) => {
        let data = [];
        switch (format) {
            case displayEnumType.Currency:
                data = currency;
                break;
            case displayEnumType.Date:
                data = dateFormat;
                break;
            case displayEnumType.DateTime:
                data = dateTimeFormat;
                break;
            case displayEnumType.Time:
                data = timeFormat;
                break;
            case displayEnumType.Decimal_1:
                data = decimal1Format;
                break;
            case displayEnumType.Decimal_2:
                data = decimal2Format;
                break;
            case displayEnumType.Decimal_3:
                data = decimal3Format;
                break;
            case displayEnumType.Decimal_4:
                data = decimal4Format;
                break;
            default:
                break;
        }
        const { classes } = this.props;
        const array = data.map((item, index) => {
            return (<MenuItem classes={{ root: classes.body1 }} key={item+index} value={item} > {item}</MenuItem >);
        });
        return array;
    }

    setThumbnailImage = (item) => {
        const { classes } = this.props;
        if (item.format === 8) {
            return(
                <Select MenuProps={{ disableScrollLock: true }} classes={{ root: classes.body1 }} className={classes.thumbnailWidth} value={(!item.imageType) ? '0' : item.imageType} onChange={(e) =>
                    this.handleChange(item.fieldName, e.target.value, columnsEnum.ThumbnailImageType)} >
                        {this.setDropDownData(thumbnailImageType)}
                </Select>
            )
        }
    }

    setDisplayTypeOptions =(item) => {
        const { classes } = this.props;
        const fieldDbType = this.GetDbType(item.fieldType);
        if (fieldDbType.toLowerCase() === Enums.FieldType.String) {
            
            return <Select MenuProps={{ disableScrollLock: true }} classes={{ root: classes.body1 }} className={classes.typeWidth} value={(!item.format) ? '0' : item.format} onChange={(e) => this.handleChange(item.fieldName, e.target.value, columnsEnum.FieldFormat)} >
                {displayType.filter(filter => filter.type === 3 || filter.type === 4 || filter.type === 0).map((type, index) => (
                    <MenuItem classes={{ root: classes.body1 }} key={type.label + index} value={type.value} > {type.label}</MenuItem >
                ))}
            </Select>
        }
        else if (fieldDbType.toLowerCase() === Enums.FieldType.DateTime) {
           // if (!item.format) {
             //   this.handleChange(item.fieldName, 3, columnsEnum.FieldFormat);
            //}
            return <Select MenuProps={{ disableScrollLock: true }} classes={{ root: classes.body1 }} className={classes.typeWidth} value={(!item.format) ? '0' : item.format} onChange={(e) => this.handleChange(item.fieldName, e.target.value, columnsEnum.FieldFormat)} >
                {displayType.filter(filter => filter.type === 2 || filter.type === 0).map((type, index) => (
                    <MenuItem classes={{ root: classes.body1 }} key={type.label + index} value={type.value} > {type.label}</MenuItem >
                ))}
            </Select>
        }
        else if (fieldDbType.toLowerCase() === Enums.FieldType.Binary) {
            return <Select MenuProps={{ disableScrollLock: true }}  classes={{ root: classes.body1 }} className={classes.typeWidth} value={(!item.format) ? '0' : item.format} onChange={(e) => this.handleChange(item.fieldName, e.target.value, columnsEnum.FieldFormat)} >
                {displayType.filter(filter => filter.type === 4 || filter.type === 0).map((type, index) => (
                    <MenuItem classes={{ root: classes.body1 }} key={type.label + index} value={type.value} > {
                        type.label}</MenuItem >
                ))}
            </Select>
        } else if (fieldDbType.toLowerCase() === Enums.FieldType.Time) {
            return <Select MenuProps={{ disableScrollLock: true }}  classes={{ root: classes.body1 }} className={classes.typeWidth} value={(!item.format) ? '0' : item.format} onChange={(e) => this.handleChange(item.fieldName, e.target.value, columnsEnum.FieldFormat)} >
                {displayType.filter(filter => filter.type === 5 || filter.value===0 ).map((type, index) => (
                    <MenuItem classes={{ root: classes.body1 }} key={type.label + index} value={type.value} > {
                        type.label}</MenuItem >
                ))}
            </Select>
        }
        else {
            return <Select MenuProps={{ disableScrollLock: true }}  classes={{ root: classes.body1 }} className={classes.typeWidth} value={(!item.format) ? '0' : item.format} onChange={(e) => this.handleChange(item.fieldName, e.target.value, columnsEnum.FieldFormat)} >
                {displayType.filter(filter => filter.type === 1 || filter.type === 0).map((type, index) => (
                    <MenuItem classes={{ root: classes.body1 }} key={type.label + index} value={type.value} > {
                        type.label}</MenuItem >
                ))}
            </Select>
        }
    }

    setAggregateOptions = (item) => {
        const { classes } = this.props;
        const fieldDbType = this.GetDbType(item.fieldType);
        if (fieldDbType.toLowerCase() === Enums.FieldType.String) {
            return <Select MenuProps={{ disableScrollLock: true }}  classes={{ root: classes.body1 }} className={classes.aggregateWidth} value={(!item.aggregateOption) ? Enums.AggregateOptions.None : item.aggregateOption} onChange={(e) => this.handleChange(item.fieldName, e.target.value, columnsEnum.AggregateOption)} >
                {aggregateOptions.filter(filter => filter.count === 6 || filter.count===1).map((aggregate, index) => (
                    <MenuItem classes={{ root: classes.body1 }} key={aggregate.label + index} value={aggregate.value} > {aggregate.label}</MenuItem >
                ))}
            </Select>
        }
        else if (fieldDbType.toLowerCase() === Enums.FieldType.DateTime || fieldDbType.toLowerCase() === Enums.FieldType.Time )
        {
            return <Select MenuProps={{ disableScrollLock: true }}  classes={{ root: classes.body1 }} className={classes.aggregateWidth} value={(!item.aggregateOption) ? Enums.AggregateOptions.None : item.aggregateOption} onChange={(e) => this.handleChange(item.fieldName, e.target.value, columnsEnum.AggregateOption)} >
                {aggregateOptions.filter(filter => filter.count >= 4 || filter.count === 1).map((aggregate, index) => (
                    <MenuItem classes={{ root: classes.body1 }} key={aggregate.label + index} value={aggregate.value} > {aggregate.label}</MenuItem >
                ))}
            </Select>
        }
        else
        {
            if (fieldDbType.toLowerCase() !== Enums.FieldType.Binary) {
                return <Select MenuProps={{ disableScrollLock: true }}  classes={{ root: classes.body1 }} className={classes.aggregateWidth} value={(!item.aggregateOption) ? Enums.AggregateOptions.None : item.aggregateOption} onChange={(e) => this.handleChange(item.fieldName, e.target.value, columnsEnum.AggregateOption)} >
                            {aggregateOptions.map((aggregate, index) => (
                                <MenuItem classes={{ root: classes.body1 }} key={aggregate.label + index} value={aggregate.value} > {aggregate.label}</MenuItem >
                            ))}
                        </Select>
            }
        }
    }

    GetDbType = (fieldType) => {
        let dbType = Enums.FieldType.String;
        if (fieldType === Enums.DbType.Int || fieldType === Enums.DbType.SmallInt || fieldType === Enums.DbType.Decimal || fieldType === Enums.DbType.Numeric || fieldType === Enums.HanaDbType.Int || fieldType === Enums.DbType.Float) {
            dbType = Enums.FieldType.Numeric;
        }
        else if (fieldType === Enums.DbType.Binary || fieldType === Enums.DbType.VarBinary) {
            dbType = Enums.FieldType.Binary;
        }
        else if (fieldType === Enums.DbType.Varchar || fieldType === Enums.DbType.Nvarchar) {
            dbType = Enums.FieldType.String;
        }
        else if (fieldType === Enums.DbType.DateTime || fieldType === Enums.DbType.Date || fieldType === Enums.HanaDbType.DateTime) {
            dbType = Enums.FieldType.DateTime;
        } else if (fieldType === Enums.DbType.Time) {
            dbType = Enums.FieldType.Time;
        }

        return dbType;
    }

    closeHighLight = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        this.setState({ showHighLight: false, highLightData: [] })
    }

    showHighLight = (fieldName,data) => {
        if (showStatus) {
            let highlightData = { fieldName: fieldName, data: data }
            this.setState({ showHighLight: true, highLightData: highlightData })
        } else {

            showStatus = true;
        }
    }

    closeDerivedField = () => {

        this.setState({ showDerivedField: false, derivedFieldData: [] })
    }

    showDerivedField = (fieldName,index) => {
        let fieldData = { reportId: this.props.dataView.reportId, fieldName: "", formula: "", dataType:"" }
        if (fieldName) {
            const selectedRow = this.props.fieldDisplay.find(u => u.fieldName === fieldName) 
            if (selectedRow) {
                if (selectedRow.formula) {
                    fieldData = {
                        reportId: this.props.dataView.reportId,
                        fieldName: selectedRow.fieldName,
                        dataType: selectedRow.fieldType,
                        formula: selectedRow.formula,
                        id: selectedRow.fieldId,
                        index: index
                    }
                }
            }
        }
        this.setState({ showDerivedField: true, derivedField: fieldData })
    }

    handleChange = (fieldName, value, column) => {
        let data = { fieldName: fieldName, value: value, column: column, type: types.update }
        this.props.dispatch(updateFieldDisplay(this.props.fieldDisplay, data));

    }

    deleteHighLight = (fieldName) => {
        showStatus = false;
        this.props.dispatch(updateHighLight(this.props.fieldDisplay, "", fieldName));
        
    }

    renderApplyHighlight = (item) => {
        const { classes } = this.props;
        if (item.kpiDetails) {
            if (item.kpiDetails.length > 0) {
                return (<Button classes={{ root: classes.chipLabel, endIcon: classes.buttonHighlightEndIcon }} className={classes.buttonHighlighted} variant="default"
                    endIcon={<CancelIcon className={classes.iconHighlighted} onClick={() => { this.deleteHighLight(item.fieldName) }} />}
                    onClick={() => this.showHighLight(item.fieldName, item.kpiDetails)}
                >
                            Highlighted
                </Button>);

            } else {
                return this.renderHighlightButton(item);
            }
        } else {
            return this.renderHighlightButton(item);
        }
    }

    

    renderHighlightButton = (item) => {
        const { classes } = this.props;
        return (<Button classes={{ root: classes.chipLabel, startIcon: classes.buttonHighlightStartIcon }} className={classes.buttonHighlight} variant="contained"
            startIcon={<AddIcon />}
            onClick={() => this.showHighLight(item.fieldName, item.kpiDetails)}
        >
            Highlight
        </Button>);
    }
   
    
    deleteRow = (fieldName) => {
        let data = { fieldName: fieldName, type: types.delete }
        this.props.dispatch(updateFieldDisplay(this.props.fieldDisplay, data));

    }
    getFieldNameColumnComponent = (index,item) => {
        const { classes } = this.props;
        
        if (item.fieldCategory === Enums.FieldCategory.CalculatedField) {
            return  <Link
                classes={{ root: classes.body2 }} className={classes.fieldNameWidth}
                component="button"
                variant="body2"
                underline="none"
                onClick={() => {
                    this.showDerivedField(item.fieldName, index);
                }}
            >
                <Typography classes={{ root: classes.body2 }} className={classes.fieldWrap}>{item.fieldValue}</Typography>
            </Link> 
        } else {
            return <Typography classes={{ root: classes.body2 }} className={`${classes.fieldNameWidth} ${classes.fieldWrap}`} >{item.fieldValue}</Typography >
        }
    }
    deleteRowFilterTemplate = (index, item) => {
        if (item.formula!=="") {
            return (<IconButton id={"delete" + index} type="button" onClick={(e) => this.deleteRow(item.fieldName)}  ><DeleteOutlineIcon /></IconButton>)
        } return "";
    }
    render() {
      
        this.setDisplayData();
        const { classes } = this.props;
        return (
            <div>
                {this.state.showHighLight ? <HighLight handleClose={this.closeHighLight} highLight={this.state.highLightData} visible={this.state.showHighLight} /> : ""}
                {this.state.showDerivedField ? <DerivedField fromDataView={true} isActionLink={false} handleClose={this.closeDerivedField} derivedField={this.state.derivedField} visible={this.state.showDerivedField} /> : ""}
                <Card variant="outlined" p={3} className={classes.card}>
                    <TableContainer className={classes.tableContainer}>
                        <Table stickyHeader className={classes.table}>

                            <TableHead>
                                <TableRow>
                                    <TableCell classes={{ root: classes.body2 }} className={classes.stickyHeader + ' ' + classes.dragIconStyle}>&nbsp;</TableCell>
                                    <TableCell classes={{ root: classes.body2 }} className={classes.stickyHeader + ' ' + classes.visibleStyle}>Visible</TableCell>
                                    <TableCell classes={{ root: classes.body2 }} className={classes.stickyHeader + ' ' + classes.fieldNameStyle}>Field Name</TableCell>
                                    <TableCell classes={{ root: classes.body2 }} className={classes.stickyHeader + ' ' + classes.displayLabelStyle + ' ' + classes.displayLabelHeaderWidth}>Display Label</TableCell>
                                    <TableCell classes={{ root: classes.body2 }} className={classes.stickyHeader + ' ' + classes.showLabelStyle + ' ' + classes.showLabelHeaderWidth}>Show Label</TableCell>
                                    <TableCell classes={{ root: classes.body2 }} className={classes.stickyHeader + ' ' + classes.aggregateStyle}>Aggregate</TableCell>
                                    <TableCell classes={{ root: classes.body2 }} className={classes.stickyHeader + ' ' + classes.groupByStyle}>Group By</TableCell>
                                    <TableCell classes={{ root: classes.body2 }} className={classes.stickyHeader + ' ' + classes.sortStyle}>Sort</TableCell>
                                    <TableCell classes={{ root: classes.body2 }} className={classes.stickyHeader + ' ' + classes.displayTypeStyle}>Display Type</TableCell>
                                    <TableCell classes={{ root: classes.body2 }} className={classes.stickyHeader + ' ' + classes.thumbnailStyle}>Thumbnail</TableCell>
                                    <TableCell classes={{ root: classes.body2 }} className={classes.stickyHeader + ' ' + classes.localizationStyle}>Localization</TableCell>
                                    <TableCell classes={{ root: classes.body2 }} className={classes.stickyHeader + ' ' + classes.fileNameStyle}>File Name</TableCell>
                                    <TableCell classes={{ root: classes.body2 }} className={classes.stickyHeader + ' ' + classes.highlightsStyle + ' ' + classes.highlightsHeaderWidth}>Apply Highlights</TableCell>
                                    <TableCell classes={{ root: classes.body2 }} className={classes.stickyHeader}></TableCell>
                                </TableRow>
                            </TableHead>
                            <DragDropContext onDragEnd={this.handleDragEnd}>
                                <Droppable droppableId={"droppable"} direction="vertical">
                                    {(droppableProvided: DroppableProvided) => (
                                        <TableBody
                                            ref={droppableProvided.innerRef}
                                            {...droppableProvided.droppableProps} className={classes.tableBody}
                                        >
                                            {this.props.fieldDisplay.map((item: DataItem, index: number) => (
                                                <Draggable
                                                    key={item.fieldValue + index}
                                                    draggableId={item.fieldValue}
                                                    index={index}
                                                >
                                                    {(draggableProvided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
                                                        return (
                                                            <TableRow key={item.index}
                                                                ref={draggableProvided.innerRef}
                                                                {...draggableProvided.draggableProps}
                                                                hover
                                                                onClick={(e, rowNumber) => {
                                                                    this.setState({ selectedID: item.fieldName });
                                                                    rowIndex = index;
                                                                }}
                                                                selected={this.state.selectedID === item.fieldName}
                                                                classes={{ selected: classes.selected }}
                                                                className={classes.tableRow}
                                                            >
                                                                {/* note: `snapshot.isDragging` is useful to style or modify behaviour of dragged cells */}
                                                                <TableCell align="left" className={classes.dragIconStyle} >
                                                                    <div {...draggableProvided.dragHandleProps}>
                                                                        <ReorderIcon />
                                                                    </div>
                                                                </TableCell>

                                                                <TableCell align="center" className={classes.visibleStyle}>
                                                                    <Checkbox color="primary" classes={{ root: classes.body1 }} checked={item.isVisible} onChange={(e) => this.handleChange(item.fieldName, e.target.checked, 'isVisible')} />
                                                                </TableCell>

                                                                <TableCell className={classes.fieldNameStyle}>
                                                                    {this.getFieldNameColumnComponent(index,item)}
                                                                </TableCell>

                                                                <TableCell className={classes.displayLabelStyle}> 
                                                                    <TextField InputProps={{ classes: { input: classes.body1 } }} inputProps={{ maxLength: 250, }} className={classes.displayFieldNameWidth } id={item.displayFieldName + index} defaultValue={item.displayFieldName} onBlur={(e) => this.handleChange(item.fieldName, e.target.value, columnsEnum.DisplayFieldName)} />
                                                                </TableCell>

                                                                <TableCell align="center" className={classes.showLabelStyle}>
                                                                    <Checkbox color="primary" classes={{ root: classes.body1 }}  checked={item.isDisplayTitle} onChange={(e) => this.handleChange(item.fieldName, e.target.checked, columnsEnum.IsDisplayTitle)} />
                                                                </TableCell>

                                                                
                                                                <TableCell className={classes.aggregateStyle}>
                                                                    {this.setAggregateOptions(item)}
                                                                </TableCell>

                                                                <TableCell align="center" className={classes.groupByStyle}>
                                                                    <Checkbox color="primary" classes={{ root: classes.body1 }} checked={item.isGroupable} onChange={(e) => this.handleChange(item.fieldName, e.target.checked, columnsEnum.IsGroupable)} />
                                                                </TableCell>

                                                                <TableCell className={classes.sortStyle} >
                                                                    <Select MenuProps={{ disableScrollLock: true }}  classes={{ root: classes.body1 }} className={classes.sortWidth} value={(!item.sort) ? 0 : item.sort} onChange={(e) => this.handleChange(item.fieldName, e.target.value, columnsEnum.SortingOption)} >
                                                                        {this.setDropDownData(sortingOption)}
                                                                    </Select>
                                                                </TableCell>

                                                                <TableCell className={classes.displayTypeStyle}>
                                                                    {this.setDisplayTypeOptions(item)}
                                                                </TableCell>

                                                                <TableCell className={classes.thumbnailStyle}>
                                                                    
                                                                    {this.setThumbnailImage(item)}
                                                                </TableCell>

                                                                <TableCell className={classes.localizationStyle}>
                                                                    <Select MenuProps={{ disableScrollLock: true }}  classes={{ root: classes.body1 }} className={classes.localizationWidth} value={(!item.culture) ? "" : item.culture} onChange={(e) => this.handleChange(item.fieldName, e.target.value, columnsEnum.Culture)} >
                                                                        {this.setLocalizationData(item.format)}
                                                                    </Select>
                                                                </TableCell>

                                                                <TableCell className={classes.fileNameStyle}>
                                                                    <Select MenuProps={{ disableScrollLock: true }}  classes={{ root: classes.body1 }} className={classes.fileNameWidth} value={item.fileTypeField || ""} onChange={(e) => this.handleChange(item.fieldName, e.target.value, columnsEnum.FileTypeField)}  >
                                                                        {this.setFileNameDropDownData()}
                                                                    </Select>
                                                                </TableCell>

                                                                <TableCell className={classes.highlightsStyle}>{(item.fieldType.toLowerCase() === Enums.DbType.Int || item.fieldType.toLowerCase() === Enums.DbType.Decimal || item.fieldType.toLowerCase() === Enums.DbType.Numeric || item.fieldType.toLowerCase() === Enums.DbType.SmallInt || item.fieldType.toLowerCase() === Enums.HanaDbType.Int)
                                                                    ? this.renderApplyHighlight(item)  : ""}
                                                                </TableCell>
                                                                <TableCell className={classes.cellPadding}>{this.deleteRowFilterTemplate(index, item) }</TableCell>
                                                            </TableRow>
                                                        );
                                                    }}
                                                </Draggable>
                                            ))}
                                            {droppableProvided.placeholder}
                                        </TableBody>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </Table>
                    </TableContainer>
                    {this.props.fieldDisplay ? <Grid container><Grid item className={classes.button}>
                        <Button
                            disabled={this.props.dataView.dataSourceType === ReportDataSourceType.StoredProcedure?true:false}
                            className={classes.buttonStyle}
classes={{root:classes.smallButton}}
                                variant="contained"
                                color="primary"
                                startIcon={<AddIcon />}
                                onClick={this.showDerivedField}
                            >
                                DERIVED FIELD
                            </Button>
                    </Grid></Grid> : ""}
                </Card>
            </div>
        );

    }
}

/**
 * mapStateToProps
 * @param {*} state 
 */
function mapStateToProps(state) {
    console.log("fieldDisplay", state.dataViews.dataView.fieldDisplay)
    return {
        dataView: state.dataViews.dataView,
        fieldDisplay: state.dataViews.dataView.fieldDisplay,
        isLoading: state.loader.isLoading,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage,
    };
};
export default withRouter(connect(mapStateToProps)(withStyles(fieldDisplayTheme)(FieldDisplay)));