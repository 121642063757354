import React from 'react';
import { Typography, Box } from '@mui/material';
import { customColorPalette } from 'common/theme.js';
import { formTheme } from 'client/components/Form/theme/formTheme';
import { withStyles } from '@mui/styles';

const sectionTitle = (props) => {
    const divTopColor = (props.settings.captionColor) ? props.settings.captionColor : customColorPalette.black[36];
    const typographyColor = (props.settings.captionColor) ? props.settings.captionColor : customColorPalette.black[87];
    const divStyle = {
        backgroundColor: props.settings.backGroundColor,
        borderTop: "1px solid " + divTopColor,
        paddingBottom: "1px"
    }
    return (
        <React.Fragment>
            <Box key={props.settings.controlUiId} style={divStyle}>
                <Typography
                    sx={{ mt: 1, mb: "-5px!important", textAlign: props.settings.textAlign }}
                    color={typographyColor}
                    display="block"
                    variant="h6"
                    className={props.classes.sectionTitle}
                >
                    {props.settings.caption}
                </Typography>
            </Box>
            <Box pb={2}>
            </Box>
        </React.Fragment>
    );
}
export default withStyles(formTheme)(sectionTitle);