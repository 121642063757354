import { ActionTypes as types } from 'admin/actions/actionTypes';
import { produce } from 'immer';
import { newMenu, modifyMenuStructure, modifyQuickMenuStructure, modifyNavAndQuickMenuStructure, modifyMenuItemName, clearMenuSelection} from 'admin/actions/menuActions';

const initialState = {
    menus: [],
    menu: newMenu(),
    objects: [],
    roles:[],
    isUpdate: false,
    isDelete:false,
    errors: {},
    alert: "",
    dragData: { item: { id: "", contentType: "" } },
    isLoading: true,
    groupId: 90000,
    navMenuSelectedNode: { itemSelected: [], treeViewSelected: {} },
    actionType:'',
};

const menus = produce((draft, action) => {
    draft.isUpdate = false;
    draft.actionType = action.type;
    switch (action.type) {
        case types.VGO_GET_MENUS_REQUEST: 
            draft.isLoading = true;
            break;
        case types.VGO_GET_MENUS_SUCCESS:
            draft.menus = produce(action.menus, () => { });
            draft.isLoading = false;
            draft.navMenuSelectedNode = produce(draft.navMenuSelectedNode, draftSelectedMenu => { clearMenuSelection(draftSelectedMenu) });
            break;
        case types.VGO_GET_MENU_ROLES_REQUEST:
            draft.isLoading = true;
            break;
        case types.VGO_GET_MENU_ROLES_SUCCESS:
            draft.isLoading = false;
            draft.roles = action.roles;
            break;
        case types.VGO_GET_MENU_SUCCESS:
            draft.menu = action.menu;
            draft.navMenuSelectedNode = produce(draft.navMenuSelectedNode, draftSelectedMenu => { clearMenuSelection(draftSelectedMenu) });
            draft.errors = {};
            break;
        case types.VGO_NAV_MENU_SELECTED:
            if (!draft.isDelete) {
                draft.navMenuSelectedNode = { itemSelected: [action.settings.menuItem.nodeId], treeViewSelected: action.settings.menuItem };
            }
            draft.isDelete = false;
            break;
        case types.VGO_NAV_MENU_CLEAR_SELECTION:
            draft.navMenuSelectedNode = produce(draft.navMenuSelectedNode, draftSelectedMenu => { clearMenuSelection(draftSelectedMenu) });
            break;
        case types.VGO_MODIFY_QUICKACTION_STRUCTURE_MENU:
            draft.menu = produce(draft.menu, draftMenu => { modifyQuickMenuStructure(draftMenu, action.settings) });
            draft.navMenuSelectedNode = produce(draft.navMenuSelectedNode, draftSelectedMenu => { clearMenuSelection(draftSelectedMenu) });
            draft.dragData = action.settings.dragMenu ? action.settings.dragMenu : {};
            break;
        case types.VGO_MODIFY_NAV_QUICKACTION_STRUCTURE_MENU:
            draft.menu = produce(draft.menu, draftMenu => { modifyNavAndQuickMenuStructure(draftMenu, action.settings) });
            draft.navMenuSelectedNode = produce(draft.navMenuSelectedNode, draftSelectedMenu => { clearMenuSelection(draftSelectedMenu) });
            draft.dragData = action.settings.dragMenu ? action.settings.dragMenu : {};
            break;
        case types.VGO_MODIFY_NAV_STRUCTURE_MENU:
            {
                draft.menu = produce(draft.menu, draftMenu => { modifyMenuStructure(draftMenu, action.settings) });
                draft.dragData = action.settings.dragMenu ? action.settings.dragMenu : {};
                if (action.settings.addGroup) {
                    draft.groupId = draft.groupId + 1;
                }
                else if (action.settings.deleteMenu) {
                    draft.isDelete = true;
                }
                draft.navMenuSelectedNode = produce(draft.navMenuSelectedNode, draftSelectedMenu => { clearMenuSelection(draftSelectedMenu) });
                break;
            }
        case types.VGO_CHANGE_MENU_MENUITEM_LABEL:
            draft.menu = produce(draft.menu, draftMenu => { modifyMenuItemName(draftMenu, action.settings) });
            break;
        case types.VGO_CHANGE_MENU:
            action.change(draft.menu);
            draft.navMenuSelectedNode = produce(draft.navMenuSelectedNode, draftSelectedMenu => { clearMenuSelection(draftSelectedMenu) });
            break;

        case types.VGO_NEW_MENU:
            draft.menu = newMenu();
            draft.errors = {};
            draft.navMenuSelectedNode = produce(draft.navMenuSelectedNode, draftSelectedMenu => { clearMenuSelection(draftSelectedMenu) });
            break;

        case types.VGO_DELETE_MENU_SUCCESS:
            {
                let menus = draft.menus;
                if (draft.menus.length > 0) {
                    menus = draft.menus.filter((d) => d.menuId !== action.menuId);
                }
                draft.menus = menus;
                draft.navMenuSelectedNode = produce(draft.navMenuSelectedNode, draftSelectedMenu => { clearMenuSelection(draftSelectedMenu) });
            }
            break;

        case types.VGO_UPDATE_MENU_SUCCESS:
            {
                const menu = action.menu;
                let menuIndex = draft.menus.findIndex(d => d.menuId === menu.menuId);
                if (menuIndex >= 0) {
                    draft.menus[menuIndex] = menu;
                    draft.isUpdate = true;
                } else {
                    draft.menus.push(menu);
                    draft.isUpdate = false;
                }
                draft.menu = menu;
                draft.navMenuSelectedNode = produce(draft.navMenuSelectedNode, draftSelectedMenu => { clearMenuSelection(draftSelectedMenu) });
            }

            break;

        case types.VGO_VALIDATE_MENU_ONBLUR:

            draft.errors = produce(draft.errors, draftErrors => { return { ...draftErrors, ...action.errors } });
            break;

        case types.VGO_VALIDATE_MENU_ERROR:
            draft.alert = action.alert;
            break;

        case types.VGO_GET_MENU_CONTENT_DRAG_DATA:
            draft.dragData = action.dragData;
            break;

        default:
            break;
    }
}, initialState);

export default menus;

