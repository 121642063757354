import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { styled } from '@mui/styles';
import { withStyles } from '@mui/styles';
import { spacing } from '@mui/system';
import {  Grid, Typography, MenuItem, Box } from '@mui/material';
import MuiCard from '@mui/material/Card';
import { getRoles, getMenu, getChangeMenu, getNewMenu, validateFieldsOnBlur, getContentDragData, modifyNavMenuStructure, modifyQuickActionMenuStructure } from 'admin/actions/menuActions';
import {getNewRole } from 'admin/actions/roleActions';
import Setup from './Setup';
import ContentList from './ContentList';
import { menuTheme} from './theme/menuTheme';
import { showError } from 'admin/actions/alertActions';

import NavMenuStructure from './NavMenuStructure';
import QuickActionMenuStructure from './QuickActionMenuStructure';

import RightArrow from 'admin/assets/images/RightArrow.png';
import LeftArrow from 'admin/assets/images/LeftArrow.png';
import * as Enums from 'client/components/Common/Enum';

const Card = styled(MuiCard)(spacing);

let addMainMenu = false;
const topLevel = 3;
const errorMessage = 'This item is already in the Menu';
class MenuEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isUpdate: this.props.match.params.menuId !== "new",
            objectType: "",
            tabIndex: 0,
            treeViewSelected: [],
            structurePosition: [],
        };
    }

    componentDidMount() {
        
        const menuId = this.props.match.params.menuId;
        if (this.state.isUpdate) {
            this.props.dispatch(getMenu(menuId));
        }
        else
        {
            this.dispatchGetRoles(0);
            this.props.dispatch(getNewMenu());
            this.props.dispatch(getNewRole());
        }
    }
    componentDidUpdate(prevProps) {

        if (this.props.isUpdateSuccess !== prevProps.isUpdateSuccess)
        {
            if (this.props.isUpdateSuccess && this.props.menu.menuId > 0)
            {
                this.props.history.push(`/Admin/Menus/${this.props.menu.menuId}`);
            }
        }
        else if (this.props.menu.menuId > 0 && this.props.menu.menuId !== prevProps.menu.menuId )
        {
            if (this.props.actionType !== 'VGO_UPDATE_MENU_SUCCESS') {
                const roleId = this.props.menu.menuRoles.length > 0 ? this.props.menu.menuRoles[0].roleId : 0;
                this.dispatchGetRoles(roleId);
            }
        }

    }

    dispatchGetRoles = (roleId) => {
        this.props.dispatch(getRoles(roleId ));
    }

    changeMenu = (change) => {
        this.props.dispatch(getChangeMenu(change));
    }

    droppedQuickActions = (event, dropOnMenuItem) => {
        const { dragData, dropData } = event;
        const hasItem = this.props.menu.quickActions.findIndex(item => item.id === dragData.id && item.contentType === dragData.contentType)
        if (hasItem >= 0) {
            showError(this.props.dispatch, errorMessage, 5000);

        } else {
            
            this.props.dispatch(modifyQuickActionMenuStructure({ item: dragData }, dropOnMenuItem ? dropData: undefined, true, false, false));
        }
    }

    droppedMainMenu = (event, dropOnMenuItem) => {
        const { dragData, dropData } = event;
        const hasItem = dropData.objectList.findIndex(item => item.id === dragData.id && item.type === dragData.contentType)
        if (hasItem >=0) {
            showError(this.props.dispatch, errorMessage, 5000);
        } else {
            this.props.dispatch(modifyNavMenuStructure({ item: dragData }, dropOnMenuItem ? dropData : undefined, true, false, false));
            
        }
    }
    
    recursiveDropToMainMenu = (node, data, id, contentType, count) => {
        let items = [...node.items];
        if (node.id === id && node.contentType === 0) {
            items.push(data);
            return { ...node, items: items }
        }
        let index = node.items.findIndex(obj => obj.id === id && obj.contentType === contentType);
        if (index !== -1) {
            items.splice(index, 0, data);
            return { ...node, items: items }
        }
        let newItems = node.items.map(childNode => {
            if (count < topLevel) {
                return this.recursiveDropToMainMenu(childNode, data, id, contentType, count + 1);
            }
            return { ...childNode, items: childNode.items }
        })
        return { ...node, items: newItems }
    }

    addRepetitionItem = (nodes, id, type, dragData) => {
        const newItem = Array.isArray(nodes)
            ? nodes.map((node) => {
                let items = Object.assign([], node.items);
                if (node.id === id && node.contentType === 0) {
                    items.push(dragData);
                    return { ...node, items: items }
                }

                if (node.items.some(item => item.id === id && item.contentType === type)) {
                    items.push(dragData);
                    return { ...node, items: items }
                } else {
                    return { ...node, items: items }
                }
            })
            : null;

        return newItem;
    }



    handleDrop = (event) => {
        if (event.dropData.mode === Enums.MenuItemMode.MainMenu) {
            if (!addMainMenu) {
                if (event.dropElem) {

                    if (event.dropElem.firstElementChild && event.dropElem.firstElementChild.childNodes.length > 0) {
                        if (event.dropElem.firstElementChild.childNodes[0].childElementCount > 0 && event.dropElem.firstElementChild.childNodes[0].childNodes[0].id === "mainMenuTreeView") {
                            this.droppedMainMenu(event, false);
                        }
                        else {
                            this.droppedMainMenu(event, true);
                        }
                    }
                   
                }
            }
                
            else
                addMainMenu = false;
        }
        else
        {
            if (event.dropElem) {

                if (event.dropElem.firstElementChild && event.dropElem.firstElementChild.childNodes.length > 0)
                {
                    if (event.dropElem.firstElementChild.childNodes[0].childElementCount > 0 && event.dropElem.firstElementChild.childNodes[0].childNodes[0].id === "quickActionsTreeView")
                    {
                        this.droppedQuickActions(event, false);
                    }
                    else
                    {
                        this.droppedQuickActions(event, true);
                    }
                    
                }
               
            }
            
        }
    }

    handleBlurValidation = (fieldName) => {

        this.props.dispatch(validateFieldsOnBlur(this.props.menu, fieldName));
    }

    fillRoles = (roles) => {
        let newRoles = roles.map((role, index) => {
            return (<MenuItem key={index} value={role.roleId}> {role.roleName} </MenuItem>);
        });
        newRoles.unshift(<MenuItem key={0} value={0}>{""}</MenuItem>);

        return newRoles;
    }
   
    render() {
        const { classes } = this.props;
        return (
            <React.Fragment >
                <Card variant="outlined" className={classes.card}>
                    <Grid container className={classes.header}>
                        <Grid item xs={6}>
                            <Typography variant="body1">Information</Typography>
                        </Grid>
                        <Grid item xs={6} ml={-7.35}>
                            <Typography variant="body1">Availability</Typography>
                        </Grid>
                    </Grid>

                    <Grid container xs={12} pb={3}>
                        <Setup />
                    </Grid>

                    <Grid container className={classes.header}>
                        <Grid item xs={6}>
                            <Typography variant="body1">Content</Typography>
                        </Grid>
                        <Grid item xs={6} ml={-7.35}>
                            <Typography variant="body1">Menu</Typography>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.header} pt={3.25}>
                        <Grid item xs={4} >
                            <ContentList handleDrop={this.handleDrop} />
                        </Grid>
                        <Grid item xs={2}>
                            <Box pl={11} pt={43.75}>
                                <img alt='' src={RightArrow} />
                            </Box>
                            <Box pl={11} mt={-.5}>
                                <img alt='' src={LeftArrow} />
                            </Box>
                        </Grid>
                        <Grid item xs={6} ml={-7.35}>
                            <Box p={2} mr={-3} border={1} mb={.4} pr={0} borderColor="grey.300" >
                                <QuickActionMenuStructure />
                            </Box>
                            <Box p={2} pr={0} mr={-3} pr={0} border={1} borderColor="grey.300" >
                                <NavMenuStructure topLevel={topLevel} />
                            </Box>
                        </Grid>
                    </Grid>
                </Card>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state) {
     return {
         menu: state.menus.menu,
         actionType: state.menus.actionType,
         isLoading: state.loader.isLoading,
     };
 };

export default withRouter(connect(mapStateToProps)(withStyles(menuTheme)(MenuEdit)));


