export const alertStyle = () => ({
    wrapper: {
        width: "716px!important",
        height: "43px!important",
        paddingTop: '8px!important'
        
    },
    root: {
        paddingBottom: "0px!important",
        paddingTop: "0px!important"
    },
    closeIcon: {
        color:"black!important"
    },
    confirmContainer: {
        minWidth: "130px!important"
    }


});