import { theme, customThemeStyles, customColorPalette } from 'common/theme.js';
import * as Enums from 'client/components/Common/Enum';
export const chartTheme = () => ({
    ...customThemeStyles,
    tabRoot: {
        background: 'white!important',
        color: 'rgba(0, 0, 0, 0.87)!important'
    },
    chartTab: {
        minWidth: '145.09px!important',
        maxWidth: '360px!important',
        [theme.breakpoints.down('sm')]: {
            minWidth: '112px!important',
            maxWidth: '112px!important',
        },
    },
    funnelTitle: {
        textAlign: 'center',
        paddingTop: '9px!important',
        color: customColorPalette.black[58],
    },
    funnelLegend: {
        textAlign: 'center',
        paddingTop: '18px!important',
        color: `${customColorPalette.black[36]} !important`,
        position: 'relative',
        zIndex: 1000,
        maxHeight: '508px!important',
        overflowY: 'hidden'
    },
    dashboardFunnelLegend: {
        textAlign: 'center',
        paddingTop: '18px!important',
        color: `${customColorPalette.black[36]} !important`,
        position: 'relative',
        zIndex: 1000,
    },
    funnelTooltip: {
        width: 'auto',
        height: 'auto',
        display: 'inline-block',
        padding: '2px 10px',
        borderWidth: '2px',
        borderRadius: '20px',
    },
    funnelTooltipPosition: {
        position: 'absolute',
        zIndex: 10000,
    },
    funnelTooltipBackground: {
        backgroundColor: '#fff',
    },
    popup: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 17.6,
        marginRight: -18,
    },
    dashboardPanelpopup: {
        display: 'flex',
        justifyContent: 'flex-end',
        position: "absolute",
        right: "8px",
        zIndex:1000
    },
    funnelDecorationNone: {
        textDecoration: 'none',
        color: `${customColorPalette.black[87]} !important`,
        [theme.breakpoints.down('sm')]: {
            fontSize: "12px !important"
        },
    },
    funnelDecoration: {
        textDecoration: 'line-through solid black 2px',
        [theme.breakpoints.down('sm')]: {
            marginTop: "12px !important"
        },
    },
    chartPopup: {
        height: "65%",
        justifyContent: 'center',
        margin: 'auto'
    },
    funnelMarginTop: {

        [theme.breakpoints.up('lg')]: {
            marginTop: "-4% !important"
        },
        [theme.breakpoints.down('lg')]: {
            marginTop: "-10% !important"
        },
        [theme.breakpoints.down('md')]: {
            marginTop: "-15% !important"
        },

    },
    chartTitle: {

        textAlign: "center",
        marginTop: "6px !important",
        fontSize: 16,
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 400,
        letterSpacing: 0.15,
        color: `${customColorPalette.black[58]} !important`
    },
    chartTitleReport: {
        fontSize: 16,
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 400,
        letterSpacing: 0.15,
        textAlign: "center",
        marginTop: "65px !important",
        color: `${customColorPalette.black[58]} !important`
    }
});

export const getChartAxesLabelStyle = (deviceType) => {
    if (deviceType === Enums.DeviceType.Mobile) {
        return ({
            fontFamily: "Roboto",
            fontWeight: 100,
            fontSize: 10,
            fontStyle: "normal",
            letterSpacing: 0.5,
            fontColor: 'rgba(0, 0, 0, 0.36)',
        })
    }
    else if (deviceType === Enums.DeviceType.Tablet) {
        return ({
            fontFamily: "Roboto",
            fontWeight: 200,
            fontSize: 13,
            fontStyle: "normal",
            letterSpacing: 0.5,
            fontColor: 'rgba(0, 0, 0, 0.36)',
        })
    }
    else {
        return ({
            fontFamily: "Roboto",
            fontWeight: 400,
            fontSize: 16,
            fontStyle: "normal",
            letterSpacing: 0.5,
            fontColor: 'rgba(0, 0, 0, 0.36)',
        })
    }
}

