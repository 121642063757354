import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Typography, Box } from '@mui/material';
import { showError } from 'admin/actions/alertActions';
import { TreeView } from '@mui/lab';
import {  DropTarget } from 'react-drag-drop-container';
import NavMenuItem from './NavMenuItem';
import { menuTheme } from './theme/menuTheme';
import { modifyQuickActionMenuStructure, modifyNavAndQuickActionMenuStructure, changeMenuItemName, clearSelection } from 'admin/actions/menuActions';
import { withStyles } from '@mui/styles';
import * as Enums from 'client/components/Common/Enum';
const errorMessage = 'This item is already in the Menu';
class QuickActionMenuStructure extends React.PureComponent {


    handleMainMenuNodeSelect = (menuItem) => {
        this.setState({ treeViewSelected: menuItem, itemSelected: [menuItem.nodeId] });
    };
    handleDropMainMenu = (event) => {
        const { dragData, dropData } = event;
        if (dropData.mode === Enums.MenuItemMode.QuickAction) {
            this.props.dispatch(modifyQuickActionMenuStructure(dragData, dropData, dragData.isList, dragData.isNav, false));
        }
        else if (dropData.mode === Enums.MenuItemMode.MainMenu)
        {
            //adding from  QuickAction to nav
            const hasItem = dropData.objectList.findIndex(item => item.id === dragData.id && item.type === dragData.contentType)
            if (hasItem >= 0) {
                showError(this.props.dispatch, errorMessage, 5000);
                
            }
            else
            {
                this.props.dispatch(modifyNavAndQuickActionMenuStructure(dragData, dropData, dropData.add === "new", dropData.add !== "new", false));
            }
        }
        
    };

    handleDeleteMainMenu = (menuItem) => {
        this.props.dispatch(modifyQuickActionMenuStructure(menuItem, undefined, false, false, true));
    }
    handleBlurMainMenu = (menuItem, label) => {
        this.props.dispatch(changeMenuItemName(label, menuItem, 0));
    }
    
    onClickMenuFooter = (event) => {
        if (this.props.selectedMenuItem.itemSelected.length !== 0) {
            this.props.dispatch(clearSelection());
        }


    }
    renderChildNode = (nodes, menuType, index) => {
        let contentType = (nodes.contentType === 0) ? "" : nodes.contentType;
        let contentSubType = (nodes.contentSubType === 0) ? "" : nodes.contentSubType;

        const nodeId = `${nodes.id}|${contentType}`;
        return (
            <NavMenuItem
                id={nodes.id}
                keyId={"key_" + nodes.id}
                nodeId={nodeId}
                labelText={nodes.label}
                color="#1a73e8"
                bgColor="#1a73e8"
                targetKey="quickAction"
                contentTypeText={contentType}
                contentSubTypeText={contentSubType}
                menuType={Enums.MenuItemMode.QuickAction}
                items={nodes.items}
                item={nodes}
                level={0}
                index={index}
                isMenu={true}
                parent={false}
                objectList={[]}
                onDeleteMainMenu={this.handleDeleteMainMenu}
                onBlurMainMenu={this.handleBlurMainMenu}
                quickActions={this.props.menu.quickActions}
                onDropMainMenu={this.handleDropMainMenu}
                onSelectMainMenu={this.handleMainMenuNodeSelect}
            >
            </NavMenuItem>
        )
    };
    renderParentNode = (nodes, menuType) => {
        return (
            <div>
                {nodes.map((node, index) => {
                    return (
                        <div key={index}>{this.renderChildNode(node, menuType, index)}</div>
                    )
                })}
            </div>
        )
    };
    render() {
        
        const { classes } = this.props;
        const dropData = { mode: Enums.MenuItemMode.QuickAction, add: "new", quickActions: this.props.menu.quickActions };
        
        return (
            <React.Fragment>
                <Box onClick={this.onClickMenuFooter}>
                    <Typography variant="body1" className={classes.menuColor}>Quick Actions</Typography>
                </Box>
                <Box pt={1} pl={1} onClick={this.onClickMenuFooter} >
                    <div id="super" style={{ height:"180px", display:"flex", flexDirection:"column"}} >
                        <div id="top" >
                            
                                <TreeView 
                                sx={{ maxHeight: "170px", overflowX: 'auto', paddingBottom:1 }}
                                >
                                    {this.renderParentNode(this.props.menu.quickActions, "action")}
                                </TreeView>
                            
                        </div>
                        <div id="bottom" style={{ flex: 1 }} >
                            <DropTarget targetKey="content" dropData={dropData}>
                                <DropTarget targetKey="mainMenu" dropData={dropData}>
                                    <DropTarget targetKey="quickAction" dropData={dropData}>
                                    <Box id="quickActionsTreeView" style={{ height: "100%" }} />
                                    </DropTarget>
                                </DropTarget>
                            </DropTarget>
                        </div>
                    </div>




                            
                       
                     
                        
                </Box>
            </React.Fragment>
        );
    }

}
function mapStateToProps(state) {
    return {
        menus: state.menus.menus,
        menu: state.menus.menu,
        menuGroupId: state.menus.groupId,
        roles: state.roles,
        objects: state.menus.objects,
        isLoading: state.loader.isLoading,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage,
        isUpdateSuccess: state.menus.isUpdate,
        alert: state.menus.alert,
        selectedMenuItem: state.menus.navMenuSelectedNode,
    };
};

export default withRouter(connect(mapStateToProps)(withStyles(menuTheme)(QuickActionMenuStructure)));