import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';

import ExportList from 'admin/components/section/package/ExportList';
import PackageInformation from 'admin/components/section/package/PackageInformation';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import { packageTheme } from './theme/packageTheme';


import {addItem, removeItem, getDependecyListItems, selectAllItem,getExportListItems } from 'admin/actions/packageActions';


import ActionBackdrop from 'admin/components/section/settings/Backdrop';
import { ExportObjectType } from 'client/components/Common/Enum';




class ExportContent extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
           panel:true,
        };
    }
   

    componentDidMount() {
        this.dispatchGetExportList();
    }

    dispatchGetExportList = () => {
        this.props.dispatch(getExportListItems());
    }
 
    
    modifyItemList = (item,add) => {
        if (add) {
            //getDependecyListItems
            if (this.props.selectDependencies && item.objectType !== ExportObjectType.Role && item.objectType !== ExportObjectType.Company ) {
                this.props.dispatch(getDependecyListItems(item, this.props.exportPackage));
            }
            else
            {
                this.props.dispatch(addItem(item));
            }
            
        }
        else
            this.props.dispatch(removeItem(item));
    }
    selectAll = (checked) => {
        this.props.dispatch(selectAllItem(checked));
    }
    
    handlePanelChange = () => {
        this.setState({panel:!this.state.panel})
    }
    
   render() {
       
        const { classes } = this.props;
       
       return (
           <div>
               {this.props.isLoading && <ActionBackdrop/>}
           <Grid container direction="row" justifyContent="space-between" >
                   <Grid item xs={12} >
                       <Accordion expanded={this.state.panel} onChange={this.handlePanelChange} >
                               <AccordionSummary classes={{ content: classes.packageSetup, root: classes.packageSetup, }}
                                   expandIcon={<ExpandMoreIcon />}
                                   aria-controls="panel1a-content"
                                   id="panel1a-header"
                               >
                               <Typography className={classes.heading} pt={1}>Package Information</Typography>
                               </AccordionSummary >
                               <AccordionDetails >
                                   <PackageInformation />
                               </AccordionDetails>
                           </Accordion>
                       
                   
                   </Grid>
               </Grid>
               <Grid container direction="row" justifyContent="space-between" >
                   <Grid item xs={12} pt={1}>
                       <ExportList modifyItemList={this.modifyItemList} selectAll={this.selectAll} isLoading={this.props.isLoading} />
                       </Grid>
                </Grid>
           </div>
       );
        
    }
}

function mapStateToProps(state) {
    
     return {
         exportPackage: state.packages.package,
         isLoading: state.packages.isLoading,
         enableDownloadLog: !state.packages.downloadLog,
         selectDependencies: state.packages.selectDependencies,
     };
 };

export default withRouter(connect(mapStateToProps)(withStyles(packageTheme)(ExportContent)));


