import React from 'react';
import { FormControl, Select, MenuItem, FormHelperText } from '@mui/material';
import Cookies from 'universal-cookie';
import * as enumerator from 'client/components/Common/Enum';
import DateTime from '../../Form/Controls/DateTimePicker';
import { formFormatValue, formatDateCulture, formatValue } from 'client/components/Common/Utility';
const cookies = new Cookies();
class FilterSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectItems: [],
            parameterDataType: "",
            parameterCulture: "",
        }
    }
    componentDidMount() {
        this.generateFilterControlTypeSelectOptions();
    }

    generateFilterControlTypeSelectOptions = async () => {
        let filterOptions = [];
        if (this.props.filterObj.dropDownListValueType === "static" & this.props.filterObj.reportFilterDetails.length > 0) {
            for (let index in this.props.filterObj.reportFilterDetails) {
                let filterDetail = this.props.filterObj.reportFilterDetails[index];
                if (filterDetail.valueDescription) {
                    filterOptions.push(<MenuItem key={index + 1} className={this.props.menuItemClassName} value={filterDetail.valueDisplayId}> {filterDetail.valueDescription}</MenuItem>)
                }
            }
            this.setState({ selectItems: filterOptions, parameterDataType: this.props.filterObj.parameterDataType })
        } else if (this.props.filterObj.dropDownListValueType === "dynamic") {
            var reportId = this.props.reportId;
            var parameterName = this.props.filterObj.parameterName;

            let url = `api/ObjectData/GetFilterDataSourceData/${reportId}/${parameterName}`;
            if (this.props.ReportDataSourceType === enumerator.ReportDataSourceType.CrystalReport) {
                url = `api/ObjectData/CrystalReportFilterDataSourceData/${reportId}/${parameterName}`;
            }
            else {
                let versagoCookie = cookies.get('Versago');

                if (versagoCookie === undefined) {
                    url = `api/ObjectData/GetpublicFilterDataSourceData/${reportId}/${parameterName}`;
                }
            }

            const response = await fetch(url)
            if (response.status === 200) {
                const reportDefinition = await response.json();
                var formatColObj = {
                    fieldFormat: reportDefinition.format === "none" ? this.props.filterObj.parameterDataType : reportDefinition.format,
                    culture: reportDefinition.format === "none" ? "" : reportDefinition.culture.replace("mm", "MM")
                }
                for (let index in reportDefinition.fieldData) {
                    let optionObject = reportDefinition.fieldData[index];
                    if (this.props.filterObj.parameterDataType == "date" || this.props.filterObj.parameterDataType == "datetime" || this.props.filterObj.parameterDataType == "time") {

                        var formatedDate = optionObject.value;
                        if (formatColObj.culture != "") {
                            formatedDate = formFormatValue(formatColObj, optionObject.value);//formatDateCulture(optionObject.description, reportDefinition.culture);
                        }
                        else {
                            formatedDate = new Date(formatedDate).toLocaleString();
                        }
                        filterOptions.push(<MenuItem key={index + 1} className={this.props.menuItemClassName} value={formatedDate.toString()}> {formatedDate.toString()}</MenuItem>)
                    } else {
                        if ((this.props.filterObj.parameterDataType.toLowerCase() === enumerator.DbType.Int || this.props.filterObj.parameterDataType.toLowerCase() === enumerator.DbType.SmallInt || this.props.filterObj.parameterDataType.toLowerCase() === enumerator.DbType.Decimal || this.props.filterObj.parameterDataType.toLowerCase() === enumerator.DbType.Numeric) && reportDefinition.format !== enumerator.FormatType.None && typeof Number(optionObject.description)) {
                            let column = { fieldFormat: reportDefinition.format, culture: reportDefinition.culture }
                            filterOptions.push(
                                <MenuItem key={index + 1} className={this.props.menuItemClassName} value={optionObject.value.toString()}> {formatValue(column, parseFloat(optionObject.description))}</MenuItem>)
                        } else {

                            filterOptions.push(
                                <MenuItem key={index + 1} className={this.props.menuItemClassName} value={optionObject.value.toString()}> {optionObject.description}</MenuItem>)
                        }
                    }
                }
                this.setState({ selectItems: filterOptions, parameterDataType: formatColObj.fieldFormat, parameterCulture: formatColObj.culture })
            }
        }

    }


    checkValue = (value) => {
        if (this.state.selectItems.length > 0) {
            var checkValue = value[0];
            if (this.state.parameterDataType == "date" || this.state.parameterDataType == "datetime" || this.state.parameterDataType == "time") {
                var formatColObj = {
                    fieldFormat: this.state.parameterDataType,
                    culture: this.state.parameterCulture

                }


                if (formatColObj.culture != "") {
                    checkValue = formFormatValue(formatColObj, checkValue);//formatDateCulture(optionObject.description, reportDefinition.culture);
                }
                else {
                    checkValue = checkValue;//new Date(checkValue).toLocaleString();
                }

                // checkValue = formFormatValue(formatColObj, checkValue);
                value[0] = checkValue;
            }
            var element = null;
            element = this.state.selectItems.find(element => element.props.value?.toUpperCase() === checkValue?.toUpperCase())

            if (element) {
                return value
            } else {
                if (this.props.defaultvalue) {
                    this.props.onValueChange(this.props.indexValue, this.props.filterObj.parameterName, null, [], null, this.props.ReportDataSourceType, this.props.isSingle)
                }
            }
        }
        return [];
    }
    multiSelectDisplay = (selected) => {

        return selected.join(',')
    }

    render() {
        let selectedValue = [];
        const value = this.props.defaultvalue;
        if (!(!value)) {
            selectedValue = typeof value === 'string' ? value.split('|') : value;
        }
        const showError = (this.props.error && (!value))
        return (
            <FormControl variant="standard" error={showError}>

                <Select variant="standard" MenuProps={{ disableScrollLock: true }}

                    className={this.props.className}
                    displayEmpty
                    multiple={(this.props.isSingle || this.props.ReportDataSourceType === enumerator.ReportDataSourceType.StoredProcedure || this.props.ReportDataSourceType === enumerator.ReportDataSourceType.CrystalReport) ? false : true}
                    value={this.checkValue(selectedValue)}
                    renderValue={(selected) => selected.length === 0 ? <span className={this.props.placeHolderClassName}>Select</span> : this.multiSelectDisplay(selected)}
                    onChange={(e) => this.props.onValueChange(this.props.indexValue, this.props.filterObj.parameterName, null, e.target.value, null, this.props.ReportDataSourceType, this.props.isSingle)}
                >
                    {this.state.selectItems}
                </Select>
                <FormHelperText>{showError ? this.props.helperText : ' '}</FormHelperText>
            </FormControl>
        )
    }
} export default FilterSelect