import React, { Component } from 'react'
import ReactDom from 'react-dom'
import PropTypes from 'prop-types'
let timeout;
export default class Trigger extends Component {
    static propTypes = {
        type: PropTypes.string,
        children: PropTypes.object,
        setVisibility: PropTypes.func,
        getCursorPosition: PropTypes.func
    }

    constructor(props) {
        super(props)
        this.state = {
            styles: {}
        }
    }

    componentDidMount() {
        let childStyles = window.getComputedStyle(
            ReactDom.findDOMNode(this.refs.triggerContainer.children[0]),
        )
           this.setState({
            styles: {
                width: childStyles.getPropertyValue('width'),
                height: childStyles.getPropertyValue('height'),
                margin: childStyles.getPropertyValue('margin')
            }
        })
    }

    render() {

        const { styles } = this.state
        return (
            <div
                onMouseOver={this.onMouseOver.bind(this)}
                onMouseOut={this.onMouseOut.bind(this)}
                onMouseMove={this.onMouseMove.bind(this)}
                onTouchStart={this.onTouchStart.bind(this)}
                onTouchEnd={this.onTouchEnd.bind(this)}
                ref="triggerContainer"
                className={this.props.children.props.imageStyle}
            >
                {this.props.children.props.children}
            </div>
        )
    }

    onMouseOver() {

        timeout = setTimeout(() => {
            const { setVisibility } = this.props.children.props
            setVisibility(true)
        }, 500)

    }

    onMouseOut() {
        clearTimeout(timeout)
        const { setVisibility } = this.props.children.props
        setVisibility(false)
    }

    onMouseMove(e) {
        const { getCursorPosition } = this.props.children.props
        getCursorPosition(e)
    }

    onTouchStart() {
        const { setVisibility } = this.props.children.props
        setVisibility(true)
    }

    onTouchEnd() {
        const { setVisibility } = this.props.children.props
        setVisibility(false)
    }
}