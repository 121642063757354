import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Profile from './Profile.js';
import Cookies from 'universal-cookie';
import { getActiveUser} from 'admin/actions/activeUserAction';
import ActionBackdrop from 'admin/components/section/settings/Backdrop';
const cookies = new Cookies();

class ProfileWrapper extends React.Component {

    componentDidUpdate() {
        if (!this.props.activeUser.roleName && !this.props.isLoading) {
            const versagoCookie = cookies.get('Versago');
            if (versagoCookie !== undefined) {
                let roleCookie = cookies.get('roleId');
                this.props.dispatch(getActiveUser(roleCookie));
            }
        }
    }

    userMenuItemClick = async (item) => {
        if (item.key === "signout") {
            const response = await fetch("api/Login/SignOut");
            if (response.status === 200) {
                this.props.history.push("/");
            }

        }

    }

    showUserMenu = () => {
        let image = "";
        if (this.props.activeUser.profileImage !== undefined && this.props.activeUser.profileImage !== '')
            image = `data:image/png;base64,${this.props.activeUser.profileImage}`;
        const userMenu = [];
        userMenu.push({ key: "signout", label: "Sign Out" });

        const user = {
            name: this.props.activeUser.firstName,
            title: this.props.activeUser.roleName,
            image: image,
            roleList: userMenu
        }

        return (<Profile isAdmin={true} user={user} handleClick={this.userMenuItemClick} ></Profile>);

    }

    render() {
        return (
            <div>

                {this.showUserMenu()}
            </div>
        );
    }
}

function mapStateToProps(state) {
    let userState = state.activeUser;
    let props = {
        activeUser: userState.activeUser,
        isLoading: state.loader.isLoading,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage,
    };
    console.log('ProfileWrapper - mapStateToProps state: ', state, ', props: ', props);
    return props;
};


export default withRouter(connect(mapStateToProps)(ProfileWrapper));