import { getRandomCompany, getRandomTables, getRandomFields, getRandom } from 'admin/mock/mockData';

/**
 * represents back end store
 */
let mockData = {
    filters: [],
    databases: [{ databaseId: -1, databaseConnectionName: "" }],
    datasources :[],
    fields :[]

};

/**
 * gets or generates filters and returns all
 */
const mockGetFilters = () => {
    console.log( 'mockGetFilters' );
    if ( mockData.filters.length === 0 ) {
        let filters = generateFilters();
        mockData.filters = filters;
    }
    return {
        data: [...mockData.filters],
    };
};

/**
 * Updates the mock back end store
 * @param {Filter} filter 
 */
const mockUpdateFilter = ( filter ) => {
    let updatefilter = {
        ...filter,
    };
    let filters = mockData.filters;
    let index = filters.findIndex(elem => elem.profileId === updatefilter.profileId );
    // keep updated version in "back-end store"
    if ( index >= 0 ) {
        filters[index] = updatefilter;
    }
    return {
        data: updatefilter,
    };
};

/**
 * Finds the mock back end filter and returns
 * @param {int} id 
 */
const mockGetFilter = (id) => { 
    let filter = null;
    let filters = mockData.filters;
    console.log("filter List ",filters);
    let index = filters.findIndex(elem => elem.profileId === parseInt(id));
    console.log("index", index);
    if ( index >= 0 ) {
        filter = filters[ index ];
    }
    let result = {
        data: filter
    };
    console.log( "mockGetFilter - id: ", id, ', result: ', result );
    return result;
}

/**
 * mock delete filter
 */
const mockDeleteFilter = (id) => { 
    
    mockData.filters = mockData.filters.filter(elem => elem.profileId !== parseInt(id));
    let result = {
        data: mockData.filters,
    };
    console.log("mockGetFilter - id: ", id, ', result: ', result);
    return result;
}

/**
 * Utility function to generator mock filters
 * @param {int} num 
 */
const generateFilters = ( num = 10 ) => {
    console.log('generateFilters', num);
    let filters = [];
    for (let i = 0; i < num; i++) {
        let month1 = getRandom(0, 12);
        let max = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        let day1 = getRandom(1, max[month1] + 1);
        filters.push({
            profileId: i + 1,
            profileName: "AutoGenProfile" + (i + 1),
            createdUserId: 1,
            createdDateTime: day1,
            updatedUserId: 1,
            updatedDateTime: day1,
            remarks: "AutoGenProfile" + (i + 1),
            sourceTableName: getRandomTables(),
            company: {
                companyId: i + 1,
                companyName: getRandomCompany()
            },
            filterDetails: {
                profileId: i + 1,
                fieldName: getRandomFields(),
                fieldDisplayName: getRandomFields(),
                isText: true,
                profileFieldIdentifier: "",
                id: i + 1
            }
        }) 
    };
    return filters;
}; 

/*
 *  
    Data Source object for combo box
 */
const mockGetDatabaseList=()=> {
    for (let i = 0; i < 10; i++) {
        let database = { databaseId: i+1, databaseConnectionName: getRandomCompany() };
        mockData.databases.push(database);
    }
    return {
        data: [...mockData.databases]
    }; 
}
const mockGetDataSourceList= (companyid) => {
    for (let i = 0; i < 10; i++) {
        let datasource = [getRandomTables()];
        mockData.datasources.push(datasource);
    } 
    return {
        data: [...mockData.datasources]
    };
}
const mockGetFieldsList=(companyid, dataSource) => {
    for (let i = 0; i < 10; i++) {
        let filds = [getRandomFields()];
        mockData.fields.push(filds);
    } 
    return {
        data: [...mockData.fields]
    };
}

export { mockGetFilters, mockUpdateFilter, mockGetFilter, mockDeleteFilter, mockGetDatabaseList, mockGetDataSourceList, mockGetFieldsList};