import { theme, customThemeStyles, customColorPalette } from 'common/theme';
const listStyle= {
    listStyle: "none outside none"
}
export const sideBarTheme = () => ({
    ...customThemeStyles,
    root: {
        [theme.breakpoints.up('lg')]: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: 265
        },
        [theme.breakpoints.down('lg')]: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: "100%"
        },
        
    },
    input: {
        /*marginLeft: theme.spacing(1),*/
        flex: 1,
        fontSize: 16,
        height: 40

    },
    iconButton: {
        padding: 10

    },
    searchIcon: {
        fontSize: 30
    },
    
    widgetHeaderIconColor: {
        color: customColorPalette.black[36]
    },
    sidebarWrapper: {
        [theme.breakpoints.up('lg')]: {
            float: "right",
            minHeight: "100%",
            height:"100% !important",
            boxShadow: "inset 2px 0px 0px -1px #b4bcc4",
        },
    },
    sidebar: {
        [theme.breakpoints.up('lg')]: {
            float: "right",
            width: "305px",
            height: "100vh",
        },
        //[theme.breakpoints.only('lg')]: {
        //   /* float: "right",*/
        //    width: "100%",
        //    height: "100vh",
        //},
        [theme.breakpoints.down('lg')]: {
          /*  float: "right",*/
            width: "100%",
            height: "100vh",
        },
        //[theme.breakpoints.down('lg')]: {
        //    float: "none",
        //    width: "auto",
        //    paddingBottom: "20px",
        //    borderTop: "1px solid rgba(75, 96, 116, .42)",
        //},
    },
    
    
    widgets: {
        ...listStyle
    },
    widget: {
        position: "relative",
        borderBottom: "1px solid rgba(75, 96, 116, .22)",
        [theme.breakpoints.down('lg')]: {
            borderRight: "1px solid rgba(75, 96, 116, .22)",
            borderTop: "1px solid rgba(75, 96, 116, .22)",
        },
        [theme.breakpoints.down('sm')]: {
            borderRight: "1px solid rgba(75, 96, 116, .22)",
            borderRight: "0px",
           
        },
       

    },
    widgetHead: {
        padding: "30px 26px 0px",
    },
    widgetBody: {
        padding: "20px 26px",
       
    },
    listLinks: {
        ...listStyle,
        marginLeft: "32px",

    },
    listItemLinks: {
        ...listStyle,
        marginBottom: "16px",
        

    },
    blockquote:
        {
        background: "#f9f9f9",
    borderLeft: "10px solid #ccc",
    margin: "1.5em 10px",
    padding: "0.5em 10px",
        quotes: '"“" "”" "‘" "’"',
        ...customThemeStyles.subtitle2,
        '&::before': {
            color:"#ccc",
            content: 'open-quote',
            fontSize: "4em",
            lineHeight: "0.1em",
            marginRight: "0.25em",
            verticalAlign: "-0.4em",
        }
    },
    widgetHeadContent: {
        ...theme.subtitle1,
        fontWeight:"500 !important",
    },
    blockQuoteContent: {
        ...theme.subtitle2,
        lineHeight: "1.7",
        fontSize:"15px !important",
    },
    weatherHead: {
        overflow: "hidden",
        color: "#5F6871",
        paddingLeft: "28%",
        position: "relative",
        paddingBottom: "10px",
    },
    weatherIcon: {
        position: "absolute",
        right: "75%",
        top: "0px",
        fontSize: "70px",
        lineHeight: "1"
    },
    weatherDegrees: {
        fontSize: "20px !important",
        fontWeight: "300",
        lineHeight: "1",
        padding: "15px 0 0",
        margin: 0,
    },
    weatherBody: {
        textAlign: "center",
        padding: "10px 0",
    },
});