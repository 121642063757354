import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ActionBackdrop from 'admin/components/section/settings/Backdrop';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getNewUser } from 'admin/actions/userActions';
class UserListHeader extends React.Component {

	onClickNewUser = () => {
		this.props.dispatch( getNewUser() );
		this.props.history.push( `/Admin/Users/new` );
	}

    render() {
        return (
            <Grid container direction="row" justifyContent="space-between">
                {this.props.isDataLoading && <ActionBackdrop />}
					<Grid item>
                        <Typography variant="h4">Users</Typography>
					</Grid>
					<Grid item>
                            <Button
								variant="contained" 
								color="primary" 
								disableElevation 
								startIcon={<AddIcon />}
								onClick={this.onClickNewUser}
								>ADD USER</Button>
					</Grid>
				</Grid>
        );
    }

}

/**
 * mapStateToProps
 * @param {*} state 
 */
function mapStateToProps( state ) {
    return {
        isDataLoading: state.users.isLoading,
	};
};

export default withRouter(connect(mapStateToProps)(UserListHeader));
