import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { styled } from '@mui/styles';
import { withStyles } from '@mui/styles';
import { spacing } from '@mui/system';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MUIDataTable from 'mui-datatables';
import MuiCard from '@mui/material/Card';
import Box from '@mui/material/Box';
import { setupTheme } from 'admin/components/section/dataViews/setup/theme/setupTheme';
import { setQueryResultPanel } from 'admin/actions/dataViewActions';
const Card = styled(MuiCard)(spacing);


class QueryResult extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            options: {
                download: false,
                elevation: 0,
                jumpToPage: false,
                viewColumns: false,
                filter: false,
                search: false,
                sorting: true,
                print: false,
                pagination: false,
                selectableRows: 'none',
            },
            showBusy: false,
        };
    }
    getColumns = () => {
        return this.props.queryFields.map((queryField, i) => {
            return {
                name: queryField.fieldName,
                label: queryField.fieldName,
            }

        });


    }
    handlePanelChange = (panel) => (event, newExpanded) => {
        this.props.dispatch(setQueryResultPanel(newExpanded));
    }
    renderQueryResults = (classes) => {

        const columns = this.getColumns();
        
        if (this.props.querySettings.queryStatus && this.props.activeTabIndex === 0 && this.props.querySettings.showResult) {
            return (
                <Box pl={3} pr={3} mt={-4}>
                <Grid container direction="row" justifyContent="space-between" >
                    <Grid item xs={12} >
                <Card className={classes.queryCard}> <Grid container direction="row" justifyContent="space-between">
                <Grid item xs={12}>
                      <Accordion expanded={this.props.querySettings.showResult} onChange={this.handlePanelChange('panel1')}>
                        <AccordionSummary classes={{ content: classes.querySummary, root: classes.querySummary }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>Results</Typography>
                        </AccordionSummary>
                        <AccordionDetails classes={{ root: classes.queryDetails }}>

                            {!this.props.querySettings.hasError &&
                                <MUIDataTable className={classes.MUIDataTable}
                                title={''}
                                data={this.props.querySettings.queryData}
                                columns={columns}
                                options={this.state.options}
                            />
                            }
                            {this.props.querySettings.hasError &&
                                <Typography >{this.props.querySettings.message}</Typography>
                            }


                        </AccordionDetails>
                    </Accordion>
                </Grid>

                        </Grid></Card></Grid></Grid></Box>);
        }
        else {
            return (<div></div>);
        }
    }

    render() {
        const { classes } = this.props;
        return this.renderQueryResults(classes);
    }
}
function mapStateToProps(state) {
    return {

        querySettings: state.dataViews.querySettings,
        queryFields: state.dataViews.queryFields,
        activeTabIndex: state.dataViews.activeTabIndex,
        isLoading: state.loader.isLoading,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage,
    };
};

export default withRouter(connect(mapStateToProps)(withStyles(setupTheme)(QueryResult)));