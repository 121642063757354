const requestNews = 'REQUEST_NEWS';
const receiveNews = 'RECEIVE_NEWS';
const receiveLandingPage = 'RECEIVE_LANGING_PAGE';
const initialState = { news: [], isLoading: false, landingSettings: { linkStatus: false }, actionSource:"Initial" };
export const reducer = (state, action) => {

    state = state || initialState;

    if (action.type === requestNews) {
        return {
            ...initialState,
            isLoading: true
        };
    }
    else if (action.type === receiveNews) {
        return {
            ...state,
            isLoading: false,
            news: action.news,
            landingSettings: {
                landingObjectType: action.landingObjectType, objectId: action.objectId, linkStatus: true
            },
            actionSource: action.actionSource

        };
    }
    else if (action.type === receiveLandingPage) {
        return {
            ...state,
            landingSettings: { linkStatus: false }

        };

    }
   
    return state;
}