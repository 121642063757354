import React from 'react';
import ValidEmail from './ValidEmail';
import CreateNewPassword from './CreateNewPassword';
import { Box } from '@mui/material';
import  LayoutBasicHeader  from '../Common/LayoutBasicHeader';

class resetPassword extends React.Component {

    render() {
        let search = window.location.search;
        let parameters = new URLSearchParams(search);
        let key = parameters.get('p'); 
        return (
            <Box pt={0} sx={{ minWidth: "300px" }}>

                <LayoutBasicHeader title="Password Reset"/>
                {key === null ? <ValidEmail /> : <CreateNewPassword uniqueId={key} />}
            </Box>
        );
    }
}

export default resetPassword;