import React from 'react';
import { withStyles } from '@mui/styles';
import Password from 'client/components/UserProfile/Password';
import PasswordHelp from './PasswordHelp';
import Button from '@mui/material/Button';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Box, Grid, Typography, CardContent } from '@mui/material';
import { resetPasswordTheme } from './theme/ResetPasswordTheme';
import { ServerErrorCode } from 'client/components/Common/Enum';
import { passwordStyles } from 'client/components/UserProfile/theme/PasswordStyles';

const misMatchError = 'The passwords you entered did not match'
const passwordRequired = 'Password is required'
const passwordRuleRequired = 'The password rules have not been met';

class createNewPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newPassword: '',
            confirmPassword: '',
            heading: 'Enter and confirm a new password for your account.',
            buttonStatus: true,
            showHelper:false,
            newPasswordStatus: true,
            confirmPasswordStatus: true,
            passwordValidated: false,
            passwordHelper: [],
            passwordError: false,
            confirmPasswordError: false,
            passwordValidateStatus: false,
            passwordHelperText: '',
            confirmPasswordHelperText: '',
            havePrimaryPasswordFocus: false,
            checkPasswordError: false,
        }
    }

    componentDidMount() {
       this.isValidUser();
    }
   
    render() {
        const { classes } = this.props;
        return (
            <Box pt={0}>
                    <Grid container direction="column" alignItems="center">
                    <Grid item xs={12}  >
                            <Box pt={7}>
                                <Typography align="center" variant="h6" >{this.state.heading}</Typography>
                            </Box>
                        </Grid>
                    <Grid item xs={12} >
                            <Box pt={3} className={classes.textPassword}>
                            {this.state.newPasswordStatus && <Password
                                    id="newPassword"
                                    name="newPassword"
                                    height="44px"
                                    label="New Password"
                                    handlePasswordChange={this.handlePasswordChange}
                                    holderName="Password"
                                    error={this.state.passwordHelperText.length === 0 ? false : true}
                                    helperText={this.state.passwordHelperText}
                                    handlePasswordBlur={this.handlePasswordBlur}
                                    handlePasswordFocus={this.handlePasswordFocus}
                                    tabIndex={1}
                                    value={this.state.newPassword}
                                    validateHelper={true}
                                    autoFocus={true}
                                />}
                            </Box>
                        </Grid>
                    <Grid item xs={12} >
                            <Box pt={5} className={classes.textPassword}>
                            {this.state.confirmPasswordStatus &&<Password
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    height="44px"
                                    label="Confirm Password"
                                    handlePasswordChange={this.handleConfirmPasswordChange}
                                    holderName="Re-enter Password"
                                    error={this.state.confirmPasswordError}
                                    helperText={this.state.confirmPasswordHelperText}
                                    handlePasswordBlur={this.handleConfirmPasswordBlur}
                                    handlePasswordFocus={this.handleConfirmPasswordFocus}
                                    tabIndex={2}
                                    value={this.state.confirmPassword}
                                    value2={this.state.newPassword}
                                    validateHelper={false}
                                    autoFocus={false}
                                />}
                            </Box>
                        </Grid>
                    <Grid item xs={12}>
                        {this.state.buttonStatus && <Box pt={8} >
                            <Button type="submit" id="btnSetNewPwd" name="btnSetNewPwd"
                                        tabIndex={3} onClick={this.handleNewPassword}
                                        variant="contained"
                                        className={classes.resetButton + ' ' + classes.resetButton_Client}
                                        color="primary"
                                        disableElevation
                                        startIcon={<LockOutlinedIcon />}
                                >
                                    SET PASSWORD
                                </Button> 
                                <Button type="submit" id="btnCancelEmail"
                                    tabIndex={4} onClick={this.handleClear}
                                    className={classes.cancelButton}
                                    variant="contained"
                                    disableElevation
                            >
                                    CLEAR
                            </Button>
                            </Box>}
                        </Grid>
                    <Grid item xs={12} >
                        {this.state.buttonStatus && <Box pt={4} className={ classes.passwordHelp}>
                            <PasswordHelp showHelper={this.state.showHelper} primaryFocus={this.state.havePrimaryPasswordFocus} passwordHelper={this.state.passwordHelper} ></PasswordHelp> 
                            </Box>}
                        </Grid>
                    </Grid>
                </Box>
        );
    }

    handleNewPassword = async(e) => {
        e.preventDefault();
        if (this.isPasswordValidated() && this.isPasswordMatch()) {
            this.resetPassword();
        }
    }

    handleClear = e => {
        e.preventDefault();
        this.setState({
            newPassword: '',
            confirmPassword: '',
            passwordError: false,
            confirmPasswordError: false,
            passwordHelperText: '',
            confirmPasswordHelperText: '',
            havePrimaryPasswordFocus: true
        });
        this.state.passwordHelper.map((helper, index) => (
            helper.Status = false
        ));
    }

    handlePasswordChange = (password, passwordHelper, validatedStatus) => {
        this.setState({
            passwordHelper: passwordHelper,
            passwordValidateStatus: validatedStatus,
            passwordError: false,
            newPassword: password
        })
    }

    handlePasswordBlur = (event, passwordError) => {
        let errorText=''
        if (!this.state.newPassword) {
            errorText = passwordRequired;
        }
        else if (passwordError) {
            errorText = passwordRuleRequired;
        }
       
        this.setState({
            passwordHelperText: errorText,
            checkPasswordError: passwordError,
            confirmPasswordHelperText: this.confirmPasswordValidationLogic(this.state.confirmPassword).helperText,
            confirmPasswordError: this.confirmPasswordValidationLogic(this.state.confirmPassword).error
        })
    }
    handlePasswordFocus = (event, passwordError, passwordHelper) => {
        this.setState({
            passwordHelperText: '',
            passwordHelper: passwordHelper,
            showHelper: true,
            havePrimaryPasswordFocus: true
        })
    } 

    handleConfirmPasswordChange = (password, passwordHelper, validatedStatus) => {
        this.setState({
            confirmPassword: password,
            confirmPasswordHelperText: this.confirmPasswordValidationLogic(password).helperText,
            confirmPasswordError: this.confirmPasswordValidationLogic(password).error
        })
    }

    handleConfirmPasswordBlur = (event, passwordError) => {
        this.setState({
            confirmPasswordHelperText: this.confirmPasswordValidationLogic(this.state.confirmPassword).helperText,
            confirmPasswordError: this.confirmPasswordValidationLogic(this.state.confirmPassword).error
        })
    }
    handleConfirmPasswordFocus = (event, passwordError) => {
        this.setState({ confirmPasswordHelperText: '', confirmPasswordError: false, havePrimaryPasswordFocus: false})
    }

    handleBackToHomePage = async (e) => {
        e.preventDefault();
        window.location.href = "/";
    }

    isPasswordMatch = () => {
        if (this.state.confirmPassword !== this.state.newPassword) {
            this.setState({
                confirmPasswordHelperText: misMatchError,
                confirmPasswordError: true
            })
            return false;
        } 
        return true;
    }

    isPasswordValidated = () => {
        if (!this.state.newPassword) {
            this.setState({
                passwordHelperText: passwordRequired,
            });
            return false;
        } else if (this.state.checkPasswordError) {
            this.setState({
                passwordHelperText: passwordRuleRequired,
            });
            return false;
        }
        return true;
    } 

    confirmPasswordValidationLogic = (confirmPassword) => {
        var result = { error: false, helperText: "" }
        if (confirmPassword !== this.state.newPassword) {
            result.error = true;
            result.helperText = misMatchError;

        } else if (confirmPassword) {
            result.error = false;
            result.helperText = 'Match';
        }

        return result
    }

    resetPassword = async () => {

        const url = `api/ResetPassword/ResetPassword`;
        const response = await window.fetch(url,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    NewPassword: this.state.newPassword,
                    UniqueId: this.props.uniqueId
                })
            });
        const message = await response.json();
        if (response.status === ServerErrorCode.SuccessStatus) {

            this.setState({
                heading: message,
                buttonStatus: false,
                passwordHelper: [],
                newPasswordStatus: false,
                confirmPasswordStatus: false,
                passwordValidated: false, 
                passwordError: false,
            });
        } else if (response.status === ServerErrorCode.ServerErrorStatus) {
            this.setState({
                heading: 'Password resetting failed.',
                buttonStatus: false,
                passwordHelper: [],
                newPasswordStatus: false,
                confirmPasswordStatus: false
            });
        } else {
            this.setState({
                heading: message,
                buttonStatus: false,
                passwordHelper: [],
                newPasswordStatus: false,
                confirmPasswordStatus: false
            });
        }
    }

    isValidUser = async () => {

        const url = `api/ResetPassword/IsValidUser`;
        const response = await window.fetch(url,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    UniqueId: this.props.uniqueId
                })
            });
        if (response.status !== ServerErrorCode.SuccessStatus) {
            const message = await response.json();
            this.setState({
                heading: message,
                buttonStatus: false,
                passwordHelper: [],
                newPasswordStatus: false,
                confirmPasswordStatus: false
            });
        }
    }
}

export default withStyles(resetPasswordTheme)(createNewPassword);
