
export const imageCropTheme = () => ({
    cropApp: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },

    cropContainer: {
        position: "absolute",
        top: "4%",
        left: "4%",
        right: 10,
        bottom: 0,
        width: "93%",
        height: "56%",
    },

    cropControls: {
        position: "relative",
        bottom: 0,
        top: "67.6%",
        left: "3%",
        width: "94%",
        height: 80,
        backgroundColor: "white",
        border: "1px thin grey",
        marginTop: -30,
    },

    cropControlSlider: {
        paddingLeft: 4,
        paddingRight: 1,
    },

    previewBox: {
        position: "absolute",
        top: "20px",
        right: "0px",
        left: "70%",
    },

    prevImage: {
        width: "99%",
        borderRadius: "50%",
        minHeight: "140px",
    },

    "@media (min-width: 375px) and (max-width:767px)": {
        prevImage: {
            minHeight: "95px",
            marginTop: 25,
        },
    },

    "@media (min-width: 768px) and (max-width: 1023px)": {
        prevImage: {
            minHeight: "130px",
            marginTop: 25,
        },
    },

    fileUpload: {
        display: "none",
    },

    slider: {
        container: 'slider',
    },

});