import { customThemeStyles } from 'common/theme.js';

export const subRecordsTheme = (theme) => ({
    ...customThemeStyles,
    card: {
        height: "825px!important",
        marginLeft: theme.spacing(-3) + '!important',
        marginRight: theme.spacing(-3) + '!important',
        marginTop: theme.spacing(-3.1) + '!important',
        padding: '0px!important'
    },
    
    customRender: {
        marginTop: "14px !important",
        marginBottom: "-9px !important",
    },
    customRenderDelete: {
        paddingTop: "11px !important",
    },
    clearButton: {
        maxWidth: '130px!important',
        maxHeight: '32px!important'
    },
});