import React from 'react';
import { styled } from '@mui/styles';
import { spacing } from '@mui/system';
import MuiTextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import ErrorIcon from '@mui/icons-material/Error';
import { InputAdornment } from '@mui/material';
const TextField = styled(MuiTextField)(spacing);
class RoleNameText extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            roleName: this.props.roleName,
            error: false,
            helperText:' '
        }
    }
    componentDidMount() {
        console.log(" RoleNameText componentDidMount ", this.props.roleName)
      
    }
    componentDidUpdate(prevProps, prevState) {
      //  console.log(" RoleNameText componentDidUpdate ", this.props.roleName)
        if (this.props.roleName !== prevProps.roleName) {
            this.setState({ roleName: this.props.roleName, error:false, helperText:' '})
        }
        if (this.props.error !== prevProps.error) {
            this.setState({ roleName: this.props.roleName, error: this.props.error, helperText: this.props.helperText })
        }
    }
    handleChange = (value) => {
        if (this.checkIsEmpty(value)) {
            this.setState({ roleName: value, error: true, helperText: 'Role Name required'}, () => {
                this.props.setRoleNameValue(value);
            }) 
        } else {
            this.setState({ roleName: value,error:false,helperText:' ' }, () => {
                this.props.setRoleNameValue(value);
            }) 
        }
       
    }
    checkIsEmpty = (value) => {
        return (!value || value.length === 0 || !value.trim());
    }
    render() {
        return (
            <TextField error={this.state.error} helperText={this.state.helperText} autoFocus

                required
                label="Role Name"
                size="medium"
                value={this.state.roleName}
                fullWidth={true}
                onChange={(e) => { this.handleChange(e.target.value) }}
                onBlur={(e) => this.handleChange(e.target.value)}
                inputProps={{ maxLength: 50 }} 
            >
            </TextField>
        );
    }

}

export default RoleNameText;