import { customThemeStyles } from 'common/theme.js';
import grey from '@mui/material/colors/grey';
export const databaseTheme = (theme) => ({
    ...customThemeStyles,
    databaseListBox: {
        borderColor: grey[300]+' !important',
        backgroundColor:"white !important",
    },

    addButton:{

        maxWidth: '185px!important',
        maxHeight: '36px!important'
    },

    MUIDataTablePadding: {
        paddingTop: '10px!important',
       
    },
    serverTypeOptions: {
        paddingTop:'15px!important',
    },
    timeZoneOptions: {
        paddingTop: '19px!important',
    },
    root: {
        justifyContent: 'center!important',
        flexWrap: 'wrap!important',
        height: "93px!important",
        borderRadius: "4px!important"
    },
    card: {
        //width: "724px",
        height: "100%!important",
        marginLeft: theme.spacing(2) +'!important',
        marginRight: theme.spacing(2) + '!important'
    },
    chip: {
        margin: theme.spacing(0.5) + '!important'
    },
    gridColumn: { wordBreak: "break-all" },
    nameColumn: { width: "230px", wordBreak: "break-all" },
    descriptionColumn: { width: "260px", wordBreak: "break-all" },
    serverColumn: { width: "300px", wordBreak: "break-all" },
    deleteColumn: { width: "60px!important" },
    notesItem: {
        paddingTop:'0px!important'
    },
    serverInfoItem: {
        paddingTop:"26x!important"
       // paddingLeft: '0px!important'
    },
    connectionName: {
        //paddingTop: "10px!important"
    },
    serverInfoTitle: {
        paddingTop: "48px!important"
    },
    serverName: {
        width: "360px!important",
       // paddingTop: "26px!important"
    }
});