import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { styled } from '@mui/styles';
import { withStyles } from '@mui/styles';
import { spacing } from '@mui/system';
import MUIDataTable from 'mui-datatables';
import { Checkbox, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Select, MenuItem, Typography, Box } from '@mui/material'
import { theme } from 'common/theme.js';
import MuiTextField from '@mui/material/TextField';
import Delete from '@mui/icons-material/DeleteOutline';
import EditOutLine from '@mui/icons-material/EditOutlined';
import { rowUpdateFilter, getAdminFilters } from 'admin/actions/dataViewActions';
import MuiCard from '@mui/material/Card';
import AlertMessage from 'admin/components/section/dataViews/dataFilters/AlertMessage';
import * as enumerator from 'client/components/Common/Enum';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import cloneDeep from 'lodash/cloneDeep';
import {
    DragDropContext,
    Droppable,
    Draggable,
    DraggableProvided,
    DroppableProvided,
    DraggableStateSnapshot
} from "react-beautiful-dnd";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Grid
} from "@mui/material";
import ReorderIcon from "@mui/icons-material/Reorder";
import { filterTheme } from 'admin/components/section/dataViews/dataFilters/theme/filterTheme';
import * as Enums from 'client/components/Common/Enum';
import { sortName } from 'client/components/Common/Utility';
const TextField = styled(MuiTextField)(spacing);
const Card = styled(MuiCard)(spacing);
const filterTypeArray = [
    { id: 'userAppliedFilter', value: 'User-Applied Filter', },
    { id: 'userProfileFilter', value: 'User Account Filter', },
];
const filterType = { UserAppliedFilter: 'userAppliedFilter', UserProfileFilter: 'userProfileFilter', }
const controlTypeData = { Text: 'text', DropDown: 'dropDown', Numeric: 'numeric', DatePicker: 'datePicker', CheckBox: 'checkBox' }
const controlType = [
    { id: 'text', value: 'Text', },
    { id: 'numeric', value: 'Numeric', },
    { id: 'dropDown', value: 'DropDown', },
    { id: 'datePicker', value: 'DatePicker', },
    { id: 'checkBox', value: 'CheckBox', },
];
const valuesSourceData = { 'Dynamic': 'dynamic', 'Static': 'static' }
const valuesSource = [
    { id: 'dynamic', value: 'Dynamic', },
    { id: 'static', value: 'Static', },
];
const errorMessage = 'Value is required'
const errorLabelMessage = 'Label is required'
const defaultColumnOptions = {
    filter: true,
    sort: true,
    justifyContent: "left",

    // style a compact grid https://github.com/gregnb/mui-datatables/issues/714
    //setCellProps: (cellValue, rowIndex, columnIndex) => {
    //    return {
    //        style: {
    //            paddingLeft: theme.spacing(0),
    //            paddingBottom: theme.spacing(.1),
    //            paddingTop: theme.spacing(4.2),
    //            paddingRight: theme.spacing(0),
    //           },
    //    };
    //},

    setCellHeaderProps: (cellValue, rowIndex, columnIndex) => {
        return {
            style: {
                paddingLeft: theme.spacing(0),
                paddingTop: theme.spacing(0),
                paddingBottom: theme.spacing(1.8),
                paddingRight: theme.spacing(0),
            },
        };
    },
};
const regularField = 1;
let rowIndex = "";
let tableData = [];
class DataFilterList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filterDetailsOptions: {
                download: false,
                elevation: 0,
                jumpToPage: false,
                viewColumns: false,
                filter: false,
                search: false,
                sorting: true,
                print: false,
                pagination: false,
                selectableRows: 'none',
                responsive: 'scrollMaxHeight',
                tableBodyMaxHeight: '310px',
                tableBodyHeight: '310px',
            },
            openFilterDetails: false,
            filterRowIndex: -1,
            showMessage: false,
            filterDetailsData: []

        };
    }

    componentDidMount() {
        this.dispatchGetAdminFilters();
    }

    dispatchGetAdminFilters() {
        this.props.dispatch(getAdminFilters());

    }

    getDataFilterData(reportId) {
        this.props.history.push(`/Admin/DataFilters/${reportId}`);
    }

    resetIndex = (filter, displayOrder) => {

        for (var i = 0; i < filter.length; i++) {
            let item = Object.assign({}, filter[i]);

            if (item.controlVisibleOrder > displayOrder) {
                item.controlVisibleOrder = item.controlVisibleOrder - 1
                filter[i] = item;
            }
        }
        return filter;
    }
    checkIsEmpty(value) {
        return (!value || value.length === 0 || !value.trim());
    }
    addFilter = () => {
        let newDataFilter = {
            parameterId: 0,
            dropDownListValueType: "none",
            isMandatory: false,
            isVisible: true,
            controlType: "text",
            parameterDefaultValue: "",
            parameterFilterType: "equals",
            parameterFriendlyName: '',
            adminParameterName: '',
            parameterName: '',
            lineBreak: false,
            parameterType: this.props.dataView.dataSourceType === "storedProcedure"
                ? filterType.UserProfileFilter
                : filterType.UserAppliedFilter,
            reportFilterDetails: [],
            controlVisibleOrder: this.props.reportFilter.length + 1
        };
        let dataView = this.props.dataView;
        let dataFilter = Object.assign([], this.props.reportFilter);
        dataFilter.push(newDataFilter);
        dataView = { ...this.props.dataView, reportFilter: dataFilter }
        return this.handleChange(dataView)
    }

    deleteFilter = (event) => {
        let dataView = this.props.dataView;
        let dataFilter = Object.assign([], this.props.reportFilter);
        const index = event.currentTarget.id.replace("delete", "").trim();
        const displayOrder = dataFilter[index].controlVisibleOrder

        dataFilter.splice(index, 1)

        this.resetIndex(dataFilter, displayOrder);
        let filterData = {
            parameterId: 0,
            dropDownListValueType: "none",
            isMandatory: false,
            isVisible: true,
            controlType: "text",
            parameterDefaultValue: "",
            parameterFilterType: "equals",
            parameterFriendlyName: '',
            adminParameterName: '',
            parameterName: '',
            lineBreak: false,
            parameterType: this.props.dataView.dataSourceType === "storedProcedure"
                ? filterType.UserProfileFilter
                : filterType.UserAppliedFilter,
            reportFilterDetails: [],
            controlVisibleOrder: 1
        };
        if (dataFilter.length === 0) {
            dataFilter.push(filterData)
        } else {
            if (dataFilter[dataFilter.length - 1].parameterName) {
                filterData.controlVisibleOrder = dataFilter.length + 1
                dataFilter.push(filterData)
            }

        }

        dataView = { ...this.props.dataView, reportFilter: dataFilter }
        this.props.dispatch(rowUpdateFilter(this.props.dataView, dataView));
    }

    deleteRowFilterTemplate = (value, rowIndex, updateValue) => {
        return (
            <IconButton id={"delete" + rowIndex} type="button" onClick={this.deleteFilter} disabled={(
                (this.props.dataView.dataSourceType === enumerator.ReportDataSourceType.StoredProcedure || this.props.dataView.dataSourceType === enumerator.ReportDataSourceType.CrystalReport) &&
                this.props.reportFilter[rowIndex].parameterType === filterType.UserAppliedFilter)
            }  ><Delete /></IconButton>
        );
    }

    addFilterDetails = (filterRowIndex) => {
        //const filterRowIndex = this.state.filterRowIndex;
        let newFilterDetails = {
            valueId: 0,
            valueDisplayId: '',
            valueDescription: '',
            parameterName: ''

        };
        let dataView = this.props.dataView;
        let reportFilter = this.props.reportFilter;
        let newFilters = [...reportFilter];
        let filterDetails = Object.assign([], this.props.reportFilter[filterRowIndex].reportFilterDetails);
        filterDetails.push(newFilterDetails);
        newFilters[filterRowIndex] = { ...reportFilter[filterRowIndex], reportFilterDetails: filterDetails }
        dataView = { ...this.props.dataView, reportFilter: newFilters }
        return this.handleChange(dataView)
    }

    deleteFilterDetails = (event) => {
        const filterRowIndex = this.state.filterRowIndex;
        const filterDetails = cloneDeep(this.state.filterDetailsData);
        filterDetails.splice(event.currentTarget.id.replace("deleteDetails", "").trim(), 1)
        const newFilterData = {
            valueId: 0,
            valueDisplayId: '',
            valueDescription: '',
            parameterName: this.props.reportFilter[filterRowIndex].parameterName,
            valueDisplayIdError: false,
            valueDisplayIdHelperText: ' ',
            valueDescriptionError: false,
            valueDescriptionHelperText: ' '

        };

        if (filterDetails.length === 0) {
            filterDetails.push(newFilterData)
        } else {
            if (filterDetails.length !== 1 && this.checkIsEmpty(filterDetails[filterDetails.length - 1].valueDisplayId) && this.checkIsEmpty(filterDetails[filterDetails.length - 1].valueDescription)) {
                filterDetails[filterDetails.length - 1].valueDisplayIdError = false
                filterDetails[filterDetails.length - 1].valueDisplayIdHelperText = ' '
                filterDetails[filterDetails.length - 1].valueDescriptionError = false
                filterDetails[filterDetails.length - 1].valueDescriptionHelperText = ' '
            }
            this.addFieldDetailsData(filterDetails)

        }
        this.setState({ filterDetailsData: filterDetails })
    }

    deleteRowFilterDetailsTemplate = (value, tableMeta, updateValue) => {
        return (
            <IconButton id={"deleteDetails" + tableMeta.rowIndex} type="button" onClick={this.deleteFilterDetails
            }><Delete /></IconButton>
        );
    }

    handleClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        this.setState({ openFilterDetails: false, filterRowIndex: -1 })

    };

    setControlType = (rowIndex) => {

        let Types = [];

        const fieldControlType = this.getControlType(this.props.reportFilter[rowIndex].parameterName)

        if (fieldControlType === controlTypeData.Text) {
            Types.push({ id: 'text', value: 'Text' }, { id: 'dropDown', value: 'DropDown' })
        } else if (fieldControlType === controlTypeData.Numeric) {
            Types.push({ id: 'numeric', value: 'Numeric' }, { id: 'dropDown', value: 'DropDown' })

        }

        else if (fieldControlType === controlTypeData.DatePicker) {
            Types.push({ id: 'datePicker', value: 'DatePicker' }, { id: 'dropDown', value: 'DropDown' })

        }

        else if (fieldControlType === controlTypeData.CheckBox) {
            Types.push({ id: 'checkBox', value: 'CheckBox' })

        }
        else if (fieldControlType === controlTypeData.DropDown) {
            Types.push({ id: 'text', value: 'Text' }, { id: 'dropDown', value: 'DropDown' })

        }
        else {
            Types = controlType
        }

        return this.createMenuItem(Types);
    }

    validateFilterDetails = () => {

        const filterDetails = this.state.filterDetailsData
        let status = true;
        const index = 0;
        if (filterDetails.length === index + 1) {
            const item = Object.assign({}, filterDetails[index]);
            if (this.checkIsEmpty(item.valueDisplayId)) {

                item.valueDisplayIdError = true;
                item.valueDisplayIdHelperText = errorMessage;
                status = false;

            }

            if (this.checkIsEmpty(item.valueDescription)) {

                item.valueDescriptionError = true;
                item.valueDescriptionHelperText = errorLabelMessage;
                status = false;


            }
            filterDetails[index] = item;
        } else {
            for (var i = 0; i < filterDetails.length; i++) {
                const item = Object.assign({}, filterDetails[i]);

                if (i !== filterDetails.length - 1) {
                    if (this.checkIsEmpty(item.valueDisplayId)) {

                        item.valueDisplayIdError = true;
                        item.valueDisplayIdHelperText = errorMessage;
                        status = false;

                    }

                    if (this.checkIsEmpty(item.valueDescription)) {

                        item.valueDescriptionError = true;
                        item.valueDescriptionHelperText = errorLabelMessage;
                        status = false;


                    }
                } else {
                    if (i === filterDetails.length - 1) {
                        if (this.checkIsEmpty(item.valueDisplayId) && !this.checkIsEmpty(item.valueDescription)) {
                            item.valueDisplayIdError = true;
                            item.valueDisplayIdHelperText = errorMessage;
                            status = false;

                        }
                        if (!this.checkIsEmpty(item.valueDisplayId) && this.checkIsEmpty(item.valueDescription)) {
                            item.valueDescriptionError = true;
                            item.valueDescriptionHelperText = errorLabelMessage;
                            status = false;

                        }
                    }

                }

                if (this.checkIsEmpty(item.valueDisplayId) && this.checkIsEmpty(item.valueDescription) && i !== filterDetails.length - 1) {

                    item.valueDisplayIdError = true;
                    item.valueDisplayIdHelperText = errorMessage;
                    item.valueDescriptionError = true;
                    item.valueDescriptionHelperText = errorLabelMessage
                    status = false;


                }


                filterDetails[i] = item;

            }
        }




        if (!status) {

            this.setState({ showMessage: true, filterDetailsData: filterDetails })
        }

        return status;
    }

    onMessageClose = () => {
        this.setState({ showMessage: false })
    }

    handleSave = (event) => {
        if (this.validateFilterDetails()) {
            this.setState({ openFilterDetails: false, filterRowIndex: -1 })
            let dataView = this.props.dataView;
            let reportFilter = this.props.reportFilter;
            let newDataFilters = [...reportFilter];
            newDataFilters[this.state.filterRowIndex] = { ...reportFilter[this.state.filterRowIndex], reportFilterDetails: this.state.filterDetailsData }
            dataView = { ...this.props.dataView, reportFilter: newDataFilters }
            return this.handleChange(dataView)

        }
    };

    handleChange = (data) => {
        this.props.dispatch(rowUpdateFilter(this.props.dataView, data));


    };

    createMenuItem = (options) => {
        return (
            options.sort((a, b) => sortName(a.value, b.value)).map((option) => (
                <MenuItem key={option.id} value={option.id}>
                    {option.value}
                </MenuItem>
            ))
        );
    }

    handleDragEnd = (result, provided) => {
        if (!result.destination) {
            return;
        }
        if (result.destination.index === result.source.index) {
            return;
        }

        this.setLocalItems(result)
    }

    setLocalItems = (result) => {
       
        let updateData = cloneDeep(this.props.reportFilter);
        const selectedItem = updateData[result.source.index]
        updateData.splice(result.source.index, 1)
        let modifiedArray = [];
        let index = 0;

        if (result.destination.index === updateData.length) {
            selectedItem.controlVisibleOrder = result.destination.index+1
            updateData.push(selectedItem)
            modifiedArray = updateData

        } else {

            for (let i = 0; i < updateData.length; i++) {

                if (index === result.destination.index) {
                    selectedItem.controlVisibleOrder = result.destination.index + 1
                    modifiedArray.push(selectedItem)
                    updateData[i].controlVisibleOrder = result.destination.index + 2
                    modifiedArray.push(updateData[i])
                    index++;
                } else {

                    updateData[i].controlVisibleOrder = index+1
                    modifiedArray.push(updateData[i])

                }


                index++;
            }
        }


        tableData = modifiedArray;
        let dataView = { ...this.props.dataView, reportFilter: modifiedArray }
        this.props.dispatch(rowUpdateFilter(this.props.dataView, dataView));

        return "";
    }

    getOperator = (fieldName) => {
        const paramFieldName = fieldName ? fieldName.toUpperCase() : fieldName;
        let index = this.props.dataView.fieldDisplay.findIndex(c => c.fieldName && c.fieldName.toUpperCase() === paramFieldName);
        let dataType = '';
        if (index !== -1) {

            dataType = this.props.dataView.fieldDisplay[index].fieldType;
        }

        const options = [];
        options.push(<MenuItem key={0} value={enumerator.ReportFilter.Equals}>Equals</MenuItem>);
        options.push(<MenuItem key={1} value={enumerator.ReportFilter.NotEqual}>Not Equals</MenuItem>);
        switch (dataType) {
            case "int":
            case "smallint":
            case "decimal":
            case "float":
            case "money":
            case "numeric":
            case "real":
            case "integer":
            case "uniqueIdentifier":
                {
                    options.push(<MenuItem key={2} value={enumerator.ReportFilter.GreaterThan}>Greater than</MenuItem>);
                    options.push(
                        <MenuItem key={3} value={enumerator.ReportFilter
                            .GreaterThanOrEqualTo}>Greater than or equal</MenuItem>);
                    options.push(<MenuItem key={4} value={enumerator.ReportFilter.LessThan}>Less Than</MenuItem>);
                    options.push(
                        <MenuItem key={5} value={enumerator.ReportFilter.LessThanOrEqualTo}>Less than or equal</MenuItem>);
                    options.push(<MenuItem key={6} value={enumerator.ReportFilter.Between}>Between</MenuItem>);
                    break;
                }
            case "char":
            case "nchar":
            case "nText":
            case "nvarchar":
            case "text":
            case "varchar":
            case "xml":
                {
                    options.push(<MenuItem key={2} value={enumerator.ReportFilter.Contains}>Contains</MenuItem>);
                    options.push(<MenuItem key={3} value={enumerator.ReportFilter.StartWith}>Start With</MenuItem>);
                    options.push(<MenuItem key={4} value={enumerator.ReportFilter.EndWith}>End With</MenuItem>);
                    break;
                }
            case "date":
            case "datetime":
            case "timestamp":
                {
                    options.push(<MenuItem key={2} value={enumerator.ReportFilter.GreaterThan}>Greater than</MenuItem>);
                    options.push(<MenuItem key={3} value={enumerator.ReportFilter.GreaterThanOrEqualTo}>Greater than or equal</MenuItem>);
                    options.push(<MenuItem key={4} value={enumerator.ReportFilter.LessThan}>Less Than</MenuItem>);
                    options.push(<MenuItem key={5} value={enumerator.ReportFilter.LessThanOrEqualTo}>Less than or equal</MenuItem>);
                    options.push(<MenuItem key={2} value={enumerator.ReportFilter.Between}>Between</MenuItem>);
                    break;
                }
            default:
                {
                    options.push(<MenuItem key={2} value={enumerator.ReportFilter.Equals}>Equals</MenuItem>);
                    options.push(<MenuItem key={3} value={enumerator.ReportFilter.NotEquals}>Not Equals</MenuItem>);
                }
        }

        return options;
    }

    getControlType = (fieldName) => {
        const paramFieldName = fieldName ? fieldName.toUpperCase() : fieldName;
        let index = this.props.dataView.fieldDisplay.findIndex(c => c.fieldName && c.fieldName.toUpperCase() === paramFieldName);
        let dataType = '';
        if (index !== -1) {

            dataType = this.props.dataView.fieldDisplay[index].fieldType;
        }

        let controlType = '';
        switch (dataType) {
            case "int":
            case "smallint":
            case "decimal":
            case "float":
            case "money":
            case "numeric":
            case "real":
            case "integer":
            case "uniqueIdentifier":
                {
                    controlType = 'numeric'
                    break;
                }
            case "char":
            case "nchar":
            case "nText":
            case "nvarchar":
            case "text":
            case "varchar":
            case "xml":
                {
                    controlType = 'text'
                    break;
                }
            case "date":
            case "datetime":
            case "timestamp":
                {
                    controlType = 'datePicker'
                    break;
                }
            case "bit":
                {
                    controlType = 'checkBox'
                    break;
                }
            default:
                {
                    controlType = 'text';
                }
        }

        return controlType;
    }

    getDataType = (fieldName) => {
        const paramFieldName = fieldName ? fieldName.toUpperCase() : fieldName;
        let index = this.props.dataView.fieldDisplay.findIndex(c => c.fieldName && c.fieldName.toUpperCase() === paramFieldName);
        let dataType = 'nvarchar';
        if (index !== -1) {

            dataType = this.props.dataView.fieldDisplay[index].fieldType;
        }

        return dataType;
    }

    filterTypeTemplate = (value, rowIndex, classes) => {
        return (
            <Select
                MenuProps={{
                    disableScrollLock: true
                }}
                id={"filterType" + rowIndex}
                value={value}
                size="medium"
                fullWidth={true}
                className={classes.filterTypeControl}
                classes={{ root: classes.filterTypeControl }}
                disabled={(this.props.dataView.dataSourceType === enumerator.ReportDataSourceType.StoredProcedure || this.props.dataView.dataSourceType === enumerator.ReportDataSourceType.CrystalReport)}
                onChange={event => {
                    let dataView = this.props.dataView;
                    let reportFilter = this.props.reportFilter;
                    let newDataFilters = [...reportFilter];
                    if (event.target.value === filterType.UserProfileFilter) {
                        newDataFilters[rowIndex] = {
                            ...reportFilter[rowIndex],
                            parameterType: event.target.value,
                            parameterName: '',
                            parameterFriendlyName: '',
                            ParameterDataType: 'nvarchar',
                            controlType: 'text',
                            parameterDefaultValue: '',
                            lineBreak: false,
                            isVisible: false,
                            isMandatory: false,
                            dropDownListValueType: 'dynamic',
                            adminParameterName: '',
                            parameterId: 0
                        }
                    } else {
                        newDataFilters[rowIndex] = {
                            ...reportFilter[rowIndex],
                            parameterType: event.target.value,
                            parameterName: '',
                            controlType: 'text',
                            parameterFriendlyName: '',
                            adminParameterName: '',
                            parameterId: 0
                        }
                    }
                    dataView = { ...this.props.dataView, reportFilter: newDataFilters }
                    return this.handleChange(dataView)
                }}
            >
                {this.createMenuItem(filterTypeArray)}
            </Select>
        );
    }

    filterNameTemplate = (value, rowIndex, classes) => {

        if ((this.props.dataView.dataSourceType === enumerator.ReportDataSourceType.StoredProcedure || this.props.dataView.dataSourceType === enumerator.ReportDataSourceType.CrystalReport) &&
            this.props.reportFilter[rowIndex].parameterType === filterType.UserAppliedFilter) {

            return (
                <TextField
                    id={"filterName" + rowIndex}
                    size="medium"
                    fullWidth={true}
                    disabled
                    value={value}
                    className={classes.fieldNameControl}
                >
                </TextField>
            );
        }
        let names = [];
        let index = -1;

        if (this.props.reportFilter[rowIndex].parameterType === filterType.UserProfileFilter) {
            names = this.props.adminFilters.map(item => {

                index = this.props.reportFilter.findIndex(p => p.parameterName === item.profileId.toString() &&
                    p.parameterType === filterType.UserProfileFilter)
                if (index === -1) {
                    return { id: item.profileId.toString(), value: item.profileName };
                } else {

                    if (index !== rowIndex) {
                        return null
                    } else {

                        return { id: item.profileId.toString(), value: item.profileName };
                    }
                }

            }).filter(Boolean);
        } else {
            if (this.props.dataView.fieldDisplay.length !== 0) {
                names = this.props.dataView.fieldDisplay.map(item => {

                    if (item.fieldCategory !== Enums.FieldCategory.RegularField && item.fieldCategory !== regularField) {
                        return null
                    }
                    index = this.props.reportFilter.findIndex(p => p.parameterName === item.fieldName &&
                        p.parameterType === filterType.UserAppliedFilter)
                    if (index === -1) {
                        return { id: item.fieldName, value: item.fieldName };
                    } else {

                        if (index !== rowIndex) {
                            return null
                        } else {

                            return { id: item.fieldName, value: item.fieldName };
                        }
                    }
                }).filter(Boolean);
            } else {
                if (this.props.queryFields.length > 0) {
                    names = this.props.queryFields.map(item => {
                        return { id: item.fieldName, value: item.fieldName };
                    });
                }

            }

            if (value) {

                const index = names.findIndex(field => field.value.toLowerCase() === value.toLowerCase())
                if (index != -1) {

                    value = names[index].value
                }

            }

        }

        // names.unshift({ id: '', value: '' });

       


        return (
            <Select
                MenuProps={{
                    disableScrollLock: true,
                }}
                id={"filterName" + rowIndex}
                value={value ? value : ""}
                size="medium"
                fullWidth={true}
                className={classes.fieldNameControl}
                onChange={event => {
                    let dataView = this.props.dataView;
                    let reportFilter = this.props.reportFilter;
                    let newDataFilters = [...reportFilter];
                    if (this.props.reportFilter[rowIndex].parameterType === filterType.UserProfileFilter) {
                        newDataFilters[rowIndex] = {
                            ...reportFilter[rowIndex],
                            parameterName: event.target.value,
                            parameterFriendlyName: '',
                            controlType: 'text',
                            dropDownListValueType: "none",
                            reportFilterDetails: [],
                        }


                    } else {

                        newDataFilters[rowIndex] = {
                            ...reportFilter[rowIndex],
                            parameterName: event.target.value,
                            parameterFriendlyName: event.target.value,
                            ParameterDataType: this.getDataType(event.target.value),
                            controlType: this.getControlType(event.target.value),
                            dropDownListValueType: "none",
                            parameterFilterType: "equals",
                            reportFilterDetails: [],
                            parameterDefaultValue: ''
                        }

                    }

                    let filterData = {
                        parameterId: 0,
                        dropDownListValueType: "none",
                        isMandatory: false,
                        isVisible: true,
                        controlType: "text",
                        parameterDefaultValue: "",
                        parameterFilterType: "equals",
                        parameterFriendlyName: '',
                        adminParameterName: '',
                        parameterName: '',
                        lineBreak: false,
                        parameterType: (this.props.dataView.dataSourceType === enumerator.ReportDataSourceType.StoredProcedure || this.props.dataView.dataSourceType === enumerator.ReportDataSourceType.CrystalReport)
                            ? filterType.UserProfileFilter
                            : filterType.UserAppliedFilter,
                        reportFilterDetails: [],
                        controlVisibleOrder: newDataFilters.length + 1
                    };

                    //Add new row 
                    if (newDataFilters.length > 1) {

                        if (newDataFilters[reportFilter.length - 1].parameterName) {
                            newDataFilters.push(filterData)
                        }

                    } else {
                        if (newDataFilters[0].parameterName) {
                            newDataFilters.push(filterData)
                        }

                    }


                    dataView = { ...this.props.dataView, reportFilter: newDataFilters }
                    return this.handleChange(dataView)
                }}
            >
                {this.createMenuItem(names)}
            </Select>
        );
    }

    controlTypeTemplate = (value, rowIndex, classes) => {
        return (
            <Select
                MenuProps={{
                    disableScrollLock: true
                }}
                id={"controlType" + rowIndex}
                disabled={this.props.reportFilter[rowIndex].parameterType === filterType.UserProfileFilter}
                value={value}
                size="medium"
                fullWidth={true}
                className={classes.controlTypeControl}
                classes={{ root: classes.controlTypeControl }}
                onChange={event => {
                    let dataView = this.props.dataView;
                    let reportFilter = this.props.reportFilter;
                    let newDataFilters = [...reportFilter];
                    if (event.target.value === controlTypeData.DropDown) {
                        if (this.props.dataView.dataSourceType === "storedProcedure") {

                            let filterDetails = Object.assign([], newDataFilters[rowIndex].reportFilterDetails);
                            if (filterDetails.length === 0) {
                                let newFilterData = {
                                    valueId: 0,
                                    valueDisplayId: '',
                                    valueDescription: '',
                                    parameterName: newDataFilters[rowIndex].parameterName,
                                    valueDisplayIdError: false,
                                    valueDisplayIdHelperText: ' ',
                                    valueDescriptionError: false,
                                    valueDescriptionHelperText: ' '

                                };
                                filterDetails.push(newFilterData)
                            }
                            this.setState({
                                openFilterDetails: true,
                                filterRowIndex: rowIndex,
                                showMessage: false,
                                filterDetailsData: filterDetails
                            })


                            newDataFilters[rowIndex] = { ...reportFilter[rowIndex], controlType: event.target.value, dropDownListValueType: 'static' }
                        } else {
                            newDataFilters[rowIndex] = { ...reportFilter[rowIndex], controlType: event.target.value, dropDownListValueType: 'dynamic' }
                        }
                    } else {
                        newDataFilters[rowIndex] = { ...reportFilter[rowIndex], controlType: event.target.value, dropDownListValueType: 'none', reportFilterDetails: [] }
                    }
                    dataView = { ...this.props.dataView, reportFilter: newDataFilters }
                    return this.handleChange(dataView)
                }}
            >

                {this.setControlType(rowIndex)}

            </Select>
        );
    }
    // this.props.dataView.dataSourceType === "storedProcedure" && this.props.reportFilter[rowIndex].parameterType === filterType.UserAppliedFilter ? this.setControlType(rowIndex): this.createMenuItem(controlType)}
    displayLabelTemplate = (value, rowIndex, classes) => {
        return (
            <TextField
                id={"displayLabel" + rowIndex}
                size="small"
                fullWidth={true}
                className={classes.displayLabelControl}
                disabled={this.props.reportFilter[rowIndex].parameterType === filterType.UserProfileFilter}
                value={value ? value : ""}
                inputProps={{ maxLength: 200 }}
                onChange={event => {
                    let dataView = this.props.dataView;
                    let reportFilter = this.props.reportFilter;
                    let newDataFilters = [...reportFilter];
                    newDataFilters[rowIndex] = { ...reportFilter[rowIndex], parameterFriendlyName: event.target.value }
                    dataView = { ...this.props.dataView, reportFilter: newDataFilters }
                    return this.handleChange(dataView)
                }}
            >
            </TextField>
        );
    }

    requiredTemplate = (value, rowIndex, classes) => {
        return (
            <Checkbox color="primary"
                checked={value}
                className={classes.required}
                disabled={this.props.reportFilter[rowIndex].parameterType === filterType.UserProfileFilter}
                onChange={event => {
                    let dataView = this.props.dataView;
                    let reportFilter = this.props.reportFilter;
                    let newDataFilters = [...reportFilter];
                    newDataFilters[rowIndex] = { ...reportFilter[rowIndex], isMandatory: event.target.checked }
                    dataView = { ...this.props.dataView, reportFilter: newDataFilters }
                    return this.handleChange(dataView)
                }}
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
        );
    }

    valuesSourceTemplate = (value, rowIndex, classes) => {

        return (
            <Grid container alignItems="flex-end"><Grid item xs={6}> <Select
                MenuProps={{
                disableScrollLock: true
            }}
                id={"valuesSource" + rowIndex}
                disabled={(this.props.reportFilter[rowIndex].parameterType === filterType.UserProfileFilter) || this.props.reportFilter[rowIndex].controlType !== controlTypeData.DropDown || this.props.dataView.dataSourceType === "storedProcedure"}
                value={value}
                size="medium"
                fullWidth={true}
                className={classes.sourceControl}
                classes={{ root: classes.sourceControl }}
                onChange={event => {

                    let dataView = this.props.dataView;
                    let reportFilter = this.props.reportFilter;
                    let newDataFilters = [...reportFilter];
                    let filterDetails = Object.assign([], newDataFilters[rowIndex].reportFilterDetails);
                    if (event.target.value === "static") {
                        if (filterDetails.length === 0) {
                            let newFilterData = {
                                valueId: 0,
                                valueDisplayId: '',
                                valueDescription: '',
                                parameterName: newDataFilters[rowIndex].parameterName,
                                valueDisplayIdError: false,
                                valueDisplayIdHelperText: ' ',
                                valueDescriptionError: false,
                                valueDescriptionHelperText: ' '

                            };
                            filterDetails.push(newFilterData)
                        }
                        this.setState({
                            openFilterDetails: true,
                            filterRowIndex: rowIndex,
                            showMessage: false,
                            filterDetailsData: filterDetails
                        })
                        newDataFilters[rowIndex] = { ...reportFilter[rowIndex], dropDownListValueType: event.target.value }
                    } else {
                        newDataFilters[rowIndex] = { ...reportFilter[rowIndex], dropDownListValueType: event.target.value, reportFilterDetails: [] }
                    }


                    dataView = { ...this.props.dataView, reportFilter: newDataFilters }
                    return this.handleChange(dataView)
                }}
            >
                {this.createMenuItem(valuesSource)}
            </Select></Grid><Grid className={classes.editIconGrid} item xs={6}>{this.props.reportFilter[rowIndex].dropDownListValueType === valuesSourceData.Static ? <EditOutLine id={"edit" + rowIndex} disabled={(this.props.reportFilter[rowIndex].parameterType === filterType.UserProfileFilter) || this.props.reportFilter[rowIndex].controlType !== controlTypeData.DropDown} onClick={() => { this.showDetailWindow(rowIndex) }} className={classes.editIcon} /> : ""}</Grid></Grid>


        );

    }

    operatorTemplate = (value, rowIndex, fieldName, classes) => {
        return (
            <Select
                MenuProps={{
                    disableScrollLock: true
                }}
                id={"operator" + rowIndex}
                disabled={(this.props.reportFilter[rowIndex].parameterType === filterType.UserProfileFilter || this.props.dataView.dataSourceType === enumerator.ReportDataSourceType.StoredProcedure || this.props.dataView.dataSourceType === enumerator.ReportDataSourceType.CrystalReport)}
                value={value}
                size="small"
                fullWidth={true}
                className={classes.operatorControl}
                onChange={event => {
                    let dataView = this.props.dataView;
                    let reportFilter = this.props.reportFilter;
                    let newDataFilters = [...reportFilter];
                    newDataFilters[rowIndex] = { ...reportFilter[rowIndex], parameterFilterType: event.target.value }
                    dataView = { ...this.props.dataView, reportFilter: newDataFilters }
                    return this.handleChange(dataView)
                }}
            >
                {this.getOperator(fieldName)}
            </Select>
        );
    }

    defaultValueTemplate = (value, rowIndex, classes) => {
        return (

            <TextField
                id={"defaultValue" + rowIndex}
                size="small"
                fullWidth={true}
                className={classes.defaultValueControl}
                disabled={this.props.reportFilter[rowIndex].parameterType === filterType.UserProfileFilter}
                value={value ? value : ""}
                inputProps={{ maxLength: 1000 }}
                onChange={event => {
                    let dataView = this.props.dataView;
                    let reportFilter = this.props.reportFilter;
                    let newDataFilters = [...reportFilter];
                    newDataFilters[rowIndex] = { ...reportFilter[rowIndex], parameterDefaultValue: event.target.value }
                    dataView = { ...this.props.dataView, reportFilter: newDataFilters }
                    return this.handleChange(dataView)
                }}
            >
            </TextField>
        );
    }

    visibleTemplate = (value, rowIndex, classes) => {
        return (
            <Checkbox color="primary"
                checked={value}
                disabled={(this.props.reportFilter[rowIndex].parameterType === filterType.UserProfileFilter || this.props.dataView.dataSourceType === enumerator.ReportDataSourceType.StoredProcedure || this.props.dataView.dataSourceType === enumerator.ReportDataSourceType.CrystalReport)}
                onChange={event => {
                    let dataView = this.props.dataView;
                    let reportFilter = this.props.reportFilter;
                    let newDataFilters = [...reportFilter];
                    newDataFilters[rowIndex] = { ...reportFilter[rowIndex], isVisible: event.target.checked }
                    dataView = { ...this.props.dataView, reportFilter: newDataFilters }
                    return this.handleChange(dataView)
                }}
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
        );
    }

    lineBreakTemplate = (value, rowIndex, classes) => {
        return (
            <Checkbox color="primary"
                checked={value}
                disabled={(this.props.reportFilter[rowIndex].parameterType === filterType.UserProfileFilter )}
                onChange={event => {
                    let dataView = this.props.dataView;
                    let reportFilter = this.props.reportFilter;
                    let newDataFilters = [...reportFilter];
                    newDataFilters[rowIndex] = { ...reportFilter[rowIndex], lineBreak: event.target.checked }
                    dataView = { ...this.props.dataView, reportFilter: newDataFilters }
                    return this.handleChange(dataView)
                }}
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
        );
    }

    showDetailWindow = (rowIndex) => {
        let reportFilter = this.props.reportFilter;
        let newDataFilters = [...reportFilter];
        let filterDetails = Object.assign([], newDataFilters[rowIndex].reportFilterDetails);
        if (filterDetails.length === 0) {
            let newFilterData = {
                valueId: 0,
                valueDisplayId: '',
                valueDescription: '',
                parameterName: newDataFilters[rowIndex].parameterName,
                valueDisplayIdError: false,
                valueDisplayIdHelperText: ' ',
                valueDescriptionError: false,
                valueDescriptionHelperText: ' '

            };
            filterDetails.push(newFilterData)
        }
        this.setState({ openFilterDetails: true, filterRowIndex: rowIndex, showMessage: false, filterDetailsData: filterDetails })

    }

    staticFilterNameTemplate = (value, tabValue, updateValue) => {

        return (<Typography className={this.props.classes.filterDetailsName} variant="body1"> {value}</Typography>);

    }

    addFieldDetailsData = (filters) => {
        const newFilterData = {
            valueId: 0,
            valueDisplayId: '',
            valueDescription: '',
            parameterName: this.props.reportFilter[this.state.filterRowIndex].parameterName

        };

        if (!this.checkIsEmpty(filters[filters.length - 1].valueDisplayId) && !this.checkIsEmpty(filters[filters.length - 1].valueDescription)) {
            filters.push(newFilterData)
        }

        return filters
    }

    valueTemplate = (value, tabValue, updateValue) => {
        return (

            <TextField
                id={"value" + tabValue.rowIndex}
                size="small"
                fullWidth={true}
                value={value ? value : ''}
                inputProps={{ maxLength: 20 }}
                className={this.props.classes.valueDisplay}
                error={this.state.filterDetailsData[tabValue.rowIndex].valueDisplayIdError ? this.state.filterDetailsData[tabValue.rowIndex].valueDisplayIdError : false}
                helperText={this.state.filterDetailsData[tabValue.rowIndex].valueDisplayIdHelperText ? this.state.filterDetailsData[tabValue.rowIndex].valueDisplayIdHelperText : ' '}
                onChange={event => {
                    const detailsRowIndex = tabValue.rowIndex;
                    let filterDetails = this.state.filterDetailsData
                    let newFilterDetails = [...filterDetails];
                    newFilterDetails[detailsRowIndex] = { ...filterDetails[detailsRowIndex], valueDisplayId: event.target.value }
                    this.setState({ filterDetailsData: newFilterDetails })

                }}
                onBlur={event => {

                   const detailsRowIndex = tabValue.rowIndex;
                    let filterDetails = this.state.filterDetailsData;
                    let newFilterDetails = [...filterDetails];

                    if (!this.checkIsEmpty(newFilterDetails[detailsRowIndex].valueDescription) && this.checkIsEmpty(newFilterDetails[detailsRowIndex].valueDisplayId)) {
                        newFilterDetails[detailsRowIndex] = {
                            ...filterDetails[detailsRowIndex],
                            valueDisplayIdError: true,
                            valueDisplayIdHelperText: errorMessage
                        }
                        this.setState({ filterDetailsData: newFilterDetails })
                    }
                    else if (this.checkIsEmpty(newFilterDetails[detailsRowIndex].valueDisplayId) && detailsRowIndex !== newFilterDetails.length - 1) {
                        newFilterDetails[detailsRowIndex] = {
                            ...filterDetails[detailsRowIndex],
                            valueDisplayIdError: true,
                            valueDisplayIdHelperText: errorMessage
                        }
                        this.setState({ filterDetailsData: newFilterDetails })
                    }

                    else {

                        if (this.checkIsEmpty(newFilterDetails[detailsRowIndex].valueDisplayId) && this.checkIsEmpty(newFilterDetails[detailsRowIndex].valueDescription)) {
                            if (detailsRowIndex === newFilterDetails.length - 1) {
                                newFilterDetails[detailsRowIndex] = {
                                    ...filterDetails[detailsRowIndex],
                                    valueDescriptionError: false,
                                    valueDescriptionHelperText: ' ',
                                    valueDisplayIdError: false,
                                    valueDisplayIdHelperText: ' '
                                }
                            }
                        } else {
                            
                                newFilterDetails[detailsRowIndex] = {
                                    ...filterDetails[detailsRowIndex],
                                    valueDisplayIdError: false,
                                    valueDisplayIdHelperText: ' '
                                }
                         
                        }
                        this.addFieldDetailsData(newFilterDetails)
                        this.setState({ filterDetailsData: newFilterDetails })
                    }

                }}
            >
            </TextField>
        );
    }

    labelTemplate = (value, tabValue, updateValue) => {
        return (

            <TextField
                id={"label" + tabValue.rowIndex}
                size="small"
                fullWidth={true}
                value={value ? value : ''}
                inputProps={{ maxLength: 100 }}
                className={this.props.classes.valueDisplayLabel}
                error={this.state.filterDetailsData[tabValue.rowIndex].valueDescriptionError ? this.state.filterDetailsData[tabValue.rowIndex].valueDescriptionError : false}
                helperText={this.state.filterDetailsData[tabValue.rowIndex].valueDescriptionHelperText ? this.state.filterDetailsData[tabValue.rowIndex].valueDescriptionHelperText : ' '}
                onBlur={event => {

                    const detailsRowIndex = tabValue.rowIndex;
                    let filterDetails = this.state.filterDetailsData
                    let newFilterDetails = [...filterDetails];

                    if (!this.checkIsEmpty(newFilterDetails[detailsRowIndex].valueDisplayId) && this.checkIsEmpty(newFilterDetails[detailsRowIndex].valueDescription)) {
                        newFilterDetails[detailsRowIndex] = {
                            ...filterDetails[detailsRowIndex],
                            valueDescriptionError: true,
                            valueDescriptionHelperText: errorLabelMessage
                        }
                        this.setState({ filterDetailsData: newFilterDetails })
                    }
                    else if (this.checkIsEmpty(newFilterDetails[detailsRowIndex].valueDescription) && detailsRowIndex !== newFilterDetails.length - 1) {
                        newFilterDetails[detailsRowIndex] = {
                            ...filterDetails[detailsRowIndex],
                            valueDescriptionError: true,
                            valueDescriptionHelperText: errorLabelMessage
                        }
                        this.setState({ filterDetailsData: newFilterDetails })
                    }

                    else {

                        if (this.checkIsEmpty(newFilterDetails[detailsRowIndex].valueDisplayId) && this.checkIsEmpty(newFilterDetails[detailsRowIndex].valueDescription)) {
                            if (detailsRowIndex === newFilterDetails.length - 1) {
                                newFilterDetails[detailsRowIndex] = {
                                    ...filterDetails[detailsRowIndex],
                                    valueDescriptionError: false,
                                    valueDescriptionHelperText: ' ',
                                    valueDisplayIdError: false,
                                    valueDisplayIdHelperText: ' '
                                }
                            }
                        } else {
                          
                                newFilterDetails[detailsRowIndex] = {
                                    ...filterDetails[detailsRowIndex],
                                    valueDescriptionError: false,
                                    valueDescriptionHelperText: ' '
                                }
                          
                        }
                        this.addFieldDetailsData(newFilterDetails)
                        this.setState({ filterDetailsData: newFilterDetails })
                    }

                }}
                onChange={event => {
                    const detailsRowIndex = tabValue.rowIndex;
                    let filterDetails = this.state.filterDetailsData;
                    let newFilterDetails = [...filterDetails];
                    newFilterDetails[detailsRowIndex] = { ...filterDetails[detailsRowIndex], valueDescription: event.target.value }
                    this.setState({ filterDetailsData: newFilterDetails })
                }}
            >
            </TextField>
        );
    }

    renderFilterDetails(classes) {
        let reportFilter = this.props.reportFilter;
        let reportFilterDetails = reportFilter[this.state.filterRowIndex].reportFilterDetails;

        const columns = [
            {
                name: "parameterName",
                label: "Filter Name",
                options: {
                    ...defaultColumnOptions,
                    customBodyRender: this.staticFilterNameTemplate,
                    setCellProps: () => ({
                        style: {
                            paddingLeft: theme.spacing(0),
                            paddingBottom: theme.spacing(.1),
                            paddingTop: theme.spacing(4.2),
                            paddingRight: theme.spacing(0),
                            width: 192
                        }
                    })
                },
            },
            {
                name: "valueDisplayId",
                label: "Value",
                options: {
                    ...defaultColumnOptions,
                    width: 200,
                    customBodyRender: this.valueTemplate,
                    setCellProps: () => ({
                        style: {
                            paddingLeft: theme.spacing(0),
                            paddingBottom: theme.spacing(.1),
                            paddingTop: theme.spacing(4.2),
                            paddingRight: theme.spacing(0),
                            width: 209
                        }
                    })
                },
            },
            {
                name: "valueDescription",
                label: "Label",
                options: {
                    ...defaultColumnOptions,
                    customBodyRender: this.labelTemplate,
                    setCellProps: () => ({
                        style: {
                            paddingLeft: theme.spacing(0),
                            paddingBottom: theme.spacing(.1),
                            paddingTop: theme.spacing(4.2),
                            paddingRight: theme.spacing(0),
                            width: 234
                        }
                    })
                },
            },
            {
                name: "",
                label: "",
                options: {
                    ...defaultColumnOptions,
                    customBodyRender: this.deleteRowFilterDetailsTemplate,
                    setCellProps: () => ({
                        style: {
                            paddingLeft: theme.spacing(0),
                            paddingBottom: theme.spacing(.1),
                            paddingTop: theme.spacing(4.2),
                            paddingRight: theme.spacing(0),

                        }
                    })
                },
            },
        ];

        return (
            <Dialog classes={{ paper: classes.dialog }} disableScrollLock={true}
                open={this.state.openFilterDetails}
                onClose={this.handleClose}
                //PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle id="draggable-dialog-title">
                    <Box pt={1.2}><Typography variant="h6"> Static Filter Values</Typography></Box>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>

                    <MUIDataTable container style={{ paddingLeft: '0px' }}
                        title={''}
                        data={this.state.filterDetailsData}
                        columns={columns}
                        options={this.state.filterDetailsOptions}
                    />

                </DialogContent>
                <DialogActions>

                    <Grid container spacing={1}><Grid item xs={9} className={classes.alertGrid}><Box pl={2} style={{ height: 24 }}>
                        <AlertMessage open={this.state.showMessage} severity='error'
                        title=''
                        message='Please correct the indicated items'
                        icon={<ErrorIcon />}
                        close={this.onMessageClose} /></Box>
                    </Grid>
                        <Grid item xs={3} className={classes.buttonGrid}>
                            <Box pt={1}>
                                <Grid container spacing={0}>
                                    <Grid item xs><Button variant="default" className={classes.buttonCancel} onClick={this.handleClose} >CLOSE</Button><Button color="primary" onClick={this.handleSave}>SAVE</Button></Grid>
                                </Grid>
                            </Box>
                        </Grid></Grid>
                </DialogActions>
            </Dialog>
        );
    };

    render() {
        let reportFilter = this.props.reportFilter;

        const { classes } = this.props;
        return (
            <React.Fragment>
                {this.state.filterRowIndex > -1 ? this.renderFilterDetails(classes) : null}
                <Card variant="outlined" p={3} className={classes.card} disableScrollLock={true}>

                    <TableContainer className={classes.tableContainer} disableScrollLock={true}>
                        <Table stickyHeader className={classes.table} disableScrollLock={true}>

                            <TableHead>
                                <TableRow className={classes.tableHeaderRow}>
                                    <TableCell className={classes.dragIcon + ' ' + classes.tableHeaderCell} >&nbsp;</TableCell>
                                    <TableCell className={classes.filterType + ' ' + classes.tableHeaderCell}>Filter Type</TableCell>
                                    <TableCell className={classes.fieldName + ' ' + classes.tableHeaderCell}>Field/Filter Name</TableCell>
                                    <TableCell className={classes.displayLabel + ' ' + classes.tableHeaderCell}>Display Label</TableCell>
                                    <TableCell className={classes.controlType + ' ' + classes.tableHeaderCell}>Control Type</TableCell>
                                    <TableCell className={classes.required + ' ' + classes.tableHeaderCell}>Required</TableCell>
                                    <TableCell className={classes.source + ' ' + classes.tableHeaderCell}>Source</TableCell>
                                    <TableCell className={classes.operator + ' ' + classes.tableHeaderCell}>Operator</TableCell>
                                    <TableCell className={classes.defaultValueType + ' ' + classes.tableHeaderCell}>Default Value</TableCell>
                                    <TableCell className={classes.visible + ' ' + classes.tableHeaderCell}>Visible</TableCell>
                                    <TableCell className={classes.lineBreak + ' ' + classes.tableHeaderCell}>Line Break</TableCell>
                                    <TableCell className={classes.tableHeaderCell}>&nbsp;</TableCell>

                                </TableRow>
                            </TableHead>
                            <DragDropContext onDragEnd={this.handleDragEnd}>
                                <Droppable droppableId={"droppable"} direction="vertical">
                                    {(droppableProvided: DroppableProvided) => (
                                        <TableBody 
                                            ref={droppableProvided.innerRef}
                                            {...droppableProvided.droppableProps} style={{ overFlow: "scroll", maxHeight: "150px" }}
                                        >
                                            {this.props.reportFilter.map((item: DataItem, index: number) => (
                                                <Draggable
                                                    key={index}
                                                    draggableId={index.toString()}
                                                    index={index} isDragDisabled={item.parameterName ? false : true}
                                                >
                                                    {(draggableProvided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
                                                        return (
                                                            <TableRow key={item.index} className={classes.tableRow} classes={{ selected: classes.selected }}
                                                                ref={draggableProvided.innerRef}
                                                                {...draggableProvided.draggableProps}

                                                                onClick={(e, rowNumber) => {
                                                                    this.setState({ selectedID: item.fieldName });
                                                                    rowIndex = index;
                                                                }}
                                                                selected={this.state.selectedID === item.fieldName}
                                                                classes={{ selected: this.props.classes.selected }}
                                                                className={this.props.classes.tableRow}
                                                            >
                                                                {/* note: `snapshot.isDragging` is useful to style or modify behaviour of dragged cells */}
                                                                <TableCell className={classes.dragIcon} >
                                                                    <div {...draggableProvided.dragHandleProps}>
                                                                        <ReorderIcon className={classes.dragIconControl} />
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell className={classes.filterType}  >{this.filterTypeTemplate(item.parameterType, index, classes)}</TableCell>
                                                                <TableCell className={classes.fieldName} >{this.filterNameTemplate(item.parameterName, index, classes)}</TableCell>
                                                                <TableCell className={classes.displayLabel} >{this.displayLabelTemplate(item.parameterFriendlyName, index, classes)}</TableCell>
                                                                <TableCell className={classes.controlType} >{this.controlTypeTemplate(item.controlType, index, classes)}</TableCell>
                                                                <TableCell className={classes.required}>{this.requiredTemplate(item.isMandatory, index, classes)}</TableCell>
                                                                <TableCell className={classes.source} >{this.valuesSourceTemplate(item.dropDownListValueType, index, classes)}</TableCell>
                                                                <TableCell className={classes.operator} >{this.operatorTemplate(item.parameterFilterType, index, item.parameterName, classes)}</TableCell>
                                                                <TableCell className={classes.defaultValueType} >{this.defaultValueTemplate(item.parameterDefaultValue, index, classes)}</TableCell>
                                                                <TableCell className={classes.visible} >{this.visibleTemplate(item.isVisible, index, classes)}</TableCell>
                                                                <TableCell className={classes.lineBreak} >{this.lineBreakTemplate(item.lineBreak, index, classes)}</TableCell>
                                                                <TableCell className={classes.delete}>{this.deleteRowFilterTemplate(item.parameterDefaultValue, index, classes)}</TableCell>


                                                            </TableRow>
                                                        );
                                                    }}
                                                </Draggable>
                                            ))}
                                            {droppableProvided.placeholder}
                                        </TableBody>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </Table>
                    </TableContainer>

                </Card >

            </React.Fragment>
        );

    }

}

function mapStateToProps(state) {
    console.log("mapStateToProps-filter", state)
    return {
        dataViews: state.dataViews.dataViews,
        dataView: state.dataViews.dataView,
        queryFields: state.dataViews.queryFields,
        reportFilter: state.dataViews.dataView.reportFilter,
        adminFilters: state.dataViews.adminFilters,
        isLoading: state.loader.isLoading,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage,

    };
};

export default withRouter(connect(mapStateToProps)(withStyles(filterTheme)(DataFilterList)));
