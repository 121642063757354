import { ActionTypes as types } from 'admin/actions/actionTypes';

import { produce } from 'immer';
import { newFilter } from 'admin/actions/filterActions';

const initialState = {
    filters: [],
    databases: [],
    datasources: [],
    fields: [], 
    filter: newFilter(),
    isUpdate: false,
    errors: {},
    isLoading:false
};
const filters = produce((draft, action) => {
    draft.isUpdate = false;
    switch (action.type) {
        case types.VGO_GET_FILTERS_REQUEST: 
            draft.isLoading = true;
            break;
        case types.VGO_DELETE_FILTER_SUCCESS:
        case types.VGO_GET_FILTERS_SUCCESS:
            
            draft.filters = action.filters;
            draft.errors = {};
            draft.isLoading = false;
            break; 
        case types.VGO_GET_FILTERS_DATABASE_SOURCE_SUCCESS:
          
            draft.databases = action.databases;
            draft.datasources = [];
            draft.fields = [];
            // draft.filter.sourceTableName = "";
           // draft.filter.filterDetails.fieldName = "";
            
            break; 
        case types.VGO_GET_FILTERS_DATASOURCE_SUCCESS:
          
            draft.datasources = action.datasources;
            draft.fields = [];
           // draft.filter.filterDetails.fieldName = "";
            break;
        case types.VGO_GET_FILTERS_FIELDS_SOURCE_SUCCESS:
          
            //console.log(JSON.stringify(action.fields));
            draft.fields = action.fields;
            break; 
        case types.VGO_NEW_FILTER:
           
            draft.filter = newFilter();
           
            break;
        case types.VGO_CHANGE_FILTER:
           
            action.change(draft.filter);
            break;
        case types.VGO_GET_FILTER_SUCCESS: 
         
            draft.filter = action.filter;
            break;
        case types.VGO_UPDATE_FILTER_SUCCESS:
           
            draft.isUpdate = true;
            draft.filter = action.filter;
            break;
        case types.VGO_VALIDATE_FIELD_ONBLUR:

            draft.errors = produce(draft.errors, draftErrors => { return { ...draftErrors, ...action.errors } });
            break; 
        default:
            break;
    }
}, initialState);

export default filters;
