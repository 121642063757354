import { ActionTypes as types } from 'admin/actions/actionTypes';
import { produce} from 'immer'; 
import {
    dataViewUpdateConditionValue, dataViewNewConditionValue, dataViewCancelConditionValue, dataViewRemoveConditionValue, dataViewDeleteLinkValue,
    dataViewUpdateLinkValue, dataViewRemoveConditionParameterValue, dataViewNewConditionParameterValue, dataViewNewLinkValue,
} from 'admin/actions/dataViewAddLinksActions'; 
const initialState = { 
    dataViewReportLinks: [],
    dataViewReportLinksAnchorName: [],
};
const dataViewReportLinks = produce((draft, action) => {
    switch (action.type) {
        case types.VGO_GET_DATAVIEW_REPORTLINKS_LIST_REQUEST:
            break;
        case types.VGO_UPDATE_DATAVIEW_CONDITION_REQUEST:
            break; 
        case types.VGO_UPDATE_DATAVIEW_CONDITION_SUCCESS:
            draft.dataViewReportLinks = dataViewUpdateConditionValue(action);
            break; 
        case types.VGO_NEW_DATAVIEW_CONDITION_REQUEST:
            break; 
        case types.VGO_NEW_DATAVIEW_CONDITION_SUCCESS:
            draft.dataViewReportLinks = dataViewNewConditionValue(action);
            break; 
        case types.VGO_CANCEL_DATAVIEW_CONDITION_REQUEST:
            break;
        case types.VGO_CANCEL_DATAVIEW_CONDITION_SUCCESS:
            draft.dataViewReportLinks = dataViewCancelConditionValue(action);
            break; 
        case types.VGO_REMOVE_DATAVIEW_CONDITION_REQUEST:
            break;
        case types.VGO_REMOVE_DATAVIEW_CONDITION_SUCCESS:
            draft.dataViewReportLinks = dataViewRemoveConditionValue(action);
            break; 
        case types.VGO_DELETE_DATAVIEW_LINK_REQUEST:
            break;
        case types.VGO_UPDATE_DATAVIEW_REPORTLINKS_REQUEST:
            break;
        case types.VGO_REMOVE_DATAVIEW_CONDITION_PARAMETER_REQUEST:
            break;
        case types.VGO_NEW_DATAVIEW_CONDITION_PARAMETER_REQUEST:
            break;
        case types.VGO_NEW_DATAVIEW_CONDITION_PARAMETER_SUCCESS:
            draft.dataViewReportLinks = dataViewNewConditionParameterValue(action);
            break;
        case types.VGO_GET_DATAVIEW_REPORTLINKS_REPORTFIELD_LIST_REQUEST:
            break;
        case types.VGO_NEW_DATAVIEW_LINK_REQUEST:
            break;
    default:
        break;
    }
}, initialState);

export default dataViewReportLinks;