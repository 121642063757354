import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { dataViewCalendarUpdate, getNewCalendarObjectField, validateCalendarFieldsOnBlur } from 'admin/actions/dataViewActions';
import { Grid, FormControlLabel, Typography, Switch,Box } from '@mui/material'
import { spacing } from '@mui/system';
import MuiTextField from '@mui/material/TextField';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/styles';
import { withStyles } from '@mui/styles';
import Autocomplete from '@mui/lab/Autocomplete';
import FormControl from '@mui/material/FormControl'; 
import cloneDeep from 'lodash/cloneDeep';
import * as enumerator from 'client/components/Common/Enum';
import { calendarTheme } from 'admin/components/section/dataViews/calendar/theme/calendar';
import { sortName } from 'client/components/Common/Utility';


const TextField = styled(MuiTextField)(spacing);
const Card = styled(MuiCard)(spacing);
class Calendar extends React.Component {
   

    blurValidation = (fieldName, value) => {
        
        this.props.dispatch(validateCalendarFieldsOnBlur( value, fieldName));
    }

    getDateTimeObjectValue = (parameterValue) => {
        let val = this.props.dataViewReportFields.filter(paramObj => paramObj.fieldName === parameterValue)
        if (val !== undefined && val.length > 0)
            return val[0];
        return null;
    }

    getFieldOfType = (fieldTypes) => {
//        let filteredData = this.props.dataViewReportFields.filter(dv => (dv.fieldType === fieldType || dv.fieldType === 'date'));

        let filteredData = this.props.dataViewReportFields.filter(field => fieldTypes.includes(field.fieldType))
        if (filteredData !== undefined && filteredData.length > 0)
            return filteredData.sort((a, b) => sortName(a.displayFieldName, b.displayFieldName));
        return [{ displayFieldName:"",fieldName:"" } ]
    }

    handleDateRangeOption = ( fieldIndex, property, value) => {
        let calenderData = cloneDeep(this.props.calenderData);
        let calenderCloneData = { ...calenderData };
        switch (property) {
        case "eventFieldSettings":
            if (value) {
                calenderCloneData.eventFieldSettings =
                    enumerator.CalendarEventFieldSettings.HasStartDateTimeAndEndDateTime;
            }
            else
            {
                calenderCloneData.eventFieldSettings = enumerator.CalendarEventFieldSettings.HasStartDate;
                calenderCloneData.endDate = getNewCalendarObjectField();
                calenderCloneData.endTime = getNewCalendarObjectField();
                if (this.props.errors.endDate) {
                    this.blurValidation('endDate', calenderCloneData);
                }
            }
            break;
            case "startDate": {
                if (value) {
                    calenderCloneData.startDate = value;
                }
                else {
                    calenderCloneData.startDate = getNewCalendarObjectField();
                }
                break;
            }
            case "endDate":
                {
                    if (value) {
                        calenderCloneData.endDate = value;
                    }
                    else {
                        calenderCloneData.endDate = getNewCalendarObjectField();
                    }
                    break;
                }
            case "startTime":
                {
                    if (value) {
                        calenderCloneData.startTime = value;
                    }
                    else {
                        calenderCloneData.startTime = getNewCalendarObjectField();
                    }
                    break;
                }
            
            case "endTime":
                {
                    if (value) {
                        calenderCloneData.endTime = value;
                    }
                    else {
                        calenderCloneData.endTime = getNewCalendarObjectField();
                    }
                    break;
                }
        case "title":
                calenderCloneData.titleFields[fieldIndex].title = value;
                break;
            case "fieldName":
                if (value) {
                    calenderCloneData.titleFields[fieldIndex].fieldName = value.fieldName;
                }
                else {
                    const title = calenderCloneData.titleFields[fieldIndex].title;
                    calenderCloneData.titleFields[fieldIndex] = getNewCalendarObjectField();
                    calenderCloneData.titleFields[fieldIndex].title = title;
                }
                break;
            default:
                {

                }
    }  

        
       this.props.dispatch(dataViewCalendarUpdate(calenderCloneData));
        
    }
   

    
    getPresentationFieldList = (currentIndex) => {

        const currentFieldName = this.props.calenderData.titleFields[currentIndex].fieldName;

        ////const presentationFieldList = this.props.dataViewReportFields.filter(( field) => {
        ////    if (field.fieldName === currentFieldName || !this.props.calenderData.titleFields.some(title => title.fieldName === field.fieldName)) {
        ////        return field;
        ////    }


        ////});
        const presentationFieldList = this.props.dataViewReportFields.filter(field => (field.fieldName === currentFieldName || !this.props.calenderData.titleFields.some(title => title.fieldName === field.fieldName)));
            return presentationFieldList.sort((a, b) => sortName(a.displayFieldName, b.displayFieldName));
    }
    
    render() {
        const spacing = 2;
        const { classes } = this.props;
        return (
            <Grid container direction="row" justifyContent="space-between" >
                <Grid item xs={12} >
                    <Card variant="outlined" p={3} className={classes.card}>
                        <Grid container direction="row" spacing={spacing} className={classes.mainGrid}>
                            <Grid item xs={6} className={classes.mainGridItem} >
                                <Grid item><Typography>Date/Time Fields</Typography></Grid>
                                <Box mt={.3} className={classes.subGrid}>
                                    <Grid container direction="row" spacing={4} >
                                        <Grid item xs={6} className={classes.gridPadding} >
                                            <Autocomplete
                                                id="StartDateField"
                                                size="small"
                                                fullWidth={true}
                                                

                                                options={this.getFieldOfType(["date","datetime"])}
                                                getOptionLabel={(option) => option.displayFieldName}
                                                getOptionSelected={(option, value) => value.fieldName === option.fieldName}
                                                value={this.getDateTimeObjectValue(this.props.calenderData.startDate.fieldName)}

                                                onChange={(event, newValue) => {
                                                    this.handleDateRangeOption(0, "startDate", newValue)
                                                    
                                                }}
                                                onBlur={(event) => {
                                                    this.blurValidation('startDate', this.props.calenderData);
                                                     
                                                }}
                                                renderInput={params => (
                                                    <TextField {...params}
                                                        variant="standard"
                                                        size="small"
                                                        label="Date *"
                                                        placeholder="Date *"
                                                        error={!(!this.props.errors.startDate)}
                                                        helperText={this.props.errors.startDate ? this.props.errors.startDate : ' '}
                                                        

                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={classes.gridPadding}>
                                            <Autocomplete
                                                id="StartTimeField"
                                                size="small"
                                                fullWidth={true}
                                                
                                                options={this.getFieldOfType(["datetime","time"])}
                                                getOptionLabel={(option) => option.displayFieldName}
                                                getOptionSelected={(option, value) => value.fieldName === option.fieldName}
                                                value={this.getDateTimeObjectValue(this.props.calenderData.startTime.fieldName)}
                                                onChange={(event, newValue) => {
                                                    this.handleDateRangeOption(0, "startTime", newValue)
                                                }}
                                                renderInput={params => (
                                                    <TextField {...params}
                                                        variant="standard"
                                                        size="small"
                                                        label="Time"
                                                        placeholder="Time"
                                                        helperText={" "}
                                                        error={false}
                                                    />
                                                )}
                                                />

                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" spacing={4} >
                                        <Grid item xs={12} className={classes.gridPadding} >
                                            <FormControl fullWidth={true}>
                                                <FormControlLabel
                                                    control={<Switch
                                                        color="primary"
                                                        checked={this.props.calenderData.eventFieldSettings === enumerator.CalendarEventFieldSettings.HasStartDateAndEndDate || this.props.calenderData.eventFieldSettings ===enumerator.CalendarEventFieldSettings.HasStartDateTimeAndEndDateTime }
                                                        onChange={(e) => this.handleDateRangeOption(0, "eventFieldSettings", e.target.checked)}
                                                    />}
                                                    label="Date Span" />
                                            </FormControl>
                                            </Grid>
                                    </Grid>
                                    <Grid container direction="row" spacing={4} >
                                        <Grid item xs={6} className={classes.gridPadding} >
                                            <Autocomplete
                                                id="endDate"
                                                size="small"
                                                fullWidth={true}
                                                
                                                disabled={!(this.props.calenderData.eventFieldSettings === enumerator.CalendarEventFieldSettings.HasStartDateAndEndDate || this.props.calenderData.eventFieldSettings === enumerator.CalendarEventFieldSettings.HasStartDateTimeAndEndDateTime)}
                                                options={this.getFieldOfType(["date", "datetime"])}
                                                getOptionLabel={(option) => option.displayFieldName}
                                                getOptionSelected={(option, value) => value.fieldName === option.fieldName}
                                                value={this.getDateTimeObjectValue(this.props.calenderData.endDate.fieldName)}
                                                onChange={(event, newValue) => {
                                                    this.handleDateRangeOption(0, "endDate", newValue)

                                                }}
                                                onBlur={(event) => {

                                                    this.blurValidation('endDate', this.props.calenderData);


                                                }}
                                                renderInput={params => (
                                                    <TextField {...params}
                                                        variant="standard"
                                                        size="small"
                                                        label="End Date *"
                                                        placeholder="End Date *"
                                                        error={!(!this.props.errors.endDate)}
                                                        helperText={this.props.errors.endDate ? this.props.errors.endDate : ' '}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={classes.gridPadding} >
                                            <Autocomplete
                                                id="endTime"
                                                size="small"
                                                fullWidth={true}
                                                
                                                disabled={!(this.props.calenderData.eventFieldSettings === enumerator.CalendarEventFieldSettings.HasStartDateAndEndDate || this.props.calenderData.eventFieldSettings === enumerator.CalendarEventFieldSettings.HasStartDateTimeAndEndDateTime)}
                                                options={this.getFieldOfType(["datetime","time"])}
                                                getOptionLabel={(option) => option.displayFieldName}
                                                getOptionSelected={(option, value) => value.fieldName === option.fieldName}
                                                value={this.getDateTimeObjectValue(this.props.calenderData.endTime.fieldName)}
                                                onChange={(event, newValue) => {
                                                    this.handleDateRangeOption(0, "endTime", newValue)
                                                }}
                                                renderInput={params => (
                                                    <TextField {...params}
                                                        variant="standard"
                                                        size="small"
                                                        label="End Time"
                                                        placeholder="End Time"
                                                        helperText={" "}
                                                        error={false}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                
                             </Grid>
                        </Grid>
                        <Grid container direction="row" spacing={spacing} className={classes.mainGrid}>
                            <Grid item xs={6} >

                            </Grid>
                            <Grid item xs={6} className={classes.mainGridItem}>
                                <Box pt={13} ml={ -9}>
                                        <Grid item><Typography>Presentation</Typography></Grid>
                                        <Box mt={.3} className={classes.subGrid}>
                                            <Grid container direction="row" justifyContent="space-between" spacing={4}>
                                            <Grid item xs={6} pb={ 3}>
                                                    <TextField
                                                        variant="standard"
                                                        size="small"
                                                        fullWidth={true}
                                                        id={"title1"}
                                                        label="Label"
                                                        placeholder="Label"
                                                    helperText={" "}
                                                    inputProps={{ maxLength: 50 }}
                                                        value={this.props.calenderData.titleFields[0].title} onChange={(e) => this.handleDateRangeOption(0, "title", e.target.value)} />

                                                </Grid>
                                            <Grid item xs={6} pb={3}>
                                                    <Autocomplete
                                                        id="cmbTitle1"
                                                        size="small"
                                                        
                                                        fullWidth={true}
                                                        options={this.getPresentationFieldList(0)}
                                                        getOptionLabel={(option) => option.displayFieldName}
                                                        getOptionSelected={(option, value) => value.fieldName === option.fieldName}
                                                        value={this.getDateTimeObjectValue(this.props.calenderData.titleFields[0].fieldName)}
                                                        onChange={(event, newValue) => {
                                                            this.handleDateRangeOption(0, "fieldName", newValue)
                                                    }}
                                                    onBlur={(event) => {

                                                        this.blurValidation('titleFields', this.props.calenderData);


                                                    }}
                                                        renderInput={params => (
                                                            <TextField {...params}
                                                                variant="standard"
                                                                size="small"
                                                                label="Field *"
                                                                placeholder="Field *"
                                                                error={!(!this.props.errors.titleFields)}
                                                                helperText={this.props.errors.titleFields ? this.props.errors.titleFields : ' '}
                                                            />
                                                        )}
                                                    />

                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="space-between" spacing={4}>
                                            <Grid item xs={6} pb={3}>
                                                    <TextField
                                                        variant="standard"
                                                        size="small"
                                                        fullWidth={true}
                                                        id={"title2"}
                                                        label="Label"
                                                        placeholder="Label"
                                                    helperText={" "}
                                                    inputProps={{ maxLength: 50 }}
                                                        value={this.props.calenderData.titleFields[1].title} onChange={(e) => this.handleDateRangeOption(1, "title", e.target.value)} />
                                                </Grid>
                                            <Grid item xs={6} pb={3}>
                                                    <Autocomplete
                                                        id="cmbTitle2"
                                                        size="small"
                                                        
                                                        fullWidth={true}
                                                        options={this.getPresentationFieldList(1)}
                                                        getOptionLabel={(option) => option.displayFieldName}
                                                        getOptionSelected={(option, value) => value.fieldName === option.fieldName}
                                                        value={this.getDateTimeObjectValue(this.props.calenderData.titleFields[1].fieldName)}
                                                        onChange={(event, newValue) => {
                                                            this.handleDateRangeOption(1, "fieldName", newValue)
                                                    }}
                                                    onBlur={(event) => {

                                                        this.blurValidation('titleFields', this.props.calenderData);


                                                    }}
                                                        renderInput={params => (
                                                            <TextField {...params}
                                                                variant="standard"
                                                                size="small"
                                                                label="Field"
                                                                placeholder="Field"
                                                                helperText={" "}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="space-between" spacing={4}>
                                            <Grid item xs={6} pb={3}>
                                                    <TextField
                                                        variant="standard"
                                                        size="small"
                                                        fullWidth={true}
                                                        id={"title3"}
                                                        label="Label"
                                                        placeholder="Label"
                                                    helperText={" "}
                                                    inputProps={{ maxLength: 50 }}
                                                        value={this.props.calenderData.titleFields[2].title} onChange={(e) => this.handleDateRangeOption(2, "title", e.target.value)} />
                                                </Grid>
                                            <Grid item xs={6} pb={3}>
                                                    <Autocomplete
                                                        id="cmbTitle3"
                                                        
                                                        size="small"
                                                        fullWidth={true}
                                                        options={this.getPresentationFieldList(2)}
                                                        getOptionLabel={(option) => option.displayFieldName}
                                                        getOptionSelected={(option, value) => value.fieldName === option.fieldName}
                                                        value={this.getDateTimeObjectValue(this.props.calenderData.titleFields[2].fieldName)}
                                                        onChange={(event, newValue) => {
                                                            this.handleDateRangeOption(2, "fieldName", newValue)
                                                    }}
                                                    onBlur={(event) => {

                                                        this.blurValidation('titleFields', this.props.calenderData);


                                                    }}
                                                        renderInput={params => (
                                                            <TextField {...params}
                                                                variant="standard"
                                                                size="small"
                                                                label="Field"
                                                                placeholder="Field"
                                                                helperText={" "}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                </Grid>
                                    </Box>
                                    <Box  pr={13}>
                                        <Grid container>
                                            <Grid item xs={12} className={classes.presentationPreview} >

                                                <Grid container direction="row" justifyContent="space-between" spacing={1}>
                                                    <Grid item className={classes.gridPadding} xs={1}><Typography className={classes.customLabel}> Example</Typography> </Grid>
                                                    <Grid item className={classes.presentationgridLabelItem} xs={2}><Typography className={classes.customLabel}><span className={classes.presentationItemCaption}>Label: </span>(Empty)</Typography></Grid>
                                                    <Grid item className={classes.presentationgridFieldItem} xs={3}><Typography className={classes.customLabel}><span className={classes.presentationItemCaption}>Field: </span> TransType</Typography> </Grid>
                                                    <Grid item className={classes.presentationgridItemUserSeesCaptionColumn} xs={2}><Typography className={classes.customLabel}> User Sees</Typography> </Grid>
                                                    <Grid item className={classes.gridPadding} xs={3} ><Typography className={classes.customLabel}> Purchase</Typography> </Grid>
                                                </Grid>
                                                <Grid container direction="row" justifyContent="space-between" spacing={1}>
                                                    <Grid item className={classes.gridPadding} xs={1}></Grid>
                                                    <Grid item className={classes.presentationgridLabelItem} xs={2}><Typography className={classes.customLabel}><span className={classes.presentationItemCaption}>Label: </span> Item #</Typography></Grid>
                                                    <Grid item className={classes.presentationgridFieldItem} xs={3}><Typography className={classes.customLabel}><span className={classes.presentationItemCaption}>Field: </span> ItemCode</Typography></Grid>
                                                    <Grid item className={classes.gridPadding} xs={2}></Grid>
                                                    <Grid item className={classes.gridPadding} xs={3}><Typography className={classes.customLabel}>Item # TR-0345</Typography> </Grid>
                                                </Grid>
                                                <Grid container direction="row" justifyContent="space-between" spacing={1}>
                                                    <Grid item className={classes.gridPadding} xs={1}></Grid>
                                                    <Grid item className={classes.presentationgridLabelItem} xs={2}><Typography className={classes.customLabel}><span className={classes.presentationItemCaption}>Label: </span> Qty</Typography></Grid>
                                                    <Grid item className={classes.presentationgridFieldItem} xs={3}><Typography className={classes.customLabel}><span className={classes.presentationItemCaption}>Field: </span> ItemQuantity</Typography></Grid>
                                                    <Grid item className={classes.gridPadding} xs={2}></Grid>
                                                    <Grid item className={classes.gridPadding} xs={3}><Typography className={classes.customLabel}>Qty 200</Typography></Grid>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
            
            );
         
        
    }
}

function mapStateToProps(state) {
    
    return {
        dataView: state.dataViews.dataView,
        dataViewReportFields: state.dataViews.dataView.fieldDisplay,
        calenderData: state.dataViews.dataView.reportCalender,
        isLoading: state.loader.isLoading,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage,
        errors: state.dataViews.errors.calendar ? state.dataViews.errors.calendar : {},
    };
};
export default withRouter(connect(mapStateToProps)(withStyles(calendarTheme)(Calendar)));