import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PasswordText from 'client/components/UserProfile/Password.js';
import { ActionTypes as types } from 'admin/actions/actionTypes';
import ActionBackdrop from 'admin/components/section/settings/Backdrop';

export default function ImportPassword(props) {
    const [open, setOpen] = React.useState(true);
    const [password, setPassword] = React.useState('');
    const [error, setError] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
        props.resetAction();
    };
    const handlePasswordChange = (password, passwordHelper, validatedStatus) => {
        setPassword(password);
        setError(!password);
        if (props.validateStatus === types.VGO_IMPORT_PACKAGE_VALIDATE_ERROR) {
            props.clearErrorAction();
        }
        
    }
    const handlePasswordBlur = (event) => {
        
            setError(!event.target.value);
        
    }
    const validate = () => {
        if (password) {
            props.validateAction(password);
        }
        else {
            setError(!password);
        }
        
    }
    return (
        <div>
            
            <Dialog disableScrollLock={true}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    sx: {
                        width: "20%",
                        maxHeight: 240
                    }
                }}
            >
                <DialogTitle>Password</DialogTitle>
                <DialogContent>
                    <Box >
                        {props.validateStatus === types.VGO_IMPORT_PACKAGE_VALIDATE_REQUEST && <ActionBackdrop />}
                        <PasswordText
                            id="newPassword"
                            name="newPassword"
                            required={true}
                            label="Password *"
                            handlePasswordChange={handlePasswordChange}
                            holderName="Password *"
                            value={password}
                            error={error}
                            helperText={error ? 'Password is required' : ' '}
                            handlePasswordBlur={handlePasswordBlur}
                            autoFocus
                        />
                    </Box>
                    <Box>
                        {props.validateStatus === types.VGO_IMPORT_PACKAGE_VALIDATE_ERROR  && <Typography color="red">Password verification failed </Typography>}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>CANCEL</Button>
                    <Button onClick={validate} >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}