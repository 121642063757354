import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'client/store/Objects';
import { loginActionCreators } from 'client/store/Login';
import { errorActionCreator } from 'client/store/Error';
import MenuIcon from '@mui/icons-material/Menu';
import Profile from 'admin/components/aside/Profile.js';
import Cookies from 'universal-cookie';
import * as Enum from 'client/components/Common/Enum';
import { getDeviceType } from 'client/components/Common/Utility'
import { withStyles } from '@mui/styles';
import { leftPanelTheme } from './theme/leftPanelTheme.js'
import { withRouter } from 'react-router-dom';
const deviceType = getDeviceType();
const cookies = new Cookies();
const receiveObjects = 'RECEIVE_OBJECTS';
class BergerMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = { closed: true, roleName: this.props.roleName };

    }
    componentDidMount() {
        const versagoCookie = cookies.get('Versago');
        if (versagoCookie === undefined) {
            if (this.props.actionStatus !== receiveObjects) {
                this.props.requestPublicObjects();
                this.props.requestPublicBanners();
                this.props.requestPublicNews();
            }

        }
        else {

            const roleCookie = parseInt(cookies.get('roleId'));
            const propsRoleId = parseInt(this.props.propsRoleId);
            if (propsRoleId !== roleCookie) {
                this.props.resetLayout(roleCookie);
            }
            //if (this.props.menu.length <= 0) {
            //    this.props.requestObjects(this.props.propsRoleId);
            //    this.props.requestBanners(this.props.propsRoleId);
            //    this.props.requestNews(this.props.propsRoleId);
            //}
        }
    }
    componentDidUpdate(nextProps) {
        if (this.props.propsRoleId !== nextProps.propsRoleId) {
            const versagoCookie = cookies.get('Versago');
            if (versagoCookie === undefined) {

                this.props.requestPublicObjects();
                this.props.requestPublicBanners();
                this.props.requestPublicNews();
            } else {

                this.props.requestObjects(this.props.propsRoleId);
                this.props.requestBanners(this.props.propsRoleId);
                this.props.requestNews(this.props.propsRoleId);
            }
        }
    }

    
    render() {
        const { classes } = this.props;
        return(
            <div className={`${classes.customTheme} ${classes.burgerIcon} ${classes.burgerCollapsed}`}>
                <MenuIcon className={classes.burgerIconRoot} onClick={this.props.toggleHeader}></MenuIcon>
            </div>
        )
    }
}
export default connect(
    state => state.objects,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(withStyles(leftPanelTheme)(BergerMenu));