import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import MUIDataTable from 'mui-datatables';

import { DateTime } from 'luxon';
import { getForms, deleteForm, getNewFormUI } from 'admin/actions/formActions';
import { Box } from '@mui/material'
import { formTheme } from './theme/formTheme.js';
import { withStyles } from '@mui/styles';
import { produce } from 'immer';
import DeleteButton from 'common/DeleteButton.js'
import api from 'admin/api/adminApi';
import ActionBackdrop from 'admin/components/section/settings/Backdrop';
import { objectListFormatDate } from 'admin/components/Common//DateFormat';
import { MuiGridOptions } from 'admin/components/Common/CommonOptions';
const defaultColumnOptions = {
    filter: true,
    sort: true,
};
let cellIndex = "";
class FormList extends React.Component {

    constructor(props) {
        const { classes } = props;
       super(props);
        this.state = {
            columns: [
                {
                    name: "category",
                    label: "Category",
                    options: {
                        ...defaultColumnOptions,
                        setCellProps: () => ({ className: classes.categoryColumn }),
                    }
                },
                {
                    name: "formName",
                    label: "Name",
                    options: {
                        ...defaultColumnOptions,
                        setCellProps: () => ({ className: classes.nameColumn }),
                    }
                },
                {
                    name: "formId",
                    label: "Form ID",
                    options: { 
                        ...defaultColumnOptions,
                         setCellProps: () => ({ className: classes.formIdColumn }),
                    }
                },
                {
                    name: "layoutType",
                    label: "Type",
                    options: {
                        ...defaultColumnOptions
                    }
                },
                {
                    name: "database",
                    label: "Connection",
                    options: {
                        ...defaultColumnOptions,
                        setCellProps: () => ({ className: classes.nameColumn }),
                    }
                },
                {
                    name: "headerTablename",
                    label: "Data Source",
                    options: {
                        ...defaultColumnOptions,
                        setCellProps: () => ({ className: classes.nameColumn }),
                    }
                },

                {
                    name: "createDate",
                    label: "Created",
                    options: {
                        ...defaultColumnOptions,
                        customBodyRender: objectListFormatDate,
                        setCellProps: () => ({ className: classes.dateColumn }),
                       
                    }
                },
                {
                    name: "modifiedDate",
                    label: "Modified",
                    options: {
                        ...defaultColumnOptions,
                        customBodyRender: objectListFormatDate,
                        setCellProps: () => ({ className: classes.dateColumn }),
                    }
                },
                {
                    name: "",
                    label: "",
                    showIcon: true,
                    options: {
                        ...defaultColumnOptions,
                        setCellProps: () => ({ className: classes.deleteColumn }),
                        customBodyRender: this.deleteTemplate,
                        filter: false,
                    }
                }
            ],
            options: {
                download: false,
                elevation: 0,
                jumpToPage: true,
                onRowClick: (rowData, rowMeta) => {
                    let dataIndex = rowMeta.dataIndex;
                    let form = this.props.forms[dataIndex];
                    this.getFormData(form.formId)
                },
                onCellClick: (rowNumber, columnId) => {
                   
                    this.setCellIndex(columnId.colIndex)

                },
                
                responsive: 'scrollMaxHeight',
                print: false,
                searchOpen: true,
                pagination: false,
                filter: true,
                filterType: 'multiselect',
                selectableRows: 'none', 
                sortOrder: {
                    name: 'category',
                    direction: 'asc',
                },
                onFilterChange: (changedColumn, filterList, type, changedColumnIndex, displayData) => {
                    this.setState(produce(draft => {
                        draft.columns[changedColumnIndex].options.filterList = filterList[changedColumnIndex];
                    }));
                },
                onColumnSortChange: (changedColumn, direction) => {
                    this.setState(produce(draft => {
                        draft.options.sortOrder = { name: changedColumn, direction: direction };
                    }));
                }
            },
        };
    }
    formatDate = (value, tableMeta, updateValue) => {
        if (typeof (value) === "string") {
            value = DateTime.fromISO(value);
        }
        return value.toFormat('LL-dd-yyyy hh:mm:ss a');
    }
    componentDidMount() {
        this.dispatchGetForms();
    }

    dispatchGetForms = () => {
        if (this.props.forms.length === 0) {
            this.props.dispatch(getForms());
        }
        
        this.props.dispatch(getNewFormUI());
    }

    getFormData = (formId) => { 
        if (cellIndex !==8) {
            this.props.history.push("/Admin/Forms/" + formId);
            } 
    } 
    setCellIndex = (index) => {
        cellIndex = index;

    } 
    deleteTemplate = (value, tableMeta, updateValue) => {
        let columnIndex = 2;
        return <DeleteButton preValidationServer={api.canDeleteForm} onClick={this.dispatchDeleteForm} id={tableMeta.rowData[columnIndex]} />
    }
    dispatchDeleteForm = (formId) => {
        let form = this.props.forms.find(u => u.formId === formId);
        this.props.dispatch(deleteForm(form.formId));
    }

    render() {
        const { classes } = this.props;
        return (
            <Box border={1} className={classes.commonObjectListBox}>
                {this.props.isDataLoading && <ActionBackdrop />}
                <MUIDataTable container
                    title={''}
                    data={this.props.forms}
                    columns={this.state.columns}
                    options={MuiGridOptions(this.state.options, this.props.isDataLoading)}
                />
            </Box>
        ); 
    }

}

/**
 * mapStateToProps
 * @param {*} state 
 */
function mapStateToProps(state) {
   
    return {
        forms: state.forms.forms,
        isLoading: state.loader.isLoading,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage,
        isDataLoading: state.forms.isLoading,
    };
};

export default withRouter(connect(mapStateToProps)(withStyles(formTheme)(FormList)));
