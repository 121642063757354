import * as yup from "yup";
import { ReportDataSourceType } from 'client/components/Common/Enum';


export const dataViewSetupSchema = yup.object().shape({
    reportName: yup.string().required("ReportName is required"),
    title: yup.string().required("ReportTitle is required"),
    category: yup.object()
        .test('isCategoryEmpty',
            'Category is required',
            function(value) {
                return value.categoryName ? true : false;
            })
        .required("Category is required"),
    company: yup.object()
        .test('isCompanyEmpty',
            'Connection is required',
            function(value) {
                return value.databaseId === 0 ? false : true;
            })
        .required("Connection is required"),
    dataSourceType: yup.string()
        .test('isDataSourceEmpty',
            'Data source type is required',
            function(value) {
                return value === 'none' ? false : true;
            })
        .required("Data source type is required"),

    dataSource: yup.object()
        .when("dataSourceType",
            (dataSourceType) => {
                if (dataSourceType === undefined) {
                    return yup.object().test('isDataSourceEmpty',
                            'Data source is required',
                            function(value) {
                                dataSourceType = this.options.context.dataSourceType
                                if (dataSourceType === ReportDataSourceType.View ||
                                    dataSourceType === ReportDataSourceType.B1Query ||
                                    dataSourceType === ReportDataSourceType.StoredProcedure)
                                    return !value.value ? false : true;
                                else
                                    return true;
                            })
                        .required("Data source is required")
                }
                if (dataSourceType === ReportDataSourceType.View ||
                    dataSourceType === ReportDataSourceType.B1Query ||
                    dataSourceType === ReportDataSourceType.StoredProcedure)
                    return yup.object().test('isDataSourceEmpty',
                            'Data source is required',
                            function(value) {
                                return !value.value ? false : true;
                            })
                        .required("Data source is required")
            }),

    roles: yup.array().min(1,'Roles is required'),
    chart: yup.array()
        .transform(fields => fields.slice(1)) // to remove last row from array
        .of(
        yup.object().shape(
            {
                chartTitle: yup.string().required("Chart title is required"),
                chartManager: yup.object().shape({
                    name: yup.string().required("Chart name is required")
                }),
                //chartName: yup.string().required("Chart name is required"),
                chartYAxes: yup.array().of(
                    yup.object().shape({
                        yText: yup.string().required("y-axis is required")
                    }))
            }))
       
});



