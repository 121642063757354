import React from 'react';
import { styled } from '@mui/styles';
import { spacing } from '@mui/system';
import MuiTextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import ErrorIcon from '@mui/icons-material/Error';
import { InputAdornment, Popover} from '@mui/material';
import { Checkbox } from '@mui/material';
import { hasRoleObjectDepend } from 'admin/actions/roleActions';
import { withStyles } from '@mui/styles';
//import { deleteActionStyle } from './theme/deleteButtonTheme.js';
import { connect } from 'react-redux';
import Alert from '@mui/lab/Alert';
import { withRouter } from 'react-router-dom';
import Button from '@mui/material/Button';
import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { rolesTheme } from './theme/rolesTheme.js';
const TextField = styled(MuiTextField)(spacing);

const MuiPopover = withStyles((theme) => ({
    root: {},
    paper: {
       // boxShadow: rolesTheme.popper.boxShadow,
    },
}))(Popover)
class GridCheckBox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            checked: this.props.checked,
            anchorElement:null,
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.id === prevProps.id) {
            if (this.props.checked !== prevProps.checked) {
                this.setState({ checked: this.props.checked })
            }
        } else {
            this.setState({ checked: this.props.checked })
        }
    }
    handleSelect = (event) => {
       // this.setState({ checked: !this.state.checked }, () => {
        if (!event.target.checked) {
            if (this.props.depended) {
                this.props.dispatch(hasRoleObjectDepend(this.props.depended.type, this.props.depended.id, this.props.depended.roleId)).then((resp) => {
                    if (resp) {
                        this.setState({ anchorElement: event.target })
                    } else {
                        this.setState({ checked: false }, () => {
                            this.props.handleSelect(this.props.id, this.props.name ? this.props.name : "", this.state.checked, event.target);
                        })
                    }

                });

            } else {
                this.setState({ checked: !this.state.checked }, () => {
                    this.props.handleSelect(this.props.id, this.props.name ? this.props.name : "", this.state.checked, event.target)
                })
            
            }
            
        } else {
            this.setState({ checked: true }, () => {
                this.props.handleSelect(this.props.id, this.props.name ? this.props.name : "", true, event.target);
            })   
            }
       // })

    }
    handleOnClick = () => {
        this.props.handleSelect(this.props.depended.id, "", false);
        this.setState({ anchorElement: null, checked: false})
    }
    handleClose = () => {
        this.setState({ anchorElement: null })
    };
    getMessage = () => {
        let message = [];
        const dependencyMessage = ("Other content makes use of this. Are you sure?");
        const { classes } = this.props;

        const actionButton = (<React.Fragment>
            <Button color="inherit" size="small" className={classes.alertActionButton} onClick={this.handleOnClick}>YES</Button>
            <Button color="inherit" size="small" className={classes.alertActionButton} onClick={this.handleClose}>NO</Button>
            <IconButton className={classes.alertIconButton} onClick={this.handleClose}><CloseIcon className={rolesTheme.closeButton} fontSize="small" /></IconButton>

        </React.Fragment>)


       /* message = dependencyMessage.split('\n').map((item, key) => {*/
        return <div><span >{dependencyMessage}</span>{  actionButton  }<br /></div>
        //})
        return message;
    };
    render() {
        const open = Boolean(this.state.anchorElement);
        const id = open ? 'simple-popover' : undefined;
        
        return (
            <div  >
                <Checkbox className={this.props.selectCheckboxclasses} checked={this.state.checked} onChange={this.handleSelect} color="primary" />
                 
                    <MuiPopover
                        id={id}
                        open={open}
                        anchorEl={this.state.anchorElement}
                        onClose={this.handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                    }}  >
                    <Alert severity="warning"  classes={{ icon: this.props.classes.alertIcon, message: this.props.classes.alertMessage }}  >
                            {

                                this.getMessage()
                            }


                        </Alert>
                    </MuiPopover> 
                 
                </div>
        );
    }

}

////export default GridCheckBox;
//export default withRouter(connect()(GridCheckBox))
export default withRouter(connect()(withStyles(rolesTheme)(GridCheckBox)));
