export const getErrorMessage = (err) => {
    let { message } = err;
    if (err.response && err.response.data) {
        message = err.response.data;
        if (typeof (message) !== "string") {
            if (message.title && typeof (message.title) === "string") {
                message = message.title;
            }
            else {
                message = err.message;
            }
        }
    }
    return message;
};