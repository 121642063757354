import { ActionTypes as types } from './actionTypes';
import adminApi from 'admin/api/adminApi';
import mockApi from 'admin/api/mockApi';
import { showError, showSuccess, hideError } from './alertActions';
import { getError } from './errorActions';

import settings from 'settings.js';
import { DateTime } from 'luxon';
import { validateObject, validateObjectAt } from 'admin/actions/validationAction';
import { homePageValidationSchema } from 'admin/components/section/homePage/HomePageValidationSchema';
export const publishedState = { published: "Published", unPublished: "UnPublished" };
export const homePageType = { Banner: "banner", Feed: "feed" }
const message = 'Please correct the indicated items'
/**
 * reads mock data setting from settings
 */
const useMocks = settings.useMocks;

/**
 * api in use, mock or real
 */
const api = useMocks ? mockApi : adminApi;

/**
 * generic calls
 */

/**
 * Invokes the mock or real web api to get users
 */


const apiGetHomePage = () => {

    return api.getHomePageList();
};


/**
 * action creators
 */

/**
 * Home page request action creator
 */
const getHomePageRequest = () => {
    return {
        type: types.VGO_GET_HOMEPAGE_REQUEST,
    }
};

const getHomePageContentRequest = () => {
    return {
        type: types.VGO_HOME_PAGE_CONTENT_REQUEST,
    }
};

const getHomePageUpdateRequest = () => {
    return {
        type: types.VGO_GET_HOMEPAGE_UPDATE_REQUEST,
    }
};
const deleteHomePageUpdateRequest = () => {
    return {
        type: types.VGO_GET_HOMEPAGE_UPDATE_REQUEST,
    }
};

const apiUpdateHomePage = (homePageContent) => {
    return api.updateHomePage(homePageContent);
};

const apiPublishHomePage = (homePageContent) => {
    return api.publishHomePage(homePageContent);
};

const apiDeleteHomePage = (homePageContent) => {
    return api.deleteHomePage(homePageContent);
};

const apiGetHomePageContent = (homePageContent) => {
    return api.getHomePageContent(homePageContent.id, homePageContent.homePageType);
};


/**
 * Home page success action creator
 * @param {*} resp 
 */
const getHomePageSuccess = (resp) => {

    return {
        type: types.VGO_GET_HOMEPAGE_SUCCESS,
        data: updateHomePageProperties(resp.data),
    };
};

const getHomePageContentSuccess = (resp) => {

    return {
        type: types.VGO_HOME_PAGE_CONTENT_SUCCESS,
        data: resp.data,
    };
};

export const getChangeHomePage = (change) => {
    return {
        type: types.VGO_CHANGE_HOME_PAGE_CONTENT,
        change,
    };
}


export const newHomePage = () => {
    let homePage = {
        id: 0,
        homePageType: homePageType.Banner,
        subType: 0,
        title: '',
        userName: '',
        updatedUserId: 0,
        createdDate: DateTime.local(),
        modifiedDate: DateTime.local(),
        active: false,
        thumbnailImageName: '',
        isPreLogin: false,
        roles: [],
        contentEventDate: DateTime.local(),
        expiryDate: DateTime.local().plus({ month: 6 }),
        detailDisplayType: 0,
        summary: '',
        content: '',
        thumbnailImage: '',
        base64Image: '',
        postedDate: DateTime.local(),
        publishedDate: DateTime.local(),

    };


    return homePage;
};
const setValidateFieldsOnBlur = (errors) => {
    return {
        type: types.VGO_VALIDATE_HOME_PAGE_ON_BLUR,
        errors: errors,
    };
};

export const validateFieldsOnBlur = (value, fieldPath) => {
    return dispatch => {
        validateObjectAt(homePageValidationSchema, value, fieldPath, fieldPath)
            .then(resp => {

                dispatch(setValidateFieldsOnBlur(resp));
            })

    }
}

export const updateHomePageProperty = (homePageContent) => {

    if (!homePageContent.hasOwnProperty('publishState')) {
        Object.defineProperty(homePageContent, 'publishState', {
            get() {
                return this.isActive ? publishedState.published : publishedState.unPublished;
            },
            set(newState) {
                this.isActive = newState === publishedState.published;
            },

        });
    }
    return homePageContent;
};

const updateHomePageProperties = (homePageList) => {
    homePageList.map(homePage => updateHomePageProperty(homePage));
    return homePageList;
};

export const getHomePage = () => {

    return dispatch => {
        dispatch(getHomePageRequest());
        return apiGetHomePage()
            .then((resp) => {
                hideError(dispatch);
                dispatch(getHomePageSuccess(resp));

            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
                showError(dispatch, message, 5000);
            });
    }
};

export const updateHomePage = (homePageContent) => {

    return dispatch => {
        validateObject(homePageValidationSchema, homePageContent)
            .then(resp => {
                if (resp !== true) {
                    //  fieldValidation = false; 
                    dispatch(setValidateFieldsOnBlur(resp));
                    dispatch(getError(message));
                    showError(dispatch, message, 5000);
                } else {
                    dispatch(getHomePageUpdateRequest());
                    return apiUpdateHomePage(homePageContent)
                        .then((resp) => {
                            dispatch(getHomePageContentSuccess(resp));
                            showSuccess(dispatch, 'Success', 'Home Page Content saved!', 5000);
                        })
                        .catch(err => {
                            let { message } = err;
                            if (err.response && err.response.data) {
                                message = err.response.data;
                                if (typeof (message) !== "string") {
                                    if (message.title && typeof (message.title) === "string") {
                                        message = message.title;
                                    } else {
                                        message = err.message;
                                    }
                                }
                            }
                            dispatch(getError(message));
                            showError(dispatch, message, 10000);
                        });

                }
            });
    };
};
export const publishHomePage = (homePageContent) => {

    return dispatch => {

        dispatch(getHomePageUpdateRequest());
        return apiPublishHomePage(homePageContent)
            .then((resp) => {
                dispatch(getHomePageSuccess(resp));
                showSuccess(dispatch, 'Success', 'Home Page Content saved!', 5000);
            })
            .catch(err => {
                let { message } = err;
                if (err.response && err.response.data) {
                    message = err.response.data;
                    if (typeof (message) !== "string") {
                        if (message.title && typeof (message.title) === "string") {
                            message = message.title;
                        } else {
                            message = err.message;
                        }
                    }
                }
                dispatch(getError(message));
                showError(dispatch, message, 10000);
            });


    };
};
export const deleteHomePage = (homePageContent) => {

    return dispatch => {
        dispatch(deleteHomePageUpdateRequest());
        return apiDeleteHomePage(homePageContent)
            .then((resp) => {
                dispatch(getHomePageSuccess(resp));
                showSuccess(dispatch, 'Success', 'Home Page Content deleted!', 5000);
            })
            .catch(err => {
                let { message } = err;
                if (err.response && err.response.data) {
                    message = err.response.data;
                    if (typeof (message) !== "string") {
                        if (message.title && typeof (message.title) === "string") {
                            message = message.title;
                        }
                        else {
                            message = err.message;
                        }
                    }
                }
                dispatch(getError(message));
                showError(dispatch, message, 10000);
            });
    };
};
export const getHomePageContent = (homePageContent) => {

    return dispatch => {
        dispatch(getHomePageContentRequest());
        return apiGetHomePageContent(homePageContent)
            .then((resp) => {
                dispatch(getHomePageContentSuccess(resp));

            })
            .catch(err => {
                let { message } = err;
                if (err.response && err.response.data) {
                    message = err.response.data;
                    if (typeof (message) !== "string") {
                        if (message.title && typeof (message.title) === "string") {
                            message = message.title;
                        }
                        else {
                            message = err.message;
                        }
                    }
                }
                dispatch(getError(message));
                showError(dispatch, message, 10000);
            });
    };
};