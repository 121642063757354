import React, { Fragment } from 'react';

import { withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { Box } from '@mui/material';

import UserEditHeader from './users/UserEditHeader';
import UserListHeader from './users/UserListHeader';
import FilterListHeader from './filter/FilterListHeader';
import FilterEditHeader from './filter/FilterEditHeader';
import RoleEditHeader from './roles/RoleEditHeader';
import RoleListHeader from './roles/RoleListHeader';

import DatabaseEditHeader from './database/DatabaseEditHeader';
import DatabaseListHeader from './database/DatabaseListHeader';

import DataViewEditHeader from './dataViews/DataViewEditHeader';
import DataViewListHeader from './dataViews/DataViewListHeader';

import DashboardListHeader from './dashboard/DashboardListHeader';
import DashboardEditHeader from './dashboard/DashboardEditHeader';

import FormListHeader from './form/FormListHeader';
import FormEditHeader from './form/FormEditHeader';

import MenuEditHeader from './menu/MenuEditHeader';
import MenuListHeader from './menu/MenuListHeader';
import SettingsHeader from './settings/SettingsHeader';
import APILinkHeader from './customWidgets/APILinkHeader';
import SiteThemeEditHeader from './siteTheme/SiteThemeEditHeader';
import HomePageListHeader from './homePage/HomePageListHeader';
import HomePageEditHeader from './homePage/HomePageEditHeader';
import ExportHeader from './package/ExportHeader';
import ImportHeader from './package/ImportHeader';
import AlertMessage from 'admin/components/Common/AlertMessage';
import { getAlertHide, hideError } from 'admin/actions/alertActions';
class Header extends React.Component {

    componentDidUpdate(prevProps) {
        if (prevProps.alert.open === this.props.alert.open && prevProps.alert.severity === this.props.alert.severity) {
            if (prevProps.location.pathname !== this.props.location.pathname) {
                this.props.dispatch(this.hideErrorMessage());
            }
        }
    }

    componentDidMount() {
       document.addEventListener("mousedown", this.handleClick);
    }

    handleClick = () => {
       
        if (this.props.alert.message !== "" && this.props.alert.severity !== "warning" && this.props.alert.source !== "dashboard") {
          
            this.props.dispatch(this.hideErrorMessage());
        }
       

    }
    onMessageClose = (timeOutId) => {
        if (timeOutId) {
            clearTimeout(timeOutId)
        }
        
        this.props.dispatch(getAlertHide(this.props.alert.severity));
    }

    hideErrorMessage = () => {
        return dispatch => {
            hideError(dispatch);
        };
    };

    render() {
        let alert = this.props.alert;
        return (
            <Fragment>
                <Box  sx={{zIndex:3}}position="fixed" ml={62.3} >
                    <AlertMessage
                        open={alert.open}
                        severity={alert.severity}
                        title={alert.title}
                        message={alert.message}
                        icon={alert.icon}
                        close={this.onMessageClose}
                        action={alert.action}
                        source={alert.source}
                    />
                </Box>
                <Switch>
                
                <Route path="/Admin/Dashboards/:dashboardId" component={DashboardEditHeader} />
                <Route path="/Admin/Dashboards" component={DashboardListHeader} />
                <Route path="/Admin/DataViews/:reportId" component={DataViewEditHeader} />
                <Route path="/Admin/DataViews" component={DataViewListHeader} />
                <Route path="/Admin/Databases/:databaseId" component={DatabaseEditHeader} />
                <Route path="/Admin/Databases" component={DatabaseListHeader} />
                <Route path="/Admin/Users/:userId" component={UserEditHeader} />
                <Route path="/Admin/Users" component={UserListHeader} />
                <Route path="/Admin/Roles/:roleId" component={RoleEditHeader} />
                <Route path="/Admin/Roles" component={RoleListHeader} />
                <Route path="/Admin" Component={Box} exact />
                <Route path="/Admin/Filters" component={FilterListHeader} />
                <Route path="/Admin/Filter/:filter" component={FilterEditHeader} />
                <Route path="/Admin/Menus/:menuId" component={MenuEditHeader} />
                <Route path="/Admin/Menus" component={MenuListHeader} />
                <Route path="/Admin/Filter/:filter" component={FilterEditHeader} /> 
                <Route path="/Admin/Forms/:formId" component={FormEditHeader} />
                <Route path="/Admin/Forms" component={FormListHeader} />
                    <Route path="/Admin/Settings" component={SettingsHeader} />
                    <Route path="/Admin/OtherLinks" component={APILinkHeader} />
                <Route path="/Admin/SiteTheme" component={SiteThemeEditHeader} />
                    <Route path="/Admin/HomePage/:homePageId" component={HomePageEditHeader} />
                    <Route path="/Admin/HomePage" component={HomePageListHeader} />
                    <Route path="/Admin/Export" component={ExportHeader} />
                    <Route path="/Admin/Import" component={ImportHeader} />
                </Switch>
            </Fragment>
        );
    }

}

function mapStateToProps(state) {
    let props = {
        alert: state.alert,
    };
    return props;
};

export default withRouter(connect(mapStateToProps)(Header));