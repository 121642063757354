import React from 'react';
import FormEdit from './FormEdit';
import FormSourceTable from './setup/formSourceTable';

export default function () {
    return (
        <React.Fragment>
            <FormEdit />
            <FormSourceTable />
        </React.Fragment>
    );
}