import React from 'react';
import { Typography } from '@mui/material';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const defaultQuote= "The greatest glory in living lies not in never falling, but in rising every time we fall."
   const defaultAuthor= "Nelson Mandela"
class quote extends React.Component {

    constructor(props) {
        super(props); 
        this.state = {
            error: null,
            isLoaded: false,
            quote: defaultQuote,
            author: defaultAuthor,
            background:"",
            dated:""
        };
    }
    componentDidMount() {
        var existingState = cookies.get("quotefetch");
        var lastFetch = "";
        if (existingState !== undefined) { lastFetch = existingState.dated; }
        if (this.props.widget.apiUrl !== "" && lastFetch !== (new Date()).toDateString()) {
            fetch(this.props.widget.apiUrl)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.error === undefined) {
                            this.setState({
                                isLoaded: true,
                                error:null,
                                quote: result.contents.quotes[0].quote,
                                dated: (new Date()).toDateString(),
                                author: result.contents.quotes[0].author,
                                background: result.contents.quotes[0].background
                            });
                            cookies.set("quotefetch", this.state, { path: '/', maxAge: 86400 });
                        } else {
                            this.setState({
                                isLoaded: true,
                                error:result.error,
                                quote: defaultQuote,
                                author: defaultAuthor,
                                background: "",
                                dated: ""
                            });
                            cookies.set("quotefetch", "");
                        }
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        ////this.setState({
                        ////    isLoaded: true,
                        ////    error,
                        ////    quote: "",
                        ////    author: "",
                        ////    background: "",
                        ////    dated: ""
                        ////});
                        this.setState({
                            isLoaded: true,
                            quote: defaultQuote,
                            author: defaultAuthor,
                            background: "",
                            dated: ""
                        });
                        cookies.set("quotefetch", "");
                    }
                );
        }
        else {
            this.setState(existingState);
        }
    } 
    renderQuote = (widget) => {

        return ( 
            <div>
                <header className={this.props.classes.widgetHead}>
                    <Typography id="idAuthor" className={this.props.classes.widgetHeadContent} >{this.state.author}</Typography>
                </header>
                <blockquote className={`${this.props.classes.blockquote} ${this.props.classes.widgetBody} ${this.props.classes.blockQuoteContent}`  }  >
                    {this.state.quote}
                </blockquote>
                
               {/* <p style={{ color: "red" }}>{this.state.error !== null && "OOPS! " + this.state.error.message} <strong>{ }</strong></p>*/}
                {/*<span style={{ zIndex: 50, fontSize: "0.9em", fontWeight: "bold"}}>*/}
                {/*    <img src="https://theysaidso.com/branding/theysaidso.png" height="20" width="20" alt="theysaidso.com" />*/}
                {/*    <a href="https://theysaidso.com" title="Powered by quotes from theysaidso.com" style={{ color: "#ccc", marginLeft: "4px", verticalAlign: "middle"}}>*/}
                {/*        They Said So®*/}
                {/*    </a>*/}
                {/*</span>*/}
            </div>
    );
}
    render() {


    return (
        <li key={this.props.widget.type} className={this.props.classes.widget}>
            {this.renderQuote(this.props.widget)}
        </li>
);
}
}
export default quote;