import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import { getDashboards, setChangeRoleData,  setChangeRoleDataName } from 'admin/actions/roleActions';
import { RoleTab } from 'admin/actions/actionTypes';
import { theme } from 'common/theme.js';
import { rolesTheme } from './theme/rolesTheme.js';

import { DateTime } from 'luxon';
import GridCheckBox from './GridCheckBox';
import GridCheckBoxHeader from './GridCheckBoxHeader';
import { produce } from 'immer';
import { withStyles } from '@mui/styles';
import ActionBackdrop from 'admin/components/section/settings/Backdrop';
import { MuiGridOptions } from 'admin/components/Common/CommonOptions';
import { objectListFormatDate } from 'admin/components/Common//DateFormat';
const defaultColumnOptions = {
    filter: true,
    sort: true,
    setCellProps: ( cellValue, rowIndex, columnIndex ) => {
        return {
            style: {
                padding: theme.spacing(1) 
            }
        };
    }
};

class DashboardList extends React.Component {

    constructor(props) {
        super(props);

        const options = {
            filterType: "textField",
            fixedHeader: true,
            sort: true,
            search: true,
            selectableRows: "none",
            responsive: 'scrollMaxHeight',
            download: false,
            elevation: 0,
            filter: true,
            filterType: 'multiselect',
            jumpToPage: true,
            searchOpen: false,
            pagination: false,
            onColumnSortChange: (changedColumn, direction) => {
                this.setState(produce(draft => {
                    draft.options.sortOrder = { name: changedColumn, direction: direction };
                    draft.sortSettings = { sortFrom: changedColumn, direction: direction, clearSort: false };
                }));
            },
            print: false,
            searchPlaceholder: 'Enter search text ...',
            sortOrder: {
                name: 'name',
                direction: 'asc'
            }, searchOpen: true,
        }
       this.state = {
           columns: [
               {
                   name: "activeState",
                   label: "Active",
                   options: {
                       customHeadRender: (columnMeta, updateDirection) => (
                           <GridCheckBoxHeader
                               selectAll={props.classes.selectAll}
                               selectCheckbox={this.props.classes.selectCheckbox}
                               checked={this.props.roles.dashboards?.length === this.props.roles.role.DashboardList?.length ? true : false}
                               handleChange={this.handleChange}
                               handleSelectCheckBoxSort={this.handleSelectCheckBoxSort}
                               sortSettings={this.state.sortSettings}
                               clearSort={this.clearSelectSort}
                               selectedObjectKey={"DashboardList"}
                           />
                          
                       ),
                       filter: false,
                       customBodyRender: this.checkBoxTemplate,
                   }
               },
               {
                   name: "dashboardId",
                   label: "dashboardId",
                   options: {
                       filter: false,
                       display: false,
                       viewColumns: false,
                   }
               },
               
                {
                    name: "name",
                    label: "Name",
                    options: { defaultColumnOptions }
                },
                {
                    name: "createdDateTime",
                    label: "Created",
                    options: {
                        ...defaultColumnOptions,
                        customBodyRender: objectListFormatDate
                    }
                }
           ],
           options: options,
           sortSettings: { sortFrom: "", direction: "", clearSort: false }
        };
    }

    componentDidMount() {
        this.dispatchGetDashboards();
    } 
    componentDidUpdate(prevProps, prevState) {
        if (this.props.roleData !== undefined) {
            this.getSelectedRow(this.props)
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.sortSettings.sortFrom !== this.state.sortSettings.sortFrom && !nextState.sortSettings.clearSort) {
            return true;
        }
        if (nextState.sortSettings.sortFrom === this.state.sortSettings.sortFrom && nextState.sortSettings.direction !== this.state.sortSettings.direction && !nextState.sortSettings.clearSort) {
            return true;
        }
        if (nextProps.roles.role.activeTab === RoleTab.dashboard || nextProps.roles.role.activeTab === RoleTab.roleName || nextProps.roles.role.activeTab === RoleTab.defaultObjectId || nextProps.roles.role.activeTab === RoleTab.defaultObjectTypeWithOutRefresh) {
            if (this.props.roles.dashboards !== undefined) {
                return false;
            } else { return true; }
        }
        return true;
    }

    dispatchGetDashboards = () => {
        if (this.props.roles.dashboards === undefined) {
            this.props.dispatch(getDashboards());
        }
    }
    formatDate = (value) => {
        if (value) {
            if (typeof (value) === "string") {
                value = DateTime.fromISO(value);
            }
            return value.toFormat('LL-dd-yyyy hh:mm:ss a');
        } else {

            return value;
        }

    }
    getSelectedRow = (props) => {
        let array = []
        if (props.roles !== undefined) {
            if (props.roles.role !== undefined) {
                props.roles.role.DashboardList.map((dashboard) => {
                    return props.roles.dashboards.map((role, index) => {
                        if (role.id === dashboard.Id) {
                            array.push(index)
                        }
                        return "";
                    });

                });

            }
        }

        return array


    }
    handleChange = (e) => {

        let array = [];
        if (e.target.checked) {

            for (let i = 0; i < this.props.roles.dashboards.length; i++) {
                var dashObject = this.props.roles.dashboards[i];
                array.push({Id: dashObject.dashboardId, Name: dashObject.name })
            }
        }

        this.props.dispatch(setChangeRoleData(RoleTab.dashboardWithRefresh, '', this.props.roles.role.DashboardList, array));


    }
    handleSelect = (Id, Name) => {

        this.props.dispatch(setChangeRoleDataName(RoleTab.dashboard, Id, Name, this.props.roles.role.DashboardList));
    }
    checkBoxTemplate = (value, tableMeta, updateValue) => {

        let status = false;
        let rId = tableMeta.rowData[1];
        let rName = tableMeta.rowData[2];
        let formIndex = this.props.roles.role.DashboardList.findIndex(c => c.Id === rId )

        if (formIndex !== -1) {
            status = true;
        }
        return (
            <GridCheckBox selectCheckboxclasses={this.props.classes.selectCheckbox ? this.props.classes.selectCheckbox : ""} checked={status} id={rId} name={rName} handleSelect={this.handleSelect} />
        )
    }
    handleSelectCheckBoxSort = () => {
        const sortDirection = this.state.sortSettings.direction
        const sortField = this.state.sortSettings.sortFrom
        let direction = "";
        if (sortField === "select" && sortDirection === "asc") {
            direction = "desc"
        }
        else if (sortField === "select" && sortDirection === "desc") {
            direction = "asc"
        }
        else {
            direction = "asc";
        }
        this.setState(produce(draft => {
            draft.options.sortOrder = { name: "select", direction: direction };
            draft.sortSettings = { sortFrom: "select", direction: direction, clearSort: false };
        }));
    }
    clearSelectSort = () => {
        this.setState(produce(draft => {
            draft.options.sortOrder = {};
            draft.sortSettings = { sortFrom: "", direction: "", clearSort: true };
        }));
    }
    render() {
        const { classes } = this.props;
        const dashboards = this.props.getSortedObjectList(this.props.roles.dashboards, this.props.roles.role.DashboardList, "dashboardId", "Id", "Name", this.state.sortSettings);
        return (
            <div>
                {this.props.isLoading && <ActionBackdrop />}
                {this.props.roles.dashboards ? <MUIDataTable container classes={{ responsiveScrollMaxHeight: classes.responsiveScrollMaxHeight }}
                    title={''}
                    data={dashboards}
                    columns={this.state.columns}
                    options={MuiGridOptions(this.state.options, this.props.isLoading)}
                /> : ""}
           </div>
        );

    }

}
function mapStateToProps(state) {
    return {
        roles: state.roles,
        isLoading: state.loader.isLoading,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage
    };
};

//export default withRouter(connect(mapStateToProps)(DashboardList));
export default withRouter(connect(mapStateToProps)(withStyles(rolesTheme)(DashboardList)));
