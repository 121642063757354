import { ActionTypes as types, RoleTab, UserType, ActiveState } from './actionTypes';
import { showError, showSuccess, hideError, showWarning } from './alertActions';
import adminApi from 'admin/api/adminApi';
import { cloneDeep } from 'lodash';
const api =  adminApi;
/**
 * generic calls
 */
export const newRole = () => {
    let role = {
        roleId: 0,
        roleName: '',
        defaultObjectId: 0,
        defaultObjectType:0,
        UserList: [],
        ReportList: [],
        FormList: [],
        DashboardList: [],
        MenuList: [],
        helperText: ' ',
        error: false,
        activeTab: RoleTab.roleHeader
    };
    return role;
};


export const getSelectedList = (action, role) => {

    let array = [];
    role.activeTab = action.roleTab;
    if (action.roleTab === RoleTab.user || action.roleTab == RoleTab.userWithRefresh)
    {
        //for (let i = 0; i < action.value.length; i++) {
        //    array.push({ Id: action.value[i] })
        //    }
        if (action.checkedList) {

            
            for (let i = 0; i < action.checkedList.length; i++) {
                array.push({ Id: action.checkedList[i].Id, Name: action.checkedList[i].Name })
                }
            

        } else {
            if (action.data) {
                array = Object.assign([], action.data)
                const index = array.findIndex(u => u.Id === action.value)
                if (index !== -1) {
                    array.splice(index, 1)

                } else {
                    array.push({ Id: action.value, Name: action.name })

                }

            } else {
                array.push({ Id: action.value, Name: action.name })

            }
        }
        role.UserList = array

    }
    else if (action.roleTab === RoleTab.report || action.roleTab == RoleTab.reportWithRefresh) {
        
        if (action.checkedList) { 

            for (let i = 0; i < action.checkedList.length; i++) {
                array.push({ ReportId: action.checkedList[i].reportId, ReportName: action.checkedList[i].reportName })
            } 

        } else {
            if (action.data) {
                array = Object.assign([], action.data)
                const index = array.findIndex(u => u.ReportId === action.value)
                if (index !== -1) {
                    array.splice(index, 1)

                } else {
                    array.push({ ReportId: action.value ,ReportName:action.name})

                }

            } else {
                array.push({ ReportId: action.value, ReportName: action.name })

            }
        }
         
        role.ReportList = array

    }
    else if (action.roleTab === RoleTab.form || action.roleTab == RoleTab.formWithRefresh) {
       
        if (action.checkedList) {

            for (let i = 0; i < action.checkedList.length; i++) {
                array.push({ FormId: action.checkedList[i].formId, formName: action.checkedList[i].formName })
            }

        } else {
            if (action.data) {
                array = Object.assign([], action.data)
                const index = array.findIndex(u => u.FormId === action.value)
                if (index !== -1) {
                    array.splice(index, 1)

                } else {
                    array.push({ FormId: action.value, formName: action.name })

                }

            } else {
                array.push({ FormId: action.value, formName: action.name })

            }
        }
         
        role.FormList = array



    }
    else if (action.roleTab === RoleTab.dashboard || action.roleTab == RoleTab.dashboardWithRefresh) {
        
        if (action.checkedList) {

            for (let i = 0; i < action.checkedList.length; i++) {
                array.push({ Id: action.checkedList[i].Id, Name: action.checkedList[i].Name })
            }

        } else {
            if (action.data) {
                array = Object.assign([], action.data)
                const index = array.findIndex(u => u.Id === action.value)
                if (index !== -1) {
                    array.splice(index, 1)

                } else {
                    array.push({ Id: action.value, Name: action.name })

                }

            } else {
                array.push({ Id: action.value, Name: action.name })

            }
        }
         
        role.DashboardList = array

    }
    else if (action.roleTab === RoleTab.menu || action.roleTab == RoleTab.menuWithRefresh) {

       
        if (action.checkedList) {

            for (let i = 0; i < action.checkedList.length; i++) {
                array.push({ Id: action.checkedList[i].Id, Name: action.checkedList[i].name })
            }

        } else {
            if (action.data) {
                array = Object.assign([], action.data)
                const index = array.findIndex(u => u.Id === action.value)
                if (index !== -1) {
                    array.splice(index, 1)

                } else {
                    array.push({ Id: action.value, Name: action.name })

                }

            } else {
                array.push({ Id: action.value, Name: action.name })

            }
        }
        
        role.MenuList = array

    }

    else if (action.roleTab === RoleTab.roleName) {
        role.roleName = action.value
        if (checkIsEmpty(action.value)) {

            role.RoleNameHelperText = 'Role Name required';
            role.IsRoleNameError = true;
        } else {

            role.RoleNameHelperText = ' ';
            role.IsRoleNameError = false;
        }

    }

    else if (action.roleTab === RoleTab.defaultObjectId || action.roleTab === RoleTab.defaultObjectIdWithOutRefresh) {
       
       // if (action.value !== -1) {
            role.defaultObjectId = action.value
        //}
            role.LandingPageHelperText = '';
            role.IsLandingPageError = false;
        //} else {
        //    //role.LandingPageHelperText = 'Landing Page required ra 204';
        //    role.IsLandingPageError = false;
        //}
        //if (action.roleTab == RoleTab.defaultObjectIdWithOutRefresh) {
        //     role.defaultObjectId = -1;
        //}
       

    }

    else if (action.roleTab === RoleTab.defaultObjectType || action.roleTab === RoleTab.defaultObjectTypeWithOutRefresh) {
        role.defaultObjectType = action.value
         //role.defaultObjectId = -1;
    }
     
    return role;
};

const checkIsEmpty=(value)=> {
     return (!value || value.length === 0 || !value.trim()); 
     
}
const checkIsEmpty_No_Trim = (value) => {
    /*return (!value || value.length === 0 || !value.trim());*/
    return (!value || value.length === 0);
}



export const setSelectedList = (action, role) => {
    
    const data = action.role;
    role.UserList = [];
    role.ReportList = [];
    role.FormList = [];
    role.DashboardList = [];
    role.MenuList = [];
    role.roleId = data.roleId;
    role.roleName = data.roleName;
    role.defaultObjectId = data.defaultObjectId;
    role.defaultObjectType = data.defaultObjectType;

    if (data.userList.length > 0) {
        
        for (let i = 0; i < data.userList.length; i++) {
            role.UserList.push({ Id: data.userList[i].id, Name: data.userList[i].email })
        }
    }

    if (data.reportList.length > 0) {
         
        for (let i = 0; i < data.reportList.length; i++) {
            role.ReportList.push({ ReportId: data.reportList[i].reportId, ReportName: data.reportList[i].reportName })
        }
    }

    if (data.formList.length) {
        
        for (let i = 0; i < data.formList.length; i++) {
            role.FormList.push({ FormId: data.formList[i].formId, formName: data.formList[i].formName})
        }
    }

    if (data.dashboardList.length) {
        
        for (let i = 0; i < data.dashboardList.length; i++) {
            role.DashboardList.push({ Id: data.dashboardList[i].id, Name: data.dashboardList[i].name})
        }
    }

    if (data.menuList.length) {
       
        for (let i = 0; i < data.menuList.length; i++) {
            role.MenuList.push({ Id: data.menuList[i].id, Name: data.menuList[i].name})
        }
    }







    return role;
};



/**
 * Invokes the mock or real web api to get users
 */
const apiGetUsers = () => {
    return api.getAdminUsers();
};




const apiGetRole = (id) => {
    return api.getRole(id);
};

/**
 * Involes the mock or real web api to get all roles
 */
const apiGetRoles = () => {
   return api.getRoles();
};

const apiGetReport = () => {
    return api.getReports();
};

const apiGetForm = () => {
   return api.getForms();
};

const apiGetDashboard = () => {
    return api.getDashboards();
};

const apiGetMenu = () => {
    return api.getMenus();
};

const apiUpdateRole = (role) => {
  return api.updateRole(role);
};

const apiDeleteRole = (roleId) => {
    return api.deleteRole(roleId);
};

const apiHasDepend = (type, id,roleId) => {
    return api.hasObjectDependency(type, id,roleId);
}
 
/**
 * action creators
 */

/**
 * Search Request action creator
 * @param {string} searchText 
 */
export const searchRequest = ( searchText ) => {
    return {
        type: types.VGO_UNIVERSAL_SEARCH,
        searchText: searchText
    }
};

/**
 * Users request action creator
 */
export const getNewRole= (change) => {
    return {
        type: types.VGO_NEW_ROLE,
    };
}
const getUsersRequest = (status) => {
    return {
        type: types.VGO_GET_ROLES_USERS_REQUEST, status:status
    }
};
export const updateUserProperty = (user) => {
    if (!user.hasOwnProperty('userType')) {
        Object.defineProperty(user, 'userType', {
            get() {
                return this.hasAdmin ? UserType.admin : UserType.user;
            },
            set(newValue) {
                this.hasAdmin = newValue === UserType.admin;
            },
            enumerable: true,
        });
    }
    if (!user.hasOwnProperty('activeState')) {
        Object.defineProperty(user, 'activeState', {
            get() {
                return this.isActive ? ActiveState.active : ActiveState.inactive;
            },
            set(newState) {
                this.isActive = newState === ActiveState.active;
            },
            enumerable: true,
        });
    }
    return user;
};
export const updateUserProperties = (users) => {
    users.map(user => updateUserProperty(user));
    return users;
};

const getUsersSuccess = ( resp ) => {
    return {
        type: types.VGO_GET_ROLEUSER_SUCCESS,
        users: updateUserProperties(resp.data),

    };
};

const getUpdateRoleSuccess = (resp) => {
    return {
        type: types.VGO_UPDATE_ROLE_SUCCESS,
        data: resp.data,
        message:'Data saved successfully',
    };
};

const getDeleteRoleSuccess = (resp) => {
    return {
        type: types.VGO_DELETE_ROLE_SUCCESS,
        roles: resp.data,
       
    };
};

/**
 * Error action creator
 * @param {*} errorMessage 
 */
const getError = ( errorMessage ) => {
    return {
        type: types.VGO_API_CALL_ERROR,
        hasError: true,
        errorMessage,
    };
};




const getRoleRequest = (id) => {
    return {
        type: types.VGO_GET_ROLE_REQUEST,
        roleId: id
    };
};


/**
 * Get all roles request action creator
 */

const getUpdateRoleRequest = (role) => {
    return {
        type: types.VGO_UPDATE_ROLE_REQUEST,
        role: role,
    };
};

const getErrorRequest = (IsRoleError,IsLandingPageError) => {
    return {
        type: types.VGO_GET_ROLE_ERROR_REQUEST,
        roleNameError: 'Role Name required', 
        IsRoleError: IsRoleError,
        LandingPageHelperText: IsLandingPageError?'Landing Page required':"",
        IsLandingPageError: IsLandingPageError,

};
};

const getDeleteRoleRequest = (role) => {
    return {
        type: types.VGO_DELETE_ROLE_REQUEST,
        role: role,
    };
};

const getRolesRequest = () => {
    return {
        type: types.VGO_GET_ROLES_REQUEST,
    };
};

const getReportRequest = () => {
    return {
        type: types.VGO_GET_REPORT_REQUEST,
    };
};
const getFormRequest = () => {
    return {
        type: types.VGO_GET_FORM_REQUEST,
    };
};

const getDashboardRequest = () => {
    return {
        type: types.VGO_GET_FORM_REQUEST,
    };
};

const getMenuRequest = () => {
    return {
        type: types.VGO_GET_MENU_REQUEST,
    };
};

const getRoleObjectDependRequest = () => {
    return {
        type: types.VGO_GET_ROLE_OBJECT_DEPEND_REQUEST,
    };
};

/**
 * Get all roles success action creator
 * @param {Response} resp 
 */
const getRolesSuccess = ( resp ) => {
    return {
        type: types.VGO_GET_ROLES_SUCCESS,
        roles: resp.data,
    };
};

const getRoleSuccess = (resp) => {
    return {
        type: types.VGO_GET_ROLE_SUCCESS,
        role: resp.data,
    };
};

const getReportSuccess = (resp) => {
    return {
        type: types.VGO_GET_REPORT_SUCCESS,
        reports: resp.data,
    };
};

const getFormSuccess = (resp) => {
    return {
        type: types.VGO_GET_FORMS_ROLE_LIST_SUCCESS,
        forms: resp.data,
    };
};

const getDashboardSuccess = (resp) => {
    return {
        type: types.VGO_GET_DASHBOARD_ROLE_LIST_SUCCESS,
        dashboards: resp.data,
    };
};

const getMenuSuccess = (resp) => {
    return {
        type: types.VGO_GET_MENU_ROLE_LIST_SUCCESS,
        menus: resp.data,
    };
};
const getRoleObjectDependSuccess = (resp) => {
    return {
        type: types.VGO_GET_ROLE_OBJECT_DEPEND_SUCCESS,
        hasDepend: resp.data,
    };
};


/**
 * Dispatch methods
 */

/**
 * Get Users dispatch
 */


export const setChangeRoleData = (roleTab,value,data,checkedList) => {
    return {
        type: types.VGO_CHANGE_ROLE_DATA,
        roleTab: roleTab, value: value,name:"", data: data, checkedList: checkedList,         
    };
}
export const setChangeRoleDataName = (roleTab, value,name, data, checkedList) => {
    return {
        type: types.VGO_CHANGE_ROLE_DATA,
        roleTab: roleTab, value: value,name:name, data: data, checkedList: checkedList,
    };
}



export const deleteRole = (roleId) => {
    return dispatch => {
        dispatch(getDeleteRoleRequest(roleId));
        return apiDeleteRole(roleId)
            .then((resp) => {
                dispatch(getDeleteRoleSuccess(resp));
                showSuccess(dispatch, 'Success', 'Role deleted successfully.', 5000);
            })
            .catch(err => {
                let { message } = err;
                if (err.response && err.response.data) {
                    message = err.response.data;
                    if (typeof (message) !== "string") {
                        if (message.title && typeof (message.title) === "string") {
                            message = message.title;
                        }
                        else {
                            message = err.message;
                        }
                    }
                }
                dispatch(getError(message));
                showError(dispatch, message, 5000);
            });
    };
}; 
export const updateRole = (role,data) => {
    const isRoleNameEmpty = checkIsEmpty(role.roleName);
    const isLandingPageEmpty = role.defaultObjectId === -1 ? true : false;
    return dispatch => {
        if (!isRoleNameEmpty && !isLandingPageEmpty) {
            dispatch(getUpdateRoleRequest(role));
            return apiUpdateRole(role)
                .then((resp) => {
                    dispatch(getUpdateRoleSuccess(resp));

                    if (data.roles) {
                        let updatedRoles = cloneDeep(data.roles)
                        //Update role into collections
                        let roleIndex = updatedRoles.findIndex(r => r.roleId === resp.data.roleId);
                        if (roleIndex === -1) {
                            updatedRoles.push(resp.data);
                            dispatch(getRolesSuccess({ data: updatedRoles }));
                        }

                    }
                   
                    showSuccess(dispatch, 'Success', 'Role saved successfully.', 5000);
                })
                .catch(err => {
                    let { message } = err;
                    if (err.response && err.response.data) {
                        message = err.response.data;
                        if (typeof (message) !== "string") {
                            if (message.title && typeof (message.title) === "string") {
                                message = message.title;
                            }
                            else {
                                message = err.message;
                            }
                        }
                    } 
                    dispatch(getError(message));
                    showError(dispatch, message, 5000);
                });
        } else {

            dispatch(getErrorRequest(isRoleNameEmpty, isLandingPageEmpty));
            showError(dispatch, 'Please correct the indicated items', 5000);

        }
    };
};



export const getUsers = () => {
    return dispatch => {
        dispatch(getUsersRequest(true));
        return apiGetUsers()
            .then((resp) => {
                dispatch(getUsersSuccess(resp));
            })
            .catch(err => {
                dispatch(getUsersRequest(false));
                const { message } = err;
                dispatch(getError(message));

            });
    } 
};



export const getRole = (id) => {
    return dispatch => {
        dispatch(getRoleRequest(id));
        return apiGetRole(id)
            .then((resp) => {
                dispatch(getRoleSuccess(resp));
                hideError(dispatch);
            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
                showError(dispatch, message, 5000);
            });
    };
};

export const getRoles = () => {
    return dispatch => {
        dispatch( getRolesRequest() );
        return apiGetRoles()
            .then( resp => {
                dispatch(getRolesSuccess(resp));
                hideError(dispatch);
            })
            .catch( err => {
                const { message } = err;
                dispatch( getError( message ) );
            })
    };
};

export const getReports = () => {
    return dispatch => {
        dispatch(getReportRequest());
        return apiGetReport()
            .then(resp => {
                dispatch(getReportSuccess(resp));
            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
            })
    };
};
export const getForms = () => {
    return dispatch => {
        dispatch(getFormRequest());
        return apiGetForm()
            .then(resp => {
                dispatch(getFormSuccess(resp));
            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
            })
    };
};

export const getDashboards = () => {
    return dispatch => {
        dispatch(getDashboardRequest());
        return apiGetDashboard()
            .then(resp => {
                dispatch(getDashboardSuccess(resp));
            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
            })
    };
};

export const getMenus = () => {
    return dispatch => {
        dispatch(getMenuRequest());
        return apiGetMenu()
            .then(resp => {
                dispatch(getMenuSuccess(resp));
            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
            })
    };
}; 
export const hideErrorMessage = () => {
    return dispatch => {
        hideError(dispatch);
    };
}; 
export const hasRoleObjectDepend = (type,id,roleId) => {
    return dispatch => {
        dispatch(getRoleObjectDependRequest());
        return apiHasDepend(type, id, roleId)
            .then(resp => {
                dispatch(getRoleObjectDependSuccess(resp));
              
                //if (resp.data) {
                //    showWarning(dispatch,  'Deselected Object have dependency!', 5000);
                //}
                return resp.data;
            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
            })
    };
};

export const discardRole = () => {
    return {
        type: types.VGO_DISCARD_ROLE,
    };
}


