import React from 'react';
import { connect } from 'react-redux';

import { styled } from '@mui/styles';
import { withStyles } from '@mui/styles';
import { spacing } from '@mui/system';
import { Grid, FormControlLabel, Box, Button, Checkbox } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { packageTheme } from './theme/packageTheme';
import { getChangePackage, getPackageLogFile } from 'admin/actions/packageActions';
import MuiTextField from '@mui/material/TextField';
import PasswordText from 'client/components/UserProfile/Password.js';

const TextField = styled(MuiTextField)(spacing);

class PackageInformation extends React.Component {


    constructor(props) {
        super(props);
        
    }

    changePackage = (change) => {

        this.props.dispatch(getChangePackage(change));
    }
    handlePasswordChange = (password) => {
        this.changePackage(exportPackage => exportPackage.password = password)
    }
    handlePasswordBlur = (event) => {
        this.changePackage((exportPackage) => {
            const hasError = event.target.value ? false : true;
            exportPackage.passwordError = hasError
            exportPackage.passwordHelper = hasError ? 'Password is required' : ''
            return exportPackage;
        })
    }
    onDownloadClick = () => {

        this.props.dispatch(getPackageLogFile(false));
    }
    render() {

        const { classes } = this.props;
        const spacing = 0;
        return (
                            
                                <Grid container direction="row" spacing={spacing} >
                                    <Grid item xs={6} >


                                        <Box mt={.3} className={classes.subGrid}>


                                            <Grid container direction="row" spacing={4} >
                                                <Grid item xs={6} className={classes.gridPadding} >
                                <TextField error={this.props.exportPackage.nameError} helperText={this.props.exportPackage.nameHelper ? this.props.exportPackage.nameHelper : ' '} autoFocus
                                                        required
                                                        value={this.props.exportPackage.packageName}
                                                        onBlur={(event) => {
                                                            this.changePackage((exportPackage) => {
                                                                const hasError = event.target.value ? false : true;
                                                                exportPackage.nameError = hasError
                                                                exportPackage.nameHelper = hasError ? 'Name is required' : ''
                                                                return exportPackage;
                                                            })


                                                        }}
                                                        onChange={event => this.changePackage(exportPackage => exportPackage.packageName = event.target.value)}
                                                        name="packageName"
                                                        label="Name"
                                                        size="small"
                                                        variant="standard"
                                                        inputProps={{ maxLength: 100 }}
                                                    >
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={6} className={classes.gridPadding}>
                                                    <TextField error={this.props.exportPackage.versionError} helperText={this.props.exportPackage.versionHelper ? this.props.exportPackage.versionHelper : ' '}
                                                        variant="standard"
                                                        value={this.props.exportPackage.packageVersion}
                                                        onBlur={(event) => {
                                                            this.changePackage((exportPackage) => {
                                                                const hasError = event.target.value ? false : true;
                                                                exportPackage.versionError = hasError
                                                                exportPackage.versionHelper = hasError ? 'Version is required' : ''
                                                                return exportPackage;
                                                            })


                                                        }}
                                                        onChange={event => this.changePackage(exportPackage => exportPackage.packageVersion = event.target.value)}
                                                        required
                                                        name="packageVersion"
                                                        label="Version"
                                                        size="small"
                                                        inputProps={{ maxLength: 100 }}
                                                    >
                                                    </TextField>
                                                </Grid>
                                            </Grid>


                                        </Box>
                                        <Box className={classes.subGrid} mt={4}>
                                            <Grid container direction="row" spacing={0}>
                                                <Grid item xs={12}  >
                                                    <TextField error={this.props.exportPackage.descriptionError} helperText={this.props.exportPackage.descriptionHelper ? this.props.exportPackage.descriptionHelper : ' '}
                                                        variant="standard"
                                                        required
                                                        value={this.props.exportPackage.packageDescription}
                                                        onBlur={(event) => {
                                                            this.changePackage((exportPackage) => {
                                                                const hasError = event.target.value ? false : true;
                                                                exportPackage.descriptionError = hasError
                                                                exportPackage.descriptionHelper = hasError ? 'Description is required' : ''
                                                                return exportPackage;
                                                            })


                                                        }}
                                                        onChange={event => this.changePackage(exportPackage => exportPackage.packageDescription = event.target.value)}
                                                        label="Description"
                                                        size="small"
                                                        inputProps={{ maxLength: 4000 }}
                                                    >
                                                    </TextField>
                                                </Grid>

                                            </Grid>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={5}>
                                        <Box mt={.3} className={classes.subGrid}>


                                            <Grid container direction="row" spacing={0} >
                                                <Grid item xs={6} pt={2}  >
                                                    <FormControlLabel control={<Checkbox checked={this.props.exportPackage.isPasswordProtected}
                                                        onChange={event => this.changePackage(exportPackage => exportPackage.isPasswordProtected = event.target.checked)} />}
                                                        label="Password Protection" />
                                                </Grid>
                                                <Grid item xs={6} className={classes.gridPadding}>
                                                    <PasswordText
                                                        id="newPassword"
                                                        name="newPassword"
                                                        required={this.props.exportPackage.isPasswordProtected}
                                                        label={`Password ${this.props.exportPackage.isPasswordProtected ? "*" : ""}`}
                                                        handlePasswordChange={this.handlePasswordChange}
                                                        holderName={`Password ${this.props.exportPackage.isPasswordProtected ? "*" : ""}`}
                                                        value={this.props.exportPackage.password}
                                                        error={this.props.exportPackage.isPasswordProtected && this.props.exportPackage.passwordError}
                                                        helperText={(this.props.exportPackage.isPasswordProtected && this.props.exportPackage.passwordHelper) ? this.props.exportPackage.passwordHelper : ' '}
                                                        handlePasswordBlur={this.handlePasswordBlur}

                                                    />
                                                </Grid>
                                            </Grid>


                                        </Box>
                                        <Box mt={7} className={classes.subGrid}>


                                            <Grid container direction="row" spacing={0} >
                                                <Grid item xs={6} className={classes.gridPadding} sx={{marginTop:"-8px"}} >
                                                    <FormControlLabel control={<Checkbox checked={this.props.selectDependencies}
                                                        onChange={event => this.changePackage(exportPackage => exportPackage.selectDependencies = event.target.checked)} />}
                                                        label="Include Dependencies" />
                                                </Grid>
                                                <Grid item xs={6} className={classes.gridPaddingLogFile}>
                                                    < Button disabled={this.props.enableDownloadLog}
                                                        disableElevation
                                                        disableFocusRipple
                                                        disableRipple
                                                        variant="text"
                                                        onClick={this.onDownloadClick}
                                                        startIcon={<FileDownloadIcon />}>
                                                        {"Log File"}
                                                    </Button>
                                                </Grid>
                                            </Grid>


                                        </Box>
                                    </Grid>
                                </Grid>
        );

    }

}
function mapStateToProps(state) {

    return {
        exportPackage: state.packages.packageInformation,
        enableDownloadLog: !state.packages.downloadLog,
        selectDependencies: state.packages.selectDependencies,
    };
};
export default connect(mapStateToProps)(withStyles(packageTheme)(PackageInformation));