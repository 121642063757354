import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { chartTheme } from 'client/components/Charts/theme/chartTheme';
import {  Typography } from '@mui/material';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box  height={669} pb={12} px={1}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}


const useStyles = makeStyles(chartTheme);

export default function CreateTab(props) {
    
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div >
            <AppBar position="static" className={classes.tabRoot}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="scrollable auto tabs example"
                >
                    {props.details.map((detail, i) => {
                        return (
                            <Tab key={i} label={detail.name} {...a11yProps(i)} classes={{ root: classes.chartTab }} className={classes.body2}/>
                        )
                    })}
                </Tabs>
            </AppBar>
            {props.details.map((detail, i) => {
                return (
                    <TabPanel key={i} value={value} index={i}>
                        {detail.content ? detail.content : <Typography variant="subtitle1" className={classes.chartTitleReport}>{detail.name}</Typography>}
                    </TabPanel>
                )
            })}
        </div>
    );
}