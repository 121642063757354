import React from 'react';
import { SlideDown } from 'react-slidedown'
import { Link } from 'react-router-dom'
import 'react-slidedown/lib/slidedown.css'
import * as enumerator from 'client/components/Common/Enum';
import { bannerTheme } from 'client/components/Banner/theme/bannerTheme'
import { Box, Typography,Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import ClearIcon from '@mui/icons-material/Clear';
import ErrorSharpIcon from '@mui/icons-material/ErrorSharp';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
class banner extends React.Component {

    constructor(props) {
        super(props);
        this.state = { closed: true,caption:"Expand",showBanner:true };
    }
    toggleBannerState = () => {
        if (this.state.closed) {
            this.setState({ closed: false, caption: "Minimize", showBanner: true });
        } else {
            this.setState({ closed: true, caption: "Expand", showBanner: true });
        }
    }

    hideBanner = () => {
        this.setState({ closed: true, caption: "Expand", showBanner: false });
    }


     createMarkup(text) { return { __html: text }; }

    renderBanner() {
       

        if (this.props.banner.type === enumerator.BannerType.Notification) {
            return this.renderNotification();
        }
        else if (this.props.banner.type === enumerator.BannerType.Alert) {
            return this.renderAlert();
        }
        else if (this.props.banner.type === enumerator.BannerType.Promotion) {
            return this.renderPromotion();
        }
    }
    renderNotification()
    {
        const { classes } = this.props;
        let notification;
        let banner = this.props.banner;
        if (banner.detailDisplayType !== enumerator.DetailDisplayType.Expand)
        {

            notification = (
                <Box className={classes.alertNotification} >< NotificationImportantIcon className={classes.alertNotificationIcon}  />
                    
                    <Box className={classes.alertBody}>
                        <Typography className={classes.h4} >
                            <Grid container>
                                <Grid item> < header className={classes.alertHead1}><Typography  > <strong className={classes.titleH4}>Notification</strong> </Typography></header></Grid>
                                <Grid item  > {banner.title}</Grid><Grid item>
                                    {(this.props.isSiteTheme) ? <Typography  className={classes.alertNotificationLink} >Learn More</Typography> :
                                    <Link className={classes.alertNotificationLink} to={(this.props.isSiteTheme) ? "" : "/Banner/" + banner.id}>{banner.detailDisplayType === enumerator.DetailDisplayType.More ? "More" : "Learn More"}</Link>}
                            </Grid> </Grid>
                        </Typography>
                    </Box>
                    <ClearIcon onClick={this.hideBanner} className={`${classes.linkButton} ${classes.alertClose}`} fontSize="large" />
                </Box>
                            )
        }
        else
        {
            notification = (


                 <Box className={classes.alertNotification}>
                    <ErrorSharpIcon className={classes.alertNotificationIcon} />
                   
                    <Box className={classes.alertBody}>
                        <Typography className={classes.h4} >  
                            <Grid container>
                                <Grid item> <header style={{ marginRight: "16px" }}><Typography className={classes.h4} ><strong className={classes.titleH4}>Notification</strong></Typography></header></Grid>
                                <Grid item>{banner.title}</Grid>
                                <Grid item> {(this.props.isSiteTheme) ? <Typography className={classes.alertNotificationLink} >{this.state.caption}</Typography> :
                                    <button onClick={this.toggleBannerState} className={`${classes.linkButton} ${classes.alertNotificationLink}`}> {this.state.caption} </button>}
                                </Grid></Grid>
                        </Typography>
                <SlideDown closed={this.state.closed}>
                            <Box className={classes.alertBodyExpanded}>
                <p dangerouslySetInnerHTML={this.createMarkup(banner.content)}/>
                </Box>
                </SlideDown>

                </Box>
                    {(this.props.isSiteTheme) ? <ClearIcon className={classes.alertClose} fontSize="large" /> :
                      <ClearIcon onClick={this.hideBanner} className={`${classes.linkButton} ${classes.alertClose}`} fontSize="large" />}

            </Box>
                    )
        }

        return notification;
    }

    renderAlert() {
        const { classes } = this.props;
        let alert;
        let banner = this.props.banner; 
        if (banner.detailDisplayType !== enumerator.DetailDisplayType.Expand) {

            alert = (
                <Box className={classes.alert} > <ErrorSharpIcon className={classes.alertIcon}/>
                    
                    <Box className={classes.alertBody}>
                        <Typography className={classes.h4} > 
                            <Grid container>
                                <Grid item> < header className={classes.alertHead1}><Typography  > <strong className={classes.titleH4}>Alert</strong> </Typography></header></Grid>
                                <Grid item> {banner.title}</Grid><Grid item>

                                    {(this.props.isSiteTheme) ? <Typography  className={classes.alertLink} >Learn More</Typography> :
                                        <Link className={classes.alertLink} to={(this.props.isSiteTheme) ? null : "/Banner/" + banner.id}>{banner.detailDisplayType === enumerator.DetailDisplayType.More ? "More" : "Learn More"}</Link>}
                                </Grid> </Grid>
                        </Typography>
                    </Box>
                    {(this.props.isSiteTheme) ? <ClearIcon className={classes.alertClose} fontSize="large" /> :
                        <ClearIcon onClick={this.hideBanner} className={`${classes.linkButton} ${classes.alertClose}`} fontSize="large" />}
                </Box>
            )
        }
        else {
            alert = (
                <Box className={classes.alert}>
                    <ErrorSharpIcon className={classes.alertIcon} />
                    <header className={classes.alertHead}><Typography className={classes.h4} ><strong className={classes.titleH4}>Alert</strong></Typography></header>
                    <Box className={classes.alertBody}>
                        <Typography className={classes.h4} >  {banner.title}
                            <button onClick={this.toggleBannerState} className={`${classes.linkButton} ${classes.alertLink}`}> {this.state.caption} </button>
                        </Typography>
                <SlideDown closed={this.state.closed}>
                            <Box className={classes.alertBodyExpanded}>
                <p dangerouslySetInnerHTML={this.createMarkup(banner.content)}/>
                </Box>
                </SlideDown>

                </Box>
                    <ClearIcon onClick={this.hideBanner} className={`${classes.linkButton} ${classes.alertClose}`} fontSize="large" />

            </Box>
            )
        }

        return alert;
    }

    renderPromotion() {
        const { classes } = this.props;
        let promotion;
        let banner = this.props.banner;
        if (banner.detailDisplayType !== enumerator.DetailDisplayType.Expand) {

            promotion = (
                <Box className={classes.alertPromotion} ><MonetizationOnIcon className={classes.alertPromotionIcon} />

                    <Box className={classes.alertBody}>
                        <Typography className={classes.h4} >

                            <Grid container>
                                <Grid item> < header className={classes.alertHead1}><Typography  > <strong className={classes.titleH4}>Promotion</strong> </Typography></header></Grid>
                                <Grid item> {banner.titleList}</Grid><Grid item>


                                    {(this.props.isSiteTheme) ? <Typography  className={classes.alertPromotionLink} >Learn More</Typography> :
                                        <Link className={classes.alertPromotionLink} to={"/Banner/" + banner.id}>{banner.detailDisplayType === 1 ? "More" : "Learn More"}</Link>}
                                </Grid> </Grid>
                        </Typography>
                    </Box>
                    {(this.props.isSiteTheme) ? <ClearIcon className={classes.alertClose} fontSize="large" /> :
                        <ClearIcon onClick={this.hideBanner} className={`${classes.linkButton} ${classes.alertClose}`} fontSize="large" />}
                </Box>
            )
        }
        else {
             promotion = (
                 <Box className={classes.alertPromotion}>
                     <MonetizationOnIcon className={classes.alertPromotionIcon} />
                     <header className={classes.alertHead}><Typography className={classes.h4} ><strong className={classes.titleH4}>Promotion</strong></Typography></header>
                     <Box className={classes.alertBody}>
                         <Typography className={classes.h4} > {banner.title}
                             <button onClick={this.toggleBannerState} className={`${classes.linkButton} ${classes.alertPromotionLink}`} > {this.state.caption} </button>
                         </Typography>
                         <SlideDown closed={this.state.closed}  >
                             <div className={classes.alertBodyExpanded}>
                                <p dangerouslySetInnerHTML={this.createMarkup(banner.content)} />
                            </div>
                        </SlideDown> 
                    </Box>
                     <ClearIcon onClick={this.hideBanner} className={`${classes.linkButton} ${classes.alertClose}`} fontSize="large" />
                </Box>
            )
        }

        return promotion;
    }

    render() {
        return (
            
        <div>
                {this.state.showBanner && this.renderBanner()}
        </div>

            );
    }


}


export default (withStyles(bannerTheme)(banner));