import { customThemeStyles } from 'common/theme.js';
export const setupTheme = (theme) => ({
    ...customThemeStyles,
    root: {
        justifyContent: 'center!important',
        flexWrap: 'wrap!important',
        height: "100%!important",
        borderRadius: "4px!important",
    },
    card: {

        //marginLeft: theme.spacing(-3) + '!important',
        //marginRight: theme.spacing(-3) + '!important',
        /*marginTop: theme.spacing(-3) + '!important',*/
    },
    tableSectionCard: {

        marginLeft: theme.spacing(-3) + '!important',
        marginRight: theme.spacing(-3) + '!important',
        marginTop: theme.spacing(1) + '!important',
    },
    chip: {
        margin: theme.spacing(0.5) + '!important',
    },

    buttonSave: {
        width: '94px!important'
    },
    buttonCancel: {
        width: '89px!important',
        marginRight: '4px!important',
    },



    MUIDataTable: {
        overflow: 'auto!important',
        height: '544px!important',
        width: '100%!important',
        maxWidth: '1500px!important',

    },

    columnHeader: {
        fontWeight: 700,
    },

    subGrid: {
        maxWidth: '640px!important'

    },
    mainGridItem: {
        maxWidth: '696px!important'

    },


    gridPadding: {

        paddingBottom: '8px!important'
    }
    ,
    paddingEmpty: {

        paddingBottom: '0px!important',
        paddingTop: '0px!important'
    },

    enablePaging: {

        paddingTop: '0px!important'
    },

    cloneButton: {
        padding: '9px 12px 9px 12px !important',
        color: theme.palette.common.white + '!important',
    },

    alert: {
        padding: "1px 16px!important",
        position: "absolute!important",
        top: "5px!important",
        right: "5px!important",
        zIndex: '10px!important',
    },


    formIdText: {
        marginTop: '28px!important',
        textAlign: 'center',
    },

    setupTab: {
        minWidth: '156px!important',
    },


    formName: {

        paddingTop: '25px!important',
        /*paddingLeft: '29px!important',*/
        wordBreak: 'break-all'
    },
    tabRoot: {
        background: '#fff!important'
    },

    roleControl: {

        minWidth: '560px!important',
        marginLeft: '-24px',
        paddingRight:'39px'
    },
    formTypeRadioCaption: {
        paddingTop: '8px',
        paddingRight: '16px',
    },
    formAccessCheckBox: {
        marginTop: "-12px",
    },

    tableTypeRadionLabel: {
        paddingTop: '8px',
    },
    cloneGridItem: {
        textAlign: "right", paddingTop: "8px"
    },
    formTableGridColumnHeader: {
        borderBottom: "none !important" 
    },

    formTableReadOnlyColumn: {
        fontWeight: "400!important",
        fontSize: "16px",
        fontStyle: "normal!important",
        letterSpacing: "0.5px!important",
        lineHeight: "1.4375em",
        color: "rgba(0, 0, 0, 0.38)",
        boxSizing: "border - box",
        width: "100 %",
    },
    formTableControlTypeColumn: { minWidth: "150px", maxWidth: "150px", borderBottom: "none !important" },
    formTablePrecisionColumn: { minWidth: "50px", maxWidth: "50px", borderBottom: "none  !important" },
    formTableSizeColumn: { minWidth: "80px", maxWidth: "80px", borderBottom: "none  !important" },
    formTableDataTypeColumn: { minWidth: "100px", maxWidth: "100px", borderBottom: "none  !important" },

    tableGridRightMargin: { marginRight: '0px !important' },
    editHeader: {
        width: "199px"


    },
    setupSummary: {

        minHeight: "0px!important",
        marginTop: '5px!important',
        '&$expanded': {
            minHeight: "0px!important",
            marginTop: '0px!important',
        }
    },





});
