import React from 'react';

import { Button, Grid, Typography } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { dashboardTheme } from './theme/dashboardTheme';

class DashboardListHeader extends React.Component {

    handleClick = () => {
        this.props.history.push("/Admin/Dashboards/new");

    }

    render() {
        const { classes } = this.props;
        return (

            <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                    <Typography variant="h4">Dashboards</Typography>
                </Grid>
                <Grid item>

                    <Button className={classes.addButton}
                        variant="contained"
                        color="primary"
                        disableElevation
                        startIcon={<AddIcon />}
                        onClick={this.handleClick}
                    >ADD DASHBOARD</Button>

                </Grid>
            </Grid>

        );
    }

}
function mapStateToProps(state) {
    return {
        alert: state.alert,
    };
};

export default withRouter(connect(mapStateToProps)(withStyles(dashboardTheme)(DashboardListHeader)));
