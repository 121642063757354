import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import { getForms, setChangeRoleData, setChangeRoleDataName } from 'admin/actions/roleActions';
import { RoleTab } from 'admin/actions/actionTypes';
import { theme } from 'common/theme.js';
import { rolesTheme } from './theme/rolesTheme.js';
import { produce } from 'immer';
import { DateTime } from 'luxon';
import GridCheckBox from './GridCheckBox';
import GridCheckBoxHeader from './GridCheckBoxHeader';
import ActionBackdrop from 'admin/components/section/settings/Backdrop'; 
import { withStyles } from '@mui/styles'; 
import { MuiGridOptions } from 'admin/components/Common/CommonOptions';
import { objectListFormatDate } from 'admin/components/Common//DateFormat';
const defaultColumnOptions = {
    filter: true,
    sort: true,
   setCellProps: ( cellValue, rowIndex, columnIndex ) => {
        return {
            style: {
                padding: theme.spacing(1) 
            }
        };
    }
};

class FormList extends React.Component {

    constructor(props) {
        super(props);
        const options = {
            filterType: "textField",
            fixedHeader: true,
            sort: true,
            search: true,
            selectableRows: "none",
            responsive: 'scrollMaxHeight',
            download: false,
            elevation: 0,
            filter: true,
            filterType: 'multiselect',
            jumpToPage: true,
            searchOpen: false,
            pagination: false,
            onColumnSortChange: (changedColumn, direction) => {
                this.setState(produce(draft => {
                    draft.options.sortOrder = { name: changedColumn, direction: direction };
                    draft.sortSettings = { sortFrom: changedColumn, direction: direction, clearSort: false };
                }));
            },
            print: false,
            searchPlaceholder: 'Enter search text ...',
            sortOrder: {
                name: 'formName',
                direction: 'asc'
            }, searchOpen: true,
        }
        this.state = {
            columns: [
                {
                    name: "activeState",
                    label: "Active",
                    options: {
                        customHeadRender: (columnMeta, updateDirection) => (
                            <GridCheckBoxHeader
                                selectAll={props.classes.selectAll}
                                selectCheckbox={this.props.classes.selectCheckbox}
                                checked={this.props.roles.forms?.length === this.props.roles.role.FormList?.length ? true : false}
                                handleChange={this.handleChange}
                                handleSelectCheckBoxSort={this.handleSelectCheckBoxSort}
                                sortSettings={this.state.sortSettings}
                                clearSort={this.clearSelectSort}
                                selectedObjectKey={"FormList"}
                            />
                        ),
                        filter: false,
                        customBodyRender: this.checkBoxTemplate,
                    }
                },
                {
                    name: "formId",
                    label: "formId",
                    options: {
                        filter: false,
                        display: false,
                        viewColumns: false,}
                },
                {
                    name: "formName",
                    label: "Name",
                    options: { defaultColumnOptions }
                },
                {
                    name: "layoutType",
                    label: "Type",
                    options: {
                       defaultColumnOptions
                    }
                },
                {
                    name: "category",
                    label: "Category",
                    options: { defaultColumnOptions }
                }
                ,

                {
                    name: "createDate",
                    label: "Created",
                    options: {
                        defaultColumnOptions,
                        customBodyRender: objectListFormatDate
                    }
                }
            ],
            options: options,
            sortSettings: { sortFrom: "", direction: "", clearSort: false }
           
        };
    }

    componentDidMount() {
        this.dispatchGetForms();
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.roleData !== undefined) {
            this.getSelectedRow(this.props)
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.sortSettings.sortFrom !== this.state.sortSettings.sortFrom && !nextState.sortSettings.clearSort) {
            return true;
        }
        if (nextState.sortSettings.sortFrom === this.state.sortSettings.sortFrom && nextState.sortSettings.direction !== this.state.sortSettings.direction && !nextState.sortSettings.clearSort) {
            return true;
        }
            if (nextProps.roles.role.activeTab === RoleTab.form || nextProps.roles.role.activeTab === RoleTab.roleName || nextProps.roles.role.activeTab === RoleTab.defaultObjectId || nextProps.roles.role.activeTab === RoleTab.defaultObjectTypeWithOutRefresh) {
                if (this.props.roles.forms !== undefined && this.props.roles.forms.length>0) {
                    return false;
                } else { return true; }
            }
            return true;
        } 
    dispatchGetForms = () => {
        /*if (this.props.roles.forms === undefined) {*/
            this.props.dispatch(getForms());
       /* }*/
    }
    formatDate = (value) => {
        if (typeof (value) === "string") {
            value = DateTime.fromISO(value);
        }
        return value.toFormat('LL-dd-yyyy hh:mm:ss a');

    }
    handleChange = (e) => {

        let array = [];
        if (e.target.checked) {

            for (let i = 0; i < this.props.roles.forms.length; i++) {
                var formObject = this.props.roles.forms[i];
                array.push({ formId: formObject.formId, formName: formObject.formName })
            }
        }

        this.props.dispatch(setChangeRoleData(RoleTab.formWithRefresh, '', this.props.roles.role.FormList, array));


    }
    handleSelect = (formId, formName, checked, anchor) => {
            this.props.dispatch(setChangeRoleDataName(RoleTab.form, formId, formName, this.props.roles.role.FormList)); 
    }
    checkBoxTemplate = (value, tableMeta, updateValue) => {

        let status = false;
        let rId = tableMeta.rowData[1];
        let rName = tableMeta.rowData[2];
        let formIndex = this.props.roles.role.FormList.findIndex(c => c.FormId === rId || c.formId === rId)

        if (formIndex !== -1) {
            status = true;
        }
        return (
            <GridCheckBox selectCheckboxclasses={this.props.classes.selectCheckbox ? this.props.classes.selectCheckbox : ""} checked={status} id={rId} name={rName} depended={{ type: "form", id: rId, roleId: this.props.roles.role.roleId }}
                handleSelect={this.handleSelect} />
        )
    }
    getSelectedRow = (props) => {
        let array = [];
        if (props.roles !== undefined) {
            if (props.roles.role.FormList !== undefined && props.roles.forms!== undefined) {
                props.roles.role.FormList.map((form) => {
                    return props.roles.forms.map((role, index) => {
                        if (role.formId === form.FormId) {
                            array.push(index)
                        }
                        return "";
                    });

                });
            }
        } 
        return array 
    }
    handleSelectCheckBoxSort = () => {
        const sortDirection = this.state.sortSettings.direction
        const sortField = this.state.sortSettings.sortFrom
        let direction = "";
        if (sortField === "select" && sortDirection === "asc") {
            direction = "desc"
        }
        else if (sortField === "select" && sortDirection === "desc") {
            direction = "asc"
        }
        else {
            direction = "asc";
        }
        this.setState(produce(draft => {
            draft.options.sortOrder = { name: "select", direction: direction };
            draft.sortSettings = { sortFrom: "select", direction: direction, clearSort: false };
        }));
    }
    clearSelectSort = () => {
        this.setState(produce(draft => {
            draft.options.sortOrder = {};
            draft.sortSettings = { sortFrom: "", direction: "", clearSort: true };
        }));
    }
    render() {
        const { classes } = this.props;
        const forms = this.props.getSortedObjectList(this.props.roles.forms, this.props.roles.role.FormList, "formId", "FormId", "formName", this.state.sortSettings);
        return (
            <div>
                {this.props.isLoading && <ActionBackdrop />}
                {this.props.roles.forms ? <MUIDataTable container classes={{ responsiveScrollMaxHeight: classes.responsiveScrollMaxHeight }}
                    title={''}
                    data={forms}
                    columns={this.state.columns}
                    options={MuiGridOptions(this.state.options, this.props.isLoading)}
                /> : ""}
           </div>
        );

    }

}
function mapStateToProps(state) {
    return {
        roles: state.roles,
        isLoading: state.loader.isLoading,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage
    };
};


export default withRouter(connect(mapStateToProps)(withStyles(rolesTheme)(FormList)));
