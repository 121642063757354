import { ActionTypes as types } from './actionTypes';
import { UserType, ActiveState } from './actionTypes';
import adminApi from 'admin/api/adminApi';
import mockApi from 'admin/api/mockApi';
import { showError, showSuccess, hideError } from './alertActions';
import { getError } from './errorActions';
import { userValidationSchema } from 'admin/components/section/users/UserValidationSchema';
import { validateObject, validateObjectAt } from 'admin/actions/validationAction';
import settings from 'settings.js';
import { DateTime } from 'luxon';
const message = 'Please correct the indicated items'
/**
 * reads mock data setting from settings
 */
const useMocks = settings.useMocks;

/**
 * api in use, mock or real
 */
const api = useMocks ? mockApi : adminApi;


const apiGetAdminUsers = () => {
    return api.getAdminUsers();
};

/**
 * generic calls
 */

/**
 * Invokes the mock or real web api to get users
 */
const apiGetUsers = () => {
    return api.getUsers();
};

const apiGetFilter = (id) => {
    return api.userGetFilter(id);
};

/**
 * Invokes the mock or real web api to update a user
 * @param {User} user 
 */
const apiUpdateUser = ( user ) => {
    return api.updateUser( user );
};
const apiUpdateUserLite = (user) => {
    return api.updateUserLite(user);
};

/**
 * Invokes the mock or real web api to get a user by id
 * @param {int} id 
 */
const apiGetUser = ( id, onSuccess ) => {
    return api.getUser( id );
};

/**
 * Invokes the mock or real web api to get all roles
 */
const apiGetRoles = () => {
    return api.getRoles();
};

const apiGetFilters = () => {
    return api.getFilters();
};

const apiGetCountry= () => {
    return api.getCountryList();
} 
 
/**
 * action creators
 */

/**
 * Users request action creator
 */
const getUsersRequest = () => {
    console.log( 'getUsersRequest' );
    return {
        type: types.VGO_GET_USERS_REQUEST,        
    }
};

/**
 * Users success action creator
 * @param {*} resp 
 */
const getUsersSuccess = ( resp ) => {
    console.log( 'getUsersSuccess', resp );
    return {
        type: types.VGO_GET_USERS_SUCCESS,
        users: updateUserProperties( resp.data ),
    };
};

/**
 * Update User request action creator
 * @param {User} user 
 */
const getUpdateUserRequest = (user) => {
    return {
        type: types.VGO_UPDATE_USER_REQUEST,
        user: user,
    };
};

/**
 * Update User success action creator
 * @param {Response} resp 
 */
const getUpdateUserSuccess = ( resp ) => {
    return {
        type: types.VGO_UPDATE_USER_SUCCESS,    
        user: updateUserProperty( resp.data ),
    };
};

/**
 * User request action creator
 * @param {int} id 
 */
const getUserRequest = ( id ) => {
    return {
        type: types.VGO_GET_USER_REQUEST,
        userId: id,
    };
};

const getFiltersRequest = (id) => {
    return {
        type: types.VGO_GET_USER_FILTERS_REQUEST,
        userId: id,
    };
};

/**
 * User success action creator
 * @param {Response} resp 
 */
const getUserSuccess = ( resp ) => {
    return {
        type: types.VGO_GET_USER_SUCCESS,
        user: updateUserProperty( resp.data ),
    };
};

const getUserDefaultRoleSuccess = (user) => {
    return {
        type: types.VGO_GET_USER_SUCCESS,
        user
};
};

/**
 * Get all roles request action creator
 */
const getRolesRequest = () => {
    return {
        type: types.VGO_GET_ROLES_REQUEST,
    };
};

const getFilterRequest = (dataIndex) => {
    return {
        type: types.VGO_GET_USER_FILTER_REQUEST,
        dataIndex: dataIndex
    };
};

/**
 *  get all country List
 */
const getCountryRequest = () => {
    return {
        type: types.VGO_GET_COUNTRY_REQUEST,
    };
};


/**
 * Get all roles success action creator
 * @param {Response} resp 
 */
const getRolesSuccess = ( resp ) => {
    return {
        type: types.VGO_GET_ROLES_SUCCESS,
        roles: resp.data,
    };
};

const getFiltersSuccess = (resp) => {
    return {
        type: types.VGO_GET_USER_FILTERS_SUCCESS,
        filters: resp.data,
    };
};

const getFilterSuccess = (resp) => {
    return {
        type: types.VGO_GET_USER_FILTER_SUCCESS,
        filter: resp.data, 
    };
};

export const getChangeUser = ( change ) => {
    return {
        type: types.VGO_CHANGE_USER,
        change,
    };
};



const setValidateFieldsOnBlur = (errors) => {
    return {
        type: types.VGO_VALIDATE_USER_ON_BLUR,
        errors: errors,
    };
};

export const validateFieldsOnBlur = (value, fieldPath) => {
    return dispatch => {
        validateObjectAt(userValidationSchema, value, fieldPath, fieldPath)
            .then(resp => {

                dispatch(setValidateFieldsOnBlur(resp));
            })

    }
}





export const getNewUser = () => {
    return {
        type: types.VGO_NEW_USER,
    };
};
/**
 * Get country data success
 * @param {Response} resp
 */
const getCountrySuccess = (resp) => {
    return {
        type: types.VGO_GET_COUNTRY_SUCCESS,
        countryList: resp.data,
    };
}; 

/**
 * Get Users dispatch
 */
export const getUsers = () => {
    return dispatch => {
        dispatch(getUsersRequest());
        return apiGetUsers()
            .then((resp) => {
                hideError(dispatch);
                dispatch(getUsersSuccess(resp));
               
            })
            .catch(err => {
                const { message } = err;
                dispatch( getError( message ) );
                showError( dispatch, message, 5000 );
            });
    }
}; 
export const getAdminUsers = () => {
    return dispatch => {
        dispatch(getUsersRequest());
        return apiGetAdminUsers()
            .then((resp) => {
                hideError(dispatch);
                dispatch(getUsersSuccess(resp));

            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
                showError(dispatch, message, 5000);
            });
    }
};


/**
 * Validate profile Name
 * @param {filter} filter
 */

const validateFilter = (filter) => {
    let filterData = Object.assign([], filter);
    for (var i = filterData.length - 1; i >= 0; i--) {

        if (filterData[i].profileName === '' || filterData[i].value === '') {

            filterData.splice(i, 1);
        }
    }
    return filterData
}

/**
 * Update User dispatch
 * @param {User} user 
 */
export const updateUser = (user, filter) => {
    let userData = Object.assign({}, user); 
    filter = validateFilter(filter)
    if (filter) {
        userData.filter = filter
    }
    
    return dispatch => {

        validateObject(userValidationSchema, user)
            .then(resp => {
                if (resp !== true) {
                    
                    dispatch(setValidateFieldsOnBlur(resp));
                    dispatch(getError(message));
                    showError(dispatch, message, 5000);
                } else {

                    dispatch(getUpdateUserRequest(userData));
                    return apiUpdateUser(userData)
                        .then((resp) => {
                            dispatch(getUpdateUserSuccess(resp));
                            showSuccess(dispatch, 'Success', 'User saved!', 5000);
                        })
                        .catch(err => {
                            let { message } = err;
                            if (err.response && err.response.data) {
                                message = err.response.data;
                                if (typeof (message) !== "string") {
                                    if (message.title && typeof (message.title) === "string") {
                                        message = message.title;
                                    }
                                    else {
                                        message = err.message;
                                    }
                                }
                            }
                            dispatch(getError(message));
                            showError(dispatch, message, 10000);
                        });


                }
            });
    };
};

export const updateUserLite = (user) => {
    return dispatch => {
        dispatch(getUpdateUserRequest(user));
        return apiUpdateUserLite(user)
            .then((resp) => {
                dispatch(getUpdateUserSuccess(resp));
                showSuccess(dispatch, 'Success', 'User saved!', 5000);
            })
            .catch(err => {
                let { message } = err;
                if (err.response && err.response.data) {
                    message = err.response.data;
                    if (typeof (message) !== "string") {
                        if (message.title && typeof (message.title) === "string") {
                            message = message.title;
                        }
                        else {
                            message = err.message;
                        }
                    }
                }
                dispatch(getError(message));
                showError(dispatch, message, 10000);
            });
    };

}

/**
 * Get User dispatch
 * @param {int} id 
 */
export const getUser = ( id, onSuccess ) => {
    console.log( 'getUser', id );
    return dispatch => {
        dispatch( getUserRequest( id ) );
        return apiGetUser( id )
            .then((resp) => {
                hideError(dispatch);
                dispatch( getUserSuccess( resp ) );
                if ( onSuccess ) {
                    onSuccess();
                }
                
            })
            .catch( err => {
                const { message } = err;
                dispatch( getError( message ) );
            });
    };
};

export const getRoles = () => {
    console.log( 'getRoles' );
    return dispatch => {
        dispatch( getRolesRequest() );
        return apiGetRoles()
            .then( resp => {
                dispatch( getRolesSuccess( resp ) );
            })
            .catch( err => {
                const { message } = err;
                dispatch( getError( message ) );
            })
    };
};

export const getFilters = () => {
    
    return dispatch => {
        dispatch(getFiltersRequest());
        return apiGetFilters()
            .then(resp => {
                dispatch(getFiltersSuccess(resp));
            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
            })
    };
};

export const getFilter = (id, dataIndex) => {
   return dispatch => {
       dispatch(getFilterRequest(dataIndex));
       return apiGetFilter(id)
            .then((resp) => {
                dispatch(getFilterSuccess(resp));
            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
            });
    };
};
 

export const updateFilter = (profileData, data, rowIndex) => {
    return dispatch => {
        dispatch(updateFilterRequest(profileData, data, rowIndex));
                    
    };
};
const updateFilterRequest = (profileData, value, rowIndex) => {
    return {
        type: types.VGO_GET_USER_FILTER_UPDATE_SUCCESS,
        profileData: profileData,
        data: value,
        index: rowIndex
    };
};


export const getCountryList = () => {
    return dispatch => {
        dispatch(getCountryRequest());
        return apiGetCountry()
            .then((resp) => {
                dispatch(getCountrySuccess(resp));
            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
            });
    };
};

/**
 * User property updaters
 */

/**
 * Add isAdmin and isActive properties to a User
 * @param {User} user 
 */
export const updateUserProperty = ( user ) => {
    if ( !user.hasOwnProperty( 'userType') ) {
        Object.defineProperty( user, 'userType', {
            get() {
                return this.hasAdmin ? UserType.admin : UserType.user;
            },
            set( newValue ) {
                this.hasAdmin = newValue === UserType.admin;
            },
            enumerable: true,
        });
    }
    if ( !user.hasOwnProperty( 'activeState') ) {
        Object.defineProperty( user, 'activeState', {
            get() {
                return this.isActive ? ActiveState.active : ActiveState.inactive;
            },
            set( newState ) {
                this.isActive = newState === ActiveState.active;
            },
            enumerable: true,
        });
    }
    return user;
};

/**
 * Add isAdmin and isActive properties to an array of User
 * @param {User[]} users 
 */
export const updateUserProperties = ( users ) => {
    users.map( user => updateUserProperty( user ) );
    return users;
};

export const newUser = () => {
    let user = {
        userId: 0,
        email: '',
        firstName: '',
        lastName: '',
        companyName: '',
        createDateTime: DateTime.local(),
        lastUpdateDateTime: DateTime.local(),
        hasAdmin: false,
        isActive: true,
        roles: [],
        phone: '',
        fax: '',
        extension: 0,
        address1: '', 
        address2: '',
        city: '',
        state: '',
        zip: 0,
        country: '',
        profilePicUrl: '',
        filter:[],
        password: '',
        password2: '',
    };
    return user;
};

export const newFilter = () => {
    let array=[]
    let filter = {
        profileId:0,
        profileName: '',
        value: ''

    };
    array.push(filter)
    return array;
};


export const updateFilterValue = (action) => {
     
    let profileData = Object.assign([], action.profileData);
    if (action.data) {
        profileData[action.index] = action.data
    } else if (action.index !== '') {
        profileData.splice(action.index,1)
    }
    else
    {
    let filter = {
            profileId: 0,
            profileName: '',
            value: ''
          

    };
        profileData.unshift(filter)
    }

    return profileData;
};

export const sortRoles = (roles) => roles.sort((a, b) => a.roleName.localeCompare(b.roleName));
 
