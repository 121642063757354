import React from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import MuiTextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { spacing } from '@mui/system';
import { styled } from '@mui/styles';
import { ActionTypes as actionTypes } from 'admin/actions/actionTypes';
const TextField = styled(MuiTextField)(spacing);
const filter = createFilterOptions();

class Category extends React.Component {



    render() {

        return (
            <Autocomplete
                {...this.props.input}
                id="size-small-outlined-multi"
                size="small"
                fullWidth={true}
                disabled={this.props.disabled ? this.props.disabled:false}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    if (params.inputValue !== '') {
                        filtered.push({
                            inputValue: params.inputValue,
                            categoryName: `Add "${params.inputValue}"`,
                        });
                    }

                    return filtered;
                }}
                loading={this.props.requestStatus === actionTypes.VGO_GET_CATEGORIES_REQUEST}
                options={this.props.categories}
                getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                        return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    // Regular option
                    return option.categoryName;
                }}
                value={this.props.dataView.category}
             
                renderOption={(props, option) => <li {...props}>{option.categoryName}</li>}
                onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                        this.props.changeDataView(event, this.props.input, { categoryId: 0, categoryName: newValue })
                    } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        this.props.changeDataView(event, this.props.input, { categoryId: 0, categoryName: newValue.inputValue })
                    } else {
                        this.props.changeDataView(event, this.props.input, !newValue ? { categoryId: 0, categoryName: "" } : newValue)
                    }
                }}

                onBlur={(event) => {
                   this.props.onBlurValidation('category',this.props.dataView.category)

                }}
                renderInput={params => (
                    <TextField {...params} name="Category"
                        required
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    { this.props.requestStatus === actionTypes.VGO_GET_CATEGORIES_REQUEST && <CircularProgress color="inherit" size={20} />}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                        error={this.props.errors.category}
                        helperText={this.props.errors.category ? this.props.errors.category : ' '}
                        size="small"
                        label="Category"
                        placeholder="Category" />
                )}
               
            />
            )

    }

}
export default Category;