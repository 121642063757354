import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'client/store/Objects';
import Cookies from 'universal-cookie';
import { Loading } from 'client/components/Loading';
import * as enumerator from 'client/components/Common/Enum';
import Nav from 'admin/components/aside/Nav.js';
import { getDeviceType } from 'client/components/Common/Utility'
import { Box } from '@mui/material';
import { withStyles } from '@mui/styles';
import { leftPanelTheme } from 'client/components/LeftPanel/theme/leftPanelTheme'
const menuType = enumerator.MenuType;
const deviceType = getDeviceType();
const cookies = new Cookies();
const receiveObjects = 'RECEIVE_OBJECTS';
class NavMenu extends React.Component {

    componentDidMount() {
       const versagoCookie = cookies.get('Versago');
        if (versagoCookie === undefined) {
            if (this.props.actionStatus !== receiveObjects) {
                this.props.requestPublicObjects();
                this.props.requestPublicBanners();
                this.props.requestPublicNews();
            }
            
        }
        else {
          
            const roleCookie = parseInt(cookies.get('roleId'));
            const propsRoleId = parseInt(this.props.propsRoleId);
            if (propsRoleId !== roleCookie) {
                this.props.resetLayout(roleCookie);
            }
                      
        }
    }
    componentDidUpdate(nextProps) {
        if (this.props.propsRoleId !== nextProps.propsRoleId) {
            const versagoCookie = cookies.get('Versago');
            if (versagoCookie === undefined ) {
              
                this.props.requestPublicObjects();
                this.props.requestPublicBanners();
                this.props.requestPublicNews();
            } else {
              
                this.props.requestObjects(this.props.propsRoleId);
                this.props.requestBanners(this.props.propsRoleId);
                this.props.requestNews(this.props.propsRoleId);
            }
        }
    }

    getRoute = (prefix,item) => {
        const dataView = "Data View";
        const type = (item.type === dataView) ? menuType.Report : item.type.toLowerCase();
        const id = item.id;
       return {
           key: `${prefix}_${id}_${type}`,
           route: type === menuType.Calendar ? "/Calendar/" + id
               : (type === menuType.Report || type === menuType.RecordProcessing || type === menuType.CrystalReport) ? "/Report/" + id
                   : type === menuType.Dashboard  ? "/Dashboard/" + id
                       : "/Form/" + id,
       }
    }
    getMenuData = () => {
        
        const actions = this.props.actions;
        const favorites = this.props.favorites;
        const objects = this.props.menu;
        
        
        let navData = [];
        const actionData = actions.sort((a, b) => a.displayOrder - b.displayOrder).map( (action)=> {

            
            const routeObject = this.getRoute('action', action);
            return (
                {
                    key: routeObject.key,
                    icon: action.icon,
                    label: action.title,
                    route:routeObject.route,
                    quickLink: true
                }

            );

        });

        const favoritesData = favorites.map( (favorite)=> {
           
            const routeObject = this.getRoute('favorite', favorite);
            return (
                {
                    key: routeObject.key,
                    icon: favorite.icon,
                    label: favorite.name,
                    route: routeObject.route,
                    
                }

            );

        });

        
        const menuObjectData = objects.map( (object, index)=> {
            if (object.isMenu === false) {
              
                return ({
                    key: `rootMenu${index}`,
                    label: object.label,
                    icon: "icon-folder",
                    items: object.items.map((subMenu,subIndex) => {
                        if (!subMenu.isMenu) {

                            return (
                                {
                                    key: `subMenu${subIndex}`,
                                    label: subMenu.label,
                                    icon: "icon-folder",
                                    items: subMenu.items.map((innerMenu, innerIndex) => {
                                        if (!innerMenu.isMenu) {
                                            return({
                                                key: `innerMenu${innerIndex}`,
                                                label: innerMenu.label,
                                                icon: "icon-folder",
                                                items: innerMenu.items.map((innerMenuItems) => {
                                                    const routeObject = this.getRoute('menu', innerMenuItems);
                                                    
                                                    return (
                                                        {
                                                            key: routeObject.key,
                                                            icon: innerMenuItems.icon,
                                                            label: innerMenuItems.title,
                                                            route: routeObject.route,

                                                        }
                                                    );

                                                })
                                            });
                                        }
                                        else {
                                            
                                            const routeObject = this.getRoute('menu', innerMenu);
                                            return (
                                                {
                                                    key: routeObject.key,
                                                    icon: innerMenu.icon,
                                                    label: innerMenu.title,
                                                    route: routeObject.route,

                                                }
                                            );
                                        }
                                    })

                        });
                        }
                        else {
                            
                            const routeObject = this.getRoute('menu', subMenu);
                            return (
                                {
                                    key: routeObject.key,
                                    icon: subMenu.icon,
                                    label: subMenu.title,
                                    route: routeObject.route,

                                }

                            );
                        }
                    })
            });
        }
            else {
                
                const routeObject = this.getRoute('menu', object);
                    return (
                        {
                            key: routeObject.key,
                            icon: object.icon,
                            label: object.title,
                            route: routeObject.route,

                        }

                    );
        }
        });


      
        

        if (actionData.length > 0) {
            navData.push(...actionData)
            navData.push({ key: "divider", });
        }
        if (favoritesData.length > 0) {
            navData.push({ key: "favorites", label: "Favorites", icon: "icon-star", items: [...favoritesData] });
        }
        navData.push(...menuObjectData);
        return navData;
    }
    resetMargin = (collapsed) => {
        if (deviceType === enumerator.DeviceType.Mobile) {
           
            this.props.setLeftMenuState(collapsed);
        }
        this.props.resetMargin(collapsed);
    }
    render() {
        const navData = this.getMenuData();
        return (<Box className={`${this.props.classes.leftPanelColor} ${this.props.classes.menuPadding}`} >
            <Loading loading={this.props.isLoading} />
            <Nav navData={navData} resetMargin={this.resetMargin}></Nav>
        </Box>);
    }
}
 
export default connect(
    state => state.objects,
    dispatch =>   bindActionCreators(actionCreators, dispatch)
)(withStyles(leftPanelTheme)(NavMenu));

NavMenu.defaultProps = {
   actions:[],
   favorites:[],
    menu:[]
};
