import { ActionTypes as types } from 'admin/actions/actionTypes';
import { newRole, getSelectedList, setSelectedList } from 'admin/actions/roleActions';
import { produce } from 'immer';
const initialState = {
    roles: [],
    role: newRole(),
    getRoleListRequestStatus: "",
    getRoleRequestStatus: "",
    forms: [],
    isLoading:false,
    hasDepended:false,
};



const roles = produce((draft, action) => {
    switch (action.type) {
        case types.VGO_GET_ROLES_USERS_REQUEST:
           
            draft.userLoading = true;
            break;
        case types.VGO_GET_ROLEUSER_SUCCESS:
            draft.users = action.users;
            draft.userLoading = false;
            break;
        case types.VGO_GET_ROLES_REQUEST:
            draft.getRoleListRequestStatus = types.VGO_GET_ROLES_REQUEST;
            draft.isLoading = true;
            break;
        case types.VGO_GET_ROLES_SUCCESS:
            draft.roles = action.roles;
            draft.getRoleListRequestStatus = types.VGO_GET_ROLES_SUCCESS;
            draft.isLoading = false;
            break;
        case types.VGO_GET_ROLE_REQUEST:
            draft.getRoleRequestStatus = types.VGO_GET_ROLE_REQUEST;
            draft.isLoading = true;
            break;
        case types.VGO_GET_ROLE_SUCCESS:
            draft.isLoading = false;
            draft.rolesData = action.role;
            draft.role = setSelectedList(action, draft.role);
            draft.showMessage = false;
            draft.getRoleRequestStatus = types.VGO_GET_ROLE_SUCCESS;
           
            break;
        case types.VGO_GET_REPORT_SUCCESS:
            draft.reports = action.reports;
            break;
        case types.VGO_GET_FORMS_ROLE_LIST_SUCCESS:
            draft.forms = action.forms
            break;
        case types.VGO_GET_DASHBOARD_ROLE_LIST_SUCCESS:
            draft.dashboards = action.dashboards
            break;
        case types.VGO_GET_MENU_ROLE_LIST_SUCCESS:
            draft.menus = action.menus
            break;
        case types.VGO_GET_FORM_SUCCESS:
            draft.forms = action.forms
            break;
        case types.VGO_GET_DASHBOARD_SUCCESS:
            draft.dashboards = action.dashboards
            break;
        case types.VGO_GET_MENU_SUCCESS:
            draft.menus = action.menus
            break;
    case types.VGO_NEW_ROLE:
            draft.role = newRole();
            draft.rolesData = []
            break;
        case types.VGO_CHANGE_ROLE_DATA: 
            draft.role = getSelectedList(action, draft.role);
            break;
        case types.VGO_UPDATE_ROLE_SUCCESS:
           
            draft.isUpdate = true;
            draft.role.roleId = action.data.roleId;
            draft.role.defaultObjectId = action.data.defaultObjectId;
            draft.role.defaultObjectType = action.data.defaultObjectType;

             break;
        case types.VGO_API_CALL_ERROR:
            break;
        case types.VGO_DELETE_ROLE_SUCCESS:
            draft.roles = action.roles;
            break;
       
    case types.VGO_DISCARD_ROLE:
           draft.role = newRole();
            draft.rolesData = []
            break;

        case types.VGO_GET_ROLE_ERROR_REQUEST:
            draft.role.RoleNameHelperText = action.roleNameError;
            draft.role.IsRoleNameError = action.IsRoleError;
            draft.role.LandingPageHelperText = action.LandingPageHelperText;
            draft.role.IsLandingPageError = action.IsLandingPageError;
            break;
        //case types.VGO_GET_ROLES_USERS_REQUEST:
        //    draft.userLoading = action.status
        //   break;
        case types.VGO_GET_ROLE_OBJECT_DEPEND_SUCCESS:
            draft.hasDepended = action.hasDepend
            break;
            default:
            break;
    }
}, initialState );

export default roles;
