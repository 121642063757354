import React from 'react';
import { withStyles } from '@mui/styles';


import { errorActionCreator } from 'client/store/Error';
import { dataViewActionCreator } from 'client/store/DataView';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Box, Grid,  } from '@mui/material'; 
import { alertHandlerAction } from 'client/store/AlertHandler';
import PDFViewer from './PDFViewer'
import LayoutObjectHeader from 'client/components/Common/LayoutObjectHeader';
import { dataListTheme } from './theme/dataListTheme';


class CrystalReportViewerActionLink extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reportId: this.props.match.params.id,
            actionId: this.props.match.params.actionid,
            reportName:""
        }
        this.rootRef = React.createRef();
    } 
    componentDidMount() {
        this.getReportDefinition(this.props.match.params.id, this.props.match.params.actionid)
    }
    getActionDetails = async (actionId) => {
    }
    getReportDefinition = async (reportId, actionId) => {
        let url = `api/ReportDefinition/GetReportDefinition/${reportId}/${actionId}`;
        this.rootRef.current.scrollIntoView();
        const response = await fetch(url);
        if (response.status === 200) {
            const reportDefinition = await response.json(); 
            this.setState({ reportName: reportDefinition.reportName });
            await this.getCrystalReportFile(reportId, actionId)
            //this.initReportFiltersObject(reportId);
        }
        else if (response.status === 401) {
            this.props.actions.alertHandler.showErrorMessage("The Data view you selected is not available to this account.");
            this.setState({ hasError: true });
        }
        else {
            this.props.actions.alertHandler.showErrorMessage("Data view  loading failed");
            this.setState({ hasError: true });
        }
    }
    getCrystalReportFile = async (reportId, actionId) => {
        
        let search = window.location.search;
        if (search) {
            search = decodeURI(search.slice(2));
        }
        let url = `api/ObjectData/GetCrystalReportFile`;
        const response = await fetch(url,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ReportId: reportId, 
                    ActionId: actionId,
                    ActionLinkParameter: search,
                })
            });
        if (response.status === 200) {
            const reportDataObject = await response.json();
            this.rootRef.current.scrollIntoView();
            this.setState({ data: `data:application/pdf;base64, ${reportDataObject.base64Content}`});
        }
        else {
            this.props.actions.alertHandler.showErrorMessage("Data view  loading failed");
        }
    }
     
    showConfirmActionLink = (history, isClose) => {
        if (isClose) {
            this.closeActionLinkWindow(isClose)
        }
        else {
            this.backActionLinkWindow(history)
        }

    }
    closeActionLinkWindow = (isClose) => {
        window.close();

    }
    backActionLinkWindow = (history) => {
        history.goBack();

    }
    noActionActionLinkWindow = () => {
    }
    componentDidUpdate(nextProps) {
       
    }
    render() {
        return (
            <div ref={this.rootRef}>
                <Box pt = { 0} >
                        <LayoutObjectHeader title={this.state.reportName} loadType={this.props.match.params.loadType} pageAction={this.showConfirmActionLink}/>
                        <Grid container spacing={0} >
                            <Grid item xs={12} >
                  
                            </Grid>
                        </Grid>
                        <Box  pr={0} >
                            <PDFViewer height={840} fileName={""} data={this.state.data} />
                        </Box>
                 </Box >
            </div>
       ) 
    }
}
function mapDispatchToProps(dispatch) {
    return {
        actions: {
            errorActionCreator: bindActionCreators(errorActionCreator, dispatch),
            alertHandler: bindActionCreators(alertHandlerAction, dispatch),
            setSelectedRows: bindActionCreators(dataViewActionCreator, dispatch),
        }
    };
}
export default connect(null,
    mapDispatchToProps
)(withStyles(dataListTheme)(CrystalReportViewerActionLink));