
import { createTheme } from '@mui/material/styles';
import { theme, customThemeStyles } from 'common/theme.js';

export const databaseFieldsTheme = (theme) => ({
    ...customThemeStyles,
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    root: {
        height: 340,
        flexGrow: 1,
        maxWidth: 660,
        overflow: 'auto',
    },
    datatableHeader: {
        display: 'none!important'
    },
    spanPrimary: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '14px',
        alignItems: 'center',
        letterSpacing: '0.0025em',
        marginLeft: '20px',
        color: '#2196F3'
    },
    spanActive: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '14px',
        alignItems: 'center',
        letterSpacing: '0.0025em',
        color: 'rgba(0, 0, 0, 0.87)',
        paddingLeft: "10px",
        overflowWrap: "break-word",
    },
    spanInActive: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '14px',
        alignItems: 'center',
        letterSpacing: '0.0025em',
        color: 'rgba(0, 0, 0, 0.36)',
        paddingLeft: "10px",
    },
    spanField: {
        paddingLeft: "10px", fontSize: "12px"

    },
    searchText: {
        width: '380px!important',
        marginBottom: '10px!important',
    paddingBottom: '10px!important',

    },
    searchTextMenu: {
        width: '480px!important',
        marginLeft: "-30px !important",
        marginTop: "-28px !important"
    },
    menuBox: {
        border: "1px solid rgba(0, 0, 0, 0.1)",
        marginLeft: "-32px",
        marginRight: "-121px",
        width: "471px",
        paddingTop: "1px",
    },
    menuDetails: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginLeft: "-16px",
        marginTop: "3px",
    },
    menuDotIcon: {
        height: "9px!important",
        width: "9px!important",
        color: theme.palette.primary.main + '!important',
    },
    AddThreeDots: {
        display: "inline-block",
        width: "176px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        padding: "5px",
    },
    textAlign: {
        textAlign: "end",
    },
    
});

export const getMuiTheme = () => createTheme({

    components: {
        MuiTableHead: {
            styleOverrides: {
                root: {
                    display: "none"
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    border: 0,

                },
            }
        },

        MUIDataTableToolbar: {
            styleOverrides: {
                left: {
                    width: '100%!important',
                },
            }
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    overflowX: 'clip',
                },
            }
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    marginBottom: '-13px',
                },
            }
        },
    },
    

})