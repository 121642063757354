import React from 'react'
import * as enumerator from 'client/components/Common/Enum';
import Modal from '@mui/material/Modal';
class lightBox extends React.Component {
    getLightBox = () => {

        if (this.props.deviceType === enumerator.DeviceType.Desktop) {
            return (<Modal open={true}><div id="lightBox"><div style={{ position: "relative", display: "inline-block" }}> <div style={
                {
                    position: "absolute",
                    textAlign: "right",
                    width: "100%",
                    paddingTop: "1%"
                }}><span onClick={this.props.hideLightBox} className="spanLightBox" aria-hidden="true"></span></div><div id="content">
                    <img src={this.props.src} alt="" /></div></div></div></Modal>)

        } else {

            return (<Modal open={true}><div id="mobileLightBox"><div style={{ position: "relative", display: "inline-block" }}>

                        <span onClick={this.props.hideLightBox} className="mobileSpanLightBox" aria-hidden="true"></span>
                <div style={{
                position: "fixed",
                textAlign: "right",
                width: "100%",
                paddingTop: "1%"
            }} ></div><div id="content" >
                    <img src={this.props.src} alt="" /></div></div></div></Modal>)
        }

    }
    render() {

        return (<div>{this.getLightBox()} </div>
        )

    }

}
export default lightBox;