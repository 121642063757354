import { customColorPalette } from 'common/theme.js';
export const deleteActionStyle = {

    deleteIconButton: {
        padding: '0 ',
    },
    alertActionButton: {
        minWidth: '45px',
        paddingLeft: '8px !important',
        paddingRight: '0px !important',
    },
    alertIcon: {
        padding: '9px 0 7px 0',
    },
    alertMessage: {
        padding: '0px !important',
    },
    alertIconButton: {
        marginLeft: '30px !important',
        paddingRight: '0px !important',
    },
    closeButton: {
        color: `${customColorPalette.black[87]} !important`
    },
    popper: {
        boxShadow:'0px 1px 2px rgba(0, 0, 0, 0.2), 0px 1px 3px rgba(0, 0, 0, 0.1)',
    },
}