import React from 'react';
import { withStyles } from '@mui/styles';
import { contentLayoutTheme } from 'client/components/ContentLayout/theme/contentLayoutTheme'
class thumbnailImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {imageSource:""}
    }

    componentDidMount() {
        this.fetchImage();

    }
    componentDidUpdate(prevProps) {
        if (this.props.contentId != prevProps.contentId) {
            this.fetchImage();
        }
    }

    fetchImage = async () => {
        const url = `api/News/GetThumbnailImage/${this.props.contentId}`;
        const response = await fetch(url);
        const image = await response.json();
        this.setState({ imageSource: image.fileContents });
        
    }

    render() {

        return (<img src={`data:image/png;base64,${this.state.imageSource}`} alt="" width="110" height="74" className={this.props.classes.alignLeft} />);
    }

}
export default (withStyles(contentLayoutTheme)(thumbnailImage)); ;
