import { showError, showSuccess } from 'client/components/Common/AlertAction';
const requestUserProfile = 'REQUEST_UserProfile';
const receiveUserProfile = 'RECEIVE_UserProfile';
const requestUpdate = 'REQUEST_UPDATE';
const receiveUpdate = 'RECEIVE_UPDATE';
const requestMessagerequestMessage = 'REQUEST_MESSAGE';
const receiveMessage = 'RECEIVE_MESSAGE';
const alertHide = 'HIDE_ALERT'
const successStatus = 200;
const passwordComplexityValidation = 409;
export const badRequestStatus = 400;
const initialState = { loading: false, userProfile: [] };
export const userProfileActionCreators = {

    requestUserProfile: () => async  (dispatch, getState) => {

        dispatch({ type: requestUserProfile });
        dispatch({ type: alertHide });
        const url = `api/UserProfile/GetUserProfile`;
        const response = await fetch(url);

        if (response.status === successStatus ){
            const responseUserProfile = await response.json();
            dispatch({
                type: receiveUserProfile,
                userProfile: responseUserProfile,
                status: response.status
            });

            
        }
        else {

            dispatch({
                type: receiveUserProfile,
                status: response.status
            });
           
            showError(dispatch, 'User Profile Loading failed.');

        }


    },
    requestProfileUpdate: (data) => async (dispatch, getState) => {

            dispatch({ type: requestUpdate });
            const response = await fetch('api/UserProfile/UpdateUserProfile',
                {
                    method: 'POST',
                    body: data
                });
        
        dispatch({ type: receiveUpdate, status: response.status });
        if (response.status === successStatus) {

            const responseUserProfile = await response.json();
            dispatch({
                type: receiveUserProfile,
                userProfile: responseUserProfile,
                status: response.status
            });

            dispatch({
                type: 'RECEIVE_IMAGE',
                firstName: data.get('firstName'),
                profileImage: data.get('ProfilePath')
            });
            showSuccess(dispatch, 'Success', 'Profile updated successfully', 5000);
        } else if (response.status === badRequestStatus) {

            showError(dispatch, 'The current password provided does not match our records', 5000);

        }
        else if (response.status === passwordComplexityValidation) {

            showError(dispatch,
                'The new password entered did not meet the requirements.',
                5000);

        } else {
            showError(dispatch, 'Oops! something went wrong. Please try again. If the problem persists contact your system administrator.');
        }

    },

     requestMessage: (errorMessage) =>  (dispatch, getState) => {
       showError(dispatch, errorMessage);
        }
};



export const reducer = (state, action) => {

    state = state || initialState;
    if (action.type === requestUserProfile) {
        return {
            ...state,
            loading: true,
            message: "",
            showMessage: false

        };
    }
    if (action.type === receiveUserProfile) {
        if (action.status === successStatus) {
            return {
                ...state,
                userProfile: action.userProfile,
                loading: false,
                showMessage: false

            };
        } else {

            return {
                ...state,
                loading: false,
                showMessage: false

            };
        }

    }

    if (action.type === requestUpdate) {
        return {
            ...state,
            loading: true,
            message: "",
            showMessage: false

        };
    }

    if (action.type === receiveUpdate) {
        if (action.status === successStatus) {
            return {
                ...state,
                loading: false,
               
            };
        } else if (action.status === 401) {
            return {
                ...state,
                loading: false,
                
            };
        } else if (action.status === passwordComplexityValidation) {
            return {
                ...state,
                loading: false,
               };
        } else {
            return {
                ...state,
                loading: false,
               
            };
        }
    }
   


    



return state;
    

};
