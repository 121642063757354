import { customThemeStyles, customColorPalette, mainTheme } from 'common/theme.js';
 

//export const defaultRoleColor = "#FFFFFF!important";
//export const defaultRoleBGColor = "#43A047!important";

export const formTheme = () => ({
    ...customThemeStyles,
    deleteColumn: { width: "60px!important" },
    nameColumn: { width: "170px!important", wordBreak: "break-all!important" },
    formIdColumn: { width: "90px!important" },
    categoryColumn: { width: "155px!important", wordBreak: "break-all!important" },
    dateColumn: { width: "150px!important" },
    borderBox: {
        border: 1,
        borderColor: "grey.300 !important",
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.12), 0px 4px 4px rgba(0, 0, 0, 0.24) !important",

    },
    controlSettingsPannel: {
        paddingBottom: "57px!important"
    },
    controlSettingsInnerPannel: {
        paddingLeft: "22px!important",
        paddingRight: "22px!important",

    },
    controlSettingsTitlePannel: {
        paddingLeft: "24px!important",
    },
    scrollCardInnerPannel: {
        width: "100%!important",
        height: "733px!important"
    },
    primaryIcon: {
        float: "right",
        color: "#2196F3"
    },
    controlSettingCheckBoxLabel: {
        // marginRight: "15px!important"
        height: "20px!important",
        marginBottom: "18px!important",

    },
    controlSettingCheckBoxLabel2: {
        height: "20px!important",
        marginBottom: "9.5px!important"
    },
    controlSettingCheckBoxLabel3: {
        marginTop:"7px!important",
        height: "20px!important",
        marginBottom: "9.5px!important"
    },
    controlSettingCheckBoxLabelNoPadding: {
        height: "20px!important",
    },
    controlSettingsSubTitleNoTopMargin: {
        marginBottom: "32px!important"
    },
    controlSettingsSubTitle: {
        marginTop: "48.5px!important",
        marginBottom: "32px!important"
    },
    controlSettingsSubTitleButtonSettings: {
        marginTop: "52.5px!important",
        marginBottom: "31px!important"
    },
    controlSettingsSubTitleButtonSettingsResponse: {
        marginTop: "20.5px!important",
        marginBottom: "29px!important"
    },
    controlSettingsSubTitle2: {
        marginTop: "6.5px!important",
        marginBottom: "18px!important"
    },
    closeIcon: {
        cursor: "pointer !important"
    },
    controlSettingsCheckBoxGroup: {
        marginTop: "33px!important",
        marginBottom: "11px!important"
    },
    controlSettingsTopText: {
        marginBottom: "8px!important"
    },
    colorChip: {
        width: '24px!important',
        height: '24px!important',
        flexDirection: 'row!important',
        marginBottom: '12px!important',
        marginRight: "6px!important",
        marginTop: "8px!important"
    },
    MultiTextOptions: {
        marginTop: "33px!important",
        marginBottom: "26px!important"
    },
    customBody3: {
        fontSize: "12px!important",
        letterSpacing: "0.25%!important"
    },
    customBody2: {
        color: `${customColorPalette.black[87]} !important`,
    },
    customBody1: {
        color: `${customColorPalette.black[54]} !important`,
    },
    customBody1Error: {
        color: '${mainTheme.palette.error.main} !important',
    },
    customBody1culture: {
        color: `${customColorPalette.black[54]} !important`,
        // marginTop: "-8px!important"
    },
    customBody1Decimal: {
        color: `${customColorPalette.black[54]} !important`,
        //marginTop: "-16px!important"
    },
    customBody1DefaultValue: {
        color: `${customColorPalette.black[54]} !important`,
        // marginTop: "-24px!important"
    },
    customBody1LookUpSource: {
        color: `${customColorPalette.black[54]} !important`,
        //marginTop: "-12px!important"
    },
    customBody1LookUpSourceMore: {
        color: `${customColorPalette.black[54]} !important`
    },
    dialogContent: {
        overflowY: "hidden!important",
        paddingTop: "3px!important",
        marginBottom: "10px!important"
    },
    dialoginstruction: {
        marginTop: "-20.06px!important",
        width: "65%!important",
        color: `${customColorPalette.black[36]} !important`,
    },
    circularProgressStyle: {
        color: `${customColorPalette.black[54]} !important`,
    },
    buttonSettingsTopText: {
        marginBottom: "20px!important"
    },
    buttonSettingsTopText2: {
        marginBottom: "39.06px!important"
    },
    buttonSettingsTopText3: {
        marginTop: "19px!important"
    },
    buttonSettingsparameterFields: {
        marginBottom: "36px!important"
    },
    buttonSettingsparameterBlock: {
        marginTop: "6px!important"
    },
    buttonSettingsparameterRemove: {
        marginBottom: "10px!important", float: "right"
    },
    buttonSettingsparameterStaticCheck: {
        marginBottom: "15px!important"
    },
    buttonSettigsParamMap: {
        marginTop: "49px!important"
    },
    buttonSettingsExecutionOrder: {
        marginBottom: "20.56px!important"
    },
    buttonSettingsExecutionOrderAction_Save: {
        color: `${customColorPalette.black[87]} !important`,
        marginLeft: "77px!important",
        display: 'inline-block'
    },
    buttonSettingsExecutionOrderAction_Del: {
        marginLeft: "104px!important",
        color: `${customColorPalette.black[87]} !important`,
        display: 'inline-block'
    },
    buttonSettingsExecutionOrderAction_Bz: {
        color: `${customColorPalette.black[87]} !important`,
        marginLeft: "29px!important",
        display: 'inline-block'
    },
    buttonSettingsExecutionOrderAction_Url: {
        color: `${customColorPalette.black[87]} !important`,
        marginLeft: "119px!important",
        display: 'inline-block'
    },
    buttonSettingsActionTab: {
        marginTop: "6.5px!important",

    },
    buttonSettingsMultiline: {
        minHeight: "88px!important"
    },
    buttonSettingsActionTabTabStrip: {
        minHeight: "42px!important",
        height: "42px!important"
    },
    buttonSettingsActionTabTabForm: {
        width: "101px!important",
        padding: "0px!important"
    },
    buttonSettingsActionTabTabBw: {
        width: "117px!important",
        padding: "0px!important"
    },
    buttonSettingsActionTabTabUrl: {
        padding: "0px!important",
        // paddingRight: "19px!important",
        width: "30px!important",
    },
    marginTop32: {
        marginTop: "32px!important",
    },
    controlSettingsSubTitleResponse: {
        marginBottom: "19px!important"
    },
    controlSettingsSubTitleResponse2: {
        marginBottom: "11px!important"
    },
    marginBottom21: {
        marginBottom: "21px!important"
    },
    dialogPapercontrolParameter: {
        minWidth: '723px',
        maxWidth: '723px',
        height: '516px',
        overflowY: 'hidden !important',
    },
    dialogPapercontrolAction: {
        marginBottom: "10px!important"
    },
    lookupListFCControl: {
        width: "250px!important",
        paddingRight: "0px!important",
        color: `${customColorPalette.black[36]} !important`,
    },
    lookupListSCControl: {
        width: "241px!important",
        paddingLeft: "3px!important",
        color: `${customColorPalette.black[36]} !important`,
    },
    lookupListFCControl1: {
        width: "280px!important",
        paddingRight: "0px!important",
        color: `${customColorPalette.black[36]} !important`,
    },
    lookupListSCControl1: {
        width: "280px!important",
        paddingLeft: "3px!important",
        color: `${customColorPalette.black[36]} !important`,
    },
    lookupListTCControl: {
        width: "20px!important",
        paddingRight: "0px!important",
        color: `${customColorPalette.black[54]} !important`,
    },
    lookupListTCControlsm: {
        width: "20px!important",
        padding: "0px!important",
        color: `${customColorPalette.black[54]} !important`,
    },
    lookupListTabPannel: {
        minHeight: "50px!important",
        maxHeight: "280px !important",
        overflowY: 'auto !important',
        overflowX: 'hidden !important'
    },
    lookupSettingsTabTabForm: {
        width: "160px !important"
    },
    lookupSettingsTab: {
        width: "320px !important"
    },
    MUIDataTable: {
        overflowY: 'hidden!important',
        overflowx: 'hidden!important',
        width: '100%!important',
        minWidth: '750px !important',

    },
    alertBox: {
        position: "absolute !important"
    },
    ddlMeuOnlySection: {
        color: `${customColorPalette.black[54]} !important`,
        marginTop: "9px!important",
        marginBottom: "4px!important"
    },
    customBody1StaticMenu: {
        color: `${customColorPalette.black[54]} !important`,
        marginBottom: "24px!important"
    },
    controlSettingsSubTitleAdornment: {
        marginTop: "11.5px!important",
        marginBottom: "2px!important"
    },
    customBody1Caption: {
        color: `${customColorPalette.black[54]} !important`,
        marginTop: "0px!important",
    }, customBody1colorchip: {
        color: `${customColorPalette.black[54]} !important`,
        marginTop: "8px!important",
    },
    customBody1MenuItemLabel: {
        color: `${customColorPalette.black[54]} !important`,
        marginTop: "10px!important",
    },
    customBody2ExecutionOrder: {
        color: `${customColorPalette.black[87]} !important`,
        marginTop: "27px!important",
    },
    marginTopMapToEnter: {
        marginTop: "8px!important"
    },
    controlRoleSwitch: {
        marginTop: "28px!important"
    },
    roleSelectionGroup: {
        marginTop: "48px!important"
    },
    roleControl: {
        minHeight:"39px!important"
    },

    alertStyle: {
        paddingTop: "0px!important",
        paddingBottom: "20px!important"
    }


});
