import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import MUIDataTable from 'mui-datatables';

import { getDataViews, deleteDataView, changeDataViewTab } from 'admin/actions/dataViewActions';

import { Box } from '@mui/material'

import { withStyles } from '@mui/styles';

import { produce } from 'immer';
import ActionBackdrop from 'admin/components/section/settings/Backdrop';
import { MuiGridOptions } from 'admin/components/Common/CommonOptions';
import DeleteButton from 'common/DeleteButton.js';
import { objectListFormatDate } from 'admin/components/Common//DateFormat'; 
import { dataViewsTheme } from './theme/dataViewsTheme.js';
import api from 'admin/api/adminApi';
import { showError } from 'admin/actions/alertActions';
import { setTabTitle } from 'client/components/Common/Utility';
const defaultColumnOptions = {
    filter: true,
    sort: true,
};
const dataViewTab = 0;
class DataViewList extends React.Component {

    constructor(props) {
        const { classes } = props;
        super(props);
        this.state = {
            columns: [
                {
                    name: "reportId",
                    label: "Report Id",
                    options: {
                        ...defaultColumnOptions,
                        display: 'false'
                    }
                },
                {
                    name: "category",
                    label: "Category",
                    options: {
                        ...defaultColumnOptions,
                        setCellProps: () => ({ className: classes.databaseColumn }),
                    }
                },
                {
                    name: "reportName",
                    label: "Name",
                    options: {
                        ...defaultColumnOptions,
                        setCellProps: () => ({ className: classes.nameColumn }),
                    }
                },
                {
                    name: "type",
                    label: "Type",
                    options: {
                        ...defaultColumnOptions
                    }
                },
                {
                    name: "database",
                    label: "Database",
                    options: {
                        ...defaultColumnOptions,
                        setCellProps: () => ({ className: classes.databaseColumn }),
                    }
                },
                {
                    name: "createTime",
                    label: "Created",
                    options: {
                        ...defaultColumnOptions,
                        customBodyRender: objectListFormatDate
                    }


                },
                {
                    name: "modifiedTime",
                    label: "Modified",
                    options: {
                        ...defaultColumnOptions,
                        customBodyRender: objectListFormatDate
                    }
                },
                {
                    name: "",
                    label: "",
                    options: {
                        ...defaultColumnOptions,
                        setCellProps: () => ({ className: classes.deleteColumn }),
                        customBodyRender: this.deleteRowTemplate,
                        filter: false,
                    },
                    
                }
            ],
            options: {
                download: false,
                disableScrollLock: true,
                elevation: 0,
                responsive: 'scrollMaxHeight',
                pagination: false,
                filter: true,
                filterType: 'multiselect',
                jumpToPage: true,
                onCellClick: (colData, cellMeta) => {
                    let dataIndex = cellMeta.dataIndex;
                    let dataView = this.props.dataViews[dataIndex];
                    let columnIndex = this.state.columns.findIndex(c => c.name === "");
                    if (columnIndex !== cellMeta.colIndex)
                        this.getDataViewData(dataView.reportId)
                },
                print: false,
                searchOpen: true,
                selectableRows: 'none',
                sortOrder: {
                    name: 'category',
                    direction: 'asc',
                },
                textLabels: {
                    body: {
                        noMatch: "",
                    },
                },
                onFilterChange: (changedColumn, filterList, type, changedColumnIndex, displayData) => {
                    this.setState(produce(draft => {
                        draft.columns[changedColumnIndex].options.filterList = filterList[changedColumnIndex];
                    }));
                },
                onColumnSortChange: (changedColumn, direction) => {
                    this.setState(produce(draft => {
                        draft.options.sortOrder = { name: changedColumn, direction: direction };
                    }));
                }
            },
        };
        setTabTitle("Data Views", true);
    }

    componentDidMount() {
        this.dispatchGetDataViews();
    }

    dispatchGetDataViews = () => {
        this.props.dispatch(getDataViews());
    }

    deleteRowTemplate = (value, tableMeta, updateValue) => {
        const columnIndex = 0;
        return <DeleteButton preValidationServer={api.canDeleteDataView} onClick={this.dispatchDeleteDataView} id={tableMeta.rowData[columnIndex]} />
    } 

    getDataViewData(reportId) {
        this.props.history.push(`/Admin/DataViews/${reportId}`);
        this.props.dispatch(changeDataViewTab(dataViewTab));
    }
    deletePreValidation = () => {
        return { canDelete: true, message: "" }
    }
    dispatchDeleteDataView = (id) => {
        const dataView = this.props.dataViews.find(u => u.reportId === id);
        this.props.dispatch(deleteDataView(dataView.reportId));
        //api.canDeleteDataView(id).then((resp) => {
        //    let message = (resp.data === "") ? resp.data : resp.data.message;

        //    if (message) {
        //        showError(this.props.dispatch, message, 0);

        //    } else {
        //        this.props.dispatch(deleteDataView(dataView.reportId));
        //    }

        //})
        //    .catch((err) => {
        //        let message = err.response.data;
        //        showError(this.props.dispatch, message, 0);
        //    });


       
    }
    //options={MuiGridOptions(this.state.options, this.props.isDataLoading)}
    render() {
        const { classes } = this.props;
       
        return (
            <Box border={1} className={classes.commonObjectListBox}>
             {this.props.isLoading && <ActionBackdrop />}
                <MUIDataTable container 
                    title={''}
                    data={this.props.dataViews}
                    columns={this.state.columns}
                    options={MuiGridOptions(this.state.options, this.props.isDataLoading)}
                />
            </Box>
        );
    }
}
function mapStateToProps(state) { 
    return {
        dataViews: state.dataViews.dataViews,
        isLoading: state.loader.isLoading,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage,
        isDataLoading: state.dataViews.isLoading,
    };
};
export default withRouter(connect(mapStateToProps)(withStyles(dataViewsTheme)(DataViewList)));
//export default withRouter(connect(mapStateToProps)(DataViewList));
