import { theme, customThemeStyles } from 'common/theme.js';
export const mainContentTheme = (contentTheme) => ({
    ...customThemeStyles,
    leftPanelCollapsed: {
        marginLeft: "64px!important",

    }
    ,

    leftPanelExpanded: {

        //marginLeft: "302.4px!important",
        [theme.breakpoints.only('xs')]: {
            marginLeft: "64px!important"

        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: "302.4px!important"


        },
        position:"relative"


    },

    leftPanelMargin: {
        position: "relative",
        [theme.breakpoints.only('xs')]: {
            marginLeft: "64px!important"

        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: "302.4px!important"


        },


    }



});