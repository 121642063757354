import { ActionTypes as types } from 'admin/actions/actionTypes'; 
import { produce } from 'immer';
import { newSubRecord, addNewToSubRecord, resetSubRecord } from 'admin/actions/dataViewSubRecordsActions';


const initialState = {
    subRecord: newSubRecord(),
    subRecordFields: [],
};
const dataViewSubRecords = produce((draft, action) => {
    switch (action.type) {
        case types.VGO_GET_DATAVIEW_SUBRECORD_FIELDS_SUCCESS:
            draft.subRecordFields = action.subRecordFields;
            break;
        case types.VGO_UPDATE_DATAVIEW_SUBRECORD_FIELDS_LINK_SUCCESS:
            draft.subRecord = action.subRecord;
            break;
        case types.VGO_DELETE_DATAVIEW_SUBRECORD_FIELDS_LINK_SUCCESS:
            draft.subRecord = action.subRecord;
            break;
        case types.VGO_GET_DATAVIEW_SUBRECORD:
            draft.subRecord = action.subRecord;
            break;
        case types.VGO_GET_RESET_DATA_SUBRECORD:
            draft.subRecord = resetSubRecord(action.data, action.subRecord);
            break;
            case types.VGO_CHANGE_SUBRECORD:
            action.change(draft.subRecord);
            break;
        case types.VGO_UPDATE_DATAVIEW_SUBRECORD_MAPPINGFIELDS_SUCCESS:
            draft.subRecord = addNewToSubRecord(action.subRecord);
            break;
        default:
            break;
    }
},
    initialState);

export default dataViewSubRecords;