import { combineReducers } from 'redux';

import loader from './loader';
import users from './users';
import systemSettings from './systemSettings';
import roles from './roles';
import error from './error';
import activeUser from './activeUser';
import databases from './databases';
import alert from './alert';
import filters from './filters'
import dataViews from './dataViews';
import dataViewsSubRecords from './dataViewsSubRecords';
import dataViewsReportLinks from './dataViewsReportLinks';
import forms from './forms';
import menus from './menus';
import dashboards from './dashboards';
import customWidgets from './customWidget';
import siteThemes from './siteThemes';
import homePage from './homePage';
import packages from './package';
//import { reducer as reduxFormReducer } from 'redux-form';

const rootReducer = combineReducers({
    loader,
    users,
    systemSettings,
    error,
    databases,
    alert,
    filters,
    activeUser,
    dataViews,
    roles,
    dataViewsSubRecords,
    dataViewsReportLinks,
    forms,
    menus,
    dashboards,
    customWidgets,
    siteThemes,
    homePage,
    packages,
  //  form: reduxFormReducer,
});

export default rootReducer;