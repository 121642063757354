import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { styled } from '@mui/styles';
import { withStyles } from '@mui/styles';
import { spacing } from '@mui/system';
import { Grid, Checkbox, Select, MenuItem, Switch } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MuiCard from '@mui/material/Card';
import MuiTextField from '@mui/material/TextField';
import { updateRecordSubmission, updateReportSetup, getChangeRecordSubmission } from 'admin/actions/recordSubmissionActions';
import { recordSubmissionTheme } from 'admin/components/section/dataViews/recordSubmission/theme/recordSubmissionTheme';
import FormControlLabel from '@mui/material/FormControlLabel';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import * as Enums from 'client/components/Common/Enum';
import { sortName } from 'client/components/Common/Utility';
const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
const enumfield = { field1: 0, field2: 1, field3: 2, IsProcessPayments: 'isProcessPayments', DisplayFieldId: 'displayFieldId', SqlServer:'sqlServer' }
class RecordSubmission extends React.Component {

    componentDidMount() {
        console.log('componentDidMount: ', this, "props: ", this.props, "state: ", this.state);
    }

    setFieldList = (data, itemIndex) => {
        var displayfield = [];
        if (this.props.recordProcessing.displayFieldId) {
            if (this.props.recordProcessing.displayFieldId.indexOf(",") > -1) {
                displayfield = this.props.recordProcessing.displayFieldId.split(",")
            } else {

                displayfield.push(this.props.recordProcessing.displayFieldId)
            }
        } else {
            displayfield.push('')
        }

        const array = [...data].sort((a, b) => sortName(a.displayFieldName, b.displayFieldName)).map((item, index) => {
            let status = true;
            for (var i = 0; i < displayfield.length; i++) {
                if (item.fieldName === displayfield[i] && itemIndex !== i) {
                    status=false;
                }
            }

                      if (status) {
                          return (<MenuItem key={item.fieldName + index} value={item.fieldName} > {item.displayFieldName}</MenuItem >);
                      } else {
                          return null;
                      }

            
           
        }).filter(Boolean);
        let fieldSelect = 'Select'
        if (itemIndex === enumfield.field1) {
            fieldSelect='Select*'
        }
        array.unshift(<MenuItem key="Select" value="Select" >{fieldSelect}</MenuItem >)
        return array;
    }

    setDatabaseData = (data) => {
        const array = data.map((item, index) => {

            if (item.databaseType === enumfield.SqlServer) {
                return (<MenuItem key={item.databaseId + index} value={item.databaseId} > {item.databaseConnectionName}</MenuItem >);
            } else {

                return null;
            }
        }).filter(Boolean);
        array.unshift(<MenuItem key="Select" value="Select" >Select</MenuItem >)
        return array;
    }

    getFieldValue = (index) => {
        if (this.props.recordProcessing) {
            if (this.props.recordProcessing.displayFieldId) {
                if (this.props.recordProcessing.displayFieldId.indexOf(",") > -1) {
                    return this.props.recordProcessing.displayFieldId.split(",")[index]
                } else {
                    if (index === enumfield.field1) {
                        return this.props.recordProcessing.displayFieldId;
                    } else {
                        return "";
                    }
                }
            } else {
                return "";
            }
        } else {
            return "";
        }
    }
    setFieldValue = (index, value) => {
        if (this.props.recordProcessing.displayFieldId != null) {
            if (this.props.recordProcessing.displayFieldId.indexOf(",") > -1) {

                const array = this.props.recordProcessing.displayFieldId.split(",")
                array[index] = value;
                this.handleOnChange(enumfield.DisplayFieldId, array.join())

            }
            else {
                let field = "";
                if (index === enumfield.field1) {
                    field = value;
                } else if (index === enumfield.field2) {

                    field = this.props.recordProcessing.displayFieldId+ "," + value;
                }
                else if (index === enumfield.field3) {

                    field = this.props.recordProcessing.displayFieldId+",," + value;
                }

                this.handleOnChange(enumfield.DisplayFieldId, field)
            }
        } else {

            let field = "";
            if (index === enumfield.field1) {
                field = value;
            } else if (index === enumfield.field2) {

                field = "," + value;
            }
            else if (index === enumfield.field3) {

                field = ",," + value;
            }

            this.handleOnChange(enumfield.DisplayFieldId, field)

        }
        return "";
    }

    handleSubmissionOnChange = (name, value) => {
        let data = { name: name, value: value }
        this.props.dispatch(updateReportSetup(this.props.dataView, data));
        if (!value) {
            let data = { name: enumfield.IsProcessPayments, value: value }
            this.props.dispatch(updateRecordSubmission(this.props.recordProcessing, data));

        }


    }

    handleOnChange = (name, value) => {
        let data = { name: name, value: value }
        this.props.dispatch(updateRecordSubmission(this.props.recordProcessing, data));

    }

    changeRecordSubmission = (change) => {

        this.props.dispatch(getChangeRecordSubmission(change));
    }

    checkEditableColumn = () => {

        if (this.props.dataView.fieldDisplay) {
            let recordSubmission = this.props.dataView.recordProcessing;
            if (recordSubmission.editableFieldId) {
                const index = this.props.dataView.fieldDisplay.findIndex(e => e.fieldName === recordSubmission.editableFieldId)
                if (index !== -1) {
                    let item = this.props.dataView.fieldDisplay[index];
                    if (!(item.fieldType.toLowerCase() === Enums.DbType.Int) && !(item.fieldType.toLowerCase() === Enums.DbType.Decimal) && !(item.fieldType.toLowerCase() === Enums.DbType.Numeric) && !(item.fieldType.toLowerCase() === Enums.DbType.SmallInt) && !(item.fieldType.toLowerCase() === Enums.HanaDbType.Int)) {
                        this.changeRecordSubmission(recordProcessing => {
                            recordProcessing.hasAggregate = false
                        })
                        return true;
                    } else {

                        //this.changeRecordSubmission(recordProcessing => {
                        //    recordProcessing.hasAggregate = true
                        //})
                        return false;
                    }
                }
            }

        }

        return false;

    }

    editableDatasource = (data) => {
        const array = [...data].sort((a, b) => sortName(a.displayFieldName, b.displayFieldName)).map((item, index) => {

            if (this.props.recordProcessing.isProcessPayments) {
                if (!(item.fieldType.toLowerCase() === Enums.DbType.Int) && !(item.fieldType.toLowerCase() === Enums.DbType.Decimal) && !(item.fieldType.toLowerCase() === Enums.DbType.Numeric) && !(item.fieldType.toLowerCase() === Enums.DbType.SmallInt) && !(item.fieldType.toLowerCase() === Enums.HanaDbType.Int)) {
                    return null
                } else {
                    return (<MenuItem key={item.fieldName + index} value={item.fieldName} > {item.displayFieldName}</MenuItem >);
                }
            } else {
                return (<MenuItem key={item.fieldName + index} value={item.fieldName} > {item.displayFieldName}</MenuItem >);
            }
        }).filter(Boolean);
        array.unshift(<MenuItem key="Select" value="Select" >Select</MenuItem >)
        return array;

    }

    checkStringFieldDataType = (value) => {

        if (this.props.dataView.fieldDisplay) {
            const index = this.props.dataView.fieldDisplay.findIndex(e => e.fieldName === value)
            if (index !== -1) {

                if (this.props.dataView.fieldDisplay[index].fieldDbType === "string") {
                    return true;
                } else {
                    return false;
                }
            }

        }

        return false;

    }

    resetEditableColumn = () => {

        if (this.props.dataView.fieldDisplay) {
            let recordSubmission = this.props.dataView.recordProcessing;
            if (recordSubmission.editableFieldId) {
                const index = this.props.dataView.fieldDisplay.findIndex(e => e.fieldName === recordSubmission.editableFieldId)
                if (index !== -1) {
                    let item = this.props.dataView.fieldDisplay[index];
                    if (!(item.fieldType.toLowerCase() === Enums.DbType.Int) && !(item.fieldType.toLowerCase() === Enums.DbType.Decimal) && !(item.fieldType.toLowerCase() === Enums.DbType.Numeric) && !(item.fieldType.toLowerCase() === Enums.DbType.SmallInt) && !(item.fieldType.toLowerCase() === Enums.HanaDbType.Int)) {
                        this.changeRecordSubmission(recordProcessing => {
                            recordProcessing.editableFieldId = 'Select'
                        })
                       }
                }
            }

        }
              

    }

   checkEditableFIelds=()=>{
       if (this.props.recordProcessing.hasConfirm) {
           if (this.props.recordProcessing.displayFieldId.indexOf(',') === -1) {

               if (this.props.recordProcessing.displayFieldId === 'Select' || !this.props.recordProcessing.displayFieldId ) {
                   return true;
               }

           } else {
               const array = this.props.recordProcessing.displayFieldId.split(',')
               if (array.length < 3) {
                   if (array[0] === 'Select' && array[1] === 'Select') {
                       return true;

                   }
               } else {

                   if (array[0] === 'Select' && array[1] === 'Select' && array[2] === 'Select') {
                       return true;

                   }
               }


           }
       }
       return false;

}

    render() {
        const { classes } = this.props;
        const paymentIcon = <Grid container direction="row" spacing={1} pt={1}>
            <Grid item >
                <MonetizationOnOutlinedIcon className={classes.paymentIcon} /></Grid>
            <Grid item xs={1}> <Typography pt={.1} variant="subtitle2">Payment</Typography>
            </Grid>
        </Grid>
        return (
            <Card variant="outlined" pt={2.7} pb={1.3} pr={12} pl={2.7} className={classes.rootCard}>
                <div>
                    <Grid container direction="row" justifyContent="space-between">
                        <Grid container direction="row" spacing={0}>
                            <Grid item xs={2}>
                                <Typography variant="body1">Submit Record Set</Typography>

                            </Grid> </Grid>
                        <Grid container direction="row" spacing={0} pt={1.9}>
                            <Grid item xs={1}>
                                <FormControlLabel
                                    control={
                                        <Switch name="enablePayment"
                                            name="hasRecordSubmission"
                                            checked={this.props.dataView.hasRecordSubmission ? this.props.dataView.hasRecordSubmission : ""}
                                            inputProps={{ 'aria-label': 'primary checkbox' }} onChange={(event) => { this.handleSubmissionOnChange(event.target.name, event.target.checked) }}
                                        />}
                                    label={<Typography variant="body2">Enable</Typography>} />

                            </Grid>
                            <Grid item xs={1}>
                                <FormControlLabel
                                    control={
                                        <Switch className={classes.payment} name="isProcessPayments" disabled={this.props.dataView ? !this.props.dataView.hasRecordSubmission : false}
                                            checked={this.props.dataView.recordProcessing ? this.props.recordProcessing.isProcessPayments : ""}
                                            inputProps={{ 'aria-label': 'primary checkbox' }} onChange={(event) => {
                                                this.handleOnChange(event.target.name, event.target.checked)
                                                if (event.target.checked) {
                                                    this.resetEditableColumn();
                                                }

                                            }}
                                        />}
                                    label={paymentIcon} />

                            </Grid>


                        </Grid >

                        <Grid container direction="row" spacing={4} pt={3.3}>
                            <Grid item xs={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox name="enablePayment" disabled={this.props.dataView ? !this.props.dataView.hasRecordSubmission ? true : this.props.recordProcessing.isProcessPayments ? true : false : false}
                                            checked={this.props.recordProcessing ? this.props.recordProcessing.enablePayment : ""}
                                            inputProps={{ 'aria-label': 'primary checkbox' }} onChange={(event) => { this.handleOnChange(event.target.name, event.target.checked) }}
                                        />}
                                    label={<Typography variant="body2">Editable Column</Typography>} />
                            </Grid>
                            <Grid item xs={3}>

                            </Grid>

                            <Grid item xs={3} className={classes.editableColumnItem}>
                                <Typography variant="body1" mt={.7} ml={1.9}>Button Labels</Typography>
                            </Grid>
                            <Grid item xs={3}>

                            </Grid>
                        </Grid >
                        <Grid container direction="row" spacing={4}>

                            <Box className={classes.editableColumn} pl={4} pt={3.8} >

                                <FormControl mt={2} error={this.props.dataView.recordProcessing.editableFieldIdError} className={classes.formControl + ' ' + classes.controls + ' ' + classes.editableControl} fullWidth={true}>
                                   
                                    <Select MenuProps={{ disableScrollLock: true }}  disabled={this.props.dataView ? !this.props.dataView.hasRecordSubmission : false} name="editableFieldId" onChange={(event) => {

                                      
                                            this.handleOnChange(event.target.name, event.target.value)
                                       
                                       
                                    }} value={this.props.recordProcessing ? this.props.recordProcessing.editableFieldId ? this.props.recordProcessing.editableFieldId:"Select" : "Select"} fullWidth={true}
                                        onBlur={(event) => {
                                            this.changeRecordSubmission(recordProcessing => {
                                                recordProcessing.editableFieldIdError = event.target.value === 'Select' && this.props.recordProcessing.enablePayment ? true : false
                                            })
                                            this.changeRecordSubmission(recordProcessing => {
                                                recordProcessing.editableFieldIdHelper = event.target.value === 'Select' && this.props.recordProcessing.enablePayment ? 'Editable field is required' : ' '
                                            })

                                        }}
                                    >{this.props.fieldDisplay ? this.editableDatasource(this.props.fieldDisplay) : ""}


                                    </Select>
                                    <FormHelperText>{this.props.dataView.recordProcessing.editableFieldIdHelper ? this.props.dataView.recordProcessing.editableFieldIdHelper : ' '}</FormHelperText>
                                </FormControl>

                            </Box>
                            <Box pt={4} className={classes.submitColumnItem}>
                                <FormControlLabel className={classes.showEditable}
                                    control={
                                        <Switch disabled={this.checkEditableColumn()} name="hasAggregate" checked={this.props.recordProcessing ? this.props.recordProcessing.hasAggregate : ""} disabled={this.props.dataView ? !this.props.dataView.hasRecordSubmission : false} onChange={(event) => { this.handleOnChange(event.target.name, event.target.checked) }} inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />}
                                    label={<Typography variant="body2">Show & Submit Editable Column Total</Typography>} />
                            </Box>
                            <Box pt={3.8} className={classes.buttonsubmitItem}>
                                <TextField error={this.props.dataView.recordProcessing.submitButtonError}
                                    helperText={this.props.dataView.recordProcessing.submitButtonHelper ? this.props.dataView.recordProcessing.submitButtonHelper : ' '}
                                    className={classes.controls} disabled={this.props.dataView ? !this.props.dataView.hasRecordSubmission : false}
                                    label="Submit" name="selectedRowSubmitCaption" onChange={(event) => { this.handleOnChange(event.target.name, event.target.value) }}
                                    size="small" fullWidth={true} value={this.props.recordProcessing ? this.props.recordProcessing.selectedRowSubmitCaption : ""}
                                    onBlur={(event) => {
                                        this.changeRecordSubmission(recordProcessing => {
                                            recordProcessing.submitButtonError = event.target.value.trim() === '' ? true : false
                                        })
                                        this.changeRecordSubmission(recordProcessing => {
                                            recordProcessing.submitButtonHelper = event.target.value.trim() === '' ? 'Submit button label is required' : ' '
                                        })

                                    }}
                                    inputProps={{ maxLength:30 }}
                                >
                                </TextField>
                            </Box>
                            <Grid item xs={3} className={classes.editableColumnItem}>
                                <TextField error={this.props.dataView.recordProcessing.resetButtonError}
                                    helperText={this.props.dataView.recordProcessing.resetButtonHelper ? this.props.dataView.recordProcessing.resetButtonHelper : ' '} className={classes.controls} disabled={this.props.dataView ? !this.props.dataView.hasRecordSubmission : false} label="Reset" size="small" fullWidth={true} name="selectedRowClearCaption" onChange={(event) => { this.handleOnChange(event.target.name, event.target.value) }} value={this.props.recordProcessing ? this.props.recordProcessing.selectedRowClearCaption : ""}
                                    onBlur={(event) => {
                                        this.changeRecordSubmission(recordProcessing => {
                                            recordProcessing.resetButtonError = event.target.value.trim() === '' ? true : false
                                        })
                                        this.changeRecordSubmission(recordProcessing => {
                                            recordProcessing.resetButtonHelper = event.target.value.trim() === '' ? 'Reset button label is required' : ' '
                                        })

                                    }}
                                    inputProps={{ maxLength:30 }}
                                ></TextField>
                            </Grid>
                        </Grid >

                        <Box sx={{ width: 1 }}> <Card variant="outlined" className={classes.card}></Card></Box>
                        <Grid container direction="row" spacing={4} pt={2.8}>
                            <Grid item xs={3} >
                                <FormControlLabel
                                    control={
                                        <Checkbox disabled={this.props.dataView ? !this.props.dataView.hasRecordSubmission ? true : this.props.recordProcessing.isProcessPayments ? true : false : false} checked={this.props.recordProcessing ? this.props.recordProcessing.hasConfirm : ""} name="hasConfirm" onChange={(event) => { this.handleOnChange(event.target.name, event.target.checked) }} inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />}
                                    label={<Typography variant="body2">Include confirmation Page</Typography>} />
                            </Grid>

                            <Grid item xs={3} className={classes.editableColumnItem}>
                                <Typography variant="body1" pl={2.2} mt={1}>Confirmation Page Fields</Typography>


                            </Grid>
                            <Grid item xs={3} className={classes.editableColumnItem}>
                                <Typography variant="body1" pl={1} mt={1} ml={1} >Button Labels</Typography>


                            </Grid>

                        </Grid >
                        <Grid container direction="row" spacing={4} pt={.2}>

                            <Grid item xs={3}>
                                <TextField className={classes.controls} disabled={this.props.dataView ? !this.props.dataView.hasRecordSubmission : false} name="confirmScreenTitle" onChange={(event) => { this.handleOnChange(event.target.name, event.target.value) }} value={this.props.recordProcessing ? this.props.recordProcessing.confirmScreenTitle : ""} label="Page Title" size="small" fullWidth={true}
                                    error={this.props.dataView.recordProcessing.confirmScreenTitleError} helperText={this.props.dataView.recordProcessing.confirmScreenTitleHelper ? this.props.dataView.recordProcessing.confirmScreenTitleHelper : ' '}
                                    onBlur={(event) => {
                                        this.changeRecordSubmission(recordProcessing => {
                                            recordProcessing.confirmScreenTitleError = event.target.value.trim() === '' && this.props.recordProcessing.hasConfirm ? true : false
                                        })
                                        this.changeRecordSubmission(recordProcessing => {
                                            recordProcessing.confirmScreenTitleHelper = event.target.value.trim() === '' && this.props.recordProcessing.hasConfirm ? 'Page title is required' : ' '
                                        })

                                    }}
                                    inputProps={{ maxLength: 50 }}
                                ></TextField>
                            </Grid>
                            <Grid item xs={3} className={classes.confirmSelect1}>
                                <Box pt={2}></Box>
                                <FormControl className={classes.formControl + ' ' + classes.controls} fullWidth={true} error={this.props.dataView.recordProcessing.displayFieldError} >
                                    <Select MenuProps={{ disableScrollLock: true }}  disabled={this.props.dataView ? !this.props.dataView.hasRecordSubmission : false} onChange={(event) => { this.setFieldValue(0, event.target.value) }} value={this.getFieldValue(0) ? this.getFieldValue(0):'Select'} fullWidth={true}
                                        onBlur={() => {
                                            this.changeRecordSubmission(recordProcessing => {
                                                recordProcessing.displayFieldError = this.checkEditableFIelds() ? true : false
                                            })
                                            this.changeRecordSubmission(recordProcessing => {
                                                recordProcessing.displayFieldHelper = this.checkEditableFIelds()  ? 'Confirmation page field is required' : ' '
                                            })

                                        }}
                                    >{this.props.fieldDisplay ? this.setFieldList(this.props.fieldDisplay,0) : ""}</Select>
                                    <FormHelperText>{this.props.dataView.recordProcessing.displayFieldHelper ? this.props.dataView.recordProcessing.displayFieldHelper : ' '}</FormHelperText>
                                </FormControl>
                            </Grid>

                            <Box pt={4} pr={4} className={classes.confirmSelect2} ml={1}>
                                <TextField className={classes.controls} disabled={this.props.dataView ? !this.props.dataView.hasRecordSubmission : false} name="confirmSubmitCaption"
                                    onChange={(event) => { this.handleOnChange(event.target.name, event.target.value) }} value={this.props.recordProcessing ? this.props.recordProcessing.confirmSubmitCaption : ""}
                                    label="Submit" size="small" fullWidth={true}
                                    error={this.props.dataView.recordProcessing.confirmSubmitLabelError} helperText={this.props.dataView.recordProcessing.confirmSubmitLabelHelper ? this.props.dataView.recordProcessing.confirmSubmitLabelHelper : ' '}

                                    onBlur={(event) => {
                                        this.changeRecordSubmission(recordProcessing => {
                                            recordProcessing.confirmSubmitLabelError = event.target.value.trim() === '' && this.props.recordProcessing.hasConfirm ? true : false
                                        })
                                        this.changeRecordSubmission(recordProcessing => {
                                            recordProcessing.confirmSubmitLabelHelper = event.target.value.trim() === '' && this.props.recordProcessing.hasConfirm ? 'Submit button label is required' : ' '
                                        })

                                    }}
                                    inputProps={{ maxLength:30 }}
                                ></TextField>
                            </Box>
                            <Grid item xs={3} className={classes.editableColumnItem} >
                                <TextField className={classes.controls} disabled={this.props.dataView ? !this.props.dataView.hasRecordSubmission : false} name="confirmClearCaption"
                                    onChange={(event) => { this.handleOnChange(event.target.name, event.target.value) }} value={this.props.recordProcessing ? this.props.recordProcessing.confirmClearCaption : ""} label="Cancel" size="small"
                                    fullWidth={true} varient="filled"
                                    error={this.props.dataView.recordProcessing.confirmResetLabelError} helperText={this.props.dataView.recordProcessing.confirmResetLabelHelper ? this.props.dataView.recordProcessing.confirmResetLabelHelper : ' '}

                                    onBlur={(event) => {
                                        this.changeRecordSubmission(recordProcessing => {
                                            recordProcessing.confirmResetLabelError = event.target.value.trim() === '' && this.props.recordProcessing.hasConfirm ? true : false
                                        })
                                        this.changeRecordSubmission(recordProcessing => {
                                            recordProcessing.confirmResetLabelHelper = event.target.value.trim() === '' && this.props.recordProcessing.hasConfirm ? 'Cancel button label is required' : ' '
                                        })

                                    }}
                                    inputProps={{ maxLength:30 }}
                                ></TextField>
                            </Grid>

                        </Grid >

                        <Grid container direction="row" spacing={4}>

                            <Grid item xs={3}>

                            </Grid>
                            <Grid item xs={3} className={classes.confirmSelect1} pt={3.4}>
                                <FormControl error={this.props.dataView.recordProcessing.displayField1Error} className={classes.formControl + ' ' + classes.controls} fullWidth={true}>
                                    <Box pt={1.5}></Box>
                                    <Select MenuProps={{ disableScrollLock: true }}  disabled={this.props.dataView ? this.props.dataView.hasRecordSubmission ? this.getFieldValue(0) ? false : true : true : true} onChange={(event) => { this.setFieldValue(1, event.target.value) }} value={this.getFieldValue(1) ? this.getFieldValue(1):"Select"} fullWidth={true}
                                        onBlur={() => {
                                            this.changeRecordSubmission(recordProcessing => {
                                                recordProcessing.displayFieldError = this.checkEditableFIelds() ? true : false
                                            })
                                            this.changeRecordSubmission(recordProcessing => {
                                                recordProcessing.displayFieldHelper = this.checkEditableFIelds() ? 'Confirmation page field is required' : ' '
                                            })

                                        }}
                                    >{this.props.fieldDisplay ? this.setFieldList(this.props.fieldDisplay,1) : ""}</Select>
                                    <FormHelperText>{this.props.dataView.recordProcessing.displayField1Helper ? this.props.dataView.recordProcessing.displayField1Helper : ' '}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>

                            </Grid>
                            <Grid item xs={3}>

                            </Grid>
                        </Grid >

                        <Grid container direction="row" spacing={0} mt={-5.9}>

                            <Grid item xs={3}>
                                <TextField className={classes.controls} disabled={this.props.dataView ? !this.props.dataView.hasRecordSubmission : false} name="confirmInstructions" onChange={(event) => { this.handleOnChange(event.target.name, event.target.value) }} value={this.props.recordProcessing ? this.props.recordProcessing.confirmInstructions : ""} label="Page Instructions" size="small" fullWidth={true}

                                    error={this.props.dataView.recordProcessing.confirmInstructionError} helperText={this.props.dataView.recordProcessing.confirmInstructionHelper ? this.props.dataView.recordProcessing.confirmInstructionHelper : ' '}
                                    onBlur={(event) => {
                                        this.changeRecordSubmission(recordProcessing => {
                                            recordProcessing.confirmInstructionError = event.target.value.trim() === '' && this.props.recordProcessing.hasConfirm ? true : false
                                        })
                                        this.changeRecordSubmission(recordProcessing => {
                                            recordProcessing.confirmInstructionHelper = event.target.value.trim() === '' && this.props.recordProcessing.hasConfirm ? 'Page instructions is required' : ' '
                                        })

                                    }}
                                    inputProps={{ maxLength: 50 }}
                                ></TextField>
                            </Grid>
                            <Grid item xs={3}>

                            </Grid>
                            <Grid item xs={3}>

                            </Grid>
                            <Grid item xs={3}>

                            </Grid>
                        </Grid >
                        <Grid container direction="row" spacing={4} >

                            <Grid item xs={3}>

                            </Grid>
                            <Grid item xs={3} className={classes.confirmSelect1 + ' ' + classes.confirmSelect1Item}>
                                <FormControl className={classes.formControl + ' ' + classes.controls + ' ' + classes.confirmSelect1Item} fullWidth={true} error={this.props.dataView.recordProcessing.displayField2Error}>
                                    <Box pt={1.2}></Box>
                                    <Select MenuProps={{ disableScrollLock: true }}  disabled={this.props.dataView ? this.props.dataView.hasRecordSubmission ? this.getFieldValue(1) ? false : true : true : true} onChange={(event) => { this.setFieldValue(2, event.target.value) }} value={this.getFieldValue(2)?this.getFieldValue(2):"Select"} fullWidth={true}
                                        onBlur={() => {
                                            this.changeRecordSubmission(recordProcessing => {
                                                recordProcessing.displayFieldError = this.checkEditableFIelds() ? true : false
                                            })
                                            this.changeRecordSubmission(recordProcessing => {
                                                recordProcessing.displayFieldHelper = this.checkEditableFIelds() ? 'Confirmation page field is required' : ' '
                                            })

                                        }}
                                    >{this.props.fieldDisplay ? this.setFieldList(this.props.fieldDisplay,2) : ""}</Select>
                                    <FormHelperText>{this.props.dataView.recordProcessing.displayField2Helper ? this.props.dataView.recordProcessing.displayField2Helper : ' '}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>

                            </Grid>
                            <Grid item xs={3}>

                            </Grid>
                        </Grid >
                        <Box mt={-.4} sx={{ width: 1 }}><Card variant="outlined" className={classes.card}></Card></Box>
                        <Grid container direction="row" spacing={0} pt={.7}>

                            <Grid item xs={4}>

                                <Typography variant="body1">Data Capture</Typography>
                            </Grid>
                            <Grid item xs={4} className={classes.bizweaverLabel}>
                                <Typography variant="body1" >Bizweaver Service Call</Typography>
                            </Grid>
                            <Grid item xs={4}>
                            </Grid >
                        </Grid >
                        <Grid container direction="row" spacing={0} pt={1.1}>

                            <Grid item xs={4}>
                                <FormControl className={classes.formControl + ' ' + classes.controls} fullWidth={true} error={this.props.dataView.recordProcessing.databaseError}>
                                    <InputLabel id="database">Connection*</InputLabel>
                                    <Select MenuProps={{ disableScrollLock: true }}  disabled={this.props.dataView ? !this.props.dataView.hasRecordSubmission : false} name="databaseId" onChange={(event) => { this.handleOnChange(event.target.name, event.target.value) }} value={this.props.recordProcessing ? this.props.recordProcessing.databaseId : ""} fullWidth={true}
                                        onBlur={(event) => {
                                            this.changeRecordSubmission(recordProcessing => {
                                                recordProcessing.databaseError = event.target.value === 'Select'|| event.target.value === 0 ? true : false
                                            })
                                            this.changeRecordSubmission(recordProcessing => {
                                                recordProcessing.databaseHelper = event.target.value === 'Select' || event.target.value === 0 ? 'Connection is required' : ' '
                                            })

                                        }}

                                    >{this.props.databases ? this.setDatabaseData(this.props.databases) : ""}</Select>
                                    <FormHelperText>{this.props.dataView.recordProcessing.databaseHelper ? this.props.dataView.recordProcessing.databaseHelper : ' '}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Box className={classes.bizWeaverItem}>

                                <Grid container direction="row">
                                    <Box className={classes.enableBizweaverItem}>
                                        <FormControlLabel className={classes.enableBizweaver}
                                            control={
                                                <Switch name="hasBzwEnable" checked={this.props.recordProcessing ? this.props.dataView.recordProcessing.hasBzwEnable : ""} disabled={this.props.dataView ? !this.props.dataView.hasRecordSubmission : false} onChange={(event) => { this.handleOnChange(event.target.name, event.target.checked) }} inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />}
                                            label={<Typography variant="body2">Enable</Typography>} />
                                    </Box>
                                    <Box className={classes.workFlowIdItem} pt={.4}>
                                        <TextField disabled={this.props.dataView ? !this.props.dataView.hasRecordSubmission || !this.props.dataView.recordProcessing.hasBzwEnable ? true : false : false} error={this.props.dataView.recordProcessing.bzwWorkFlowIdError} helperText={this.props.dataView.recordProcessing.bzwWorkFlowIdHelper ? this.props.dataView.recordProcessing.bzwWorkFlowIdHelper : ' '} className={classes.workFlowId} name="bzwWorkFlowId" onChange={(event) => {
                                            const regex = /^[0-9\b]+$/;
                                            if (event.target.value === '' || regex.test(event.target.value)) {
                                                this.handleOnChange(event.target.name, event.target.value)
                                            }


                                        }} value={this.props.recordProcessing ? this.props.dataView.recordProcessing.bzwWorkFlowId : ""} label="Workflow Id" size="small" fullWidth={true}
                                            onBlur={(event) => {
                                                this.changeRecordSubmission(recordProcessing => {
                                                    recordProcessing.bzwWorkFlowIdError = event.target.value === '' || event.target.value === '0' ? true : false
                                                })
                                                this.changeRecordSubmission(recordProcessing => {
                                                    recordProcessing.bzwWorkFlowIdHelper = event.target.value === '' || event.target.value === '0' ? 'WorkFlow Id is required' : ' '
                                                })

                                            }}
                                            inputProps={{ maxLength: 4 }}
                                        ></TextField>
                                    </Box>
                                </Grid>

                            </Box >
                            <Grid item xs={5}>

                                <TextField disabled={this.props.dataView ? !this.props.dataView.hasRecordSubmission || !this.props.dataView.recordProcessing.hasBzwEnable?true:false : false} error={this.props.dataView.recordProcessing.bzwServiceURLError} helperText={this.props.dataView.recordProcessing.bzwServiceURLHelper ? this.props.dataView.recordProcessing.bzwServiceURLHelper : ' '} pr={2} className={classes.targetURL} name="bzwServiceURL" onChange={(event) => { this.handleOnChange(event.target.name, event.target.value) }} value={this.props.recordProcessing ? this.props.recordProcessing.bzwServiceURL : ""} label="Target URL" size="small" fullWidth={true}
                                    onBlur={(event) => {
                                        this.changeRecordSubmission(recordProcessing => {
                                            recordProcessing.bzwServiceURLError = event.target.value.trim() === '' ? true : false
                                        })
                                        this.changeRecordSubmission(recordProcessing => {
                                            recordProcessing.bzwServiceURLHelper = event.target.value.trim() === '' ? 'Target URL is required' : ' '
                                        })

                                    }}
                                    inputProps={{ maxLength:150 }}
                                ></TextField>


                            </Grid >
                        </Grid >


                        <Grid container direction="row" spacing={0} mt={-1.5}>
                            <Grid item xs={4}>
                                <TextField className={classes.controls} disabled={this.props.dataView ? !this.props.dataView.hasRecordSubmission : false} name="tableName" onChange={(event) => { this.handleOnChange(event.target.name, event.target.value) }} value={this.props.recordProcessing ? this.props.recordProcessing.tableName : ""} label="Table Name*" size="small" fullWidth={true}
                                    error={this.props.dataView.recordProcessing.tableNameError} helperText={this.props.dataView.recordProcessing.tableNameHelper ? this.props.dataView.recordProcessing.tableNameHelper : ' '}
                                    onBlur={(event) => {
                                        this.changeRecordSubmission(recordProcessing => {
                                            recordProcessing.tableNameError = event.target.value.trim() === '' ? true : false
                                        })
                                        this.changeRecordSubmission(recordProcessing => {
                                            recordProcessing.tableNameHelper = event.target.value.trim() === '' ? 'Table Name is required' : ' '
                                        })

                                    }}
                                    inputProps={{ maxLength: 50 }}
                                ></TextField>
                            </Grid>
                            <Grid item xs={4} className={classes.bizweaverParamterName}>
                                <TextField disabled={this.props.dataView ? !this.props.dataView.hasRecordSubmission || !this.props.dataView.recordProcessing.hasBzwEnable ? true : false : false} error={this.props.dataView.recordProcessing.bzwBatchIdParameterNameError} helperText={this.props.dataView.recordProcessing.bzwBatchIdParameterNameHelper ? this.props.dataView.recordProcessing.bzwBatchIdParameterNameHelper : ' '} name="bzwBatchIdParameterName" className={classes.controls} onChange={(event) => { this.handleOnChange(event.target.name, event.target.value) }} value={this.props.recordProcessing ? this.props.dataView.recordProcessing.bzwBatchIdParameterName : ""} label="Batch Id Parameter Name*" size="small" fullWidth={true}
                                    onBlur={(event) => {
                                        this.changeRecordSubmission(recordProcessing => {
                                            recordProcessing.bzwBatchIdParameterNameError = event.target.value.trim() === '' ? true : false
                                        })
                                        this.changeRecordSubmission(recordProcessing => {
                                            recordProcessing.bzwBatchIdParameterNameHelper = event.target.value.trim() === '' ? 'Batch ID parameter name is required' : ' '
                                        })

                                    }}
                                    inputProps={{ maxLength: 50 }}
                                ></TextField>
                            </Grid >
                            <Grid item xs={4}>

                            </Grid>
                        </Grid>


                    </Grid >
                </div>
            </Card>
        );
    }

}

/**
 * 
 * @param {State} state 
 */
function mapStateToProps(state) {
    let props = {
        databases: state.databases.databases,
        fieldDisplay: state.dataViews.dataView.fieldDisplay,
        recordProcessing: state.dataViews.dataView.recordProcessing,
        dataView: state.dataViews.dataView,
        isLoading: state.loader.isLoading,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage,
    };
    return props;
};

export default withRouter(connect(mapStateToProps)(withStyles(recordSubmissionTheme)(RecordSubmission)));


