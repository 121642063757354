import React from 'react'
import { Button } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { ControlType } from 'client/components/Common/Enum';
import { ReactComponent as SavenClose } from 'client/assets/images/form/SavenClose.svg'
import { ReactComponent as SavenNew } from 'client/assets/images/form/SavenNew.svg'



    
export default function (props) {
    const callButtonAction = () => {
        if (props.column !== undefined) {
            props.onClick(props.column.action, props.column.controlId, props.column.controlType)
        } else {

            props.onClick()
        }

    }

    const setButtonIcon = () => {
        let icon ;
        switch (props.column.controlType) {
            case ControlType.ActionWithClearGet:{
                icon = <DoneIcon />;
                break;
            }
            case ControlType.Submit: {
                icon = <SavenClose />;
                break;
            }
            case ControlType.ActionWithClear: {
                icon = <SavenNew />;
                break;
            }
            default: {
                icon = <div />;
            }
        }

        return icon;
    }

    const setButtonClassName = () => {
        let className;
        switch (props.column.controlType) {
            case ControlType.ClearWithGetAction: {
                className = props.otherButtonClassName;
                break;
            }
            case ControlType.ActionWithConfirmClear: {
                className = props.otherButtonClassName;
                break;
            }
            default: {
                className = props.saveButtonClassName;
            }
        }

        return className;
    }

    return (
        <Button
            variant="contained"
            className={setButtonClassName()}
            disabled={props.disabled}
            onClick={callButtonAction}
            startIcon={setButtonIcon()}
            disableElevation>
            {props.column.caption}
        </Button>
    );
    }
    //setButton = () => {
      
    //    if (this.props.custom !== undefined) {
    //        return <Button  type="button" className="field customButton" label={this.props.column.caption}  name={this.props.name} onClick={this.props.onChange}></Button>
    //    }
    //    else {
    //        if (this.props.caption.indexOf("Save and Close") !== -1 || this.props.caption.indexOf("Save and New") !== -1) {
    //            return <Button type="button" className={`${styles["btn"]} btn btn-primary btn-enabled`} style={{ width: "140px" }} name={this.props.name} placeholder={this.props.placeholder} onClick={this.callButtonAction} >
    //                <span> <i id="icnsavenclose" className="icon-check"></i>{this.props.caption.replace('and', '&')}</span></Button>

    //        }

    //        else if (this.props.caption.indexOf("Save") !== -1) {
    //            return <Button type="button" className={`${styles["btn"]} btn btn-primary btn-enabled`} style={{ width: "80px" }} name={this.props.name} placeholder={this.props.placeholder} onClick={this.callButtonAction} >
    //                <span> <i id="icnsave" className="icon-check"></i> {this.props.caption.replace('and', '&')}</span></Button>

    //        }
    //        else {
    //            if (this.props.caption.indexOf("Find") === -1) {
    //                return <Button type="button" className={`${styles["btn"]} btn btn-s`} label="" style={{ backgroundColor: "transparent", color: "#4B535A" }} name={this.props.name} placeholder={this.props.placeholder} onClick={this.callButtonAction}>
    //                    <span>{this.props.caption}</span></Button>
    //            }
    //        }
    //    }
    //    return "";


    //}

    