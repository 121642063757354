 
import { theme, customColorPalette, customThemeStyles, mainTheme } from 'common/theme';
import { grey } from '@mui/material/colors';
export const profileTheme = (profileTheme) => ({
    ...customThemeStyles,
    heading: {
        textAlign: "center!important"
    },
    header: {
        paddingTop: "12px!important"
    },
    homeIcon: {
        fontSize: '40px!important'
    },
    cardHome: {
        height: '64px!important',
        background: '#F5F5F5!important',
        padding: '0px!important'
    },


    createdDateList: {
        fontSize: '12px!important',
        fontFamily: 'Roboto!important',
        fontWeight: '400!important',
        lineHeight: '14.06px!important',
        letterSpacing: '0.4px!important',
        textAlign: 'center!important',


    },

    saveButton: {
        maxWidth: '83px!important',
        maxHeight: '32px!important',
        minWidth: '83px!important',
        minHeight: '32px!important',
        //backgroundColor: theme.palette.primary.light,
        //"&:hover": {
        //    backgroundColor: theme.palette.primary.light,
        //}
    },

    resetButton: {
        maxWidth: '72px!important',
        maxHeight: '32px!important',
        minWidth: '72px!important',
        minHeight: '32px!important',
        marginRight: '12px!important',
        //backgroundColor: theme.palette.grey[300],
        //color: theme.palette.common.black,
        //"&:hover": {
        //    backgroundColor: theme.palette.grey[300],
        //}
    },

    imageAvatar: {
        zIndex: 1000 + '!important',
        float: "right",
        borderRadius: "50%!important",
        //padding: "2px",
        backgroundColor: "#ffffff!important",
        border: "2px solid #989FA6!important"
    },

    button: {
        borderRadius: '25px!important',
        fontSize: '12px!important',
        fontFamily: 'Roboto!important',
        fontWeight: '400!important',
        lineHeight: '14.06px!important',
        letterSpacing: '0.4px!important',
        textTransform: 'capitalize!important',
        marginTop: '45px!important',
        width: '46px!important',
        // color: theme.palette.common.black,
        border: '1px solid rgba(0, 0, 0, 0.23)',
        '&:hover': {
            border: '1px solid rgba(0, 0, 0, 0.23)'
        }
    },

    ovelButton: {
        borderRadius: '25px!important',
        fontSize: '12px!important',
        fontFamily: 'Roboto',
        fontWeight: '400!important',
        lineHeight: '14.06px!important',
        letterSpacing: '0.4px!important',
        textTransform: 'capitalize!important',
        marginTop: '45px!important',
        width: '63px!important',
        height: '24px!important',
        color: customColorPalette.black[54] + '!important',
        border: '1px solid rgba(0, 0, 0, 0.54)!important',
        '&:hover': {
            border: '1px solid rgba(0, 0, 0, 0.54)!important'
        }

    },
    roleButton: {
        height: '32px!important',
        borderRadius: '25px!important',
        color: '#000000!important',
        borderColor: '#6EADEA!important',
        fontSize: '12px!important',
        fontFamily: 'Roboto!important',
        fontWeight: '400!important',
        lineHeight: '14.06px!important',
        letterSpacing: '0.4px!important',
        textTransform: 'capitalize!important',
        marginTop: '45px!important',
        '&:hover': {
            color: '#6EADEA!important',
        },

    },

    chip: {
        //color: '#2196F3!important',
        //borderColor: '#6EADEA!important',
        fontFamily: 'Roboto!important',
        fontStyle: 'normal!important',
        fontWeight: 'normal!important',
        fontSize: '13px!important',
        lineHeight: '15px!important',
        paddingLeft: '7px!important',

    },
    chipLabelText: {
        color: '#000000!important',
    },

    roleItem: {
        flexWrap: 'wrap!important'


    },

    roleItemText: {
        fontFamily: 'Roboto!important',
        fonStyle: 'normal!important',
        fontWeight: 'normal!important',
        fontSize: '13px!important',
        lineHeight: '15px!important',
        display: 'flex!important',
        alignItems: 'center!important',
        '&:hover': {
            color: '#6EADEA!important',
        },
    },

    dateGrid: {
        //[theme.breakpoints.up('sm')]: {
        //    maxWidth: '330px!important',
        //    minWidth: '330px!important'
        //},
        //[theme.breakpoints.up('md')]: {
        //    maxWidth: '330px!important',
        //    minWidth: '330px!important'
        //}
        //,
        [theme.breakpoints.up('lg')]: {
            maxWidth: '330px!important',
            minWidth: '330px!important'
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: '165px!important',
            minWidth: '165px!important'
        }

    },
    avatarGrid: {
        maxWidth: "280px!important",
        minWidth: "280px!important"
    },
    avatarGridUpload: {
        textAlign: 'right!important',
        paddingTop: '20px!important',
        paddingRight:'8px!important'
    },
    avatarGridRemove: {
        textAlign: 'left!important',
        paddingTop: '20px!important'
    },
    formControlSelect: {
        width: "100%"
    },
    passWordMatchColor: {
        color: "green!important"
    },

    buttonWrapper: {
        [theme.breakpoints.up('md')]: {
            paddingTop: "32px",
            paddingBottom: "32px"

        }
    },

    buttonOuterWrapper: {
        [theme.breakpoints.up('lg')]: {
            height: "72px"

        }
    },
    createdDate: {
        fontSize: '12px!important',
        fontFamily: 'Roboto!important',
        fontWeight: '400!important',
        lineHeight: '14.06px!important',
        letterSpacing: '0.4px!important',
        textAlign: 'center!important',
        color: '#989FA6!important',

    },


    controlWrapper: {
        [theme.breakpoints.up('lg')]: {
            paddingRight: "104px"

        },
        [theme.breakpoints.down('lg')]: {
            paddingRight: "24px"

        }
    },

    buttonContainer: {

        maxWidth: "180px",
        float: "right",

        //[theme.breakpoints.up('xs')]: {
        //    float: "left"

        //}
    },


    buttonItem: {
        [theme.breakpoints.down('sm')]: {
            paddingRight: "8px"

        }
      
    },
    buttonSaveItem: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: "5px"

        }

    },
     createdDateItem: {
        [theme.breakpoints.down('md')]: {
            paddingRight: "9px"

        }

    },
    buttonItem: {

        [theme.breakpoints.down('sm')]: {
            minWidth: "130px!important"

        }
        

    }

        
    
});

