import { ActionTypes as types, section } from './actionTypes';
import adminApi from 'admin/api/adminApi';
import { getError } from './errorActions';
import { showError, showSuccess } from './alertActions';
const timeout = 5000;
const apiGetSystemSettings = () => {
    return adminApi.getSystemSettings();
};

const apiSmtpSettings = (emailSettings) => {
    return adminApi.checkSmtpSettings(emailSettings)
};

const apiUpdateSystemSettings = (systemSettings) => {
    return adminApi.updateSystemSettings(systemSettings);
};

const apiUpdateEmailSettings = (emailSettings) => {
    return adminApi.updateEmailSettings(emailSettings);
};

const apiUpdatePasswordSettings = (passwordSettings) => {
    return adminApi.updateResetPasswordSettings(passwordSettings);
};

const apiGetVersion = () => {
    return adminApi.getVersion();
};


const apiUpdateSettings = (settings, sectionType) => {


    if (sectionType === section.RecordCount) {
       
        return adminApi.updateRecordCountSettings({ RecordCount: settings.recordCount });
    }
    else if (sectionType === section.BizweaverToken) {
        
        return adminApi.updateBizweaverSettings({ BizweaverToken: settings.bizweaverToken });

    }

    else if (sectionType === section.SessionPeriod) {

        const settingsData = Object.assign({}, settings)
        const sessionData = Object.assign({}, settingsData.sessionTime)
        sessionData.hours = sessionData.hours ? parseInt(sessionData.hours) : 0
        sessionData.minutes = sessionData.minutes ? parseInt(sessionData.minutes) : 0
        sessionData.seconds = sessionData.seconds ? parseInt(sessionData.seconds) : 0
        settingsData.sessionTime = sessionData;
        return adminApi.updateSessionPeriodSettings(sessionData);

    } else if (sectionType === section.TimeZone) {

        return adminApi.updateTimeZoneSettings({ SystemTimeZone: settings.systemTimeZone });

    }
    else if (sectionType === section.FileUpload) {
        return adminApi.updateFileUploadSettings({ FormFileSize: settings.formFileSize, AllowedExtensions: settings.allowedExtensions });

    }
    else if (sectionType === section.InstallationId) {
        return adminApi.updateInstallationIdSettings(settings);

    }
    else if (sectionType === section.PaymentGateWay) {
        return adminApi.updatePaymentGatewaySettings(settings);

    }
};


const getUpdateSystemSettingsRequest = (systemSettings) => {
    return {
        type: types.VGO_UPDATE_SYSTEM_SETTINGS_REQUEST,
        user: systemSettings
    };
};

const getUpdateEmailSettingsRequest = (emailSettings) => {
    return {
        type: types.VGO_UPDATE_EMAIL_SETTINGS_REQUEST,
        email: emailSettings
    };
};


const getUpdatePasswordSettingsRequest = (passwordSettings) => {
    return {
        type: types.VGO_UPDATE_PASSWORD_SETTINGS_REQUEST,
        password: passwordSettings
    };
};


const getUpdateSettinsRequest = (settings) => {
    return {
        type: types.VGO_UPDATE_SETTINGS_REQUEST,
        settings: settings
    };
};


const getUpdateSystemSettingsSuccess = (resp) => {
    return {
        type: types.VGO_UPDATE_SYSTEM_SETTINGS_SUCCESS,
        systemSettings: resp.data
    };
};

const getUpdateEmailSettingsSuccess = (resp) => {
    return {
        type: types.VGO_UPDATE_EMAIL_SETTINGS_SUCCESS,
        emailSettings: resp.data
    };
};

const getUpdatePasswordSettingsSuccess = (resp) => {
    return {
        type: types.VGO_UPDATE_PASSWORD_SETTINGS_SUCCESS,
        passwordSettings: resp.data
    };
};

const getUpdateSettingsSuccess = (resp) => {
    return {
        type: types.VGO_UPDATE_SETTINGS_SUCCESS,
        settings: resp.data
    };
};


const getSystemSettingsRequest = () => {
    console.log('getSiteSettingsRequest');
    return {
        type: types.VGO_GET_SYSTEM_SETTINGS_REQUEST
    }
};

const getCheckMailRequest = (status) => {
    return {
        type: types.VGO_GET_EMAIL_CHECK_REQUEST,
        status: status
    }
};

const getSystemSettingsSuccess = (resp) => {
    console.log('getSiteSettingsSuccess', resp);
    return {
        type: types.VGO_GET_SYSTEM_SETTINGS_SUCCESS,
        systemSettings: resp.data
    };
};

const getVersionSuccess = (resp) => {
    return {
        type: types.VGO_GET_VERSION_SUCCESS,
        version: resp.data
    };
};

const showSettingsSuccess = (message, section, alertData) => {
    const severity = section + 'Severity';
    const sectionMessage = section + 'Message';

    let alert = { [severity]: 'success', [message]: message, [section]: true }

    if (alertData !== null) {
        const modifyData = Object.assign({}, alertData)
        modifyData[section] = true;
        modifyData[sectionMessage] = message;
        modifyData[severity] = 'success';
        alert = modifyData
    }

    return {
        type: types.VGO_GET_EMAIL_SETTINGS_ALERT_SUCCESS,
        alert
    };
};

const showSettingsError = (message, section, alertData) => {
    const severity = section + 'Severity';
    const sectionMessage = section + 'Message';
    let alert = { [severity]: 'error', [sectionMessage]: message, [section]: true }
    if (alertData !== null) {
        const modifyData = Object.assign({}, alertData)
        resetAlert(modifyData);
        modifyData[section] = true;
        modifyData[sectionMessage] = message;
        modifyData[severity] = 'error';
        alert = modifyData
    }
    return {
        type: types.VGO_GET_EMAIL_SETTINGS_ALERT_SUCCESS,
        alert
    };
};

const resetEmailObject = (data) => {

    let settings = {
        sender: "",
        mailServer: "",
        username: "",
        password: "",
        mailPort: "",
        enableSSL: false,
        senderName: ""
    }
    if (data) {
        settings = data;
    }
    return {
        type: types.VGO_GET_SYSTEM_SETTINGS_CLEAR_SUCCESS,
        emailSettings: settings
    };
};

const resetPasswordDataObject = (data) => {

    let settings = {
        fromEmail: '',
        subject: '',
        message: '',
    }
    if (data) {
        settings = data;
    }

    return {
        type: types.VGO_GET_SYSTEM_RESET_PASSWORD_CLEAR_SUCCESS,
        passwordSettings: settings
    };
};


const resetRecordCountObject = (recordCount) => {


    return {
        type: types.VGO_UPDATE_RECORD_COUNT_CLEAR_SUCCESS,
        recordCount: recordCount

    };
};

const resetBizweaverTokenObject = (data) => {


    return {
        type: types.VGO_UPDATE_BIZWEAVER_TOKEN_CLEAR_SUCCESS,
        token: data

    };
};

const resetSessionPeriodObject = (sessionPeriod,data) => {
    let sessionTime = { hours: 0, minutes: sessionPeriod, seconds: 0 }
    if (data) {
        sessionTime = { hours: data.hours, minutes: data.minutes, seconds: 0 }

    }

    return {
        type: types.VGO_UPDATE_SESSION_PERIOD_CLEAR_SUCCESS,
        sessionTime

    };
};

const resetAlert = (alertData) => {
    for (var item in alertData) {

        if (alertData.hasOwnProperty(item)) {
           
            if (item.indexOf(section.RecordCount + 'Severity') !== -1 && alertData[item] === 'success') {
                alertData[section.RecordCount] = false;

            }
            else if (item.indexOf(section.BizweaverToken + 'Severity') !== -1 && alertData[item] === 'success') {

                alertData[section.BizweaverToken] = false;
            }
            else if (item.indexOf(section.EmailSettings + 'Severity') !== -1 && alertData[item] === 'success') {

                alertData[section.EmailSettings] = false;
            }
            else if (item.indexOf(section.FileUpload + 'Severity') !== -1 && alertData[item] === 'success') {

                alertData[section.FileUpload] = false;
            }
            else if (item.indexOf(section.ResetPassword + 'Severity') !== -1 && alertData[item] === 'success') {

                alertData[section.ResetPassword] = false;
            }
            else if (item.indexOf(section.SessionPeriod + 'Severity') !== -1 && alertData[item] === 'success') {

                alertData[section.SessionPeriod] = false;
            }

            else if (item.indexOf(section.TimeZone + 'Severity') !== -1 && alertData[item] === 'success') {

                alertData[section.TimeZone] = false;
            }
            else if (item.indexOf(section.InstallationId + 'Severity') !== -1 && alertData[item] === 'success') {

                alertData[section.InstallationId] = false;
            }


        }

    }

    return alertData
}

const resetTimeZoneObject = (data) => {
    return {
        type: types.VGO_UPDATE_TIMEZONE_CLEAR_SUCCESS,
        data: data


    };
};

const resetInstallationObject = (data) => {
   return {
        type: types.VGO_UPDATE_INSTALLATION_CLEAR_SUCCESS,
        data: data


    };
};

const resetPaymentObject = (data) => {
    return {
        type: types.VGO_UPDATE_PAYMENT_GATEWAY_SUCCESS,
        data: data


    };
};

const resetFileUploadObject = (size, format) => {
    return {
        type: types.VGO_UPDATE_FILE_UPLOAD_CLEAR_SUCCESS, size:size, format:format


    };
};





const hideSettingsError = (sectionType,alertData) => {
    let alert = {};
    const severity = sectionType + 'Severity';
    const message = sectionType + 'Message';
    if (alertData !== null) {
        const modifyData = Object.assign({}, alertData)
        resetAlert(modifyData);
       modifyData[sectionType] = false;
        modifyData[message] = '';
        modifyData[severity] = '';
        alert = modifyData
    }
    else {
        alert = {
            severity: '',
            message: '',
            [section.EmailSettings]: false,
            [section.ResetPassword]: false,
            [section.RecordCount]: false,
            [section.BizweaverToken]: false,
            [section.SessionPeriod]: false,
            [section.TimeZone]: false,
            [section.PaymentGateWay]: false,
        }
    }

    return {
        type: types.VGO_GET_EMAIL_SETTINGS_ALERT_SUCCESS,
        alert
    };
};


export const getChangeSystemSettings = (change) => {

    return {
        type: types.VGO_CHANGE_SYSTEM_SETTINGS,
        change
    };
}



export const checkSmtpSettings = (emailSettings, sectionType, alertData) => {
    return dispatch => {
        dispatch(getCheckMailRequest(true));
        return apiSmtpSettings(emailSettings)
            //  adminApi.checkSmtpSettings(emailSettings)
            .then((resp) => {
                dispatch(showSettingsSuccess('Test was successful!', sectionType, alertData));
                setTimeout(() => dispatch(hideSettingsError(sectionType, alertData)), timeout);
            })
            .catch(err => {
                //  const { message } = err;
                dispatch(showSettingsError('Error! Check settings and try again.', section.EmailSettings));

            });
    }
};

/**
 * Dispatch methods
 */


export const getSystemSettings = () => {

    return dispatch => {
        dispatch(getSystemSettingsRequest());
        return apiGetSystemSettings()
            .then((resp) => {
                dispatch(getSystemSettingsSuccess(resp));
            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
            });
    }
};

export const getVersion = () => {

    return dispatch => {
        return apiGetVersion()
            .then((resp) => {
                dispatch(getVersionSuccess(resp));
            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
            });
    }
};

export const updateSystemSettings = (systemSettings) => {
    return dispatch => {
        dispatch(getUpdateSystemSettingsRequest(systemSettings));
        return apiUpdateSystemSettings(systemSettings)
            .then((resp) => {
                dispatch(getUpdateSystemSettingsSuccess(resp));
                //dispatch(showMessage('Action completed successfully ','success'));
                showSuccess(dispatch, 'Success', 'System Settings saved successfully', timeout);
            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
                showError(dispatch, message, timeout);
            });
    };
};

export const updateEmailSettings = (emailSettings, sectionType, alertData) => {
    return dispatch => {
        dispatch(getUpdateEmailSettingsRequest(emailSettings));
        return apiUpdateEmailSettings(emailSettings)
            .then((resp) => {
                dispatch(getUpdateEmailSettingsSuccess(resp));
                dispatch(showSettingsSuccess('Email Server saved successfully!', sectionType, alertData));
                setTimeout(() => dispatch(hideSettingsError(sectionType, alertData)), timeout);
                })
            .catch(err => {
                const { message } = err;
                dispatch(showSettingsError(message, section.EmailSettings));

            });
    };
};

export const updatePasswordSettings = (passwordSettings ,sectionType, alertData) => {
    return dispatch => {
        dispatch(getUpdatePasswordSettingsRequest(passwordSettings));
        return apiUpdatePasswordSettings(passwordSettings)
            .then((resp) => {
                dispatch(getUpdatePasswordSettingsSuccess(resp));
                dispatch(showSettingsSuccess('Password Reset saved successfully!', sectionType, alertData));
                setTimeout(() => dispatch(hideSettingsError(sectionType, alertData)), timeout);
                })
            .catch(err => {
                const { message } = err;
                dispatch(showSettingsError(message, section.ResetPassword));

            });
    };
};


export const updateSettingsData = (settings, sectionType,alertData) => {
    return dispatch => {
        dispatch(getUpdateSettinsRequest(settings));
        return apiUpdateSettings(settings, sectionType)
            .then((resp) => {
                
                if (sectionType === section.RecordCount) {
                    dispatch(resetRecordCountObject(resp.data.recordCount));
                    dispatch(showSettingsSuccess('DataView Record Threshold saved successfully!', sectionType, alertData));
                } else if (sectionType === section.BizweaverToken) {
                    dispatch(resetBizweaverTokenObject(resp.data.bizweaverToken));
                    dispatch(showSettingsSuccess('Bizweaver Token saved successfully!', sectionType, alertData));

                }
                else if (sectionType === section.SessionPeriod) {
                    dispatch(resetSessionPeriodObject('',resp.data.sessionTime));
                    dispatch(showSettingsSuccess('Session Inactive Period saved successfully!', sectionType, alertData));

                }
                else if (sectionType === section.TimeZone) {
                    dispatch(resetTimeZoneObject(resp.data.systemTimeZone));
                    dispatch(showSettingsSuccess('Time Zone Versago - Server saved successfully!', sectionType, alertData));

                }

                else if (sectionType === section.FileUpload) {
                    dispatch(resetFileUploadObject(resp.data.formFileSize, resp.data.allowedExtensions));
                    dispatch(showSettingsSuccess('Form File Upload settings saved successfully!', sectionType, alertData));

                }

                else if (sectionType === section.InstallationId) {
                    dispatch(resetInstallationObject(resp.data.installationID));
                    dispatch(showSettingsSuccess('Installation ID saved successfully!', sectionType, alertData));

                }
                else if (sectionType === section.PaymentGateWay) {
                    dispatch(resetPaymentObject(resp.data));
                    dispatch(showSettingsSuccess('Payment Gateway saved successfully!', sectionType, alertData));

                }


                setTimeout(() => dispatch(hideSettingsError(sectionType, alertData)), timeout);
            })
            .catch(err => {
                const { message } = err;

                dispatch(showSettingsError(message, sectionType, alertData));

            });
    };
};


export const showNotification = (message, section,alertData) => {
    return dispatch => {

        dispatch(showSettingsError(message, section, alertData));


    };
};

export const clearEmailSection = (sectionType, alertData) => {
    return dispatch => {

        dispatch(resetEmailObject());
        dispatch(hideSettingsError(sectionType,alertData));

    };
};


export const clearResetPasswordSection = (sectionType, alertData) => {
    return dispatch => {

        dispatch(resetPasswordDataObject());
        dispatch(hideSettingsError(sectionType, alertData));

    };
};


export const clearRecordCountSection = (recordCount, sectionType, alertData) => {
    return dispatch => {

        dispatch(resetRecordCountObject(recordCount));
        dispatch(hideSettingsError(sectionType, alertData));

    };
};

export const clearBizweaverSection = (sectionType, alertData) => {
    return dispatch => {

        dispatch(resetBizweaverTokenObject(''));
        dispatch(hideSettingsError(sectionType, alertData));

    };
};


export const clearSessionPeriodSection = (sessionPeriod, sectionType, alertData) => {
    return dispatch => {

        dispatch(resetSessionPeriodObject(sessionPeriod));
        dispatch(hideSettingsError(sectionType, alertData));

    };
};


export const clearTimeZoneSection = (sectionType, alertData) => {
    return dispatch => {

        dispatch(resetTimeZoneObject('none'));
        dispatch(hideSettingsError(sectionType, alertData));

    };
};

export const clearInstallationSection = (sectionType, alertData) => {
    const data = { installationID: '', activeUsers: 0, licensedUsers: 0,message:'' }
    return dispatch => {

        dispatch(resetInstallationObject(data));
        dispatch(hideSettingsError(sectionType, alertData));

    };
};

export const clearPaymentGatewaySection = (sectionType, alertData) => {
    const data = {
        paymentGateway: "authorizeDotNet",
        merchantUserID: "",
        merchantPassword: "",
        isCCDemoAccount: false,
        iseCheck: false,
        isECheckDemoAccount: false,
        hasVisa: false,
        hasMaster: false,
        hasAmex: false,
        hasDiscover: false,
isCreditCard: true,
payeezy_HMACKey: "",
payeezy_KeyID: ""
    }
    return dispatch => {

        dispatch(resetPaymentObject(data));
        dispatch(hideSettingsError(sectionType, alertData));

    };
};


export const clearFileUploadSection = (size, format, sectionType, alertData) => {
    return dispatch => {

        dispatch(resetFileUploadObject(size, format));
        dispatch(hideSettingsError(sectionType, alertData));

    };
};



export const hideSettingsMessage = (sectionType, alertData) => {
    return dispatch => {

        dispatch(hideSettingsError(sectionType, alertData));


    };
};
