import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withStyles } from '@mui/styles';
import { Typography, Box, Switch, Card } from '@mui/material'
import MuiTextField from '@mui/material/TextField';
import {
    DragDropContext,
    Droppable,
    Draggable,
    DraggableProvided,
    DroppableProvided,
    DraggableStateSnapshot
} from "react-beautiful-dnd";
import {
    TableBody,
    TableCell,
    TableRow, Grid
} from "@mui/material";
import { APILinksTheme } from './theme/APILinksTheme';
import ControlCameraTwoToneIcon from '@mui/icons-material/ControlCameraTwoTone';
import LinkTwoToneIcon from '@mui/icons-material/LinkTwoTone';

import IconButton from '@mui/material/IconButton';
 
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { spacing } from '@mui/system';
import MUIDataTable from 'mui-datatables';
import Delete from '@mui/icons-material/DeleteOutline';
import { styled } from '@mui/styles';
import { getCustomWidgetList, changeActiveWidget, getChangeCustomWidget, changeDisplayOrder, deleteLinkDetail, newLinkDetail, onFieldBlur} from 'admin/actions/customWidgetAction';
 
import { WidgetType } from 'client/components/Common/Enum';
const TextField = styled(MuiTextField)(spacing);

 
class APILinks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeItemIndex: 1,
            activeItem: {}
        }
        
    }
    handleDragEnd = (result, provided) => {  
        
        if (result.source != null && result.destination != null) {
            this.props.dispatch(changeDisplayOrder(this.props.customWidgets, result.source.index, result.destination.index));
        }
    }
    componentDidMount() {
        this.dispatchGetCustomWidgetList(); 
    }
    
    dispatchGetCustomWidgetList() {
        this.props.dispatch(getCustomWidgetList());
    }
    
    handleClick = (item) => {
        this.props.dispatch(changeActiveWidget(item.displayOrder, this.props.activeWidget));
    }
    getLinkTitleFromType = (linkType) => {
        switch (linkType) {
            case WidgetType.Weather: {
                return "Weather";
              
            } 
            case WidgetType.Links: {
                return "Links";
                
            }
            case WidgetType.Quote: {
                return "Quote";
                
            }
            default: {
                return "Other";
                 
            }
        }
    }
    getLinkObject = (item) => {
        const { classes } = this.props;
        return (
            <List className={classes.listItemObjectinner} >
                <ListItem disablePadding  >
                    <ListItemAvatar>
                        <Avatar>
                            {item.type === WidgetType.Links? <LinkTwoToneIcon /> : <ControlCameraTwoToneIcon />}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={this.getLinkTitleFromType(item.type) } className={classes.subtitle1} />
                    <Switch checked={item.isEnabled} className={classes.switchIcon}
                        onChange={(event) => {
                        this.handleWidgetItemChange(item => item.isEnabled = event.target.checked) 
                    }}/>
                </ListItem>
            </List>
        );
    }
    handleWidgetItemChange = (item) => {
        this.props.dispatch(getChangeCustomWidget(item, this.props.activeWidget))
    }
    getDetailObject = () => {
        if (this.props.customWidgets.length > 0) {
            var item = this.props.activeWidget;
            const { classes } = this.props;
            
            return (
                <Grid container direction="row" justifyContent="space-between" spacing={3}>
                   
                    <Grid item xs={12}>
                        <TextField
                            className={classes.shortText}
                            helperText=" "
                            disabled
                            size="small"
                            label="Name"
                            value={item.name}

                            onChange={(event) => {
                                this.handleWidgetItemChange(item => item.name = event.target.value)
                               
                            }}
                         
                         placeholder="Name" 

                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            className={classes.longText}
                            helperText=" "
                            size="small"
                            label="Description"
                            inputProps={{ maxLength: 200}}
                            value={item.description}
                            onChange={(event) => {
                                this.handleWidgetItemChange(item => item.description = event.target.value)
                                
                            }} 
                        />
                    </Grid>
                    {item.type !== WidgetType.Links && <Grid item xs={12}>
                        <TextField
                            className={classes.longText}
                            helperText=" "
                            size="small"
                            label="URL"
                            disabled
                            inputProps={{ maxLength: 250 }}
                            value={item.apiUrl}
                            error={this.props.validationError.apiUrl}
                            helperText={this.props.validationError.apiUrl ? this.props.validationError.apiUrl:' '}
                            onChange={(event) => {
                                this.handleWidgetItemChange(item => item.apiUrl = event.target.value)
                            }}
                            onBlur={(event) => {
                                this.props.dispatch(onFieldBlur(this.props.activeWidget))
                            }}

                        />
                    </Grid>}
                    {item.type === WidgetType.Weather && <Grid item xs={12}>
                        <TextField
                            className={classes.shortText}
                            helperText=" "
                            disabled
                            size="small"
                            label="Key"
                            inputProps={{ maxLength: 50 }}
                            value={item.apiKey}
                            onChange={(event) => {
                                this.handleWidgetItemChange(item => item.apiKey = event.target.value)
                                
                            }}
                        />
                    </Grid>
                    }
                    {item.type === WidgetType.Weather && <Grid item xs={12}>
                        <Typography> {"Provided by "}
                            <a target="_blank" rel="noopener noreferrer" href="https://www.weatherapi.com/" title="Weather API">WeatherAPI.com</a>
                        </Typography>
                    </Grid>
                    }
                    {item.type === WidgetType.Links && <Grid item xs={12}>
                        <Grid container direction="row" justifyContent="space-between" spacing={3}>
                            <Grid item xs={12}>

                                {this.getDetilObjectLinkGrid(item.details)}

                            </Grid>
                        </Grid>
                        </Grid>}
                         
                </Grid>
            )
        }
    }
   
    getDetilObjectLinkGrid = (details) => {
        const { classes } = this.props;
        
        let parameterGrid = {
            columns: [
                {
                    name: "id",
                    label: "",
                    options: {
                        display: false,
                    }
                },
                {
                    name: "urlCaption",
                    label: "Caption",
                    options: {
                        setCellHeaderProps: (cellValue, rowIndex, columnIndex) => {
                            return {
                                style: {
                                   // paddingLeft: 0,
                                    paddingRight: 0,
                                    borderBottom: 0,
                                    width:500,
                                },
                            };
                        },
                        setCellProps: (cellValue, rowIndex, columnIndex) => {
                            return {
                                style: {
                                    width: 500,
                                  //  paddingLeft: 0,
                                    // backgroundColor: 'white'
                                    borderBottom:0
                                },
                            };
                        },
                        customBodyRender: this.customRendercaption,
                    }

                },
                {
                    name: "url",
                    label: "URL",
                    options: {
                        setCellHeaderProps: (cellValue, rowIndex, columnIndex) => {
                            return {
                                style: {
                                    // paddingLeft: 0,
                                    paddingRight: 0,
                                    borderBottom: 0,
                                    width: 1000,
                                },
                            };
                        },
                        setCellProps: (cellValue, rowIndex, columnIndex) => {
                            return {
                                style: {
                                    width: 1000,
                                    paddingRight: 0,
                                    //backgroundColor: 'white'
                                    borderBottom: 0
                                },
                            };
                        },
                        customBodyRender: this.customRenderURL,
                    }

                }, 
                {
                    name: "Delete",
                    label: " ",
                    options: {
                        setCellHeaderProps: (cellValue, rowIndex, columnIndex) => {
                            return {
                                style: {
                                    // paddingLeft: 0,
                                    paddingRight: 60,
                                    borderBottom: 0, 
                                    width: 20,
                                    float: "right", 
                                },
                            };
                        },
                        setCellProps: (cellValue, rowIndex, columnIndex) => {
                            return {
                                style: {
                                    paddingRight: 60,
                                    borderBottom: 0,
                                    width: 20,
                                    float: "right",
                                },
                            };
                        },
                        customBodyRender: this.customRenderDelete,
                    }

                }
            ],
            options: {
                download: false,
                elevation: 0,
                filter: false,
                print: false,
                search: false,
                viewColumns: false,
                pagination: false,
                selectableRows: "none",
                responsive: 'scrollMaxHeight',
                toolbar: {
                    display: false
                },
            }
        }
        return (
            <Grid container direction="row" justifyContent="space-between"> 
                <Grid item xs={12} >
                    <Grid item xs={12} pb={4}>
                        <Card >
                            <MUIDataTable container
                                className={ classes.detailGrid}
                            title={''}
                            data={details}
                            columns={parameterGrid.columns}
                            options={parameterGrid.options}
                            />
                            </Card >
                    </Grid>
                </Grid>
            </Grid>
        );
    }
    customRendercaption = (value, tableMeta, updatedValue) => {
        const { classes } = this.props; 
        return (<TextField className={classes.gridCaption} value={value}
            helperText={this.props.activeWidget.details && this.props.activeWidget.details[tableMeta.rowIndex].captionHelperText ? this.props.activeWidget.details[tableMeta.rowIndex].captionHelperText ? this.props.activeWidget.details[tableMeta.rowIndex].captionHelperText : ' ' : " "}
        error={this.props.activeWidget.details && this.props.activeWidget.details[tableMeta.rowIndex].captionHelperText ? this.props.activeWidget.details[tableMeta.rowIndex].captionHelperText!==" "?true:false:false}
            inputProps={{ maxLength: 100 }}
            onChange={(event) => {
                this.handleWidgetItemChange(
                    item => {
                        item.details[tableMeta.rowIndex].urlCaption = event.target.value;
                        if (event.target.value.trim() !== "") 
                        item.details[tableMeta.rowIndex].captionHelperText = " ";
                        return item;
                    })}
            }
            onBlur={(event) => {
                this.addNewDetailItem(tableMeta.rowIndex);
            }}
        > </TextField>) 
    }
    customRenderURL = (value, tableMeta, updatedValue) => {
        const { classes } = this.props; 
        return (<TextField value={value}
            helperText={this.props.activeWidget.details && this.props.activeWidget.details[tableMeta.rowIndex].urlHelperText ? this.props.activeWidget.details[tableMeta.rowIndex].urlHelperText ? this.props.activeWidget.details[tableMeta.rowIndex].urlHelperText : ' ' : " "}
            error={this.props.activeWidget.details && this.props.activeWidget.details[tableMeta.rowIndex].urlHelperText ? this.props.activeWidget.details[tableMeta.rowIndex].urlHelperText!==" " ? true : false:false}
            inputProps={{ maxLength: 100 }}
            onChange={(event) => {
                this.handleWidgetItemChange(item => {
                    item.details[tableMeta.rowIndex].url = event.target.value;
                    if (event.target.value.trim() !== "")
                    item.details[tableMeta.rowIndex].urlHelperText = " ";
                })}
            }
        > </TextField>)
    }
    customRenderDelete = (value, tableMeta, updatedValue) => { 
         return (<IconButton id={1} type="button" onClick={(e) => {
                if (tableMeta.rowIndex + 1 < this.props.activeWidget.details.length) {
                    this.props.dispatch(deleteLinkDetail(this.props.customWidgets, this.props.activeWidget.type, tableMeta.rowIndex));
                }
            }}> <Delete /></IconButton> )
    }
    addNewDetailItem = (rowIndex) => {
        var activeWidget = this.props.activeWidget;
        if (activeWidget.details.length === rowIndex+1) {
            if (activeWidget.details[rowIndex].urlCaption !== "" || activeWidget.details[rowIndex].url !== "") {
                this.props.dispatch(newLinkDetail(this.props.customWidgets, this.props.activeWidget.type, rowIndex));
            }
        }
     }
    render()
    {
        const { classes } = this.props;
        const CustomTableCell = withStyles({
            root: {
                borderBottom: "none !important", 
            }
        })(TableCell);

      
        var APILinks = this.props.customWidgets;
//        [{ Id: 0, name: "Weather", description: "Weather", isEnabled: false, apiKey: "07b2ded7da2d4e41bc4153341170302", apiUrl: "https://api.apixu.com/v1/current.json?key=[ApiId]&q=[zipCode]", type: "weater", displayOrder: 0, details: [] },
        //    { Id: 0, name: "Other", description: "Other", isEnabled: false, apiKey: "", apiUrl: "https://api.icndb.com/jokes/random?exclude=[explicit]", type: "other", displayOrder: 0, details: [] },
        //    { Id: 0, name: "Link", description: "Link", isEnabled: false, apiKey: "", apiUrl: "", type: "link", displayOrder: 0, details: [{urlCaption:"",url:"",Id:0}] },
        //    { Id: 0, name: "Quote", description: "Quote", isEnabled: false, apiKey: "", apiUrl: "", type: "quote", displayOrder: 0, details: [] }
        //]; 
        //if (this.props.customWidgets.length > 0) {
        //    APILinks = this.props.customWidgets;
        //}
            //{ index: 1, caption: "Quotes", displayOrder: 1, details: [] }, { index: 2, caption: "Other", displayOrder: 2, details: [] },
            //{ index: 3, caption: "Links", displayOrder: 3, details: [{ id: 0, caption: "test", url: "testurl" }, { id: 1, caption: "test1", url: "testurl1" }] }]
        return (
           // <Box sx={{ boxShadow: 2 }} className={classes.contentBox}>
            <Card className={classes.contentBox}>
                <Grid container direction="row" justifyContent="space-between" >
                    <Grid item xs={12} pt={2.5} pl={3}>
                        <Typography> Information </Typography>
                   </Grid>
                    <Grid item xs={2.75}>
                        <Box pt={4} pl={3}>
                            <Card className={classes.listItemObject}  >
                       {/* <Box pt={3} pl={3}>*/}
                           
                            <DragDropContext onDragEnd={this.handleDragEnd} >
                                <Droppable droppableId={"droppable"} direction="vertical">
                                    {(droppableProvided: DroppableProvided) => (
                                        <TableBody className={classes.listItemObjectinner}
                                            ref={droppableProvided.innerRef}
                                            {...droppableProvided.droppableProps}
                                        >
                                            {APILinks.map((item: DataItem, index: number) => (
                                                <Draggable
                                                    key={index}
                                                    draggableId={index.toString()}
                                                    index={index} isDragDisabled={false}
                                                   
                                                >
                                                    {(draggableProvided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
                                                        return (
                                                            <TableRow key={item.Id} className={classes.linkObject} onClick={() => this.handleClick(item)}
                                                                ref={draggableProvided.innerRef}
                                                                {...draggableProvided.draggableProps}>
                                                                <CustomTableCell className={(this.props.activeWidgetIndex === item.displayOrder)?classes.activeItem:classes.inActiveItem}  >
                                                                    <div {...draggableProvided.dragHandleProps} >
                                                                        {this.getLinkObject(item)}
                                                                    </div>
                                                                </CustomTableCell> 
                                                                

                                                            </TableRow>
                                                        );
                                                    }}
                                                </Draggable>
                                            ))}
                                            {droppableProvided.placeholder}
                                        </TableBody>
                                    )}
                                </Droppable>
                            </DragDropContext>
                         
                            </Card></Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box pt={1.3} >
                            {this.getDetailObject()}
                        </Box>
                    </Grid>
                    <Grid item xs={2.25}>
                    </Grid>
                </Grid>
            </Card>
        )
    }
}
function mapStateToProps(state) {    
    return {
        customWidgets: state.customWidgets.customWidgets,
        activeWidgetIndex: state.customWidgets.activeWidgetIndex,
        activeWidget: state.customWidgets.activeWidget,
        validationError: state.customWidgets.widgetValidation,

    };
}
export default withRouter(connect(mapStateToProps)(withStyles(APILinksTheme)(APILinks)));

 