import React from 'react';

import { styled } from '@mui/styles';
import { spacing } from '@mui/system';
import { Grid, Typography } from '@mui/material';
import MuiButton from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check'

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { triggerFormSubmit, updateForms, showErrorWithValidationMessage} from 'admin/actions/formSetupActions';
import { showError } from 'admin/actions/alertActions';
import { ActionTypes as actionTypes } from 'admin/actions/actionTypes';
import ActionBackdrop from 'admin/components/section/settings/Backdrop';
import { setupTheme } from 'admin/components/section/form/setup/theme/setupTheme';
import { clearError } from 'admin/actions/errorActions';
import { withStyles } from '@mui/styles';
const Button = styled(MuiButton)(spacing);

class FormEditHeader extends React.Component {

    constructor(props) {
        super(props);
        this.isUpdate = this.props.match.params.formId !== "new";
    }

    componentDidUpdate() {
        if (this.props.match.params.formId === "new" && this.props.form.formId !== 0) {
            this.isUpdate = true;
        }

    }

    onDiscardClick = () => {
        this.props.dispatch(clearError());
        this.props.history.push('/Admin/Forms');
    }

    onSaveClick = () => {


        if (this.props.hasError && this.props.forms.validationObject.length>0) {
           // 
            this.props.dispatch(showErrorWithValidationMessage(this.props.forms.validationObject));
            showError(this.props.dispatch, this.props.errorMessage, 5000)

        } else {

            if (this.props.activeTabIndex === 0) {
                this.props.dispatch(triggerFormSubmit());
            } else {

                this.props.dispatch(updateForms(this.props.formSetup, this.props.primaryTableFields.list, this.props.detailTableFields.list, this.isUpdate));

            }

        }

    }

    render() {
        const { classes } = this.props;
        return (
            <Grid container direction="row" justifyContent="space-between" spacing={2}>
                <Grid item className={classes.editHeader}>
                    <Typography variant="h4">{this.isUpdate ? "Edit" : "Add"} Form</Typography>
                    {this.props.actionType === actionTypes.VGO_UPDATE_FORM_REQUEST && <ActionBackdrop />}
                </Grid>
                <Grid item className={classes.formName} xs>
                    <Typography variant="h5">{this.props.form.formName ? this.props.form.formName : ""}</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Grid container direction="row" justifyContent="flex-end" spacing={1} >
                        <Grid item>
                            <Button className={`${classes.customControlButton} ${classes.buttonCancel}`}
                                variant="defaultContained"
                                disableElevation
                                onClick={this.onDiscardClick}

                            >CANCEL</Button>
                        </Grid>
                        <Grid item>
                            <Button className={`${classes.customControlButton} ${classes.buttonSave}`}

                                color="primary"
                                variant="contained"
                                disableElevation
                                startIcon={<CheckIcon />}
                                onClick={this.onSaveClick}
                            >SAVE</Button>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        );

    }

}

/**
 * mapStateToProps
 * @param {State} state 
 */
function mapStateToProps(state) {
    console.log("form Edit Header", state);
    let props = {
        alert: state.alert,
        form: state.forms.form,
        forms:state.forms,
        activeTabIndex: state.activeTabIndex,
        primaryTableFields: state.forms.primaryTableFields,
        detailTableFields: state.forms.detailTableFields,
        actionType: state.loader.type,
        formSetup: state.forms.form,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage,
    };
    return props;
};

export default withRouter(connect(mapStateToProps)(withStyles(setupTheme)(FormEditHeader)));
