import React from 'react';
import FilterSelect from './FilterSelect'
import * as enumerator from 'client/components/Common/Enum';
import NumericInput from 'client/components/Common/NumericInput';
import { filterTheme } from './../theme/dataListTheme';
import { withStyles } from '@mui/styles';
import { styled } from '@mui/styles';
import { FormControl, TextField, Select, InputLabel, MenuItem, Box, Grid, Typography, Button, Divider } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import * as Enums from 'client/components/Common/Enum';
import DateFnsUtils from '@date-io/date-fns';
import { LocalizationProvider, DatePicker } from '@mui/lab';
import { groupBy, orderBy } from 'lodash';
import { getDateOnly, getDeviceType} from 'client/components/Common/Utility';

const operatorLabel = "operatorLabel";
const operatorControl = "operatorControl";
    const deviceType = getDeviceType();
       
const filterControlType= {
    Select :"select",
    Text :"text",
    DropDown :"dropDown",
    DatePicker :"datePicker",
    CheckBox: "checkBox",
    Numeric:"numeric",
}

const FilterMenuItem = styled(MenuItem)({
    color: filterTheme.filterControlColor.color,
});

class dataViewFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = { filterData: [] };
    }

    componentDidMount() {
       
        this.resetFilterData();
    }
    

    renderOperatorControl = (filterObject, index) => {
        const { classes } = this.props;
          let value = filterObject.parameterFilterType,
            mandatory = filterObject.isMandatory ? "*" : "";
        if (this.state.filterData.length > 0) {
            if (this.state.filterData[index] !== undefined && this.state.filterData[index].condition !== null) {
                value = this.state.filterData[index].condition;
            }
        }
        const labelId = `${operatorLabel}${filterObject.parameterId}`;
        const valueId = `${operatorControl}${filterObject.parameterId}`;
        return (
            <FormControl value="Standard" variant="standard" >
                <InputLabel id={labelId}> {filterObject.parameterFriendlyName}{mandatory}</InputLabel>
                <Select variant="standard" MenuProps={{ disableScrollLock: true }}
                    className={classes.filterControl}
                    disabled={(this.props.ReportDataSourceType === enumerator.ReportDataSourceType.StoredProcedure || this.props.ReportDataSourceType === enumerator.ReportDataSourceType.CrystalReport)?true:false}
                    labelId={labelId}
                    id={valueId}
                    name={valueId}
                    value={value}
                    onChange={(e) => this.addToFilterObject(index,
                        filterObject.parameterName,
                        e.target.value,
                        null,
                        null)}
                    label={filterObject.parameterFriendlyName + mandatory}
                >
                    {this.generateOperationControlType(filterObject)}
                </Select>
            </FormControl>
        );
    };

    numericControlChange = (event, value, settings) => {
        if (isNaN(value)) {
            value = "";
        }
        this.addToFilterObject(settings.index, settings.parameterName, null, value, this.state.filterData[settings.index].value2,'from')
    }
    numericRangeControlChange = (event, value, settings) => {
        if (isNaN(value)) {
            value = "";
        }
        this.addToFilterObject(settings.index, settings.parameterName, null, this.state.filterData[settings.index].value, value,'to')
    }
    selectControlChange = (id, fieldName, condition, value, value2, reportDataSourceType) => {
        let  selectedValue = '';
        if (reportDataSourceType === enumerator.ReportDataSourceType.StoredProcedure || reportDataSourceType === enumerator.ReportDataSourceType.CrystalReport) {
             selectedValue = value;

        } else {
             selectedValue =  value.join('|');
        }
        
        this.addToFilterObject(id, fieldName, null, selectedValue, null)
    }

    renderValueControl = (filterObject, index) => {
        const { classes } = this.props;
        const displayName = " ";
        if (this.state.filterData.length > 0) {

            let defvalue = this.state.filterData[index].value;
            let defvalue2 = this.state.filterData[index].value2;
            let condition = this.state.filterData[index].condition;
            const error = this.state.filterData[index].errorText.length === 0 ? false : true;
            if (filterObject.controlType === filterControlType.Text) {

                if (filterObject.parameterDataType === "decimal" ||
                    filterObject.parameterDataType === "int" ||
                    filterObject.parameterDataType === "float" ||
                    filterObject.parameterDataType === "numeric") {
                    const settings = {
                        index,
                        parameterName: filterObject.parameterName,
                        name: filterObject.parameterName,
                        caption: "  ",
                        textAlign: "right",
                        foreColor: filterTheme.filterControlColor.color,
                    }
                    if (condition === enumerator.ReportFilter.Between) {
                        const error = this.state.filterData[index].errorText.length === 0 ? false : true;
                        const helperText = error ? this.state.filterData[index].errorText : " ";

                        let toError = error;
                        let toHelperText = helperText;
                        let fromError = error;
                        let fromHelperText = helperText;

                        if ( !this.state.filterData[index].launchControl.includes("from")) {
                            fromError = false;
                            fromHelperText = " ";
                        }
                        if ( !this.state.filterData[index].launchControl.includes("to")) {
                            toError = false;
                            toHelperText = " ";
                        }

                        return (
                            <Grid container rowSpacing={0} columnSpacing={2}>
                                <Grid item>
                                    <NumericInput id="N1" fullWidth={true} className={classes.numericfilterControl} onChange={this.numericControlChange} settings={settings}
                                                  value={defvalue}
                                                  error={fromError}
                                        helperText={fromHelperText} />
                                </Grid>
                                <Grid item>
                                    <NumericInput id="N2" fullWidth={true} className={classes.numericfilterControl} onChange={this.numericRangeControlChange} settings={settings}
                                        value={defvalue2}
                                        error={toError}
                                        helperText={toHelperText} />
                                </Grid>
                            </Grid>
                            )


                    } else {
                       
                        return (<NumericInput id="N3" className={classes.numericfilterControl} onChange={this.numericControlChange} settings={settings}
                            value={defvalue}
                            error={error}
                            helperText={error ? this.state.filterData[index].errorText : " "} />);
                    }

                } else {
                    return (<TextField variant="standard" fullWidth={true}
                        error={error} 
                        inputProps={{ className: classes.textFilterControl}}
                        helperText={error ? this.state.filterData[index].errorText : " "}
                        className={classes.textFilterControl}
                        value={defvalue}
                        onChange={(e) => this.addToFilterObject(index, filterObject.parameterName, null, e.target.value, null)} />);
                }

               
            }
            else if (filterObject.controlType === filterControlType.Numeric) {
                const settings = {
                    index,
                    parameterName: filterObject.parameterName,
                    name: filterObject.parameterName,
                    caption: displayName,
                    textAlign: "right",
                    foreColor: filterTheme.filterControlColor.color,
                }
                if (condition === enumerator.ReportFilter.Between) {
                    return (
                        <Grid container rowSpacing={0} columnSpacing={2}>
                            <Grid item>
                                <NumericInput id="N4" variant="standard" fullWidth={true} className={classes.numericfilterControl} onChange={this.numericControlChange} settings={settings}
                                    value={defvalue}
                                    error={error}
                                    helperText={error ? this.state.filterData[index].errorText : " "} />
                            </Grid>
                            <Grid item>
                                <NumericInput id="N5" variant="standard" fullWidth={true} className={classes.numericfilterControl} onChange={this.numericRangeControlChange} settings={settings}
                                    value={defvalue2}
                                    error={error}
                                    helperText={error ? this.state.filterData[index].errorText : " "} />
                            </Grid>
                        </Grid>
                    )


                }
                else {

                    return (<NumericInput id="N6" variant="standard" fullWidth={true} className={classes.numericfilterControl} onChange={this.numericControlChange} settings={settings}
                        value={defvalue}
                        error={error}
                        helperText={error ? this.state.filterData[index].errorText : " "} />);
                }
            } else if (filterObject.controlType === filterControlType.CheckBox) {
                return (<input type="checkbox"   value={defvalue} onChange={(e) => this.addToFilterObject(index,
                    filterObject.parameterName,
                    null,
                    e.target.value,
                    null)} />);
            } else if (filterObject.controlType === filterControlType.DropDown) {

                if (condition === enumerator.ReportFilter.Between) {
                    return (
                        <Grid container rowSpacing={0} columnSpacing={2}>
                            <Grid item>
                                <FilterSelect
                                    className={classes.selectFilterControl}
                                    placeHolderClassName={classes.calendarIconColor}
                                    menuItemClassName={classes.filterControlColor}
                                    operator={condition}
                                    isSingle={true}
                                    caption={displayName}
                                    onValueChange={(id, fieldName, condition, value, value2,reportDataSourceType,isSingle)=>{
                                         const selectedValue = isSingle ? value:value.join('|');
                                        this.addToFilterObject(id, fieldName, null, selectedValue, this.state.filterData[index].value2)
}

                                       }
                                    defaultvalue={defvalue} filterObj={filterObject}
                                    reportId={this.props.reportId} indexValue={index}
                                    error={error}
                                    helperText={error ? this.state.filterData[index].errorText : " "}>
                                </FilterSelect>
                            </Grid>
                            <Grid item>
                                <FilterSelect
                                    caption={displayName}
                                    className={classes.selectFilterControl}
                                    placeHolderClassName={classes.calendarIconColor}
                                    menuItemClassName={classes.filterControlColor}
                                    operator={condition}
                                    isSingle={true}
                                    onValueChange={(id, fieldName, condition, value, value2, reportDataSourceType, isSingle) => {
                                        const selectedValue = isSingle ? value : value.join('|');
                                        this.addToFilterObject(id, fieldName, null, this.state.filterData[index].value,selectedValue)
                                    }

                                    }
                                    defaultvalue={defvalue2} filterObj={filterObject}
                                    reportId={this.props.reportId} indexValue={index}
                                    error={error}
                                    helperText={error ? this.state.filterData[index].errorText : " "}>
                                </FilterSelect>
                            </Grid>
                        </Grid>
                    )


                }
                else {
                    return (
                        <div>
                            <FilterSelect
                                ReportDataSourceType={this.props.ReportDataSourceType}
                                caption=" "
                                className={classes.selectFilterControl}
                                placeHolderClassName={classes.calendarIconColor}
                                menuItemClassName={classes.filterControlColor}
                                operator={condition}
                                onValueChange={this.selectControlChange}
                                defaultvalue={defvalue} filterObj={filterObject}
                                reportId={this.props.reportId} indexValue={index}
                                error={error}
                                helperText={error ? this.state.filterData[index].errorText : " "}>
                            </FilterSelect>
                        </div>
                    );
                }
            }

            else if (filterObject.controlType === filterControlType.DatePicker) {
                console.log('defvalue',defvalue)
                defvalue = defvalue === "" ? null : getDateOnly(defvalue) ;
                console.log('afterdefvalue', defvalue)
                if (condition === enumerator.ReportFilter.Between) {
                    defvalue2 = defvalue2 === "" ? null : getDateOnly(defvalue2);
                    return (
                        <LocalizationProvider dateAdapter={DateFnsUtils}>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <DatePicker fullWidth={true}
                                        label={displayName }
                                        renderInput={(props) => this.renderDate(props, index, classes, "from")}
                                        disableToolbar
                                        margin="normal"
                                        value={defvalue}
                                        onChange={(date) => this.addToFilterObject(index,
                                            filterObject.parameterName,
                                            null,
                                            getDateOnly(date),
                                            this.state.filterData[index].value2,
                                            "from",
                                            )}
                                        openPickerIcon={<CalendarTodayIcon fontSize="small" className={classes
                                            .calendarIconColor} />}
                                            />
                                </Grid>
                                <Grid item pb={2}>
                                    <DatePicker fullWidth={true}
                                        label={displayName}
                                        renderInput={(props) => this.renderDate(props, index, classes,"to")}
                                        disableToolbar
                                        margin="normal"
                                        value={defvalue2}
                                        onChange={(date) => this.addToFilterObject(index,
                                            filterObject.parameterName,
                                            null,
                                            this.state.filterData[index].value,
                                            getDateOnly(date),
                                            "to",
                                            )}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        openPickerIcon={<CalendarTodayIcon fontSize="small" className={classes
                                            .calendarIconColor}  />}
                                            />
                                    </Grid>
                                </Grid>
                        </LocalizationProvider>
                    )
                } else {
                    return (
                        <LocalizationProvider dateAdapter={DateFnsUtils}>
                            <DatePicker  fullWidth={true}
                                label={displayName}
                                renderInput={(props) => this.renderDate(props, index, classes)}
                                disableToolbar
                                variant="inline"
                                margin="normal"
                                value={defvalue}
                                inputValue={defvalue}
                                onChange={(date) => {
                                    
                                        this.addToFilterObject(index,
                                            filterObject.parameterName,
                                            null,
                                            getDateOnly(date),
                                            null)
                                }
                                }


                                
                                openPickerIcon={<CalendarTodayIcon fontSize="small" className={classes.calendarIconColor
}  />}
                            />
                        </LocalizationProvider>
                    )
                }
            }
        }
        return null;
    };

    renderDate = (props, index, classes,range) => {
        let error = this.state.filterData[index].errorText.length === 0 ? false : true;
        let helperText = error ? this.state.filterData[index].errorText : " ";
        if (range === "from" && !this.state.filterData[index].launchControl.includes("from")) {
            error = false;
            helperText = "";
        }
        if (range === "to" && !this.state.filterData[index].launchControl.includes("to")) {
            error = false;
            helperText = "";
        }
        let newProps = { ...props };
        newProps.inputProps.placeholder = "Select";
        return <TextField  {...newProps} variant="standard"
            error={error}
            helperText={helperText}
            placeholder="Select"
            className={classes.root}
            inputProps={{
                ...newProps.inputProps,
                className: classes.filterControlColor,
            }}
            
        />;
    }
    refineSearch = () => {
        const filterObjectList = this.state.filterData;


        

        let errorText = "";
        let hasError =false;
        if (filterObjectList.length > 0) {
            for (let filterObject of filterObjectList) {
                if (filterObject.isMandatory) {
                    if (filterObject.condition === enumerator.ReportFilter.Between) {
                        errorText = (!filterObject.value || !filterObject.value2) ? filterObject.helperText : "";
                        if (!(!errorText)) {
                            filterObject.launchControl = [];
                            if (!filterObject.value) {
                                filterObject.launchControl.push("from");
                            }
                            if (!filterObject.value2) {
                                filterObject.launchControl.push("to");
                            }
                        }
                        if (errorText && !hasError) {
                            hasError = true
                        }
                    }
                    else
                    {
                        errorText = !filterObject.value ? filterObject.helperText : "";
                        if (errorText && !hasError) {
                            hasError =true
                        }
                    }
                    filterObject.errorText = errorText;
                }
            }
        }
       

        if (hasError)
        {
            this.setState({ filterData: filterObjectList });
        }
        else
        {
            this.props.RefineReportEvent(filterObjectList);
        }
            
    }
    resetSearch = () => {
        this.resetFilterData();
    }
    resetFilterData = () => {
        let filterDataObject = [];
        for (let filterDefinition of this.props.FilterDefinition) {
            filterDataObject.push({
                id: filterDefinition.parameterId,
                fieldName: filterDefinition.parameterName,
                condition: filterDefinition.parameterFilterType,
                controlType: filterDefinition.controlType,
                value: filterDefinition.parameterDefaultValue,
                value2: null,
                errorText: "",
                isMandatory: filterDefinition.isMandatory,
                helperText: "" + filterDefinition.parameterFriendlyName + " is required",
                launchControl: [],
            });
        }
        this.setState({ filterData: filterDataObject });
    }

    generateOperationControlType = (filterObject) => {
        const operationOptions = [];
        switch (filterObject.controlType) {
        case filterControlType.Numeric:
                {
                    operationOptions.push(<FilterMenuItem key={0} value={enumerator.ReportFilter.Equals}>Equals</FilterMenuItem>);
                    operationOptions.push(<FilterMenuItem key={1} value={enumerator.ReportFilter.NotEqual}>Not Equal</FilterMenuItem>);
                    operationOptions.push(<FilterMenuItem key={2} value={enumerator.ReportFilter.GreaterThan}>Greater than</FilterMenuItem>);
                    operationOptions.push(<FilterMenuItem key={3} value={enumerator.ReportFilter.GreaterThanOrEqualTo}>Greater than or equal</FilterMenuItem>);
                    operationOptions.push(<FilterMenuItem key={4} value={enumerator.ReportFilter.LessThan}>Less Than</FilterMenuItem>);
                    operationOptions.push(<FilterMenuItem key={5} value={enumerator.ReportFilter.LessThanOrEqualTo}>Less than or equal</FilterMenuItem>);
                    operationOptions.push(<FilterMenuItem key={6} value={enumerator.ReportFilter.Between}>Between</FilterMenuItem>);
                    break;
                }
        
            case filterControlType.Text:
            {
                    const dataType = filterObject.parameterDataType;
                    switch(dataType) {
                        case enumerator.DbType.Int: case enumerator.DbType.Decimal: case enumerator.DbType.SmallInt: case enumerator.DbType.Numeric: case enumerator.HanaDbType.Int:
                            {
                                operationOptions.push(<FilterMenuItem key={0} value={enumerator.ReportFilter.Equals}>Equals</FilterMenuItem>);
                                operationOptions.push(<FilterMenuItem key={1} value={enumerator.ReportFilter.NotEqual}>Not Equal</FilterMenuItem>);
                                operationOptions.push(<FilterMenuItem key={2} value={enumerator.ReportFilter.GreaterThan}>Greater than</FilterMenuItem>);
                                operationOptions.push(<FilterMenuItem key={3} value={enumerator.ReportFilter.GreaterThanOrEqualTo}>Greater than or equal</FilterMenuItem>);
                                operationOptions.push(<FilterMenuItem key={4} value={enumerator.ReportFilter.LessThan}>Less Than</FilterMenuItem>);
                                operationOptions.push(<FilterMenuItem key={5} value={enumerator.ReportFilter.LessThanOrEqualTo}>Less than or equal</FilterMenuItem>);
                                operationOptions.push(<FilterMenuItem key={6} value={enumerator.ReportFilter.Between}>Between</FilterMenuItem>);
                                break;
                        }
                        default:
                        {
                                operationOptions.push(<FilterMenuItem key={0} value={enumerator.ReportFilter.Equals}>Equals</FilterMenuItem>);
                                operationOptions.push(<FilterMenuItem key={1} value={enumerator.ReportFilter.NotEqual}>Not Equal</FilterMenuItem>);
                                operationOptions.push(<FilterMenuItem key={2} value={enumerator.ReportFilter.Contains}>Contains</FilterMenuItem>);
                                operationOptions.push(<FilterMenuItem key={3} value={enumerator.ReportFilter.StartWith}>Start With</FilterMenuItem>);
                                operationOptions.push(<FilterMenuItem key={4} value={enumerator.ReportFilter.EndWith}>End With</FilterMenuItem>);
                        }
                    }
                    
                    break;
            }
            case filterControlType.DropDown:
                {
                    const dataType = filterObject.parameterDataType;
                        switch (dataType) {
                            case enumerator.DbType.Int:
                            case enumerator.DbType.Decimal:
                            case enumerator.DbType.SmallInt:
                            case enumerator.DbType.Numeric:
                            case enumerator.DbType.Date:
                            case enumerator.DbType.DateTime:
                            case enumerator.HanaDbType.Int:
                            {
                                operationOptions.push(<FilterMenuItem key={0} value={enumerator.ReportFilter.Equals}>Equals</FilterMenuItem>);
                                operationOptions.push(<FilterMenuItem key={1} value={enumerator.ReportFilter.NotEqual}>Not Equal</FilterMenuItem>);
                                operationOptions.push(<FilterMenuItem key={2} value={enumerator.ReportFilter.GreaterThan}>Greater than</FilterMenuItem>);
                                operationOptions.push(<FilterMenuItem key={3} value={enumerator.ReportFilter.GreaterThanOrEqualTo}>Greater than or equal</FilterMenuItem>);
                                operationOptions.push(<FilterMenuItem key={4} value={enumerator.ReportFilter.LessThan}>Less Than</FilterMenuItem>);
                                operationOptions.push(<FilterMenuItem key={5} value={enumerator.ReportFilter.LessThanOrEqualTo}>Less than or equal</FilterMenuItem>);
                                operationOptions.push(<FilterMenuItem key={6} value={enumerator.ReportFilter.Between}>Between</FilterMenuItem>);
                                break;
                            }
                            default:
                            {
                                operationOptions.push(<FilterMenuItem key={0} value={enumerator.ReportFilter.Equals}>Equals</FilterMenuItem>);
                                operationOptions.push(<FilterMenuItem key={1} value={enumerator.ReportFilter.NotEqual}>Not Equal</FilterMenuItem>);
                                operationOptions.push(<FilterMenuItem key={2} value={enumerator.ReportFilter.Contains}>Contains</FilterMenuItem>);
                                operationOptions.push(<FilterMenuItem key={3} value={enumerator.ReportFilter.StartWith}>Start With</FilterMenuItem>);
                                operationOptions.push(<FilterMenuItem key={4} value={enumerator.ReportFilter.EndWith}>End With</FilterMenuItem>);
                            }
                        
                    }
                        break;
                }
            case filterControlType.DatePicker:
                {
                    operationOptions.push(<FilterMenuItem key={0} value={enumerator.ReportFilter.Equals}>Equals</FilterMenuItem>);
                    operationOptions.push(<FilterMenuItem key={1} value={enumerator.ReportFilter.NotEqual}>Not Equal</FilterMenuItem>);
                    operationOptions.push(<FilterMenuItem key={2} value={enumerator.ReportFilter.GreaterThan}>Greater than</FilterMenuItem>);
                    operationOptions.push(<FilterMenuItem key={3} value={enumerator.ReportFilter.GreaterThanOrEqualTo}>Greater than or equal</FilterMenuItem>);
                    operationOptions.push(<FilterMenuItem key={4} value={enumerator.ReportFilter.LessThan}>Less Than</FilterMenuItem>);
                    operationOptions.push(<FilterMenuItem key={5} value={enumerator.ReportFilter.LessThanOrEqualTo}>Less than or equal</FilterMenuItem>);
                    operationOptions.push(<FilterMenuItem key={6} value={enumerator.ReportFilter.Between}>Between</FilterMenuItem>);
                    break;
                }
            default:
                {
                    operationOptions.push(<FilterMenuItem key={0} value={enumerator.ReportFilter.Equals}>Equals</FilterMenuItem>);
                    operationOptions.push(<FilterMenuItem key={1} value={enumerator.ReportFilter.NotEqual}>Not Equal</FilterMenuItem>);
                    operationOptions.push(<FilterMenuItem key={2} value={enumerator.ReportFilter.Contains}>Contains</FilterMenuItem>);
                    operationOptions.push(<FilterMenuItem key={3} value={enumerator.ReportFilter.StartWith}>Start With</FilterMenuItem>);
                    operationOptions.push(<FilterMenuItem key={4} value={enumerator.ReportFilter.EndWith}>End With</FilterMenuItem>);
                }
        }

        return operationOptions;
    }

    renderEachFilter = () => {
        let filterObjectData = [];
        let filterCount = 0;
        const { classes } = this.props;
        const filterOrderBy = orderBy(this.props.FilterDefinition, ["row"]);

        const filterGroupBy = groupBy(filterOrderBy, function (filter) {
            return filter.row;
        });
        let controlRow = {};
        for (const [key, value] of Object.entries(filterGroupBy)) {
            controlRow[key] = [];
            let controlIndex = 0;
            for (let filterObject of value) {

                if (this.props.ReportDataSourceType === enumerator.ReportDataSourceType.StoredProcedure) {
                    controlRow[key].push(
                        <Grid item key={filterObject.parameterId} >
                            <Grid container spacing={2} >
                                <Grid item> {this.renderOperatorControl(filterObject, filterCount)} </Grid>
                                <Grid item> {this.renderValueControl(filterObject, filterCount)} </Grid>
                            </Grid>
                        </Grid>);
                }
                else {
                    controlRow[key].push(

                        <Grid item key={filterObject.parameterId} >
                            <Grid container spacing={2} >
                                <Grid item> {this.renderOperatorControl(filterObject, filterCount)} </Grid>
                                <Grid item> {this.renderValueControl(filterObject, filterCount)} </Grid>
                            </Grid>
                        </Grid>);
                }

                controlIndex += 1;

                if (controlIndex !== value.length ) {
                    controlRow[key].push(

                        <Grid item pt={4} display={{ xs: 'none', lg: 'block' }}>
                           
                            <Divider orientation="vertical" className={classes.dividerFilterControl}  />
                                
                        </Grid>);
                }
                filterCount += 1;
            }

            filterObjectData.push(
                <Grid item >
                    <Grid container id="filterObjectDataContainer" direction="row" spacing={1} style={{ minHeight: "75px" }}>
                    {controlRow[key]}
                    </Grid>
                </Grid>
            );

        }

        if (filterCount > 0) {
            filterObjectData = (
                <Accordion defaultExpanded={true} >
                    <AccordionSummary 
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className={classes.filterAccordionSummary}
                    >
                        <Box ml={1}><Typography variant="subtitle1">Pre-filter</Typography> </Box>
                </AccordionSummary>
                    <AccordionDetails className={classes.filerAccordionDetails} >
                        <Grid className={classes.filterList}  >
                        <Grid item lg={10}>
                            <Box ml={1}>
                                    <Grid id="filterObjData"  direction="row"   >
                                    {filterObjectData}
                                </Grid>
                              
                             </Box>
                            </Grid>
                            <Grid item lg={10} pt={1}>
                                <Grid container justifyContent="flex-start" >
                                    <Grid item className={classes.applyButtonGridItem}>
                                        <Button className={classes.applyButton} color="primary" variant="outlined" onClick={(e) => this.refineSearch()} >Apply</Button>
                                    </Grid>
                                    <Grid item className={classes.applyButtonGridItem}>
                                        <Button className={classes.applyButton} color="primary" variant="outlined" onClick={(e) => this.resetSearch()} >Reset</Button>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                        
                    </AccordionDetails>
                   
                </Accordion>

            );
        }
        return filterObjectData;
    }
    addToFilterObject = (id, fieldName, condition, value, value2,launchControl) => {
        let filterObjectList = this.state.filterData;
        if (filterObjectList.length > 0) {
            for (let filterObject of filterObjectList) {
                if (filterObject.fieldName === fieldName) {
                    filterObject.id = id;
                    if (condition !== null) {
                        filterObject.condition = condition;
                        if (condition === enumerator.ReportFilter.Between) {
                            if (filterObject.value && filterObject.value.length !== 0) {
                                filterObject.value = filterObject.value.split('|')[0]
                            }
                            
                        }
                        
                    }
                    else {
                        if (value !== null) {
                            filterObject.value = value;

                        } else {
                            filterObject.value = "";
                        }
                        if (value2 !== null) {
                            filterObject.value2 = value2;
                        }
                        else {
                            filterObject.value2 = "";
                        }
                        if (filterObject.isMandatory) {
                            if (filterObject.condition === enumerator.ReportFilter.Between)
                            {
                                filterObject.errorText = (!filterObject.value || !filterObject.value2) ? filterObject.helperText : "";
                                //filterObject.launchControl = [];
                                if (!(!filterObject.errorText)) {

                                    if (launchControl === "from" && !filterObject.value) {
                                        if (!filterObject.launchControl.includes("from")) {
                                            filterObject.launchControl.push("from");
                                        }

                                    }
                                    else if (launchControl === "from") {
                                        filterObject.launchControl = filterObject.launchControl.filter(item => item !== "from")
                                    }
                                    if (launchControl === "to" && !filterObject.value2) {
                                        if (!filterObject.launchControl.includes("to")) {
                                            filterObject.launchControl.push("to");
                                        }
                                    }
                                    else if (launchControl === "to") {
                                        filterObject.launchControl = filterObject.launchControl.filter(item => item !== "to")
                                    }
                                }
                                else {
                                    filterObject.launchControl = [];
                                }
                               
                            }
                            else {
                                filterObject.errorText = !value ? filterObject.helperText : "";
                            }
                        }
                    }
                }


            }
            this.setState({ filterData: filterObjectList });
        }
    }
    isMobileDevice = () => {
        return deviceType != Enums.DeviceType.Desktop  ? true : false;
    }
    render() {
        return ( 
            <Box pl={1} pr={this.props.isCalendar?0:this.isMobileDevice()?2:7} mb={1}>
                {this.renderEachFilter()}
             </Box> 
        )
    };
}
export default withStyles(filterTheme)(dataViewFilter)