import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import { DateTime } from 'luxon';
import { getHomePage, publishHomePage, deleteHomePage, homePageType } from 'admin/actions/homePageActions';
import Switch from '@mui/material/Switch';
import { Box } from '@mui/material'
import { withStyles } from '@mui/styles';
import { produce } from 'immer';
import DeleteButton from 'common/DeleteButton.js';
import { homePageTheme } from './theme/homePageTheme.js';
import ActionBackdrop from 'admin/components/section/settings/Backdrop';
import Alert from '@mui/lab/Alert';
import Button from '@mui/material/Button';
import { deleteActionStyle } from 'common/theme/deleteButtonTheme.js';
import Popover from '@mui/material/Popover';
import { objectListFormatDate } from 'admin/components/Common//DateFormat';

const MuiPopover = withStyles((theme) => ({
    root: {},
    paper: {
        boxShadow: deleteActionStyle.popper.boxShadow,
    },
}))(Popover)
const defaultColumnOptions = {
    filter: true,
    sort: true,

};
let currntHomePageContent = '';
let currentPublishState = false;
let currentPublishType = '';
class HomePageList extends React.Component {

    constructor(props) {
        const { classes } = props;
        super(props);
        this.state = {
            columns: [
                {
                    name: "publishState",
                    label: "Publish",
                    options: {
                        ...defaultColumnOptions,
                        customBodyRender: this.publishActiveTemplate,
                        setCellHeaderProps: () => ({ className: classes.publishHeader }),
                        setCellProps: () => ({ className: classes.publishCell }),
                    },
                },
                {
                    name: "homePageType",
                    label: "Type",
                    options: {
                        ...defaultColumnOptions,
                        setCellHeaderProps: () => ({ className: classes.typeHeader }),
                        setCellProps: () => ({ className: classes.typeCell }),

                    },
                },
                {
                    name: "subTypeDisplay",
                    label: "Sub-type",
                    options: {
                        ...defaultColumnOptions,
                        setCellHeaderProps: () => ({ className: classes.subTypeHeader }),
                    },
                },
                {
                    name: "title",
                    label: "Title",
                    options: {
                        ...defaultColumnOptions,
                        setCellHeaderProps: () => ({ className: classes.titleHeader }),
                    },
                },
                {
                    name: "userName",
                    label: "Creator",
                    options: {
                        ...defaultColumnOptions,
                        setCellHeaderProps: () => ({ className: classes.creatorHeader }),
                    },
                },
                {
                    name: "createdDate",
                    label: "Created",
                    options: {
                        ...defaultColumnOptions,
                        customBodyRender: objectListFormatDate,
                        setCellHeaderProps: () => ({ className: classes.createdHeader }),
                        setCellProps: () => ({ className: classes.dateCell }),
                        
                    },
                }
                ,
                {
                    name: "modifiedDate",
                    label: "Modified",
                    options: {
                        ...defaultColumnOptions,
                        customBodyRender: objectListFormatDate,
                        setCellHeaderProps: () => ({ className: classes.modifiedHeader }),
                        setCellProps: () => ({ className: classes.dateCell }),
                    },
                }
                ,
                {
                    name: "expiryDate",
                    label: "Expiration",
                    options: {
                        ...defaultColumnOptions,
                        customBodyRender: objectListFormatDate,
                        setCellHeaderProps: () => ({ className: classes.expirationHeader }),
                        setCellProps: () => ({ className: classes.dateCell }),
                    },
                },

                {
                    name: "",
                    label: "",
                    options: {
                        ...defaultColumnOptions,
                        customBodyRender: this.deleteRowTemplate,
                        setCellProps: () => ({ className: classes.deleteCell }),
                        filter: false,
                    },

                }
            ],
            options: {
                download: false,
                elevation: 0,
                responsive: 'scrollMaxHeight',
                pagination: false,
                filter: true,
                filterType: 'multiselect',
                jumpToPage: true,
                onViewColumnsChange: (changedColumn, action) => {
                    let columnIndex = this.state.columns.findIndex(c => c.name === changedColumn);
                    this.setState(produce(draft => {
                        draft.columns[columnIndex].options.display = action === "add";
                    }));
                },
                print: false,
                searchOpen: true,
                selectableRows: 'none',
                onCellClick: (colData, cellMeta) => {
                    let colIndex = cellMeta.colIndex;
                    let colName = this.state.columns[colIndex].name;
                    if (colName !== "publishState" && colName !== "") {

                        let dataIndex = cellMeta.dataIndex;
                        let homePageContent = this.props.homePageContentList[dataIndex];

                        this.props.history.push({
                            pathname: `/Admin/HomePage/${homePageContent.id}`,
                            state: {
                                data: homePageContent
                            },
                        })
                    }
                },
                onFilterChange: (changedColumn, filterList, type, changedColumnIndex, displayData) => {
                    this.setState(produce(draft => {
                        draft.columns[changedColumnIndex].options.filterList = filterList[changedColumnIndex];
                    }));
                },
                onColumnSortChange: (changedColumn, direction) => {
                    this.setState(produce(draft => {
                        draft.options.sortOrder = { name: changedColumn, direction: direction };
                    }));
                }
            },
            anchorElement: null
        };
    }

    componentDidMount() {

        this.dispatchGetHomePage();
    }

    dispatchGetHomePage = () => {

        this.props.dispatch(getHomePage());
    }


    formatDate = (value, tableMeta, updateValue) => {
        if (typeof (value) === "string") {
            value = DateTime.fromISO(value);
        }
        return value.toFormat('LL-dd-yyyy hh:mm a');
    }


    setHomePageActive = (data, value) => {
        let updateContent = {
            ...data,
            isActive: value,
        };
        this.props.dispatch(publishHomePage(updateContent));
    }
    deleteRowTemplate = (value, tableMeta, updateValue) => {

        let columnIndex = this.state.columns.findIndex(c => c.name === "title");
        let data = this.props.homePageContentList.find(u => u.title === tableMeta.rowData[columnIndex]);
        return <DeleteButton preValidation={this.deletePreValidation} onClick={(e) => this.deleteHomePage(data)} id={tableMeta.rowData[columnIndex]} />
    }
    deletePreValidation = () => {
        return { canDelete: true, message: "" }
    }

    deleteHomePage = (data) => {
        this.props.dispatch(deleteHomePage(data));

    }

    checkBannerPublish = (homePage) => {

        let index = this.props.homePageContentList.findIndex(r => r.isActive && r.title !== homePage.title && r.homePageType === homePageType.Banner && r.subType === homePage.subType)
        if (index !== -1) {
            return false;
        }
        return true;
    }



    publishActiveTemplate = (value, tableMeta, updateValue) => {
        let columnIndex = this.state.columns.findIndex(c => c.name === "title");
        let homePageTypeIndex = this.state.columns.findIndex(c => c.name === "homePageType");
        let homePageContent = this.props.homePageContentList.find(u => u.homePageType === tableMeta.rowData[homePageTypeIndex] && u.title === tableMeta.rowData[columnIndex]);
        return (
            <Switch checked={homePageContent.isActive} onChange={event => {
                if (event.target.checked && homePageContent.homePageType === homePageType.Banner) {

                    if (this.checkBannerPublish(homePageContent)) {
                        this.setHomePageActive(homePageContent, event.target.checked)
                    } else {
                        currntHomePageContent = homePageContent;
                        currentPublishState = event.target.checked
                        currentPublishType = homePageContent.subTypeDisplay
                        this.setState({ anchorElement: event.currentTarget })
                    }


                } else {
                    this.setHomePageActive(homePageContent, event.target.checked)
                }


            }}> </Switch>
        );
    }
    getMessage = () => {
        let message = [];
        const dependencyMessage = `Only one of each banner sub-type can be published. One ${currentPublishType} is already published.\n Would you like to unpublish it and publish this one?.\n`;

        
        const actionButton = (<React.Fragment>
            <Button color="inherit" size="small" className={this.props.classes.alertActionButton} onClick={() => {

                this.setHomePageActive(currntHomePageContent, currentPublishState);
                this.handleClose();
            }}>YES</Button>
            <Button color="inherit" size="small" className={this.props.classes.alertActionButton} onClick={this.handleClose}>NO</Button>
            {/*<IconButton className={this.props.classes.alertIconButton} onClick={this.handleClose}><CloseIcon className={this.props.classes.closeButton} fontSize="small" /></IconButton>*/}

        </React.Fragment>)


        message = dependencyMessage.split('\n').map((item, key) => {
            return <div><span key={key}>{item}</span>{key === 2 ? actionButton : ''}<br /></div>
        })
        return message;
    };

    handleClose = () => {
        this.setState({
            anchorElement: null, innerWarning: false,
        })
    };
    render() {
        const open = Boolean(this.state.anchorElement);
        const id = open ? 'simple-popover' : undefined;
        return (




            <Box border={1} borderColor="grey.300"> {/* default in theme?? */}
                <MuiPopover
                    id={id}
                    open={open}
                    //className={this.props.classes.poperBoxShadow}
                    anchorEl={this.state.anchorElement}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Alert severity="warning" classes={{ icon: this.props.classes.alertIcon, message: this.props.classes.alertMessage }}  >
                        {

                            this.getMessage()
                        }


                    </Alert>
                </MuiPopover>


                {this.props.isLoading && <ActionBackdrop />}
                <MUIDataTable container
                    title={''}
                    data={this.props.homePageContentList ? this.props.homePageContentList : []}
                    columns={this.state.columns}
                    options={this.state.options}
                />
            </Box>
        );

    }

}

/**
 * mapStateToProps
 * @param {*} state 
 */
function mapStateToProps(state) {
    return {
        homePageContentList: state.homePage.homePageContentList,
        isLoading: state.loader.isLoading,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage,
    };
};

export default withRouter(connect(mapStateToProps)(withStyles(homePageTheme)(HomePageList)));
