import React from 'react'
import { SignatureComponent } from '@syncfusion/ej2-react-inputs';
import { getComponent } from '@syncfusion/ej2-base';
import Button from 'client/components/Form/Controls/Button';
import Grid from '@mui/material/Grid';
import * as Enums from 'client/components/Common/Enum';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
class signature extends React.Component {

    componentDidMount() {
       
        this.loadSignature();
    }
    componentDidUpdate() {

      this.loadSignature();
      
    }

    loadSignature = () => {
        const signatureId = this.props.settings.controlUiId;
        let signature = getComponent(document.getElementById(signatureId), 'signature');
        signature.strokeColor = this.props.settings.foreColor ? this.props.settings.foreColor : "black";
        signature.backgroundColor = this.props.settings.backGroundColor ? this.props.settings.backGroundColor : "white";
        signature.disabled = !this.props.settings.isEditable;
        if (this.props.dataModel[this.props.settings.fieldName]) {
            signature.load(this.props.dataModel[this.props.settings.fieldName]);
        }

        else {
            signature.clear()
        }

       
    }

    change = (args) => {
        const signatureId = this.props.settings.controlUiId;
        let signature = getComponent(document.getElementById(signatureId), 'signature');
        if (args.actionName !== "clear") {
            if (!signature.isEmpty()) {

                this.props.onChange(this.props.settings.isMandatory, this.props.settings.fieldName, signature.getSignature());
            } else {
                this.props.onChange(this.props.settings.isMandatory, this.props.settings.fieldName, "")
            }
        }

    }
    clearSignture = () => {
        const signatureId = this.props.settings.controlUiId;
        let signature = getComponent(document.getElementById(signatureId), 'signature');
        signature.clear()
        this.props.onChange(this.props.settings.isMandatory, this.props.settings.fieldName, "")
    }

    render() {
        let labelStyle = {
            color: this.props.settings.captionColor ? this.props.settings.captionColor : "rgba(0, 0, 0, 0.54)",
            wordBreak: "break-all",
            marginRight: 20,
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            letterSpacing: "0.5px",
            lineHeight: "1.4375em"
        }

        let styleHelperText = this.props.classes.signatureHelperText

        let requiredIndicator = "";
        let helperText = this.props.settings.helpText;
        if (this.props.settings.isMandatory) {
            requiredIndicator = " *";
            const validation = this.props.validationSchema.filter(field => field.fieldName === this.props.settings.fieldName && field.errorState)
            if (validation.length > 0) {
             //   errorState.error = true;
                helperText = `${this.props.settings.caption} is required`;
                labelStyle = {
                    color: "#f44336",
                    wordBreak: "break-all",
                    marginRight: 20,
                    fontSize: "12px",
                    fontWeight: "400",
                    fontStyle: "normal",
                    letterSpacing: "0.5px",
                    lineHeight: "1.4375em"

                }
                styleHelperText = this.props.classes.signatureError;
            }
        }


       
        const control = { controlType: Enums.ControlType.Button, caption: "clear"  }
        return (<div key={"div" + this.props.settings.caption} className="form-row">

            <div id={'signature-control'+ this.props.settings.caption}>
                <Grid container spacing={0} >
                    <Grid item xs >
                        <Typography style={labelStyle} > {this.props.settings.caption}{requiredIndicator}</Typography>
                    </Grid>
                    <Grid item className={this.props.classes.signatureItem} >
                        <Button column={control} disabled= {!this.props.settings.isEditable} saveButtonClassName={`${this.props.classes.customButton_Client} ${this.props.classes.clearButton}`} onClick={this.clearSignture} />
                    </Grid>
                </Grid>

                <SignatureComponent id={this.props.settings.controlUiId} className={this.props.classes.signature} change={(e) =>  this.change(e) } />
                <FormHelperText className={styleHelperText}  >{helperText}</FormHelperText>
            </div>


        </div>);

    }

}
export default signature;
