import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { userProfileActionCreators } from 'client/store/UserProfile';
import { errorActionCreator } from 'client/store/Error';
import { alertHandlerAction } from 'client/store/AlertHandler';
import { getTimeZoneList, setTabTitle } from 'client/components/Common/Utility';
import Cookies from 'universal-cookie';
import { BackdropLoading } from 'client/components/Loading';
import ImagePath from 'client/assets/images/defaultprofilelg.png';
import ImageCrop from 'client/components/ImageCrop';
import 'client/assets/css/imageCrop.css';
import { Dialog, Select, TextField, MenuItem, FormControl, InputLabel, Autocomplete } from '@mui/material';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/styles';
import { spacing } from '@mui/system';
import MuiButton from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check'
import { profileTheme } from 'client/components/UserProfile/theme/ProfileTheme';
import { Box, Typography } from '@mui/material';
import LabelIcon from '@mui/icons-material/Label';
import { withStyles } from '@mui/styles';
import PasswordText from 'client/components/UserProfile/Password.js';
import PasswordHelp from 'client/components/ResetPasswords/PasswordHelp.js';
import Chip from '@mui/material/Chip';
import LayoutBasicHeader from '../Common/LayoutBasicHeader';
import Stack from '@mui/material/Stack';
import * as Enum from 'client/components/Common/Enum';
const Button = styled(MuiButton)(spacing);
const cookies = new Cookies();
let roleList = [];
const indicationError = 'Please correct the indicated items'
const passwordNotMatchError = 'Password does not match';
const matchMessage = 'Match'
const confirmPasswordError = 'Confirm password is required'
const newPasswordError = 'New password is required'
const passwordRule = 'The password rules have not been met'

class userProfile extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            timeZone: '',
            company: '',
            fax: '',
            phone: '',
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
            country: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            zipCode: '',
            createdDate: '',
            updatedDate: '',
            countryOption: [],
            timeZoneOption: [],
            imagePath: ImagePath,
            roleList: [],
            showModel: false,
            firstNameClass: 'field',
            lastNameClass: 'field',
            emailClass: '',
            currentPasswordText: 'field',
            newPasswordText: 'field',
            confirmPasswordText: 'field',
            currentPasswordStatus: false,
            newPasswordStatus: false,
            confirmPasswordStatus: false,
            passwordMatch: '',
            passwordHelper: [],
            showHelper: false,
            confirmPasswordValidateStatus: false,
            newPasswordHelperText: ' ',
            confirmPasswordHelperText: ' ',
            currentPasswordHelperText: ' ',
            havePrimaryPasswordFocus: false,
            checkPasswordError: false,

        };
        this.handleInputChange = this.handleInputChange.bind(this);

    }

    componentDidMount() {

        let versagoCookie = cookies.get('Versago');
        if (versagoCookie !== undefined) {
            this.props.actions.userProfileActionCreators.requestUserProfile();
        }

    }

    componentDidUpdate(nextProps) {
        if (nextProps.userProfile.user !== this.props.userProfile.user) {
            let user = this.props.userProfile.user;
           
            let arrayCountry = [];
            let arrayList = this.props.userProfile.country;
            arrayCountry = arrayList.map((value) => {
                return value.name;
            })
            arrayCountry.unshift('-Select-');
           
            let timeZoneOptions = getTimeZoneList();
            timeZoneOptions.unshift('-Select-');

            let image = '';
            if (user.profileImage != null)
                image = `data:image/png;base64,${user.profileImage}`;
            else
                image = this.state.imagePath;
            let createdDate = '';
            let lastUpdateDate = '';
            if (user.createDateTime !== undefined)
                createdDate = Intl.DateTimeFormat('en-US', { day: '2-digit', month: 'short', year: 'numeric' }).format(new Date(user.createDateTime));
            if (user.lastUpdateDateTime !== undefined)
                lastUpdateDate = Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short', day: '2-digit' }).format(new Date(user.lastUpdateDateTime));

            this.setState({
                countryOption: arrayCountry,
                timeZoneOption: timeZoneOptions,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                timeZone: user.systemTimeZone,
                company: user.companyName,
                fax: user.fax,
                phone: user.phone,
                country: user.country,
                address1: user.address1,
                address2: user.address2,
                city: user.city,
                state: user.state,
                zipCode: user.zip,
                createdDate: createdDate,
                updatedDate: lastUpdateDate,
                imagePath: image,
                firstNameError: false,
                lastNameError: false,
                firstNameHelper: ' ',
                lastNameHelper: '',
                currentPassword: '',
                newPassword: '',
                confirmPassword: '',
                newPasswordStatus: false,
                confirmPasswordStatus: false,
                currentPasswordStatus: false,
                newPasswordHelperText: ' ',
                confirmPasswordHelperText: ' ',
                currentPasswordHelperText: ' ',
                passwordHelper: Enum.passwordHelp,
                checkPasswordError: false,
            });
        }

    }
    handlePasswordChange = (password, passwordHelper, validatedStatus) => {


        this.setState({ "currentPassword": password });
    }
    handlePasswordBlur = (event, passwordError) => {
        this.validateControl(event);
    }
    handlePasswordFocus = (event, passwordError) => {
        this.setState({ currentPasswordStatus: false, currentPasswordHelperText: ' ', havePrimaryPasswordFocus: false, })
    }
    handleNewPasswordChange = (password, passwordHelper, validatedStatus) => {
        this.setState({
            passwordHelper: passwordHelper, validateStatus: validatedStatus, newPasswordStatus: false, "newPassword": password

        })

    }
    handleNewPasswordBlur = (event, passwordError) => {


        this.setState({ newPasswordStatus: passwordError, newPasswordHelperText: passwordError ? passwordRule : " ", checkPasswordError: passwordError })

    }
    handleNewPasswordFocus = (event, passwordError, passwordHelper) => {

        this.setState({ newPasswordStatus: false, passwordHelper: passwordHelper, showHelper: true, newPasswordHelperText: "", havePrimaryPasswordFocus: true })
    }

    handleConfirmPasswordChange = (password, passwordHelper, validatedStatus) => {
        this.setState({ "confirmPassword": password, confirmPasswordStatus: !validatedStatus, confirmPasswordHelperText: !validatedStatus ? passwordNotMatchError : (this.state.confirmPassword.length > 0 && (this.state.newPassword === this.state.confirmPassword)) ? "" : matchMessage })

    }
    handleConfirmPasswordBlur = (event, passwordError) => {

        this.setState({ confirmPasswordStatus: (this.state.confirmPassword.length > 0 && (this.state.newPassword === this.state.confirmPassword)) ? false : true, confirmPasswordHelperText: passwordError ? passwordNotMatchError : (this.state.confirmPassword.length > 0 && (this.state.newPassword === this.state.confirmPassword)) ? matchMessage : passwordNotMatchError })

    }

    handleCurrentPasswordBlur = (event, passwordError) => {
        if (event.target.value) {
            this.setState({ currentPasswordStatus: false, currentPasswordHelperText: ' ' })

        }

    }
    handleConfirmPasswordFocus = (event, passwordError) => {
        this.setState({ confirmPasswordStatus: false, confirmPasswordHelperText: ' ', havePrimaryPasswordFocus: false })

    }

    handleInputChange(event) {
        try {
            const target = event.target;
            const value = target.type === 'checkbox' ? target.checked : target.value;
            const name = target.name;
            this.setState({
                [name]: value
            });

        } catch (error) {
            this.props.actions.errorActionCreator.showErrorMessage(error);
        }
    }
    validateControl = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if (name === 'firstName') {
            if (this.checkIsEmpty(value)) {
                this.setState({ firstNameError: true, firstNameHelper: 'First Name is required' });
            } else {
                this.setState({ firstNameError: false, firstNameHelper: ' ' });
            }
        } else if (name === 'lastName') {

            if (this.checkIsEmpty(value)) {
                this.setState({ lastNameError: true, lastNameHelper: 'Last Name is required' });
            } else {
                this.setState({ lastNameError: false, lastNameHelper: '' });
            }
        }






    }

    onSubmit = () => {
        try {
            if (this.validateForm() === false)
                return;

            var data = new FormData();
            data.append('firstName', this.state.firstName);
            data.append('lastName', this.state.lastName);
            data.append('email', this.state.email);
            if (this.state.timeZone && this.state.timeZone !== '-Select -')
                data.append('SystemTimeZone', this.state.timeZone);
            if (this.state.company)
                data.append('CompanyName', this.state.company);
            if (this.state.phone)
                data.append('phone', this.state.phone);
            if (this.state.fax)
            data.append('fax', this.state.fax);
            if (this.state.country && this.state.country !== '-Select -')
                data.append('country', this.state.country);
            if (this.state.address1)
                data.append('address1', this.state.address1);
            if (this.state.address2)
                data.append('address2', this.state.address2);
            if (this.state.city)
                data.append('city', this.state.city);
            if (this.state.state)
                data.append('state', this.state.state);
            if (this.state.zipCode)
            data.append('Zip', this.state.zipCode);
            data.append('password', this.state.currentPassword);
            data.append('newPassword', this.state.newPassword);
            var imageBase64 = '';
            if (this.state.imagePath.indexOf(',') > -1)
                imageBase64 = this.state.imagePath.split(',')[1];
            data.append('ProfilePath', imageBase64);
            this.props.actions.userProfileActionCreators.requestProfileUpdate(data);
        } catch (error) {
            this.props.actions.errorActionCreator.showErrorMessage(error);
        }
    }
    validateForm = () => {
        let status = true;
        if (this.checkIsEmpty(this.state.firstName) || this.checkIsEmpty(this.state.lastName)) {

            this.props.actions.alertHandler.showErrorMessage(indicationError);
            return false;

        }
        else if (!this.checkIsEmpty(this.state.newPassword) && !this.checkIsEmpty(this.state.confirmPassword) && this.checkIsEmpty(this.state.currentPassword)) {
            this.setState({ currentPasswordStatus: true, currentPasswordHelperText: "Current password is required" })
            this.props.actions.alertHandler.showErrorMessage(indicationError);
            return false;

        } else if (this.checkIsEmpty(this.state.confirmPassword) && !this.checkIsEmpty(this.state.newPassword)) {
            this.setState({ confirmPasswordStatus: true, confirmPasswordHelperText: confirmPasswordError })
            this.props.actions.alertHandler.showErrorMessage(indicationError);
            return false;

        } else if (!this.checkIsEmpty(this.state.confirmPassword) && this.checkIsEmpty(this.state.newPassword)) {
            this.setState({ newPasswordStatus: true, newPasswordHelperText: newPasswordError })
            this.props.actions.alertHandler.showErrorMessage(indicationError);
            return false;

        }

        else if (this.checkIsEmpty(this.state.newPassword) && this.checkIsEmpty(this.state.confirmPassword) && !this.checkIsEmpty(this.state.currentPassword)) {
            if (this.checkIsEmpty(this.state.newPassword)) {

                this.setState({ newPasswordStatus: true, newPasswordHelperText: newPasswordError })
                this.props.actions.alertHandler.showErrorMessage(indicationError);
                return false;
            } else {
                this.setState({ confirmPasswordStatus: true, confirmPasswordHelperText: confirmPasswordError })
                this.props.actions.alertHandler.showErrorMessage(indicationError);
                return false;

            }

        }

        else if (!this.checkIsEmpty(this.state.confirmPassword) && !this.checkIsEmpty(this.state.newPassword)) {


            if (this.state.newPasswordStatus) {
                this.props.actions.alertHandler.showErrorMessage(indicationError);
                return false;
            } else if (this.state.confirmPassword !== this.state.newPassword) {
                this.setState({
                    confirmPasswordStatus: true,
                    confirmPasswordHelperText: passwordNotMatchError
                })
                this.props.actions.alertHandler.showErrorMessage(indicationError);
                return false;
            }
            else if (this.state.checkPasswordError) {

                this.setState({ newPasswordStatus: true, newPasswordHelperText: passwordRule })
                this.props.actions.alertHandler.showErrorMessage(indicationError);
                return false;
            } else {
                this.setState({
                    confirmPasswordStatus: false,
                    confirmPasswordHelperText: " "
                })

            }
        } else {

            return true;
        }


    }

    checkIsEmpty(str) {
        return (!str || str.length === 0 || !str.trim());
    }

    getCropImage = (accepted, imageString) => {

        if (accepted)
            this.setState({ showModel: false, imagePath: imageString });
        else
            this.setState({ showModel: false });

    }

    clearImage = () => {

        this.setState({ imagePath: ImagePath });
    }

    resetUserProfile = () => {
        try {
            this.props.actions.userProfileActionCreators.requestUserProfile();
        } catch (error) {
            this.props.actions.errorActionCreator.showErrorMessage(error);
        }
    }

    getRoleList = () => {
        if (this.props.userProfile.user) {
            roleList = (this.props.userProfile.user.roles.map((item, index) => {
                return <Box key={"RoleContainer" + index} pr={2} pt={2}><Chip key={"Role" + index} color="primary" className={`${this.props.classes.chip} ${this.props.classes.customTheme.backgroundColor}`} classes={{ label: this.props.classes.chipLabelText }} variant="outlined" icon={<LabelIcon />} label={item.roleName} /></Box>
            }));
        }
        return roleList
    }

  


    render() {
        setTabTitle("User Profile", true);
        const { classes } = this.props;
        return (

            <Box pt={0} sx={{minWidth:"300px"}}>

                <LayoutBasicHeader title="User Profile" />
                <BackdropLoading loading={this.props.loading} />
                <Dialog className="cropImageDialog" open={this.state.showModel} aria-labelledby="form-dialog-title" disableScrollLock={true}>
                    <div className="CropOuterContainer" ><ImageCrop actionresult={this.getCropImage}></ImageCrop> </div >
                </Dialog>
                <Box pl={3} >
                    <Box pr={4.2} className={classes.buttonOuterWrapper} >
                        <Box className={classes.buttonWrapper} xs={12} >
                            <Grid container spacing={1} justifyContent="center" alignItems="flex-end">
                                <Grid item lg={6} md={6} sm={6} xs={6}>

                                    <Grid container spacing={1} className={classes.dateGrid}   >
                                        <Grid item lg={6} md={6} sm={12} xs={12} >
                                            <Grid container spacing={0}  >
                                                <Grid item xs={6} lg={6} md={6} sm={6} ><Typography className={classes.createdDate}>User Created</Typography> </Grid>
                                                <Grid item xs={6} lg={6} md={6} sm={6}><Typography className={classes.createdDateList}>{this.state.createdDate}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={12} xs={12}  >
                                            <Grid container spacing={0}  >
                                                <Grid item xs={6} lg={6} md={6} sm={6} className={classes.createdDateItem}><Typography className={classes.createdDate}>Last Edited</Typography></Grid>
                                                <Grid item xs={6} lg={6} md={6} sm={6} ><Typography className={classes.createdDateList}>{this.state.updatedDate}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6} className={classes.buttonItem} >

                                    <Grid container spacing={1} justifyContent="flex-end" alignItems="flex-end">
                                        <Grid item xs={12} sx={{ textAlign: "right" }}>

                                            <Box className={classes.buttonContainer } >
                                            <Grid container spacing={0} >
                                                    <Grid item lg={6} lg={6} md={6} sm={6} xs={12} className={classes.buttonItem }>
                                                    <Button className={classes.resetButton} variant="defaultContained" disableElevation onClick={(e) => this.resetUserProfile()}>Reset</Button>
                                                </Grid>
                                                    <Grid item lg={6} lg={6} md={6} sm={6} xs={12} className={classes.buttonSaveItem}>
                                                    <Button className={`${classes.smallButton} ${classes.saveButton} ${classes.resetButton_Client}`} color="primary" variant="contained" disableElevation startIcon={<CheckIcon />} onClick={this.onSubmit}>Save</Button>
                                                </Grid>
                                                </Grid>
                                                </Box>
                                        </Grid>

                                    </Grid>


                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box pt={1} pr={1} >
                        <Grid container spacing={0} justifyContent="center">
                            <Grid item xs={12} lg={1} className={classes.avatarGrid} >
                                <Grid container spacing={0}>
                                    <Grid item xs={4} lg={4} className={classes.avatarGridUpload}>
                                        <Button variant="outlined" className={`${classes.caption} ${classes.ovelButton}`} onClick={(e) => this.setState({ showModel: true })} >Upload</Button>

                                    </Grid>
                                    <Grid item xs={4} lg={4} >
                                        <img id="imguserprf1" src={this.state.imagePath} alt="" className={classes.imageAvatar} width="100" height="100" />
                                    </Grid>
                                    <Grid item xs={4} lg={4} className={classes.avatarGridRemove} >
                                        <Button variant="outlined" className={classes.ovelButton} onClick={this.clearImage} >Remove</Button>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box className={classes.controlWrapper} >
                        <Grid container key="root" spacing={0}>
                            <Grid item xs={12} >

                                <Box pt={.8} >
                                    <Grid container key="user" spacing={1}>
                                        <Grid item xs={12} lg={1}>

                                            <Typography variant="h6"> User</Typography>



                                        </Grid>

                                    </Grid>
                                </Box>
                                <Box pt={.9} >
                                    <Grid container key="user" spacing={6} >
                                        <Grid item lg={4} md={4} sm={12} xs={ 12}>

                                            <TextField id="firstName" fullWidth={true} variant="standard" error={this.state.firstNameError} helperText={this.state.firstNameHelper} name="firstName" label="First Name *" value={this.state.firstName} onBlur={this.validateControl} onChange={this.handleInputChange} inputProps={{ maxLength: 50 }}

                                            />

                                        </Grid>
                                        <Grid item lg={4} md={4} sm={12} xs={12}>
                                            <TextField label="Last Name *" fullWidth={true} variant="standard" error={this.state.lastNameError} helperText={this.state.lastNameHelper} type="text" id="lastName" name="lastName" onBlur={this.validateControl} value={this.state.lastName} onChange={this.handleInputChange} inputProps={{ maxLength: 50 }}


                                            />
                                        </Grid>
                                        <Grid item lg={4} md={4} sm={12} xs={12}>
                                            <TextField label="Email" fullWidth={true} variant="standard" className={this.state.emailClass} id="email" name="email" value={this.state.email} onBlur={this.validateControl} onChange={this.handleInputChange} maxLength="50" disabled />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box pt={2.2} >
                                    <Grid container key="user" spacing={9}>
                                        <Grid item lg={3} md={3} sm={12} xs={12} >
                                            <Autocomplete
                                                id="timeZone"
                                                name="timeZone"
                                                variant="standard"
                                                options={this.state.timeZoneOption}
                                                value={this.state.timeZone ? this.state.timeZone : ""}
                                                onChange={(event, newValue) => {
                                                    this.setState({ ["timeZone"]: newValue});
                                                }}
                                                className={classes.formControlSelect}
                                                renderInput={(params) => <TextField {...params} label="Time Zone" />}
                                            />
                                        </Grid>
                                        <Grid item lg={3} md={3} sm={12} xs={12}>
                                            <TextField label="Company" fullWidth={true} variant="standard" error={this.state.companyError} helperText={this.state.companyHelper} id="company" name="company" value={this.state.company} onChange={this.handleInputChange} inputProps={{ maxLength: 100 }} />
                                        </Grid>
                                        <Grid item lg={3} md={3} sm={12} xs={12}>
                                            <TextField label="Phone" fullWidth={true} variant="standard" id="phone" name="phone" value={this.state.phone} onChange={this.handleInputChange} inputProps={{ maxLength: 20 }} />
                                        </Grid>
                                        <Grid item lg={3} md={3} sm={12} xs={12}>
                                            <TextField label="Fax" id="fax" fullWidth={true} variant="standard" name="fax" value={this.state.fax} onChange={this.handleInputChange} inputProps={{ maxLength: 20 }} />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box pt={7.2} >
                                    <Grid container key="password" spacing={1}>
                                        <Grid item xs={12}>

                                            <Typography variant="h6">Change Password</Typography>



                                        </Grid>

                                    </Grid>
                                </Box>
                                <Box pt={1.2} >
                                    <Grid container key="passwordControl" spacing={6}>
                                        <Grid item lg={4} md={4} sm={12} xs={12}>
                                            <PasswordText
                                                id="currentPassword"
                                                name="currentPassword"
                                                label="Current Password"
                                                handlePasswordChange={this.handlePasswordChange}
                                                holderName="Current Password"
                                                error={this.state.currentPasswordStatus}
                                                helperText={this.state.currentPasswordHelperText}
                                                handlePasswordBlur={this.handleCurrentPasswordBlur}
                                                handlePasswordFocus={this.handlePasswordFocus}

                                                value={this.state.currentPassword}
                                                validateHelper={false}
                                                autoFocus={false}
                                            />

                                        </Grid>
                                        <Grid item lg={4} md={4} sm={12} xs={12}>
                                            <PasswordText
                                                id="newPassword"
                                                name="newPassword"
                                                label="New Password"
                                                handlePasswordChange={this.handleNewPasswordChange}
                                                holderName="New Password"
                                                error={this.state.newPasswordStatus}
                                                helperText={this.state.newPasswordHelperText}
                                                handlePasswordBlur={this.handleNewPasswordBlur}
                                                handlePasswordFocus={this.handleNewPasswordFocus}

                                                value={this.state.newPassword}
                                                validateHelper={true}
                                                autoFocus={false}
                                            />

                                        </Grid>
                                        <Grid item lg={4} md={4} sm={12} xs={12}>
                                            <PasswordText
                                                id="confirmPassword"
                                                name="confirmPassword"
                                                label="Confirm New Password"
                                                handlePasswordChange={this.handleConfirmPasswordChange}
                                                holderName="Confirm New Password"
                                                error={this.state.confirmPasswordStatus}
                                                helperText={this.state.confirmPasswordHelperText}
                                                handlePasswordBlur={this.handleConfirmPasswordBlur}
                                                handlePasswordFocus={this.handleConfirmPasswordFocus}
                                                value={this.state.confirmPassword}
                                                value2={this.state.newPassword}
                                                validateHelper={false}
                                                autoFocus={false}
                                            />

                                        </Grid>

                                    </Grid>
                                </Box>
                                <Grid container key="user" spacing={0}>
                                    <Grid item lg={4} md={4} sm={12} xs={12}>

                                    </Grid>
                                    <Grid item lg={5} md={5} sm={12} xs={12} >
                                        <Box pl={1}><PasswordHelp primaryFocus={this.state.havePrimaryPasswordFocus} showHelper={this.state.showHelper} passwordHelper={this.state.passwordHelper} ></PasswordHelp></Box>
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={12} xs={12}>



                                    </Grid>

                                </Grid>
                                <Box pt={1} >
                                    <Grid container key="user" spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6">User Address</Typography>

                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box pt={.9} >
                                    <Grid container key="user" spacing={6}>
                                        <Grid item lg={4} md={4} sm={12} xs={12}>

                                            <Autocomplete
                                                id="country"
                                                name="country"
                                                variant="standard"
                                                options={this.state.countryOption}
                                                value={this.state.country ? this.state.country : ""}
                                                onChange={(event, newValue) => {
                                                    this.setState({ ["country"]: newValue });
                                                }}
                                                className={classes.formControlSelect}
                                                renderInput={(params) => <TextField {...params} label="Country" />}
                                            />

                                        </Grid>
                                        <Grid item lg={4} md={4} sm={12} xs={12}>


                                            <TextField fullWidth={true} variant="standard" label="Address Line 1" id="address1" name="address1" value={this.state.address1} onChange={this.handleInputChange} inputProps={{ maxLength: 200 }} />

                                        </Grid>
                                        <Grid item lg={4} md={4} sm={12} xs={12}>

                                            <TextField fullWidth={true} variant="standard" label="Address Line 2" id="address2" name="address2" value={this.state.address2} onChange={this.handleInputChange} inputProps={{ maxLength: 200 }} />

                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box pt={5} >
                                    <Grid container key="user" spacing={6}>
                                        <Grid item lg={4} md={4} sm={12} xs={12}>
                                            <TextField fullWidth={true} variant="standard" label="City" id="city" name="city" value={this.state.city} onChange={this.handleInputChange} inputProps={{ maxLength: 50 }} />


                                        </Grid>
                                        <Grid item lg={4} md={4} sm={12} xs={12}>

                                            <TextField fullWidth={true} variant="standard" label="State" id="state" name="state" value={this.state.state} onChange={this.handleInputChange} inputProps={{ maxLength: 50 }} />

                                        </Grid>
                                        <Grid item lg={4} md={4} sm={12} xs={12}>
                                            <TextField fullWidth={true} variant="standard" label="Zip Code" id="zipCode" name="zipCode" value={this.state.zipCode} onChange={this.handleInputChange} inputProps={{ maxLength: 20 }} />


                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box pt={4.1} >
                                    <Grid container key="root" spacing={0}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6">Roles & Permissions</Typography>

                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box pt={0} >

                                    <Stack className={this.props.classes.roleItem} direction="row" verticalSpacing={0}>
                                        {this.getRoleList()}
                                    </Stack>

                                </Box>
                            </Grid>


                        </Grid>




                    </Box>
                </Box>

            </Box>

        )
    };
}
function mapDispatchToProps(dispatch) {
    return {
        actions: {
            userProfileActionCreators: bindActionCreators(userProfileActionCreators, dispatch),
            errorActionCreator: bindActionCreators(errorActionCreator, dispatch),
            alertHandler: bindActionCreators(alertHandlerAction, dispatch)
        }
    };
}
export default connect(state => state.UserProfile, mapDispatchToProps)(withStyles(profileTheme)(userProfile));


