import { ActionTypes as types } from 'admin/actions/actionTypes'; 
import { produce } from 'immer';
import { newDatabase, checkServerInfoChange } from 'admin/actions/databaseActions';
import { DatabaseType } from 'client/components/Common/Enum';
 

const initialState = {
    databases: [],
    sqlDatabases:[],
    database: newDatabase(),
    isUpdate: false, 
    getListRequestStatus: "",
    canDelete: false,
    existingDataBaseValue: {},
    validatePassword: true,
    isLoading:false,
}; 

const databases = produce((draft, action) => { 
    draft.isUpdate = false;
    
    switch (action.type) {
        case types.VGO_GET_DATABASES_REQUEST:
            draft.getListRequestStatus = types.VGO_GET_DATABASES_REQUEST;
            draft.isLoading = true;
            break;
        case types.VGO_GET_DATABASES_SUCCESS:
            draft.databases = produce(action.databases, () => { });
            draft.getListRequestStatus = types.VGO_GET_DATABASES_SUCCESS;
            draft.sqlDatabases = draft.databases.filter(database => database.databaseType === DatabaseType.SqlServer)
            draft.isLoading = false;
            break;

        case types.VGO_GET_DATABASE_SUCCESS:
            draft.existingDataBaseValue = action.database;
            draft.validatePassword = false;
            draft.database = action.database;
            draft.errors = {};
            break;

    case types.VGO_UPDATE_DATABASE_SUCCESS:
            {
                draft.isUpdate = true;
            const database = action.database;
            let databaseIndex = draft.databases.findIndex(d => d.databaseId === database.databaseId);
            if (databaseIndex >= 0) {
                draft.databases[databaseIndex] = database;
                //draft.isUpdate = true;
                //draft.successMessage = "Data updated successfully"
            } else {
                draft.databases.push(database);
                //draft.isUpdate = true;
                //draft.successMessage = "Data saved successfully"
            }
                draft.database = database;
                draft.sqlDatabases = draft.databases.filter(database => database.databaseType === DatabaseType.SqlServer)
        }
       
        break;

    case types.VGO_DELETE_DATABASE_SUCCESS:
        {
            let databases = draft.databases;
            if (draft.databases.length > 0) {
                databases = draft.databases.filter((d) => d.databaseId !== action.databaseId);
            }
                draft.databases = databases;
                draft.sqlDatabases = databases.filter(database => database.databaseType === DatabaseType.SqlServer)
            //draft.successMessage = "Data deleted successfully";
            }
            break;
    case types.VGO_NEW_DATABASE:
            draft.database = newDatabase();
            draft.validatePassword = true;
            draft.errors = {};
        break;

    case types.VGO_CHANGE_DATABASE:
            action.change(draft.database);
            draft.validatePassword=checkServerInfoChange(draft.database, draft.existingDataBaseValue);
            break;

    case types.VGO_DISCARD_DATABASE:
        if (draft.database.databaseId > 0) {
            let databaseIndex = draft.databases.findIndex(d => d.databaseId === draft.database.databaseId);
            if (databaseIndex >= 0) {
                draft.database = newDatabase();
                   // draft.database = draft.databases[databaseIndex];
            } else {
                    draft.database = newDatabase();
            }
        } else { draft.database = newDatabase();
            } break;
        case types.VGO_VALIDATE_DATABASEFIELD_ONBLUR: 
            draft.errors = produce(draft.errors, draftErrors => { return { ...draftErrors, ...action.errors } });
            break;
    default:
        break;
    }
}, initialState);

export default databases;