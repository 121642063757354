import React from 'react';
import { Box, Grid } from '@mui/material';
import {  Link } from 'react-router-dom';
import logo from 'client/assets/images/Thirdwavelogo.png';
import versagoLogo from 'client/assets/images/Versago.png';
import { companyTheme } from './theme/companyTheme.js';
import { withStyles } from '@mui/styles';
import { getDeviceType } from 'client/components/Common/Utility';
import * as Enums from 'client/components/Common/Enum';
const currentCompany = {
    name: 'Capital Inc.' ,
    image: logo
};
const deviceType = getDeviceType();




class Company extends React.Component {

    resetMargin = () => {
        if (deviceType === Enums.DeviceType.Mobile) {
            this.props.resetMargin(true);
        }
    }
    render() {
        const { isSiteTheme, themeColor, companyLogo } = (this.props.siteTheme) ? this.props.siteTheme : '';
        const classes = this.props.classes;
        if (companyLogo) {
            return (
                <Box className={this.props.isAdmin ? "" : classes.customTheme} style={{ backgroundColor: themeColor }}>
                    <Grid container direction="column" alignContent="center" className={classes.root}>
                        <Grid item >{(isSiteTheme) ? <img className={classes.siteLogo} src={companyLogo} alt={currentCompany.name} /> :
                            <Link to="/" className={classes.link}>
                                <img className={classes.siteLogo} src={companyLogo} alt={currentCompany.name} onClick={this.resetMargin}/>
                            </Link>
                        }
                        </Grid>
                    </Grid>
                </Box>
            );
        } else {
            return (
                <Box className={this.props.isAdmin ? "" : classes.customTheme} style={{ backgroundColor: themeColor }}>
                    <Grid container direction="column" alignContent="center" className={classes.root}>
                        <Grid item className={classes.logo}>
                            <Link to="/" className={classes.link}>
                                <img className={classes.logo} src={versagoLogo} alt={currentCompany.name} />
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            );
        }
    }
}
export default (withStyles(companyTheme)(Company))
