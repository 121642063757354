import * as yup from "yup"; 

export const filterSetupSchema = yup.object().shape({
    profileName: yup.string().trim().required("Name is required"), 
    company: yup.object()
        .test('isCompanyEmpty',
            'Connection is required',
            function (value) {
                return value?.companyId === 0 ? false : true;
            })
        .nullable()
        .required("Connection is required"),
    
    sourceTableName: yup.string().required("DataSource is required"),
    filterDetails: yup.object()
        .test('isFieldEmpty',
            'Field is required',
            function (value) {
                return value?.fieldName === "" ? false : true;
            })
        .nullable()
        .required("Field is required"),
});