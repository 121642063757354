import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import MUIDataTable from 'mui-datatables';
import { Grid, Typography, IconButton, TableRow, TableCell, Box } from '@mui/material'
import { spacing } from '@mui/system';
import { styled } from '@mui/styles';
import MuiCard from '@mui/material/Card';
import MuiTextField from '@mui/material/TextField';
import Autocomplete from '@mui/lab/Autocomplete';
import cloneDeep from 'lodash/cloneDeep';
import {
    getDataViewsLinks, dataViewDeleteLink,
    dataViewLinkUpdate,  apiDataReportParameterDestinationFieldsList, apiDataFormFieldsList,
    conditionParameterObject, dataViewNewLink, addNewDataViewLink
} from 'admin/actions/dataViewAddLinksActions';
import { getDataViews, getDataViewListAndFormListByRoleList} from 'admin/actions/dataViewActions';
import { getForms } from 'admin/actions/formActions';
import Delete from '@mui/icons-material/DeleteOutline';
import Checkbox from '@mui/material/Checkbox'
import * as enumerator from 'client/components/Common/Enum';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { linksTheme } from './theme/linksTheme';
import CircularProgress from "@mui/material/CircularProgress";
import CrystalReportSource from 'admin/components/section/dataViews/common/CrystalReportSource';
import { showError, hideError } from 'admin/actions/alertActions';
import SubLinks from './SubLinks'
import DerivedField from '../fieldDisplay/DerivedField'
import { ActionTypes as actionTypes } from 'admin/actions/actionTypes';
import { sortName } from 'client/components/Common/Utility';

const linkToEnum = [
    { actionType: "Bizweaver API", value: "bizweaverWebservice" },
    { actionType: "Crystal Report", value: "crystalReport" },
    { actionType: "Data View", value: "report" },
    { actionType: "Form", value: "forms" },
    { actionType: "Other URL", value: "otherURL" }
];
const actionModeEnum = [{ actionMode: "ExistingColumn", value: "existingColumn" },{ actionMode: "NewColumn", value: "newColumn" }]
const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);

const Links = (props) => {
    const [rowsExpanded, setRowsExpanded] = useState([]);
    const [names, setNames] = useState({});
    const [targetNames, setTargetNames] = useState({});
    const [workId, setWorkId] = useState({});
    const [showRules, setShowRules] = useState(false);
    const [ruleIndex, setRuleIndex] = useState(-1);
    const defaultParamTarget = [
        {
            conditionIndex: 0,
            linkActionType: enumerator.LinkActionType.Report,
            sourceId: 0,
            fieldList: [{ key: "", value: "" }]
        }
    ]
    const [paramDestinationFieldSource, setParamDestinationFieldSource] = useState(defaultParamTarget);
    let paramTargetFields = [];

    const {
        classes,
        dispatch,
        dataViewReportLinks,
    } = props;

    useEffect(() => {
        let roleList = "";
        for (let i = 0; i < props.dataView.roles.length; i++) {
            roleList = `${roleList}roleIdList[${i}]=${props.dataView.roles[i].roleId}&`
        }
        if (roleList !== props.objectList.roles && roleList) {
            props.dispatch(getDataViewListAndFormListByRoleList(roleList));
        }
        dispatchParamFieldsDetails()
        if (dataViewReportLinks.length !== 0) {
            let count = dataViewReportLinks.length-1;
            if (dataViewReportLinks[count].actionName && dataViewReportLinks[count].actionType !== 0) {
                dispatch(getDataViewsLinks(dataViewReportLinks));
            }
        }
        else {
            dispatch(addNewDataViewLink());
        }
    }, []);

    useEffect(() => {
        setNames({});
        setTargetNames({});
        setWorkId({});
    }, [dataViewReportLinks])


    const addNewLink = (updatedDataViewLink) => {
        dispatch(dataViewNewLink(updatedDataViewLink));
    }

    const dispatchParamFieldsDetails = () => {
        paramTargetFields = [];
        dataViewReportLinks.map((link, index) => {
            if (link.actionDestinationObjectId > 0) {
                dispatchGetParamDestinationFieldSource(index, link.actionDestinationObjectId, true);
            }
        })
    }

    const getLinkToEnumValue = (actionTypeValue) => {
        let val = linkToEnum.filter(actionObj => actionObj.value === actionTypeValue)
        if (val !== undefined && val.length > 0)
            return val[0];
        return null;
    }
    const getactionModeValue = (actionMode) => {
        let val = actionModeEnum.filter(modeObj => modeObj.value === actionMode)
        if (val !== undefined && val.length > 0)
            return val[0];
        return null
    }

    const getAnchorNameObjectValue = (parameterValue) => {
        let val = props.dataViewReportAnchorName.filter(paramObj => paramObj.fieldName === parameterValue)
        if (val !== undefined && val.length > 0)
            return val[0];
        return null;
    }
    

    const deleteLink = (rowIndex) => {
        let dataViewLinks = cloneDeep(dataViewReportLinks);
        dispatch(dataViewDeleteLink(rowIndex, dataViewLinks));
        setNames({});
        setTargetNames({});
        setWorkId({});
    }

    const handleLinkDataChange = (index, field, value, isParameter, paramIndex, updatedDataViewLinks) => {
        let dataViewLinks = (updatedDataViewLinks) ? updatedDataViewLinks : cloneDeep(dataViewReportLinks);
        let data = { ...dataViewLinks[index] };
        if (!isParameter) {
            switch (field) {
                case "actionDestination":
                    {
                        if (data.actionType === enumerator.LinkActionType.BizweaverWebservice ||
                            data.actionType === enumerator.LinkActionType.OtherURL ||
                            data.actionType === enumerator.LinkActionType.CrystalReport) {
                            data.actionDestinationObjectId = 0;
                            data.actionDestination = value;
                        } else {
                            data.actionDestinationObjectId = value.id;
                            data.actionDestination = value.name;
                        }
                        break;
                    }
                case "actionLinkField":
                    data.actionLinkField = value;
                    break;
                case "actionMode": {
                    data.actionMode = value;
                    if (data.actionMode === enumerator.ActionMode.NewColumn) {
                        data.actionLinkField = ''
                    }
                    break;
                }
                case "actionName":
                    {
                        data.actionName = value;
                        break;
                    }
                case "actionType":
                    {
                        data.actionType = value;
                        data.actionDestinationObjectId = 0;
                        data.actionDestination = "";
                        data.reportLinkDetails = conditionParameterObject();
                        break;
                    }
                case "isShowInNewTab":
                    data.isShowInNewTab = value;
                    break;
                case "BizweaverTaskId":
                    data.bizweaverTaskId = value;
                    break;
                case "formula":
                    data.conditionFormula = value;
                    break;
            }

        } 
        
        let newDataViewLink = dataViewUpdateLinkValue(index, data, dataViewLinks);
        dispatch(dataViewLinkUpdate(newDataViewLink));
        return newDataViewLink;
    }

    const dataViewUpdateLinkValue = (rowIndex, updatedObject, dataViewReportLinks) => {
        let dataViewLinks = dataViewReportLinks.map((reportLink, index) => {
            if (index === rowIndex) {
                reportLink = updatedObject;
            }
            return reportLink;
        })
        return dataViewLinks;
    };

    const addParameterFromCrystalReport = (filters, index) => {
        let urlParamArrayObject = filters.filter(param => param.parameterName).map((param) => {
            let paramObject = conditionParameterObject()[0]
            paramObject.parameterName = param.parameterName;
            return paramObject;
        });
        return addNewParameter(index, urlParamArrayObject, true, cloneDeep(dataViewReportLinks))
    }
    const dispatchGetParamDestinationFieldSource = (conditionIndex, actionDestinationObjectId, isWillMount) => {
        let sourceId = actionDestinationObjectId;
        if (sourceId == null) {
            sourceId = dataViewReportLinks[conditionIndex].actionDestinationObjectId;
        }
        let linkActionType = dataViewReportLinks[conditionIndex].actionType;

        if (linkActionType === enumerator.LinkActionType.Report) {
             apiDataReportParameterDestinationFieldsList(sourceId)
                .then((resp) => {
                    setdestinationFieldSource(resp.data, sourceId, conditionIndex, linkActionType, isWillMount);
                })
                .catch(err => {
                    const { message } = err;
                });

        } else if (linkActionType === enumerator.LinkActionType.Forms) {
            apiDataFormFieldsList(sourceId)
                .then((resp) => {
                    setdestinationFieldSource(resp.data, sourceId, conditionIndex, linkActionType, isWillMount);
                })
                .catch(err => {
                    const { message } = err;
                });
        }


    }
    const setdestinationFieldSource = (data, sourceId, conditionIndex, linkActionType, isWillMount) => {
        let destinationFieldSource = (isWillMount) ? [...paramTargetFields] : [...paramDestinationFieldSource];
        let dSource = { conditionIndex: conditionIndex, linkActionType: enumerator.LinkActionType.Report, sourceId: sourceId, fieldList: [] }

        data.map((field, index) => {
            if (linkActionType === enumerator.LinkActionType.Report) {
                dSource.fieldList.push({ key: field.displayFieldName, value: field.fieldName });
            } else if (linkActionType === enumerator.LinkActionType.Forms) {
                dSource.fieldList.push({ key: field.fieldName, value: field.fieldName });
            }
        });
        if (!isWillMount) {
            if (destinationFieldSource[conditionIndex] != undefined) {
                destinationFieldSource[conditionIndex].fieldList = dSource.fieldList;
            } else {
                destinationFieldSource.push(dSource);
            }
            setParamDestinationFieldSource(destinationFieldSource);
        }
        else {
            destinationFieldSource.push(dSource);
            paramTargetFields = [...destinationFieldSource];
            setParamDestinationFieldSource(paramTargetFields);
        }
       

    }
    const addNewParameter = (conditionIndex, paramObjectArray, clearExistingParam, dataViewLinks) => {
        let dataViewLinksNew = dataViewLinks.map((reportLink, rowIndex) => {
            if (rowIndex === conditionIndex) {
                if (reportLink.reportLinkDetails === undefined || clearExistingParam) {
                    reportLink.reportLinkDetails = [];
                }
                paramObjectArray.map((paramObj) => {
                    reportLink.reportLinkDetails.push(paramObj);
                })
                return reportLink;
            } else {
                return reportLink;
            }
        })
        return dataViewLinksNew;
    };

    

    const dispatchError = (show, message) => {
        if (show) {
            showError(dispatch, message);
        }
        else {
            hideError(dispatch);
        }

    }
    const setFiltersFromCrystalReport = (filters, crystalReportSourceName, rowIndex) => {
        let dataViewLinksNew = addParameterFromCrystalReport(filters, rowIndex);
        handleLinkDataChange(rowIndex, "actionDestination", crystalReportSourceName, false, 0, dataViewLinksNew)

    }

    const handleRuleDialog = (rowIndex) => {
        setShowRules(true);
        setRuleIndex(rowIndex);
    }
    const closeRules = (save, formula) => {
        if (save) {
            handleLinkDataChange(ruleIndex, "formula", formula, false)
        }
        setShowRules(false);
        setRuleIndex(-1);
    }
    const deleteRules = (rowIndex) => {
        handleLinkDataChange(rowIndex, "formula", "", false);
        setShowRules(false);
        setRuleIndex(-1);
    }

    const customRenderNameFields = (value, tableMeta, updatedValue) => {
        const rowIndex = tableMeta.rowIndex;
        return (<TextField
            id={"fieldName" + rowIndex}
            onChange={(e) => {
                let actionNames = { ...names };
                actionNames['name' + rowIndex] = e.target.value;
                setNames(actionNames);
            }}
            value={(names['name' + tableMeta.rowIndex] !== undefined) ? names['name' + rowIndex] : dataViewReportLinks[rowIndex].actionName}
            onBlur={event => {
                let newDataViewLink = handleLinkDataChange(rowIndex, "actionName", event.target.value, false)

                if (rowIndex === dataViewReportLinks.length - 1) {
                    if (event.target.value && dataViewReportLinks[rowIndex].actionType  !== 0) {
                        addNewLink(newDataViewLink);
                    }
                }
                
            }}
            inputProps={{ maxLength: 250 }}
        />)
    }
    const customRenderLinkToFields = (value, tableMeta, updatedValue) => {
        return (<Box pt={.4}><Autocomplete
            
            id={"paramSourceAuto" + tableMeta.rowIndex}
            size="small"
            disableClearable={true}
            fullWidth={true}
            options={linkToEnum}
            getOptionLabel={(option) => option.actionType}
            getOptionSelected={(option, value) => value.actionType === option.actionType}
            value={getLinkToEnumValue(dataViewReportLinks[tableMeta.rowIndex].actionType)}
            onChange={(event, newValue) => {
                handleLinkDataChange(tableMeta.rowIndex, "actionType", newValue.value, false)

                if (!rowsExpanded.includes(tableMeta.rowIndex)) {
                    rowsExpanded.push(tableMeta.rowIndex);
                    setRowsExpanded(rowsExpanded);
                }
                
            }}
            onBlur={event => {

                if (tableMeta.rowIndex === dataViewReportLinks.length - 1) {
                    if (event.target.value && dataViewReportLinks[tableMeta.rowIndex].actionName) {
                        addNewLink(dataViewReportLinks);
                    }
                }
            }}
            renderInput={params => (
                <TextField {...params}
                    id={"displayFieldName" + tableMeta.rowIndex}
                    value="" />
            )}
        /></Box>)
    }
    const customRenderMethodFields = (value, tableMeta, updatedValue) => {
        let actionType = dataViewReportLinks[tableMeta.rowIndex].actionType;
        return (<TextField id={"BizweaverTaskId" + tableMeta.rowIndex}
            disabled
            value={actionType === enumerator.LinkActionType.BizweaverWebservice ? "POST" : ""} />)
    }
    const customRenderWorkFlowIdFields = (value, tableMeta, updatedValue) => {
        let actionType = dataViewReportLinks[tableMeta.rowIndex].actionType;
        return (<TextField id={"BizweaverTaskId" + tableMeta.rowIndex}
            disabled={actionType === enumerator.LinkActionType.BizweaverWebservice ? false : true}
            onChange={(e) => {
                let allWorkId = { ...workId };
                allWorkId['workId' + tableMeta.rowIndex] = e.target.value;
                setWorkId(allWorkId);
            }}
            value={actionType === enumerator.LinkActionType.BizweaverWebservice ? (workId['workId' + tableMeta.rowIndex] !== undefined) ? workId['workId' + tableMeta.rowIndex] : dataViewReportLinks[tableMeta.rowIndex].bizweaverTaskId : ""}
            onBlur={e => {
                handleLinkDataChange(tableMeta.rowIndex, "BizweaverTaskId", e.target.value, false)
            }}
        />)
    }
    const customRenderAnchorTypeFields = (value, tableMeta, updatedValue) => {
        return (<Box pt={.4}><Autocomplete
            id={"anchorType" + tableMeta.rowIndex}
            size="small"
            disableClearable={true}
            fullWidth={true}
            options={actionModeEnum}
            getOptionLabel={(option) => option.actionMode}
            getOptionSelected={(option, value) => value.actionMode === option.actionMode}
            value={getactionModeValue(dataViewReportLinks[tableMeta.rowIndex].actionMode)}
            onChange={(event, newValue) => {
                handleLinkDataChange(tableMeta.rowIndex, "actionMode", newValue.value, false)
            }}
            renderInput={params => (
                <TextField {...params}
                    id={"displayFieldName" + tableMeta.rowIndex}
                    value="" />
            )}
        /></Box>)
    }
    const customRenderAnchorFields = (value, tableMeta, updatedValue) => {
        let actionMode = dataViewReportLinks[tableMeta.rowIndex].actionMode;
        if (actionMode === enumerator.ActionMode.ExistingColumn) {
            return (<Box pt={.4}><Autocomplete
                id={"anchorName" + tableMeta.rowIndex}
                disableClearable={true}
                size="small"
                fullWidth={true}
                loading={props.dataViewReportAnchorName.length === 0}
                options={[...props.dataViewReportAnchorName].sort((a, b) => sortName(a.displayFieldName, b.displayFieldName))}
                getOptionLabel={(option) => option.displayFieldName}
                getOptionSelected={(option, value) => value.fieldName === option.fieldName}
                value={getAnchorNameObjectValue(dataViewReportLinks[tableMeta.rowIndex].actionLinkField)}
                onChange={(event, newValue) => {
                    handleLinkDataChange(tableMeta.rowIndex, "actionLinkField", newValue.fieldName, false)
                }}
                renderInput={params => (
                    <TextField {...params}
                        id={"displayFieldName" + tableMeta.rowIndex}
                        value=""
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {props.dataViewReportAnchorName.length === 0 ? (
                                        <CircularProgress color="inherit" size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            )
                        }}
                    />
                )}
            /></Box>)
        } else if (actionMode === enumerator.ActionMode.NewColumn) {
            return (<TextField id={"anchorName" + tableMeta.rowIndex}
                disabled
                value=''
            />);
        }
        return (<TextField id={"anchorName" + tableMeta.rowIndex} disabled value={''} />);
    }
    const customRenderTarget = (value, tableMeta, updatedValue) => {
        let actionType = dataViewReportLinks[tableMeta.rowIndex].actionType;

        if (actionType === enumerator.LinkActionType.Forms) {
            const currentFormList = props.objectList.objectList.formList.filter(value => value.objectId === dataViewReportLinks[tableMeta.rowIndex].actionDestinationObjectId)[0];
            return (<Box pt={.4}><Autocomplete
                id={"anchorDestination" + tableMeta.rowIndex}
                size="small"
                disableClearable
                fullWidth={true}
                options={props.objectList.objectList.formList}
                loading={props.requestStatus.getObjectListByRole === actionTypes.VGO_GET_DATAVIEWLISTANDFORMLISTBYROLELIST_REQUEST}
                getOptionLabel={(option) => option.objectName ? option.objectName : option.formName}
                getOptionSelected={(option, value) => value.formId === option.formId}
                value={{ formName: (currentFormList) ? currentFormList.objectName : '' }}
                onChange={(event, newValue) => {
                    handleLinkDataChange(tableMeta.rowIndex, "actionDestination", { id: newValue.objectId, name: newValue.objectName }, false)
                    dispatchGetParamDestinationFieldSource(tableMeta.rowIndex, newValue.objectId, false)
                }}
                renderInput={params => (
                    <TextField {...params}
                        id={"displayFieldName" + tableMeta.rowIndex}
                        value=""
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {props.requestStatus.getObjectListByRole === actionTypes.VGO_GET_DATAVIEWLISTANDFORMLISTBYROLELIST_REQUEST ? (
                                        <CircularProgress color="inherit" size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            )
                        }}
                    />
                )}
            /></Box>)
        } else if (actionType === enumerator.LinkActionType.Report) {
            const currentDataView = props.objectList.objectList.dataViewList.filter(value => value.objectId === dataViewReportLinks[tableMeta.rowIndex].actionDestinationObjectId)[0];
            return (<Box pt={.4}><Autocomplete
                id={"anchorDetinationForms" + tableMeta.rowIndex}
                disableClearable
                size="small"
                fullWidth={true}
                options={props.objectList.objectList.dataViewList}
                getOptionLabel={(option) => option.objectName ? option.objectName : option.reportName}
                getOptionSelected={(option, value) => value.reportName === option.reportName}
                value={{ reportName: (currentDataView) ? currentDataView.objectName : '' }}
                loading={props.requestStatus.getObjectListByRole === actionTypes.VGO_GET_DATAVIEWLISTANDFORMLISTBYROLELIST_REQUEST}
                onChange={(event, newValue) => {
                    handleLinkDataChange(tableMeta.rowIndex, "actionDestination", { id: newValue.objectId, name: newValue.objectName }, false)
                    dispatchGetParamDestinationFieldSource(tableMeta.rowIndex, newValue.objectId, false)
                }}
                renderInput={params => (
                    <TextField {...params}
                        id={"displayFieldName" + tableMeta.rowIndex}
                        //value=""
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {props.requestStatus.getObjectListByRole === actionTypes.VGO_GET_DATAVIEWLISTANDFORMLISTBYROLELIST_REQUEST ? (
                                        <CircularProgress color="inherit" size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            )
                        }}
                    />
                )}
            /></Box>)
        }
        else if (actionType === enumerator.LinkActionType.BizweaverWebservice || actionType === enumerator.LinkActionType.OtherURL) {
            return (<TextField id={"displayFieldName" + tableMeta.rowIndex}
                onChange={(e) => {
                    let actionNames = { ...targetNames };
                    actionNames['targetName' + tableMeta.rowIndex] = e.target.value;
                    setTargetNames(actionNames);
                }}
                value={(targetNames['targetName' + tableMeta.rowIndex] !== undefined) ? targetNames['targetName' + tableMeta.rowIndex] : dataViewReportLinks[tableMeta.rowIndex].actionDestination}
                onBlur={e => {
                    handleLinkDataChange(tableMeta.rowIndex, "actionDestination", e.target.value, false)
                }}
                inputProps={{ maxLength: 500 }}
            />)
        }
        else if (actionType === enumerator.LinkActionType.CrystalReport) {
            return <CrystalReportSource id="crystalSource" label="Choose"
                value={dataViewReportLinks[tableMeta.rowIndex].actionDestination}
                dispatchError={dispatchError}
                setFilter={setFiltersFromCrystalReport}
                showFileSelection={false}
                isDataViewLink={true}
                dataViewLinkRowIndex={tableMeta.rowIndex}
                actionLinkRowIndex={tableMeta.rowIndex}
            />
        }
        else {
            return (<TextField id={"displayFieldName" + tableMeta.rowIndex} disabled value="" />)
        }
    }
    const customRenderNewTabFields = (value, tableMeta, updatedValue) => {
        let actionType = dataViewReportLinks[tableMeta.rowIndex].actionType;
        return (<Checkbox id={"taskID" + tableMeta.rowIndex} size="small"
            disabled={actionType === enumerator.LinkActionType.BizweaverWebservice ? true : false}
            onChange={(e) => { handleLinkDataChange(tableMeta.rowIndex, "isShowInNewTab", e.target.checked, false) }}
            checked={actionType === enumerator.LinkActionType.BizweaverWebservice ? false : dataViewReportLinks[tableMeta.rowIndex].isShowInNewTab} />)
    }
    const customRenderRules = (value, tableMeta, updatedValue) => {
        if (!dataViewReportLinks[tableMeta.rowIndex].conditionFormula) {
            return (
                <AddIcon id={"condition" + tableMeta.rowIndex} cursor="pointer" className={classes.ruleIconColor} size="small"
                    onClick={() => handleRuleDialog(tableMeta.rowIndex)}
                />)
        }
        else {
            return (
                <React.Fragment>
                    <EditIcon id={"condition" + tableMeta.rowIndex} cursor="pointer" className={classes.ruleIconColor + ' ' + classes.editIconSize}
                        onClick={() => handleRuleDialog(tableMeta.rowIndex)} />
                    <CloseIcon id={"condition" + tableMeta.rowIndex} cursor="pointer" className={classes.ruleIconColor + ' ' + classes.closeIconSize}
                        onClick={() => deleteRules(tableMeta.rowIndex)} />
                </React.Fragment>
            )
        }
    }
    const deleteLinkTemplate = (value, tableMeta, updateValue) => {
        return (
            <IconButton id={tableMeta.rowData[0]} type="button" onClick={() => deleteLink(tableMeta.rowIndex)} >
                <Delete /></IconButton>
        );
    }

    let gridColumns = {
        columns: [
            {
                name: "actionName",
                label: "Name",
                options: {
                    setCellHeaderProps: (cellValue, rowIndex, columnIndex) => {
                        return {
                            style: {
                                paddingLeft: 4,
                                paddingRight: 0,
                                //backgroundColor: 'white'
                            },
                        };
                    },
                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                        return {
                            style: {
                                paddingLeft: 4,
                                paddingRight: 0,
                                width: 205,
                                //backgroundColor: 'white'
                            },
                        };
                    },
                    customBodyRender: customRenderNameFields,
                }
            },
            {
                name: "actionType",
                label: "Link To",
                options: {
                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                        return {
                            style: {
                                width: 155,
                                paddingRight: 0,
                                //backgroundColor: 'white'
                            },
                        };
                    },
                    customBodyRender: customRenderLinkToFields,
                }
            },
            {
                name: "isDefaultAction",
                label: "Method",
                options: {
                    setCellHeaderProps: (cellValue, rowIndex, columnIndex) => {
                        return {
                            style: {
                                paddingLeft: 20,
                                paddingRight: 0,
                               // backgroundColor: 'white'
                            },
                        };
                    },
                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                        return {
                            style: {
                                width: 104,
                                paddingLeft: 20,
                                paddingRight: 0,
                                //backgroundColor: 'white'
                            },
                        };
                    },
                    customBodyRender: customRenderMethodFields,
                }
            },
            {
                name: "id",
                label: "Workflow ID",
                options: {
                    setCellHeaderProps: (cellValue, rowIndex, columnIndex) => {
                        return {
                            style: {
                               // backgroundColor: 'white',
                                paddingRight: 0,
                            },
                        };
                    },
                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                        return {
                            style: {
                                width: 106,
                                //backgroundColor: 'white',
                                paddingRight: 0,
                            },
                        };
                    },
                    customBodyRender: customRenderWorkFlowIdFields,
                }
            },
            {
                name: "actionMode",
                label: "Anchor Type",
                options: {
                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                        return {
                            style: {
                                width: 216,
                                paddingRight: 0,
                                //backgroundColor: 'white'
                            },
                        };
                    },
                    customBodyRender: customRenderAnchorTypeFields,
                }
            },
            {
                name: "displayFieldName",
                label: "Anchor Field",
                options: {
                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                        return {
                            style: {
                                width: 216,
                                paddingRight: 0,
                                //backgroundColor: 'white'
                            },
                        };
                    },
                    customBodyRender: customRenderAnchorFields,
                }
            },
            {
                name: "actionDestination",
                label: "Target",
                options: {
                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                        return {
                            style: {
                                width: 226,
                                paddingRight: 0,
                                //backgroundColor: 'white'
                            },
                        };
                    },
                    customBodyRender: customRenderTarget,
                }
            },
            {
                name: "isShowInNewTab",
                label: "New Tab",
                options: {
                    setCellHeaderProps: (cellValue, rowIndex, columnIndex) => {
                        return {
                            style: {
                                paddingRight: 0,
                                paddingLeft: 31,
                                //backgroundColor: 'white'
                            },
                        };
                    },
                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                        return {
                            style: {
                                width: 100,
                                paddingRight: 0,
                                paddingLeft: 34,
                                //backgroundColor: 'white'
                            },
                        };
                    },
                    customBodyRender: customRenderNewTabFields,
                }
            },
            {
                name: "Conditions",
                label: "Rules",
                options: {
                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                        return {
                            style: {
                                width: 1,
                                paddingRight: 0,
                                //backgroundColor: 'white'
                            },
                        };
                    },
                    customBodyRender: customRenderRules,
                }
            },
            {
                name: "",
                label: "",
                options: {
                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                        return {
                            style: {
                                width: 30,
                                //backgroundColor: 'white'
                            },
                        };
                    },
                    customBodyRender: deleteLinkTemplate,
                }
            },
        ],
        options: {
            download: false,
            elevation: 0,
            jumpToPage: false,
            viewColumns: false,
            filter: false,
            search: false,
            sorting: true,
            print: false,
            pagination: false,
            selectableRows: 'none',
            expandableRows: true,
            expandableRowsHeader: false,
            responsive: 'scrollMaxHeight',
            rowsExpanded: rowsExpanded,
            onRowExpansionChange: (rowData, rowMeta) => {
                let rowsExpanded = [];
                rowMeta.map((row, index) => {
                    rowsExpanded.push(row.index);
                })
                setRowsExpanded(rowsExpanded);
            },
            renderExpandableRow: (rowData, rowMeta) => {
                const colSpan = rowData.length + 1;
                let dataSource = [];
                let dataSourceMaster = cloneDeep(dataViewReportLinks[rowMeta.dataIndex].reportLinkDetails);
                if (dataSourceMaster !== undefined) {
                    dataSource = dataSourceMaster.map(source => {
                        Object.defineProperty(source, "linkIndex", { value: rowMeta.dataIndex })
                        return source;
                    })
                }
                return (
                    <TableRow style={{ backgroundColor: 'white' }}>
                        <TableCell colSpan={colSpan} style={{ backgroundColor: 'white' }}>
                            {
                                <SubLinks dataSource={dataSource} parentRowIndex={rowMeta.dataIndex} paramDestinationFieldSource={paramDestinationFieldSource}/>
                            }
                        </TableCell>
                    </TableRow>
                );
            }
        },
    }

    return (
        <React.Fragment>
            {showRules && <DerivedField fromDataView={true} isActionLink={true} handleClose={closeRules} visible={showRules} value={dataViewReportLinks[ruleIndex].conditionFormula} />}
            <Card variant="outlined" p={3} className={classes.card}>
                <Grid item xs={12} mt={3.7}>
                <MUIDataTable container 
                                title={''}
                                data={dataViewReportLinks}
                                columns={gridColumns.columns}
                                options={gridColumns.options}
                            />
                        </Grid>
                    </Card>
        </React.Fragment>
        )

}
function mapStateToProps(state) {
    return {
        dataView: state.dataViews.dataView,
        dataViewReportLinks: state.dataViews.dataView.reportLink,
        dataViewReportAnchorName: state.dataViews.dataView.fieldDisplay,
        objectList: state.dataViews.objectListByRole,
        requestStatus: state.dataViews.requestStatus,
        isLoading: state.loader.isLoading,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage
    };
};
export default withRouter(connect(mapStateToProps)(withStyles(linksTheme)(Links)));