export const crystalReportSourceStyle =
{
    fileUploadIcon: {
        padding: "0px 8px 0px 0px",
        marginTop: "-5px",
        marginRight: "-60px"
    },
    fileName: {
        marginTop:"-13px"
    }
    
}
    