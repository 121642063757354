import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import { homeTheme } from 'client/components/Feed/theme/homeTheme';
import { Box, Typography } from '@mui/material'

const useStyles = makeStyles(homeTheme);


export function Loading(props) {
    const classes = useStyles();
    return (
        <Box  >
            { props.loading &&
                <Box className={classes.LoadingStyle} >
                    <CircularProgress />
                    <Typography sx={{ paddingTop: '10px', paddingLeft: '24px' }}  variant="caption" component="div" color="inherit"> Loading </Typography>
                </Box>
            }
        </Box>
    );

}


export function BackdropLoading(props) {
    const classes = useStyles();

    const position = (props.isDashboard) ? "absolute" : "fixed";
    return (
        <Backdrop className={classes.BackdropLoadingStyle} open={props.loading} sx={{ position: position, }}>
            <CircularProgress color="inherit" />
            <Box
                sx={{
                    top: 0,
                    left: 130,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" color="inherit"> Loading </Typography>
            </Box>
        </Backdrop>
    );

}

