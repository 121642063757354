import React from 'react';
import { withRouter } from 'react-router-dom';
import { styled } from '@mui/styles';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { Box, Grid, Switch, Typography, Radio, FormControlLabel, RadioGroup, Select, MenuItem, InputLabel } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import MuiTextField from '@mui/material/TextField';
import { spacing } from '@mui/system';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import { getCategories, getChangeDataView, getChangeDataViewDatabase, getChangeDataViewDataSourceType, getDataViewSourceList, getDataViewSourceFieldList, getDataViewQueryFieldList, getDataViewCrystalReportFilterList, validateOnBlur } from 'admin/actions/dataViewActions';
import { getDataViewSubRecordFields, newSubRecord } from 'admin/actions/dataViewSubRecordsActions';
import { getRoles, sortRoles } from 'admin/actions/userActions';
import { getDatabases } from 'admin/actions/databaseActions';
import { showError, hideError, showConfirmWarning } from 'admin/actions/alertActions';
import { ReportDataSourceType } from 'client/components/Common/Enum';
import Query from './Query';
import Category from './Category';
import { ActionTypes as actionTypes } from 'admin/actions/actionTypes';
import ActionBackdrop from 'admin/components/section/settings/Backdrop';
import MuiCard from '@mui/material/Card';
import ListIcon from '@mui/icons-material/List';
import BarChartIcon from '@mui/icons-material/BarChart';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ContentCopy from '@mui/icons-material/ContentCopy';
import { setupTheme } from 'admin/components/section/dataViews/setup/theme/setupTheme';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import { createFilterOptions } from '@mui/material/Autocomplete';
import CrystalReportSource from 'admin/components/section/dataViews/common/CrystalReportSource';
import cloneDeep from 'lodash/cloneDeep';
import { sortName } from 'client/components/Common/Utility';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
const filterOptions = createFilterOptions({
    //matchFrom: 'start',
    stringify: (option) => option.name,
});
const DataSourceType = [
    {
        name: "-Select-",
        value: "none",

    },
    {
        name: "B1Query on Selected Connection",
        value: ReportDataSourceType.B1Query,

    },
    {
        name: "Crystal Report",
        value: ReportDataSourceType.CrystalReport

    },
    {
        name: "StoredProc on Selected Connection",
        value: ReportDataSourceType.StoredProcedure,

    },
    {
        name: "SQL Query",
        value: ReportDataSourceType.SQLQuery,

    },
    {
        name: "View on Selected Connection",
        value: ReportDataSourceType.View,

    },
   
]

class DataViewSetup extends React.Component {

    componentDidMount() {

        this.dispatchGetRoles();
        this.dispatchGetDatabases();
        this.dispatchGetCategories();

    }
    dispatchGetRoles = () => {

        if (this.props.roles.list.length === 0) {

            this.props.dispatch(getRoles());
        }
    }
    dispatchGetDatabases() {
        if (this.props.databases.list.length === 0) {
            this.props.dispatch(getDatabases());
        }
    }
    dispatchGetCategories() {
        this.props.dispatch(getCategories());
    }

    dispatchError = (show, message) => {
        if (show) {
            showError(this.props.dispatch, message);
        }
        else {
            hideError(this.props.dispatch);
        }

    }


    checkIsEmpty(value) {
        return (!value || value.length === 0 || !value.trim());
    }


    changeCategory = (event, input, value) => {

        this.changeDataView(dataView => dataView.category = value);
    }

    changeDataView = (change) => {

        this.props.dispatch(getChangeDataView(change));
    }
    toggleIsPublic = (event) => {

        this.changeDataView(dataView => dataView.isPublic = event.target.checked);
    }

    toggleHideMenu = (event) => {

        this.changeDataView(dataView => dataView.doNotShowInMenu = event.target.checked);
    }
    toggleEnablePaging = (event) => {
        if (event.target.checked) {
            this.changeDataView(dataView => dataView.enablePaging = event.target.checked);
        }

        else {
            this.changeDataView(dataView => dataView.enablePaging = event.target.checked)
            this.changeDataView(dataView => dataView.pageCount = 0)
        }

    }
    changeDatabase = (change, value) => {
        this.props.dispatch(getChangeDataViewDatabase(change));
    }
    changeDataSourceTypeDataView = (change, value) => {

        this.props.dispatch(getChangeDataViewDataSourceType(change));
        let dataSourceType = value;
        if (dataSourceType === ReportDataSourceType.View || dataSourceType === ReportDataSourceType.B1Query || dataSourceType === ReportDataSourceType.StoredProcedure) {
            this.props.dispatch(getDataViewSourceList(this.props.dataView.company.databaseId, dataSourceType));
        }
    }
    changeDataSourceDataView = (change, value) => {

        this.props.dispatch(getChangeDataView(change));
        let dataSource = value.value;
        let dataSourceType = this.props.dataView.dataSourceType;
        if (dataSourceType === ReportDataSourceType.View) {
            this.props.dispatch(getDataViewSourceFieldList(this.props.dataView.company.databaseId, dataSourceType, dataSource, 0, false, this.props.dataView.fieldDisplay, this.props.dataView.reportFilter, this.props.dataView.recordProcessing, this.props.dataView.reportSubReport));
        }
        else if (dataSourceType === ReportDataSourceType.B1Query || dataSourceType === ReportDataSourceType.StoredProcedure) {
            this.changeDataView(dataView => dataView.executeQuery = true);
            let dataViewDto = { company: this.props.dataView.company, reportId: this.props.dataView.reportId, dataSourceType: this.props.dataView.dataSourceType, dataSource: value, query: this.props.dataView.query }
            this.props.dispatch(getDataViewQueryFieldList(dataViewDto, 0, false, this.props.dataView.fieldDisplay, this.props.dataView.reportFilter, this.props.dataView.recordProcessing, this.props.dataView.reportSubReport));
        }
    }
    onOpenDataSourceDataView = (event) => {
        if (this.props.dataSourceList.length === 0 && this.props.actionType !== actionTypes.VGO_GET_DATAVIEW_DATASOURCE_REQUEST) {
            // if (this.props.dataSourceList.length === 0 ) {
            const dataSourceType = this.props.dataView.dataSourceType;
            if (dataSourceType === ReportDataSourceType.View || dataSourceType === ReportDataSourceType.B1Query || dataSourceType === ReportDataSourceType.StoredProcedure) {
                this.props.dispatch(getDataViewSourceList(this.props.dataView.company.databaseId, dataSourceType));
            }
        }
    }
    confirmYes = (value) => {
        hideError(this.props.dispatch);
        const unAccessibleObjectSettings = value.unAccessibleObjectSettings;
        this.changeDataView(dataView => {
            dataView.roles = sortRoles([...value.roleList])
            
            if (unAccessibleObjectSettings.subReport.length > 0) {
                dataView.reportSubReport = [newSubRecord()];
                
            }
            if (unAccessibleObjectSettings.linkReport.length > 0 || unAccessibleObjectSettings.linkForm.length > 0) {
                let currentList = cloneDeep(this.props.dataView.reportLink);
                for (let linkReport of unAccessibleObjectSettings.linkReport) {
                    const index = currentList.findIndex(link => link.actionType === 'report' && link.actionDestinationObjectId === linkReport.objectId)
                    if (index !== -1) {
                        currentList.splice(index, 1);
                    }
                }
                for (let linkForm of unAccessibleObjectSettings.linkForm) {
                    const index = currentList.findIndex(link => link.actionType === 'forms' && link.actionDestinationObjectId === linkForm.objectId)
                    if (index !== -1) {
                        currentList.splice(index, 1);
                    }
                }
                dataView.reportLink = currentList;
            }
            
            return dataView;
        });

        if (unAccessibleObjectSettings.subReport.length > 0) {
            this.props.dispatch(getDataViewSubRecordFields());

        }
        
    }
    confirmNo = () => {
        hideError(this.props.dispatch);

    }
    onRolesChange = (event, value, reason) => {


        switch (reason) {

            case 'selectOption':
                this.changeDataView(dataView => dataView.roles = sortRoles(value));
                break;
            case 'removeOption':
                {
                    const unAccessibleObjectSettings = this.checkRoleDependency(value)
                    if (unAccessibleObjectSettings.objectNameList.length > 0) {
                        let objectList = ` [${unAccessibleObjectSettings.objectNameList.join()}] `;
                        let actionParam = { roleList: value, unAccessibleObjectSettings: unAccessibleObjectSettings }
                        let action = { primaryAction: this.confirmYes, SecondaryAction: this.confirmNo, value: actionParam }
                        const message = `Removing this role will remove user access to ${objectList} ${unAccessibleObjectSettings.objectTypes} on this data view also assigned to this role. Are you sure?`

                        showConfirmWarning(this.props.dispatch, message, action);
                    }
                    else
                    {
                        this.changeDataView(dataView => dataView.roles = sortRoles(value));
                    }
                    break;
                }

            default:
                break;


        }
    }
    checkRoleDependency = (roleList) => {
        
        let unAccessibleObjectSettings = { subReport: [], linkReport: [], linkForm: [], objectNameList: [], objectTypes: '' }
        let listType = '',formType='';
       const removedRoleList=  this.props.dataView.roles.filter(object1 => {
            return !roleList.some(object2 => {
                return object1.roleId === object2.roleId;
            });
        });
        if (removedRoleList.length > 0) {

            let hasSubReport = false;
            let unAccessibleDataViewList = []
            if (this.props.dataView.reportSubReport && this.props.dataView.reportSubReport.length > 0 && this.props.dataView.reportSubReport[0].reportId) {
                hasSubReport = true;
                unAccessibleDataViewList = this.props.objectList.objectList.dataViewList.filter(dataView => !dataView.isPublic && dataView.roleList.length === 1 && dataView.roleList[0] === removedRoleList[0].roleId);
                const reportIndex = unAccessibleDataViewList.findIndex(report => report.objectId === this.props.dataView.reportSubReport[0].reportId)
                if (reportIndex !== -1) {
                    const subReportName = unAccessibleDataViewList[reportIndex].objectName;
                    unAccessibleObjectSettings.subReport.push(unAccessibleDataViewList[reportIndex]);
                    unAccessibleObjectSettings.objectNameList.push(subReportName)
                    listType = 'lists';
                }
            }
            if (this.props.dataView.reportLink.length > 0) {
                const linkReportList = this.props.dataView.reportLink.filter(link => link.actionType === 'report' && link.actionDestinationObjectId > 0)
                if (linkReportList.length > 0) {
                    if (!hasSubReport) {
                        unAccessibleDataViewList = this.props.objectList.objectList.dataViewList.filter(dataView => !dataView.isPublic && dataView.roleList.length === 1 && dataView.roleList[0] === removedRoleList[0].roleId);
                        for (let linkReport of linkReportList) {
                            let linkReportIndex = unAccessibleDataViewList.findIndex(report => report.objectId === linkReport.actionDestinationObjectId)
                            if (linkReportIndex !== -1) {
                                let linkReportName = unAccessibleDataViewList[linkReportIndex].objectName;
                                unAccessibleObjectSettings.objectNameList.push(linkReportName)
                                unAccessibleObjectSettings.linkReport.push(unAccessibleDataViewList[linkReportIndex]);
                                listType = 'lists';
                            }
                        }
                        
                    }
                }
                const linkFormList = this.props.dataView.reportLink.filter(link => link.actionType === 'forms' && link.actionDestinationObjectId > 0)
                if (linkFormList.length > 0) {


                    const unAccessibleFormViewList = this.props.objectList.objectList.formList.filter(form => !form.isPublic &&form.roleList.length === 1 && form.roleList[0] === removedRoleList[0].roleId);
                    if (unAccessibleFormViewList.length > 0) {
                        for (let linkForm of linkFormList) {
                            let linkFormIndex = unAccessibleFormViewList.findIndex(form => form.objectId === linkForm.actionDestinationObjectId)
                            if (linkFormIndex !== -1) {
                                let linkFormName = unAccessibleFormViewList[linkFormIndex].objectName;
                                unAccessibleObjectSettings.objectNameList.push(linkFormName)
                                unAccessibleObjectSettings.linkForm.push(unAccessibleFormViewList[linkFormIndex]);
                                formType='forms'
                            }
                        }
                    }
                }
            }
        }
        unAccessibleObjectSettings.objectTypes = (listType && formType) ? `${listType}\\${formType}` : `${listType}${formType}`
        return unAccessibleObjectSettings;
    }
    setDataSourceType = () => {


        let dataSource = Object.assign([], DataSourceType);


        if (this.props.dataView.company.databaseType === "hana") {

            const index = dataSource.findIndex(e => e.name === 'StoredProc on Selected Connection')
            dataSource.splice(index, 1)
        }


        return dataSource.map(function getDataSourceType(dataSourceType, index) {



            return (<MenuItem key={index + 1} value={dataSourceType.value}> {dataSourceType.name} </MenuItem>);

        })



    }

    blurValidation = (fieldName, value, dependencyFields) => {

        this.props.dispatch(validateOnBlur(fieldName, value, fieldName, dependencyFields));
    }
    setFiltersFromCrystalReport = (filters, crystalReportSourceName) => {
        this.props.dispatch(getDataViewCrystalReportFilterList(filters, crystalReportSourceName));
    }
    handleClone = () => {

        this.props.dispatch(getChangeDataView(dataView => {
            dataView.previousReportId = dataView.reportId
            dataView.isClone = true
            dataView.reportId = 0;
            dataView.reportName = '';
            dataView.title = '';
            dataView.recordProcessing.id = 0;
            return dataView;
        }));
        this.props.history.push(`/Admin/DataViews/new`);
    }

    renderDataSourceList = () => {
        return (
            <Grid container direction="row" spacing={0}>
                <Grid item xs={12}>
                    <Autocomplete
                        id="size-small-outlined-multi"
                        disabled={this.props.dataView.isClone ? true : false}
                        size="small"
                        fullWidth={true}
                        value={this.props.dataView.dataSource}
                        options={[...this.props.dataSourceList].sort((a, b) => sortName(a.name, b.name))}
                        filterOptions={filterOptions}
                        getOptionLabel={option => option.name}
                        onChange={(event, newValue) => {
                            let changedDataSource = !newValue ? { name: '', value: "" } : newValue;
                            this.changeDataSourceDataView(dataView => dataView.dataSource = changedDataSource,
                                changedDataSource);
                        }}
                        onOpen={(event) => {
                            this.onOpenDataSourceDataView(event);
                        }}
                        onBlur={(event) => {
                            this.changeDataView(dataView => {
                                dataView.dataSourceFieldError = event.target.value ? false : true
                            })
                            this.changeDataView(dataView => {
                                dataView.dataSourceFieldHelper = event.target.value ? ' ' : 'Datasource is required'
                            })

                        }}

                        loading={this.props.actionType === actionTypes.VGO_GET_DATAVIEW_DATASOURCE_REQUEST}
                        renderInput={(params) => (
                            <TextField
                                disabled={this.props.dataView.isClone ? true : false}
                                {...params}
                                label="Data Source"
                                error={this.props.dataView.dataSourceFieldError} helperText={this.props.dataView.dataSourceFieldHelper ? this.props.dataView.dataSourceFieldHelper : ' '}
                                placeholder="Select Data Source"
                                variant="standard"
                                size="small"
                                loading={this.props.actionType === actionTypes.VGO_GET_DATAVIEW_DATASOURCE_REQUEST ? "true" : "false"}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {(this.props.isLoading && this.props.actionType === actionTypes.VGO_GET_DATAVIEW_DATASOURCE_REQUEST) ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}

                            />
                        )}

                    />

                </Grid>
            </Grid>
        );
    }
    //  <Card variant="outlined" p={3} className={classes.card}>
    render() {

        const spacing = 2;
        const { classes } = this.props;
        return (
            <Grid container direction="row" justifyContent="space-between" >
                <Grid item xs={12} >
                    {(this.props.isLoading && (this.props.actionType === actionTypes.VGO_GET_DATAVIEW_REQUEST || this.props.actionType === actionTypes.VGO_GET_DATAVIEW_DATASOURCE_FIELD_REQUEST || this.props.actionType === actionTypes.VGO_GET_DATAVIEW_QUERY_FIELD_REQUEST || this.props.actionType === actionTypes.VGO_EXECUTE_STORED_PROCEDURE_WITH_PARAMETER_DATAVIEW_REQUEST)) && <ActionBackdrop />}
                    {this.props.requestStatus.getDataView === actionTypes.VGO_GET_DATAVIEW_REQUEST && <ActionBackdrop />}
                  
                    <Accordion className={classes.setupAccordion} defaultExpanded={true}>
                        <AccordionSummary classes={{ content: classes.querySummary, root: classes.querySummary }}
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                    >
                                <Typography >Setup</Typography>
                    </AccordionSummary >
                    <AccordionDetails >
                        <Grid container direction="row" spacing={spacing} className={classes.mainGrid}>
                            <Grid item xs={6} className={classes.mainGridItem}>
                                <Grid item><Typography>Information</Typography></Grid>

                                <Box mt={.3} className={classes.subGrid}>


                                    <Grid container direction="row" spacing={4} >
                                        <Grid item xs={6} className={classes.gridPadding} >
                                            <TextField error={this.props.dataView.reportNameError} helperText={this.props.dataView.reportNameHelper ? this.props.dataView.reportNameHelper : ' '} autoFocus
                                                required
                                                value={this.props.dataView.reportName}
                                                onBlur={(event) => {
                                                    this.changeDataView(dataView => {
                                                        dataView.reportNameError = event.target.value ? false : true
                                                    })
                                                    this.changeDataView(dataView => {
                                                        dataView.reportNameHelper = event.target.value ? ' ' : 'Name is required'
                                                    })

                                                }}
                                                onChange={event => this.changeDataView(dataView => dataView.reportName = event.target.value)}
                                                name="ReportName"
                                                label="Name"
                                                size="small"
                                                variant="standard"
                                                inputProps={{ maxLength: 100 }}
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={6} className={classes.gridPadding}>
                                            <TextField error={this.props.dataView.titleError} helperText={this.props.dataView.titleHelper ? this.props.dataView.titleHelper : ' '}
                                                variant="standard"
                                                onBlur={(event) => {
                                                    this.changeDataView(dataView => {
                                                        dataView.titleError = event.target.value ? false : true
                                                    })
                                                    this.changeDataView(dataView => {
                                                        dataView.titleHelper = event.target.value ? ' ' : 'Title is required'
                                                    })

                                                }}
                                                required
                                                name="Title"
                                                value={this.props.dataView.title}
                                                onChange={event => this.changeDataView(dataView => dataView.title = event.target.value)}
                                                label="Title"
                                                size="small"
                                                inputProps={{ maxLength: 100 }}
                                            >
                                            </TextField>
                                        </Grid>
                                    </Grid>


                                </Box>
                                <Box className={classes.subGrid} pt={5.3}>
                                    <Grid container direction="row" spacing={4}>
                                        <Grid item xs={6} className={classes.paddingEmpty}>

                                            <Category disabled={this.props.dataView.isClone ? true : false} errors={this.props.errors ? this.props.errors : {}} onBlurValidation={this.blurValidation} onChangeValue={this.changeDataView} changeDataView={this.changeCategory} dataView={this.props.dataView} requestStatus={this.props.categories.requestStatus} categories={this.props.categories.list} />
                                        </Grid>

                                    </Grid>
                                </Box>
                                <Box className={classes.subGrid} mt={6}>
                                    <Grid container direction="row" spacing={4}>
                                        <Grid item xs={12} className={classes.enablePaging} >
                                            <TextField
                                                variant="standard"
                                                value={this.props.dataView.description}
                                                onChange={event => this.changeDataView(dataView => dataView.description = event.target.value)}
                                                label="Description"
                                                size="small"
                                                inputProps={{ maxLength: 2000 }}
                                                disabled={this.props.dataView.isClone ? true : false}
                                            >
                                            </TextField>
                                        </Grid>

                                    </Grid>
                                </Box>
                                <Box className={classes.subGrid} mt={5.5}>
                                    <Grid container direction="row" spacing={0}>
                                        <Grid item xs={12} className={classes.enablePaging} >
                                            <TextField
                                                variant="standard"
                                                value={this.props.dataView.userInstruction}
                                                onChange={event => this.changeDataView(dataView => dataView.userInstruction = event.target.value)}
                                                label="Instruction"
                                                size="small"
                                                inputProps={{ maxLength: 450 }}
                                                disabled={this.props.dataView.isClone ? true : false}
                                            >
                                            </TextField>
                                        </Grid>

                                    </Grid>
                                </Box>
                                <Box mt={5.8} width={1} >
                                    <Typography >Connection</Typography>
                                </Box>
                                <Box mt={1.5} width={1}>
                                    <Grid container direction="row" spacing={2}>
                                        <Grid item xs={12} >
                                            <Box className={classes.subGrid}>
                                                <Grid container direction="row" spacing={4}  >
                                                    <Grid item xs={6} className={classes.gridPadding}>

                                                        <Autocomplete
                                                            id="dataViewDatabase"
                                                            size="small"
                                                            disabled={this.props.dataView.isClone ? true : false}
                                                            fullWidth={true}
                                                            value={this.props.dataView.company}
                                                            options={this.props.databases.list}
                                                            getOptionLabel={option => option.databaseConnectionName}
                                                            onChange={(event, newValue) => {
                                                                this.changeDatabase(dataView => dataView.company = !newValue ? { databaseId: 0, databaseConnectionName: "", database: "" } : newValue)
                                                            }}

                                                            onBlur={(event) => {
                                                                this.changeDataView(dataView => {
                                                                    dataView.connectionError = event.target.value ? false : true
                                                                })
                                                                this.changeDataView(dataView => {
                                                                    dataView.connectionHelper = event.target.value ? ' ' : 'Connection is required'
                                                                })

                                                            }}


                                                            loading={this.props.databases.requestStatus === actionTypes.VGO_GET_DATABASES_REQUEST}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    variant="standard"
                                                                    {...params}
                                                                    label="Connection Name"
                                                                    name="Connection"
                                                                    error={this.props.dataView.connectionError} helperText={this.props.dataView.connectionHelper ? this.props.dataView.connectionHelper : ' '}
                                                                    required
                                                                    size="small"
                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        endAdornment: (
                                                                            <React.Fragment>
                                                                                {this.props.databases.requestStatus === actionTypes.VGO_GET_DATABASES_REQUEST && <CircularProgress color="inherit" size={20} />}
                                                                                {params.InputProps.endAdornment}
                                                                            </React.Fragment>
                                                                        ),
                                                                    }}

                                                                />
                                                            )}
                                                        />


                                                    </Grid>
                                                    <Grid item xs={6}>

                                                        <Typography className={classes.databaseText}>{this.props.dataView.company.database} </Typography>

                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>



                                    </Grid>
                                </Box>
                                <Box className={classes.subGrid} mt={2.4}>
                                    <Grid container direction="row" spacing={4} >
                                        <Grid item xs={6} className={classes.paddingEmpty} >
                                            <FormControl className={classes.formControl} error={this.props.dataView.dataSourceError} value="Standard" variant="standard">
                                                <InputLabel id="dataSource">Data Source Type*</InputLabel>
                                                    <Select
                                                        MenuProps={{
                                                            disableScrollLock: true
                                                        }}
                                                        variant="standard"
                                                    id="dataSourceType"
                                                    value={this.props.dataView.dataSourceType}
                                                    disabled={this.props.dataView.company.database ? this.props.dataView.isClone ? true : false : true}
                                                    onChange={(event) => {
                                                        this.changeDataSourceTypeDataView(dataView => dataView.dataSourceType = event.target.value, event.target.value)
                                                    }}
                                                    name="dataSourceType"
                                                    error={this.props.dataView.dataSourceError}

                                                    onBlur={(event) => {
                                                        this.changeDataView(dataView => {
                                                            dataView.dataSourceError = event.target.value === 'none' ? true : false
                                                        })
                                                        this.changeDataView(dataView => {
                                                            dataView.dataSourceHelper = event.target.value === 'none' ? 'DataSource is required' : ' '
                                                        })

                                                    }}
                                                >
                                                    {this.setDataSourceType()}

                                                </Select>
                                                <FormHelperText error>{this.props.dataView.dataSourceHelper ? this.props.dataView.dataSourceHelper : ' '}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6} className={classes.dataSourceItem}>


                                            {((this.props.dataView.dataSourceType === ReportDataSourceType.View || this.props.dataView.dataSourceType === ReportDataSourceType.B1Query || this.props.dataView.dataSourceType === ReportDataSourceType.StoredProcedure)) && this.renderDataSourceList()}
                                            {this.props.dataView.dataSourceType === ReportDataSourceType.CrystalReport &&
                                                <Grid container direction="row" spacing={0}>
                                                    <Grid item xs={12}>
                                                    <CrystalReportSource id="crystalSource" label="Choose" disabled={this.props.dataView.isClone ? true : false}
                                                            value={this.props.dataView.reportDataSource}
                                                            dispatchError={this.dispatchError}
                                                            setFilter={this.setFiltersFromCrystalReport}
                                                            showFileSelection={this.props.actionType === actionTypes.VGO_CHANGE_DATAVIEW_DATASOURCETYPE}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            }

                                        </Grid>
                                    </Grid>
                                </Box>



                            </Grid>
                            <Grid item xs={5}>
                                <Grid item><Typography >Availability</Typography></Grid>
                                <Box pt={3}>
                                    <Grid container direction="row">
                                        <Grid item xs={5} className={classes.checkboxItem} >
                                            <Grid container direction="row" spacing={0}>
                                                <Grid item xs={12}>
                                                    <FormControl >
                                                        <FormControlLabel
                                                            disabled={this.props.dataView.isClone ? true : false}
                                                            control={<Switch
                                                                color="primary"
                                                                checked={this.props.dataView.isPublic}
                                                                onChange={this.toggleIsPublic}
                                                            />}
                                                            label="Public" />
                                                    </FormControl>
                                                </Grid></Grid>
                                            <Grid container direction="row" spacing={0}>
                                                <Grid item xs={12}>
                                                    <FormControl>
                                                        <FormControlLabel
                                                            disabled={this.props.dataView.isClone ? true : false}
                                                            control={<Switch
                                                                color="primary"
                                                                checked={this.props.dataView.doNotShowInMenu}
                                                                onChange={this.toggleHideMenu}
                                                            />}
                                                            label="Do not show in menu" />
                                                    </FormControl>
                                                </Grid></Grid>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Grid item container direction="column"><Box mt={2} width={382} pl={1.2}>
                                                <Autocomplete multiple
                                                    id="dataViewRole"
                                                    disabled={this.props.dataView.isClone ? true : false}
                                                    size="small"
                                                    fullWidth={true}
                                                    disableClearable
                                                    className={classes.roleControl}
                                                    loading={this.props.roles.requestStatus === actionTypes.VGO_GET_ROLES_REQUEST}
                                                    options={this.props.roles.list}
                                                    getOptionLabel={option => option.roleName}
                                                    isOptionEqualToValue={
                                                        (option, value) => option.roleName === value.roleName}
                                                    value={this.props.dataView.roles}
                                                    onChange={this.onRolesChange}
                                                    onBlur={(event, value, reason) => {
                                                        this.changeDataView(dataView => {
                                                            dataView.rolesError = this.props.dataView.roles.length !== 0 ? false : true
                                                        })
                                                        this.changeDataView(dataView => {
                                                            dataView.rolesHelper = this.props.dataView.roles.length !== 0 ? ' ' : 'Roles is required'
                                                        })

                                                    }}
                                                    renderInput={params => (
                                                        <TextField {...params} variant="outlined"
                                                            error={this.props.dataView.rolesError} helperText={this.props.dataView.rolesHelper ? this.props.dataView.rolesHelper : ' '}
                                                            required
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <React.Fragment>
                                                                        {this.props.roles.requestStatus === actionTypes.VGO_GET_ROLES_REQUEST && <CircularProgress color="inherit" size={20} />}
                                                                        {params.InputProps.endAdornment}
                                                                    </React.Fragment>
                                                                ),
                                                            }}
                                                            size="medium"
                                                            label="Roles"
                                                        />
                                                    )}
                                                /></Box>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Box>
                                <Box mt={3.2}>
                                    <Grid container direction="row" spacing={3}>
                                        <Grid item><Typography >Presentation Format</Typography></Grid>
                                    </Grid>
                                </Box>
                                <Box mt={1.8}>
                                    <Grid container direction="row" spacing={3}>

                                        <Grid item xs={5} className={classes.scheduleGrid}>

                                            <RadioGroup name="reportFormat" color="secondary" value={this.props.dataView.reportFormat} onChange={(event) => {
                                                this.changeDataView(dataView => dataView.reportFormat = event.target.value)
                                            }} >
                                                <FormControlLabel disabled={this.props.dataView.isClone ? true : false} value="regular" control={<Radio color="primary" />} label={<Grid container spacing={1}><Grid item className={classes.formatPadding}><ListIcon /></Grid> <Grid item className={classes.formatPadding}>List or</Grid><Grid item className={classes.formatPadding}><BarChartIcon /></Grid><Grid item className={classes.formatPadding}>Chart</Grid></Grid>} />
                                                <FormControlLabel disabled={this.props.dataView.isClone ? true : false} value="calendar" control={<Radio color="primary" />} label={<Grid container spacing={1} ><Grid item className={classes.formatPadding}> <CalendarTodayIcon /></Grid> <Grid item className={classes.formatPadding}>  Schedule</Grid></Grid>} />
                                            </RadioGroup>

                                        </Grid>
                                        <Grid item xs={7} className={classes.enablePaging}>
                                            <Grid container direction="row" spacing={0}>

                                                <Grid item xs={6} className={classes.formatPadding}>
                                                    <Box pt={2}>  <FormControl>
                                                        <FormControlLabel
                                                            disabled={this.props.dataView.isClone ? true : false}
                                                            control={<Switch
                                                                color="primary"
                                                                checked={this.props.dataView.enablePaging}
                                                                onChange={this.toggleEnablePaging}
                                                            />}
                                                            label={<span className={classes.setupSpan}>Enable Paging</span>} />

                                                    </FormControl></Box>
                                                </Grid>
                                                <Grid item xs={6}><Box width={133} pt={2} >
                                                    <TextField
                                                        variant="standard"
                                                        error={this.props.dataView.pageCountError && this.props.dataView.enablePaging} helperText={this.props.dataView.pageCountHelper && this.props.dataView.enablePaging ? this.props.dataView.pageCountHelper : ' '}
                                                        disabled={this.props.dataView.isClone ? true : this.props.dataView.enablePaging ? !this.props.dataView.enablePaging : true}
                                                        required
                                                        label="Record Limit"
                                                        size="medium"
                                                        value={this.props.dataView.pageCount}
                                                        onChange={(event) => {
                                                            const regex = /^[0-9\b]+$/;
                                                            if (event.target.value === '' ||
                                                                regex.test(event.target.value)) {
                                                                this.changeDataView(dataView => dataView.pageCount = event.target.value)
                                                            }
                                                        }}
                                                        onBlur={(event, value, reason) => {
                                                            this.changeDataView(dataView => {
                                                                dataView.pageCountError = this.props.dataView.pageCount.length !== 0 ? this.props.dataView.pageCount > 0 ? false : true : true
                                                                dataView.pageCountHelper = this.props.dataView.pageCount.length !== 0 ? this.props.dataView.pageCount > 0 ? '  ' : 'Record limit is required' : 'Record limit is required'
                                                            })
                                                        }}

                                                        inputProps={{ maxLength: 5 }}
                                                    >
                                                    </TextField></Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>

                            </Grid>

                            <Grid item xs={1} className={classes.cloneGridItem}>

                                <Box mt={44} width={1} className={classes.cloneGrid} >
                                    <Button
                                        className={this.props.classes.customControlButton + ' ' + this.props.classes.buttonSave + ' ' + this.props.classes.cloneButton}
                                        color="primary"
                                        variant="contained"
                                        disableElevation
                                        disabled={this.props.dataViews.isUpdate ? false : true}
                                        startIcon={<ContentCopy />} onClick={this.handleClone} >CLONE</Button>
                                </Box>

                            </Grid>
                        </Grid>

                    </AccordionDetails>
                    </Accordion>
                </Grid>
                    <Grid item xs={12} >
                        <Grid item xs={12} >
                    <Grid container direction="row" justifyContent="space-between" spacing={1}>
                        <Grid item xs={12}>
                            {this.props.dataView.dataSourceType === ReportDataSourceType.SQLQuery && <Query disabled={this.props.dataView.company.database ? this.props.dataView.isClone ? true : false : true} changeDataView={this.changeDataView} />}
                        </Grid>
                    </Grid>
                        </Grid>
                </Grid>
            </Grid>
        );
    }

}

function mapStateToProps(state) {

    return {
        dataViews: state.dataViews,
        dataView: state.dataViews.dataView,
        objectList: state.dataViews.objectListByRole,
        requestStatus: state.dataViews.requestStatus,
        dataSourceList: state.dataViews.dataSources,
        roles: { requestStatus: state.roles.getRoleListRequestStatus, list: state.dataViews.roles },
        databases: { requestStatus: state.databases.getListRequestStatus, list: state.databases.databases },
        categories: state.dataViews.categories,
        isLoading: state.loader.isLoading,
        actionType: state.loader.type,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage,
        errors: state.dataViews.errors ? state.dataViews.errors : {},
    };
};

export default withRouter(connect(mapStateToProps)(withStyles(setupTheme)(DataViewSetup)));