import React from 'react';
import { styled } from '@mui/styles';
import { spacing } from '@mui/system';
import { Grid, Typography } from '@mui/material';
import MuiButton from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { updateHomePage } from 'admin/actions/homePageActions';
import { setupTheme } from 'admin/components/section/dataViews/setup/theme/setupTheme';
import cloneDeep from 'lodash/cloneDeep';
import { ActionTypes as actionTypes } from 'admin/actions/actionTypes';
import ActionBackdrop from 'admin/components/section/settings/Backdrop';
import { withStyles } from '@mui/styles';
const Button = styled(MuiButton)(spacing);

class HomePageEditHeader extends React.Component {

    constructor(props) {
        super(props);
        this.isUpdate = this.props.match.params.homePageId !== "new";
        
    }

    componentDidUpdate(prevProps) {
        this.isUpdate = this.props.match.params.homePageId !== "new";

    }

   onDiscardClick = () => {
        
        this.props.history.push('/Admin/HomePage');
    }

 
    resetUrl=(props)=>{
        if (props.match.params.homePageId === "new" && props.homePageContent.id !== 0) {
            this.isUpdate = props.homePageContent.id;
            this.props.history.push({
                pathname: `/Admin/HomePage/${props.homePageContent.id}`,
                state: {
                    data: props.homePageContent
                },
            })
        }

    }
    
    saveHomePageContent=() => {
        let homePageContent = cloneDeep(this.props.homePageContent);
        this.props.dispatch(updateHomePage(homePageContent));
    }

    render() {
        const { classes } = this.props;
       this.resetUrl(this.props)
       
        
        return (
            <Grid container direction="row" justifyContent="space-between" spacing={2}>
                <Grid item xs={4}>
                    <Typography variant="h4">{this.isUpdate ? "Edit" : "Add"} Home Page Content</Typography>
                        {this.props.actionType === actionTypes.VGO_UPDATE_DATAVIEW_REQUEST && <ActionBackdrop />}
                        
                </Grid>
                

                <Grid item xs={3}>
                    <Grid container direction="row" justifyContent="flex-end" spacing={1} >
                        <Grid item>
                            <Button className={`${classes.customControlButton} ${classes.buttonCancel}`}
                                variant="defaultContained"
                               disableElevation
                            onClick={this.onDiscardClick}
                        >CANCEL</Button>
                        </Grid>
                        <Grid item>
                            <Button className={`${classes.customControlButton} ${classes.buttonSave}`}
                            color="primary"
                            variant="contained"
                            disableElevation
                            startIcon={<CheckIcon />}
                                onClick={this.saveHomePageContent}
                        >SAVE</Button>
                    </Grid>
                    </Grid>
            </Grid>
                </Grid>

            
        );
    }

}

/**
 * mapStateToProps
 * @param {State} state 
 */
function mapStateToProps(state) {
    let props = {
        alert: state.alert,
        homePageContent: state.homePage.homePageContent,
    };

    return props;
};

export default withRouter(connect(mapStateToProps)(withStyles(setupTheme)(HomePageEditHeader)));
