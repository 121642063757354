import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import { getReports, setChangeRoleData, setChangeRoleDataName} from 'admin/actions/roleActions';
import { RoleTab } from 'admin/actions/actionTypes';
import { theme } from 'common/theme.js';
import { produce } from 'immer';
import { DateTime } from 'luxon';
import { rolesTheme } from './theme/rolesTheme.js';
import { withStyles } from '@mui/styles'; 
import GridCheckBox from './GridCheckBox';
import GridCheckBoxHeader from './GridCheckBoxHeader';
import { MuiGridOptions } from 'admin/components/Common/CommonOptions';
import ActionBackdrop from 'admin/components/section/settings/Backdrop';
import { objectListFormatDate } from 'admin/components/Common//DateFormat';
 
 
const defaultColumnOptions = {
    filter: true,
    sort: true,
    setCellProps: (cellValue, rowIndex, columnIndex) => {
        return {
            style: {
                padding: theme.spacing(1)
            }
        };
    }
};

class ReportList extends React.Component {

    constructor(props) {
        super(props);
        const options = {
            filterType: "textField",
            fixedHeader: true,
            sort: true,
            search: true,
            selectableRows: "none",
            responsive: 'scrollMaxHeight',
            download: false,
            elevation: 0,
            filter: true,
            filterType: 'multiselect',
            jumpToPage: true,
            searchOpen: false,
            pagination: false,
            onColumnSortChange: (changedColumn, direction) => {
                this.setState(produce(draft => {
                    draft.options.sortOrder = { name: changedColumn, direction: direction };
                    draft.sortSettings = { sortFrom: changedColumn, direction: direction, clearSort: false };
                }));
            },
            print: false,
            searchPlaceholder: 'Enter search text ...',
            sortOrder: {
                name: 'reportName',
                direction: 'asc'
            }, searchOpen: true,
        }
       this.state = {
           columns: [
               {
                   name: "activeState",
                   label: "Active",
                   options: {
                       customHeadRender: (columnMeta, updateDirection) => (
                           <GridCheckBoxHeader 
                               selectAll={props.classes.selectAll}
                               selectCheckbox={this.props.classes.selectCheckbox}
                               checked={this.props.roles.reports?.length === this.props.roles.role.ReportList?.length ? true : false}
                               handleChange={this.handleChange}
                               handleSelectCheckBoxSort={this.handleSelectCheckBoxSort}
                               sortSettings={this.state.sortSettings}
                               clearSort={this.clearSelectSort}
                               selectedObjectKey={ "ReportList"}
                               />
                           
                       ), 
                       //...defaultColumnOptions,
                       filter: false,
                       customBodyRender: this.checkBoxTemplate,
                       //filterList: [ActiveState.active],
                   }
               },
               {
                   name: "reportId",
                   label: "",
                   options: {
                       filter: false,
                       display: false,
                       viewColumns:false,
                   }
               },
                {
                    name: "reportName",
                    label: "Name",
                    options: { defaultColumnOptions }
                },
                {
                    name: "type",
                    label: "Type",
                    options: {
                        defaultColumnOptions
                    }
                },
                {
                    name: "category",
                    label: "Category",
                    options: { defaultColumnOptions }
                },
                {
                    name: "createTime",
                    label: "Created",
                    options: {
                        defaultColumnOptions,
                        customBodyRender: objectListFormatDate
                    }
                }
           ],
           options: options,
           sortSettings: { sortFrom: "", direction: "", clearSort: false}

        };
    }
    checkBoxTemplate = (value, tableMeta, updateValue) => {
        
        let status = false;
        let rId = tableMeta.rowData[1];
        let rName = tableMeta.rowData[2];
        let reportIndex = this.props.roles.role.ReportList.findIndex(c => c.ReportId === rId || c.reportId === rId)
         
        if (reportIndex !== -1) {
            status = true;
        }
        return ( 
            <GridCheckBox selectCheckboxclasses={this.props.classes.selectCheckbox ? this.props.classes.selectCheckbox : ""} checked={status} id={rId} name={rName} depended={{ type: "report", id: rId, roleId: this.props.roles.role.roleId }}
                handleSelect={this.handleSelect} />
        )
    }

    componentDidMount() {
        this.dispatchGetReport();
    }
    componentDidUpdate(prevProps, prevState) {
       if (this.props.roleData !== undefined) { 
            
                this.getSelectedRow(this.props)
         }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.sortSettings.sortFrom !== this.state.sortSettings.sortFrom && !nextState.sortSettings.clearSort) {
            return true;
        }
        if (nextState.sortSettings.sortFrom === this.state.sortSettings.sortFrom && nextState.sortSettings.direction !== this.state.sortSettings.direction && !nextState.sortSettings.clearSort) {
            return true;
        }
        if (nextProps.roles.role.activeTab === RoleTab.report || nextProps.roles.role.activeTab === RoleTab.roleName || nextProps.roles.role.activeTab === RoleTab.defaultObjectId || nextProps.roles.role.activeTab === RoleTab.defaultObjectTypeWithOutRefresh) {
            if (this.props.roles.reports!==undefined) {
                return false;
            } else { return true;}
        } 
        return true;
    }
    dispatchGetReport = () => {
        if (this.props.roles.reports=== undefined) {
            
            this.props.dispatch(getReports());
        }
    }
    formatDate = (value) => {
        if (typeof (value) === "string") {
            value = DateTime.fromISO(value);
        }
        return value.toFormat('LL-dd-yyyy hh:mm:ss a');

    }
    getSelectedRow = (props) => {
        let array = []
        if ( props.roles !== undefined) {
            if (props.roles.role.ReportList !== undefined && props.roles.reports !== undefined) {
                props.roles.role.ReportList.map((report) => {
                    return props.roles.reports.map((role, index) => {
                        if (role.reportId === report.ReportId) {
                            array.push(index)
                        }
                        return "";
                    });
                });
            }
        }

        return array


    }
    handleChange = (e) => {
        
        let array = [];
        if (e.target.checked) {

            for (let i = 0; i < this.props.roles.reports.length; i++) {
                array.push({ reportId: this.props.roles.reports[i].reportId, reportName: this.props.roles.reports[i].reportName  })
            }
        }

        this.props.dispatch(setChangeRoleData(RoleTab.reportWithRefresh, '', this.props.roles.role.ReportList, array));


    }
    
    handleSelect = (reportId, reportName, checked,anchor) => {
            this.props.dispatch(setChangeRoleDataName(RoleTab.report, reportId, reportName, this.props.roles.role.ReportList));
    }
    handleSelectCheckBoxSort = () => {
        const sortDirection = this.state.sortSettings.direction
        const sortField = this.state.sortSettings.sortFrom
        let direction = "";
        if (sortField === "select" && sortDirection === "asc") {
            direction="desc"
            }
        else if (sortField === "select" && sortDirection === "desc") {
            direction="asc"
            }
        else
        {
            direction = "asc";
            }
        this.setState(produce(draft => {
            draft.options.sortOrder = { name: "select", direction: direction};
            draft.sortSettings = { sortFrom: "select", direction: direction, clearSort:false };
        }));
    }
    clearSelectSort = () => {
        this.setState(produce(draft => {
            draft.options.sortOrder = { };
            draft.sortSettings = { sortFrom: "", direction: "",clearSort:true };
        }));
    }

    render() {
        const { classes } = this.props;
        const reports = this.props.getSortedObjectList(this.props.roles.reports, this.props.roles.role.ReportList, "reportId", "ReportId", "ReportName", this.state.sortSettings);
        return ( 
            <div style={this.props.style}>
                {this.props.isLoading && <ActionBackdrop />}
               
                <MUIDataTable container classes={{ responsiveScrollMaxHeight: classes.responsiveScrollMaxHeight }}
                    title={''}
                    data={reports}
                    columns={this.state.columns}
                    options={MuiGridOptions(this.state.options, this.props.isLoading) }
                />  
            </div>
        );

    }

}
function mapStateToProps(state) {
  
    return {
        roles: state.roles,
        isLoading: state.loader.isLoading,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage

    };
};

export default withRouter(connect(mapStateToProps)(withStyles(rolesTheme)(ReportList)));



