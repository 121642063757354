import clientApi from 'client/api/clientApi';
export const callBizweaverAction = async (workFlowurl, taskId, parameters) => {
    let parameter = "{pTaskID:" + taskId + ",pStartAfter:0,pArguments:{" + parameters + "}}";

    if(!taskId){
        parameter = parameters
    }
    let postHeaders;
     const response = await clientApi.GetCurrentUtcDate()
    if (response.status === 200) {
        const responseJson = response.data;
        postHeaders = {
            "Content-Type": "application/json",
            "authToken": responseJson.encryptedToken,
            "authTokenStamp": responseJson.currentUtcDate,
        }

        return clientApi.callBizweaver(workFlowurl, parameter, postHeaders)
       
    }
    else {
        return response;
    }


}