import React, { useEffect}  from "react";
import 'roboto-fontface/css/roboto/roboto-fontface.css' 
import { IdleTimer } from "./IdleTimerComponent";
import MuiButton from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IdleTimerTheme } from 'theme/IdleTimerTheme';
import { withStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { spacing } from '@mui/system';
import Cookies from 'universal-cookie';
import { getDeviceType } from 'client/components/Common/Utility';
import * as Enums from 'client/components/Common/Enum';


const Button = styled(MuiButton)(spacing);
const cookies = new Cookies();
const deviceType = getDeviceType();

class IdleTimerContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: true,
            modalIsOpen: false, 
            servertimeOut: 500,  
            debounceTime: 30000, 
            promptBeforeIdle: 50000,
            clearIntervalId: null,
            clearServerCallIntervalId:null,
            remainingTime: 50, 
            throttle: 0
        } 
        this.idleTimer = null
       
        window.addEventListener('storage', (event) => {
         
            if (event.storageArea != localStorage) return;
            if (event.key === 'loggedIn' ) { 
                this.stayLoggedIn();
            }
        });
        const versagoCookie = cookies.get('Versago');
        if (versagoCookie !== undefined) { 
            window.localStorage.setItem("loggedIn", "true");
        }
         
    }

    componentDidMount() { 
        this.getServerSession();
        const versagoCookie = cookies.get('Versago');
        if (versagoCookie !== undefined) { 
            window.localStorage.setItem("loggedIn", "false");
        }
     
    }
    componentDidUpdate() {
       
        if (!this.idleTimer.isPrompted()) {          
            if (this.props.sessionTimeOut > 0 && this.state.servertimeOut != this.props.sessionTimeOut) {
               /* console.log("timer-ServerSession did update setServerSession");*/
             this.setServerSession(this.props.sessionTimeOut);
            } else {
               // console.log("timer-start");
                //this.idleTimer.start();
            }
            if (this.props.logOutAll) {
                
                    this.idleTimer.message("fulllogout")
                //    window.location.reload();
                clearInterval(this.state.clearIntervalId);
                clearInterval(this.state.clearServerCallIntervalId);
                    
                 
            }
        }
    }

    setServerSession = (timeout) => {
      
        this.setState({ servertimeOut: timeout, remainingTime:50}, function () { 
            this.idleTimer.start()
        }); 
    }
    getServerSession = async () => {
        var responseObject = {};
        var currentObject = this;
        fetch('adminapi/SessionTimeOut')
            .then(async function (response) {
                if (response.status === 200) {  
                    responseObject = await response.json();  
                        var timeout = responseObject.sessionTime;
                        var hourtosecond = timeout.hours * 3600;
                        var minutestosecond = timeout.minutes * 60;
                        var seconds = timeout.seconds;
                         var millisecond =  (hourtosecond + minutestosecond + seconds) ; //30000;// 
                    currentObject.setState({ servertimeOut: millisecond, remainingTime: 50}, function () {
                        
                            this.idleTimer.start()
                        });
                } 
            })  
    }
    setModalIsOpen = (status) => {
        if (status) {
            this.setState({ modalIsOpen: status, remainingTime: 50 })
        } else {
            this.setState({ modalIsOpen: status})
        }
    }
    setIsLoggedIn = (status) => {
        this.setState({ stayLoggedIn: status })
    } 
    onIdle = () => {
        
        if (this.state.servertimeOut) {
            
                const versagoCookie = cookies.get('Versago');
                if (versagoCookie !== undefined) {
                    let roleCookie = cookies.get('roleId');
                    if (roleCookie !== undefined) {
                        this.logOut();
                       
                        
                    }
                }
             
        } 
    } 
    onPrompt = () => {
        clearInterval(this.state.clearIntervalId);
        clearInterval(this.state.clearServerCallIntervalId);
        const versagoCookie = cookies.get('Versago'); 
        if (versagoCookie !== undefined) {
            let roleCookie = cookies.get('roleId'); 
            if (roleCookie !== undefined) { 
                this.setModalIsOpen(true); 
                const clearServerCallIntervalId = setInterval(() => {
                    this.setState({ remainingTime:  (this.idleTimer.getRemainingTime() / 1000).toFixed(0), clearServerCallIntervalId: clearServerCallIntervalId })
                }, 1000)
            }
        } 
    };
   
    onAction = () => {
        fetch('adminapi/SessionInvoke');
        if (this.state.debounceTime > 0) {
            var today = new Date();
           // console.log('debounce -' + today.toLocaleString())
           clearInterval(this.state.clearServerCallIntervalId);
           const clearServerCallIntervalId = setInterval(() => {
               this.setState({ clearServerCallIntervalId: clearServerCallIntervalId })
               var today = new Date();
               //console.log('Interval -' + today.toLocaleString())
            fetch('adminapi/SessionInvoke');
           }, 180000)
           this.setState({ debounceTime: 0, throttle: 30000, clearServerCallIntervalId: clearServerCallIntervalId });
        }
        else if (this.state.throttle > 0) {
            var today = new Date();
           // console.log('throttle -' + today.toLocaleString())
            
           clearInterval(this.state.clearServerCallIntervalId);
           this.setState({ debounceTime: 30000, throttle: 0, clearServerCallIntervalId:null});
        }
    }
    
    logOut = async () => {
        clearInterval(this.state.clearIntervalId);
        clearInterval(this.state.clearServerCallIntervalId);
        this.setModalIsOpen(false);
        this.setIsLoggedIn(false);
        this.idleTimer.reset() 
        await fetch("api/Login/SignOut");
        this.idleTimer.message("fulllogout")
         window.location.reload();
        
    }; 
    stayLoggedIn = () => { 
        // this.getServerSession(); 
        this.setModalIsOpen(false); 
        this.idleTimer.message("stay") 
        clearInterval(this.state.clearIntervalId);
        clearInterval(this.state.clearServerCallIntervalId);
       this.idleTimer.start()
    }; 
    onMessage = async (message) => {
       
        //console.log("timer-message",message)
        if (message==="stay") {
            fetch('adminapi/SessionInvoke'); 
            this.setModalIsOpen(false);
        }  
        else if (message === "fulllogout") {
            this.setModalIsOpen(false);
            this.setIsLoggedIn(false);
            this.idleTimer.reset()
          //  if (deviceType === Enums.DeviceType.Desktop) { 
                 await fetch("api/Login/SignOut");
               window.location.reload();
           // }
        } 
    }
    getServerTimeOutMilliseconds = () => {
        var timeout = this.state.servertimeOut * 1000;
        /*console.log("timer-getServerTimeOutMilliseconds",timeout);*/
        return timeout;
    }
    render() {
        const { classes } = this.props;
        const serverTimeOut = this.getServerTimeOutMilliseconds();
        
        return (
            <div> 
                <Dialog
                    classes={{ paper: classes.dialog }}
                    open={this.state.modalIsOpen} 
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                     >
                    <DialogTitle id="alert-dialog-title" className={classes.dialogHeader}>
                        <Typography variant="h6"> Are you still here?</Typography> 
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <DialogContentText id="alert-dialog-description">
                            <Typography variant="body1" className={ classes.alertContent}> You will be automatically logged out in {this.state.remainingTime} seconds.</Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button disableFocusRipple={true} className={classes.linkButton} color="primary" autoFocus onClick={()=>this.stayLoggedIn()}> Stay Logged In  </Button>
                    </DialogActions>
                </Dialog>
                <IdleTimer name="idleTimerComponent" timeout={serverTimeOut} onIdle={this.onIdle} promptBeforeIdle={this.state.promptBeforeIdle} onPrompt={this.onPrompt}
                   debounce={this.state.debounceTime}
                    throttle={this.state.throttle}
                    syncTimers={1}
                    onAction={this.onAction} onMessage={this.onMessage} crossTab={true} ref={ref => { this.idleTimer = ref }}
                    
                >
                    <div></div>
                </IdleTimer>
            </div>
        );
    }
}

export default (withStyles(IdleTimerTheme)(IdleTimerContainer));
