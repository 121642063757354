import React from 'react';
import Weather from './Weather'
import Joke from './Joke'
import Quote from './Quote'
import Links from './Links'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'client/store/CustomWidget';
import { Loading } from 'client/components/Loading';
import * as Enums from 'client/components/Common/Enum';
import { Box, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { sideBarTheme } from './theme/sideBarTheme';
class sideBar extends React.Component {

    

    componentDidMount() { 
		this.props.requestCustomWidget(); 
	} 

    renderWidgets = () => {
        const { isSiteTheme, themeColor, widgetList } = (this.props.siteTheme) ? this.props.siteTheme : '';
        const widgets = (isSiteTheme) ? widgetList : this.props.customWidget;
        return widgets.map((widget, index) => { //.filter(widget => widget.type !== Enums.WidgetType.Joke)
            
            if (widget.type === Enums.WidgetType.Weather) {
                return (
                    <Grid item xl={12} lg={12} md={6} sm={12} style={{ width: "100%" }}>  <Weather key="1" widget={widget} classes={this.props.classes} isSiteTheme={isSiteTheme} themeColor={themeColor} /></Grid>
                    );

            }
            else if (widget.type === Enums.WidgetType.Joke) {
            return (
                <Grid item xl={12} lg={12} md={6} sm={12}><Joke key="2" widget={widget} classes={this.props.classes} /></Grid>
            );

            }
            else if (widget.type === Enums.WidgetType.Links) {
                return (
                    <Grid item xl={12} lg={12} md={6} sm={12}><Links key="3" widget={widget} classes={this.props.classes} isSiteTheme={isSiteTheme} themeColor={themeColor} /></Grid>
            );

            }
            else if (widget.type === Enums.WidgetType.Quote) {
                return (
                    <Grid item xl={12} lg={12} md={6} sm={12}> <Quote key="4" widget={widget} classes={this.props.classes} /></Grid>
                );
            }
            return "";
        });
        
    }
    renderWidget = () => {
        const { classes } = this.props;
        const { isSiteTheme, widgetList } = (this.props.siteTheme) ? this.props.siteTheme : '';
        const widgets = (isSiteTheme) ? widgetList : this.props.customWidget;
        const widgetCount = widgets.length;
        const widget = widgetCount ? (
            <div className={classes.sidebarWrapper} id="divSidebar" style={this.props.isLoading ? { paddingTop: "20%" } : {}}>
                {!this.props.siteTheme && <Loading loading={this.props.isLoading} />}
                <div className={classes.sidebar}>
                    <ul className={classes.widgets}>

                        <Grid container>
                          
                                {this.renderWidgets(this.props.customWidget)}
                                </Grid>
                    </ul>
                </div>
            </div >
        ) :
            (<div />)
        if (this.props.dataFetched && widgetCount === 0) {
            this.props.hideSideBar();
        }
        return widget;
    }
    render() {

        
        return (
            
                
            this.renderWidget()
                 
                
    
    );
}
}
export default connect(
	state => state.customWidget,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(withStyles(sideBarTheme)(sideBar));

