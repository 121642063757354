 
import * as React from 'react';
 
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-icons/styles/material.css';
import '@syncfusion/ej2-buttons/styles/material.css';
import '@syncfusion/ej2-splitbuttons/styles/material.css';
import '@syncfusion/ej2-inputs/styles/material.css';
import '@syncfusion/ej2-lists/styles/material.css';
import '@syncfusion/ej2-navigations/styles/material.css';
import '@syncfusion/ej2-popups/styles/material.css';
import '@syncfusion/ej2-richtexteditor/styles/material.css';

import '@syncfusion/ej2-react-pdfviewer/styles/material.css'
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-buttons/styles/material.css';
import '@syncfusion/ej2-dropdowns/styles/material.css'; 
import '@syncfusion/ej2-notifications/styles/material.css';
import '@syncfusion/ej2-drawings/styles/material.css';
import '@syncfusion/ej2-inplace-editor/styles/material.css';
import '@syncfusion/ej2-calendars/styles/material.css';
import './theme/pdfViewer.css';
import * as Enums from 'client/components/Common/Enum';
import { Box } from '@mui/material';
import {  getDeviceType } from 'client/components/Common/Utility';
import {PdfViewerComponent, Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView,   ThumbnailView, Print, TextSelection, TextSearch, Annotation, Inject} from '@syncfusion/ej2-react-pdfviewer';
 
class PDFViewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            weburl: "https://ej2services.syncfusion.com/production/web-services/api/pdfviewer", 
            localurl2: "/api/PDFViewer"
        };
    }
    componentDidMount() { 
    }
    componentDidUpdate(prevProps, prevState) { }

    render() {
        const deviceType = getDeviceType();
        
        return (<Box height="75%" pl={1} pr={deviceType === Enums.DeviceType.Desktop?7:1}>
            <div className='control-section'>
                <PdfViewerComponent id="container" downloadFileName={this.props.fileName} documentPath={this.props.data} serviceUrl={this.state.localurl2} style={{ 'height': `${this.props.height}px` }}>
                    <Inject services={[Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation]} />
                </PdfViewerComponent>
            </div> 
        </Box>
        );  
    }
}
export default PDFViewer;