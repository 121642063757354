import React from 'react';
import DatabaseFields from 'common/DatabaseFields';

class TabContentWrapper extends React.Component {

    constructor(props) {

        super(props);
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.roleId !== this.props.roleId) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        return (
            <DatabaseFields
                TableDataField={this.props.TableDataField}
                handleDrop={this.props.handleDrop}
                isFromMenu={this.props.isFromMenu} />
            );
    }
}

export default TabContentWrapper;