import { theme, customThemeStyles } from 'common/theme';
export const bannerTheme = () => ({
    ...customThemeStyles,

    alert: {
        background: "#fff!important",
        border: "1px solid #F5A623!important",
        padding: "14px 52px 14px 32px!important",
        position: "relative!important",
        borderRadius: "27px!important",
        position: "relative!important",
        overflow: "hidden!important",
        marginBottom: "20px!important",
        fontFamily: 'Roboto',

    },

    alertNotification: {
        background: "#fff!important",
        border: "1px solid #BCBFC2!important",
        padding: "14px 52px 14px 32px!important",
        position: "relative!important",
        borderRadius: "27px!important",
        position: "relative!important",
        overflow: "hidden!important",
        marginBottom: "20px!important",
        fontFamily: 'Roboto',

    },
    alertPromotion: {
        background: "#fff!important",
        border: "1px solid #7ed321!important",
        padding: "14px 52px 14px 32px!important",
        position: "relative!important",
        borderRadius: "27px!important",
        position: "relative!important",
        overflow: "hidden!important",
        marginBottom: "20px!important",
        fontFamily: 'Roboto',

    },



    alertHead1: {
        float: "left",
        //marginLeft: "20px",
        marginRight: "22px"
    },
    alertHead: {
        float: "left",
        marginLeft: "20px",
        marginRight: "22px"
    },

    alertClose: {
        fontSize: "33px!important",
        lineHeight: "52px",
        //width: " 52px",
        //height: " 52px",
        textAlign: " center",
        position: "absolute",
        top: "8px",
        right: "10px",
        color: "rgba(216, 216, 216, .8)",
        transition: "color .15s"
    },

    linkButton: {
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
        textDecoration: "underline",
        display: "inline",
        margin: 0,
        padding: 0,
        "&:hover": {
            textDecoration: "none!important"
        },
        "&:focus": {
            textDecoration: "none!important"
        }
    },

    alertBody: {
        margin: " 0 0 0 20px",
        fontFamily: 'Roboto'
    },

    alertIcon: {
        fontSize: "18px",
        lineHeight: "30px",
        width: "26px",
        height: "26px",
        borderRadius: "13px",
        textAlign: "center",
        color: "#F5A623",
        background: "#fff",
        top: "13px",
        left: "13px",
        position: "absolute"
    },



    alertLink: {
        marginLeft: "20px",
        color: "#F5A623",
        whiteSpace: "nowrap",
        textDecoration: "none",
        [theme.breakpoints.only('xs')]: {
            marginLeft: "3px",
        },
    },
    alertBodyExpanded: {
        paddingTop: " 18px"

    },
    alertNotificationIcon: {
        fontSize: "18px",
        lineHeight: "30px",
        width: "26px",
        height: "26px",
        borderRadius: "13px",
        textAlign: "center",
        color: "#BCBFC2",
        background: "#fff",
        top: "13px",
        left: "13px",
        position: "absolute"
    },

    alertNotificationLink: {
        marginLeft: "20px",
        color: "#10e08c",
        whiteSpace: "nowrap",
        textDecoration: "none",

        [theme.breakpoints.only('xs')]: {
            marginLeft: "0px",
        },
    },

    alertPromotionIcon: {
        fontSize: "18px",
        lineHeight: "30px",
        width: "26px",
        height: "26px",
        borderRadius: "13px",
        textAlign: "center",
        color: "#7ed321",
        background: "#fff",
        top: "13px",
        left: "13px",
        position: "absolute"
    },

    alertPromotionLink: {
        marginLeft: "20px",
        color: "#7ed321",
        whiteSpace: "nowrap",
        textDecoration: "none",
        [theme.breakpoints.only('xs')]: {
            marginLeft: "3px",
        },
    },
    titleH4: {

        fontWeight: 500
    },
    title: {


        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 400,
            letterSpacing: "0.25px",
            minWidth: "122px",

            whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"
        },

        [theme.breakpoints.up('sm')]: {
            fontSize: '26px!important',
            fontFamily: "Roboto!important",
            fontStyle: "normal!important",
            fontWeight: 400,
            letterSpacing: "0.25px!important",
            minWidth: "194px",

            whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"
        },


    },
    bannerContainer: {
        paddingLeft: "24px",
        paddingRight: "24px"
    },

    article: {
        padding: "36px"

    },

    articleBody: {
        overflow: "hidden",
        paddingTop: "0px"
    },
    homeIcon: {


        [theme.breakpoints.up('sm')]: {
            fontSize: '40px!important',
             
            color: customThemeStyles.customTheme.backgroundColor,

        },

        [theme.breakpoints.down('sm')]: {
            fontSize: '30px!important',
            
            color: customThemeStyles.customTheme.backgroundColor,

        }

    },
    header: {
        paddingTop: "12px",
        marginBottom: "-34px"
    },



});