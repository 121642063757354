import React from "react";
import NewsAndAnnouncement from "client/components/Feed/NewsAndAnnouncement";
import { connect } from 'react-redux';
import { Loading } from 'client/components/Loading';
import CellTowerOutlinedIcon from '@mui/icons-material/CellTowerOutlined';
import { homeTheme } from 'client/components/Feed/theme/homeTheme';
import { withStyles } from '@mui/styles';
import { Box, Typography, Grid } from '@mui/material';
const recordLimit = 5;
class newsAndAnnouncements extends React.Component {

    constructor(props) {
        super(props);
        const news = (this.props.siteTheme) ? this.props.siteTheme.newsList : this.props.news ;
        const newsLength = news.length;
        if (newsLength > recordLimit) {
            this.state = {
                HasMore: true,
                NewsCount: news.length,
                CurrentCount: recordLimit
            };
        } else {
            this.state = {
                HasMore: false,
                NewsCount: news.length,
                CurrentCount: recordLimit
            };
        }
    }

    componentDidUpdate(newProps) {
        if (newProps.isMore) {
            let NewsCount = newProps.news.length;
            if (this.state.CurrentCount < NewsCount) {
                if ((this.state.CurrentCount + recordLimit) >= NewsCount) {
                    this.setState((state, props) => ({
                        HasMore: false,
                        NewsCount: state.NewsCount,
                        CurrentCount: (state.CurrentCount + recordLimit)
                    }));
                } else {
                    this.setState((state, props) => ({
                        HasMore: true,
                        NewsCount: state.NewsCount,
                        CurrentCount: (state.CurrentCount + recordLimit)
                    }));
                }

            }
        }
    }

    shouldComponentUpdate(newProps, newState) {

        if (this.props.isLoading && this.state.CurrentCount > recordLimit) {
            this.resetCount();
        }
        if (newProps.news.length > recordLimit)
            return this.state.CurrentCount < newProps.news.length;
        else
            return true;
    }

    resetCount = () => {
        this.setState({
            HasMore: false,
            NewsCount: 0,
            CurrentCount: recordLimit
        });
    }


    renderContents = () => {
        const { classes } = this.props;
        const news = (this.props.siteTheme) ? this.props.siteTheme.newsList : this.props.news;
        return (
            <section className={`${classes.section} ${classes.sectionIndent}`}>
                <header className={classes.sectionHead}>
                    <Grid container>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={classes.towerIcon}>
                            <Typography className={classes.h3}><CellTowerOutlinedIcon className={classes.towerIcon} /> <strong className={classes.title}>News &amp; Announcements</strong></Typography>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={classes.feedContainer} >
                            <aside className={classes.sectionHeadAside}>
                                <Typography className={classes.feedUpdateText}>Updated<strong className={classes.feedUpdateDate}>
                                    {news[0].modifiedDate} </strong>
                                </Typography>
                            </aside>
                        </Grid>
                    </Grid>
            </header>
                <Box id="idPosted" className={classes.sectionBody}>
                    {this.renderContent(news)}
                </Box>
        </section>
    );
}


    renderContent = (contents) => {
        let newsCount = 0;
        const { isSiteTheme, themeColor  } = (this.props.siteTheme) ? this.props.siteTheme : '';
        let curCount = this.state.CurrentCount;
        const contentList = contents.map(function contentItem(content) {
            newsCount = newsCount + 1;
            if (newsCount <= curCount) {

                return (
                    <NewsAndAnnouncement key={content.id} content={content} isSiteTheme={isSiteTheme} themeColor={themeColor}/>
                );

            }
            else
            {

                return (<Box key={`div${newsCount}` } />);
            }

        });

        return contentList;
    }

    render()
    {
        const { isSiteTheme, newsList } = (this.props.siteTheme) ? this.props.siteTheme : '';
        const news = (isSiteTheme) ? newsList : this.props.news;
        return (
       
            <Box style={this.props.isLoading ? { paddingTop: "20%" } : {}}>
                {!this.props.siteTheme && <Loading loading={this.props.isLoading} />}
                {news.length > 0 && this.renderContents()}
            </Box>
        );
    }
}

export default connect(state => state.news)(withStyles(homeTheme)(newsAndAnnouncements));


newsAndAnnouncements.defaultProps = {
    news: [],
};