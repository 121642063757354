import { ActionTypes as types } from './actionTypes';
import cloneDeep from 'lodash/cloneDeep';
const controlName = { IsProcessPayments: 'isProcessPayments', HasBzwEnable: 'hasBzwEnable', BzwWorkFlowId: 'bzwWorkFlowId', HasConfirm: 'hasConfirm', EnablePayment:'enablePayment' }
/**
 * action creators
 */
const updateRecordSubmissionRequest = (recordProcessingData, data) => {
    return {
        type: types.VGO_UPDATE_RECORD_PROCESSING_REQUEST,
        recordProcessingData: recordProcessingData,
        data: data,
       };
};

const updateReportRequest = (recordProcessingData, data) => {
    return {
        type: types.VGO_UPDATE_RECORD_SETUP_REQUEST,
        recordProcessingData: recordProcessingData,
        data: data,
    };
};

/**
 * Error action creator
 * @param {*} errorMessage 
 */
const getError = (errorMessage) => {
    return {
        type: types.VGO_API_CALL_ERROR,
        hasError: true,
        errorMessage
    };
};




/**
 * Get all fieldDisplay success action creator
 * @param {Response} resp 
 */


/**
 * Dispatch methods
 */
export const updateRecordSubmission = (recordProcessingData, data) => {
    return dispatch => {
        dispatch(updateRecordSubmissionRequest(recordProcessingData, data));

    };
};

export const getChangeRecordSubmission = (change) => {
    return {
        type: types.VGO_CHANGE_RECORD_SUBMISSION,
        change,
    };
}


export const updateReportSetup = (setupData, data) => {
    return dispatch => {
        dispatch(updateReportRequest(setupData, data));
        
    };
};


export const updateRecordSubmissionValue = (action) => {
    const recordData = Object.assign({}, action.recordProcessingData)
    if (action.data) {
        if (action.data.name === controlName.IsProcessPayments) {
            recordData[action.data.name] = action.data.value;
             if (action.data.value) {
                recordData.enablePayment = action.data.value;
                recordData.hasAggregate = action.data.value;
                recordData.hasConfirm = action.data.value;
            }
           // recordData.hasBzwEnable  = action.data.value;
            if (!action.data.value) {
                recordData.editableFieldIdError = false;
                recordData.editableFieldIdHelper = ' ';
                recordData.submitButtonError = false;
                recordData.submitButtonHelper = ' ';
                recordData.submitButtonError = false;
                recordData.submitButtonHelper = ' ';
                recordData.resetButtonError = false;
                recordData.resetButtonHelper = ' ';
                recordData.confirmScreenTitleError = false;
                recordData.confirmScreenTitleHelper = ' ';
                recordData.confirmInstructionError = false;
                recordData.confirmInstructionHelper = ' ';
                recordData.displayFieldError = false;
                recordData.displayFieldHelper = ' ';
                recordData.confirmSubmitLabelError = false;
                recordData.confirmSubmitLabelHelper = ' ';
                recordData.confirmResetLabelError = false;
                recordData.confirmResetLabelHelper = ' ';
                recordData.databaseError = false;
                recordData.databaseHelper = ' ';
                recordData.tableNameError = false;
                recordData.tableNameHelper = ' ';
                recordData.bzwWorkFlowIdError = false;
                recordData.bzwWorkFlowIdHelper = ' ';
                recordData.bzwServiceURLError = false;
                recordData.bzwServiceURLHelper = ' ';
                recordData.bzwBatchIdParameterNameError = false;
                recordData.bzwBatchIdParameterNameHelper = ' ';
                recordData.displayFieldError = false;
                recordData.displayFieldHelper = ' ';
                recordData.displayField1Error = false;
                recordData.displayField1Helper = ' ';
                recordData.displayField2Error = false;
                recordData.displayField2Helper = ' ';
               

            }
        }
        else if (action.data.name === controlName.HasBzwEnable) {
            recordData[action.data.name] = action.data.value;
            recordData.bzwWorkFlowIdError = false;
            recordData.bzwWorkFlowIdHelper = ' ';
            recordData.bzwServiceURLError = false;
            recordData.bzwServiceURLHelper = ' ';
            recordData.bzwBatchIdParameterNameError = false;
            recordData.bzwBatchIdParameterNameHelper = ' ';
        }
        else if (action.data.name === controlName.HasConfirm) {
            recordData[action.data.name] = action.data.value;
            if (!action.data.value) {
                recordData.confirmResetLabelError = false;
                recordData.confirmResetLabelHelper = ' ';
                recordData.confirmSubmitLabelError = false;
                recordData.confirmSubmitLabelHelper = ' ';
                recordData.confirmScreenTitleError = false;
                recordData.confirmScreenTitleHelper = ' ';
                recordData.confirmInstructionError = false;
                recordData.confirmInstructionHelper = ' ';
                recordData.displayFieldError = false;
                recordData.displayFieldHelper = ' ';
            }
        }
        else if (action.data.name === controlName.EnablePayment) {
            recordData[action.data.name] = action.data.value;
            recordData.editableFieldIdError = false;
            recordData.editableFieldIdHelper = ' ';
        }
          else {
            recordData[action.data.name] = action.data.value;
        }
    }
    
    return recordData
};

export const updateSetupValue = (action) => {
    const recordData = Object.assign({}, action.recordProcessingData)
    if (action.data) {
       
            recordData[action.data.name] = action.data.value;
        
    }

    return recordData
};

export const setRecordProcessingData = (fieldsData, data) => {
    const recordSubmissionData=cloneDeep(data)

    if (recordSubmissionData) {
        if (recordSubmissionData.displayFieldId) {

            if (recordSubmissionData.displayFieldId.indexOf(",") > -1) {
                const array = recordSubmissionData.displayFieldId.split(",")
                for (var i = 0; i < array.length; i++) {
                   
                    var index = fieldsData.findIndex(d => d.fieldName === array[i]);
                    if (index === -1) {
                        array[index] = 'Select';
                    }
                }

                recordSubmissionData.displayFieldId = array.join()
            }
            else {
                if (recordSubmissionData.displayFieldId !== 'Select') {

                    index = fieldsData.findIndex(d => d.fieldName === recordSubmissionData.displayFieldId);
                    if (index === -1) {
                        recordSubmissionData.displayFieldId = 'Select';
                    }
                }


            }

        }

        if (recordSubmissionData.editableFieldId) {
            if (recordSubmissionData.editableFieldId !== 'Select') {
                index = fieldsData.findIndex(d => d.fieldName === recordSubmissionData.editableFieldId)
                if (index === -1) {
                    recordSubmissionData.editableFieldId = 'Select';
                }
            }

        }

    }
    
    return recordSubmissionData;

};
