import { customThemeStyles } from 'common/theme.js';
export const chartTheme = (theme) => ({
    ...customThemeStyles,
    card: {
        marginLeft: theme.spacing(-3),
        marginRight: theme.spacing(-3),
        marginTop: theme.spacing(-3.1),
        paddingRight: theme.spacing(3),
    },
    bottomCard: {
        marginLeft: theme.spacing(-3),
        marginRight: theme.spacing(-3),
        marginTop: theme.spacing(.6),
    },
    select: {
        marginTop: '-3px!important',
        paddingTop: '2px!important',
    },
    xAxis: {
        paddingBottom: '9px!important'
    },
    label: {
        display: "inline-block !important",
        marginLeft: "-4px !important",
    },

    yAxis: {
        paddingTop: '25px!important',
        paddingBottom: '10.5px!important'
    },
     displayAxis: {
        marginTop: '15px!important',
    },
    colorStyle: {
        width: '24px!important',
        height: '24px!important',
        flexDirection: 'row!important',
        marginBottom: '20px!important'
    },
    stacked: {
        marginBottom: '15px!important'
    },
    name: {
        width: '220px!important',
    },
    title: {
        width: '220px!important',
    },
    chartType: {
        width: '200px!important',
    },
    groupField: {
        width: '260px!important',
    },
    recordCount: {
        width: '40px!important',
    },
    yChartType: {
        width: '178px!important',
    },
    yText: {
        width: '183px!important',
    },
    yColumn: {
        width: '196px!important',
    },
    legend: {
        width: '178px!important',
    },
    dataLabel: {
        width: '206px!important',
    },
    toolTip: {
        width: '204px!important',
    },
    deleteStyle: {
        marginBottom: '15px!important'
    },
    radius: {
        width: '77px!important',
    },
    selectedRow: {
        backgroundColor: "rgba(0, 0, 0, 0.10)!important"
    },
  
});