import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { styled } from '@mui/styles';
import { withStyles } from '@mui/styles';
import { spacing } from '@mui/system';
import { InputAdornment} from '@mui/material';
import MUIDataTable from 'mui-datatables';
import MuiTextField from '@mui/material/TextField';
import { DragDropContainer } from 'react-drag-drop-container';
import { ThemeProvider } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { ObjectType } from 'client/components/Common/Enum';
import { databaseFieldsTheme, getMuiTheme } from 'common/theme/databaseFieldsTheme';
import Grid from '@mui/material/Grid';
import { sectionType } from 'admin/actions/actionTypes';
import { getContentDragData } from 'admin/actions/menuActions';
import ListTemplate from 'admin/components/section/menu/ListTemplate';
import { MuiGridOptions } from 'admin/components/Common/CommonOptions';

const TextField = styled(MuiTextField)(spacing);

class DatabaseFields extends React.Component {

    dashboardItemTemplate = (value, tableMeta, updateValue) => {
        const type = this.props.TableDataField[tableMeta.rowIndex].type;
        const dashboardItemType = {chart:"Chart",list:"List",schedule:"Schedule"}
        
        return (<Grid className='pointerIcon' container onClick={() => { this.props.addPanel(this.props.TableDataField[tableMeta.rowIndex]) }}  >
            <Grid item xs={ 8} >
                <span className={this.props.classes.spanActive} >{this.props.TableDataField[tableMeta.rowIndex].caption}</span>
            </Grid>
            <Grid item xs={4} sx={{textAlign:"center"}} >
                <span className={this.props.classes.spanActive} >{dashboardItemType[type]}</span>
            </Grid>
        </Grid>
        );
    }
    dashboardItemTypeTemplate = (value, tableMeta, updateValue) => {
        
        return (
            <span style={{ display: "none" }} className={this.props.classes.spanActive} >{value}</span>
        );
    }

    getFormControl = (data, fieldData, value, active) => {

        if (active) {
            return (<Grid className='pointerIcon' container onClick={() => { this.props.addControl(data, this.props.controlTarget) }} ><Grid item ><span className={active ? this.props.classes.spanActive : this.props.classes.spanInActive} >{value}</span></Grid><Grid item >
                <span className={active ? this.props.classes.spanActive : this.props.classes.spanInActive} >{"( " + fieldData.sqlDataType + " )"}</span>
            </Grid><Grid item><span className={this.props.classes.spanPrimary}>{data.isPrimary ? data.sectionType === sectionType.Header ? "P" : "L" : ""}</span> </Grid></Grid>
            );
        } else {
            return (<Grid container><Grid item><span className={active ? this.props.classes.spanActive : this.props.classes.spanInActive} >{value}</span></Grid><Grid item>
                <span className={active ? this.props.classes.spanActive : this.props.classes.spanInActive} >{"( " + fieldData.sqlDataType + " )"}</span>
            </Grid><Grid item><span className={this.props.classes.spanPrimary}>{data.isPrimary ? data.sectionType === sectionType.Header?"P":"L" : ""}</span> </Grid></Grid>
            );
        }
    }

    formControlTemplate = (value, tableMeta, updateValue) => {

        const fieldData = this.props.TableDataField[tableMeta.rowIndex];
        
        let data = {
            sectionType: this.props.sectionType, dataType: this.props.TableDataField[tableMeta.rowIndex].dataType,
            control: this.props.TableDataField[tableMeta.rowIndex].controlType, fieldName: this.props.TableDataField[tableMeta.rowIndex].fieldName,
            isPrimary: this.props.TableDataField[tableMeta.rowIndex].isPrimaryKeyField,
            length: this.props.TableDataField[tableMeta.rowIndex].size,
            formatType: this.props.TableDataField[tableMeta.rowIndex].formatType
        }

        if (this.props.formSection) {
            let index = this.props.formSection.findIndex(f => f.fieldName === fieldData.fieldName)
            if (index !== -1) {
                if (this.props.formSection[index].controlUiId) {
                    if (this.props.objectType === ObjectType.Form && !this.props.isFromMenu) {
                        return (this.getFormControl(data, fieldData, value,false));
                       

                    } else {
                        return (<div><span style={{ fontSize: "12px" }}>{value}</span><span className={this.props.classes.spanField} >{data.dataType}</span></div>)
                    }
                } else {


                    if (this.props.objectType === ObjectType.Form && !this.props.isFromMenu) {

                        return (this.getFormControl(data, fieldData, value,true));
                       

                    } else {

                        return (<DragDropContainer dragData={data} onDrop={this.props.handleDrop}
                            targetKey="formContainer">
                            <div><span style={{ fontSize: "12px" }}>{value}</span><span className={this.props.classes.spanField}>{data.dataType}</span></div>
                        </DragDropContainer>)
                    }


                }
            }

            else {
                if (this.props.objectType === ObjectType.Form && !this.props.isFromMenu) {
                    return (this.getFormControl(data, fieldData, value, true));
                   

                } else {
                    return (
                        <DragDropContainer dragData={data} onDrop={this.props.handleDrop}
                            targetKey="formContainer">
                            <div><span style={{ fontSize: "12px" }}>{value}</span><span style={{ paddingLeft: "10px", fontSize: "12px" }}>{data.dataType}</span></div>
                        </DragDropContainer>
                    );
                }

            }
        }

        else {
            return (
                <DragDropContainer dragData={data} onDrop={this.props.handleDrop}
                    targetKey="formContainer">
                    <div><span style={{ fontSize: "12px" }}>{value}</span><span style={{ paddingLeft: "10px", fontSize: "12px" }}>{data.dataType}</span></div>
                </DragDropContainer>

            );
        }
    }

    objectTemplate = (value, tableMeta, updateValue) => {
        return (
            <ListTemplate rowData={tableMeta.rowData} rowIndex={tableMeta.rowIndex} handleDrop={this.props.handleDrop}/>
        );
    }

    getSelectedValue = () => {
        let array = [];

        array = this.props.TableDataField.map((item, index) => {

            if (this.props.formSection) {
                let rowIndex = this.props.formSection.findIndex(f => f.fieldName === item.fieldName)
                if (rowIndex !== -1) {
                    if (this.props.formSection[rowIndex].controlUiId) {
                        return index;
                    }
                }
            }
        })

        return array;


    }

    getColumns = () => {
        let columns = [];
        if (this.props.isFromMenu) {
                columns = [
                    {
                        name: "id",
                        label: "",
                        options: {
                            setCellProps: (cellValue, rowIndex, columnIndex) => {
                                return {
                                    style: {
                                        background: "white"
                                    },
                                };
                            },
                            customBodyRender: () => {
                                return (null);
                            },
                        },
                    },
                    {
                        name: "name",
                        label: "",
                        options: {
                            setCellProps: (cellValue, rowIndex, columnIndex) => {
                                return {
                                    style: {
                                        padding: 0,
                                        background:"white"
                                    },
                                };
                            },
                            customBodyRender: this.objectTemplate,
                        },
                    },
                    {
                        name: "type",
                        label: "",
                        options: {
                            setCellProps: (cellValue, rowIndex, columnIndex) => {
                                return {
                                    style: {
                                        background: "white"
                                    },
                                };
                            },
                            customBodyRender: () => {
                                return (null);
                            },
                        },
                    }, 
                    {
                        name: "quickActionChecked",
                        label: "",
                        options: {
                            setCellProps: (cellValue, rowIndex, columnIndex) => {
                                return {
                                    style: {
                                        background: "white"
                                    },
                                };
                            },
                            customBodyRender: () => {
                                return (null);
                            },
                        },
                    }, 
                    {
                        name: "mainMenuChecked",
                        label: "",
                        options: {
                            setCellProps: (cellValue, rowIndex, columnIndex) => {
                                return {
                                    style: {
                                        background: "white"
                                    },
                                };
                            },
                            customBodyRender: () => {
                                return (null);
                            },
                        },
                    },
                    {
                        name: "contentType",
                        label: "",
                        options: {
                            setCellProps: (cellValue, rowIndex, columnIndex) => {
                                return {
                                    style: {
                                        background: "white"
                                    },
                                };
                            },
                            customBodyRender: () => {
                                return (null);
                            },
                        },
                    },
                ];
        } else {

            if (this.props.objectType === ObjectType.Form) {
                columns = [
                    {
                        name: "fieldName",
                        label: "",
                        options: {
                            customBodyRender: this.formControlTemplate,
                        },
                    },
                ];
            }
            else if (this.props.objectType === ObjectType.Dashboard) {
                columns = [
                    {
                        name: "caption",
                        label: "",
                        options: {
                            customBodyRender: this.dashboardItemTemplate,
                        },
                    },
                    {
                        name: "type",
                        label: "",
                        options: {
                            customBodyRender: this.dashboardItemTypeTemplate,
                            
                        }
                    },
                ];
            }
            else {
                columns = [
                    {
                        name: "checked",
                        label: "",
                        options: {
                            customBodyRender: this.checkBoxTemplate,
                        },
                    },
                    {
                        name: "fieldName",
                        label: "",
                        options: {
                            customBodyRender: this.formControlTemplate,
                        },
                    },
                ];
            }
        }
        return columns;
    }
    render() {
        const { isFromMenu,isFromDashBoard, classes, TableDataField } = this.props;
        let columnOptions = {
            sort: false,
            search: true,
            viewColumns: false,
            selectableRows: "none",
            responsive: "stacked",
            rowHove: false,
            selectableRowsHeader: false,
            download: false,
            elevation: 0,
            filter: false,
            print: false,
            searchPlaceholder: 'Search',
            searchText: "",
            pagination: false,
            searchAlwaysOpen: true,
            toolbar: {},
            tableBodyHeight: isFromMenu ?"544px":isFromDashBoard?"460px":"400px",
            customToolbarSelect: () => { },

            customSearchRender: (searchText, handleSearch, hideSearch, options) => {


                return (
                    <TextField className={(isFromMenu) ? classes.searchTextMenu : classes.searchText} inputRef={(input) => {
                        if (input != null) {
                            input.focus();
                        }
                    }}
                        label="Search"
                        size="large"
                        fullWidth={true}
                        variant="standard"
                        value={(searchText) ? searchText : ""}
                        onChange={(event) => handleSearch(event.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            )
                        }}
                    />);
            },
            rowStyle: {
                fontSize: 12,
            },
            onSearchChange: (searchText) => {
                if (this.props.isFromMenu) {
                    this.props.dispatch(getContentDragData({ item: { id: "", contentType: "" } }));
                }
            },
        }
        if (this.props.objectType === ObjectType.Dashboard) {
            columnOptions = MuiGridOptions(columnOptions, this.props.isDataLoading)
        }
        return (

            <ThemeProvider theme={getMuiTheme}>
                 
                    <MUIDataTable container title={''}  
                        data={TableDataField}
                        columns={this.getColumns()}
                        options={columnOptions}
                        /> 
            </ThemeProvider>

        );
    }
}


function mapStateToProps(state) {
    return {
        isLoading: state.loader.isLoading,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage,
    };
};

export default withRouter(connect(mapStateToProps)(withStyles(databaseFieldsTheme)(DatabaseFields)));


