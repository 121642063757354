import * as yup from "yup";
import { CalendarEventFieldSettings } from 'client/components/Common/Enum';
export const dataViewCalendarSchema = yup.object().shape({
    eventFieldSettings: yup.string(),
    startDate: yup.object()
        .test('isStartDateEmpty',
            'Start date is required',
            function (value) {
                return !value.fieldName ? false : true;
            }),
    titleFields: yup.array()
        .test('isTitleFieldSelected',
            'Field is required',
            function (value) {
                return value.some(titleField => !(!titleField.fieldName))
            }),
    
    endDate: yup.object()
        .when(["eventFieldSettings"],
            (eventFieldSettings) => {
                if (eventFieldSettings === CalendarEventFieldSettings.HasStartDateTimeAndEndDateTime || eventFieldSettings === CalendarEventFieldSettings.HasStartDateAndEndDate )
                    return yup.object().test('isEndDateEmpty',
                        'End date is required',
                        function (value) {
                            return !value.fieldName ? false : true;
                        })
            })
    ,
    
    


})
               