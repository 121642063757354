import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles'
import AddIcon from '@mui/icons-material/Add';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { mainTheme } from 'common/theme';
const buttonTheme = createTheme({
	...mainTheme,
	overrides: {
		MuiButton: {
			containedPrimary: {
				backgroundColor: mainTheme.palette.primary.light,
			},
		},
	},
});

class FormListHeader extends React.Component {

    onClickNewForm = () => {
		this.props.history.push( `/Admin/Forms/new` );
	}


    render() {

		

        return (
            <Grid container direction="row" justifyContent="space-between" spacing={0} xl={12}>
                <Grid item>
                    <Typography variant="h4">Forms</Typography>
                </Grid>
                <Grid item>
                    <ThemeProvider theme={buttonTheme}>
                        <Button
                            variant="contained"
                            color="primary"
                            disableElevation
                            startIcon={<AddIcon />}
                            onClick={(e) => this.onClickNewForm()}
                        >ADD FORM</Button>
                    </ThemeProvider>
                </Grid>
            </Grid> 
        );
    }

}

/**
 * mapStateToProps
 * @param {*} state 
 */
function mapStateToProps( state ) {
    return {
		alert: state.alert,
	};
};

export default withRouter(connect(mapStateToProps)(FormListHeader));
