import React from 'react';
import { makeStyles } from '@mui/styles';
import Alert from '@mui/lab/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { SettingStyle } from 'admin/components/section/settings/theme/SettingsStyle'
const useStyles = makeStyles((theme) => (SettingStyle(theme)));

export default function TransitionAlerts(props) {
    
    const [open, setOpen] = React.useState(true);
    const style = useStyles();
    return (

        <div className={style.alertDivRoot} >
            <Collapse in={open} className={style.alertCollapse}>
                <Alert
                    className={style.alertRoot}
                        severity={props.severity}
                        iconMapping={{ success: <CheckCircleIcon fontSize="inherit" /> }}
                    action={props.severity === "error" ? <IconButton aria-label="close" color="inherit" size="small" onClick={props.onHide}><CloseIcon style={{color:"black"}} fontSize="inherit" /></IconButton>:""}
                    >
                    {props.message}
                </Alert>
            </Collapse>
          
        </div>
    );
}