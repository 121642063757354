import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';

import ListItemIcon from '@mui/material/ListItemIcon';
import FolderIcon from '@mui/icons-material/Folder';
import ListItemText from '@mui/material/ListItemText';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import { Typography, Box, Breadcrumbs, Link, InputLabel } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { customTheme } from 'common/theme.js';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Collapse } from '@mui/material';
import { passwordHelperCustom } from 'client/components/ResetPasswords/theme/PasswordHelpTheme';
import { makeStyles } from '@mui/styles';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { withStyles } from '@mui/styles';
 
class passwordHelp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            manualClicked: false,
            expandMode: this.props.showHelper,

        }
    }

    componentDidUpdate(nextProps) {
        if (this.props !== nextProps) {
            if (this.props.primaryFocus) {
                this.setState({ expandMode: this.props.showHelper });
            }
        }
    }
    
    renderPasswordHelp = (passwordHelper) => {
        const { classes } = this.props;
        return (
            <Grid item xs={12} md={12}>
                <Box pl={3}> 
                    <List dense={true} disableGutters={true}>
                        {passwordHelper.map((helper, index) => (
                            <ListItem disableGutters={true}>
                                <ListItemIcon>
                                    {helper.Status ? <DoneIcon style={{ color: "green" }} /> : <ClearIcon style={{color:"red"}}/>}
                                </ListItemIcon>
                                <ListItemText variant="caption" disableTypography  classes={{ root: classes.helperTextFont }}
                                    primary={helper.Message}  
                                />
                            </ListItem>
                            ))} 
                    </List>
                 </Box>
            </Grid>
     )
    }
    helperOpen = () => { 
        this.setState({ expandMode: !this.state.expandMode, manualClicked:true}); 
    }
    expandMode = () => {
       return this.state.expandMode
    }
    render() {
        const { classes } = this.props;
        return (
            <ThemeProvider theme={customTheme}> 
                <Box xs={12} md={12}>
                    <Grid> 
                        <Grid container direction="row" onClick={e => { this.helperOpen() }} > 
                            <PlayArrowIcon className={this.expandMode() ? classes.helperOpen : classes.helperClosed }  ></PlayArrowIcon>
                           
                            <Typography classes={{ root: classes.helperText }} variant="body1" > Password help </Typography> 

                            
                     </Grid>
                        
                    </Grid>
                    <Collapse in={this.expandMode()} timeout="auto" unmountOnExit>
                        <Box>{this.renderPasswordHelp(this.props.passwordHelper)}</Box>
                    </Collapse>
                </Box>
</ThemeProvider>
            ) 
    }
}     
export default withStyles(passwordHelperCustom)(passwordHelp);