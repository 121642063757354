import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, AppBar, Tabs, Tab, Box } from '@mui/material';
//import SubRecords1 from './tabcomponents/SubRecords';
import SubRecords from './subRecords/SubRecords';
import Links from './links/Links';
import Calendar from './calendar/Calendar'; 
import { getDataView, getNewDataView, executeStoredProcedure, changeDataViewTab, getDataViewQueryFieldList, getChangeDataView } from 'admin/actions/dataViewActions';
//import DataViewSetup from 'admin/components/section/dataViews/setup/hookSetup';
import DataViewSetup from 'admin/components/section/dataViews/setup/setup';
import DataFilters from './dataFilters/DataFilterList';
import FieldDisplay from './fieldDisplay/FieldDisplay';
import Chart from './chart/Chart';
import { showError } from 'admin/actions/alertActions';
import RecordSubmission from './recordSubmission/RecordSubmission';
import { setupTheme } from 'admin/components/section/dataViews/setup/theme/setupTheme';
import { ReportDataSourceType } from 'client/components/Common/Enum';
import { reportFormat} from 'client/components/Common/Enum';
const errorQueryMessage = 'No valid fields found, please check data source'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}


class DataViewEdit extends React.Component {

    constructor(props) {
        super(props);
        
        let reportId = this.props.match.params.reportId;
        let id = null;
        let isUpdate = reportId !== "new";
        if (isUpdate) {
            id = parseInt(reportId);
            if (isNaN(id)) {
                // TODO: error on page?
                id = null;
            }
        }
        this.state = {
            reportId: isUpdate ? id : null,
            isUpdate: isUpdate,
            tabValue: 0,
        };
    }

    handleChange = (event, newValue) => {
        //if (this.props.dataView.dataSourceType === ReportDataSourceType.StoredProcedure &&
        //    this.props.storedProcedureParameters.length > 0 &&
        //    this.props.dataView.fieldDisplay.length === 0 &&
        //    newValue > 1) {
        //    this.dispatchStoredProcedureCall(newValue);
        //}
        

        if (newValue > 0 && this.props.dataView.query && (this.props.dataView.dataSourceType !== ReportDataSourceType.CrystalReport)) {
            if (!this.props.dataView.executeQuery) {
                this.props.dispatch(getChangeDataView(dataView => dataView.executeQuery = true));
                let dataViewDto = {
                    company: this.props.dataView.company,
                    reportId: this.props.dataView.reportId,
                    dataSourceType: this.props.dataView.dataSourceType,
                    query: this.props.dataView.query
                }
                if (dataViewDto.query) {
                    this.props.dispatch(getDataViewQueryFieldList(dataViewDto, newValue, true, this.props.dataView.fieldDisplay, this.props.dataView.reportFilter, this.props.dataView.recordProcessing,this.props.dataView.reportSubReport));
                } else {
                    showError(this.props.dispatch, "Invalid query ", 5000)
                }
            } else {
                if (this.props.dataView.fieldDisplay.length === 0) {
                    showError(this.props.dispatch, errorQueryMessage, 5000)
                } else {
                    this.props.dispatch(changeDataViewTab(newValue));
                }
            }
        } else if (this.props.dataView.fieldDisplay.length === 0 && this.props.dataView.dataSourceType !== ReportDataSourceType.CrystalReport) {
            showError(this.props.dispatch, errorQueryMessage, 5000)
        }
        else {
            if (this.props.dataView.dataSourceType === ReportDataSourceType.CrystalReport && newValue > 1) {

            }
            else if (this.props.dataView.dataSourceType === ReportDataSourceType.CrystalReport && newValue === 1 && !this.props.dataView.reportDataSource ) {
                showError(this.props.dispatch, errorQueryMessage, 5000)
            }
            else {
                this.props.dispatch(changeDataViewTab(newValue));
            }
            
        }



    };

    componentDidMount() {
        let reportId = this.props.match.params.reportId;
        let isUpdate = reportId !== "new";
        reportId = isUpdate ? reportId : null;
        this.dispatchGetDataView(reportId);


    }

   dispatchGetDataView = (reportId) => {
        let id = parseInt(reportId);
        if (isNaN(id)) {
            // TODO: error on page?
            id = null;
        }
        if (id) {
            this.props.dispatch(getDataView(id));
        }
        else {
            this.props.dispatch(getNewDataView());
        }
    }


    componentDidUpdate(prevProps) {
        // go back to list if save success
        //if (this.props.isUpdateSuccess) {
        //    this.props.history.push('/Admin/DataViews');
        //}

        if (this.props.isUpdateSuccess !== prevProps.isUpdateSuccess) {
            if (this.props.isUpdateSuccess && this.props.dataView.reportId > 0) {
                this.props.history.push(`/Admin/DataViews/${this.props.dataView.reportId}`);
                
            }
        }



    }




  





    dispatchStoredProcedureCall = (newTabIndex) => {


        let dataViewDTO = cloneDeep(this.props.dataView);
        let parameterList = dataViewDTO.reportFilter.map((parameter) => {
            if (parameter.parameterType != 'userProfileFilter') {
                const inputParameter = this.props.storedProcedureParameters.filter(filter => filter.fieldName === parameter.adminParameterName);
                parameter.parameterDataType = inputParameter[0].filterDataType;
                return parameter;
            }
            else {
                return null
            }
        }).filter(Boolean);;
        dataViewDTO.reportFilter = parameterList;
       
    }

    render() {
        return (
            <Grid container direction="row" justifyContent="space-between">
                <Grid item xs={12}>
                    <div>
                        <AppBar position="static" className={this.props.classes.tabRoot}  >
                            <Tabs value={this.props.activeTabIndex} onChange={this.handleChange} aria-label="simple tabs example" indicatorColor="primary" textColor="primary" scrollButtons={false}>
                                <Tab label="SETUP" {...a11yProps(0)} className={this.props.classes.body2} classes={{ root: this.props.classes.setupTab }} />
                                <Tab disabled={this.props.dataView.isClone ? true : false} label="FILTERS" {...a11yProps(1)} className={this.props.classes.body2} classes={{ root: this.props.classes.filterTab }} />
                                <Tab disabled={this.props.dataView.isClone ? true : false} label="FIELD DISPLAY" {...a11yProps(23)} classes={{ root: this.props.classes.fieldDisplayTab}} className={this.props.classes.body2} />
                                <Tab label="SCHEDULE" {...a11yProps()} className={this.props.classes.body2} classes={{ root: this.props.classes.calenderTab }} disabled={this.props.dataView.isClone || this.props.dataView.reportFormat !== reportFormat.Calendar ? true : false  } />
                                <Tab disabled={this.props.dataView.isClone ? true : false} label="SUB-RECORDS" {...a11yProps(4)} className={this.props.classes.body2} classes={{ root: this.props.classes.subRecordTab }} disabled={this.props.dataView.isClone||this.props.dataView.reportFormat === reportFormat.Calendar?true:false} />
                                
                                <Tab disabled={this.props.dataView.isClone ? true : false} label="LINKS" {...a11yProps(5)} className={this.props.classes.body2} classes={{ root: this.props.classes.linksTab }} />
                                <Tab label="RECORD/PAYMENT SUBMISSION" {...a11yProps(6)} className={this.props.classes.body2} classes={{ root: this.props.classes.recordSubmissionTab }} disabled={this.props.dataView.isClone || this.props.dataView.reportFormat === reportFormat.Calendar ? true : false} />
                                <Tab label="CHARTS" {...a11yProps(7)} className={this.props.classes.body2} classes={{ root: this.props.classes.chartTab }} disabled={this.props.dataView.isClone || this.props.dataView.reportFormat === reportFormat.Calendar ? true : false} />
                            </Tabs>
                        </AppBar>
                        <Box mt={1}>
                        <TabPanel value={this.props.activeTabIndex} index={0}>
                            <DataViewSetup />
                        </TabPanel>
                        <TabPanel value={this.props.activeTabIndex} index={1}>
                            <DataFilters />
                        </TabPanel>
                        <TabPanel value={this.props.activeTabIndex} index={2}>
                            <FieldDisplay />
                        </TabPanel>
                        <TabPanel value={this.props.activeTabIndex} index={3}>
                            <Calendar></Calendar>
                        </TabPanel>
                        <TabPanel value={this.props.activeTabIndex} index={4}>
                            <SubRecords></SubRecords>
                            </TabPanel>
                        
                        <TabPanel value={this.props.activeTabIndex} index={5}>
                            <Links />
                        </TabPanel>
                       
                        <TabPanel value={this.props.activeTabIndex} index={6}>
                            <RecordSubmission />
                        </TabPanel>
                        <TabPanel value={this.props.activeTabIndex} index={7}>
                            <Chart />
                        </TabPanel>
                        </Box>
                    </div>
                </Grid>
                
            </Grid>
        );
    }

}

/**
 * 
 * @param {State} state 
 */
function mapStateToProps(state) {
    let dataViewState = state.dataViews;
    let props = {
        dataViews: dataViewState.dataViews,
        dataView: dataViewState.dataView,
        storedProcedureParameters: dataViewState.storedProcedureParameters,
        activeTabIndex: dataViewState.activeTabIndex,
        isLoading: state.loader.isLoading,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage,
        isUpdateSuccess: dataViewState.isUpdate,
    };
    
    return props;
};

export default withRouter(connect(mapStateToProps)(withStyles(setupTheme)(DataViewEdit)));

/**
 * Default properties for the DataViewEdit component
 */
DataViewEdit.defaultProps = {
    textFieldVariant: 'standard',
};

/**
 * Property types for the DataViewEdit properties
 */
DataViewEdit.propTypes = {
    textFieldVariant: PropTypes.oneOf([
        'filled',
        'outlined',
        'standard',
    ]),
};


