import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';

import { Grid, FormControlLabel, Box, Button, Checkbox, Typography } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUpload from 'admin/components/Common/FileUpload'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import { packageTheme } from './theme/packageTheme';


import { importPackageGetFile, getChangeImportPackage, getConnectionChangeImportPackage, importPackageReset, processValidateRequest, importPackageClearPasswordError, getPackageLogFile} from 'admin/actions/packageActions';
import { getDatabases } from 'admin/actions/databaseActions';


import ActionBackdrop from 'admin/components/section/settings/Backdrop';
import { ExportObjectType } from 'client/components/Common/Enum';

import ImportList from './ImportList'
import ImportPassword from './ImportPassword'




class ImportContent extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            panel: true,
            listPanel:true,
        };
    }
    componentDidMount() {
        if (this.props.databases.length === 0) {
            this.dispatchGetDatabases();
        }
    }

    handleFileUploadChange = (value,fileName) => {
        console.log('vgofile')
        this.props.dispatch(importPackageGetFile(value,fileName));
    }
    dispatchGetDatabases() { //
        this.props.dispatch(getDatabases());
    }
    dispatchValidatePackage = (password) => {
        const validatePackage = { password: this.props.importPackage.password,password1:password}
        this.props.dispatch(processValidateRequest(validatePackage));
    }
    dispatchChange=(changeObject) =>{
        this.props.dispatch(getChangeImportPackage(changeObject));
    }
    dispatchConnectionChange = (changeObject,connectionChangeObject) => {
        this.props.dispatch(getConnectionChangeImportPackage(changeObject, connectionChangeObject));
    }
    
    dispatchReset = () => {
        this.props.dispatch(importPackageReset());
    }
    dispatchClearPasswordError = () => {
        this.props.dispatch(importPackageClearPasswordError());
    }
    onDownloadClick = () => {

        this.props.dispatch(getPackageLogFile(true));
    }
    
    handleContentListPanelChange = () => {
        this.setState({listPanel:!this.state.listPanel})
    }
    handlePanelChange = () => {
        this.setState({ panel: !this.state.panel })
    }
    skipDuplicateCheckChange = (event) => {
        this.dispatchChange(importPackage => {
            importPackage.skipSameObject = event.target.checked;
            return importPackage;
        });
    }
   render() {
       
       const { classes } = this.props;
       const spacing = 0;
       
       return (
           <div>
               {this.props.isLoading && <ActionBackdrop />}
               {this.props.importPackage.isPasswordProtected && <ImportPassword
                   resetAction={this.dispatchReset}
                   validateAction={this.dispatchValidatePackage}
                   clearErrorAction={this.dispatchClearPasswordError}
                   validateStatus={this.props.validateImportPackageStatus} />}

           <Grid container direction="row" justifyContent="space-between" >
                   <Grid item xs={12} >
                       <Accordion expanded={this.state.panel} onChange={this.handlePanelChange} >
                               <AccordionSummary classes={{ content: classes.packageSetup, root: classes.packageSetup, }}
                                   expandIcon={<ExpandMoreIcon />}
                                   aria-controls="panel1a-content"
                                   id="panel1a-header"
                               >
                               <Typography className={classes.heading} pt={1}>Package Information</Typography>
                               </AccordionSummary >
                               <AccordionDetails >
                               <Grid container direction="row" spacing={spacing} >
                                   <Grid item xs={6} >

                                       <Box mt={.3} className={classes.subGrid}>


                                           <Grid container direction="row" spacing={4} >
                                               <Grid item xs={6} className={classes.gridPadding} >
                                                   <Box sx={{ width: 390 }}>
                                                   <FileUpload placeholder="File Upload" accept=".vgo"
                                                       onFileUploadChange={this.handleFileUploadChange}
                                                       fileName={this.props.importPackage.packageFileName}
                                                       reset={ !this.props.importPackage.packageFileName}
                                                       />
                                                       </Box>
                                               </Grid>
                                               <Grid item xs={6} className={classes.gridPadding}>

                                               </Grid>
                                           </Grid>


                                       </Box>
                                       <Box mt={4} className={classes.subGrid}>


                                           <Grid container direction="row" spacing={4} >
                                               <Grid item xs={9} className={classes.gridPadding} >
                                                   <Typography>Name:{` ${this.props.importPackage.packageName}`}</Typography>
                                               </Grid>
                                               <Grid item xs={3} className={classes.gridPadding}>
                                                   <Typography>Version:{` ${this.props.importPackage.packageVersion}`}</Typography>
                                               </Grid>
                                           </Grid>


                                       </Box>
                                       <Box className={classes.subGrid} mt={4} mb={ 6}>
                                           <Grid container direction="row" spacing={0}>
                                               <Grid item xs={12}  >
                                                   <Typography sx={{ wordBreak: "break-word" }}>Description:{` ${this.props.importPackage.packageDescription}`}</Typography>
                                                   
                                                   
                                               </Grid>
                                               

                                           </Grid>
                                       </Box>
                                   </Grid>

                                   <Grid item xs={5}>
                                       <Box mt={.3} className={classes.subGrid}>


                                           <Grid container direction="row" spacing={0} >
                                               <Grid item xs={6}   >
                                                   <FormControlLabel control={<Checkbox 
                                                       checked={this.props.importPackage.skipSameObject} onChange={this.skipDuplicateCheckChange}    />}
                                                       label="Ignore duplicates" />
                                               </Grid>
                                               <Grid item xs={6} className={classes.gridPadding}>
                                                   < Button disabled={this.props.enableDownloadLog}
                                                       disableElevation
                                                       disableFocusRipple
                                                       disableRipple
                                                       variant="text"
                                                       onClick={this.onDownloadClick}
                                                       startIcon={<FileDownloadIcon />}>
                                                       {"Log File"}
                                                   </Button>
                                               </Grid>
                                           </Grid>


                                       </Box>
                                      
                                   </Grid>
                               </Grid>
                               </AccordionDetails>
                           </Accordion>
                       
                   
                   </Grid>
               </Grid>
               <Grid container direction="row" justifyContent="space-between" >
                   <Grid item xs={12} pt={1}>
                       <Accordion expanded={this.state.listPanel} onChange={this.handleContentListPanelChange} >
                           <AccordionSummary classes={{ content: classes.packageSetup, root: classes.packageSetup, }}
                               expandIcon={<ExpandMoreIcon />}
                               aria-controls="panel1a-content"
                               id="panel1a-header"
                           >
                               <Grid container direction="row" justifyContent="space-between">
                                   <Grid item xs={6}>
                                       <Typography variant="subtitle1" pt={1}>Connection Mapping</Typography>
                                   </Grid>
                                   <Grid item xs={6}>
                                       <Typography variant="subtitle1" pt={1} pl={1.5 }>Package Contents</Typography>
                                   </Grid>
                                   
                                   </Grid>
                               
                           </AccordionSummary >
                           <AccordionDetails >
                               <ImportList
                                   selectAll={this.props.importPackage.selectAll}
                                   databaseList={this.props.databases}
                                   connectionMappingList={this.props.importPackage.connectionMapping}
                                   exportConnectionList={this.props.exportConnectionList}
                                   importItemList={this.props.importPackage.importItemList}
                                   changeObject={this.dispatchChange}
                                   connectionChange={this.dispatchConnectionChange}
                                   invalidConnectionList={this.props.importConnectionMappingErrorList}
                               />
                           </AccordionDetails>
                       </Accordion>
                   </Grid>
                </Grid>
           </div>
       );
        
    }
}

function mapStateToProps(state) {
    
    return {
         databases: state.databases.databases,
         importPackage: state.packages.importPackage,
         isLoading: state.packages.isLoading,
         enableDownloadLog: !state.packages.downloadLog,
         exportConnectionList: state.packages.exportConnectionList,
        validateImportPackageStatus: state.packages.validateImportPackageStatus,
        importConnectionMappingErrorList: state.packages.importConnectionMappingErrorList,
     };
 };

export default withRouter(connect(mapStateToProps)(withStyles(packageTheme)(ImportContent)));


