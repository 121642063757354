import { customThemeStyles, customColorPalette, mainTheme } from 'common/theme.js';
export const formUITheme = (theme) => ({
    ...customThemeStyles,

    root: {
        flexGrow: 1,
        marginTop: 30,
        display: 'flex',
        oveflow: "scroll",
        maxWidth: "100%"
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: '100%'
    },

    card: {
        marginTop: "8px",
        background: "#FFFFFF",
        boxShadow: " 0px 1px 2px rgba(0, 0, 0, 0.2), 0px 1px 3px rgba(0, 0, 0, 0.1)",
        borderRadius: "4px 4px 0px 0px"

    },
    scrollCard: {
        width: "100%!important",
        height: "785px!important",
        paddingRight: "10px"

    },
    editStyle1: {
        color: `${customColorPalette.black[87]} !important`,
        padding: "0px",
        fontSize: "14px!important",
        cursor: "pointer"
    },
    editStyle2: {
        color: "#43A047",
        fill: "#43A047",
        stroke: "#43A047",
        height: "12.16px",
        width: "10.67px",
        padding: "0px",
        fontSize: "12px!important",
        cursor: "pointer"
    },

    editArrowStyle1: {
        color: "#2196F3",
        fill: "#2196F3",
        stroke: "#2196F3",
        height: "13.16px",
        width: "11.67px",
        padding: "0px",
        fontSize: "12px!important",
        cursor: "pointer",
        display: "none",
    },
    editArrowStyle2: {
        color: "#43A047",
        fill: "#43A047",
        stroke: "#43A047",
        height: "12.16px",
        width: "10.67px",
        padding: "0px",
        fontSize: "10px!important",
        cursor: "pointer"
    },
    editDeleteIcon: {
        // marginLeft: "12px"
    
    },
    editColumn: {
        height: "18px!important",
        display: "block"
    },

    addIcon: {
        position: "relative",
        top: " 35%",
        left: "50 %",
        cursor: "pointer"
    },
    subRowIcon: {
        float: "right",
        paddingTop: "5px",
        paddingRight: "5px",
        cursor: "pointer"

    },
    scrollbarHorizontal: {
        display: 'none!important',
    },
    saveItem: {
        width: 117
    },
    save: {
        width: 25
    },
    saveNewItem: {
        width: 169
    },

    saveCloseItem: {
        width: 184
    },
    cancelItem: {
        width: 110
    },
    controlSettings: {
        position: "absolute!important",
        top: "106px!important",
        left: "79.2%!important",
        maxWidth: "344px!important",
        backgroundColor: "white!important",
        minWidth: "344px!important",
    },

    formControltab: {
        width: '106.67px!important',
        maxWidth: '106.67px!important',
        fontFamily: 'Roboto',
        fontSize: '11px!important',
        fontWeight: 400 + '!important',
        lineHeight: '13px!important',
        letterSpacing: 0,
        textAlign: 'center!important',
        padding: '0px 0px 2px 0px!important',

    },

    tabRoot: {
        background: `${mainTheme.palette.common.white} !important`,
        /* height:'40px'*/
    },
    tabsRoot: {
        minHeight: '43px!important',
        height: '43px!important'
    },
    heading: {
        color: `${customColorPalette.black[54]} !important`,

    },
    spanControltab: {
        fontSize: '12px!important',
        marginLeft: '5px!important',
        fontFamily: 'Roboto!important',
        fontStyle: 'normal!important',
        fontWeight: 400 + '!important',
        lineHeight: '14px!important',
        alignItems: 'center!important',
        textAlign: 'center!important',
        letterSpacing: '0.0025em!important',
        color: `${customColorPalette.black[87]} !important`,

    },
    spanControltab1: {
        fontSize: '12px!important',
        fontFamily: 'Roboto!important',
        fontStyle: 'normal!important',
        fontWeight: 400 + '!important',
        lineHeight: '14px!important',
        alignItems: 'center!important',
        textAlign: 'center!important',
        letterSpacing: '0.0025em!important',
        color: `${customColorPalette.black[87]} !important`,

    },
    spanControlText: {
        fontSize: '12px',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '14px',
        alignItems: 'center',
        textAlign: 'center',
        letterSpacing: '0.0025em',
        color: `${customColorPalette.black[87]} !important`,
    },
    sectionControlTab: {
        maxHeight: '75px!important'
    },
    controlTextRow: {
        //borderColor: 'grey',
        //borderStyle: 'solid',
        //borderWidth:'thin',
        height: '57px',
        verticalAlign: 'middle',
        paddingTop: '15px',
        maxWidth: '112px!important',
        minWidth: '112px!important',
        paddingLeft: '35px!important'

    },

    controlNumericRow: {
        height: '57px',
        verticalAlign: 'middle',
        paddingTop: '15px',
        maxWidth: '112px!important',
        minWidth: '112px!important',
        paddingLeft: '8px!important'

    },

    controlSelectRow: {
        height: '57px',
        verticalAlign: 'middle',
        paddingTop: '15px',
        maxWidth: '113px!important',
        minWidth: '113px!important',
        paddingLeft: '14px!important'

    },

    //second row
    controlDateRow: {
        height: '57px',
        verticalAlign: 'middle',
        paddingTop: '15px',
        maxWidth: '112px!important',
        minWidth: '112px!important',
        paddingLeft: '16px!important'

    },

    controlTimeRow: {
        height: '57px',
        verticalAlign: 'middle',
        paddingTop: '15px',
        maxWidth: '112px!important',
        minWidth: '112px!important',
        paddingLeft: '15px!important'

    },

    controlButtonRow: {
        height: '57px',
        verticalAlign: 'middle',
        paddingTop: '15px',
        maxWidth: '113px!important',
        minWidth: '113px!important',
        paddingLeft: '27px!important'

    },

    //third row
    controlSingleRow: {
        height: '57px',
        verticalAlign: 'middle',
        paddingTop: '15px',
        maxWidth: '112px!important',
        minWidth: '112px!important',
        paddingLeft: '11px!important'

    },

    controlMultiRow: {
        height: '57px',
        verticalAlign: 'middle',
        paddingTop: '15px',
        maxWidth: '112px!important',
        minWidth: '112px!important',
        paddingLeft: '13px!important'

    },

    controlFileRow: {
        height: '57px',
        verticalAlign: 'middle',
        paddingTop: '15px',
        maxWidth: '113px!important',
        minWidth: '113px!important',
        paddingLeft: '17px!important'

    },


    controlSignRow: {
        height: '57px',
        verticalAlign: 'middle',
        paddingTop: '15px',
        maxWidth: '112px!important',
        minWidth: '112px!important',
        paddingLeft: '22px!important'

    },

    controlTabRoot: {
        width: '432px',
        height: '536px'
    },
    controlTabHeader: {
        width: '100%',
        height: '480px!important'
    },
    deleteFormControlRow: {
        height: '15px'
    },
    deleteFormControlItem: {
        textAlign: 'right',
        paddingTop: '3px',
        paddingright: '5px',

    },
    formRoleIcon: {
        marginRight: '10px'
    },
    formRoleIconSmall: {
        width: '14px !important',
        height: '9.8px !important',
    },
    formRoleButtonIcon: {
        paddingLeft: '0px!important',
        paddingTop: '0px!important'
    },
    controlSecondRow: {
        /* marginTop: '5px',*/
        height: '15px',
        paddingLeft: '5px'
    },
    arrowDownIconItem: {
        width: '16px',
        textAlign: 'left'
    },
    arrowUpIconItem: {
        width: '15px',
        textAlign: 'left'
    },
    controlFieldNameRow: {
        /* height: '15px',*/
        marginTop: '5px'
    },
    controlFieldNameItem: {
        textAlign: 'left',
        marginLeft: '9px!important',
        marginTop: '7px!important'
    },
    controlFieldNameItem1: {
        textAlign: 'left',
        marginLeft: '9px!important',

    },
    controlFieldNameItemLessSpace: {
        textAlign: 'left',
        marginLeft: '9px!important',
        marginTop: '7px!important'
    },
    formControl: {
        color: `${customColorPalette.black[54]} !important`,

    },

    controlTablDownIconItem: {
        width: '16px!important',

    },
    controlTablUpIconItem: {
        width: '20px!important',

    },
    controlArea: {
        marginTop: '9px',
        minHeight: '46px'
    },
    GridEditIcon: {
        marginTop: '25px'
    },
    GridEditItem: {
        width: '20px',
        textAlign: 'right'
    },
    gridItemWidth: {
        maxWidth: "1440px!important",

    },

    textBoxIcon: {
        marginLeft: '2px!important',

    },

    controlName: {
        color: `${customColorPalette.black[54]} !important`,
        marginTop: "4.5px!important",
        fontWeight: '500!important'
    },
    controlName1: {
        color: `${customColorPalette.black[54]} !important`,
        marginTop: "4.5px!important",
        marginLeft: "6.75px!important",
        fontWeight: '500!important'
    },
    controlBarIcon: {
        cursor: 'pointer !important'
    },
    spanPrimary: {
        color: '#2196F3!important'
    },
    primaryfieldItem: {
        textAlign: "left!important",
        paddingLeft: "10px!important",
        paddingTop: "3px!important"
    },
    iconColor: {
        color: `${customColorPalette.black[54]} !important`,
        transform: "scale(.7)"
    },
    layoutContainer: {
        minWidth: "1488px!important",
        width: "1488px!important",
        paddingRight: "10px!important",
        paddingBottom: "10px!important"
    },
    layoutContainerDefault: {
        paddingRight: "10px!important",
        paddingBottom: "10px!important",
        width: "1488px!important",
    },
    controlSettingsItem: {
        width: "344px"
    },
    buttonContainer: {
        width: "103px!important",
        marginRight: "20px",
    },
    buttonContainer2: {
        width: "175px!important",
    },
    buttonContainer3: {
        width: "87px!important",
        marginRight: "20px",
    },

    selectedControl: {
        color: "#2196F3!important",
       

    }

});
