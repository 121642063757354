import { ActionTypes as types } from 'admin/actions/actionTypes';
import { getNewDashboard, removeDashboardFromList, modifyDashboardList, setPanelObjectList, setPanelUI, updatePanelLayout, updatePanelLayoutWithObjectList} from 'admin/actions/dashboardActions'
import { produce } from 'immer';
const initialState = {
    dashboards: [],
    dashboardActionStatus: "",
    dashboard: getNewDashboard(),
    dashboardItems: { roles: [], items: [], actionStatus: '' },
    panelObjectList: [],
    panelList:[],
    isUpdate:false,
    errors: {},
    isLoading:true,
};
const dashboards = produce((draft, action) => {
    
    switch (action.type) {
        case types.VGO_GET_DASHBOARDS_REQUEST:
            draft.dashboardActionStatus = types.VGO_GET_DASHBOARDS_REQUEST;
            draft.isUpdate = false;
            draft.isLoading = true;
            break;
        case types.VGO_GET_DASHBOARDS_SUCCESS:
            draft.dashboards = produce(action.dashboards, () => { });
            draft.dashboardActionStatus = types.VGO_GET_DASHBOARDS_SUCCESS;
            draft.panelObjectList = [];
            draft.isUpdate = false;
            draft.isLoading = false;
            break;
        case types.VGO_GET_DASHBOARD_REQUEST:
            draft.dashboardActionStatus = types.VGO_GET_DASHBOARD_REQUEST;
            break;
        case types.VGO_GET_DASHBOARD_SUCCESS:
            draft.dashboard = produce(action.dashboard, () => { });
            draft.dashboardActionStatus = types.VGO_GET_DASHBOARD_SUCCESS;
            draft.errors = {};
            draft.panelObjectList = setPanelObjectList(action.dashboard);
            draft.panelList = action.dashboard.panelList
            draft.isUpdate = false;
            draft.dashboardItems = { roles: [], items: [], actionStatus: '' }
            break;
        case types.VGO_UPDATE_DASHBOARD_REQUEST:
            draft.dashboardActionStatus = types.VGO_UPDATE_DASHBOARD_REQUEST;
            break;
        case types.VGO_UPDATE_DASHBOARD_SUCCESS:
            draft.dashboard = produce(action.dashboard, () => { });
            draft.dashboardActionStatus = types.VGO_UPDATE_DASHBOARD_SUCCESS;
            draft.dashboards = modifyDashboardList(draft.dashboards, draft.dashboard)
            draft.isUpdate = true;
            break;
            
        case types.VGO_NEW_DASHBOARD:
            draft.dashboard = getNewDashboard();
            draft.panelObjectList = [];
            draft.panelList = [];
            draft.errors = {};
            draft.isUpdate = false;
            draft.dashboardItems = { roles: [], items: [], actionStatus: '' }
            break;
        case types.VGO_DELETE_DASHBOARD_SUCCESS:
            draft.dashboards = removeDashboardFromList(draft.dashboards, action.dashboardId)
            break;
        case types.VGO_GET_DASHBOARDITEMLIST_REQUEST:
            draft.dashboardActionStatus = types.VGO_GET_DASHBOARDITEMLIST_REQUEST;
            draft.dashboardItems = { roles: [], items: [], actionStatus: types.VGO_GET_DASHBOARDITEMLIST_REQUEST }
            break;
        case types.VGO_GET_DASHBOARDITEMLIST_SUCCESS:
            draft.dashboardItems = produce(action.dashboardList, () => { });
            draft.dashboardActionStatus = types.VGO_GET_DASHBOARDITEMLIST_SUCCESS;
            break;
        case types.VGO_CHANGE_DASHBOARD:

            action.change(draft.dashboard);
            if (action.errors) {
                draft.errors = produce(draft.errors, draftErrors => { return { ...draftErrors, ...action.errors } });
            }
            break;
        case types.VGO_VALIDATE_DASHBOARD_SETUP_ONBLUR:

            draft.errors = produce(draft.errors, draftErrors => { return { ...draftErrors, ...action.errors } });
            break;
        

        case types.VGO_SET_PANEL_DASHBOARD:
            
            const newPanelSettings = setPanelUI(action.panelSettings.panels, action.panelSettings.panel, action.panelSettings.addPanel, action.panelSettings.panelObjects);
            draft.panelList = newPanelSettings.panels;
            draft.panelObjectList = newPanelSettings.panelObjectList;
            break;
        case types.VGO_SETUI_PANEL_DASHBOARD:
            draft.panelList = updatePanelLayout(draft.panelList, action.panelSettings.panels);
            break;
        case types.VGO_SETUI_PANELWITHOBJECT_DASHBOARD:
            const newPanelListSettings = updatePanelLayoutWithObjectList(action.panelSettings.panelList, action.panelSettings.panelObjectList);
            draft.panelList = newPanelListSettings.panels;
            draft.panelObjectList = newPanelListSettings.panelObjectList;
            break;
        default:
            break;
    }

}, initialState);

export default dashboards;