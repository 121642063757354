import React from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Collapse, IconButton } from '@mui/material';
import { Alert } from '@mui/lab';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, Button } from '@mui/material';
import { alertStyle } from 'admin/components/Common/theme/AlertStyle';
import ClickAwayListener from '@mui/base/ClickAwayListener';
/**
 * AlertMessage Component
 */
class AlertMessage extends React.Component {
    
    renderClose = () => {
        if (this.props.open ) {
            let timeOutId = 0;
            if (this.props.severity === "success" && !this.props.source) {
                 timeOutId =  setInterval( ()=> {
                    this.props.close(timeOutId);
                    
                }, 3000)
            }
            return (
                <IconButton
                    aria-label="close"
                   color="inherit"
                    size="small"  
                    onClick={() => {
                        this.props.close(timeOutId);

                    }}
                >
                    <CloseIcon className={this.props.classes.closeIcon} fontSize="inherit" />
                </IconButton>								
            );
        }
        else {
            return null;
        }
    }

    handleClickAway = () => {
        this.props.close();
    }
    renderActions = (classes) => {
        if (this.props.close && this.props.severity !== "success") {
            return (



                <Grid container className={classes.confirmContainer}>
                    <Grid item>
                        <Button color="inherit" size="small" className={this.props.classes.alertActionButton} onClick={() => { this.props.action.primaryAction(this.props.action.value) }}>YES</Button>
                    </Grid>
                    <Grid item>
                        <Button color="inherit" size="small" className={this.props.classes.alertActionButton} onClick={this.props.action.SecondaryAction}>NO</Button>
                    </Grid>
                    <Grid item >
                        <Box pt={.2}>
                            <IconButton
                                aria-label="close"
                                size="small"
                                onClick={this.props.close}
                            >
                                <CloseIcon
                                    className={this.props.classes.alertCloseIcon} fontSize="inherit" />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>


            );
        }
        else {
            
            return null;
        }
    }
    renderAlert = () => {
        const { classes } = this.props;
        
        const alert = (
            <Collapse in={this.props.open} classes={{ wrapper: classes.wrapper }}>
                <Alert classes={{ root: classes.root }}
                    action={this.props.action ? this.renderActions(classes) : this.renderClose()}
                     icon={this.props.icon}
                    severity={this.props.severity}
                >
                    <div dangerouslySetInnerHTML={{ __html: this.props.message }} />
                    
                </Alert>
            </Collapse>
        )
        
        if (this.props.source === "dashboard") {
            return (
                <ClickAwayListener onClickAway={this.handleClickAway}>
                {alert}
                </ClickAwayListener>
            )
        }
        else
        {
            return alert;
        }
        
    }
    /**
     * Render the AlertMessage
     */
    render () {
        
       
        return (
            
                this.renderAlert()
                
        );

    }

}

/**
 * Default properties for the AlertMessage
 */
AlertMessage.defaultProps = {
    open: true,
    severity: 'success',
    title: '',
    message: '',
    icon: <CheckIcon fontSize="inherit" />,
    close: null,
};

/**
 * Property types for the AlertMessage properties
 */
AlertMessage.propTypes = {
    open: PropTypes.bool,
    severity: PropTypes.oneOf( [
        'success',
        'info',
        'warning',
        'error',
    ] ),
    title: PropTypes.string,
    message: PropTypes.string,
    icon: PropTypes.element,
    close: PropTypes.func,
};

/**
 * default export for the AlertMessage component
 */
export default withStyles(alertStyle)(AlertMessage)
