import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import MUIDataTable from 'mui-datatables';
import { Grid, Typography, IconButton, Box } from '@mui/material'
import { spacing } from '@mui/system';
import { styled } from '@mui/styles';
import MuiTextField from '@mui/material/TextField';
import Autocomplete from '@mui/lab/Autocomplete';
import cloneDeep from 'lodash/cloneDeep';
import { dataViewLinkUpdate, dataViewRemoveConditionParameter, conditionParameterObject } from 'admin/actions/dataViewAddLinksActions';
import Delete from '@mui/icons-material/DeleteOutline';
import Checkbox from '@mui/material/Checkbox'
import * as enumerator from 'client/components/Common/Enum';
import { linksTheme } from './theme/linksTheme';
import CircularProgress from "@mui/material/CircularProgress";
import { sortName } from 'client/components/Common/Utility';
const TextField = styled(MuiTextField)(spacing);


const SubLinks = (props) => {
    const [paramSources, setParamSources] = useState({});
    const [paramTargets, setParamTargets] = useState({});
   
    const {
        classes,
        dispatch,
        dataViewReportLinks,
        parentRowIndex,
        paramDestinationFieldSource
    } = props;

    useEffect(() => {
        setParamSources({});
        setParamTargets({});
    }, [dataViewReportLinks[parentRowIndex]])

    const handleLinkDataChange = (index, field, value, isParameter, paramIndex, updatedDataViewLinks) => {
        let dataViewLinks = (updatedDataViewLinks) ? updatedDataViewLinks : cloneDeep(dataViewReportLinks);
        let data = { ...dataViewLinks[index] };

        switch (field) {
            case "isStatic":
                {
                    data.reportLinkDetails[paramIndex].isStatic = value;
                    data.reportLinkDetails[paramIndex].parameterValue = ''
                    break;
                }
            case "parameterName":
                {
                    data.reportLinkDetails[paramIndex].parameterName = value;
                    break;
                }
            case "parameterValue":
                {
                    data.reportLinkDetails[paramIndex].parameterValue = value;
                    break;
                }
        }
        //dispatch(dataViewLinkUpdate(index, data, dataViewLinks));
        let newDataViewLink = dataViewUpdateLinkValue(index, data, dataViewLinks);
        dispatch(dataViewLinkUpdate(newDataViewLink));
    }
    const dataViewUpdateLinkValue = (rowIndex, updatedObject, dataViewReportLinks) => {
        let dataViewLinks = dataViewReportLinks.map((reportLink, index) => {
            if (index === rowIndex) {
                reportLink = updatedObject;
            }
            return reportLink;
        })
        return dataViewLinks;
    };
    const getParamDestinationFieldValue = (paramValue, index) => {
        let paramDestination = paramDestinationFieldSource.filter(paramDestination => paramDestination.conditionIndex === index)
        if (paramDestination.length > 0) {
            let val = paramDestination[0].fieldList.filter(paramObj => paramObj.value?.toUpperCase() === paramValue?.toUpperCase())
            if (val !== undefined && val.length > 0)
                return val[0];
        }
        return null;
    }
    const addNewParameter = (conditionIndex, paramObjectArray, clearExistingParam, dataViewLinks) => {
        let dataViewLinksNew = dataViewLinks.map((reportLink, rowIndex) => {
            if (rowIndex === conditionIndex) {
                if (reportLink.reportLinkDetails === undefined || clearExistingParam) {
                    reportLink.reportLinkDetails = [];
                }
                paramObjectArray.map((paramObj) => {
                    reportLink.reportLinkDetails.push(paramObj);
                })
                return reportLink;
            } else {
                return reportLink;
            }
        })
        return dataViewLinksNew;
    };
    const removeParameter = (conditionIndex, rowIndex, removeAll) => {
        let dataViewLinks = cloneDeep(dataViewReportLinks);
        dispatch(dataViewRemoveConditionParameter(conditionIndex, rowIndex, dataViewLinks, removeAll));
        setParamSources({});
        setParamTargets({});
    }
    const getAnchorNameObjectValue = (parameterValue) => {
        let val = props.dataViewReportAnchorName.filter(paramObj => paramObj.fieldName === parameterValue)
        if (val !== undefined && val.length > 0)
            return val[0];
        return null;
    }

    const customRenderParameterIsStatic = (value, tableMeta, updatedValue) => {
        if (!dataViewReportLinks[parentRowIndex]) {
            return;
        }
        if (!dataViewReportLinks[parentRowIndex].reportLinkDetails[tableMeta.rowIndex]) {
            return;
        }
        return (<Checkbox id={"parameterStatic" + tableMeta.rowIndex} size="small"
            onChange={(e) => { handleLinkDataChange(parentRowIndex, "isStatic", e.target.checked, true, tableMeta.rowIndex) }}
            checked={dataViewReportLinks[parentRowIndex].reportLinkDetails[tableMeta.rowIndex].isStatic} />)
    }
    const customRenderParameterSourceValue = (value, tableMeta, updatedValue) => {
        let dataViewLinks = cloneDeep(dataViewReportLinks);
        if (!dataViewLinks[parentRowIndex]) {
            return;
        }
        if (!dataViewLinks[parentRowIndex].reportLinkDetails[tableMeta.rowIndex]) {
            return;
        }
        let isStatic = dataViewLinks[parentRowIndex].reportLinkDetails[tableMeta.rowIndex].isStatic;
        if (isStatic) {
            return (<TextField id={"paramName" + tableMeta.rowIndex}
                onChange={(e) => {
                    let actionNames = { ...paramSources };
                    actionNames['paramSource' + parentRowIndex + tableMeta.rowIndex] = e.target.value;
                    setParamSources(actionNames);
                }}
                value={(paramSources['paramSource' + parentRowIndex + tableMeta.rowIndex] !== undefined) ? paramSources['paramSource' + parentRowIndex + tableMeta.rowIndex] : dataViewLinks[parentRowIndex].reportLinkDetails[tableMeta.rowIndex].parameterValue}
                onBlur={event => {
                    handleLinkDataChange(parentRowIndex, "parameterValue", event.target.value, true, tableMeta.rowIndex)
                }}
                inputProps={{ maxLength: 250 }}
            />)
        } else {
            return (<Box pt={.4}><Autocomplete
                id={"conditionSource" + tableMeta.rowIndex}
                disableClearable={true}
                size="small"
                fullWidth={true}
                options={[...props.dataViewReportAnchorName].sort((a, b) => sortName(a.displayFieldName, b.displayFieldName))}
                getOptionLabel={(option) => option.displayFieldName}
                getOptionSelected={(option, value) => value.fieldName === option.fieldName}
                value={getAnchorNameObjectValue(dataViewLinks[parentRowIndex].reportLinkDetails[tableMeta.rowIndex].parameterValue)}
                onChange={(event, newValue) => {
                    handleLinkDataChange(parentRowIndex, "parameterValue", newValue.fieldName, true, tableMeta.rowIndex)
                }}
               
                renderInput={params => (
                    <TextField {...params} id={"displayFieldName" + tableMeta.rowIndex} value={dataViewLinks[parentRowIndex].reportLinkDetails[tableMeta.rowIndex].parameterValue} />
                )}
            /></Box>)
        }

    }
    const customRenderParameterTargetField = (value, tableMeta, updatedValue) => {
        let dataViewLinks = cloneDeep(dataViewReportLinks);
        if (!dataViewLinks[parentRowIndex]) {
            return;
        }
        if (!dataViewLinks[parentRowIndex].reportLinkDetails[tableMeta.rowIndex]) {
            return;
        }
        let actionType = dataViewLinks[parentRowIndex].actionType;
        let dataViewLinksNew;
       
        if (actionType === enumerator.LinkActionType.Forms || actionType === enumerator.LinkActionType.Report) {
            let paramDestinationSource = paramDestinationFieldSource.filter(pObject => pObject.conditionIndex === parentRowIndex)
            return (<Box pt={.4}><Autocomplete
                id={"paramDestination" + tableMeta.rowIndex}
                disableClearable={true}
                size="small"
                fullWidth={true}
                loading={paramDestinationSource === undefined}
                options={paramDestinationSource !== undefined && paramDestinationSource.length > 0 ? paramDestinationSource[0].fieldList !== undefined ? paramDestinationSource[0].fieldList.sort((a, b) => sortName(a.value, b.value)) : [{ key: "", value: "" }] : [{ key: "", value: "" }]}
                getOptionLabel={(option) => option.key}
                getOptionSelected={(option, value) => value.key === option.key}
                value={getParamDestinationFieldValue(dataViewLinks[parentRowIndex].reportLinkDetails[tableMeta.rowIndex].parameterName, parentRowIndex)}
                onChange={(event, newValue) => {
                    if (tableMeta.rowIndex === dataViewLinks[parentRowIndex].reportLinkDetails.length - 1 && actionType !== enumerator.LinkActionType.CrystalReport) {
                        if (newValue.key) {
                            dataViewLinksNew = addNewParameter(parentRowIndex, conditionParameterObject(), false, dataViewLinks);
                        }
                    }

                    handleLinkDataChange(parentRowIndex, "parameterName", newValue.value, true, tableMeta.rowIndex, dataViewLinksNew)
                }}
                renderInput={params => (
                    <TextField {...params}
                        id={"destParam" + tableMeta.rowIndex}
                        value={dataViewLinks[parentRowIndex].reportLinkDetails[tableMeta.rowIndex].parameterName}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {paramDestinationSource === undefined ? (
                                        <CircularProgress color="inherit" size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            )
                        }}
                    />
                )}
            /></Box>)
        } else {
            return (<TextField id={"destParam" + tableMeta.rowIndex}
                onChange={(e) => {
                    let targets = { ...paramTargets };
                    targets['paramTarget' + parentRowIndex + tableMeta.rowIndex] = e.target.value;
                    setParamTargets(targets);

                }}
                disabled={actionType === enumerator.LinkActionType.CrystalReport ? true : false}
                value={(paramTargets['paramTarget' + parentRowIndex + tableMeta.rowIndex] !== undefined) ? paramTargets['paramTarget' + parentRowIndex + tableMeta.rowIndex] : dataViewLinks[parentRowIndex].reportLinkDetails[tableMeta.rowIndex].parameterName}
                onBlur={event => {
                    if (tableMeta.rowIndex === dataViewLinks[parentRowIndex].reportLinkDetails.length - 1 && actionType !== enumerator.LinkActionType.CrystalReport) {
                        if (event.target.value) {
                            dataViewLinksNew = addNewParameter(parentRowIndex, conditionParameterObject(), false, dataViewLinks);
                        }
                    }
                    handleLinkDataChange(parentRowIndex, "parameterName", event.target.value, true, tableMeta.rowIndex, dataViewLinksNew)
                }}
                inputProps={{ maxLength: 250 }}
            />);
        }

    }
    const customRenderParameterDelete = (value, tableMeta, updatedValue) => {
        if (!dataViewReportLinks[parentRowIndex]) {
            return;
        }
        if (!dataViewReportLinks[parentRowIndex].reportLinkDetails[tableMeta.rowIndex]) {
            return;
        }
        let actionType = dataViewReportLinks[parentRowIndex].actionType;
        if (actionType !== enumerator.LinkActionType.CrystalReport) {
            return (
                <IconButton id={parentRowIndex} type="button" onClick={() => removeParameter(parentRowIndex, tableMeta.rowIndex, false)} >
                    <Delete /></IconButton>
            );
        }
    }

    let parameterGrid = {
        columns: [
            {
                name: "linkIndex",
                label: "",
                options: {
                    display: false,
                }
            },
            {
                name: "isStatic",
                label: "Static Value",
                options: {
                    setCellHeaderProps: (cellValue, rowIndex, columnIndex) => {
                        return {
                            style: {
                                paddingLeft: 0,
                                paddingRight: 0,
                            },
                        };
                    },
                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                        return {
                            style: {
                                width: 100,
                                // backgroundColor: 'white'
                            },
                        };
                    },
                    customBodyRender: customRenderParameterIsStatic,
                }

            },
            {
                name: "parameterName",
                label: "Source Value",
                options: {
                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                        return {
                            style: {
                                width: 300,
                                paddingRight: 0,
                                //backgroundColor: 'white'
                            },
                        };
                    },
                    customBodyRender: customRenderParameterSourceValue,
                }

            },
            {
                name: "parameterValue",
                label: "Target Field",
                options: {
                    setCellHeaderProps: (cellValue, rowIndex, columnIndex) => {
                        return {
                            style: {
                                paddingLeft: 42,
                                paddingRight: 0,
                                //backgroundColor: 'white'
                            },
                        };
                    },
                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                        return {
                            style: {
                                width: 350,
                                paddingRight: 0,
                                paddingLeft: 42,
                                //backgroundColor: 'white'
                            },
                        };
                    },
                    customBodyRender: customRenderParameterTargetField,
                }

            },
            {
                name: "Delete",
                label: " ",
                options: {
                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                        return {
                            style: {
                                width: 10,
                                paddingLeft: 42,
                                //backgroundColor: 'white'
                            },
                        };
                    },
                    customBodyRender: customRenderParameterDelete,
                }

            }
        ],
        options: {
            download: false,
            elevation: 0,
            filter: false,
            print: false,
            search: false,
            viewColumns: false,
            pagination: false,
            selectableRows: "none",
            responsive: 'scrollMaxHeight',
            toolbar: {
                display: false
            },
        }
    }
    return (
        <Grid container direction="row" justifyContent="space-between">
            <Grid item xs={12} pl={4.5} pt={2}>
                <Typography className={classes.body1}>Parameters</Typography>
            </Grid>
            <Grid item xs={12} pl={4.5}>
                <Grid item xs={6} >
                    <MUIDataTable container
                        title={''}
                        data={props.dataSource}
                        columns={parameterGrid.columns}
                        options={parameterGrid.options}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}
function mapStateToProps(state) {
    return {
        dataView: state.dataViews.dataView,
        dataViewReportLinks: state.dataViews.dataView.reportLink,
        dataViewReportAnchorName: state.dataViews.dataView.fieldDisplay,
        dataViews: state.dataViews.dataViews,
        formsList: state.forms.forms,
        isLoading: state.loader.isLoading,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage
    };
};
export default withRouter(connect(mapStateToProps)(withStyles(linksTheme)(SubLinks)));