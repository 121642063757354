import { customThemeStyles } from 'common/theme.js';
import { customColorPalette } from 'common/theme.js';
export const APILinksTheme = () => ({
    ...customThemeStyles,
    root: { 
    },
    linkObject: { 
        height:"74px"
    },
    contentBox: {
        height:"1054px"
    },
    listItemObject: {
        width: "351px!important",
    },
    activeItem: {
        backgroundColor:customColorPalette.black[1]
    },
    inActiveItem: {
        
    },
    shortText: {
        width:"304px!important"
    },
    longText: {
        width: "608px!important"
    },
    gridCaption: {
        width:"100%!important"
    },
     gridUrl: {
        width: "100%!important"
    },
    switchIcon: {
        //height:"30px!important",
        //width:"15px!important"
    },
    listItemObjectinner: {
        width: "323.5px!important",
    },
    detailGrid: {
       // width:"934px!important"
    }
     
});