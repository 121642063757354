import React from 'react';
import { Box, Grid } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import Aside from './aside/Aside.js';
import Section from './section/Section.js'; 
import { customTheme, customThemeStyles } from 'common/theme.js';
import { withStyles } from '@mui/styles';

class Main extends React.Component {
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, true);

    }
    handleScroll = (event) => {
        //Fix for the issue of list openned while scrolling
        let closeAutoList = false
        let ListArray = document.getElementsByClassName("MuiAutocomplete-popper");
        if (ListArray.length > 0) {
            if (event.srcElement.className) {
                if (!event.srcElement.className.includes("MuiAutocomplete-listbox")) {
                    closeAutoList = true;
                }
            } else {
                closeAutoList = true;
            }
            if (closeAutoList) {  
                let ListArrayFocused = document.getElementsByClassName("Mui-focused");
                if (ListArrayFocused.length > 0) {
                    if (ListArrayFocused[0].children.length > 0) {
                        if (ListArrayFocused[0].children[0].children.length > 0) {
                            //fimd div
                            let divObject = ListArrayFocused[0].children[0].querySelector("div");
                            if (divObject && divObject.children.length > 1) {
                                if (divObject.children[1].children.length > 0) {
                                    let button  = divObject.children[1].querySelector('button[title="Close"]');                                  
                                    if(button)
                                    button.click();
                                }
                            }
                        }
                    }
                }
            }
        }
    }
   
    render() {
        const classes = this.props.classes; 
        return (
            <ThemeProvider theme={customTheme} >
                <Box m={0} p={0} height="100%" className={classes.topBox}>
                <Grid container>
                    <Box> 
                            <Aside toggleHeader={this.toggleHeader}></Aside>
                 </Box>
                        <Grid item xs className={classes.mainContainer}>
                            <Box className={classes.mainBox} >
                                <Section></Section>
                            </Box>
                        </Grid>
            </Grid>
                </Box>
            </ThemeProvider>
        );
    }
 }
export default (withStyles(customThemeStyles)(Main))
