import { customThemeStyles } from 'common/theme.js';
export const recordSubmissionTheme = (theme) => ({
    ...customThemeStyles,
    rootCard: {
        height: "100%",
        marginLeft: theme.spacing(-3) +'!important',
        marginRight: theme.spacing(-3) + '!important',
        marginTop: theme.spacing(-3.1) + '!important',
            
    },
    root: {
        justifyContent: 'center',
        flexWrap: 'wrap',
        height: "93px",
        borderRadius: "4px",
    },
    card: {
        width: "100%",
        borderTop: "none",
        borderRight: "none",
        borderLeft: "none",
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(5),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    paymentIcon: {
        color:'#43A047'
    },
    payment: {
        width:"50px!important"
    },
    controls: {
        width:"304px!important"
    },
    editableColumnItem: {
        paddingLeft: "0px!important"
    },
     editableColumn: {
         width: "370px!important"
    },
    showEditable: {
        marginTop:'16px!important'
    },
    submitColumnItem: {
        width: "360px!important"
    },
    buttonsubmitItem: {
        width: "336px!important"
    }
    ,
    confirmSelect1: {
        paddingLeft: "21px!important",
        marginTop: '-3px!important'
    },
    confirmSelect1Item: {
        
        marginTop: '-11px!important'
    },
    confirmSelect2: {
        paddingLeft: "9px!important"
    },
    targetURL: {
        paddingTop: "35px!important",
        width: "605px!important"
    }

    ,
    workFlowId: {
        width: "98px!important"
    }
    ,
    enableBizweaverItem: {
        width: "130px!important",
        paddingLeft:'4px'
    }
    ,
    workFlowIdItem: {
        width: "150px!important"
    },
    enableBizweaver: {
        paddingTop: "17px!important"
    },
    bizWeaverItem: {
        width: "325px!important"
    },

    bizweaverLabel: {
        paddingLeft:'3px!important'
    },
    bizweaverParamterName: {
        paddingLeft: '5px!important'
    },
    editableControl: {
        marginTop:'16px!important'
    },
    confirmPageControl1: {
        marginTop: '16px!important'
    }

   
});