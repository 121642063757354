import React from 'react';
import { bindActionCreators  } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { loginActionCreators } from 'client/store/Login';
import { errorActionCreator } from 'client/store/Error';
import { withStyles } from '@mui/styles';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Cookies from 'universal-cookie';
import SignInImage from 'client/assets/images/Vector.png'
import { loginTheme, checkboxTheme } from './theme/LoginTheme';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import { mainTheme } from 'common/theme';

const expandMenu = "login";
const CustomCheckbox = withStyles(checkboxTheme)(Checkbox);

const cookies = new Cookies();
export class Login extends React.Component {
    
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            expandMenu: "",
            expandClassName: "form form-access",
            hidden: true,
            password: "",
            email: "" ,
            rememberMe: false,
            passwordClass: "HidePwdIcon"
            };
        
    }   
    componentDidMount() { 
        let versagoRememberMeCookieValue = cookies.get('Versago_RM_UserName');
        this.getRememberMeValue(versagoRememberMeCookieValue)
    }
    showComponent = () => {
       
            return this.showLoginComponent();
        
    }
    toggleExpand = () => {
        try {
        if (this.props.expandMenu === expandMenu) {
            this.props.setExpandMenu('');
        }
        else {
            this.props.setExpandMenu(expandMenu);
            }
        } catch (error) {
            this.props.actions.errorActionCreator.showErrorMessage(error);
        } 
    }

    handlePasswordChange = (e) => {

        try {
            this.setState({ password: e.target.value });
        } catch (error) {
            this.props.actions.errorActionCreator.showErrorMessage(error);
        } 
    }
    toggleShow = () => {
        try {
        this.setState({
            hidden: !this.state.hidden

        });

        if (this.state.hidden === true)
            this.setState({ passwordClass: "ShowPwdIcon" });
        else
            this.setState({ passwordClass: "HidePwdIcon" });
        } catch (error) {
            this.props.actions.errorActionCreator.showErrorMessage(error);
        } 
    }
    usernameChange = event => {
        try {
        this.setState({
            [event.target.name]: event.target.value
        });
        } catch (error) {
            this.props.actions.errorActionCreator.showErrorMessage(error);
        } 
    }
    onSubmit = (e) => {
        try {
        e.preventDefault();
            let { email, password, rememberMe } = this.state; 
            this.props.actions.loginActionCreators.requestLogin(email, password, rememberMe);
            this.props.history.push("/");
        } catch (error) {
            this.props.actions.errorActionCreator.showErrorMessage(error);
        } 
    }
    getRememberMeValue = (versagoRememberMeCookieValue) => { 
        this.setState({ email: versagoRememberMeCookieValue ? versagoRememberMeCookieValue : "", rememberMe: versagoRememberMeCookieValue?true:false }) 
        
    }
    handleRememberMe = (event) => {
        this.setState({ rememberMe: event.target.checked })
    } 
    showLoginComponent = () => {
        const { classes } = this.props;
        const { isSiteTheme, themeColor} = (this.props.siteTheme) ? this.props.siteTheme : '';
        return (
            <form onSubmit={this.onSubmit} className={(isSiteTheme) ? classes.adminLeftPanelColor : classes.leftPanelColor}>
                <Box>
                    <button onClick={this.toggleExpand} className={classes.toggleButtonStyle} id="btnToggleSigninBox" >
                        <PersonOutlinedIcon/>
                    </button>
                    <Box>
                        <Box px={3} pt={3} pb={3.2}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                <TextField
                                    className={classes.loginTextbox}
                                    id="standard-adornment-username"
                                    name="email"
                                    type="text"
                                    onChange={this.usernameChange}
                                    value={ this.state.email}
                                    variant="outlined"
                                    placeholder="Username"
                                    required={true}
                                    size="small"
                                    color="primary"
                                    disabled={(isSiteTheme) ? true : false}
                                    sx={{
                                        "& .MuiOutlinedInput-root.Mui-focused": {
                                            "& > fieldset": {
                                                borderColor: themeColor
                                            }
                                        }
                                    }}
                                    autoFocus={true}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccountCircleOutlinedIcon className={classes.icon} />
                                            </InputAdornment>)
                                    }}
                                    />
                                </Grid >
                                <Grid item xs={12}>
                                    <TextField
                                        className={classes.loginTextbox}
                                        id="standard-adornment-password"
                                        type={this.state.hidden ? "password" : "text"}
                                        value={this.state.password}
                                        onChange={this.handlePasswordChange}
                                        variant="outlined"
                                        size="small"
                                        required={true}
                                        color="primary"
                                        placeholder="Password"
                                        width="5px"
                                        disabled={(isSiteTheme) ? true : false}
                                        sx={{
                                            "& .MuiOutlinedInput-root.Mui-focused": {
                                                "& > fieldset": {
                                                    borderColor: themeColor
                                                }
                                            }
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <LockOutlinedIcon className={classes.icon}  />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton className={classes.iconButton}
                                                        aria-label="toggle password visibility"
                                                        onClick={this.toggleShow}
                                                    >
                                                        {this.state.passwordClass === "ShowPwdIcon" ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                    </IconButton>
                                                </InputAdornment>)
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>

                        <Box px={3} pb={3} >
                            <Button variant="text" type="submit" classes={{ root: classes.customLabelBold }} className={classes.signInButton} disableElevation disabled={(isSiteTheme) ? true : false}>
                                <img src={SignInImage} className={classes.image}/>
                                <Typography variant="subtitle2" className={classes.signInText}> Sign In</Typography>
                            </Button>
                        </Box>

                        {this.props.message &&
                            <Box px={3} pb={4} pt={2}>

                                <Box alignItems="center" display="flex"  >
                                    <Box>
                                    <InfoOutlinedIcon className={classes.resetIcon} > </InfoOutlinedIcon>
                                    </Box>
                                <Box ><Typography variant="subtitle2" className={classes.errorText} >{this.props.message}</Typography></Box>
                                </Box>

                            </Box>}

                        <Box pl={15} pr={5} pb={3} pt={0}>
                            <FormControlLabel control={
                                <CustomCheckbox checked={this.state.rememberMe}
                                    onChange={e => { this.handleRememberMe(e) }} />
                            }
                                disabled={(isSiteTheme) ? true : false}
                                label={<Typography classes={{ root: classes.customLabelBold }} className={classes.loginRememberMeText}>Remember me</Typography>} />
                           
                        </Box>
                    </Box>

                    <Box pt={2} pr={4} pb={6} pl={3} borderColor="#00000038" borderTop={1}>
                        <Box alignItems="center" display="flex"  >
                            <Box>
                                <Link to="/ResetPassword" className={classes.resetLink} >
                                    <VpnKeyOutlinedIcon className={classes.resetIcon}  > </VpnKeyOutlinedIcon>
                                </Link>
                            </Box>
                            <Box mb={1} pl={1}>
                                {isSiteTheme ?
                                    <Typography classes={{ root: classes.customLabelItalics }} className={classes.loginResetPasswordText}>Reset Password</Typography> :
                                    <Link to="/ResetPassword" className={classes.resetLink} >
                                        <Typography classes={{ root: classes.customLabelItalics }} className={classes.loginResetPasswordText}>Reset Password</Typography>
                                    </Link>
                                }
                            </Box>
                        </Box>
                    </Box>
                </Box>
                </form>
        );
        
    }
    render() {
        return this.showComponent();
    }
}
function mapDispatchToProps(dispatch) {
    return {
        actions: {
            loginActionCreators: bindActionCreators(loginActionCreators, dispatch),
            errorActionCreator: bindActionCreators(errorActionCreator, dispatch)
        }
    };
}
export default connect(state => state.Login, mapDispatchToProps)(withStyles(loginTheme)(Login));



