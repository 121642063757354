import React from 'react';

import { SvgIcon } from '@mui/material';
import { withStyles } from '@mui/styles';
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import GridOnOutlinedIcon from '@mui/icons-material/GridOnOutlined';
import ReorderOutlinedIcon from '@mui/icons-material/ListOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import StarRateIcon from '@mui/icons-material/StarRate';
import ViewColumnOutlinedIcon from '@mui/icons-material/ViewColumnOutlined';
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import InputIcon from '@mui/icons-material/Input';
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { menuIconTheme } from './theme/menuIconTheme.js';
const components = {
    "icon-api-links": LinkOutlinedIcon,
    "icon-arrow-left": KeyboardArrowLeftOutlinedIcon,
    "icon-arrow-right": KeyboardArrowRightOutlinedIcon,
    "icon-calendar": DateRangeOutlinedIcon,
    "icon-chart": AssessmentOutlinedIcon,
    "icon-check": CheckOutlinedIcon,
    "icon-clear": ClearOutlinedIcon,
    "icon-close": CloseOutlinedIcon,
    "icon-created": NoteAddOutlinedIcon,
    "icon-dashboards": DashboardOutlinedIcon,
    "icon-database": DnsOutlinedIcon,
    "icon-edited": EditOutlinedIcon,
    "icon-email": EmailOutlinedIcon,
    "icon-export": ImportExportOutlinedIcon,
    "icon-folder": FolderOutlinedIcon,
    "icon-forms": InputIcon,
    "icon-grid": GridOnOutlinedIcon,
    "icon-import": ImportExportOutlinedIcon,
    "icon-list": ReorderOutlinedIcon,
    "icon-menus": ListOutlinedIcon,
    "icon-news": AnnouncementOutlinedIcon,
    "icon-password": LockOutlinedIcon,
    "icon-pay": CreditCardOutlinedIcon,
    "icon-payments": CreditCardOutlinedIcon,
    "icon-plus": AddOutlinedIcon,
    "icon-quick-add": AddCircleOutlinedIcon,
    "icon-remove": DeleteOutlinedIcon,
    "icon-reports": ListOutlinedIcon,
    "icon-roles": VisibilityOutlinedIcon,
    "icon-settings": TuneOutlinedIcon,
    "icon-theme": PaletteOutlinedIcon,
    "icon-trash": DeleteOutlineOutlinedIcon,
    "icon-user-profiles": PeopleOutlinedIcon,
    "icon-user": PersonOutlinedIcon,
    "icon-view-list": ViewListOutlinedIcon,
    "icon-star": StarRateIcon,
    "icon-dataView": ViewColumnOutlinedIcon,
    "icon-menu": MenuOpenOutlinedIcon,
    "icon-home": OutlinedFlagIcon,
    "icon-link": InsertLinkOutlinedIcon,
    "icon-verifiedUser": VerifiedUserOutlinedIcon,
    "icon-role": SupervisorAccountOutlinedIcon,
    "icon-filters": FilterListOutlinedIcon,
    "icon-info": InfoOutlinedIcon,
    "icon-select": FactCheckOutlinedIcon,
};

class VersagoSvgIcon extends React.Component {

    constructor(props) {
        super(props);
        this.renderIcon = this.renderIcon.bind(this);
    }

    renderIcon(icon) {
        let component = components[ icon ];

        if ( component ) {
            return ( 
                <SvgIcon component={component} className={this.props.isAdmin?this.props.classes.icon:""} fontSize={icon === "icon-star" ? "medium" : "medium"}/>
            );
        }
        else {
            return null;
        }

    }

    render() {
        return this.renderIcon(this.props.icon);
    }

}

export default (withStyles(menuIconTheme)(VersagoSvgIcon))
