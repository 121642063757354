import React from 'react';
import { withStyles } from '@mui/styles';
import Home from 'client/components/Home/Home';
import Login from 'client/components/LeftPanel/Login';
import { leftPanelTheme } from 'client/components/LeftPanel/theme/leftPanelTheme.js'
import MenuIcon from '@mui/icons-material/Menu';
import Company from 'admin/components/aside/Company';
import { banners, newsAndAnnouncements, widgets} from './mockData'
import { Box, Grid } from '@mui/material';
import Drawer from '@mui/material/Drawer';

const DummyHome = (props) => {
    const { classes } = props;

    const siteTheme = {
        isSiteTheme: true,
        themeColor: props.themeColor,
        companyLogo: props.companyLogo,
        bannerList: banners,
        newsList: newsAndAnnouncements,
        widgetList: widgets 
    }

    return (<Grid container>
        <Grid item xs={2}>
            <Drawer
                variant="permanent"
                className={`${classes.drawer} ${classes.drawerOpen} ${classes.siteThemePosition}`}
                classes={{
                    paper: `${classes.drawerOpen} ${classes.siteThemePosition}`,
                    paperAnchorDockedLeft: classes.border,
                }}

            >
                <Box>
                    <Box className={`${classes.customTheme} ${classes.burgerIcon} ${classes.burgerExpanded}`} style={{ backgroundColor: props.themeColor}}><MenuIcon ></MenuIcon></Box>
                    <Company siteTheme={siteTheme} />
                </Box>
                <Box sx={{ height: 620 }}>
                    <Login siteTheme={siteTheme}/>
                </Box>
            </Drawer>
        </Grid>
        <Grid item xs={10}>
            <Home siteTheme={siteTheme}/>
        </Grid>
    </Grid>
    )
}

export default withStyles(leftPanelTheme)(DummyHome);