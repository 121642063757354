import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Box, Grid, Typography, Radio, FormControlLabel, RadioGroup, Select, InputLabel, Checkbox, MenuItem } from '@mui/material';
import { ActionTypes as actionTypes } from 'admin/actions/actionTypes';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { spacing } from '@mui/system';
import { withStyles } from '@mui/styles';
import MuiCard from '@mui/material/Card';
import MuiTextField from '@mui/material/TextField';
import { styled } from '@mui/styles';
import { homePageTheme } from './theme/homePageTheme.js';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import RichTextEditor from 'admin/components/section/settings/RichTextEditor';
import Autocomplete from '@mui/material/Autocomplete';
import FileUpload from 'admin/components/Common/FileUpload'
import { getRoles, sortRoles } from 'admin/actions/userActions';
import {  getChangeHomePage, homePageType, validateFieldsOnBlur, getHomePageContent } from 'admin/actions/homePageActions';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import cloneDeep from 'lodash/cloneDeep';
import { DateTime } from 'luxon';
const Card = styled(MuiCard)(spacing);
const contentTypes = {Event:2}
const TextField = styled(MuiTextField)(spacing);
const contentTypeList = [
    {
        name: "- Select -",
        value: 0,

    },
    {
        name: "Announcement",
        value: 3,

    },
    {
        name: "Article",
        value: 1,

    },
    {
        name: "Event",
        value: 2,

    },

]

const bannerTypeList = [
    {
        name: "- Select -",
        value: 0,

    },
    {
        name: "Alert",
        value: 2,

    },
    {
        name: "Notification",
        value: 1,

    },
    {
        name: "Promotion",
        value: 3,

    },

]

const detailTypeList = [
    {
        name: "- Select -",
        value: 0,

    },
    {
        name: "Expand",
        value: "expand",

    },
    {
        name: "Learn more",
        value: "learnMore",

    },
    {
        name: "More",
        value: "more",

    },
]



class HomePageEdit extends React.Component {

    constructor(props) {
        const { classes } = props;
        super(props);
        this.state = {
            messageError: false,
            messageHelper: ' ',
        }

    }

    componentDidMount() {

        this.dispatchGetRoles();
        if (this.props.location.state) {
            this.dispatchGetHomepageContent();
        }

    }


    componentDidUpdate(prevProps) {

        if (prevProps.errors.content !== this.props.errors.content) {

            if (this.props.errors.content) {
                this.setState({ messageError: true, messageHelper: 'Content is required' })
            }

           
        }
        if (prevProps.homePageContent.expiryDate !== this.props.homePageContent.expiryDate) {
            if (this.props.errors.expiryDate) {
                this.blurValidation('expiryDate');
            }
        }

        if (prevProps.homePageContent.contentEventDate !== this.props.homePageContent.contentEventDate) {
            if (this.props.errors.contentEventDate) {
                this.blurValidation('contentEventDate');
            }
        }

    }
    dispatchGetRoles = () => {

        if (this.props.roles.list.length === 0) {

            this.props.dispatch(getRoles());
        }


    }

    dispatchGetHomepageContent = (data) => {



        this.props.dispatch(getHomePageContent(this.props.location.state.data));



    }

    handleChange = (change) => {

        this.props.dispatch(getChangeHomePage(change));

    }

    fillType = (pageType) => {

        if (pageType === homePageType.Banner) {

            return bannerTypeList.map(function getBannerType(bannerType, index) {


                return (<MenuItem key={index + 1} value={bannerType.value}> {bannerType.name} </MenuItem>);

            })
        } else {

            return contentTypeList.map(function getContentType(contentType, index) {


                return (<MenuItem key={index + 1} value={contentType.value}> {contentType.name} </MenuItem>);

            })

        }



    }

    fillDetailTypeLIst = () => {



        return detailTypeList.map(function getdetailType(detailType, index) {


            return (<MenuItem key={index + 1} value={detailType.value}> {detailType.name} </MenuItem>);

        })



    }

    handleFileUploadChange = (image,name) => {
        this.props.dispatch(getChangeHomePage(
            homePageContent => {
                homePageContent.base64Image = image
                homePageContent.thumbnailImageName = name
                return homePageContent
    }
    ));

        //    changeSiteTheme(siteTheme => siteTheme.companyLogo = value);
    }


    onRolesChange = (event, value, reason) => {
        switch (reason) {

            case 'selectOption':
            case 'removeOption':
                {
                    let copyList = cloneDeep(value);
                    const roleList = sortRoles(copyList);
                    this.props.dispatch(getChangeHomePage(homePageContent => homePageContent.roles = roleList));
                    break;
                }
            default:
                break;


        }
    }
    handleEditorData = (event) => {

        let hasText = "";
        var regex = /(<([^>]+)>)/ig
        if (event.value) {
            let value = event.value
            hasText = !!value.replace(regex, "").length;
        }
        if (hasText) {

            this.props.dispatch(getChangeHomePage(homePageContent => homePageContent.content = event.value));
            if (this.state.messageError) {
                this.setState({ messageError: false, messageHelper: ' ' })
            }

        } else {
            if (!this.state.messageError) {
                this.setState({ messageError: true, messageHelper: 'Content is required' })
                this.props.dispatch(getChangeHomePage(homePageContent => homePageContent.content = ''));
            }

        }

    }

    handleEditor = (event) => {

        if (this.props.homePageContent.content) {

            if (this.state.messageError) {
                this.setState({ messageError: false, messageHelper: ' ' })
            }

        } else {
            if (!this.state.messageError) {
                this.setState({ messageError: true, messageHelper: 'Content is required' })

            }

        }

    }
    formatedDate = (value, format) => {
        if (typeof value === 'object') {
            return value;
        }
        else {

            const result = moment(value, format).toDate();
            if (isNaN(result)) {
                return null;
            } else {
                return result;
            }
        }
    }

    blurValidation = (fieldName) => {

       this.props.dispatch(validateFieldsOnBlur(this.props.homePageContent, fieldName));
    }

 render() {
        const { classes } = this.props;
        return (
            <Card variant="outlined" pl={3} pr={3} pb={33.25} pt={3.125}>
                <Grid container justifyContent="space-between" >
                    <Grid item xs={12} >


                        <Grid container spacing={0}  >
                            <Grid item xs={7}  >



                                <Grid container spacing={0} >

                                    <Grid item xs={6} >
                                        <Box style={{ width: "600px" }} >
                                            <Typography className={classes.body1}>Information</Typography>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={6} pl={32.75} >
                                        <Typography className={classes.body1}>Availability</Typography>
                                    </Grid>
                                </Grid>

                                <Grid container pt={1.3} >
                                    <Grid item xs={6}  >
                                        <RadioGroup row aria-label="position" name="position"
                                            onChange={
                                                (event) => {
                                                    this.handleChange(homePageContent => {
                                                        homePageContent.homePageType = event.target.value
                                                        homePageContent.subType = 0
                                                        homePageContent.contentEventDate= DateTime.local()
                                                        return homePageContent
                                                    })
                                                }}
                                            onBlur={(event) => {
                                                if (this.props.errors.detailDisplayType && this.props.homePageContent.homePageType === homePageType.Feed) {
                                                    this.blurValidation('detailDisplayType');
                                                }
                                                
                                            }}
                                            value={this.props.homePageContent.homePageType}
                                        >
                                            <FormControlLabel disabled={this.props.match.params.homePageId !== "new" ? true : false} value={homePageType.Banner} control={<Radio color="primary" />} label={<Typography className={classes.body2}
                                            >Banner</Typography>} />
                                            <Box pl={1}><FormControlLabel disabled={this.props.match.params.homePageId !== "new" ? true : false} value={homePageType.Feed} classes={{ root: classes.body2 }} control={<Radio color="primary" />} label={<Typography className={classes.body2} >Feed</Typography>} /></Box>
                                        </RadioGroup>
                                    </Grid>


                                    <Grid item xs={6} pl={32.75} >
                                        <FormControlLabel control={<Checkbox checked={this.props.homePageContent.isPreLogin}
                                            onChange={(event) => {
                                                this.handleChange(homePageContent => {
                                                    homePageContent.isPreLogin = event.target.checked
                                                })
                                            }}
                                        />} label="Public" />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={0} pt={1} >


                                    <Box sx={{ width: "304px" }}>
                                        <FormControl className={classes.formControl} error={this.props.errors.subType} value="Standard" variant="standard">
                                            <InputLabel id="dataSource">Type*</InputLabel>
                                            <Select variant="standard" autoFocus MenuProps={{ disableScrollLock: true }}
                                                id="type"
                                                value={this.props.homePageContent.subType}
                                                name="type"
                                                onChange={(event) => {
                                                    this.handleChange(homePageContent => {
                                                        homePageContent.subType = event.target.value
                                                        if (this.props.homePageContent.homePageType === homePageType.Feed && event.target.value !== "2") {
                                                            homePageContent.contentEventDate = DateTime.local()

                                                       }
                                                        return homePageContent
                                                    })
                                                }}
                                                onBlur={(event) => {
                                                  this.blurValidation('subType'); }}

                                            >
                                                {this.fillType(this.props.homePageContent.homePageType)}

                                            </Select>
                                            <FormHelperText>{this.props.errors.subType ? this.props.errors.subType : " "}</FormHelperText>
                                        </FormControl>
                                    </Box>


                                    <Grid item xs={5} pl={3.75} >
                                        <Box className={classes.eventDateItem}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker disabled={this.props.homePageContent.homePageType === homePageType.Banner || (this.props.homePageContent.subType !== "2" && this.props.homePageContent.subType !== 2) ? true : false}
                                                    renderInput={(param) => <TextField
                                                        {...param}
                                                        helperText={this.props.errors.contentEventDate ? this.props.errors.contentEventDate : " "}
                                                        error={this.props.errors.contentEventDate}
                                                        onBlur={(event) => {
                                                            this.blurValidation('contentEventDate');

                                                        }}
                                                       
                                                    />
                                                    }
                                                    onChange={(date) => {



                                                        this.handleChange(homePageContent => {
                                                            homePageContent.contentEventDate = date
                                                        })

                                                    }}
                                                    
                                                    value={this.props.homePageContent.contentEventDate}
                                                    variant="inline"
                                                    name="eventDate"
                                                    inputFormat="yyyy/MM/dd"
                                                    label="Event Date"
                                                    disableMaskedInput
                                                    margin="normal"
                                                    id="eventDate"
                                                    
                                                    
                                                /></LocalizationProvider>
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={0} justifyContent="space-between" mt={4.5} >
                                    <Grid item xs={9} pr={1.5} >


                                        <TextField className={classes.formControl} label="*Title" variant="standard" value={this.props.homePageContent.title}
                                            error={this.props.errors.title}
                                            helperText={this.props.errors.title ? this.props.errors.title : " "}
                                            onChange={(event) => {
                                                this.handleChange(homePageContent => homePageContent.title = event.target.value)
                                            }}

                                            inputProps={{ maxLength: 50 }}

                                            onBlur={(event) => {
                                                this.blurValidation('title');

                                            }}
                                        >


                                        </TextField>
                                    </Grid>
                                    <Grid item xs={3} pl={5.25} >
                                        
                                        <Box className={classes.expirationItem}>  <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                               renderInput={(param) => <TextField
                                                    {...param}
                                                    helperText={this.props.errors.expiryDate ? this.props.errors.expiryDate : ' '}
                                                    error={this.props.errors.expiryDate ? true : false}

                                                    onBlur={(date) => {

                                                        this.blurValidation('expiryDate');
                                                    }}


                                                />
                                                }

                                                variant="inline"
                                                name="expiration"
                                                inputFormat="yyyy/MM/dd"
                                                label="Expiration"
                                                disableMaskedInput
                                                margin="normal"
                                                id="eventDate"
                                                onChange={(date) => {
                                                    this.handleChange(homePageContent => {
                                                        homePageContent.expiryDate = date
                                                    })
                                                    
                                                }}
                                                value={this.props.homePageContent.expiryDate}
                                                onClose={() => {
                                                    this.blurValidation('expiryDate');
                                                }}
                                            /></LocalizationProvider></Box>
                                    </Grid>
                                </Grid>



                                <Grid container justifyContent="space-between" mt={4.7}>
                                    <Box className={classes.clickPromptItem}>
                                        <FormControl className={classes.formControl} disabled={this.props.homePageContent.homePageType === homePageType.Feed ? true : false} value="Standard" variant="standard"
                                            error={this.props.errors.detailDisplayType}
                                        >
                                            <InputLabel id="dataSource">Click Prompt*</InputLabel>
                                            <Select variant="standard" MenuProps={{ disableScrollLock: true }}
                                                id="ClickPrompt"
                                                value={this.props.homePageContent.detailDisplayType}
                                                name="ClickPrompt"
                                                onChange={(event) => {
                                                    this.handleChange(homePageContent => {
                                                        homePageContent.detailDisplayType = event.target.value
                                                    })

                                                }}

                                                onBlur={(event) => {
                                                    this.blurValidation('detailDisplayType');

                                                }}

                                            >
                                                {this.fillDetailTypeLIst()}

                                            </Select>
                                            <FormHelperText>{this.props.errors.detailDisplayType ? this.props.errors.detailDisplayType : " "}</FormHelperText>
                                        </FormControl>
                                    </Box>
                                    <Grid item xs pl={3.75}>
                                        <Box pt={2.3} className={classes.fileUploadBox}>
                                            <FileUpload disabled={this.props.homePageContent.homePageType === homePageType.Banner || (this.props.homePageContent.subType === contentTypes.Event.toString() || this.props.homePageContent.subType === contentTypes.Event) ? true : false} onFileUploadChange={this.handleFileUploadChange} fileName={this.props.homePageContent.thumbnailImageName ? this.props.homePageContent.thumbnailImageName : ""} placeholder="our-Logo.png" accept=".png" isHomePage={true} />
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Grid container justifyContent="space-between" mt={7.5}>
                                    <Grid item xs={8} >
                                        <TextField disabled={this.props.homePageContent.homePageType === homePageType.Banner ? true : false}
                                            helperText={this.props.errors.summary ? this.props.errors.summary : " "}
                                            error={this.props.errors.summary}
                                            label="Summary"
                                            size="small"
                                            multiline
                                            rows={5}
                                            fullWidth={true}
                                            value={this.props.homePageContent.summary}
                                            onChange={(event) => {
                                                this.handleChange(homePageContent => {
                                                    homePageContent.summary = event.target.value
                                                })

                                            }}
                                            inputProps={{ maxLength: 4000 }}
                                            >
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={4} >
                                    </Grid>


                                </Grid>


                            </Grid>

                            <Grid item xs={5} pl={4.88} pt={6.5}  >



                                <Box className={classes.roleItem}>
                                    <Autocomplete multiple
                                        id="dataViewRole"
                                        loading={this.props.roles.requestStatus === actionTypes.VGO_GET_ROLES_REQUEST}
                                        onChange={this.onRolesChange}
                                        onBlur={(event) => {
                                            this.blurValidation('roles');

                                        }}
                                        size="small"
                                        fullWidth={true}
                                        options={this.props.roles.list}
                                        getOptionLabel={option => option.roleName}
                                        isOptionEqualToValue={
                                            (option, value) => option.roleName === value.roleName}
                                        value={this.props.homePageContent.roles}
                                        renderInput={params => (
                                            <TextField {...params} variant="outlined"
                                                error={this.props.errors.roles} helperText={this.props.errors.roles ? this.props.errors.roles : ' '}
                                                required
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>

                                                        </React.Fragment>
                                                    ),
                                                }}
                                                size="medium"
                                                label="Roles"
                                            />
                                        )}
                                    />
                                </Box>


                            </Grid>


                        </Grid>

                        <Grid container mt={8}>
                            <Grid item xs={12} >

                                <FormControl error={this.state.messageError} >
                                    <RichTextEditor height={541}
                                        onChange={this.handleEditorData}
                                        data={this.props.homePageContent.content}
                                        onBlur={this.handleEditor}
                                    />

                                    <FormHelperText>{this.state.messageHelper}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Card >

        );

    }

}



/**
 * mapStateToProps
 * @param {*} state 
 */
function mapStateToProps(state) {
    return {
        homePageContent: state.homePage.homePageContent,
        isLoading: state.loader.isLoading,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage,
        roles: { requestStatus: state.roles.getRoleListRequestStatus, list: state.dataViews.roles },
        errors: state.homePage.errors ? state.homePage.errors : {},
    };
};

export default withRouter(connect(mapStateToProps)(withStyles(homePageTheme)(HomePageEdit)));
