import * as enumerator from 'client/components/Common/Enum';
import { customColorPalette } from 'common/theme.js';
import { getChartAxesLabelStyle } from './theme/chartTheme';
import { formatValue } from 'client/components/Common/Utility';
import * as Enums from 'client/components/Common/Enum';

let yScaleCount = {};
let yScaleColumnName;
let yAxesTitle = '';
let yAxesScales = [];
let axisValues = {
    xValues: [],
    yValues: []
}
let multiYAxis = [];
const defaultSeriesColor = "#2196F3";

const getChartXAndYValues = (props, yAxis) => {
    let xValues = [];
    let yValues = [];
    const data = props.data;
    let xColumn = props.chartDetail.xColumn;
    const yColumn = yAxis.yColumn;
    let xCount = props.chartDetail.recordCountChart === 0 ? data.length : props.chartDetail.recordCountChart;
    let column = props.columns.filter((value, index) => value.fieldName === xColumn);
    const groupField = (!props.chartDetail.groupField) ? "" : props.chartDetail.groupField;
    if (groupField === "") {
        data.forEach((row, i) => {
            if (i < xCount) {
                const value = formatValue(column[0], row[xColumn])
                xValues.push((value) ? value : '');
                yValues.push(row[yColumn]);
            }
        });
    } else {
        xColumn = groupField;
        column = props.columns.filter((value, index) => value.fieldName === xColumn);
        Array.from(data.slice(0, xCount).reduce((m, item) => {
            const formatName = formatValue(column[0], item[groupField])
            const name = (formatName) ? formatName : '';
            const value = item[yColumn];
            return m.set(name, (m.get(name) || 0) + value)
        }, new Map
        ), ([name, value]) => {
            xValues.push(name);
            yValues.push(value);
        });
        multiYAxis.push(yValues);
    }
    axisValues.xValues = xValues;
    axisValues.yValues = yValues;
   
}

export const getChartXYValueData = (props, yAxis) => {
    const data = props.data;
    const xColumn = props.chartDetail.xColumn;
    const yColumn = yAxis.yColumn;
    const groupField = (!props.chartDetail.groupField) ? "" : props.chartDetail.groupField;
    const radius = (!yAxis.radius) ? "" : yAxis.radius;
    let xCount = props.chartDetail.recordCountChart === 0 ? data.length : props.chartDetail.recordCountChart;
    let xyValues = '';
    if (groupField === "") {
        data.forEach((row, i) => {
            if (i < xCount)
                xyValues += radius === ""
                    ? '{ "x" :' + row[xColumn] + ', "y" : ' + row[yColumn] + '},'
                    : '{ "x" :' + row[xColumn] + ', "y" : ' + row[yColumn] + ', "r" : ' + row[radius] + '},';
        });
        xyValues = xyValues.slice(0, -1);
    } else {
        let xValuesGroup = [];
        xValuesGroup = [...new Set(data.slice(0, xCount).map((row, i) => (row[groupField]) ? row[groupField] : 0))];

        xValuesGroup.forEach((xValue, j) => {
            let totalYValue = 0;
            let totalRadius =1;
            data.forEach((row, i) => {
                if (i < xCount) {
                    if (xValue === row[groupField]) {
                        totalYValue += row[yColumn];
                         totalRadius += row[radius];
                    }

                }
            });
            xyValues += radius === ""
                ? '{ "x" :' + xValue + ', "y" : ' + totalYValue + '},'
                : '{ "x" :' + xValue + ', "y" : ' + totalYValue + ', "r" : ' + totalRadius + '},';

        });
        xyValues = xyValues.slice(0, -1);
    }
    return JSON.parse("[" + xyValues + "]");
}

const setBackgroundColor = (canvas, props, yAxis) => {
    const groupField = props.chartDetail.groupField;
    let data;
    if (groupField === "") {
        data = props.data;
    } else {
        data = axisValues.yValues;
    }
    const yColumn = yAxis.yColumn;
    const kpiDetails = props.chartDetail.chartManager.kpiDetails;
    let backgroundColor = [];
    let hoverBackgroundColor = [];
    const ctx = canvas.getContext("2d");
    let gradient;

    let chartSeriesColor = seriesColorList()
    let j = -1;
    let checkRelation = false;
    data.forEach((row) => {
        if (j === colorCount)
            j = -1;
        j++
        if (kpiDetails.length > 0) {
            kpiDetails.forEach((kpi, i) => {
                gradient = (enumerator.ChartType.Bar === yAxis.chartType
                    ? ctx.createLinearGradient(700, 0, 0, 0)
                    : ctx.createLinearGradient(0, 0, 0, 700));

                let color = kpi.icon;
                gradient.addColorStop(0, color);
                gradient.addColorStop(1, '#ffffff');
                let value;
                if (groupField === "") {
                    value = row[yColumn];
                } else {
                    value = row;
                }
                switch (kpi.relationalOperator) {
                    case enumerator.KpiRelation.LessThan:
                        {
                            if (value < parseFloat(kpi.firstValue)) {
                                backgroundColor.push((yAxis.chartType === enumerator.ChartType.Pie || yAxis.chartType === enumerator.ChartType.Doughnut || yAxis.chartType === enumerator.ChartType.Bubble || yAxis.chartType === enumerator.ChartType.Scatter) ?
                                    color : gradient);
                                hoverBackgroundColor.push(color);
                                checkRelation = true;
                            }
                            break;
                        }
                    case enumerator.KpiRelation.GreaterThan:
                        {
                            if (value > parseFloat(kpi.firstValue)) {
                                backgroundColor.push((yAxis.chartType === enumerator.ChartType.Pie || yAxis.chartType === enumerator.ChartType.Doughnut || yAxis.chartType === enumerator.ChartType.Bubble || yAxis.chartType === enumerator.ChartType.Scatter) ?
                                    color : gradient);
                                hoverBackgroundColor.push(color);
                                checkRelation = true;
                            }
                            break;
                        }
                    case enumerator.KpiRelation.Between:
                        {
                            if (value >= parseFloat(kpi.firstValue) && value <= parseFloat(kpi.secondValue)) {
                                backgroundColor.push((yAxis.chartType === enumerator.ChartType.Pie || yAxis.chartType === enumerator.ChartType.Doughnut || yAxis.chartType === enumerator.ChartType.Bubble || yAxis.chartType === enumerator.ChartType.Scatter) ?
                                    color : gradient );
                                hoverBackgroundColor.push(color);
                                checkRelation = true;
                            }
                            break;
                        }
                    case enumerator.KpiRelation.EqualTo:
                        {
                            if (value === parseFloat(kpi.firstValue)) {
                                backgroundColor.push((yAxis.chartType === enumerator.ChartType.Pie || yAxis.chartType === enumerator.ChartType.Doughnut || yAxis.chartType === enumerator.ChartType.Bubble || yAxis.chartType === enumerator.ChartType.Scatter) ?
                                    color : gradient );
                                hoverBackgroundColor.push(color);
                                checkRelation = true;
                            }
                            break;
                        }
                    default:
                        {
                            backgroundColor.push((yAxis.chartType === enumerator.ChartType.Pie || yAxis.chartType === enumerator.ChartType.Doughnut || yAxis.chartType === enumerator.ChartType.Bubble || yAxis.chartType === enumerator.ChartType.Scatter) ?
                                color :gradient);
                            hoverBackgroundColor.push(color);
                            break;
                        }
                }

            });
            if (!checkRelation) {
                if (yAxis.chartType === enumerator.ChartType.Pie || yAxis.chartType === enumerator.ChartType.Doughnut) {
                    backgroundColor.push(chartSeriesColor[j])
                    hoverBackgroundColor.push(chartSeriesColor[j]);
                } else {
                    gradient = (enumerator.ChartType.Bar === yAxis.chartType
                        ? ctx.createLinearGradient(700, 0, 0, 0)
                        : ctx.createLinearGradient(0, 0, 0, 700));
                    const defaultColor = (yAxis.seriesColor) ? yAxis.seriesColor : defaultSeriesColor
                    gradient.addColorStop(0, defaultColor);
                    gradient.addColorStop(1, '#ffffff');
                    if (yAxis.chartType === enumerator.ChartType.Bubble || yAxis.chartType === enumerator.ChartType.Scatter ||
                        yAxis.chartType === enumerator.ChartType.Radar || yAxis.chartType === enumerator.ChartType.Polar) {
                        backgroundColor.push( defaultColor);
                        hoverBackgroundColor.push(defaultColor);
                    } else {
                        backgroundColor.push(gradient);
                        hoverBackgroundColor.push(defaultColor);
                    }
                }
            }
            checkRelation = false;
        } else {
            if (yAxis.chartType === enumerator.ChartType.Pie || yAxis.chartType === enumerator.ChartType.Doughnut) {
                backgroundColor.push(chartSeriesColor[j])
                hoverBackgroundColor.push(chartSeriesColor[j]);
            } else {
                gradient = (enumerator.ChartType.Bar === yAxis.chartType
                    ? ctx.createLinearGradient(700, 0, 0, 0)
                    : ctx.createLinearGradient(0, 0, 0, 700));
                const defaultColor = (yAxis.seriesColor) ? yAxis.seriesColor : defaultSeriesColor
                gradient.addColorStop(0, defaultColor);
                gradient.addColorStop(1, '#ffffff');
                if (yAxis.chartType === enumerator.ChartType.Bubble || yAxis.chartType === enumerator.ChartType.Scatter ||
                    yAxis.chartType === enumerator.ChartType.Radar || yAxis.chartType === enumerator.ChartType.Polar) {
                    backgroundColor.push(defaultColor );
                    hoverBackgroundColor.push(defaultColor);
                } else {
                    backgroundColor.push(gradient);
                    hoverBackgroundColor.push(defaultColor);
                }
            }
        }

    });
    return {
        backgroundColor: backgroundColor,
        hoverBackgroundColor: hoverBackgroundColor
    };
}

export const colorCount = 10;

export const seriesColorList = () => {
    return ["#78F8FA", "#7EC1FF", "#8379F6", "#9A43DD", "#F46CE6", "#FC6FB1", "#F1716E", "#EBA766", "#E7EE73", "#B6FF7D", "#75FD84"];
}

export const chartOptions = (chart, chartType, deviceType) => {
    const chartAxesLabelStyle = getChartAxesLabelStyle(deviceType);
    loadYAxes(chart, chartType, deviceType);
    return {
        maintainAspectRatio: false,
        responsive: true,
        legend: {
            display: true,
            labels: {
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: 14,
                fontStyle: "normal",
                letterSpacing: 0.25,
                usePointStyle: true,
            },
        },
        title: {
            display: true,
            text: chart.props.chartDetail.chartTitle,
            fontSize: 16,
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 400,
            letterSpacing: 0.15,
            fontColor: `${customColorPalette.black[58]} !important`,
        },
        tooltips: {
            enabled: false,
            mode: "point",
            intersect: false,
            custom: (tooltipModel) => {

                // Tooltip Element
                let tooltipEl = document.getElementById('chartjs-tooltip');
                // Create element on first render
                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjs-tooltip';
                    tooltipEl.className = 'chartjs-tooltip';
                    tooltipEl.innerHTML = "<table></table>"
                    document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }

                const groupField = (!chart.props.chartDetail.groupField) ? '' : chart.props.chartDetail.groupField;
                const yAxes = chart.props.chartDetail.chartYAxes[tooltipModel.dataPoints[0].datasetIndex];
                if (!yAxes.toolTip)
                    return;
                const fieldName = (groupField === '') ? yAxes.toolTip : yAxes.yColumn;
                const tooltipName = (!fieldName) ? '' : fieldName;
                if (!tooltipName)
                    return;
                const seriesColor = chart.props.chartDetail.chartYAxes[tooltipModel.dataPoints[0].datasetIndex].seriesColor;
                const labelColor = (seriesColor) ? seriesColor : defaultSeriesColor

                // Set caret Position
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                    tooltipEl.classList.add('no-transform');
                }

                function getBody(bodyItem) {
                    return bodyItem.lines;
                }

                const column = chart.props.columns.filter((value, index) => value.fieldName === tooltipName);
                let tooltipValue = '';
                // Set Text
                if (tooltipModel.body) {
                    let bodyLines = tooltipModel.body.map(getBody);

                    let currentValue = "";
                    if (groupField === "") {
                        currentValue = formatValue(column[0], chart.props.data[tooltipModel.dataPoints[0].index][tooltipName]);
                    } else {
                        let yValue = multiYAxis[tooltipModel.dataPoints[0].datasetIndex];
                        currentValue = formatValue(column[0], yValue[tooltipModel.dataPoints[0].index]);
                    }
                    tooltipValue = (currentValue) ? currentValue : "";

                    var transForm = 'translate(0, -120%)';
                    let innerHtml = '<tbody>';

                    transForm = 'translate(-50%, -120%)';
                    innerHtml += '<tr><td>' + tooltipValue + '</td></tr>';
                    innerHtml += '</tbody>';

                    let tableRoot = tooltipEl.querySelector('table');
                    tableRoot.innerHTML = innerHtml;
                }
                let topCanvas = 203;
                let leftCanvas = 288

                let y_axis, doc = document, ele = doc.documentElement;
                y_axis = ele.scrollTop || 0;

                if (chart.refs.chart) {
                    // `this` will be the overall tooltip
                    const canvas = chart.refs.chart.chartInstance.chart.canvas.getBoundingClientRect();
                    //topCanvas = canvas.top;
                    topCanvas = canvas.top + y_axis;
                    leftCanvas = canvas.left;
                }
                // Display, position, and set styles for font
                tooltipEl.style.opacity = 1;
                tooltipEl.style.left = leftCanvas + tooltipModel.caretX + 'px';
                tooltipEl.style.top = topCanvas + tooltipModel.caretY + 'px';
                tooltipEl.style.padding = "2px 10px";
                tooltipEl.style.border = (tooltipValue) ? "solid 1px #ffffff" : "solid 0px #ffffff";
                tooltipEl.style.zIndex = "100000";
                tooltipEl.style.backgroundColor = (tooltipValue) ? '#ffffff' : "";
                tooltipEl.style.fontSize = '14px';
                tooltipEl.style.fontFamily = "Roboto";
                tooltipEl.style.fontStyle = "normal";
                tooltipEl.style.fontWeight = "500";
                tooltipEl.style.letterSpacing = "0.1px";
                tooltipEl.style.color = labelColor;
                tooltipEl.style.transform = transForm;
            }
        },
        scales: {
            yAxes: yAxesScales,
            xAxes: [
                {
                    stacked: chart.props.chartDetail.isStacked,
                    display: (chartType === enumerator.ChartType.Pie || chartType === enumerator.ChartType.Doughnut || chartType === enumerator.ChartType.Radar || chartType === enumerator.ChartType.Polar) ? false : true,
                    scaleLabel: {
                        display: true,
                        labelString: enumerator.ChartType.Bar === chartType ? yAxesTitle : chart.props.chartDetail.xText,
                        ...chartAxesLabelStyle,
                    },
                    ticks: {
                        autoSkip: false,
                        ...chartAxesLabelStyle,
                        callback: function (value, index, values) {
                            if (chartType === enumerator.ChartType.Bar) {
                                const yColumn = chart.props.chartDetail.chartYAxes[0].yColumn;
                                const column = chart.props.columns.filter((value, index) => value.fieldName === yColumn);
                                return formatValue(column[0], value);
                            } else {
                                if (chartType === enumerator.ChartType.Bubble || chartType === enumerator.ChartType.Scatter) {
                                    const groupField = (!chart.props.chartDetail.groupField) ? "" : chart.props.chartDetail.groupField;
                                    const xColumn = (!groupField) ? chart.props.chartDetail.xColumn : groupField;
                                    const column = chart.props.columns.filter((value, index) => value.fieldName === xColumn);
                                    return formatValue(column[0], value);
                                } else
                                    return value;
                            }
                        }
                    }
                }
            ]
        }
    }

}

export const chartPlugins = (props, deviceType) => {
    let fontDetails = '14px Roboto';
    if (deviceType === Enums.DeviceType.Mobile) {
        fontDetails = '10px Roboto';
    }
    else if (deviceType === Enums.DeviceType.Tablet) {
        fontDetails = '12px Roboto';
    }
    return {
        afterDraw: (chartInstance, easing) => {
            let chart = chartInstance.chart;
            let ctx = chart.ctx;
            const yAxes = props.chartDetail.chartYAxes;
            const columns = props.columns;
            const groupField = (!props.chartDetail.groupField) ? '' : props.chartDetail.groupField;
            chart.data.datasets.forEach((dataset, i) => {
                if (!yAxes[i].label)
                    return;
                const fieldName = (groupField === '') ? yAxes[i].label : yAxes[i].yColumn;
                let labelName = (!fieldName) ? '' : fieldName;
                let chartType = yAxes[i].chartType;
                const column = columns.filter((value, index) => value.fieldName === labelName);
                let meta = chart.getDatasetMeta(i);
                if (!meta.hidden) {
                    meta.data.forEach((element, index, elements) => {
                        // Draw the text in black, with the specified font
                        ctx.fillStyle = '#4B6074';
                        ctx.font = fontDetails;
                        //ctx.fillStyle = dataset.labelColor[index];

                        let fontSize = 11;
                        let dataDetails = [];
                        dataDetails = dataset.gridData;
                        let dataString = "";
                        if (groupField === "") {
                            dataDetails = dataset.gridData;
                            dataString = labelName === "" ? "" : formatValue(column[0], dataDetails[index][labelName]);
                        } else {
                            dataDetails = dataset.data;
                            let value = dataDetails[index];
                            if (chartType === enumerator.ChartType.Bubble || chartType === enumerator.ChartType.Scatter) {
                                value = dataDetails[index].y;
                            }
                            dataString = labelName === "" ? "" : formatValue(column[0], value);
                        }
                        if (element.hidden) {
                            dataString = "";
                        }


                        let xPoint;
                        let yPoint;
                        if (chartType === enumerator.ChartType.Polar) {
                            ctx.textAlign = 'right';
                            ctx.textBaseline = 'bottom';
                            let angle = ((element._model.startAngle) + (element._model.endAngle)) / 2;
                            xPoint = (parseFloat(element._model.outerRadius) + 20) * (Math.cos(angle)) + (element._model.x);
                            yPoint = (parseFloat(element._model.outerRadius) + 20) * (Math.sin(angle)) + (element._model.y);
                            if (yPoint >= chart.chartArea.bottom - 10)
                                yPoint = yPoint - 30;

                        } else {
                            ctx.textAlign = 'center';
                            ctx.textBaseline = 'middle';
                            let paddingY = 5;
                            let paddingX = 0;
                            let position = element.tooltipPosition();
                            if (chartType === enumerator.ChartType.Bar) {
                                let barRight = (String(dataString).length) + element._model.x;
                                let barLeft = element._model.x - (String(dataString).length);
                                if (barRight > chart.chartArea.right - 100) {
                                    paddingX = -(chart.chartArea.right - element._model.x);
                                    ctx.textAlign = 'right';
                                }
                                else if (barLeft - 100 < chart.chartArea.left) {
                                    paddingX = element._model.x - chart.chartArea.left;
                                    ctx.textAlign = 'left';
                                }
                                paddingY = -5;

                            }
                            else if (chartType === enumerator.ChartType.Line || chartType === enumerator.ChartType.Area || chartType === enumerator.ChartType.Bubble || chartType === enumerator.ChartType.Scatter || chartType === enumerator.ChartType.Column || chartType === enumerator.ChartType.Radar) {
                                let middle = ((elements.length - 1) / 3);
                                if (index < middle) {
                                    ctx.textAlign = 'left';
                                    paddingX = (chartType === enumerator.ChartType.Column) ? element._model.width / 2 : 0;
                                } else if (index >= middle && index <= middle * 2) {
                                    ctx.textAlign = 'center';
                                }
                                else if (index > middle) {
                                    ctx.textAlign = 'right';
                                    paddingX = (chartType === enumerator.ChartType.Column) ? -(element._model.width / 2) : 0;
                                }

                                if (element._model.y - 10 <= chart.chartArea.top) {
                                    paddingY = -15;
                                }

                            }

                            xPoint = position.x - paddingX;
                            yPoint = position.y - (fontSize / 2) - paddingY;


                        }
                        ctx.fillText((dataString) ? dataString : '', xPoint, yPoint);


                    });
                }
            });
        }
    }
}

const loadYAxes = (chart, chartType, deviceType) => {
    yAxesTitle = '';
    yAxesScales = [];
    let xText = chart.props.chartDetail.xText;
    chart.props.chartDetail.chartYAxes.forEach((yAxis, i) => {
        let chartType = yAxis.chartType;
        let yText = yAxis.yText;
        yAxesTitle += yText + " * ";
        if (!chart.props.chartDetail.useDefaultYScale) {
            if (chartType === enumerator.ChartType.Bar) {
                if (i === 0) {
                    yAxesScales.push(loadYAxesScales(chartType, yText, xText, i, chart.props, deviceType));
                }
            } else {
                yAxesScales.push(loadYAxesScales(chartType, yText, xText, i, chart.props, deviceType));
            }
        }
    });

    yAxesTitle = yAxesTitle.substring(0, yAxesTitle.length - 2);
    if (chart.props.chartDetail.useDefaultYScale) {
        let count = 0;
        yAxesScales.push(loadYAxesScales(chartType, yAxesTitle, xText, count, chart.props, deviceType));
    }
}

const loadYAxesScales = (chartType, yText, xText, count, chart, deviceType) => {
    const chartAxesLabelStyle = getChartAxesLabelStyle(deviceType);
    yScaleCount[`chart_${chart.chartDetail.chartId}`] = chart.chartDetail.chartYAxes.length;
    const isStacked = chart.chartDetail.isStacked;
    const useDefaultYScale = chart.chartDetail.useDefaultYScale;
    const id = (useDefaultYScale) ? 'y-axis' : (chartType === enumerator.ChartType.Bar && count === 0) ? 'y-axis' : 'y-axis-' + count;
    return {
        stacked: isStacked,
        id: id,
        display: (chartType === enumerator.ChartType.Pie ||
            chartType === enumerator.ChartType.Doughnut ||
            chartType === enumerator.ChartType.Radar ||
            chartType === enumerator.ChartType.Polar)
            ? false
            : true,
        scaleLabel: {
            display: true,
            labelString: enumerator.ChartType.Bar === chartType
                ? xText
                : yText,
            ...chartAxesLabelStyle,
        },
        ticks: {
            suggestedMin: 0,
            ...chartAxesLabelStyle,
            callback: chart.chartDetail.chartYAxes.length === 1 ? function (value, index, values) {
                if (chartType === enumerator.ChartType.Bar)
                    return value;

                const column = chart.columns.filter((value, index) => value.fieldName === chart.chartDetail.chartYAxes[0].yColumn);
                return formatValue(column[0], value);
            } :
                function (value, index, values) {
                    if (chartType === enumerator.ChartType.Bar)
                        return value;
                    if (index === 0) {
                        let yScaleCountVal = yScaleCount[`chart_${chart.chartDetail.chartId}`];
                        yScaleCountVal--;
                        yScaleColumnName = chart.chartDetail.chartYAxes[yScaleCountVal].yColumn;
                        yScaleCount[`chart_${chart.chartDetail.chartId}`] = yScaleCountVal
                    }
                    else if (index === values.length - 1) {
                        let yScaleCountVal = yScaleCount[`chart_${chart.chartDetail.chartId}`];
                        yScaleCountVal = (yScaleCountVal === 0) ? chart.chartDetail.chartYAxes.length : yScaleCountVal;
                        yScaleCount[`chart_${chart.chartDetail.chartId}`] = yScaleCountVal
                    }
                    const column = chart.columns.filter((value, index) => value.fieldName === yScaleColumnName);
                    return formatValue(column[0], value);
                }
        }
    };
}

export const loadDataSet = (canvas, props) => {
    let dataSet = [];
    const chartYAxes = props.chartDetail.chartYAxes;
    multiYAxis = [];
    chartYAxes.forEach((yAxis, i) => {
        getChartXAndYValues(props, yAxis);

        switch (yAxis.chartType) {
            case enumerator.ChartType.Column:
                dataSet.push(loadColumnDataSet(canvas, props, yAxis, i))
                break;
            case enumerator.ChartType.Bar:
                dataSet.push(loadBarDataSet(canvas, props, yAxis, i, chartYAxes.length))
                break;
            case enumerator.ChartType.Line:
                dataSet.push(loadLineDataSet(canvas, props, yAxis, i))
                break;
            case enumerator.ChartType.Area:
                dataSet.push(loadAreaDataSet(canvas, props, yAxis, i))
                break;
            case enumerator.ChartType.Pie:
                dataSet.push(loadPieDataSet(canvas, props, yAxis, i))
                break;
            case enumerator.ChartType.Doughnut:
                dataSet.push(loadDoughnutDataSet(canvas, props, yAxis, i))
                break;
            case enumerator.ChartType.Radar:
                dataSet.push(loadRadarDataSet(canvas, props, yAxis, i))
                break;
            case enumerator.ChartType.Polar:
                dataSet.push(loadPolarDataSet(canvas, props, yAxis, i))
                break;
            case enumerator.ChartType.Scatter:
                dataSet.push(loadScatterDataSet(canvas, props, yAxis, i))
                break;
            case enumerator.ChartType.Bubble:
                dataSet.push(loadBubbleDataSet(canvas, props, yAxis, i))
                break;
            default:
                dataSet.push()
        }
    });
    if (chartYAxes[0].chartType === enumerator.ChartType.Bubble || chartYAxes[0].chartType === enumerator.ChartType.Scatter) {
        return dataSet;
    } else {
        return {
            labels: axisValues.xValues,
            datasets: dataSet
        }
    }
}

const loadBarDataSet = (canvas, props, yAxis, i, count) => {
    const color = setBackgroundColor(canvas, props, yAxis);
    return {
        label: (!yAxis.legendText) ? '' : yAxis.legendText,
        backgroundColor: color.backgroundColor,
        borderColor: color.backgroundColor,
        borderWidth: 1,
        hoverBackgroundColor: color.hoverBackgroundColor,
        hoverBorderColor: color.hoverBackgroundColor,
        data: axisValues.yValues,
        gridData: props.data,
        labelColor: color.hoverBackgroundColor,
        yAxisID: 'y-axis',
        type: 'horizontalBar'
    }
}
const loadColumnDataSet = (canvas, props, yAxis, i) => {
    const useDefaultYScale = props.chartDetail.useDefaultYScale;
    const color = setBackgroundColor(canvas, props, yAxis);
    return {
        label: (!yAxis.legendText) ? '' : yAxis.legendText,
        backgroundColor: color.backgroundColor,
        borderColor: color.backgroundColor,
        borderWidth: 1,
        hoverBackgroundColor: color.hoverBackgroundColor,
        hoverBorderColor: color.hoverBackgroundColor,
        data: axisValues.yValues,
        gridData: props.data,
        labelColor: color.hoverBackgroundColor,
        yAxisID: (useDefaultYScale) ? 'y-axis' : 'y-axis-' + i,
        type: enumerator.ChartType.Bar
    }
}

const loadLineDataSet = (canvas, props, yAxis, i) => {
    const useDefaultYScale = props.chartDetail.useDefaultYScale;
    const color = setBackgroundColor(canvas, props, yAxis);
    return {
        label: (!yAxis.legendText) ? '' : yAxis.legendText,
        fill: false,
        data: axisValues.yValues,
        borderColor: (yAxis.seriesColor) ? yAxis.seriesColor : defaultSeriesColor,
        borderWidth: 1,
        pointBackgroundColor: color.hoverBackgroundColor,
        gridData: props.data,
        labelColor: color.hoverBackgroundColor,
        type: enumerator.ChartType.Line,
        yAxisID: (useDefaultYScale) ? 'y-axis' : 'y-axis-' + i
    }
}
const loadAreaDataSet = (canvas, props, yAxis, i) => {
    const useDefaultYScale = props.chartDetail.useDefaultYScale;
    const ctx = canvas.getContext("2d");
    var gradientArea = ctx.createLinearGradient(0, 0, 0, 700);
    gradientArea.addColorStop(0, (yAxis.seriesColor) ? yAxis.seriesColor : defaultSeriesColor);
    gradientArea.addColorStop(1, '#ffffff');
    const color = setBackgroundColor(canvas, props, yAxis);
    return {
        label: (!yAxis.legendText) ? '' : yAxis.legendText,
        fill: true,
        data: axisValues.yValues,
        borderColor: (yAxis.seriesColor) ? yAxis.seriesColor : defaultSeriesColor,
        borderWidth: 1,
        backgroundColor: gradientArea,
        pointBackgroundColor: color.hoverBackgroundColor,
        gridData: props.data,
        labelColor: color.hoverBackgroundColor,
        type: enumerator.ChartType.Line,
        yAxisID: (useDefaultYScale) ? 'y-axis' : 'y-axis-' + i
    }
}
const loadPieDataSet = (canvas, props, yAxis, i) => {
    const color = setBackgroundColor(canvas, props, yAxis);
    return {
        label: (!yAxis.legendText) ? '' : yAxis.legendText,
        backgroundColor: color.hoverBackgroundColor,
        borderWidth: 1,
        data: axisValues.yValues,
        gridData: props.data,
        labelColor: color.hoverBackgroundColor,
        type: enumerator.ChartType.Pie,
        yAxisID: 'y-axis-' + i
    }
}
const loadDoughnutDataSet = (canvas, props, yAxis, i) => {
    const color = setBackgroundColor(canvas, props, yAxis);
    return {
        label: (!yAxis.legendText) ? '' : yAxis.legendText,
        backgroundColor: color.backgroundColor,
        borderWidth: 1,
        data: axisValues.yValues,
        gridData: props.data,
        labelColor: color.hoverBackgroundColor,
        type: enumerator.ChartType.Doughnut,
        yAxisID: 'y-axis-' + i

    }
}
const loadRadarDataSet = (canvas, props, yAxis, i) => {
    const color = setBackgroundColor(canvas, props, yAxis);
    return {
        label: (!yAxis.legendText) ? '' : yAxis.legendText,
        backgroundColor: (yAxis.seriesColor) ? yAxis.seriesColor : defaultSeriesColor,
        borderColor: (yAxis.seriesColor) ? yAxis.seriesColor : defaultSeriesColor,
        pointBackgroundColor: color.backgroundColor,
        pointBorderColor: color.backgroundColor,
        data: axisValues.yValues,
        gridData: props.data,
        labelColor: color.hoverBackgroundColor,
        type: enumerator.ChartType.Radar,
        yAxisID: 'y-axis-' + i

    }
}
const loadPolarDataSet = (canvas, props, yAxis, i) => {
    const color = setBackgroundColor(canvas, props, yAxis);
    return {
        label: (!yAxis.legendText) ? '' : yAxis.legendText,
        backgroundColor: color.backgroundColor,
        data: axisValues.yValues,
        gridData: props.data,
        labelColor: color.hoverBackgroundColor,
        yAxisID: 'y-axis-' + i
        //type: enumerator.ChartType.Radar

    }
}
const loadScatterDataSet = (canvas, props, yAxis, i) => {
    const useDefaultYScale = props.chartDetail.useDefaultYScale;
    const color = setBackgroundColor(canvas, props, yAxis);
    return {
        label: (!yAxis.legendText) ? '' : yAxis.legendText,
        backgroundColor: color.backgroundColor,
        pointHoverRadius: 5,
        pointRadius: 2,
        data: getChartXYValueData(props, yAxis),
        gridData: props.data,
        labelColor: color.hoverBackgroundColor,
        type: enumerator.ChartType.Scatter,
        yAxisID: (useDefaultYScale) ? 'y-axis' : 'y-axis-' + i
    }
}
const loadBubbleDataSet = (canvas, props, yAxis, i) => {
    const useDefaultYScale = props.chartDetail.useDefaultYScale;
    const color = setBackgroundColor(canvas, props, yAxis);
    return {
        label: (!yAxis.legendText) ? '' : yAxis.legendText,
        backgroundColor: color.backgroundColor,
        borderColor: color.backgroundColor,
        data: getChartXYValueData(props, yAxis),
        gridData: props.data,
        labelColor: color.hoverBackgroundColor,
        type: enumerator.ChartType.Bubble,
        yAxisID: (useDefaultYScale) ? 'y-axis' : 'y-axis-' + i

    }
}