import React, { useState } from 'react';
import DisplayEvents from './DisplayEvents';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';

export default function SchedulerFooter  ( props)  {
    const [showDisplayDialog, setShowDisplay] = useState(false); 
    const handleDisplayClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        setShowDisplay(false);
        props.setOpenMore(false);
    }
    const showDisplayEvents = (eventData) => {
        props.setOpenMore(true);
        setShowDisplay(true);
    } 
    return (<div className="quick-info-footer">
        <div className="event-footer">
            <ButtonComponent id="close" cssClass='e-flat' content="Close" onClick={(e) => props.closeQuickInfoPopup()}/>
            <ButtonComponent id="more-details" cssClass='e-flat' content="More Details" isPrimary={true} onClick={(e) => showDisplayEvents(e)} />
        </div>
        <DisplayEvents handleAlertHandlerMessage={props.handleAlertHandlerMessage} setOpen={showDisplayDialog} handleClose={handleDisplayClose} seletedEventId={props.rowId} data={props.schedulerdata} dataDefinition={props.definition} pushActionLinkToHistory={props.pushActionLinkToHistory} />
    </div>);
   
}
