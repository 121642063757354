import { ActionTypes as types } from './actionTypes';
import adminApi from 'admin/api/adminApi';
import mockApi from 'admin/api/mockApi';
import { showError } from './alertActions';
import { getError } from './errorActions';
import settings from 'settings.js';
import cloneDeep from 'lodash/cloneDeep';


/**
 * reads mock data setting from settings
 */
const useMocks = settings.useMocks;

/**
 * api in use, mock or real
 */
const api = useMocks ? mockApi : adminApi;

/**
 * Invokes the mock or real web api to get all filters
 */

const apiDataViewSubRecordFields = (id) => {
    return api.getDataViewRecordFields(id);
};

const dataViewFieldsLinkUpdate = (rowIndex, colIndex, value, subRecord) => {
    const linkData = subRecord.mappingFields;
    const linkDataCopy = Object.assign([], linkData);
    const editObject = linkDataCopy[rowIndex];
    if (editObject != null) {
        const fieldLink = {
            parentFieldName: editObject.parentFieldName,
            childFieldName: editObject.childFieldName,
            childObjectId: editObject.childObjectId,
            id: editObject.id,
            parentField: editObject.parentField,
            childField: editObject.childField,
            error: editObject.error,
            helperText: editObject.helperText,
        };
        if (colIndex === 0) {
            fieldLink.parentFieldName = value;
        } else if (colIndex === 1) {
            fieldLink.childFieldName = value;
        }
        linkDataCopy[rowIndex] = fieldLink;
    }
    let lastObject = linkDataCopy[linkDataCopy.length - 1];
    if (lastObject == null || (lastObject.childFieldName !== '' && lastObject.parentFieldName !== '')) {
        const fieldLink = {
            parentFieldName: '',
            childFieldName: '',
            childObjectId: 0,
            id: 0,
            error: false,
            helperText: ''
        };
        linkDataCopy.push(fieldLink);
    }

    const subRecordCopy = Object.assign({}, subRecord);
    if (linkDataCopy) {
        subRecordCopy.mappingFields = linkDataCopy
    }
    return subRecordCopy
};

const validationMessageUpdate = (rowIndex, message, subRecord) => {
    const linkData = subRecord.mappingFields;
    const linkDataCopy = Object.assign([], linkData);
    const editObject = linkDataCopy[rowIndex];
    if (editObject != null) {
        const fieldLink = {
            parentFieldName: editObject.parentFieldName,
            childFieldName: editObject.childFieldName,
            childObjectId: editObject.childObjectId,
            id: editObject.id,
            parentField: editObject.parentField,
            childField: editObject.childField,
            error: (message) ? true : false,
            helperText: message,
        };
        linkDataCopy[rowIndex] = fieldLink;
    }
    const subRecordCopy = Object.assign({}, subRecord);
    if (linkDataCopy) {
        subRecordCopy.mappingFields = linkDataCopy
    }
    return subRecordCopy
}


const dataViewFieldsLinkDelete = (rowIndex, subRecord) => {
    const linkData = subRecord.mappingFields;
    const linkDataCopy = Object.assign([], linkData);
    linkDataCopy.splice(rowIndex, 1);

    const subRecordCopy = Object.assign({}, subRecord);
    if (linkDataCopy) {
        subRecordCopy.mappingFields = linkDataCopy
    }
    return subRecordCopy

}
const getDataViewsSubRecordFieldsRequest = () => {
    return {
        type: types.VGO_GET_DATAVIEW_SUBRECORD_FIELDS_REQUEST,
    }
};

const getDataViewSubRecordFieldsSuccess = (resp) => {
    return {
        type: types.VGO_GET_DATAVIEW_SUBRECORD_FIELDS_SUCCESS,
        subRecordFields: resp.data,
    };
};

const updateDataViewsFieldsLinkRequest = () => {
    return {
        type: types.VGO_UPDATE_DATAVIEW_SUBRECORD_FIELDS_LINK_REQUEST,
    }
};

const updateDataViewsFieldsLinkSuccess = (resp) => {

    return {
        type: types.VGO_UPDATE_DATAVIEW_SUBRECORD_FIELDS_LINK_SUCCESS,
        subRecord: resp,
    };
};

const dataViewSubRecordUpdateSuccess = (subRecord) => {
    return {
        type: types.VGO_UPDATE_DATAVIEW_SUBRECORD,
        subRecord: subRecord,
    };
};

const deleteDataViewsFieldsLinkRequest = () => {
    return {
        type: types.VGO_DELETE_DATAVIEW_SUBRECORD_FIELDS_LINK_REQUEST,
    }
};

const deleteDataViewsFieldsLinkSuccess = (resp) => {
    return {
        type: types.VGO_DELETE_DATAVIEW_SUBRECORD_FIELDS_LINK_SUCCESS,
        subRecord: resp,
    };
};

export const newSubRecord = () => {
    const subRecord = {
        childObjectId: 0,
        createDateTime: new Date(),
        id: 0,
        mappingFields: [{
            parentFieldName: '',
            childFieldName: '',
            childObjectId: 0,
            id: 0,
            error: false,
            helperText: ''
        }],
        reportId: 0,
        reportLink: [],
        reportName: '',
    };
    return subRecord;
};

export const resetSubRecord = (data, subRecordList) => {

    const subRecord = cloneDeep(subRecordList);
    if (subRecord) {
        if (subRecord.length > 0) {
            if (subRecord[0].mappingFields) {
            for (var i = 0; i < subRecord[0].mappingFields.length; i++) {

                const item = subRecord[0].mappingFields[i]
                var index = data.findIndex(d => d.fieldName === item.parentFieldName)
                if (index === -1) {
                    item.parentFieldName = ''
                    item.parentField = ''
                }
                }
            }
        }

    }

       return subRecord;
};

export const getDataViewSubRecordFields = (newsubRecord, subRecord) => {
    return dispatch => {
        const subRecordCopy = updateSubRecord(newsubRecord, subRecord);
        dispatch(updateDataViewsFieldsLinkSuccess(subRecordCopy));
        if (newsubRecord) {
            dispatch(dataViewSubRecordUpdateSuccess(subRecordCopy));

            if (newsubRecord.reportId) {
                dispatch(getDataViewsSubRecordFieldsRequest());
                return apiDataViewSubRecordFields(newsubRecord.reportId)
                    .then((resp) => {
                        dispatch(getDataViewSubRecordFieldsSuccess(resp));

                    })
                    .catch(err => {
                        const { message } = err;
                        dispatch(getError(message));
                        showError(dispatch, message, 5000);
                    });
            }
        }
    }
};

export const updateDataViewsFieldsLink = (rowIndex, colIndex, value, subRecord) => {
    return dispatch => {
        dispatch(updateDataViewsFieldsLinkRequest());
        const subRecordCopy = dataViewFieldsLinkUpdate(rowIndex, colIndex, value, subRecord);
        dispatch(updateDataViewsFieldsLinkSuccess(subRecordCopy));
        dispatch(dataViewSubRecordUpdateSuccess(subRecordCopy));
    }
}

export const updateValidationMessage = (rowIndex, message, subRecord) => {
    return dispatch => {
        dispatch(updateDataViewsFieldsLinkRequest());
        const subRecordCopy = validationMessageUpdate(rowIndex, message, subRecord);
        dispatch(updateDataViewsFieldsLinkSuccess(subRecordCopy));
        dispatch(dataViewSubRecordUpdateSuccess(subRecordCopy));
    }
}

export const deleteDataViewsFieldsLink = (rowIndex, subRecord) => {
    return dispatch => {
        dispatch(deleteDataViewsFieldsLinkRequest());
        const subRecordCopy = dataViewFieldsLinkDelete(rowIndex, subRecord);
        dispatch(deleteDataViewsFieldsLinkSuccess(subRecordCopy));
        dispatch(dataViewSubRecordUpdateSuccess(subRecordCopy));
    }
}

const getDataViewSubRecordSuccess = (resp) => {

    return {
        type: types.VGO_GET_DATAVIEW_SUBRECORD,
        subRecord: resp,
    };
}

export const addNewToSubRecord = (subRecord) => {
    let subRecordCopy = [];
    if (subRecord === undefined || subRecord.length === 0)
        subRecordCopy = newSubRecord();
    else {
        const fieldLink = {
            mappingField: '',
            childFieldName: '',
            childObjectId: 0,
            id: 0,
            error: false,
            helperText: ''
        };
        let newMappingField = [];
        subRecordCopy = { ...subRecord[0] };
        let mappingField = subRecord[0].mappingFields;
        if (mappingField === undefined || mappingField.length === 0) {
            newMappingField = [...mappingField, fieldLink];
        }
        else {
            if (mappingField[mappingField.length - 1].childFieldName) {
                newMappingField = [...mappingField, fieldLink];
            }
        }



        if (newMappingField.length > 0) {
            subRecordCopy.mappingFields = newMappingField
        }

    }
    return subRecordCopy;
}



const updateSubRecord = (newsubRecord, subRecord) => {
    let subRecordCopy = [];
    if (subRecord) {
        subRecordCopy = { ...subRecord };
        subRecordCopy.reportName = newsubRecord.reportName;
        subRecordCopy.reportId = newsubRecord.reportId;
        subRecordCopy.mappingFields = newsubRecord.mappingFields;
        subRecordCopy.Id = (newsubRecord.reportName) ? subRecord.id : 0;
    }
    else {
        subRecordCopy = newSubRecord();
    }

    return subRecordCopy;

}

export const getDataViewSubRecord = (subRecord) => {
    const subRecordCopy = addNewToSubRecord(subRecord);
    return dispatch => {
        dispatch(getDataViewSubRecordSuccess(subRecordCopy));
    }
};

export const getChangeSubRecord = (change) => {
    return {
        type: types.VGO_CHANGE_SUBRECORD,
        change,
    };
}

export const clearSubRecord = () => {
    return dispatch => {
        const subRecordCopy = newSubRecord()
        dispatch(getDataViewSubRecordSuccess(subRecordCopy));
        dispatch(dataViewSubRecordUpdateSuccess(subRecordCopy));
    }
}

