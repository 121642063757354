import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { styled } from '@mui/styles';
import { withStyles } from '@mui/styles';
import { spacing } from '@mui/system';
import { Grid, Typography, Box } from '@mui/material';
import MuiCard from '@mui/material/Card';
import ColorPicker from 'common/colorPicker/index';
import { getChangeSiteTheme, getSiteTheme } from 'admin/actions/siteThemeActions';
import FileUpload from 'admin/components/Common/FileUpload'
import { siteTheme } from './theme/siteTheme';
import DummyHome from './DummyHome';
import Cookies from 'universal-cookie';
import { getActiveUser } from 'admin/actions/activeUserAction';
const Card = styled(MuiCard)(spacing);
const cookies = new Cookies();
const SiteThemeEdit = (props) => {
    const { classes, siteTheme, dispatch, activeUser, isLoading } = props;

    useEffect(() => {
        if (!activeUser.roleName && !isLoading) {
            const versagoCookie = cookies.get('Versago');
            if (versagoCookie !== undefined) {
                let roleCookie = cookies.get('roleId');
                dispatch(getActiveUser(roleCookie));
            }
        }
        dispatch(getSiteTheme());
    },[]);

    const changeSiteTheme = (change) => {
        dispatch(getChangeSiteTheme(change));
    }

    const handleChange = (value) => {
        changeSiteTheme(siteTheme => siteTheme.theme = value);
    }
    const handleFileUploadChange = (value,fileName) => {
        changeSiteTheme(siteTheme => {
            siteTheme.companyLogo = value;
            siteTheme.companyLogoFileName = fileName;
            return siteTheme
        })}
    const logoFileName = siteTheme.companyLogoFileName ? siteTheme.companyLogoFileName : "company_logo.png"
    console.log(siteTheme.companyLogoFileName)
    return (
         <React.Fragment >
            <Card variant="outlined" className={classes.card}>
                <Grid container className={classes.header}>
                    <Grid item xs={12}>
                        <Typography variant="body1">Information</Typography>
                    </Grid>
                </Grid>
                <Grid container item xs={12} mb={5.7} >
                    <Grid xs={3} ml={3}>
                        <Box sx={{ width: 390 }}>
                            <FileUpload placeholder="Logo Upload" accept=".png" onFileUploadChange={handleFileUploadChange} fileName={siteTheme.companyLogoFileName}/>
                       </Box>
                     </Grid>
                    <Grid xs={6} ml={5} mt={.8}>
                        <Grid container item xs={12}>
                            <Grid xs={2}>
                                <Typography variant="body1">Theme Color</Typography>
                            </Grid>
                            <Grid ml={-1}>
                                <ColorPicker name='icon'
                                    style={{ backgroundColor: siteTheme.theme }}
                                    value={siteTheme.theme}
                                    onChange={(value) => handleChange(value)}
                                    variant="filled"
                                    className={classes.colorStyle}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
            <Card variant="outlined" mt={1} className={classes.preview}>
                <DummyHome themeColor={siteTheme.theme} companyLogo={ siteTheme.companyLogo }/>
            </Card>
        </React.Fragment>
    );
    
}
function mapStateToProps(state) {
    let props = {
        siteTheme: state.siteThemes.siteTheme,
        activeUser: state.activeUser.activeUser,
        isLoading: state.loader.isLoading,
    };
    return props;
};

export default withRouter(connect(mapStateToProps)(withStyles(siteTheme)(SiteThemeEdit)));

