import * as yup from "yup";
import { FormType } from 'client/components/Common/Enum';
export const formSetupSchema = yup.object().shape({
    formName: yup.string().required("Name is required"),
    title: yup.string().required("Title is required"),
    category: yup.object()
        .test('isCategoryEmpty',
            'Category is required',
            function (value) {
                return value.categoryName ? true : false;
            })
        .required("Category is required"),
    roles: yup.array().min(1, 'Roles is required'),
    company: yup.object()
        .test('isCompanyEmpty',
            'Connection is required',
            function (value) {
                return value.databaseId === 0 ? false : true;
            })
        .required("Connection is required"),
    primaryTableName: yup.string().required("Primary table is required"),
    detailTableName: yup.string()
        .when(["formType"],
            (formType) => {
                if (formType === FormType.HeaderDetail)
                    return yup.string().test('isDetailTableEmpty',
                        'Detail table is required',
                        function (value) {
                            return !value ? false : true;
                        })
            }),
    //eventFieldSettings: yup.string(),
    //startDate: yup.object()
    //    .test('isStartDateEmpty',
    //        'Start date is required',
    //        function (value) {
    //            return !value.fieldName ? false : true;
    //        }),
    //titleFields: yup.array()
    //    .test('isTitleFieldSelected',
    //        'Field is required',
    //        function (value) {
    //            return value.some(titleField => !(!titleField.fieldName))
    //        }),

    //endDate: yup.object()
    //    .when(["eventFieldSettings"],
    //        (eventFieldSettings) => {
    //            if (eventFieldSettings === CalendarEventFieldSettings.HasStartDateTimeAndEndDateTime || eventFieldSettings === CalendarEventFieldSettings.HasStartDateAndEndDate)
    //                return yup.object().test('isEndDateEmpty',
    //                    'End date is required',
    //                    function (value) {
    //                        return !value.fieldName ? false : true;
    //                    })
    //        })
    




})
