import React from 'react';
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-react-layouts/styles/material.css';
import { DashboardLayoutComponent } from '@syncfusion/ej2-react-layouts';
import DataView from 'client/components/DataView/DataView';
import LayoutObjectHeader from 'client/components/Common/LayoutObjectHeader'
import { Box, Grid, Typography } from '@mui/material';
import * as Enumerators from 'client/components/Common/Enum';
import Cookies from 'universal-cookie';
import { getDeviceType, setTabTitle } from 'client/components/Common/Utility';
import * as Enums from 'client/components/Common/Enum';
const cookies = new Cookies();
const deviceType = getDeviceType();


class dashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dashboardId: 0,
            definition: { panelList:[]},
            hasError: false,
            hasDefinition: false
        }
        this.cellSpacing = [16, 8];
    }
    componentDidMount() {
        this.getDashboardDefinition(this.props.match.params.id)
    }

    componentDidUpdate(nextProps) {
        if (this.props.match.params.id !== nextProps.match.params.id) {
            this.props.actions.alertHandler.hideAlert();
          this.getDashboardDefinition(this.props.match.params.id)
        }
    }

    getDashboardDefinition = async (dashboardId) => {
        let url = `api/DashboardDefinition/GetDashboardDefinition/${dashboardId}`;
        let versagoCookie = cookies.get('Versago');

        if (versagoCookie === undefined) {
            url = `api/DashboardDefinition/GetDashboardDefinitionPublic/${dashboardId}`;
        }
        const response = await fetch(url);
        if (response.status === 200) {
            const dashboardDefinition = await response.json();
            this.setState({
                dashboardId: dashboardId,
                hasError: false,
                hasDefinition: true,
                definition: dashboardDefinition,
                isValidDashboard: dashboardDefinition.favoriteStatus === Enumerators.FavoriteStatus.Delete ? false : true
            });

        }
        else if (response.status === 401) {
            this.props.actions.alertHandler.showErrorMessage("The Dashboard selected is not available to this account.");
            this.setState({
                hasError: true,
                hasDefinition: false,
                dashboardId: 0,
                definition: { panelList: [], },
            });
        }
        else if (response.status === 409) {
            
            this.setState({
                hasError: true,
                dashboardId: 0,
                definition: { hasDefinition:false, favoriteStatus: Enumerators.FavoriteStatus.Delete, panelList:[], },
                removedDashboardId: parseInt(dashboardId),
            });
        }
        else {
            this.props.actions.alertHandler.showErrorMessage("Dashboard  loading failed");
            this.setState({
                hasError: true,
                hasDefinition: false,
                dashboardId: 0,
                definition: {  panelList: [], },
            });
        }
    }

    panelContentTemplate = (panelObject) => {
        let panelContent=<div/>
        if (panelObject.dashboardItem.type === Enumerators.DashboardObjectType.Chart) {
            panelContent = <DataView
                type={Enumerators.ReportObjectLoadType.Dashboard}
                subtype={panelObject.dashboardItem.type}
                reportId={panelObject.dashboardItem.parentObjectId}
                objectId={panelObject.dashboardItem.objectId}
                panelId={panelObject.id}
                panelSizeX={panelObject.sizeX === 0 ? 1 : panelObject.sizeX}
                panelSizeY={panelObject.sizeY}
            />
        }

        else {
            panelContent = <DataView
                type={Enumerators.ReportObjectLoadType.Dashboard}
                subtype={panelObject.dashboardItem.type}
                reportId={panelObject.dashboardItem.objectId}
                panelId={panelObject.id}
                panelSizeX={panelObject.sizeX}
                panelSizeY={panelObject.sizeY}
            />
        }
        return panelContent;
    }
    renderActionBar = (instructions) => {
        
        return (

            <Box id="actionBar" pl={3}  pb={3} mr={7}>
                <Grid container justify="flex-end">
                    <Grid item  >
                        <Box mt={1}>
                            <Typography variant="body1" style={{ wordBreak: "break-all"}}>{instructions}</Typography>
                        </Box>
                    </Grid>

                </Grid>
            </Box>

        );
    }
    getObjectDetailsForFavorite = () => {

        const id = parseInt(this.props.match.params.id);
        const objectId = parseInt(this.state.dashboardId)
        let objectDetails = {
            objectType: Enumerators.MenuType.Dashboard,
            objectName: '',
            objectId: 0,
            removedObjectId: 0,
            favoriteStatus: '',
        }
        if (id === objectId) {
            objectDetails.objectId = objectId
            objectDetails.objectName = this.state.definition.title
            objectDetails.favoriteStatus = this.state.definition.favoriteStatus
        }
        else if (id > 0 && objectId == 0 && id === this.state.removedDashboardId) {
            objectDetails.removedObjectId = this.state.removedDashboardId
            objectDetails.favoriteStatus = this.state.definition.favoriteStatus
        }
        return objectDetails;

        
    }
    renderDashboardNew = () => {
        const panelList = this.state.definition.panelList.map((panel) => {

            panel.content = this.panelContentTemplate.bind(this, panel)
            panel.zIndex = "";
            return panel;
        }) ;
        const aspectRatio = deviceType === Enums.DeviceType.Desktop ? 16 / 9 : 10 / 10;
        const objectDetails = this.getObjectDetailsForFavorite();
        const showHeader = (this.state.hasDefinition || objectDetails.removedObjectId) ? true : false;
        if (objectDetails.objectName) {
            setTabTitle(objectDetails.objectName);
        }
        return (
            <div id="dashboardRoot">
                {showHeader && <LayoutObjectHeader title={this.state.definition.title}
                    objectDetails={objectDetails}
                    favoriteStatus={objectDetails.favoriteStatus}
                    
                />}
                <Box>
                    <Grid container spacing={0} >
                        <Grid item xs={12} >
                            {(this.state.hasDefinition && this.state.definition.userInstruction) && this.renderActionBar(this.state.definition.userInstruction)}
                        </Grid>
                    </Grid>
                    </Box>
                <Box pl={1} pr={1} pb={2} >
                    
                    {panelList.length >0 && <Grid container spacing={0}>
                        <Grid item xs={12} >
                            <DashboardLayoutComponent
                                id={`dashboard_${this.state.dashboardId}`}
                                ref={dashboard => this.dashboardObj = dashboard}
                                cellSpacing={this.cellSpacing}
                                panels={panelList}
                                cellAspectRatio={aspectRatio}
                                allowDragging={false}
                                columns={2} />

                        </Grid>
                    </Grid>}
                </Box>
             </div>  
        );
    }
    render() {
        
        return (
            this.renderDashboardNew()
            );
    }
}
export default (dashboard)