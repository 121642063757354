import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import { theme } from 'common/theme.js';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import ColorPicker from 'common/colorPicker/index';
import Delete from '@mui/icons-material/DeleteOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { updateHighLight, showValidationMessage, hideValidationMessage} from 'admin/actions/fieldDisplayActions';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { Alert, AlertTitle } from '@mui/lab';
import { Typography, Box, Grid, IconButton, FormControl, FormHelperText } from '@mui/material'
import { highlightTheme } from 'admin/components/section/dataViews/fieldDisplay/theme/highlightTheme';
import AlertMessage from 'admin/components/section/dataViews/dataFilters/AlertMessage';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
const relationErrorMessage = 'Comparison is required';
const value1ErrorMessage = 'Value 1 is required';
const value2ErrorMessage = 'Value 2 is required';
const displayErrorMessage = 'Display is required';
const relationTypes = { Select: 0, LessThan: 1, GreaterThan: 2, Between: 3, EqualTo: 4 }
const relationTypesList = [
    {
        label: "Select",
        value: relationTypes.Select
    },
    {
        label: "Less Than",
        value: relationTypes.LessThan
    },
    {
        label: "Greater Than",
        value: relationTypes.GreaterThan
    },
    {
        label: "Between",
        value: relationTypes.Between
    },
    {
        label: "Equal To",
        value: relationTypes.EqualTo
    },
];
const displayTypes = [
    {
        label: "Select",
        value: 0
    },
    {
        label: "Cell",
        value: 2
    },
    {
        label: "Text",
        value: 1
    },
    {
        label: "Icon",
        value: 3
    },
];
const defaultColumnOptions = {
    filter: true,
    sort: true,
    justifyContent: "left",
   
    setCellHeaderProps: (cellValue, rowIndex, columnIndex) => {
        return {
            style: {
                paddingLeft: theme.spacing(0),
                paddingTop: theme.spacing(0),
                paddingBottom: theme.spacing(1.8),
                paddingRight: theme.spacing(0),
            },
        };
    },
};

class HighLight extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
           
           
            options: {
                download: false,
                elevation: 0,
                jumpToPage: false,
                viewColumns: false,
                filter: false,
                search: false,
                sorting: true,
                print: false,
                pagination: false,
                selectableRows: 'none',
                responsive: 'scrollMaxHeight',
                tableBodyMaxHeight: '230px',
                tableBodyHeight: '230px',
            },
            data: [],
            isFromChart: this.props.isFromChart,
            showMessage: false,
            
        }

    }
    componentDidMount() {
        const data = (this.props.highLight.data) ? this.props.highLight.data : [];

        if (data.length === 0) {
            this.addRow(this.state.data);
        } else {
            const newData = [...data];
            data.forEach((row, i) => {
                newData[i] = {
                    ...row,
                    relationError: false,
                    relationHelperText: '',
                    value1Error: false,
                    value1HelperText: '',
                    value2Error: false,
                    value2HelperText: '',
                    colorError: false,
                    colorHelperText: '',
                    displayError: false,
                    displayHelperText: '',
                }
            })
            this.addRow(newData);
        }
        this.props.dispatch(hideValidationMessage());
       
    }
   
    setDropDownData = (data) => {
        const { classes } = this.props;
        const array = data.map((item, index) => {
            return (<MenuItem classes={{ root: classes.body1 }} key={item.value + index} value={item.value} > {item.label}</MenuItem >);
        });
        return array;
    }

    relationTemplate = (value, tableMeta, updateValue) => {
        const { classes } = this.props;
        return (
            <FormControl error={this.state.data[tableMeta.rowIndex].relationError ? this.state.data[tableMeta.rowIndex].relationError: false}>
                <Select classes={{ root: classes.body1 }} className={classes.relationStyle} key={"relation" + tableMeta.rowIndex}
                    value={this.state.data[tableMeta.rowIndex]["relation"]} name="relation"
                    onChange={(e) => this.handleChange(e.target.name, tableMeta.rowIndex, e.target.value)}
                    onBlur={e => {
                        const rowIndex = tableMeta.rowIndex;
                        let data = this.state.data;
                        let newData = [...data];
                        const currentData = this.state.data[rowIndex];
                        if (currentData.relation === 0) {
                            newData[rowIndex] = {
                                ...data[rowIndex],
                                relationError: true,
                                relationHelperText: relationErrorMessage
                            }
                        } else {
                            newData[rowIndex] = {
                                ...data[rowIndex],
                                relationError: false,
                                relationHelperText: ''
                            }
                        }
                        
                        const length = data.length;
                        if (this.state.isFromChart) {
                            if (data[length - 1].relation !== 0 &&
                                data[length - 1].firstValue !== '' ) {
                                this.addRow(newData);
                            } else {
                                this.setState({ data: newData });
                            }
                        } else {
                            if (data[length - 1].relation !== 0 &&
                                data[length - 1].firstValue !== '' &&
                                data[length - 1].displayTypeValue !== 0) {
                                this.addRow(newData);
                            } else {
                                this.setState({ data: newData });
                            }
                        }


                    }}>
                    {this.setDropDownData(relationTypesList)}
                </Select>
                <FormHelperText>{this.state.data[tableMeta.rowIndex].relationHelperText ? this.state.data[tableMeta.rowIndex].relationHelperText: ' '}</FormHelperText>
            </FormControl>
        );
    }

    displayTypeTemplate = (value, tableMeta, updateValue) => {
        const { classes } = this.props;
        return (
            <FormControl error={this.state.data[tableMeta.rowIndex].displayError ? this.state.data[tableMeta.rowIndex].displayError: false}>
                <Select classes={{ root: classes.body1 }} className={classes.displayStyle} key={"displayType" + tableMeta.rowIndex} value={this.state.data[tableMeta.rowIndex][
                    "displayTypeValue"]} name="displayTypeValue"
                    onChange={(e) => this.handleChange(e.target.name,
                        tableMeta.rowIndex,
                        e.target.value)}
                    onBlur={e => {
                        const rowIndex = tableMeta.rowIndex;
                        let data = this.state.data;
                        let newData = [...data];
                        const currentData = this.state.data[rowIndex];
                        if (currentData.displayTypeValue === 0) {
                            newData[rowIndex] = {
                                ...data[rowIndex],
                                displayError: true,
                                displayHelperText: displayErrorMessage
                            }
                        } else {
                            newData[rowIndex] = {
                                ...data[rowIndex],
                                displayError: false,
                                displayHelperText: ''
                            }
                        }
                        const length = data.length;
                        if (data[length - 1].relation !== 0 &&
                            data[length - 1].firstValue !== '' &&
                            data[length - 1].displayTypeValue !== 0) {
                            this.addRow(newData);
                        } else {
                            this.setState({ data: newData });
                        }

                    }}>
                    {this.setDropDownData(displayTypes)}
                </Select>
                <FormHelperText>{this.state.data[tableMeta.rowIndex].displayHelperText ? this.state.data[tableMeta.rowIndex].displayHelperText: ' '}</FormHelperText>
            </FormControl>
        );
    }

    value1Template = (value, tableMeta, updateValue) => {
        const { classes } = this.props;
        return (
            <TextField InputProps={{ classes: { input: classes.body1 } }}
                className={classes.value1Style}
                value={this.state.data[tableMeta.rowIndex]["firstValue"]}
                name="firstValue"
                onChange={(e) => this.handleChange(e.target.name, tableMeta.rowIndex, e.target.value)}
                onBlur={e => {
                    const rowIndex = tableMeta.rowIndex;
                    let data = this.state.data;
                    let newData = [...data];
                    const currentData = this.state.data[rowIndex];
                    if (currentData.firstValue === '') {
                        newData[rowIndex] = {
                            ...data[rowIndex],
                            value1Error: true,
                            value1HelperText: value1ErrorMessage
                        }
                    } else {
                        newData[rowIndex] = {
                            ...data[rowIndex],
                            value1Error: false,
                            value1HelperText: ''
                        }
                    }
                    const length = data.length;
                    if (this.state.isFromChart) {
                        if (data[length - 1].relation !== 0 &&
                            data[length - 1].firstValue !== '') {
                            this.addRow(newData);
                        } else {
                            this.setState({ data: newData });
                        }
                    } else {
                        if (data[length - 1].relation !== 0 &&
                            data[length - 1].firstValue !== '' &&
                            data[length - 1].displayTypeValue !== 0) {
                            this.addRow(newData);
                        } else {
                            this.setState({ data: newData });
                        }
                    }

                } }
                error={this.state.data[tableMeta.rowIndex].value1Error ? this.state.data[tableMeta.rowIndex].value1Error: false}
                helperText={this.state.data[tableMeta.rowIndex].value1HelperText ? this.state.data[tableMeta.rowIndex].value1HelperText : ' '}
            />
        );
    }
    value2Template = (value, tableMeta, updateValue) => {
        const { classes } = this.props;
        return (
            <TextField InputProps={{ classes: { input: classes.body1 } }} className={classes.value2Style}
                value={(this.state.data[tableMeta.rowIndex].relation === 3) ? this.state.data[tableMeta.rowIndex]["secondValue"] : ''} name="secondValue"
                onChange={(e) => this.handleChange(e.target.name, tableMeta.rowIndex, e.target.value)}
                disabled={(this.state.data[tableMeta.rowIndex].relation !== 3) ? true: false }
                onBlur={e => {
                    const rowIndex = tableMeta.rowIndex;
                    let data = this.state.data;
                    let newData = [...data];
                    const currentData = this.state.data[rowIndex];
                    if (currentData.secondValue === '') {
                            newData[rowIndex] = {
                                ...data[rowIndex],
                                value2Error: true,
                                value2HelperText: value2ErrorMessage
                            }
                    } else {
                        newData[rowIndex] = {
                            ...data[rowIndex],
                            value2Error: false,
                            value2HelperText: ''
                        }
                    }
                    const length = data.length;
                    if (this.state.isFromChart) {
                        if (data[length - 1].relation !== 0 &&
                            data[length - 1].firstValue !== '') {
                            this.addRow(newData);
                        } else {
                            this.setState({ data: newData });
                        }
                    } else {
                        if (data[length - 1].relation !== 0 &&
                            data[length - 1].firstValue !== '' &&
                            data[length - 1].displayTypeValue !== 0) {
                            this.addRow(newData);
                        } else {
                            this.setState({ data: newData });
                        }
                    }

                }}
                error={this.state.data[tableMeta.rowIndex].value2Error ? this.state.data[tableMeta.rowIndex].value2Error : false}
                helperText={this.state.data[tableMeta.rowIndex].value2HelperText ? this.state.data[tableMeta.rowIndex].value2HelperText : ' '}
            />
        );
    }
    
    colorTemplate = (value, tableMeta, updateValue) => {
        const { classes } = this.props;
        return (
            <ColorPicker name='icon'
                style={{ backgroundColor: this.state.data[tableMeta.rowIndex]["icon"]}}
                value={this.state.data[tableMeta.rowIndex]["icon"]}
                onChange={(color) => this.handleChange("icon", tableMeta.rowIndex, color)}
                variant="filled"
                className={classes.colorStyle}
             />

        );
    }
    deleteTemplate = (value, tableMeta, updateValue) => {
        const { classes } = this.props;
        return (
            <IconButton id={"deleteDetails" + tableMeta.rowIndex} type="button" onClick={() => this.deleteRow(tableMeta.rowIndex)} className={classes.deleteButton}>
                <Delete />
            </IconButton>
        );
    }
    
    addRow = (data) => {
        const newData = {
            relation: 0,
            firstValue: "",
            secondValue: "",
            displayTypeValue: 0,
            icon: "#E10050",
            relationError: false,
            relationHelperText: '',
            value1Error: false,
            value1HelperText: '',
            value2Error: false,
            value2HelperText: '',
            colorError: false,
            colorHelperText: '',
            displayError: false,
            displayHelperText: '',
        };

        data.push(newData)
        this.setState({ data: data })
        
    }

    deleteRow = (rowIndex) => {
        let updateArray = Object.assign([], this.state.data)
        updateArray.splice(rowIndex, 1)
        if (rowIndex === this.state.data.length - 1) {
            this.addRow(updateArray);
        } else {
            this.setState({ data: updateArray })
        }

    }

    handleChange = (name, index, value) => {
        let updateArray = Object.assign([], this.state.data)
        let rowData = Object.assign({ }, updateArray[index])
        rowData[name] = value;
        updateArray[index] = rowData;
        this.setState({ data: updateArray })

    }

    checkConditions = (item, status) => {

        if (item.relation === relationTypes.Select) {
            item.relationError = true;
            item.relationHelperText = relationErrorMessage;
            status = false;
        }
        if (item.relation === relationTypes.Between) {
            if (!item.secondValue) {
                item.value2Error = true;
                item.value2HelperText = value2ErrorMessage;
                status = false;
            }
        }
        if (!item.firstValue) {
            item.value1Error = true;
            item.value1HelperText = value1ErrorMessage;
            status = false;
        }
        if (!this.state.isFromChart) {
            if (item.displayTypeValue === relationTypes.Select) {
                item.displayError = true;
                item.displayHelperText = displayErrorMessage;
                status = false;
            }
        }
        return status;
    }

    validateHighLightData = () => {
        let data = this.state.data;
        const length = data.length;
        let status = true;
        if (length === 1) {
            if (this.state.isFromChart) {
                const oldDataLength = this.props.highLight.data.length;
                if (oldDataLength === 0)
                    status = this.checkConditions(data[0]);
            } else {
                status = this.checkConditions(data[0]);
            }
        }
        for (let i = 0; i < length; i++) {

            if (i === length - 1) {
                if (this.state.isFromChart) {
                    if (data[i].relation !== relationTypes.Select || data[i].firstValue !== '' ) {
                        status = this.checkConditions(data[i], status);
                    }}
                else {
                    if (data[i].relation !== relationTypes.Select || data[i].firstValue !== '' || data[i].displayTypeValue !== relationTypes.Select) {
                        status = this.checkConditions(data[i], status);
                    }
                }
            } else
                status = this.checkConditions(data[i], status);
        }
        if (!status) {
            this.setState({
                showMessage: true,
                data: data,
            });
        }

        return status;
    }

    onMessageClose = () => {
        this.setState({ showMessage: false })
    }

    updateHighLight=() => {
        if (this.validateHighLightData()) {
            const updateArray = Object.assign([], this.state.data)
            updateArray.splice(this.state.data.length - 1);
            if (this.state.isFromChart) {
                this.props.handleUpdate(updateArray);
            } else {
                
                this.props.dispatch(updateHighLight(this.props.fieldDisplay,
                    updateArray,
                    this.props.highLight.fieldName));
                this.props.handleClose();
            }
        }
    }
    
    render() {
       
        const fieldDisplayColumns = [
            {
                name: "relation",
                label: "Comparison",
                options: {
                    ...defaultColumnOptions,
                    customBodyRender: this.relationTemplate,
                    setCellProps: () => ({
                        style: {
                            paddingLeft: theme.spacing(0),
                            paddingRight: theme.spacing(0),
                            width: 144
                        }
                    })
                }

            },
            {
                name: "value1",
                label: "Value 1",
                options: {
                    ...defaultColumnOptions,
                    customBodyRender: this.value1Template,
                    setCellProps: () => ({
                        style: {
                            paddingLeft: theme.spacing(0),
                            paddingRight: theme.spacing(0),
                            width: 190
                        }
                    })
                }
            },
            {
                name: "value2",
                label: "Value 2",
                options: {
                    ...defaultColumnOptions,
                    customBodyRender: this.value2Template,
                    setCellProps: () => ({
                        style: {
                            paddingLeft: theme.spacing(.7),
                            paddingRight: theme.spacing(0),
                            width: 182
                        }
                    })
                }
            },
            {
                name: "icon",
                label: "Color",
                options: {
                    ...defaultColumnOptions,
                    customBodyRender: this.colorTemplate,
                    setCellProps: () => ({


                        style: {
                            paddingLeft: 10,
                            paddingRight:  theme.spacing(0),
                            width: 65
                        }
                    })
                }
            },
            {
                name: "displayType",
                label: "Display",
                options: {
                    ...defaultColumnOptions,
                    customBodyRender: this.displayTypeTemplate,
                    setCellProps: () => ({
                        style: {
                           paddingLeft: theme.spacing(0),
                           paddingRight: theme.spacing(0),
                            width: 127
                        }
                    })
                }
            },
            {
                name: "",
                label: "",
                options: {
                    ...defaultColumnOptions,
                    customBodyRender: this.deleteTemplate,
                    setCellProps: () => ({
                        style: {
                            paddingLeft: 5,
                            paddingRight: theme.spacing(0),
                        }
                    })
                }
            },
        ];
        const chartColumns = [
            {
                name: "relation",
                label: "Comparison",
                options: {
                    ...defaultColumnOptions,
                    customBodyRender: this.relationTemplate,
                    setCellProps: () => ({
                        style: {
                            paddingLeft: theme.spacing(0),
                            paddingRight: theme.spacing(0),
                            width: 144
                        }
                    })
                }

            },
            {
                name: "value1",
                label: "Value 1",
                options: {
                    ...defaultColumnOptions,
                    customBodyRender: this.value1Template,
                    setCellProps: () => ({
                        style: {
                            paddingLeft: theme.spacing(0),
                            paddingRight: theme.spacing(0),
                            width: 190
                        }
                    })
                }
            },
            {
                name: "value2",
                label: "Value 2",
                options: {
                    ...defaultColumnOptions,
                    customBodyRender: this.value2Template,
                    setCellProps: () => ({
                        style: {
                            paddingLeft: theme.spacing(.7),
                            paddingRight: theme.spacing(0),
                            width: 182
                        }
                    })
                }
            },
            {
                name: "icon",
                label: "Color",
                options: {
                    ...defaultColumnOptions,
                    customBodyRender: this.colorTemplate,
                    setCellProps: () => ({


                        style: {
                            paddingLeft: 10,
                            paddingRight: theme.spacing(0),
                            width: 65
                        }
                    })
                }
            },
            {
                name: "",
                label: "",
                options: {
                    ...defaultColumnOptions,
                    customBodyRender: this.deleteTemplate,
                    setCellProps: () => ({
                        style: {
                            paddingLeft: 5,
                            paddingRight: theme.spacing(0),
                        }
                    })
                }
            },
        ];
        const { classes } = this.props;
        return (
            <Dialog classes={{ paper: (!this.state.isFromChart) ? classes.dialog : classes.dialogChart }} onClose={this.props.handleClose}  disableScrollLock={true}
                aria-labelledby="customized-dialog-title" open={this.props.visible}>
                <DialogTitle id="customized-dialog-title" onClose={this.props.handleClose}>
                    <Box pt={1.2} pb={.2}><Typography variant="h6">
                        {(this.state.isFromChart) ? "Color Conditions" : "Highlights"}
                    </Typography></Box>
                </DialogTitle>
                <DialogContent >
                    <MUIDataTable container title={''} className={(this.state.isFromChart) ? classes.MUIDataTableChart : classes.MUIDataTable}
                        data={this.state.data}
                            columns={(this.state.isFromChart) ? chartColumns : fieldDisplayColumns}
                        options={this.state.options}
                       
                        />
                </DialogContent>
               
                <DialogActions>
                    <Grid container spacing={1}>
                        <Grid item xs={9} className={classes.alertGrid}><Box pl={2} >
                            <AlertMessage open={this.state.showMessage} severity='error'
                                title=''
                                message='Please correct the indicated items'
                                icon={<ErrorIcon />}
                                close={this.onMessageClose} fromHighlight={true} /></Box>
                        </Grid>
                        <Grid item xs={3} className={(!this.state.isFromChart)? classes.buttonGrid: null}>
                            <Box pt={1} pr={(this.state.isFromChart) ? 0: 4.6 }>
                                <Grid container spacing={0}>
                                    <Grid item xs>
                                        <Button color="primary" className={classes.buttonCancel} onClick={this.props.handleClose} >CANCEL</Button>
                                        <Button color="primary" onClick={this.updateHighLight}>SAVE</Button></Grid>
                                </Grid>
                            </Box>
                        </Grid></Grid>
                </DialogActions>
            </Dialog>

        );

    }

}

/**
 * 
 * @param {*} state 
 */
function mapStateToProps(state) {
    return {
        fieldDisplay: state.dataViews.dataView.fieldDisplay,
        alert: state.dataViews.alert,
        isLoading: state.loader.isLoading,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage
    };
};
export default withRouter(connect(mapStateToProps)(withStyles(highlightTheme)(HighLight)));