import React from 'react';
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-react-grids/styles/material.css';
import '@syncfusion/ej2-react-navigations/styles/material.css';
import '@syncfusion/ej2-react-inputs/styles/material.css';
import { ColumnDirective, ColumnsDirective, GridComponent } from '@syncfusion/ej2-react-grids';
import { Inject,Sort } from '@syncfusion/ej2-react-grids';
import { Checkbox, Typography ,Grid} from '@mui/material';
import ImportDestinationConnection from './importDestinationConnection';
import SelectCheckbox from 'admin/components/section/package/SelectCheckbox';
import 'admin/components/section/package/theme/grid.css'


class ImportList extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            connectionSearch:"",
        }

    }
    sourceConnectionDetails = (data) => {
        const connection = this.props.exportConnectionList.filter(connection => connection.id === data.oldObjectId);
        const sourceConnection = connection.length > 0 ?
            connection[0] :
            {name:'',database:''};
        return (
            <div>
                <Typography variant="body1"> {sourceConnection.name} </Typography>
                <Typography variant="caption"> {sourceConnection.database} </Typography>
            </div>
        );
    }
    destinationConnectionDetails = (data) => {
        return (
            <ImportDestinationConnection
                databaseList={this.props.databaseList}
                invalidConnectionList={this.props.invalidConnectionList}
                connectionChange={this.props.connectionChange}
                data={data}
            />
        );
    }
    headerCheckChange = (event) => {
        this.props.changeObject(importPackage => {
            const checked = !this.props.selectAll;
            for (let index = 0; index < importPackage.importItemList.length; index++) {
                importPackage.importItemList[index].select = checked;
            }
            
            
                importPackage.selectAll = checked;
            return importPackage;
        });
    }
    selectHeaderTemplate = (props) => {
        return (
            <Checkbox sx={{ marginTop: "-4px" }} checked={this.props.selectAll}
                onChange={this.headerCheckChange} />
        );
    }
    selectTemplate = (data) => {
        let checked = data.select;
        return (
            <SelectCheckbox checked={checked} data={data}
                checkChange={this.checkChange} />
        );
    }
    checkChange = (data, checked) => {
        
        this.props.changeObject(importPackage => {
            importPackage.importItemList[data.rowIndex].select = checked;
            if (!checked) {
                importPackage.selectAll = false;
            }
            return importPackage;
        });
    }
    connectionHeaderTemplate = (title) => {
        return (
            <Typography sx={{color:"common.black"}} variant="body2"> {title} </Typography>
        );
    }
    listHeaderTemplate = (title) => {
        return (
            <Typography sx={{ color: "common.black", paddingTop:1 }} variant="body2"> {title} </Typography>
        );
    }
    objectTypeTemplate = (objectType, data) => {
        return (
            <Typography variant="body2"> {objectType[data.objectType]} </Typography>
        );
    }
    connectionGridCreated() {
        const parElement = this.connectionGrid.element;
        const emptyRecord = parElement.querySelector('.e-emptyrow');
        if (emptyRecord) {

            emptyRecord.firstChild.innerText = "";
        }

    }
    connectionGridDataBound() {
        const parElement = this.connectionGrid.element;
        const emptyRecord = parElement.querySelector('.e-emptyrow');
        if (emptyRecord) {

            emptyRecord.firstChild.innerText = "";
        }

    }
    listGridCreated() {
        const parElement = this.itemGrid.element;
        const emptyRecord = parElement.querySelector('.e-emptyrow');
        if (emptyRecord) {

            emptyRecord.firstChild.innerText = "";
        }

    }
    listGridDataBound() {
        const parElement = this.itemGrid.element;
        const emptyRecord = parElement.querySelector('.e-emptyrow');
        if (emptyRecord) {

            emptyRecord.firstChild.innerText = "";
        }

    }
    render() {
        const objectType = { report: "Data View", form: "Form", dashboard: "Dashboard", menu: "Menu", filter: "Account Filter", role: "Role", user: "User" }
        return (
            <Grid container direction="row"  >
                <Grid item xs={6} >
                    <GridComponent dataSource={this.props.connectionMappingList}
                        id="importConnection"
                        enableHover={false}
                        allowSelection={false}
                        ref={grid => this.connectionGrid = grid}
                        created={this.connectionGridCreated.bind(this)}
                        dataBound={this.connectionGridDataBound.bind(this)}
                    >
                        <ColumnsDirective>
                            <ColumnDirective field='oldObjectId'  headerTemplate={this.connectionHeaderTemplate.bind(this,"Importing")} template={this.sourceConnectionDetails.bind(this)} />
                            <ColumnDirective field='newObjectId'  headerTemplate={this.connectionHeaderTemplate.bind(this, "This System")} template={this.destinationConnectionDetails.bind(this)} />
                        </ColumnsDirective>
                    </GridComponent>
                </Grid>
                <Grid item xs={6} >
                    <GridComponent dataSource={this.props.importItemList} allowSorting={true}
                        //enableVirtualization={true}
                        ref={grid => this.itemGrid = grid}
                        enableHover={false}
                        allowSelection={false}
                        created={this.listGridCreated.bind(this)}
                        dataBound={this.listGridDataBound.bind(this)}
                        height={500}
                       // pageSettings={{ pageSize: 50 }}
                    >
                        <Inject services={[Sort]} />
                        <ColumnsDirective>
                            <ColumnDirective field='Select' headerText='id' width='40' template={this.selectTemplate.bind(this)} headerTemplate={this.selectHeaderTemplate.bind(this)} />

                            <ColumnDirective field='name' headerText='Name' width='140' headerTemplate={this.listHeaderTemplate.bind(this, "Content Name")} />

                            {/* <ColumnDirective field='objectType' headerText='Type' width='120' template={this.objectTypeTemplate.bind(this, objectType)} headerTemplate={this.listHeaderTemplate.bind(this, "Type")}  />*/}
                             <ColumnDirective field='displayObjectType' headerText='Type' width='120'  />
                         </ColumnsDirective>
                    </GridComponent>
                </Grid>
                </Grid>
            );
    }

}

export default ImportList;