import React from 'react';
import { withStyles } from '@mui/styles';
import { Box, Typography, Grid } from '@mui/material';
import FunnelChart from 'common/react-funnel-chart/index';
import { seriesColorList, colorCount } from './ChartUtility';
import { chartTheme } from 'client/components/Charts/theme/chartTheme';
import * as enumerator from 'client/components/Common/Enum';
import ChartPopup from './ChartPopup';
import { formatValue } from 'client/components/Common/Utility';

class funnel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.setIndexToData(props.data),
            hideList: [],
            chartSeriesColor: this.setSeriesColor(),
            height: 0,
        };
    }

    componentDidMount() {
        const height = this.divElement.clientHeight;
        this.setState({ height });
    }

    render() {
        const { panelSizeX, panelSizeY, isFromDashboard, deviceType} = this.props;
        const X2Y1 = (panelSizeX === 2 && panelSizeY === 1) ? true : false;
        const X2Y2 = (panelSizeX === 2 && panelSizeY === 2) ? true : false;
        const X1Y2 = (panelSizeX === 1 && panelSizeY === 2) ? true : false;
        const X1Y1 = (panelSizeX === 1 && panelSizeY === 1) ? true : false;
       
        let height = 90;
        let funnelMarginTop = "-3%";
        if (isFromDashboard) {
            if (deviceType === enumerator.DeviceType.Desktop) {
                if (X2Y1) {
                    height = 90;
                    funnelMarginTop = "-3%";
                }
                else if (X2Y2) {
                    height = 100;
                    funnelMarginTop = "-7%";
                }
                else if (X1Y2) {
                    height = 100;
                    funnelMarginTop = "-15%";
                }
                else if (X1Y1) {
                    height = 90;
                    funnelMarginTop = "-7%";
                }
            } else if (deviceType === enumerator.DeviceType.Mobile) {
                if (X2Y1) {
                    height = 74;
                    funnelMarginTop = "-7%";
                }
                else if (X2Y2) {
                    height = 95;
                    funnelMarginTop = "-17%";
                }
                else if (X1Y2) {
                    height = 98;
                    funnelMarginTop = "-28%";
                }
                else if (X1Y1) {
                    height = 71;
                    funnelMarginTop = "-11%";
                }
            }
            else if (deviceType === enumerator.DeviceType.Tablet) {
                if (X2Y1) {
                    height = 63;
                    funnelMarginTop = "-6%";
                }
                else if (X2Y2) {
                    height = 90;
                    funnelMarginTop = "-15%";
                }
                else if (X1Y2) {
                    height = 90;
                    funnelMarginTop = "-24%";
                }
                else if (X1Y1) {
                    height = 64;
                    funnelMarginTop = "-10%";
                }
            }
        }
        const funnelHeightVariable = isFromDashboard ? (deviceType === enumerator.DeviceType.Mobile) ? 70 : 85 : 90;
        const groupField = this.props.chartDetail.groupField;
        let data = this.props.data;
        if (groupField === "") {
            data = this.sortData(data);
        } else {
            data = this.groupXAxis(data);
        }
        let stateHeight = 80;
        if (isFromDashboard) {
            stateHeight = (data.length <= 10) ? 50 : 80;
        }
        let funnelHeight = `calc(${height}% - ${this.state.height - stateHeight}px)`;
        if (!isFromDashboard) {
            funnelHeight = this.state.height === 508 ? 0 : funnelHeight;
        }
        
        const chart = <React.Fragment>
            <Grid continer height="100%">
                <Grid item xs={12}>
                        {this.funnelTitleRender()}
                    </Grid>

                    {this.loadLegend()}
                    {this.state.height &&

                    
                    <Grid item xs={12} style={{ height: funnelHeight, marginTop: funnelMarginTop }} >
                            {this.funnelChartRender(this.props.deviceType)}
                        </Grid>
                    }
            </Grid>
        </React.Fragment>;

        const marginTopPopup =  '-4%' ;

        const chartPopup = <React.Fragment>
            <Grid continer>
                <Grid item xs={12} style={{ height: '600px' }}>
                    <Grid item>
                        {this.funnelTitleRender()}
                    </Grid>

                    {this.loadLegend()}
                    {this.state.height &&

                        <Grid item style={{ height: `calc(${funnelHeightVariable}% - ${this.state.height - 80}px)`, marginTop: marginTopPopup }}>
                            {this.funnelChartRender()}
                        </Grid>
                    }
                </Grid>
            </Grid>
        </React.Fragment>
        return (
            <React.Fragment>
                <Box className={this.props.popupClassName}>
                    <ChartPopup chart={chartPopup} type="funnel" />
                </Box>
                {chart}
            </React.Fragment>
        );
    }

    funnelTitleRender = () => {
        const { classes } = this.props;
        return (
            <Typography className={classes.subtitle1} classes={{ root: classes.funnelTitle }} >{this.props.chartDetail.chartTitle}</Typography>
        )
    }

    funnelChartRender = (deviceType) => {
        let fontSize = 14;
        if (deviceType === enumerator.DeviceType.Mobile) {
            fontSize = 10;
        }
        else if (deviceType === enumerator.DeviceType.Tablet) {
            fontSize = 12;
        }
        const { classes } = this.props;
        return (
            <FunnelChart list={this.loadList()}
                isGradient={false}
                labelStyle={'#333'}
                dataStyle={'rgba(0, 0, 0, 0.54)'}
                strokeStyle={'#000'}
                minPercent={0.2}
                fontSize={fontSize}
                fontFamily={'Roboto'}
                tooltipColor={this.props.chartDetail.chartYAxes[0].seriesColor}
                tooltip=
                {
                    {
                        show: true,
                        formatter: (ringInfo) => {
                            return <Typography className={classes.subtitle1}>{ringInfo.tooltip}</Typography>;
                        }
                    }
                }
                onClick={(e, ringInfo) => {
                    //alert(ringInfo.value);
                }}
                onHover={(e, ringInfo) => {
                    //console.log(ringInfo.value);
                }}
            />);
    }

    loadList = () => {

        let xColumn = this.props.chartDetail.xColumn;
        const yAxis = this.props.chartDetail.chartYAxes[0];
        const yColumn = yAxis.yColumn;
        let tooltip = yAxis.toolTip;
        let label = yAxis.label;
        const groupField = this.props.chartDetail.groupField;
        const kpiDetails = this.props.chartDetail.chartManager.kpiDetails;
       
        let data = this.state.data
        var chartSeriesColor = this.state.chartSeriesColor;
        let list = '',  color = '#989FA6';
        let checkRelation = false;
        if (groupField === "") {
            data = this.sortData(data);
        } else {
            data = this.groupXAxis(data);
            xColumn = groupField;
            tooltip = yColumn;
            label = yColumn;
        }
        const tooltipCulture = this.props.columns.filter((value, index) => value.fieldName === tooltip);
        const labelCulture = this.props.columns.filter((value, index) => value.fieldName === label);
        let xCount = this.props.chartDetail.recordCountChart === 0
            ? data.length
            : this.props.chartDetail.recordCountChart;
        data.forEach((row, i) => {
            if (i < xCount) {
                if (kpiDetails.length > 0) {
                    kpiDetails.forEach((kpi, i) => {
                        let kpiColor = kpi.icon;

                        switch (kpi.relationalOperator) {
                            case enumerator.KpiRelation.LessThan:
                                {
                                    if (row[yColumn] < parseFloat(kpi.firstValue)) {
                                        color = kpiColor;
                                        checkRelation = true;
                                    }
                                    break;
                                }
                            case enumerator.KpiRelation.GreaterThan:
                                {
                                    if (row[yColumn] > parseFloat(kpi.firstValue)) {
                                        color = kpiColor;
                                        checkRelation = true;
                                    }
                                    break;
                                }
                            case enumerator.KpiRelation.Between:
                                {
                                    if (row[yColumn] >= parseFloat(kpi.firstValue) && row[yColumn] <= parseFloat(kpi.secondValue)) {
                                        color = kpiColor;
                                        checkRelation = true;
                                    }
                                    break;
                                }
                            case enumerator.KpiRelation.EqualTo:
                                {
                                    if (row[yColumn] === parseFloat(kpi.firstValue)) {
                                        color = kpiColor;
                                        checkRelation = true;
                                    }
                                    break;
                                }
                            default:
                                {
                                    color = kpiColor;
                                    break;
                                }
                        }
                    });
                    if (!checkRelation) {
                        color = chartSeriesColor[i].value;
                    }
                    checkRelation = false;
                } else {
                    color = chartSeriesColor[i].value;
                }

                const tooltipValue = (!tooltip) ? '' : formatValue(tooltipCulture[0], row[tooltip]);
                const labelValue = (!label) ? '' : formatValue(labelCulture[0], row[label]);

                if (row[yColumn])
                    list += '{ "name" :"' + row[xColumn] + '", "value" : ' + row[yColumn] + ', "backgroundColor" : "' + color + '", "tooltip" : "' + tooltipValue + '", "label" : "' + labelValue + '"},';
            }
            
        });
        list = list.slice(0, -1);
        return JSON.parse("[" + list + "]");
    }

    loadLegend = () => {
        const { classes } = this.props;
        let data = this.props.data;
        let xColumn = this.props.chartDetail.xColumn;
        const groupField = this.props.chartDetail.groupField;
        const yAxis = this.props.chartDetail.chartYAxes[0];
        const yColumn = yAxis.yColumn;
        const kpiDetails = this.props.chartDetail.chartManager.kpiDetails;

        let firstValueList = [];
        let secondValueList = [];
        let colorList = [];
        kpiDetails.forEach((kpi, i) => {
            firstValueList.push(parseInt(kpi.firstValue));
            secondValueList.push(parseInt(kpi.secondValue));
            colorList.push(kpi.color);
        });
        if (groupField === "") {
            data = this.sortData(data);
        } else {
            data = this.groupXAxis(data);
            xColumn = groupField;
        }
        let xCount = this.props.chartDetail.recordCountChart === 0
            ? data.length
            : this.props.chartDetail.recordCountChart;
        let chartSeriesColor = seriesColorList();
        let j = -1, color = '#989FA6';
        let checkRelation = false;
        const legendClassName = this.props.isFromDashboard ? classes.dashboardFunnelLegend : classes.funnelLegend;
        return (
            <Grid item xs={12} className={legendClassName} ref={(divElement) => { this.divElement = divElement }}>
                {data.map((row, i) => {
                    if (j === colorCount)
                        j = -1;
                    j++
                    if (i < xCount) {
                        if (kpiDetails.length > 0) {
                            kpiDetails.forEach((kpi, i) => {

                                let kpiColor = kpi.icon;

                                switch (kpi.relationalOperator) {
                                    case enumerator.KpiRelation.LessThan:
                                        {
                                            if (row[yColumn] < parseFloat(kpi.firstValue)) {
                                                color = kpiColor;
                                                checkRelation = true;
                                            }
                                            break;
                                        }
                                    case enumerator.KpiRelation.GreaterThan:
                                        {
                                            if (row[yColumn] > parseFloat(kpi.firstValue)) {
                                                color = kpiColor;
                                                checkRelation = true;
                                            }
                                            break;
                                        }
                                    case enumerator.KpiRelation.Between:
                                        {
                                            if (row[yColumn] >= parseFloat(kpi.firstValue) && row[yColumn] <= parseFloat(kpi.secondValue)) {
                                                color = kpiColor;
                                                checkRelation = true;
                                            }
                                            break;
                                        }
                                    case enumerator.KpiRelation.EqualTo:
                                        {
                                            if (row[yColumn] === parseFloat(kpi.firstValue)) {
                                                color = kpiColor;
                                                checkRelation = true;
                                            }
                                            break;
                                        }
                                    default:
                                        {
                                            color = kpiColor;
                                            break;
                                        }
                                }
                            });
                            if (!checkRelation) {
                                color = chartSeriesColor[j];
                            }
                            checkRelation = false;
                        } else {
                            color = chartSeriesColor[j];
                        }

                        if (row[yColumn]) {
                            return (
                                <Box key={i} display="inline-flex">{this.setIcon(row, xColumn, yColumn, color, i)} </Box>
                            );
                        }

                    }
                    return "";
                })
                }

            </Grid>
        )
    }


    hideUnhideLegend = (row, yColumn, index) => {
        let data = [...this.state.data];
        const groupField = this.props.chartDetail.groupField;
        if (groupField === "") {
            data = this.sortData(data);
        } else {
            data = this.groupXAxis(data);
        }
        let xCount = this.props.chartDetail.recordCountChart === 0
            ? data.length
            : this.props.chartDetail.recordCountChart;
        let newData = data.slice(0, xCount);
        let hideList = this.state.hideList;
        let chartSeriesColor = this.state.chartSeriesColor;
        const allSeriesColor = this.setSeriesColor();
        const findIndex = hideList.findIndex(value => value === index);
        if (findIndex === -1) {
            hideList.push(index);
            newData = newData.filter((value, index) => value[yColumn] !== row[yColumn] || value.TWBS_Index !== row.TWBS_Index);
            let hideColor = allSeriesColor[index];
            chartSeriesColor = chartSeriesColor.filter((color) => color.value !== hideColor.value || color.key !== hideColor.key);
        }
        else {
            hideList = hideList.filter(item => item !== index);
            let data = [...this.props.data];
            const groupField = this.props.chartDetail.groupField;
            if (groupField === "") {
                data = this.sortData(data);
            } else {
                data = this.groupXAxis(data);
            }
            let xCount = this.props.chartDetail.recordCountChart === 0
                ? data.length
                : this.props.chartDetail.recordCountChart;
            data = data.slice(0, xCount);
            let currentData = data[index];
            newData = [...newData, { ...currentData }]
            let unhideColor = allSeriesColor[index];
            chartSeriesColor.push(unhideColor);
            chartSeriesColor.sort((a, b) => a.key - b.key);
        }

        this.setState({
            data: newData,
            hideList: hideList,
            chartSeriesColor: chartSeriesColor,
        });
    }

    setIndexToData = (data) => {
        var newData = data.map((row, i) => {
            row.TWBS_Index = i
            return row;
        });
        return newData;
    }

    setSeriesColor = () => {
        let chartColor = [];
        let j = -1;
        this.props.data.forEach((row, i) => {
            if (j === colorCount)
                j = -1;
            j++;
            let obj = {
                key: i,
                value: seriesColorList()[j]
            }

            chartColor.push(obj);
            
        });
        return chartColor;
    }

    setIcon = (row, xColumn, yColumn, color, i) => {
        let style = this.props.classes.funnelDecorationNone;
        let hideList = this.state.hideList;
        const findIndex = hideList.findIndex(value => value === i);
        if (findIndex !== -1) {
            style = this.props.classes.funnelDecoration;
        }
        const column = this.props.columns.filter((value, index) => value.fieldName === xColumn);
        const stringValue = String(formatValue(column[0], (row[xColumn] !== null) ? row[xColumn] : ""));
        const value = (stringValue !== "undefined") ? stringValue : "";
        return (
            <table style={{ margin: "-12px 0px" }}>
                <tbody><tr onClick={() => this.hideUnhideLegend(row, yColumn, i)} >
                    <td>
                        <svg height="20" width="27" >
                            <circle cx="15" cy="11" r="8.3" stroke={color} strokeWidth="1" fill={color} />
                        </svg></td>
                    <td >
                        <Typography className={this.props.classes.body2 + " " + style} >{value}</Typography>
                    </td>
                </tr></tbody>
            </table>
        )
    }

    sortData = (data) => {
        const yColumn = this.props.chartDetail.chartYAxes[0].yColumn;
        const sortData = data.slice(0);
        sortData.sort(function (a, b) {
            return b[yColumn] - a[yColumn];
        });
        return sortData;
    }

    groupXAxis = (data) => {
        const xColumn = this.props.chartDetail.groupField;
        const yColumn = this.props.chartDetail.chartYAxes[0].yColumn;
       
        let newData = [];

        data.filter((value, index, self) => {
            let totalYValue = 0;
            self.forEach((row, i) => {
                if (row[xColumn] === value[xColumn]) {
                    totalYValue += row[yColumn];
                }
                return i;
            });
            newData.push({ ...value, [yColumn]: totalYValue });
        });
        newData = newData.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t[xColumn] === value[xColumn]
            ))
        );
        return this.sortData(newData);
    }
   
}

export default (withStyles(chartTheme)(funnel));