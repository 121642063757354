import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loginActionCreators } from 'client/store/Login';
import { errorActionCreator } from 'client/store/Error';
import Profile from 'admin/components/aside/Profile.js';
import Cookies from 'universal-cookie';
import * as Enum from 'client/components/Common/Enum';
import { getDeviceType } from 'client/components/Common/Utility'
const deviceType = getDeviceType();
const cookies = new Cookies();

class userPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = { closed: true, roleName: this.props.roleName };

    }

    
    submitRole(item) {
        let roleId = item.key;
        let roleName = (item.subLabel) ? item.subLabel : item.label;
        try {
            this.props.actions.loginActionCreators.resetLayout(roleId);
            this.setState({ closed: true, roleName: roleName });
        } catch (error) {
            this.props.actions.errorActionCreator.showErrorMessage(error);
        }
    }


    handleSignOut=() => {
        const versagoCookie = cookies.get('Versago');
        if (versagoCookie === undefined) {
            this.props.actions.loginActionCreators.requestSignOut();
            this.props.history.push("/");
            return false;

        } else {

            return true;
        }
    }


    userMenuItemClick = (item) => {

        if (!this.handleSignOut()) {
            return;
        }

        if (item.key === "profile") {
            if (deviceType === Enum.DeviceType.Mobile) {
                this.props.resetMargin(true);
            }
            this.props.history.push(item.route);
        }
       else if (item.key === "signout") {
           this.props.actions.loginActionCreators.requestSignOut();
           this.props.history.push("/");
        }
       else if (item.key === "admin") {
           
               window.open("/Admin/DataViews");
           
       }
       else {
           this.submitRole(item)
            }
     }

    showUserMenu = () => {
        let image = "";
        if (this.props.profileImage) {
            image = `data:image/png;base64,${this.props.profileImage}`;
        }
        const defaultRoleId = this.props.roles.filter(role => (role.roleName === this.state.roleName)).map((item) => {
            return item.roleId;
        });
        const roleList = [{ key: `${defaultRoleId}`, label: "Role: ", subLabel: `${this.state.roleName}`, items: [] }];

        const roles = this.props.roles.filter(role => (role.roleName !== this.state.roleName)).map((item) => {

            return { key: `${item.roleId}`, label: `${item.roleName}`, isRoleList: true };
        });

        const userMenu = [...roleList];
        if (this.props.roles.length > 1) {
            userMenu.push(...roles);
        }

        userMenu.push({ key: "profile", label: "Profile", route: "/UserProfile" });
        if (this.props.hasAdmin) {
            userMenu.push({ key: "admin", label: "Admin", route: "/Admin/DataViews" });
        }

        userMenu.push({ key: "signout", label: "Sign Out" });

        const user = {
            name: this.props.firstName,
            title: this.state.roleName,
            image: image,
            roleList: userMenu
        }

        return (<Profile expanded={true} user={user} handleClick={this.userMenuItemClick} ></Profile>);

    }

    render() {

        return (
          
            <div>
                {this.showUserMenu()}
            </div>

        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            loginActionCreators: bindActionCreators(loginActionCreators, dispatch),
            errorActionCreator: bindActionCreators(errorActionCreator, dispatch)
        }
    };
}
export default connect(state => state.Login, mapDispatchToProps)(userPanel);

userPanel.defaultProps = {
    roles: [],
};
