import { ActionTypes as types } from './actionTypes';
import api from 'admin/api/adminApi';
import { getError } from './errorActions';
import { showError, showSuccess } from './alertActions';

const apiGetSiteTheme = () => {
    return api.getSiteTheme();
}

const apiUpdateSiteTheme = (siteTheme) => {
    return api.updateSiteTheme(siteTheme);
}

const getSiteThemeSuccess = (resp) => {
    return {
        type: types.VGO_GET_SITE_THEME_SUCCESS,
        siteTheme: resp.data,
    }
}

const updateSiteThemeSuccess = (siteTheme) => {
    return {
        type: types.VGO_UPDATE_SITE_THEME_SUCCESS,
        siteTheme: siteTheme,
    }
}

export const updateSiteTheme = (siteTheme) => {
    return dispatch => {
        return apiUpdateSiteTheme(siteTheme)
            .then(resp => {
                dispatch(updateSiteThemeSuccess(siteTheme));
                showSuccess(dispatch, 'Success', 'Site theme saved!', 5000);
            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
                showError(dispatch, message, 5000);
            })
    }
}

export const getChangeSiteTheme = (change) => {
    return {
        type: types.VGO_CHANGE_SITE_THEME,
        change
    }
}

export const getSiteTheme = () => {
    return dispatch => {
        return apiGetSiteTheme()
            .then(resp => {
                dispatch(getSiteThemeSuccess(resp))
            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
            })
    }
}
