import React from 'react';
import { TextField } from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ErrorIcon from '@mui/icons-material/Error';
import * as Enum from 'client/components/Common/Enum';
import cloneDeep from 'lodash/cloneDeep'; 
import { withStyles } from '@mui/styles';
import { profileTheme } from 'client/components/UserProfile/theme/ProfileTheme';
const matchMessage = 'Match'
class password extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hidden: true,
            passwordClass: "HidePwdIcon",
            passwordHelper: cloneDeep(Enum.passwordHelp),
            passwordValidated: false,//
            newPassword: ""
        };
    } 
    componentDidMount() {
       // this.validatePasswordHelp(null, null, true, null);
    }
    toggleShow = () => {
        this.setState({
            hidden: !this.state.hidden

        });
    }

    validatePasswordHelp = (event, callBack, validate, secondValue) => {
        let newPassword = null;
        if (event) {
            event.preventDefault(); 
            newPassword= event.target.value;
      }

    let pHelperObject = this.state.passwordHelper;
        let validHelperCount = 0;
        if (validate) {
            if (!newPassword) {
                pHelperObject = cloneDeep(Enum.passwordHelp);
            } else {
                if (newPassword.length > 11) {
                    pHelperObject.find(o => o.Type === Enum.PasswordHelperCode.PasswordTooShort).Status = true;
                    validHelperCount += 1;
                } else {
                    pHelperObject.find(o => o.Type === Enum.PasswordHelperCode.PasswordTooShort).Status = false;
                    validHelperCount -= 1
                }
                let chkUpper = /[A-Z]/.test(newPassword)
                if (chkUpper) {
                    pHelperObject.find(o => o.Type === Enum.PasswordHelperCode.PasswordRequiresUpper).Status = true;
                    validHelperCount += 1;
                } else {
                    pHelperObject.find(o => o.Type === Enum.PasswordHelperCode.PasswordRequiresUpper).Status = false;
                    validHelperCount -= 1
                }
                let chkLower = /[a-z]/.test(newPassword)
                if (chkLower) {
                    pHelperObject.find(o => o.Type === Enum.PasswordHelperCode.PasswordRequiresLower).Status = true;
                    validHelperCount += 1;
                } else {
                    pHelperObject.find(o => o.Type === Enum.PasswordHelperCode.PasswordRequiresLower).Status = false;
                    validHelperCount -= 1
                }
                let chkNumber = /\d/.test(newPassword)
                if (chkNumber) {
                    pHelperObject.find(o => o.Type === Enum.PasswordHelperCode.PasswordRequiresDigit).Status = true;
                    validHelperCount += 1;
                } else {
                    pHelperObject.find(o => o.Type === Enum.PasswordHelperCode.PasswordRequiresDigit).Status = false;
                    validHelperCount -= 1
                }
                let chkSpecialChar = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(newPassword)
                if (chkSpecialChar) {
                    pHelperObject.find(o => o.Type === Enum.PasswordHelperCode.PasswordRequiresNonAlphanumeric).Status =
                        true;
                    validHelperCount += 1;
                } else {
                    pHelperObject.find(o => o.Type === Enum.PasswordHelperCode.PasswordRequiresNonAlphanumeric).Status =
                        false;
                    validHelperCount -= 1
                }
                let chkNoCon = /([a-z,A-Z,0-9,!,@,#,$,%,&,*,),(,+,=,.,_,-,',^,~,[,?,>,<,`])\1\1/ig.test(newPassword)
                if (!chkNoCon) {
                    pHelperObject.find(o => o.Type === Enum.PasswordHelperCode.NoMoreThan2ConsecutiveCharacters)
                        .Status =
                        true;
                    validHelperCount += 1;
                } else {
                    pHelperObject.find(o => o.Type === Enum.PasswordHelperCode.NoMoreThan2ConsecutiveCharacters)
                        .Status =
                        false;
                    validHelperCount -= 1;
                }
                const chkDoubleSpace = /([ ])\1/ig.test(newPassword)
                if (!chkDoubleSpace) {
                    pHelperObject.find(o => o.Type === Enum.PasswordHelperCode.NoConsecutiveSpaces).Status = true;
                    validHelperCount += 1;
                } else {
                    pHelperObject.find(o => o.Type === Enum.PasswordHelperCode.NoConsecutiveSpaces).Status = false;
                    validHelperCount -= 1;
                }
            }
            this.setState({ passwordValidated: validHelperCount === 7 ? true : false,passwordHelper:pHelperObject });
            if (callBack) {
                callBack(newPassword, pHelperObject, validHelperCount === 7 ? true : false)
            }
        } else {
            if (secondValue != null && newPassword !== secondValue) {
                this.setState({ passwordValidated: false });
                if (callBack) {
                    callBack(newPassword, pHelperObject, false)
                }
            } else {
                this.setState({ passwordValidated: true });
                if (callBack) {
                    callBack(newPassword, pHelperObject, true)
                }

            }
        }  
       
    } 

    handleBlur = (event, callBack) => { 
        this.setState({ newPassword: this.state.newPassword })
        if (event.relatedTarget !== null) {
            if (event.relatedTarget.id !== "idIcon") {
                if (event.target.value === "" || !this.state.passwordValidated) {
                    if (callBack) {
                        callBack(event, true);
                    }
                } else {
                    if (callBack) {
                        callBack(event, false);
                    }
                }
            }
        } else {
            if (!this.state.passwordValidated) {
                if (callBack) {
                    callBack(event, true);
                }
            } else {
                if (callBack) {
                    callBack(event, false);
                }
            }

        }
    }
    handleFocus = (event, callBack) => {
        this.validatePasswordHelp(event, null, this.props.validateHelper, this.props.value2);
        if (callBack) {
            callBack(event, false, this.props.value === "" ? Enum.passwordHelp : this.state.passwordHelper);
        }

    }
    getTextField(props) {
        return <TextField id={props.name}
            name={props.name}
            label={props.holderName}
            type={this.state.hidden ? "password" : "text"}
            inputProps={{ tabIndex: props.tabIndex }}
            value={props.value}
            style={{
                width: props.width, height: props.height }}
            onChange={event => this.validatePasswordHelp(event,props.handlePasswordChange,props.validateHelper,props.value2)}
            onBlur={event => this.handleBlur(event,props.handlePasswordBlur)}
            onFocus={event => this.handleFocus(event ,props.handlePasswordFocus)}
            variant="standard"
            size="small"
            fullWidth={true}
            autoFocus={props.autoFocus}
            placeholder={props.holderName}
            error={props.error}
            helperText={props.helperText} 
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            id="idIcon"
                            onClick={this.toggleShow}
                            tabIndex="-1"
                        >
                            {(this.state.hidden ? <VisibilityOffIcon /> : <VisibilityIcon />)
                            }</IconButton>
                    </InputAdornment>)
            }}
            InputLabelProps={{
                style: { zIndex: 100, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", right: "22px" }
            }}
            FormHelperTextProps={{
                classes: {
                    root: props.helperText===matchMessage?this.props.classes.passWordMatchColor:''
                }
            }}
        />
    }

    render() {
        return (
            this.getTextField(this.props)
        )
    }
}


//export default password;
export default withStyles(profileTheme)(password);