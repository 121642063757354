import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { styled } from '@mui/styles';
import { withStyles } from '@mui/styles';
import { spacing } from '@mui/system';
import { Grid, Typography, RadioGroup, Radio, FormControlLabel, Box, FormLabel, Checkbox } from '@mui/material';
import Autocomplete from '@mui/lab/Autocomplete';


import MuiButton from '@mui/material/Button';
import { databaseTheme } from './theme/databaseTheme.js';
import MuiCard from '@mui/material/Card';
import MuiTextField from '@mui/material/TextField';
import PasswordText from 'client/components/UserProfile/Password.js';
import { getTimeZoneList } from 'client/components/Common/Utility';
import { getDatabase, getChangeDatabase, getNewDatabase, validateOnBlur} from 'admin/actions/databaseActions';
import ActionBackdrop from 'admin/components/section/settings/Backdrop';
//const compare = (a, b) => a.localeCompare(b);

const languages = ['None', 'ln_English'];//.sort(compare);


const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);




class DatabaseEdit extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            isUpdate: this.props.match.params.databaseId !== "new",
            isSAP: false,
            timeZoneOptions: [],
            showPassword: false,
            showB1Password: false,
            disableB1Password: false,
            disablePassword: false
        };
    }
    handleSwitchChange = (e) => {
        this.setState({ isSAP: e.target.checked})
    }
    handlePasswordChange = (password, passwordHelper, validatedStatus) => {
        this.changeDatabase(database => database.password = password)
    }
    handlePasswordBlur = () => {

        this.blurValidation('password', { ...this.props.database, validatePassword:this.props.validatePassword});
    }

    componentDidMount() {
        const timeZoneNames = getTimeZoneList();
        let timeZoneOptions = [];
        timeZoneNames.map(function getTimeZone(timeZone) {
            return timeZoneOptions.push({ timeZone });

        });
        timeZoneOptions.unshift({ timeZone: 'None' });

        this.setState({
            timeZoneOptions: timeZoneOptions
        });

        let databaseId = this.props.match.params.databaseId;
        let isUpdate = databaseId !== "new"; 
        databaseId = isUpdate ? databaseId : null;
        if (isUpdate) {
            this.props.dispatch(getDatabase(databaseId));
            this.setState({
                disablePassword: !this.state.disablePassword,
                disableB1Password: !this.state.disableB1Password
            });
        } else
            this.props.dispatch(getNewDatabase());

    }
 
    componentDidUpdate(prevProps) {
       
        if (this.props.isUpdateSuccess !== prevProps.isUpdateSuccess) {
            console.log("componentDidUpdate", this.props.isUpdateSuccess, prevProps.isUpdateSuccess, this.props.database.databaseId > 0)
            if (this.props.isUpdateSuccess && this.props.database.databaseId> 0) {
                this.props.history.push(`/Admin/Databases/${this.props.database.databaseId}`);
            }
        }

    }
 
    changeDatabase = (change) => {
        this.props.dispatch(getChangeDatabase(change));
    }

    onLanguageChange = (event, value, reason) => {
        switch (reason) {
        case 'select-option':
        case 'remove-option':
                this.changeDatabase(database => database.language = value);
            break;

        default:
            break;

        }
    }

    handleClickShowPassword = () => {
        this.setState({
            showPassword: !this.state.showPassword
        });
    };
    handleClickShowB1Password = () => {
        this.setState({
            showB1Password: !this.state.showB1Password
        });
    };

   handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

   handleUpdateB1Password=(event) => {
       this.setState({
           disableB1Password: !this.state.disableB1Password
       });
       this.changeDatabase(database => database.b1Password = "");
    }

   handleUpdatePassword = (event) => {
       this.setState({
           disablePassword: !this.state.disablePassword
       });
       this.changeDatabase(database => database.password = "");
    }
   timeZoneChange = (event, value, ref) => {
       this.changeDatabase(database => database.timeZone = value.timeZone)
    }
    getSelectedTimeZone = (value) => {
        if (value !== "") {
            var val = this.state.timeZoneOptions.filter(pObj => pObj.timeZone === value)
            if (val !== null && val !== undefined && val.length > 0)
                return val[0];
        }
        return null;
    }
    disableIsMultiTenant = () => {
       return  this.props.database.databaseType === 'hana' ? false : true;
    }
    blurValidation = (fieldName, value,dependField) => {

        this.props.dispatch(validateOnBlur(fieldName, value, fieldName, dependField));
    }
   render() {
       
        const { classes } = this.props;
        
        //const selectedType = (!this.props.database.databaseType) ? 'sqlServer' : 'hana';
        const selectedType = this.props.database.databaseType;
        return (
            <Card variant="outlined" > 
                {this.props.isLoading &&  <ActionBackdrop />} 
                <Grid container ml={3} mb={33.25} mt={ 3.125} justifyContent="space-between">
                <Grid item xs={4}>  
                            <Grid container spacing={2}>
                                <Grid item xs={12}><Typography className={classes.body1}>Connection Info</Typography></Grid>
                               
                            <Grid item xs={9} className={classes.connectionName}>
                                <TextField autoFocus
                                                required 
                                                label="Name"
                                                size="small"
                                                fullWidth={true}
                                                value={this.props.database.databaseConnectionName}
                                                onChange={event => this.changeDatabase(database => database.databaseConnectionName = event.target.value)}
                                                error={this.props.errors.databaseConnectionName} helperText={this.props.errors.databaseConnectionName ? this.props.errors.databaseConnectionName : ' '}
                                                onBlur={(event) => {
                                                    this.blurValidation('databaseConnectionName', this.props.database.databaseConnectionName);

                                    }}
                                                inputProps={{ maxLength: 200 }}
                                            >
                                                </TextField>  
                                    </Grid> 
                            <Grid item xs={6} className={classes.timeZoneOptions} >
                                 
                                    <Autocomplete
                                        size="small"
                                        fullWidth={true}
                                        options={this.state.timeZoneOptions}
                                        value={this.getSelectedTimeZone(this.props.database.timeZone)}
                                        getOptionLabel={option => option.timeZone}
                                        onChange={this.timeZoneChange}
                                        renderInput={params => (
                                            <TextField {...params}

                                                size="small"
                                                label="Time Zone"
                                                placeholder="Time Zone" />
                                        )}
                                    /> 
                                </Grid> 
                            <Grid item xs={12} className={classes.serverInfoTitle}><Typography className={classes.body1}>Server Info</Typography></Grid>
                            <Grid item xs={12} alignItems="center" className={classes.serverTypeOptions}>
                                <Grid container direction="row"  >
                                    {/*<Grid item  >*/}
                                    {/*    <Box pt={1}  > <FormLabel classes={{ root: classes.body1 }}  >Type:</FormLabel></Box>*/}
                                    {/*</Grid>*/}
                                    <Grid item  >
                                        <Box pl={0} >
                                            <Grid container direction="row"  >
                                                <RadioGroup row aria-label="position" name="position" value={selectedType}
                                                    onChange={event => this.changeDatabase(database => {
                                                        database.databaseType = event.target.value
                                                        database.isMultiTenant = event.target.value !== "hana" ? false : database.isMultiTenant
                                                    })}>
                                                    <FormControlLabel value="sqlServer" control={<Radio color="primary" />} label={<Typography className={classes.body2} >SQL</Typography>} />
                                                    <Box pl={1}><FormControlLabel value="hana" classes={{ root: classes.body2 }} control={<Radio color="primary" />} label={<Typography className={classes.body2} >HANA</Typography>} /></Box>
                                                </RadioGroup>
                                                <Box pl={3}><FormControlLabel control={<Checkbox checked={this.props.database.isMultiTenant}
                                                    disabled={this.disableIsMultiTenant()} onChange={event => this.changeDatabase(database => database.isMultiTenant = event.target.checked)} />} label="Multi-tenant" /></Box>


                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>


                            </Grid>
                            <Grid item xs={9} className={classes.serverName}>
                                 
                                <TextField inputProps={{ maxLength: 250 }}
                                            required
                                    error={this.props.errors.serverName} helperText={this.props.errors.serverName ? this.props.errors.serverName : ' '}
                                            label="Server Name"
                                            size="small"
                                            fullWidth={true}
                                            value={this.props.database.serverName}
                                    onChange={event => this.changeDatabase(database => database.serverName = event.target.value)}
                                    onBlur={(event) => {
                                        this.blurValidation('serverName', this.props.database.serverName);

                                    }}
                                        >
                                        </TextField> 
                                    </Grid>
                            <Grid item xs={9} className={classes.tenantName}>
                                   
                                <TextField inputProps={{ maxLength: 200 }}
                                    required={this.props.database.isMultiTenant}
                                    error={this.props.database.isMultiTenant ? this.props.errors.tenantName : false} helperText={this.props.database.isMultiTenant? this.props.errors.tenantName ? this.props.errors.tenantName : ' ':' '}
                                            label="Tenant Name"
                                            size="small"
                                    fullWidth={true}
                                    disabled={!this.props.database.isMultiTenant}
                                            value={this.props.database.tenantName}
                                    onChange={event => this.changeDatabase(database => database.tenantName = event.target.value)}
                                    onBlur={(event) => {
                                        this.blurValidation('tenantName', this.props.database);

                                    }}
                                        >
                                        </TextField> 
                                    </Grid>
                                <Grid item xs={9} className={classes.databaseName}>
                                  
                                <TextField inputProps={{ maxLength: 250 }}
                                                        required
                                                        fullWidth={true}
                                                        label="Database Name"
                                                        size="small"
                                    error={this.props.errors.database} helperText={this.props.errors.database ? this.props.errors.database : ' '}
                                                        value={this.props.database.database}
                                    onChange={event => this.changeDatabase(database => database.database = event.target.value)}
                                    onBlur={(event) => {
                                        this.blurValidation('database', this.props.database.database);

                                    }}
                                                    >
                                                    </TextField> 
                                                </Grid>
                            <Grid item xs={9} className={classes.user}>
                                   
                                        <Grid container spacing={1} direction="row" justifyContent="space-between">
                                            <Grid item xs={4}><Box width={200}>
                                        <TextField inputProps={{ maxLength: 50 }}
                                                    required 
                                                    label="User ID"
                                                    size="small"
                                            error={this.props.errors.userId} helperText={this.props.errors.userId ? this.props.errors.userId : ' '}
                                                    value={this.props.database.userId}
                                            onChange={event => this.changeDatabase(database => database.userId = event.target.value)}
                                            onBlur={(event) => {
                                                this.blurValidation('userId', this.props.database.userId);

                                            }}
                                                >
                                                </TextField></Box>
                                            </Grid>
                                            <Grid item xs={4} className={classes.serverInfoItem}><Box width={200}>
                                        <PasswordText inputProps={{ maxLength: 1000 }}
                                                    id="newPassword"
                                                    name="newPassword"
                                            required={this.props.validatePassword}
                                            label={`Password ${this.props.validatePassword ? "*" : ""}` }
                                                    handlePasswordChange={this.handlePasswordChange}
                                            holderName={  `Password ${this.props.validatePassword ? "*" : ""}`}
                                            value={this.props.database.password}
                                            error={this.props.validatePassword && this.props.errors.password} helperText={this.props.errors.password ? this.props.errors.password : ' '}
                                            handlePasswordBlur={this.handlePasswordBlur}
                                            
                                                /></Box>
                                               
                                            </Grid> 
                                          </Grid> 
 
                                </Grid>
                                   
                            </Grid>
                        {/*</CardContent>*/}
                    {/*</Card>*/}
                </Grid> 
                <Grid item xs={8}  >
                        <Box mr={68.17} mt={1} ml={ 5.83}>
                                    <TextField
                                        label="Notes"
                                        size="small"
                                        multiline
                                        rows={3}
                                        fullWidth={true}
                                value={this.props.database.description}
                                        inputProps={{ maxLength: 1000 }}
                                        onChange={event => this.changeDatabase(database => database.description = event.target.value)}
                                    >
                                    </TextField>
                                </Box>
                            </Grid>

                       
                    </Grid>
               
            </Card>
        );
    }
}

function mapStateToProps(state) {
    console.log("database Edit State", state);
     return {
         databases: state.databases.databases,
         database: state.databases.database,
         isLoading: state.loader.isLoading,
         hasError: state.error.hasError,
         errorMessage: state.error.errorMessage,
         isUpdateSuccess: state.databases.isUpdate,
         errors: state.databases.errors ? state.databases.errors : {},
         validatePassword: state.databases.validatePassword,
     };
 };

export default withRouter(connect(mapStateToProps)(withStyles(databaseTheme)(DatabaseEdit)));


