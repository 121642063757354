import { customThemeStyles } from 'common/theme.js';
export const fieldDisplayTheme = (theme) => ({
    ...customThemeStyles,
    card: {
        height: "100%",
        marginLeft: theme.spacing(-3) +'!important',
        marginRight: theme.spacing(-3) + '!important',
        marginTop: theme.spacing(-3.1) + '!important',
        padding: '0px!important'
    },
    tableRow: {
        "&$selected, &$selected:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.08)!important"
        }
    },
    selected: {},
    stickyHeader: {
        backgroundColor: "#fff!important",
        paddingBottom: '17px!important',
        color: '#000000!important',
    },
    tableContainer: {
        marginTop: '48px!important',
        //maxHeight: 560,
        maxWidth: '1576px!important',
    },
    tableBody: {
        overflow: "scroll",
        maxHeight: '150px!important'
    },
    table: {

        tableLayout: 'fixed'
    },
    button: {
        textAlign: "right!important",
        width: "100%!important",
        paddingTop: '40px!important',
        paddingRight: '40px!important',
        paddingBottom: '40px!important'
    },
    buttonHighlight: {
        borderRadius: '25px!important',
        backgroundColor: theme.palette.primary.light + " !important",
        textTransform: 'none!important',
        color: '#fff!important',
        width: '90px!important',
        height: '24px!important',
    },
    buttonHighlighted: {
        borderRadius: '25px!important',
        backgroundColor: customThemeStyles.form_detail_highlight.backgroundColor + " !important",
        textTransform: 'none!important',
        width: '105px!important',
        height: '24px!important',
    },
    iconHighlighted: {
        fontSize: "17px !important",
        color: "#0000005C!important",
    },
    buttonHighlightEndIcon: {
        marginLeft: '5px!important',
    },
    buttonHighlightStartIcon: {
        marginRight: '3px!important',
        marginLeft: '-7px!important',
    },
    buttonStyle: {
        width: '153px!important',
        height: '32px!important',
    },
    dragIconWidth: {
        textAlign: 'center!important',
        width: '58px!important',
    },
    visibleWidth: {
        width: '64px!important',
    },
    sortWidth: {
        width: '122px!important',
    },
    typeWidth: {
        width: '80px!important',
    },
    aggregateWidth: {
        width: '122px!important',
    },
    thumbnailWidth: {
        width: '68px!important',
    },
    fileNameWidth: {
        width: '122px!important',
    },
    localizationWidth: {
        width: '78px!important',
    },
    fieldNameWidth: {
        width: '148px!important',
        textAlign: "left!important"
    },
    displayFieldNameWidth: {
        width: '121px!important',
    },
    displayLabelHeaderWidth: {
        width: "130px !important",
    },
    showLabelHeaderWidth: {
        width: "80px!important",
        paddingLeft: "0px!important"
    },
    highlightsHeaderWidth: {
        paddingLeft: "6px!important"
    },
    dragIconStyle: {
        paddingTop: '2px!important',
        paddingBottom: '7px!important',
        textAlign: 'center!important',
        width: '58px!important',
       /* paddingLeft: '0px!important',*/
        paddingRight: '0px!important',
        color: '#323232!important',
    },
    visibleStyle: {
        width: '64px!important',
        paddingLeft: '6px!important',
        paddingRight: '7px!important',
        paddingTop: '2px!important',
        paddingBottom: '7px!important',
        textAlign: 'left!important'
    },
    fieldNameStyle: {
        width: '158px!important',
        paddingLeft: '16px!important',
        paddingRight: '0px!important',
        textAlign: 'left!important',
        paddingTop: '2px!important',
        paddingBottom: '7px!important',
        color: '#000000',
    },
    displayLabelStyle: {
        width: '142px!important',
        paddingLeft: '15px!important',
        paddingRight: '0px!important',
        textAlign: 'left!important',
        paddingTop: '2px!important',
        paddingBottom: '7px!important',
    },
    showLabelStyle: {
       /* width: '69px!important',*/
        paddingRight: '0px!important',
        textAlign: 'left!important',
        paddingTop: '2px!important',
       /* paddingLeft: '17px!important',*/
        paddingBottom: '7px!important',
    },
    aggregateStyle: {
        width: '149px!important',
        paddingLeft: '12px!important',
        paddingRight: '0px!important',
        textAlign: 'left',
        paddingTop: '2px!important',
        paddingBottom: '7px!important',
    },
    groupByStyle: {
        width: '68px!important',
        paddingLeft: '0px!important',
        paddingRight: '0px!important',
        textAlign: 'left!mportant',
        paddingTop: '2px!important',
        paddingBottom: '7px!important',

    },
    sortStyle: {
        width: '146px!important',
        paddingLeft: '16px!important',
        paddingRight: '0px!important',
        textAlign: 'left!important',
        paddingTop: '2px!important',
        paddingBottom: '7px!important',
    },
    displayTypeStyle: {
        width: '100px!important',
        paddingLeft: '16px!important',
        paddingRight: '0px!important',
        textAlign: 'left!important',
        paddingTop: '2px!important',
        paddingBottom: '7px!important',
    },
    thumbnailStyle: {
        width: 89,
        paddingLeft: '16px!important',
        paddingRight: '0px!important',
        textAlign: 'left',
        paddingTop: '2px!important',
        paddingBottom: '7px!important',
    },
    localizationStyle: {
        width: '99px!important',
        paddingLeft: '14px!important',
        paddingRight: '0px!important',
        textAlign: 'left!important',
        paddingTop: '2px!important',
        paddingBottom: '7px!important',
    },
    fileNameStyle: {
        width: '146px!important',
        paddingLeft: '14px!important',
        paddingRight: '0px!important',
        textAlign: 'left',
        paddingTop: '2px!important',
        paddingBottom: '7px!important',
    },
    highlightsStyle: {
        width: '154px!important',
        paddingLeft: '16px!important',
        paddingRight: '0px!important',
        textAlign: 'left!important',
        paddingTop: '2px!important',
        paddingBottom: '7px!important',
    },
    fieldWrap: {
        flexWrap: 'wrap!important',
        textAlign: 'left!important',
        overflowWrap:'break-word!important'
    }
});