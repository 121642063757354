import { showError } from 'client/components/Common/AlertAction';
import { ServerErrorCode,  FavoriteStatus } from 'client/components/Common/Enum';
const requestObjects = 'REQUEST_OBJECTS';
const receiveObjects = 'RECEIVE_OBJECTS';

const requestBanners = 'REQUEST_BANNERS';
const receiveBanners = 'RECEIVE_BANNERS';

const requestNews = 'REQUEST_NEWS';
const receiveNews = 'RECEIVE_NEWS';

const invalidAuth = "INVALID_AUTH";
const receiveLandingPage = 'RECEIVE_LANGING_PAGE';

const resetFavorite = "RESET_FAVORITE";

const initialState = {
    actions: [],
    favorites: [],
    menu: [],
    isLoading: false,
    roleId: 0,
    actionStatus: '',
      
};

export const actionCreators = {
    requestObjects: (roleId) => async (dispatch, getState) => {

        dispatch({ type: requestObjects });
        const url = `api/Object/GetObjects/${roleId}`;
        const response = await fetch(url);
        if (response.status === ServerErrorCode.SuccessStatus) {
            const objects = await response.json();
            dispatch({
                type: receiveObjects,
                actions: objects.objectNavigation.actions,
                favorites: objects.objectNavigation.favorites,
                menu: objects.objectNavigation.menu,
                roleId: roleId,
               
            });
        }
        else {
            if (response.status === ServerErrorCode.NoContent) {
                showError(dispatch, 'The Role you selected is not available to this account. To confirm available Roles, refresh your browser', 5000);
            }
            else if (response.status === ServerErrorCode.UnauthorizedStatus) {
                dispatch({
                    type: invalidAuth,
                    authAction: { auth: false, actionName: "GetObjects" }
                });
            }
            else {
                showError(dispatch, 'Object menu loading failed.', 5000);
            }
            dispatch({
                type: receiveObjects,
                actions: [],
                favorites: [],
                menu: [],
                roleId: 0
            });
        }
    },
    requestPublicObjects: () => async (dispatch, getState) => {

        dispatch({ type: requestObjects });

        const url = 'api/Object/GetPublicObjects';
        const response = await fetch(url);
        if (response.status === ServerErrorCode.SuccessStatus) {
            const objects = await response.json();
            dispatch({
                type: receiveObjects,
                actions: objects.objectNavigation.actions,
                favorites: objects.objectNavigation.favorites,
                menu: objects.objectNavigation.menu,
                roleId: 0
            });
        } else {

            dispatch({
                type: "SHOW_ERROR",
                message: "Public object menu loading failed"
            });
            dispatch({
                type: receiveObjects,
                actions: [],
                favorites: [],
                menu: [],
                roleId: 0
            });

        }


    },
    
    requestPublicBanners: () => async (dispatch, getState) => {
        dispatch({ type: requestBanners });
        const url = 'api/Banner/GetPublicBanners';
        const response = await fetch(url);
        if (response.status === ServerErrorCode.SuccessStatus) {
            const banners = await response.json();
            dispatch({
                type: receiveBanners,
                banners: banners.banners
            });
        }
        else {

            dispatch({
                type: "SHOW_ERROR",
                message: "Public banner list loading failed"
            });
            dispatch({
                type: receiveBanners,
                banners: []
            });

        }

    },
    requestBanners: (roleId) => async (dispatch, getState) => {
        dispatch({ type: requestBanners });
        const url = `api/Banner/GetBanners/${roleId}`;
        const response = await fetch(url);
        if (response.status === ServerErrorCode.SuccessStatus) {
            const banners = await response.json();
            dispatch({
                type: receiveBanners,
                banners: banners.banners

            });
        }
        else {
            if (response.status === ServerErrorCode.UnauthorizedStatus) {
                dispatch({
                    type: invalidAuth,
                    authAction: { auth: false, actionName: "GetBanners" }
                });
            }
            else {
                dispatch({
                    type: "SHOW_ERROR",
                    message: "Banner list loading failed"
                });
                dispatch({
                    type: receiveBanners,
                    banners: []
                });
            }

        }
    },

    requestPublicNews: () => async (dispatch, getState) => {
        dispatch({ type: requestNews });
        const url = 'api/News/GetPublicNews';
        const response = await fetch(url);
        if (response.status === ServerErrorCode.SuccessStatus) {
            const news = await response.json();
            dispatch({
                type: receiveNews,
                news: news.newsAndAnnouncements
            });
        }
        else {

            dispatch({
                type: "SHOW_ERROR",
                message: "Public news list loading failed"
            });
            dispatch({
                type: receiveNews,
                news: []
            });

        }
    },
    requestNews: (roleId, actionSource) => async (dispatch, getState) => {
        
            dispatch({ type: requestNews });
            const url = `api/News/GetNews/${roleId}`;
            const response = await fetch(url);
            if (response.status === ServerErrorCode.SuccessStatus) {
                const news = await response.json();
                dispatch({
                    type: receiveNews,
                    news: news.newsAndAnnouncements,
                    landingObjectType: news.objectType,
                    objectId: news.objectId,
                    actionSource: actionSource
                });
            }
            else {
                if (response.status === ServerErrorCode.UnauthorizedStatus) {
                    dispatch({
                        type: invalidAuth,
                        authAction: { auth: false, actionName: "GetNews" }
                    });
                }
                else {
                    dispatch({
                        type: "SHOW_ERROR",
                        message: "News list loading failed"
                    });
                    dispatch({
                        type: receiveNews,
                        news: []
                    });
                }

            }
         
    },

    resetLayout: (roleId) => async (dispatch, getState) => {       
        dispatch({
            type: 'RECEIVE_LAYOUT',
            isLogin: true,
            actionSource: 'Initial',
            roleId: roleId
        });

    },

    resetLandingPage: () => async (dispatch, getState) => {
        dispatch({
            type: receiveLandingPage,
                 });

    },
    setLeftMenuState: (isMenuCollapsed) => async (dispatch, getState) => {       
        dispatch({
            type: 'RECEIVE_LEFTMENU',
            isMenuCollapsed: isMenuCollapsed,
        });

    },

}

const resetFavoriteList = (favoriteList, favoriteSettings) => {
    //console.log('resetFavoriteList', favoriteList)
    let favList = [];
    if (favoriteSettings.removedObjectId) {
        //const type = favoriteSettings.objectType.toLowerCase();
        const type = favoriteSettings.type.toLowerCase();
        favList = favoriteList.filter(fav => !(fav.id === favoriteSettings.removedObjectId && fav.type.toLowerCase() === type));
        
    }
    else
    {
        if (favoriteSettings.status === FavoriteStatus.Delete) {
            const type = favoriteSettings.type.toLowerCase();
             favList = favoriteList.filter(fav => !(fav.id === favoriteSettings.id && fav.type.toLowerCase() === type)  )
            
        }
        else
        {
            const newItem = { id: favoriteSettings.id, name: favoriteSettings.name, type: favoriteSettings.type, status: 'add', icon: favoriteSettings.icon };
            favList= [...favoriteList, newItem];
        }
    }
    return sortFavoriteList(favList);
}

const sortFavoriteList = (favList) => {
    if (favList) {
        favList.sort((a, b) => {
            const nameA = a.name.toUpperCase(); // ignore upper and lowercase
            const nameB = b.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            // names must be equal
            return 0;
        });
        return favList;
    }
    return [];
}
export const reducer = (state, action) => {

    state = state || initialState;

    if (action.type === requestObjects) {
        return {
            ...initialState,
            isLoading: true,
            actionStatus: requestObjects,
        };
    }
    else if (action.type === receiveObjects) {
        return {
            ...state,
            isLoading: false,
            actions: action.actions,
            favorites: sortFavoriteList(action.favorites),
            menu: action.menu,
            roleId: action.roleId,
            actionStatus: receiveObjects,


        };
    }
    else if (action.type === resetFavorite) {
        return {
            ...state,
            favorites: resetFavoriteList(state.favorites, action.favoriteSettings)
        }
    }
    

    return state;
}