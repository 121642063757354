
import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import {  AppBar, Tabs, Tab, Box } from '@mui/material';

import { getNewForm, changeFormTab, getForm } from 'admin/actions/formSetupActions';
import { getForms } from 'admin/actions/formActions';
import FormSetup from 'admin/components/section/form/setup/setup';
import FormUI from 'admin/components/section/form/formLayoutUI/FormUI';
import { setupTheme } from 'admin/components/section/dataViews/setup/theme/setupTheme';
 


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}
class FormEdit extends React.Component {

    constructor(props) {
        super(props);
        let formId = this.props.match.params.formId;
        let id = null;
        let isUpdate = formId !== "new";
        if (isUpdate) {
            id = parseInt(formId);
            if (isNaN(id)) {
                // TODO: error on page?
                id = null;
            }
        }
        this.state = {
            formId: isUpdate ? id : null,
            isUpdate: isUpdate
        };
    }
    handleChange = (event, newValue) => {

        if (!this.props.hasError || newValue===1) {
            this.props.dispatch(changeFormTab(newValue));
        }
    };
    componentDidMount() {
        let formId = this.props.match.params.formId;
        let isUpdate = formId !== "new";
        formId = isUpdate ? formId : null ;
        this.dispatchGetForm(formId);
        if (this.props.forms.length === 0) {
            this.props.dispatch(getForms());
        }
        
    }
    dispatchGetForm = (formId) => {
        let id = parseInt(formId);
        if (isNaN(id)) {
            // TODO: error on page?
            id = null;
        }
        if (id) {
                this.props.dispatch(getForm(id));
        }
        else {
            this.props.dispatch(getNewForm());
        }
    }  
    componentDidUpdate() {
       
        if (this.props.isUpdateSuccess) {
            window.history.replaceState(null, "Form", `/Admin/Forms/${this.props.form.formId}`)
        }
    }
    render() {
        return (
           
                    <div>
                        <AppBar position="static" className={this.props.classes.tabRoot}  >
                            <Tabs value={this.props.activeTabIndex} onChange={this.handleChange} >
                        <Tab label="SETUP" {...a11yProps(0)} className={this.props.classes.body2} classes={{ root: this.props.classes.formSetupTab }}  />
                        <Tab label="LAYOUT" {...a11yProps(1)} disabled={this.props.form.isClone} className={this.props.classes.body2} classes={{ root: this.props.classes.formSetupTab }} />
                            </Tabs>
                        </AppBar>
                        <Box mt={1}>
                            <TabPanel value={this.props.activeTabIndex} index={0}>
                                <FormSetup/>
                            </TabPanel>
                            <TabPanel value={this.props.activeTabIndex} index={1}>
                                <FormUI />
                            </TabPanel>
                          </Box>
                    </div> 
                
        );
    }

}

/**
 * 
 * @param {State} state 
 */
function mapStateToProps(state) {
    let formState = state.forms;
   
    let props = {
        forms: formState.forms,
        form: formState.form,
        activeTabIndex: formState.activeTabIndex,
        isLoading: state.loader.isLoading,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage,
        isUpdateSuccess: formState.updateStatus,
    };
    
    return props;
};

export default withRouter(connect(mapStateToProps)(withStyles(setupTheme)(FormEdit)));

/**
 * Default properties for the DataViewEdit component
 */
FormEdit.defaultProps = {
    textFieldVariant: 'standard',
};

/**
 * Property types for the DataViewEdit properties
 */
FormEdit.propTypes = {
    textFieldVariant: PropTypes.oneOf([
        'filled',
        'outlined',
        'standard',
    ]),
};


