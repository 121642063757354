import React from 'react';
import { connect } from 'react-redux';
import { styled } from '@mui/styles';
import { withStyles } from '@mui/styles';
import { spacing } from '@mui/system';
import { Box, Grid, Typography } from '@mui/material';
import MuiButton from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check'
import { withRouter } from 'react-router';
import { updateRole, hideErrorMessage, discardRole } from 'admin/actions/roleActions';
import { ActionTypes as types } from 'admin/actions/actionTypes';
import AlertMessage from 'admin/components/Common/AlertMessage';
import { getAlertHide } from 'admin/actions/alertActions';
import { rolesTheme } from './theme/rolesTheme.js';
const Button = styled(MuiButton)(spacing);

class RoleEditHeader extends React.Component {

	constructor(props) {
		super(props);
		this.isUpdate = this.props.match.params.roleId !== "new";
	}
    discardClick = () => {
        this.props.dispatch(discardRole());
        this.props.dispatch(hideErrorMessage());
        this.props.history.push("/Admin/Roles");
    }
    onSaveClick = () => {
        if (!this.props.isLoading) {

            this.props.dispatch(updateRole(this.props.roles.role, this.props.roles));
        }
    }
    componentDidUpdate() {
        this.isUpdate = this.props.match.params.roleId !== "new";;
        
    }
    
    render() {
        let alert = this.props.alert; 
        return (
          
            <Grid container direction="row" justifyContent="space-between" >
               
					<Grid item>
						<Typography variant="h4">{this.isUpdate ? "Edit" : "Add"} Role</Typography>
                    </Grid>
                <Grid item>

					<Grid container direction="row" spacing={1}>
						<Grid item>
                            <Button
                                className={this.props.classes.customControlButton + ' ' + this.props.classes.buttonCancel} width={89}
                                variant="defaultContained"
								disableElevation onClick={this.discardClick}>CANCEL</Button>
						</Grid>
						<Grid item>
                            <Button
                                className={this.props.classes.customControlButton + ' ' + this.props.classes.buttonSave} 
                                color="primary"
                                variant="contained"
                                 
								disableElevation 
                                    startIcon={<CheckIcon />} onClick={this.onSaveClick}>SAVE</Button>
						</Grid>
						</Grid>
					</Grid>
				</Grid>
            
        );
    }

}

function mapStateToProps(state) {
    return {
        roles: state.roles,
        isLoading: state.loader.isLoading,
        alert: state.alert,
    };
};
export default withRouter(connect(mapStateToProps)(withStyles(rolesTheme)(RoleEditHeader)));
