import React, { useRef, useState, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import {  IconButton, Box, Grid } from '@mui/material';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import { customColorPalette } from 'common/theme';
import { fileUploadTheme } from './theme/fileUploadTheme'
import { spacing } from '@mui/system';
import MuiTextField from '@mui/material/TextField';
import { styled } from '@mui/styles';
const TextField = styled(MuiTextField)(spacing);

const FileUpload = (props) => {
    const { placeholder, accept, onFileUploadChange, classes, fileName,reset } = props;
    const inputRef = useRef(null);
    const [value, setValue] = useState('');

    useEffect(() => {
        if (!reset)
        {
            setValue(value ? value : fileName)
        }
        else
        {
            setValue('')
        }
    }, [fileName, value])

    const handleChange = (event) => {
        const file = event.target.files[0];

        if (file) {
        setValue(file.name)
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            onFileUploadChange(reader.result, file.name);
        };
        reader.onerror = error => {
            console.log("Error: ", error);
        };
        }
        event.target.value = null
    };
    return (
        <Grid container spacing={2}>
            <Grid item xs={10}>
                <TextField
                    id="fileUpload"
                    name="fileUpload"
                    variant="standard"
                    disabled
                    size="small"
                    placeholder={placeholder}
                    className={props.isHomePage ? classes.inputThemeUpload : classes.inputUpload}
                    value={value}
                    InputProps={{
                        readOnly: true,
                        endAdornment: (
                            <IconButton disabled={props.disabled}
                                aria-label="upload"
                                className={classes.fileUploadIcon}
                                component="label"
                            >
                                <DriveFolderUploadIcon fontSize="medium" style={{ color: customColorPalette.black[54] }} />
                                <input
                                    hidden
                                    accept={accept}
                                    ref={inputRef}
                                    type="file"
                                    onChange={handleChange}
                                />

                            </IconButton>
                        ),
                    }}
                />
            </Grid>
            <Grid item xs={2}>
                <Box ml={2}>
                    {(value) ? <CheckCircleOutlinedIcon fontSize="medium" className={classes.checkCircleIcon} /> :
                        <DoDisturbAltIcon fontSize="medium" className={classes.doNotDisturbIcon} />}
                </Box>
            </Grid>
        </Grid>

    );
}

export default withStyles(fileUploadTheme)(FileUpload);