import React from 'react';

import { styled } from '@mui/styles';
import { withStyles } from '@mui/styles';
import { spacing } from '@mui/system';
import { Grid, Typography } from '@mui/material';
import MuiButton from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check'

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { updateUser } from 'admin/actions/userActions';
import { usersTheme } from './theme/usersTheme.js';

const Button = styled(MuiButton)(spacing);

class UserEditHeader extends React.Component {
    
    constructor( props ) {
        super(props);
       this.isUpdate = this.props.match.params.userId !== "new";
    }
    componentDidUpdate() {
        this.isUpdate = this.props.match.params.userId !== "new";
    }

onDiscardClick = () => {
		this.props.history.push( '/Admin/Users' );
	}

	onSaveClick = () => {
        this.props.dispatch(updateUser(this.props.user, this.props.filterData ) );
	}

    render() {
        let alert = this.props.alert;
        console.log('FilterEditHeader - alert', alert);
        return (
            <Grid container direction="row" justifyContent="space-between" spacing={0} xl={12}>
                <Grid item>
                    <Typography variant="h4">{this.isUpdate ? "Edit" : "Add"} User</Typography>
                </Grid>

                <Grid item >
                    <Grid container direction="row" justifyContent="space-between" spacing={1} >
                        <Grid item >
                            <Button
                                className={this.props.classes.customControlButton }
                                variant="defaultContained"
                                disableElevation
                                startIcon={""}
                                onClick={this.onDiscardClick}
                            >CANCEL</Button>
                        </Grid>
                        <Grid item >
                            <Button
                                className={this.props.classes.customControlButton}
                                color="primary"
                                variant="contained"
                                disableElevation
                                startIcon={<CheckIcon />}
                                onClick={this.onSaveClick}
                            >SAVE</Button>
                        </Grid> </Grid>
                </Grid>
            </Grid>
        );
    }

}

/**
 * mapStateToProps
 * @param {State} state 
 */
function mapStateToProps( state ) {
	let props = {
         alert: state.alert,
        user: state.users.user,
         filterData: state.users.filterData,
     };
     console.log( 'UserEditHeader - mapStateToProps state: ', state, ', props: ', props );
     return props;
};

export default withRouter(connect(mapStateToProps)(withStyles(usersTheme)(UserEditHeader)));
