import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { errorActionCreator } from 'client/store/Error';
class errorHandler extends React.Component {
    
    handleClose = () => {
        this.props.closeError();
        if (this.props.redirectProfile === true) {
            window.location = '/UserProfile';
        }
    };
    render() {

        const buttonStyle = "delete-confirm btn btn-primary errorButtonStyle";
        return (
            <div >
                <Dialog className="messageDialog" open={this.props.showError} aria-labelledby="customized-dialog-title">
                    <DialogTitle className="messageTitle"> { "Error" }</DialogTitle>
                    <MuiDialogContent dividers> <span className="messageTextStyle">
                        {this.props.messageQueue}</span>
                    </MuiDialogContent>

                    <DialogActions>
                        <Button className={buttonStyle}  onClick={this.handleClose} >OK</Button>
                    </DialogActions>
                </Dialog> 
            </div>
            );
    }
}
export default connect(
    state => state.error,
    dispatch => bindActionCreators(errorActionCreator, dispatch)
)(errorHandler);