import { customThemeStyles } from 'common/theme.js';
export const siteTheme = (theme) => ({
    ...customThemeStyles,
    colorStyle: {
        width: '24px!important',
        height: '24px!important',
        flexDirection: 'row!important',
        marginBottom: '12px!important'
    },

    header: {
        padding: '23px',
        paddingBottom: '19px',
    },
    card: {
        marginTop: '-2px'
    },
    preview: {
        height: '800px',
        marginTop: '6px'
    }
});