import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { Typography, Box, Grid } from '@mui/material';
import { databaseFieldsTheme} from 'common/theme/databaseFieldsTheme';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { ObjectType } from 'client/components/Common/Enum';
import { DragDropContainer } from 'react-drag-drop-container';

class ListTemplate extends React.Component {
    constructor(props) {
        super(props);
    }

    checkQuickAction = (id, contentType) => {
        let checked = false;
        let rowIndex = this.props.quickActions.findIndex(a => a.id === id && a.contentType === contentType);
        if (rowIndex !== -1) {
            checked = true;
        }
        return checked;
    }
    checkMainMenuAction = (id, contentType) => {
        let checked = false;
        let rowIndex = this.props.menuStructure.findIndex(s => s.id === id && s.contentType === contentType);
        if (rowIndex !== -1) {
            checked = true;
        } else {
            checked = false;
            this.props.menuStructure.map((node) => {
                if (!checked) {
                    if (node.items.length > 0) {
                        rowIndex = node.items.findIndex(s => s.id === id && s.contentType === contentType);
                        if (rowIndex !== -1) {
                            checked = true;
                        } else {
                            checked = false;
                            node.items.map((subNode1) => {
                                if (!checked) {
                                    if (subNode1.items.length > 0) {
                                        rowIndex = subNode1.items.findIndex(s => s.id === id && s.contentType === contentType);
                                        if (rowIndex !== -1) {
                                            checked = true;
                                        } else {
                                            checked = false;
                                            subNode1.items.map((subNode2) => {
                                                if (!checked) {
                                                    if (subNode2.items.length > 0) {
                                                        rowIndex = subNode2.items.findIndex(s => s.id === id && s.contentType === contentType);
                                                        if (rowIndex !== -1) {
                                                            checked = true;
                                                        } else {
                                                            checked = false;
                                                        }
                                                    }
                                                }
                                            });
                                        }
                                    }
                                }
                            });
                        }
                    }
                }
            });
        }
        return checked;
    }
    shouldComponentUpdate(nextProps) {
        if ( nextProps.dragData.item.id === this.props.rowData[0] && nextProps.dragData.item.contentType === this.props.rowData[5] ||
            nextProps.dragData.item.id === "" && nextProps.dragData.item.contentType === "" || nextProps.dragData.item.contentType === 0) {
            return true;
        } else {
            return false;
        }
    }
    
    render() {
        let [
            id,
            name,
            subType,
            quickActionChecked,
            mainMenuChecked,
            contentType,
        ] = this.props.rowData;

        quickActionChecked = this.checkQuickAction(id, contentType);
        mainMenuChecked = this.checkMainMenuAction(id, contentType);

        let type = ObjectType.Report;
        if (contentType === ObjectType.Form) {
            type = ObjectType.Form;
        } else if (contentType === ObjectType.Dashboard) {
            type = ObjectType.Dashboard;
        }

        const dragData = {
            contentSubType: subType,
            contentType: contentType,
            id: id,
            items: [],
            label: name,
            index: this.props.rowIndex,
            type: type,
            quickActionChecked: quickActionChecked,
            mainMenuChecked: mainMenuChecked,
            isList: true,
            isNav: false,
            isMenu:true,
        }
        
        return (
            <DragDropContainer dragElemOpacity={"1"} dragClone={ true} dragHandleClassName="drag" dragData={dragData} onDrop={this.props.handleDrop} targetKey="content">
                <Box className={this.props.classes.menuBox} sx={{ backgroundColor: "white" }}>
                    <Grid container>
                        <Grid item xs={1} pt={.375} pl={1}>
                            <DragHandleIcon className="drag" fontSize="small" />
                        </Grid>
                        <Grid item xs={7.7} pt={.5}>
                            <Typography variant="caption" >{name}</Typography>
                        </Grid>
                        <Grid item xs={2.5} pt={.5} className={this.props.classes.textAlign}>
                            <Typography variant="caption" >
                                {subType}</Typography>
                        </Grid>
                        <Grid item xs={.8} pt={.1} pl={2}>
                            <Box mt={-.25} mb={-1.25}>
                                {quickActionChecked ? <FiberManualRecordIcon fontSize="small" className={this.props.classes.menuDotIcon} /> : <Box height={20}></Box>}
                            </Box>
                            <Box >
                                {mainMenuChecked ? <FiberManualRecordIcon fontSize="small" className={this.props.classes.menuDotIcon} /> : <div></div>}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </DragDropContainer>
        );
    }
}

function mapStateToProps(state) {
    return {
        quickActions: state.menus.menu.quickActions,
        menuStructure: state.menus.menu.menuStructure,
        dragData: state.menus.dragData,
    };
};
export default withRouter(connect(mapStateToProps)(withStyles(databaseFieldsTheme)(ListTemplate)));
