import React from 'react';
import { Typography,  Stack } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
class joke extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            heading: "",
            text: "" 
        };
    }
    componentDidMount() {
        if (this.props.widget.apiUrl !== '') {
            fetch(this.props.widget.apiUrl)
                .then(res => res.json())
                .then((result) => {
                    this.setState({
                        error:false,
                        isLoaded: true,
                        heading: this.props.widget.description,
                        joke: result.value 
                    });
                },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error,
                            heading: "",
                            joke: "" 
                        });
                    });
        }
    }
    renderJoke = (widget) => {

        return (
            <div>
                <header className={this.props.classes.widgetHead}>
                    <Stack direction="row" alignItems="center" gap={1}>
                        <LinkIcon className={this.props.classes.widgetHeaderIconColor} />
                        <Typography className={this.props.classes.widgetHeadContent}>{this.state.heading}</Typography>
                    </Stack>
                </header>
                <div className={this.props.classes.widgetBody}>
                    <Typography id="idchuck" >{this.state.joke}</Typography>
                </div>
            </div>
            );
    }
    render() {
        return (
            <li key={this.props.widget.type} className={this.props.classes.widget}>
            {this.renderJoke(this.props.widget)}
    </li>
    );
}
}
export default joke;