import { produce } from 'immer';
import { ActionTypes as types } from 'admin/actions/actionTypes';

import { newUser, newFilter, updateFilterValue } from 'admin/actions/userActions';

const initialState = {
    users: [],
    roles: [],
    user: newUser(),
    filterData: newFilter(),
    isUpdate: false,
    countryList: [],
    dataIndex: 0,
    isLoading:false,
};

const users = produce( ( draft, action ) => {

    //console.log( 'users reducer - action: ', action );

    draft.isUpdate = false;

    switch ( action.type ) {
        case types.VGO_GET_USERS_REQUEST:
            draft.isLoading = true;
            break;
        case types.VGO_GET_USERS_SUCCESS:
            draft.users = produce(action.users, draftUsers => { });
            draft.isLoading = false;
            draft.errors = [];
            break;
        case types.VGO_GET_ROLES_SUCCESS:
            draft.roles = produce( action.roles, draftRoles => {} );
            break;
        case types.VGO_GET_USER_FILTERS_SUCCESS:
            draft.filters = action.filters;
            break;
        case types.VGO_GET_USER_FILTER_REQUEST:
            draft.filter = {};
            draft.dataIndex = action.dataIndex;
            break;
        case types.VGO_GET_USER_FILTER_SUCCESS:
            draft.filter = action.filter;
            break;
        case types.VGO_GET_USER_FILTER_UPDATE_SUCCESS:

            draft.filterData = updateFilterValue(action);
            break;

        case types.VGO_UPDATE_USER_SUCCESS:
            draft.isUpdate = true;  // eslint-disable-next-line
            draft.user = action.user;
        case types.VGO_GET_USER_SUCCESS:
            let user = action.user;
            draft.filterData = action.user.filter
            if ( draft.users.length > 0 ) {
                let userIndex = draft.users.findIndex( u => u.userId === action.user.userId );
                if ( userIndex >= 0 ) {
                    draft.users[ userIndex ] = user;
                }
                else {
                    draft.users.push( user );
                }
            }
            draft.user = user;
            break;

        case types.VGO_NEW_USER:
            draft.user = newUser();
            draft.filterData = newFilter();
            break;

        case types.VGO_CHANGE_USER:
            action.change( draft.user );
            break;

        case types.VGO_DISCARD_USER:
            if ( draft.user.userId > 0 ) {
                let userIndex = draft.users.findIndex( u => u.userId === draft.user.userId );
                if ( userIndex >= 0 ) {
                    draft.user = draft.users[ userIndex ];
                }
                else {
                    draft.user = newUser();
                    draft.filterData = newFilter();
                }
            }
            else {
                draft.user = newUser();
                draft.filterData = newFilter();
            }
            break;  
        case types.VGO_GET_COUNTRY_SUCCESS:
            draft.countryList = action.countryList;
            break;
        case types.VGO_VALIDATE_USER_ON_BLUR:

            draft.errors = produce(draft.errors, draftErrors => { return { ...draftErrors, ...action.errors } });
            break;

        default:
            break;

    }

}, initialState );

export default users;
