
const requestContentDetail = 'REQUEST_CONTENT_DETAIL';
const receiveContentDetail = 'RECEIVE_CONTENT_DETAIL';

const initialState = { isLoading: false,hasContent:false };

export const actionCreators = {

    requestPublicContentById: (Id) => async (dispatch, getState) => {

        dispatch({ type: requestContentDetail });
        const url = `api/News/GetPublicNewsById/${Id}`;
        const response = await fetch(url);
        if (response.status === 200) {
            const contentDetail = await response.json();
            dispatch({ type: receiveContentDetail, hasContent:true, contentDetail });
        }
        else {

            dispatch({
                type: "SHOW_ERROR",
                message: "News details loading failed"
            });
            dispatch({ type: receiveContentDetail, hasContent: false });

        }

    },
    requestContentById: (Id) => async (dispatch, getState) => {

        dispatch({ type: requestContentDetail });
        const url = `api/News/GetNewsById/${Id}`;
        const response = await fetch(url);
        if (response.status === 200) {
            const contentDetail = await response.json();
            dispatch({ type: receiveContentDetail, hasContent: true, contentDetail });
        }
        else {

            dispatch({
                type: "SHOW_ERROR",
                message: "News details loading failed"
            });
            dispatch({ type: receiveContentDetail, hasContent: false });

        }

    }
};



export const reducer = (state, action) => {
    state = state || initialState;
    if (action.type === requestContentDetail) {
        return {
            ...state,
            isLoading: true,
            hasContent: false
    };
    }
    if (action.type === receiveContentDetail) {
        return {
            ...state,
            isLoading: false,
            hasContent: action.hasContent,
            contentDetail: action.contentDetail
            
        };

    }

    return state;
}