import * as yup from "yup";
 
export const databaseSetUpSchema = yup.object().shape({
    databaseConnectionName: yup.string().required("Connection Name is required"),
    serverName: yup.string().required("Server Name is required"),
    database: yup.string().required("Database Name is required"),
    userId: yup.string().required("User Id is required"),
    password: yup.string().when(["validatePassword"], (validatePassword) => {
        if (validatePassword) {
            return yup.string().test('password',
                "Password is required",
                function (value) {
                    return !value ? false : true;
                })
           
        }
    }) ,
    tenantName: yup.string().when(["isMultiTenant"], (isMultiTenant) => {
        if (isMultiTenant) {
            return yup.string().test('tenantName',
                'Tenant Name is required',
                function (value) {
                    return !value ? false : true;
                })
        } else {
            return yup.string().test('tenantName',
                'Tenant Name is required',
                function (value) {
                    console.log(value)
                    return true;
                }).nullable(true)
        }
    })
})
