import React from 'react'; 
import { Loading } from 'client/components/Loading'; 
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { Typography,  Stack } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Cookies from 'universal-cookie';
import * as moment from 'moment'
const cookies = new Cookies();


class weather extends React.Component {

    constructor(props) {
        super(props);
        var existingState = cookies.get("weatherLast");
        if (existingState !== undefined) {
            this.state = {
                error: null,
                Loading: false,
                zipcode: existingState.zipcode,
                name: existingState.name,
                region: existingState.region,
                country: existingState.country,
                image: existingState.image,
                date: existingState.date,
                tem_c: existingState.tem_c,
                temp_f: existingState.temp_f,
                temp: existingState.temp,
                day: existingState.day,
                moment: existingState.moment
            };
            var stateMoment = this.state.moment;
            var currentMoment = moment();
            var duration = currentMoment.isAfter(stateMoment, 'hour'); 
            if (duration) {
                this.fetchweather();
            }
        } else {
            this.state = {
                error: null,
                Loading: false,
                zipcode: "",
                name: "",
                region: "",
                country: "",
                image: "",
                date: "",
                tem_c: "",
                temp_f: "",
                temp: "",
                day: "",
                moment:""
            };
        }
        this.fetchweather = this.fetchweather.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.webcallFetch = 0;
    }
     
    handleChange=e=>{
    this.setState({ zipcode: e.target.value });
    }

    handleClearState=(Loading, error)=> {
        this.setState({
            Loading: Loading,
            error: error,
            name: "",
            image: "",
            region: "",
            country: "",
            temp_c: "",
            temp_f: "",
            day: "",
            date: "",
            temp: "",
            moment:""
        });
    }

    fetchweather = () => {
        const inputValue = this.state.zipcode;
        if (inputValue ) {
            var apiurl = this.props.widget.apiUrl.replace("[ApiId]", this.props.widget.apiKey).replace("[zipCode]", inputValue);
            this.handleClearState(true, null);
            fetch(apiurl)
                .then(res => res.json())
                .then((result) => {
                    if (result.error === undefined) { 
                        if (result.hasOwnProperty("current") && result.current.hasOwnProperty("condition")) {
                            const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };

                            this.setState({
                                error: null,
                                Loading: false,
                                name: result.location.name,
                                region: result.location.region,
                                country: result.location.country,
                                image: result.current.condition.icon,
                                temp_c: result.current.temp_c,
                                temp_f: result.current.temp_f,
                                day: result.current.condition.text,
                                date: (new Date()).toLocaleDateString('en-US', DATE_OPTIONS),
                                temp: result.current.temp_c + "°C/" + result.current.temp_f + "°F",
                                moment:moment()
                            });
                            cookies.set("weatherLast", this.state, { path: '/', maxAge: 2592000 });
                        } else { this.handleClearState(false, null); }
                    }
                    else {
                        this.handleClearState(false, result.error); 
                    }
                },
                    (error) => { 
                        this.handleClearState(false, error);
                        if (this.webcallFetch < 3) { this.webcallFetch = this.webcallFetch + 1; this.fetchweather(); } else { this.webcallFetch = 0; }
                    }
                );
        } else {
            this.handleClearState(false, null);
        }
       
    }
   
    renderWeather = (widget) => {
        
        return (
            <div>
                <header className={this.props.classes.widgetHead}>
                    <Stack direction="row" alignItems="center" gap={1}>
                        <LinkIcon className={this.props.classes.widgetHeaderIconColor} />
                        <Typography className={this.props.classes.widgetHeadContent}>Weather API</Typography>
                    </Stack>
                </header>
                <div className={this.props.classes.widgetBody}>
                    <div> 
                        <Paper className={this.props.classes.root}>
                            
                            <InputBase
                                className={this.props.classes.input}
                                placeholder="City/Zip Code"
                                onChange={this.handleChange}
                                value={this.state.zipcode}
                                disabled={(this.props.isSiteTheme) ? true : false}
                            />
                            <IconButton color="primary" className={this.props.classes.iconButton} aria-label="search" style={{ color: this.props.themeColor }} onClick={this.fetchweather} disabled={(this.props.isSiteTheme) ? true : false}>
                                <SearchIcon className={this.props.classes.searchIcon} />
                            </IconButton>
                        </Paper>

                        

                    </div>
                    <Box pt={.5}>
                        <Loading  loading={this.state.Loading} />
                        <header className={ this.props.classes.weatherHead}>
                            <img alt="" id="idwthricon"
                            className={this.props.classes.weatherIcon}
                            src={this.state.image} />
                            <Typography className={this.props.classes.weatherDegrees}>{this.state.temp}</Typography>
                            <Typography>{this.state.day}</Typography>
                        </header>
                        <div className={this.props.classes.weatherBody}>
                            <Typography variant="body1">{this.state.date !== '' && "Today is"} {this.state.date}</Typography>
                            <p style={{ color: "red" }}>{this.state.date === '' && this.state.error !== null && "OOPS! " + this.state.error.message +".Please try again later."} <strong>{}</strong></p>
                        </div>
                    </Box>
                </div>
            </div>
            );
    }

    render() { 
        return (
            <li key={this.props.widget.type} className={this.props.classes.widget}>
                    {this.renderWeather(this.props.widget)}
            </li>
    );
}
}
//export default withStyles(useStyles)(weather);
export default (weather);