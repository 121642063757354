import { customThemeStyles } from 'common/theme.js';
export const defaultRoleColor = "#FFFFFF";
export const defaultRoleBGColor = "#43A047";
export const usersTheme = () => ({
    ...customThemeStyles,
    avatarPic: {
        minWidth: "48.33px!important",
        minHeight: "48.33px!important"
    },
    avatarPicBox: {
        paddingTop: "20px!important"
    },
    statusSwitch: {
        height: "30px!important",

    },
    filterTable: {
        width: "100%!important",
        borderBottom: "none!important"
    },
    MUIDataTable: {
        paper: {
            width: '100%!important',
            maxWidth: '100%!important'
        }
    },
    profileInfoSec1: {
        maxHeight: "200px!important" //if removed the date stamp will get expanded down

    },
    dateSection: {
        // maxWidth: "199px"
        marginTop: "17px!important",
        height: "51px!important"
    },
    MuiTableCell: {
        root: {
            borderBottom: "none!important"
        }
    },
    dateElementPadding: {
        paddingRight: "10px!important"
    },
    dateElementPaddingUpdate: {
        paddingRight: "6px!important"
    },
    contactInfoItem: {
        paddingTop: '0px!important'
    },
    defaultColumn: { maxWidth: "300px!important", wordBreak: "break-all!important" },

    responsiveScrollMaxHeight: {
        maxHeight: 'calc(100vh - 210px) !important',
    }
});
