import React from 'react';
import DatabaseFields from 'common/DatabaseFields';
import { ObjectType } from 'client/components/Common/Enum';
import { Grid, Popover, Button } from '@mui/material';
import MuiCard from '@mui/material/Card';
import AddIcon from '@mui/icons-material/Add';
import { withStyles } from '@mui/styles';
import { formUITheme } from 'admin/components/section/form/formLayoutUI/theme/formUITheme.js';
import { connect } from 'react-redux';
import { getDashboardItemListByRoleList } from 'admin/actions/dashboardActions';
import { ActionTypes as types } from 'admin/actions/actionTypes';

class DashboardItemList extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            anchorElement: null,
        };
        
    }
    handleAddPanelClick = (event) => {
        
        this.dispatchGetDashboardItems();
        this.setState({ anchorElement: event.currentTarget })

    }
    closedashboardItemList = () => {
        this.setState({ anchorElement: null })
    }
    dispatchGetDashboardItems = () => {
        let roleList = "";
        for (let i = 0; i < this.props.dashboard.roles.length; i++) {
            roleList = `${roleList}roleIdList[${i}]=${this.props.dashboard.roles[i].roleId}&`

        }
        if (roleList !== this.props.dashboardItems.roles) {
            this.props.dispatch(getDashboardItemListByRoleList(roleList));
        }

    }
    dashboardItemList = (classes) => {
        const open = Boolean(this.state.anchorElement);
        
        return (
            <Popover p={3} disableScrollLock={true}
                open={open}
                anchorEl={this.state.anchorElement}
                onClose={this.closedashboardItemList}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MuiCard>
                    <Grid container >
                        <Grid item xs={12} className={classes.controlTabRoot}>
                          
                                <DatabaseFields
                                    TableDataField={this.props.dashboardItems.items}
                                    isFromMenu={false}
                                    isFromDashBoard={true}
                                    addPanel={this.props.addPanel}
                                    isDataLoading={this.props.dashboardActionStatus == types.VGO_GET_DASHBOARDITEMLIST_REQUEST}
                                    objectType={ObjectType.Dashboard} />
                           
                        </Grid>
                    </Grid>
                    
                </MuiCard>
            </Popover>
        );
    }
    render() {
        const { classes } = this.props;
        return (
            <Grid container direction="row" spacing={4}>
                <Grid item xs={2}></Grid>
                <Grid item xs={10} ml={-1}>
                    <Button className={this.props.addPanelButtonClassName}
                        variant="contained"
                        color="primary"
                        disableElevation
                        startIcon={<AddIcon />}
                        onClick={this.handleAddPanelClick}
                    >PANEL</Button>
                    {this.dashboardItemList(classes)}

                </Grid>
            </Grid>
            )
    }

}
function mapStateToProps(state) {


    return {
        dashboard: state.dashboards.dashboard,
        dashboardItems: state.dashboards.dashboardItems,
        dashboardActionStatus: state.dashboards.dashboardActionStatus,
    };
};
export default connect(mapStateToProps)(withStyles(formUITheme)(DashboardItemList));