import React from 'react';
import defaultFile from 'client/assets/css/images/icon-file-default.svg';
import docFile from 'client/assets/css/images/icon-file-doc.svg';
import imgFile from 'client/assets/css/images/icon-file-img.svg';
import pdfFile from 'client/assets/css/images/icon-file-pdf.svg';
import pptFile from 'client/assets/css/images/icon-file-ppt.svg';
import txtFile from 'client/assets/css/images/icon-file-txt.svg';
import xlsFile from 'client/assets/css/images/icon-file-xls.svg';
import xmlFile from 'client/assets/css/images/icon-file-xml.svg';
import * as enumerator from 'client/components/Common/Enum';
import { getDeviceType, actionFileDownload, convertAllRuleFormula, formatDateCulture, formatDate, formatExactTime } from 'client/components/Common/Utility';
import * as Enums from 'client/components/Common/Enum';
import ImageTemplate from 'client/components/Form/Controls/Image';
import CheckboxControl from 'client/components/Form/Controls/CheckBox';
import { Loading } from 'client/components/Loading';
import { Typography, Link } from '@mui/material';
import { eval as Evaluate, parse as parseExpression} from 'expression-eval';
const deviceType = getDeviceType();
class columnTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            downloadingFile: false
        };
    }

    setColumnSettings = (data, column) => {

        let templates = null;

        if (data !== undefined) {
            let dataValue = (data[column.fieldName]) ? data[column.fieldName] : (data[column.fieldName] === 0) ? data[column.fieldName] : "";
            if (column.fieldType === enumerator.DbType.Date || column.fieldType === enumerator.DbType.DateTime) {
                dataValue = (dataValue !== null) ? dataValue.toString() : '';
            }
            
            let formattedValue = data[column.fieldName];
            const textStyle = {
                textAlign: column.textAlign ? (deviceType === Enums.DeviceType.Mobile ? 'left' : column.textAlign): "left",
            }
            if (column.controlType === enumerator.ControlType.MultiText1) {
               
                textStyle.whiteSpace = "pre-line";
            }
            if (dataValue && typeof dataValue === 'object') {
                dataValue = dataValue.toString();
            }
            templates = <Typography variant="body2" style={textStyle} >{dataValue === "" ? column.actionName : dataValue}</Typography>;
            if (this.props.actionType === true) {

                if (column.fieldFormat !== enumerator.FormatType.None) {
                    if (column.fieldFormat === enumerator.FormatType.Date || column.fieldFormat === enumerator.FormatType.DateTime) {
                        formattedValue = this.props.formatValue(column, dataValue.toString());
                    }
                    else
                    {
                        if (column.fieldType === enumerator.DbType.Varchar || column.fieldType === enumerator.DbType.Nvarchar) {
                            dataValue = dataValue.replace(/<[^>]+>|&nbsp;/g, " ").trim();
                        }
                        formattedValue = this.props.formatValue(column, dataValue);
                    }
                    templates = <Typography variant="body2" style={textStyle}>{formattedValue ? formattedValue : column.actionName}</Typography>;
                }
                else if (column.fieldType === enumerator.DbType.Varchar || column.fieldType === enumerator.DbType.Nvarchar && dataValue) {
                    dataValue = dataValue.replace(/<[^>]+>|&nbsp;/g, " ").trim();
                    templates = <Typography variant="body2" style={textStyle} >{dataValue}</Typography>;
                }
                if (column.kpi)
                {
                    if (column.kpi.length > 0) {
                        templates = this.getKpi(dataValue, formattedValue, column.kpi);
                    }
                }

                templates = this.columnLinkTemplate(templates ,data);
            }
            else if (column.fieldFormat !== enumerator.FormatType.None || column.format === enumerator.FormatType.Image || column.format === enumerator.FormatType.Checkbox) {

                if (column.fieldFormat === enumerator.FormatType.Url) {
                    templates = this.columnUrlTemplate(dataValue);
                } else if (column.fieldFormat === enumerator.FormatType.File) {
                    templates = this.columnFileFormatTemplate(data, column);
                }
                else if (column.fieldFormat === enumerator.FormatType.Image) {
                    templates = this.columnImageTemplate(data, column);
                }
                else if (column.fieldFormat === enumerator.FormatType.CheckBox) {
                    templates = this.checkBoxTemplate(data, column);
                }
                else {
                    if (column.fieldFormat !== enumerator.FormatType.None) {
                        if (column.fieldFormat === enumerator.FormatType.Date || column.fieldFormat === enumerator.FormatType.DateTime || column.fieldFormat === enumerator.FormatType.Time) {
                            formattedValue = this.props.formatValue(column, dataValue.toString());
                        } else {
                            formattedValue = this.props.formatValue(column, dataValue === "" ? column.actionName : dataValue);
                        }
                        templates = <Typography variant="body2" style={textStyle}>{formattedValue}</Typography>;
                    }
                    else {
                        if (column.fieldType === enumerator.FormatType.Date || column.fieldType === enumerator.FormatType.DateTime.toLowerCase() || column.fieldType === enumerator.FormatType.Time) {
                            formattedValue = this.props.formatValue(column, dataValue.toString());
                            templates = <Typography variant="body2" style={textStyle}>{formattedValue}</Typography>;
                        }
                       
                    }
                    if (column.kpi) {
                        if (column.kpi.length > 0) {
                            templates = this.getKpi(dataValue, formattedValue, column.kpi);
                        }
                    }

                }
            }

            else if (column.kpi && column.kpi.length > 0) {
                templates = this.getKpi(dataValue, formattedValue, column.kpi);
            }
            
        }
        return templates;

    }

    columnUrlTemplate = (dataValue) => {
        return (<a href={dataValue} target="_blank"> {dataValue} </a>);
    }
    
    columnLinkTemplate = (dataValue,  data) => {
        const actionId = this.props.column.actionId;
        var conditionValidation = true;
        var conditionFormula = this.props.column.conditionFormula;
        if (conditionFormula.length > 0) {
            var tempData = { ...data };
            for (var rpt in this.props.reportOutputFields) {
                var obj = this.props.reportOutputFields[rpt];
                var culture = "YYYY-MM-DDTHH:MM:SS";
                var isDate = true;
                if (obj.fieldFormat == "time") {
                    culture = "HH:mm:ss";
                    isDate = false;
                }
               // if (data[obj.fieldName]!=null && data[obj.fieldName]!=="") {
                    tempData[obj.fieldName] = isDate ? formatDate(culture, data[obj.fieldName]) : formatExactTime(culture, data[obj.fieldName]);
                    conditionFormula = conditionFormula.split(' ').join(' ');
                    var splt = conditionFormula.split(new RegExp("\\b" + obj.fieldName + "\\b"))
                    for (var iCount = 1; iCount < splt.length; iCount++) {
                        var splValue = splt[iCount].split('"')
                        const tempObjectKey = `formatedDateObject_${obj.fieldId}_${iCount}`
                        const tempObjectKeySecond = `formatedDateObjectSecond_${obj.fieldId}_${iCount}`
                        if (splValue.length > 1 && splValue[1] != "") {
                            var formatedDateObject = isDate ? formatDateCulture(splValue[1], obj.fieldFormat !== "none" ? obj.culture : "") : formatExactTime(obj.fieldFormat !== "none" ? obj.culture : "", splValue[1]);
                            splValue[1] = tempObjectKey;
                            if (splValue[0].includes("between")) {
                                var formatedDateObjectSecond = isDate ? formatDateCulture(splValue[3], obj.fieldFormat !== "none" ? obj.culture : "") : formatExactTime(obj.fieldFormat !== "none" ? obj.culture : "", splValue[3]);
                                    //formatDateCulture(splValue[3], obj.fieldFormat !== "none" ? obj.culture : "");
                                splValue[3] = tempObjectKeySecond;
                                tempData[tempObjectKeySecond] = isDate ? formatDate(culture, formatedDateObjectSecond) : formatExactTime(culture, formatedDateObjectSecond); //formatedDateObjectSecond
                            }
                            tempData[tempObjectKey] = isDate ? formatDate(culture, formatedDateObject) : formatExactTime(culture, formatedDateObject);//formatedDateObject
                        }
                        splt[iCount] = splValue.join('"');
                        splt[iCount] = splt[iCount].replace('"' + tempObjectKey + '"', tempObjectKey).replace('"' + tempObjectKeySecond + '"', tempObjectKeySecond);

                    }
                    conditionFormula = splt.join(obj.fieldName);
                //}
               
            }
            let fieldNameSpace = conditionFormula.match(/(?<=\[)[^\][]*(?=])/g);
            for (let key in tempData) {
                if (fieldNameSpace) {
                    for (const fieldName of fieldNameSpace) {
                        if (key === fieldName) {
                            const newField = fieldName.replace(/\s/g, '')
                            tempData[newField] = tempData[key];
                            delete tempData[key];
                        }
                    }
                }
            }
            conditionFormula = convertAllRuleFormula(conditionFormula).trim(); 
            const ast = parseExpression(conditionFormula); // abstract syntax tree (AST)
            conditionValidation = Evaluate(ast, tempData); // 2.4
            
        }
        
        if (conditionValidation) {
            return (<Link
                component="button"
                variant="body2"
                tabindex="-1"
                onClick={() => {
                    //actionHandler()
                    this.props.handleLinkTemplateClick(actionId, data)
                    //console.info("I'm a button.");
                }} >
                {dataValue}
            </Link>)
        } else {
           // console.log("tempData", conditionFormula, tempData);
            return (<div>{dataValue}</div> )
         }
      //  return (<a href={dataValue}> {dataValue} </a>);
    }

    columnImageTemplate = (data, column) => {
        var src = data[column.fieldName];
        return (<ImageTemplate showCaption={false} width={50} height={50} showHover={true} imageSize={column.thumbnailImageType} deviceType={deviceType} url={src} alt={column.fieldName} />);
    }

    checkBoxTemplate = (data, column) => {
        return (<CheckboxControl isEditable={false} showCaption={false} isGridColumn={column.isGridColumn ? column.isGridColumn:""} settings={column} dataModel={data} />)
    }

    columnFileFormatTemplate = (data, column) => {
        var filepath = "";
        var fileExtension = "";
        let vgoRowId = data["VgoRowId"];
        let dataValue = data[column.fieldName];
        filepath = dataValue;
        if (!filepath) {
            filepath = data[column.fileTypeField]
        }
        
        if (filepath) {
            if (filepath.includes('.')) {
                let bFieldName = filepath.split('.');
                if (bFieldName.length>1) {
                    fileExtension = bFieldName[bFieldName.length-1];
                }
                const fileSettings = { fileDataField: column.fieldName, fileNameField: column.fileTypeField, fileValue: dataValue }

                switch (fileExtension) {
                    case "rtf":
                    case "doc":
                    case "docx":
                        {
                            return (<img alt="" style={{ cursor: 'pointer' }} src={docFile} width={25} height={22} onClick={() => this.invokeDownloadFile(fileSettings, vgoRowId)} />)
                        }
                    case "pdf":
                        {
                            return (<img alt="" style={{ cursor: 'pointer' }} src={pdfFile} width={25} height={22} onClick={() => this.invokeDownloadFile(fileSettings, vgoRowId)} />);
                        }
                    case "image":
                    case "jpeg":
                    case "jpg":
                    case "png":
                        {
                            return (<img alt="" style={{ cursor: 'pointer' }} src={imgFile} width={25} height={22} onClick={() => this.invokeDownloadFile(fileSettings, vgoRowId)} />);
                        }
                    case "ppt":
                        {
                            return (<img alt="" style={{ cursor: 'pointer' }} src={pptFile} width={25} height={22} onClick={() => this.invokeDownloadFile(fileSettings, vgoRowId)} />);
                        }
                    case "txt":
                        {
                            return (<img alt="" style={{ cursor: 'pointer' }} src={txtFile} width={25} height={22} onClick={() => this.invokeDownloadFile(fileSettings, vgoRowId)} />);
                        }
                    case "xls":
                        {
                            return (<img alt="" style={{ cursor: 'pointer' }} src={xlsFile} width={25} height={22} onClick={() => this.invokeDownloadFile(fileSettings, vgoRowId)} />);
                        }
                    case "xml":
                        {
                            return (<img alt="" style={{ cursor: 'pointer' }} src={xmlFile} width={25} height={22} onClick={() => this.invokeDownloadFile(fileSettings, vgoRowId)} />);
                        }
                    default:
                        {
                            return (<img alt="" style={{ cursor: 'pointer' }} src={defaultFile} width={25} height={22} onClick={() => this.invokeDownloadFile(fileSettings, vgoRowId)} />);
                        }
                }
            }
        }
    }

    invokeDownloadFile = (fileSettings, vgoRowId) => {

        this.setState({ downloadingFile: true });
        actionFileDownload(fileSettings, vgoRowId, this.props.dataFilter).then(response => {
            this.setState({ downloadingFile: false });
            if (response === false) {
                this.props.showErrorMessage("File downloading failed.")
            }

        });

    }
    getKpi = (value, formatedValue, kpi) => {
        for (let i = 0; i < kpi.length; i++) {
            let element = kpi[i];
            if (element.relationalOperator === enumerator.KpiRelation.LessThan) {
                if (parseFloat(value) < parseFloat(element.firstValue)) {
                    return this.getKpiIndicator(element, formatedValue);

                }
            }
            else if (element.relationalOperator === enumerator.KpiRelation.GreaterThan) {
                if (parseFloat(value) > parseFloat(element.firstValue)) {
                    return this.getKpiIndicator(element, formatedValue);

                }
            }
            else if (element.relationalOperator === enumerator.KpiRelation.Between) {
                let cellValue = parseFloat(value);
                if (cellValue >= parseFloat(element.firstValue) && cellValue <= parseFloat(element.secondValue)) {
                    return this.getKpiIndicator(element, formatedValue);
                }
            }
            else if (element.relationalOperator === enumerator.KpiRelation.EqualTo) {
                if (parseFloat(value) === parseFloat(element.firstValue)) {
                    return this.getKpiIndicator(element, formatedValue);

                }
            }

        }
        return formatedValue;

    };
    getKpiIndicator = (kpi, formatedValue) => {

        if (kpi.displayType === enumerator.KpiDisplayType.Cell) {
            return (<p style={{ backgroundColor: kpi.icon, display: 'block', width: '100%' }}>{formatedValue}</p >);
        }
        else if (kpi.displayType === enumerator.KpiDisplayType.Text) {

            return (<p style={{ color: kpi.icon }}>{formatedValue}</p>);
        } else {
            return (
                <div style={{ display: 'inline-flex' }}>
                    <div style={{ display: 'block', paddingRight: "8px", marginTop: "-3px" }}>
                        <svg height="20" width="20" ><circle cx="10" cy="10" r="10" fill={kpi.icon}></circle></svg>

                    </div>
                    <div>
                        <p>{formatedValue}</p>
                    </div>
                </div>
            );
        }
    };


    render() {
        const template = this.setColumnSettings(this.props.data, this.props.column);

        if (this.state.downloadingFile) {
            return (<div key={"container" + this.props.column.fieldId}><Loading loading={true} /></div>);
        } else
            return (<div key={"container" + this.props.column.fieldId}>{template}</div>);
    }
}


export default columnTemplate;