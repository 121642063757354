import { customThemeStyles, customColorPalette, mainTheme, theme,  } from 'common/theme.js';

export const menuTheme = () => ({
    ...customThemeStyles,
    header: {
        padding: '23px',
        marginBottom: '-21px',
    },
    card: {
        marginTop: '-2px',
        paddingBottom: '24px'
    },
    tabShadow: {
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.12), 0px 4px 4px rgba(0, 0, 0, 0.24)"
    },
   
    tabRoot: {
        background: `${mainTheme.palette.common.white} !important`,
        /* height:'40px'*/
    },
    menuColor: {
        color: customColorPalette.black[54],
        paddingLeft: "8px",
    },
    buttonStyle: {
        textAlign: "right",
        marginTop: "-16px !important",
        paddingRight: "24px !important"
    },
    dataViewTab: {
        minWidth: '160px!important',
    },
    formTab: {
        minWidth: '160px!important',
    },
    dashboardTab: {
        minWidth: '209px!important',
    },
    DatabaseFieldsStyle: {
        paddingLeft: "31px",
        paddingTop: "10px",
    },
    
    expandIcon:{
        marginRight: "-70px",
        marginTop: "-3px",
        zIndex: 999,
    },
    addIcon: {
        height: "32px !important",
        width: "32px !important"
    },
    messagePosition: {
        position: "absolute",
        paddingTop: "100px",
        paddingLeft: "9px",
        zIndex: 1001,
    }
   
});
export const menuTreeviewTheme = () => ({
    content: {
        backgroundColor: theme.palette.common.white + '!important',
        '&:hover': {
            backgroundColor: theme.palette.action.hover + '!important',
        },
        '&.Mui-selected': {
            backgroundColor: theme.palette.grey[200] + '!important',
        },
    },
    group: {
        marginLeft: "42px !important",
    },

    caption: {
        fontFamily: "Roboto!important",
        fontSize: "12px!important",
        fontStyle: "normal!important",
        fontWeight: "400!important",
        letterSpacing: "0.4px!important",

    },
    contentQuickAction: {
        backgroundColor: theme.palette.common.white + '!important',
    },
    label: {
        fontWeight: 'inherit',
        color: 'inherit',
        marginLeft: "-7px !important",
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0),
    },
    labelText: {
        fontWeight: 'inherit',
        flexGrow: 1,
        display: 'inline-block',
    },
    contentTypeText: {
        fontWeight: 'inherit',
        flexGrow: 1,
        display: 'inline-block',
        width: '135px',
        marginLeft: '10px',
    },
    contentSubTypeText: {
        fontWeight: 'inherit',
        flexGrow: 1,
        textAlign: 'right',
        display: 'inline-block',
        width: '103px',
    },
    span: {
        fontWeight: 'inherit',
        flexGrow: 1,
        display: 'none',
    },
    deleteIcon: {
        marginRight: theme.spacing(1),
    },


    menuBox: {
        border: "1px solid rgba(0, 0, 0, 0.1)",
        marginLeft: "-24px",
        height: "31px",
        width: "585px",
    },
    menuDetails: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginLeft: "-16px",
        marginTop: "3px",
    },
    AddThreeDots: {
        display: "inline-block",
        width: "176px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        padding: "5px",
    },
    deleteIcon: {
        marginRight: theme.spacing(1),
    },
    textResize: {
        fontSize: "13px !important",
        padding: "4px 0px 2px 5px !important"
    }
})

