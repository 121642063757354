import React from 'react';
import ReactHover from 'client/components/Form/Controls/Image/ReactHover'
import LightBox from 'client/components/Form/Controls/Image/LightBox'
import Hover from 'client/components/Form/Controls/Image/Hover'
import Trigger from 'client/components/Form/Controls/Image/Trigger'
import 'client/assets/css/image.css';
import * as enumerator from 'client/components/Common/Enum';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
const optionsCursorTrueWithMargin = {
    followCursor: true,
    shiftX: 0,
    shiftY: 0
}
let imageStyle;
class image extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showLightBox: false,
            imageUrl: ''
        }
    }

    setImageUrl=(src,size) => {

        const splitSrc = src.split("_")
        
        if (splitSrc.length>0) {
            splitSrc[splitSrc.length - 1]=splitSrc[splitSrc.length - 1].replace('45', size).replace('250', size).replace('65', size).replace('1024', size);
            return splitSrc.join('_')
        } else {
            return src;
        }

        
    }

    setImageTemplate = (src, deviceType, imageSize) => {
        imageStyle = "img45Style";
        let noImage = "SiteImages/NoImage_45.png";
        if (src === "No Image" || src === "" || src === undefined || src === null || src === "0") {
            src = noImage;
        }

       

        if (imageSize === enumerator.ThumbnailImageType.FortyFivePixel || imageSize === enumerator.ImageCulture.FortyFivePixel || imageSize === 'Pixel45') {
            src = this.setImageUrl(src,"45");
        }
        else if (imageSize === enumerator.ThumbnailImageType.SixtyFivePixel || imageSize === enumerator.ImageCulture.SixtyFivePixel || imageSize === 'Pixel65') {
            src = this.setImageUrl(src, "65");
            imageStyle = "img65Style";
         }
        else if (imageSize === enumerator.ThumbnailImageType.TwoFiftyPixel || imageSize === enumerator.ImageCulture.TwoFiftyPixel || imageSize === 'Pixel250') {
            src = this.setImageUrl(src, "250");
            imageStyle = "img250Style";

        }


        if (deviceType === enumerator.DeviceType.Desktop) {
            if (this.props.isForm) {
                return (<div><img className={`${imageStyle} ${this.props.showHover ?'imageCursor':''}`} src={src} alt="" /></div>);
            } else {

                return (<div><img className={`${imageStyle} ${this.props.showHover ? 'imageCursor' : ''}`} onClick={() => { this.showLightBox(src) }} src={src} alt="" /></div>);
            }

        } else {
            if (this.props.isForm) {
                return (<div><img className={`img45Style ${this.props.showHover ? 'imageCursor' : ''}`} src={src} alt="" /></div>);
            }
            else {

                return (<div><img className={`img45Style ${this.props.showHover ? 'imageCursor' : ''}`} onClick={() => { this.showLightBox(src) }} src={src} alt="" /></div>);
            }


        }
    }
    setHoverImageTemplate = (src) => {
        let noImage = "SiteImages/NoImage_45.png";
        if (src === "No Image" || src === "" || src === undefined || src === null || src === "0") {
            src = noImage;
        }
        if (src) {
            src = this.setImageUrl(src, "250");
        }
        return (<img src={src} className="imageCursor" onClick={() => { this.showLightBox(src) }} alt="" />);
    }
    showLightBox = (src) => {

        this.setState({ showLightBox: true, imageUrl: this.setImageUrl(src, "1024") })

    }

    getCaption = () => {

        if (this.props.showCaption) {

            return (<span className=" form-label"> {this.props.caption} </span>)
        } else {

            return (<span className=" form-label"> </span>);
        }
    }

    hideLightBox = () => {
       
        this.setState({ showLightBox: false })
    }
    render() {

        const imageTemplate = this.setImageTemplate(this.props.url, this.props.deviceType, this.props.imageSize);
        const hoverImageTemplate = this.setHoverImageTemplate(this.props.url);
        const caption = this.getCaption();
        const labelStyle = {
            color: this.props.column ? this.props.column.captionColor ? this.props.column.captionColor : "rgba(0, 0, 0, 0.54)" : "rgba(0, 0, 0, 0.54)",
            backGroundColor: this.props.column ? this.props.column.backGroundColor : "",
            foreColor: this.props.column ? this.props.column.foreColor : "",
            wordBreak: "break-all",
            fontSize: "14px"

        }
        return (
            <div key={"ImageContainer" + this.props.caption}>{this.state.showLightBox ? <LightBox deviceType={this.props.deviceType} src={this.state.imageUrl} hideLightBox={this.hideLightBox} /> : ''}

                {this.props.deviceType === enumerator.DeviceType.Desktop && this.props.showHover ? <div>
                    <div key={"divImage" + this.props.caption} className="form-row">{caption}</div>
                    <ReactHover options={optionsCursorTrueWithMargin}>
                        <Trigger imageStyle={imageStyle} type="trigger">{imageTemplate}</Trigger>
                        <Hover type="hover" onClick={this.showLightBox} url={this.props.url}>
                            {hoverImageTemplate}
                        </Hover></ReactHover>
                </div> : <div>

                    <Grid container key={"div" + this.props.caption} >
                        <Grid item>
                            <Typography style={labelStyle} > {this.props.showCaption ? this.props.caption : ""} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container key={"div1" + this.props.caption} pt={.3}>
                        <Grid item>
                            {imageTemplate}
                        </Grid>
                    </Grid>

                </div>
                }</div>
        )

    }
}

export default image;