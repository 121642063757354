
import { ActionTypes as types, controlType, sectionType } from './actionTypes';
import { showError, showSuccess, hideError } from './alertActions';
import adminApi from 'admin/api/adminApi';
import * as Enum from 'client/components/Common/Enum';
const api = adminApi;
const formatType = {
    None: "None", Decimal: "decimal", Currency: "currency", Date: "date", DateTime: "dateTime", Email: "email", File: "file", Url: "url", Image: "image", TextMaximum: "textMaximum", Time: "time",
    Pixel45: 'Pixel45'}
const defalutCulture = { None: "", Date: "MM/dd/yyyy", DateTime: "MM/dd/yyyy hh:mm tt" }

/**
 * generic calls
 */
export const newForm = () => {
    let form = {
        FormList: [],

    };
    return form;
};


export const getFormControl = () => {

    const formControlObject = {
        "controlId": 0,
        "controlName": "",
        "caption": "",
        "formSectionId": 0,
        "tableName": "",
        "fieldName": '',
        "dataType": 0,
        "length": 0,
        "controlType": 0,
        "displayOrder": 0,
        "tabOrder": 0,
        "helpText": "",
        "defaultValue": "",
        "falseValue": "",
        "isMandatory": false,
        "isVisible": false,
        "isEditable": false,
        "isPrimaryField": true,
        "isForeignKey": false,
        "isViewInMobile": false,
        "listId": 0,
        "formula": "",
        "formulaUI": "",
        "endPoint": "",
        "displayField": "",
        "valueField": "",
        "label": "",
        "rowCommands": "",
        "controlRow": 0,
        "controlPosition": 0,
        "format": "decimal",
        "culture": "",
        "staticValues": "",
        "isShowInFind": false,
        "textAlign": "",
        "backGroundColor": "",
        "foreColor": "",
        "captionColor": "",
        "rowSpan": 0,
        "precision": 0,
        "isDataBind": true,
        "isAllowNewValue": false,
        "controlUiId": '',
        "controlWidth": 0,
        "hasFocus": false,
        "comboBoxStatic":true,
        "formAction": [],// formActionObject([])
        "formControlListMapping": [],
        "formControlListParameter": [],
        formControlRoles: [],
         keyMapped: false
    }
    return formControlObject
}

export const getButtonActionObject = () => {
    var buttonActionObject = {
        formId: -1,
        actionId: -1,
        actionName: "",
        actionType: types.serviceType,
        controlId: -1,
        actionSequence: -1,
        serviceType: "",
        actionTarget: "",
        parameter: "",
        actionTrigger: "0",
        validateTrueValue: "",
        validateErrorMessage: "",
        validateSuccessMessage: "",
        hideSuccessMessage: false,
        hideErrorMessage: false,
        isBizweaverService: false,
        isActive: false, 
    }
    return buttonActionObject;
}
export const getParameterListObject = () => {
    return {
        id: -1,
        parentControlId: -1,
        controlName: "",
        listField: "",
        isMandatory : false,
        isGrid: false,
        isDetailTableField:false,
    }
}
export const getFormMappingListObject = () => {
    return {
        id: -1,
        parentControlId: -1,
        controlName: "",
        listFieldValue: "", 
        fieldName: "", 
        isGrid: false,
        isDetailTableField: false,
    }
}
export const getControlRoleObject = () => {
    return {
        id: -1,
        controlId: -1,
        objectType: 1,
        roleId: -1,
        sectionId: -1,
        isVisible: false,
        isEditable:false
    } 
}
export const getControlWithSuffix = (control, target) => {
    if (control === controlType.TextBox || control.indexOf("txtDiv") > -1) {
        return `txtDiv_${target ? target : ""}`;
    }
    else if (control === controlType.DropdownList || control.indexOf("ddlDiv") > -1) {
        return `ddlDiv_${target ? target : ""}`;
    }

    else if (control === controlType.SectionTitle || control.indexOf("secDiv") > -1) {
        return `secDiv_${target ? target : ""}`;
    }
    else if (control === controlType.FileUpload || control.indexOf("fileDiv") > -1) {
        return `fileDiv_${target ? target : ""}`;
    }
    else if (control === controlType.DatePicker || control.indexOf("dateDiv") > -1) {
        return `dateDiv_${target ? target : ""}`;
    }
    else if (control === controlType.TimePicker || control.indexOf("dtpDiv") > -1) {
        return `dtpDiv_${target ? target : ""}`;
    }
    else if (control === controlType.NumericTextBox || control.indexOf("intDiv") > -1) {
        return `intDiv_${target ? target : ""}`;
    }
    else if (control === controlType.Signature || control.indexOf("signDiv") > -1) {
        return `signDiv_${target ? target : ""}`;
    }
    else if (control === controlType.Button || control.indexOf("btnDiv") > -1) {
        return `btnDiv_${target ? target : ""}`;
    }

    else if (control === controlType.Image || control.indexOf("imageDiv") > -1) {
        return `imageDiv_${target ? target : ""}`;
    }
    else if (control === controlType.CheckBox || control.indexOf("chkDiv") > -1) {
        return `chkDiv_${target ? target : ""}`;
    }
}
export const getControlCaption = (sourceControl, section) => {
    let controlValue = { caption: '', fieldName: '' }
    if (sourceControl) {
        if (section === sectionType.Detail) {


            if (sourceControl.indexOf("int") > -1) {
                controlValue.caption = 'Numeric'
                controlValue.fieldName = `nbdgrid_${sourceControl.replaceAll("-", "_")}`


            }
            else if (sourceControl.indexOf("txt") > -1) {

                controlValue.caption = 'Text Box'
                controlValue.fieldName = `nbdgrid_${sourceControl.replaceAll("-", "_")}`


            }
            else if (sourceControl.indexOf("date") > -1) {

                controlValue.caption = 'Date Picker'
                controlValue.fieldName = `nbdgrid_${sourceControl.replaceAll("-", "_")}`


            }

            else if (sourceControl.indexOf("dtp") > -1) {
                controlValue.caption = 'Time Picker'
                controlValue.fieldName = `nbdgrid_${sourceControl.replaceAll("-", "_")}`
            }
            else if (sourceControl.indexOf("sec") > -1) {
                controlValue.caption = 'Section Title'
                controlValue.fieldName = 'Section Title'
            }

            else if (sourceControl.indexOf("ddl") > -1) {
                controlValue.caption = 'Select Menu'
                controlValue.fieldName = `nbdgrid_${sourceControl.replaceAll("-", "_")}`
            }
            else if (sourceControl.indexOf("file") > -1) {
                controlValue.caption = 'File Upload'
                controlValue.fieldName = 'File Upload'
            }
            else if (sourceControl.indexOf("image") > -1) {
                controlValue.caption = 'Image'
                controlValue.fieldName = `nbdgrid_${sourceControl.replaceAll("-", "_")}`
            }

            else if (sourceControl.indexOf("chk") > -1) {
                controlValue.caption = 'Checkbox'
                controlValue.fieldName = `nbdgrid_${sourceControl.replaceAll("-", "_")}`
            }


            else {

                controlValue.caption = 'Text Box'
                controlValue.fieldName = `nbdgrid_${sourceControl.replaceAll("-", "_")}`
            }
        } else {

            if (sourceControl.indexOf("int") > -1) {
                controlValue.caption = 'Numeric'
                controlValue.fieldName = `nbd\_${sourceControl.replaceAll("-", "_")}`


            }

            else if (sourceControl.indexOf("txt") > -1) {
                controlValue.caption = 'Text Box'
                controlValue.fieldName = `nbd_${sourceControl.replaceAll("-", "_")}`
            }
            else if (sourceControl.indexOf("dtp") > -1) {
                controlValue.caption = 'Time Picker'
                controlValue.fieldName = `nbd_${sourceControl.replaceAll("-", "_")}`
            }

            else if (sourceControl.indexOf("date") > -1) {

                controlValue.caption = 'Date Picker'
                controlValue.fieldName = `nbd_${sourceControl.replaceAll("-", "_")}`

            } else if (sourceControl.indexOf("sec") > -1) {
                controlValue.caption = 'Section Title'
                controlValue.fieldName = 'Section Title'
            }

            else if (sourceControl.indexOf("ddl") > -1) {
                controlValue.caption = 'Select Menu'
                controlValue.fieldName = `nbd_${sourceControl.replaceAll("-", "_")}`
            }
            else if (sourceControl.indexOf("btn") > -1) {
                controlValue.caption = 'Button'
                controlValue.fieldName = `nbd_${sourceControl.replaceAll("-", "_")}`
            }
            else if (sourceControl.indexOf("chk") > -1) {
                controlValue.caption = 'Checkbox'
                controlValue.fieldName = `nbd_${sourceControl.replaceAll("-", "_")}`
            }
            else if (sourceControl.indexOf("sign") > -1) {
                controlValue.caption = 'Signature'
                controlValue.fieldName = `nbd_${sourceControl.replaceAll("-", "_")}`
            }
           
            else if (sourceControl.indexOf("image") > -1) {
                controlValue.caption = 'Image'
                controlValue.fieldName = `nbd_${sourceControl.replaceAll("-", "_")}`
            }
            else if (sourceControl.indexOf("file") > -1) {
                controlValue.caption = 'File Upload'
                controlValue.fieldName = 'File Upload'
            }
            else {

                controlValue.caption = 'Text Box'
                controlValue.fieldName = `nbd_${sourceControl.replaceAll("-", "_")}`
            }
        }

    }


    return controlValue
}
export const getControlFomat = (sourceControl) => {
    let type = formatType.None
    if (sourceControl) {
        if (sourceControl.indexOf("int") > -1) {
            type = formatType.Decimal


        }
        else if (sourceControl.indexOf("txt") > -1) {

            type = formatType.None


        }
        else if (sourceControl.indexOf("date") > -1) {

            type = formatType.DateTime


        }

        else if (sourceControl.indexOf("dtp") > -1) {
            type = formatType.Time
        }
        else if (sourceControl.indexOf("sec") > -1) {
            type = formatType.None
        }

        else if (sourceControl.indexOf("ddl") > -1) {
            type = formatType.None
        }
        else if (sourceControl.indexOf("image") > -1) {
            type = formatType.Pixel45
        }



    }


    return type
}
export const getControlCulture = (sourceControl,controlFormatType) => {
   
    if (sourceControl) {
        if (sourceControl.indexOf("date") > -1) {

            if (controlFormatType) {

                if (controlFormatType === formatType.DateTime) {
                    return defalutCulture.DateTime

                } else {
                    return defalutCulture.Date
                }


            } else {
                return defalutCulture.Date

            }


        } else if (sourceControl.indexOf("dtp") > -1) {

            return defalutCulture.DateTime
        } else {
            return defalutCulture.None;
        }

    } else {
        if (controlFormatType) {

            if (controlFormatType === formatType.Date) {
                return defalutCulture.Date

            } else {
                return defalutCulture.DateTime
            }

        }

    }

    return defalutCulture.None;
    
}
const clearFormControlCommonProperties = (formControlObject) => {
    formControlObject.length = 0;
    formControlObject.rowSpan = 0;
    formControlObject.isAllowNewValue = false;
    formControlObject.comboBoxStatic = true;
    formControlObject.listId = 0;
    formControlObject.formControlListMapping = [];
    formControlObject.formControlListParameter = [];
    formControlObject.displayField = "";
    formControlObject.valueField = "";
    formControlObject.staticValues = "";
    formControlObject.decimalPlaces = 0;
    formControlObject.formula = "";
    formControlObject.formatType = Enum.FormatType.None;
    return formControlObject;
}
export const getControlDefault = (enumControlType,existingFormControl) => {
    let formControlObject = existingFormControl ?existingFormControl:this.getFormControl();
     
    formControlObject=clearFormControlCommonProperties(formControlObject);
    switch (enumControlType) {
        case Enum.ControlType.TextBox:
            {
                formControlObject.controlType = Enum.ControlType.TextBox;
                formControlObject.length = existingFormControl.fieldName ? existingFormControl.length : 50;
                formControlObject.rowSpan = 0;
                return formControlObject;
            }
        case Enum.ControlType.MultiText1:{
            formControlObject.controlType = Enum.ControlType.MultiText1;
            formControlObject.length = existingFormControl.fieldName ? existingFormControl.length:50;
            formControlObject.rowSpan = 0;
            return formControlObject;
        }
        case Enum.ControlType.SectionTitle: { return formControlObject; }
        case Enum.ControlType.DropdownList: {
            formControlObject.isAllowNewValue = false;
            formControlObject.comboBoxStatic = true;
            
            return formControlObject;
        }
        case Enum.ControlType.NumericTextBox: {
            formControlObject.formatType = Enum.FormatType.Decimal; 
            return formControlObject;
        }
        case Enum.ControlType.DatePicker: {
            if (formControlObject.dataType.toUpperCase() === Enum.FormatType.Date.toUpperCase()) {
                formControlObject.format = Enum.FormatType.Date;
                formControlObject.culture = "MM/dd/yyyy"
            } else {
                formControlObject.format = Enum.FormatType.DateTime;
                formControlObject.culture = "MM/dd/yyyy hh:mm tt"
            }
            formControlObject.formatType = Enum.FormatType.DateTime; 
         
            return formControlObject;
        }
        case Enum.ControlType.TimePicker: {
            formControlObject.formatType = Enum.FormatType.Time;
            formControlObject.format = "time"
            formControlObject.culture = "MM/dd/yyyy hh:mm tt"
            return formControlObject;
        }
        case Enum.ControlType.FileUpload: { return formControlObject; }
        case Enum.ControlType.Signature: { return formControlObject; }
        case Enum.ControlType.Image: { return formControlObject; }
       
        case Enum.ControlType.CheckBox: { return formControlObject; }
    }
    return formControlObject;
}

const getColumns = () => {
    const columnArray = [];
    let columnObject = Object.assign({}, column)
    columnObject.column = 1;
    columnObject.width = 6;
    columnArray.push(columnObject)
    columnObject = Object.assign({}, column)
    columnObject.column = 2;
    columnObject.width = 6;
    columnArray.push(columnObject)
    return columnArray;
}


const column = {
    column: "",
    controlId: "",
    controlUiId: "",
    controlType: 0,
    style: "",
    css: "",
    fieldName: "",
    caption: "",
    isVisible: false,
    helpText: "",
    defaultValue: "",
    falseValue: "",
    isMandatory: false,
    isEditable: false,
    isPrimaryField: false,
    isForiegnKey: "",
    isViewInMobile: false,
    listId: 0,
    formula: "",
    formulaUI: "",
    endPoint: "",
    displayField: "",
    valueField: "",
    label: "",
    rowCommands: "",
    controlRow: 0,
    controlPosition: 0,
    width: 0,
    subRows: [],
    rowColumnId: ""
}



const row = {

    row: '',
    //rowColID: '',
    // ctrlID: '',
    //subRows: [],
    columns: getColumns()

}


/**
 * Invokes the mock or real web api to get forms
 */


/**
 * Involes the mock or real web api to get all forms
 */


const apiGetForm = () => {
    return api.getForms();
};



const apiDeleteForm = (formId) => {
    return api.deleteForm(formId);
};

/**
 * action creators
 */

/**
 * Search Request action creator
 * @param {string} searchText 
 */
export const searchRequest = (searchText) => {
    return {
        type: types.VGO_UNIVERSAL_SEARCH,
        searchText: searchText
    }
};


export const getFormUi = () => {

    const rowsArray = [];

    const rowObject = Object.assign({}, row)
    rowObject.row = 1;
    rowsArray.push(rowObject)

    return rowsArray;
}


/**
 * Error action creator
 * @param {*} errorMessage 
 */
 const getError = (errorMessage) => {
    return {
        type: types.VGO_API_CALL_ERROR,
        hasError: true,
        errorMessage,
    };
};



/**
 * Get all forms request action creator
 */


const getFormRequest = () => {
    return {
        type: types.VGO_GET_FORM_LIST_REQUEST,
    };
};

const getDeleteFormRequest = () => {
    return {
        type: types.VGO_DELETE_FORM_LIST_REQUEST,
    };
};

const updateFormUiRequest = (actionData) => {
    return {
        type: types.VGO_UPDATE_FORM_UI_SUCCESS,
        data: actionData,

    };
}

const updateFormPropertyRequest = (actionData, ActiveControlProperty) => {
    return {
        type: types.VGO_UPDATE_FORM_PROPERTY_SUCCESS,
        data: actionData,
        ActiveControlProperty

    };
}

const updateActiveControlPropertyRequest = (actionData) => {
    return {
        type: types.VGO_UPDATE_ACTIVE_CONTROL_PROPERTY_SUCCESS,
        data: actionData,

    };
}

const updateActiveFormPropertyRequest = (actionData) => {
    return {
        type: types.VGO_UPDATE_ACTIVE_CONTROL_PROPERTY_CHANGE_SUCCESS,
        data: actionData,

    };
}




/**
 * Get all roles success action creator
 * @param {Response} resp 
 */

const getFormSuccess = (resp) => {
    return {
        type: types.VGO_GET_FORM_LIST_SUCCESS,
        forms: resp.data,
    };
};

const deleteFormSuccess = (formId) => {
    return {
        type: types.VGO_DELETE_FORM_LIST_SUCCESS,
        formId: formId,

    };
};

/**
 * Dispatch methods
 */

/**
 * Get Forms dispatch
 */



export const getForms = () => {
    return dispatch => {
        dispatch(getFormRequest());
        return apiGetForm()
            .then(resp => {
                hideError(dispatch);
                dispatch(getFormSuccess(resp));
            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
            })
    };
};
export const deleteForm = (formId) => {
    return dispatch => {
        dispatch(getDeleteFormRequest());
        return apiDeleteForm(formId)
            .then((resp) => {
                dispatch(deleteFormSuccess(formId));
                showSuccess(dispatch, 'Success', 'Form deleted successfully', 5000);
            })
            .catch(err => {
                let { message } = err;
                if (err.response && err.response.data) {
                    message = err.response.data;
                    if (typeof (message) !== "string") {
                        if (message.title && typeof (message.title) === "string") {
                            message = message.title;
                        }
                        else {
                            message = err.message;
                        }
                    }
                }
                dispatch(getError(message));
                showError(dispatch, message, 5000);
            });
    };
};

export const updateFormUI = (actionData) => {
    return dispatch => {
        dispatch(updateFormUiRequest(actionData));

    };
};

export const updateFormUIProperty = (actionData, ActiveControlProperty) => {
    return dispatch => {
        dispatch(updateFormPropertyRequest(actionData, ActiveControlProperty));

    };
};



export const updateActiveControlProperty = (actionData) => {
    return dispatch => {
        dispatch(updateActiveControlPropertyRequest(actionData));

    };
};

export const getNewFormUI = () => {
    return dispatch => {
        dispatch(updateFormUiRequest(getFormUi()));

    };
};
export const updateActiveFormUIProperty = (actionData) => {
    return dispatch => {
        dispatch(updateActiveFormPropertyRequest(actionData));

    };
};

export const showErrorMessage = (message) => {
    return dispatch => {
        showError(dispatch, message, 5000);

    };
};

export const getValidationError = (validationObject,errorMessage) => {
   // console.log('getError: ', errorMessage);
    return {
        type: types.VGO_API_CALL_VALIDATION_ERROR,
        hasError: true,
        validationObject,
        errorMessage
    }
};






