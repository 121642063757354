import { ActionTypes as types } from './actionTypes';
//import { customWidgetType} from './actionTypes';
import adminApi from 'admin/api/adminApi';
import mockApi from 'admin/api/mockApi';
import { showError, showSuccess, hideError } from './alertActions';
import { getError } from './errorActions';
import { WidgetType } from 'client/components/Common/Enum';
import settings from 'settings.js';
import { DateTime } from 'luxon';
import { cloneDeep } from 'lodash';

/**
 * reads mock data setting from settings
 */
const useMocks = settings.useMocks;

/**
 * api in use, mock or real
 */
const api = useMocks ? mockApi : adminApi;

/**
 * generic calls
 */

/**
 * Invokes the mock or real web api to get users
 */
const apiGetCustomWidgetList = () => {
    console.log('apiGetCustomWidgetList');
    return api.getCustomWidgetList();
};
/**
 
 */
const getCustomWidgetListRequest = () => {
    console.log('getCustomWidgetListRequest');
    return {
        type: types.VGO_GET_CUSTOMWIDGET_REQUEST,
    }
};

const apiUpdateCustomWidgetList = (customWidgets) => {
    console.log('apiUpdateCustomWidgetList');
    return api.updateCustomWidget(customWidgets);
};

/**
 
 * @param {*} resp 
 */
const getCustomWidgetListSuccess = (resp) => {
    console.log('getCustomWidgetListSuccess', resp);
    return {
        type: types.VGO_GET_CUSTOMWIDGET_SUCCESS,
        cwidgets: resp.data,


    };
};

const getErrorState = (data, activeWidget) => {

    return {
        type: types.VGO_UPDATE_CUSTOMWIDGET_ERROR,
        customWidgets: data,
        activeWidget: activeWidget


    };
};

/**
 
 */
const updateCustomWidgetRequest = () => {
    console.log('updateCustomWidgetRequest');
    return {
        type: types.VGO_UPDATE_CUSTOMWIDGET_REQUEST,
    }
};

/**
 
 * @param {*} resp 
 */
const updateCustomWidgetSuccess = (resp) => {
    console.log('updateCustomWidgetSuccess', resp);
    return {
        type: types.VGO_UPDATE_CUSTOMWIDGET_SUCCESS,
        cwidgets: resp.data,


    };
};
const deleteCWLink = (data) => {

    return {
        type: types.VGO_CHANGE_CUSTOMWIDGET_LINK_DELETE,
        cwidgets: data,
    };
}

const newCWLink = (data) => {
    return {
        type: types.VGO_NEW_CUSTOMWIDGET_LINK,
        cwidgets: data,
    }
}


/**
 * Get Users dispatch
 */
export const getCustomWidgetList = () => {
    console.log('getCustomWidgetList');
    return dispatch => {
        dispatch(getCustomWidgetListRequest());
        return apiGetCustomWidgetList()
            .then((resp) => {
                console.log("resp", resp);
                hideError(dispatch);
                dispatch(getCustomWidgetListSuccess(resp));

            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
                showError(dispatch, message, 5000);
            });
    }
};
const changeActiveWidgetIndex = (widgetIndex) => {
    return {
        type: types.VGO_CHANGE_CUSTOMWIDGET_ACTIVEINDEX,
        activeWidgetIndex: widgetIndex,
        widgetValidation: {}
    };
}
const changeWidgetDisplayIndex = (customWidgets, source, destination) => {
    var cWidgetObject = customWidgets;
    var sourceActual = source + 1;
    var destinationActual = destination + 1;
    let changedObject = cWidgetObject.map((customObject, index) => {
        let currentObject = cloneDeep(customObject);
        if (destinationActual < sourceActual) {
            if (customObject.displayOrder === sourceActual) {
                currentObject.displayOrder = destinationActual;
            } else {
                if (customObject.displayOrder <= sourceActual && customObject.displayOrder >= destinationActual)
                    currentObject.displayOrder = currentObject.displayOrder + 1;
            }
        } else {
            if (customObject.displayOrder === sourceActual) {
                currentObject.displayOrder = destinationActual;
            } else {
                if (customObject.displayOrder >= sourceActual && customObject.displayOrder <= destinationActual)
                    currentObject.displayOrder = currentObject.displayOrder - 1;
            }
        }
        return currentObject;
    })

    changedObject = changedObject.sort((a, b) => a.displayOrder - b.displayOrder);
    return {
        type: types.VGO_CHANGE_CUSTOMWIDGET_DISPLAYORDER,
        cwidgets: changedObject,
        activeWidgetIndex: destinationActual,
    };
}
const invokeValidationError = (validationObject, activeWidget) => {
    return {
        type: types.VGO_CUSTOMWIDGET_ERROR,
        widgetValidation: validationObject,
        cwidgets: activeWidget,
    };
}
const validate = (activeWidget) => {
    var validationObject = {};
    if ((activeWidget.type === WidgetType.Joke || activeWidget.type === WidgetType.Quote || activeWidget.type === WidgetType.Weather) && (activeWidget.apiUrl === "" || activeWidget.apiUrl === undefined)) {
        validationObject["apiUrl"] = "Url is Required";
        return validationObject;
    }

    else if ((activeWidget.type === WidgetType.Joke ||
        activeWidget.type === WidgetType.Quote ||
        activeWidget.type === WidgetType.Weather) &&
        (activeWidget.apiUrl !== "")) {
        var validUrl =
            /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i
                .test(activeWidget.apiUrl);;

        if (!validUrl) {
            validationObject["apiUrl"] = "Url is Invalid";
            return validationObject;
        } else {
            return {};
        }

    } else {
        return {};
    }
}
const validateLink = (activeWidget) => {
    var validationObject = {};

    let status = true;

    for (var i = 0; i < activeWidget.details.length; i++) {
        let item = activeWidget.details[i];
        if ((item.urlCaption !== "" && item.url.trim() === "")) {
            item.urlHelperText = "URL is required"
            if (status) {
                validationObject["linkError"] = "Error";
                status = false
            }

        }

        if ((item.urlCaption.trim() === "" && item.url !== "")) {
            item.captionHelperText = "Caption is required"
            if (status) {
                validationObject["linkError"] = "Error";
                status = false
            }

        }


    }

    return validationObject;

}
export const changeActiveWidget = (widgetIndex, activeWidget) => {

    var validationObject = validate(activeWidget);
    if (!('apiUrl' in validationObject)) {
        return dispatch => { dispatch(changeActiveWidgetIndex(widgetIndex)) };
    } else {
        return dispatch => { dispatch(invokeValidationError(validationObject, activeWidget)) }
    }
}
export const onFieldBlur = (activeWidget) => {
    var validationObject = validate(activeWidget);
    //if (!('apiUrl' in validationObject)) {
    //    return dispatch => { dispatch(invokeValidationError({}, activeWidget)) };
    //} else {
    return dispatch => { dispatch(invokeValidationError(validationObject, activeWidget)) }
    //}
}
export const getChangeCustomWidget = (change) => {
    return {
        type: types.VGO_CHANGE_CUSTOMWIDGET,
        change,
    };
}
export const updateCustomWidgetList = (customWidgets, activeWidget) => {
    console.log('UpdateCustomWidgetList');
    var linkData = "";
    var validationObject = {};
    let index = customWidgets.findIndex(item => item.type === WidgetType.Links)
    if (index !== -1) {
        linkData = cloneDeep(customWidgets[index]);
        validationObject = validateLink(linkData);
    }
    if (Object.keys(validationObject).length === 0)
        validationObject = validate(activeWidget);
    return dispatch => {
        if (!('apiUrl' in validationObject) && !('linkError' in validationObject)) {
            dispatch(updateCustomWidgetRequest());
            return apiUpdateCustomWidgetList(customWidgets)
                .then((resp) => {
                    showSuccess(dispatch, 'Success', ' API & Links Saved!', 5000);
                    dispatch(updateCustomWidgetSuccess(resp));

                })
                .catch(err => {
                    const { message } = err;
                    dispatch(getError(message));
                    showError(dispatch, message, 5000);
                });
        } else {
            //dispatch(getError('Please correct the indicated items'));
            if ('linkError' in validationObject) {
                var customWidgetData = cloneDeep(customWidgets);
                if (index !== -1) {
                    customWidgetData[index] = linkData

                    if (activeWidget.type === WidgetType.Links)
                        dispatch(getErrorState(customWidgetData, linkData))
                    else
                        dispatch(getErrorState(customWidgetData, activeWidget))
                }

            }

            return showError(dispatch, 'Please correct the indicated items', 5000);
        }
    }
};
export const changeDisplayOrder = (customWidgets, source, destination) => {
    return dispatch => { dispatch(changeWidgetDisplayIndex(customWidgets, source, destination)) }
}

export const deleteLinkDetail = (customWidget, widgetType, detailIndex) => {
    //var cObjectData = customWidget.find(cw => cw.type === widgetType);

    //if (cObjectData) {
    //    cObjectData=cObjectData.details.splice(detailIndex, 1);
    //}
    var cWidget = customWidget.map((cObject, index) => {
        var cObjectData = cloneDeep(cObject);
        if (cObjectData.type === widgetType) {
            cObjectData.details.splice(detailIndex, 1);
        }
        return cObjectData;
    });
    return dispatch => { dispatch(deleteCWLink(cWidget)) };
}
export const newLinkDetail = (customWidget, widgetType, detailIndex) => {
    var cWidget = customWidget.map((cObject, index) => {
        var cObjectData = cloneDeep(cObject);
        if (cObjectData.type === widgetType) {
            cObjectData.details.push({ apiId: cObject.id, urlCaption: "", url: "", displayOrder: cObjectData.details[cObjectData.details.length - 1].displayOrder + 1 })
        }
        return cObjectData;
    });
    //var cObjectData = customWidget.find(cw => cw.type === widgetType);
    //if (cObjectData) {
    //    cObjectData= cObjectData.details.push({ apiId: cObjectData.Id, urlCaption: "", url: "" ,Id:0})
    //}
    return dispatch => { dispatch(newCWLink(cWidget)) };
}

