import React from 'react';
import { Grid, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

class SettingsHeader extends React.Component {


    render() {
        return (
            
				<Grid container direction="row" justifyContent="space-between">
					<Grid item>
                        <Typography variant="h4">System Settings</Typography>
					</Grid>
					<Grid item xs={7}>
						
					</Grid>
					<Grid item>
                          
					</Grid>
				</Grid>
            
        );
    }

}

/**
 * mapStateToProps
 * @param {*} state 
 */
function mapStateToProps( state ) {
    return {
		alert: state.alert,
	};
};

export default withRouter(connect(mapStateToProps)(SettingsHeader));
