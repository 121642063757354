import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router-dom';
import { siteTheme } from './theme/siteTheme.js';
import { styled } from '@mui/styles';
import { spacing } from '@mui/system';
import { Box, Grid, Typography } from '@mui/material';
import MuiButton from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check'
import { updateSiteTheme } from 'admin/actions/siteThemeActions';

const Button = styled(MuiButton)(spacing);

const SiteThemeEditHeader = (props) => {

    const onSaveClick = () => {
        props.dispatch(updateSiteTheme(props.siteTheme));
    }

    return (
        <Grid container direction="row" justifyContent="space-between" spacing={0} xl={12}>
            <Grid item>
                <Typography variant="h4">Theme</Typography>
            </Grid>

            <Grid item >
                <Grid container direction="row" justifyContent="space-between" spacing={1} >
                    <Grid item >
                        <Button
                            className={props.classes.customControlButton}
                            color="primary"
                            variant="contained"
                            disableElevation
                            startIcon={<CheckIcon />}
                            onClick={onSaveClick}
                        >SAVE</Button>
                    </Grid> </Grid>
            </Grid>
        </Grid>
    );

}
function mapStateToProps(state) {
    let props = {
        siteTheme: state.siteThemes.siteTheme,
    };
    return props;
};

export default withRouter(connect(mapStateToProps)(withStyles(siteTheme)(SiteThemeEditHeader)));
