import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DateTime } from 'luxon';

import PropTypes from 'prop-types';

import MUIDataTable from 'mui-datatables';
import { styled } from '@mui/styles';
import { withStyles } from '@mui/styles';
import { spacing } from '@mui/system';
import { Avatar, Grid, Switch, Typography, Box, Chip } from '@mui/material';
import Autocomplete from '@mui/lab/Autocomplete';
import { ActionTypes as actionTypes } from 'admin/actions/actionTypes';
import CircularProgress from '@mui/material/CircularProgress';
import MuiCard from '@mui/material/Card';
import MuiTextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import { getRoles, updateUser, getChangeUser, sortRoles, getFilters, updateFilter, getFilter, getCountryList, getUser, validateFieldsOnBlur } from 'admin/actions/userActions';
import AddIcon from "@mui/icons-material/Add";
import { IconButton, FormControlLabel, InputLabel, MenuItem, FormControl } from '@mui/material'
import MuiButton from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear'; 

import PasswordText from 'client/components/UserProfile/Password.js'; 
import PasswordHelp from 'client/components/ResetPasswords/PasswordHelp.js';

import * as Enum from 'client/components/Common/Enum';
import cloneDeep from 'lodash/cloneDeep';

import { usersTheme, defaultRoleColor, defaultRoleBGColor} from './theme/usersTheme.js';
 import { sortName } from 'client/components/Common/Utility';

const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
let profileId = 0;
let profileName = "";
 
 
class UserEdit extends React.Component {

    constructor(props) {
        super(props);
        
        let userId = this.props.match.params.userId;
        let id = null;
        let isUpdate = userId !== "new";
        if (isUpdate) {
            id = parseInt(userId);
            if (isNaN(id)) {
                // TODO: error on page?
                id = null;
            }
        }
        this.state = {
            userId: isUpdate ? id : null,
            isUpdate: isUpdate,
            options: {
                download: false,
                elevation: 0,
                jumpToPage: false,
                viewColumns: false,
                filter: false,
                search: false,
                sorting: true,
                print: false,
                pagination: false,
                selectableRows: 'none', 
                onRowClick: (rowData, rowMeta) => {
                    let dataIndex = rowMeta.dataIndex;
                    let profile = this.props.filterData[dataIndex];
                    if (profile.profileId) {
                        profileId = profile.profileId
                        profileName = profile.profileName
                        this.props.dispatch(getFilter(profile.profileId, dataIndex));                         
                    }
                }, 
            },
            profileMenuItem: [],
            showProfile: false,
            showPasswordHelper:false,
            profileId: 0,
            passwordHelper: cloneDeep(Enum.passwordHelp),
            passwordError: false,
            conPasswordError: false,
            countryOption: [], 
            confirmPasswordValidateStatus: false,
            havePrimaryPasswordFocus: false,
            roleRemoveError:false
        };
    }

 
    componentDidMount() {

        this.dispatchCountryList();
        this.dispathGetRoles();
        this.dispatchGetFilters();
        let userId = this.props.match.params.userId;
        let uid = this.props.user;
        let isUpdate = userId !== "new";
        userId = isUpdate ? userId : null;
        this.dispatchGetUser(userId);
         
    }
    componentDidUpdate(prevProps) {  
        if (this.props.countryList !== prevProps.countryList) {
            this.loadCountry();
        }
        if (this.props.isUpdateSuccess !== prevProps.isUpdateSuccess) {
            if (this.props.isUpdateSuccess && this.props.user.userId>0) {
                this.props.history.push(`/Admin/Users/${this.props.user.userId}`);
            }
        }

    }

    loadCountry() {
        var arrayCountry = [];
        var arrayList = [...this.props.countryList].sort((a, b) => sortName(a.name, b.name));
        arrayCountry.push(<MenuItem key='0'> -Select-  </MenuItem>);
        for (var k = 0; k < arrayList.length; k++) {
            arrayCountry.push(
                <MenuItem key={arrayList[k].code} value={arrayList[k].name} > {arrayList[k].name} </MenuItem>);
        }
        this.setState({
            countryOption: arrayCountry,
        })
    }


    dispathGetRoles = () => {
      
        this.props.dispatch(getRoles());
    }

    dispatchGetFilters = () => {
        this.props.dispatch(getFilters());
    }

    dispatchGetUser = ( userId ) => {
       
        if (userId!=null ) {
            this.props.dispatch(getUser(userId) );
        }
    }

    dispatchUpdateUser = (user) => {
       
        this.props.dispatch(updateUser(user));
    }
    dispatchCountryList=() => {
        this.props.dispatch(getCountryList()); 
    }

    changeUser = (change) => { 
      
        this.props.dispatch( getChangeUser( change ) );
    }

    formatDateTime = (dateTime) => {
       
        let result = '';
        if (dateTime) {
            result = DateTime.fromISO(dateTime).toFormat('LL-dd-yyyy hh:mm:ss a');
        }
        return result;
    }

    toggleActive = (event) => {
       
        this.changeUser(user => user.isActive = event.target.checked);
    }

    toggleAdmin = (event) => {
       
        this.changeUser(user => user.hasAdmin = event.target.checked);
    }

    onRolesChange = (event, value, reason) => {
        this.setState({ "roleRemoveError": false })
        switch (reason) {
            case 'selectOption':
                {
                    if (value.length === 1) { //If the selected list have only one role selectred
                        this.changeUser(user => user.defaultRoleId = value[0].Id)
                        this.changeUser(user => user.roles = sortRoles(value));
                    } else { //If more than one ,logic to check if the default role is repeating
                        const rValue = value.filter(element => element.roleId === value[value.length - 1].roleId)
                        if (rValue.length === 1) {
                            this.changeUser(user => user.roles = sortRoles(value));
                        }
                    }
                    break;
                }
            case 'removeOption':
                {
                    if (this.checkCanDelete(value)) {
                        this.changeUser(user => user.roles = sortRoles(value));
                    } else {
                        this.setState({ "roleRemoveError": true })
                    }

                    break;
                }
        default:
                break;

        }
    }
    onRoleBlur=(event, value, reason) => {
        this.setSate({"roleRemoveError":false})
    }
    checkCanDelete = (value) => {
        var canDelete = false;
        if (value.length === 0) {
            //All options got deleted
            canDelete = false;
        } else {
            //Looping in remaining options after removed
            value.map((role) => {
                if (role.roleId === this.props.user.defaultRoleId) {
                    //If the default option exist in remaining then the current action can perform
                    canDelete = true;
                }
            });
        }
        return canDelete;
    }
    handleRoleChipClick = (item) => {
        
        if (item.currentTarget != null) {
            this.changeUser(user => user.defaultRoleId = parseInt(item.currentTarget.id));
        }
    }
    profileNameTemplate = (value, tableMeta, updateValue) => {       
         
        var filterData = this.props.filters !== undefined
            ? this.props.filters.find(filter => filter.profileId === tableMeta.rowData[3])
            : [];

        return (
            <Autocomplete
                value={filterData !== undefined ? filterData : []}
                id={"profileName" + tableMeta.rowIndex}
                size="small"
                
                fullWidth={true}
                options={this.props.filters !== undefined ? [...this.props.filters].sort((a, b) => sortName(a.profileName, b.profileName)) : []}
                getOptionLabel={(option) => option.profileName !== undefined ? option.profileName:""}
                getOptionSelected={(option, value) => value.profileId === option.profileFieldIdentifier}
                renderInput={params => (
                    <TextField {...params}
                        id={"profileName" + tableMeta.rowIndex}
                        size="small"
                        label="Enter filter name"
                        /*value={{ profileName: tableMeta.rowData[tableMeta.columnIndex] }}*/
                    />
                )}
                onChange={(event, value, ref) => this.handleChange(event, value, ref, tableMeta.rowIndex)}

            />
        );
    }

    profileValueTemplate = (value, tableMeta, updateValue) => {
        
        var data = tableMeta.rowData;
        console.log(" profilevalue rowData", tableMeta.rowData);
        var filterDataobj = this.props.filterData !== undefined
            ? this.props.filterData.find(filter => filter.profileId === tableMeta.rowData[3])
            : [];
         

        let filterData = { profileId: data[3], profileName: filterDataobj.profileName, value:data[1]};
        let filter = "";
        let array = [];
         
        if (this.props.filter !== undefined && this.props.filter.FieldList!==undefined) {
            array = [];
            for (let i = 0; i < this.props.filter.FieldList.length; i++) {
                let field = this.props.filter.FieldList[i]
                array.push({ value: String(field[this.props.filter.FieldName]) })
            }
           
        } else {
           
            if (filter) {
                array = [];
                array.push({ value: String(filter) })
            }
          
        } 
            return (
                <Autocomplete
                    value={filterData !== undefined ? filterData : null}
                    id={"profileValue" + tableMeta.rowIndex}
                    size="small"
                    fullWidth={true} 
                    options={array.sort((a, b) => sortName(a.value, b.value))}
                    getOptionLabel={(option) => option.value}
                    getOptionSelected={(option, value) => value.value === option.value}
                    loading={this.props.actionType === actionTypes.VGO_GET_USER_FILTER_REQUEST}
                    renderInput={params => (
                        <TextField {...params}
                            size="small"
                            label="Enter filter value"
                           // value={{ label: String(filter) || "" }}
                            loading={this.props.actionType === actionTypes.VGO_GET_USER_FILTER_REQUEST ? "true" : "false"}
                            InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {(this.props.isLoading && this.props.activeFilterIndex === tableMeta.rowIndex && this.props.actionType === actionTypes.VGO_GET_USER_FILTER_REQUEST) ? <CircularProgress color="inherit" size={20} /> : <span style={{width:20}}></span>}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                        />
                    )}
                    
                  
                    onChange={(event, value, ref) => this.handleFilterValueChange(event,tableMeta.rowIndex)}

                />
            );
         

    }

    deleteTemplate = (value, tableMeta, updateValue) => {
        return (
            <IconButton id={"delete" + tableMeta.rowIndex} type="button" onClick={this.deleteRow}><ClearIcon /></IconButton>
        );
    }


    handleClose = () => {
        this.setState({ showProfile: false })
    }

    handleChange = (event, value, ref,index) => {
        
        let rowIndex = index;
        let item = this.props.filters.find(p => p.profileName === event.target.textContent)
        console.log("item", item);
        console.log("filters", this.props.filters);
        let data = { profileId: 0, profileName: "", value: '' }
        if (item) { 
            data = { profileId: item.profileId, profileName: event.target.textContent, value: '' }          
        }
        this.props.dispatch(updateFilter(this.props.filterData, data, rowIndex));
        this.setState({ profileId: data.profileId })
    }

    handleFilterValueChange = (event,index) => { 
         
        let rowIndex = index;
        let data = { profileId: "", profileName: "", value: "" }
        if (event.target.textContent) {
             data = { profileId: profileId, profileName: profileName, value: event.target.textContent }
        }
        this.props.dispatch(updateFilter(this.props.filterData, data, rowIndex)); 
    }

    addRow = () => {
        this.props.dispatch(updateFilter(this.props.filterData, "", ""));
    }

    deleteRow = (event) => {
        this.props.dispatch(updateFilter(this.props.filterData, "", event.currentTarget.id.replace("delete", "").trim()));
    }

    handlePasswordChange = (password, passwordHelper, validatedStatus) => { 
        this.setState({
            passwordHelper: passwordHelper,
            validateStatus: validatedStatus,
            passwordError: false
        })
        this.changeUser(user => user.password = password)
    }
    handlePasswordBlur = (event, passwordError) => {
        this.setState({ passwordError: passwordError })
        if (event.target.name === "newPassword") {

            this.blurValidation('password')
        } else {
            this.blurValidation('password2')
        }

                
    }
    handlePasswordFocus = (event, passwordError, passwordHelper) => {
        this.setState({
            passwordError: false,
            passwordHelper: passwordHelper,
            showPasswordHelper: true,
            havePrimaryPasswordFocus:true
        })
    } 

    handleConfirmPasswordChange = (password, passwordHelper, validatedStatus) => {
        this.setState({ conPasswordError: !validatedStatus, confirmPasswordValidateStatus: validatedStatus, }) 
        this.changeUser(user => user.password2 = password)
    }
    handleConfirmPasswordBlur = (event, passwordError) => {
        this.setState({ conPasswordError: passwordError })
        this.blurValidation('password2')
        
    }
    handleConfirmPasswordFocus = (event, passwordError) => {
        this.setState({ conPasswordError: false, havePrimaryPasswordFocus:false }) 
    }
    renderRoleChip = (option, index, getTagProps) => {
        
        if (option.roleId === this.props.user.defaultRoleId) {
            return (<Chip id={option.roleId} style={{ backgroundColor: defaultRoleBGColor, color: defaultRoleColor }} onClick={this.handleRoleChipClick} variant="outlined" label={`${option.roleName}`} {...getTagProps({ index })} />)
        } else {
            return (<Chip id={option.roleId} variant="outlined" onClick={this.handleRoleChipClick} label={`${option.roleName}`} {...getTagProps({ index })} />)
        }
    }

    blurValidation = (fieldName) => {

        this.props.dispatch(validateFieldsOnBlur(this.props.user, fieldName));
    }

    render() {
        const columns = [
            {
                name: "profileName",
                label: "Filter Name",
                options: {
                   // ...defaultColumnOptions,
                    customBodyRender: this.profileNameTemplate,
                },
            },
            {
                name: "value",
                label: "Filter Value",
                options: {
                    //...defaultColumnOptions,
                    customBodyRender: this.profileValueTemplate
                },
            },
            {
                name: "",
                label: "",
                options: {
                    //...defaultColumnOptions,
                    customBodyRender: this.deleteTemplate,
                },
            },
            {
                name: "profileId",
                label: "", 
                options: {
                    display: false,
                },
            },
        ]; 
         
        const { classes } = this.props; 
        return ( 
            <Card variant="outlined" > 
                
                <Box mr={4} mb={8} mt={3} ml={3}   > 
                    <Grid container direction="row" justifyContent="space-between" spacing={6} >
                        <Grid item lg={6}  >
                            <Grid container spacing={7}>
                                <Grid item container lg={12}>
                                    <Grid item><Typography variant="subtitle1">Profile Info</Typography></Grid>
                                    <Grid item container direction="row" spacing={0} justifyContent="space-between">
                                        <Grid container item   lg={4}   className={classes.profileInfoSec1}  >
                                            <Grid item xl={3}   >
                                                <Box  className={classes.avatarPicBox}  >
                                                    <Avatar alt={`${this.props.user.firstName} ${this.props.user.lastName}`} className={classes.avatarPic} src={this.props.user.profilePicUrl}></Avatar> 
                                                </Box>
                                                </Grid>
                                            <Grid item xl={9}  > 
                                                <Box  pt={1} >
                                                        <FormControlLabel
                                                            control={<Switch
                                                                color="primary"
                                                                checked={this.props.user.isActive}
                                                                onChange={this.toggleActive}
                                                            />}
                                                            className={classes.body2}
                                                            label="Active" /> 

                                                        <FormControlLabel 
                                                            control={<Switch
                                                                color="primary"
                                                                checked={this.props.user.hasAdmin}
                                                                onChange={this.toggleAdmin}
                                                            />}
                                                            className={classes.body2}
                                                            label="Admin Access" />  
                                                    </Box>  
                                                </Grid>
                                            {this.props.user.userId > 0 &&
                                            <Grid item container className={classes.dateSection} lg={12} >
                                                <Box pt={1}>
                                                <Grid item container direction="row" spacing={0} >
                                                   
                                                    <Typography   classes={{root:classes.dateElementPadding}} className={classes.subtitle3}>Created</Typography>
                                                    <Typography  className={classes.customLabel}>{this.formatDateTime(this.props.user.createDateTime)}</Typography>
                                                   
                                                    </Grid> </Box><Box pt={1}>
                                                <Grid item container direction="row"  >
                                                    
                                                    <Typography   classes={{ root: classes.dateElementPaddingUpdate }} className={classes.subtitle3}>Updated</Typography>
                                                    <Typography   className={classes.customLabel}>{this.formatDateTime(this.props.user.lastUpdateDateTime)}</Typography>
                                                    
                                                </Grid>
                                                </Box>
                                                </Grid>
                                            }
                                        </Grid> 
                                            <Grid container item spacing={2} lg={8}>
                                            <Box width="100%" mt={2.3} pl={2} maxWidth={464}>
                                                <Grid container item spacing={5}>
                                                <Grid item lg><Box maxWidth={464}>
                                                        <TextField autoFocus={!this.state.isUpdate?true:false}
                                                            required
                                                            variant={this.props.textFieldVariant}
                                                            label="Email Address"
                                                            value={this.props.user.email}
                                                            size="small"
                                                            fullWidth={true}
                                                            onChange={event => this.changeUser(user => user.email = event.target.value)}
                                                            onBlur={(event) => { this.blurValidation('email') }}
                                                            error={this.props.errors.email}
                                                            helperText={this.props.errors.email ? this.props.errors.email : " "}
                                                            inputProps={{
                                                                maxLength: 50
                                                            }}

                                                            disabled={this.props.user.userId > 0?true:false}
                                                    >
                                                    </TextField></Box>
                                                </Grid>
                                                <Grid container item direction="row" justifyContent="space-between" spacing={4}  >
                                                        <Grid item lg  ><Box pt={.3} maxWidth={216}>
                                                            <TextField autoFocus={this.state.isUpdate ? true : false}
                                                            required
                                                            variant={this.props.textFieldVariant}
                                                            label="First Name"
                                                            size="small"
                                                            fullWidth={true}
                                                            value={this.props.user.firstName}
                                                                onChange={event => this.changeUser(user => user.firstName = event.target.value)}
                                                                onBlur={(event) => { this.blurValidation('firstName') }}
                                                                error={this.props.errors.firstName}
                                                                helperText={this.props.errors.firstName ? this.props.errors.firstName : " "}
                                                                inputProps={{
                                                                    maxLength: 50
                                                                }}
                                                        >
                                                        </TextField></Box>
                                                    </Grid>
                                                        <Grid item lg  ><Box pt={.3} maxWidth={216}>
                                                        <TextField
                                                            required
                                                            variant={this.props.textFieldVariant}
                                                            label="Last Name"
                                                            size="small"
                                                            fullWidth={true}
                                                            value={this.props.user.lastName}
                                                                onChange={event => this.changeUser(user => user.lastName = event.target.value)}
                                                                onBlur={(event) => { this.blurValidation('lastName') }}
                                                                error={this.props.errors.lastName}
                                                                helperText={this.props.errors.lastName ? this.props.errors.lastName : " "}
                                                                inputProps={{
                                                                    maxLength: 50
                                                                }}
                                                        >
                                                            </TextField></Box>
                                                    </Grid>
                                                        </Grid>
                                                    {!this.props.user.userId > 0 &&
                                                        <Grid item container>
                                                    <Grid item container direction="row" justifyContent="space-between" spacing={4} >
                                                        {/* TODO: use <Input> with <Adornment>*/}
                                                        {/* TODO: too long helperText widens control*/}
                                                        <Grid item lg> 
                                                            
                                                                <PasswordText
                                                                    id="newPassword"
                                                                    name="newPassword"
                                                                    label="Password"
                                                                    handlePasswordChange={this.handlePasswordChange}
                                                                    holderName="Password *"
                                                                    error={this.state.passwordError || this.props.errors.password?true:false}
                                                                    helperText={this.state.passwordError ? "The password rules have not been met" : this.props.errors.password ? this.props.errors.password:""}
                                                                    handlePasswordBlur={this.handlePasswordBlur}
                                                                    handlePasswordFocus={this.handlePasswordFocus}
                                                                   
                                                                    value={this.props.user.password}
                                                                    validateHelper={true}
                                                                    autoFocus={false}
                                                            /> 
                                                            </Grid> 
                                                     
                                                        <Grid item lg> 
                                                            <PasswordText
                                                                id="confirmPassword"
                                                                name="confirmPassword"
                                                                label="Confirm Password"
                                                                handlePasswordChange={this.handleConfirmPasswordChange}
                                                                holderName="Confirm Password *"
                                                                    error={this.state.conPasswordError || this.props.errors.password2 ? true : false}
                                                                    helperText={this.state.conPasswordError ? "Must match password" : (this.props.user.password2.length > 0 && (this.props.user.password === this.props.user.password2)) ? "Match" : this.props.errors.password2 ? this.props.errors.password2:""}
                                                                handlePasswordBlur={this.handleConfirmPasswordBlur}
                                                                handlePasswordFocus={this.handleConfirmPasswordFocus}
                                                                
                                                                value={this.props.user.password2}
                                                                value2={this.props.user.password}
                                                                validateHelper={false}
                                                                autoFocus={false}
                                                            />
                                                         </Grid>
                                                        </Grid>
                                                         
                                                        <Grid item lg style={{ 'marginLeft': '-7px' }} >
                                                            <Box mt={3}>
                                                                <PasswordHelp showHelper={this.state.showPasswordHelper} primaryFocus={this.state.havePrimaryPasswordFocus} passwordHelper={this.state.passwordHelper} ></PasswordHelp>
                                                                </Box>
                                                                   
                                                            </Grid>
                                                                
                                                                        </Grid >
                                                       
                                                 }
                                                </Grid>
                                                </Box>
                                            
                                            </Grid>
                                       
                                    </Grid>
                                </Grid>
                                
                                <Grid item className={classes.contactInfoItem}   ><Box pt={2} maxWidth={689}>
                                    <Grid item><Typography variant="subtitle1">Contact Info</Typography></Grid>
                                        <Grid item container spacing={4}>
                                            <Grid container item direction="row" justifyContent="space-between" spacing={1} >
                                            <Grid item lg={5}><Box pt={1}>
                                                <TextField
                                                                required
                                                                variant={this.props.textFieldVariant}
                                                                label="Company Name"
                                                                size="small"
                                                                fullWidth={true}
                                                                value={this.props.user.companyName}
                                                    onChange={event => this.changeUser(user => user.companyName = event.target.value)}

                                                    onBlur={(event) => { this.blurValidation('companyName') }}
                                                    error={this.props.errors.companyName}
                                                    helperText={this.props.errors.companyName ? this.props.errors.companyName : " "}
                                                    inputProps={{
                                                        maxLength: 100
                                                    }}
                                                            >
                                                </TextField></Box>
                                                        </Grid>
                                            <Grid item lg={3}><Box pt={1}>
                                                            <TextField
                                                                variant={this.props.textFieldVariant}
                                                                label="Phone"
                                                                size="small"
                                                                fullWidth={true}
                                                                value={this.props.user.phone}
                                                    onChange={event => this.changeUser(user => user.phone = event.target.value)}
                                                    inputProps={{
                                                        maxLength: 20
                                                    }}
                                                            >
                                                </TextField></Box>
                                                        </Grid>
                                                <Grid item lg={3}><Box pt={1}>
                                                            <TextField
                                                                variant={this.props.textFieldVariant}
                                                                label="Fax"
                                                                size="small"
                                                                fullWidth={true}
                                                                value={this.props.user.fax}
                                                    onChange={event => this.changeUser(user => user.fax = event.target.value)}
                                                    inputProps={{
                                                        maxLength: 20
                                                    }}
                                                            >
                                                </TextField></Box>
                                                        </Grid>
                                                    </Grid>
                                            <Grid container item  >
                                            <Box pt={.4} width={1}>
                                                            <TextField
                                                                variant={this.props.textFieldVariant}
                                                                label="Address 1"
                                                                size="small"
                                                                fullWidth={true}
                                                                value={this.props.user.address1}
                                                    onChange={event => this.changeUser(user => user.address1 = event.target.value)}
                                                    inputProps={{
                                                        maxLength: 200
                                                    }}
                                                            >
                                                            </TextField>
                                            </Box>
                                                        
                                        </Grid>
                                        <Grid container item> 
                                            <Box pt={.4} width={1}>
                                                <TextField
                                                    variant={this.props.textFieldVariant}
                                                    label="Address 2"
                                                    size="small" 
                                                    fullWidth={true}
                                                    value={this.props.user.address2}
                                                    onChange={event => this.changeUser(user => user.address2 = event.target.value)}
                                                    inputProps={{
                                                        maxLength: 200
                                                    }}
                                                >
                                                </TextField> </Box>
                                        </Grid>
                                           
                                        <Grid container item direction="row" justifyContent="space-between" spacing={2}>
                                            <Grid item  lg={5}>
                                            <TextField
                                                variant={this.props.textFieldVariant}
                                                label="City"
                                                size="small"
                                                fullWidth={true}
                                                value={this.props.user.city}
                                                    onChange={event => this.changeUser(user => user.city = event.target.value)}
                                                    inputProps={{
                                                        maxLength: 50
                                                    }}
                                                >

                                                </TextField>
                                            </Grid>
                                            <Grid item lg={3}>
                                                            <TextField
                                                                variant={this.props.textFieldVariant}
                                                                label="State"
                                                                size="small"
                                                                fullWidth={true}
                                                                value={this.props.user.state}
                                                    onChange={event => this.changeUser(user => user.state = event.target.value)}
                                                    inputProps={{
                                                        maxLength: 50
                                                    }}
                                                            >
                                                            </TextField>
                                                        </Grid>
                                            <Grid item lg={3}>
                                                            <TextField
                                                                variant={this.props.textFieldVariant}
                                                                label="Zip Code"
                                                                size="small"
                                                                fullWidth={true}
                                                                value={this.props.user.zip}
                                                    onChange={event => this.changeUser(user => user.zip = event.target.value)}
                                                    inputProps={{
                                                        maxLength: 20
                                                    }}
                                                            >
                                                            </TextField>
                                            </Grid></Grid>
                                        <Grid container item direction="row" justifyContent="space-between" spacing={2} >
                                            <Grid item lg ={5}>
                                                <FormControl >
                                                    <InputLabel id="labelCountry">Country</InputLabel>
                                                    <Select MenuProps={{ disableScrollLock: true }}
                                                        labelId="labelCountry"
                                                        id="country"
                                                        name="country"
                                                        label="Country"
                                                        value={this.props.user.country || ""}
                                                        onChange={event => this.changeUser(user => user.country = event.target.value)}>
                                                        {this.state.countryOption}
                                                    </Select>
                                                </FormControl> 
                                            </Grid>
                                            <Grid item lg={3}></Grid>
                                            <Grid item lg={3}></Grid>
                                            </Grid>
                                        </Grid></Box>
                                    </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={6}> 
                            <Grid container spacing={7} justifyContent="space-between"  >
                                <Grid container item lg={10} spacing={2} justifyContent="space-between"> 
                                    <Grid item>
                                        <Box mb={1}>
                                            <Typography variant="subtitle1">Role Assignment</Typography>
                                        </Box>
                                    </Grid>
                                <Grid item container>
                                    <Autocomplete
                                        multiple
                                        id="size-small-outlined-multi"
                                         
                                            style={{width:'569px'}}
                                        disableClearable
                                        filterSelectedOptions
                                        options={this.props.roles}
                                        getOptionLabel={option => option.roleName}
                                        value={this.props.user.roles}
                                        renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    this.renderRoleChip(option, index, getTagProps)
                                                ))
                                            }
                                        renderInput={params => (
                                                <TextField {...params}
                                                    variant="outlined"
                                               
                                                    label="Roles"
                                                    required
                                                    placeholder="Roles"
                                                error={this.state.roleRemoveError || this.props.errors.roles?true:false}
                                                helperText={this.state.roleRemoveError ? "A new default role must be selected before removing this one." : this.props.errors.roles ? this.props.errors.roles : " "}
                                               // onBlur={this.onRolesBlur}

                                                onBlur={(event) => { this.blurValidation('roles') }}
                                                
                                                />
                                            )}
                                            onChange={this.onRolesChange}
                                            
                                    />
                                    </Grid> 
                                </Grid> 
                                <Grid item container justifyContent="space-between" spacing={7} >
                                    <Grid item container justifyContent="space-between" direction="row"  >
                                        <Grid item xs={5}><Typography variant="subtitle1">Data Filters</Typography></Grid>
                                        <Box mr={9.2}>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            disableElevation
                                            startIcon={<AddIcon />}
                                            onClick={this.addRow}
                                                className={classes.customMedButton}
                                            ><Typography className={classes.customMedButtonText} >Add Filter</Typography></Button>
                                            </Box>
                                    </Grid>
                                    <Box width="100%" ml={5}>
                                    <MUIDataTable className={classes.filterTable} title={ ''}
                                            data={this.props.filterData}
                                            columns={columns}
                                            options={this.state.options}
                                        /> 
                                        </Box>
                                </Grid>
                            </Grid>
                        
                </Grid>
                       </Grid>
                     </Box>
                    </Card>
             
        );
    } 
}

/**
 * 
 * @param {State} state 
 */
function mapStateToProps(state) {
    let userState = state.users;
    console.log("userSate",userState);
    let props = {
        users: userState.users,
        user: userState.user,
        roles: userState.roles,
        filter: userState.filter,
        filters: userState.filters,
        filterData: userState.filterData,
        isLoading: state.loader.isLoading,
        actionType: state.loader.type,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage,
        isUpdateSuccess: userState.isUpdate,
        countryList: userState.countryList,
        activeFilterIndex: userState.dataIndex,
        errors: userState.errors ? userState.errors : {},
    };
    
    return props;
};

export default withRouter(connect(mapStateToProps)(withStyles(usersTheme)(UserEdit)));

/**
 * Default properties for the UserEdit component
 */
UserEdit.defaultProps = {
    textFieldVariant: 'standard',
};

/**
 * Property types for the UserEdit properties
 */
UserEdit.propTypes = {
    textFieldVariant: PropTypes.oneOf([
        'filled',
        'outlined',
        'standard',
    ]),
};


