import * as yup from "yup";
export const userValidationSchema = yup.object().shape({
    email: yup.string().email("The email address is not properly formatted").required("Email is required"),
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    companyName: yup.string().required("Company Name is required"),
    password: yup.string().test('isPassword',
        'Password is required',
        function (value, context) {
            if (context.parent.userId === 0) {
                return (value === null || value === "") ? false : true;
            } {
                return true
            }
            

        }).nullable(),
    password2: yup.string().test('isConfirmPassword',
        'Confirm password is required',
        function (value, context) {
            if (context.parent.userId === 0) {
                return (value === null || value === "") ? false : true;
            } {
                return true
            }


        }).nullable(),
    roles: yup.array().min(1, 'Roles is required'),

})