import { ActionTypes as types } from './actionTypes';
import api from 'admin/api/adminApi';
import { showError, showSuccess, hideError } from './alertActions';
import { getError } from './errorActions';
import { databaseSetUpSchema } from 'admin/components/section/database/DataBaseValidationSheme';
import { validateObject, validateField, validateObjectAt } from 'admin/actions/validationAction';

const message = 'Please correct the indicated items'
const apiGetDatabases = () => {
    return api.getDatabases();
};

const apiGetDatabase = (id) => {
    return api.getDatabase(id);
};

const apiUpdateDatabase = (database) => {
    return api.updateDatabase(database);
};

const apiDeleteDatabase = (id) => {
    return api.deleteDatabase(id);
};
const apiCanDeleteDatabase = (id) => {
    return api.canDeleteDatabase(id);
};


const getDatabasesRequest = () => {
    return {
        type: types.VGO_GET_DATABASES_REQUEST,
         errors: {}
    }
};

const getDatabasesSuccess = (resp) => {
    return {
        type: types.VGO_GET_DATABASES_SUCCESS,
        databases: resp.data,
        errors: {}
    };
};

const updateDatabaseRequest = (database) => {
    return {
        type: types.VGO_UPDATE_DATABASE_REQUEST,
        database: database
    };
};

const updateDatabaseSuccess = (resp) => {
    return {
        type: types.VGO_UPDATE_DATABASE_SUCCESS,
        database: resp.data
    };
};

const getDatabaseRequest = (id) => {
    return {
        type: types.VGO_GET_DATABASE_REQUEST,
        companyId: id 
    };
};

const getDatabaseSuccess = (resp) => {
    let database = resp.data;
    if (!database) {
        database = newDatabase();
    }
    return {
        type: types.VGO_GET_DATABASE_SUCCESS,
        database: resp.data 
    };
};

const deleteDatabaseRequest = (id) => {
    return {
        type: types.VGO_DELETE_DATABASE_REQUEST,
        databaseId: id
    };
};
const deleteDatabaseSuccess = (id) => {
    return {
        type: types.VGO_DELETE_DATABASE_SUCCESS,
        databaseId: id
    };
};





export const getDatabases = () => {
    return dispatch => {
        dispatch(getDatabasesRequest());
        return apiGetDatabases()
            .then((resp) => {
                dispatch(getDatabasesSuccess(resp));
                hideError(dispatch);
            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
            });
    }
};

export const getDatabase = (id) => {
    return dispatch => {
        dispatch(getDatabaseRequest(id));
        return apiGetDatabase(id)
            .then((resp) => {
                dispatch(getDatabaseSuccess(resp));
                hideError(dispatch);
            })
            .catch(err => {
                const errorMessage = err.response.data;
                dispatch(getError(errorMessage));
                showError(dispatch, errorMessage, 5000);
            });
    };
};

export const updateDatabase = (database,previousDatabaseValue) => {
   // let passCalendarValidation = true;
   
    return dispatch => {
        validateObject(databaseSetUpSchema, database)
            .then(resp => {
                if (resp !== true) {
                    //  fieldValidation = false; 
                    dispatch(setValidateOnBlur(resp));
                    dispatch(getError(message));
                    showError(dispatch, message, 5000);
                } else {
                    dispatch(updateDatabaseRequest(database));
                    return apiUpdateDatabase(database)
                        .then((resp) => {
                            dispatch(updateDatabaseSuccess(resp));
                            showSuccess(dispatch, 'Success', 'Connection saved!', 5000);
                        })
                        .catch(err => {
                            const { message } = err;
                           
                            var errMsg = "The server can not be found";
                            if (err.response != null && err.response.data !== "") {
                                errMsg = err.response.data;
                            }
                            dispatch(getError(errMsg));
                            showError(dispatch, errMsg, 5000);
                        });
                }

            });
    }
}; 
export const checkServerInfoChange = (database, previousDatabaseValue) => {
    if (database.databaseId) {
        if (database.databaseType !== previousDatabaseValue.databaseType || database.serverName !== previousDatabaseValue.serverName || database.userId !== previousDatabaseValue.userId || database.tenantName !== previousDatabaseValue.tenantName
            || database.database !== previousDatabaseValue.database || database.isMultiTenant !== previousDatabaseValue.isMultiTenant) {
            return true;
        }
        return false;

    } else {
         return true;
    }

} 
export const deleteDatabase = (id) => {
    return dispatch => {
        dispatch(deleteDatabaseRequest(id));
        return apiDeleteDatabase(id)
            .then((resp) => {
                dispatch(deleteDatabaseSuccess(id));
                showSuccess(dispatch, 'Success', 'Connection deleted successfully', 5000);
            })
            .catch((err) => {
                const errorMessage = err.response.data;
                dispatch(getError(errorMessage));
                showError(dispatch, errorMessage, 5000);
            });
    };
};
export const validateOnBlur = (fieldName, value, fieldPath, dependencyFields) => {
    return dispatch => {
        if (fieldPath === "tenantName" || fieldPath === "password") {
            validateObjectAt(databaseSetUpSchema, value, fieldPath, fieldPath)
                .then(resp => {
                    dispatch(setValidateOnBlur(resp));
                })
        } else {
            validateField(databaseSetUpSchema, fieldPath, value, fieldPath, dependencyFields)
                .then(resp => {

                    dispatch(setValidateOnBlur(resp));
                })
        }

    }
}
 
const setValidateOnBlur = (errors) => {
    return {
        type: types.VGO_VALIDATE_DATABASEFIELD_ONBLUR,
        errors: errors,
    };
};


export const getChangeDatabase = (change) => {
    return {
        type: types.VGO_CHANGE_DATABASE,
        change
    };
}

export const getNewDatabase = () => {
    return {
        type: types.VGO_NEW_DATABASE,
        errors: {}
    };
}

export const newDatabase = () => {
    let database = {
        b1Password: "",
        b1UserName: "",
        database: "",
        databaseConnectionName: "",
        databaseId: 0,
        databaseType: "sqlServer",
        description: "",
        isDefault: false,
        password: "",
        serverName: "",
        userId: "",
        language: "",
        licenseServer: "",
        licenseServerPort: "",
        timeZone: "",
        isMultiTenant: false,
        tenantName:""
    };
    return database;
};