import React from "react";
import { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { withStyles } from '@mui/styles';

const styles = theme => ({
    checkbox: {
        padding:'0px!important'
    },
});

function EditCheckBoxColumnTemplate(props) {
    const row = { ...props.data };
    const [checked, setChecked] = useState(row[props.column.fieldName]);
    const handleChange = (event) => {
        event.target.focus()
        const checked = event.target.checked;

        setChecked(checked);
        let { column, foreignKeyData, ...row } = props.data;
        column = null;
        foreignKeyData = null;
        row[props.column.fieldName] = checked;
        props.setGridValue(row, 'check', props.column.fieldName);

    };
    const { classes } = props;
    return ( 
        <Checkbox className={classes.checkbox} checked={checked} onChange={handleChange} size="small" />
    );

};
export default withStyles(styles)(EditCheckBoxColumnTemplate)