import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import { getRoles, deleteRole } from 'admin/actions/roleActions';
import { Box } from '@mui/material'
import {  withStyles} from '@mui/styles';
import { produce } from 'immer';

import DeleteButton from 'common/DeleteButton.js'
import { DateTime } from 'luxon';
import { rolesTheme } from './theme/rolesTheme.js';

import api from 'admin/api/adminApi';
import ActionBackdrop from 'admin/components/section/settings/Backdrop';
import { objectListFormatDate } from 'admin/components/Common//DateFormat';
 
import { MuiGridOptions } from 'admin/components/Common/CommonOptions';

let cellIndex = ""
const deleteColumnIndex = 4;
const defaultColumnOptions = {
    filter: true,
    sort: true,
 
};
class RoleList extends React.Component {

    constructor(props) {
        super(props);
       this.state = {
            columns: [
                
                {
                    name: "roleName",
                    label: "Name",
                    options: {
                        ...defaultColumnOptions,
                    }
                    
               }
               ,
               {
                   name: "usersCount",
                   label: "Users",
                   options: {
                       ...defaultColumnOptions
                   }
               }
               ,
                {
                    name: "createdDateTime",
                    label: "Created",
                    options: {
                        ...defaultColumnOptions,
                        customBodyRender: objectListFormatDate
                    }
                },
                {
                    name: "updatedDateTime",
                    label: "Updated",
                    options: {
                        ...defaultColumnOptions,
                        customBodyRender: objectListFormatDate
                    }
                },
                {
                    label: "",
                    name: "",
                    options: {
                        ...defaultColumnOptions,
                        customBodyRender: this.deleteTemplate,
                        filter: false,
                    }
                }
            ],
            options: {
                download: false,
                elevation: 0,
                responsive: 'scrollMaxHeight',
                pagination:false,
                filter: true,
                filterType: 'multiselect', 
                onViewColumnsChange: ( changedColumn, action ) => {
                    console.log( changedColumn, action );
                    let columnIndex = this.state.columns.findIndex( c => c.name === changedColumn );
                    this.setState( produce( draft => {
                        draft.columns[ columnIndex ].options.display = action === "add";
                    }));
                },
                onRowClick: (rowData, rowMeta) => {
                    let dataIndex = rowMeta.dataIndex;
                    let role = this.props.roles[dataIndex];
                    this.getRoleData(role.roleId)
                },
                onCellClick: (rowNumber, columnId) => {
                    this.setCellIndex(columnId.colIndex)
                    
                },
                print: false,
                searchOpen: true,
                selectableRows: 'none',
                sortOrder: {
                    name: 'roleName',
                    direction: 'asc'
                },
                onFilterChange: (changedColumn, filterList, type, changedColumnIndex, displayData) => {
                    this.setState(produce(draft => {
                        draft.columns[changedColumnIndex].options.filterList = filterList[changedColumnIndex];
                    }));
                },
                onColumnSortChange: (changedColumn, direction) => {
                    this.setState(produce(draft => {
                        draft.options.sortOrder = { name: changedColumn, direction: direction };
                    }));
                }
            }
           
        };
    }
    componentDidMount() {
        this.dispatchGetRole();
    }

    dispatchGetRole=()=> {
        this.props.dispatch(getRoles() );
    }
    deleteTemplate = (value, tableMeta, updateValue) => {
        let columnIndex = 0;
        /* return <DeleteButton preValidation={this.dispatchValidateDeleteRole} showWarning={tableMeta.rowData[deleteColumn] > 0} onClick={this.dispatchDeleteRole} id={tableMeta.rowData[columnIndex]} />*/
        let roleName = tableMeta.rowData[columnIndex];
        let role = this.props.roles.find(u => u.roleName === roleName);
        let idValue = role.roleId;
        return <DeleteButton preValidationServer={api.canDeleteRole} onClick={this.dispatchDeleteRole} id={idValue} />
         
    }
    dispatchDeleteRole = (roleId) => {
       // let role = this.props.roles.find(u => u.roleName === roleName);
        this.props.dispatch(deleteRole(roleId));
    }
    dispatchValidateDeleteRole = (roleName) => {
        let role = this.props.roles.find(u => u.roleName === roleName);
        if (role.isDefaultRole) {
            return { canDelete: false, message: "This role can not be deleted while it is the default role for one or more users." };
        } else {
            return { canDelete: true, message: "" };
        }
    }

    formatDate = (value) => {
        if (value) {
            if (typeof (value) === "string") {
                value = DateTime.fromISO(value);
            }
            return value.toFormat('LL-dd-yyyy hh:mm:ss a');
        } else {

            return value;
        }

    }
    getRoleData = (roleId) => {

        if (cellIndex !== deleteColumnIndex) {
            this.props.history.push("/Admin/Roles/" + roleId);
        }
    }

    setCellIndex = (index) => {
        cellIndex = index;

    }
    
    render() {
        const { classes } = this.props;
        return (
            <Box border={1} className={classes.roleListBox}> {/* default in theme?? */}
                {this.props.isDataLoading && <ActionBackdrop />}
               <MUIDataTable container
                    title={''}
                    data={this.props.roles}
                    columns={this.state.columns}
                    options={MuiGridOptions(this.state.options, this.props.isDataLoading)}
                /> 
            </Box>
        );

    }

}

/**
 * 
 * @param {*} state 
 */
function mapStateToProps(state) {
    console.log("Roles List", state);
    return {
        roles: state.roles.roles,
        isLoading: state.loader.isLoading,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage,
        isDataLoading: state.roles.isLoading,
    };
};

export default withRouter(connect(mapStateToProps)(withStyles(rolesTheme)(RoleList)));
