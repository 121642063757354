import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid, Typography,  Box, AppBar, Tabs, Tab } from '@mui/material'
import { getChangeForm } from 'admin/actions/formSetupActions';
import { spacing } from '@mui/system';
import MuiTextField from '@mui/material/TextField';
import { withStyles } from '@mui/styles';
import { styled } from '@mui/styles';
import * as Enum from 'client/components/Common/Enum';
import Autocomplete from '@mui/lab/Autocomplete';
 
import { getDataView } from 'admin/actions/dataViewActions';
 
import MUIDataTable from 'mui-datatables';
 
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {getParameterListObject, getFormMappingListObject } from 'admin/actions/formActions';
import cloneDeep from 'lodash/cloneDeep';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button';
 
import { formTheme } from 'admin/components/section/form/theme/formTheme';
 
import PropTypes from 'prop-types';
 
import AlertMessage from 'admin/components/section/dataViews/dataFilters/AlertMessage';
 
const TextField = styled(MuiTextField)(spacing);
const MUIDataTableStyled = styled(MUIDataTable)(({ theme }) => ({
    root: {
            paddingRight: "0px!important",
            paddingLeft:"0px!important" 
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}
const userAppliedFilter ="userAppliedFilter"
class ControlParameterList extends React.Component {
    constructor(props) {
        super(props);  
        this.tableOptions = {
            download: false,
            elevation: 0,
            //responsive: 'scrollMaxHeight',
            filter: false,
            filterType: 'multiselect',
            jumpToPage: false,
            print: false,
            searchOpen: false,
            pagination: false,
            selectableRows: 'none',
            toolbar: false,
            search: false,
            viewColumns: false,
            showAlert: false,
        }
        this.state = {
            tabIndex: 0,
            filterDataTableData: this.getFilterTabDataSource(),
            mappingDataTableData: this.getMappingTabDataSource(),
            mappingFormControlList: [],
            filterFormControlList:[]

        }

    } 
    getFilterDataTableColumn = () => {
        return ( [{
                name: "controlName",
                label: "Filter Using",
            options: {
                setCellProps: () => ({ className: this.props.classes.lookupListFCControl }),
                customBodyRender: this.FilterUsingColumTemplate,
                //setCellProps: () => ({ 
                //    style: { 
                //        width: 250
                //    }
                //})
                   
                }
            },
            {
                name: "listField",
                label: "Apply to Column",
                options: {
                   setCellProps: () => ({ className: this.props.classes.lookupListSCControl }),
                    customBodyRender: this.ApplyColumTemplate,
                    //setCellProps: () => ({
                    //    style: {
                    //        width: 250
                    //    }
                    //})
                   
                }

            },
            {
                name: "isMandatory",
                label: "Required",
                options: {
                    setCellProps: () => ({ className: this.props.classes.lookupListTCControl}),
                    customBodyRender: this.thirdColumnTemplate,
                    //setCellProps: () => ({
                    //    style: {
                    //        width: 50
                    //    }
                    //})
                }
            },
            {
                name: "",
                label: "",
                options: {
                    setCellProps: () => ({ className: this.props.classes.lookupListTCControlsm }),
                    customBodyRender: this.deleteRowTemplate,
                    //setCellProps: () => ({
                    //    style: {
                    //        width: 50
                    //    }
                    //})
                }
            }]
        )
    }
    getMappingDataTableColumn = () => {
       return ( [
            {
               name: "fieldName",
                label: "List Column",
               options: {
                   setCellProps: () => ({ className: this.props.classes.lookupListFCControl1 }),
                    customBodyRender: this.ApplyColumTemplateMapping,
                   
                }
            },
            {
                name: "controlName",
                label: "Form Control",
                options: {
                    setCellProps: () => ({ className: this.props.classes.lookupListSCControl1 }),
                    customBodyRender: this.FilterUsingColumTemplateMapping,
                   
                }

            },
            {
                name: "",
                label: "",
                options: {
                    setCellProps: () => ({ className: this.props.classes.lookupListTCControlsm }),
                    customBodyRender: this.deleteRowTemplate, 
                    filter: false,
                }
            }
        ])
    }
    newParameterListObject = () => {
        var newObject = getParameterListObject();
        //newObject.controlName = "Select";
        //newObject.listField = "Select";
        newObject.parentControlId = this.props.activeFormControl.formControl.controlId;
        return newObject;
    }
    newMappingListObject = () => {
        var newObject = getFormMappingListObject();
       /* newObject.listFieldValue = "Select";*/
        newObject.parentControlId = this.props.activeFormControl.formControl.controlId;
        //newObject.isGrid=this.props.activeFormControl.
        return newObject;
    }
    componentDidMount() {
        
        this.getCurrectSectionControls(false)
        this.getCurrectSectionControls(true)
       // this.getFilterTabDataSource();
        //this.getMappingTabDataSource();
       
    }
    componentDidUpdate(prevProps, prevState) {
        //if (prevState.fullControlSourceMapping != this.state.fullControlSourceMapping) {
        //    this.getCurrectSectionControls(true)
        //}
    }
    
    deleteRowTemplate = (value, tableMeta, updateValue) => {
       
        var showDeleteIcon = true;
        if (this.state.tabIndex === 0) {
            if (this.state.filterDataTableData.length-1 === tableMeta.rowIndex) { showDeleteIcon = false; } 
        }
        if (this.state.tabIndex === 1) {
            if (this.state.mappingDataTableData.length-1 === tableMeta.rowIndex) { showDeleteIcon = false; } 
        }
        if(showDeleteIcon)
            return <DeleteOutlineIcon id={"dlb" + tableMeta.rowIndex} onClick={() => { this.onDeleteRowClick(tableMeta.rowIndex) }}></DeleteOutlineIcon>
        return "";
        
    }
    getFiltersFilterData = (selectedvalue) => {
        
        let filterObject = this.props.dataViewFilter.filter(field => {
            let name = field.parameterName ? field.parameterName.toLowerCase() : field.parameterName;
            let value = selectedvalue ? selectedvalue.toLowerCase() : selectedvalue;
            if (name === value) {
                return field;
            }
        });
        return filterObject.length > 0 ? filterObject[0] : { parameterName: "" }; 
    }
    getMappingControlData = (selectedvalue) => {
        let mappingColumnObject = this.state.fullControlSourceMapping.filter(e => e.controlName === selectedvalue || e.fieldName===selectedvalue );
        return mappingColumnObject.length > 0 ? mappingColumnObject[0] : {controlName:""}  ;
    }
    getFilterColumnData = (tableMeta, isMapping) => {       
        let colObjectfromState=[];
        if (!isMapping) {
           
            colObjectfromState = this.state.filterFormControlList.find(f => f.controlName === this.state.filterDataTableData[tableMeta.rowIndex].controlName)
            if (colObjectfromState === undefined) { colObjectfromState = this.newParameterListObject();}
            // colObjectfromState = this.state.filterDataTableData[tableMeta.rowIndex]
        } else {
           // mappingFormControlList
           // colObjectfromState = this.state.mappingDataTableData[tableMeta.rowIndex]
            colObjectfromState= this.state.mappingFormControlList.find(f => f.controlName === this.state.mappingDataTableData[tableMeta.rowIndex].controlName)
        } 
        return colObjectfromState; 
    }
    getColumDataSource = () => {
       
        return this.props.lookupFields;        
    }
    getDataViewFilterDataSource = () => {
        console.log(this.props.dataViewFilter);
        let fDataTableDataArray = [];
        this.props.dataViewFilter.filter(e => e.parameterType === userAppliedFilter).forEach((args, i) => {
            let fData = this.state.filterDataTableData.filter(e => e.listField === args.parameterName);
            if (fData.length === 0) {
                fDataTableDataArray.push(args);
            } 
        })
        return fDataTableDataArray; 
    }
    getCurrectSectionControls = (isMapping) => {
        var dSource = [];
        var fullControlSourceMapping = [];
        if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
            var filterdSource = this.props.formSectionHeader.filter(e => e.controlType !== Enum.ControlType.SectionTitle && e.controlType !== Enum.ControlType.Button  && e.controlType !== Enum.ControlType.FileUpload);
            filterdSource.forEach((row, index) => {
                let rowObj = { ...row };
                if (isMapping) {
                    let filterData = [];
                    let fData = this.state.mappingDataTableData.filter(e => e.controlName === rowObj.controlName || e.controlName === rowObj.fieldName);
                    if (fData.length === 0) {
                        dSource.push(rowObj)
                    }
                    fullControlSourceMapping.push(rowObj);
                } else {
                    dSource.push(rowObj)
                }
            });
            // fullControlSourceMapping = dSource;
        }
        else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {

            if (isMapping === false) {
                this.props.formSectionHeader.forEach((row, index) => {
                    let rowObj = { ...row };
                    if (rowObj.controlType !== Enum.ControlType.SectionTitle) {
                        rowObj.isDetailTableField = false;
                        dSource.push(rowObj)
                    }
                });

            }
            this.props.formSectionDetail.forEach((row, index) => {
                let rowObj = { ...row };
                if (rowObj.controlType !== Enum.ControlType.SectionTitle && rowObj.controlType !== Enum.ControlType.Button  && rowObj.controlType !== Enum.ControlType.FileUpload) {
                    rowObj.isDetailTableField = true;
                    rowObj.controlName = rowObj.controlName;
                    if (isMapping) {
                        let filterData = [];
                        let fData = this.state.mappingDataTableData.filter(e => e.controlName === rowObj.controlName || e.controlName === rowObj.fieldName);
                        if (fData.length === 0) {
                            dSource.push(rowObj)
                        }
                        fullControlSourceMapping.push(rowObj);
                    } else {
                        dSource.push(rowObj)
                    }
                }
            });
        }
        if (isMapping) {
            this.setState({ mappingFormControlList: dSource, fullControlSourceMapping: fullControlSourceMapping });
        } else {
            this.setState({ filterFormControlList: dSource });
        }
        //return dSource;
    }
    getMappingColumData = (selectedvalue) => {
        var dSource = this.getColumDataSource();
        var ColObject = dSource.filter(e => e.fieldName === selectedvalue);
        return ColObject.length > 0 ? ColObject[0] : this.newMappingListObject();
    }
    getOptionLabelControlName = (control) => {
        if (control.isDetailTableField) {
            return  control.controlName;
        } else {
            return control.controlName !== "" ? "Header-" + control.controlName : control.controlName;
        }
    }
    ApplyColumTemplate = (value, tableMeta, updateValue) => {
       
        return < Autocomplete
            id={"f1"}
            size="small"
            disableClearable={true}
            fullWidth={true} 
            options={this.getDataViewFilterDataSource()}
            getOptionLabel={(option) => option.parameterName}
            getOptionSelected={(option, value) => value.parameterName === option.parameterName}
            variant="body1"
            className={this.props.classes.lookupListSCControl}
            value={this.getFiltersFilterData(value)}
            onChange={
                (event, newValue) => {
                    this.handleChangeEvent("listField", newValue, tableMeta.rowIndex)
                }
            }
            renderInput={params => (
                < TextField {...params}
                    // error={this.isValidObject("controlType")}
                    id={"id"}
                    placeholder="Select"
                    value=""
                // helperText={this.isValidObject("controlType") ? "Control Type is required" : ""}
                />)}
        />
    }
    
    FilterUsingColumTemplate = (value, tableMeta, updateValue) => {
        return < Autocomplete
            id={"f1"}
            size="small"
            disableClearable={true}
            fullWidth={true}
            options={this.state.filterFormControlList}
            getOptionLabel={(option) => this.getOptionLabelControlName(option)}
            getOptionSelected={(option, value) => value.controlName === option.controlName}
            variant="body1"
            className={this.props.classes.lookupListFCControl}
            value={this.getFilterColumnData(tableMeta,false)}
            onChange={
                (event, newValue) => {
                    this.handleChangeEvent("controlName", newValue, tableMeta.rowIndex)
                }
            }
            renderInput={params => (
                < TextField {...params}
                    // error={this.isValidObject("controlType")}
                    id={"id"}
                    placeholder="Select"
                    value=""
                // helperText={this.isValidObject("controlType") ? "Control Type is required" : ""}
                />)}
        />
    }

    ApplyColumTemplateMapping = (value, tableMeta, updateValue) => {
        //  console.log("cp-state-3", this.props.dataViewFilter, this.getFiltersFilterData(value), value);
        return < Autocomplete
            id={"f1"}
            size="small"
            disableClearable={true}
            fullWidth={true}
            options={this.getColumDataSource()}//this.getDataViewFilterDataSource()}
            getOptionLabel={(option) => option.fieldName}
            getOptionSelected={(option, value) => value.fieldName === option.fieldName}
            variant="body1"
            className={this.props.classes.lookupListFCControl1}
            value={this.getMappingColumData(value)}
            onChange={
                (event, newValue) => {
                    this.handleChangeEvent("listFieldMapping", newValue, tableMeta.rowIndex)
                }
            }
            renderInput={params => (
                < TextField {...params}
                    // error={this.isValidObject("controlType")}
                    id={"id"}
                    placeholder="Select"
                    value=""
                // helperText={this.isValidObject("controlType") ? "Control Type is required" : ""}
                />)}
        />
    }
    FilterUsingColumTemplateMapping = (value, tableMeta, updateValue) => {
        
        return < Autocomplete
            id={"f1"}
            size="small"
            disableClearable={true}
            fullWidth={true}
           options={this.state.mappingFormControlList }
            
            getOptionLabel={(option) => option.caption||""}
            getOptionSelected={(option, value) => value.controlName === option.controlName}
            variant="body1"
            className={this.props.classes.lookupListSCControl1}
           
            value={this.getMappingControlData(value)}
            onChange={
                (event, newValue) => {
                    this.handleChangeEvent("controlNameMapping", newValue, tableMeta.rowIndex)
                    this.getCurrectSectionControls(true);
                }
            }
            renderInput={params => (
                < TextField {...params}
                    // error={this.isValidObject("controlType")}
                    id={"id"}
                    placeholder="Select"
                    value=""
                // helperText={this.isValidObject("controlType") ? "Control Type is required" : ""}
                />)}
        />
    }
    
    thirdColumnTemplate = (value, tableMeta, updateValue) => {
        
        return  <Checkbox checked={value}
            onChange={(e) => this.handleChangeEvent("isMandatory", e.target.checked, tableMeta.rowIndex)} />  
    }

    onDeleteRowClick = (index) => {
       
        var dataObj = this.state.tabIndex === 0 ? this.state.filterDataTableData : this.state.mappingDataTableData;
        dataObj.splice(index, 1);
        if (this.state.tabIndex === 0 ) {
            if (dataObj.length ===0) {
                dataObj.push(this.newParameterListObject())
            } 
            this.setState({ ...this.state, filterDataTableData: dataObj }); 
        }
        if (this.state.tabIndex === 1 && dataObj.length > 0) {
            if (dataObj.length===0) {
                dataObj.push(this.newMappingListObject())
            }
            this.setState({ ...this.state, mappingDataTableData: dataObj });
        }
        this.getCurrectSectionControls(true);
         
    }
    
    isValidMapping = () => {
        if (this.props.enableAlert) {
            let mappingDataTable = this.state.mappingDataTableData.filter(obj => obj.controlName !== "" && (obj.listFieldValue !== "" || obj.fieldName !== ""));
            let ParentControlMappingExist = mappingDataTable.filter(obj => obj.controlName === this.props.activeFormControl.formControl.controlName)
            return ParentControlMappingExist.length > 0 ? true : false;;
        } else return true;

    }
    getActiveFormControl = () => {
        if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
            return this.props.formSectionHeader[this.props.activeFormControl.activeControlIndex];
        } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
            return this.props.formSectionDetail[this.props.activeFormControl.activeControlIndex];
        }
    }
    changeForm = () => {
       
        if (this.isValidMapping()) {
        if (this.props.activeFormControl.sectionType === Enum.FormSection.Header) {
            this.props.dispatch(getChangeForm(form => {
                form.formSection.header[this.props.activeControlIndex].formControlListParameter = this.state.filterDataTableData.filter(obj => obj.controlName !== "" && obj.fieldName!=="")
                form.formSection.header[this.props.activeControlIndex].formControlListMapping = this.state.mappingDataTableData.filter(obj => obj.controlName !== "" &&  obj.fieldName !== "")
            }));
        } else if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
            this.props.dispatch(getChangeForm(form => {
                form.formSection.detail.formGridControl[this.props.activeControlIndex].formControlListParameter = this.state.filterDataTableData.filter(obj => obj.controlName !== "" && obj.fieldName!=="")
                form.formSection.detail.formGridControl[this.props.activeControlIndex].formControlListMapping = this.state.mappingDataTableData.filter(obj => obj.controlName !== "" &&  obj.fieldName !== "")
            }));
        }       
            this.props.handleClose();
        } else {
            this.setState({ showAlert:true})
        }
       
    }
    setAlert = () => { 
        if (this.state.showAlert) {
            return <AlertMessage 
                open={this.state.showAlert}
                severity="error" 
                title=""
                //message={`Parent control " ${this.props.activeFormControl.formControl.controlName} " must be mapped`}
                message='A field from the lookup must be mapped to the input the lookup is being added to.'
                icon=""
                close={this.onMessageClose}
            />
        }
        return "";
    }
    onMessageClose = () => {
        this.setState({ showAlert: false })
    }
    handleChangeEvent = (property, valueObject, index) => {
        var dataObj = this.state.tabIndex === 0 ? this.state.filterDataTableData : this.state.mappingDataTableData; 
        if (dataObj.length > 0) {
            if (this.props.activeFormControl.sectionType === Enum.FormSection.Details) {
                dataObj[index].isGrid = true;
            }
          //  if (property !=="isMandatory")
           
        }

        switch (property) {
            case "parentControlId":
                {
                    if (dataObj.length > 0) {
                        var activeControl = this.props.activeFormControl;
                        dataObj[index].parentControlId = activeControl.controlId;
                    }
                   
                    break;
                }
            case "controlName":
                {
                    if (dataObj.length > 0) { 
                        dataObj[index].parentControlId = this.props.activeFormControl.formControl.controlId;
                        dataObj[index].controlName = valueObject.controlName;
                        dataObj[index].isDetailTableField = valueObject.isDetailTableField;
                    }
                   
                    break;
                }
            case "listField":
                {
                    if (dataObj.length > 0) { 
                        dataObj[index].parentControlId = this.props.activeFormControl.formControl.controlId;
                        dataObj[index].listField = valueObject.parameterName;
                    }
                    break;
                }
            case "controlNameMapping":
                {
                    if (dataObj.length > 0) {
                        dataObj[index].parentControlId = this.props.activeFormControl.formControl.controlId;
                        dataObj[index].controlName = valueObject.fieldName;
                    }

                    break;
                }
            case "listFieldMapping":
                {
                    if (dataObj.length > 0) {
                        dataObj[index].parentControlId = this.props.activeFormControl.formControl.controlId;
                        dataObj[index].fieldName = valueObject.fieldName;
                    }
                    break;
                }
            case "isMandatory":
                {
                    if (dataObj.length > 0) {
                        dataObj[index].isMandatory = valueObject;
                    }
                    break;
                } 
            default: {
                break;
            }
        }
        if (this.state.tabIndex === 0 && dataObj.length > 0) {
            if (dataObj.length - 1 === index) {
                dataObj.push(this.newParameterListObject())
            }
            
            this.setState({ ...this.state, filterDataTableData: dataObj});
            
        }
        if (this.state.tabIndex === 1 && dataObj.length > 0) {
            if (dataObj.length - 1 === index) {
                dataObj.push(this.newMappingListObject())
            }
            this.setState({ ...this.state, mappingDataTableData: dataObj});
        }
        
    }

    getFilterTabDataSource = () => {
        let activeformControl = this.getActiveFormControl();
        var filterData = cloneDeep(activeformControl.formControlListParameter);
        filterData.push(this.newParameterListObject()); 
        return filterData;
    }
    getMappingTabDataSource = () => {
        let activeformControl = this.getActiveFormControl();
        var MappingData = cloneDeep(activeformControl.formControlListMapping);
        MappingData.push(this.newMappingListObject());
        return MappingData;
    }
    handleTabChange = (event, newValue) => {
        this.setState({ tabIndex: newValue });
    }

    getParameterTitleTitle = () => {
        return <Typography variant="h6">Lookup List Setup</Typography>
    }
    getFilters = () => {
       
        return (<MUIDataTableStyled className={this.props.classes.MUIDataTable}
            title={''}
            data={this.state.filterDataTableData}
            columns={this.getFilterDataTableColumn()}
            options={this.tableOptions} />)
    }
    getMappings = () => {
        console.log("mappings", this.state.mappingDataTableData);
        return (<MUIDataTableStyled
            title={''} className={this.props.classes.MUIDataTable}
            data={this.state.mappingDataTableData}
            columns={this.getMappingDataTableColumn()}
            options={this.tableOptions} />)
    }

    render() {
       
        return (
            < Dialog classes={{ paper: this.props.classes.dialogPapercontrolParameter }} open={this.props.open} aria-labelledby="customized-dialog-title" disableScrollLock={true} >
                < DialogContent className={this.props.classes.dialogContent} style={{ height: "600px!important" }}>
                    <Grid container >
                        <Grid item xs={12} >
                            <Grid container item direction="row" justifyContent="space-between" >

                                <Grid container item direction="row"  >
                                    <Grid item xs={12}>
                                        <Box pt={1.9} pb={3} style={{height:"80px!important"}}>
                                            <Grid container item direction="row"   >
                                                <Grid item xs={4} >
                                                    {this.getParameterTitleTitle()}
                                                </Grid> 
                                                </Grid >
                                        </Box>
                                    </Grid>
                                   
                                </Grid>
                                <Grid container item >
                                    <Grid item xs={12} >
                                        <Grid container item direction="row" spacing={2.125} justifyContent="space-between">
                                            <Grid item xs={12}  >
                                                <AppBar position="static" className={`${this.props.classes.tabRoot}  ${this.props.classes.lookupSettingsTab}`}  >
                                                    <Tabs value={this.state.tabIndex} onChange={this.handleTabChange} aria-label="tabs"  >
                                                        <Tab className={`${this.props.classes.customSMTab} ${this.props.classes.lookupSettingsTabTabForm}`}   {...a11yProps(0)} label={<Typography variant="subtitle2">FILTERS</Typography>} value={0} />
                                                        <Tab className={`${this.props.classes.customSMTab} ${this.props.classes.lookupSettingsTabTabForm}`} {...a11yProps(1)} label={<Typography variant="subtitle2">MAPPINGS</Typography>} value={1} />
                                                    </Tabs>
                                                </AppBar>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TabPanel value={this.state.tabIndex} index={0} className={this.props.classes.lookupListTabPannel}>  
                                                    {this.getFilters()}
                                                </TabPanel>
                                                <TabPanel value={this.state.tabIndex} index={1} className={this.props.classes.lookupListTabPannel}>
                                                    {this.getMappings()}
                                                </TabPanel>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </ DialogContent >

                < DialogActions >
                    <Grid container item mt={0} pl={2} pr={3}  justifyContent="space-between" spacing={2} >
                        {this.props.enableAlert &&
                            <Grid item xs={8} className={this.props.classes.alertStyle}  >
                                {this.setAlert()}
                            </Grid>
                        }
                        <Grid item xs={3} mr={1} pb={1.5}>
                            <Grid container item direction="row">
                                <Grid item xs={6} >
                            < Button autoFocus color="primary" onClick={() => this.props.handleClose()} >
                                CLOSE
                                    </ Button >
                                </Grid>
                                    <Grid item xs={6} >
                            < Button color="primary" onClick={() => this.changeForm()} style={{ marginRight: "20px" }}>
                                SAVE
                                    </ Button >
                                </Grid>
                    </Grid>
                        </Grid>
                    </Grid>
 
                </ DialogActions >
            </ Dialog > 
        )
    }
}


/**
 * 
 * @param {*} state 
 */
function mapStateToProps(state) { 
    return {

        controlObject: state.forms.activeFormControl.formControl,
        activeFormControl: state.forms.activeFormControl,
        activeControlIndex: state.forms.activeFormControl.activeControlIndex,
        form: state.forms.form,
        formSection: state.forms.form.formSection,
       // dataViewFilter: state.dataViews.dataView.reportFilter,
        //dataViewMapping: state.dataViews.dataView.fieldDisplay,
        detailTableFields: state.forms.detailTableFields.list.length > 0 ? state.forms.detailTableFields.list : state.forms.form.detailTableDataFields,
        primaryTableFields: state.forms.primaryTableFields.list.length > 0 ? state.forms.primaryTableFields.list : state.forms.form.primaryTableDataFields,
        formSectionHeader: state.forms.form.formSection.header,
        formSectionDetail: state.forms.form.formSection.detail!==null? state.forms.form.formSection.detail.formGridControl:[],
        //lookupList: state.dataViews.dataViews,
        //lookupFields: state.dataViews.dataView.fieldDisplay,
        isLoading: state.loader.isLoading,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage
    };
};
export default withRouter(connect(mapStateToProps)(withStyles(formTheme)(ControlParameterList)));

