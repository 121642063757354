
import { customThemeStyles, customColorPalette } from '../common/theme'; 
export const IdleTimerTheme = () => ({
    ...customThemeStyles,
    dialog: {
        height: "200px!important",
        width:"600px!important"
    },
    dialogHeader: {
        paddingTop: "27px!important",
        paddingLeft: "24px!important",
    },
    dialogContent: {
        padding: "0px!important",
        color: `${customColorPalette.black[54]} !important`
    },
    alertContent: {
        paddingLeft: "24px!important",
        marginTop:"18px!important",
    },
    linkButton: {
        marginRight: "38px!important",
      
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
        textDecoration: "underline",
        display: "inline",
        margin: 0,
        padding: 0,
        "&:hover": {
            texDecoration: "none!important",
            backgroundColor: "transparent!important",
        },
        "&:focus": {
            texDecoration: "none!important",
            backgroundColor: "transparent!important",
        }
    },
     
});