import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { customTheme } from 'common/theme';
import store from 'admin/store/configureStore';
import Main from 'admin/components/Main.js';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const history = createBrowserHistory({ basename: baseUrl });

export default class Admin extends React.Component {

    render() {

        return ( 
            <Provider store={store} >
                <ConnectedRouter history={history}>
                    <ThemeProvider theme={customTheme}>
                        <CssBaseline />
                        <Main/> 
                    </ThemeProvider>
                </ConnectedRouter>
            </Provider>
        );

    }

}