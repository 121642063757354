import React from "react";
import { Link } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import ThumbnailImage from 'client/components/ContentLayout/ThumbnailImage'
import * as enumerator from 'client/components/Common/Enum';
import { homeTheme } from 'client/components/Feed/theme/homeTheme';
import { Grid, Box, Typography } from '@mui/material';
import ArticleIcon from '@mui/icons-material/Article';
import EventIcon from '@mui/icons-material/Event';
import CampaignIcon from '@mui/icons-material/Campaign';
class newsAndAnnouncement extends React.Component {


    renderItem() {
        if (this.props.content.type === enumerator.NewsAndAnnouncement.Article) {

            return this.renderArticle();
        }
        else if (this.props.content.type === enumerator.NewsAndAnnouncement.Event) {

            return this.renderEvent();
        }
        else if (this.props.content.type === enumerator.NewsAndAnnouncement.Announcement) {

            return this.renderAnnouncement();
        }
    }

    hasThumbnail() {
        const { classes } = this.props;
        if (this.props.content.thumbnailImageFilepath == null) {
            return "";
        } else {
            return (<Box className={classes.imageContiner }><ThumbnailImage contentId={this.props.content.id} /></Box>);
        }
    }

    renderArticle() {
        const { classes } = this.props;
        return (
            <Box>
                <article className={classes.article}>
                    <Box >
                        <Grid container><Grid item> <ArticleIcon style={{ color: this.props.isSiteTheme ? this.props.themeColor : "" }}  className={classes.feedIcon} /></Grid><Grid item><Typography className={`${classes.h6} ${classes.articleItem}`} ><strong className={classes.articleMeta}>  ARTICLE, {this.props.content.modifiedDate}</strong></Typography> </Grid> </Grid>
                    </Box>
                    <header className={`${classes.articleHead} ${classes.feedHead}`}>
                        <Typography className={classes.h2} ><strong className={classes.feedSubject}> {this.props.content.subject}</strong> </Typography>
                    </header>

                    <Box className={classes.articleBody}> {this.hasThumbnail()}
                        <Typography className={classes.h4} > <strong className={classes.feedContent }>{this.props.content.summary}</strong>
                            {(this.props.isSiteTheme) ? <strong className={`${classes.feedContent} ${this.props.classes.newsMore}`} style={{ color: this.props.themeColor }}>More</strong>  :
                                <Link to={"/Content/" + this.props.content.id + "/" + this.props.content.isPreLogin} className={this.props.classes.newsMore} style={{ color: this.props.themeColor }}>More</Link>}
                        </Typography>
                    </Box>
                </article>
            </Box>
        );
    }
    renderEvent() {
        const { classes } = this.props;
        const date = new Date(this.props.content.eventDate);
        const month = date.toLocaleString('en-us', { month: 'short' });
        const day = date.toLocaleString('en-us', { day: '2-digit' });
        return (
            <Box>
                <article className={classes.article}>
                    <Box>
                        <Grid container><Grid item><EventIcon style={{ color: this.props.isSiteTheme ? this.props.themeColor : "" }} className={classes.feedIcon} /> </Grid><Grid item><Typography className={`${classes.h6} ${classes.articleItem}`} > <strong className={classes.articleMeta}>EVENT, {this.props.content.modifiedDate}</strong></Typography></Grid></Grid>
                    </Box>
                    <header className={`${classes.articleHead} ${classes.feedHead}`}>
                        <Typography className={classes.h2} ><strong className={classes.feedSubject}> {this.props.content.subject}</strong> </Typography>
                    </header>
                    <Box className={classes.articleBody}>
                        <Box className={classes.articleDate}><Box className={this.props.classes.newsCalendar} style={{ background: this.props.themeColor }}> {month} </Box><Box className={classes.articleDateDay}>
                            {day}
                        </Box>
                        </Box>
                        <Typography className={classes.h4} > <strong className={classes.feedContent}>{this.props.content.summary}</strong>
                            {(this.props.isSiteTheme) ? <strong className={`${classes.feedContent} ${this.props.classes.newsMore}`} style={{ color: this.props.themeColor }}>More</strong> :
                                <Link to={"/Content/" + this.props.content.id + "/" + this.props.content.isPreLogin} className={this.props.classes.newsMore} style={{ color: this.props.themeColor }}>More</Link>}
                        </Typography>
                    </Box>
                </article>
            </Box>
        );
    }
    renderAnnouncement() {
        const { classes } = this.props;
        return (
            <Box>
                <article className={classes.article}>
                    <Box >
                        <Grid container><Grid item><CampaignIcon style={{ color: this.props.isSiteTheme ? this.props.themeColor : "" }} className={classes.feedIcon} /> </Grid><Grid item><Typography className={`${classes.h6} ${classes.articleItem}`} ><strong className={classes.articleMeta}> ANNOUNCEMENT, {this.props.content.modifiedDate}</strong></Typography></Grid></Grid>


                    </Box>
                    <header className={`${classes.articleHead} ${classes.feedHead}`}>
                        <Typography className={classes.h2} ><strong className={classes.feedSubject}> {this.props.content.subject}</strong> </Typography>
                    </header>

                    <Box className={classes.articleBody}>
                        <Grid container>
                            <Grid item>
                        {this.hasThumbnail()}
                            </Grid>
                       
                           <Grid item>
                                <Typography className={classes.h4} ><strong className={classes.feedContent}>{this.props.content.summary}</strong> </Typography>
                            </Grid><Grid item>
                                <Typography className={classes.h4} >  {(this.props.isSiteTheme) ? <strong className={`${classes.feedContent} ${this.props.classes.newsMore}`} style={{ color: this.props.themeColor }}>More</strong>  :
                                    <Link to={"/Content/" + this.props.content.id + "/" + this.props.content.isPreLogin} className={this.props.classes.newsMore} style={{ color: this.props.themeColor }}>More</Link>} </Typography>

                            </Grid></Grid>

                           
                           
                       
                    </Box>
                </article>
            </Box>
        );
    }


    render() {

        return (
            <Box>
                {this.renderItem()}
            </Box>
        );
    }
}



export default withStyles(homeTheme)(newsAndAnnouncement);