import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getDataBaseSourceList, getDataSourceList, getDataFieldsList, getFilter, getChangeFilter, getNewFilter, validateOnBlur, getError } from 'admin/actions/filterActions';
import CircularProgress from '@mui/material/CircularProgress';
import { Grid, CardContent, Typography, Box } from '@mui/material'
import { spacing } from '@mui/system';
import MuiTextField from '@mui/material/TextField';
import { styled } from '@mui/styles';
import Autocomplete from '@mui/lab/Autocomplete';
import MuiCard from '@mui/material/Card';
import MenuItem from '@mui/material/MenuItem';
import { ActionTypes as actionTypes } from 'admin/actions/actionTypes';
import { showConfirmWarning, hideError } from 'admin/actions/alertActions';
import { sortName } from 'client/components/Common/Utility';
const compare = (a, b) => a.localeCompare(b);

const database = [{ databaseId: -1, databaseConnectionName: "" }].sort(compare);
const datasource = [].sort(compare);
const fields = [].sort(compare);
const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);


class FilterEdit extends React.Component {

    constructor(props) {

        super(props);
        let profileId = this.props.match.params.profileId;
        let id = null;
        let isUpdate = profileId !== "new";
        if (isUpdate) {
            id = parseInt(profileId);
            if (isNaN(id)) {
                // TODO: error on page?
                id = null;
            }
        }
        this.state = {
            filterId: isUpdate ? id : null,
            isUpdate: isUpdate
        };


        this.dispatchGetFilterDetails = this.dispatchGetFilterDetails.bind(this);
        this.dispatchGetDataBaseList = this.dispatchGetDataBaseList.bind(this);
        this.dispatchGetDataSourceList = this.dispatchGetDataSourceList.bind(this);
        this.dispatchGetDataFieldsList = this.dispatchGetFieldsList.bind(this);

    }

    componentDidMount() {

        let filterId = this.props.match.params.profileId;
        let isUpdate = filterId !== "new";
        filterId = isUpdate ? filterId : null;
        this.loadallDataSources(filterId);
    }
    loadallDataSources(filterId) {

        this.dispatchGetFilterDetails(filterId);
    }
    componentDidUpdate(prevProps) {
        if (this.props.isUpdateSuccess !== prevProps.isUpdateSuccess) {
            if (this.props.databases !== prevProps.databases) {
                this.dispatchGetDataBaseList();
                if (this.props.match.params.profileId > 0) {
                    this.loadallDataSources(this.props.match.params.profileId)
                }
            }

            if (this.props.isUpdateSuccess && this.props.filter.profileId > 0) {
                this.props.history.push(`/Admin/Filter/${this.props.filter.profileId}`);
            }
        }
    }
    dispatchGetFilterDetails(filterId) {

        this.dispatchGetDataBaseList();
        if (filterId) {

            this.props.dispatch(getFilter(filterId)).then(resp => {
                this.props.dispatch(getDataSourceList(this.props.filter.company.databaseId)).then(resp => {

                    this.props.dispatch(getDataFieldsList(this.props.filter.company.databaseId, this.props.filter.sourceTableName));
                });
            });

        } else {

            this.props.dispatch(getNewFilter());
        }
    }
    dispatchGetDataBaseList() {

        this.props.dispatch(getDataBaseSourceList());

    }
    dispatchGetDataSourceList(selectedValue) {
        if (selectedValue != null) {
            this.props.dispatch(getDataSourceList(selectedValue.databaseId));
        }


    }
    dispatchGetFieldsList(selectedValue) {
        if (selectedValue != null) {
            var tablename = selectedValue.table_Name === undefined ? selectedValue : selectedValue.table_Name;
            this.props.dispatch(getDataFieldsList(this.props.filter.company.databaseId, tablename));
        }

    }
    changeFilter = (change) => {
        //this.props.dispatch(showWarning(this.props.dispatch, "Filter is assigned", 0))
        this.props.dispatch(getChangeFilter(change));
    }
    getSelectedFieldOption = (options, selectedField) => {

        if (selectedField != undefined) {
            var selOption = options.find(f => f.columN_NAME === selectedField)

            return selOption;

        }
    }
    blurValidation = (fieldName, value) => {

        this.props.dispatch(validateOnBlur(fieldName, value, fieldName));
    }

    confirmDatasourceYes = (newValue) => {

        hideError(this.props.dispatch);


        if (newValue !== null) {
            this.changeFilter(filter => filter.sourceTableName = newValue.table_Name)
            this.changeFilter(filter => filter.filterDetails.fieldName = "")
            this.dispatchGetFieldsList(newValue);
            this.blurValidation('sourceTableName', newValue.table_Name);
        } else {
            this.changeFilter(filter => filter.sourceTableName = "")
            this.changeFilter(filter => filter.filterDetails.fieldName = "")
            this.blurValidation('sourceTableName', "");
        }

    }
    confirmDatasourceNo = () => {

        hideError(this.props.dispatch);

    }

    confirmConnectionYes = (newValue) => {
        hideError(this.props.dispatch);
        this.changeFilter(filter => filter.company = newValue)
        this.changeFilter(filter => filter.sourceTableName = "")
        this.changeFilter(filter => filter.filterDetails.fieldName = "")
        this.dispatchGetDataSourceList(newValue);
        this.blurValidation('company', newValue);


    }

    confirmFieldYes = (newValue) => {
        hideError(this.props.dispatch);
        if (newValue !== null) {
            this.changeFilter(filter => filter.filterDetails.fieldName = newValue.columN_NAME)
            this.blurValidation('filterDetails', { fieldName: newValue.columN_NAME });
        } else {
            this.changeFilter(filter => filter.filterDetails.fieldName = "")
            this.blurValidation('filterDetails', { fieldName: "" });
        }
    }

    render() {

        let datasources = [...this.props.datasources].sort((a, b) => sortName(a.table_Name, b.table_Name));
        let fields = [...this.props.fields].sort((a, b) => sortName(a.columN_NAME, b.columN_NAME));
        let alertmsg = "This filter is assigned to a user or other content";
        return (
            <Grid container direction="row" justifyContent="space-between">
                <Grid item xs={12} >
                    <Card variant="outlined" >
                        <Box mr={4} mb={8} mt={2.5} ml={3}   >

                            <Grid item xs={12}>
                                <Grid item xs={2}>

                                    <Typography variant="subtitle1"> Information</Typography>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={5} >

                                <Grid item xs={2}>
                                    <Grid container item justifyContent="space-between" spacing={4}>

                                        <Grid item xs={12}>
                                            <TextField helperText=" " autoFocus
                                                required

                                                label="Name"
                                                value={this.props.filter.profileName}
                                                size="small"
                                                fullWidth={true}
                                                onChange={(event) => {
                                                    this.changeFilter(filter => filter.profileName = event.target.value)
                                                    this.blurValidation('profileName', event.target.value);
                                                }}
                                                inputProps={{ maxLength: 50, tabIndex: 1 }}
                                                onBlur={(event) => {
                                                    this.blurValidation('profileName', event.target.value);

                                                }}
                                                error={!(!this.props.errors.profileName)} helperText={this.props.errors.profileName ? this.props.errors.profileName : ' '}
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Autocomplete
                                                id="size-small-outlined"
                                                size="small"
                                                fullWidth={true}
                                                options={this.props.databases ? this.props.databases:[]}
                                                getOptionLabel={(option) => option.databaseConnectionName ? option.databaseConnectionName : ""}
                                                value={{ databaseConnectionName: this.props.filter.company ? this.props.filter.company.databaseConnectionName:"" }}
                                                onChange={(event, newValue) => {
                                                    if (this.props.filter.isAssigned) {
                                                        let action = { primaryAction: this.confirmConnectionYes, SecondaryAction: this.confirmDatasourceNo, value: newValue }
                                                        showConfirmWarning(this.props.dispatch, alertmsg, action);

                                                    } else {


                                                        this.changeFilter(filter => filter.company = newValue)
                                                        this.changeFilter(filter => filter.sourceTableName = "")
                                                        this.changeFilter(filter => filter.filterDetails.fieldName = "")
                                                        this.dispatchGetDataSourceList(newValue);
                                                        this.blurValidation('company', newValue);
                                                    }
                                                }}
                                                onBlur={(event) => {
                                                    this.blurValidation('company', this.props.filter.company);

                                                }}
                                                loading={this.props.actionType === actionTypes.VGO_GET_FILTERS_DATABASE_SOURCE_REQUEST ? true : false}
                                                renderInput={params => (
                                                    <TextField {...params}
                                                        helperText=" "
                                                        required
                                                        size="small"
                                                        label="Connection"
                                                        error={!(!this.props.errors.company)} helperText={this.props.errors.company ? this.props.errors.company : ' '}
                                                        loading={this.props.actionType === actionTypes.VGO_GET_FILTERS_DATABASE_SOURCE_REQUEST ? true : false}
                                                        placeholder="Select an option"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            tabIndex: 3,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {this.props.actionType === actionTypes.VGO_GET_FILTERS_DATABASE_SOURCE_REQUEST ? <CircularProgress color="inherit" size={20} /> : null}                                                           {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            )
                                                        }}></TextField>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>

                                            <Autocomplete
                                                id="size-small-outlined"
                                                loading={true}
                                                size="small"
                                                fullWidth={true}
                                                options={datasources ? datasources:[]}
                                                getOptionLabel={(option) => { return option.table_Name }}
                                                value={{ table_Name: this.props.filter.sourceTableName }}
                                                onChange={(event, newValue) => {
                                                    if (this.props.filter.isAssigned) {

                                                        let action = { primaryAction: this.confirmDatasourceYes, SecondaryAction: this.confirmDatasourceNo, value: newValue }
                                                        showConfirmWarning(this.props.dispatch, alertmsg, action);

                                                    } else {
                                                        if (newValue !== null) {
                                                            this.changeFilter(filter => filter.sourceTableName = newValue.table_Name)
                                                            this.changeFilter(filter => filter.filterDetails.fieldName = "")
                                                            this.dispatchGetFieldsList(newValue);
                                                            this.blurValidation('sourceTableName', newValue.table_Name);
                                                        } else {
                                                            this.changeFilter(filter => filter.sourceTableName = "")
                                                            this.changeFilter(filter => filter.filterDetails.fieldName = "")
                                                            this.blurValidation('sourceTableName', "");
                                                        }
                                                    }

                                                }}
                                                onBlur={(event) => {
                                                    this.blurValidation('sourceTableName', this.props.filter.sourceTableName);

                                                }}
                                                loading={this.props.actionType === actionTypes.VGO_GET_FILTERS_DATASOURCE__REQUEST ? true : false}
                                                renderInput={params => (
                                                    <TextField {...params}
                                                        helperText=" "
                                                        required
                                                        size="small"
                                                        label="DataSource"
                                                        loading={this.props.actionType === actionTypes.VGO_GET_FILTERS_DATASOURCE_REQUEST ? true : false}
                                                        error={!(!this.props.errors.sourceTableName)} helperText={this.props.errors.sourceTableName ? this.props.errors.sourceTableName : ' '}
                                                        placeholder="Select an option"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            tabIndex: 4,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {this.props.actionType === actionTypes.VGO_GET_FILTERS_DATASOURCE_REQUEST ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            )
                                                        }} />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>

                                            <Autocomplete
                                                id="size-small-outlined"
                                                size="small"
                                                tabIndex="5"
                                                fullWidth={true}
                                                options={fields ? fields:[]}
                                                getOptionLabel={(option) => option.columN_NAME}
                                                //value={this.getSelectedFieldOption(this.props.fields,this.props.filter.filterDetails?.fieldName)}
                                                value={{ columN_NAME: this.props.filter.filterDetails?.fieldName }}
                                                onChange={(event, newValue) => {
                                                    if (this.props.filter.isAssigned) {

                                                        let action = { primaryAction: this.confirmFieldYes, SecondaryAction: this.confirmDatasourceNo, value: newValue }
                                                        showConfirmWarning(this.props.dispatch, alertmsg, action);

                                                    }
                                                    else {

                                                        if (newValue !== null) {
                                                            this.changeFilter(filter => filter.filterDetails.fieldName = newValue.columN_NAME)
                                                            this.blurValidation('filterDetails', { fieldName: newValue.columN_NAME });
                                                        } else {
                                                            this.changeFilter(filter => filter.filterDetails.fieldName = "")
                                                            this.blurValidation('filterDetails', { fieldName: "" });
                                                        }
                                                    }

                                                }}
                                                onBlur={(event) => {
                                                    this.blurValidation('filterDetails', this.props.filter.filterDetails);

                                                }}
                                                loading={this.props.actionType === actionTypes.VGO_GET_FILTERS_FIELDS_SOURCE_REQUEST ? true : false}
                                                renderInput={params => (
                                                    <TextField {...params}
                                                        helperText=" "
                                                        required
                                                        size="small"
                                                        label="Field"
                                                        loading={this.props.actionType === actionTypes.VGO_GET_FILTERS_FIELDS_SOURCE_REQUEST ? true : false}
                                                        error={!(!this.props.errors.filterDetails)} helperText={this.props.errors.filterDetails ? this.props.errors.filterDetails : ' '}
                                                        placeholder="Select an option"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            tabIndex: 5,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {this.props.actionType === actionTypes.VGO_GET_FILTERS_FIELDS_SOURCE_REQUEST ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            )
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid item spacing={10}>
                                        <TextField

                                            label="Description"
                                            value={this.props.filter.remarks}
                                            size="small"
                                            fullWidth={true}
                                            onChange={event => this.changeFilter(filter => filter.remarks = event.target.value)}
                                            inputProps={{ tabIndex: 2, maxLength: 500 }}

                                        >
                                        </TextField>
                                    </Grid>
                                </Grid>


                            </Grid>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        );
    }
}
/**
 * 
 * @param {*} state 
 */
function mapStateToProps(state) {


    return {
        fields: state.filters.fields,
        datasources: state.filters.datasources,
        databases: state.filters.databases,
        filter: state.filters.filter,
        isLoading: state.loader.isLoading,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage,
        isUpdateSuccess: state.filters.isUpdate,
        actionType: state.loader.type,
        errors: state.filters.errors ? state.filters.errors : {},
    };
};
export default withRouter(connect(mapStateToProps)(FilterEdit));