import { customThemeStyles, customColorPalette } from 'common/theme';
import createBreakpoints from "@mui/system/createTheme/createBreakpoints";
 
const breakpoints = createBreakpoints({});
export const scheduleTheme = () => ({
    ...customThemeStyles,
    schedulePopUp: {
        [breakpoints.down('md')]: { 
           // paddingLeft: "46px!important",
            //backgroundColor: "white!important",
        },
    }
});