import React from 'react';
import { connect } from 'react-redux';
import Banners from 'client/components/Banner/Banners';
import NewsAndAnnouncements from 'client/components/Feed/NewsAndAnnouncements';
import SideBar from 'client/components/Sidebar/Sidebar';
import { setTabTitle } from 'client/components/Common/Utility';
import GridLayout from '@mui/material/Grid';
import { homeTheme } from 'client/components/Home/theme/homeTheme'
import { withStyles } from '@mui/styles';
import { Box, Grid } from '@mui/material';
class home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMore: false,
            showSideBar:true,
        }
    }
   
    handleScroll = e => {
        let element = e.target;
        if (Math.round(element.scrollHeight - element.scrollTop) === element.clientHeight) {
            this.setState({ isMore: true });
        } else
            this.setState({ isMore: false });
    }
    hideSideBar = () => {
        this.setState({ showSideBar: false });
    }
    render() {

        const { classes}=this.props
        setTabTitle("Home");
        return (
            <Box id="divmain" onScroll={this.handleScroll}>
                <Box id="divMiddle1" className={classes.wrapperMiddle}>
                    <Grid container spacing={0}>
                        <Grid item xs>
                            <Box className={this.state.showSideBar ? classes.content : classes.contentNoSideBar}>
                                <Box id="divArticleNew" style={{ backgroundColor: 'transparent' }} >
                                    <Box id="contentNotesNew1" pl={this.props.siteTheme ? 6 : 0} className={classes.containerBanner} >

                                        <GridLayout container>
                                            <GridLayout item xs={12}>
                                                <GridLayout container>
                                                    <GridLayout item xs={12} >
                                                        <Banners siteTheme={this.props.siteTheme} />
                                                    </GridLayout>
                                                    <GridLayout item xs={12} >
                                                        <NewsAndAnnouncements isMore={this.state.isMore} siteTheme={this.props.siteTheme} />
                                                    </GridLayout>
                                                </GridLayout>

                                            </GridLayout>
                                            <GridLayout item>

                                            </GridLayout>

                                        </GridLayout>

                                    </Box>
                                </Box>

                            </Box>
                        </Grid>
                        
                        <Grid item className={classes.wrapperLeft }>
                            
                               
                                    {this.state.showSideBar && <SideBar siteTheme={this.props.siteTheme} hideSideBar={this.hideSideBar} />}
                                
                        </Grid>
                    </Grid>
                    
                </Box>
            </Box>
        );
    }
}


export default connect(state => state.Login)(withStyles(homeTheme)(home));

