import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import MUIDataTable from 'mui-datatables';
import { withStyles } from '@mui/styles';
import { Box } from '@mui/material'
import { produce } from 'immer';
import { dashboardTheme } from './theme/dashboardTheme';
import { getDashboards, deleteDashboard, newDashboard,candeleteDashBoard} from 'admin/actions/dashboardActions';
import DeleteButton from 'common/DeleteButton.js';
import { objectListFormatDate } from 'admin/components/Common//DateFormat';

import ActionBackdrop from 'admin/components/section/settings/Backdrop';
import { MuiGridOptions } from 'admin/components/Common/CommonOptions';

const defaultColumnOptions = {
    filter: true,
    sort: true,

};

class DashboardList extends React.Component {
    constructor(props) {
        const { classes } = props;
        super(props);
        this.state = {
            columns: [
                {
                    name: "dashboardId",
                    options: {
                        ...defaultColumnOptions,
                        display: 'false'
                    }
                },
                {
                    name: "categoryName",
                    label: "Category",
                    options: {
                        ...defaultColumnOptions,
                        setCellProps: () => ({ className: classes.categoryColumn }),
                    }

                },
                {
                    name: "name",
                    label: "Name",
                    options: {
                        ...defaultColumnOptions,
                        setCellProps: () => ({ className: classes.nameColumn }),
                    }

                },
                {
                    name: "description",
                    label: "Description",
                    options: {
                        ...defaultColumnOptions,
                        setCellProps: () => ({ className: classes.gridColumn }),
                    }
                },
                
                {
                    name: "createdDateTime",
                    label: "Created",
                    options: {
                        ...defaultColumnOptions,
                        customBodyRender: objectListFormatDate,
                        setCellProps: () => ({ className: classes.dateColumn }),
                    }


                },
                {
                    name: "updatedDateTime",
                    label: "Modified",
                    options: {
                        ...defaultColumnOptions,
                        customBodyRender: objectListFormatDate,
                        setCellProps: () => ({ className: classes.dateColumn }),
                    }
                },
                {
                    name: "",
                    label: "",
                    options: {
                        ...defaultColumnOptions,
                        customBodyRender: this.deleteRowTemplate,
                        setCellProps: () => ({ className: classes.deleteColumn }),
                        filter: false,
                    }
                }
            ],
            options: {
                download: false,
                elevation: 0,
                responsive: 'scrollMaxHeight',
                pagination: false,
                filter: true,
                filterType: 'multiselect',
                jumpToPage: true,
                onCellClick: (colData, cellMeta) => {
                    let dataIndex = cellMeta.dataIndex;
                    let dashboard = this.props.dashboards[dataIndex];
                    let columnIndex = this.state.columns.findIndex(c => c.name === "");
                    if (columnIndex !== cellMeta.colIndex)
                        this.getDashboardData(dashboard.dashboardId)
                },
                print: false,
                searchOpen: true,
                selectableRows: 'none',
                sortOrder: {
                    name: 'categoryName',
                    direction: 'asc'
                },
                onFilterChange: (changedColumn, filterList, type, changedColumnIndex, displayData) => {
                    this.setState(produce(draft => {
                        draft.columns[changedColumnIndex].options.filterList = filterList[changedColumnIndex];
                    }));
                },
                onColumnSortChange: (changedColumn, direction) => {
                    this.setState(produce(draft => {
                        draft.options.sortOrder = { name: changedColumn, direction: direction };
                    }));
                }
            }
        };
    }

    componentDidMount() {
        this.dispatchGetDashboards();
        this.dispatchClearDashboard();
    }

    dispatchGetDashboards() {
        this.props.dispatch(getDashboards());
    }
    dispatchClearDashboard() {
        this.props.dispatch(newDashboard());
    }

    getDashboardData(dashboardId) {
        this.props.history.push(`/Admin/Dashboards/${dashboardId}`);
    }

    deleteRowTemplate = (value, tableMeta, updateValue) => {
        const columnIndex = 0;
        return <DeleteButton preValidationServer={candeleteDashBoard} onClick={this.dispatchDeleteDashboard} id={tableMeta.rowData[columnIndex]} />
    }
    dispatchDeleteDashboard = (id) => {
        const dashboard = this.props.dashboards.find(u => u.dashboardId === id);
        this.props.dispatch(deleteDashboard(dashboard.dashboardId));
    }
    deletePreValidation = () => {
        return { canDelete: true, message: "" }
    }
    render() {
        const { classes } = this.props;
        return (
            <Box border={1} className={classes.commonObjectListBox}>
                {this.props.isLoading && <ActionBackdrop />}
                <MUIDataTable container 
                    title={''}
                    data={this.props.dashboards}
                    columns={this.state.columns}
                    options={MuiGridOptions(this.state.options, this.props.isDataLoading)}
                />
            </Box>
        );
    }
}

function mapStateToProps(state) {
    return {
        dashboards: state.dashboards.dashboards,
        isLoading: state.loader.isLoading,
        hasError: state.error.hasError,
        errorMessage: state.error.errorMessage,
        isDataLoading: state.dashboards.isLoading,
    };
};
export default withRouter(connect(mapStateToProps)(withStyles(dashboardTheme)(DashboardList)));