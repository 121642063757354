import { customThemeStyles } from 'common/theme.js';
import grey from '@mui/material/colors/grey';
export const defaultRoleColor = "#FFFFFF!important";
export const defaultRoleBGColor = "#43A047!important";

export const filtersTheme = () => ({
    ...customThemeStyles,
    filterListBox: {
        borderColor: grey[300] + "!important",
        backgroundColor: "white!important",
    },
    descriptionColumn: { width: "900px!important", wordBreak: "break-word!important" },
});
