import { produce } from 'immer';
import { ActionTypes as types } from 'admin/actions/actionTypes';
const initialState = {
    siteTheme: {
        theme: '',
        companyLogo: '',
        companyLogoFileName: '',
    },
}

const siteThemes = produce((draft, action) => {
    switch (action.type) {
        case types.VGO_GET_SITE_THEME_SUCCESS:
            draft.siteTheme = action.siteTheme;
            break;

        case types.VGO_UPDATE_SITE_THEME_SUCCESS:
            draft.siteTheme = action.siteTheme;
            break;

        case types.VGO_CHANGE_SITE_THEME:
            action.change(draft.siteTheme);
            break;
    }
}, initialState);

export default siteThemes;