import React, { useRef, useState, useEffect } from 'react';
import { TextField, IconButton } from '@mui/material';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import ActionBackdrop from 'admin/components/section/settings/Backdrop';
import { crystalReportSourceStyle } from 'admin/components/section/dataViews/common/theme/theme';
import { customColorPalette } from 'common/theme';

export default  function CrystalReportSource(props) {
    
    const [isLoading, setLoading] = useState(false);
    
    const id = props.id;
    const label = " ";
    const value = props.value;
    const inputRef = useRef(null);
   // const showError = !value ? props.crystalReportNameError : false;

    useEffect(() => {
        if (inputRef  && !value && props.showFileSelection) {
            inputRef.current.click();
        }
    });

    const handleChange = async (event) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            setLoading(true);
           
            upload(files[0]);
        }
    };

    const upload = async (file) => {
        props.dispatchError(false);
        const fileName = (props.isDataViewLink) ? `link-${props.dataViewLinkRowIndex}_crReport` : "crReport";
        const formData = new FormData();
        formData.append('fileName', fileName);
        formData.append('file', file);

        const response = await fetch('/adminApi/DataViews/CrystalReportFilters', {
            method: 'POST',
            body: formData,
        });

        if (response.status === 200) {
            const filters = await response.json();
            props.setFilter(filters, file.name, props.actionLinkRowIndex);
            setLoading(false);
        }
        else {
            setLoading(false);
            props.dispatchError(true, "Unable to read the crystal report. Check the file type.");
            if (inputRef.current) {
                inputRef.current.value = '';
            }
        }
                
    };

    return (
        <div>
            {isLoading && <ActionBackdrop />}
            <TextField
                id={id}
                name={id}
                label={label }
                variant="standard"
                size="small"
                style={(props.isDataViewLink) ? crystalReportSourceStyle.fileName : null}
                //helperText={ ' '}
                value={value}
                InputProps={{
                    readOnly: true,
                    //disableUnderline: true,
                    endAdornment: (
                        <>
                            
                            <IconButton disabled={props.disabled ? props.disabled:false }
                                    aria-label="upload"
                                    style={crystalReportSourceStyle.fileUploadIcon}
                                    component="label" 
                                >
                                
                                <DriveFolderUploadIcon fontSize="medium" style={{color: customColorPalette.black[54] }} />
                                    <input
                                    hidden
                                    accept=".rpt"
                                    ref={inputRef}
                                        type="file"
                                        onChange={ handleChange}
                                    />
                                </IconButton>
                            
                        </>
                    ),
                }}
                />
        </div>
    );
}