import React from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
const types = {
    VGO_ALERT_SHOW: 'SHOW_ALERT',
    VGO_ALERT_HIDE: 'HIDE_ALERT',
    VGO_ALERT_ACTION_SHOW: 'SHOW_ALERT_ACTION',
}
/**
 * Show Alert action creator
 * @param {*} open
 * 
 */
export const getAlertShow = (open, severity, title, message, icon, timerId ) => {
    return {
        type: types.VGO_ALERT_SHOW,
        alert: {
            open,
            severity,
            title,
            message,
            icon,
            timerId,
        },
    };
};

const getAlertHide = (timerId) => {
    return {
        type: types.VGO_ALERT_HIDE,
        alert: {
            open: false,
            severity: '',
            title: '',
            message: '',
            icon: null,
            timerId,
        },
    };
};
const getAlertShowWithAction = (open, severity, title, message, icon, action) => {
    return {
        type: types.VGO_ALERT_ACTION_SHOW,
        alertAction: {
            open,
            severity,
            title,
            message,
            icon,
            action: action,
        },
    };
};

export const showAlert = ( dispatch, severity, title, message, icon, timeOut ) => {
    let timerId;
    if (timeOut) {
        timerId = setTimeout(() => dispatch(getAlertHide(timerId) ), timeOut );
    }
    dispatch(getAlertShow(true, severity, title, message, icon, timerId));
}

export const showErrorAlert = (dispatch, severity, title, message, icon) => {
    dispatch(getAlertShow(true, severity, title, message, icon));
    }

export const showSuccess = ( dispatch, title, message, timeOut ) => {
    showAlert( dispatch, 'success', title, message, <CheckCircleIcon />, timeOut );
}

export const showInfo = ( dispatch, title, message, timeOut ) => {
    showAlert( dispatch, 'info', title, message, <InfoIcon />, timeOut );
}

export const showWarning = ( dispatch, message, timeOut ) => {
    showAlert( dispatch, 'warning', 'Warning', message, <WarningIcon />, timeOut );
}

export const showError = ( dispatch, message, timeOut ) => {
    showErrorAlert( dispatch, 'error', 'Error', message, <ErrorIcon /> );
}

export const showWarningWithAction = (dispatch, message, action) => {
    showAlertAction(dispatch, 'warning', 'Warning', message, <WarningIcon />, action);
}
export const showErrorWithAction = (dispatch, message, action) => {
    showAlertAction(dispatch, 'error', 'Error', message, <ErrorIcon />, action);
}
const showAlertAction = (dispatch, severity, title, message, icon, action) => {
    dispatch(getAlertShowWithAction(true, severity, title, message, icon,action));
    
}
