import * as yup from "yup";
export const dashboardSetupSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    title: yup.string().required("Title is required"),
    category: yup.object()
        .test('isCategoryEmpty',
            'Category is required',
            function (value) {
                return value.categoryName ? true : false;
            })
        .required("Category is required"),
    roles: yup.array().min(1, 'Roles is required'),
    
    




})
