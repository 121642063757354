import React from "react";
import { useState } from 'react';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
const filter = createFilterOptions();
export default function DropDownList(props) {
   
    let value = (props.dataModel[props.settings.fieldName]) ? props.dataModel[props.settings.fieldName] : "";
    if (typeof value === 'string' && value) {
        value = value.trimEnd();
    }
    const reportId = props.settings.reportId;
    let defValue = {};
    defValue[props.settings.valueField] = value;
    const [fieldValue, setFieldValue] = useState(defValue);
    
    const [data, setData] = useState([]);

    const getDependencyList = () => {
        let list = [];
        for (let filterDefinition of props.settings.listParameterFields) {
            list.push(props.dataModel[filterDefinition.controlName])
        }

        return list;
    }
    const dependencyFieldList = [props.settings.reportId, ...getDependencyList()];

    const getMappedFieldList = () => {
        let list = [];
        for (let outputField of props.settings.listOutputFields) {
            if (outputField.controlName !== props.settings.fieldName)
                list.push(props.dataModel[outputField.controlName])
        }

        return list;
    }
    const mappedFieldList = [props.settings.reportId, ...getMappedFieldList()];

    React.useEffect(() => {
            getReportData();
    }, [...dependencyFieldList]);

    React.useEffect(() => {
        if (mappedFieldList.filter(item => item === "").length !== 0 && value === props.settings.defaultValue && data.length > 0) {
            const dataIndex = data.findIndex(option => option[props.settings.valueField] == value);
            if (dataIndex >= 0) {
                const selectedValue = data[dataIndex]
                if (!props.isGrid)
                    props.onChange(props.settings.isMandatory, props.settings.fieldName, selectedValue[props.settings.valueField], props.settings.listOutputFields, selectedValue);
                else {
                    if (value !== "") {
                        props.onChange(props.settings.isMandatory, props.settings.fieldName, selectedValue[props.settings.valueField], "", "", "", props.settings.listOutputFields, selectedValue);
                    }
                }
            }
        }
    }, [...mappedFieldList]);

    React.useEffect(() => {

        if (fieldValue && value !== fieldValue[props.settings.valueField]) {
            const dataIndex = data.findIndex(option => option[props.settings.valueField] == value);
            if (dataIndex >= 0) {
                const selectedValue = data[dataIndex]
                setFieldValue(selectedValue)
                if (props.settings.listOutputFields.length > 0) {
                    if (!props.isGrid)
                        props.onChange(props.settings.isMandatory, props.settings.fieldName, selectedValue[props.settings.valueField], props.settings.listOutputFields, selectedValue);
                    else {
                        if (value!=="") {
                            props.onChange(props.settings.isMandatory, props.settings.fieldName, selectedValue[props.settings.valueField], "", "", "", props.settings.listOutputFields, selectedValue);
                        }
                    }
                }
                if (props.isGrid && props.settings.reportId) {
                    setDropDownList(props.settings, selectedValue)
                }
            }
            else {
                if (props.isGrid && data.length > 0) {
                    defValue[props.settings.valueField] = "";
                    setFieldValue(defValue)
                    props.onChange(props.settings.isMandatory, props.settings.fieldName, "", "", "", "", props.settings.listOutputFields);
                } else {
                    defValue[props.settings.valueField] = "";
                    setFieldValue(defValue)
                }
            }
        }

    }, [value]);
    React.useEffect(() => {

        if (props.settings.isAllowNewValue) {
            const dataIndex = props.newStaticValueList.findIndex(field => field.fieldName === props.settings.fieldName && !field.isGrid);
            if (dataIndex >= 0) {
                let newValue = {};
                newValue[props.settings.valueField] = props.newStaticValueList[dataIndex].value;
                newValue[props.settings.displayField] = props.newStaticValueList[dataIndex].value;
                const newData = [...data, newValue];
                setData(newData);
            }
        }

    }, [props.newStaticValueList]);

    React.useEffect(() => {

        if (props.settings.listOutputFields.length > 0 && value) {
            const dataIndex = data.findIndex(option => option[props.settings.valueField] == value);
            if (dataIndex >= 0) {
                const selectedValue = data[dataIndex]
                if (!props.isGrid) {
                    //defValue[props.settings.valueField] = value;
                    setFieldValue(selectedValue)
                    props.onChange(props.settings.isMandatory, props.settings.fieldName, selectedValue[props.settings.valueField], props.settings.listOutputFields, selectedValue);
                }
                else {
                    if (props.settings.reportId) {
                        setDropDownList(props.settings, selectedValue)
                    }
                    props.onChange(props.settings.isMandatory, props.settings.fieldName, selectedValue[props.settings.valueField], "", "", "", props.settings.listOutputFields, selectedValue);
                }
            }
        }
        else if (data.length > 0 && value && fieldValue && !fieldValue[props.settings.valueField]) {
            const dataIndex = data.findIndex(option => option[props.settings.valueField] == value);
            if (dataIndex >= 0) {
                const selectedValue = data[dataIndex]
                if (!props.isGrid) {
                   // defValue[props.settings.valueField] = value;
                    setFieldValue(selectedValue)
                    props.onChange(props.settings.isMandatory, props.settings.fieldName, selectedValue[props.settings.valueField], props.settings.listOutputFields, selectedValue);
                }
                else {
                    if (props.settings.reportId) {
                        setDropDownList(props.settings, selectedValue)
                    }
                    props.onChange(props.settings.isMandatory, props.settings.fieldName, selectedValue[props.settings.valueField], "", "", "", props.settings.listOutputFields, selectedValue);
                }
            }
        }
       

    }, [data]);

    React.useEffect(() => {
        if (props.settings.isAllowNewValue) {
            let reportData =[...data];
            if (reportData.length > 0) {
                setData(addNewStaticValue(reportData));
            }
        }
    }, [props.staticList]);

    const addNewStaticValue = (reportData) => {
        const { staticList, isGrid } = props;
        if (isGrid) {
            if (staticList.length > 0) {
                for (var i = 0; i < staticList.length; i++) {
                    const index = reportData.findIndex(data => data.Value == staticList[i]);
                    if (index === -1) {
                        reportData.push({ Name: staticList[i], Value: staticList[i] });
                    }
                }
            }
        }
        return reportData;
    }

    const getReportData = async () => {
        if (reportId) {
            let filterDataObject = [];
            let filterChecked = true;
            for (let filterDefinition of props.settings.listParameterFields) {
                let filterValue;
                if (!filterDefinition.isGrid) {
                    filterValue = props.dataModel[filterDefinition.controlName];
                } else {
                    filterValue = (!filterDefinition.isDetailTableField) ? props.headerdDataModel[filterDefinition.controlName] : props.dataModel[filterDefinition.controlName];
                }

                if (filterDefinition.isMandatory && !filterValue) {
                    if (filterValue !== 0) {
                        filterChecked = false;
                        break;
                    }
                }
                filterDataObject.push({
                    fieldName: filterDefinition.listField,
                    condition: "equals",
                    value: filterValue,
                });
            }

            for (let filterDefinition of props.settings.listParameterFields) {
                if (props.retrievedDataModel[filterDefinition.controlName] !== props.dataModel[filterDefinition.controlName]) {
                    onChangeValue("", "");
                    value = "";
                }
                else if (!props.dataModel[filterDefinition.controlName] && props.dataModel[props.settings.fieldName] && props.dataModel[props.settings.fieldName] !== props.settings.defaultValue) {
                    onChangeValue("", "");
                    value = "";
                }
            }

            
            if (filterChecked) {
                let url = `api/ObjectData/GetReportData`;
                if (props.isPublic) {
                    url = `api/ObjectData/GetPublicReportData`;
                }

                const response = await fetch(url,
                    {
                        method: 'POST',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            ReportId: reportId,
                            GetDataAboveThreshold: true,
                            FilterParameter: filterDataObject.filter(function (fieldData, index, arr) {
                                return ((fieldData.value !== "" && fieldData.value != null));

                            }),
                        })
                    });
                if (response.status === 200) {
                    const reportDataObject = await response.json();
                    let reportData = reportDataObject.result;
                    setData(reportData);
                    //if (value && fieldValue && value !== fieldValue[props.settings.valueField]) {
                    if (value/*props.settings.defaultValue*/) {
                        const dataIndex = reportData.findIndex(option => option[props.settings.valueField] == value)//props.settings.defaultValue);
                        if (dataIndex >= 0) {
                            const selectedValue = reportData[dataIndex]
                           setFieldValue(reportData[dataIndex])
                            if (props.settings.listOutputFields.length > 0) {
                                if (!props.isGrid)
                                    props.onChange(props.settings.isMandatory, props.settings.fieldName, selectedValue[props.settings.valueField], props.settings.listOutputFields, selectedValue);
                                else
                                    props.onChange(props.settings.isMandatory, props.settings.fieldName, selectedValue[props.settings.valueField], "", "", "", props.settings.listOutputFields, selectedValue);
                            }
                            if (props.isGrid && props.settings.reportId) {
                                setDropDownList(props.settings, selectedValue,true);
                            }

                        }
                        else {
                            let defValue = {};
                            defValue[props.settings.valueField] = "";
                            setFieldValue(defValue)
                            if (props.isGrid) {
                                props.onChange(props.settings.isMandatory, props.settings.fieldName, "", "", "", "", props.settings.listOutputFields);
                            }
                        }
                    }
                }

                else {
                    console.log('Dropdown options loading failed')

                }
            }
            else
            {
                let blankOption = {}
                const reportData = [blankOption]
                setData(reportData);
            }
        }
        else {
            let reportData = [];
          
            if (props.settings.staticValues.indexOf(',') > -1) {
                let splitArray = props.settings.staticValues.split(',');
                for (var i = 0; i < splitArray.length; i++) {
                    const optionItem = splitArray[i].trim()
                    reportData.push({ Name: optionItem, Value: optionItem });
                }
            } else
            {
                reportData.push({ Name: props.settings.staticValues, Value: props.settings.staticValues });
            }
            addNewStaticValue(reportData);

            setData(reportData);
            //if (value && fieldValue && value !== fieldValue[props.settings.valueField]) {
            if (value) {
                const dataIndex = reportData.findIndex(option => option[props.settings.valueField] == value);
                if (dataIndex >= 0) {
                    const selectedValue = reportData[dataIndex]
                    setFieldValue(reportData[dataIndex])
                    if (props.settings.listOutputFields.length > 0) {
                        if (!props.isGrid)
                            props.onChange(props.settings.isMandatory, props.settings.fieldName, selectedValue[props.settings.valueField], props.settings.listOutputFields, selectedValue);
                        else {
                            props.onChange(props.settings.isMandatory, props.settings.fieldName, selectedValue[props.settings.valueField], "", "", "", props.settings.listOutputFields, selectedValue);
                        }
                    }
                    if (props.isGrid && props.settings.reportId) {
                        setDropDownList(props.settings, selectedValue)
                    }
                }
            }
        }
       
    }

    const setDropDownList = (settings, values, isDefault) => {
        const label = (values) ? values[settings.displayField] : "";
        const value = (values) ? values[settings.valueField] : "";
        if (value !== label) {
            let list = {
                controlId: settings.controlId,
                label: label,
                value: value,
            };
            props.setDropDownList(list, settings.controlId, isDefault);
        }
    }
    
    const onChangeValue = (event, value) => {
        if (!value) {
            let defValue = {};
            defValue[props.settings.valueField] = "";
            setFieldValue(defValue)
            if (!props.isGrid)
                props.onChange(props.settings.isMandatory, props.settings.fieldName, "", props.settings.listOutputFields);
            else {
                if (props.settings.reportId) {
                    setDropDownList(props.settings, value)
                }
                props.onChange(props.settings.isMandatory, props.settings.fieldName, "", "", "", "", props.settings.listOutputFields);
            }
        }
        else {
            if (value.inputValue) {
                let newValue = {};
                newValue[props.settings.valueField] = value.inputValue;
                newValue[props.settings.displayField] = value.inputValue;
                setFieldValue(newValue)
                if (!props.isGrid)
                    props.onChange(props.settings.isMandatory, props.settings.fieldName, newValue[props.settings.valueField], props.settings.listOutputFields, newValue);
                else {
                    if (props.settings.reportId) {
                        setDropDownList(props.settings, newValue)
                    }
                    props.onChange(props.settings.isMandatory, props.settings.fieldName, newValue[props.settings.valueField], "", "", "", props.settings.listOutputFields, newValue);
                }
            }
            else {
                setFieldValue(value)
                if (!props.isGrid)
                    props.onChange(props.settings.isMandatory, props.settings.fieldName, value[props.settings.valueField], props.settings.listOutputFields, value);
                else {
                    if (props.settings.reportId) {
                        setDropDownList(props.settings, value)
                    }
                    props.onChange(props.settings.isMandatory, props.settings.fieldName, value[props.settings.valueField], "", "", "", props.settings.listOutputFields, value);
                }
            }
            
           
        }
    }

    const getOptionLabel = (option) => {
        const value = option[props.settings.displayField] ? option[props.settings.displayField] : "";
        return value.toString();
    }
    const controlStyle = {
        color: props.settings.foreColor,
        background: props.settings.backGroundColor,
    }
    const textAlignStyle = {
        textAlign: props.settings.textAlign
    }
    let errorState = {};
    let requiredIndicator = "";
    let helperText = props.settings.helpText;
    if (props.settings.isMandatory) {
        requiredIndicator = " *";
        const validation = props.validationSchema.filter(field => field.fieldName === props.settings.fieldName && field.errorState)
        if (validation.length > 0) {
            errorState.error = true;
            helperText = `${props.settings.caption} is required`;
        }
    }
    const labelStyle = {
        color: (errorState.error) ? '#f44336' : props.settings.captionColor,
        zIndex: 1
    }
    const helperTextStyle = { wordBreak: 'break-all' }
    const caption = !props.settings.caption ? ' ' : props.settings.caption;
    let filterOptionsObject = { };
    if (props.settings.isAllowNewValue) {
        //filterOptionsObject.freeSolo = true;
        filterOptionsObject.filterOptions = (options, params) => {
            const filtered = filter(options, params);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option[props.settings.valueField]);
            if (inputValue !== '' && !isExisting) {
                let newValue = {};
                newValue.inputValue = inputValue;
                newValue[props.settings.displayField] = `Add "${inputValue}"`;
                filtered.push(newValue);
            }

            return filtered;
        }
    }

    const useStyles = makeStyles({
        paper: {
            overflowX: "auto",
            minWidth:"200px"
        }
    });
    const classes = useStyles();
    return (<Autocomplete
        classes={{ paper: classes.paper }}
        disablePortal
        id={`vgoCmb_${props.settings.fieldName}`}
        key={`vgoCmb_key_${props.settings.fieldName}`}
        clearOnBlur
        disabled={!props.settings.isEditable}
        {...filterOptionsObject}
        options={data}
        value={fieldValue}
        getOptionLabel={getOptionLabel}
        onChange={onChangeValue}
        onBlur={(event) => {
            if (props.settings.isMandatory && !errorState.error) {
                if (fieldValue && fieldValue[props.settings.valueField] === "" && data.length > 0) {
                    if (!props.isGrid)
                        props.onChange(props.settings.isMandatory, props.settings.fieldName, "", props.settings.listOutputFields, true);
                    else {
                        if (props.settings.reportId) {
                            setDropDownList(props.settings, "")
                        }
                        props.onChange(props.settings.isMandatory, props.settings.fieldName, "", "", "", "", props.settings.listOutputFields, true);
                    }
                }
            }
                   

                }}
        renderInput={(params) => <TextField
            {...params}
            autoFocus={props.settings.hasFocus}
            helperText={!helperText ? ' ' : helperText}
            {...errorState}
            FormHelperTextProps={{
                style: helperTextStyle,
            }}
            label={`${caption}${requiredIndicator}`}
            style={{ width: "100%" }}
            InputProps={{
                
                ...params.InputProps,
                style: controlStyle,
                inputProps: {
                    ...params.inputProps,
                    style: textAlignStyle,

                },
            }}
            InputLabelProps={{
                style: labelStyle
            }}
        />}
    />);

}
