export const reportFormat = { Regular: 'regular', Calendar: 'calendar' };
export const ReportPaymentMode = { SELECT: 'select', CC: 'creditCard', ACH: 'eCheck' };
export const CreditCardType = { Visa: 'visa', Master: 'master', Amex: 'amex', Discover: 'discover' };
export const ReportPaymentAccountType = { Personal: 'personal', Business: 'business' };
export const WidgetType = { Weather: 'weather', Joke: 'joke', Links: 'link', Quote: 'quote' };
export const BannerType = { Select: 'select', Notification: 'notification', Alert: 'alert', Promotion: 'promotion' };
export const DetailDisplayType = { Select: 'select', More: 'more', Expand: 'expand', LearnMore: 'learnMore' };
export const MenuType = { Report: 'report', Dashboard: 'dashboard', Form: 'form', CrystalReport: 'crystalreport', Calendar: 'calendar', RecordProcessing: 'recordprocessing' }
export const ReportDataSourceType = { StoredProcedure: 'storedProcedure', SQLQuery: 'sqlQuery', B1Query: 'b1Query', View: 'view', CrystalReport:'crystalReport' }
export const LinkActionType = { Report: 'report', Forms: 'forms', CrystalReport: 'crystalReport', BizweaverWebservice: 'bizweaverWebservice', OtherURL: 'otherURL' }
export const ActionMode = { NewColumn: 'newColumn', ExistingColumn: 'existingColumn' }
export const FieldType = { Numeric: 'numeric', String: 'string', Time: 'time', DateTime: 'datetime', Binary: 'binary' }
export const DeviceType = { Mobile: 'mobile', Tablet: 'tablet', Desktop: 'desktop' }
export const FormatType = { None: 'none', Decimal: 'decimal', Decimal1: 'decimal1', Decimal2: 'decimal2', Decimal3: 'decimal3', Decimal4: 'decimal4', Currency: 'currency', Date: 'date', DateTime: 'dateTime',Time:'time', Email: 'email', File: 'file', Url: 'url', Image: 'image', TextMaximum: 'textMaximum', Pixel45: 'pixel45', Pixel65: 'pixel65', Pixel250: 'pixel250',CheckBox:'checkBox' }
export const Method = { Get: 'get', Post: 'post' }
export const KpiRelation = { LessThan: 'lessThan', GreaterThan: 'greaterThan', Between: 'between', EqualTo: 'equalTo' }
export const KpiDisplayType = { Cell: 'cell', Text: 'text', Icon: 'icon' }
export const FavoriteStatus = { Add: 'add', Delete: 'delete' }
export const ButtonType = { Ok: 'ok', OkCancel: 'okCancel', Cancel: 'cancel' }
export const ButtonName = { Ok: 'ok', Cancel: 'cancel', Yes: 'yes', No: 'no' }
export const ExportFormats = { CSV: 'CSV', Print: 'print', Mail: 'mail', PDF: 'PDF' }
export const CalendarEventFieldSettings = { HasStartDate: 'hasStartDate', HasStartDateAndEndDate: 'hasStartDateAndEndDate', HasStartDateTime: 'hasStartDateTime', HasStartDateTimeAndEndDateTime: 'hasStartDateTimeAndEndDateTime' }
export const ControlType = {
    Select: 'select', SectionTitle: 'sectionTitle', TextBox: 'textBox', DropdownList: 'dropdownList', Email: 'email', NumericTextBox: 'numericTextBox', DatePicker: 'datePicker', TimePicker: 'timePicker', FileUpload: 'fileUpload',
    Signature: 'signature', Image: 'image', MultiText1: 'multiText1', Button: 'button',
    ActionWithConfirmClear: 'actionWithConfirmClear', ActionWithClear: 'actionWithClear', ClearWithGetAction: 'clearWithGetAction', ActionWithClearGet: 'actionWithClearGet', Submit: 'submit', CheckBox: 'checkBox'
}
export const ListControlType = {
    Select: 'select', SectionTitle: 'sectionTitle', TextBox: 'textBox', DropdownList: 'dropdownList', NumericTextBox: 'numericTextBox', DatePicker: 'datePicker', TimePicker: 'timePicker', FileUpload: 'fileUpload',
    Signature: 'signature', Image: 'image', Button: 'button', CheckBox: 'checkBox'
    
}
export const ThumbnailImageType = { None: 'none', FortyFivePixel: 'fortyFivePixel', SixtyFivePixel: 'sixtyFivePixel', TwoFiftyPixel: 'twoFiftyPixel' }
export const ChartType = { None:'none', Pie: 'pie', Column: 'column', Bar: 'bar', Line: 'line', Area: 'area', Bubble: 'bubble', Doughnut: 'doughnut', Scatter: 'scatter', Funnel: 'funnel', Polar: 'polar', Radar: 'radar', ComboChart: 'comboChart', }
export const ObjectType = { Report: 1, Dashboard: 2, Form: 3 }
export const FrameType = { FullRow: 'fullRow', FirstColumn: 'firstColumn', SecondColumn: 'secondColumn' }
export const ActionType = { Service: 'service', Url: 'url', Script: 'script' }
export const DashboardObjectType = { List: 'list', Chart: 'chart', Schedule: 'schedule' }
export const FormulaType = { Numeric: 'numeric', AggregateSum: 'aggregateSum', DateDifference: 'dateDifference', DateAdd: 'dateAdd' }
export const ReportObjectLoadType = { Report: 'report', LookUp: 'lookup', Dashboard: 'dashboard' }
export const ReportFilter = { Equals: 'equals', NotEqual: 'notEqual', GreaterThan: 'greaterThan', GreaterThanOrEqualTo: 'greaterThanOrEqualTo', LessThan: 'lessThan', LessThanOrEqualTo: 'lessThanOrEqualTo', Between: 'between', Contains: 'contains', StartWith: 'startWith', EndWith: 'endWith' }
export const ImageCulture = { SixtyFivePixel: '65 X 65 pixels', FortyFivePixel: '45 X 45 pixels', TwoFiftyPixel: '250 X 250 pixels' }
export const ImageFormat = { Pixel45: '45 X 45 pixels', Pixel65: '65 X 65 pixels', Pixel250:'250 X 250 pixels'}
export const NewsAndAnnouncement = { Article: 'article', Event: 'event', Announcement: 'announcement' }
export const AggregateOptions = { None: 'none', Sum: 'sum', Average: 'average', Min: 'min', Max: 'max', Count: 'count' }
export const DbType = { Int: 'int', SmallInt: 'smallint', Decimal: 'decimal', Numeric: 'numeric', Varchar: 'varchar', Nvarchar: 'nvarchar', Date: 'date', DateTime: 'datetime', Binary: 'binary', VarBinary: 'varbinary', Time: 'time', Float: 'float' }
export const HanaDbType = { Int: 'integer', DateTime: 'timestamp' }
export const GridFieldType = { String: "string", Number: 'number', DateTime: 'datetime', Date: "date", Boolean: "boolean", NotDetermined:"notDetermined" }
export const FieldCategory = { RegularField: 'regularField',   CalculatedField : 'calculatedField',   NonQueryField : 'nonQueryField'}
export const ComponentList = { Home: 'home', Content: 'content', UserProfile: 'userProfile', Banner: 'banner', Calendar: 'calendar', DataView: 'dataView', Dashboard: 'dashboard', Form: 'form', ResetPassword: 'resetPassword', CrystalReportViewer:'crystalReportViewer'}
export const PasswordHelperCode ={ 
    PasswordTooShort : 1,PasswordRequiresUpper : 2,PasswordRequiresLower :3,
    PasswordRequiresDigit: 4, PasswordRequiresNonAlphanumeric: 5, NoMoreThan2ConsecutiveCharacters: 6,
    NoConsecutiveSpaces: 7,
 
}
export const ControlMode= {None:"none",Dynamic:"dynamic",Static:"static"}
export const FormType =
{
    None:0,Standard: 1,HeaderDetail: 2,
}
export const FormSection =
{
    None:"none",
    Header: "header",
    Details: "detail",
    Button: "button"
}
export const ServerErrorCode = {
    SuccessStatus: 200,
    UnauthorizedStatus: 401,
    ServerErrorStatus: 500,
    NoContent: 204,
    BadRequestStatus: 400,
}

export const passwordHelp = [
    { Type: PasswordHelperCode.PasswordTooShort, Status: false, Message: "12 characters" },
    { Type: PasswordHelperCode.PasswordRequiresUpper, Status: false, Message: "1 upper case" },
    { Type: PasswordHelperCode.PasswordRequiresLower, Status: false, Message: "1 lower case" },
    { Type: PasswordHelperCode.PasswordRequiresDigit, Status: false, Message: "1 number" },
    { Type: PasswordHelperCode.PasswordRequiresNonAlphanumeric, Status: false, Message: "1 special character" },
    { Type: PasswordHelperCode.NoMoreThan2ConsecutiveCharacters, Status: false, Message: "No more than 2 consecutive characters" },
    { Type: PasswordHelperCode.NoConsecutiveSpaces, Status: false, Message: "No consecutive spaces" },
]
export const DatabaseType = { SqlServer: 'sqlServer', Hana: 'Hana' }

export const currencyCulture = ["Local Culture", "en-US", "en-Canada", "es-Mexico", "pt-Brazil", "es-Panama", "es-Chile", "es-Argentina", "en-UK",
    "en-Australia", "en-New Zealand", "kok-India", "de-Germany", "es-Spain", "nl-Netherlands"];
export const dateFormat = ["mm/dd/yyyy", "dd/mm/yyyy", "dd/mmm/yyyy", "yyyy/mm/dd", "mmm/dd/yyyy"];
export const dateTimeFormat = [
    "mm/dd/yyyy hh:mm AM/PM", "mm/dd/yyyy hh:mm", "mm/dd/yyyy hh:mm:ss AM/PM", "mm/dd/yyyy hh:mm:ss",
    "dd/mm/yyyy hh:mm AM/PM", "dd/mm/yyyy hh:mm", "dd/mm/yyyy hh:mm:ss AM/PM", "dd/mm/yyyy hh:mm:ss",
    "dd/mmm/yyyy hh:mm AM/PM", "dd/mmm/yyyy hh:mm", "dd/mmm/yyyy hh:mm:ss AM/PM", "dd/mmm/yyyy hh:mm:ss",
    "yyyy/mm/dd hh:mm AM/PM", "yyyy/mm/dd hh:mm", "yyyy/mm/dd hh:mm:ss AM/PM", "yyyy/mm/dd hh:mm:ss",
    "mmm/dd/yyyy hh:mm AM/PM", "mmm/dd/yyyy hh:mm", "mmm/dd/yyyy hh:mm:ss AM/PM", "mmm/dd/yyyy hh:mm:ss"
];
export const timeFormat = ["hh:mm AM/PM", "hh:mm", "hh:mm:ss AM/PM", "hh:mm:ss"]; 
export const displayEnumType = {
    "Select": 0,
    "Currency": 2,
    "Date": 3,
    "DateTime": 4,
    "Email": 5,
    "File": 4,
    "Url": 5,
    //"Image": 5,
    "Time": 10,
    "Decimal_1": 11,
    "Decimal_2": 12,
    "Decimal_3": 13,
    "Decimal_4": 14,
};
export const ControlSubActions = {
    none:0,
    editRole:1
}
export const MenuItemMode = { MainMenu: 'mainManu', QuickAction: 'quickAction' }

export const ExportObjectType = {
    Report:"report",
    Dashboard:"dashboard",
    Form:"form",
    Menu:"menu",
    Role:"role",
    User:"user",
    Filter:"filter",
    Company:"company",
}

export const DataType= {
    Select : 0,
        Text : 1,
        Numbers:2,
        Binary : 3,
        Date : 4
}
export const BizWeaverMessage = { Success: 'Request processed', Error: 'Unable to process request', }


