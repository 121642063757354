import { customThemeStyles } from 'common/theme.js';
import grey from '@mui/material/colors/grey';
import { customColorPalette } from 'common/theme.js';
export const rolesTheme = (theme) => ({
    ...customThemeStyles,
    roleListBox: {
        borderColor: grey[300] + '!important',
        backgroundColor: "white!important",
    },
    root: {
        justifyContent: 'center!important',
        flexWrap: 'wrap!important',
        height: "93px!important",
        borderRadius: "4px!important",
    },
    tab: {
        paddingBottom: '0px!important',
        paddingTop: '0px!important',
        paddingLeft: '0px!important',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.12), 0px 4px 4px rgba(0, 0, 0, 0.24)!important'
    },
    card: {
        height: "608px",
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.2), 0px 1px 3px rgba(0, 0, 0, 0.1)'

    },
    chip: {
        margin: theme.spacing(0.5),
    },
    selectControl: {
        paddingTop: '3px!important'

    },
    cardContent: {
        padding: '0px!important'

    },
    buttonSave: {
        minWidth: '94px!important'
    },
    buttonCancel: {
        minWidth: '89px!important'
    },
    selectAll: {
        paddingLeft: '16px!important',
        width: '15px!important',
        borderBottom: '1px solid rgba(224, 224, 224, 1)!important',
        top: 0,
        zIndex: 100,
        position: 'sticky!important',
        backgroundColor: '#fff!important',
    },
    userSelectAll: {
        paddingLeft: '8px!important',
        textAlign:"center",
        width: '15px!important',
        borderBottom: '1px solid rgba(224, 224, 224, 1)!important',
        top: 0,
        zIndex: 100,
        position: 'sticky!important',
        backgroundColor: '#fff!important',
    },
    selectCheckbox: {
        padding: '0px!important'
    },
    responsiveScrollMaxHeight: {
        maxHeight: '510px !important',
    },
    recordCount: {
        maxWidth: '118px!important'
    },
    rolesTab: {
        minWidth: '160px!important',
    },
    roleCard: {
        minHeight: '510px !important',
    },
    roleCardContent: {
        minHeight: '510px !important',
    },



    deleteIconButton: {
        padding: '0 ',
    },
    alertActionButton: {
        minWidth: '45px',
        paddingLeft: '8px !important',
        paddingRight: '0px !important',
    },
    alertIcon: {
        padding: '9px 0 7px 0',
    },
    alertMessage: {
        padding: '0px !important',
    },
    alertIconButton: {
        marginLeft: '30px !important',
        paddingRight: '0px !important',
    },
    closeButton: {
        color: `${customColorPalette.black[87]} !important`
    },
    popper: {
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.2), 0px 1px 3px rgba(0, 0, 0, 0.1)',
    },
    tabMenu: {
        visibility: 'hidden',
    }
    
});