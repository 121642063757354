import React from 'react';

import { Button, Grid, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getNewUser } from 'admin/actions/userActions';

class HomePageListHeader extends React.Component {

	onClickNewUser = () => {
		this.props.dispatch( getNewUser() );
		this.props.history.push( `/Admin/HomePage/new` );
	}

    render() {

		
        return (
				<Grid container direction="row" justifyContent="space-between">
					<Grid item>
                        <Typography variant="h4">Home Page Content</Typography>
					</Grid>
					<Grid item>
                            <Button
								variant="contained" 
								color="primary" 
								disableElevation 
								startIcon={<AddIcon />}
								onClick={this.onClickNewUser}
								>ADD CONTENT</Button>
					</Grid>
				</Grid>
        );
    }

}

/**
 * mapStateToProps
 * @param {*} state 
 */
function mapStateToProps( state ) {
    return {
		alert: state.alert,
	};
};

export default withRouter(connect(mapStateToProps)(HomePageListHeader));
