const requestBanners = 'REQUEST_BANNERS';
const receiveBanners = 'RECEIVE_BANNERS';

const initialState = { banners: [], isLoading: false };
export const reducer = (state, action) => {

    state = state || initialState;

    if (action.type === requestBanners) {
        return {
            ...initialState,
            isLoading: true
        };
    }
    else if (action.type === receiveBanners) {
        return {
            ...state,
            isLoading: false,
            banners: action.banners
        };
    }
   
    return state;
}