export const objectListFormatDate = (value) => {
    if (value) {

        const userLocale =
            navigator.languages && navigator.languages.length
                ? navigator.languages[0]
                : navigator.language;

        const date = new Date(value);
        const options = {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour:	"numeric",
            minute:	"numeric"
        };
        return date.toLocaleString(userLocale, options).replace(',', '');
    } else {
        return value;
    }
}
