import React, { useState} from 'react';
import Checkbox from '@mui/material/Checkbox';
const SelectCheckbox = (props) => {

    const [checked, setChecked] = useState(props.checked);
    React.useEffect(() => {

        setChecked(props.checked)

    }, [props.checked]);
    const handleChange = (event) => {
        setChecked(event.target.checked)
        props.checkChange(props.data, event.target.checked ? 1 : 0);
    };


    return (
        <Checkbox sx={{padding:"0 9px"}} checked={checked}
            onChange={handleChange}
        
        />
    );
        
}

export default (SelectCheckbox);