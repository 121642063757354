import React from 'react';

import { styled } from '@mui/styles';
import { withStyles } from '@mui/styles';
import { spacing } from '@mui/system';
import {  Grid, Typography } from '@mui/material';
import MuiButton from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check'

import { connect } from 'react-redux';
import { withRouter } from 'react-router';


import { dashboardTheme } from './theme/dashboardTheme';
import ActionBackdrop from 'admin/components/section/settings/Backdrop';
import { updateDashboardWithPanel, updateDashboard} from 'admin/actions/dashboardActions';

const Button = styled(MuiButton)(spacing);


class DashboardEditHeader extends React.Component {

    constructor(props) {
        super(props);
        this.isUpdate = this.props.match.params.dashboardId !== "new";
    }
    componentDidUpdate() {
        this.isUpdate = this.props.match.params.dashboardId !== "new";
    }
    onDiscardClick = () => {
        this.props.history.push('/Admin/Dashboards');
    }

    onSaveClick = () => {
        const dashboard = updateDashboardWithPanel(this.props.dashboard, this.props.panelListUI, this.props.panelObjectList)
        this.props.dispatch(updateDashboard(dashboard));
    }

    render() {
        return (
            <Grid container direction="row" justifyContent="space-between" spacing={0} xl={12}>
                {this.props.dashboardActionStatus ==="VGO_UPDATE_DATABASE_REQUEST" && <ActionBackdrop />}
                <Grid item>
                    <Typography variant="h4">{this.isUpdate ? "Edit" : "Add"} Dashboard</Typography>
                </Grid>
                <Grid item >
                    <Grid container direction="row" justifyContent="space-between" spacing={1} >
                        <Grid item >
                            <Button
                                className={this.props.classes.customControlButton }
                                variant="defaultContained"
                                disableElevation
                                startIcon={""}
                                onClick={this.onDiscardClick}
                            >CANCEL</Button>
                        </Grid>
                        <Grid item >
                            <Button
                                className={this.props.classes.customControlButton}
                                color="primary"
                                variant="contained"
                                disableElevation
                                startIcon={<CheckIcon />}
                                onClick={this.onSaveClick}
                            >SAVE</Button>
                        </Grid> </Grid>
                </Grid>
            </Grid> 
        );
    }

}

function mapStateToProps(state) {
    let props = {
        dashboard: state.dashboards.dashboard,
        panelObjectList: state.dashboards.panelObjectList,
        panelListUI: state.dashboards.panelList,
        dashboardActionStatus: state.dashboards.dashboardActionStatus,
        alert: state.alert,
    };
    return props;
};
 
export default withRouter(connect(mapStateToProps)(withStyles(dashboardTheme)(DashboardEditHeader)));
