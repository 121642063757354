import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Grid, Typography, Card, CardContent } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Link } from 'react-router-dom';
import { objectHeaderTheme } from './theme/layoutObjectHeaderTheme';
import Divider from '@mui/material/Divider';
 
const useStyles = makeStyles({
    ...objectHeaderTheme
});
export default function LayoutContentHeader(props) {
    const classes = props.classes;//useStyles(props);
    
    return (
        <Grid container spacing={0}>
            <Grid item xs={12} lg >
               
                        <Box className={classes.header} pb={2} >
                            <Grid container spacing={0} justifyContent="flex-start" alignItems="flex-start"  >
                        <Grid item xs={2} lg={2} > 
                                    <Box pl={1} > <Link to="/#ContentLanding?otype=C">
                                        <HomeOutlinedIcon className={classes.homeIcon} color="primary" /></Link></Box>
                                </Grid>
                                <Grid item xs={9} lg={8} className={classes.heading} >

                                    <Typography variant="h4" >{props.title}</Typography>

                                </Grid>
                                <Grid item xs={1} lg={2}  >
                                    
                                    
                                </Grid>
                            </Grid>
                </Box>
                <Box pl={5} pr={5} width={1} mt={4.4}><Divider /></Box>
                    
            </Grid>
        </Grid>
   );
}
