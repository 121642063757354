import { theme, customThemeStyles } from 'common/theme.js';
export const routeTheme = (contentTheme) => ({
    ...customThemeStyles,
    routeTheme: {
        position: "relative",
        width: "100%",
               
    },
    inner: {
        marginTop: "67.2px!important",
        position: "fixed",
        left: "56%",
        transform: "translate(-44%, 0)",
        zIndex: 20000
    }

});