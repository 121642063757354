import { theme, customThemeStyles } from 'common/theme';
export const homeTheme = () => ({
    ...customThemeStyles,

    mainWithSidebar: {


    },
    content: {
        padding: "24px",
        paddingLeft: "0px",
        paddingRight: "0px",
         float: "left",
        width: '100%'   //'calc(100% - 305px)',
     
    },
    contentNoSideBar: {
        padding: "24px",
        paddingLeft: "0px",
        paddingRight: "0px",
    },
    wrapperMiddle: {
        height:"100vh",
        display: "block !important",
       
       
    },

    wrapperLeft: {

        minWidth: "305px"

    },

    containerBanner: {

        width: "100%",

        [theme.breakpoints.only('xs')]: {
            minWidth: "300px",
          
        },
        

    }


});