import { ActionTypes as types, sectionType } from './actionTypes';
import api from 'admin/api/adminApi';
import { showError, showSuccess } from './alertActions';
import { getError } from './errorActions';
import { getFormUi, getFormControl, getButtonActionObject } from './formActions';
import { updateCategoryList } from './dataViewActions';
import { FormType, ControlType } from 'client/components/Common/Enum';
import { validateObject, validateObjectAt } from 'admin/actions/validationAction';
import { getDataViewListByRoleListSuccess } from 'admin/actions/dataViewActions';
import { formSetupSchema } from 'admin/components/section/form/setup/validationSchema';
import * as Enum from 'client/components/Common/Enum';

const message = 'Please correct the indicated items';

const tab = 0;
const apiGetForm = (id) => {
    return api.getForm(id);
};

const apiGetDataSourceList = (companyId) => {
    return api.getTableList(companyId);
};
const apiGetTableFieldsList = (companyId, dataSource) => {
    return api.getTableFieldList(companyId, dataSource);
};

const apiUpdateForm = (form) => {
    return api.updateForm(form);
};

const getFormRequest = (id) => {
    return {
        type: types.VGO_GET_FORM_REQUEST,
        reportId: id,
    };
};

const getFormSuccess = (resp) => {
    return {
        type: types.VGO_GET_FORM_SUCCESS,
        formRepository: resp.data,
    };
};

const getTableFieldsRequest = (actionType) => {
    return {
        type: actionType
    }
};
const getTableFieldsListSuccess = (resp, actionType) => {
    return {
        type: actionType,
        fields: resp.data
    }
};
const getDataSourceRequest = () => {
    return {
        type: types.VGO_GET_FILTERS_DATASOURCE_REQUEST
    }
};
const getDataSourceListSuccess = (resp) => {

    return {
        type: types.VGO_GET_FILTERS_DATASOURCE_SUCCESS,
        dataSources: resp.data,
    }
};

const getUpdateFormRequest = (form) => {
    return {
        type: types.VGO_UPDATE_FORM_REQUEST,
        formRepository: form,
    };
};

const getUpdateFormSuccess = (resp) => {
    return {
        type: types.VGO_UPDATE_FORM_SUCCESS,
        formRepository: resp.data,
    };
};

export const getNewForm = (change) => {
    return {
        type: types.VGO_NEW_FORM,
    };
}
export const getChangeForm = (change) => {
    return {
        type: types.VGO_CHANGE_FORM,
        change,
    };
}
export const getChangeFormRole = (change, resetlookUpList) => {
    return {
        type: types.VGO_CHANGE_FORMROLE,
        roleChange:{ change, resetlookUpList},
    };
}
export const getChangeFormTableOption = (change) => {
    return {
        type: types.VGO_CHANGE_FORM_TABLE_OPTION,
        change,
    };
}
export const getChangeFormSource = (change) => {
    return {
        type: types.VGO_CHANGE_FORM_FORMSOURCE,
        change,
    };
}
export const getChangeFormType = (change) => {
    return {
        type: types.VGO_CHANGE_FORM_FORMTYPE,
        change,
    };
}
export const getChangeDataSourceForm = (change) => {
    return {
        type: types.VGO_CHANGE_RESET_FORM,
        change,
    };
}

export const triggerFormSubmit=() => {
    return{
        type: types.VGO_ACTION_TRIGGER_FORM_SUBMIT,
    }
}
export const getChangeKeyField = (change, tableType,checked,fields) => {
    const valid = validateKeyField(tableType, checked, fields);
    
    if (!valid.status) {
        return dispatch => {
            showError(dispatch, valid.message, 5000);
        };

    } else {
        return {
            type: types.VGO_CHANGE_FORM_FORMSOURCE,
            change,
        };
    }
   
}

const changeTabIndex = (tabIndex) => {
    return {
        type: types.VGO_CHANGE_FORM_TAB_INDEX,
        tabIndex: tabIndex,
    };
}

export const changeFormTab = (tabIndex) => {
    return dispatch => { dispatch(changeTabIndex(tabIndex)) };
}


export const newFormPrimaryTableField = (tableType) => {
    if (tableType === "primary") {
        return {
            type: types.VGO_NEW_FORM_PRIMARY_TABLE_FIELD,
        };
    } else {
        return {
            type: types.VGO_NEW_FORM_DETAIL_TABLE_FIELD,
        };
    }
}
export const deleteFormPrimaryTableField = (rowIndex, tableType) => {
    if (tableType === "primary") {
        return {
            type: types.VGO_DELETE_FORM_PRIMARY_TABLE_FIELD,
            data: { index: rowIndex },
        };
    } else {
        return {
            type: types.VGO_DELETE_FORM_DETAIL_TABLE_FIELD,
            data: { index: rowIndex },
        };
    }

}


export const getForm = (id) => {
    return dispatch => {
        dispatch(getFormRequest(id));
        return apiGetForm(id)
            .then((resp) => {
                dispatch(getFormSuccess(resp));
                let roleList = "";
                resp.data.formBuilderDTO.roles.forEach((role,index) => {
                    roleList = `${roleList}roleIdList[${index}]=${role.roleId}&`
                })
                dispatch(getDataViewListByRoleListSuccess({ data: { roles: roleList, dataViews: resp.data.dataViewListByRole } }));
            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
                showError(dispatch, message, 5000);
            });
    };
};

export const getDataSourceList = (companyId) => {
    return dispatch => {
        if (validateCompany(companyId)) {
            dispatch(getDataSourceRequest());
            return apiGetDataSourceList(companyId)
                .then(resp => {
                    dispatch(getDataSourceListSuccess(resp));
                })
                .catch(err => {
                    const { message } = err;
                    dispatch(getError(message));
                })
        } else {
            const { message } = "Invalid database selection";
            dispatch(getError(message));
            showError(dispatch, message, 5000);
        }
    };
};
export const getTableFieldsList = (companyId, dataSource, tableType) => {
    const requestActionType = tableType === "primary" ? types.VGO_GET_FORM_PRIMARY_TABLE_FIELDS__REQUEST : types.VGO_GET_FORM_DETAIL_TABLE_FIELDS__REQUEST;
    const successActionType = tableType === "primary" ? types.VGO_GET_FORM_PRIMARY_TABLE_FIELDS__SUCCESS : types.VGO_GET_FORM_DETAIL_TABLE_FIELDS__SUCCESS;
    return dispatch => {
        dispatch(getTableFieldsRequest(requestActionType));
        return apiGetTableFieldsList(companyId, dataSource)
            .then(resp => {
                dispatch(getTableFieldsListSuccess(resp, successActionType));
            })
            .catch(err => {
                const { message } = err;
                dispatch(getError(message));
                showError(dispatch, message, 5000);
            })
    };
};

export const updateForms = (form, primaryFieldList, detailFieldList,isUpdate) => {
    return dispatch => {

        let fieldValidation = true;
       
        validateObject(formSetupSchema, form)
            .then(resp => {
                if (resp !== true) {
                    fieldValidation = false;
                    dispatch(changeFormTab(tab));
                    dispatch(setValidateFieldsOnBlur(resp));
                }
                let valid = validateTableFields(form, primaryFieldList, detailFieldList);
                if (valid.status) {
                    dispatch(getError(valid.message));
                    valid = validateFormUIData(form, isUpdate)
                    if (valid.status) {
                        if (fieldValidation) {
                            dispatch(getUpdateFormRequest(form));
                            return apiUpdateForm(form)
                                .then((resp) => {
                                    dispatch(getUpdateFormSuccess(resp));
                                    showSuccess(dispatch, 'Success', 'Form saved successfully', 5000);
                                    if (form.category.categoryId === 0) {
                                        dispatch( updateCategoryList(resp.data.formBuilderDTO.category));
                                    }

                                })
                                .catch(err => {
                                    let { message } = err;
                                    if (err.response && err.response.data) {
                                        message = err.response.data;
                                        if (typeof (message) !== "string") {
                                            if (message.title && typeof (message.title) === "string") {
                                                message = message.title;
                                            }
                                            else {
                                                message = err.message;
                                            }
                                        }
                                    }
                                    dispatch(changeFormTab(tab));
                                    dispatch(getError(message));
                                    showError(dispatch, message, 5000);
                                });
                        }
                        else {
                            dispatch(changeFormTab(tab));
                            dispatch(getError(message));
                            showError(dispatch, message, 5000);
                        }
                    }
                    else {
                        dispatch(changeFormTab(valid.tab));
                        dispatch(getError(valid.message));
                        showError(dispatch, valid.message, 5000);
                        

                    }
                }

                else {
                    dispatch(changeFormTab(valid.tab));
                    dispatch(getError(valid.message));
                    showError(dispatch, valid.message, 5000);
                }


            })

       

};
};

const validateCompany = (companyId) => {
    if (companyId === 0) {
        return false;
    }
    return true;
}


const validateKeyField = (tableType,checked,fields) => {
    let valid = { status: true, message: '', tab:0 };
    if (checked) {
        if (tableType === "primary") {
            const primaryChecked = fields.filter(field => field.isPrimaryKeyField);
            if (primaryChecked.length > 0) {
                valid.status = false;
                valid.message = `${primaryChecked[0].fieldName} already marked as primary key`;
                valid.tab = 0;
            }
        } else {
            const primaryChecked = fields.filter(field => field.isPrimaryKeyField);
            if (primaryChecked.length > 0) {
                valid.status = false;
                valid.message = `${primaryChecked[0].fieldName} already marked as linked field`;
                valid.tab = 0;
            }
        }
    }
    return valid;

}

const validateTableFields=(form,primaryFieldList,detailFieldList) => {
    let valid = { status: true, message: '', tab: 0 };
    const fieldList = form.primaryTableDataFields.filter(field => !field.fieldName);

    if (form.formType === FormType.HeaderDetail && form.primaryTableName.toLowerCase() === form.detailTableName.toLowerCase()) {
        valid.status = false;
        valid.message = "The Primary and Detail tables can not have the same names";
        valid.tab = 0;
        return valid;
    }




    if (fieldList.length === form.primaryTableDataFields.length || form.primaryTableDataFields.length===0) {
        valid.status = false;
        valid.message = "Enter required information";
        valid.tab = 0;
        return valid;
    }
    const primaryKeyFieldList = form.primaryTableDataFields.filter(field => field.isPrimaryKeyField);
    if (primaryKeyFieldList.length === 0) {
        valid.status = false;
        valid.message = "A primary field must be selected for the primary table";
        valid.tab = 0;
        return valid;
    }

    if (form.formType === 2) {
        const detailFieldList = form.detailTableDataFields.filter(field => !field.fieldName);
        if (detailFieldList.length === form.detailTableDataFields.length || form.detailTableDataFields.length === 0) {
            valid.status = false;
            valid.message = "Detail table fields are required";
            valid.tab = 0;
            return valid;
        }
        const detailForeignKeyFieldList = form.detailTableDataFields.filter(field => field.isPrimaryKeyField);
        if (detailForeignKeyFieldList.length === 0) {
            valid.status = false;
            valid.message = "A linked field must be selected for the detail table";
            valid.tab = 0;
            return valid;
        }
    }
    if (form.formId === 0 && form.createNewTable) {
        
        form.primaryTableDataFields.map(function (newField) {
            const list = form.primaryTableDataFields.filter(field => field.fieldName.toLowerCase() === newField.fieldName.toLowerCase());
            if (list.length >1 ) {
                valid.status = false;
                valid.message = `The field '${newField.fieldName}'  already exists in '${form.primaryTableName}' table`;
                valid.tab = 0;
                
                return valid;
            }
        });

        if (form.formType === 2) {
            form.detailTableDataFields.map(function (newField) {
                const detailList = form.detailTableDataFields.filter(field => field.fieldName.toLowerCase() === newField.fieldName.toLowerCase());
                if (detailList.length > 1) {
                    valid.status = false;
                    valid.message = `The field '${newField.fieldName}'  already exists in '${form.detailTableName}' table`;
                    valid.tab = 0;
                    
                    return valid;
                }
            });
        }
    }
    //if (form.formId > 0) {
    //    const newFieldList = form.primaryTableDataFields.filter(field => field.isNew);
    //    newFieldList.map(function(newField) {
    //        const list = primaryFieldList.filter(field => field.fieldName === newField.fieldName);
    //        if (list.length > 0) {
    //            valid.status = false;
    //            valid.message = `Cannot add new field '${newField.fieldName}' since field already exist in table '${form.primaryTableName}'`;
    //            return valid;
    //        }
    //    });

    //    if (form.formType === 2) {
    //        const newDetailFieldList = form.detailTableDataFields.filter(field => field.isNew);
    //        newDetailFieldList.map(function (newField) {
    //            const detailList = detailFieldList.filter(field => field.fieldName === newField.fieldName);
    //            if (detailList.length > 0) {
    //                valid.status = false;
    //                valid.message = `Cannot add new field '${newField.fieldName}' since field already exist in table '${form.detailTableName}'`;
    //                return valid;
    //            }
    //        });
    //    }
    //}
    return valid;
}

const validateFormUIData = (form, isUpdate) => {
    
    let valid = { status: true, message: '', tab: 0 };

    if (isUpdate) {
        const fieldIndex = form.primaryTableDataFields.findIndex(f => f.isPrimaryKeyField)
        const index = form.formSection.header.findIndex(f => f.fieldName === form.primaryTableDataFields[fieldIndex].fieldName && f.controlUiId !== '' && f.controlUiId !== null)
        if (index === -1) {
            valid.status = false;
            valid.message = "A Primary field control is required";
            valid.tab = 1;
            return valid;
        }



        if (form.formType === FormType.HeaderDetail) {
            const fieldIndex = form.detailTableDataFields.findIndex(f => f.isPrimaryKeyField)
            const index = form.formSection.detail.formGridControl.findIndex(f => f.fieldName === form.detailTableDataFields[fieldIndex].fieldName && f.controlUiId !== '' && f.controlUiId !== null)
            if (index === -1) {
                valid.status = false;
                valid.message = "A Linked field control is required for the detail section";
                valid.tab = 1;
                return valid;
            }


        }
    }
   
    if (form.formSection.header) {
        
        if (form.formSection.header.length > 0) {

            if ((form.formSection.header.findIndex(f => f.controlUiId !== null && f.controlUiId !== '') !== -1)){
                const fieldIndex = form.primaryTableDataFields.findIndex(f => f.isPrimaryKeyField)
                const index = form.formSection.header.findIndex(f => f.fieldName === form.primaryTableDataFields[fieldIndex].fieldName && f.controlUiId !== '' && f.controlUiId !== null)
                if (index === -1) {
                    valid.status = false;
                    valid.message = "A Primary field control is required";
                    valid.tab = 1;
                    return valid;
                }
            }


        }
    }



    if (form.formType === FormType.HeaderDetail) {

        if ((form.formSection.header.findIndex(f => f.controlUiId !== null && f.controlUiId !== '') !== -1) && form.formSection.detail.formGridControl.length===0) {

            valid.status = false;
            valid.message = "A Linked field control is required for the detail section";
            valid.tab = 1;
            return valid;
        }
        if (form.formSection.detail.formGridControl.length > 0) {
            
            if ((form.formSection.detail.formGridControl.findIndex(f => f.controlUiId !== '' && f.controlUiId !== null)!==-1)){
            const fieldIndex = form.detailTableDataFields.findIndex(f => f.isPrimaryKeyField)
            const index = form.formSection.detail.formGridControl.findIndex(f => f.fieldName === form.detailTableDataFields[fieldIndex].fieldName && f.controlUiId !== '' && f.controlUiId !== null)
            if (index === -1) {
                valid.status = false;
                valid.message = "A Linked field control is required for the detail section";
                valid.tab = 1;
                 return valid;
                }

                                

        }
        }
    }
    return valid;
    
}

export const newFormSource = () => {
    let form = {
        formId: 0,
        formType: FormType.Standard,
        createNewTable: true,
        primaryTableDataFields: newTableFields([]),
        detailTableDataFields: newTableFields([]),
    };
    return form;
};
export const newForm = () => {
    let form = {
        formId: 0,
        formName: '',
        title: '',
        description: '',
        userInstruction: '',
        category: { categoryId: 0, categoryName: '' },
        company: { databaseId: 0, databaseConnectionName: "", database: "" },
        roles: [],
        isPublic: false,
        doNotShowInMenu: false,
        formType: FormType.Standard,
        isReadOnly: false,
        hasDependency: false,
        createNewTable: true,
        primaryTableName: '',
        detailTableName: '',
        primaryTableDataFields: newTableFields([]),
        detailTableDataFields: newTableFields([]),
        formUI: getFormUi(),
        formSection: newSection(), 
    };
    return form;
}; 
const newSection= () => {
    let section = {
        header: [],
        detail: { parentControlId: 0, formGridControl: [] },
        button: defaultFormButtons([])
    }
    return section;
}
export const defaultFormButtons = (tableButtonList) => {
    var saveButton = getFormControl(); 
    saveButton.formId = 0;
    saveButton.controlType = Enum.ControlType.ActionWithClearGet;
    saveButton.caption = "Save";
    saveButton.controlName = "Save";
    saveButton.isVisible = true;
    
    var saveAction = getButtonActionObject();
    saveAction.formId = 0
    saveAction.isActive = true;
    saveAction.actionName = "Save";
    saveAction.actionType = Enum.ActionType.Service;
    saveAction.actionSequence = 1;
    saveAction.serviceType = Enum.Method.Post;
    saveAction.actionTarget = "api/FormData/FormSubmitHDR";
    saveAction.actionTrigger = "0";
    saveAction.validateErrorMessage = "Saving Failed";
    saveAction.validateSuccessMessage = "Form saved successfully";
    saveButton.formAction.push(saveAction);
    tableButtonList.push(saveButton)

    var saveandNewButton = getFormControl();
    saveandNewButton.formId = 0;
    saveandNewButton.controlType = Enum.ControlType.ActionWithClear;
    saveandNewButton.caption = "Save and New";
    saveandNewButton.controlName = "Save and New";
    saveandNewButton.isVisible = true;
    var saveandnewAction = getButtonActionObject();
    saveandnewAction.formId = 0
    saveandnewAction.isActive = true;
    saveandnewAction.actionName = "Save and New";
    saveandnewAction.actionType = Enum.ActionType.Service;
    saveandnewAction.actionSequence = 1;
    saveandnewAction.serviceType = Enum.Method.Post;
    saveandnewAction.actionTarget = "api/FormData/FormSubmitHDR";
    saveandnewAction.actionTrigger = "0"; 
    saveandnewAction.validateErrorMessage = "Saving Failed";
    saveandnewAction.validateSuccessMessage = "Form saved successfully";
     
    saveandNewButton.formAction.push(saveandnewAction);
    tableButtonList.push(saveandNewButton)

    var saveandCloseButton = getFormControl();
    saveandCloseButton.formId = 0;
    saveandCloseButton.controlType = Enum.ControlType.Submit;
    saveandCloseButton.caption = "Save and Close";
    saveandCloseButton.controlName = "Save and Close";
    saveandCloseButton.isVisible = true;
    var saveandcloseAction = getButtonActionObject();
    saveandcloseAction.formId = 0
    saveandcloseAction.isActive = true;
    saveandcloseAction.actionName = "Save and Close";
    saveandcloseAction.actionType = Enum.ActionType.Service;
    saveandcloseAction.actionSequence = 1;
    saveandcloseAction.serviceType = Enum.Method.Post;
    saveandcloseAction.actionTarget = "api/FormData/FormSubmitHDR";
    saveandcloseAction.actionTrigger = "0";
    saveandcloseAction.validateErrorMessage = "Saving Failed";
    saveandcloseAction.validateSuccessMessage = "Form saved successfully";
    
    saveandCloseButton.formAction.push(saveandcloseAction);
    tableButtonList.push(saveandCloseButton)

    var deleteButton = getFormControl(); 
    deleteButton.formId = 0;
    deleteButton.controlType = Enum.ControlType.ActionWithConfirmClear;
    deleteButton.caption = "Delete";
    deleteButton.controlName = "Delete";
    deleteButton.isVisible = true;
    var deleteAction = getButtonActionObject();
    deleteAction.formId = 0;
    deleteAction.isActive = true;
    deleteAction.actionName = "Delete";
    deleteAction.actionType = Enum.ActionType.Service;
    deleteAction.actionSequence = 1;
    deleteAction.serviceType = Enum.Method.Get;
    deleteAction.actionTarget = "api/FormData/FormDelete";
    deleteAction.actionTrigger = "0"
    deleteAction.validateErrorMessage = "Deletion Failed"
    deleteAction.validateSuccessMessage = "Deleted Successfully"
    deleteButton.formAction.push(deleteAction);
    tableButtonList.push(deleteButton)

    var cancelButton = getFormControl(); 
    cancelButton.formId = 0;
    cancelButton.controlType = Enum.ControlType.ClearWithGetAction;
    cancelButton.caption = "Cancel";
    cancelButton.controlName = "Cancel";
    cancelButton.isVisible = true;
    var cancelAction = getButtonActionObject();
    cancelAction.formId = 0; 
    cancelAction.isActive = false;
    cancelAction.actionName = "";
    cancelAction.actionType = Enum.ActionType.Service;
    cancelAction.actionSequence = 1;
    cancelAction.serviceType = Enum.Method.Get;
    cancelAction.actionTarget = "";
    cancelAction.actionTrigger = "0"
    cancelAction.validateErrorMessage = ""
    cancelAction.validateSuccessMessage = ""
    cancelButton.formAction.push(cancelAction);
    tableButtonList.push(cancelButton)
    
    return tableButtonList;
}
export const newTableFields = (tableFieldList) => {
    tableFieldList.push({
        controlId: 0,
        fieldName: '',
        controlType: ControlType.TextBox,
        dataType: 'text',
        formatType: 'text',
        isPrimaryKeyField: false,
        isForeignKeyField: false,
        size: '50',
        precision: '0',
        isNew: true,
    });
    return tableFieldList;
}
export const removeTableFields = (tableFieldList, index) => {
    tableFieldList.splice(index, 1);
    return tableFieldList;
}
export const mergeNewTableField = (fieldList, currentFieldList) => {

    var tableFieldList = fieldList.map((item) => {

        const fieldIndex = currentFieldList.findIndex(f => f.fieldName === item.fieldName);
        if (fieldIndex !== -1) {
            return currentFieldList[fieldIndex];
        } else {
            return item
        }
        
    });

    return tableFieldList;
}

export const validateFieldsOnBlur = (value, fieldPath,) => {
    return dispatch => {
        validateObjectAt(formSetupSchema, value, fieldPath, fieldPath)
            .then(resp => {

                dispatch(setValidateFieldsOnBlur(resp));
            })

    }
}

const setValidateFieldsOnBlur = (errors) => {
    return {
        type: types.VGO_VALIDATE_FORM_SETUP_ONBLUR,
        errors: errors,
    };
};
export const showErrorWithValidationMessage = (validationObject) => {

    //return dispatch => {
     //   showError(dispatch, message, 5000);
    return {
        type: types.VGO_API_CALL_VALIDATION_TAB_ERROR,
        errorTab: validationObject.length > 0 ? validationObject[0].errorTab : "",
        validationObject: validationObject.length > 0 ? validationObject : {}
    }; 
};
export const resetControlRoles = (form, resetlookUpList) => {
    let roleList = form.roles;
    if (form.formSection.detail && form.formSection.detail.formGridControl) {
        form.formSection.detail.formGridControl = form.formSection.detail.formGridControl.map((control) => {
            control.formControlRoles = control.formControlRoles.filter(controlRole => {
                if (roleList.findIndex(r => r.roleId === controlRole.roleId) > -1) {
                    return controlRole;
                }
            }).map((role) => { return role; })
           
            return control;
        })
    }
    if (form.formSection.header) {
        form.formSection.header = form.formSection.header.map((control) => {
            control.formControlRoles = control.formControlRoles.filter(controlRole => {
                if (roleList.findIndex(r => r.roleId === controlRole.roleId) > -1) {
                    return controlRole;
                }
            }).map((role) => { return role; })
            
            return control;
        })
    }
    if (form.formSection.button) {
        form.formSection.button = form.formSection.button.map((control) => {
            control.formControlRoles = control.formControlRoles.filter(controlRole => {
                if (roleList.findIndex(r => r.roleId === controlRole.roleId) > -1) {
                    return controlRole;
                }
            }).map((role) => { return role; })

            return control;
        })
    }
    return form;
}




