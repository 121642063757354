import * as React from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { formTheme } from 'client/components/Form/theme/formTheme';
import { Grid} from '@mui/material';
import { withStyles } from '@mui/styles';
const CheckboxControl=(props)=> {
    

    const handleChange = (event) => {
           props.onChange(props.settings.isMandatory, props.settings.fieldName, event.target.checked ? 1 : 0);
    };
    let labelStyle = {
        color: props.settings.captionColor ? props.settings.captionColor : "rgba(0, 0, 0, 0.54)",
        wordBreak: "break-all",
       
    }
    let requiredIndicator = "";
    let helperText = props.settings.helpText ? props.settings.helpText:" ";
    let validation = [];
    if (props.settings.isMandatory && props.validationSchema) {
        requiredIndicator = " *";
        validation = props.validationSchema.filter(field => field.fieldName === props.settings.fieldName && field.errorState)
        if (validation.length > 0) {
            helperText = `${props.settings.caption} is required`;
            labelStyle = {
               color: "#f44336",
               wordBreak: "break-all",
           
            }

        }
    }
        
    const { classes } = props;

    return (
        <Grid container spacing={0}>
            <Grid item mt={props.isGrid ? 2.2 : 3.4} ml={props.isGrid ? .2 : props.isGridColumn?1.4:0}>
       
            <FormControl variant="standard" error={validation.length > 0 ? true : false} disabled={!props.isEditable}>
                <FormControlLabel style={labelStyle} 
                    label={<Typography variant="body2" >{props.showCaption ? props.settings.caption + requiredIndicator : ""}</Typography>}


                    control={
                        <Checkbox autoFocus={props.settings.hasFocus}
                            checked={props.dataModel[props.settings.fieldName] ? props.dataModel[props.settings.fieldName] === 1 || props.dataModel[props.settings.fieldName] === "1" ? true : false : false}
                            onChange={handleChange}
                            sx={{
                                content: '""',
                                height: 15,
                                width: 15,
                                marginLeft: props.showCaption ? 1.1 : "",
                                borderColor: "transparent",
                                color: props.settings.backGroundColor ? props.settings.backGroundColor : "",
                                marginRight: props.showCaption ? 1.5 : "",
                                "&.Mui-checked": {
                                    color: props.settings.backGroundColor,
                                    backgroundColor: props.settings.foreColor ? props.settings.foreColor : ""

                                },
                                "&.Mui-disabled": {
                                    color: props.settings.backGroundColor,

                                },
                                "&.Mui-text": {
                                    color: props.settings.backGroundColor ? props.settings.backGroundColor : ""
                                },
                                
                            }}
                            name={props.settings.fieldName}
                        />
                    }


                />
                <FormHelperText className={classes.checkBoxHelperText} >{helperText}</FormHelperText>
                    </FormControl>
                    </Grid>
                </Grid>
        
    );
}

export default withStyles(formTheme)(CheckboxControl);
